// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {
  numFulfilled: number,
  nuRequired: number,
};

const Header1: ComponentType<Props> = styled.h4`
  display: inline-block !important;
  margin-top: 0 !important;
  font-weight: 700 !important;
  color: ${({ numFulfilled, numRequired, theme }) => {
    if (numFulfilled === numRequired) return theme.colors.success("1");
    if (numFulfilled === 0) return theme.colors.danger("1");
    return theme.colors.primary_black("1");
  }} !important;
`;

export default Header1;
