import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { submitFile } from "common/actions/utils";
import api from "common/api";

const actions = keyMirror({
  SET_TITLE: null,
  REQUEST_ATTORNEY: null,
  RECEIVE_ATTORNEY: null,
  REQUEST_REQUIREMENTS: null,
  RECEIVE_REQUIREMENTS: null,
  REDIRECT_TO_NEXT_PHASE: null,
  REDIRECT_TO_NEXT_STEP: null,
  REQUEST_ONBOARDING_FORM: null,
  RECEIVE_ONBOARDING_FORM: null,
  REQUEST_ONBOARDING_FORM_ITEMS: null,
  RECEIVE_ONBOARDING_FORM_ITEMS: null,
  REQUEST_SUBMIT_ONBOARDING_FORM: null,
  RECEIVE_SUBMIT_ONBOARDING_FORM: null,
  START_ONBOARDING_POLL: null,
  STOP_ONBOARDING_POLL: null,

  // Attorney verifications
  REQUEST_BAR_INFORMATIONS: null,
  RECEIVE_BAR_INFORMATIONS: null,
  REQUEST_ALL_VERIFICATIONS: null,
  RECEIVE_ALL_VERIFICATIONS: null,
  REQUEST_VERIFICATION: null,
  RECEIVE_VERIFICATION: null,
  REQUEST_SUBMIT_BAR_FORM: null,
  RECEIVE_SUBMIT_BAR_FORM: null,
  REQUEST_SUBMIT_BARCARD_FILE: null,
  RECEIVE_SUBMIT_BARCARD_FILE: null,
  RECEIVE_SEND_PHONE_VERIFICATION_CODE: null,
  RECEIVE_SUBMIT_PHONE_VERIFICATION: null,

  // Non-attorney verifications
  REQUEST_SCHOOL_TRANSCRIPTS: null,
  RECEIVE_SCHOOL_TRANSCRIPTS: null,
  REQUEST_EMPLOYMENT_REFERENCES: null,
  RECEIVE_EMPLOYMENT_REFERENCES: null,

  // Files & profile picture
  REQUEST_SUBMIT_FILE: null,
  RECEIVE_SUBMIT_FILE: null,
  REQUEST_SUBMIT_TRANSCRIPT: null,
  RECEIVE_SUBMIT_TRANSCRIPT: null,
  REQUEST_SUBMIT_PROFILE_PICTURE_CROP: null,
  RECEIVE_SUBMIT_PROFILE_PICTURE_CROP: null,
  CLEAR_PROFILE_PICTURE: null,
  ADD_TRANSCRIPT: null,
  REMOVE_TRANSCRIPT: null,
  ADD_FOCUSED_ITEM: null,
});

export default actions;

export const addTranscript = createAction(actions.ADD_TRANSCRIPT);

export const removeTranscript = createAction(actions.REMOVE_TRANSCRIPT);

export const requestAttorney = () =>
  api.get("/api/v1/attorneys/self/", [
    actions.REQUEST_ATTORNEY,
    actions.RECEIVE_ATTORNEY,
  ]);

export const submitTranscriptFile = submitFile([
  actions.REQUEST_SUBMIT_TRANSCRIPT,
  actions.RECEIVE_SUBMIT_TRANSCRIPT,
]);
