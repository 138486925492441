import React from "react";
import ReactSelect from "react-select";
import querystring from "querystring";

import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { createJobsQuery } from "queries/requests/jobs";

const JobsDropdown = props => {
  const {
    jobs: {
      data,
      isFinished,
      isPending,
      query: { data: queryData },
    },
    onJobSelect,
  } = props;

  const options = isFinished
    ? queryData.map(jobId => {
        const job = data[jobId];
        const appCount = job.candidate_counts.total;
        const label = `${job.formattedTitle} (${appCount} applicant${
          appCount !== 1 ? "s" : ""
        })`;
        return {
          label,
          value: jobId,
        };
      })
    : [];
  options.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });
  const placeholder = isPending
    ? "Loading..."
    : "Select a job to view all candidates";

  return (
    <ReactSelect
      name="jobs"
      isLoading={isPending}
      options={options}
      onChange={onJobSelect}
      placeholder={placeholder}
      clearable
    />
  );
};

const mapPropsToConfig = () => {
  const params = querystring.stringify({
    joblisting__published: true,
    ordering: "-published_date",
    limit: 999,
  });
  return createJobsQuery({
    url: `/api/v2/jobs/?${params}`,
  });
};
const mapStateToProps = createResourceSelector("jobs", mapPropsToConfig);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  JobsDropdown,
);
