import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { mutateAsync } from "redux-query";
import { connectRequest } from "redux-query-react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { addNotification } from "reapop";

import Loading from "common/components/Loading";
import { createSuccessNotification } from "common/utils/notifications";
import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import types from "resources/types";
import { update } from "queries/mutations/job-workers";

class ManageWorkersForJob extends Component {
  handleWorkerClick = worker => () => {
    const {
      [types.CONTRACTORS]: { data: contractors },
      job,
      notify,
      toggleWorkerActive,
    } = this.props;

    toggleWorkerActive(worker).then(() => {
      const contractor = contractors[worker.contractor];
      const contractorName = contractor
        ? contractor.full_name
        : "<Name unavailable>";
      const newActive = !worker.active;
      notify(
        createSuccessNotification({
          message: `${contractorName} has been made ${
            newActive ? "active" : "inactive"
          } on ${job.title}.`,
        }),
      );
    });
  };

  render() {
    const {
      [types.CONTRACTORS]: {
        data: contractors,
        isFinished: contractorsFinished,
        isPending: contractorsPending,
      },
      [types.JOB_WORKERS]: {
        selectedData: workers,
        isFinished: workersFinished,
        isPending: workersPending,
      },
      job,
    } = this.props;

    const isFinished = contractorsFinished && workersFinished;
    const isPending = contractorsPending || workersPending;

    return (
      <div style={{ marginBottom: "3rem" }}>
        <label style={{ fontSize: "1.5rem" }}>
          Select active workers for <strong>{job.title}</strong>:
        </label>
        {isFinished && (
          <ButtonToolbar>
            {workers.map(worker => (
              <Button
                key={`activate-worker-${worker.uuid}`}
                bsStyle={worker.active ? "primary" : "default"}
                onClick={this.handleWorkerClick(worker)}
                style={{ marginBottom: "5px" }}
              >
                {contractors[worker.contractor] &&
                  contractors[worker.contractor].full_name}
                {!worker.active && <span>{" (inactive)"}</span>}
              </Button>
            ))}
          </ButtonToolbar>
        )}
        {isPending && <Loading />}
      </div>
    );
  }
}

const jobWorkersQuery = props =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: `/api/v2/job_workers/?job=${props.job.uuid}&limit=999`,
  });
const contractorsQuery = props =>
  createResourceListQuery(types.CONTRACTORS, {
    url: `/api/dev/contractors/?jobworker__job=${props.job.uuid}&limit=999`,
  });

const filterWorkers = (data, state, props) =>
  Object.values(data).filter(worker => worker.job === props.job.uuid);

const mapPropsToConfig = props => [
  jobWorkersQuery(props),
  contractorsQuery(props),
];

const jobWorkersSelectorConfig = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkersQuery,
  filterWorkers,
);
const contractorsSelectorConfig = createResourceSelectorConfig(
  types.CONTRACTORS,
  contractorsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...jobWorkersSelectorConfig,
  ...contractorsSelectorConfig,
});
export default compose(
  connect(
    mapStateToProps,
    {
      notify: addNotification,
      toggleWorkerActive: worker =>
        mutateAsync(update(worker.uuid, { active: !worker.active })),
    },
  ),
  connectRequest(mapPropsToConfig),
)(ManageWorkersForJob);
