import React, { Component } from "react";
import { sizes } from "styles/themeStyles";

const isMobileScreen = () => window.innerWidth < sizes.SM;
const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";

const withScreenSize = WrappedComponent => {
  class WithScreenSize extends Component {
    constructor(props) {
      super(props);
      const isMobile = isMobileScreen();
      this.state = {
        isDesktop: !isMobile,
        isMobile: isMobile,
      };
    }
    componentDidMount() {
      window.addEventListener("resize", this.handleWindowSizeChange);
    }
    componentWillUnmount() {
      window.removeEventListener("resize", this.handleWindowSizeChange);
    }
    handleWindowSizeChange = () => {
      const isMobile = isMobileScreen();
      this.setState({ isDesktop: !isMobile, isMobile: isMobile });
    };
    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  }
  WithScreenSize.displayName = `WithScreenSize(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithScreenSize;
};

export default withScreenSize;
