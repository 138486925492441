import React, { Component } from "react";
import { connect } from "react-redux";

import { requestJobsV2 } from "admin/actions/jobsDropdown";
import { ReactSelectField } from "common/components/forms/inputs";

class JobsDropdown extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { jobOptions, jobsLoading, name, ...otherProps } = this.props;

    return (
      <ReactSelectField
        name={name || "job"}
        id={`id_${name || "job"}`}
        options={jobOptions}
        placeholder={jobsLoading ? "Loading..." : "Select a Job"}
        {...otherProps}
      />
    );
  }
}

JobsDropdown.propTypes = {};

JobsDropdown.defaultProps = {
  name: "job",
};

export default connect(
  state => state.admin.jobsDropdown,
  { onLoad: requestJobsV2 },
)(JobsDropdown);
