import styled from "styled-components";

const Footing = styled.div`
  width: 100%;
  padding: 5px;
  border-radius: 0 0 8px 8px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.background_gray()};
  border-top: none;
  text-align: center;

  &:hover h3 {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.link_hover()} !important;
    text-decoration-color: ${({ theme }) =>
      theme.colors.link_hover()} !important;
  }

  ${({ theme }) => theme.media.mobile`
    padding: 0 5px 5px 5px;
    border-radius: 0;
    border: none;
  `};
`;

export default Footing;
