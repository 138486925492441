import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import Edit from "./blocks";
import states from "common/constants/states";

const DirectDepositDisplay = ({
  bankData: {
    bank_name,
    routing_number,
    account_number,
    fields: {
      birthdate: { day, month, year },
      ssn_last_4,
      first_name,
      last_name,
      address_1,
      address_2,
      city,
      state: stateID,
      postal_code,
      email_address,
      phone_home,
    },
  },
  onClick,
}) => (
  <Edit.Container>
    <Edit.ContainerContent>
      {bank_name && (
        <Row>
          <Col xs={6}>BANK</Col>
          <Col xs={6}>{bank_name}</Col>
        </Row>
      )}
      {routing_number && (
        <Row>
          <Col xs={6}>ROUTING</Col>
          <Col xs={6}>
            <code>{routing_number}</code>
          </Col>
        </Row>
      )}
      {account_number && (
        <Row>
          <Col xs={6}>ACCOUNT</Col>
          <Col xs={6}>
            <code>{`*****${account_number}`}</code>
          </Col>
        </Row>
      )}
      {ssn_last_4 && (
        <Row>
          <Col xs={6}>SSN</Col>
          <Col xs={6}>{`***-***-${ssn_last_4}`}</Col>
        </Row>
      )}
      {month &&
        day &&
        year && (
          <Row>
            <Col xs={6}>DOB</Col>
            <Col xs={6}>{`${month}/${day}/${year}`}</Col>
          </Row>
        )}
      {first_name &&
        last_name && (
          <Row>
            <Col xs={6}>NAME</Col>
            <Col xs={6}>{`${first_name} ${last_name}`}</Col>
          </Row>
        )}
      {address_1 && (
        <Row>
          <Col xs={6}>ADDRESS</Col>
          <Col xs={6}>
            {address_1}
            {address_2 &&
              address_2.length > 1 && (
                <Fragment>
                  <br />
                  {address_2}
                </Fragment>
              )}
            {city &&
              stateID &&
              postal_code && (
                <Fragment>
                  <br />
                  {`${city}, ${
                    states.find(obj => obj.value === stateID).label
                  } ${postal_code}`}
                </Fragment>
              )}
          </Col>
        </Row>
      )}
      {email_address && (
        <Row>
          <Col xs={6}>EMAIL</Col>
          <Col xs={6}>{email_address}</Col>
        </Row>
      )}
      {phone_home && (
        <Row>
          <Col xs={6}>PHONE</Col>
          <Col xs={6}>{phone_home}</Col>
        </Row>
      )}
      <Edit.MobileOnly>
        <Edit.PrimaryButton onClick={onClick}>edit</Edit.PrimaryButton>
      </Edit.MobileOnly>
    </Edit.ContainerContent>
  </Edit.Container>
);

const DirectDepositDisplaySection = ({ bankData, onClickToggle }) => (
  <Fragment>
    <Row>
      <Col sm={6}>
        <Row>
          <Col xs={8}>
            <h4>Information on File</h4>
          </Col>
          <Col xs={4}>
            <Edit.DesktopOnly>
              <Edit.PrimaryButton
                compact="true"
                float="right"
                onClick={onClickToggle}
              >
                edit
              </Edit.PrimaryButton>
            </Edit.DesktopOnly>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col sm={6}>
        <DirectDepositDisplay bankData={bankData} onClick={onClickToggle} />
      </Col>
    </Row>
  </Fragment>
);

export default DirectDepositDisplaySection;
