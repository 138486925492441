import actions from "admin/actions/applications/applicationsStats";
import { handleActions } from "redux-actions";

const initialStats = {
  jobs_opened: -1,
  recommendations: -1,
  invitations: -1,
  candidates: -1,
  offers: -1,
  placements: -1,
  placements_from_recommendations: -1,
  placements_from_auto_recommendations: -1,
  total_invoiced: -1,
};

const initialState = {
  stats: [{ ...initialStats }, { ...initialStats }],
  funnelStats: [{}, {}],
  statsMapping: {
    jobs_opened: "Jobs Opened",
    recommendations: "Recommendations Made",
    invitations: "Invitations Created",
    candidates: "Candidates Created",
    offers: "Offers Made",
    placements: "Placements",
    placements_from_recommendations: "Placements from Recommendations",
    placements_from_auto_recommendations:
      "Placements from Auto Recommendations",
    total_invoiced: "Total Invoiced ($)",
  },
  isLoading: false,
  lastUpdated: null,
};

const applicationsStats = handleActions(
  {
    [actions.REQUEST_APPLICATIONS_STATS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_APPLICATIONS_STATS]: (state, action) => {
      const newStats =
        action.payload.index === 0
          ? [{ ...state.stats[0], ...action.payload.stats }, state.stats[1]]
          : [state.stats[0], { ...state.stats[1], ...action.payload.stats }];
      return {
        ...state,
        stats: newStats,
        isLoading: false,
        lastUpdated: action.receivedAt,
      };
    },
  },
  initialState,
);
export default applicationsStats;
