import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { history as browserHistory } from "app";

import { markNotificationRead } from "hiringagent-dashboard/actions/notifications";
import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";

const contentTypes = ["alert", "jobpostingcloseeventforhiringagent"];

const NotificationsRemainingsStatus = ({ numRemaining }) =>
  numRemaining ? (
    <span style={{ verticalAlign: "-webkit-baseline-middle" }}>
      <small>
        {numRemaining} remaining{" "}
        {numRemaining > 1 ? "notifications" : "notification"}
      </small>
    </span>
  ) : null;

const NavigationButton = props => (
  <Button
    bsStyle="link"
    className="pull-right"
    onClick={() => props.handleCloseModal(props.notification.uuid)}
  >
    {props.numSubsequentNotifications > 0 ? "Next" : "Close"}
  </Button>
);

const GoToReviewsPageButton = props =>
  props.numSubsequentNotifications === 0 ? (
    <Row>
      <Col xs={12}>
        <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
          <Button
            bsStyle="success"
            onClick={() => props.handleRouteToReviews(props.notification.uuid)}
          >
            Go to performance reviews
          </Button>
        </div>
      </Col>
    </Row>
  ) : null;

const PerformanceReviewBodyText = ({ jobName }) => (
  <span>
    The job <strong>{jobName} </strong> was recently closed. Please take a
    moment to complete performance reviews for the contractors that worked on
    this job.
  </span>
);

class Notification extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    numSubsequentNotifications: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
  };

  render() {
    const {
      notification,
      numSubsequentNotifications,
      title,
      body,
      handleCloseModal,
    } = this.props;

    const bodyContent = (
      <div>
        <div style={{ marginBottom: "1rem" }}>{body}</div>
        <Row>
          <Col xs={12}>
            <NotificationsRemainingsStatus
              numRemaining={numSubsequentNotifications}
            />
            <NavigationButton {...this.props} />
            <span className="clearfix" />
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="notifications-container">
        <ConfirmModal
          key={`notification-modal-${notification.uuid}`}
          body={bodyContent}
          title={title}
          showFooter={false}
          show={Boolean(notification)}
          close={() => handleCloseModal(notification.uuid)}
        />
      </div>
    );
  }
}

Notification = connect(
  null,
  dispatch => ({
    handleCloseModal: notificationId =>
      dispatch(markNotificationRead(notificationId)),
  }),
)(Notification);

class PerformanceReviewNotification extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    numSubsequentNotifications: PropTypes.number.isRequired,
  };

  render() {
    const { notification, numSubsequentNotifications } = this.props;
    const body = (
      <div>
        <PerformanceReviewBodyText
          jobName={notification.content_object.job_name}
        />
        <GoToReviewsPageButton {...this.props} />
      </div>
    );

    return (
      <Notification
        notification={notification}
        numSubsequentNotifications={numSubsequentNotifications}
        title={"Pending Performance Reviews"}
        body={body}
      />
    );
  }
}

PerformanceReviewNotification = connect(
  null,
  dispatch => ({
    handleRouteToReviews: notificationId => {
      browserHistory.push("/agency/reviews/");
      dispatch(markNotificationRead(notificationId));
    },
  }),
)(PerformanceReviewNotification);

const Notifications = ({ pendingNotifications }) => {
  const notification = pendingNotifications[0];
  const notificationsCount = pendingNotifications.length;

  if (
    notificationsCount > 0 &&
    contentTypes.includes(notification.content_type)
  ) {
    if (notification.content_type === "alert") {
      return (
        <Notification
          notification={notification}
          numSubsequentNotifications={notificationsCount - 1}
          title={notification.content_object.title}
          body={<p>{notification.content_object.message}</p>}
        />
      );
    } else if (
      notification.content_type === "jobpostingcloseeventforhiringagent"
    ) {
      return (
        <PerformanceReviewNotification
          notification={notification}
          numSubsequentNotifications={notificationsCount - 1}
        />
      );
    }
  }

  return null;
};
Notifications.propTypes = {
  pendingNotifications: PropTypes.array.isRequired,
};

export default Notifications;
