import types from "resources/types";

const requiredResources = [
  types.CANDIDATES,
  types.JOBS,
  types.JOB_LISTINGS,
  types.JOB_PERMISSION,
  types.JOB_REQUIREMENTS,
  types.OWN_CONTRACTOR,
];

export default requiredResources;
