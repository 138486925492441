import React from "react";
import { Button, FormControl, Pager } from "react-bootstrap";

import {
  MessagesControlWrapper,
  CustomHeader,
  SearchForm,
  StyledPager,
} from "../blocks";
import { inits } from "../Messaging";

const MessagesControl = ({
  offset,
  onSearch,
  onSearchChange,
  onFilterChange,
  onPaginateMessages,
  mQuery,
  pendingSearch,
  filter,
}) => (
  <MessagesControlWrapper>
    <CustomHeader>Messages</CustomHeader>
    <SearchForm onSubmit={onSearch}>
      <FormControl
        type="search"
        onChange={onSearchChange}
        placeholder="Search..."
        value={pendingSearch}
      />
      <Button bsStyle="primary" type="submit">
        <i className="far fa-search" />
      </Button>
    </SearchForm>
    <FormControl
      componentClass="select"
      onChange={onFilterChange}
      value={filter}
    >
      <option value={"&inbox=1"}>Inbox</option>
      <option value={"&unread=1"}>Unread</option>
      <option value={"&sent=1"}>Sent</option>
      <option value={""}>All Messages</option>
      <option value={"&archived=1"}>Archived</option>
    </FormControl>
    <StyledPager>
      <Pager.Item previous onClick={() => onPaginateMessages("start")}>
        <i className="fas fa-chevron-double-left" />
      </Pager.Item>
      <Pager.Item previous onClick={() => onPaginateMessages("prev")}>
        <i className="fas fa-chevron-left" />
      </Pager.Item>
      {mQuery && mQuery.count > 0 && (
        <label>
          {`${offset + 1}-${Math.min(offset + inits.limit, mQuery.count)} of ${
            mQuery.count
          }`}
        </label>
      )}
      <Pager.Item next onClick={() => onPaginateMessages("end")}>
        <i className="fas fa-chevron-double-right" />
      </Pager.Item>
      <Pager.Item next onClick={() => onPaginateMessages("next")}>
        <i className="fas fa-chevron-right" />
      </Pager.Item>
    </StyledPager>
  </MessagesControlWrapper>
);

export default MessagesControl;
