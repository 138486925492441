import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { LinkContainer } from "react-router-bootstrap";
import { ButtonGroup, DropdownButton, MenuItem } from "react-bootstrap";

import { ContractorProfileShareModal } from "common/components/contractor-profile";
import InviteToJobsAction from "common/components/contractor-profile/InviteToJobsAction";
import { addMessageRecipientIds } from "hiringagent-dashboard/actions/messages";

const ActionsDropdownButton = styled(DropdownButton)`
  text-transform: uppercase;
  &&.btn-default.dropdown-toggle {
    &&,
    &&:active,
    &&:hover,
    &&:focus {
      color: ${({ theme }) => theme.colors.link()};
    }
  }
`;

const ContractorActionsDropdown = props => {
  const [showModal, setModal] = useState(false);
  const { contractor, addMessageRecipientIds } = props;

  return (
    <Fragment>
      <ButtonGroup justified>
        <ActionsDropdownButton
          title="actions"
          id={`contractor-actions-${contractor.uuid}`}
          block
        >
          <LinkContainer
            to={"/agency/messages/create/"}
            onClick={addMessageRecipientIds}
          >
            <MenuItem>
              <i className="far fa-envelope" /> Message
            </MenuItem>
          </LinkContainer>
          {contractor.allow_public_profile && (
            <MenuItem onSelect={() => setModal(true)}>
              <i className="far fa-share-square" /> Share Profile
            </MenuItem>
          )}
          <InviteToJobsAction
            className="invite-to-jobs-actions"
            style={{ width: "2px" }}
            onInviteClick={addMessageRecipientIds}
          />
        </ActionsDropdownButton>
      </ButtonGroup>
      <ContractorProfileShareModal
        modalStatus={showModal}
        close={() => setModal(false)}
        contractor={contractor}
      />
    </Fragment>
  );
};
ContractorActionsDropdown.propTypes = {
  contractor: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addMessageRecipientIds: () =>
    dispatch(addMessageRecipientIds([ownProps.contractor.uuid])),
});

export default connect(
  null,
  mapDispatchToProps,
)(ContractorActionsDropdown);
