import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { Label } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AttorneyIcon } from "hiringagent-dashboard/components/attorney/AttorneyNameIcon";
import { createResourceDetailQuery, createResourceSelector } from "queries";
import types from "resources/types";

const candidateStatusMapping = [
  { label: "Pending", bsStyle: "info" },
  { label: "Rejected", bsStyle: "danger" },
  { label: "Negotiating", bsStyle: "warning" },
  { label: "Hired", bsStyle: "success" },
];

export const CandidateContractorTableRow = props => {
  const {
    [types.CONTRACTORS]: { data: contractors, isPending },
    candidate,
  } = props;

  const contractor = contractors[candidate.contractor];

  if (isPending || !contractor) return null;

  const { label, bsStyle } = candidateStatusMapping[candidate.status];

  return (
    <tr>
      <td>
        <AttorneyIcon
          style={{ visibility: contractor.is_attorney ? "visible" : "hidden" }}
        />{" "}
        <Link to={`/agency/candidates/${candidate.uuid}/`}>
          {contractor.full_name}
        </Link>
      </td>
      <td>
        <Label bsStyle={bsStyle}>{label}</Label>
      </td>
    </tr>
  );
};

const mapPropsToConfig = props =>
  createResourceDetailQuery(types.CONTRACTORS, {
    url: `/api/dev/contractors/${props.candidate.contractor}/`,
  });

const mapStateToProps = createResourceSelector(
  types.CONTRACTORS,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(CandidateContractorTableRow);
