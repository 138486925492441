import actions from "admin/actions/previousEmploymentTagManager";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  employments: {},
  count: -1,
  next: null,
  isLoading: false,
  lastUpdated: false,
};

const addNewTagsAttribute = item => ({ ...item, newTags: [] });

const previousEmploymentTagManager = handleActions(
  {
    [actions.REQUEST_UNTAGGED_EMPLOYMENTS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_UNTAGGED_EMPLOYMENTS]: (state, action) => ({
      ...state,
      employments: listToObject(
        action.payload.results.map(addNewTagsAttribute),
        "uuid",
      ),
      count: action.payload.count,
      next: action.payload.next,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.REQUEST_MARK_EMPLOYMENT_REVIEWED]: (state, action) => {
      const { [action.payload]: _, ...newEmployments } = state.employments;
      return {
        ...state,
        count: state.count - 1,
        employments: newEmployments,
      };
    },
    [actions.RECEIVE_UNTAGGED_EMPLOYMENTS_NEXT_PAGE]: (state, action) => ({
      ...state,
      employments: {
        ...state.employments,
        ...listToObject(
          action.payload.results.map(addNewTagsAttribute),
          "uuid",
        ),
      },
      count: action.payload.count,
      next: action.payload.next,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.REQUEST_ADD_TAG_TO_EMPLOYMENT]: (state, action) => ({
      ...state,
      employments: {
        ...state.employments,
        [action.payload.employmentId]: {
          ...state.employments[action.payload.employmentId],
          tags: [
            ...state.employments[action.payload.employmentId].tags,
            action.payload.tagId,
          ],
        },
      },
    }),
  },
  initialState,
);
export default previousEmploymentTagManager;
