import actions from "../actions/search";
import favoritesActions from "../actions/favorites";
import { handleActions } from "redux-actions";

import languageOptions from "../constants/languageOptions";
import milesOptions from "../constants/milesOptions";
import stateOptions from "../constants/stateOptions";
import yearsExperienceOptions from "../constants/yearsExperienceOptions";

// Params that can be added to state.queryParams but are cleared on a new search
const extraParams = [
  "is_available",
  "barred_in",
  "practice_areas",
  "proficient_in",
  "years_experience",
  "max_years_experience",
  "miles",
  "location",
  "lat",
  "lng",
  "contractor_type",
];

const initialState = {
  attorneys: {},
  numberOfResults: -1,
  hasNextPage: false,
  queryParams: {
    keywords: "",
    page: 0,
    contractor_type: "attorneys",
    // practiceAreas: ''
  },
  filterOptions: {
    practiceAreas: {},
    languages: languageOptions,
    miles: milesOptions,
    states: stateOptions,
    yearsExperience: yearsExperienceOptions,
  },
  showFilters: true,
  isLoading: false,
  lastUpdated: null,
};

const search = handleActions(
  {
    [actions.INITIALIZE_SEARCH]: (state, action) => {
      // Initialize the queryParams based on URL params passed in to action.payload
      const newQueryParams = {};
      const { payload: query } = action;
      Object.keys(query).forEach(paramName => {
        if (paramName === "page") {
          newQueryParams[paramName] = parseInt(query[paramName], 10);
        } else {
          newQueryParams[paramName] = query[paramName].toString();
        }
      });
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...newQueryParams,
        },
        isLoading: true,
      };
    },
    [actions.RECEIVE_PRACTICE_AREAS_OPTIONS]: (state, action) => {
      const practiceAreas = {};
      action.payload.results.forEach(practiceArea => {
        practiceArea.selected = false;
        practiceAreas[practiceArea.name] = practiceArea;
      });
      const { practice_areas: paramPracticeAreas } = state.queryParams;
      if (paramPracticeAreas) {
        paramPracticeAreas.split(",").forEach(practiceAreaName => {
          if (practiceAreaName) {
            practiceAreas[practiceAreaName].selected = true;
          }
        });
      }
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          practiceAreas,
        },
      };
    },
    [actions.REQUEST_ATTORNEYS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.UPDATE_SEARCH_KEYWORDS]: (state, action) => ({
      ...state,
      queryParams: {
        ...state.queryParams,
        keywords: action.payload,
      },
    }),
    [actions.REMOVE_QUERY_PARAM]: (state, action) => {
      const queryParams = { ...state.queryParams };
      delete queryParams[action.payload];
      return {
        ...state,
        queryParams,
      };
    },
    [actions.SUBMIT_QUERY]: (state, action) => ({
      ...state,
      queryParams: {
        ...state.queryParams,
        ...action.payload,
        page: 0,
      },
      attorneys: {},
      hasNextPage: false,
      numberOfResults: -1,
      isLoading: true,
    }),
    [actions.INCREMENT_PAGE_NUMBER]: state => ({
      ...state,
      queryParams: {
        ...state.queryParams,
        page: state.queryParams.page + 1,
      },
    }),
    [actions.TOGGLE_SHOW_FILTERS]: state => ({
      ...state,
      showFilters: !state.showFilters,
    }),
    [actions.UPDATE_PRACTICE_AREA]: (state, action) => ({
      ...state,
      queryParams: {
        ...state.queryParams,
        practice_areas: action.payload,
      },
    }),
    [actions.CLEAR_FILTERS]: state => {
      const queryParams = { ...state.queryParams };
      extraParams.forEach(param => {
        queryParams[param] = "";
      });
      return {
        ...state,
        queryParams,
      };
    },
    [actions.REMOVE_ATTORNEY]: (state, action) => {
      const { attorneys } = state;
      delete attorneys[action.payload];
      return {
        ...state,
        attorneys,
      };
    },
    [favoritesActions.RECEIVE_UPDATE_FAVORITE]: (state, action) => {
      if (Object.keys(state.attorneys).includes(action.payload.attorneyUuid)) {
        return {
          ...state,
          attorneys: {
            ...state.attorneys,
            [action.payload.attorneyUuid]: {
              ...state.attorneys[action.payload.attorneyUuid],
              attorney: {
                ...state.attorneys[action.payload.attorneyUuid].attorney,
                on_a_list: action.payload.isFavorite,
              },
            },
          },
        };
      }

      return { ...state };
    },
    [actions.RECEIVE_ALGOLIA]: (state, action) => {
      const newAttorneys = {};
      const { page } = state.queryParams;
      action.payload.hits.forEach((attorney, index) => {
        attorney.uuid = attorney.objectID; // TODO: Index uuid
        newAttorneys[attorney.uuid] = {
          attorney,
          order: index + page * 20,
        };
      });
      return {
        ...state,
        attorneys: {
          ...state.attorneys,
          ...newAttorneys,
        },
        numberOfResults: action.payload.nbHits,
        hasNextPage: action.payload.page < action.payload.nbPages - 1,
        isLoading: false,
        lastUpdated: action.meta.receivedAt,
      };
    },
  },
  initialState,
);
export default search;
