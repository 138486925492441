// @flow strict
import React from "react";
// $FlowFixMe 'Cannot resolve module redux-form.'
import { reduxForm } from "redux-form";

import Workstyle from "contractor/components/WorkstyleAssessment/blocks";
import RadioGroupQuestion from "common/components/RadioGroupQuestion";

const WorkstyleAssessmentForm = ({
  handleSubmit,
  handleUpdateProgress,
  page,
  questions,
}) => (
  <Workstyle.Form onSubmit={handleSubmit}>
    {page === 0 && <Workstyle.Header>Workstyle Assessment</Workstyle.Header>}
    {questions.map((question, key) => (
      <RadioGroupQuestion
        question={question}
        key={key}
        handleUpdateProgress={handleUpdateProgress}
      />
    ))}
  </Workstyle.Form>
);

export default reduxForm({
  form: "workstyleAssessmentForm",
  destroyOnUnmount: false,
})(WorkstyleAssessmentForm);
