import React from "react";
import { reduxForm } from "redux-form";
import { Alert, Col, Row } from "react-bootstrap";

import {
  EmailAddressInput,
  PhoneNumberInput,
  TextField,
} from "common/components/forms/inputs";

let ContactInformationForm = ({
  error,
  handleSubmit,
  pending_email_address,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <Row>
      <Col sm={6}>
        <TextField name="first_name" label="First Name" />
      </Col>
      <Col sm={6}>
        <TextField name="last_name" label="Last Name" />
      </Col>
    </Row>
    <Row>
      <Col sm={6}>
        <EmailAddressInput
          disabled={Boolean(pending_email_address)}
          name="pending_email_address"
        />
      </Col>
      <Col sm={6}>
        <PhoneNumberInput name="phone_home" />
      </Col>
    </Row>
  </form>
);

export default reduxForm({
  form: "onboardingContactInformationForm",
})(ContactInformationForm);
