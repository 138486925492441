import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";

import Onboard from "../../blocks";
import ConfirmModal from "./ConfirmModal";

const Status = ({ status }) => {
  let textType = "default";

  switch (status) {
    case "rejected":
    case "expired":
      textType = "danger";
      break;
    case "pending":
      textType = "warning";
      break;
    case "complete":
      textType = "success";
      break;
    default:
      textType = "default";
  }

  return (
    <Onboard.TableStatusText status={textType}>
      {status}
    </Onboard.TableStatusText>
  );
};

const MobileEntry = ({
  isAttorney,
  last,
  onShowDeleteModal,
  onShowResendModal,
  reviewRequest,
  reviewRequest: {
    related_previous_employment,
    reviewer_first_name,
    reviewer_last_name,
    reviewer_email,
    status,
  },
}) => (
  <div>
    <br />
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>NAME</Onboard.EntryDataLabel>
      <Col xs={6}>
        {reviewer_first_name
          ? reviewer_last_name
            ? `${reviewer_first_name} ${reviewer_last_name}`
            : `${reviewer_first_name}`
          : "None"}
      </Col>
    </Onboard.EntryDataRow>
    {reviewer_email && (
      <Onboard.EntryDataRow>
        <Onboard.EntryDataLabel>EMAIL</Onboard.EntryDataLabel>
        <Col xs={6}>{reviewer_email}</Col>
      </Onboard.EntryDataRow>
    )}
    {!isAttorney && (
      <Onboard.EntryDataRow>
        <Onboard.EntryDataLabel>WORK EXPERIENCE</Onboard.EntryDataLabel>
        <Col xs={6}>
          {related_previous_employment ? (
            <Onboard.Icon iconType="success" />
          ) : (
            <Onboard.Icon iconType="unqualifying" />
          )}
        </Col>
      </Onboard.EntryDataRow>
    )}
    {status && (
      <Onboard.EntryDataRow>
        <Onboard.EntryDataLabel>STATUS</Onboard.EntryDataLabel>
        <Col xs={6}>
          <Status status={status} />
        </Col>
      </Onboard.EntryDataRow>
    )}
    <br />
    <Onboard.EntryDataRow>
      <Col xs={6}>
        <Onboard.TableButton
          bsStyle="primary"
          block
          onClick={() => onShowResendModal(reviewRequest)}
        >
          Resend
        </Onboard.TableButton>
      </Col>
      <Col xs={6}>
        <Onboard.TableButton
          bsStyle="danger"
          block
          onClick={() => onShowDeleteModal(reviewRequest)}
        >
          Delete
        </Onboard.TableButton>
      </Col>
    </Onboard.EntryDataRow>
    <br />
    {!last && <Onboard.ContainerDivider />}
  </div>
);

const DesktopEntry = ({
  isAttorney,
  onShowDeleteModal,
  onShowResendModal,
  reviewRequest,
  reviewRequest: {
    related_previous_employment,
    reviewer_first_name,
    reviewer_last_name,
    reviewer_email,
    status,
  },
}) => (
  <tr>
    {reviewer_first_name ? (
      reviewer_last_name ? (
        <td>{`${reviewer_first_name} ${reviewer_last_name}`}</td>
      ) : (
        <td>{`${reviewer_first_name}`}</td>
      )
    ) : (
      <td>None</td>
    )}
    {reviewer_email && <td>{reviewer_email}</td>}
    {!isAttorney && (
      <td>
        <center>
          {related_previous_employment ? (
            <Onboard.Icon iconType="success" />
          ) : (
            <Onboard.Icon iconType="unqualifying" />
          )}
        </center>
      </td>
    )}
    {status && (
      <td>
        <Status status={status} />
      </td>
    )}
    <Onboard.TableButtonCell>
      <Onboard.TableButton
        bsStyle="primary"
        margin="0 5px 0 0"
        onClick={() => onShowResendModal(reviewRequest)}
      >
        Resend
      </Onboard.TableButton>
      <Onboard.TableButton
        bsStyle="danger"
        onClick={() => onShowDeleteModal(reviewRequest)}
      >
        Delete
      </Onboard.TableButton>
    </Onboard.TableButtonCell>
  </tr>
);

class Entry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletingReviewRequest: null,
      resendingReviewRequest: null,
      showDeleteModal: false,
      showResendModal: false,
    };
  }

  handleHideDeleteModal = () =>
    this.setState({
      deletingReviewRequest: null,
      showDeleteModal: false,
      showResendModal: false,
    });

  handleHideResendModal = () =>
    this.setState({
      resendingReviewRequest: null,
      showDeleteModal: false,
      showResendModal: false,
    });

  handleShowDeleteModal = deletingReviewRequest =>
    this.setState({
      deletingReviewRequest,
      showDeleteModal: true,
      showResendModal: false,
    });

  handleShowResendModal = resendingReviewRequest =>
    this.setState({
      resendingReviewRequest,
      showDeleteModal: false,
      showResendModal: true,
    });

  render() {
    const {
      state: {
        deletingReviewRequest,
        resendingReviewRequest,
        showDeleteModal,
        showResendModal,
      },
      props: {
        isAttorney,
        last,
        mobile,
        onDeleteClick,
        onResendClick,
        reviewRequest,
      },
      handleHideDeleteModal,
      handleHideResendModal,
      handleShowDeleteModal,
      handleShowResendModal,
    } = this;
    const entryProps = {
      isAttorney,
      last,
      onShowDeleteModal: handleShowDeleteModal,
      onShowResendModal: handleShowResendModal,
      reviewRequest,
    };

    return (
      <Fragment>
        {mobile ? (
          <MobileEntry {...entryProps} />
        ) : (
          <DesktopEntry {...entryProps} />
        )}
        {deletingReviewRequest && (
          <ConfirmModal
            action="delete"
            reviewRequest={deletingReviewRequest}
            onActionClick={onDeleteClick}
            onHide={handleHideDeleteModal}
            show={showDeleteModal}
          />
        )}
        {resendingReviewRequest && (
          <ConfirmModal
            action="resend"
            reviewRequest={resendingReviewRequest}
            onActionClick={onResendClick}
            onHide={handleHideResendModal}
            show={showResendModal}
          />
        )}
      </Fragment>
    );
  }
}

export default Entry;
