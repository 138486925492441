// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { mutateAsync } from "redux-query";

import { JobAttachmentsList } from "common/components/jobs";
import { Job } from "types";
import { create, Delete } from "queries/mutations/job-attachments";

type Props = {
  job: Job,
  deleteAttachment: Function,
  uploadAttachment: Function,
};

class JobAttachments extends Component<Props> {
  handleSubmitFileClick = files => {
    files.forEach(file => {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("job", this.props.job.uuid);
      this.props.uploadAttachment(fd);
    });
  };

  render() {
    const { job, deleteAttachment } = this.props;

    return (
      <div className="main-info-container">
        <h2 className="main-info-title">Files</h2>
        {job.status !== Job.STATUS.CLOSED && (
          <div style={{ textAlign: "center" }}>
            <p className="file-upload-instructions">
              Upload files to share with any contractor hired to work on this
              job.
            </p>
            <Dropzone
              className="file-upload-dropzone"
              activeClassName="active-file-upload-dropzone"
              maxSize={15728640}
              onDrop={files => this.handleSubmitFileClick(files)}
            >
              <div style={{ textAlign: "center" }}>
                <i
                  className="fas fa-file-alt fa-5x"
                  style={{ marginBottom: "7px" }}
                />
                <p>Drag a file here or click to browse.</p>
              </div>
            </Dropzone>
          </div>
        )}

        <JobAttachmentsList
          jobId={job.uuid}
          onDeleteFileClick={deleteAttachment}
        />
      </div>
    );
  }
}
export default connect(
  null,
  {
    uploadAttachment: data => mutateAsync(create(data)),
    deleteAttachment: id => mutateAsync(Delete(id)),
  },
)(JobAttachments);
