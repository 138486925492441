import React, { Component } from "react";
import { connect } from "react-redux";
import { requestAsync } from "redux-query";
import { formValueSelector } from "redux-form";
import { Col, Row } from "react-bootstrap";
import querystring from "querystring";
import { createStructuredSelector } from "reselect";

import { deepEqual } from "common/utils";
import { JobSearchFilters, JobSearchResults } from "contractor/components";
import types from "resources/types";
import { createResourceListQuery, createResourceSelector } from "queries";

class JobSearchWithSearchResults extends Component {
  render() {
    const { [types.JOB_LISTINGS]: searchResults, onSearchSubmit } = this.props;
    return (
      <Row>
        <Col md={4}>
          <JobSearchFilters onSearchSubmit={onSearchSubmit} />
        </Col>
        <Col md={8}>
          <JobSearchResults searchResults={searchResults} />
        </Col>
      </Row>
    );
  }
}

const searchQuery = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?${querystring.stringify(props.formValues)}`,
  });
const searchSelector = createResourceSelector(types.JOB_LISTINGS, searchQuery);
const mapDispatchToProps = (dispatch, props) => ({
  onSearchSubmit: () => dispatch(requestAsync(searchQuery(props))),
});
JobSearchWithSearchResults = connect(
  searchSelector,
  mapDispatchToProps,
  null,
  { areOwnPropsEqual: deepEqual, areStatePropsEqual: deepEqual },
)(JobSearchWithSearchResults);

class JobSearch extends Component {
  render() {
    const { formValues } = this.props;
    return <JobSearchWithSearchResults formValues={formValues} />;
  }
}

const formSelector = formValueSelector("jobSearchForm");
const specificFormValueSelector = field => state => formSelector(state, field);
const mapStateToProps = createStructuredSelector({
  formValues: createStructuredSelector({
    limit: specificFormValueSelector("limit"),
    offset: specificFormValueSelector("offset"),
    ordering: specificFormValueSelector("ordering"),
    keywords: specificFormValueSelector("keywords"),
    location: specificFormValueSelector("location"),
    search: specificFormValueSelector("search"),
  }),
});
export default connect(mapStateToProps)(JobSearch);
