import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Alert } from "react-bootstrap";
import { CheckboxField } from "common/components/forms/inputs";

export const formName = "positionInterestForm";
export const requiredFields = [];

const PositionInterestLabel = ({ type }) => (
  <span>
    I&apos;m interested in <strong>{type}</strong> positions
  </span>
);

class PositionInterestForm extends Component {
  render() {
    const { anyTouched, error, submitFailed } = this.props;
    const showErrorMsg = error && (anyTouched || submitFailed);

    return (
      <div>
        {showErrorMsg && <Alert bsStyle="danger">{error}</Alert>}
        <CheckboxField
          className="input-large"
          name="interested_in_temp"
          label={<PositionInterestLabel type="temporary" />}
        />
        <CheckboxField
          className="input-large"
          name="interested_in_perm"
          label={<PositionInterestLabel type="permanent" />}
        />
      </div>
    );
  }
}

PositionInterestForm = reduxForm({ form: formName })(PositionInterestForm);

export default PositionInterestForm;
