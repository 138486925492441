import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { entitiesReducer, queriesReducer } from "redux-query";
import { routerReducer } from "react-router-redux";
import { reducer as notificationsReducer } from "reapop";

import adminReducer from "admin/reducers";
import authReducer from "common/reducers/auth";
import commonReducer from "common/reducers";
import contractorReducer from "contractor/reducers";
import hiringAgentDashboardReducer from "hiringagent-dashboard/reducers";
import publicReducer from "public/reducers";
import { resourcesReducer, resourcesStoreReducer } from "resources/reducer";

export default combineReducers({
  admin: adminReducer,
  auth: authReducer,
  common: commonReducer,
  contractor: contractorReducer,
  hiringAgentDashboard: hiringAgentDashboardReducer,
  anonymous: publicReducer,
  form: formReducer,
  notifications: notificationsReducer(),
  routing: routerReducer,
  resources: resourcesReducer,
  resourcesStore: resourcesStoreReducer,
  entities: entitiesReducer,
  queries: queriesReducer,
});
