import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { SupportLink } from "common/components";

const VerificationDenied = () => (
  <Row>
    <Helmet>
      <title>Verification Denied</title>
    </Helmet>
    <Col md={8}>
      <h2>Sorry, your hiring agency verification has been denied.</h2>
      <p>
        We appreciate your interest in Hire an Esquire, but unfortunately your
        hiring agency verification has been denied. If you have any questions
        about your application or if you believe you have received this message
        in error, please contact <SupportLink />.
      </p>
    </Col>
  </Row>
);

export default VerificationDenied;
