import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dropdown,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class SharePublicJobButton extends Component {
  static propTypes = {
    jobId: PropTypes.string.isRequired,
    text: PropTypes.string,
  };

  static defaultProps = {
    placement: "bottom",
    text: "Preview & Share",
    tooltipPlacement: "right",
  };

  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleCopyClick = () => {
    this.setState({ copied: true });
  };

  selectText = () => {
    if (this.textInput) this.textInput.select();
  };

  render() {
    const {
      jobId,
      placement,
      text,
      tooltipPlacement,
      ...otherProps
    } = this.props;
    const { copied } = this.state;

    const url = `${window.location.origin}/public/jobs/${jobId}`;

    const copyTooltip = (
      <Tooltip id="share-copy-tooltip">
        {copied ? "Copied!" : "Copy to clipboard"}
      </Tooltip>
    );
    const copyIcon = (
      <OverlayTrigger placement={tooltipPlacement} overlay={copyTooltip}>
        <Button style={{ height: "34px" }}>
          <i className="far fa-clipboard text-muted" />
        </Button>
      </OverlayTrigger>
    );
    const shareOverlay = (
      <Popover id="share-popover" title="Copy to Clipboard">
        <InputGroup>
          <FormControl
            type="text"
            readOnly
            value={url}
            inputRef={input => {
              this.textInput = input;
            }}
            onFocus={this.selectText}
          />
          <InputGroup.Button>
            <CopyToClipboard text={url} onCopy={this.handleCopyClick}>
              {copyIcon}
            </CopyToClipboard>
          </InputGroup.Button>
        </InputGroup>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement={placement}
        overlay={shareOverlay}
        onExited={() => this.setState({ copied: false })}
      >
        <Dropdown.Toggle bsStyle="primary" {...otherProps}>
          {text}
        </Dropdown.Toggle>
      </OverlayTrigger>
    );
  }
}
