import React from "react";

import { SelectField } from "common/components/forms/inputs";
import { withLanguageOptions } from "common/connectors";

const LanguageSelect = props => {
  const {
    languages: {
      isLoading,
      lastUpdated,
      data: { results },
    },
  } = props;

  return (
    <SelectField name="language" label={"Languages"}>
      {!isLoading &&
        lastUpdated &&
        results.map(({ uuid, name }) => (
          <option key={`language-option-${uuid}`} value={uuid}>
            {name}
          </option>
        ))}
    </SelectField>
  );
};
export default withLanguageOptions()(LanguageSelect);
