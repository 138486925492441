import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import { SubmissionError, initialize } from "redux-form";
import { addNotification as notify } from "reapop";

import {
  deleteEmailVerification,
  removeEmailVerification,
  submitCreateEmailVerification,
  submitEmailVerification,
} from "admin/actions/attorneyVerificationManager";
import {
  withEmailVerificationSet,
  withAttorneyVerificationManager,
} from "admin/connectors";
import { emailVerificationStatusMapping } from "contractor/utils/onboarding";
import EmailVerificationForm, {
  formName,
} from "admin/forms/EmailVerificationForm";
import {
  VerificationStatusText,
  VerificationActions,
} from "admin/components/attorney-verification-manager/utils";
import LoadingOr from "common/components/LoadingOr";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";

class EmailVerificationsList extends Component {
  render() {
    const { onDelete, onRequest, verifications } = this.props;

    return (
      <div>
        <Table className="verifications-list-table">
          <thead>
            <tr>
              <th>Email Address</th>
              <th>Status</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {verifications.map(verification => (
              <tr key={verification.uuid}>
                <td>{verification.verification_email_address}</td>
                <td>
                  <VerificationStatusText
                    {...emailVerificationStatusMapping[verification.state]}
                    status={verification.state}
                  />
                </td>
                <td>{moment(verification.created).format("L")}</td>
                <td>{moment(verification.updated).format("L")}</td>
                <td>
                  <VerificationActions
                    onRequest={() => onRequest(verification.uuid)}
                    state={verification.state}
                  />
                </td>
                <td>
                  {
                    <Button
                      bsStyle="danger"
                      bsSize="small"
                      onClick={() => onDelete(verification.uuid)}
                    >
                      <i className="fas fa-trash-alt" />
                    </Button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {verifications.length === 0 && (
          <div className="text-center">No Email Verifications</div>
        )}
      </div>
    );
  }
}

class EmailVerifications extends Component {
  handleSubmit = values => {
    const {
      submitCreateEmailVerification,
      notify,
      initializeForm,
      attorneyVerificationManager: { selectedBarInformationId },
    } = this.props;
    return submitCreateEmailVerification(values).then(({ json, response }) => {
      if (response.ok) {
        initializeForm({ barinformation: selectedBarInformationId });
      } else {
        notify(
          createErrorNotification({
            message: `Encountered an error when trying to add email verification.
                              Please refresh and try again.`,
          }),
        );
        throw new SubmissionError(json);
      }
    });
  };

  handleDelete = verificationId => {
    const { notify, onDelete, removeEmailVerification } = this.props;
    onDelete(verificationId)
      .then(removeEmailVerification(verificationId))
      .then(
        notify(
          createSuccessNotification({
            message: "Email verification successfully deleted.",
          }),
        ),
      );
  };

  handleRequest = verificationId => {
    const { notify, submitEmailVerification } = this.props;
    submitEmailVerification(verificationId, {
      action: "request_verification_authorization",
    }).then(
      notify(
        createSuccessNotification({
          message: "Email verification successfully requested.",
        }),
      ),
    );
  };

  render() {
    const {
      emailVerifications = {},
      emailVerificationSet: { ready },
      attorneyVerificationManager: { selectedBarInformationId },
    } = this.props;
    const verifications = Object.values(emailVerifications).filter(
      verification => verification.barinformation === selectedBarInformationId,
    );

    return (
      <LoadingOr showLoading={!ready}>
        <div>
          <EmailVerificationsList
            onRequest={this.handleRequest}
            onDelete={this.handleDelete}
            verifications={verifications}
          />
          <EmailVerificationForm
            initialValues={{ barinformation: selectedBarInformationId }}
            onSubmit={this.handleSubmit}
          />
        </div>
      </LoadingOr>
    );
  }
}

export default compose(
  connect(
    state => ({
      emailVerifications: state.admin.emailVerifications.emailVerifications,
    }),
    {
      initializeForm: data => initialize(formName, data),
      notify,
      onDelete: deleteEmailVerification,
      removeEmailVerification,
      submitCreateEmailVerification,
      submitEmailVerification,
    },
  ),
  withAttorneyVerificationManager(),
  withEmailVerificationSet(
    ({ attorneyVerificationManager: { selectedBarInformationId } }) => ({
      path: `email_verifications/?bar_info=${selectedBarInformationId}`,
    }),
  ),
)(EmailVerifications);
