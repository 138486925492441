import React from "react";
import PropTypes from "prop-types";

const LoadingOr = ({
  children,
  showLoading,
  showLoadingText,
  style,
  className,
}) =>
  showLoading ? (
    <div style={{ textAlign: "center", fontSize: "20px", ...style }}>
      <i className={`far fa-cog fa-spin ${className}`} />
      {showLoadingText && <span>&nbsp;Loading...</span>}
    </div>
  ) : (
    children
  );

LoadingOr.propTypes = {
  children: PropTypes.node.isRequired,
  showLoading: PropTypes.bool.isRequired,
  showLoadingText: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

LoadingOr.defaultProps = {
  showLoadingText: true,
  style: {},
  className: "",
};

export default LoadingOr;
