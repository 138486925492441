import React, { Fragment } from "react";

import {
  PasswordInput,
  RepeatPasswordInput,
} from "common/components/forms/inputs";
import { RenderWithCheckbox } from "common/components/render-props";

const PasswordInputsWithReveal = () => (
  <RenderWithCheckbox
    text="Show Password"
    render={reveal => {
      const inputType = reveal ? "text" : "password";
      return (
        <Fragment>
          <PasswordInput
            type={inputType}
            label="New Password"
            name="new_password1"
          />
          <RepeatPasswordInput
            type={inputType}
            label="Confirm New Password"
            name="new_password2"
          />
        </Fragment>
      );
    }}
  />
);

export default PasswordInputsWithReveal;
