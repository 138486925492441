import React from "react";
import { Button, ButtonToolbar, Label } from "react-bootstrap";
import NullOr from "common/components/NullOr";

export const VerificationStatusText = ({ status, style }) => (
  <span className={`text-${style}`}>
    <strong>{status}</strong>
  </span>
);

export const VerificationActions = ({ state, onDeny, onRequest, onVerify }) => (
  <ButtonToolbar>
    {state === "uploaded" && (
      <Button bsStyle="success" bsSize="small" onClick={onVerify}>
        Verify
      </Button>
    )}
    {state === "uploaded" && (
      <Button bsStyle="danger" bsSize="small" onClick={onDeny}>
        Deny
      </Button>
    )}
    {state === "init" && (
      <Button bsStyle="info" bsSize="small" onClick={onRequest}>
        Request
      </Button>
    )}
  </ButtonToolbar>
);

export const DuplicateLicenseWarning = ({ barInfo, size }) => (
  <NullOr isNull={!barInfo.is_duplicate}>
    {size === "small" ? (
      <i
        className="danger-color far fa-fw fa-exclamation-triangle"
        title="Duplicate License!"
      />
    ) : (
      <Label bsStyle="danger">Duplicate License!</Label>
    )}
  </NullOr>
);

export const OverallRejectedWarning = ({ barInfo, size }) => (
  <NullOr isNull={!barInfo.attorney_is_rejected}>
    {size === "small" ? (
      <i
        className="danger-color far fa-fw fa-exclamation-triangle"
        title="Contractor is Rejected Overall"
      />
    ) : (
      <Label bsStyle="danger">Contractor Rejected</Label>
    )}
  </NullOr>
);
