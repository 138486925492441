import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";

import { ProfilePicture } from "hiringagent-dashboard/components/search/AttorneyProfilePicture";

class ProfilePictureForm extends Component {
  static propTypes = {
    onClearStashUrl: PropTypes.func.isRequired,
    onCropSave: PropTypes.func.isRequired,
    onProfilePictureUpload: PropTypes.func.isRequired,
  };

  initialState = {
    crop: {
      aspect: 1,
    },
    pxCrop: {},
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleProfilePictureUrlChange = files => {
    const fd = new FormData();
    fd.append("picture", files[0]);
    this.props.onProfilePictureUpload(fd);
  };

  handleCropComplete = (crop, pxCrop) => {
    this.setState({
      crop,
      pxCrop,
    });
  };

  handleModalClose = () => {
    this.setState(this.initialState);
    this.props.onClearStashUrl();
  };

  handleCropSave = () => {
    const { pxCrop } = this.state;
    this.props.onCropSave({
      x1: pxCrop.x,
      x2: pxCrop.width + pxCrop.x,
      y1: pxCrop.y,
      y2: pxCrop.height + pxCrop.y,
    });
    this.setState(this.initialState);
  };

  render() {
    const { stashUrl, profile_picture_url } = this.props;

    const cropModal = stashUrl && (
      <Modal
        show={Boolean(stashUrl)}
        onHide={this.handleModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactCrop
            src={stashUrl}
            crossorigin={null}
            crop={this.state.crop}
            onChange={this.handleCropComplete}
            onComplete={this.handleCropComplete}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="link" onClick={this.handleModalClose}>
            Cancel
          </Button>
          <Button bsStyle="success" onClick={this.handleCropSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );

    return (
      <div>
        {stashUrl && cropModal}
        <Dropzone
          className="file-upload-dropzone"
          activeClassName="active-file-upload-dropzone"
          onDrop={this.handleProfilePictureUrlChange}
          accept="image/*"
          maxSize={15728640}
          style={{
            border: "3px dashed #DDD",
            borderRadius: "4px",
            cursor: "pointer",
            padding: "2rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {profile_picture_url ? (
              <ProfilePicture
                pictureUrl={profile_picture_url}
                firstName="A"
                lastName="A"
              />
            ) : (
              <i
                className="far fa-user-circle fa-5x"
                style={{ color: "#BBB", margin: "2rem 0", display: "block" }}
              />
            )}
            <span>Drag a file here or click to browse to change.</span>
          </div>
        </Dropzone>
      </div>
    );
  }
}

export default ProfilePictureForm;
