import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

export default class AttorneyPracticeAreaFilter extends Component {
  handleChange(newValue) {
    this.props.onPracticeAreasChange(newValue);
  }

  render() {
    const { practiceAreas, selectedPracticeArea } = this.props;
    const options = Object.keys(practiceAreas).map(practiceAreaName => ({
      value: practiceAreaName,
      label: practiceAreaName,
    }));

    return (
      <div className="form-group practice-areas-container">
        <ReactSelect
          name="practice_areas"
          id="id_practice_areas"
          options={options}
          onChange={(newValue, selectedOptions) =>
            this.handleChange(newValue, selectedOptions)
          }
          value={selectedPracticeArea}
          placeholder="Practice Area"
          simpleValue
        />
      </div>
    );
  }
}

AttorneyPracticeAreaFilter.propTypes = {
  practiceAreas: PropTypes.object.isRequired,
};
