import styled from "styled-components";

const Close = styled.i.attrs({
  className: "far fa-times",
  "aria-hidden": "true",
})`
  margin: 0 0 17px 0;
  padding: 0;
`;

export default Close;
