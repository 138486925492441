import React, { Component } from "react";
import { compose, connect, connectRequest } from "queries/utils";
import { Col, Row } from "react-bootstrap";
import { querySelectors } from "redux-query";
import { history as browserHistory } from "app";

import Loading from "common/components/Loading";
import { JobNavigationActions } from "common/components/jobs";
import NotFound from "public/components/NotFound";
import { PublicJobDetails } from "public/components/jobs";
import { JobMarketingFooter } from "public/components/marketing";
import { createResourceListQuery, createResourceSelector } from "queries";
import { authQuery } from "queries/requests/auth";
import types from "resources/types";
import PublicJobHeader from "./PublicJobHeader";

const PlatformDescription = () => (
  <div style={{ textAlign: "center", marginTop: "2rem" }}>
    <Row>
      <Col sm={10} smOffset={1}>
        <p className="header-copy">
          Join Hire an Esquire to apply to this role and gain access to
          incredible career opportunities and benefits available exclusively to
          our curated network.
        </p>
      </Col>
    </Row>
    <hr />
  </div>
);

const JobListing = ({ jobListing }) =>
  jobListing ? (
    <div
      className="public-job"
      itemScope
      itemType="http://schema.org/JobPosting"
    >
      <PlatformDescription />
      <PublicJobHeader jobListing={jobListing} />
      <PublicJobDetails jobListing={jobListing} />
      <JobNavigationActions isPublic={true} />
      <JobMarketingFooter showSignup={false} />
    </div>
  ) : (
    <NotFound />
  );

export class PublicJob extends Component {
  componentDidUpdate() {
    const {
      auth: {
        data: { is_contractor },
        isFinished,
      },
      match: {
        params: { id },
      },
    } = this.props;
    if (isFinished && is_contractor) {
      browserHistory.replace(`/contractor/jobs/${id}`);
    }
  }
  render() {
    const {
      auth: { isFinished: authFinished },
      [types.JOB_LISTINGS]: {
        data: jobListingsData,
        isFinished: jobListingsFinished,
        query: { data: jobListingsQueryData },
      },
    } = this.props;

    const jobListing = jobListingsData[jobListingsQueryData[0]];
    const isFinished = authFinished && jobListingsFinished;

    return isFinished ? <JobListing jobListing={jobListing} /> : <Loading />;
  }
}

const jobListingsQuery = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.match.params.id}`,
  });

const mapPropsToConfig = props => [jobListingsQuery(props), authQuery()];

const jobListingsSelector = createResourceSelector(
  types.JOB_LISTINGS,
  jobListingsQuery,
);

const mapStateToProps = (state, props) => ({
  auth: {
    ...state.entities.auth,
    isFinished: querySelectors.isFinished(state.queries, authQuery()),
  },
  ...jobListingsSelector(state, props),
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(PublicJob);
