import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Dropzone from "react-dropzone";

import Onboard from "../../blocks";
import { connect, mutateAsync } from "queries/utils";
import states, { statesByAbbreviation } from "common/constants/states";
import { addBarcardFileQuery } from "queries/mutations/barInformations";
import ToolTip from "common/components/ToolTip";
import { barIDTooltipCopy } from "./copy";
import StyledText from "common/blocks/StyledText";

const BarDocUpload = ({
  barInfoObj,
  justUploadedIds,
  onFileUploadError,
  onUploadBarcardFile,
  uploadVals,
}) =>
  uploadVals.label === "verified" ? (
    <Onboard.TableStatusText status="success">Done</Onboard.TableStatusText>
  ) : (
    <Dropzone
      maxSize={15728640}
      onDropRejected={onFileUploadError}
      onDrop={file => onUploadBarcardFile(file)}
      className="dropzone"
    >
      {barInfoObj &&
        justUploadedIds.includes(barInfoObj.barcard_verifications[0]) && (
          <Onboard.Icon iconType="success" />
        )}
      <Onboard.TableButton bsStyle={uploadVals.uploadStatus}>
        {uploadVals.label}
      </Onboard.TableButton>
    </Dropzone>
  );

const MobileEntry = ({
  barInfoObj,
  getStateLabel,
  justUploadedIds,
  onUploadBarcardFile,
  last,
  uploadVals,
  onFileUploadError,
  verifStatusVals,
}) => (
  <div>
    <br />
    <Onboard.EntryDataRow>
      <Col xs={6}>
        <StyledText textType="black" bold>
          BAR ID
        </StyledText>{" "}
        <ToolTip content={barIDTooltipCopy} placement="top">
          <i className="far fa-info-circle" />
        </ToolTip>
      </Col>
      <Col xs={6}>{barInfoObj.bar_id}</Col>
    </Onboard.EntryDataRow>
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>STATE</Onboard.EntryDataLabel>
      <Col xs={6}>{getStateLabel(barInfoObj)}</Col>
    </Onboard.EntryDataRow>
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>DOCUMENT</Onboard.EntryDataLabel>
      <Col xs={6}>
        <BarDocUpload
          barInfoObj={barInfoObj}
          justUploadedIds={justUploadedIds}
          onFileUploadError={onFileUploadError}
          onUploadBarcardFile={onUploadBarcardFile}
          uploadVals={uploadVals}
        />
      </Col>
    </Onboard.EntryDataRow>
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>STATUS</Onboard.EntryDataLabel>
      <Col xs={6}>
        <Onboard.TableStatusText status={verifStatusVals.verifStatus}>
          {verifStatusVals.text}
        </Onboard.TableStatusText>
      </Col>
    </Onboard.EntryDataRow>
    <br />
    {!last && <Onboard.ContainerDivider />}
  </div>
);

const DesktopEntry = ({
  barInfoObj,
  getStateLabel,
  justUploadedIds,
  onUploadBarcardFile,
  uploadVals,
  onFileUploadError,
  verifStatusVals,
}) => (
  <tr>
    <td>{barInfoObj.bar_id}</td>
    <td>{getStateLabel(barInfoObj)}</td>
    <Onboard.TableButtonCell>
      <BarDocUpload
        barInfoObj={barInfoObj}
        justUploadedIds={justUploadedIds}
        onFileUploadError={onFileUploadError}
        onUploadBarcardFile={onUploadBarcardFile}
        uploadVals={uploadVals}
      />
    </Onboard.TableButtonCell>
    <td>
      <Onboard.TableStatusText status={verifStatusVals.verifStatus}>
        {verifStatusVals.text}
      </Onboard.TableStatusText>
    </td>
  </tr>
);

class Entry extends Component {
  handleUploadBarcardFile = async files => {
    const {
      addBarcardFileQuery,
      addJustUploadedId,
      barInfoObj,
      refreshReqs,
    } = this.props;
    const verifId = barInfoObj.barcard_verifications[0];

    if (files.length) {
      const fd = new FormData();
      fd.append("barcard-attachment", files[0]);
      await addBarcardFileQuery(fd, verifId);
      addJustUploadedId(verifId);
      refreshReqs();
    }
  };

  getStateLabel = barInfoObj => {
    const state = states.find(obj => obj.value === barInfoObj.state).label;
    return statesByAbbreviation.find(obj => obj.label === state).value;
  };

  getUploadValues = () => {
    const {
      props: { barInfoObj, barVerifObj },
    } = this;
    let label, uploadStatus;

    if (barInfoObj.verified === 1) {
      label = "verified";
      uploadStatus = "success";
    } else {
      switch (barVerifObj.state) {
        case "uploaded":
          label = "change";
          uploadStatus = "success";
          break;
        case "verified":
          label = "verified";
          uploadStatus = "success";
          break;
        case "requested":
        default:
          label = "upload";
          uploadStatus = "primary";
      }
    }

    return { label, uploadStatus };
  };

  getVerifStatusValues = () => {
    let verifStatus, text;

    switch (this.props.barInfoObj.verified) {
      case -2:
        verifStatus = "warning";
        text = "unknown";
        break;
      case -1:
        verifStatus = "danger";
        text = "rejected";
        break;
      case 0:
        if (this.props.barVerifObj.state === "requested") {
          verifStatus = "default";
          text = "waiting for upload";
        } else {
          verifStatus = "warning";
          text = "pending";
        }
        break;
      case 1:
        verifStatus = "success";
        text = "verified";
        break;
      case 2:
        verifStatus = "default";
        text = "inactive";
        break;
      default:
        verifStatus = "warning";
        text = "unknown";
    }

    return { verifStatus, text };
  };

  render() {
    const {
      props: { barInfoObj, justUploadedIds, onFileUploadError, last, mobile },
      getStateLabel,
      getUploadValues,
      getVerifStatusValues,
      handleUploadBarcardFile,
    } = this;
    const entryProps = {
      barInfoObj,
      getStateLabel,
      justUploadedIds,
      onUploadBarcardFile: handleUploadBarcardFile,
      uploadVals: getUploadValues(),
      onFileUploadError,
      verifStatusVals: getVerifStatusValues(),
    };

    return mobile ? (
      <MobileEntry {...entryProps} last={last} />
    ) : (
      <DesktopEntry {...entryProps} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addBarcardFileQuery: (data, verifId) =>
    dispatch(mutateAsync(addBarcardFileQuery(data, verifId))),
});

export default connect(
  null,
  mapDispatchToProps,
)(Entry);
