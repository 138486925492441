import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { submit } from "redux-form";

import { JobSearchForm } from "contractor/forms";

class JobSearchFilters extends Component {
  componentDidMount() {
    this.props.submitSearchForm();
  }

  handleSubmit = values => {
    this.props.onSearchSubmit(values);
  };

  render() {
    return (
      <div>
        <h2>Filters</h2>
        <JobSearchForm onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      submitSearchForm: () => submit("jobSearchForm"),
    },
  ),
)(JobSearchFilters);
