import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";

import { FormRow, OptionalFormMessage, withFormError } from "./utils";
import { TextAreaField } from "common/components/forms/inputs";

class AdditionalDetailsForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormRow>
          <TextAreaField name="description" rows="10" maxLength={500} />
          <div>
            <p>
              Is anything else important to this job (e.g. hearing dates,
              deadlines, access to research)?
            </p>
          </div>
        </FormRow>
      </form>
    );
  }
}

AdditionalDetailsForm = compose(
  reduxForm({
    enableReinitialize: true,
  }),
  withFormError(),
)(AdditionalDetailsForm);

export default class AdditionalDetails extends Component {
  render() {
    const { formProps, jobListing } = this.props;
    const initialValues = {
      description: jobListing.description,
    };
    return (
      <div>
        <h2>Anything else we should know?</h2>
        <div className="description">
          <OptionalFormMessage />
        </div>
        <AdditionalDetailsForm {...formProps} initialValues={initialValues} />
      </div>
    );
  }
}
