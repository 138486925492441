import React from "react";
import { Col, Row } from "react-bootstrap";

export default () => (
  <Row>
    <Col lgOffset={2} lg={8}>
      <div className="legal-document">
        <div className="header-title">
          <h1 className="document-title">Privacy Policy</h1>
        </div>
        <p>
          This is the privacy policy of Hire an Esquire. This document explains
          Hire an Esquire's policies for the collection, use, and disclosure of
          personal information.
        </p>
        <h2>The Information We Collect</h2>
        <h3>Registration</h3>
        <p>
          If you register to sell or obtain services through Hire an Esquire we
          collect and store personal information that you submit to set up your
          account and profile including first and last name, address, phone
          number, e-mail information, valid financial information, professional
          license and disciplinary information, work history and experience and
          work preferences. We will also ask you to submit banking/ financial
          information to either make payments or accept payments for your work.
          Banking and financial information is encrypted and transmitted via
          2048-bit encryption for your protection. We will not sell, share or
          sell any Personal Information with third parties in any way other than
          what is disclosed in this policy.
        </p>
        <h3>Team Accounts</h3>
        <p>
          If you are a user of a Team Account, the information you provide to
          the Site may be accessible to other Users of your Team Account and
          Users of all related Team Accounts.
        </p>
        <h3>Online Surveys and Contests</h3>
        <p>
          Occasionally, Hire an Esquire conducts a User survey to collect
          information about our Users' preferences. These surveys are optional
          and if you choose to respond, your responses will be kept anonymous.
          Similarly, we may offer contests to qualifying Users in which we ask
          for contact and demographic information such as name, email address
          and mailing address. None of this information is shared with third
          parties, except in summary form, if at all. The demographic
          information that Hire an Esquire collects in the registration process
          and through surveys is used to help us improve our Services to meet
          the needs and preferences of our Users. This may include targeting
          advertising to you about our Services. Information we gather through a
          contest may also be disclosed to third parties as necessary for prize
          fulfillment and other aspects of any contest or similar offering.
        </p>
        <h3>The Hire an Esquire Payment System</h3>
        <p>
          The Hire an Esquire Payment Service requires Members to pay with a
          credit card, wire transfer, ACH or debit card, check, or PayPal. Hire
          an Esquire also requires bank account information for members to
          receive payments for services provided through automatic deposit.
          Consequently, Hire an Esquire will collect your credit card number
          and/or other entered financial institution information such as bank
          account numbers or PayPal email ID and will use that information to
          complete billing and payment activities, including but not limited to
          the use and disclosure of such financial information to third parties
          as necessary to complete such billing and payment operation. The user
          verifies credit information through the authentication process.
        </p>
        <h3>Service Providers and Agents</h3>
        <p>
          We use credit card and other payment processing companies to bill you
          for goods and services and email marketing companies to send emails
          from Hire an Esquire to opt-in users. We will share your Personal
          Information as necessary for third parties to provide these services.
          These companies do not retain, share, store or use personal
          information for any purposes other than those necessary to complete
          these services as contracted by Hire an Esquire.
        </p>
        <h2>How We Use This Information</h2>
        <p>
          This information is used to aid in the provisions of our various
          products and services, including customer service, accounting,
          billing, collections and the marketing of other products services.
        </p>
        <p>
          Hire an Esquire may use aggregate or anonymous information for various
          uses for itself and third parties.
        </p>
        <h2>Who We Share This Information With</h2>
        <p>
          Hire an Esquire does not share personal information with any third
          parties except as disclosed in this policy. Hire an Esquire may
          provide personal information to Hire an Esquire subcontractors,
          professional advisers and third parties that work on behalf of or with
          Hire an Esquire to provide you with the services of this site or to
          help us communicate with you or maintain the site.
        </p>
        <p>
          Although we currently do not have a parent company, any subsidiaries,
          joint ventures or other companies under a common control
          (collectively, "Affiliates"), we may in the future. We may share some
          or all of your Personal Information with these Affiliates, in which
          case we will require our Affiliates to honor this Privacy Policy.
        </p>
        <h2>Public Information</h2>
        <p>
          As part of registration, you may set up a Profile describing your
          experience, licenses and qualifications. To become a member and either
          apply for projects or post projects you must create a profile with
          information about yourself, experience, licenses and qualification and
          work preferences or about your firm, company or organization and the
          projects that you wish to post. This Information in your Profile is
          available for any other registered User to access. We will not have
          any obligation under this Privacy Policy with respect to information
          in your Profile or information you voluntarily provide to other Users
          via the Site.
        </p>
        <h2>Security</h2>
        <p>
          Personal information is stored in a combination of personal and
          electronic files. Electronic information transmitted through the site
          is secured by 2048-bit SSL. All passwords are encrypted with state of
          the art algorithms.
        </p>
        <h2>Accessing Information</h2>
        <p>
          Individuals may review their personal information contained in Hire an
          Esquire's files by contacting privacy@hireanesquire.com. If an
          individual believes that any of their personal information is
          inaccurate, we will make the appropriate corrections.
        </p>
        <h2>Cookies</h2>
        <p>
          Cookies are used by Hire an Esquire for the convenience of our users.
          Cookies automatically authenticate the user. A user can access the
          Hire an Esquire Web site with the cookie feature turned off. However,
          in doing so they may find themselves challenged for username and
          password information on multiple occasions. Hire an Esquire also uses
          cookies to track users' visits and uses that information to improve
          the users' experience.
        </p>
        <h2>Links</h2>
        <p>
          The Hire an Esquire Web site may contain links to information at other
          Web sites. When you click on one of these links, you are moving to
          another Web site. We encourage you to read the privacy statements of
          these linked sites as their privacy policy may differ from ours.
        </p>
        <h2>Contact Us</h2>
        <p>
          For more information on Hire an Esquire and privacy please contact
          Hire an Esquire at{" "}
          <a href="mailto:privacy@hireanesquire.com">
            privacy@hireanesquire.com
          </a>
        </p>
      </div>
    </Col>
  </Row>
);
