// @flow strict
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import ContractorProfileMetaDescription from "common/components/contractor-profile/ContractorProfileMetaDescription";

type Props = {
  contractor: PublicContractor,
};

const ContractorProfileHead = ({ contractor }: Props) => {
  const { REACT_APP_API_HOST, REACT_APP_FACEBOOK_APP_ID } = process.env;
  const contractorType =
    contractor && contractor.is_attorney ? "attorney" : "legal professional";
  const title = `View Hire an Esquire ${contractorType} profile | Hire an Esquire`;
  const imgURL = REACT_APP_API_HOST
    ? `${REACT_APP_API_HOST}/content/images/hae-logo-v1-white.png`
    : null;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={title} />
        {contractor &&
          contractor.summary_title && (
            <meta
              property="og:description"
              content={contractor.summary_title}
            />
          )}
        {imgURL && <meta property="og:image" content={imgURL} />}
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content={REACT_APP_FACEBOOK_APP_ID} />
      </Helmet>
      {contractor && (
        <ContractorProfileMetaDescription contractor={contractor} />
      )}
    </Fragment>
  );
};

export default ContractorProfileHead;
