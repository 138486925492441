import React, { Component } from "react";
import { connect } from "react-redux";
import { verifiedSelector } from "hiringagent-dashboard/selectors";

import { getNextPage } from "hiringagent-dashboard/actions/search";
import AttorneySearchFilters from "./AttorneySearchFilters";
import AttorneySearchResultsList from "./AttorneySearchResultsList";

import { Alert, Col, Row } from "react-bootstrap";

class AttorneySearchResultsContainer extends Component {
  onNextPageClick() {
    this.props.dispatch(getNextPage());
  }

  render() {
    const { hasNextPage, verified, verifiedLastUpdated } = this.props;
    const showPreviewAlert = !verified && verifiedLastUpdated;

    return (
      <div className="search-results-container">
        <div className="search-results-filters-container">
          <AttorneySearchFilters />
        </div>
        <div className="search-results-list-container">
          <AttorneySearchResultsList
            key="search-results-list"
            showPreviewAlert={showPreviewAlert}
          />
          {hasNextPage && (
            <div className="next-page-btn-container">
              {showPreviewAlert && (
                <Row>
                  <Col
                    xs={12}
                    xsOffset={0}
                    lg={6}
                    lgOffset={3}
                    sm={10}
                    smOffset={1}
                  >
                    <Alert bsStyle="info" style={{ marginBottom: "2rem" }}>
                      <i className="far fa-exclamation-circle" />
                      &nbsp;<strong>Preview enabled</strong> You are currently
                      viewing a preview of the Hire an Esquire network so your
                      search results are limited to one page. Once your agency
                      has been verified, you will have access to full search
                      results.
                    </Alert>
                  </Col>
                </Row>
              )}
              <div className="text-center">
                <button
                  className="btn btn-info"
                  onClick={() => this.onNextPageClick()}
                  disabled={!verified}
                >
                  Next Page &darr;
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  ...state.hiringAgentDashboard.search,
  verified: verifiedSelector(state),
  verifiedLastUpdated: state.hiringAgentDashboard.agency.lastUpdated,
}))(AttorneySearchResultsContainer);
