import React from "react";
import { Label } from "react-bootstrap";

import {
  filterPendingSchools,
  filterVerifiedSchools,
  filterRejectedSchools,
  filterPendingReferences,
  filterVerifiedReferences,
  filterRejectedReferences,
  hasRecentRelatedFilter,
} from "./utils";

const Counter = ({ num, bsStyle }) => (
  <Label bsStyle={num > 0 ? bsStyle : "default"}>{num}</Label>
);

export const PendingSchoolsCounter = ({ schools }) => {
  const pendingSchools = schools.filter(filterPendingSchools);
  return <Counter num={pendingSchools.length} bsStyle="info" />;
};
export const VerifiedSchoolsCounter = ({ schools }) => {
  const verifiedSchools = schools.filter(filterVerifiedSchools);
  return <Counter num={verifiedSchools.length} bsStyle="success" />;
};
export const RejectedSchoolsCounter = ({ schools }) => {
  const rejectedSchools = schools.filter(filterRejectedSchools);
  return <Counter num={rejectedSchools.length} bsStyle="danger" />;
};

export const PendingReferencesCounter = ({ references }) => {
  const pendingReferences = references.filter(filterPendingReferences);
  return <Counter num={pendingReferences.length} bsStyle="info" />;
};

export const VerifiedReferencesCounter = ({ references }) => {
  const verifiedReferences = references.filter(filterVerifiedReferences);
  return <Counter num={verifiedReferences.length} bsStyle="success" />;
};

export const RejectedReferencesCounter = ({ references }) => {
  const rejectedReferences = references.filter(filterRejectedReferences);
  return <Counter num={rejectedReferences.length} bsStyle="danger" />;
};

export const PendingEmploymentReferencesCounter = ({ nonAttorney }) => {
  const pendingReferences = nonAttorney.review_requests
    .filter(filterPendingReferences)
    .filter(review_request =>
      hasRecentRelatedFilter(review_request, nonAttorney),
    );
  return <Counter num={pendingReferences.length} bsStyle="info" />;
};

export const VerifiedEmploymentReferencesCounter = ({ nonAttorney }) => {
  const verifiedReferences = nonAttorney.review_requests
    .filter(filterVerifiedReferences)
    .filter(review_request =>
      hasRecentRelatedFilter(review_request, nonAttorney),
    );
  return <Counter num={verifiedReferences.length} bsStyle="success" />;
};

export const RejectedEmploymentReferencesCounter = ({ nonAttorney }) => {
  const rejectedReferences = nonAttorney.review_requests
    .filter(filterRejectedReferences)
    .filter(review_request =>
      hasRecentRelatedFilter(review_request, nonAttorney),
    );
  return <Counter num={rejectedReferences.length} bsStyle="danger" />;
};
