import actions from "hiringagent-dashboard/actions/agency";
import { handleActions } from "redux-actions";

const initialState = {
  agency: {},
  isLoading: false,
  lastUpdated: null,
};

const agency = handleActions(
  {
    [actions.REQUEST_AGENCY]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_AGENCY]: (state, action) => ({
      ...state,
      agency: { ...state.agency, ...action.payload },
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
    [actions.OPTIMISTIC_UPDATE_ONBOARDING]: (state, action) => ({
      ...state,
      agency: {
        ...state.agency,
        onboarding_state: action.payload,
      },
    }),
  },
  initialState,
);
export default agency;
