import React, { Component } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";

import {
  AgreeTermsOfServiceInput,
  EmailAddressInput,
  FirstNameInput,
  LastNameInput,
  PasswordInput,
  PhoneNumberInput,
  RepeatPasswordInput,
  TextField,
} from "common/components/forms/inputs";
import {
  getCampaignParamsFromLocalStorage,
  getUrlParamOrCookie,
} from "common/utils";
import { validatePasswords, validateRequiredFields } from "common/utils/forms";

const FormInputsContainer = styled.div`
  & .form-group {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Submit = styled.div`
  text-align: center;
  margin: 20px 0;

  & .btn {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const requiredFields = [
  "first_name",
  "last_name",
  "phone_main",
  "email_address",
  "password",
  "repeat_password",
  "name",
  "agreed_tos",
];

const validate = values => {
  let errors = validateRequiredFields(requiredFields, values);
  errors = validatePasswords(values, errors);
  return errors;
};

class HiringAgencySignupForm extends Component {
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <FormInputsContainer>
        <form onSubmit={handleSubmit}>
          <TextField name="name" label="Firm or Company Name" />
          <FirstNameInput />
          <LastNameInput />
          <PhoneNumberInput name="phone_main" />
          <EmailAddressInput />
          <PasswordInput />
          <RepeatPasswordInput />
          <TextField name="discount_code" label="Promo or Referral Code" />
          <AgreeTermsOfServiceInput link="/terms-of-service/agency" />
          <Submit>
            <Button
              type="submit"
              style={{ width: "160px" }}
              bsSize="large"
              bsStyle="info"
              disabled={submitting}
            >
              {submitting ? (
                <span>
                  <i className="far fa-cog fa-spin" aria-hidden="true" />
                  &nbsp;Submitting...
                </span>
              ) : (
                "Sign Up"
              )}
            </Button>
          </Submit>
        </form>
      </FormInputsContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: {
    discount_code: props.discountCode,
    gclid: getUrlParamOrCookie("gclid"),
    hubspotutk: getUrlParamOrCookie("hubspotutk"),
    page_name: document.title,
    ...getCampaignParamsFromLocalStorage(),
  },
});

HiringAgencySignupForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: "HiringAgencySignupForm",
    validate,
  }),
)(HiringAgencySignupForm);

export default HiringAgencySignupForm;
