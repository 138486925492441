import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import { history as browserHistory } from "app";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connectRequest } from "redux-query-react";
import { compose, connect, mutateAsync, requestAsync } from "queries/utils";

import { createMessageQuery } from "queries/mutations/messages";
import { hiringAgentQueryWithConfig } from "queries/requests/hiring-agents";
import types from "resources/types";
import {
  createResourceDetailQuery,
  createResourceSelectorConfig,
  createResourceListQuery,
} from "queries";
import { email_re, phone_re } from "common/utils/regex";
import { ContractorAlert } from "common/components/copy/contactInfoAlerts";
import { TextField, TextAreaField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";

const formName = "messageHiringAgentForm";
const requiredFields = ["subject", "body"];
const validate = values => validateRequiredFields(requiredFields, values);
const subjectPlaceholder = "What is your message about?";
const bodyPlaceholder =
  "Message the Posting Agent to discuss or clarify hours," +
  " rate, start date, availability, location, application status," +
  " or other information needed for your application.";

const MessageToAgentSent = ({ job, params }) => (
  <div>
    <p style={{ marginBottom: "5em" }}>
      Your message has been sent to the Posting Agent for{" "}
      <Link to={`/contractor/jobs/${params.id}`}>{job && job.title}</Link>.
    </p>
    <div style={{ padding: "2.5em 0em" }}>
      <IndexLinkContainer to={`/contractor/jobs/${params.id}`}>
        <Button bsStyle="primary" className="pull-right">
          <i className="fas fa-times" /> Close
        </Button>
      </IndexLinkContainer>
      <IndexLinkContainer to={`/contractor/messages`}>
        <Button
          bsStyle="primary"
          className="pull-right"
          style={{ marginRight: "1em" }}
        >
          <i className="fas fa-paper-plane" /> Go to Messages
        </Button>
      </IndexLinkContainer>
    </div>
  </div>
);

let MessageHiringAgentForm = props => (
  <Form horizontal onSubmit={props.handleSubmit}>
    <fieldset>
      <TextField
        label="Subject*"
        horizontal
        name="subject"
        placeholder={subjectPlaceholder}
        disabled={props.isSending}
        onChange={props.checkSubjectForContactInfo}
      />
      <TextAreaField
        label="Body*"
        horizontal
        name="body"
        placeholder={bodyPlaceholder}
        rows="7"
        disabled={props.isSending}
        onChange={props.checkMessageForContactInfo}
      />
      <IndexLinkContainer to={`/contractor/jobs/${props.jobId}`}>
        <Button
          bsStyle="primary"
          className="pull-right"
          disabled={props.isSending}
        >
          <i className="fas fa-times" /> Cancel
        </Button>
      </IndexLinkContainer>
      <Button
        bsStyle="primary"
        disabled={props.isSending || props.pristine}
        className="pull-right"
        style={{ marginRight: "1em" }}
        type="submit"
      >
        <i className="fas fa-paper-plane" /> Send
      </Button>
    </fieldset>
  </Form>
);

MessageHiringAgentForm = reduxForm({
  form: formName,
  validate,
})(MessageHiringAgentForm);

// Modal has 'show' permanently 'true' because display is handled by routing
export class MessageToAgent extends Component {
  constructor(props) {
    super(props);
    this.state = { sent: false, error: false, isSending: false };
  }

  handleSubmit = values => {
    this.setState({ isSending: true }, async () => {
      const haeuser = await this.getHAEUser();
      if (haeuser) {
        this.postMessage(values, haeuser);
      }
    });
  };

  getHAEUser = async () => {
    const {
      jobs,
      jobListings,
      match: { params },
      agentQuery,
    } = this.props;
    const { joblisting } = jobs.data[params.id];
    const { posting_agent } = jobListings.data[joblisting];
    const result = await agentQuery(posting_agent);
    if (result.status === 200 && result.body && result.body.haeuser) {
      return result.body.haeuser;
    } else {
      this.setState({ sent: false, error: true, isSending: false });
      return null;
    }
  };

  postMessage = async (values, haeuser) => {
    const {
      match: { params },
      messageQuery,
    } = this.props;
    const data = {
      subject: values.subject,
      body: values.body,
      recipients: [haeuser],
      job: params.id,
    };
    const result = await messageQuery(data);
    const isSuccessful = result.status === 201;
    this.setState({
      sent: isSuccessful,
      error: !isSuccessful,
      isSending: false,
    });
  };

  handleClose = () => {
    if (!this.state.isSending) {
      browserHistory.push(`/contractor/jobs/${this.props.match.params.id}`);
    }
  };

  checkForContactInfo = event => {
    return (
      phone_re.test(event.target.value) || email_re.test(event.target.value)
    );
  };

  checkSubjectForContactInfo = event => {
    this.setState({ subjectHasPersonalInfo: this.checkForContactInfo(event) });
  };

  checkMessageForContactInfo = event => {
    this.setState({ messageHasPersonalInfo: this.checkForContactInfo(event) });
  };

  render() {
    const { sent, error, isSending } = this.state;
    const {
      jobs,
      jobListings,
      match: { params },
    } = this.props;
    const job = jobListings.data[jobs.data[params.id].joblisting];
    return (
      <Modal bsSize="large" show={true} onHide={this.handleClose}>
        <Modal.Header closeButton style={{ borderStyle: "none" }}>
          <Modal.Title>
            <i className="fas fa-paper-plane" /> New Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2em 3em" }}>
          {sent ? (
            <MessageToAgentSent params={params} job={job} />
          ) : (
            <Fragment>
              <p style={{ paddingBottom: "1em" }}>
                <b>To:</b> Posting Agent for{" "}
                <Link to={`/contractor/jobs/${params.id}`}>
                  {job && job.title}
                </Link>
              </p>
              <MessageHiringAgentForm
                onSubmit={this.handleSubmit}
                jobId={params.id}
                isSending={isSending}
                checkSubjectForContactInfo={this.checkSubjectForContactInfo}
                checkMessageForContactInfo={this.checkMessageForContactInfo}
              />
            </Fragment>
          )}
        </Modal.Body>

        {(this.state.messageHasPersonalInfo ||
          this.state.subjectHasPersonalInfo) && (
          <Modal.Footer style={{ borderStyle: "none", textAlign: "left" }}>
            <Alert bsStyle={"danger"}>
              <ContractorAlert />
            </Alert>
          </Modal.Footer>
        )}
        {error && (
          <Modal.Footer style={{ borderStyle: "none" }}>
            <Alert bsStyle="danger">
              We&apos;re sorry, there was a problem submitting your request.
              Please refresh this page and try again.
            </Alert>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });
const jobListingQuery = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.match.params.id}`,
  });
const mapPropsToConfig = props => [jobQuery(props), jobListingQuery(props)];
const jobsSelector = createResourceSelectorConfig(types.JOBS, jobQuery);
const jobListingsSelector = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingQuery,
);
const mapStateToProps = createStructuredSelector({
  ...jobsSelector,
  ...jobListingsSelector,
});
const mapDispatchToProps = dispatch => ({
  agentQuery: posting_agent =>
    dispatch(
      requestAsync(hiringAgentQueryWithConfig({ force: true }, posting_agent)),
    ),
  messageQuery: data => dispatch(mutateAsync(createMessageQuery(data))),
});
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(MessageToAgent);
