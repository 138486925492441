import * as dateActions from "admin/actions/invoicing/dates";

const initialDatesState = {
  startDate: null,
  endDate: null,
};

export default function dates(state = initialDatesState, action) {
  switch (action.type) {
    case dateActions.SET_INITIAL_DATES:
      return Object.assign({}, state, {
        startDate: action.startDate,
        endDate: action.endDate,
      });
    case dateActions.SET_START_DATE:
      return Object.assign({}, state, {
        startDate: action.startDate,
      });
    case dateActions.SET_END_DATE:
      return Object.assign({}, state, {
        endDate: action.endDate,
      });
    default:
      return state;
  }
}
