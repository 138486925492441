// @flow strict
import styled from "styled-components";

const OuterTop = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
  margin: 15px 0 25px 0;
  padding: 0;
`;

export default OuterTop;
