import React from "react";
import { Field, FieldArray } from "redux-form";
import { Link } from "react-router-dom";

import {
  BubbleCheckboxControl,
  DatePickerInput,
  DropzoneInput,
  FormControlGroup,
  FormFieldToolTip,
  FormGroupNonControl,
  GeosuggestControl,
  RangeSliderControl,
  RadioButtonSet,
  RatingInputGroup,
  ReactSelectFormControlGroup,
  SelectInput,
  ToggleRadioButtons,
} from "..";
import states, { statesByAbbreviation } from "common/constants/states";

const passwordTooltipHint =
  "Your password should be 8+ characters long and include an " +
  "uppercase letter, a lower case letter, and a number or symbol.";
export const PasswordToolTip = ({ label = "Password" }) => (
  <FormFieldToolTip
    label={label}
    title="Note"
    content={passwordTooltipHint}
    placement="top"
  />
);

// TODO: Put each of these different field types into their own module
// Defines base component which renders a <Field>
// Each <Field> here should have a different component prop
export const BubbleCheckboxField = props => (
  <Field component={BubbleCheckboxControl} {...props} />
);
export const CheckboxField = props => (
  <Field
    component={FormGroupNonControl}
    type="checkbox"
    typeClass="checkbox"
    parse={v => Boolean(v)}
    {...props}
  />
);
export const DatePickerField = props => (
  <Field component={DatePickerInput} {...props} />
);
export const DropzoneField = props => (
  <Field component={DropzoneInput} {...props} />
);
const FormControlGroupField = props => (
  <Field component={FormControlGroup} {...props} />
);
export const GeosuggestField = props => (
  <Field component={GeosuggestControl} {...props} />
);
export const HiddenField = props => (
  <Field component="input" type="hidden" {...props} />
);
export const HiddenFieldArray = props => (
  <FieldArray component="input" type="hidden" {...props} />
);
export const RatingField = props => (
  <Field component={RatingInputGroup} {...props} />
);
export const SelectField = props => (
  <Field component={SelectInput} componentClass="select" {...props} />
);
export const RadioButtonSetField = props => (
  <Field component={RadioButtonSet} {...props} />
);
export const RangeSliderField = props => (
  <Field component={RangeSliderControl} {...props} />
);
export const ReactSelectField = props => (
  <Field component={ReactSelectFormControlGroup} {...props} />
);
export const ToggleRadioButtonsField = props => (
  <Field component={ToggleRadioButtons} {...props} />
);

// Generic 'type' fields
const EmailField = props => <FormControlGroupField type="email" {...props} />;
export const HiddenFieldWithHelpText = props => (
  <FormControlGroupField type="hidden" {...props} />
);
export const NumberField = props => (
  <FormControlGroupField type="number" step="any" {...props} />
);
export const PasswordField = props => (
  <FormControlGroupField type="password" {...props} />
);
export const TextField = props => (
  <FormControlGroupField type="text" {...props} />
);
export const TextAreaField = props => (
  <TextField componentClass="textarea" {...props} />
);
const TelField = props => <FormControlGroupField type="tel" {...props} />;

// Inputs with label
export const PhoneNumberInput = props => (
  <TelField label="Phone Number" {...props} />
);

// Inputs with label and name
export const AgreeNotARecruiterInput = props => (
  <CheckboxField name="agreed_nar" label="Recruiter Acknowledgement" {...props}>
    <br />
    <span>
      By checking this box you acknowledge that you are not a recruiter, an
      attorney that engages in the business of staffing attorneys, or otherwise
      a third party to the retaining or hiring of attorneys unless expressly
      permitted by Hire an Esquire.
    </span>
  </CheckboxField>
);
const getDefaultToSLabel = (link = "/public/terms-of-service/") => (
  <span>
    By checking this box, you agree to the{" "}
    <Link to={link} target="_blank" rel="noopener noreferrer">
      Terms of Service&nbsp;
      <i className="fas fa-external-link-alt" aria-hidden="true" />
    </Link>{" "}
    as outlined.
  </span>
);
export const AgreeTermsOfServiceInput = ({ link = null, ...otherProps }) => {
  const label = getDefaultToSLabel(link);
  return <CheckboxField name="agreed_tos" label={label} {...otherProps} />;
};

export const EmailAddressInput = props => (
  <EmailField name="email_address" label="Email Address" {...props} />
);
export const FirstNameInput = props => (
  <TextField name="first_name" label="First Name" {...props} />
);
export const LastNameInput = props => (
  <TextField name="last_name" label="Last Name" {...props} />
);
export const PasswordInput = props => (
  <PasswordField name="password" label={<PasswordToolTip />} {...props} />
);
export const RepeatPasswordInput = props => (
  <PasswordField name="repeat_password" label="Confirm Password" {...props} />
);
export const DollarInput = props => <NumberField addonLeft="$" {...props} />;
export const DollarPerHourInput = props => (
  <DollarInput addonRight="per hour" step="1" {...props} />
);

export const StateSelect = ({ byAbbreviation = false, ...otherProps }) => {
  const stateOptions = byAbbreviation ? statesByAbbreviation : states;
  return (
    <SelectField name="state" label="State" {...otherProps}>
      {stateOptions.map((state, index) => (
        <option key={`state-option-${index}`} value={state.value}>
          {state.label}
        </option>
      ))}
    </SelectField>
  );
};

export {
  default as BarRequirementsFieldArray,
} from "./BarRequirementsFieldArray";
export { default as CityStateGeosuggest } from "./CityStateGeosuggest";
export {
  default as ContractorTypeSelectField,
} from "./ContractorTypeSelectField";
export {
  default as ExpertiseAreasSelectField,
} from "./ExpertiseAreasSelectField";
export { default as HoursPerWeekSelectField } from "./HoursPerWeekSelectField";
export { default as HowYouHeardSelect } from "./HowYouHeardSelect";
export { default as LanguageSelect } from "./LanguageSelect";
export {
  default as LanguageProficienciesFieldArray,
} from "./LanguageProficienciesFieldArray";
export {
  default as LanguageProficiencySelect,
} from "./LanguageProficiencySelect";
export {
  default as PasswordInputsWithReveal,
} from "./PasswordInputsWithReveal";
export { default as PositionTypeSelectField } from "./PositionTypeSelectField";
export {
  default as PracticeAreaRequirementsFieldArray,
} from "./PracticeAreaRequirementsFieldArray";
export {
  default as PrimaryPracticeAreaSelectField,
} from "./PrimaryPracticeAreaSelectField";
export {
  default as ProjectLengthSelectField,
} from "./ProjectLengthSelectField";
export {
  default as YearsExperienceRadioField,
} from "./YearsExperienceRadioField";
