// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Container from "common/components/styled/Container";
import Main from "./Main";
import Details from "./Details";
import Date from "./Date";
import CompanyName from "./CompanyName";
import LocationContainer from "./LocationContainer";
import PracticeAreasItem from "./PracticeAreasItem";
import FirmType from "./FirmType";
import Extra from "./Extra";
import Footing from "./Footing";
import FootingLink from "./FootingLink";

const ExperienceBlock: ComponentType<Props> = styled.div`
  margin: 0 0 50px 0;
`;

const Experience = (props: {}) => <ExperienceBlock {...props} />;

Experience.Container = Container;
Experience.Main = Main;
Experience.Details = Details;
Experience.Date = Date;
Experience.CompanyName = CompanyName;
Experience.LocationContainer = LocationContainer;
Experience.PracticeAreasItem = PracticeAreasItem;
Experience.FirmType = FirmType;
Experience.Extra = Extra;
Experience.Footing = Footing;
Experience.FootingLink = FootingLink;

export default Experience;
