import styled from "styled-components";

const DropdownTrigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100px;
  height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
  margin: 0;
  padding: 0;
  border-bottom: 2px solid transparent;
  background: none;
  outline: none;
  cursor: pointer;
`;

export default DropdownTrigger;
