import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { validateRequiredFields } from "common/utils/forms/validators";
import { CheckboxField, HiddenField } from "common/components/forms/inputs";
import FormButton from "common/components/FormButton";
import { TrackerDatePicker } from "contractor/components/time-tracker";

export const formName = "timeEntryForm";
export const requiredFields = ["date", "job_worker", "agree"];

const agreeLabel = `I hereby certify that, to the best of my knowledge, I have completed all work and
     deliverables related to the job above.`;

const validate = values => {
  const errors = validateRequiredFields(requiredFields, values);
  if (!values.agree) errors.agree = "You must agree.";
  return errors;
};

class FixedRateOccurrenceForm extends Component {
  render() {
    const {
      isDisabled,
      handleSubmit,
      pristine,
      submitting,
      valid,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <TrackerDatePicker disabled={isDisabled} />
        <CheckboxField disabled={isDisabled} name="agree" label={agreeLabel} />
        <HiddenField name="job_worker" />
        <div className="text-right">
          <FormButton
            className="text-right"
            action="save"
            submitting={submitting}
            disabled={isDisabled || pristine || submitting || !valid}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  validate,
})(FixedRateOccurrenceForm);
