import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Loading from "common/components/Loading";
import ToolTip from "common/components/ToolTip";
import Job from "types/Job";

const OutstandingItemTableRow = ({ isGenerating, job, onGenerateClick }) => {
  const jobExpenses = Object.values(job.expenses || {});
  const expenseTotal = jobExpenses.reduce(
    (previous, current) => previous + parseFloat(current.amount),
    0,
  );

  const jobTimeEntries = Object.values(job.timeEntries || {});
  const timeEntryTotalMinutes = jobTimeEntries.reduce(
    (previous, current) => previous + parseFloat(current.minutes),
    0,
  );
  const timeEntryTotalAmount =
    (timeEntryTotalMinutes / 60) * parseFloat(job.rate);

  const jobFixedRates = Object.values(job.fixedRates || {});
  const fixedRateTotal = jobFixedRates.length * parseFloat(job.rate);
  const estimatedTotal = timeEntryTotalAmount + expenseTotal + fixedRateTotal;

  const paymentMethod = job.payment_method
    ? job.payment_method === Job.PAYMENT_METHODS.WIRE
      ? "Wire"
      : "Stripe"
    : "Unknown";

  const invoiceTaskIsRunning = job.latest_invoice_task_status === false;

  return (
    <tr>
      <td>{job.title}</td>
      <td>{paymentMethod}</td>
      <td className="decimal">${job.rate}</td>
      <td className="number">{jobTimeEntries.length}</td>
      <td className="number">{timeEntryTotalMinutes}</td>
      <td className="decimal">${timeEntryTotalAmount.toFixed(2)}</td>
      <td className="number">{jobExpenses.length}</td>
      <td className="decimal">${expenseTotal.toFixed(2)}</td>
      <td className="number">{jobFixedRates.length}</td>
      <td className="decimal">${fixedRateTotal.toFixed(2)}</td>
      <td className="decimal">${estimatedTotal.toFixed(2)}</td>
      <td>
        {isGenerating ? (
          <Loading />
        ) : invoiceTaskIsRunning ? (
          <span>
            <i className="far fa-cog fa-spin" />
            &nbsp;Generating (refresh to update)...
          </span>
        ) : (
          <a href="" onClick={e => onGenerateClick(e, job.uuid)}>
            Generate Invoice
          </a>
        )}
      </td>
    </tr>
  );
};

export default class OutstandingItems extends Component {
  handleCreateInvoiceClick(e, jobId) {
    e.preventDefault();
    this.props.onCreateInvoice(jobId);
  }

  render() {
    const estimateHint =
      "This subtotal is just an estimate. It likely has rounding errors and does not take " +
      "into account many specific calculations that happen after the invoice is generated. Also it does not " +
      "include any taxes or fees.";

    const paymentHint =
      'If method is "Stripe": if an invoice is generated for this job and ' +
      "and approved, pending stripe charges will be automatically created for review.";

    const { isGenerating, loading, jobs } = this.props;

    return (
      <Table condensed striped>
        <thead>
          <tr>
            <th>Job</th>
            <th>
              <ToolTip content={paymentHint}>
                <span>
                  Payment Method <i className="far fa-question-circle" />
                </span>
              </ToolTip>
            </th>
            <th className="decimal">Job Rate</th>
            <th className="number"># Time entries</th>
            <th className="number">Time entries minutes</th>
            <th className="decimal">Time entry total</th>
            <th className="number"># Expenses</th>
            <th className="decimal">Expense Total</th>
            <th className="number"># FROs</th>
            <th className="decimal">FRO Total</th>
            <th className="decimal">
              <ToolTip content={estimateHint}>
                <span>
                  Estimated Subtotal <i className="far fa-question-circle" />{" "}
                </span>
              </ToolTip>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!loading &&
            Object.values(jobs).map(job => (
              <OutstandingItemTableRow
                key={job.uuid}
                job={job}
                isGenerating={isGenerating}
                onGenerateClick={this.handleCreateInvoiceClick.bind(this)}
              />
            ))}
        </tbody>
      </Table>
    );
  }
}
