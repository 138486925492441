import { handleActions } from "redux-actions";

import { listToObject } from "common/utils";

export const createInitialState = initialData => ({
  data: initialData || {},
  error: null,
  isLoading: false,
  lastUpdated: null,
  ready: false,
  store: {},
});

export default (type, initialData, handlers = {}) => {
  const initialState = createInitialState(initialData);

  return handleActions(
    {
      [`REQUEST/${type}`]: state => ({
        ...state,
        isLoading: true,
      }),
      // TODO: Normalizr
      [`RECEIVE/${type}`]: (state, action) => {
        const hasResults = action.payload.hasOwnProperty("results");
        let newStore = {};
        if (hasResults) {
          newStore = listToObject(action.payload.results, "uuid");
        } else {
          newStore = { [action.payload.uuid]: action.payload };
        }
        return {
          ...state,
          isLoading: false,
          lastUpdated: action.meta.receivedAt,
          data: action.error ? state.data : action.payload,
          error: action.error ? action.payload : null,
          store: {
            ...state.store,
            ...newStore,
          },
        };
      },
      ...handlers,
    },
    initialState,
  );
};
