import React from "react";
import { Table } from "react-bootstrap";
import moment from "moment";

import Loading from "common/components/Loading";
import { formatDuration, formatUSD } from "common/utils/helpers";

import { createResourceListQuery, createResourceSelector } from "queries";
import { compose, connect, connectRequest, types } from "queries/utils";

const InvoiceEntriesList = ({ entries, paymentDetail }) => (
  <Table responsive striped>
    <thead>
      <tr>
        <th>Contractor</th>
        <th style={{ minWidth: "100px" }}>Date</th>
        <th>Description</th>
        {paymentDetail.payment_type === 0 && (
          <th className="text-center">Time</th>
        )}
        <th className="text-center">Rate</th>
        <th className="text-center">Overtime</th>
        <th className="text-right">Subtotal</th>
      </tr>
    </thead>
    <tbody>
      {entries.map((ie, i) => (
        <tr key={`invoice-entry-${i}`}>
          <td>{ie.contractor_name}</td>
          <td>{moment(ie.entry_created).format("MMM D, YYYY")}</td>
          <td id="mobile-width">{ie.description}</td>
          {paymentDetail.payment_type === 0 && (
            <td className="text-center">{formatDuration(ie.units)}</td>
          )}
          <td className="text-center">
            {ie.is_overtime_rate
              ? formatUSD(paymentDetail.overtime_rate)
              : formatUSD(paymentDetail.rate)}
            /hr
          </td>
          <td className="text-center">
            {ie.is_overtime_rate ? (
              <i className="success-check far fa-check-circle" />
            ) : (
              <i className="danger-times far fa-times-circle" />
            )}
          </td>
          <td className="text-right">{formatUSD(ie.total)}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const InvoiceEntries = props => {
  const {
    paymentDetail,
    [types.INVOICE_ENTRIES]: {
      data,
      query: { count, data: queryData },
      isFinished,
    },
  } = props;

  return (
    <div className="main-info-container">
      <h2 className="main-info-title">Entries</h2>
      {!isFinished ? (
        <Loading />
      ) : count ? (
        <InvoiceEntriesList
          entries={queryData.map(id => data[id])}
          paymentDetail={paymentDetail}
        />
      ) : (
        <p className="no-content-message">
          There are no entries for this invoice
        </p>
      )}
    </div>
  );
};

const mapPropsToConfig = props =>
  createResourceListQuery(types.INVOICE_ENTRIES, {
    url: `/api/dev/invoice_entries/?invoice=${props.invoiceId}&limit=999`,
  });

const mapStateToProps = createResourceSelector(
  types.INVOICE_ENTRIES,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(InvoiceEntries);
