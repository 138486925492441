import actions from "hiringagent-dashboard/actions/recommendations";

export default {
  [actions.MARK_RECOMMENDATION_INVITED]: (state, action) => ({
    ...state,
    [action.payload.recommendationId]: {
      ...state[action.payload.recommendationId],
      invited: true,
    },
  }),
  [actions.MARK_RECOMMENDATION_DISMISSED]: (state, action) => ({
    ...state,
    [action.payload.recommendationId]: {
      ...state[action.payload.recommendationId],
      dismissed: true,
    },
  }),
};
