import React, { Component } from "react";
import { Grid, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { connectRequest } from "redux-query-react";
import { createStructuredSelector } from "reselect";

import { TopNavHeader } from "common/components";
import { ContentWrapper, Footer } from "common/components/layout";
import NullOr from "common/components/NullOr";
import { connect, compose } from "resources/components/utils";
import {
  createResourceDetailQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";

export class Base extends Component {
  static propTypes = {
    empty: PropTypes.bool,
    fluid: PropTypes.bool,
    showSearchLink: PropTypes.bool,
    showSignupButton: PropTypes.bool,
  };

  static defaultProps = {
    empty: false,
    fluid: false,
    showSearchLink: false,
    showSignupButton: true,
  };

  render() {
    const {
      [types.CURRENT_USER_TYPE]: { data: userData, isFinished: userFinished },
      empty,
      fluid,
      showSearchLink,
      showSignupButton,
    } = this.props;
    const userType = userFinished ? Object.values(userData)[0].user_type : null;
    const isAdmin = userType === "admin";

    return (
      <div style={{ backgroundColor: "white" }}>
        <TopNavHeader
          empty={empty}
          userType={userType}
          withSearchLink={showSearchLink}
          withSignUpButton={showSignupButton}
        />
        <ContentWrapper>
          <Grid fluid={fluid}>
            <NullOr isNull={!isAdmin}>
              <Alert bsStyle="danger">Logged in as Admin!</Alert>
            </NullOr>
            {this.props.children}
          </Grid>
        </ContentWrapper>
        <Footer />
      </div>
    );
  }
}

const currentUserQuery = () =>
  createResourceDetailQuery(types.CURRENT_USER_TYPE, {
    url: "/api/v2/current_user_type/",
    force: true,
  });

const mapPropsToConfig = props => [currentUserQuery(props)];

const currentUserConfig = createResourceSelectorConfig(
  types.CURRENT_USER_TYPE,
  currentUserQuery,
);

const mapStateToProps = createStructuredSelector({
  ...currentUserConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(Base);
