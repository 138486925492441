import actions from "hiringagent-dashboard/actions/tags";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  tags: {},
  isLoading: false,
  lastUpdated: null,
};

const tags = handleActions(
  {
    [actions.REQUEST_PRACTICE_AREAS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_PRACTICE_AREAS]: (state, action) => ({
      ...state,
      tags: listToObject(action.payload.results, "uuid"),
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
  },
  initialState,
);
export default tags;
