import React from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { compose, connect, connectRequest } from "queries/utils";

import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

const JobWorkerActionButtons = props => {
  const {
    worker: { active, job: jobId },
    [types.PAYMENT_DETAILS]: {
      data,
      isFinished,
      query: { data: queryData },
    },
  } = props;

  const paymentDetail = data[queryData[0]];
  const actionButtonStyles = { display: "inline-block", margin: "0 3px" };

  return isFinished && paymentDetail ? (
    <div style={{ display: "inline-block" }}>
      {paymentDetail.isFixed &&
        paymentDetail.isFixedAutomatic && (
          <LinkContainer to={`/contractor/jobs/${jobId}/mark_event`}>
            <Button
              style={actionButtonStyles}
              bsStyle="primary"
              disabled={!active}
            >
              <span>
                <i className="far fa-list-alt" />&nbsp;Record Billable Event
              </span>
            </Button>
          </LinkContainer>
        )}
      {!paymentDetail.isFixed && (
        <LinkContainer to={`/contractor/jobs/${jobId}/time_tracker`}>
          <Button
            style={actionButtonStyles}
            bsStyle="primary"
            disabled={!active}
          >
            <span>
              <i className="far fa-clock" />&nbsp;Time Tracker
            </span>
          </Button>
        </LinkContainer>
      )}
      {paymentDetail.allow_expenses && (
        <LinkContainer to={`/contractor/jobs/${jobId}/expenses`}>
          <Button
            style={actionButtonStyles}
            bsStyle="primary"
            disabled={!active}
          >
            <span>
              <i className="fas fa-dollar-sign" />&nbsp;Track Expenses
            </span>
          </Button>
        </LinkContainer>
      )}
    </div>
  ) : null;
};

const mapPropsToConfig = props =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/?job=${props.jobId}`,
  });

const paymentDetailsSelector = createResourceSelector(
  types.PAYMENT_DETAILS,
  mapPropsToConfig,
);

export default compose(
  connect(paymentDetailsSelector),
  connectRequest(mapPropsToConfig),
)(JobWorkerActionButtons);
