import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import moment from "moment";

import Onboard from "../../blocks";
import Label from "common/blocks/Label";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import StyledText from "common/blocks/StyledText";
import DeleteModal from "../../DeleteModal";

const Tags = ({ tags }) =>
  tags.map((tag, key) => (
    <Label color="default" margin="5px 5px 0 0" key={key}>
      {tag.name}
    </Label>
  ));

const MobileRow = ({ children, label }) => (
  <Onboard.EntryDataRow>
    <Onboard.EntryDataLabel>{label}</Onboard.EntryDataLabel>
    <Col xs={6}>{children}</Col>
  </Onboard.EntryDataRow>
);

const MobileEntry = ({
  onEditClick,
  onShowDeleteModal,
  prevEmployment,
  prevEmployment: {
    company_name,
    description,
    end_date,
    firm_type,
    job_title,
    position_type,
    start_date,
    tags,
  },
  last,
}) => (
  <div>
    <br />
    {job_title && <MobileRow label="JOB TITLE">{job_title}</MobileRow>}
    {company_name && <MobileRow label="COMPANY">{company_name}</MobileRow>}
    {position_type && <MobileRow label="POSITION">{position_type}</MobileRow>}
    {firm_type && <MobileRow label="JOB TYPE">{firm_type}</MobileRow>}
    {start_date &&
      end_date && (
        <MobileRow label="DATE">
          {moment(start_date).format("MMM Do YYYY")}
          {end_date
            ? ` - ${moment(end_date).format("MMM Do YYYY")}`
            : " to Present"}
        </MobileRow>
      )}
    {description && (
      <Onboard.EntryDataRow>
        <Col xs={12}>
          <StyledText textType="black" bold>
            DESCRIPTION
          </StyledText>
          <p>{description}</p>
        </Col>
      </Onboard.EntryDataRow>
    )}
    {tags &&
      tags.length > 0 && (
        <Onboard.EntryDataRow>
          <Col xs={12}>
            <StyledText textType="black" bold>
              AREAS OF EXPERTISE
            </StyledText>
            <br />
            <Tags tags={tags} />
          </Col>
        </Onboard.EntryDataRow>
      )}
    <br />
    <Onboard.EntryDataRow>
      <Col xs={6}>
        <Onboard.TableButton
          bsStyle="primary"
          block
          onClick={() => onEditClick(prevEmployment)}
        >
          Edit
        </Onboard.TableButton>
      </Col>
      <Col xs={6}>
        <Onboard.TableButton
          bsStyle="danger"
          block
          onClick={() => onShowDeleteModal(prevEmployment)}
        >
          Delete
        </Onboard.TableButton>
      </Col>
    </Onboard.EntryDataRow>
    <br />
    {!last && <Onboard.ContainerDivider />}
  </div>
);

const DesktopRow = ({ children, label }) => (
  <Onboard.EntryDataRow>
    <Col xs={4}>
      <StyledText textType="black" bold>
        {label}
      </StyledText>
    </Col>
    <Col xs={8}>{children}</Col>
  </Onboard.EntryDataRow>
);

const DesktopEntry = ({
  onEditClick,
  onShowDeleteModal,
  prevEmployment,
  prevEmployment: {
    company_name,
    description,
    end_date,
    firm_type,
    tags,
    job_title,
    position_type,
    start_date,
  },
  last,
}) => (
  <div>
    <br />
    <Onboard.EntryDataRow>
      <Col xs={12}>
        <Onboard.TableButton
          bsStyle="danger"
          float="right"
          onClick={() => onShowDeleteModal(prevEmployment)}
        >
          Delete
        </Onboard.TableButton>
        <Onboard.TableButton
          bsStyle="primary"
          float="right"
          margin="0 5px 0 0"
          onClick={() => onEditClick(prevEmployment)}
        >
          Edit
        </Onboard.TableButton>
      </Col>
    </Onboard.EntryDataRow>
    {job_title && <DesktopRow label="JOB TITLE">{job_title}</DesktopRow>}
    {company_name && <DesktopRow label="COMPANY">{company_name}</DesktopRow>}
    {position_type && <DesktopRow label="POSITION">{position_type}</DesktopRow>}
    {firm_type && <DesktopRow label="JOB TYPE">{firm_type}</DesktopRow>}
    {start_date &&
      end_date && (
        <DesktopRow label="DATE">
          {moment(start_date).format("MMM Do YYYY")}
          {end_date
            ? ` - ${moment(end_date).format("MMM Do YYYY")}`
            : " to Present"}
        </DesktopRow>
      )}
    {description && <DesktopRow label="DESCRIPTION">{description}</DesktopRow>}
    {tags &&
      tags.length > 0 && (
        <DesktopRow label="AREAS OF EXPERTISE">
          <Tags tags={tags} />
        </DesktopRow>
      )}
    <br />
    {!last && <Onboard.ContainerDivider />}
  </div>
);

class Entry extends Component {
  constructor(props) {
    super(props);
    this.state = { deletingPrevEmployment: null, showDeleteModal: false };
  }

  handleHideDeleteModal = () =>
    this.setState({ deletingPrevEmployment: null, showDeleteModal: false });

  handleShowDeleteModal = deletingPrevEmployment =>
    this.setState({ deletingPrevEmployment, showDeleteModal: true });

  render() {
    const {
      state: { deletingPrevEmployment, showDeleteModal },
      props: { last, onDeleteClick, onEditClick, prevEmployment },
      handleHideDeleteModal,
      handleShowDeleteModal,
    } = this;
    const entryProps = {
      last,
      onEditClick,
      onShowDeleteModal: handleShowDeleteModal,
      prevEmployment,
    };

    return (
      <Fragment>
        <MobileOnly>
          <MobileEntry {...entryProps} />
        </MobileOnly>
        <DesktopOnly>
          <DesktopEntry {...entryProps} />
        </DesktopOnly>
        {deletingPrevEmployment && (
          <DeleteModal
            title="Delete Previous Employment Entry"
            onDeleteClick={onDeleteClick}
            onHide={handleHideDeleteModal}
            show={showDeleteModal}
            uuid={deletingPrevEmployment.uuid}
          >
            <p>
              {`Are you sure you want to delete your ${
                deletingPrevEmployment.job_title
              } employment at ${deletingPrevEmployment.company_name}?`}
            </p>
          </DeleteModal>
        )}
      </Fragment>
    );
  }
}

export default Entry;
