import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SubmissionError, initialize } from "redux-form";
import { addNotification as notify } from "reapop";
import { Button } from "react-bootstrap";

import {
  submitCreateExperience,
  submitDeleteExperience,
  submitUpdateExperience,
} from "contractor/actions/profile";
import { withExperience } from "contractor/connectors";

import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { validateRequiredFields } from "common/utils/forms";
import { connectFixtures } from "common/utils";

import {
  formName,
  requiredFields,
  requiredNonAttorneyFields,
} from "contractor/forms/WorkExperienceForm";
import ListExperience from "contractor/components/profile/experience/ListExperience";
import EditExperienceForm from "contractor/forms/profile/EditExperienceForm";
import DeleteExperienceModal from "contractor/components/profile/experience/DeleteExperienceModal";

class EditExperience extends Component {
  state = {
    editingExpId: null,
    deletingExpId: null,
  };

  handleAddExperienceClick = () => {
    this.props.initializeForm();
    this.setState({ editingExpId: null });
  };

  handleDeleteExperienceClick = experienceId =>
    this.setState({ deletingExpId: experienceId });

  handleDeleteExperienceCloseClick = () =>
    this.setState({ deletingExpId: null });

  handleDeleteExperienceConfirmClick = () => {
    const {
      notify,
      onReload,
      submitDeleteExperience,
      initializeForm,
    } = this.props;
    const { deletingExpId } = this.state;

    submitDeleteExperience(deletingExpId).then(() => {
      this.setState({ deletingExpId: null, editingExpId: null });
      initializeForm();
      onReload();
    });
    notify(
      createSuccessNotification({ message: "Experience has been deleted." }),
    );
  };

  handleEditExperienceClick = experienceId =>
    this.setState({ editingExpId: experienceId });

  handleSubmit = values => {
    const {
      initializeForm,
      notify,
      onReload,
      submitCreateExperience,
      submitUpdateExperience,
    } = this.props;
    const { editingExpId } = this.state;
    const formattedValues = this._getExpFormattedForSubmission(values);
    const args = editingExpId
      ? [editingExpId, formattedValues]
      : [formattedValues];
    const action = editingExpId
      ? submitUpdateExperience
      : submitCreateExperience;

    return action(...args).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      notify(
        createSuccessNotification({
          message: editingExpId
            ? "Work experience has been updated."
            : "New work experience has been saved.",
        }),
      );
      initializeForm();
      this.setState({ editingExpId: null });
      onReload();
    });
  };

  validate = values => {
    const requiredFormFields = this.props.contractor.is_attorney
      ? requiredFields
      : requiredNonAttorneyFields;
    const errors = validateRequiredFields(requiredFormFields, values);

    if (values.description && values.description.length < 200) {
      errors.description = "Description must be at least 200 characters.";
    }
    return errors;
  };

  _getFormattedTags(values) {
    const formattedTags = values.tags.map(tagId => ({ tag: tagId }));
    return { tags: formattedTags };
  }

  _getFormattedReference(values) {
    const {
      reference_phone_number,
      reference_first_name,
      reference_last_name,
      reference_email,
    } = values;
    return {
      previousemploymentreference: {
        reference_phone_number,
        reference_first_name,
        reference_last_name,
        reference_email,
      },
    };
  }

  _getExpFormattedForSubmission(values) {
    const { ...otherValues } = values;
    const formattedTags = this._getFormattedTags(values);
    const nonAttorneyReference = this.props.contractor.is_attorney
      ? null
      : this._getFormattedReference(values);
    return { ...otherValues, ...formattedTags, ...nonAttorneyReference };
  }

  _getExpFormattedForFormDisplay(experience) {
    const {
      fixtures: { firmTypes, positionTypes },
    } = this.props;
    const {
      previousemploymentreference,
      company_name,
      firm_type,
      position_type,
      ...otherValues
    } = experience;
    const tagIds = experience.tags.map(tag => tag.tag);
    const firmTypeId =
      firm_type && firmTypes.options.find(firm => firm.name === firm_type).uuid;
    const positionTypeId =
      position_type &&
      positionTypes.options.find(pos => pos.name === position_type).uuid;

    const formattedExp = {
      ...otherValues,
      ...previousemploymentreference,
      other_company: company_name,
      tags: tagIds,
      firm_type: firmTypeId,
      position_type: positionTypeId,
    };
    return formattedExp;
  }

  renderAddExperience() {
    return (
      <div>
        <h2>Add Work Experience</h2>
        <EditExperienceForm
          onSubmit={this.handleSubmit}
          validate={this.validate}
        />
      </div>
    );
  }

  renderEditExperience() {
    const {
      experience: {
        data: { results = [] },
      },
    } = this.props;
    const { editingExpId } = this.state;
    const editingExperience = results.find(
      experience => experience.uuid === editingExpId,
    );
    const expFormattedForForm = this._getExpFormattedForFormDisplay(
      editingExperience,
    );

    return (
      <div>
        <h2>
          Edit Work Experience
          <Button
            bsSize="xs"
            bsStyle="success"
            style={{ marginLeft: "1rem" }}
            onClick={this.handleAddExperienceClick}
          >
            <i className="far fa-plus" />&nbsp;Add new work experience
          </Button>
        </h2>
        <EditExperienceForm
          key={`edit-experience-${editingExpId}`}
          initialValues={expFormattedForForm}
          onSubmit={this.handleSubmit}
          validate={this.validate}
        />
      </div>
    );
  }

  render() {
    const {
      experience: {
        data: { results = [] },
        isLoading,
        lastUpdated,
      },
    } = this.props;
    const { editingExpId, deletingExpId } = this.state;

    return (
      <div>
        <h2 className="edit-section-title">Experience</h2>
        <ListExperience
          onDeleteClick={this.handleDeleteExperienceClick}
          onEditClick={this.handleEditExperienceClick}
        />

        {editingExpId && !isLoading && lastUpdated
          ? this.renderEditExperience()
          : this.renderAddExperience()}

        <DeleteExperienceModal
          show={Boolean(deletingExpId)}
          onSubmit={this.handleDeleteExperienceConfirmClick}
          onClose={this.handleDeleteExperienceCloseClick}
          experience={results.find(exp => exp.uuid === deletingExpId)}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      initializeForm: () => initialize(formName),
      notify,
      submitCreateExperience,
      submitDeleteExperience,
      submitUpdateExperience,
    },
  ),
  withExperience(),
  connectFixtures,
)(EditExperience);
