import moment from "moment";

export const formatDuration = (totalMins, abbreviated = false) => {
  const numberEnding = number => (number > 1 && !abbreviated ? "s" : "");
  const hourWord = abbreviated ? "hr" : "hour";
  const minuteWord = abbreviated ? "min" : "minute";

  if (totalMins > 0) {
    const hours = Math.floor(totalMins / 60);
    const minutes = Math.floor(totalMins % 60);
    const h = hours > 0 ? `${hours} ${hourWord}${numberEnding(hours)}` : "";
    const m =
      minutes > 0 ? `${minutes} ${minuteWord}${numberEnding(minutes)}` : "";

    if (h && m) return `${h}${abbreviated ? "" : ","} ${m}`;

    return `${h}${m}`;
  }

  return `0 ${minuteWord}`;
};

export const formatUSD = number =>
  parseFloat(number).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

export const formatStripeAmountUSD = amount => formatUSD(amount / 100);

export const formatDateTime = (dateObj, withTime = false) =>
  withTime
    ? moment(dateObj).format("MMMM D, YYYY, h:mm a")
    : moment(dateObj).format("MMMM D, YYYY");

// This uses base-ten in the calculation (this is what most hard disks use and most computers show)
// To display the size in binary (what RAM uses and what django seems to use), change 1000 to 1024
// Ex for binary: humanFileSize(3487, true)
export const humanFileSize = (size, binary = false) => {
  const numeral = binary ? 1024 : 1000;

  if (size === 0) {
    return "0 bytes";
  }

  const i = Math.floor(Math.log(size) / Math.log(numeral));
  return `${(size / Math.pow(numeral, i)).toFixed(1) * 1} ${
    ["bytes", "kB", "MB", "GB", "TB"][i]
  }`;
};

const coerceToMoment = dateTime =>
  typeof dateTime === "number" ? moment.unix(dateTime) : moment(dateTime);

export const lessThanOneMonthAgo = dateTime => {
  if (dateTime) {
    const dateAgo = coerceToMoment(dateTime);
    return dateAgo.diff(moment(), "months") < 1;
  }
  return false;
};

export const getResourcesAreReady = (requiredResources, props) => {
  const allFinished = requiredResources.every(r => props[r].isFinished);
  const nonePending = !requiredResources.some(r => props[r].isPending);
  return allFinished && nonePending;
};

const mockLocalStorage = {
  getItem: key => {
    // eslint-disable-next-line no-console
    console.log(
      `Could not get item "${key}" from local storage; local storage is not available.`,
    );
    return null;
  },
  setItem: (key, value) => {
    // eslint-disable-next-line no-console
    console.log(
      `Could not set item "${key}" with value "${value}" in local storage; local storage is not available.`,
    );
  },
};
export const getLocalStorage = () => {
  try {
    const storage = window.localStorage;
    return storage.getItem ? storage : mockLocalStorage;
  } catch (e) {
    return mockLocalStorage;
  }
};

export const localStorage = getLocalStorage();
