import React, { Component } from "react";

import Side from "./blocks";
import LinkList from "./LinkList";
import MobileSidebar from "./MobileSidebar";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";

export default class Sidebar extends Component {
  render() {
    const { currentStep, steps } = this.props;

    return (
      <Side>
        <DesktopOnly>
          <LinkList currentStep={currentStep} steps={steps} />
        </DesktopOnly>
        <MobileOnly>
          <MobileSidebar currentStep={currentStep} steps={steps} />
        </MobileOnly>
      </Side>
    );
  }
}
