import styled from "styled-components";

const InfoCircle = styled.i.attrs({
  className: "far fa-info-circle",
})`
  margin-left: 5px;
  cursor: pointer;
`;

export default InfoCircle;
