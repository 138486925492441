import React from "react";
import { Link } from "react-router-dom";

import Side from "./blocks";
import { shouldDisableStep, shouldDisplayStep } from "../utils";
import { getIcon } from "./utils";
import { basePath } from "contractor/components/Onboarding/values";

const LinkItem = ({ currentStep, deadLink, linkStepName, steps }) => {
  const isDisabled = shouldDisableStep(currentStep, linkStepName, steps);
  const isDisplayed = shouldDisplayStep(linkStepName, steps);
  const label = linkStepName
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return isDisplayed ? (
    deadLink || isDisabled ? (
      <Side.ChildItem isDisabled={isDisabled}>
        {getIcon(linkStepName, steps, isDisabled)} {label}
      </Side.ChildItem>
    ) : (
      <Side.ChildItem isDisabled={isDisabled}>
        <Link to={`${basePath}/${linkStepName.replace(/_/g, "-")}`}>
          {getIcon(linkStepName, steps, isDisabled)} {label}
        </Link>
      </Side.ChildItem>
    )
  ) : null;
};

export default LinkItem;
