import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { metaGenerator } from "admin/utils";
import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_RELATIONSHIP_CHOICES: null,
  RECEIVE_RELATIONSHIP_CHOICES: null,
  REQUEST_DECLINED_REASON_CHOICES: null,
  RECEIVE_DECLINED_REASON_CHOICES: null,
  REQUEST_REVIEW_REQUEST: null,
  RECEIVE_REVIEW_REQUEST: null,
  REQUEST_SUBMIT_REVIEW: null,
  RECEIVE_SUBMIT_REVIEW: null,
  REQUEST_SUBMIT_DECLINE_REVIEW_REQUEST: null,
  RECEIVE_SUBMIT_DECLINE_REVIEW_REQUEST: null,
});
export default actions;

export const logSurveyNotAvailable = (reviewRequestId, authTokenId) => (
  dispatch,
  getState,
) =>
  oldApi.post(
    `/api/v2/public/review_requests/${reviewRequestId}/log_not_available/`,
    getState().anonymous.reviews.reviewRequest,
    {
      "X-TERNAL-LINK-TOKEN": authTokenId,
    },
  );

export const requestRelationshipChoices = authTokenId => dispatch => {
  dispatch(createAction(actions.REQUEST_RELATIONSHIP_CHOICES)());
  return oldApi
    .get("/api/v2/review_relationship_choices/", {
      "X-TERNAL-LINK-TOKEN": authTokenId,
    })
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error retrieving Relationship Choices.");
      dispatch(
        createAction(
          actions.RECEIVE_RELATIONSHIP_CHOICES,
          p => p,
          metaGenerator,
        )(payload),
      );
    });
};

export const requestDeclinedReasonChoices = authTokenId => dispatch => {
  dispatch(createAction(actions.REQUEST_DECLINED_REASON_CHOICES)());
  return oldApi
    .get("/api/v2/review_request_declined_reason_choices/", {
      "X-TERNAL-LINK-TOKEN": authTokenId,
    })
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error retrieving Declined Reason Choices.");
      dispatch(
        createAction(
          actions.RECEIVE_DECLINED_REASON_CHOICES,
          p => p,
          metaGenerator,
        )(payload),
      );
    });
};

export const requestReviewRequest = (
  reviewRequestId,
  authTokenId,
) => dispatch => {
  dispatch(createAction(actions.REQUEST_REVIEW_REQUEST)());
  return oldApi
    .get(`/api/v2/public/review_requests/${reviewRequestId}/`, {
      "X-TERNAL-LINK-TOKEN": authTokenId,
    })
    .then(({ json, response }) => {
      const payload = response.ok
        ? { reviewRequest: json }
        : new Error("Error retrieving Review Request.");
      dispatch(
        createAction(actions.RECEIVE_REVIEW_REQUEST, p => p, metaGenerator)(
          payload,
        ),
      );
    });
};

export const submitReview = (data, authTokenId) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_REVIEW)());
  return oldApi
    .post("/api/v2/public/reviews/", data, {
      "X-TERNAL-LINK-TOKEN": authTokenId,
    })
    .then(({ json, response }) => {
      const payload = response.ok ? json : new Error("Error creating review.");
      dispatch(
        createAction(actions.RECEIVE_SUBMIT_REVIEW, p => p, metaGenerator)(
          payload,
        ),
      );
      return { json, response };
    });
};

export const submitDeclineReviewRequest = (
  reviewRequestId,
  data,
  authTokenId,
) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_DECLINE_REVIEW_REQUEST)());
  return oldApi
    .post(`/api/v2/public/review_requests/${reviewRequestId}/decline/`, data, {
      "X-TERNAL-LINK-TOKEN": authTokenId,
    })
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error declining review request.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_DECLINE_REVIEW_REQUEST,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};
