import React from "react";
import { Button } from "react-bootstrap";
import { querySelectors } from "redux-query";
import { addNotification as notify } from "reapop";

import { receiveUpdatedContractor } from "admin/actions/nonAttorneyVerification";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { connect, mutateAsync } from "queries/utils";
import { getDefaultOptions } from "queries";
import { createApiUrl } from "utils";

const RejectButton = ({ nonAttorney, onClick }) => (
  <Button bsStyle="danger" disabled={nonAttorney.rejected} onClick={onClick}>
    Reject
  </Button>
);

export const updateVerifiedQuery = ({ nonAttorney: { uuid } }) => ({
  url: createApiUrl(`/api/v2/non_attorneys/${uuid}/`),
  body: {
    is_verified: false,
    rejected: true,
  },
  options: {
    ...getDefaultOptions(),
    method: "PATCH",
  },
  update: {},
});
const mapDispatchToProps = (dispatch, props) => ({
  onClick: () =>
    dispatch(mutateAsync(updateVerifiedQuery(props))).then(result => {
      let notification;
      if (result.status === 200) {
        notification = createSuccessNotification({
          title: "Contractor has been rejected",
        });
      } else {
        notification = createErrorNotification({
          title: "Error verifying contractor",
          message: "Try again or refresh the page.",
        });
      }
      dispatch(notify(notification));
      dispatch(receiveUpdatedContractor(result.body));
    }),
});

export default connect(
  (state, props) => ({
    isPending: querySelectors.isPending(
      state.queries,
      updateVerifiedQuery(props),
    ),
  }),
  mapDispatchToProps,
)(RejectButton);
