// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {};

const ContainerContent: ComponentType<Props> = styled.div`
  div {
    margin: 5px 0;
    div:first-child {
      font-weight: 700;
      text-transform: uppercase;
    }
    div:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export default ContainerContent;
