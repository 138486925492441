// @flow
import * as React from "react";
import { Alert as BootstrapAlert, Button, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

type Props = {
  children: React.Node,
  label: string,
  link: string,
  title: string,
  onHideClick: Function,
  pagination: ?React.Node,
};

class Alert extends React.Component<Props> {
  render() {
    const {
      children,
      label,
      link,
      onHideClick,
      pagination,
      title,
    } = this.props;

    return (
      <BootstrapAlert onDismiss={onHideClick}>
        <h4>{title}</h4>
        {children}
        <Row style={{ marginTop: "1rem" }} className="text-center">
          <Col xs={12} md={8}>
            <LinkContainer className="pull-left" to={link}>
              <Button bsStyle="info">{label}</Button>
            </LinkContainer>
          </Col>
          <Col xs={12} md={4}>
            {pagination && (
              <div className="alerts-pagination-container">{pagination}</div>
            )}
          </Col>
        </Row>
      </BootstrapAlert>
    );
  }
}

export default Alert;
