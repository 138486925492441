// @flow strict
import React from "react";
import styled from "styled-components";

import Container from "common/blocks/Container/index.js";
import ContainerContent from "./ContainerContent";
import GreyText from "./GreyText";
import BigButtonAsLink from "./BigButtonAsLink";
import PrimaryButton from "common/blocks/PrimaryButton";
import MobileOnly from "common/blocks/MobileOnly";
import DesktopOnly from "common/blocks/DesktopOnly";
import RedText from "common/blocks/RedText";
import Header from "./Header";

type Props = {};

const EditBlock = styled.div``;

const Edit = (props: Props) => <EditBlock {...props} />;

Edit.Container = Container;
Edit.ContainerContent = ContainerContent;
Edit.GreyText = GreyText;
Edit.BigButtonAsLink = BigButtonAsLink;
Edit.PrimaryButton = PrimaryButton;
Edit.MobileOnly = MobileOnly;
Edit.DesktopOnly = DesktopOnly;
Edit.RedText = RedText;
Edit.Header = Header;

export default Edit;
