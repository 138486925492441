import React, { Component } from "react";
import { connect, compose, connectResource } from "resources/components/utils";
import { addNotification as notify } from "reapop";
import { SubmissionError, submit } from "redux-form";
import { Button } from "react-bootstrap";

import { action } from "common/actions/utils";
import { ownContractorRequest } from "resources/requests";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { validateRequiredFields } from "common/utils/forms";
import RateInfoForm, {
  formName,
  requiredFields,
} from "contractor/forms/RateInfoForm";

const updateRate = data =>
  action({
    type: "rateInfo",
    path: "rate_info",
    method: "post",
    data,
  });

const fields = [
  "dollar_per_hr_mode",
  "dollar_per_hr_low",
  "dollar_per_hr_high",
];

export class EditRate extends Component {
  validate = values => validateRequiredFields(requiredFields, values);

  handleSubmit = values => {
    const {
      notify,
      onReloadHandlers: { ownContractor: requestOwnContractor },
    } = this.props;
    return this.props.onSubmit(values).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      requestOwnContractor();
      notify(
        createSuccessNotification({
          message: "Rate information has been saved.",
        }),
      );
    });
  };

  render() {
    const {
      onSaveClick,
      contractor: { rate },
    } = this.props;

    const initialValues = rate
      ? fields.reduce(
          (res, val) => ({
            ...res,
            [val]: rate[val],
          }),
          {},
        )
      : {};

    return (
      <div>
        <h2 className="edit-section-title">Rate</h2>
        <RateInfoForm
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validate={this.validate}
        />
        <div className="text-right">
          <Button type="submit" bsStyle="primary" onClick={onSaveClick}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      onSaveClick: () => submit(formName),
      onSubmit: updateRate,
      notify,
    },
  ),
  connectResource(ownContractorRequest),
)(EditRate);
