import React from "react";

const fillStar = (i, value) => {
  if (value >= i) {
    return "fas fa-star";
  }
  if (i - value === 0.5) {
    return "far fa-star-half";
  }
  return "far fa-star";
};

export default props => (
  <div className="text-center rating-container">
    {Array.from(Array(5).keys())
      .map(i => i + 1)
      .map(i => (
        <i
          key={i}
          className={`fa-2x rating ${fillStar(i, props.value)}`}
          onClick={() => props.onChange(i)}
        />
      ))}
    {props.num_reviews >= 0 ? (
      <span className="rating_num_reviews">&nbsp;({props.num_reviews})</span>
    ) : null}
  </div>
);
