import React, { Component } from "react";
import { Badge, Panel } from "react-bootstrap";
import { createSelector } from "reselect";
import { connect } from "queries/utils";

import { FormGroupValidation } from "common/components/forms";
import { validationState } from "common/utils/forms";

const SM_SCREEN_SIZE = 768;

const isMobileScreen = () => window.innerWidth < SM_SCREEN_SIZE;

const SelectedTagsMessage = ({ selectedTagsCount, totalTagsCount }) => (
  <div className="selected-tags-count text-center text-muted">
    <small>{`(${selectedTagsCount} out of ${totalTagsCount} selected)`}</small>
  </div>
);

const TagCheckbox = props => {
  const {
    tag: { name, uuid: id },
    selectedTags,
    handleTagCheckboxClick,
  } = props;
  return (
    <div style={{ marginBottom: "5px" }}>
      <input
        type="checkbox"
        id={id}
        value={id}
        checked={selectedTags.includes(id)}
        onChange={e => handleTagCheckboxClick(e, id)}
      />{" "}
      <span style={{ verticalAlign: "text-bottom" }}>{name}</span>
    </div>
  );
};

const SelectAllCheckbox = props => (
  <div className="select-all-checkbox">
    <input
      type="checkbox"
      checked={props.isChecked}
      onChange={props.handleSelectAllClick}
    />
  </div>
);

class ExpertiseAreasForPracticeArea extends Component {
  state = {
    allChecked: false,
    isMobile: isMobileScreen(),
  };

  componentDidMount() {
    const {
      tagsForTagGroup,
      input: { value: selectedTags },
    } = this.props;

    if (this.allTagsAreSelected(tagsForTagGroup, selectedTags)) {
      this.setAllCheckedTo(true);
    }

    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      allTags: nextAllTags,
      tagsForTagGroup: nextTagsForTagGroup,
      input: { value: nextSelectedTags },
    } = nextProps;
    const {
      allTags,
      input: { value: selectedTags },
    } = this.props;

    const allTagsHasUpdated = nextAllTags.length !== allTags.length;
    const selectedTagsHasUpdated =
      nextSelectedTags.length !== selectedTags.length;

    if (allTagsHasUpdated || selectedTagsHasUpdated) {
      if (this.allTagsAreSelected(nextTagsForTagGroup, nextSelectedTags)) {
        this.setAllCheckedTo(true);
      } else if (this.state.allChecked) {
        this.setAllCheckedTo(false);
      }
    }
  }

  allTagsAreSelected = (tagIdsTagGroup, selectedTags) =>
    selectedTags.length > 0 && tagIdsTagGroup.length === selectedTags.length;

  changeAndBlurField = newValue => {
    this.props.input.onChange(newValue);
    this.props.input.onBlur(newValue);
  };

  handleSelectAllClick = () => {
    let newValue = [];
    if (!this.state.allChecked) {
      newValue = this.props.tagsForTagGroup.map(tag => tag.uuid);
    }
    this.changeAndBlurField(newValue);
  };

  handleTagCheckboxClick = (event, tagId) => {
    const {
      input: { value: selectedTags },
    } = this.props;
    const newValue = [...selectedTags];

    if (event.target.checked) {
      newValue.push(tagId);
    } else {
      newValue.splice(newValue.indexOf(tagId), 1);
    }

    this.changeAndBlurField(newValue);
  };

  // TODO: Extract; be sure to throttle or debounce resize listener
  handleWindowSizeChange = () => {
    this.setState({ isMobile: isMobileScreen() });
  };

  setAllCheckedTo(status) {
    this.setState({ allChecked: status });
  }

  render() {
    const {
      allTags,
      tagsForTagGroup,
      meta,
      input,
      input: { value: selectedTags },
    } = this.props;

    const selectedTagsCount = tagsForTagGroup.filter(tag =>
      selectedTags.includes(tag.uuid),
    ).length;
    const tagGroupName = tagsForTagGroup[0] && tagsForTagGroup[0].group;
    const isMobile = this.state.isMobile || false;
    const shouldExpand = allTags.length > 1 && selectedTagsCount === 0;

    return (
      <FormGroupValidation input={input} meta={meta}>
        <Panel
          className={`expertise-areas-panel ${
            validationState(meta) === "error" ? "hasError" : ""
          }`}
          defaultExpanded={shouldExpand}
        >
          <Panel.Heading>
            <SelectAllCheckbox
              handleSelectAllClick={this.handleSelectAllClick}
              isChecked={this.state.allChecked}
            />
            <Panel.Title toggle={isMobile}>
              <span>{tagGroupName}</span>{" "}
              {isMobile && <Badge>{selectedTagsCount}</Badge>}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible={isMobile}>
            {!isMobile && (
              <SelectedTagsMessage
                selectedTagsCount={selectedTagsCount}
                totalTagsCount={tagsForTagGroup.length}
              />
            )}
            {tagsForTagGroup.map(tag => (
              <TagCheckbox
                key={tag.uuid}
                tag={tag}
                selectedTags={selectedTags}
                handleTagCheckboxClick={this.handleTagCheckboxClick}
              />
            ))}
          </Panel.Body>
        </Panel>
      </FormGroupValidation>
    );
  }
}

const getAllTags = (_, props) => props.allTags;
const getTagGroupId = (_, props) => props.tagGroupId;

const getTagsForTagGroup = createSelector(
  [getAllTags, getTagGroupId],
  (allTags, tagGroupId) =>
    allTags.filter(area => area.tag_group_id === tagGroupId),
);

export default connect((state, props) => ({
  tagsForTagGroup: getTagsForTagGroup(state, props),
}))(ExpertiseAreasForPracticeArea);
