import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Label, Table } from "react-bootstrap";
import { formValueSelector } from "redux-form";

import {
  BarVerificationStatus,
  DuplicateLicenseWarning,
} from "admin/components/attorney-verification-manager";
import { withAttorneyVerificationManager } from "admin/connectors";
import { Pagination, USState } from "common/components";
import Loading from "common/components/Loading";
import { createApiUrl } from "utils";

class VerificationTablePagination extends Component {
  static propTypes = {
    length: PropTypes.number.isRequired,
  };

  handlePageClick = pageNumber => {
    const {
      formValues: { limit },
      onOffsetChange,
    } = this.props;
    const newOffset = pageNumber * limit - limit;
    onOffsetChange(newOffset);
  };

  render() {
    const {
      formValues: { limit, offset },
      length,
    } = this.props;
    const numPages = Math.ceil(length / limit);
    const currentPage = offset / limit + 1;
    return (
      numPages > 1 && (
        <div className="text-center">
          <Pagination
            currentPage={currentPage}
            totalPages={numPages}
            onChange={this.handlePageClick}
          />
        </div>
      )
    );
  }
}

export const createPaginationForForm = formName => {
  const paginationSelector = formValueSelector(formName);
  return connect(state => ({
    formValues: {
      limit: paginationSelector(state, "limit"),
      offset: paginationSelector(state, "offset"),
    },
  }))(VerificationTablePagination);
};

class BarInformationsTable extends Component {
  handleBarInfoClick = barInfoId => {
    this.props.setSelectedBarInformationId(barInfoId);
  };

  render() {
    const {
      attorneyVerificationManager: { selectedBarInformationId },
      barInformations: {
        data: { results },
        isLoading,
        ready,
      },
      type,
    } = this.props;

    return (
      <div>
        <Table className="verifications-table" hover striped>
          <thead>
            <tr>
              <th>Admin</th>
              <th>Name</th>
              <th>State</th>
              <th>ID</th>
              <th>Created</th>
              <th>Status</th>
              <th>
                <i
                  className="far fa-fw fa-envelope"
                  style={{ marginRight: "3px" }}
                />&nbsp;
                <i className="far fa-fw fa-id-card" />
              </th>
            </tr>
          </thead>
          <tbody>
            {ready &&
              results.map(barInfo => (
                <tr
                  key={`${type}-verification-row-${barInfo.uuid}`}
                  onClick={() => this.handleBarInfoClick(barInfo.uuid)}
                  className={
                    selectedBarInformationId === barInfo.uuid ? "selected" : ""
                  }
                >
                  <td>
                    <a
                      href={createApiUrl(
                        `/hae_admin/contractor/contractor/${
                          barInfo.attorney
                        }/change/`,
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-external-link-alt" />
                    </a>
                  </td>
                  <td>{barInfo.full_name}</td>
                  <td>
                    <a
                      href={barInfo.bar_cert_website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <USState stateId={barInfo.state} />
                    </a>
                  </td>
                  <td>
                    {barInfo.bar_id}{" "}
                    <DuplicateLicenseWarning barInfo={barInfo} size="small" />
                  </td>
                  <td>{moment(barInfo.created).fromNow()}</td>
                  <td>
                    <BarVerificationStatus barInfo={barInfo} />
                  </td>
                  <td>
                    <Label>{barInfo.email_verifications.length}</Label>&nbsp;
                    <Label>{barInfo.barcard_verifications.length}</Label>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {isLoading && (
          <div className="text-center">
            <Loading />
          </div>
        )}
        {ready &&
          results.length === 0 && (
            <p className="text-center">No results were found</p>
          )}
      </div>
    );
  }
}

export default withAttorneyVerificationManager()(BarInformationsTable);
