import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";

const ApplyNowButton = () => (
  <span className="cta-button-container">
    <LinkContainer to="/signup/legal_professional/">
      <Button bsSize="large" bsStyle="success">
        Apply Now
      </Button>
    </LinkContainer>
  </span>
);

export default ApplyNowButton;
