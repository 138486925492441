import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm, FieldArray } from "redux-form";
import { Alert, Button, Col, ControlLabel, Row } from "react-bootstrap";
import moment from "moment";

import {
  FirstNameInput,
  LastNameInput,
  RatingField,
  SelectField,
  TextField,
} from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";
import { RequiredFieldError } from "common/utils/forms/validators";

const FORM_NAME = "reviewForm";

const requiredFields = [
  "reviewer_first_name",
  "reviewer_last_name",
  "title",
  "relationship",
  "work_with_location",
  "work_with_again",
  "complete_requirements",
  "review",
];

const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const thisYear = new Date().getFullYear();
const years = [];
for (let i = 0; i < 50; i++) {
  years.push((thisYear - i).toString());
}

const reviewFormSelector = formValueSelector(FORM_NAME);

class DateFields extends Component {
  render() {
    const { attorneyFirstName, fields } = this.props;
    return (
      <div>
        {fields.map((field, i) => (
          <Row key={field}>
            <Col xs={12}>
              {i === 0 && (
                <ControlLabel>
                  When did you work with {attorneyFirstName}?
                </ControlLabel>
              )}
              {i === 1 && <ControlLabel>Until</ControlLabel>}
            </Col>
            <Col xs={6}>
              <SelectField name={`${field}.month`}>
                {months.map(month => (
                  <option key={month} value={month}>
                    {moment(new Date(`2000-${month}-02`)).format("MMMM")}
                  </option>
                ))}
              </SelectField>
            </Col>
            <Col xs={6}>
              <SelectField name={`${field}.year`}>
                {years.map(year => (
                  <option key={year} value={year}>
                    {moment(new Date(`${year}-01-02`)).format("YYYY")}
                  </option>
                ))}
              </SelectField>
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

class PracticeAreaRatingFields extends Component {
  render() {
    const { attorneyFirstName, fields } = this.props;
    return (
      <div>
        <p>
          For each of the following, please rate {attorneyFirstName}&apos;s
          experience and proficiency in the following practice areas (if
          applicable):
        </p>
        {fields.map((field, i) => (
          <RatingField
            key={field}
            name={`${field}.rating`}
            label={fields.get(i).name}
          />
        ))}
      </div>
    );
  }
}

const validate = values => {
  const localRequiredFields = [...requiredFields];

  if (values.relationship === 5) localRequiredFields.push("relationship_other");

  const errors = validateRequiredFields(localRequiredFields, values);

  const workWithDateHasErrors = false;
  const workWithDateErrors = [{}, {}];
  if (values.work_with_date) {
    values.work_with_date.forEach((dateField, i) => {
      ["month", "year"].forEach(dateComponent => {
        if (!dateField[dateComponent]) {
          workWithDateErrors[i][dateComponent] = <RequiredFieldError />;
        }
      });
    });
  }

  if (workWithDateHasErrors) {
    errors.work_with_date = workWithDateErrors;
  }

  if (Object.keys(errors).length > 0) {
    errors._error = "Please fix the errors.";
  }

  return errors;
};

const WorkWithAgainLabel = ({ firstName }) => (
  <span>
    If given the opportunity, how likely are you to work with {firstName} again?
  </span>
);
const CompleteRequirementsLabel = ({ firstName }) => (
  <span>
    In the context of your relationship, how well did {firstName} perform at
    their role?
  </span>
);

class ReviewForm extends Component {
  render() {
    const {
      attorneyFirstName,
      error,
      handleSubmit,
      relationship,
      relationshipChoices,
      submitting,
      submitFailed,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {submitFailed && error && (
          <Alert bsStyle="danger">
            Please fix the errors below to continue.
          </Alert>
        )}
        <FirstNameInput name="reviewer_first_name" />
        <LastNameInput name="reviewer_last_name" />
        <TextField name="title" label="What is your title?" />
        <SelectField
          name="relationship"
          label={`What is your professional relationship with ${attorneyFirstName}?`}
        >
          {relationshipChoices.map(choice => (
            <option key={choice.value} value={choice.value}>
              {choice.label}
            </option>
          ))}
        </SelectField>
        {relationship === "5" && (
          <TextField
            name="relationship_other"
            label={`Describe your professional relationship with ${attorneyFirstName}`}
          />
        )}

        <FieldArray
          name="work_with_date"
          component={DateFields}
          attorneyFirstName={attorneyFirstName}
        />

        <TextField
          name="work_with_location"
          label="Where did you work together?"
        />
        <RatingField
          name="work_with_again"
          label={<WorkWithAgainLabel firstName={attorneyFirstName} />}
        />
        <RatingField
          name="complete_requirements"
          label={<CompleteRequirementsLabel firstName={attorneyFirstName} />}
        />

        <FieldArray
          name="practicearearating_set"
          component={PracticeAreaRatingFields}
          attorneyFirstName={attorneyFirstName}
        />

        <TextField
          name="review"
          label={`Tell us about your experience working with ${attorneyFirstName}`}
          componentClass="textarea"
          rows={5}
          maxLength={500}
        />
        <TextField
          name="additional_comments"
          label="Do you have any additional comments?"
          componentClass="textarea"
          rows={3}
          maxLength={500}
        />

        {submitFailed && error && (
          <Alert bsStyle="danger">
            Please fix the errors above to continue.
          </Alert>
        )}

        <div className="text-center">
          <Button type="submit" bsStyle="info" disabled={submitting}>
            {submitting ? (
              <span>
                <i className="far fa-cog fa-spin" />
                &nbsp;Submitting...
              </span>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    );
  }
}

ReviewForm = compose(
  connect(state => ({
    relationship: reviewFormSelector(state, "relationship"),
  })),
  reduxForm({
    form: FORM_NAME,
    validate,
  }),
)(ReviewForm);
export default ReviewForm;
