// @flow strict
import React from "react";
import { connect } from "react-redux";
// $FlowFixMe 'Cannot resolve module redux-form.'
import { submit } from "redux-form";

import Workstyle from "contractor/components/WorkstyleAssessment/blocks";

type Props = {
  disabled: boolean,
  isSubmitting: boolean,
  dispatch: (() => void) => void,
  form: string,
};

export const RemoteSubmitButton = ({
  disabled,
  isSubmitting,
  dispatch,
  form,
}: Props) => (
  <Workstyle.Button
    disabled={disabled || isSubmitting}
    onClick={() => dispatch(submit(form))}
  >
    {isSubmitting ? "Submitting..." : "Finish ›"}
  </Workstyle.Button>
);

export default connect()(RemoteSubmitButton);
