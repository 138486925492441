import moment from "moment";

import { createApiUrl } from "utils";

export function fetchResourceWithDateRange(startDate, endDate, resource) {
  return fetch(
    createApiUrl(
      `/api/dev/${resource}/?format=json&limit=9999&date__gte=${startDate}&date__lte=${endDate}&invoiced=false`,
    ),
    { credentials: "include" },
  ).then(response => response.json());
}

export function formatDate(date) {
  if (!date) {
    return null;
  }
  return moment.parseZone(date).format("YYYY-MM-DD");
}

/**
 * Converts a list of objects with an `identifier` attribute to an object keyed by `identifier`.
 * @param {array} list
 * @param {string} identifier
 * @returns {{}}
 */
export function listToObject(list = null, identifier) {
  if (!list) {
    return {};
  }
  const items = {};
  Object.keys(list).forEach(key => (items[list[key][identifier]] = list[key]));
  return items;
}

function getOrCreateJobToAdd(job, paymentDetails, jobsWithEvents) {
  const jobPaymentDetails = paymentDetails[job.paymentdetails] || {};
  return (
    jobsWithEvents[job.uuid] || {
      uuid: job.uuid,
      title: job.title,
      rate: jobPaymentDetails.rate,
      payment_method: job.payment_method,
      latest_invoice_task_status: job.latest_invoice_task_status,
    }
  );
}

export function mergeEvents(event, eventName, jobsWithEvents, resources) {
  const { jobs, jobWorkers, paymentDetails } = resources;
  const workerId = event.worker || event.job_worker;
  const worker = jobWorkers[workerId] || {};
  const jobId = worker.job;
  const job = jobs[jobId];

  if (job) {
    // Get job object to add job events to
    const jobToAdd = getOrCreateJobToAdd(job, paymentDetails, jobsWithEvents);
    // Create a new event list with this event added
    const jobEvents = Object.assign({}, jobToAdd[eventName], {
      [event.uuid]: event,
    });
    // Merge the new event list to the job
    Object.assign(jobToAdd, { [eventName]: jobEvents });
    // Merge the updated job to the jobEvents list
    Object.assign(jobsWithEvents, { [job.uuid]: jobToAdd });
  }
}

export const metaGenerator = () => ({ receivedAt: Date.now() });
