// @flow strict
import React, { Component, Fragment } from "react";
import { Prompt } from "react-router-dom";

import Workstyle from "./blocks";
import { WorkstyleAssessmentForm } from "contractor/forms";
import RemoteSubmitButton from "contractor/forms/WorkstyleAssessmentForm/RemoteSubmitButton";
import { Loading, ProgressBar } from "common/components";
import { randomizeArray } from "common/utils";
import { compose, connect, mutateAsync } from "queries/utils";
import {
  startFitScoreCalculationQuery,
  postCompetencyQuestionResponsesQuery,
} from "queries/mutations/assessments";

type Props = {
  competencyQuestions: Array<{ uuid: number, content: string }>,
  candidateId: string,
  contractorId: string,
  onFinishMain: () => void,
};

type State = {
  completed: Array<{
    contractor: string,
    response: number,
    competency_question: string,
  }>,
  page: number,
  questions: Array<Array<{ uuid: number, content: string }>>,
  isSubmitting: boolean,
};

const questionsPerPage = 10;

class MainSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      completed: [],
      page: 0,
      questions: [],
      isSubmitting: false,
      submitted: false,
    };
  }

  componentDidMount() {
    this.handleCreatePages();
  }

  handleSubmit = () => {
    this.setState({ isSubmitting: true }, () => {
      const {
        candidateId,
        submitFormData,
        startFitScoreCalc,
        onFinishMain,
      } = this.props;
      const { completed } = this.state;

      submitFormData(completed).then(result => {
        if (result.status === 201) {
          startFitScoreCalc({ candidate: candidateId }).then(() =>
            this.setState({ submitted: true }, onFinishMain),
          );
        }
      });
    });
  };

  nextPage = () => {
    this.scrollToTop();
    this.setState({ page: this.state.page + 1 });
  };

  prevPage = () => {
    this.scrollToTop();
    this.setState({ page: this.state.page - 1 });
  };

  scrollToTop = () => window.scrollTo(0, 0);

  handleUpdateProgress = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      const { contractorId } = this.props;
      const { completed } = this.state;
      const completedLength = completed.length;

      for (let i = 0; i < completedLength; i++) {
        if (completed[i].competency_question === e.currentTarget.name) {
          const completedClone = completed.slice();
          completedClone[i].response = e.currentTarget.value;
          this.setState({ completed: completedClone });
          return;
        }
      }

      this.setState({
        completed: completed.concat({
          contractor: contractorId,
          response: e.currentTarget.value,
          competency_question: e.currentTarget.name,
        }),
      });
    }
  };

  handleCreatePages = () => {
    const shuffledClone = randomizeArray(this.props.competencyQuestions);
    const questions = [];

    while (shuffledClone.length > 0) {
      const page = [];
      while (shuffledClone.length > 0 && page.length < questionsPerPage) {
        page.push(shuffledClone.shift());
      }
      questions.push(page);
    }

    this.setState({ questions });
  };

  shouldDisable = () => {
    const { completed, page, questions } = this.state;
    let shouldHaveDone = 0;

    if (questions.length > 0) {
      for (let i = 0; i <= page; i++) {
        shouldHaveDone += questions[i].length;
      }
    }

    if (completed.length < shouldHaveDone) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { competencyQuestions, contractorId, route } = this.props;
    const { completed, page, questions, isSubmitting, submitted } = this.state;
    const shouldPrompt = completed.length > 0 && !submitted;

    return (
      <Fragment>
        <Prompt
          when={shouldPrompt}
          message="Your Workstyle Assessment will not be saved. Are you sure you want to leave?"
        />
        {page !== 0 && (
          <Workstyle.OuterTop>
            <Workstyle.LinkButton onClick={this.prevPage}>
              ← PREVIOUS PAGE
            </Workstyle.LinkButton>
            <Workstyle.AlternateHeader>
              WORKSTYLE ASSESSMENT
            </Workstyle.AlternateHeader>
          </Workstyle.OuterTop>
        )}
        {contractorId ? (
          questions.map(
            (group, key) =>
              page === key && (
                <Workstyle.Container key={key}>
                  <WorkstyleAssessmentForm
                    onSubmit={this.handleSubmit}
                    handleUpdateProgress={this.handleUpdateProgress}
                    page={page}
                    questions={group}
                    total={competencyQuestions.length}
                  />
                </Workstyle.Container>
              ),
          )
        ) : (
          <Workstyle.Container>
            <Loading />
          </Workstyle.Container>
        )}
        {page !== questions.length - 1 && (
          <Workstyle.Button
            disabled={this.shouldDisable()}
            onClick={this.nextPage}
          >
            Next ›
          </Workstyle.Button>
        )}
        {page === questions.length - 1 && (
          <RemoteSubmitButton
            form="workstyleAssessmentForm"
            disabled={this.shouldDisable()}
            isSubmitting={isSubmitting}
          />
        )}
        <ProgressBar
          completed={completed.length}
          total={competencyQuestions.length}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitFormData: data =>
    dispatch(mutateAsync(postCompetencyQuestionResponsesQuery(data))),
  startFitScoreCalc: candidateId =>
    dispatch(mutateAsync(startFitScoreCalculationQuery(candidateId))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(MainSection);
