import React from "react";
import { Col, DropdownButton, Label, MenuItem, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { createApiUrl } from "utils";

const rootPrintUrl = invoice =>
  `/agency/invoice/${invoice.invoice_number}/print`;
const invoicePrintUrls = {
  basic: invoice => createApiUrl(rootPrintUrl(invoice)),
  bundled: invoice => createApiUrl(`${rootPrintUrl(invoice)}/bundled`),
  detailed: invoice => createApiUrl(`${rootPrintUrl(invoice)}/detailed`),
  xls: invoice => createApiUrl(`${rootPrintUrl(invoice)}/.xls`),
};

const InvoiceHeader = ({ invoice }) => (
  <Row>
    {/* Mobile */}
    <div className="visible-xs-block visible-sm-block invoice-header">
      <Col xs={12}>
        <div style={{ marginBottom: "2rem", textAlign: "left" }}>
          <Link to="/agency/invoices/">&larr; Back to Invoices</Link>
        </div>
      </Col>
      <Col xs={12}>
        <h1 className="invoice-header">
          Invoice #{invoice ? `${invoice.invoice_number}` : ""}
        </h1>
      </Col>
      <Col xs={12}>
        <div className="status-label">
          {(invoice.paid_in_full || invoice.is_overdue) &&
          invoice.paid_in_full ? (
            <Label bsStyle="success">
              <i className="far fa-check" aria-hidden="true" /> Paid
            </Label>
          ) : (
            invoice.is_overdue && (
              <Label bsStyle="danger">
                <i className="far fa-exclamation-circle" aria-hidden="true" />{" "}
                OVERDUE
              </Label>
            )
          )}
        </div>
      </Col>
      <Col xs={12}>
        <div className="dropdown">
          <button
            className="btn btn-default btn-block dropdown-toggle invoice-dropdown"
            id="invoice-dropdown-mobile"
            data-toggle="dropdown"
            aria-haspopup="true"
          >
            View Invoice
            <span className="caret" />
          </button>
          <ul className="dropdown-menu">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.basic(invoice)}
              >
                Overview
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.bundled(invoice)}
              >
                By Contractor
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.detailed(invoice)}
              >
                By Individual Entry
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.xls(invoice)}
              >
                Individual Entry XLS
              </a>
            </li>
          </ul>
        </div>
      </Col>
    </div>
    {/* Non-mobile */}
    <div className="hidden-xs hidden-sm">
      <Col lg={12}>
        <div style={{ marginBottom: "2rem" }}>
          <Link to="/agency/invoices/">&larr; Back to Invoices</Link>
        </div>
        <div className="invoice-header">
          <div className="pull-left">
            <h1 className="invoice-title" style={{ marginRight: "2rem" }}>
              Invoice # {invoice ? `${invoice.invoice_number}` : ""}
            </h1>
            <div className="status-label">
              {(invoice.paid_in_full || invoice.is_overdue) &&
              invoice.paid_in_full ? (
                <Label bsStyle="success">
                  <i className="far fa-check" aria-hidden="true" /> Paid
                </Label>
              ) : (
                invoice.is_overdue && (
                  <Label bsStyle="danger">
                    <i
                      className="far fa-exclamation-circle"
                      aria-hidden="true"
                    />{" "}
                    OVERDUE
                  </Label>
                )
              )}
            </div>
            <span className="clearfix" />
          </div>
          <div className="pull-right">
            <DropdownButton
              className="invoice-dropdown"
              title="View Invoice"
              id="invoice-dropdown"
            >
              <MenuItem
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.basic(invoice)}
              >
                Overview
              </MenuItem>
              <MenuItem
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.bundled(invoice)}
              >
                By Contractor
              </MenuItem>
              <MenuItem
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.detailed(invoice)}
              >
                By Individual Entry
              </MenuItem>
              <MenuItem
                target="_blank"
                rel="noopener noreferrer"
                href={invoicePrintUrls.xls(invoice)}
              >
                Individual Entry XLS
              </MenuItem>
            </DropdownButton>
          </div>
          <span className="clearfix" />
        </div>
      </Col>
    </div>
  </Row>
);

export default InvoiceHeader;
