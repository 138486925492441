// @flow strict
import { BROADCAST_MESSAGE_KEY } from "./config";
import { type ActionType } from "redux-actions";
import { localStorage } from "common/utils/helpers";

export default function sendMessage<T>(action: ActionType<T>) {
  // localstorage events will only fire if the key/value pair do not already
  // exist, so make every message unique with `uid`
  const message = {
    action,
    uid: new Date().getTime() + Math.random(),
  };
  localStorage.setItem(BROADCAST_MESSAGE_KEY, JSON.stringify(message));
  localStorage.removeItem(BROADCAST_MESSAGE_KEY);
}
