import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import api, { oldApi } from "common/api";

import { metaGenerator } from "common/actions";

const actions = keyMirror({
  REQUEST_HIRING_AGENT: null,
  RECEIVE_HIRING_AGENT: null,
  REQUEST_HIRING_AGENTS: null,
  RECEIVE_HIRING_AGENTS: null,
  REQUEST_SUBMIT_AGENCY_ADMIN_ACTION: null,
  RECEIVE_SUBMIT_AGENCY_ADMIN_ACTION: null,
  REQUEST_SUBMIT_HIRING_AGENT_FORM: null,
  RECEIVE_SUBMIT_HIRING_AGENT_FORM: null,
  REQUEST_SUBMIT_EDIT_PROFILE_FORM: null,
  RECEIVE_SUBMIT_EDIT_PROFILE_FORM: null,
  TOGGLE_ADD_AGENT_MODAL: null,
  SET_FOCUSED_AGENT: null,
});
export default actions;

export const requestHiringAgents = () =>
  api.get("/api/v2/hiring_agencies/agents/", [
    actions.REQUEST_HIRING_AGENTS,
    actions.RECEIVE_HIRING_AGENTS,
  ]);

export const submitAgencyAdminAction = (hiringAgentId, action) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_AGENCY_ADMIN_ACTION)());
  return oldApi
    .post(`/api/dev/hiring_agents/${hiringAgentId}/${action}/`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error(`Error submitting hiring agent ${action} request`);
      if (!response.ok) {
        return Promise.reject(json);
      }
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_AGENCY_ADMIN_ACTION,
          p => p,
          metaGenerator,
        )(payload),
      );
      dispatch(requestHiringAgents());
      dispatch(toggleModal(null));
      return json;
    });
};

export const submitHiringAgentForm = data => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_HIRING_AGENT_FORM)());
  return oldApi
    .post("/api/dev/hiring_agents/", data)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error creating new hiring agent");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_HIRING_AGENT_FORM,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const submitEditProfileForm = (hiringAgentId, data) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_EDIT_PROFILE_FORM)());
  if (Object.keys(data).length < 1) {
    return Promise.reject({ _error: "You have not made any changes" });
  }
  return oldApi
    .patch(`/api/dev/hiring_agents/${hiringAgentId}/`, data)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error editing hiring agent profile");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_EDIT_PROFILE_FORM,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const setFocusedAgent = createAction(actions.SET_FOCUSED_AGENT);
export const toggleModal = createAction(actions.TOGGLE_ADD_AGENT_MODAL);
