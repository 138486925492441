import { createAction } from "redux-actions";
import { listToObject, metaGenerator } from "admin/utils";
import keyMirror from "keymirror";

import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_HIRING_AGENCIES: null,
  RECEIVE_HIRING_AGENCIES: null,
});
export default actions;

export const requestHiringAgenciesV2 = () => dispatch => {
  dispatch(createAction(actions.REQUEST_HIRING_AGENCIES)());
  return oldApi
    .get("/api/v2/hiring_agencies/?limit=9999")
    .then(({ json, response }) => {
      const payload = response.ok
        ? { hiringAgencies: listToObject(json.results, "uuid") }
        : new Error("Error retrieving hiring agencies.");
      return dispatch(
        createAction(actions.RECEIVE_HIRING_AGENCIES, p => p, metaGenerator)(
          payload,
        ),
      );
    });
};
