// @flow strict
import { css } from "styled-components";

export const sizes = {
  XS: 576,
  SM: 768,
  MD: 992,
  LG: 1200,
  XL: 1440,
};

// To be made available only through ThemeProvider component
const themeStyles = {
  colors: {
    primary_turquoise: (opacity: string = "1") =>
      `rgba(0, 198, 215, ${opacity})`,
    pale_turquoise: (opacity: string = "1") =>
      `rgba(174, 237, 242, ${opacity})`,
    paler_turquoise: (opacity: string = "1") =>
      `rgba(212, 246, 248, ${opacity})`,
    primary_orange: (opacity: string = "1") => `rgba(233, 131, 0, ${opacity})`,
    light_orange: (opacity: string = "1") => `rgba(251, 165, 52, ${opacity})`,
    primary_black: (opacity: string = "1") => `rgba(0, 0, 0, ${opacity})`,
    primary_black60: (opacity: string = "1") =>
      `rgba(102, 102, 102, ${opacity})`,
    primary_black40: (opacity: string = "1") =>
      `rgba(153, 153, 153, ${opacity})`,
    primary_black20: (opacity: string = "1") =>
      `rgba(204, 204, 204, ${opacity})`,
    primary_black10: (opacity: string = "1") =>
      `rgba(229, 229, 229, ${opacity})`,
    secondary_red: (opacity: string = "1") => `rgba(180, 30, 38, ${opacity})`,
    secondary_blue: (opacity: string = "1") => `rgba(0, 115, 207, ${opacity})`,
    secondary_navy: (opacity: string = "1") => `rgba(15, 48, 99, ${opacity})`,
    warning: (opacity: string = "1") => `rgba(254, 203, 0, ${opacity})`,
    danger: (opacity: string = "1") => `rgba(196, 38, 46, ${opacity})`,
    success: (opacity: string = "1") => `rgba(92, 184, 92, ${opacity})`,
    background_gray: (opacity: string = "1") =>
      `rgba(204, 204, 204, ${opacity})`,
    background_lightgray: (opacity: string = "1") =>
      `rgba(239, 239, 239, ${opacity})`,
    white: (opacity: string = "1") => `rgba(255, 255, 255, ${opacity})`,
    link: (opacity: string = "1") => `rgba(8, 152, 196, ${opacity})`,
    link_hover: (opacity: string = "1") => `rgba(8, 85, 111, ${opacity})`,
    navlink: (opacity: string = "1") => `rgba(0, 115, 207, ${opacity})`,
    navlink_hover: (opacity: string = "1") => `rgba(0, 95, 187, ${opacity})`,
    navlink_focus: (opacity: string = "1") => `rgba(0, 75, 167, ${opacity})`,
    container_bg: (opacity: string = "1") => `rgba(242, 242, 242, ${opacity})`,
    container_bg_darker: (opacity: string = "1") =>
      `rgba(217, 217, 217, ${opacity})`,
    nav_btn_default: (opacity: string = "1") => `rgba(0, 123, 255, ${opacity})`,
    nav_btn_hover: (opacity: string = "1") => `rgba(0, 105, 217, ${opacity})`,
    nav_btn_active: (opacity: string = "1") => `rgba(0, 98, 204, ${opacity})`,
    nav_dropdown: (opacity: string = "1") => `rgba(222, 248, 250, ${opacity})`,
    shadow: (opacity: string = "0.2") => `rgba(0, 0, 0, ${opacity})`,
    transparent: () => "transparent",
  },
  media: {
    landscape_mobile_lg: (...args: Array<string>) => css`
      @media (max-height: 425px) {
        ${css(...args)};
      }
    `,
    landscape_mobile: (...args: Array<string>) => css`
      @media (max-height: 991px) {
        ${css(...args)};
      }
    `,
    mobile_xs: (...args: Array<string>) => css`
      @media (max-width: 320px) {
        ${css(...args)};
      }
    `,
    mobile_sm: (...args: Array<string>) => css`
      @media (min-width: 321px) {
        ${css(...args)};
      }
    `,
    mobile_md: (...args: Array<string>) => css`
      @media (min-width: 375px) {
        ${css(...args)};
      }
    `,
    mobile_lg: (...args: Array<string>) => css`
      @media (min-width: 425px) {
        ${css(...args)};
      }
    `,
    mobile: (...args: Array<string>) => css`
      @media (max-width: 991px) {
        ${css(...args)};
      }
    `,
    tablet: (...args: Array<string>) => css`
      @media (min-width: 576px and max-width: 991px) {
        ${css(...args)};
      }
    `,
    not_sm: (...args: Array<string>) => css`
      @media (max-width: 767px) {
        ${css(...args)};
      }
    `,
    sm: (...args: Array<string>) => css`
      @media (min-width: 768px) {
        ${css(...args)};
      }
    `,
    desktop: (...args: Array<string>) => css`
      @media (min-width: 992px) {
        ${css(...args)};
      }
    `,
    md: (...args: Array<string>) => css`
      @media (min-width: 992px) {
        ${css(...args)};
      }
    `,
    lg: (...args: Array<string>) => css`
      @media (min-width: 1200px) {
        ${css(...args)};
      }
    `,
    xl: (...args: Array<string>) => css`
      @media (min-width: 1440px) {
        ${css(...args)};
      }
    `,
  },
  sizes: {
    top_nav_header_desktop_height: "68px",
    top_nav_header_mobile_height: "50px",
  },
};

export default themeStyles;
