import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Col, Row } from "react-bootstrap";

import FormButton from "common/components/FormButton";
import { CheckboxField, TextField } from "common/components/forms/inputs";

export const formName = "referencesForm";
export const requiredFields = [
  "reviewer_first_name",
  "reviewer_last_name",
  "reviewer_email",
  "agree_real_reference",
];

const formInstructionText = `Please add professional references who have direct experience with and can vouch for your work
    and skills. Each reference will receive an email with a link to a survey which they can complete.
    You can come back to check the status of these surveys. We require 3 completed references to gain
    full access to our service.`;

const agreeRealReferenceText = `I certify that I understand that creating a fake reference and/or sending the reference to a
    person other than the named valid professional reference will result in being deactivated from
    the Hire an Esquire Network.`;

class ReferencesForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <p style={{ margin: "1.5rem 0" }}>{formInstructionText}</p>
        <Row>
          <Col md={6}>
            <TextField name="reviewer_first_name" label="First Name" />
          </Col>
          <Col md={6}>
            <TextField name="reviewer_last_name" label="Last Name" />
          </Col>
        </Row>
        <TextField name="reviewer_email" label="Email" />
        <CheckboxField
          name="agree_real_reference"
          label={agreeRealReferenceText}
        />
        <div className="text-right">
          <FormButton
            className="text-right"
            action="send"
            submitting={submitting}
            disabled={pristine || submitting || !valid}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  enableReinitialize: true,
})(ReferencesForm);
