import styled, { css } from "styled-components";

const Label = styled.span`
  display: inline-block;
  margin: ${({ margin }) => (margin ? margin : "0")};
  padding: 0.3rem 0.6rem;
  border: 2px solid;
  border-radius: 6px;
  font-size: 80%;
  font-weight: bold;
  text-transform: uppercase;

  ${({ color, theme }) => {
    switch (color) {
      case "success":
        return css`
          color: ${theme.colors.success()};
          border-color: ${theme.colors.success()};
        `;
      case "warning":
        return css`
          color: ${theme.colors.warning()};
          border-color: ${theme.colors.warning()};
        `;
      case "danger":
        return css`
          color: ${theme.colors.secondary_red()};
          border-color: ${theme.colors.secondary_red()};
        `;
      default:
        return css`
          color: ${theme.colors.primary_turquoise()};
          border-color: ${theme.colors.primary_turquoise()};
        `;
    }
  }}};
`;

export default Label;
