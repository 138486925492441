import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MakeHiringEasierMarketing } from "public/components/marketing/blurbs";

const PublicProfileMarketing = () => (
  <div>
    <hr />
    <div className="marketing-section">
      <Row>
        <Col md={10} mdOffset={1}>
          <MakeHiringEasierMarketing />
          <div className="signup-btn">
            <Button
              bsStyle="info"
              bsSize="lg"
              href="https://hireanesquire.com/firms-and-hiring-managers/"
            >
              LEARN MORE
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default PublicProfileMarketing;
