import React, { Component } from "react";
import { Alert, Col } from "react-bootstrap";

import Onboard from "../../blocks";
import { RemoteSubmitButton } from "../../NextButton";
import { getActionIsRequired, getCustomErrorMsg } from "../../utils";
import { compose, connect, mutateAsync } from "queries/utils";
import { updateOwnContractorQuery } from "queries/mutations/contractors";
import { resendPendingEmailConfirmationQuery } from "queries/mutations/email";
import { handleSubmitError, redirectToNextStepIfNeeded } from "../../utils";
import { validateRequiredFields } from "common/utils/forms";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import Label from "common/blocks/Label";
import {
  EmailChangeCancelledCopy,
  UnconfirmedCopy,
  ConfirmedCopy,
  resendErrorCopy,
  ResendSuccessCopy,
} from "./copy";

const stepName = "email_address_confirmation";
const requiredFields = ["pending_email_address"];

class EmailAddressConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailChangeCancelled: false,
      nextButtonLoading: false,
      resending: false,
      resent: false,
      resendError: false,
    };
  }

  handleResendConfirmationEmail = () => {
    this.setState({ resending: true }, () => {
      this.props.resendPendingEmailConfirmation().then(({ status }) => {
        if (status >= 200 && status < 300) {
          this.setState({ resending: false, resent: true, resendError: false });
        } else {
          this.setState({ resending: false, resent: false, resendError: true });
        }
      });
    });
  };

  handleSubmitEmailAddressConfirmationForm = async values => {
    await this.setState({ nextButtonLoading: true });

    const {
      props: {
        ownContractor: { uuid },
        updateOwnContractor,
      },
      handleNextClick,
      stopButtonLoading,
    } = this;
    const result = await updateOwnContractor(values, uuid);

    stopButtonLoading();
    handleSubmitError(result);
    handleNextClick();
  };

  handleCancelEmailChange = async () => {
    await this.setState({ nextButtonLoading: true });

    const {
      props: {
        ownContractor: { uuid },
        refreshReqs,
        updateOwnContractor,
      },
    } = this;

    await updateOwnContractor({ pending_email_address: null }, uuid);

    refreshReqs();

    this.setState({
      emailChangeCancelled: true,
      nextButtonLoading: false,
      resent: false,
      resendError: false,
    });
  };

  validate = values => validateRequiredFields(requiredFields, values);

  handleNextClick = () => {
    redirectToNextStepIfNeeded(
      stepName,
      this.props.refreshReqs,
      this.stopButtonLoading,
    );
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: {
        emailChangeCancelled,
        nextButtonLoading,
        resending,
        resent,
        resendError,
      },
      props: {
        ownContractor: { email_address, pending_email_address },
        reqs: { steps },
      },
      handleCancelEmailChange,
      handleResendConfirmationEmail,
    } = this;
    const actionIsRequired = getActionIsRequired(stepName, steps);
    const customErrorMsg = getCustomErrorMsg(stepName, steps);

    return (
      <Onboard.Page>
        <Onboard.Container>
          <DesktopOnly>
            <Onboard.ContainerHeader>
              <div>
                <h1>Email Confirmation</h1>
              </div>
              <div>
                {actionIsRequired && (
                  <Label color="danger">ACTION REQUIRED</Label>
                )}
              </div>
            </Onboard.ContainerHeader>
          </DesktopOnly>
          <MobileOnly>
            <h1>Email Confirmation</h1>
            {actionIsRequired && (
              <div>
                <Label color="danger">ACTION REQUIRED</Label>
                <br />
                <br />
              </div>
            )}
          </MobileOnly>
          {!resending &&
            resendError && <Alert bsStyle="danger">{resendErrorCopy}</Alert>}
          {!resending &&
            resent && (
              <Alert bsStyle="warning">
                <ResendSuccessCopy />
              </Alert>
            )}
          {!resending &&
            emailChangeCancelled && (
              <EmailChangeCancelledCopy emailAddress={email_address} />
            )}
          {pending_email_address ? (
            <UnconfirmedCopy
              pendingEmailAddress={pending_email_address}
              resending={resending}
              resent={resent}
              resendError={resendError}
            />
          ) : (
            <ConfirmedCopy />
          )}
          {pending_email_address && (
            <Onboard.EntryDataRow>
              <Col xs={12}>
                <Onboard.TableButton
                  bsStyle="primary"
                  margin="0 5px 0 0"
                  onClick={handleResendConfirmationEmail}
                >
                  Resend Confirmation Email
                </Onboard.TableButton>
                <Onboard.TableButton
                  bsStyle="danger"
                  onClick={handleCancelEmailChange}
                >
                  Cancel Email Change
                </Onboard.TableButton>
              </Col>
            </Onboard.EntryDataRow>
          )}
          {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
        </Onboard.Container>
        <RemoteSubmitButton
          formName="onboardingEmailAddressConfirmationForm"
          isLoading={nextButtonLoading}
        />
      </Onboard.Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateOwnContractor: (data, uuid) =>
    dispatch(mutateAsync(updateOwnContractorQuery(data, uuid))),
  resendPendingEmailConfirmation: () =>
    dispatch(mutateAsync(resendPendingEmailConfirmationQuery())),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(EmailAddressConfirmation);
