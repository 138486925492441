import styled from "styled-components";

const Section = styled.div`
  margin: 0;
  padding: 0 0 15px 0;

  &:last-child {
    padding: 0;
  }
`;

export default Section;
