// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  badge: number | boolean,
  open: boolean,
};

const SecondaryNavItem: ComponentType<Props> = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0 15px;
  border-radius: 20px;

  & > span:first-child {
    float: left;
    height: 42px;
    line-height: 42px;
    margin: 0 10px;
    padding: 0;
    vertical-align: middle;
    box-sizing: border-box;
  }

  & > span:first-child::after {
    display: block;
    content: attr(name);
    font-weight: 900;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  & span:nth-child(2) {
    visibility: ${({ badge }) => (badge ? "visible" : "hidden")};
    float: left;
    vertical-align: middle;
    margin: 0;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white()};
    background-color: ${({ theme }) => theme.colors.secondary_red()};
    border-radius: 10px;
  }

  & > span:nth-child(3) {
    display: none;
    position: absolute;
    right: 15px;
    margin: 0;
    padding: 0 0 4px 0;
    color: ${({ theme }) => theme.colors.navlink()};
    font-weight: 900;
  }

  ${({ open }) =>
    open &&
    css`
      background-color: #cce3f5;

      & > span:nth-child(3) {
        display: inline;
      }
    `};
`;

export default SecondaryNavItem;
