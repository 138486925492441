import React from "react";
import { Helmet } from "react-helmet";

import { JobSearch } from "common/components/job-search";
import {
  JobMarketingFooter,
  JobSearchMarketing,
} from "public/components/marketing";

const PublicJobSearch = () => (
  <div className="public-job-search">
    <Helmet>
      <title>Search for legal professional jobs | Hire an Esquire</title>
      <meta
        name="description"
        content="Search the Hire an Esquire marketplace for projects that fit your skills and schedule."
      />
    </Helmet>
    <JobSearchMarketing />
    <JobSearch />
    <JobMarketingFooter />
  </div>
);

export default PublicJobSearch;
