import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";

import Loading from "common/components/Loading";
import { selectRow } from "admin/actions/nonAttorneyVerification";
import { NonAttorneyRow } from "./TableComponents";

class NonAttorneyTable extends Component {
  handleNonAttorneyRowClick(e, uuid) {
    if (!e.target.className.includes("fa-external-link"))
      this.props.selectRow(uuid);
  }

  render() {
    const { nonAttorneys, lastUpdated, isLoading, selectedRow } = this.props;
    return (
      <div>
        <Table className="verifications-table" hover striped>
          <thead>
            <tr>
              <th />
              <th>First Name</th>
              <th>Last Name</th>
              <th>Created</th>
              <th>Status</th>
              <th>Schools</th>
              <th>Reviews</th>
              <th>
                Reviews <small>(with recent related employment)</small>
              </th>
            </tr>
          </thead>
          <tbody>
            {lastUpdated &&
              !isLoading &&
              nonAttorneys.map(nonAttorney => (
                <NonAttorneyRow
                  key={nonAttorney.uuid}
                  nonAttorney={nonAttorney}
                  selected={selectedRow === nonAttorney.uuid}
                  onClick={e =>
                    this.handleNonAttorneyRowClick(e, nonAttorney.uuid)
                  }
                />
              ))}
          </tbody>
        </Table>
        {isLoading && <Loading />}
      </div>
    );
  }
}

const ConnectedNonAttorneyTable = connect(
  null,
  { selectRow },
)(NonAttorneyTable);

export default ConnectedNonAttorneyTable;
