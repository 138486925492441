import types from "./types";

export const applicationsRequest = () => ({
  type: "applications",
  params: { limit: 999 },
});

export const bankAccountsRequest = () => ({
  type: "bankAccounts",
  path: "bank_accounts/self",
});

export const currentUserTypeRequest = () => ({
  type: "currentUserType",
  path: "current_user_type",
});

export const expensesForJobRequest = props => ({
  type: "expenses",
  version: "dev",
  id: props.jobId,
  params: { job: props.jobId, limit: 999 },
});

export const fixedRateOccurrencesForJobRequest = props => ({
  type: "fixedRateOccurrences",
  path: "fixed_rate_occurrences",
  version: "dev",
  id: props.jobId,
  params: { job: props.jobId, limit: 999 },
});

export const jobRequest = props => ({
  type: "jobs",
  id: props.match.params.id,
  path: `jobs/${props.match.params.id}`,
});

export const jobWorkersRequest = () => ({
  type: "jobWorkers",
  path: "job_workers",
  params: { limit: 99 },
});

export const messagesRequest = () => ({
  type: "messages",
  version: "dev",
  limit: 999,
});

export const metadataRequest = () => ({ type: "metadata" });

export const languagesRequest = () => ({
  type: types.LANGUAGES,
  params: { limit: 999 },
});

export const ownUserRequest = () => ({
  type: "ownUser",
  path: "users/self",
});

export const ownContractorRequest = () => ({
  type: "ownContractor",
  path: "contractors/self",
  version: "dev",
});

export const positionTypesRequest = () => ({
  type: types.POSITION_TYPES,
  path: "position_types",
  params: { limit: 99 },
});

export const previousJobWorkers = () => ({
  type: types.PREVIOUS_JOB_WORKERS,
  path: "job_workers",
  params: { job_closed: true },
});

export const recommendationsByJobIdRequest = propsToJobId => props => {
  const jobId = propsToJobId(props);
  return {
    type: types.RECOMMENDATIONS,
    path: "recommendations/",
    version: "dev",
    params: { limit: 999, job: jobId },
  };
};

export const reviewRequestsRequest = () => ({
  type: "reviewRequests",
  path: "review_requests",
  version: "v2",
  params: { limit: 99 },
});

export const tagGroupsRequest = () => ({
  type: types.TAG_GROUPS,
  path: "tag_groups",
  version: "dev",
  params: { limit: 99 },
});

export const tagsRequest = () => ({
  type: types.TAGS,
  path: "tags",
  version: "dev",
  params: { limit: 999 },
});

export const timeEntriesForJobRequest = props => ({
  type: "timeEntries",
  path: "time_entries",
  version: "dev",
  id: props.jobId,
  params: { job: props.jobId, limit: 999 },
});
