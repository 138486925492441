import React, { Component, Fragment } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { Provider as ReduxQueryProvider } from "redux-query-react";
import NotificationsSystem from "reapop";
import theme from "reapop-theme-bootstrap";
import { ThemeProvider } from "styled-components";

import { trackPageView } from "common/utils/history";
import AppStyles from "./AppStyles";
import { getQueries } from "store/utils";
import themeStyles from "styles/themeStyles";

import AdminRoutes from "admin/routes";
import ContractorRoutes from "contractor/routes";
import { RedirectToApp } from "common/components/auth";
import HiringAgentRoutes from "hiringagent-dashboard/routes";
import PublicRoutes from "public/routes";
import { ErrorBoundary } from "common/components/error-boundary";
import history from "./history";

class App extends Component {
  componentDidMount() {
    trackPageView(history.location);
  }
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <ReduxQueryProvider queriesSelector={getQueries}>
          <ThemeProvider theme={themeStyles}>
            <Fragment>
              <AppStyles />
              <ErrorBoundary>
                <Router history={history}>
                  <Switch>
                    <Route exact path="/" component={RedirectToApp} />
                    <Route
                      path="/hae_admin/dashboards"
                      component={AdminRoutes}
                    />
                    <Route path="/contractor" component={ContractorRoutes} />
                    <Route path="/agency" component={HiringAgentRoutes} />
                    <Route path="/" component={PublicRoutes} />
                  </Switch>
                </Router>
              </ErrorBoundary>
              <div className="notifications-container">
                <NotificationsSystem theme={theme} />
              </div>
            </Fragment>
          </ThemeProvider>
        </ReduxQueryProvider>
      </Provider>
    );
  }
}

export default App;
