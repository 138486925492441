import actions from "hiringagent-dashboard/actions/languages";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  languages: {},
  isLoading: false,
  lastUpdated: null,
};

const languages = handleActions(
  {
    [actions.REQUEST_LANGUAGES]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_LANGUAGES]: (state, action) => ({
      ...state,
      languages: listToObject(action.payload.results, "uuid"),
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
  },
  initialState,
);
export default languages;
