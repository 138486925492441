import React, { Component, Fragment } from "react";
import { Fade } from "react-bootstrap";
import { reduxForm } from "redux-form";

import { SelectField } from "common/components/forms/inputs";
import { RequiredFieldError } from "common/utils/forms/validators";
import {
  FormRow,
  withFormError,
} from "hiringagent-dashboard/components/jobs/create-job-listing/utils";
import { compose } from "resources/components/utils";
import { PaymentDetails } from "types";

const allowedPaymentTypes = [
  PaymentDetails.PAYMENT_TYPES.HOURLY,
  PaymentDetails.PAYMENT_TYPES.PROJECT,
  PaymentDetails.PAYMENT_TYPES.FIXED_MONTH,
];

const validate = values => {
  const { payment_type } = values;
  const errors = {};
  if (!allowedPaymentTypes.includes(parseInt(payment_type, 10))) {
    errors.payment_type = <RequiredFieldError />;
  }
  return errors;
};

const PaymentTypeDescription = ({ title, children }) => (
  <Fragment>
    <dt>{title}</dt>
    <dd style={{ marginBottom: "1rem" }}>{children}</dd>
  </Fragment>
);

export class PaymentTypeDefinitions extends Component {
  state = {
    expanded: false,
  };

  handleExpandDefinitions = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { expanded } = this.state;

    return (
      <div>
        <a href="#" onClick={this.handleExpandDefinitions}>
          What options are available?
          <i className={`far fa-fw fa-angle-${expanded ? "down" : "left"}`} />
        </a>
        <Fade in={expanded}>
          <div>
            <div style={{ marginTop: "2rem" }}>
              <dl>
                <PaymentTypeDescription title="Hourly">
                  Pay for time worked. Invoiced weekly.
                </PaymentTypeDescription>
                <PaymentTypeDescription title="Project">
                  Pay a flat rate for a defined scope of work. Invoiced when
                  contractor marks the project as complete.
                </PaymentTypeDescription>
                <PaymentTypeDescription title="Fixed Month">
                  Pay a flat monthly rate for ongoing work within defined
                  parameters. Invoiced monthly upon contractor marking monthly
                  requirements complete.
                </PaymentTypeDescription>
              </dl>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

export class PaymentTypeForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    const paymentTypeChoices = Object.entries(
      PaymentDetails.paymentTypesMapping,
    ).filter(([key, _value]) =>
      allowedPaymentTypes.includes(parseInt(key, 10)),
    );

    return (
      <form onSubmit={handleSubmit}>
        <FormRow>
          <SelectField name="payment_type" label="Billing Options">
            {paymentTypeChoices.map(([key, value]) => (
              <option key={`payment-type-option-${key}`} value={key}>
                {value.name}
              </option>
            ))}
          </SelectField>
          <PaymentTypeDefinitions />
        </FormRow>
      </form>
    );
  }
}

const ConnectedPaymentTypeForm = compose(
  reduxForm({
    validate,
    form: "jobPaymentDetailsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  withFormError(),
)(PaymentTypeForm);

export default class PaymentType extends Component {
  render() {
    const { formProps, initialValues } = this.props;
    return (
      <div>
        <h2>Choose a billing option.</h2>
        <ConnectedPaymentTypeForm
          {...formProps}
          initialValues={initialValues}
        />
      </div>
    );
  }
}
