import React from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { useRequest } from "redux-query-react";

import { talentPoolsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";

import SelectedItemsList, {
  SelectedItem,
} from "common/components/SelectedItemsList";

const TalentPoolMultiSelect = ({ onChange, value = [] }) => {
  const request = talentPoolsRequest({
    url: { params: { limit: 99 } },
  });
  const selector = createResourceSelector(types.TALENT_POOLS, request);
  const {
    [types.TALENT_POOLS]: { data },
  } = useSelector(selector);
  const [{ isFinished, isPending }, _] = useRequest(request);

  const handleChange = val => onChange([...value, val.value]);
  const handleDelete = talentPoolId => () =>
    onChange(value.filter(id => id !== talentPoolId));

  const options = isFinished
    ? Object.keys(data)
        .filter(id => !value.includes(id))
        .map(id => data[id])
        .map(talentPool => ({
          value: talentPool.uuid,
          label: talentPool.name,
        }))
    : [];
  let placeholder = "Select at least one";
  if (isPending) placeholder = "Loading...";
  return (
    <div>
      <SelectedItemsList>
        {value.map(talentPoolId => (
          <SelectedItem
            key={`${talentPoolId}-selected`}
            label={data[talentPoolId].name}
            onDelete={handleDelete(talentPoolId)}
          />
        ))}
      </SelectedItemsList>
      <ReactSelect
        placeholder={placeholder}
        options={options}
        value={null}
        onChange={handleChange}
        clearable={false}
        disabled={isPending}
      />
    </div>
  );
};
export default TalentPoolMultiSelect;
