import types from "resources/types";
import { createResourceDetailQuery, createResourceListQuery } from "queries";

export const createCandidateQuery = (config = {}, candidate) =>
  createResourceDetailQuery(types.CANDIDATES, {
    url: `/api/v2/candidates/${candidate}/`,
    ...config,
  });

export const createCandidatesQuery = (config = {}) =>
  createResourceListQuery(types.CANDIDATES, {
    url: "/api/v2/candidates/?limit=999",
    ...config,
  });
