// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {};

const Footer: ComponentType<Props> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem -2rem -2rem -2rem;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.background_gray()};
`;

export default Footer;
