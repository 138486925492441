import styled from "styled-components";

const Footing = styled.div`
  margin: -2rem;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.background_gray()};
  text-align: center;
`;

export default Footing;
