import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { createStructuredSelector } from "reselect";
import { Col, ControlLabel, FormGroup, InputGroup, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import {
  setInitialDates,
  setStartDate,
  setEndDate,
} from "admin/actions/invoicing/dates";
import {
  fetchOpenInvoicesIfNeeded,
  generateInvoiceIfNeeded,
} from "admin/actions/invoicing/invoices";
import { refreshInvoicesAndEvents } from "admin/actions/invoicing/jobEvents";

import { mergeEvents } from "admin/utils";
import OpenInvoices from "admin/components/invoicing/OpenInvoices";
import OutstandingItems from "admin/components/invoicing/OutstandingItems";
import TimeSinceUpdate from "admin/components/invoicing/TimeSinceUpdate";
import Loading from "common/components/Loading";
import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import types from "resources/types";

class Invoicing extends Component {
  state = { modal: false };

  resourceDependencies = [types.JOBS, types.JOB_WORKERS, types.PAYMENT_DETAILS];

  componentDidMount() {
    const today = new Date(Date.now());
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - today.getDay() - 6);
    const endDate = new Date(today);
    endDate.setDate(today.getDate() - today.getDay());

    this.props.dispatch(setInitialDates(startDate, endDate));
    this.props.dispatch(fetchOpenInvoicesIfNeeded());
  }

  getJobEvents() {
    const {
      [types.JOBS]: { data: jobs },
      [types.JOB_WORKERS]: { data: jobWorkers },
      [types.PAYMENT_DETAILS]: { data: paymentDetails },
      invoicing: {
        expenses: { items: expenses },
        timeEntries: { items: timeEntries },
        fixedRates: { items: fixedRates },
      },
    } = this.props;
    const eventGroups = { expenses, timeEntries, fixedRates };
    const jobResources = { jobs, jobWorkers, paymentDetails };

    return this.getMergedJobEvents(eventGroups, jobResources);
  }

  getMergedJobEvents(eventGroups, jobResources) {
    const jobsWithEvents = {};
    Object.entries(eventGroups).forEach(([eventType, eventGroup]) => {
      Object.values(eventGroup).forEach(event => {
        mergeEvents(event, eventType, jobsWithEvents, jobResources);
      });
    });
    return jobsWithEvents;
  }

  render() {
    const {
      [types.JOBS]: { data: jobs },
      invoicing: {
        dates,
        jobEvents,
        expenses,
        timeEntries,
        fixedRates,
        invoices: {
          items: invoices,
          isFetching: invoicesFetching,
          isGenerating: invoicesGenerating,
        },
      },
    } = this.props;

    const jobEventsLoading =
      expenses.isFetching || timeEntries.isFetching || fixedRates.isFetching;
    const resourcesLoading = this.resourceDependencies.some(
      resource => this.props[resource].isPending,
    );
    const isLoading = jobEventsLoading || resourcesLoading;

    const jobsWithEvents = this.getJobEvents();

    return (
      <div>
        <a className="pull-right" href="/hae_admin/invoicing/invoice">
          Go to Invoices Admin <i className="fas fa-external-link-alt" />
        </a>
        <h1>Invoicing</h1>
        <Row>
          <Col md={4}>
            <FormGroup className="form-control-react-datepicker">
              <ControlLabel>Start Date</ControlLabel>
              <InputGroup>
                <DatePicker
                  className="form-control"
                  selected={
                    dates.startDate ? moment(dates.startDate).toDate() : null
                  }
                  onChange={newDate => this.handleStartDateChange(newDate)}
                  showMonthDropdown
                  showYearDropdown
                  todayButton="Today"
                  dropdownMode="select"
                />
                <InputGroup.Addon>
                  <i className="fas fa-calendar-alt" />
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="form-control-react-datepicker">
              <ControlLabel>End Date</ControlLabel>
              <InputGroup>
                <DatePicker
                  className="form-control"
                  selected={
                    dates.endDate ? moment(dates.endDate).toDate() : null
                  }
                  onChange={newDate => this.handleEndDateChange(newDate)}
                  showMonthDropdown
                  showYearDropdown
                  todayButton="Today"
                  dropdownMode="select"
                />
                <InputGroup.Addon>
                  <i className="fas fa-calendar-alt" />
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4} style={{ textAlign: "right" }}>
            <TimeSinceUpdate
              lastUpdated={jobEvents.lastUpdated}
              onRefresh={e => this.handleRefreshClick(e)}
            />
          </Col>
        </Row>

        <h2 style={{ marginTop: "1rem" }}>Outstanding Items</h2>
        <OutstandingItems
          loading={isLoading}
          isGenerating={invoicesGenerating}
          onCreateInvoice={jobId => this.handleCreateInvoice(jobId)}
          jobs={jobsWithEvents}
        />

        {isLoading && <Loading />}

        <div style={{ marginTop: "3rem" }}>
          <h2 style={{ display: "inline-block" }}>
            Open Invoices (not paid or approved)
          </h2>
        </div>
        <OpenInvoices
          loading={invoicesFetching}
          invoices={invoices}
          jobs={jobs}
        />

        {invoicesFetching && <Loading />}
      </div>
    );
  }

  handleStartDateChange(newDate) {
    this.props.dispatch(setStartDate(newDate));
  }

  handleEndDateChange(newDate) {
    this.props.dispatch(setEndDate(newDate));
  }

  handleCreateInvoice(jobId) {
    this.props
      .dispatch(generateInvoiceIfNeeded(jobId))
      .then(() => this.props.forceRequest());
  }

  handleRefreshClick(e) {
    e.preventDefault();
    this.props.dispatch(refreshInvoicesAndEvents());
    this.props.forceRequest();
  }
}

const jobsQuery = () =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=9999",
    force: true,
  });
const jobWorkersQuery = () =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: "/api/v2/job_workers/?limit=9999",
    force: true,
  });
const paymentDetailsQuery = () =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: "/api/v2/payment_details/?limit=9999",
    force: true,
  });

const mapPropsToConfig = props => [
  jobsQuery(props),
  jobWorkersQuery(props),
  paymentDetailsQuery(props),
];

const jobsConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);
const jobWorkersConfig = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkersQuery,
);
const paymentDetailsConfig = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailsQuery,
);

const getAdminInvoicing = state => state.admin.invoicing;

const mapStateToProps = createStructuredSelector({
  invoicing: getAdminInvoicing,
  ...jobsConfig,
  ...jobWorkersConfig,
  ...paymentDetailsConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(Invoicing);
