import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import querystring from "querystring";

import Loading from "common/components/Loading";
import ContractorProfile from "hiringagent-dashboard/components/contractor/ContractorProfile";
import JobRecommendationNavigation from "hiringagent-dashboard/components/recommendations/JobRecommendationNavigation";
import { verifiedSelector } from "hiringagent-dashboard/selectors";
import { connect, compose, connectResource } from "resources/components/utils";
import { recommendationsByJobIdRequest } from "resources/requests";
import { resourceSelectors } from "resources/selectors";
import types from "resources/types";

const RecommendationNotAvailableMsg = ({ jobId }) => (
  <Alert bsStyle="danger">
    <h3 style={{ margin: "1rem" }}>
      No recommendation available for this candidate
    </h3>
    <p style={{ margin: "1rem" }}>
      Click{" "}
      <Link to={`/agency/jobs/create/${jobId}/recommended_candidates/`}>
        here
      </Link>{" "}
      to view your current recommendations. You may have already invited or
      dismissed this candidate. Otherwise, if the job requirements were recently
      updated, the candidate may no longer be a fit.
    </p>
  </Alert>
);

class RecommendationHeader extends Component {
  title() {
    const {
      query,
      recommendation: { joblisting_title },
    } = this.props;
    const jobDisplay = (
      <small>for {joblisting_title && joblisting_title}</small>
    );
    return query ? (
      "You're all done!"
    ) : (
      <span>Recommended Candidates {joblisting_title && jobDisplay}</span>
    );
  }

  render() {
    const { query, verified } = this.props;

    return (
      <div>
        <div style={{ fontSize: "14.5px" }}>
          <h2>{this.title()}</h2>
          {query && (
            <p>
              The Hire an Esquire matching algorithm has recommended the
              following candidates. If you don&apos;t have time to review these
              candidates now, don&apos;t worry, they can be accessed from the
              details page for this job or your dashboard.
            </p>
          )}
          <p>
            {`${
              verified
                ? "Candidates "
                : `As soon as
                        your agency is verified, candidates `
            }you invite will receive a link to the job and
                        an application form. Dismissed candidates will not be notified.`}
          </p>
          <p>Our algorithm uses your selections to improve recommendations.</p>
        </div>
        <hr />
      </div>
    );
  }
}

RecommendationHeader = connect(state => ({
  verified: verifiedSelector(state),
}))(RecommendationHeader);

export class Recommendation extends Component {
  render() {
    const {
      recommendations: {
        metadata: { isLoading, lastUpdated },
        data: recommendations,
      },
      onReloadHandlers: { recommendations: reloadRecommendations },
      match: {
        params: { contractorId, id: jobId },
      },
      location: { search },
    } = this.props;

    const query = querystring.parse(search.substring(1));
    const recommendationsForJob = Object.values(recommendations).filter(
      r => r.job === jobId,
    );

    const recommendation =
      lastUpdated &&
      Object.values(recommendationsForJob).find(
        r => r.contractor.uuid === contractorId,
      );

    return lastUpdated ? (
      <div>
        {recommendation ? (
          <div>
            <RecommendationHeader
              query={query.job}
              recommendation={recommendation}
            />
            <div style={{ textAlign: "center" }}>
              <h4 style={{ marginBottom: "2rem" }}>{recommendation.reason}</h4>
              <JobRecommendationNavigation
                recsForJob={recommendationsForJob}
                recommendation={recommendation}
                queryString={search}
                onReload={reloadRecommendations}
              />
              <hr />
            </div>
          </div>
        ) : (
          <RecommendationNotAvailableMsg jobId={jobId} />
        )}
        <ContractorProfile
          contractorId={contractorId}
          isRecommendation={true}
          {...this.props}
        />
      </div>
    ) : (
      isLoading && <Loading />
    );
  }
}

const recommendationsSelector = resourceSelectors[types.RECOMMENDATIONS];
const propsToJobId = props => props.match.params.id;

const mapStateToProps = () =>
  createStructuredSelector({
    recommendations: recommendationsSelector,
  });

export default Recommendation = compose(
  connect(mapStateToProps),
  connectResource(recommendationsByJobIdRequest(propsToJobId)),
)(Recommendation);
