import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { getQueryKey } from "redux-query";

import { history as browserHistory } from "app";
import Responsive from "./Responsive";
import {
  connect,
  compose,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { messagesQuery } from "queries/requests/messages";
import { authUserQuery } from "queries/requests/auth";
import { newItemsQuery } from "queries/requests/newItems";
import {
  toggleThreadArchivedQuery,
  toggleThreadUnreadQuery,
} from "queries/mutations/messages";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";
import { getResourcesAreReady } from "common/utils/helpers";
import Loading from "common/components/Loading";

const pollingInterval = 15000;
const otherRequiredResources = [types.AUTH];
export const inits = {
  config: { force: true, replace: true },
  filter: "&inbox=1",
  limit: 10,
  offset: 0,
  search: "",
};

class Messaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMsgKey: this.props.match.params.id || null,
      archivedLoadId: null,
      unreadLoadId: null,
      pendingSearch: "",
      unreadCount: null,
      ...inits,
    };
  }

  componentDidMount() {
    this.handleMessagesPolling();
  }

  componentWillUnmount() {
    clearTimeout(this.messagesTimer);
  }

  handleMessagesPolling = async () => {
    const r = await this.props.fetchNewItems();
    if (r && r.body) {
      const fetchedCount = r.body.unread_messages;
      if (
        typeof fetchedCount === "number" &&
        (typeof this.state.unreadCount !== "number" ||
          fetchedCount > this.state.unreadCount)
      ) {
        await this.handleFetchMessages();
        this.setState({ unreadCount: fetchedCount });
      }
    }
    this.messagesTimer = setTimeout(
      this.handleMessagesPolling,
      pollingInterval,
    );
  };

  handleSearch = async e => {
    e.preventDefault();
    const { pendingSearch, search } = this.state;
    const searchAsFilter = `&q=${pendingSearch}`;
    if (search !== searchAsFilter) {
      await this.setState({
        config: inits.config,
        filter: "",
        search: searchAsFilter,
        offset: 0,
      });
      this.handleFetchMessages();
    }
  };

  handleSearchChange = async e => {
    if (e && e.target && e.target.value) {
      this.setState({ pendingSearch: e.target.value });
    } else {
      this.handleClearSearch();
    }
  };

  handleClearSearch = async () => {
    await this.setState({
      config: inits.config,
      pendingSearch: "",
      search: "",
      offset: 0,
    });
    this.handleFetchMessages();
  };

  handleFilterChange = async e => {
    e.preventDefault();
    const { target } = e;
    if (target instanceof HTMLSelectElement) {
      await this.setState({
        config: inits.config,
        pendingSearch: "",
        search: "",
        filter: target.value,
        offset: 0,
      });
      this.handleFetchMessages();
    }
  };

  handlePaginateMessages = async pos => {
    const { [this.handleGetQueryKey()]: mQuery } = this.props.messages.queries;
    if (!mQuery) return;
    const { offset, limit } = this.state;
    let newOffset = offset;
    if (pos === "start") newOffset = 0;
    if (pos === "prev") newOffset -= limit;
    if (pos === "next") newOffset += limit;
    if (pos === "end") {
      newOffset = mQuery.count - (mQuery.count % limit);
      if (newOffset === mQuery.count) newOffset -= limit;
    }

    if (newOffset >= 0 && newOffset < mQuery.count) {
      await this.setState({
        config: { force: true, replace: false },
        offset: newOffset,
      });
      this.handleFetchMessages();
    }
  };

  handleMessageItemClick = async (uuid, e) => {
    const id = e && e.target && e.target.id ? e.target.id : null;
    if (!id) this.setState({ activeMsgKey: uuid });
  };

  handleTogglersClick = async (uuid, e) => {
    const id = e && e.target && e.target.id ? e.target.id : null;
    if (id) {
      if (id === "archived-loader" || id === "unread-loader") return;
      const {
        props: { messages, toggleThreadArchived, toggleThreadUnread },
        state: { filter },
      } = this;
      if (id === "toggle-archive-icon") {
        await this.setState({ archivedLoadId: uuid, config: inits.config });
        await toggleThreadArchived(messages, uuid, filter);
        this.setState({ archivedLoadId: null });
      } else if (id === "toggle-unread-icon") {
        await this.setState({ unreadLoadId: uuid, config: inits.config });
        await toggleThreadUnread(messages, uuid, filter);
        if (uuid === this.state.activeMsgKey) {
          this.setState({ activeMsgKey: null, unreadLoadId: null });
        } else {
          this.setState({ unreadLoadId: null });
        }
      }
      this.handleFetchMessages();
    }
  };

  handleFetchMessages = async () => {
    const { config, filter, limit, offset, search } = this.state;
    const r = await this.props.fetchMessages(
      config,
      filter,
      limit,
      offset,
      search,
    );
    return r;
  };

  handleCloseThread = () => {
    this.setState({ activeMsgKey: null });
    browserHistory.push(`/${this.handleGetBaseRoute()}/messages`);
  };

  handleGetBaseRoute = () => {
    const { data: authData } = this.props.auth;
    const auth = Object.values(authData)[0];
    const userType = auth ? auth.user_type : null;
    if (userType && this.state.activeMsgKey) {
      if (userType === "hiring_agent") return "agency";
      if (userType === "contractor") return "contractor";
    }
  };

  handleGetQueryKey = () => {
    const { config, filter, limit, offset, search } = this.state;
    return getQueryKey(messagesQuery(config, filter, limit, offset, search));
  };

  render() {
    const {
      state: {
        activeMsgKey,
        offset,
        archivedLoadId,
        unreadLoadId,
        pendingSearch,
        search,
        filter,
      },
      props: {
        auth: { data: authData },
        messages: {
          data: messagesData,
          queries: { [this.handleGetQueryKey()]: mQuery },
        },
      },
    } = this;
    const isReady = getResourcesAreReady(otherRequiredResources, this.props);
    if (!isReady) return <Loading />;
    const commonProps = {
      authUser: Object.values(authData)[0],
      activeMsgKey,
      messagesData,
      mQuery,
      offset,
      onSearch: this.handleSearch,
      onSearchChange: this.handleSearchChange,
      onFetchMessages: this.handleFetchMessages,
      onCloseThread: this.handleCloseThread,
      onFilterChange: this.handleFilterChange,
      onGetBaseRoute: this.handleGetBaseRoute,
      onMessageItemClick: this.handleMessageItemClick,
      onTogglersClick: this.handleTogglersClick,
      onPaginateMessages: this.handlePaginateMessages,
      archivedLoadId,
      unreadLoadId,
      pendingSearch,
      search,
      filter,
      ...this.props,
    };

    return <Responsive {...commonProps} />;
  }
}

const messagesConfig = createResourceSelectorConfig(
  types.MESSAGES,
  messagesQuery,
);

const newItemsConfig = createResourceSelectorConfig(
  types.NEW_ITEMS,
  newItemsQuery,
);

const authUserConfig = createResourceSelectorConfig(types.AUTH, authUserQuery);

const mapStateToProps = createStructuredSelector({
  ...messagesConfig,
  ...authUserConfig,
  ...newItemsConfig,
});

const mapDispatchToProps = dispatch => ({
  toggleThreadArchived: (messages, uuid, filter) =>
    dispatch(mutateAsync(toggleThreadArchivedQuery(messages, uuid, filter))),
  toggleThreadUnread: (messages, uuid, filter) =>
    dispatch(mutateAsync(toggleThreadUnreadQuery(messages, uuid, filter))),
  fetchMessages: (config, filter, limit, offset, search) =>
    dispatch(
      requestAsync(messagesQuery(config, filter, limit, offset, search)),
    ),
  fetchNewItems: () => dispatch(requestAsync(newItemsQuery({ force: true }))),
});

const mapPropsToConfig = () => [authUserQuery(), newItemsQuery()];

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Messaging);
