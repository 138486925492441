import React from "react";
import styled from "styled-components";

import ParentList from "./ParentList";
import ParentItem from "./ParentItem";
import ChildList from "./ChildList";
import ChildItem from "./ChildItem";
import CollapseContainer from "./CollapseContainer";
import CollapseBase from "./CollapseBase";
import CollapseHeader from "./CollapseHeader";
import CollapseContent from "./CollapseContent";
import CollapseToggle from "./CollapseToggle";
import CollapseBar from "./CollapseBar";

const SidebarBlock = styled.div`
  margin: 0;
  padding: 0;
`;

const Side = props => <SidebarBlock {...props} />;

Side.ParentList = ParentList;
Side.ParentItem = ParentItem;
Side.ChildList = ChildList;
Side.ChildItem = ChildItem;
Side.CollapseContainer = CollapseContainer;
Side.CollapseBase = CollapseBase;
Side.CollapseHeader = CollapseHeader;
Side.CollapseContent = CollapseContent;
Side.CollapseToggle = CollapseToggle;
Side.CollapseBar = CollapseBar;

export default Side;
