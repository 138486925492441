import React from "react";
import styled, { css } from "styled-components";

const Score = styled.span`
  font-weight: bold;
`;

export const AssessmentScore = styled(Score)`
  ${({ score, theme }) => {
    if (score > 70) {
      return css`
        color: ${theme.colors.success()};
      `;
    } else if (score < 40)
      return css`
        color: ${theme.colors.primary_black40()};
      `;
  }};
`;
export const RequirementsScore = styled(Score).attrs({ percentage: true })`
  ${({ score, theme }) => {
    if (score === 100) {
      return css`
        color: ${theme.colors.success()};
      `;
    } else if (score === 0) {
      return css`
        color: ${theme.colors.primary_black40()};
      `;
    }
  }};
`;

export default Score;
