// @flow strict
import React, { Component, Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";

import Dropdown from "./Dropdown";
import LinkItem from "./LinkItem";
import Top from "../blocks";
import Desktop from "./blocks";
import Brand from "../Brand";
import SecondaryNavList from "./SecondaryNavList";
import PendingCandidatesNav, {
  type PendingCandidatesNavRenderProps,
} from "../PendingCandidatesNav";
import PendingInvoicesNav, {
  type PendingInvoicesNavRenderProps,
} from "../PendingInvoicesNav";
import MessagesLinkItem, {
  type MessagesLinkItemRenderProps,
} from "../MessagesLinkItem";
import { isActiveURL } from "common/utils";
import { agentHireURLs, agentManageURLs } from "../values";

const renderMessages = (to: string) => (props: MessagesLinkItemRenderProps) => (
  <LinkItem to={to} name="Inbox" {...props} />
);

type NavListProps = {
  activeSecondaryNav: string | null,
  onSetSecondaryNav: ("hire" | "manage") => void,
  userType: UserType,
  withSearchLink: boolean,
};

const NavList = ({
  activeSecondaryNav,
  onSetSecondaryNav,
  userType,
  withSearchLink,
}: NavListProps) => {
  switch (userType) {
    case "admin":
      return <Desktop.NavList />;
    case "anonymous":
      if (withSearchLink) {
        return (
          <Desktop.NavList>
            <LinkItem badge={false} name="Search" to="/public/jobs/" />
          </Desktop.NavList>
        );
      } else {
        return <Desktop.NavList />;
      }
    case "contractor":
      return (
        <Desktop.NavList>
          <LinkItem
            badge={false}
            name="Profile"
            to="/contractor/profile/view/"
          />
          <LinkItem badge={false} name="Find Jobs" to="/contractor/jobs/" />
          <MessagesLinkItem render={renderMessages("/contractor/messages/")} />
        </Desktop.NavList>
      );
    case "hiring_agent":
      return (
        <Desktop.NavList>
          <PendingCandidatesNav
            render={({ dot }: PendingCandidatesNavRenderProps) => (
              <Desktop.NavTrigger
                dot={dot}
                onClick={() => onSetSecondaryNav("hire")}
                open={activeSecondaryNav === "hire"}
              >
                <span>•</span>
                <span name="Hire">
                  Hire
                  <span />
                </span>
                <Desktop.Plus open={activeSecondaryNav === "hire"} />
              </Desktop.NavTrigger>
            )}
          />
          <PendingInvoicesNav
            render={({ dot }: PendingInvoicesNavRenderProps) => (
              <Desktop.NavTrigger
                dot={dot}
                onClick={() => onSetSecondaryNav("manage")}
                open={activeSecondaryNav === "manage"}
              >
                <span>•</span>
                <span name="Manage">
                  Manage
                  <span />
                </span>
                <Desktop.Plus open={activeSecondaryNav === "manage"} />
              </Desktop.NavTrigger>
            )}
          />
          <MessagesLinkItem render={renderMessages("/agency/messages/")} />
        </Desktop.NavList>
      );
    default:
      return <Desktop.NavList />;
  }
};

type ButtonProps = {
  userType: UserType,
  withSignUpButton: boolean,
};

const Button = ({ userType, withSignUpButton }: ButtonProps) => {
  switch (userType) {
    case "admin":
      return null;
    case "anonymous":
      if (withSignUpButton) {
        return (
          <LinkContainer to="/signup">
            <Top.Button bsStyle="primary">Sign Up</Top.Button>
          </LinkContainer>
        );
      } else {
        return null;
      }
    case "contractor":
      return null;
    case "hiring_agent":
      return (
        <LinkContainer to="/agency/jobs/create">
          <Top.Button bsStyle="primary">+ Post a Job</Top.Button>
        </LinkContainer>
      );
    default:
      return null;
  }
};

type DesktopNavProps = {
  empty: boolean,
  pathname: string,
  userType: UserType,
  withSearchLink: boolean,
  withSignUpButton: boolean,
};

type DesktopNavState = {
  activeSecondaryNav: "hire" | "manage" | null,
  dropdownOpen: boolean,
};

export default class DesktopNav extends Component<
  DesktopNavProps,
  DesktopNavState,
> {
  constructor(props: DesktopNavProps) {
    super(props);
    this.state = { activeSecondaryNav: null, dropdownOpen: false };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseDown, false);
  }

  handleMouseDown = (e: MouseEvent) => {
    const { activeSecondaryNav, dropdownOpen } = this.state;
    const topNavDropdown = document.querySelector("#top-nav-dropdown");
    const secondaryNavList = document.querySelector("#secondary-nav-list");

    if (
      dropdownOpen &&
      !(topNavDropdown && topNavDropdown.contains(e.target))
    ) {
      this.setState({ dropdownOpen: false });
    }

    if (
      activeSecondaryNav &&
      !(secondaryNavList && secondaryNavList.contains(e.target))
    ) {
      this.setState({ activeSecondaryNav: null });
    }
  };

  handleCloseSecondaryNav = () => this.setState({ activeSecondaryNav: null });

  handleSetSecondaryNav = (trigger: "hire" | "manage") =>
    this.setState({ activeSecondaryNav: trigger });

  handleToggleUserDropdown = () =>
    this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));

  render() {
    const { empty, userType, withSearchLink, withSignUpButton } = this.props;
    const { activeSecondaryNav, dropdownOpen } = this.state;

    return (
      <Desktop>
        <Desktop.Main>
          <Brand userType={userType} />
          {!empty && (
            <Fragment>
              <NavList
                activeSecondaryNav={activeSecondaryNav}
                onSetSecondaryNav={this.handleSetSecondaryNav}
                userType={userType}
                withSearchLink={Boolean(withSearchLink)}
              />
              <Button
                userType={userType}
                withSignUpButton={Boolean(withSignUpButton)}
              />
              <Dropdown
                dropdownOpen={dropdownOpen}
                onToggleUserDropdown={this.handleToggleUserDropdown}
                userType={userType}
              />
            </Fragment>
          )}
        </Desktop.Main>
        {!empty && userType === "hiring_agent" && (
          <SecondaryNavList
            activeSecondaryNav={activeSecondaryNav}
            onCloseSecondaryNav={this.handleCloseSecondaryNav}
          />
        )}
      </Desktop>
    );
  }
}
