import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "react-bootstrap";

import { domOnlyProps, validationState } from "common/utils/forms";
import FieldHelpBlock from "./FieldHelpBlock";

export const OldFormGroupNonControl = ({
  field,
  label,
  type,
  children,
  typeClass,
  checked = false,
  ...props
}) => {
  const validationState = field.touched && field.error ? "error" : "success";
  return (
    <div className={typeClass}>
      <FormGroup validationState={validationState}>
        <label>
          <input
            type={type}
            checked={checked}
            {...domOnlyProps(field)}
            {...domOnlyProps(props)}
          />
          {label}
        </label>
        {children}
        <FieldHelpBlock {...field} />
      </FormGroup>
    </div>
  );
};

const FormGroupNonControl = ({
  children,
  input,
  meta,
  label,
  type,
  typeClass,
  checked,
  className,
  ...otherProps
}) => (
  <div className={`${className} ${typeClass}`}>
    <FormGroup validationState={validationState(meta)}>
      <label>
        <input
          type={type}
          checked={checked}
          {...domOnlyProps(input)}
          {...domOnlyProps(otherProps)}
        />
        {label}
      </label>
      {children}
      <FieldHelpBlock {...meta} />
    </FormGroup>
  </div>
);

FormGroupNonControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  typeClass: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
};

FormGroupNonControl.defaultProps = {
  children: null,
  label: null,
  checked: false,
  className: "",
};

export { FormGroupNonControl };
