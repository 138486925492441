// @flow strict
import React from "react";

import PendingCandidatesNav, {
  type PendingCandidatesNavRenderProps,
} from "../PendingCandidatesNav";
import PendingInvoicesNav, {
  type PendingInvoicesNavRenderProps,
} from "../PendingInvoicesNav";
import Top from "../blocks";
import Mobile from "./blocks";
import { isActiveURL } from "common/utils";

type SecondaryLinkItemProps = {
  badge: number | boolean,
  handleClose: () => void,
  name: string,
  to: string,
};

const SecondaryLinkItem = ({
  badge,
  handleClose,
  name,
  to,
}: SecondaryLinkItemProps) => {
  const open = isActiveURL(to, true);

  return (
    <Top.NavLink onClick={handleClose} open={open} to={to}>
      <Mobile.SecondaryNavItem badge={badge} open={open}>
        <span name={name}>{name}</span>
        <span>{badge}</span>
        <span>•</span>
      </Mobile.SecondaryNavItem>
    </Top.NavLink>
  );
};

type SecondaryNavListProps = {
  handleClose: () => void,
  links: "hire" | "manage",
  open: boolean,
};

const SecondaryNavList = ({
  handleClose,
  links,
  open,
}: SecondaryNavListProps) => {
  const hireLinks = [
    <SecondaryLinkItem
      key={0}
      badge={0}
      name="POST A JOB"
      handleClose={handleClose}
      to="/agency/jobs/create/"
    />,
    <PendingCandidatesNav
      key={1}
      render={({ badge }: PendingCandidatesNavRenderProps) => (
        <SecondaryLinkItem
          badge={badge}
          name="VIEW APPLICANTS"
          handleClose={handleClose}
          to="/agency/candidates/"
        />
      )}
    />,
    <SecondaryLinkItem
      key={2}
      badge={0}
      name="SEARCH CANDIDATES"
      handleClose={handleClose}
      to="/agency/attorneys/"
    />,
  ];
  const manageLinks = [
    <SecondaryLinkItem
      key={0}
      badge={0}
      name="JOBS"
      handleClose={handleClose}
      to="/agency/jobs/"
    />,
    <PendingInvoicesNav
      key={1}
      render={({ badge }: PendingInvoicesNavRenderProps) => (
        <SecondaryLinkItem
          badge={badge}
          name="INVOICING"
          handleClose={handleClose}
          to="/agency/invoices/"
        />
      )}
    />,
    <SecondaryLinkItem
      key={2}
      badge={0}
      name="PAYMENT HISTORY"
      handleClose={handleClose}
      to="/agency/payment-history/"
    />,
    <SecondaryLinkItem
      key={3}
      badge={0}
      name="TALENT POOLS"
      handleClose={handleClose}
      to="/agency/talent_pools/"
    />,
  ];
  return (
    <Mobile.SecondaryNavList links={links} open={open}>
      {links === "hire" ? hireLinks : manageLinks}
    </Mobile.SecondaryNavList>
  );
};

export default SecondaryNavList;
