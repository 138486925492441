import React, { Component, Fragment } from "react";
import { Button, Modal, FormGroup } from "react-bootstrap";
import { change, Field, formValueSelector } from "redux-form";
import { connect, compose } from "queries/utils";
import moment from "moment";

import FormButton from "common/components/FormButton";
import FieldHelpBlock from "common/components/forms/FieldHelpBlock";
import { DatePickerField } from "common/components/forms/inputs";
import { validationState } from "common/utils/forms";
import { formName } from "contractor/forms/settings/job-notifications/SubscriptionForm";
import { FormError } from "hiringagent-dashboard/components/jobs/create-job-listing/utils";

class SettingsRadiosField extends Component {
  handleRadioClick = ({ target: { value } }) => {
    const {
      changeField,
      input: { onChange },
    } = this.props;
    const newValue = Number.parseInt(value, 10);
    if (newValue === 0) {
      changeField("paused_until", null);
    }

    onChange(newValue);
  };

  render() {
    const {
      input: { value },
      meta,
    } = this.props;

    return (
      <div className="settings-radio-field">
        <FormGroup validationState={validationState(meta)} bsSize="lg">
          <div className="input-large">
            <label>
              <input
                type="radio"
                name="options"
                value={0}
                checked={value === 0}
                onChange={this.handleRadioClick}
              />{" "}
              Unsubscribe to all emails
            </label>
          </div>
          <div className="input-large">
            <label>
              <input
                type="radio"
                name="options"
                value={-1}
                checked={value === -1}
                onChange={this.handleRadioClick}
              />{" "}
              Pause all emails until:
            </label>
          </div>
          <FieldHelpBlock touched={meta.touched} error={meta.error} />
        </FormGroup>
      </div>
    );
  }
}

class UnsubscribeModal extends Component {
  render() {
    const {
      handleSaveClick,
      onClose,
      show,
      subscriptionStatus,
      submitting,
      pristine,
      valid,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onClose}
        className="settings-unsubscribe-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase">
            <div className="text-center">unsubscribe or pause emails</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormError error={this.props.error} />
          <div className="field-container">
            <Field
              name="subscription_status"
              component={SettingsRadiosField}
              {...this.props}
            />
            <div className="paused-until-input">
              <DatePickerField
                name="paused_until"
                minDate={moment()
                  .add(1, "day")
                  .toDate()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={subscriptionStatus !== -1}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Cancel</Button>
          <FormButton
            onClick={handleSaveClick}
            className="text-right"
            action="save"
            submitting={submitting}
            disabled={pristine || submitting || !valid}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const SubscribeButton = ({ handleSubscribeClick, submitting }) => (
  <Button
    bsSize="lg"
    bsStyle="info"
    disabled={submitting}
    onClick={handleSubscribeClick}
    className="subscribe-button"
  >
    <span>Resubscribe to Emails</span>
  </Button>
);

const InactivateButton = ({ handleToggleModal }) => (
  <Button onClick={handleToggleModal} className="inactivate-button" bsSize="lg">
    <span>Unsubscribe or Pause Emails</span>
  </Button>
);

class SubscriptionActionButton extends Component {
  getStatusText() {
    const { pausedUntilStatus, intitalSubscriptionStatus } = this.props;
    let status = null;
    if (intitalSubscriptionStatus === 0) {
      status = <div className="status-text">You are unsubscribed.</div>;
    }
    if (intitalSubscriptionStatus === -1) {
      status = (
        <div className="status-text">
          {`Your emails are paused${
            pausedUntilStatus
              ? ` until ${moment(pausedUntilStatus).format("L")}`
              : ""
          }.`}
        </div>
      );
    }
    return status;
  }

  render() {
    const isSubscribed = this.props.intitalSubscriptionStatus === 1;
    const actionButton = isSubscribed ? (
      <InactivateButton {...this.props} />
    ) : (
      <SubscribeButton {...this.props} />
    );

    return (
      <div className="subscription-actions">
        <div className="visible-xs-block">
          <div>{actionButton}</div>
          {this.getStatusText()}
        </div>

        <div className="hidden-xs">
          <span style={{ marginRight: "2rem" }}>{actionButton}</span>
          {this.getStatusText()}
        </div>
      </div>
    );
  }
}

class SubscriptionSettingsSection extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    const hash = window.location.hash;
    const hasSubscriptionHash = hash === "#subscription_preferences";
    const isSubscribed = this.props.intitalSubscriptionStatus === 1;

    if (hasSubscriptionHash && isSubscribed) {
      this.setState({
        isModalOpen: true,
      });
    }
  }

  handleToggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleSubscribeClick = () => {
    this.props.changeField("subscription_status", 1);
    this.props.changeField("paused_until", null);
    setTimeout(this.props.handleSubmit);
  };

  handleSaveClick = () => {
    const submission = this.props.handleSubmit();
    // Convert to promise if not already (e.g. sync validation error), to avoid exception
    Promise.resolve(submission).then(resp => {
      if (!resp) {
        this.props.untouch("paused_until");
        this.handleToggleModal();
      }
    });
  };

  handleCancelClick = () => {
    this.props.reset();
    this.handleToggleModal();
  };

  render() {
    const {
      intitalSubscriptionStatus,
      formValues: { paused_until, subscription_status },
    } = this.props;

    return (
      <Fragment>
        <UnsubscribeModal
          show={this.state.isModalOpen}
          onClose={this.handleCancelClick}
          subscriptionStatus={subscription_status}
          handleSaveClick={this.handleSaveClick}
          intitalSubscriptionStatus={intitalSubscriptionStatus}
          {...this.props}
        />
        <SubscriptionActionButton
          handleSubscribeClick={this.handleSubscribeClick}
          handleToggleModal={this.handleToggleModal}
          intitalSubscriptionStatus={intitalSubscriptionStatus}
          pausedUntilStatus={paused_until}
        />
      </Fragment>
    );
  }
}

const formSelector = formValueSelector(formName);
const mapStateToProps = state => ({
  formValues: {
    subscription_status: formSelector(state, "subscription_status"),
    paused_until: formSelector(state, "paused_until"),
  },
});

export default compose(
  connect(
    mapStateToProps,
    {
      changeField: (field, value) => change(formName, field, value),
    },
  ),
)(SubscriptionSettingsSection);
