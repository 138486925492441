import React, { Component } from "react";
import styled from "styled-components";
import { Button, ControlLabel } from "react-bootstrap";

import { FormControlGroup } from "common/components/forms";
import TalentPoolSelect from "./TalentPoolSelect";
import { deepEqual } from "common/utils";
import { debounce } from "utils";

const LabelHeader = styled.h4.attrs({ className: "text-uppercase" })`
  margin: 0;
`;

class TalentPoolsSearchForm extends Component {
  static defaultProps = {
    values: {
      search: "",
    },
  };
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      values: {
        search: props.defaultValues.search,
      },
    };
  }

  componentDidMount() {
    const { onSearchChange } = this.props;
    this.debouncedOnSearchChange = debounce(onSearchChange, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { values } = this.state;
    const { values: prevValues } = prevState;
    const {
      defaultValues: { search: defaultSearch },
    } = this.props;
    const {
      defaultValues: { search: prevDefaultSearch },
    } = prevProps;

    if (!deepEqual(values, prevValues)) {
      this.debouncedOnSearchChange(values.search);
    }
    // Reset local state if a different default prop comes in (e.g. after reset)
    if (defaultSearch !== prevDefaultSearch) {
      this.setState({ values: { search: defaultSearch } });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleFieldChange(fieldName) {
    return e =>
      this.setState({
        values: { ...this.state.values, [fieldName]: e.target.value },
      });
  }

  render() {
    const { onTalentPoolChange, onReset, values: propValues } = this.props;
    const { values } = this.state;
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        <FormControlGroup
          type="search"
          name="search"
          label={<LabelHeader>Search</LabelHeader>}
          input={{
            value: values.search,
            onChange: this.handleFieldChange("search"),
          }}
          meta={{}}
        />
        <ControlLabel>
          <LabelHeader>Talent Pools</LabelHeader>
        </ControlLabel>
        <TalentPoolSelect
          onChange={onTalentPoolChange}
          value={propValues.talent_pool}
        />
        <div className="text-center">
          <Button onClick={onReset}>Clear filters</Button>
        </div>
      </form>
    );
  }
}

export default TalentPoolsSearchForm;
