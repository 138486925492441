import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";
import querystring from "querystring";

import { createErrorNotification } from "common/utils/notifications";
import Loading from "hiringagent-dashboard/components/utils/Loading";
import {
  logSurveyNotAvailable,
  requestRelationshipChoices,
  requestReviewRequest,
  submitReview,
} from "public/actions/reviews";
import ResponsiveContainer from "common/components/ResponsiveContainer";
import NotFound, { ContactSupportLink } from "public/components/NotFound";
import ReviewForm from "public/forms/ReviewForm";

export class SurveyNotAvailable extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { status, completed } = this.props;

    let explanation = null;
    if (status === "expired") {
      explanation = " because it has expired";
    } else if (status === "complete" || completed) {
      explanation = " because it has already been completed";
    } else if (status === "undeliverable") {
      explanation =
        " because we could not deliver the email containing the link to this survey";
    } else if (status === "deleted") {
      explanation = " because this request has been deleted";
    } else if (status === "declined") {
      explanation = " because you previously declined to complete this survey";
    }

    return (
      <ResponsiveContainer>
        <h1>Not available</h1>
        <p>Sorry, this survey is no longer available{explanation}.</p>
        <ContactSupportLink />
      </ResponsiveContainer>
    );
  }
}
SurveyNotAvailable.propTypes = {
  completed: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};
SurveyNotAvailable.defaultProps = {
  completed: false,
};

class Review extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      location: { search },
      match: {
        params: { id },
      },
    } = this.props;
    const query = querystring.parse(search.substring(1));
    this.props.onLoad(id, query.auth_token);
  }

  handleSubmit(values, dispatch) {
    const {
      location: { search },
      match: {
        params: { id },
      },
      notify,
    } = this.props;
    const { practicearearating_set } = values;
    values.review_request_id = id;

    const query = querystring.parse(search.substring(1));

    // Only submit practice areas that have ratings.
    const filteredValues = {
      ...values,
      // Need to be careful not to mutate `values.practiceAreaRating`,
      // or else the fields will be unregistered and removed from the FieldArray.
      practicearearating_set: [...practicearearating_set].filter(
        practiceAreaRating => Boolean(practiceAreaRating.rating),
      ),
    };

    return dispatch(submitReview(filteredValues, query.auth_token)).then(
      ({ json, response }) => {
        if (!response.ok) {
          if (Object.entries(json).length > 0) {
            json._error = "There are form errors";
          } else {
            notify(createErrorNotification());
          }
          throw new SubmissionError(json);
        }
        return json;
      },
    );
  }

  getPracticeAreasInitialValues() {
    const {
      reviewRequest: { practice_areas },
    } = this.props;
    const practiceAreaFields = [];
    practice_areas.forEach(practiceArea => {
      practiceAreaFields.push({
        practice_area: practiceArea.uuid,
        name: practiceArea.name,
        rating: null,
      });
    });
    return practiceAreaFields;
  }

  getInitalValues() {
    const {
      reviewer_first_name,
      reviewer_last_name,
    } = this.props.reviewRequest;

    return {
      reviewer_first_name,
      reviewer_last_name,
      practicearearating_set: this.getPracticeAreasInitialValues(),
      work_with_date: Array(2)
        .fill()
        .map(() => ({ month: null, year: null })),
    };
  }

  render() {
    const {
      isLoading,
      lastUpdated,
      relationshipChoices,
      reviewCreated,
      reviewRequest,
      reviewRequest: {
        attorney_first_name,
        attorney_last_name,
        reviewer_first_name,
      },
      logSurveyNotAvailable,
      location,
      match: {
        params: { id },
      },
    } = this.props;

    const query = querystring.parse(location.search.substring(1));
    const { auth_token } = query;

    const linkToDecline = (
      <Link
        to={{
          ...location,
          pathname: `/attorney/profile/review/decline/${id}`,
        }}
      >
        Click here
      </Link>
    );

    const reviewForm = reviewCreated ? (
      <ResponsiveContainer>
        <h1>Reference Submitted</h1>
        <p>
          Thanks, we&apos;ve received your submission! We&apos;re sure that{" "}
          {attorney_first_name} appreciates it.
        </p>
        <p>
          If you&apos;re interested in learing more about the Hire an Esquire
          network,{" "}
          <a href="https://hireanesquire.com/firms-and-hiring-managers/">
            click here
          </a>
          .
        </p>
        <p>Otherwise, feel free to close this page. Thanks again!</p>
      </ResponsiveContainer>
    ) : (
      <div>
        <ResponsiveContainer>
          <h1>Reference Request</h1>
          <div className="review-description">
            <p>
              Hi{reviewer_first_name && ` ${reviewer_first_name}`}. You have
              been selected to be a reference for{" "}
              <strong>
                {attorney_first_name} {attorney_last_name}
              </strong>
              . Please try to be thoughtful in your answers, as they may
              directly influence hiring decisions. Thank you!
            </p>
            <p className="text-muted">
              Are you not able to be {attorney_first_name}&apos;s reference?{" "}
              {linkToDecline} to decline.
            </p>
          </div>
        </ResponsiveContainer>
        <Row>
          <Col sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4}>
            {reviewRequest && (
              <ReviewForm
                initialValues={this.getInitalValues()}
                relationshipChoices={relationshipChoices}
                attorneyFirstName={attorney_first_name}
                onSubmit={this.handleSubmit}
              />
            )}
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="review-form-public">
        {isLoading ? (
          <Loading style={{ margin: "2rem 0" }} />
        ) : lastUpdated && reviewRequest.status ? (
          reviewRequest.status === "pending" &&
          !reviewRequest.review_completed ? (
            // Just to make sure this review wasn't completed and reset to pending status
            reviewForm
          ) : (
            <SurveyNotAvailable
              status={reviewRequest.status}
              completed={reviewRequest.review_completed}
              onLoad={() => logSurveyNotAvailable(id, auth_token)}
            />
          )
        ) : (
          <NotFound />
        )}
      </div>
    );
  }
}

Review.propTypes = {};

export default connect(
  state => state.anonymous.reviews,
  dispatch => ({
    onLoad: (reviewRequestId, authTokenId) => {
      dispatch(requestRelationshipChoices(authTokenId));
      dispatch(requestReviewRequest(reviewRequestId, authTokenId));
    },
    logSurveyNotAvailable: (reviewRequestId, authTokenId) => {
      dispatch(logSurveyNotAvailable(reviewRequestId, authTokenId));
    },
    notify: msg => dispatch(notify(msg)),
  }),
)(Review);
