// @flow strict
import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import Experience from "./blocks";
import PracticeAreasList from "./PracticeAreasList";

class ExperienceSection extends Component {
  render() {
    const { contractor } = this.props;
    return (
      <Experience>
        <h1>Experience</h1>
        <Experience.Container>
          {contractor.previous_employment.map(experience => (
            <Experience.Main key={experience.uuid}>
              {experience.start_date ? (
                experience.end_date ? (
                  <Experience.Date>
                    <time>
                      {moment(experience.start_date).format("MMM YYYY")}
                    </time>{" "}
                    -{" "}
                    <time>
                      {moment(experience.end_date).format("MMM YYYY")}
                    </time>
                  </Experience.Date>
                ) : (
                  <Experience.Date>
                    <time>
                      {moment(experience.start_date).format("MMM YYYY")}
                    </time>{" "}
                    - Present
                  </Experience.Date>
                )
              ) : null}
              <Experience.Details>
                {experience.company_name && (
                  <Experience.CompanyName>
                    {experience.company_name}
                  </Experience.CompanyName>
                )}
                {experience.firm_type && (
                  <Experience.FirmType>
                    {`${experience.firm_type}`}
                  </Experience.FirmType>
                )}
                {experience.job_title && (
                  <Experience.Extra>
                    {experience.position_type
                      ? `${experience.position_type} | ${experience.job_title}`
                      : experience.job_title}
                  </Experience.Extra>
                )}
              </Experience.Details>
              {experience.location && (
                <Experience.LocationContainer>
                  <span className="hidden" itemProp="address">
                    {experience.location}
                  </span>
                  <span itemProp="name">{experience.location}</span>
                </Experience.LocationContainer>
              )}
              {experience.tags && (
                <PracticeAreasList
                  attorneyUuid={contractor.uuid}
                  practiceAreas={experience.tags.map(tag => ({
                    text: tag.name,
                    name: tag.name,
                    group: tag.group,
                  }))}
                />
              )}
            </Experience.Main>
          ))}
          <Experience.Footing>
            <Link to={`/agency/attorneys/${contractor.uuid}`}>
              <Experience.FootingLink>View Full Profile</Experience.FootingLink>
            </Link>
          </Experience.Footing>
        </Experience.Container>
      </Experience>
    );
  }
}

export default ExperienceSection;
