import React from "react";
import { ButtonToolbar } from "react-bootstrap";

import PrimaryButton, { PrimaryFormButton } from "common/blocks/PrimaryButton";

const FormButtons = ({ onHide, pristine, submitting }) => (
  <ButtonToolbar>
    <PrimaryFormButton
      action="save"
      bsStyle="primary"
      disabled={pristine || submitting}
      submitting={submitting}
    />
    <PrimaryButton bsStyle="default" onClick={onHide}>
      Close
    </PrimaryButton>
  </ButtonToolbar>
);

export default FormButtons;
