import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { flattenObject } from "common/utils";

class FeedbackPanel extends Component {
  render() {
    const {
      feedback: { data: feedbackData },
      jobs: { data: jobsData },
    } = this.props;
    const feedbackList = Object.values(feedbackData)
      .filter(({ status }) => status === 1 || status === 2) // 1 = Sent, 2 = Reminded
      .slice(0, 3);

    return (
      <div>
        {feedbackList.length ? (
          feedbackList.map((feedbackItem, key) => {
            const { full_name, job } = flattenObject(feedbackItem);

            return (
              <div className="line-item" key={key}>
                <Row>
                  <Col xs={12}>
                    <Link to={`/agency/feedback/${feedbackItem.uuid}`}>
                      {full_name}
                    </Link>
                    <br />
                    <div className="subline">
                      Worked on: {jobsData[job].title}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })
        ) : (
          <div className="line-item text-center">
            <Row>
              <Col xs={12}>None listed.</Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default FeedbackPanel;
