import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { addMessageRecipientIds } from "hiringagent-dashboard/actions/messages";

import AttorneyProfilePicture from "hiringagent-dashboard/components/search/AttorneyProfilePicture";
import { SavedContractorTalentPoolDropdown, SavedContractorNotes } from ".";
import { ContractorActionsDropdown } from "hiringagent-dashboard/components/contractor";

const Layout = {
  Container: styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 75px 1fr;
    grid-template-rows: auto 1fr auto auto auto;
    grid-template-areas:
      "picture name"
      "picture contractor_type"
      "actions actions"
      "notes notes"
      "lists lists";
    gap: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 20px;

    ${({ theme }) => theme.media.desktop`
      grid-template-columns: 120px 1fr 180px;
      grid-template-rows: auto 1fr auto auto;
      grid-template-areas:
        "picture name actions"
        "picture lists actions"
        "contractor_type notes notes"
        "contractor_type notes notes";
      column-gap: 30px;
      row-gap: 10px;
    `};
  `,
  Picture: styled.div`
    grid-area: picture;
  `,
  ContractorType: styled.div`
    grid-area: contractor_type;
    ${({ theme }) => theme.media.desktop`
      justify-self: center;
    `};
  `,
  Name: styled.div`
    grid-area: name;
  `,
  Actions: styled.div`
    grid-area: actions;
    button,
    .btn {
      font-weight: bold;
      text-transform: uppercase;
    }
  `,
  Notes: styled.div`
    grid-area: notes;
  `,
  Lists: styled.div`
    grid-area: lists;
  `,
};

const ContractorProfilePicture = ({ className, contractor, size }) => (
  <div className={className}>
    <AttorneyProfilePicture
      size={size}
      pictureUrl={contractor.profile_picture_url}
      firstName={contractor.first_name}
      lastName={contractor.last_name}
      showPicture={true}
      uuid={contractor.uuid}
    />
  </div>
);

const PrivateProfileTooltip = ({
  contractor: { allow_public_profile, first_name },
  children,
  id,
}) => {
  if (allow_public_profile) return children;
  const popover = (
    <Popover id={`private-profile-tooltip-${id}`} title="Private Profile">
      {first_name} has chosen to keep their profile private.
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={["hover", "click"]}
      overlay={popover}
      placement="top"
    >
      <span className="btn-block">{children}</span>
    </OverlayTrigger>
  );
};

const ContractorTypeLabel = styled.h5.attrs({ className: "text-uppercase" })`
  display: inline-block;
  margin: 0;
  padding: 4px 8px;
  line-height: 1;
  border-width: 2px;
  border-radius: 4px;
  border-style: solid;
  text-align: center;

  &.text-uppercase {
    font-weight: 900;
  }

  ${({ contractorType, theme }) => {
    const color =
      contractorType === "Attorney"
        ? theme.colors.primary_turquoise()
        : theme.colors.primary_orange();
    return css`
      border-color: ${color};
      color: ${color};
    `;
  }};
`;

const ContractorName = styled.h2`
  margin: 0;
  font-weight: 700;
`;
const ActionsButtonContainer = styled.div`
  margin-top: 5px;
`;
const ResultNumber = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.background_gray()};
  font-weight: bold;
  ${({ theme }) => theme.media.desktop`
    font-size: 2rem;
    line-height: 2rem;
  `};
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 5px;
  min-width: 27px;
  text-align: center;
`;

// Use shorter names for contractor types
const contractorTypeMapping = {
  Attorney: "Attorney",
  "Paralegal/Legal Specialist": "Paralegal",
  "Legal Administration and Support": "Legal Admin",
  "JD/Non-Barred Lawyer": "Non-Barred Lawyer",
};

const SavedContractorCard = props => {
  const {
    savedContractor,
    savedContractor: { contractor_data: contractor },
    resultNumber = null,
  } = props;
  const dispatch = useDispatch();
  const onMessageClick = () =>
    dispatch(addMessageRecipientIds([contractor.uuid]));
  return (
    <Layout.Container>
      <Layout.Picture>
        <ContractorProfilePicture
          contractor={contractor}
          size={75}
          className="visible-xs visible-sm"
        />
        <ContractorProfilePicture
          contractor={contractor}
          size={120}
          className="hidden-xs hidden-sm"
        />
      </Layout.Picture>
      <Layout.ContractorType>
        <ContractorTypeLabel
          contractorType={contractorTypeMapping[contractor.contractor_type]}
        >
          {contractorTypeMapping[contractor.contractor_type]}
        </ContractorTypeLabel>
      </Layout.ContractorType>
      <Layout.Name>
        <ContractorName>
          <Link to={`/agency/attorneys/${contractor.uuid}`}>
            {contractor.full_name}
          </Link>
        </ContractorName>
      </Layout.Name>
      <Layout.Actions>
        <PrivateProfileTooltip contractor={contractor} id="view">
          <LinkContainer to={`/agency/attorneys/${contractor.uuid}`}>
            <Button
              bsStyle="success"
              block
              disabled={!contractor.allow_public_profile}
            >
              view
            </Button>
          </LinkContainer>
        </PrivateProfileTooltip>
        <LinkContainer to="/agency/messages/create/" onClick={onMessageClick}>
          <Button bsStyle="info" block>
            message
          </Button>
        </LinkContainer>
        <ActionsButtonContainer>
          <ContractorActionsDropdown contractor={contractor} />
        </ActionsButtonContainer>
      </Layout.Actions>
      <Layout.Notes>
        <SavedContractorNotes savedContractor={savedContractor} />
      </Layout.Notes>
      <Layout.Lists>
        <SavedContractorTalentPoolDropdown savedContractor={savedContractor} />
      </Layout.Lists>
      {resultNumber && <ResultNumber>{resultNumber}</ResultNumber>}
    </Layout.Container>
  );
};

export default SavedContractorCard;
