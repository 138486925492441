import "core-js"; // TODO: This is a temporary solution for IE11
import "react-app-polyfill/ie11";

import React from "react";
import ReactDOM from "react-dom";

import { App, history } from "./app";
import { initBroadcast } from "utils/broadcast";
import { initSentry } from "utils/sentry";
import { initStore } from "store";

import "styles";

const head = document.getElementsByTagName("head")[0];
const script = document.createElement("script");
script.type = "text/javascript";
script.src =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyAxGxzvaL74w9JpkkhBAIA7VO5oK9Csxzo&libraries=places";
head.appendChild(script);

const store = initStore();

initSentry();
initBroadcast(store);

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById("root"),
);
