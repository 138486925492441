import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Button,
  ButtonToolbar,
  Grid,
  Label,
  Panel,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { addNotification as notify } from "reapop";

import {
  AttorneyNotes,
  BarcardVerifications,
  BarInformationsForAttorney,
  BarVerificationStatus,
  DenyBarInfoModal,
  EditBarInformation,
  EmailVerifications,
  DuplicateLicenseWarning,
  OverallRejectedWarning,
} from "admin/components/attorney-verification-manager";
import { submitBarInformation } from "admin/actions/attorneyVerificationManager";
import { withAttorneyVerificationManager } from "admin/connectors";
import { USState } from "common/components";
import Loading from "common/components/Loading";
import { withAttorney } from "common/connectors";
import { statesLookup } from "common/constants/states";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import AttorneyProfilePicture from "hiringagent-dashboard/components/search/AttorneyProfilePicture";

const AttorneyDetailsTable = ({ attorney }) => (
  <Table condensed>
    <thead>
      <tr>
        <th>Email</th>
        <th>Verified</th>
        <th>Google</th>
        <th>Signed Up</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{attorney.email_address}</td>
        <td>
          {attorney.is_verified ? (
            <i className="far fa-check text-success" />
          ) : (
            <i className="far fa-times text-danger" />
          )}
        </td>
        <td>
          <a
            href={`https://www.google.com/search?q=${encodeURIComponent(
              attorney.full_name,
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google
          </a>
        </td>
        <td>{moment(attorney.created).format("L")}</td>
      </tr>
    </tbody>
  </Table>
);

const BarInformationDetails = ({ attorney, barInfo }) => (
  <div>
    <div className="clearfix" style={{ marginBottom: "1rem" }}>
      <div className="pull-left">
        <AttorneyProfilePicture
          size={130}
          pictureUrl={attorney.profile_picture_url}
          firstName={attorney.first_name}
          lastName={attorney.last_name}
          showPicture={true}
          uuid={attorney.uuid}
        />
      </div>
      <div style={{ marginLeft: "160px" }}>
        <AttorneyDetailsTable attorney={attorney} />
      </div>
    </div>

    <Panel>
      <Panel.Body>
        <AttorneyNotes attorney={attorney} />
      </Panel.Body>
    </Panel>
    <Panel>
      <Panel.Body>
        <EditBarInformation barInfo={barInfo} />
      </Panel.Body>
    </Panel>

    <div>
      <h2>Barcard Verifications</h2>
      <BarcardVerifications />
    </div>

    <div>
      <h2>Email Verifications</h2>
      <EmailVerifications />
    </div>
    <BarInformationsForAttorney attorneyId={attorney.uuid} />
  </div>
);

class BarLicenseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDenyModal: false,
    };
  }

  showDenyModal = () => this.setState({ showDenyModal: true });
  hideDenyModal = () => this.setState({ showDenyModal: false });

  submitBarInfo = data => {
    const {
      attorneyVerificationManager: { selectedBarInformationId },
      submitBarInformation,
    } = this.props;
    return submitBarInformation(selectedBarInformationId, data);
  };

  handleDenyBarInfoClick = data =>
    this.submitBarInfo(data).then(({ json, response }) => {
      let notification;
      if (response.ok) {
        notification = createSuccessNotification({
          title: "Bar info denied",
          message: `Bar information ${statesLookup[json.state].label} ${
            json.bar_id
          } for
                        ${json.full_name} has been denied.`,
        });
      } else {
        const {
          attorneyVerificationManager: {
            barInformations,
            selectedBarInformationId,
          },
        } = this.props;
        const barInfo = barInformations[selectedBarInformationId];
        notification = createErrorNotification({
          message: `Encountered an error when trying to deny bar
                        information ${statesLookup[barInfo.state].label} ${
            barInfo.bar_id
          } for
                        ${barInfo.full_name}. Please refresh and try again.`,
        });
      }
      this.hideDenyModal();
      this.props.notify(notification);
    });

  handleVerifyBarInfoClick = () => {
    this.submitBarInfo({ verified: 1 }).then(({ json, response }) => {
      let notification;
      if (response.ok) {
        notification = createSuccessNotification({
          title: "Bar info verified",
          message: `Bar information ${statesLookup[json.state].label} ${
            json.bar_id
          } for
                        ${json.full_name} has been verified.`,
        });
      } else {
        const {
          attorneyVerificationManager: {
            barInformations,
            selectedBarInformationId,
          },
        } = this.props;
        const barInfo = barInformations[selectedBarInformationId];
        notification = createErrorNotification({
          message: `Encountered an error when trying to verify bar information
                        ${statesLookup[barInfo.state].label} ${
            barInfo.bar_id
          } for ${barInfo.full_name}. Please
                        refresh and try again.`,
        });
      }
      this.props.notify(notification);
    });
  };

  render() {
    const {
      attorneyVerificationManager: {
        barInformations,
        selectedBarInformationId,
      },
      attorney: { isLoading, ready },
      attorneys,
    } = this.props;
    const { showDenyModal } = this.state;

    const barInfo = barInformations[selectedBarInformationId];
    const attorney = ready && attorneys[barInfo.attorney];

    return (
      <div style={{ position: "relative", height: "100%" }}>
        <div className="verification-overview">
          <Grid fluid>
            <h2 className="clearfix">
              <span>Selected License: {barInfo.full_name}</span>
              <ButtonToolbar className="pull-right">
                <Button
                  bsStyle="success"
                  disabled={barInfo.verified === 1}
                  onClick={this.handleVerifyBarInfoClick}
                >
                  Verify
                </Button>
                <Button
                  bsStyle="danger"
                  disabled={barInfo.verified === -1 || barInfo.verified === 2}
                  onClick={this.showDenyModal}
                >
                  Deny...
                </Button>
              </ButtonToolbar>
            </h2>

            <h3>
              <Label>
                <USState stateId={barInfo.state} />
              </Label>&nbsp;
              <Label>{barInfo.bar_id}</Label>&nbsp;{" "}
              <BarVerificationStatus barInfo={barInfo} />&nbsp;
              <DuplicateLicenseWarning barInfo={barInfo} />&nbsp;
              <OverallRejectedWarning barInfo={barInfo} />&nbsp;
            </h3>
          </Grid>
        </div>
        <div className="verification-details">
          <Grid fluid>
            {attorney ? (
              <BarInformationDetails attorney={attorney} barInfo={barInfo} />
            ) : (
              isLoading && <Loading />
            )}

            <DenyBarInfoModal
              barInfo={barInfo}
              show={showDenyModal}
              onHide={this.hideDenyModal}
              onDeny={this.handleDenyBarInfoClick}
            />
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({ attorneys: state.common.attorneys }),
    { submitBarInformation, notify },
  ),
  withAttorneyVerificationManager(),
  withAttorney(
    ({
      attorneyVerificationManager: {
        barInformations,
        selectedBarInformationId,
      },
    }) => ({
      path: `attorneys/${barInformations[selectedBarInformationId].attorney}`,
    }),
  ),
)(BarLicenseDetails);
