import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";
import { formValueSelector } from "redux-form";
import querystring from "querystring";
import { history } from "app";

import { StateSelect, TextField } from "common/components/forms/inputs";
import { updateHistoryQuery } from "public/utils/search";

class JobSearchForm extends Component {
  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleToggleMobileFiltersClick = this.handleToggleMobileFiltersClick.bind(
      this,
    );
    this.state = {
      mobileFiltersExpanded: false,
    };
  }

  componentDidMount() {
    const { change } = this.props;
    const {
      location: { search },
    } = history;
    const query = querystring.parse(search.substring(1));
    Object.keys(query).forEach(key => {
      change(key, query[key]);
    });
  }

  handleFieldChange() {
    const { change, handleSubmit, formValues } = this.props;
    if (formValues.offset > 0) {
      change("offset", 0);
    }
    updateHistoryQuery(formValues);
    handleSubmit();
  }

  handleReset() {
    const { handleSubmit, reset } = this.props;
    reset();
    updateHistoryQuery();
    setTimeout(handleSubmit);
  }

  handleToggleMobileFiltersClick(e) {
    e.preventDefault();
    this.setState({ mobileFiltersExpanded: !this.state.mobileFiltersExpanded });
  }

  render() {
    const { handleSubmit } = this.props;
    const { mobileFiltersExpanded } = this.state;
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <TextField
          name="keywords"
          label="Keywords"
          onChangeAction={this.handleFieldChange}
        />
        <div style={{ marginBottom: "1rem" }} className="visible-sm">
          <a href="" onClick={this.handleToggleMobileFiltersClick}>
            {mobileFiltersExpanded ? (
              <span>&#9662; Hide</span>
            ) : (
              <span>&#9656; Show</span>
            )}{" "}
            Filters
          </a>
        </div>
        <div className={mobileFiltersExpanded ? "" : "hidden-sm"}>
          <StateSelect
            byAbbreviation
            name="location"
            label="Location"
            onChangeAction={this.handleFieldChange}
          />
        </div>
        <div className="text-center">
          <Button onClick={this.handleReset}>Clear filters</Button>
        </div>
      </form>
    );
  }
}

const formSelector = formValueSelector("jobSearchForm");
JobSearchForm = reduxForm({
  form: "jobSearchForm",
  initialValues: {
    search: true,
    limit: 10,
    offset: 0,
    ordering: "-published_date",
  },
})(JobSearchForm);
JobSearchForm = connect(state => {
  return {
    formValues: {
      keywords: formSelector(state, "keywords"),
      location: formSelector(state, "location"),
      limit: formSelector(state, "limit"),
      offset: formSelector(state, "offset"),
      ordering: formSelector(state, "ordering"),
    },
  };
})(JobSearchForm);
export default JobSearchForm;
