import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Col, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { mutateAsync } from "redux-query";
import { addNotification } from "reapop";
import moment from "moment";

import { history as browserHistory } from "app";
import { createErrorNotification } from "common/utils/notifications";
import { update } from "queries/mutations/job-listings";
import { calculateJobListingFormUrl } from "hiringagent-dashboard/utils/jobs";

export const PageTitle = ({ clickedSave, published }) => {
  let title = "Ready to publish?";
  if (clickedSave) title = "Your job posting has been saved.";
  if (published) title = "Your job posting has been published.";
  return <h2>{title}</h2>;
};

const BeforeActionExplanation = ({ firstStepUrl, searchable }) => (
  <div>
    <p>You can save your job as a draft or publish now to:</p>
    <ul>
      {searchable && <li>Allow candidates to find and apply to your job</li>}
      <li>Receive custom candidate recommendations</li>
      <li>Invite candidates to apply</li>
    </ul>
    <p>
      To go back and edit your job, click <Link to={firstStepUrl}>here</Link>.
    </p>
  </div>
);

const SavedExplanation = () => (
  <p>
    Click below to see recommended contractors that we think would be a good fit
    for this job.
  </p>
);

const PublishedExplanation = ({ publishedDate }) => (
  <div>
    <p>Your job posting was published {moment(publishedDate).fromNow()}.</p>
  </div>
);

export const Explanation = ({
  clickedSave,
  firstStepUrl,
  published,
  publishedDate,
  searchable,
}) => {
  let explanation = (
    <BeforeActionExplanation
      searchable={searchable}
      firstStepUrl={firstStepUrl}
    />
  );
  if (clickedSave) explanation = <SavedExplanation />;
  if (published)
    explanation = <PublishedExplanation publishedDate={publishedDate} />;
  return <div className="publish-explanation">{explanation}</div>;
};

export const PublishActions = ({
  clickedSave,
  nextStepUrl,
  onPublishClick,
  onSaveClick,
  published,
}) => (
  <div style={{ textAlign: "center" }}>
    {published || clickedSave ? (
      <div className="text-center">
        <LinkContainer to={nextStepUrl}>
          <Button bsStyle="info">View recommended candidates &rarr;</Button>
        </LinkContainer>
      </div>
    ) : (
      <ButtonToolbar>
        <Button
          bsStyle="default"
          className="publish-action"
          onClick={onSaveClick}
        >
          Save as draft
        </Button>
        <Button
          bsStyle="success"
          className="publish-action"
          onClick={onPublishClick}
        >
          <strong>Publish now!</strong>
        </Button>
      </ButtonToolbar>
    )}
  </div>
);

export const getNextStepUrl = jobId =>
  `/agency/jobs/create/${jobId}/recommended_candidates/`;
export const getFirstStepUrl = jobId => `/agency/jobs/create/${jobId}/step/0/`;

export class Publish extends Component {
  state = {
    clickedSave: false,
  };

  componentDidMount() {
    const { jobListing } = this.props;
    browserHistory.push(calculateJobListingFormUrl(jobListing));
  }

  handlePublishClick = () => {
    const { notify, submitFormData } = this.props;
    return submitFormData({ published: true }).then(({ status }) => {
      if (!(status >= 200 && status < 300)) {
        notify(createErrorNotification());
      }
    });
  };

  handleSaveClick = () => {
    this.setState({
      clickedSave: true,
    });
  };

  render() {
    const {
      match: {
        params: { id: jobId },
      },
      jobListing: { published, published_date, searchable },
    } = this.props;
    const { clickedSave } = this.state;
    return (
      <div className="publish-job-listing">
        <Row style={{ margin: "4rem 0" }}>
          <Col lg={6} lgOffset={3}>
            <ProgressBar
              now={100}
              label={`${100}%`}
              srOnly
              style={{ margin: 0 }}
            />
          </Col>
        </Row>
        <PageTitle clickedSave={clickedSave} published={published} />
        <Row className="publish-description">
          <Col lg={7} md={8} sm={12}>
            <Explanation
              clickedSave={clickedSave}
              published={published}
              publishedDate={published_date}
              searchable={searchable}
              firstStepUrl={getFirstStepUrl(jobId)}
            />
          </Col>
          <Col lg={5} md={4} mdOffset={0} sm={8} smOffset={2} />
        </Row>
        <PublishActions
          clickedSave={clickedSave}
          nextStepUrl={getNextStepUrl(jobId)}
          onPublishClick={this.handlePublishClick}
          onSaveClick={this.handleSaveClick}
          published={published}
        />
      </div>
    );
  }
}

export default connect(
  null,
  (dispatch, props) => ({
    submitFormData: data =>
      dispatch(mutateAsync(update(props.jobListing.uuid, data))),
    notify: message => dispatch(addNotification(message)),
  }),
)(Publish);
