// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  dropdownOpen: boolean,
};

const DropdownName: ComponentType<Props> = styled.span`
  float: right;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary_black()};
  white-space: nowrap;
  overflow: hidden;

  &::after {
    display: block;
    content: attr(name);
    font-weight: 900;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      font-weight: 900;
    `};
`;

export default DropdownName;
