// @flow strict
import React from "react";

import Candidate from "types/Candidate";
import CandidateActions from "hiringagent-dashboard/components/candidates/CandidateActions";

type Props = {
  candidate: Candidate,
  contractor: Contractor,
  job: Job,
};

const CandidateFooter = (props: Props) => {
  const { candidate, contractor, job } = props;
  return (
    <CandidateActions candidate={candidate} contractor={contractor} job={job} />
  );
};

export default CandidateFooter;
