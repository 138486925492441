import { formatDate } from "admin/utils/index";

import { fetchExpensesIfNeeded } from "admin/actions/invoicing/expenses";
import { fetchTimeEntriesIfNeeded } from "admin/actions/invoicing/timeEntries";
import { fetchFixedRatesIfNeeded } from "admin/actions/invoicing/fixedRates";
import { fetchOpenInvoicesIfNeeded } from "admin/actions/invoicing/invoices";

function fetchEvents(startDate, endDate) {
  return dispatch => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    dispatch(fetchExpensesIfNeeded(formattedStartDate, formattedEndDate));
    dispatch(fetchTimeEntriesIfNeeded(formattedStartDate, formattedEndDate));
    return dispatch(
      fetchFixedRatesIfNeeded(formattedStartDate, formattedEndDate),
    );
  };
}

export function fetchEventsIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch(
      fetchEvents(
        state.admin.invoicing.dates.startDate,
        state.admin.invoicing.dates.endDate,
      ),
    );
  };
}

export function refreshInvoicesAndEvents() {
  return dispatch => {
    dispatch(fetchOpenInvoicesIfNeeded());
    return dispatch(fetchEventsIfNeeded());
  };
}
