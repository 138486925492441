// @flow strict
import React, { type ComponentType } from "react";
import styled, { css } from "styled-components";

import Container from "./Container";
import Main from "./Main";
import Bar from "./Bar";
import Label from "./Label";
import LabelExtra from "./LabelExtra";

type Props = {
  offset: number,
};

const ProgressBlock: ComponentType<Props> = styled.div`
  width: 100%;
  min-height: 50px;
  height: 10vh;
  max-height: 75px;
  margin: 0;
  padding: 0;
  ${({ offset }) =>
    offset &&
    css`
      bottom: ${offset}px;
    `};
`;

const Progress = (props: Props) => <ProgressBlock {...props} />;

Progress.Container = Container;
Progress.Main = Main;
Progress.Bar = Bar;
Progress.Label = Label;
Progress.LabelExtra = LabelExtra;

export default Progress;
