import React, { Component } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";

import { withOwnContractor } from "contractor/connectors";
import { CheckboxField } from "common/components/forms/inputs";

const formName = "editPrivacyForm";

const checkboxLabel = (
  <span style={{ fontSize: "1.5rem" }}>Make my profile searchable</span>
);

class EditPrivacyForm extends Component {
  render() {
    const {
      ownContractor: { isLoading },
      handleSubmit,
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div style={{ textAlign: "center" }}>
            <hr />
            <div style={{ marginBottom: "2rem" }}>
              <CheckboxField
                name="allow_public_profile"
                label={checkboxLabel}
              />
            </div>
            <hr />
            <div className="pull-right">
              {isLoading ? (
                <i className="far fa-lg fa-cog fa-spin" />
              ) : (
                <Button type="submit" bsStyle="primary">
                  Save
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  withOwnContractor(),
  reduxForm({ form: formName }),
)(EditPrivacyForm);
