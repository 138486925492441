import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export const EmailChangeCopy = () => (
  <Fragment>
    You have a pending email address change that needs to be confirmed. Check
    the status on{" "}
    <Link to="/contractor/onboarding/email-address-confirmation">
      Email Address Confirmation
    </Link>.
  </Fragment>
);
