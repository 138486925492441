import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { action } from "common/actions/utils";

const actions = keyMirror({
  SET_SELECTED_BAR_INFORMATION_ID: null,
  REMOVE_BARCARD_VERIFICATION: null,
  REMOVE_EMAIL_VERIFICATION: null,
  REMOVE_PHONE_VERIFICATION: null,
});
export default actions;

export const removeBarcardVerification = createAction(
  actions.REMOVE_BARCARD_VERIFICATION,
);
export const removeEmailVerification = createAction(
  actions.REMOVE_EMAIL_VERIFICATION,
);
export const removePhoneVerification = createAction(
  actions.REMOVE_PHONE_VERIFICATION,
);
export const setSelectedBarInformationId = createAction(
  actions.SET_SELECTED_BAR_INFORMATION_ID,
);

export const submitAttorney = (attorneyId, data) =>
  action({
    type: "attorney",
    path: `attorneys/${attorneyId}`,
    version: "v1",
    method: "patch",
    data,
  });

export const submitBarInformation = (barInfoId, data) =>
  action({
    type: "submitBarInformation",
    path: `bar_informations/${barInfoId}`,
    version: "v1",
    method: "patch",
    data,
  });

export const submitCreateBarcardVerification = data =>
  action({
    type: "createBarcardVerification",
    path: "barcard_verifications",
    version: "v1",
    method: "post",
    data,
  });

export const submitCreateEmailVerification = data =>
  action({
    type: "createEmailVerification",
    path: "email_verifications",
    version: "v1",
    method: "post",
    data,
  });

export const submitCreatePhoneVerification = data =>
  action({
    type: "createPhoneVerification",
    path: "phone_verifications",
    version: "v1",
    method: "post",
    data,
  });

export const submitEmailVerification = (emailVerificationId, data) =>
  action({
    type: "emailVerificationSet",
    path: `email_verifications/${emailVerificationId}`,
    version: "v1",
    method: "patch",
    data,
  });

export const submitPhoneVerification = (phoneVerificationId, data) =>
  action({
    type: "phoneVerificationSet",
    path: `phone_verifications/${phoneVerificationId}`,
    version: "v1",
    method: "patch",
    data,
  });

export const submitBarcardVerification = (barcardVerificationId, data) =>
  action({
    type: "barcardVerificationSet",
    path: `barcard_verifications/${barcardVerificationId}`,
    version: "v1",
    method: "patch",
    data,
  });

export const deleteBarcardVerification = barcardVerificationId =>
  action({
    type: "deleteBarcardVerification",
    path: `barcard_verifications/${barcardVerificationId}`,
    version: "v1",
    method: "delete",
  });

export const deleteEmailVerification = emailVerificationId =>
  action({
    type: "deleteEmailVerification",
    path: `email_verifications/${emailVerificationId}`,
    version: "v1",
    method: "delete",
  });

export const deletePhoneVerification = phoneVerificationId =>
  action({
    type: "deletePhoneVerification",
    path: `phone_verifications/${phoneVerificationId}`,
    version: "v1",
    method: "delete",
  });
