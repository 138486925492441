import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { Col, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import Loading from "common/components/Loading";
import NotFound from "common/components/NotFound";
import {
  JobCandidates,
  JobAttachments,
  JobDetails,
  JobHeader,
  JobInvoices,
  JobPaymentDetails,
} from "hiringagent-dashboard/components/jobs/job-details";
import {
  toggleJobModal,
  submitCloseJob,
  removeFile,
  removeMessage,
} from "hiringagent-dashboard/actions/jobs";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";

class Job extends Component {
  handleCloseJobClick(jobUuid) {
    this.props.dispatch(toggleJobModal(null));
    this.props.dispatch(submitCloseJob(jobUuid));
  }

  render() {
    const {
      [types.JOBS]: { data: jobs, isFinished: jobsFinished },
      [types.JOB_LISTINGS]: {
        data: jobListings,
        isFinished: jobListingsFinished,
        query: { data: jobListingsQueryData },
      },
      match: {
        params: { id },
      },
    } = this.props;

    const job = jobs[id];
    const jobListingId = jobListingsQueryData[0];
    const jobListing = jobListingId && jobListings[jobListingId];

    if ((!job && !jobsFinished) || (!jobListing && !jobListingsFinished)) {
      return <Loading />;
    } else if ((!job && jobsFinished) || (!jobListing && jobListingsFinished)) {
      return <NotFound />;
    }

    return (
      <div>
        <JobHeader jobId={id} />
        <Row>
          <Col lg={6}>
            {jobListing && <JobDetails job={job} jobListing={jobListing} />}
          </Col>
          <Col lg={6}>
            <JobCandidates jobId={id} />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <JobPaymentDetails
              jobId={id}
              paymentDetailsId={job.paymentdetails}
              workerCount={job.committed_worker_count}
            />
          </Col>
          <Col lg={6}>
            <JobAttachments job={job} />
          </Col>
        </Row>
        <JobInvoices jobId={id} />
      </div>
    );
  }
}

const jobsQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
    force: true,
  });

const jobListingsQuery = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.match.params.id}`,
    force: true,
  });

const mapPropsToConfig = props => [jobsQuery(props), jobListingsQuery(props)];

const jobsConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);
const jobListingsConfig = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...jobsConfig,
  ...jobListingsConfig,
});

export default compose(
  connect(
    mapStateToProps,
    dispatch => ({
      dispatch,
      dismissAlert: () => dispatch(removeMessage()),
      handleRemoveFileClick: (jobUuid, fileUuid) =>
        dispatch(removeFile(jobUuid, fileUuid)),
    }),
  ),
  connectRequest(mapPropsToConfig),
)(Job);
