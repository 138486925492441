import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Alert, Modal } from "react-bootstrap";

import { compose, connect, mutateAsync } from "queries/utils";
import {
  postContractorLanguageQuery,
  patchContractorLanguageQuery,
} from "queries/mutations/contractorLanguages";
import { handleSubmitError } from "../../utils";
import {
  LanguageSelect,
  LanguageProficiencySelect,
} from "common/components/forms/inputs";
import FormButtons from "../../FormButtons";

let LanguageProficienciesForm = ({
  error,
  handleSubmit,
  onHide,
  pristine,
  submitting,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <LanguageSelect />
    <LanguageProficiencySelect />
    <FormButtons onHide={onHide} submitting={submitting} pristine={pristine} />
  </form>
);

LanguageProficienciesForm = reduxForm({
  form: "onboardingLanguageProficienciesForm",
})(LanguageProficienciesForm);

class FormModal extends Component {
  handleSubmitLanguageProficienciesForm = values => {
    const {
      props: {
        postContractorLanguage,
        editingLanguageProficiency,
        patchContractorLanguage,
      },
      postSubmit,
    } = this;

    if (editingLanguageProficiency && editingLanguageProficiency.uuid) {
      return patchContractorLanguage(values, editingLanguageProficiency.uuid)
        .then(handleSubmitError)
        .then(postSubmit);
    } else {
      return postContractorLanguage(values)
        .then(handleSubmitError)
        .then(postSubmit);
    }
  };

  postSubmit = () => {
    const { onHide, refreshReqs, refreshOwnContractor } = this.props;
    onHide();
    refreshOwnContractor();
    refreshReqs();
  };

  getInitialValues = () => {
    const { editingLanguageProficiency } = this.props;
    let initialValues = {};
    if (editingLanguageProficiency) {
      initialValues = {
        fluency: editingLanguageProficiency.fluency,
        language: editingLanguageProficiency.language_uuid,
      };
    }
    return initialValues;
  };

  render() {
    const {
      props: { editingLanguageProficiency, show, onHide },
      getInitialValues,
      handleSubmitLanguageProficienciesForm,
    } = this;

    return (
      <Modal backdrop="static" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingLanguageProficiency ? "Edit" : "Add New"} Language
            Proficiency
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LanguageProficienciesForm
            initialValues={getInitialValues()}
            onHide={onHide}
            onSubmit={handleSubmitLanguageProficienciesForm}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postContractorLanguage: data =>
    dispatch(mutateAsync(postContractorLanguageQuery(data))),
  patchContractorLanguage: (data, uuid) =>
    dispatch(mutateAsync(patchContractorLanguageQuery(data, uuid))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(FormModal);
