import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import { addNotification } from "reapop";
import { useMutation } from "redux-query-react";

import { ExpandableText } from "common/components";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { savedContractorMutations } from "queries/mutations";
import { NotesTooltip } from "./styles";

const Title = styled.h5.attrs({ className: "text-uppercase" })`
  margin-top: 0;
`;
const Toolbar = styled.div`
  margin-top: 10px;
`;
const EditToolbar = styled.div`
  text-align: right;
  button,
  .btn {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const A = ({ onClick, ...otherProps }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick();
  };
  return <a href="edit-notes" onClick={handleClick} {...otherProps} />;
};

const SavedContractorNotes = props => {
  const {
    savedContractor: { uuid, notes = "" },
  } = props;

  const [{ isPending }, updateNotes] = useMutation(newNotes =>
    savedContractorMutations.update(uuid, { notes: newNotes }),
  );
  const dispatch = useDispatch();
  const [editedNotes, setEditedNotes] = useState(notes);
  const [isEditing, setIsEditing] = useState(false);
  const handleCancelClick = e => {
    e.preventDefault();
    setEditedNotes(notes);
    setIsEditing(false);
  };
  const notify = message => dispatch(addNotification(message));
  const submitForm = () => {
    updateNotes(editedNotes).then(response => {
      const { status } = response;
      if (status === 200) {
        notify(
          createSuccessNotification({
            message: "Your changes have been saved.",
          }),
        );
      } else {
        notify(
          createErrorNotification({
            message:
              "There was an error saving your changes. Please try again or refresh the page.",
          }),
        );
      }
      setIsEditing(false);
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    submitForm();
  };
  const handleTextareaKeyPress = e => {
    if (e.key === "Enter" && e.getModifierState("Control")) {
      submitForm();
    }
  };
  const toolbar = (
    <Toolbar>
      {isEditing && (
        <EditToolbar>
          <Button bsStyle="link" bsSize="sm" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button
            bsStyle="success"
            bsSize="sm"
            disabled={isPending}
            type="submit"
          >
            {isPending ? "Saving..." : "Save"}
          </Button>
        </EditToolbar>
      )}
      {!isEditing && (
        <A onClick={() => setIsEditing(prevIsEditing => !prevIsEditing)}>
          {notes ? "Edit" : "+ Add"} Notes
        </A>
      )}
    </Toolbar>
  );

  return (
    <div>
      <Title>Notes</Title>
      <form onSubmit={handleSubmit}>
        {isEditing && (
          <FormGroup controlId="notesInput">
            <FormControl
              componentClass="textarea"
              placeholder="Notes"
              disabled={isPending}
              value={editedNotes || ""}
              rows={5}
              onChange={e => setEditedNotes(e.target.value)}
              onKeyPress={handleTextareaKeyPress}
            />
            <NotesTooltip>
              Notes are only visible to you and other members of your hiring
              agency.
            </NotesTooltip>
          </FormGroup>
        )}
        {!isEditing && (
          <ExpandableText
            text={notes}
            maxWords={50}
            style={{ marginBottom: 0 }}
          />
        )}
        <div>{toolbar}</div>
      </form>
    </div>
  );
};

export default SavedContractorNotes;
