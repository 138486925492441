import React from "react";
import { Link } from "react-router-dom";

import Next from "./blocks";
import { basePath } from "contractor/components/Onboarding/values";

export const finished = {
  approved: {
    label: "APPROVED",
    label_color: "success",
    text: (
      <Next.Text>
        You are now verified and have full access to the Hire an Esquire
        platform. You can search our job board, apply to jobs, message
        employers, and accept job offers. Your next project is right around the
        corner!
      </Next.Text>
    ),
  },
};

export const optional = {
  optional: {
    label: "OPTIONAL",
    label_color: "neutral",
    text: (
      <Next.Text>
        <Link to="/contractor/settings/direct-deposit">
          Set up or update your direct deposit information
        </Link>
        <br />
        <Link to="/contractor/settings/job-notifications">
          Set up your email preferences
        </Link>
        <br />
        <Link to="/contractor/profile/edit/conflicts">
          Submit any conflicts
        </Link>
      </Next.Text>
    ),
  },
};

export const currentSteps = {
  contact_information: {
    label: "GET STARTED",
    label_color: "neutral",
    text: (
      <Next.Text>
        Please click &quot;<Next.BoldText>Complete Your Account</Next.BoldText>&quot;
        below to submit your account information and gain full access to our
        network.
      </Next.Text>
    ),
  },
  email_address_confirmation: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/email-address-confirmation`}>Address</Link>.
      </Next.Text>
    ),
  },
  bar_admissions: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/bar-admissions`}>Bar Documentation</Link>.
      </Next.Text>
    ),
  },
  education: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/education`}>Education</Link>.
      </Next.Text>
    ),
  },
  work_experience: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/work-experience`}>Work Experience</Link>.
      </Next.Text>
    ),
  },
  references: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/references`}>References</Link>.
      </Next.Text>
    ),
  },
  address: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/address`}>Address</Link>.
      </Next.Text>
    ),
  },
  practice_areas: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/practice-areas`}>Practice Areas</Link>.
      </Next.Text>
    ),
  },
  additional_details: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/additional-details`}>Additional Details</Link>.
      </Next.Text>
    ),
  },
  profile_picture: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/profile-picture`}>Profile Picture</Link>.
      </Next.Text>
    ),
  },
  language_proficiencies: {
    label: "IN PROGRESS",
    label_color: "neutral",
    text: (
      <Next.Text>
        Finish setting up your account to gain full access. Go back to{" "}
        <Link to={`${basePath}/language-proficiencies`}>Languages</Link>.
      </Next.Text>
    ),
  },
};

export const pendingSteps = {
  background_screened: {
    label: "PENDING",
    label_color: "warning",
    text: (
      <Next.Text>
        We are running a background check on your credentials.
      </Next.Text>
    ),
  },
  bar_admissions: {
    label: "PENDING",
    label_color: "warning",
    text: (
      <Next.Text>
        We are running a check on your bar admission entries.
      </Next.Text>
    ),
  },
  education: {
    label: "PENDING",
    label_color: "warning",
    text: (
      <Next.Text>
        We are running a background check on your education entries.
      </Next.Text>
    ),
  },
  references: {
    label: "PENDING",
    label_color: "warning",
    text: (
      <Next.Text>
        We&apos;re just waiting for your references to submit their reference
        forms. You&apos;ve done all you can for this stage. We&apos;ll notify
        you when your status changes, but you can check your status by going to
        the <Link to={`${basePath}/references`}>References</Link> page.
      </Next.Text>
    ),
  },
};

export const stepsWithActionRequired = {
  bar_admissions: {
    label: "ACTION REQUIRED",
    label_color: "danger",
    text: (
      <Next.Text>
        Your bar documentation has been rejected. Go back to{" "}
        <Link to={`${basePath}/bar-admissions`}>Bar Documentation</Link>.
      </Next.Text>
    ),
  },
  education: {
    label: "ACTION REQUIRED",
    label_color: "danger",
    text: (
      <Next.Text>
        Your education verification has been denied. Go back to{" "}
        <Link to={`${basePath}/education`}>Education</Link>.
      </Next.Text>
    ),
  },
  references: {
    label: "ACTION REQUIRED",
    label_color: "danger",
    text: (
      <Next.Text>
        One or more of your references has either expired or been denied. Go
        back to <Link to={`${basePath}/references`}>References</Link>.
      </Next.Text>
    ),
  },
};
