import React, { Component } from "react";
import { compose, connect, connectRequest } from "queries/utils";

import {
  createResourceListQuery,
  createStructuredSelector,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";
import Qualifications from "./blocks";

const requiredFilter = item => item.preference === "Required";
const preferredFilter = item => item.preference !== "Required";
const hasRequirementFilter = item => item.requirement;
const missingRequirementFilter = item => !item.requirement;
const sortYesResponsesFirst = (a, b) => (a.response && !b.response ? -1 : 1);

const QualificationResponse = ({ response, muted = false }) => (
  <Qualifications.Item>
    {response.response ? (
      <Qualifications.IconSuccessCheck />
    ) : (
      <Qualifications.IconDangerTimes />
    )}
    <Qualifications.Qualification muted={muted}>
      <p>{response.question_text}</p>
    </Qualifications.Qualification>
  </Qualifications.Item>
);
const UnansweredQualificationRequirement = ({ requirement }) => (
  <Qualifications.Item>
    <Qualifications.IconEmptyCircle />
    <Qualifications.Qualification>
      <p>{requirement.question}</p>
      <Qualifications.Qualification.HelpText>
        This candidate applied before this qualification was added to the job
      </Qualifications.Qualification.HelpText>
    </Qualifications.Qualification>
  </Qualifications.Item>
);

class QualificationsSection extends Component {
  requiredResources = [types.JOB_REQUIREMENTS, types.REQUIREMENT_RESPONSES];
  get isFinished() {
    return this.requiredResources.every(
      resource => this.props[resource].isFinished,
    );
  }
  get isPending() {
    return this.requiredResources.some(
      resource => this.props[resource].isPending,
    );
  }
  get requirements() {
    if (!this.isFinished || this.isPending) return [];
    const {
      [types.JOB_REQUIREMENTS]: {
        data: requirementsData,
        query: { data: requirementsQueryData },
      },
    } = this.props;
    return requirementsQueryData.map(
      responseId => requirementsData[responseId],
    );
  }
  get responses() {
    if (!this.isFinished || this.isPending) return [];
    const {
      [types.REQUIREMENT_RESPONSES]: {
        data: responsesData,
        query: { data: responsesQueryData },
      },
    } = this.props;
    return responsesQueryData.map(responseId => responsesData[responseId]);
  }
  requirementHasResponse(requirement) {
    const { responses } = this;
    return responses
      .map(response => response.requirement)
      .includes(requirement.uuid);
  }
  render() {
    const { isFinished, requirements, responses } = this;
    if (!isFinished) return null;
    if (responses.concat(requirements).length === 0) return null;
    const requiredResponses = responses.filter(requiredFilter);
    const preferredResponses = responses.filter(preferredFilter);
    const requiredRequirements = requirements.filter(requiredFilter);
    const preferredRequirements = requirements.filter(preferredFilter);

    // These responses should be displayed normally
    const requiredResponsesWithRequirement = requiredResponses.filter(
      hasRequirementFilter,
    );
    const preferredResponsesWithRequirement = preferredResponses.filter(
      hasRequirementFilter,
    );

    // These responses don't have an associated requirement, so they belong in the "Removed" section
    const responsesWithoutRequirement = responses.filter(
      missingRequirementFilter,
    );

    // These requirements don't have a response, so they should be marked as "not answered" in gray
    const requiredRequirementsWithoutResponse = requiredRequirements.filter(
      requirement => !this.requirementHasResponse(requirement),
    );
    const preferredRequirementsWithoutResponse = preferredRequirements.filter(
      requirement => !this.requirementHasResponse(requirement),
    );

    const requiredSectionCount = requiredResponsesWithRequirement.concat(
      requiredRequirementsWithoutResponse,
    ).length;
    const preferredSectionCount = preferredResponsesWithRequirement.concat(
      preferredRequirementsWithoutResponse,
    ).length;

    return (
      <Qualifications id="candidate-qualifications">
        <h1>Qualifications</h1>
        <Qualifications.Container>
          {requiredSectionCount > 0 && (
            <Qualifications.Section>
              <h3 className="text-uppercase">REQUIRED</h3>
              {requiredResponsesWithRequirement
                .sort(sortYesResponsesFirst)
                .map((response, key) => (
                  <QualificationResponse response={response} key={key} />
                ))}
              {requiredRequirementsWithoutResponse.map((requirement, key) => (
                <UnansweredQualificationRequirement
                  requirement={requirement}
                  key={key}
                />
              ))}
            </Qualifications.Section>
          )}
          {preferredSectionCount > 0 && (
            <Qualifications.Section>
              <h3 className="text-uppercase">PREFERRED</h3>
              {preferredResponsesWithRequirement
                .sort(sortYesResponsesFirst)
                .map((response, key) => (
                  <QualificationResponse response={response} key={key} />
                ))}
              {preferredRequirementsWithoutResponse.map((requirement, key) => (
                <UnansweredQualificationRequirement
                  requirement={requirement}
                  key={key}
                />
              ))}
            </Qualifications.Section>
          )}
          {responsesWithoutRequirement.length > 0 && (
            <Qualifications.Section>
              <h3 className="text-uppercase">Removed qualifications</h3>
              <p>
                These qualifications were removed from the job after this
                candidate applied. The candidate&apos;s responses are displayed
                here for historical purposes.
              </p>
              {responsesWithoutRequirement
                .sort(sortYesResponsesFirst)
                .map((response, key) => (
                  <QualificationResponse response={response} key={key} muted />
                ))}
            </Qualifications.Section>
          )}
        </Qualifications.Container>
      </Qualifications>
    );
  }
}

const requirementsQuery = props =>
  createResourceListQuery(types.JOB_REQUIREMENTS, {
    url: `/api/v2/requirements/?joblisting__job=${
      props.candidate.job
    }&limit=999`,
  });
const requirementResponsesQuery = props =>
  createResourceListQuery(types.REQUIREMENT_RESPONSES, {
    url: `/api/v2/requirement_responses/?application__candidate=${
      props.candidate.uuid
    }&limit=999`,
  });
const requirementsConfig = createResourceSelectorConfig(
  types.JOB_REQUIREMENTS,
  requirementsQuery,
);
const requirementResponsesConfig = createResourceSelectorConfig(
  types.REQUIREMENT_RESPONSES,
  requirementResponsesQuery,
);
const mapPropsToConfig = props => [
  requirementsQuery(props),
  requirementResponsesQuery(props),
];
const mapStateToProps = createStructuredSelector({
  ...requirementsConfig,
  ...requirementResponsesConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(QualificationsSection);
