import React from "react";

import { Authentication } from "common/components/auth";

const HiringAgentRequired = props => (
  <Authentication
    authenticationRequired
    agreedToSRequired
    emailConfirmedRequired
    onAuthPath={window.location.pathname}
    redirectTo="/login"
    requiredUserType="hiring_agent"
    {...props}
  />
);

export default HiringAgentRequired;
