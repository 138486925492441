import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useMutation } from "redux-query-react";
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
} from "react-bootstrap";
import { addNotification } from "reapop";

import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { savedContractorMutations } from "queries/mutations";
import { SavedContractorIcon, TalentPoolMultiSelect } from ".";
import { talentPoolButtonStyles, NotesTooltip } from "./styles";

const SaveToTalentPoolsButton = styled(Button)`
  ${talentPoolButtonStyles};
`;
const ButtonTitle = styled.h4.attrs({ className: "text-uppercase" })`
  margin: 0;
`;

const CreateSavedContractorButton = ({ contractorId, onSubmitSuccess }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notes, setNotes] = useState("");
  const [talentPools, setTalentPools] = useState([]);
  const [errors, setErrors] = useState({});
  const [
    { isPending: isSubmitting },
    submitCreateSavedContractor,
  ] = useMutation(() =>
    savedContractorMutations.create({
      notes,
      talentpool_set: talentPools,
      contractor: contractorId,
    }),
  );

  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);
  const notify = message => dispatch(addNotification(message));
  const clearError = field => {
    delete errors[field];
    setErrors(errors);
  };

  const validate = () => {
    let valid = true;
    if (talentPools.length < 1) {
      valid = false;
      setErrors({
        ...errors,
        talentpool_set: "You must select at least one talent pool.",
      });
    }
    return valid;
  };

  const submitForm = () => {
    const isValid = validate();
    if (!isValid) {
      notify(
        createErrorNotification({
          message: "Please fix the errors and try again.",
        }),
      );
      return;
    }
    submitCreateSavedContractor().then(response => {
      if (response.status === 201) {
        hideModal();
        onSubmitSuccess();
        notify(
          createSuccessNotification({
            message: "Your talent pools have been updated.",
          }),
        );
      } else {
        // TODO: What errors need to be handled here?
        notify(
          createErrorNotification({
            message:
              "There was an error saving your changes. Please try again or refresh the page.",
          }),
        );
      }
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    submitForm();
  };

  const modal = (
    <Modal show={isModalOpen} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Talent Pools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <FormGroup
            controlId="notesInput"
            validationState={errors.notes && "error"}
          >
            <ControlLabel>Notes</ControlLabel>
            <FormControl
              componentClass="textarea"
              placeholder="Notes"
              disabled={isSubmitting}
              value={notes}
              rows={5}
              onChange={e => {
                setNotes(e.target.value);
                clearError("notes");
              }}
            />
            {errors.notes && <HelpBlock>{errors.notes}</HelpBlock>}
            <NotesTooltip>
              Notes are only visible to you and other members of your hiring
              agency.
            </NotesTooltip>
          </FormGroup>
          <FormGroup
            controlId="talentPoolInput"
            validationState={errors.talentpool_set && "error"}
          >
            <ControlLabel>Talent Pools</ControlLabel>
            <TalentPoolMultiSelect
              value={talentPools}
              onChange={value => {
                setTalentPools(value);
                clearError("talentpool_set");
              }}
            />
            {errors.talentpool_set && (
              <HelpBlock>{errors.talentpool_set}</HelpBlock>
            )}
          </FormGroup>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} bsStyle="link">
          <ButtonTitle>Close</ButtonTitle>
        </Button>
        <Button
          onClick={submitForm}
          bsStyle="success"
          type="submit"
          disabled={isSubmitting}
        >
          <ButtonTitle>{isSubmitting ? "Saving..." : "Save"}</ButtonTitle>
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return (
    <>
      <SaveToTalentPoolsButton block onClick={showModal}>
        <SavedContractorIcon />&nbsp;Save to Talent Pools
      </SaveToTalentPoolsButton>
      {modal}
    </>
  );
};

export default CreateSavedContractorButton;
