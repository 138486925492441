import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { requestAsync, mutateAsync } from "redux-query";
import { connectRequest } from "redux-query-react";
import { Col, Row } from "react-bootstrap";
import { reset, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import { createTimeEntry } from "resources/actions/timeTracker";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { TimeEntriesList } from "contractor/components/time-tracker";
import {
  TimeTrackerInfoMsg,
  CannotAddEntriesMsg,
} from "contractor/components/time-tracker/Messages";
import { getCannotAddEntryReason } from "contractor/components/utils/timeTracker";
import TimeEntryForm, { formName } from "contractor/forms/TimeEntryForm";
import DeleteModal from "contractor/components/time-tracker/DeleteModal";
import Loading from "common/components/Loading";

import {
  createResourceListQuery,
  createResourceSelectorConfig,
  deleteResourceQuery,
} from "queries";
import types from "resources/types";

const TimeEntriesMessage = ({ canAddEntry, workerIsActive, jobIsClosed }) => {
  const reason = getCannotAddEntryReason(workerIsActive, jobIsClosed);
  return canAddEntry ? (
    <TimeTrackerInfoMsg />
  ) : (
    <CannotAddEntriesMsg reason={reason} />
  );
};

class TimeEntries extends Component {
  state = {
    deletingEntryId: null,
  };

  handleCloseModalClick = () => this.setState({ deletingEntryId: null });

  handleDeleteClick = entryId => this.setState({ deletingEntryId: entryId });

  handleDeleteConfirmClick = () => {
    const { notify, deleteEntry } = this.props;
    deleteEntry(this.state.deletingEntryId).then(({ status }) => {
      this.setState({ deletingEntryId: null });
      if (status === 204) {
        notify(
          createSuccessNotification({
            message: "Time entry has been deleted.",
          }),
        );
      } else {
        notify(createErrorNotification());
      }
    });
  };

  handleSubmit = values => {
    const { resetForm, notify, onSubmit, refreshEntries } = this.props;
    const valuesForServer = this.getValuesForServer(values);
    return onSubmit(valuesForServer).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      refreshEntries(this.props);
      notify(
        createSuccessNotification({ message: "Time entry has been saved." }),
      );
      resetForm();
    });
  };

  getValuesForServer = values => {
    const hrs = values.hours || 0;
    const mins = values.minutes || 0;
    const totalTimeInMinutes = hrs * 60 + mins;
    /*eslint-disable */
    const { hours, ...otherValues } = values;
    /*eslint-enable */
    return { ...otherValues, minutes: totalTimeInMinutes };
  };

  render() {
    const {
      worker,
      worker: { active: workerIsActive },
      job,
      job: { title, closed_date },
      [types.PAYMENT_DETAILS]: {
        data: paymentDetails,
        query: {
          data: [paymentDetailsQuery],
        },
      },
      [types.TIME_ENTRIES]: {
        isPending: timeEntriesPending,
        data: timeEntries,
        query: { data: timeEntriesQueryData },
      },
    } = this.props;

    const timeEntriesForJob = timeEntriesQueryData.map(id => timeEntries[id]);
    const canAddEntry = workerIsActive && !closed_date;
    const paymentDetail = paymentDetails[paymentDetailsQuery];

    return !paymentDetail ? null : (
      <div className="time-entries">
        <h1>
          Track Time<span className="small">{` for ${title}`}</span>
        </h1>
        <TimeEntriesMessage
          workerIsActive={workerIsActive}
          jobIsClosed={closed_date}
          canAddEntry={canAddEntry}
        />
        <Row>
          {canAddEntry && (
            <Col sm={4}>
              <h3>Add Time Entry</h3>
              <TimeEntryForm
                initialValues={{ worker: worker.uuid }}
                onSubmit={this.handleSubmit}
                billingIncrement={paymentDetail.billing_increment_minutes}
              />
            </Col>
          )}
          <Col sm={canAddEntry ? 7 : 12} smOffset={canAddEntry ? 1 : 0}>
            {timeEntriesPending ? (
              <Loading />
            ) : (
              <TimeEntriesList
                timeEntries={timeEntriesForJob}
                jobId={job.uuid}
                onDeleteClick={this.handleDeleteClick}
              />
            )}
          </Col>
        </Row>
        <DeleteModal
          type={"time entry"}
          show={Boolean(this.state.deletingEntryId)}
          onSubmit={this.handleDeleteConfirmClick}
          onClose={this.handleCloseModalClick}
        />
      </div>
    );
  }
}

const paymentDetailQuery = props =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/?job=${props.job.uuid}`,
  });

const timeEntriesQuery = props =>
  createResourceListQuery(types.TIME_ENTRIES, {
    url: `/api/dev/time_entries/?worker__job=${props.job.uuid}&limit=9999`,
    force: true,
  });

const mapPropsToConfig = props => [
  paymentDetailQuery(props),
  timeEntriesQuery(props),
];

const paymentDetailConfig = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailQuery,
);
const timeEntriesConfig = createResourceSelectorConfig(
  types.TIME_ENTRIES,
  timeEntriesQuery,
);

const mapStateToProps = createStructuredSelector({
  ...paymentDetailConfig,
  ...timeEntriesConfig,
});

const deleteEntryQuery = id =>
  deleteResourceQuery(
    types.TIME_ENTRIES,
    {
      url: `/api/dev/time_entries/${id}/`,
      options: {
        method: "DELETE",
      },
    },
    id,
  );

TimeEntries = compose(
  connectRequest(mapPropsToConfig),
  connect(
    mapStateToProps,
    {
      notify,
      onSubmit: createTimeEntry,
      deleteEntry: id => mutateAsync(deleteEntryQuery(id)),
      refreshEntries: props => requestAsync(timeEntriesQuery(props)),
      resetForm: () => reset(formName),
    },
  ),
)(TimeEntries);

export default TimeEntries;
