// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  dropdownOpen: boolean,
};

const DropdownContent: ComponentType<Props> = styled.div`
  z-index: 2;
  display: none;
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.colors.nav_dropdown()};
  width: 100%;
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.shadow()};

  & a {
    color: ${({ theme }) => theme.colors.primary_black()};
    padding: 12px 14px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
  }

  & a:hover {
    color: ${({ theme }) => theme.colors.primary_black()};
    text-decoration: none;
    font-weight: 900;
  }

  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      display: block;
    `};
`;

export default DropdownContent;
