import styled from "styled-components";

export const BackButton = styled.h5`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  grid-area: back;
`;

const PreviewTitle = styled.h1`
  grid-area: title;
  margin-top: 10px;
  ${({ theme }) => theme.media.sm`
    margin-top: 0;
  `};
`;

export const StatusDropdownContainer = styled.span`
  grid-area: status;
`;

const JobsDropdownTitle = styled.h5`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
`;

const PreviewHeader = styled.div`
  display: grid;
  grid: auto auto / auto;
  grid-template-areas:
    "title"
    "status";
  margin-bottom: 30px;

  ${({ theme }) => theme.media.sm`
    grid: auto / auto 300px;
    grid-template-areas:
      "title status";
    grid-column-gap: 1rem;
    margin-top: 20px;
    margin-bottom: 15px;
  `};
`;

PreviewHeader.JobsDropdownTitle = JobsDropdownTitle;
PreviewHeader.PreviewTitle = PreviewTitle;
export default PreviewHeader;
