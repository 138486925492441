import React from "react";
import { Col, Row } from "react-bootstrap";

import NullOr from "common/components/NullOr";
import { JobAttachmentsList } from "common/components/jobs";

const jobFilesResponsibilityText = `These files are related to this job. It is your professional responsibility
    to keep these files secret and secure, and delete them from your local computer
    as soon as you are done using them.`;

const JobAttachments = ({ jobId, isWorker }) => (
  <NullOr isNull={!isWorker}>
    <div className="main-info-container">
      <h2 className="main-info-title">Files</h2>
      <div style={{ marginBottom: "2.5rem" }}>
        <Row>
          <Col mdOffset={2} md={8}>
            <div className="text-center">{jobFilesResponsibilityText}</div>
          </Col>
        </Row>
      </div>
      <JobAttachmentsList jobId={jobId} showCreated />
    </div>
  </NullOr>
);

export default JobAttachments;
