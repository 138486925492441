import { createResourceListQuery } from "queries";
import types from "resources/types";

export const barInformationsQueryWithConfig = (config = {}) =>
  createResourceListQuery(types.BAR_INFORMATIONS, {
    url: "/api/v1/bar_informations/?limit=999",
    ...config,
  });

export const barVerificationsQueryWithConfig = (config = {}) =>
  createResourceListQuery(types.BARCARD_VERIFICATIONS, {
    url: "/api/v1/barcard_verifications/?limit=999",
    ...config,
  });
