import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import moment from "moment";

import {
  DatePickerField,
  HoursPerWeekSelectField,
  ProjectLengthSelectField,
} from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";
import { FormRow, withFormError } from "./utils";

const requiredFields = ["start_date", "project_length", "hours_per_week"];

const validate = values => validateRequiredFields(requiredFields, values);

class TimelineForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormRow>
          <DatePickerField
            name="start_date"
            label="Estimated start date"
            minDate={moment().toDate()}
          />
          <p>This locates contractors available for the duration of the job.</p>
        </FormRow>
        <FormRow>
          <ProjectLengthSelectField />
          <Fragment />
        </FormRow>
        <FormRow>
          <HoursPerWeekSelectField />
          <Fragment />
        </FormRow>
      </form>
    );
  }
}
TimelineForm = compose(
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(TimelineForm);

export default class Timeline extends Component {
  handleSubmit = values => {
    const {
      formProps: { onSubmit },
    } = this.props;
    const {
      project_length,
      start_date,
      hours_per_week,
      ...otherValues
    } = values;
    return onSubmit({
      hours_per_week: hours_per_week != 0 ? hours_per_week : null,
      project_length: project_length >= 0 ? project_length : null,
      start_date: start_date || null,
      ...otherValues,
    });
  };

  render() {
    const {
      formProps,
      jobListing: { project_length, start_date, hours_per_week },
    } = this.props;
    const initialValues = { project_length, start_date, hours_per_week };

    return (
      <div>
        <h2>What is the duration of this project?</h2>
        <TimelineForm
          {...formProps}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
        />
      </div>
    );
  }
}
