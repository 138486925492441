// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  side: string,
  isDragging: boolean,
};

const ItemOuter: ComponentType<Props> = styled.p`
  margin: 10px 0 0 0;
  padding: 0;
  position: absolute;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  ${({ side }) =>
    css`
      ${side}: 0;
      transform: ${side === "left" ? "translateX(-20px)" : "translateX(20px)"};
    `};
  ${({ isDragging }) =>
    isDragging &&
    css`
      display: none;
    `};
`;

export default ItemOuter;
