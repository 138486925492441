import styled from "styled-components";

const CollapseContent = styled.div`
  margin: 0;
  padding: 0 15px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: ${({ theme }) => theme.colors.container_bg()};
  border-radius: 0 0 8px 8px;
`;

export default CollapseContent;
