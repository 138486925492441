import React, { Component } from "react";
import { connect } from "react-redux";
import { requestContractor } from "hiringagent-dashboard/actions/attorneys";
import { attorneysSliceSelector } from "hiringagent-dashboard/selectors";
import Loading from "common/components/Loading";

export const _withContractorsIfNeeded = (
  contractorIds = [],
) => WrappedComponent => {
  class _withContractorsIfNeeded extends Component {
    componentDidMount() {
      contractorIds.forEach(contractorId => {
        this.props.requestContractor(contractorId);
      });
    }

    render() {
      const {
        attorneys: { contractors },
      } = this.props;
      const allRecipientsAreLoaded = contractorIds.every(
        contractorId => contractors[contractorId],
      );
      const neededContractors = contractorIds.map(
        recipientId => contractors[recipientId],
      );

      return allRecipientsAreLoaded ? (
        <WrappedComponent
          requiredContractors={neededContractors}
          {...this.props}
        />
      ) : (
        <Loading />
      );
    }
  }

  _withContractorsIfNeeded = connect(
    attorneysSliceSelector,
    { requestContractor },
  )(_withContractorsIfNeeded);
  return _withContractorsIfNeeded;
};

export const withContractorsIfNeeded = mapPropsToIds => WrappedComponent => {
  class withContractorsIfNeeded extends Component {
    constructor(props) {
      super(props);
      this.state = {
        contractorIds: null,
        ConnectedComponent: null,
      };
    }

    componentWillMount() {
      const contractorIds = mapPropsToIds(this.props);
      const newState = {
        contractorIds,
        ConnectedComponent: contractorIds
          ? _withContractorsIfNeeded(contractorIds)(WrappedComponent)
          : _withContractorsIfNeeded()(WrappedComponent),
      };
      this.setState(newState);
    }

    componentWillReceiveProps(nextProps) {
      const { contractorIds } = this.state;
      const newContractorIds = mapPropsToIds(nextProps);
      if (newContractorIds && newContractorIds !== contractorIds) {
        this.setState({
          contractorIds: newContractorIds,
          ConnectedComponent: _withContractorsIfNeeded(newContractorIds)(
            WrappedComponent,
          ),
        });
      }
    }

    render() {
      const { ConnectedComponent } = this.state;
      return ConnectedComponent ? (
        <ConnectedComponent {...this.props} />
      ) : (
        <Loading />
      );
    }
  }
  return withContractorsIfNeeded;
};
export default withContractorsIfNeeded;
