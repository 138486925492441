import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { oldApi } from "common/api";

import { metaGenerator } from "common/actions";
import { action } from "common/actions/utils";

const actions = keyMirror({
  RESET_APPLICATIONS: null,
  REQUEST_APPLICATIONS: null,
  RECEIVE_APPLICATIONS: null,
  REQUEST_ATTORNEY: null,
  RECEIVE_ATTORNEY: null,
  SET_APPLICATIONS_STATUS_FILTER: null,
  SET_APPLICATIONS_JOB_FILTER: null,
  SET_APPLICATIONS_SORTING: null,
  TOGGLE_APPLICATION: null,
  TOGGLE_ALL_APPLICATIONS: null,
  "REQUEST/change_application_status": null,
  "RECEIVE/change_application_status": null,
});

export default actions;

export const resetApplications = createAction(actions.RESET_APPLICATIONS);

export const requestApplications = ({
  count = 999,
  jobId = null,
  status = null,
} = {}) => (dispatch, getState) => {
  let url = `/api/dev/applications/?limit=${count}`;
  if (jobId) url = `${url}&job=${jobId}`;
  if (status !== null) url = `${url}&status=${status}`;
  const { allLoaded } = getState().hiringAgentDashboard.applications;
  if (!allLoaded) {
    dispatch(createAction(actions.REQUEST_APPLICATIONS)());
    return oldApi.get(url).then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error getting applications.");
      payload.allFetched = count === 999 && jobId === null && status === null;
      dispatch(
        createAction(actions.RECEIVE_APPLICATIONS, p => p, metaGenerator)(
          payload,
        ),
      );
      return { json, response };
    });
  }
  return null;
};

export const updateApplication = (applicationId, data) =>
  action({
    type: "change_application_status",
    path: `applications/${applicationId}/`,
    version: "dev",
    method: "patch",
    data,
  });

export const setStatusFilter = filterName =>
  createAction(actions.SET_APPLICATIONS_STATUS_FILTER)({ filterName });
export const setApplicationsJobFilter = jobFilter =>
  createAction(actions.SET_APPLICATIONS_JOB_FILTER)(jobFilter);
export const setSortingBy = createAction(actions.SET_APPLICATIONS_SORTING);
export const toggleApplication = createAction(actions.TOGGLE_APPLICATION);
export const toggleAllApplications = createAction(
  actions.TOGGLE_ALL_APPLICATIONS,
);
