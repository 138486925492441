// @flow strict
import styled from "styled-components";

import RadioOverlay from "./RadioOverlay";

const Radio = styled.label`
  position: relative;

  ${({ theme }) => theme.media.mobile`
    width: 100%;
    height: 100%;
  `};

  ${({ theme }) => theme.media.desktop`
    position: relative;
    width: 75%;
    max-width: 100px;

    &:last-child > ${RadioOverlay}:after {
      display: none;
    }
  `};
`;

export default Radio;
