import * as invoiceActions from "admin/actions/invoicing/invoices";

const initialInvoicesState = {
  isFetching: false,
  isGenerating: false,
  lastUpdated: null,
  items: {},
};

export default function dates(state = initialInvoicesState, action) {
  switch (action.type) {
    case invoiceActions.REQUEST_OPEN_INVOICES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case invoiceActions.RECEIVE_OPEN_INVOICES:
      return Object.assign({}, state, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        items: action.items,
      });
    case invoiceActions.GENERATE_INVOICE:
      return Object.assign({}, state, {
        isGenerating: true,
      });
    case invoiceActions.GENERATE_INVOICE_COMPLETE:
      return Object.assign({}, state, {
        isGenerating: false,
      });
    default:
      return state;
  }
}
