import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { action, submitFile } from "common/actions/utils";

const actions = keyMirror({
  CLEAR_STASHED_PROFILE_PICTURE: null,
  ADD_TRANSCRIPT: null,
  REMOVE_TRANSCRIPT: null,
});
export default actions;

export const submitContractorProfile = (contractorId, data) =>
  action({
    type: "ownContractor",
    path: `contractors/${contractorId}`,
    version: "dev",
    method: "patch",
    data,
  });
export const submitCreateConflict = data =>
  action({
    type: "createConflict",
    path: "conflicts",
    method: "post",
    data,
  });
export const submitDeleteConflict = conflictId =>
  action({
    type: "deleteConflict",
    path: `conflicts/${conflictId}`,
    method: "delete",
  });
export const submitUpdateConflict = (conflictId, data) =>
  action({
    type: "updateConflict",
    path: `conflicts/${conflictId}`,
    method: "patch",
    data,
  });
export const submitCreateLanguage = data =>
  action({
    type: "createLanguage",
    path: "contractor_languages",
    method: "post",
    data,
  });
export const submitDeleteLanguage = languageId =>
  action({
    type: "deleteLanguage",
    path: `contractor_languages/${languageId}`,
    method: "delete",
  });
export const submitUpdateLanguage = (languageId, data) =>
  action({
    type: "updateLanguage",
    path: `contractor_languages/${languageId}`,
    method: "patch",
    data,
  });
export const submitCreateSchool = data =>
  action({
    type: "createSchool",
    path: "schools",
    method: "post",
    data,
  });
export const submitDeleteSchool = schoolId =>
  action({
    type: "deleteSchool",
    path: `schools/${schoolId}`,
    method: "delete",
  });
export const submitUpdateSchool = (schoolId, data) =>
  action({
    type: "updateSchool",
    path: `schools/${schoolId}`,
    method: "patch",
    data,
  });
export const submitCreateExperience = data =>
  action({
    type: "createExperience",
    path: "previous_employments",
    method: "post",
    data,
  });
export const submitDeleteExperience = experienceId =>
  action({
    type: "deleteExperience",
    path: `previous_employments/${experienceId}`,
    method: "delete",
  });
export const submitUpdateExperience = (experienceId, data) =>
  action({
    type: "updateExperience",
    path: `previous_employments/${experienceId}`,
    method: "patch",
    data,
  });

export const addTranscript = createAction(actions.ADD_TRANSCRIPT);
export const removeTranscript = createAction(actions.REMOVE_TRANSCRIPT);
export const submitAddProfilePicture = data =>
  action({
    type: "addProfilePicture",
    path: "contractors/add_profile_picture",
    version: "dev",
    method: "post",
    data,
  });
export const submitStashProfilePicture = submitFile([
  "REQUEST/stashProfilePicture",
  "RECEIVE/stashProfilePicture",
]);
export const clearStashedProfilePicture = createAction(
  actions.CLEAR_STASHED_PROFILE_PICTURE,
);

export const submitCreateBarLicense = data =>
  action({
    type: "createBarLicense",
    path: "bar_informations",
    version: "v1",
    method: "post",
    data,
  });
