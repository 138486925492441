import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { createApiRequest } from "common/api/api";
import { createErrorCallback, metaGenerator } from "common/actions";
import { requestNotifications } from "hiringagent-dashboard/actions/notifications";
import { resubmitSearchQuery } from "hiringagent-dashboard/actions/search";

import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_AGENCY: null,
  RECEIVE_AGENCY: null,
  OPTIMISTIC_UPDATE_ONBOARDING: null,
});
export default actions;

export const changeAgencyOnboardingState = status => (dispatch, getState) => {
  dispatch(createAction(actions.OPTIMISTIC_UPDATE_ONBOARDING)(status));
  const { uuid } = getState().hiringAgentDashboard.agency.agency;
  return oldApi
    .patch(`/api/v2/hiring_agencies/${uuid}/`, { onboarding_state: status })
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error updating agency profile");
      dispatch(
        createAction(actions.RECEIVE_AGENCY, p => p, metaGenerator)(payload),
      );
      return { json, response };
    });
};

export const requestAgency = () => (dispatch, getState) => {
  dispatch(createAction(actions.REQUEST_AGENCY)());
  return createApiRequest("GET")("/api/v2/hiring_agencies/self/").then(
    pollUntilVerified(dispatch, getState),
    createErrorCallback(dispatch, actions.RECEIVE_AGENCY),
  );
};

export const requestAgencyIfNeeded = () => (dispatch, getState) => {
  const { isLoading, lastUpdated } = getState().hiringAgentDashboard.agency;
  if (!isLoading && !lastUpdated) {
    return dispatch(requestAgency());
  }
  return null;
};

const pollUntilVerified = (dispatch, getState) => json => {
  if (json.verified === "Waiting") {
    setTimeout(() => {
      dispatch(requestAgency());
    }, 20000);
  }
  if (newlyVerified(getState, json)) {
    dispatch(requestNotifications());
  }

  dispatch(createAction(actions.RECEIVE_AGENCY, p => p, metaGenerator)(json));

  if (json.verified === "Verified") {
    const { lastUpdated, isLoading } = getState().hiringAgentDashboard.search;
    if (
      (lastUpdated || isLoading) &&
      window.location.pathname === "/agency/attorneys/"
    ) {
      dispatch(resubmitSearchQuery());
    }
  }
};

const newlyVerified = (getState, response) => {
  const previousVerification = getState().hiringAgentDashboard.agency.agency
    .verified;
  return previousVerification === "Waiting" && response.verified === "Verified";
};
