import configureStore from "./configureStore";
import types from "resources/types";

const createInitialEntityState = () => ({
  data: {},
  queries: {},
  metadata: {
    count: -1,
    ordering: [],
  },
});

const initialState = {
  entities: Object.values(types).reduce(
    (res, val) => ({
      ...res,
      [val]: createInitialEntityState(),
    }),
    {},
  ),
};

export const initStore = () => configureStore(initialState);
