// @flow strict
import React, { Component } from "react";
import { history as browserHistory } from "app";
import { Alert, Col, Row } from "react-bootstrap";

import { createResourceSelector } from "queries";
import { compose, connect, connectRequest, mutateAsync } from "queries/utils";
import { acceptTermsOfServiceQuery } from "queries/mutations/auth";
import { authUserQuery } from "queries/requests/auth";
import types from "resources/types";
import AcceptTermsOfServiceForm from "common/components/forms/AcceptTermsOfServiceForm";
import TextContainer from "./TextContainer";

const ScrollFrom = ({ onClick }) => (
  <Row>
    <Col lgOffset={2} lg={8}>
      <Alert>
        Please agree to the Terms of Service{" "}
        <a href="#" onClick={onClick}>
          below
        </a>.
      </Alert>
    </Col>
  </Row>
);

const ScrollTo = ({ agreedToLatestTOS, error, onSubmit }) => (
  <Row>
    <Col lgOffset={2} lg={8}>
      <div id="accept-tos-form">
        <AcceptTermsOfServiceForm
          agreedToLatestTOS={agreedToLatestTOS}
          onSubmit={onSubmit}
        />
      </div>
      {error && (
        <Alert bsStyle="danger">
          We&apos;re sorry, there was a problem submitting your request. Please
          refresh this page and try again.
        </Alert>
      )}
    </Col>
  </Row>
);

type Props = {
  onCheckedSubmit: string => Promise<QueryResponse<{}>>,
  tosObj: TermsOfService,
  tosByUserType: string,
  userType: UserType,
};

type State = {
  error: boolean,
};

type Values = {
  agrees_to_tos: boolean,
};

class TOSAccept extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  componentDidMount() {
    const { userType } = this.props;
    if (userType === "admin" || userType === "anonymous") {
      this.handleRedirect("/terms-of-service");
    }
  }

  handleClickToScroll = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const acceptToSForm = document.getElementById("accept-tos-form");
    if (acceptToSForm) {
      acceptToSForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleSubmit = async (values: Values) => {
    if (values.agrees_to_tos === true) {
      const now = new Date().toISOString();
      const response = await this.props.onCheckedSubmit(now);
      const isSuccessful = response ? response.status === 200 : false;
      this.setState({ error: !isSuccessful });
      if (isSuccessful) this.handleRedirect("/");
    }
  };

  handleRedirect = (pathname: string) => browserHistory.replace(pathname);

  render() {
    const {
      tosByUserType,
      [types.AUTH]: { data: authData, isFinished: authFinished },
    } = this.props;
    const { error } = this.state;
    let agreedToLatestTOS;
    if (authFinished) {
      const authDataObj = authData[Object.keys(authData)[0]];
      agreedToLatestTOS = authDataObj.agreed_to_latest_tos;
    }

    return (
      <div>
        <ScrollFrom onClick={this.handleClickToScroll} />
        <TextContainer termsMarkdown={tosByUserType} />
        <ScrollTo
          agreedToLatestTOS={agreedToLatestTOS}
          error={error}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapPropsToConfig = authUserQuery;
const mapStatetoProps = createResourceSelector(types.AUTH, mapPropsToConfig);
const mapDispatchToProps = {
  onCheckedSubmit: now => mutateAsync(acceptTermsOfServiceQuery(now)),
};

export default compose(
  connect(
    mapStatetoProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(TOSAccept);
