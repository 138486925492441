// @flow strict
import React from "react";
import styled from "styled-components";

import RadioOverlay from "./RadioOverlay";
import RadioCaption from "./RadioCaption";
import RadioField from "./RadioField";
import Radio from "./Radio";

type Props = {};

const RadioGroupBlock = styled.div`
  justify-self: center;
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  text-align: center;

  ${({ theme, numOptions }) => theme.media.mobile`
    grid-template-rows: repeat(${numOptions}, 40px);
    grid-gap: 10px;
  `};

  ${({ theme, numOptions }) => theme.media.desktop`
    grid-template-columns: repeat(${numOptions}, 1fr);
    direction: rtl;
  `};
`;

const RadioGroup = (props: Props) => <RadioGroupBlock {...props} />;

RadioGroup.RadioOverlay = RadioOverlay;
RadioGroup.RadioCaption = RadioCaption;
RadioGroup.RadioField = RadioField;
RadioGroup.Radio = Radio;

export default RadioGroup;
