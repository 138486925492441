import { post } from "common/api/oldApi";

export const trackPageView = ({ pathname, search, hash }) => {
  post("/api/v2/user_activities/", {
    activity: 3,
    data: {
      pathname,
      search,
      hash,
    },
  });
};
