// @flow
import { type Uuid } from "./primitives";
import moment from "moment";

export default class Job {
  uuid: Uuid;
  start_date: ?string;
  filled_date: ?string;
  closed_date: ?string;
  title: ?string;
  committed_worker_count: number;
  payment_method: number;
  status: number;
  joblisting: Uuid;
  paymentdetails: Uuid;
  latest_invoice_task_status: ?boolean;
  candidate_counts: {
    hired: number,
    negotiating: number,
    pending: number,
    rejected: number,
    total: number,
  };
  created: ?string;
  updated: ?string;

  constructor(job: Object) {
    this.uuid = job.uuid;
    this.start_date = job.start_date;
    this.filled_date = job.filled_date;
    this.closed_date = job.closed_date;
    this.title = job.title;
    this.committed_worker_count = job.committed_worker_count;
    this.payment_method = job.payment_method;
    this.status = job.status;
    this.joblisting = job.joblisting;
    this.paymentdetails = job.paymentdetails;
    this.latest_invoice_task_status = job.latest_invoice_task_status;
    this.candidate_counts = job.candidate_counts;
    this.created = job.created;
    this.updated = job.updated;
  }

  static PAYMENT_METHODS = {
    WIRE: 0,
    STRIPE: 1,
  };

  static STATUS = {
    DRAFT: 0,
    OPEN_FOR_APPLICATIONS: 1,
    FILLED: 2,
    CLOSED: 3,
  };

  static STATUS_OPTIONS = [
    { label: "Draft", value: Job.STATUS.DRAFT },
    { label: "Open for applications", value: Job.STATUS.OPEN_FOR_APPLICATIONS },
    { label: "Filled", value: Job.STATUS.FILLED },
    { label: "Closed", value: Job.STATUS.CLOSED },
  ];

  get isOpenForApplications(): boolean {
    return this.status === Job.STATUS.OPEN_FOR_APPLICATIONS;
  }

  get isClosed(): boolean {
    return this.status === Job.STATUS.CLOSED;
  }

  get formattedTitle(): string {
    const { created, title } = this;
    if (title != null) return title;
    return `<Draft job created on ${moment(created).format("MM-DD-YYYY")}>`;
  }

  get statusDisplay(): string {
    return Job.STATUS_OPTIONS[this.status].label;
  }
}
