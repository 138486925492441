import actions from "../actions/hiringAgenciesDropdown";
import { handleActions } from "redux-actions";

const initialState = {
  hiringAgencies: {},
  hiringAgenciesLoading: false,
};

const jobsDropdown = handleActions(
  {
    [actions.REQUEST_HIRING_AGENCIES]: state => ({
      ...state,
      hiringAgenciesLoading: true,
    }),
    [actions.RECEIVE_HIRING_AGENCIES]: (state, action) => ({
      ...state,
      ...action.payload,
      hiringAgenciesLoading: false,
    }),
  },
  initialState,
);
export default jobsDropdown;
