import React from "react";
import { Col, Row } from "react-bootstrap";

import Edit from "./blocks";

const NoBankInfo = ({ onClick }) => (
  <Row>
    <Col xs={12}>
      <Edit.Container>
        <Edit.GreyText>
          You don&apos;t have a bank account on file.
        </Edit.GreyText>
        <Edit.GreyText>
          Click &quot;Add a Bank Account&quot; to add direct deposit
          information.
        </Edit.GreyText>
        <Edit.Container.Footer>
          <Edit.BigButtonAsLink onClick={onClick}>
            + ADD A BANK ACCOUNT
          </Edit.BigButtonAsLink>
        </Edit.Container.Footer>
      </Edit.Container>
    </Col>
  </Row>
);

export default NoBankInfo;
