import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AccountDeactivated } from "common/components/auth";
import AttorneySignup from "public/containers/signup/AttorneySignup";
import DeclineReviewRequest from "public/containers/DeclineReviewRequest";
import LegalProfSelect from "public/components/signup/LegalProfSelect";
import NonAttorneySignup from "public/containers/signup/NonAttorneySignup";
import requireAuthToken from "public/components/auth/requireAuthToken";
import Review from "public/containers/Review";
import UserSelect from "public/components/signup/UserSelect";
import LogIn from "public/components/LogIn";
import LogOut from "public/components/LogOut";
import SignupComplete from "public/components/signup/SignupComplete";
import {
  TermsOfService,
  TOSByUserType,
  TOSAccept,
  TOSGeneral,
  TOSContractor,
  TOSAgency,
} from "public/components/TermsOfService/exports";
import ForgotPassword from "public/components/password/ForgotPassword";
import { PrivacyPolicy } from "public/components/privacy-policy";
import ResetPassword from "public/components/password/ResetPassword";
import { Base } from "public/components/layout";
import { PublicJob, PublicJobSearch } from "public/components/jobs";
import {
  PublicContractorProfile,
  SharedContractorProfile,
} from "public/components/profiles";
import NotPermitted from "common/components/NotPermitted";
import ApplicationDenied from "common/components/ApplicationDenied";
import ConfirmEmail from "common/components/ConfirmEmail";
import AuthenticationDetour from "common/components/auth/AuthenticationDetour";
import NotFound from "common/components/NotFound";

const BaseWithSearchLink = props => <Base showSearchLink={true} {...props} />;
const BaseWithoutSignupButton = props => (
  <Base empty={true} showSignupButton={false} {...props} />
);
const SignupContainer = props => (
  <div className="signup-container">{props.children}</div>
);
const NotFoundRoute = () => (
  <Base showSignupButton={false}>
    <NotFound />
  </Base>
);

const wrapSignupContainer = Component => props => (
  <BaseWithoutSignupButton>
    <SignupContainer>
      <Component {...props} />
    </SignupContainer>
  </BaseWithoutSignupButton>
);

const wrapTOS = Component => props => (
  <TermsOfService>
    <Component {...props} />
  </TermsOfService>
);

const TOSRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} render={wrapTOS(TOSByUserType)} />
    <Route path={`${match.url}/accept`} component={wrapTOS(TOSAccept)} />
    <Route path={`${match.url}/general`} component={wrapTOS(TOSGeneral)} />
    <Route
      path={`${match.url}/contractor`}
      component={wrapTOS(TOSContractor)}
    />
    <Route path={`${match.url}/agency`} component={wrapTOS(TOSAgency)} />
  </Switch>
);

const PublicRoutes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/jobs`}
      render={({ match: jobsMatch }) => (
        <BaseWithSearchLink>
          <Switch>
            <Route exact path={jobsMatch.url} component={PublicJobSearch} />
            <Route path={`${jobsMatch.url}/:id`} component={PublicJob} />
          </Switch>
        </BaseWithSearchLink>
      )}
    />
    <Route
      render={({ match: innerMatch }) => (
        <Base>
          <Switch>
            <Redirect
              exact
              from={`${innerMatch.url}/contractors`}
              to="/signup"
            />
            <Redirect
              exact
              from={`${innerMatch.url}/terms_of_service`}
              to="/terms-of-service"
            />
            <Redirect
              exact
              from={`${innerMatch.url}/terms-of-service`}
              to="/terms-of-service"
            />
            <Route
              path={`${innerMatch.url}/contractors/:contractorId`}
              component={PublicContractorProfile}
            />
            <Route
              path={`${innerMatch.url}/attorney/profile/:id`}
              component={requireAuthToken(SharedContractorProfile)}
            />
          </Switch>
        </Base>
      )}
    />
    <Route component={NotFoundRoute} />
  </Switch>
);

export default () => (
  <Switch>
    <Route
      path="/signup/legal_professional"
      component={wrapSignupContainer(LegalProfSelect)}
    />
    <Route path="/signup" component={wrapSignupContainer(UserSelect)} />
    <Route
      path="/attorney/signup"
      component={wrapSignupContainer(AttorneySignup)}
    />
    <Route
      path="/non-attorney/signup"
      component={wrapSignupContainer(NonAttorneySignup)}
    />
    <Route
      path="/attorney/signup_complete"
      component={wrapSignupContainer(SignupComplete)}
    />
    <Route
      path="/signup_complete"
      component={wrapSignupContainer(SignupComplete)}
    />
    <Route path="/public" component={PublicRoutes} />

    <Route
      render={() => (
        <BaseWithoutSignupButton>
          <Switch>
            <Route
              path="/attorney/profile/review/complete/:id"
              component={requireAuthToken(Review)}
            />
            <Route
              path="/attorney/profile/review/decline/:id"
              component={requireAuthToken(DeclineReviewRequest)}
            />
            <Redirect from="/attorney/*" to="/contractor" />
            <Redirect from="/login/forgot" to="/forgot-password" />
            <Route path="/login" component={LogIn} />
            <Route path="/logout" component={LogOut} />
            <Route path="/not-permitted" component={NotPermitted} />
            <Route path="/application-denied" component={ApplicationDenied} />
            <Redirect
              from="/uc/account_deactivated"
              to="/account-deactivated"
            />
            <Route path="/account-deactivated" component={AccountDeactivated} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Redirect from="/terms_of_service" to="/terms-of-service" />
            <Route path="/terms-of-service" component={TOSRoutes} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              path="/reset-password/:uid/:token"
              component={ResetPassword}
            />
            <Route
              path="/confirm-email/:code"
              render={props => (
                <AuthenticationDetour {...props}>
                  <ConfirmEmail {...props} />
                </AuthenticationDetour>
              )}
            />
            <Route component={NotFound} />
          </Switch>
        </BaseWithoutSignupButton>
      )}
    />
  </Switch>
);
