import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert, Col, Label, Panel, Row } from "react-bootstrap";
import moment from "moment";
import Markdown from "markdown-to-jsx";

import {
  StyledPanel,
  TextStyles,
  StyledTime,
  ThreadMainWrapper,
} from "../blocks";

const ThreadItem = ({
  authUser,
  threadItem: {
    attachments,
    body_highlight: body,
    created,
    sender,
    may_include_personal_info,
  },
}) => {
  const userIsSender = authUser.haeuser_id === sender.uuid;

  return (
    <StyledPanel
      bsStyle={userIsSender ? "primary" : "default"}
      side={userIsSender ? "right" : "left"}
    >
      <Panel.Heading>
        <TextStyles>
          <Row>
            <Col xs={6}>{`${sender.first_name} ${sender.last_name}`}</Col>
            <Col xs={6}>
              <StyledTime>
                {moment(created).format("MM/DD/YY, h:mm a")}
              </StyledTime>
            </Col>
          </Row>
        </TextStyles>
      </Panel.Heading>
      <Panel.Body>
        <TextStyles>
          <Markdown>{body.replace("> <", ">&nbsp;<")}</Markdown>
        </TextStyles>
        {may_include_personal_info && (
          <Alert bsStyle="warning" style={{ margin: "15px 0 0 0" }}>
            It looks like an email address or phone number is in this message. A
            friendly reminder that hiring or invoicing outside of the platform
            is a violation of our{" "}
            <Link to="/terms-of-service#non-circumvention">TOS</Link>.
          </Alert>
        )}
      </Panel.Body>
      {attachments.length > 0 && (
        <Panel.Footer>
          {attachments.map(attachment => (
            <a
              href={attachment.single_use_url}
              download
              key={attachment.uuid}
              style={{
                textDecoration: "none",
                margin: "3px 3px 3px 0",
                display: "inline-block",
              }}
            >
              <Label bsStyle="primary">
                <i className="fas fa-paperclip" /> {attachment.file_name}
              </Label>
            </a>
          ))}
        </Panel.Footer>
      )}
    </StyledPanel>
  );
};

export default class ThreadMain extends Component {
  componentDidMount() {
    this.props.onScrollToBottom();
  }

  render() {
    const {
      authUser,
      thread: { messages: threadMain },
    } = this.props;

    return (
      <ThreadMainWrapper id="messaging-thread">
        {threadMain
          .sort((a, b) => moment(a.created) - moment(b.created))
          .map(threadItem => (
            <ThreadItem
              authUser={authUser}
              key={threadItem.uuid}
              threadItem={threadItem}
            />
          ))}
      </ThreadMainWrapper>
    );
  }
}
