import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = props => (
  <Modal className="confirm-modal" show={props.show} onHide={props.close}>
    <Modal.Header closeButton={props.closeButton}>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.body}</Modal.Body>
    {props.showFooter && (
      <Modal.Footer>
        <Button className="pull-left" bsStyle="link" onClick={props.close}>
          Cancel
        </Button>
        {props.actions}
      </Modal.Footer>
    )}
  </Modal>
);
ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.node.isRequired,
  actions: PropTypes.node,
  closeButton: PropTypes.bool,
  showFooter: PropTypes.bool,
};
ConfirmModal.defaultProps = {
  title: "Confirm",
  actions: null,
  closeButton: false,
  showFooter: true,
};
export default ConfirmModal;
