import styled from "styled-components";

const PercentText = styled.text.attrs({
  dominantBaseline: "middle",
  textAnchor: "middle",
})`
  font-size: 45px;
  font-weight: 700;

  ${({ theme }) => theme.media.mobile`
    font-size: 36px;
  `};
`;

export default PercentText;
