import styled, { css } from "styled-components";

const Qualification = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  justify-self: center;
  align-self: center;

  p {
    margin: 0;
  }

  ${props =>
    props.muted &&
    css`
      color: ${({ theme }) => theme.colors.primary_black40()};
    `};
`;

const HelpText = styled.p`
  && {
    color: ${({ theme }) => theme.colors.primary_black40()};
  }
`;

Qualification.HelpText = HelpText;
export default Qualification;
