import React, { Component, Fragment } from "react";
import { Table } from "react-bootstrap";
import { history as browserHistory } from "app";
import styled from "styled-components";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";

import { compose, connect, connectRequest } from "queries/utils";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";
import { clientFeedbackSurveyQuery } from "queries/requests/clientFeedback";
import { jobWorkersQuery } from "queries/requests/jobWorkers";
import { contractorsQuery } from "queries/requests/contractors";
import { jobsQuery } from "queries/requests/jobs";
import { flattenObject } from "common/utils";
import Loading from "common/components/Loading";

const Tr = styled.tr`
  cursor: pointer;
`;

const Th = styled.th`
  text-align: center;
`;

const Td = styled.td`
  text-align: center;
`;

class FeedbackList extends Component {
  handleRedirect = uuid => {
    browserHistory.push(`/agency/feedback/${uuid}`);
  };

  render() {
    const { handleRedirect } = this;
    const { clientFeedbackSurvey: feedback, jobs } = this.props;

    if (
      !feedback.isFinished ||
      feedback.isPending ||
      !jobs.isFinished ||
      jobs.isPending
    ) {
      return <Loading />;
    }

    // 1 = Sent, 2 = Reminded
    const feedbackList = Object.values(feedback.data).filter(
      ({ status }) => status === 1 || status === 2,
    );

    return (
      <div>
        <h1>Provide Feedback</h1>
        {feedbackList.length ? (
          <Fragment>
            <p>
              Your feedback helps us maintain a standard of high quality matches
              on Hire an Esquire. Click on a row in order to answer a few
              questions regarding any of the following contractors you have
              worked with:
            </p>
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Job</Th>
                </tr>
              </thead>
              <tbody>
                {feedbackList.map((feedbackItem, key) => {
                  const { full_name, job } = flattenObject(feedbackItem);
                  return (
                    <Tr
                      key={key}
                      onClick={() => handleRedirect(feedbackItem.uuid)}
                    >
                      <Td>{full_name}</Td>
                      <Td>{jobs.data[job].title}</Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </Fragment>
        ) : (
          <Fragment>
            <p>
              We appreciate your feedback! None is requested at this time. Check
              back here after completing projects to give feedback on specific
              contractors that you have worked with.
            </p>
            <Link to="/agency" className="btn btn-info">
              <i className="far fa-arrow-left" />&nbsp;Back to Dashboard
            </Link>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapPropsToConfig = () => [
  clientFeedbackSurveyQuery({ force: true }),
  jobWorkersQuery(),
  contractorsQuery(),
  jobsQuery(),
];

const clientFeedbackSurveySelectorConfig = createResourceSelectorConfig(
  types.CLIENT_FEEDBACK_SURVEY,
  clientFeedbackSurveyQuery,
);

const jobWorkersSelectorConfig = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkersQuery,
);

const contractorsSelectorConfig = createResourceSelectorConfig(
  types.CONTRACTORS,
  contractorsQuery,
);

const jobsSelectorConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);

const mapStateToProps = createStructuredSelector({
  ...clientFeedbackSurveySelectorConfig,
  ...jobWorkersSelectorConfig,
  ...contractorsSelectorConfig,
  ...jobsSelectorConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(FeedbackList);
