import React from "react";

import { Pagination } from "common/components";
import Applicants from "./blocks";

const BasePagination = props => (
  <Pagination hideFirstAndLastPageLinks {...props} />
);

const DesktopPagination = props => (
  <BasePagination bsSize="lg" className="hidden-xs" {...props} />
);

const MobilePagination = props => (
  <BasePagination hideEllipsis className="visible-xs-inline-block" {...props} />
);

export default props => {
  const { limit, pageNumber, onChange, numResults } = props;
  const numPages = Math.ceil(numResults / limit);
  if (numPages < 1) return null;
  let currentPage = pageNumber;
  // Ensure currentPage <= numPages (if not, react-ultimate-pagination will throw an error)
  if (currentPage > numPages) currentPage = numPages;
  return (
    <div>
      <Applicants.PaginationContainer>
        <DesktopPagination
          currentPage={currentPage}
          totalPages={numPages}
          onChange={onChange}
        />
        <MobilePagination
          currentPage={currentPage}
          totalPages={numPages}
          onChange={onChange}
        />
      </Applicants.PaginationContainer>
    </div>
  );
};
