import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { DropdownButton, MenuItem, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { formatUSD } from "common/utils/helpers";
import Loading from "common/components/Loading";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { createApiUrl } from "utils";

const MAX_INVOICES_TO_RETRIEVE = 10;

const invoiceBreakdowns = {
  basic: "Overview",
  bundled: "By Contractor",
  "bundled-monthly": "By Contractor (monthly)",
  detailed: "By Individual Entry",
};

const InvoiceStatus = ({ invoice }) => {
  let status = <span className="text-danger">Awaiting your approval!</span>;
  if (invoice.paid_in_full) {
    status = <span className="text-success">Paid in full</span>;
  } else if (invoice.is_overdue) {
    status = <span className="text-danger">Overdue</span>;
  } else if (invoice.is_disputed) {
    status = <span className="text-danger">Disputed</span>;
  } else if (invoice.approved_date) {
    status = <span className="text-info">Approved, awaiting payment</span>;
  }

  return status;
};

const JobInvoices = props => {
  const {
    jobId,
    invoices: {
      data: invoices,
      query: { count, data: ordering },
      isFinished,
      isPending,
    },
  } = props;

  const jobInvoiceIds = ordering
    .filter(invoiceId => invoices[invoiceId].job === jobId)
    .splice(0, MAX_INVOICES_TO_RETRIEVE);

  return (
    <div className="main-info-container">
      <div>
        <h2 className="main-info-title" style={{ display: "inline-block" }}>
          Invoices
        </h2>
        {isFinished &&
          count > 0 && (
            <div className="pull-right">
              <DropdownButton
                title="View Invoices"
                id="invoice-action-dropdown"
                bsStyle="link"
              >
                {Object.keys(invoiceBreakdowns).map((breakdown, i) => (
                  <MenuItem
                    key={i}
                    bsStyle="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={createApiUrl(
                      `/agency/jobs/${jobId}/invoices/?breakdown=${breakdown}`,
                    )}
                  >
                    {invoiceBreakdowns[breakdown]}
                  </MenuItem>
                ))}
              </DropdownButton>
            </div>
          )}
      </div>
      {isFinished && count === 0 ? (
        <h4 className="no-content-message">No Invoices</h4>
      ) : (
        <div>
          <Table striped>
            <tbody>
              {jobInvoiceIds
                .map(invoiceId => invoices[invoiceId])
                .map(invoice => (
                  <tr className="job-detail-invoice-row" key={invoice.uuid}>
                    <td className="">
                      <span>
                        <Link to={`/agency/invoices/${invoice.uuid}`}>
                          {`Invoice #${invoice.invoice_number}`}
                        </Link>
                      </span>
                      <span>
                        {`${moment(invoice.start_date).format("MMM D, YYYY")}
                                             - ${moment(
                                               invoice.end_date,
                                             ).format("MMM D, YYYY")}`}
                      </span>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span>{formatUSD(invoice.total)}</span>
                      <InvoiceStatus invoice={invoice} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {count > MAX_INVOICES_TO_RETRIEVE && (
            <div>
              <p className="text-center">
                Showing {MAX_INVOICES_TO_RETRIEVE} out of {count} invoices.
              </p>
              <p className="text-center">
                <Link to="/agency/invoices">Click here</Link> to view all
                invoices.
              </p>
            </div>
          )}
        </div>
      )}
      {isPending && <Loading />}
    </div>
  );
};

const mapPropsToConfig = props =>
  createResourceListQuery(types.INVOICES, {
    url: `/api/dev/invoices/?job=${props.jobId}&limit=10&order_by=-created`,
  });

const mapStateToProps = createResourceSelector(
  types.INVOICES,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobInvoices);
