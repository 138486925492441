import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifiedSelector } from "hiringagent-dashboard/selectors";
import colors from "hiringagent-dashboard/constants/colors";

const ColoredCircle = ({ size, children, uuid }) => {
  const color = colors[Math.floor(uuid.charCodeAt(0) % colors.length)];
  const sizeInPx = `${size}px`;
  const circleStyles = {
    backgroundColor: color,
    width: sizeInPx,
    height: sizeInPx,
  };
  return (
    <span className="attorney-no-profile-pic" style={circleStyles}>
      {children}
    </span>
  );
};

const AttorneyInitialsIcon = ({ firstInitial, lastInitial, size, uuid }) => {
  const profileInitialsSize = `${size / 2.5}px`;
  const initialsStyle = {
    fontSize: profileInitialsSize,
    lineHeight: profileInitialsSize,
  };
  return (
    <ColoredCircle {...{ size, uuid }}>
      <span className="profile-initials" style={initialsStyle}>
        {firstInitial}
        {lastInitial}
      </span>
    </ColoredCircle>
  );
};

export const AttorneyAvatar = ({ size, uuid }) => {
  const avatarSize = `${size / 24}rem`;
  const avatarStyle = {
    fontSize: avatarSize,
    lineHeight: avatarSize,
  };
  return (
    <ColoredCircle {...{ size, uuid }}>
      <span className="profile-initials" style={avatarStyle}>
        <i className="far fa-user" style={{ color: "#FFF" }} />
      </span>
    </ColoredCircle>
  );
};

export const ProfilePicture = ({ pictureUrl, size = 120 }) => {
  const sizeInPx = `${size}px`;
  return (
    <div className="media-object attorney-profile-picture-wrapper">
      <img
        alt="attorney profile"
        src={pictureUrl}
        className="attorney-profile-picture"
        style={
          size && {
            width: sizeInPx,
            height: sizeInPx,
          }
        }
      />
    </div>
  );
};

export class AttorneyProfilePicture extends Component {
  static propTypes = {
    pictureUrl: PropTypes.string,
    size: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    showPicture: PropTypes.bool,
    uuid: PropTypes.string.isRequired,
  };

  static defaultProps = {
    showPicture: false,
    pictureUrl: null,
    size: 120,
  };

  render() {
    const {
      firstName,
      lastName,
      pictureUrl,
      size,
      verified,
      showPicture,
      uuid,
    } = this.props;
    const displayPicture = verified || showPicture;

    return displayPicture ? (
      pictureUrl ? (
        <ProfilePicture {...{ pictureUrl, size }} />
      ) : (
        <AttorneyInitialsIcon
          firstInitial={firstName[0]}
          lastInitial={lastName[0]}
          size={size}
          uuid={uuid}
        />
      )
    ) : (
      <AttorneyAvatar size={size} uuid={uuid} />
    );
  }
}

export default connect(state => ({ verified: verifiedSelector(state) }))(
  AttorneyProfilePicture,
);
