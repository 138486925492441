import React, { Component } from "react";
import { Button, Label, Table } from "react-bootstrap";
import moment from "moment";
import { getIsInCurrentWeek } from "common/utils";
import { recentFirst } from "contractor/components/utils/timeTracker";

class ExpensesList extends Component {
  handleDeleteClick = expenseId => {
    this.props.onDeleteClick(expenseId);
  };

  render() {
    const { expenses } = this.props;
    const entriesCount = Object.keys(expenses).length;

    return (
      <div className="expenses-list">
        <Table responsive>
          <tbody>
            {entriesCount > 0 &&
              Object.values(expenses)
                .sort(recentFirst)
                .map(expense => (
                  <tr key={expense.uuid}>
                    <td style={{ whiteSpace: "pre" }}>
                      <div>{moment(expense.date).format("MM/DD/YY")}</div>
                    </td>
                    <td>
                      <Label bsStyle="info" style={{ fontSize: "1.4rem" }}>
                        {`$${expense.amount}`}
                      </Label>
                    </td>
                    <td style={{ whiteSpace: "pre" }}>
                      <a
                        href={expense.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Attachment
                      </a>
                    </td>
                    <td style={{ width: "100rem" }}>{expense.description}</td>
                    <td>
                      {getIsInCurrentWeek(expense.date) && (
                        <Button
                          onClick={() => this.handleDeleteClick(expense.uuid)}
                          bsStyle="danger"
                          bsSize="small"
                        >
                          <i className="fas fa-trash-alt" />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
        {entriesCount === 0 && (
          <div className="no-content-message">No Expenses</div>
        )}
      </div>
    );
  }
}

export default ExpensesList;
