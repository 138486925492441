import React, { Component } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { Row, Button, Col, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import keyMirror from "keymirror";
import { withAgency } from "hiringagent-dashboard/connectors";

import ActionButton from "hiringagent-dashboard/components/ActionButton";
import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";
import CreateHiringAgentForm from "hiringagent-dashboard/forms/CreateHiringAgentForm";
import {
  requestHiringAgents,
  setFocusedAgent,
  submitAgencyAdminAction,
  submitHiringAgentForm,
  toggleModal,
} from "hiringagent-dashboard/actions/hiringAgents";

const modals = keyMirror({
  ADD_HIRING_AGENT: null,
  DEACTIVATE: null,
  REACTIVATE: null,
  RECONFIRM: null,
});

const AddHiringAgentModal = props => (
  <ConfirmModal
    {...props}
    title="Add a Hiring Agent"
    showFooter={false}
    body={<CreateHiringAgentForm {...props} />}
  />
);

const modalText = (action, agent) => {
  let text = null;
  if (action === modals.DEACTIVATE) {
    text = `${agent} will no longer be able to administer this account. ${agent} will not be
                notified of this change.`;
  } else if (action === modals.REACTIVATE) {
    text = `${agent} will be able to manage jobs, applicants, and billing
                on behalf of this agency. ${agent} will not be notified of this change.`;
  } else if (action === modals.RECONFIRM) {
    text = `Once reconfirmed, ${agent} will be informed via email that they have been added
                as a hiring agent to this agency and prompted to create (or reset) their password.`;
  }

  return (
    <span>
      Are you sure you want to {action.toLowerCase()} <strong>{agent}</strong>?{" "}
      {text}
    </span>
  );
};

const editButton = hiringAgentId => (
  <LinkContainer
    to={{ pathname: `/agency/settings/agent/${hiringAgentId}/edit/` }}
  >
    <Button bsStyle="info" bsSize="sm" className="btn btn-success">
      Edit
    </Button>
  </LinkContainer>
);

export class HiringAgentsSettings extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const {
      requestHiringAgents,
      submitHiringAgentForm,
      toggleModal,
    } = this.props;

    return submitHiringAgentForm(values).then(({ json, response }) => {
      if (!response.ok) {
        throw new SubmissionError(json);
      } else {
        requestHiringAgents();
        toggleModal(null);
        return json;
      }
    });
  }

  render() {
    const {
      hiringAgents: { openModal, hiringAgents, focusedAgent },
      userHiringAgent,
      createActionClickHandler,
      createConfirmActionHandler,
    } = this.props;
    const {
      agency: {
        agency: { name },
      },
    } = this.props;
    const userHiringAgentId = userHiringAgent.uuid;

    const agentIsUser = hiringAgentId => hiringAgentId === userHiringAgentId;

    const sortedHiringAgents = Object.values(hiringAgents)
      .sort((a, b) => {
        if (a.active && !b.active) {
          return -1;
        } else if (!a.active && b.active) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (agentIsUser(a.uuid) && !agentIsUser(b.uuid)) {
          return -1;
        } else if (!agentIsUser(a.uuid) && agentIsUser(b.uuid)) {
          return 1;
        }
        return 0;
      });

    const focusedAgentFullName = focusedAgent
      ? `${focusedAgent.first_name} ${focusedAgent.last_name}`
      : "this hiring agent";

    const focusedAgentId = focusedAgent ? focusedAgent.uuid : null;

    const hiringAgentActionButton = (text, bsStyle, action, hiringAgent) => (
      <ActionButton
        text={text}
        bsStyle={bsStyle}
        bsSize="small"
        onClick={createActionClickHandler(action, hiringAgent)}
      />
    );

    return (
      <Col md={12}>
        <AddHiringAgentModal
          show={openModal === modals.ADD_HIRING_AGENT}
          close={createActionClickHandler()}
          onSubmit={this.handleSubmit}
          actions={null}
          agencyName={name}
        />

        <ConfirmModal
          show={openModal === modals.DEACTIVATE}
          close={createActionClickHandler()}
          title="Deactivate Agent"
          body={modalText(modals.DEACTIVATE, focusedAgentFullName)}
          actions={
            <ActionButton
              text="Deactivate Agent"
              bsStyle="danger"
              onClick={createConfirmActionHandler(focusedAgentId, "deactivate")}
            />
          }
        />

        <ConfirmModal
          show={openModal === modals.REACTIVATE}
          close={createActionClickHandler()}
          title="Reactivate Agent"
          body={modalText(modals.REACTIVATE, focusedAgentFullName)}
          actions={
            <ActionButton
              text="Reactivate Agent"
              bsStyle="success"
              onClick={createConfirmActionHandler(focusedAgentId, "reactivate")}
            />
          }
        />

        <ConfirmModal
          show={openModal === modals.RECONFIRM}
          close={createActionClickHandler()}
          title="Reconfirm Agent"
          body={modalText(modals.RECONFIRM, focusedAgentFullName)}
          actions={
            <ActionButton
              text="Reconfirm Agent"
              bsStyle="success"
              onClick={createConfirmActionHandler(focusedAgentId, "reconfirm")}
            />
          }
        />

        <Row>
          <div className="settings-buttons-container">
            {userHiringAgent.is_admin && (
              <Button
                bsStyle="success"
                className="pull-right"
                onClick={createActionClickHandler(modals.ADD_HIRING_AGENT)}
              >
                <i className="far fa-plus" /> Add Hiring Agent
              </Button>
            )}
            <span className="clearfix" />
          </div>
          <Table responsive className="agents-settings-table">
            <thead>
              <tr>
                <th style={{ width: "15rem" }}>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th style={{ textAlign: "right" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedHiringAgents.map((hiringAgent, key) => (
                <tr
                  key={`agent-${key}`}
                  className={
                    !hiringAgent.active ? "agent-deactivated" : undefined
                  }
                >
                  <td>{`${hiringAgent.first_name} ${hiringAgent.last_name}`}</td>
                  <td>{hiringAgent.email_address}</td>
                  <td>
                    {hiringAgent.is_admin ? "Admin" : "Agent"}
                    {hiringAgent.confirmed ? "" : " (pending)"}
                  </td>
                  <td>
                    <div className="agent-table-buttons pull-right">
                      {!userHiringAgent.is_admin &&
                        agentIsUser(hiringAgent.uuid) &&
                        editButton(hiringAgent.uuid)}
                      {userHiringAgent.is_admin && (
                        <span>
                          {editButton(hiringAgent.uuid)}
                          {!agentIsUser(hiringAgent.uuid) && (
                            <span>
                              {hiringAgent.active
                                ? hiringAgentActionButton(
                                    "Deactivate",
                                    "danger",
                                    modals.DEACTIVATE,
                                    hiringAgent,
                                  )
                                : hiringAgentActionButton(
                                    "Reactivate",
                                    "success",
                                    modals.REACTIVATE,
                                    hiringAgent,
                                  )}
                              {hiringAgentActionButton(
                                "Reconfirm",
                                "success",
                                modals.RECONFIRM,
                                hiringAgent,
                              )}
                            </span>
                          )}
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </Col>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    createActionClickHandler: (modal = null, hiringAgent = null) => () => {
      dispatch(setFocusedAgent(hiringAgent));
      dispatch(toggleModal(modal));
    },
    createConfirmActionHandler: (agentId, action) => () => {
      dispatch(submitAgencyAdminAction(agentId, action));
    },
    requestHiringAgents: () => dispatch(requestHiringAgents()),
    submitHiringAgentForm: values => dispatch(submitHiringAgentForm(values)),
    toggleModal: modal => dispatch(toggleModal(modal)),
  }),
)(withAgency()(HiringAgentsSettings));
