import types from "resources/types";
import { mutateResourceQuery } from "queries";

export const createReviewRequestQuery = data =>
  mutateResourceQuery(
    types.REVIEW_REQUESTS,
    {
      url: "/api/v2/review_requests/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const deleteReviewRequestQuery = uuid =>
  mutateResourceQuery(types.REVIEW_REQUESTS, {
    url: `/api/v2/review_requests/${uuid}`,
    options: {
      method: "DELETE",
    },
  });

export const resendReviewRequestQuery = uuid =>
  mutateResourceQuery(types.REVIEW_REQUESTS, {
    url: `/api/v2/review_requests/${uuid}/resend`,
    options: {
      method: "POST",
    },
  });
