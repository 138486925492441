import React from "react";
import styled from "styled-components";

import Container from "./Container";

const WelcomeBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Welcome = props => <WelcomeBlock {...props} />;

Welcome.Container = Container;

export default Welcome;
