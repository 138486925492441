// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  open: boolean,
};

const NavItem: ComponentType<Props> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
  margin-right: 20px;

  & > span:first-child {
    height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
    line-height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
    margin-right: 10px;
    padding: 0;
    vertical-align: middle;
    box-sizing: border-box;

    &::after {
      display: block;
      content: attr(name);
      font-weight: 900;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  ${({ open }) =>
    open &&
    css`
      & > span:first-child {
        border-bottom: 2px solid ${({ theme }) => theme.colors.navlink()};
      }
    `};

  & > span:last-child {
    visibility: ${({ badge }) => (badge ? "visible" : "hidden")};
    vertical-align: middle;
    margin: 0;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white()};
    background-color: ${({ theme }) => theme.colors.secondary_red()};
    border-radius: 10px;
  }
`;

export default NavItem;
