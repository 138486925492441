import styled from "styled-components";

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.primary_black40()};
`;

export default Divider;
