// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Heading from "./Heading";
import Header1 from "./Header1";
import Header2 from "./Header2";
import Footing from "./Footing";
import ViewLink from "./ViewLink";

type Props = {
  numFulfilled: number,
  numRequired: number,
};

const QualificationsBlock: ComponentType<Props> = styled.div`
  margin: 0px 0 50px 0;
  padding: 0;

  ${({ theme }) => theme.media.mobile`
    margin: 0px -15px 20px -15px;
    background-color: ${({ numFulfilled, numRequired, theme }) => {
      if (numFulfilled === numRequired) return theme.colors.success("0.2");
      if (numFulfilled === 0) return theme.colors.danger("0.2");
      return theme.colors.primary_black10();
    }};
  `};
`;

const Qualifications = (props: Props) => <QualificationsBlock {...props} />;

Qualifications.Heading = Heading;
Qualifications.Header1 = Header1;
Qualifications.Header2 = Header2;
Qualifications.Footing = Footing;
Qualifications.ViewLink = ViewLink;

export default Qualifications;
