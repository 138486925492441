import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Index extends Component {
  render() {
    return (
      <div>
        <h1>Admin Dashboards</h1>
        <ul>
          <li>
            <Link to="/hae_admin/dashboards/accounting/invoicing">
              Invoice Generator
            </Link>
          </li>
          <li>
            <Link to="/hae_admin/dashboards/applications">
              Applications Dashboard
            </Link>
          </li>
          <li>
            <Link to="/hae_admin/dashboards/contractor-audits">
              Contractor Audits Dashboard
            </Link>
          </li>
          <li>
            <Link to="/hae_admin/dashboards/language-audit">
              Language Audit Dashboard
            </Link>
          </li>
          <li>
            <Link to="/hae_admin/dashboards/previous-employment-tag-manager">
              Previous Employment Tag Manager
            </Link>
          </li>
          <li>
            <Link to="/hae_admin/dashboards/recommendations/stats">
              Recommendation Stats Dashboard
            </Link>
          </li>
          <li>
            <Link to="/hae_admin/dashboards/search-history">
              Search History Dashboard
            </Link>
          </li>
          <li>
            Verification Manager
            <ul>
              <li>
                <Link to="/hae_admin/dashboards/verification-manager/attorneys">
                  Verify Attorneys
                </Link>
              </li>
              <li>
                <Link to="/hae_admin/dashboards/verification-manager/non-attorneys">
                  Verify Non-attorneys
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
