import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";

import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_RECOMMENDATION_STATS: null,
  RECEIVE_RECOMMENDATION_STATS: null,
});

export default actions;

export const requestRecommendationsStats = (
  start_date = "",
  end_date = "",
) => dispatch => {
  dispatch(createAction(actions.REQUEST_RECOMMENDATION_STATS)());
  return oldApi
    .get(
      `/api/v2/recommendationstats/?start_date=${start_date}&end_date=${end_date}`,
    )
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error retrieving recommendation stats.");
      dispatch(
        createAction(
          actions.RECEIVE_RECOMMENDATION_STATS,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};
