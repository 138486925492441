import styled from "styled-components";
import { Navbar } from "react-bootstrap";

const Collapse = styled(Navbar.Collapse)`
  &&&& {
    z-index: 2;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0 0 16px 16px;
    background-color: ${({ theme }) => theme.colors.container_bg()};
  }
`;

export default Collapse;
