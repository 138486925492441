import React from "react";
import { Col, Media } from "react-bootstrap";

export const LegalProfSignupBenefits = () => (
  <div>
    <div className="marketing-blurb-container-mobile visible-xs">
      <Media>
        <Media.Left>
          <i className="far fa-users fa-fw" aria-hidden="true" />
        </Media.Left>
        <Media.Body>Free Membership</Media.Body>
      </Media>
      <Media>
        <Media.Left>
          <i className="fas fa-shield-alt fa-fw" aria-hidden="true" />
        </Media.Left>
        <Media.Body>Work with Benefits</Media.Body>
      </Media>
      <Media>
        <Media.Left>
          <i className="far fa-money-bill-alt fa-fw" aria-hidden="true" />
        </Media.Left>
        <Media.Body>Get Paid Quickly</Media.Body>
      </Media>
    </div>

    <div className="marketing-blurb-container hidden-xs">
      <Col sm={4}>
        <div className="marketing-blurb">
          <div>
            <i className="far fa-fw fa-check" aria-hidden="true" />
            &nbsp; Free Membership
          </div>
          Find the right permanent or
          <br className="visible-lg" /> flexible opportunities for you
        </div>
      </Col>
      <Col sm={4}>
        <div className="marketing-blurb">
          <div>
            <i className="far fa-fw fa-check" aria-hidden="true" />
            &nbsp; Work with Benefits
          </div>
          Get insurance, career
          <br className="visible-lg" /> assistance, and peace of mind
        </div>
      </Col>
      <Col sm={4}>
        <div className="marketing-blurb">
          <div>
            <i className="far fa-fw fa-check" aria-hidden="true" />
            &nbsp; Get Paid Quickly
          </div>
          Enjoy fully transparent pricing
          <br className="visible-lg" /> and weekly payment
        </div>
      </Col>
    </div>
  </div>
);

const LegalProfSignupMarketing = () => (
  <div>
    <div className="signup-form-header">
      <h1 className="signup-form-title">
        We know you&apos;re good.
        <br />
        Let&apos;s put your talent to work.
      </h1>
      <h4 className="signup-form-cta">
        Apply to join our curated community and find law firms and legal
        departments eager to work with you.
      </h4>
    </div>

    <LegalProfSignupBenefits />
  </div>
);

export default LegalProfSignupMarketing;
