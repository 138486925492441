import styled from "styled-components";

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme }) => theme.sizes.top_nav_header_mobile_height};
  margin: 0;
  padding: 0px 15px;

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

export default Main;
