import React, { Component } from "react";
import Dropzone from "react-dropzone";

const FileDropzone = ({ onFileDrop: handleFileUpload }) => (
  <div className="dropzone-container">
    <Dropzone
      className="file-upload-dropzone dropzone"
      activeClassName="active-file-upload-dropzone"
      onDrop={handleFileUpload}
      multiple={false}
      maxSize={15728640}
      style={{
        border: "3px dashed #DDD",
        borderRadius: "4px",
        cursor: "pointer",
        padding: "2rem",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <div className="text-center">
          <i className="fas fa-file-alt" />
          <span className="dropzone-text">
            Drag a file here or click to browse.
          </span>
        </div>
      </div>
    </Dropzone>
  </div>
);

export default class ResumeForm extends Component {
  static defaultProps = {
    formDataKey: "resume-attachment",
    currentResume: "Resume",
  };

  handleSubmitFile = files => {
    const fd = new FormData();
    fd.append(this.props.formDataKey, files[0]);
    this.props.onFileUpload(fd);
  };

  render() {
    const { currentResume } = this.props;
    return (
      <div>
        <FileDropzone onFileDrop={this.handleSubmitFile} />
        {currentResume && <p>Resume on file: {currentResume}</p>}
      </div>
    );
  }
}
