import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { Col, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import WelcomeBox from "./WelcomeBox";
import Messages from "./Messages";
import WorkPanelGroup from "./WorkPanelGroup";
import { ErrorBoundary } from "common/components/error-boundary";
import { createResourceSelectorConfig } from "queries";
import { candidateJobListingsCountQuery } from "queries/requests/jobs";
import { onboardingRequirementsQuery } from "queries/requests/onboardingRequirements";
import types from "resources/types";

class Dashboard extends Component {
  componentDidUpdate() {
    this.redirectIfNecessary();
  }

  redirectIfNecessary = () => {
    const {
      history,
      [types.ONBOARDING_REQUIREMENTS]: { data: onboardingReqsData },
    } = this.props;
    const onboardingReqs = Object.values(onboardingReqsData)[0];
    if (onboardingReqs && onboardingReqs.overall_status === "REJECTED") {
      history.push("/application-denied");
    }
  };

  render() {
    const {
      [types.JOB_LISTINGS]: {
        query: { count: hasAppliedToJobs },
      },
      [types.ONBOARDING_REQUIREMENTS]: { data: onboardingReqsData },
    } = this.props;
    const onboardingReqs = Object.values(onboardingReqsData)[0];
    const canAcceptW2Jobs =
      onboardingReqs &&
      onboardingReqs.permissions &&
      onboardingReqs.permissions.accept_w2_jobs;

    return (
      <div>
        <h1>Dashboard</h1>
        {(!hasAppliedToJobs || !canAcceptW2Jobs) && <WelcomeBox />}
        <Row>
          <Col md={12}>
            <ErrorBoundary>
              <WorkPanelGroup />
            </ErrorBoundary>
          </Col>
        </Row>
        <div>
          <ErrorBoundary>
            <Messages />
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

const candidateJobListingsCountSelectorConfig = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  candidateJobListingsCountQuery,
);

const onboardingRequirementsSelectorConfig = createResourceSelectorConfig(
  types.ONBOARDING_REQUIREMENTS,
  onboardingRequirementsQuery,
);

const mapPropsToConfig = () => [
  candidateJobListingsCountQuery(),
  onboardingRequirementsQuery(),
];

const mapStateToProps = createStructuredSelector({
  ...candidateJobListingsCountSelectorConfig,
  ...onboardingRequirementsSelectorConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(Dashboard);
