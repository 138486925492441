import moment from "moment";

export const getCannotAddEntryReason = (workerIsActive, jobIsClosed) => {
  const closedDate = moment(jobIsClosed, "YYYY-MM-DD").format("MMMM Do YYYY");
  const jobIsClosedMsg = `it closed on ${closedDate}`;
  const notActiveWorkerMsg = "you are not an active worker on this job";

  let reason = false;
  if (jobIsClosed) reason = jobIsClosedMsg;
  if (!workerIsActive) reason = notActiveWorkerMsg;
  if (jobIsClosed && !workerIsActive)
    reason = `${jobIsClosedMsg} and ${notActiveWorkerMsg}`;
  return reason;
};

export const recentFirst = (a, b) => new Date(b.date) - new Date(a.date);
