import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { compose, connect, connectRequest } from "queries/utils";
import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import { getResourcesAreReady } from "common/utils/helpers";
import DiscountCodesTotals from "hiringagent-dashboard/components/settings/DiscountCodesSettings/utils";
import types from "resources/types";
import Loading from "common/components/Loading";

const requiredResources = [types.JOB_LISTINGS];

const WelcomeBox = styled.div`
  max-width: 800px;
  margin: 40px auto 50px auto;
  padding: 15px 15px 30px 15px;
  border: 2px solid ${({ theme }) => theme.colors.primary_black10()};
  border-radius: 15px;
  text-align: center;
  position: relative;

  h2 {
    margin-bottom: 40px;
  }
`;
const WelcomeCTA = styled.div`
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
`;
const WelcomeCTALink = styled(Link).attrs({ className: "btn btn-primary" })`
  &.btn {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2rem;
    min-width: 180px;

    ${({ theme }) => theme.media.sm`
      min-width: 250px;
    `};
  }
`;
WelcomeCTA.Link = WelcomeCTALink;

class Welcome extends Component {
  render() {
    const {
      props: {
        [types.JOB_LISTINGS]: {
          query: { count },
        },
      },
    } = this;
    const isReady = getResourcesAreReady(requiredResources, this.props);

    if (count > 0) return null;

    return isReady ? (
      <WelcomeBox>
        <h1>Welcome to Hire an Esquire!</h1>
        <h2>Post a job to instantly connect with the right candidate.</h2>
        <DiscountCodesTotals showLink />
        <WelcomeCTA>
          <WelcomeCTA.Link to="/agency/jobs/create">Post a job</WelcomeCTA.Link>
        </WelcomeCTA>
      </WelcomeBox>
    ) : (
      <Loading />
    );
  }
}

const jobListingsQuery = () =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: "/api/v2/joblistings/?limit=999",
  });

const jobListingsSelector = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...jobListingsSelector,
});

const mapPropsToConfig = () => [jobListingsQuery()];

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(Welcome);
