import types from "resources/types";
import { mutateResourceQuery } from "queries";

export const createRequirementResponseQuery = data =>
  mutateResourceQuery(
    types.REQUIREMENT_RESPONSES,
    {
      url: "/api/v2/requirement_responses/",
      options: {
        method: "POST",
      },
    },
    data,
  );
