import React from "react";
import Dropzone from "react-dropzone";
import { humanFileSize } from "common/utils/helpers";

import { StyledLabel } from "../blocks";

const ThreadAttachments = ({
  attachments,
  change,
  filesExist,
  fileError,
  onRemoveFile,
  onFileUploadError,
}) => (
  <div className="attachments-section">
    {fileError && (
      <p className="attachments-error-msg">File size must be under 15 MB.</p>
    )}
    <Dropzone
      className="file-upload-dropzone"
      activeClassName="active-file-upload-dropzone"
      onDrop={files => change("files", [...attachments, ...files])}
      onDropRejected={() => onFileUploadError()}
      maxSize={15728640}
    >
      <div style={{ textAlign: "center" }}>
        <i className="fas fa-file-alt fa-5x" style={{ marginBottom: "7px" }} />
        <p>Drag a file here or click to browse</p>
      </div>
    </Dropzone>
    {filesExist && (
      <div>
        {attachments.map((attachment, index) => (
          <StyledLabel key={`message-attachment-${index}`}>
            <span style={{ float: "left" }} className="far fa-paperclip" />
            {`${attachment.name} (${humanFileSize(attachment.size)})`}
            <span
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => onRemoveFile(attachment.name)}
              className="far fa-times danger"
            />
          </StyledLabel>
        ))}
      </div>
    )}
  </div>
);

export default ThreadAttachments;
