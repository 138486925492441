import React from "react";
import styled from "styled-components";

const padding = "15px";

export const ContainerHeader = styled.span`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;

  & div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const ContainerBody = styled.div``;

const ContainerFooterButton = styled.button`
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  background: none;
  outline: none;
  border: none;
`;

const ContainerFooterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 -15px -15px -15px;
  padding: 10px;
  border-top: 2px solid ${({ theme }) => theme.colors.primary_black10()};

  ${({ theme }) => theme.media.desktop`
    margin: 0 -30px -15px -30px;
  `};
`;

export const ContainerFooter = ({ children, onClick }) => (
  <ContainerFooterBox>
    <ContainerFooterButton onClick={onClick}>{children}</ContainerFooterButton>
  </ContainerFooterBox>
);

export const ContainerDivider = styled.hr`
  height: 1px;
  margin: 0 -15px;
  color: ${({ theme }) => theme.colors.primary_black10()};
  background-color: ${({ theme }) => theme.colors.primary_black10()};

  ${({ theme }) => theme.media.desktop`
    margin: 0 -30px;
  `};
`;

const Container = styled.div`
  width: 100%;
  margin: 15px 0;
  padding: 0 15px 15px 15px;
  border: 2px solid ${({ theme }) => theme.colors.primary_black10()};
  border-radius: 15px;

  ${({ theme }) => theme.media.desktop`
    padding: 0 30px 15px 30px;
  `};
`;

export default Container;
