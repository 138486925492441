import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import moment from "moment";

import { Button, Col, ControlLabel, FormGroup, Row } from "react-bootstrap";
import { validateRequiredFields } from "common/utils/forms";
import { DatePickerField } from "common/components/forms/inputs";

const validate = values =>
  validateRequiredFields(["start_date", "end_date"], values);

class DateRangeForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={12} lg={handleSubmit ? 5 : 6}>
            <DatePickerField
              name="start_date"
              placeholderText={moment().format("YYYY-MM-DD")}
              showMonthDropdown
              showYearDropdown
              todayButton={"Today"}
              dropdownMode="select"
            />
          </Col>
          <Col md={12} lg={handleSubmit ? 5 : 6}>
            <DatePickerField
              name="end_date"
              placeholderText={moment().format("YYYY-MM-DD")}
              showMonthDropdown
              showYearDropdown
              todayButton={"Today"}
              dropdownMode="select"
            />
          </Col>
          {handleSubmit && (
            <Col md={12} lg={2}>
              <FormGroup>
                <ControlLabel>Submit</ControlLabel>
                <Button
                  bsStyle="default"
                  style={{ display: "block" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    );
  }
}

DateRangeForm = compose(
  connect(() => ({
    initialValues: {
      start_date: null,
      end_date: null,
    },
  })),
  reduxForm({
    form: "dateRangeForm",
    validate,
  }),
)(DateRangeForm);

export default DateRangeForm;
