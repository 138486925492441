// @flow strict
import React, { Component } from "react";
import Select from "react-select";
import Workstyle from "contractor/components/WorkstyleAssessment/blocks";
import { mutateAsync } from "redux-query";
import { updateDemographicDataQuery } from "queries/mutations/assessments";
import { compose } from "redux";
import { connect } from "react-redux";

type Props = {
  demographicsComplete: boolean,
};

const gender_options = [
  { value: 0, label: "No Answer" },
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: "Other" },
];

const race_options = [
  { value: 0, label: "No Answer" },
  { value: 1, label: "White (not Hispanic)" },
  { value: 2, label: "African-American/Black (not Hispanic)" },
  { value: 3, label: "Hispanic/Latino" },
  { value: 4, label: "Asian" },
  { value: 5, label: "Mixed" },
];

class DemographicsSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { gender, race } = this.props.contractor;
    this.state = { gender: gender, race: race };
  }

  shouldDisable = () => {
    return this.state.isSubmitting;
  };

  handleSubmit = () => {
    this.setState({ isSubmitting: true }, () => {
      const { submitFormData, onFinishDemographics } = this.props;
      const { gender, race } = this.state;

      submitFormData({
        gender: gender && gender.value ? gender.value : 0,
        race: race && race.value ? race.value : 0,
      }).then(result => {
        if (result.status == 200) {
          this.setState({ submitted: true }, onFinishDemographics);
        }
      });
    });
  };

  handleGenderChange = gender => {
    this.setState({ gender });
  };

  handleRaceChange = race => {
    this.setState({ race });
  };

  render() {
    return (
      <Workstyle.Container>
        <Workstyle.Header>Demographic Survey</Workstyle.Header>
        <Workstyle.Paragraph>
          Before you begin the assessment, please input your demographic
          information. To comply with government Equal Employment Opportunity /
          Affirmative Action reporting regulations, we are requesting (but NOT
          requiring) that you enter this personal data. This information will
          not be used in connection with any employment decisions, and will be
          used solely as permitted by state and federal law. Your voluntary
          cooperation would be appreciated.
        </Workstyle.Paragraph>
        <Workstyle.Subheader>Gender</Workstyle.Subheader>
        <Select
          value={this.state.gender}
          onChange={this.handleGenderChange}
          options={gender_options}
        />
        <Workstyle.Subheader>Race/Ethnicity</Workstyle.Subheader>
        <Select
          value={this.state.race}
          onChange={this.handleRaceChange}
          options={race_options}
        />
        <Workstyle.Button
          onClick={this.handleSubmit}
          disabled={this.shouldDisable()}
        >
          Next ›
        </Workstyle.Button>
      </Workstyle.Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitFormData: data =>
    dispatch(mutateAsync(updateDemographicDataQuery(data))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(DemographicsSection);
