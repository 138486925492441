// @flow strict
import React, { Fragment } from "react";

import Top from "../blocks";
import Desktop from "./blocks";
import { isActiveURL } from "common/utils";

import PendingCandidatesNav from "../PendingCandidatesNav";
import type { PendingCandidatesNavRenderProps } from "../PendingCandidatesNav";
import PendingInvoicesNav, {
  type PendingInvoicesNavRenderProps,
} from "../PendingInvoicesNav";

type SecondaryLinkItemProps = {
  badge: number | boolean,
  name: string,
  onCloseSecondaryNav: () => void,
  to: string,
};

const SecondaryLinkItem = ({
  badge,
  name,
  onCloseSecondaryNav,
  to,
}: SecondaryLinkItemProps) => {
  const open = isActiveURL(to, true);

  return (
    <Top.NavLink onClick={onCloseSecondaryNav} open={open} to={to}>
      <Desktop.SecondaryNavItem badge={badge} open={open}>
        <span name={name}>{name}</span>
        <span>{badge}</span>
      </Desktop.SecondaryNavItem>
    </Top.NavLink>
  );
};

type SecondaryNavListProps = {
  activeSecondaryNav: "hire" | "manage" | null,
  onCloseSecondaryNav: () => void,
};

const SecondaryNavList = ({
  activeSecondaryNav,
  onCloseSecondaryNav,
}: SecondaryNavListProps) => (
  <Desktop.SecondaryNavList
    activeSecondaryNav={activeSecondaryNav}
    id="secondary-nav-list"
  >
    {activeSecondaryNav === "hire" && (
      <Fragment>
        <SecondaryLinkItem
          badge={0}
          name="POST A JOB"
          onCloseSecondaryNav={onCloseSecondaryNav}
          to="/agency/jobs/create/"
        />
        <PendingCandidatesNav
          render={({ badge }: PendingCandidatesNavRenderProps) => (
            <SecondaryLinkItem
              badge={badge}
              name="VIEW APPLICANTS"
              onCloseSecondaryNav={onCloseSecondaryNav}
              to="/agency/candidates/"
            />
          )}
        />
        <SecondaryLinkItem
          badge={0}
          name="SEARCH CANDIDATES"
          onCloseSecondaryNav={onCloseSecondaryNav}
          to="/agency/attorneys/"
        />
      </Fragment>
    )}
    {activeSecondaryNav === "manage" && (
      <Fragment>
        <SecondaryLinkItem
          badge={0}
          name="JOBS"
          onCloseSecondaryNav={onCloseSecondaryNav}
          to="/agency/jobs/"
        />
        <PendingInvoicesNav
          render={({ badge }: PendingInvoicesNavRenderProps) => (
            <SecondaryLinkItem
              badge={badge}
              name="INVOICING"
              onCloseSecondaryNav={onCloseSecondaryNav}
              to="/agency/invoices/"
            />
          )}
        />
        <SecondaryLinkItem
          badge={0}
          name="PAYMENT HISTORY"
          onCloseSecondaryNav={onCloseSecondaryNav}
          to="/agency/payment-history/"
        />
        <SecondaryLinkItem
          badge={0}
          name="TALENT POOLS"
          onCloseSecondaryNav={onCloseSecondaryNav}
          to="/agency/talent_pools/"
        />
      </Fragment>
    )}
  </Desktop.SecondaryNavList>
);

export default SecondaryNavList;
