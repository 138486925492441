import React from "react";
import { getComponentDisplayName } from "common/utils";

const setDocumentTitle = title => WrappedComponent => {
  class WithDocumentTitle extends WrappedComponent {
    componentWillMount() {
      if (typeof document !== "undefined") {
        document.title = `${title} | Hire an Esquire`;
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithDocumentTitle.displayName = `WithDocumentTitle(${getComponentDisplayName(
    WrappedComponent,
  )})`;

  return WithDocumentTitle;
};

export default setDocumentTitle;
