import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

import { submitShareProfile } from "hiringagent-dashboard/actions/attorneys";

export class ContractorProfileShareModal extends Component {
  static propTypes = {
    contractor: PropTypes.object.isRequired,
    modalStatus: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
  };

  state = { value: "" };

  handleSubmit(e) {
    e.preventDefault();

    if (this.props.contractor && this.state.value) {
      this.props.submitShareProfile(
        this.props.contractor.uuid,
        this.state.value,
      );
      this.props.close();
    }
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  render() {
    const { contractor, modalStatus, close } = this.props;

    return (
      <Modal show={modalStatus} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{`Share ${contractor.first_name} ${
            contractor.last_name
          }'s Profile`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="share-profile-form" onSubmit={e => this.handleSubmit(e)}>
            <FormGroup>
              <ControlLabel>Email Address</ControlLabel>
              <FormControl
                type="email"
                placeholder="Email Address"
                value={this.state.value}
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="info" onClick={close}>
            Close
          </Button>
          <Button form="share-profile-form" type="submit" bsStyle="info">
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  null,
  { submitShareProfile },
)(ContractorProfileShareModal);
