import React, { Component } from "react";
import { initialize, SubmissionError } from "redux-form";
import { createStructuredSelector } from "reselect";
import { addNotification } from "reapop";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { createResourceSelectorConfig } from "queries";
import { applyDiscountCodeQuery } from "queries/mutations/discountCodes";
import {
  promoCodeRedemptionsQuery,
  referralCodeRedemptionsQuery,
} from "queries/requests/discountCodes";
import types from "resources/types";
import { createSuccessNotification } from "common/utils/notifications";
import { getResourcesAreReady } from "common/utils/helpers";
import Loading from "common/components/Loading";
import { AddSection, HistorySection } from "./Sections";
import ShareSection from "./ShareSection";

const formName = "discountCodesSettingsForm";
const requiredResources = [
  types.PROMO_CODE_REDEMPTIONS,
  types.REFERRAL_CODE_REDEMPTIONS,
];

const DesktopHr = styled.hr`
  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

class DiscountCodesSettings extends Component {
  handleSubmit = async ({ discount_code }) => {
    const { applyDiscountCode, initializeForm, notify } = this.props;
    const { body, status } = await applyDiscountCode(discount_code);

    if (status >= 200 && status < 300) {
      notify(
        createSuccessNotification({
          message:
            "Your code has been added and will be applied to your next invoice.",
        }),
      );
      initializeForm();
      this.postSubmit();
    } else {
      throw new SubmissionError(body);
    }
  };

  postSubmit = () => {
    this.props.refreshPromoRedemptions();
    this.props.refreshReferralRedemptions();
  };

  render() {
    const {
      agentReferralCode,
      discountCode,
      promoCodeRedemptions: { data: promoData },
      referralCodeRedemptions: { data: referralData },
    } = this.props;
    const promoCodeHistory = Object.values(promoData);
    const referralCodeHistory = Object.values(referralData);
    const isReady = getResourcesAreReady(requiredResources, this.props);
    const url = `${window.location.origin}/agency/signup/${agentReferralCode}`;

    return isReady ? (
      <div>
        <Row>
          <Col sm={6}>
            <AddSection
              discountCode={discountCode}
              onSubmit={this.handleSubmit}
            />
          </Col>
          <DesktopHr />
          {agentReferralCode && (
            <Col sm={6}>
              <ShareSection agentReferralCode={agentReferralCode} url={url} />
            </Col>
          )}
        </Row>
        <HistorySection
          promoCodeHistory={promoCodeHistory}
          referralCodeHistory={referralCodeHistory}
        />
      </div>
    ) : (
      <Loading />
    );
  }
}

const promoCodeRedemptionsSelector = createResourceSelectorConfig(
  types.PROMO_CODE_REDEMPTIONS,
  promoCodeRedemptionsQuery,
);

const referralCodesRedemptionsSelector = createResourceSelectorConfig(
  types.REFERRAL_CODE_REDEMPTIONS,
  referralCodeRedemptionsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...promoCodeRedemptionsSelector,
  ...referralCodesRedemptionsSelector,
});

const mapDispatchToProps = dispatch => ({
  applyDiscountCode: discountCode =>
    dispatch(mutateAsync(applyDiscountCodeQuery(discountCode))),
  initializeForm: () => dispatch(initialize(formName)),
  notify: fn => dispatch(addNotification(fn)),
  refreshPromoRedemptions: () =>
    dispatch(requestAsync(promoCodeRedemptionsQuery({ force: true }))),
  refreshReferralRedemptions: () =>
    dispatch(requestAsync(referralCodeRedemptionsQuery({ force: true }))),
});

const mapPropsToConfig = () => [
  promoCodeRedemptionsQuery(),
  referralCodeRedemptionsQuery(),
];

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(DiscountCodesSettings);
