import React, { Component } from "react";
import { Field } from "redux-form";
import { Button, Col, Row } from "react-bootstrap";

import DeleteButton from "common/components/DeleteButton";
import { OpacityTransition } from "common/utils/transitions";
import { Error } from "common/utils/forms/validators";
import {
  LanguageRequirementSelect,
  LanguageRequirementProficiencySelect,
} from "hiringagent-dashboard/components/jobs/create-job-listing/inputs";

const LanguageSelectFields = ({ input, onDelete }) => (
  <Row>
    <Col sm={5} xs={12}>
      <LanguageRequirementSelect name={`${input.name}.language`} />
    </Col>
    <Col sm={6} xs={10}>
      <LanguageRequirementProficiencySelect
        name={`${input.name}.proficiency`}
      />
    </Col>
    <Col sm={1} xs={2}>
      <DeleteButton onDelete={onDelete} />
    </Col>
  </Row>
);

class LanguageProficienciesFieldArray extends Component {
  handleAddLanguage = () => {
    this.props.fields.push({
      language: "",
      proficiency: "",
    });
  };

  handleDeleteLanguage = index => () => {
    this.props.fields.remove(index);
  };

  render() {
    return (
      <div style={{ marginBottom: "4rem" }}>
        <OpacityTransition
          className="list-inline"
          component="ul"
          transitionLeave={false}
        >
          {this.props.fields.map((fieldName, idx) => (
            <Field
              key={fieldName}
              name={fieldName}
              component={LanguageSelectFields}
              onDelete={this.handleDeleteLanguage(idx)}
            />
          ))}
        </OpacityTransition>
        <div className="text-center">
          <Button onClick={this.handleAddLanguage} bsStyle="success">
            <i className="far fa-plus" />&nbsp;Add Language Requirement
          </Button>
        </div>
      </div>
    );
  }
}

LanguageProficienciesFieldArray.validate = (values, errors = {}) => {
  const { joblistinglanguage_set = [] } = values;
  const languageReqArrayErrors = [];

  joblistinglanguage_set.forEach((lang, idx) => {
    const languageReqErrors = {};
    if (!lang.language) {
      languageReqErrors.language = <Error>Required</Error>;
      languageReqArrayErrors[idx] = languageReqErrors;
    }
    if (!lang.proficiency) {
      languageReqErrors.proficiency = <Error>Required</Error>;
      languageReqArrayErrors[idx] = languageReqErrors;
    }
    if (lang.language) {
      const entriesForLanguage = joblistinglanguage_set.filter(
        entry => entry.language === lang.language,
      );
      const isLanguageDuplicated = entriesForLanguage.length > 1;
      if (isLanguageDuplicated) {
        languageReqErrors.language = <Error>Language already added</Error>;
        languageReqArrayErrors[idx] = languageReqErrors;
      }
    }
  });

  return {
    ...errors,
    ...(languageReqArrayErrors.length && {
      joblistinglanguage_set: languageReqArrayErrors,
    }),
  };
};

export default LanguageProficienciesFieldArray;
