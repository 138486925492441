import actions from "hiringagent-dashboard/actions/reviews";
import { handleActions } from "redux-actions";
import { compareDateTimes, isCompleted } from "hiringagent-dashboard/utils";

const initialState = {
  performanceReviews: [],
  statusFilter: "pending",
  focusedReview: null,
  isLoading: false,
  lastUpdated: null,
};

const reviews = handleActions(
  {
    [actions.REQUEST_PERFORMANCE_REVIEWS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_PERFORMANCE_REVIEWS]: (state, action) => {
      let performanceReviews = [];
      action.payload.results.forEach(review => {
        review.isCompleted = isCompleted(review);
        performanceReviews = [...performanceReviews, review];
      });
      const sortedPerformanceReviews = performanceReviews.sort(
        compareDateTimes,
      );

      return {
        ...state,
        performanceReviews: sortedPerformanceReviews,
        lastUpdated: action.meta.receivedAt,
        isLoading: false,
      };
    },
    [actions.SET_REVIEWS_STATUS_FILTER]: (state, action) => ({
      ...state,
      statusFilter: action.payload,
    }),
    [actions.SET_FOCUSED_REVIEW]: (state, action) => ({
      ...state,
      focusedReview: action.payload,
    }),
  },
  initialState,
);
export default reviews;
