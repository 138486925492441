import React, { Component } from "react";
import { Label } from "react-bootstrap";
import { withRejectionReasons } from "common/connectors";

const statusMapping = {
  "-2": {
    bsStyle: "warning",
    text: "Unknown",
  },
  "-1": {
    bsStyle: "danger",
    text: "Rejected",
  },
  0: {
    bsStyle: "primary",
    text: "Pending",
  },
  1: {
    bsStyle: "success",
    text: "Verified",
  },
  2: {
    bsStyle: "default",
    text: "Inactive",
  },
};

class BarVerificationStatus extends Component {
  reasonRejected = () => {
    const {
      barInfo: { verified, deprecated_reason_rejected, rejection_reason },
      rejectionReasons: { data, ready },
    } = this.props;

    if (deprecated_reason_rejected) {
      return deprecated_reason_rejected;
    }
    if (ready && rejection_reason) {
      return data.results.find(item => item.uuid === rejection_reason).reason;
    }
    if (verified === -1) {
      return "No reason provided.";
    }
    return null;
  };

  render() {
    const {
      barInfo: { verified },
    } = this.props;
    const cursor = verified && verified === -1 ? "pointer" : "auto";
    return (
      <Label
        bsStyle={statusMapping[verified].bsStyle}
        style={{ cursor }}
        title={this.reasonRejected()}
      >
        {statusMapping[verified].text}
      </Label>
    );
  }
}

export default withRejectionReasons()(BarVerificationStatus);
