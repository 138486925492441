import React from "react";
import { ButtonToolbar, Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";

import PrimaryButton from "common/blocks/PrimaryButton";

const CropModal = ({
  crop,
  onModalClose,
  onCropComplete,
  onCropSave,
  stashURL,
}) => (
  <Modal show={Boolean(stashURL)} onHide={onModalClose} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>Crop Image</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ReactCrop
        src={stashURL}
        crossorigin={null}
        crop={crop}
        onChange={onCropComplete}
        onComplete={onCropComplete}
      />
      <ButtonToolbar>
        <PrimaryButton bsStyle="primary" onClick={onCropSave}>
          Save
        </PrimaryButton>
        <PrimaryButton bsStyle="default" onClick={onModalClose}>
          Cancel
        </PrimaryButton>
      </ButtonToolbar>
    </Modal.Body>
  </Modal>
);

export default CropModal;
