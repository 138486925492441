import actions from "hiringagent-dashboard/actions/pendingEntries";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  timeEntries: {},
  sorting: "created",
  sortingOptions: null,
  isLoading: false,
  lastUpdated: null,
};

const pendingEntries = handleActions(
  {
    [actions.REQUEST_PENDING_TIME_ENTRIES]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_PENDING_TIME_ENTRIES]: (state, action) => ({
      ...state,
      timeEntries: listToObject(action.payload.results, "uuid"),
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.SET_PENDING_ENTRIES_SORTING]: (state, action) => ({
      ...state,
      sorting: action.payload,
    }),
  },
  initialState,
);
export default pendingEntries;
