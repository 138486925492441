// Custom error message override
const changeIdNumberError = /You cannot change `individual\[id_number\]` via API/;
export const parseNonFieldError = message => {
  let messageStr = message;
  if (typeof message === "object") messageStr = message.message;

  if (messageStr.match(changeIdNumberError)) {
    return (
      "The social security number you entered is different from the one on file " +
      "with Stripe. If you need to change your social security number on file, " +
      "please contact support@hireanesquire.com"
    );
  }
  return messageStr;
};

export const parseNonFieldErrors = (nonFieldErrors = []) => {
  if (nonFieldErrors.length === 0) return [];
  const errors = nonFieldErrors.map(parseNonFieldError);
  return errors;
};

export const createBankToken = (createToken, routingNumber, accountNumber) =>
  createToken("bank_account", {
    country: "US",
    currency: "usd",
    routing_number: routingNumber,
    account_number: accountNumber,
  });

export const createSSNToken = (createToken, ssn) =>
  createToken("pii", {
    personal_id_number: ssn,
  });
