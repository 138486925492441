import React from "react";

import {
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  ToggleButtonGroup,
} from "react-bootstrap";

import { domOnlyProps, validationState } from "common/utils/forms";
import FieldHelpBlock from "./FieldHelpBlock";

export default ({ input, meta, label, children, ...otherProps }) => (
  <FormGroup validationState={validationState(meta)}>
    {label && <ControlLabel>{label}</ControlLabel>}
    <div>
      <ButtonToolbar>
        <ToggleButtonGroup
          type="radio"
          {...domOnlyProps(input)}
          {...domOnlyProps(otherProps)}
        >
          {children}
        </ToggleButtonGroup>
      </ButtonToolbar>
    </div>
    <FieldHelpBlock touched={meta.touched} error={meta.error} />
  </FormGroup>
);
