// @flow strict
import styled from "styled-components";

const Paragraph = styled.p`
  text-align: left;
  margin-bottom: 25px;
  font-size: 18px;
`;

export default Paragraph;
