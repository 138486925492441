// @flow strict
import React from "react";
import { FormFieldToolTip } from "common/components/forms";

export const accountInfoLabel = (
  <span>Use my Hire an Esquire account information.</span>
);

const securityMsg =
  "A Social Security Number is required as part of Stripe’s mandatory " +
  "identity verification requirement: “Stripe must collect, verify, and " +
  "maintain information on the individuals associated with every US Stripe " +
  "account, as required by our regulators and financial partners.”  Note " +
  "that Hire an Esquire does not store your Social Security Number.";
export const ssnLabel = (
  <FormFieldToolTip
    label="Social Security Number"
    content={securityMsg}
    placement="top"
  />
);

export const achAgreeLabel = (
  <span>
    I agree to the{" "}
    <a
      href="https://stripe.com/us/connect-account/legal"
      target="_blank"
      rel="noopener noreferrer"
    >
      Stripe Connected Account Agreement
    </a>
  </span>
);

// TODO: write custom ID upload message
const idMsg =
  "If we are unable to automatically verify your business and/or identity " +
  "when you submit direct deposit information, Stripe may require a copy of " +
  "a government-issued photo ID in order to manually verify the information.";
export const idLabel = (
  <FormFieldToolTip
    label="Government ID Upload"
    content={idMsg}
    placement="top"
  />
);

// TODO: write custom country message
const countryMsg = "We only accept U.S. bank account information at this time.";
export const countryLabel = (
  <FormFieldToolTip label="Country" content={countryMsg} placement="top" />
);
