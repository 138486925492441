import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Button, Col, Row } from "react-bootstrap";

import { FormFieldToolTip } from "common/components/forms";
import {
  AgreeNotARecruiterInput,
  AgreeTermsOfServiceInput,
  EmailAddressInput,
  FirstNameInput,
  LastNameInput,
  PasswordInput,
  PhoneNumberInput,
  RepeatPasswordInput,
  StateSelect,
  TextField,
} from "common/components/forms/inputs";
import { getCampaignParamsFromLocalStorage } from "common/utils";
import { validatePasswords, validateRequiredFields } from "common/utils/forms";

export const requiredFields = [
  "first_name",
  "last_name",
  "phone_home",
  "email_address",
  "bar_id",
  "state",
  "password",
  "repeat_password",
  "agreed_tos",
  "agreed_nar",
];

const validate = values => {
  let errors = validateRequiredFields(requiredFields, values);
  errors = validatePasswords(values, errors);
  return errors;
};

const BarCardToolTip = () => {
  const hint = `Admitted to practice in multiple states? We have you covered! Once your account is
                    verified, you will be able to add additional Bar IDs to your profile.`;
  return (
    <FormFieldToolTip
      label="Bar ID"
      title="Note"
      content={hint}
      placement="top"
    />
  );
};
const ToSLabel = (
  <span>
    By checking this box, you agree to the{" "}
    <Link
      to="/public/terms-of-service/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Service&nbsp;
      <i className="fas fa-external-link-alt" aria-hidden="true" />
    </Link>{" "}
    as outlined and the
    <a
      href="https://stripe.com/connect/account-terms"
      target="_blank"
      rel="noopener noreferrer"
    >
      &nbsp;Stripe Connected Account Agreement&nbsp;
      <i className="fas fa-external-link-alt" aria-hidden="true" />
    </a>
    .
  </span>
);

class AttorneySignupForm extends Component {
  render() {
    const { submitting, handleSubmit } = this.props;

    return (
      <div className="signup-form-container">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={6}>
              <FirstNameInput />
            </Col>

            <Col sm={6}>
              <LastNameInput />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <PhoneNumberInput name="phone_home" />
            </Col>
            <Col sm={6}>
              <EmailAddressInput />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TextField name="bar_id" label={<BarCardToolTip />} />
            </Col>
            <Col sm={6}>
              <StateSelect label="State Admitted to Practice" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <PasswordInput />
            </Col>
            <Col sm={6}>
              <RepeatPasswordInput />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <AgreeTermsOfServiceInput label={ToSLabel} />
            </Col>
            <Col sm={12}>
              <AgreeNotARecruiterInput />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="signup-submit-button">
                <Button
                  type="submit"
                  bsSize="large"
                  bsStyle="info"
                  disabled={submitting}
                >
                  {submitting ? (
                    <span>
                      <i className="far fa-cog fa-spin" aria-hidden="true" />
                      &nbsp;Submitting...
                    </span>
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    labels: ownProps.subtypes,
    non_attorney_type: ownProps.type,
    ...getCampaignParamsFromLocalStorage(),
  },
});

AttorneySignupForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: "attorneySignupForm",
    validate,
  }),
)(AttorneySignupForm);

export default AttorneySignupForm;
