import {
  Address,
  PaymentType,
  Rate,
  Service,
} from "hiringagent-dashboard/components/jobs/payment-details";

export default [
  {
    component: Service,
    fields: ["service"],
  },
  {
    component: PaymentType,
    fields: ["payment_type"],
  },
  {
    component: Rate,
    fields: ["rate", "billing_increment_minutes", "send_daily_reminder"],
  },
  {
    component: Address,
    fields: ["address_1", "address_2", "city", "state", "postal_code"],
  },
];
