import React, { Component } from "react";
import { connectRequest } from "redux-query-react";

import NotFound from "common/components/NotFound";
import { connect, compose } from "resources/components/utils";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

/**
 * Handles retrieving a jobListing from route params and passes the jobListing to its children.
 */
export class CreateJobListingContainer extends Component {
  render() {
    const {
      jobListings: { isPending, lastUpdated, selectedData },
    } = this.props;

    const jobListing = lastUpdated && selectedData[0];

    const propsToPass = {
      jobListing,
    };

    return (
      <div className="create-job-listing">
        <h1 className="create-job-listing-title text-muted">Post a Job</h1>
        {lastUpdated && jobListing
          ? React.cloneElement(this.props.children, propsToPass)
          : !isPending && <NotFound />}
      </div>
    );
  }
}

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.match.params.id}`,
  });

const getJobListing = props => jobListing =>
  jobListing.job === props.match.params.id;
const filterData = (data, state, props) =>
  Object.values(data).filter(getJobListing(props));
const mapStateToProps = createResourceSelector(
  types.JOB_LISTINGS,
  mapPropsToConfig,
  filterData,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(CreateJobListingContainer);
