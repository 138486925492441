import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";

import urls from "urls";
import { createApiUrl } from "utils";

class EditSocialLogin extends Component {
  render() {
    return (
      <div>
        <h2 className="edit-section-title">Social Login</h2>
        <p className="lead">
          Associate your account to use your Linkedin or Google credentials to
          quickly and easily log in to Hire an Esquire.
        </p>
        <Row>
          <Col xs={12} md={6}>
            <Button
              block={true}
              target="_blank"
              rel="noopener noreferrer"
              bsSize="lg"
              style={{ marginBottom: "1rem" }}
              className="btn-social btn-linkedin"
              href={createApiUrl(urls["social:begin"]("linkedin-oauth2"))}
            >
              <span className="fab fa-linkedin-in" /> Associate Linkedin
            </Button>
            <Button
              block={true}
              target="_blank"
              rel="noopener noreferrer"
              bsSize="lg"
              className="btn-social btn-google"
              href={createApiUrl(urls["social:begin"]("google-oauth2"))}
            >
              <span className="fab fa-google" /> Associate Google
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditSocialLogin;
