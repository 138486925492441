// @flow strict
import React from "react";
import Percentile from "./blocks";

import ToolTip from "common/components/ToolTip";

const DataItem = ({ obj }) => {
  const lastDigit = obj.score % 10;
  let sup = "TH";

  switch (lastDigit) {
    case 1:
      sup = "ST";
      break;
    case 2:
      sup = "ND";
      break;
    case 3:
      sup = "RD";
      break;
    default:
      sup = "TH";
  }

  const isStrength = obj.score >= 75;

  return (
    <Percentile.Main>
      <Percentile.Top>
        <Percentile.Predictor>{obj.name}</Percentile.Predictor>
        <ToolTip
          content={obj.description ? obj.description : "No info."}
          placement="top"
        >
          <Percentile.InfoCircle />
        </ToolTip>
        <Percentile.LabelContainer>
          {isStrength && <Percentile.Label>Strength</Percentile.Label>}
        </Percentile.LabelContainer>
        <Percentile.BarLabel>
          {obj.score.toString()}
          <sup>{sup}</sup> PERCENTILE
        </Percentile.BarLabel>
      </Percentile.Top>
      <Percentile.BarOuter>
        <Percentile.BarInner percentile={obj.score} />
      </Percentile.BarOuter>
      {isStrength && obj.strengths ? (
        <Percentile.Description>{obj.strengths}</Percentile.Description>
      ) : (
        <Percentile.HiddenDivider />
      )}
    </Percentile.Main>
  );
};

const AssessmentResults = ({ candidate, predictors }) => {
  const scores = candidate.predictor_scores;
  if (Object.keys(scores).length === 0) return null;

  const ids = Object.keys(scores);
  const idsLength = ids.length;
  const arr = [];

  for (let i = 0; i < idsLength; i++) {
    const id = ids[i];
    const score = Math.round(scores[id]);
    const predictor = predictors.find(predictor => predictor.uuid === id);
    const {
      name,
      description,
      strengths,
      lie_detector,
      test_predictor,
    } = predictor;

    if (lie_detector || test_predictor) continue;

    arr.push({
      name,
      description,
      strengths,
      score,
    });
  }

  return (
    <Percentile>
      <h1>Assessment Results</h1>
      {arr
        .sort((a, b) => b.score - a.score)
        .map((obj, key) => <DataItem obj={obj} key={key} />)}
    </Percentile>
  );
};

export default AssessmentResults;
