// @flow strict
import styled from "styled-components";

const Form = styled.form`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export default Form;
