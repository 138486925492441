import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getQueryKey } from "redux-query";
import { connectRequest } from "redux-query-react";
import { PanelGroup } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import ActiveJobsPanel from "./ActiveJobsPanel";
import OffersPanel from "./OffersPanel";
import PendingApplicationsPanel from "./PendingApplicationsPanel";
import PreviousJobsPanel from "./PreviousJobsPanel";
import RecommendationsPanel from "./RecommendationsPanel";
import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import types from "resources/types";

class WorkPanelGroup extends Component {
  render() {
    const {
      [types.CANDIDATES]: {
        data: candidates,
        queries: { [getQueryKey(candidatesQuery())]: candidatesData },
        isFinished: candidatesFinished,
      },
      [types.JOB_LISTINGS]: {
        data: jobListings,
        isFinished: jobListingsFinished,
      },
      [types.JOB_OFFERS]: {
        data: jobOffers,
        query: { data: jobOffersOrdering },
        isFinished: jobOffersFinished,
      },
      [types.JOB_WORKERS]: {
        data: jobWorkers,
        queries: {
          [getQueryKey(jobWorkersOnActiveJobsQuery())]: activeJobWorkersQuery,
          [getQueryKey(jobWorkersOnClosedJobsQuery())]: closedJobWorkersQuery,
        },
        isFinished: jobWorkersFinished,
      },
      [types.PAYMENT_DETAILS]: {
        data: paymentDetails,
        isFinished: paymentDetailsFinished,
      },
      [types.RECOMMENDATIONS]: {
        data: recommendations,
        query: { data: recommendationsOrdering, count: recommendationsCount },
        isFinished: recommendationsFinished,
      },
    } = this.props;

    return (
      <PanelGroup
        accordion
        id="contractor-jobs-panel-group"
        className="contractor-jobs-panel-group"
        defaultActiveKey="6"
      >
        {recommendationsFinished &&
          recommendationsCount > 0 && (
            <RecommendationsPanel
              eventKey="1"
              recommendations={recommendationsOrdering.map(
                id => recommendations[id],
              )}
            />
          )}
        {jobWorkersFinished &&
          jobListingsFinished &&
          activeJobWorkersQuery &&
          paymentDetailsFinished && (
            <ActiveJobsPanel
              eventKey="2"
              jobListings={jobListings}
              jobWorkers={activeJobWorkersQuery.data.map(id => jobWorkers[id])}
              paymentDetails={paymentDetails}
            />
          )}
        {jobWorkersFinished &&
          jobListingsFinished &&
          closedJobWorkersQuery &&
          paymentDetailsFinished && (
            <PreviousJobsPanel
              eventKey="3"
              jobListings={jobListings}
              jobWorkers={closedJobWorkersQuery.data.map(id => jobWorkers[id])}
              paymentDetails={paymentDetails}
            />
          )}
        {jobOffersFinished &&
          candidatesFinished &&
          jobListingsFinished && (
            <OffersPanel
              eventKey="4"
              candidates={candidates}
              jobListings={jobListings}
              jobOffers={jobOffersOrdering.map(id => jobOffers[id])}
            />
          )}
        {candidatesFinished &&
          jobListingsFinished && (
            <PendingApplicationsPanel
              eventKey="5"
              candidates={candidatesData.data.map(id => candidates[id])}
              jobListings={jobListings}
            />
          )}
      </PanelGroup>
    );
  }
}

const allCandidatesQuery = () =>
  createResourceListQuery(types.CANDIDATES, {
    url: "/api/v2/candidates/?limit=999",
    force: true,
  });
const candidatesQuery = () =>
  createResourceListQuery(types.CANDIDATES, {
    url: "/api/v2/candidates/?status=0&status=2&limit=999",
    force: true,
  });
const jobListingsQuery = () =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: "/api/v2/joblistings/?is_candidate=true&limit=9999",
    force: true,
  });
const jobOffersQuery = () =>
  createResourceListQuery(types.JOB_OFFERS, {
    url: "/api/v2/job_offers/?status=0&limit=99",
    force: true,
  });
const jobWorkersOnActiveJobsQuery = () =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: "/api/v2/job_workers/?job_active=true&limit=99",
    force: true,
  });
const jobWorkersOnClosedJobsQuery = () =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: "/api/v2/job_workers/?job_closed=true&limit=99",
    force: true,
  });
const paymentDetailsQuery = () =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: "/api/v2/payment_details/?limit=99",
    force: true,
  });
const recommendationsQuery = () =>
  createResourceListQuery(types.RECOMMENDATIONS, {
    url: "/api/dev/recommendations/?limit=99",
    force: true,
  });

const mapPropsToConfig = () => [
  allCandidatesQuery(),
  candidatesQuery(),
  jobListingsQuery(),
  jobOffersQuery(),
  jobWorkersOnActiveJobsQuery(),
  jobWorkersOnClosedJobsQuery(),
  paymentDetailsQuery(),
  recommendationsQuery(),
];

const candidatesSelector = createResourceSelectorConfig(
  types.CANDIDATES,
  candidatesQuery,
);
const jobListingsSelector = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingsQuery,
);
const jobOffersSelector = createResourceSelectorConfig(
  types.JOB_OFFERS,
  jobOffersQuery,
);
const jobWorkersOnActiveJobsSelector = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkersOnActiveJobsQuery,
);
const jobWorkersOnClosedJobsSelector = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkersOnClosedJobsQuery,
);
const paymentDetailsSelector = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailsQuery,
);
const recommendationsSelector = createResourceSelectorConfig(
  types.RECOMMENDATIONS,
  recommendationsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...candidatesSelector,
  ...jobListingsSelector,
  ...jobOffersSelector,
  ...jobWorkersOnActiveJobsSelector,
  ...jobWorkersOnClosedJobsSelector,
  ...paymentDetailsSelector,
  ...recommendationsSelector,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(WorkPanelGroup);
