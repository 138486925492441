// @flow strict
import React, { Component, Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";

import Top from "../blocks";
import Mobile from "./blocks";
import Brand from "../Brand";
import SecondaryNavList from "./SecondaryNavList";
import MobileLinkItem from "./MobileLinkItem";
import PendingCandidatesNav, {
  type PendingCandidatesNavRenderProps,
} from "../PendingCandidatesNav";
import PendingInvoicesNav, {
  type PendingInvoicesNavRenderProps,
} from "../PendingInvoicesNav";
import MessagesLinkItem, {
  type MessagesLinkItemRenderProps,
} from "../MessagesLinkItem";
import { isActiveURL } from "common/utils";
import { agentHireURLs, agentManageURLs } from "../values";

type ToggleProps = {
  onToggleMain: () => void,
  mobileNavOpen: boolean,
  userType: UserType,
  withSearchLink: boolean,
  withSignUpButton: boolean,
};

const Toggle = ({
  onToggleMain,
  mobileNavOpen,
  userType,
  withSearchLink,
  withSignUpButton,
}: ToggleProps) => {
  switch (userType) {
    case "admin":
      break;
    case "anonymous":
      if (withSearchLink || withSignUpButton) {
        break;
      } else {
        return null;
      }
    case "contractor":
      break;
    case "hiring_agent":
      break;
    default:
      return null;
  }

  return (
    <Mobile.Toggle onClick={onToggleMain}>
      {mobileNavOpen ? (
        <Fragment>
          <Mobile.Close />
          <Mobile.Text>CLOSE</Mobile.Text>
        </Fragment>
      ) : (
        <Fragment>
          <Mobile.Bar />
          <Mobile.Bar />
          <Mobile.Bar />
        </Fragment>
      )}
    </Mobile.Toggle>
  );
};

type MobileNavListProps = {
  onCloseAll: () => void,
  onToggleHire: () => void,
  onToggleManage: () => void,
  hireOpen: boolean,
  manageOpen: boolean,
  userType: UserType,
  withSearchLink: boolean,
  withSignUpButton: boolean,
};

const MobileNavList = ({
  onCloseAll,
  onToggleHire,
  onToggleManage,
  hireOpen,
  manageOpen,
  userType,
  withSearchLink,
  withSignUpButton,
}: MobileNavListProps) => {
  switch (userType) {
    case "admin":
      return <Mobile.NavList />;
    case "anonymous":
      return (
        <Mobile.NavList>
          {withSearchLink && (
            <Fragment>
              <MobileLinkItem
                badge={false}
                onClick={onCloseAll}
                name="Search"
                to="/public/jobs"
              />
              <Mobile.Divider />
            </Fragment>
          )}
          {withSignUpButton && (
            <Mobile.Footer>
              <LinkContainer onClick={onCloseAll} to="/signup">
                <Top.Button bsStyle="primary">Sign Up</Top.Button>
              </LinkContainer>
            </Mobile.Footer>
          )}
        </Mobile.NavList>
      );
    case "contractor":
      return (
        <Mobile.NavList>
          <MobileLinkItem
            badge={false}
            onClick={onCloseAll}
            name="Dashboard"
            to="/contractor"
          />
          <MobileLinkItem
            badge={false}
            onClick={onCloseAll}
            name="Profile"
            to="/contractor/profile/view"
          />
          <MobileLinkItem
            badge={false}
            onClick={onCloseAll}
            name="Find Jobs"
            to="/contractor/jobs"
          />
          <MessagesLinkItem
            render={(props: MessagesLinkItemRenderProps) => (
              <MobileLinkItem
                name="Inbox"
                to="/contractor/messages"
                onClick={onCloseAll}
                {...props}
              />
            )}
          />
          <MobileLinkItem
            badge={false}
            onClick={onCloseAll}
            name="Settings"
            to="/contractor/settings"
          />
          <Mobile.Divider />
          <Mobile.Footer>
            <Top.NavLink onClick={onCloseAll} to="/logout">
              <Mobile.LogOut>LogOut</Mobile.LogOut>
            </Top.NavLink>
          </Mobile.Footer>
        </Mobile.NavList>
      );
    case "hiring_agent":
      return (
        <Mobile.NavList>
          <MobileLinkItem
            badge={false}
            onClick={onCloseAll}
            name="Dashboard"
            to="/agency"
          />
          <Mobile.Divider />
          <PendingCandidatesNav
            render={({ dot }: PendingCandidatesNavRenderProps) => (
              <Mobile.NavTrigger
                dot={dot}
                name="Hire"
                onClick={onToggleHire}
                open={hireOpen}
              >
                <span>•</span>
                <span name="Hire">Hire</span>
                <Mobile.Plus />
              </Mobile.NavTrigger>
            )}
          />
          <SecondaryNavList
            handleClose={onCloseAll}
            links="hire"
            open={hireOpen}
          />
          <Mobile.Divider />
          <PendingInvoicesNav
            render={({ dot }: PendingInvoicesNavRenderProps) => (
              <Mobile.NavTrigger
                dot={dot}
                name="Manage"
                onClick={onToggleManage}
                open={manageOpen}
              >
                <span>•</span>
                <span name="Manage">Manage</span>
                <Mobile.Plus />
              </Mobile.NavTrigger>
            )}
          />
          <SecondaryNavList
            handleClose={onCloseAll}
            links="manage"
            open={manageOpen}
          />
          <MessagesLinkItem
            render={(props: MessagesLinkItemRenderProps) => (
              <MobileLinkItem
                name="Inbox"
                to="/agency/messages"
                onClick={onCloseAll}
                {...props}
              />
            )}
          />
          <MobileLinkItem
            badge={false}
            onClick={onCloseAll}
            name="Settings"
            to="/agency/settings"
          />
          <Mobile.Divider />
          <Mobile.Footer>
            <LinkContainer onClick={onCloseAll} to="/agency/jobs/create">
              <Top.Button bsStyle="primary">+ Post a Job</Top.Button>
            </LinkContainer>
            <Top.NavLink onClick={onCloseAll} to="/logout">
              <Mobile.LogOut>LogOut</Mobile.LogOut>
            </Top.NavLink>
          </Mobile.Footer>
        </Mobile.NavList>
      );
    default:
      return <Mobile.NavList />;
  }
};

type MobileNavProps = {
  empty: boolean,
  pathname: string,
  userType: UserType,
  withSearchLink: boolean,
  withSignUpButton: boolean,
};

type MobileNavState = {
  hireOpen: boolean,
  manageOpen: boolean,
  mobileNavOpen: boolean,
};

export default class MobileNav extends Component<
  MobileNavProps,
  MobileNavState,
> {
  constructor(props: MobileNavProps) {
    super(props);
    this.state = {
      hireOpen: isActiveURL(agentHireURLs, false),
      manageOpen: isActiveURL(agentManageURLs, false),
      mobileNavOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseDown, false);
  }

  componentDidUpdate(prevProps: { pathname: string }) {
    if (this.props.pathname !== prevProps.pathname) {
      this.setState({
        hireOpen: isActiveURL(agentHireURLs, false),
        manageOpen: isActiveURL(agentManageURLs, false),
      });
    }
  }

  handleMouseDown = (e: MouseEvent) => {
    const topNavHeader = document.querySelector("#top-nav-header");
    if (topNavHeader && topNavHeader.contains(e.target)) return;
    if (this.state.mobileNavOpen) {
      this.handleCloseAll();
    }
  };

  handleCloseAll = () =>
    this.setState({
      hireOpen: isActiveURL(agentHireURLs, false),
      manageOpen: isActiveURL(agentManageURLs, false),
      mobileNavOpen: false,
    });

  handleToggleMain = () => {
    if (this.state.mobileNavOpen) {
      this.handleCloseAll();
    } else {
      this.setState({ mobileNavOpen: true });
    }
  };

  handleToggleHire = () =>
    this.setState(state => ({ hireOpen: !state.hireOpen }));

  handleToggleManage = () =>
    this.setState(state => ({ manageOpen: !state.manageOpen }));

  render() {
    const { empty, userType, withSearchLink, withSignUpButton } = this.props;
    const { hireOpen, manageOpen, mobileNavOpen } = this.state;

    return (
      <Mobile mobileNavOpen={mobileNavOpen}>
        <Mobile.Main>
          <Brand onCloseAll={this.handleCloseAll} userType={userType} />
          {!empty && (
            <Toggle
              onToggleMain={this.handleToggleMain}
              mobileNavOpen={mobileNavOpen}
              userType={userType}
              withSearchLink={withSearchLink}
              withSignUpButton={withSignUpButton}
            />
          )}
        </Mobile.Main>
        <Mobile.Collapse in={mobileNavOpen}>
          {empty ? (
            <Mobile.NavList />
          ) : (
            <MobileNavList
              onCloseAll={this.handleCloseAll}
              onToggleHire={this.handleToggleHire}
              onToggleManage={this.handleToggleManage}
              hireOpen={hireOpen}
              manageOpen={manageOpen}
              userType={userType}
              withSearchLink={withSearchLink}
              withSignUpButton={withSignUpButton}
            />
          )}
        </Mobile.Collapse>
      </Mobile>
    );
  }
}
