import actions from "public/actions/signup";
import { handleActions } from "redux-actions";

const initialState = {
  howYouHeardChoices: [],
  isLoading: false,
  lastUpdated: null,
};

const signup = handleActions(
  {
    [actions.REQUEST_HOW_YOU_HEARD]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_HOW_YOU_HEARD]: (state, action) => ({
      ...state,
      howYouHeardChoices: action.payload.results,
    }),
  },
  initialState,
);

export default signup;
