import React, { Component } from "react";

import { Button, ButtonToolbar, Table } from "react-bootstrap";
import Loading from "common/components/Loading";
import { withConflicts } from "contractor/connectors";

class ListConflicts extends Component {
  handleDeleteClick = conflictId => () => {
    this.props.onDeleteClick(conflictId);
  };

  handleEditClick = conflictId => () => {
    this.props.onEditClick(conflictId);
  };

  render() {
    const {
      conflicts: {
        data: { results = [] },
        isLoading,
        lastUpdated,
      },
    } = this.props;
    return (
      <div>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Matter</th>
              <th>Client</th>
              <th>Firm</th>
              <th>Opposing Party</th>
              <th />
            </tr>
          </thead>
          {lastUpdated &&
            !isLoading && (
              <tbody>
                {results.map(conflict => (
                  <tr key={`conflict-row-${conflict.uuid}`}>
                    <td>{conflict.name_of_matter}</td>
                    <td>{conflict.client_represented}</td>
                    <td>{conflict.name_of_firm}</td>
                    <td>{conflict.opposing_party}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <ButtonToolbar>
                        <Button
                          bsSize="xs"
                          bsStyle="primary"
                          style={{ float: "none" }}
                          onClick={this.handleEditClick(conflict.uuid)}
                        >
                          Edit
                        </Button>
                        <Button
                          bsSize="xs"
                          bsStyle="danger"
                          style={{ float: "none" }}
                          onClick={this.handleDeleteClick(conflict.uuid)}
                        >
                          Delete
                        </Button>
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
        </Table>
        {isLoading ? (
          <Loading />
        ) : (
          results.length === 0 && (
            <p className="text-center">
              You don't have any conflicts on file. Use the form below to add
              some.
            </p>
          )
        )}
      </div>
    );
  }
}

export default withConflicts()(ListConflicts);
