import React, { Component } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";

import WorkExperienceForm, {
  formName,
} from "contractor/forms/WorkExperienceForm";

class EditExperienceForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, validate } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <WorkExperienceForm
          enableReinitialize={true}
          destroyOnUnmount={false}
          validate={validate}
        />
        <div className="text-right">
          <Button
            type="submit"
            bsStyle="primary"
            disabled={pristine || submitting}
          >
            {submitting ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }),
)(EditExperienceForm);
