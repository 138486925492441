import React from "react";
import { Col, Row } from "react-bootstrap";

import Messages from "./Messages/";
import Thread from "./Thread/";
import ThreadPlaceholder from "./Thread/ThreadPlaceholder";
import useWindowSize from "common/hooks/useWindowSize";

const fullHeight = { height: "100%", margin: "0" };

const Responsive = ({
  authUser,
  activeMsgKey,
  messagesData,
  mQuery,
  offset,
  onSearch,
  onSearchChange,
  onFetchMessages,
  onCloseThread,
  onFilterChange,
  onGetBaseRoute,
  onMessageItemClick,
  onTogglersClick,
  onPaginateMessages,
  archivedLoadId,
  unreadLoadId,
  pendingSearch,
  search,
  filter,
}) => {
  const windowSize = useWindowSize();
  if (!windowSize || !windowSize.width) return;
  const messagesProps = {
    activeMsgKey,
    messagesData,
    mQuery,
    offset,
    onSearch,
    onSearchChange,
    onFilterChange,
    onMessageItemClick,
    onTogglersClick,
    onPaginateMessages,
    archivedLoadId,
    unreadLoadId,
    pendingSearch,
    filter,
  };
  const threadProps = {
    authUser,
    activeMsgKey,
    onCloseThread,
    onGetBaseRoute,
    onFetchMessages,
    search: search ? "?" + search.slice(1) : "",
    windowSize,
  };
  let height = `calc(${windowSize.height}px - 128px - 2rem)`; // Desktop
  if (windowSize.width < 992) {
    height = `calc(${windowSize.height}px - 63px - 2rem)`; // Mobile
  }
  if (windowSize.width < 768) {
    height = `calc(${windowSize.height}px - 45px - 2rem)`; // Landscape Mobile
  }

  return (
    <div style={{ height }}>
      {windowSize.width >= 992 ? (
        <Row style={fullHeight}>
          <Col xs={4} style={fullHeight}>
            <Messages {...messagesProps} />
          </Col>
          <Col xs={8} style={fullHeight}>
            {activeMsgKey ? <Thread {...threadProps} /> : <ThreadPlaceholder />}
          </Col>
        </Row>
      ) : (
        <>
          {activeMsgKey ? (
            <Thread {...threadProps} />
          ) : (
            <Messages {...messagesProps} />
          )}
        </>
      )}
    </div>
  );
};

export default Responsive;
