// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

const Label: ComponentType<{}> = styled.span`
  justify-self: start;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary_turquoise()};

  ${({ theme }) => theme.media.mobile`
    justify-self: end;
    color: ${theme.colors.primary_black40()};
    font-size: 12px;
  `};
`;

export default Label;
