// @flow strict
import styled from "styled-components";

const Header = styled.h1`
  text-align: center;
  font-size: 32px;
  font-weight: bold;

  ${({ theme }) => theme.media.mobile`
    margin-bottom: 25px;
  `};

  ${({ theme }) => theme.media.desktop`
    margin-bottom: 50px;
  `};
`;

export default Header;
