import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connectRequest } from "redux-query-react";
import { connect, compose } from "resources/components/utils";

import Loading from "common/components/Loading";
import NotFound from "common/components/NotFound";
import { Expenses } from "contractor/components/time-tracker";

import {
  createResourceListQuery,
  createResourceSelectorConfig,
  createResourceDetailQuery,
} from "queries";
import types from "resources/types";

class ExpenseTracker extends Component {
  render() {
    const {
      [types.JOBS]: {
        data: jobs,
        query: {
          data: [jobQueryData],
        },
        isFinished: jobIsFinished,
      },
      [types.JOB_WORKERS]: {
        data: jobWorkers,
        query: {
          data: [jobWorkerQueryData],
        },
        isFinished: jobWorkerIsFinished,
      },
      [types.PAYMENT_DETAILS]: {
        data: paymentDetails,
        query: {
          data: [paymentDetailsQuery],
        },
        isFinished: paymentDetailsFinished,
      },
    } = this.props;

    const job = jobs[jobQueryData];
    const worker = jobWorkers[jobWorkerQueryData];
    const paymentDetail = paymentDetails[paymentDetailsQuery];

    const isReady =
      jobWorkerIsFinished && jobIsFinished && paymentDetailsFinished;
    const isPermitted = isReady && paymentDetail.allow_expenses;

    const ExpenseTrackerDisplay = isPermitted ? (
      <Expenses job={job} worker={worker} paymentDetail={paymentDetail} />
    ) : (
      <NotFound to={"/contractor/"} />
    );

    return (
      <div className="expense-tracker">
        {isReady ? ExpenseTrackerDisplay : <Loading />}
      </div>
    );
  }
}

const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });

const jobWorkerQuery = props =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: `/api/v2/job_workers/?job=${props.match.params.id}`,
  });

const paymentDetailQuery = props =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/?job=${props.match.params.id}`,
  });

const mapPropsToConfig = props => [
  jobQuery(props),
  jobWorkerQuery(props),
  paymentDetailQuery(props),
];

const jobConfig = createResourceSelectorConfig(types.JOBS, jobQuery);
const jobWorkerConfig = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkerQuery,
);
const paymentDetailConfig = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailQuery,
);

const mapStateToProps = createStructuredSelector({
  ...jobConfig,
  ...jobWorkerConfig,
  ...paymentDetailConfig,
});

export default (ExpenseTracker = compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(ExpenseTracker));
