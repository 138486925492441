import styled, { css } from "styled-components";

import { shouldHideFooter } from "common/components/layout/Footer";

/**
 * Page content wrapper that adds:
 *    - Top margin below the nav bar
 *    - Bottom padding above page footer
 */

const FOOTER_HEIGHT_MOBILE = 215;
const FOOTER_HEIGHT = 50;

export default styled.div`
  margin-top: 2rem;
  padding-bottom: ${FOOTER_HEIGHT_MOBILE + 20}px;

  ${({ theme }) => theme.media.sm`
    padding-bottom: ${FOOTER_HEIGHT + 20}px;
  `};

  ${() => {
    if (shouldHideFooter()) {
      return css`
        &&&& {
          margin: 15px 0 0 0;
          padding-bottom: 0;
        }
      `;
    }
  }}
`;
