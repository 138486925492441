import React, { Component } from "react";
import { compose } from "redux";

import { BarInformationsTable } from "admin/components/attorney-verification-manager";
import {
  withAttorneyVerificationManager,
  withBarInformations,
} from "admin/connectors";

class BarInformationsForAttorney extends Component {
  render() {
    const {
      attorneyId,
      attorneyVerificationManager,
      attorneyVerificationManager: { selectedBarInformationId },
      barInformations,
      barInformations: { ready },
    } = this.props;

    const attorneyVerificationBarInfos =
      attorneyVerificationManager.barInformations;
    const barInformationsForAttorney = Object.values(
      attorneyVerificationBarInfos,
    ).filter(
      barInfo =>
        barInfo.attorney === attorneyId &&
        barInfo.uuid !== selectedBarInformationId,
    );
    const barInfosForAttorneyObject = { ...barInformations };
    barInfosForAttorneyObject.data.results = barInformationsForAttorney;

    return (
      <div>
        <h2>
          Additional Bar Licenses{" "}
          {ready && <small>showing {barInformationsForAttorney.length}</small>}
        </h2>
        <BarInformationsTable
          barInformations={barInfosForAttorneyObject}
          type="additional"
        />
      </div>
    );
  }
}

export default compose(
  withAttorneyVerificationManager(),
  withBarInformations(props => ({
    match: { params: { order_by: "-created", attorney_id: props.attorneyId } },
  })),
)(BarInformationsForAttorney);
