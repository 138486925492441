import React from "react";
import { JobLanguageRequirement } from "common/components/jobs";

const JobLanguagesDisplay = ({ jobListing }) => (
  <ul>
    {jobListing.joblistinglanguage_set.map(fluency => (
      <li key={fluency.uuid}>
        <JobLanguageRequirement {...fluency} /> ({fluency.proficiency})<br />
      </li>
    ))}
  </ul>
);

export default JobLanguagesDisplay;
