// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {};

const GreyText: ComponentType<Props> = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary_black40()};
`;

export default GreyText;
