import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import moment from "moment";

import { compose, connect, mutateAsync } from "queries/utils";
import {
  createPreviousEmploymentQuery,
  updatePreviousEmploymentQuery,
} from "queries/mutations/previousEmployments";
import {
  DatePickerField,
  ReactSelectField,
  SelectField,
  TextAreaField,
  TextField,
} from "common/components/forms/inputs";
import { FirmTypeToolTip, PositionTypeToolTip } from "./copy";
import { handleSubmitError } from "../../utils";
import FormButtons from "../../FormButtons";

let WorkExperienceForm = ({
  error,
  filteredPositionTypes,
  firmTypes,
  formattedTagOptions,
  handleSubmit,
  onHide,
  pristine,
  submitting,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <TextField name="job_title" label="Job Title" />
    <TextField name="other_company" label="Company" />
    <Row>
      <Col sm={6}>
        <SelectField name="position_type" label={<PositionTypeToolTip />}>
          {filteredPositionTypes.map(option => (
            <option key={option.uuid} value={option.uuid}>
              {option.name}
            </option>
          ))}
        </SelectField>
      </Col>
      <Col sm={6}>
        <SelectField name="firm_type" label={<FirmTypeToolTip />}>
          {firmTypes.map(option => (
            <option key={option.uuid} value={option.uuid}>
              {option.name}
            </option>
          ))}
        </SelectField>
      </Col>
    </Row>
    <Row>
      <Col sm={6}>
        <DatePickerField
          name="start_date"
          label="Start Date"
          maxDate={moment().toDate()}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </Col>
      <Col sm={6}>
        <DatePickerField
          name="end_date"
          label="End Date"
          maxDate={moment().toDate()}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </Col>
    </Row>
    <TextAreaField
      name="description"
      label="Description"
      rows={8}
      maxLength={2000}
    />
    <ReactSelectField
      name="tags"
      label="Select up to 3 applicable areas of expertise."
      options={formattedTagOptions}
      multi={true}
    />
    <FormButtons onHide={onHide} submitting={submitting} pristine={pristine} />
  </form>
);

WorkExperienceForm = reduxForm({
  form: "onboardingWorkExperienceForm",
})(WorkExperienceForm);

class FormModal extends Component {
  handleSubmitWorkExperienceForm = values => {
    const {
      props: {
        createPreviousEmployment,
        editingPrevEmployment,
        updatePreviousEmployment,
      },
      getFormattedSubmitValues,
      postSubmit,
    } = this;
    const formattedValues = getFormattedSubmitValues(values);

    if (editingPrevEmployment && editingPrevEmployment.uuid) {
      return updatePreviousEmployment(
        formattedValues,
        editingPrevEmployment.uuid,
      )
        .then(handleSubmitError)
        .then(postSubmit);
    } else {
      return createPreviousEmployment(formattedValues)
        .then(handleSubmitError)
        .then(postSubmit);
    }
  };

  postSubmit = () => {
    const { onHide, refreshPreviousEmployments, refreshReqs } = this.props;
    onHide();
    refreshPreviousEmployments();
    refreshReqs();
  };

  getFormattedSubmitValues = values => {
    if (values.tags) {
      return { ...values, tags: values.tags.map(tagId => ({ tag: tagId })) };
    } else {
      return values;
    }
  };

  getFormattedInitValues = () => {
    const { editingPrevEmployment } = this.props;
    const { firmTypes, positionTypes } = this.props;
    let formattedInitialValues = {};

    if (editingPrevEmployment) {
      const {
        company_name,
        firm_type,
        position_type,
        ...otherValues
      } = editingPrevEmployment;
      const tagIds = editingPrevEmployment.tags.map(tag => tag.tag);
      const firmTypeId =
        firm_type && firmTypes.find(firm => firm.name === firm_type).uuid;
      const positionTypeId =
        position_type &&
        positionTypes.find(pos => pos.name === position_type).uuid;
      formattedInitialValues = {
        ...otherValues,
        other_company: company_name,
        tags: tagIds,
        firm_type: firmTypeId,
        position_type: positionTypeId,
      };
    }

    return formattedInitialValues;
  };

  render() {
    const {
      props: {
        editingPrevEmployment,
        firmTypes,
        show,
        onHide,
        ownContractor: { is_attorney },
        positionTypes,
        tags,
      },
      getFormattedInitValues,
      handleSubmitWorkExperienceForm,
    } = this;
    const formattedTagOptions = tags.map(tag => ({
      value: tag.uuid,
      label: tag.name,
    }));
    const filteredPositionTypes = positionTypes.filter(positionType => {
      if (is_attorney) return true;
      return !is_attorney && positionType.for_non_attorneys;
    });

    return (
      <Modal backdrop="static" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingPrevEmployment ? "Edit" : "Add New"} Work Experience
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkExperienceForm
            filteredPositionTypes={filteredPositionTypes}
            firmTypes={firmTypes}
            formattedTagOptions={formattedTagOptions}
            initialValues={getFormattedInitValues()}
            onHide={onHide}
            onSubmit={handleSubmitWorkExperienceForm}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createPreviousEmployment: data =>
    dispatch(mutateAsync(createPreviousEmploymentQuery(data))),
  updatePreviousEmployment: (data, uuid) =>
    dispatch(mutateAsync(updatePreviousEmploymentQuery(data, uuid))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(FormModal);
