// @flow
import { querySelectors, getQueryKey } from "redux-query";
import { createStructuredSelector } from "reselect";
import { type State } from "types";

const createSelectResourceData = resource => state =>
  state.entities[resource].data;
const createSelectResourceQueryData = (resource, mapPropsToConfig) => (
  state,
  props,
) => {
  const config = mapPropsToConfig(props);
  const queryKey = getQueryKey(config);
  const queries = state.entities[resource].queries;
  return queries[queryKey] || { data: [], count: -1 };
};
const createSelectResourceQueries = resource => state =>
  state.entities[resource].queries;
const createSelectResourceMetadata = resource => state =>
  state.entities[resource].metadata;
const createSelectResourceIsPending = mapPropsToConfig => (state, props) =>
  querySelectors.isPending(state.queries, mapPropsToConfig(props));
const createSelectResourceLastUpdated = mapPropsToConfig => (state, props) =>
  querySelectors.lastUpdated(state.queries, mapPropsToConfig(props));
const createSelectResourceIsFinishedAndUpdated = (
  mapPropsToConfig,
): ((State, mixed) => boolean) => (state, props) =>
  Boolean(
    querySelectors.isFinished(state.queries, mapPropsToConfig(props)) &&
      querySelectors.lastUpdated(state.queries, mapPropsToConfig(props)),
  );
const createSelectResourceSelectedData = (resource, selectData) => (
  state,
  props,
) => selectData(state.entities[resource].data, state, props);

export { createStructuredSelector };

/**
 * TODO:  mapPropsToConfig gets called way too many times here
 * TODO:  Also, using querySelectors in this manner can cause race conditions if
 *        multiple requests with differnt URLs are made for the same resource.
 *        Example:
 *          - Request made to /job_workers/?job_closed=true
 *          - Request made to /job_workers/?job_active=true
 *          - When one request finishes, `isFinished` will be set to true before
 *            the other finishes.
 */
export const createResourceSelectorConfig = (
  resource: string,
  mapPropsToConfig: Function,
  selectData: Function,
) => {
  const selector = createStructuredSelector({
    data: createSelectResourceData(resource),
    query: createSelectResourceQueryData(resource, mapPropsToConfig),
    queries: createSelectResourceQueries(resource),
    metadata: createSelectResourceMetadata(resource),
    isFinished: createSelectResourceIsFinishedAndUpdated(mapPropsToConfig),
    isPending: createSelectResourceIsPending(mapPropsToConfig),
    lastUpdated: createSelectResourceLastUpdated(mapPropsToConfig),
    ...(selectData
      ? {
          selectedData: createSelectResourceSelectedData(resource, selectData),
        }
      : {}),
  });
  return {
    [resource]: selector,
  };
};

// Improved version of this function that does not re-compute config
export const createResourceSelector = (
  resource: string,
  mapPropsToConfig: Function,
  selectData: Function,
) => {
  return (state, props) => {
    const config = mapPropsToConfig(props);
    const queryKey = getQueryKey(config);
    const queries = state.entities[resource].queries;
    return {
      [resource]: {
        data: state.entities[resource].data,
        query: queries[queryKey] || { data: [], count: -1 },
        queries,
        metadata: state.entities[resource].metadata,
        isFinished: Boolean(
          querySelectors.isFinished(state.queries, config) &&
            querySelectors.lastUpdated(state.queries, config),
        ),
        isPending: querySelectors.isPending(state.queries, config),
        lastUpdated: querySelectors.lastUpdated(state.queries, config),
        ...(selectData
          ? {
              selectedData: selectData(
                state.entities[resource].data,
                state,
                props,
              ),
            }
          : {}),
      },
    };
  };
};
