import React from "react";

import { Col, Row } from "react-bootstrap";

import { Loading, SupportLink } from "common/components";
import PaymentHistoryPagination from "./PaymentHistoryPagination";
import PaymentHistoryResult from "./PaymentHistoryResult";

import types from "resources/types";
import { createResourceListQuery, createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";

const SupportMessage = () => (
  <p>
    If you have a question about payments, please contact <SupportLink />
  </p>
);

const NoPaymentsMessage = () => (
  <p>
    Once you have made or received a payment on Hire an Esquire, it will be
    listed on this page.
  </p>
);

const PaymentHistoryResults = props => {
  const {
    [types.PAYMENT_HISTORY]: {
      data,
      isFinished,
      isPending,
      query: { count, data: queryData },
    },
    pageNumber,
    pageSize,
    onNextPageClick,
    onPreviousPageClick,
  } = props;

  const handleNextPageClick = () => {
    if (!isFinished) return;
    const nextPageStartingAfter = queryData[queryData.length - 1];
    const currentPage = [queryData[0], nextPageStartingAfter];
    onNextPageClick(currentPage);
  };

  const handlePreviousPageClick = () => {
    if (!isFinished) return;
    const currentPage = [queryData[0], queryData[queryData.length - 1]];
    onPreviousPageClick(currentPage);
  };

  if (isFinished && count === 0) {
    return (
      <div className="text-center">
        <NoPaymentsMessage />
        <SupportMessage />
      </div>
    );
  }

  return (
    <div>
      {isPending && <Loading />}
      {isFinished && (
        <React.Fragment>
          <Row>
            {queryData.map((transactionId, i) => (
              <Col key={i} sm={6}>
                <PaymentHistoryResult paymentHistory={data[transactionId]} />
              </Col>
            ))}
          </Row>
          <div className="text-center">
            <PaymentHistoryPagination
              totalCount={count}
              pageNumber={pageNumber}
              pageSize={pageSize}
              onPreviousPageClick={handlePreviousPageClick}
              onNextPageClick={handleNextPageClick}
            />
            <SupportMessage />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const createPaymentHistoryQuery = ({ startingAfter, pageSize }) => {
  const pageCursorParams = startingAfter
    ? `&starting_after=${startingAfter}`
    : "";

  return createResourceListQuery(
    types.PAYMENT_HISTORY,
    {
      url: `/api/v2/payment_history/?limit=${pageSize}${pageCursorParams}`,
    },
    "transaction_id",
  );
};

const mapPropsToConfig = createPaymentHistoryQuery;
const mapStateToProps = createResourceSelector(
  types.PAYMENT_HISTORY,
  mapPropsToConfig,
);
export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  PaymentHistoryResults,
);
