import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";

import Onboard from "../../blocks";
import DeleteModal from "../../DeleteModal";
import { ProficiencyTooltipCopy } from "./copy";
import ToolTip from "common/components/ToolTip";

const MobileEntry = ({
  last,
  languageFluency,
  languageFluency: { language, fluency },
  onEditClick,
  onShowDeleteModal,
}) => (
  <div>
    <br />
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>LANGUAGE</Onboard.EntryDataLabel>
      <Col xs={6}>{language}</Col>
    </Onboard.EntryDataRow>
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>
        PROFICIENCY{" "}
        <ToolTip content={<ProficiencyTooltipCopy />} placement="bottom">
          <i className="far fa-info-circle" />
        </ToolTip>
      </Onboard.EntryDataLabel>
      <Col xs={6}>{fluency}</Col>
    </Onboard.EntryDataRow>
    <br />
    <Onboard.EntryDataRow>
      <Col xs={6}>
        <Onboard.TableButton
          bsStyle="primary"
          block
          onClick={() => onEditClick(languageFluency)}
        >
          Edit
        </Onboard.TableButton>
      </Col>
      <Col xs={6}>
        <Onboard.TableButton
          block
          bsStyle="danger"
          onClick={() => onShowDeleteModal(languageFluency)}
        >
          Delete
        </Onboard.TableButton>
      </Col>
    </Onboard.EntryDataRow>
    <br />
    {!last && <Onboard.ContainerDivider />}
  </div>
);

const DesktopEntry = ({
  languageFluency,
  languageFluency: { language, fluency },
  onEditClick,
  onShowDeleteModal,
}) => (
  <tr>
    <td>{language}</td>
    <td>{fluency}</td>
    <Onboard.TableButtonCell>
      <Onboard.TableButton
        bsStyle="primary"
        margin="0 5px 0 0"
        onClick={() => onEditClick(languageFluency)}
      >
        Edit
      </Onboard.TableButton>
      <Onboard.TableButton
        bsStyle="danger"
        onClick={() => onShowDeleteModal(languageFluency)}
      >
        Delete
      </Onboard.TableButton>
    </Onboard.TableButtonCell>
  </tr>
);

export default class Entry extends Component {
  constructor(props) {
    super(props);
    this.state = { deletingLanguageFluency: null, showDeleteModal: false };
  }

  handleShowDeleteModal = deletingLanguageFluency =>
    this.setState({ deletingLanguageFluency, showDeleteModal: true });

  handleHideDeleteModal = () =>
    this.setState({ deletingLanguageFluency: null, showDeleteModal: false });

  render() {
    const {
      state: { deletingLanguageFluency, showDeleteModal },
      props: { last, languageFluency, mobile, onDeleteClick, onEditClick },
      handleHideDeleteModal,
      handleShowDeleteModal,
    } = this;
    const entryProps = {
      languageFluency,
      onShowDeleteModal: handleShowDeleteModal,
      onEditClick,
    };

    return (
      <Fragment>
        {mobile ? (
          <MobileEntry {...entryProps} last={last} />
        ) : (
          <DesktopEntry {...entryProps} />
        )}
        {deletingLanguageFluency && (
          <DeleteModal
            title="Delete Language Entry"
            onDeleteClick={onDeleteClick}
            onHide={handleHideDeleteModal}
            show={showDeleteModal}
            uuid={deletingLanguageFluency.uuid}
          >
            <p>
              {`Are you sure you want to delete ${
                deletingLanguageFluency.language
              } with proficiency (${deletingLanguageFluency.fluency})?`}
            </p>
          </DeleteModal>
        )}
      </Fragment>
    );
  }
}
