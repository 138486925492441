import React, { Component } from "react";
import { reduxForm } from "redux-form";
import moment from "moment";

import { Col, FormGroup, HelpBlock, Row } from "react-bootstrap";
import JobsDropdown from "admin/containers/JobsDropdown";

import { DatePickerField } from "common/components/forms/inputs";

class ApplicationsStatsForm extends Component {
  render() {
    const { error, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {error && (
          <FormGroup validationState="error">
            <Col xs={12}>
              <HelpBlock>{error}</HelpBlock>
            </Col>
          </FormGroup>
        )}

        <Row>
          <Col md={12} lg={6}>
            <DatePickerField
              name="start_date"
              placeholderText={moment().format("YYYY-MM-DD")}
              showMonthDropdown
              showYearDropdown
              todayButton={"Today"}
              dropdownMode="select"
            />
          </Col>
          <Col md={12} lg={6}>
            <DatePickerField
              name="end_date"
              placeholderText={moment().format("YYYY-MM-DD")}
              showMonthDropdown
              showYearDropdown
              todayButton={"Today"}
              dropdownMode="select"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FormGroup>
              <JobsDropdown name="job" />
            </FormGroup>
          </Col>
        </Row>
      </form>
    );
  }
}

ApplicationsStatsForm = reduxForm({
  form: "applicationsStats",
  initialValues: {
    start_date: null,
    end_date: null,
    job: null,
  },
})(ApplicationsStatsForm);

export default ApplicationsStatsForm;
