export const formName = "jobPaymentDetailsForm";

export const normalizeRate = value => {
  // Limits the rate field to 2 decimal places.
  const twoDecimalPlacesMax = /^\d+(\.\d{0,2})?$/;
  if (value !== undefined) {
    if (!twoDecimalPlacesMax.test(value)) {
      const formatted = (Math.floor(parseFloat(value) * 100) / 100).toFixed(2);
      if (!isNaN(formatted)) {
        return formatted;
      }

      return "";
    }
  }
  return value;
};

export {
  default as paymentDetailsComponents,
} from "./paymentDetailsComponents";
