import { createSelector } from "reselect";

const getVerified = state => {
  if (!state.hiringAgentDashboard) {
    return "Admin";
  }
  return state.hiringAgentDashboard.agency.agency.verified;
};
export const verifiedSelector = createSelector(
  getVerified,
  verified => verified === "Verified" || verified === "Admin",
);

// Returns a selector that selects once slice of the reducer
const createSliceSelector = namespace =>
  createSelector(
    state => state.hiringAgentDashboard[namespace],
    slice => ({ [namespace]: slice }),
  );

export const agencySliceSelector = createSliceSelector("agency");
export const applicationsSliceSelector = createSliceSelector("applications");
export const attorneysSliceSelector = createSliceSelector("attorneys");
export const invoicesSliceSelector = createSliceSelector("invoices");
export const jobsSliceSelector = createSliceSelector("jobs");
export const messagesSliceSelector = createSliceSelector("messages");
export const notificationsSliceSelector = createSliceSelector("notifications");
export const onboardingSliceSelector = createSliceSelector("onboarding");
export const recommendationsSliceSelector = createSliceSelector(
  "recommendations",
);
export const sidebarSliceSelector = createSliceSelector("sidebar");

export const agencySelector = createSelector(
  state => state.hiringAgentDashboard.agency.agency,
  agency => agency,
);

export const applicationItemsSelector = createSelector(
  state => state.hiringAgentDashboard.applications.applications,
  applications => applications,
);

export const hiringAgentSelector = createSelector(
  state => state.hiringAgentDashboard.sidebar.hiringAgent,
  hiringAgent => hiringAgent,
);

export const hiringAgentsSelector = createSelector(
  state => state.hiringAgentDashboard.hiringAgents,
  hiringAgents => hiringAgents,
);

export const hiringAgentsItemsSelector = createSelector(
  state => state.hiringAgentDashboard.hiringAgents.hiringAgents,
  hiringAgents => hiringAgents,
);

export const invoiceItemsSelector = createSelector(
  state => state.hiringAgentDashboard.invoices.invoices,
  invoices => invoices,
);

export const jobsSelector = createSelector(
  state => state.hiringAgentDashboard.jobs,
  jobs => jobs,
);

export const paymentsSelector = createSelector(
  state => state.hiringAgentDashboard.payments,
  payments => payments,
);

export const paymentSourcesItemsSelector = createSelector(
  state => state.hiringAgentDashboard.payments.paymentSources,
  paymentSources => paymentSources,
);

export const recommendationItemsSelector = createSelector(
  state => state.hiringAgentDashboard.recommendations.recommendations,
  recommendations => recommendations,
);

export const reviewsSelector = createSelector(
  state => state.hiringAgentDashboard.reviews,
  reviews => reviews,
);

const getJobId = (_, props) => props.match.params.jobId;

const getRecommendationsForJob = (jobId, recommendations) =>
  Object.values(recommendations).filter(rec => rec.jobposting_id === jobId);

// TODO: Refactor
export const recommendationsForJobSelector = createSelector(
  getJobId,
  recommendationsSliceSelector,
  recommendationItemsSelector,
  (jobId, recommendationsSlice, recommendations) => ({
    recommendations: {
      ...recommendationsSlice.recommendations,
      recommendations: getRecommendationsForJob(jobId, recommendations),
    },
  }),
);
