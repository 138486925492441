// @flow
import { type Uuid, type DateTime } from "./primitives";
import { keyForValue } from "common/utils";

export default class JobOffer {
  uuid: Uuid;
  candidate: Uuid;
  status: number;
  expiration_date: DateTime;
  rejected_reason: string;
  created: DateTime;
  updated: DateTime;

  constructor(jobOffer: Object) {
    this.uuid = jobOffer.uuid;
    this.candidate = jobOffer.candidate;
    this.status = jobOffer.status;
    this.expiration_date = jobOffer.expiration_date;
    this.rejected_reason = jobOffer.rejected_reason;
    this.created = jobOffer.created;
    this.updated = jobOffer.updated;
  }

  static STATUS_TYPES = {
    PENDING: 0,
    EXPIRED: 1,
    REJECTED: 2,
    INACTIVE: 3,
    ACCEPTED: 4,
  };

  // create class to extend to get these static methods
  static statusTypeDisplay(typeValue: any): ?string {
    return keyForValue(typeValue, this.STATUS_TYPES);
  }

  static statusTypeValue(display: any): string {
    return this.STATUS_TYPES[display];
  }
}
