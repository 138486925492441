import styled, { css } from "styled-components";

const Collapsible = styled.p`
  clear: both;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 0.35s ease-out;
  white-space: pre-line;

  ${({ open }) =>
    !open &&
    css`
      background: -webkit-linear-gradient(black, white);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `};
`;

export default Collapsible;
