import types from "resources/types";
import { createResourceDetailQuery, createResourceListQuery } from "queries";

export const contractorsQuery = (config = {}) =>
  createResourceListQuery(types.CONTRACTORS, {
    url: "/api/dev/contractors/?limit=99",
    ...config,
  });

export const ownContractorQuery = (config = {}) =>
  createResourceDetailQuery(types.OWN_CONTRACTOR, {
    url: "/api/dev/contractors/self/",
    ...config,
  });

export const contractorByIdQuery = props =>
  createResourceDetailQuery(types.CONTRACTORS, {
    url: `/api/dev/contractors/${props.match.params.contractorId}`,
  });
