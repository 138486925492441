import { redirectOnUnauthorizedResponse } from "common/api/api";
import { getCookie } from "common/utils";
import { createApiUrl } from "utils";

export function get(url, extraHeaders = {}) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...extraHeaders,
  };
  return fetch(createApiUrl(url), {
    headers,
    credentials: "include",
  }).then(response => response.json().then(json => ({ json, response })));
}

export function del(url, extraHeaders = {}) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
    ...extraHeaders,
  };
  return fetch(createApiUrl(url), {
    headers,
    credentials: "include",
    method: "DELETE",
  });
}

export function patch(url, data = {}, extraHeaders = {}) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
    ...extraHeaders,
  };
  return fetch(createApiUrl(url), {
    headers,
    body: JSON.stringify(data),
    credentials: "include",
    method: "PATCH",
  }).then(response => response.json().then(json => ({ json, response })));
}

export function put(url, data = {}, extraHeaders = {}) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
    ...extraHeaders,
  };
  return fetch(createApiUrl(url), {
    headers,
    body: JSON.stringify(data),
    credentials: "include",
    method: "PUT",
  }).then(response => response.json().then(json => ({ json, response })));
}

export function post(url, data = {}, extraHeaders = {}) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
    ...extraHeaders,
  };
  return fetch(createApiUrl(url), {
    headers,
    body: JSON.stringify(data),
    credentials: "include",
    method: "POST",
  }).then(response => {
    redirectOnUnauthorizedResponse(response);
    return response.json().then(json => ({ json, response }));
  });
}

export default {
  get,
  del,
  patch,
  put,
  post,
};
