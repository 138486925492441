import { connect } from "react-redux";
import { compose } from "redux";
import { connectRequest } from "redux-query-react";

export { compose, connect, connectRequest };
export { mutateAsync, requestAsync } from "redux-query";
export { default as types } from "resources/types";

export const areResourcesReady = resources =>
  resources.every(resource => !resource.isPending && resource.lastUpdated);

// Combines connect and connectRequest with compose
export const connectConnectRequest = (
  mapPropsToConfigs,
  mapStateToProps,
  mapDispatchToProps,
) =>
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    connectRequest(mapPropsToConfigs),
  );
