import actions from "admin/actions/attorneyVerificationManager";
import { listToObject } from "admin/utils";
import createReducer from "common/reducers/utils";

const handleReceiveEmailVerifications = (state, action) => ({
  ...state,
  emailVerifications: {
    ...state.emailVerifications,
    ...listToObject(
      action.payload.results ? action.payload.results : [action.payload],
      "uuid",
    ),
  },
});

const handleReceiveCreateEmailVerification = (state, action) => ({
  ...state,
  emailVerifications: {
    [action.payload.uuid]: action.payload,
    ...state.emailVerifications,
  },
});

const handlers = {
  "RECEIVE/emailVerificationSet": handleReceiveEmailVerifications,
  "RECEIVE/createEmailVerification": handleReceiveCreateEmailVerification,
  [actions.REMOVE_EMAIL_VERIFICATION]: (state, action) => {
    const {
      [action.payload]: _,
      ...emailVerifications
    } = state.emailVerifications;
    return {
      ...state,
      emailVerifications,
    };
  },
};

export default createReducer("emailVerifications", null, handlers);
