// @flow strict
import styled from "styled-components";

const InfoCircle = styled.i.attrs({
  className: "far fa-info-circle",
  "aria-hidden": "true",
})`
  justify-self: center;
  align-self: center;
  margin: 0 7px 5px 7px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary_black40()};
`;

export default InfoCircle;
