import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import { compose, connect, connectRequest } from "queries/utils";
import { createResourceSelectorConfig } from "queries";
import {
  promoCodeRedemptionsQuery,
  referralCodeRedemptionsQuery,
} from "queries/requests/discountCodes";
import { getResourcesAreReady } from "common/utils/helpers";
import types from "resources/types";
import Loading from "common/components/Loading";

const requiredResources = [
  types.PROMO_CODE_REDEMPTIONS,
  types.REFERRAL_CODE_REDEMPTIONS,
];

// This works given two assumptions:
// * There can only be one active promo code at a time.
// * Referral codes balances are always a dollar amount.
const parseDiscountCodeTotals = (promosData, referralsData) => {
  const p = Object.values(promosData);
  const r = Object.values(referralsData);
  let pTotal = null;
  let rTotal = null;

  if (p && p.length) {
    const { promo_code_data } = p.find(el => el.status === 0);
    const { amount_type, discount_amount } = promo_code_data;
    const amount = Math.round(discount_amount);
    const discount = amount_type === "1" ? `${amount}%` : `$${amount}`;
    pTotal = discount;
  }

  if (r && r.length) {
    let total = 0;
    for (let i = 0; i < r.length; i++) {
      const {
        current_sender_balance,
        current_recipient_balance,
        sender_or_recipient,
      } = r[i];
      if (sender_or_recipient === "sender") {
        total += Number(current_sender_balance);
      } else if (sender_or_recipient === "recipient") {
        total += Number(current_recipient_balance);
      }
    }
    rTotal = `$${Math.round(total)}`;
  }

  return { pTotal, rTotal };
};

class DiscountCodesTotals extends Component {
  constructor(props) {
    super(props);
    this.state = { fetched: false, pTotal: null, rTotal: null };
  }

  componentDidMount() {
    this.getDiscountTotals();
  }

  componentDidUpdate() {
    this.getDiscountTotals();
  }

  getDiscountTotals = () => {
    const {
      promoCodeRedemptions: { data: pData },
      referralCodeRedemptions: { data: rData },
    } = this.props;
    const isReady = getResourcesAreReady(requiredResources, this.props);

    if (!this.state.fetched && isReady) {
      const { pTotal, rTotal } = parseDiscountCodeTotals(pData, rData);
      this.setState({ fetched: true, pTotal, rTotal });
    }
  };

  render() {
    const {
      state: { pTotal, rTotal },
      props: { alert, showLink },
    } = this;
    const isReady = getResourcesAreReady(requiredResources, this.props);
    const Wrapper = alert ? Alert : "p";

    return isReady ? (
      pTotal || rTotal ? (
        <Wrapper>
          {pTotal &&
            `You have a ${pTotal} discount from promo codes which will be applied
      to your next invoice.`}
          {pTotal && rTotal && " "}
          {rTotal &&
            `You have ${rTotal} in discounts from referral codes which will be
      applied to future invoices.`}
          {showLink && (
            <>
              {" "}
              View your promo/referral code information in{" "}
              <Link to="/agency/settings/discount-codes/">
                account settings
              </Link>.
            </>
          )}
        </Wrapper>
      ) : null
    ) : (
      <Loading />
    );
  }
}

const promoCodeRedemptionsSelector = createResourceSelectorConfig(
  types.PROMO_CODE_REDEMPTIONS,
  promoCodeRedemptionsQuery,
);

const referralCodeRedemptionsSelector = createResourceSelectorConfig(
  types.REFERRAL_CODE_REDEMPTIONS,
  referralCodeRedemptionsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...promoCodeRedemptionsSelector,
  ...referralCodeRedemptionsSelector,
});

const mapPropsToConfig = () => [
  promoCodeRedemptionsQuery(),
  referralCodeRedemptionsQuery(),
];

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(DiscountCodesTotals);
