import actions from "admin/actions/attorneyVerificationManager";
import { listToObject } from "admin/utils";
import createReducer from "common/reducers/utils";

// TODO: Create handler creator function for verifications
const handleReceiveBarcardVerifications = (state, action) => ({
  ...state,
  barcardVerifications: {
    ...state.barcardVerifications,
    ...listToObject(
      action.payload.results ? action.payload.results : [action.payload],
      "uuid",
    ),
  },
});

const handleReceiveCreateBarcardVerification = (state, action) => ({
  ...state,
  barcardVerifications: {
    [action.payload.uuid]: action.payload,
    ...state.barcardVerifications,
  },
});

const handlers = {
  "RECEIVE/barcardVerificationSet": handleReceiveBarcardVerifications,
  "RECEIVE/createBarcardVerification": handleReceiveCreateBarcardVerification,
  [actions.REMOVE_BARCARD_VERIFICATION]: (state, action) => {
    const {
      [action.payload]: _,
      ...barcardVerifications
    } = state.barcardVerifications;
    return {
      ...state,
      barcardVerifications,
    };
  },
};

export default createReducer("barcardVerifications", null, handlers);
