import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import Loading from "common/components/Loading";
import { CandidateContractorTableRow } from "hiringagent-dashboard/components/jobs/job-details";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

const MAX_CANDIDATES_TO_RETRIEVE = 10;

export const JobCandidates = props => {
  const {
    jobId,
    [types.CANDIDATES]: {
      data: candidates,
      query: { count: candidatesCount, data: ordering },
      isPending,
      isFinished,
    },
  } = props;

  const candidatesLink = `/agency/candidates/job/${jobId}`;
  const recommendationsLink = `/agency/jobs/create/${jobId}/recommended_candidates/`;
  const searchLink = "/agency/search/";

  const jobCandidateIds = ordering
    .filter(candidateId => candidates[candidateId].job === jobId)
    .splice(0, MAX_CANDIDATES_TO_RETRIEVE);
  const jobHasCandidates = isFinished && candidatesCount > 0;

  return (
    <div className="main-info-container">
      <div className="job-applications-header">
        <h2 className="main-info-title" style={{ display: "inline-block" }}>
          Candidates
        </h2>
        {jobHasCandidates && (
          <div className="job-manage-applications">
            <Link to={candidatesLink}>Manage Candidates</Link>
          </div>
        )}
      </div>
      {isPending ? (
        <Loading />
      ) : (
        <div>
          <Table condensed>
            <tbody>
              {/* TODO: Show loading while any candidate contractors are loading */}
              {jobCandidateIds
                .map(candidateId => candidates[candidateId])
                .map(candidate => (
                  <CandidateContractorTableRow
                    key={`candidate-${candidate.uuid}`}
                    candidate={candidate}
                  />
                ))}
            </tbody>
          </Table>
          {candidatesCount > MAX_CANDIDATES_TO_RETRIEVE && (
            <div>
              <p className="text-center">
                Showing {MAX_CANDIDATES_TO_RETRIEVE} out of {candidatesCount}{" "}
                candidates.
              </p>
              <p className="text-center">
                <Link to={candidatesLink}>Click here</Link> to view all
                candidates.
              </p>
            </div>
          )}
          {candidatesCount === 0 && (
            <p className="text-center">
              You don&apos;t have any candidates yet. Check out our{" "}
              <Link to={recommendationsLink}>recommendations</Link> or try{" "}
              <Link to={searchLink}>searching</Link>.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const mapPropsToConfig = props =>
  createResourceListQuery(types.CANDIDATES, {
    url: `/api/v2/candidates/?job=${props.jobId}&ordering=-updated&limit=${MAX_CANDIDATES_TO_RETRIEVE}`,
  });

const mapStateToProps = createResourceSelector(
  types.CANDIDATES,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobCandidates);
