import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { verifiedSelector } from "hiringagent-dashboard/selectors";
import ToolTip from "common/components/ToolTip";
import AttorneyPracticeAreasGroupsList from "hiringagent-dashboard/components/attorney/AttorneyPracticeAreasGroupsList";
import {
  ContractorProfileActions,
  ContractorProfileInfoHeader,
} from "common/components/contractor-profile";
import { SavedContractorButton } from "hiringagent-dashboard/components/talentPools";

const ctaMessage =
  "Sign up to view all Hire an Esquire legal professionals and have full access to platform features.";
const talentPoolButtonCTA = (
  <ToolTip content={ctaMessage} placement="top">
    <Link to="/signup/" className="talentpools-button">
      <i className={"far fa-heart"} /> Save to Talent Pools
    </Link>
  </ToolTip>
);

const SignupCTA = ({ isPublicProfile }) => (
  <div style={{ textAlign: "center" }}>
    <hr />
    <span style={isPublicProfile ? { fontSize: "2.2rem" } : {}}>
      <Link to="/signup/">Sign up now</Link> to see more great candidates!
      Already a member? <a href="/login/">Log in now</a>.
    </span>
    <hr />
  </div>
);

class ContractorProfileHeader extends Component {
  static propTypes = {
    contractor: PropTypes.object.isRequired,
    isOwnContractor: PropTypes.bool,
    isPublicProfile: PropTypes.bool,
    isSharedProfile: PropTypes.bool,
    isRecommendation: PropTypes.bool,
  };

  static defaultProps = {
    isOwnContractor: false,
    isPublicProfile: false,
    isSharedProfile: false,
    isRecommendation: false,
  };

  render() {
    const {
      contractor,
      isPublicProfile,
      isSharedProfile,
      isRecommendation,
      isVerified,
    } = this.props;

    const showSignupCTA = isSharedProfile || isPublicProfile;
    const showActions =
      !isSharedProfile && !isPublicProfile && !isRecommendation && isVerified;
    const showTalentPoolsButton = showActions || isPublicProfile;

    const talentPoolsButtonDisplay = isPublicProfile ? (
      talentPoolButtonCTA
    ) : (
      <SavedContractorButton contractorId={contractor.uuid} />
    );

    return (
      <div className="attorney-header-container">
        <ContractorProfileInfoHeader {...this.props} />

        {/* Display on desktop */}
        <Row className="header-tags-row hidden-xs">
          <Col sm={2}>
            {showActions && (
              <ContractorProfileActions contractor={contractor} />
            )}
          </Col>
          <Col sm={7}>
            <div className="practice-areas">
              <AttorneyPracticeAreasGroupsList
                attorneyUuid={contractor.uuid}
                practiceAreas={contractor.practice_areas.map(pa => ({
                  text: pa.name,
                  name: pa.name,
                  group: pa.group,
                }))}
              />
            </div>
          </Col>
          <Col sm={3} className="text-right">
            {showTalentPoolsButton && (
              <SavedContractorButton contractorId={contractor.uuid} />
            )}
          </Col>
        </Row>
        {/* Display on mobile */}
        <Row className="header-tags-row visible-xs">
          <Col sm={8}>
            <div className="practice-areas">
              <AttorneyPracticeAreasGroupsList
                attorneyUuid={contractor.uuid}
                practiceAreas={contractor.practice_areas.map(pa => ({
                  text: pa.name,
                  name: pa.name,
                  group: pa.group,
                }))}
              />
            </div>
          </Col>
          <Col sm={12}>
            {showTalentPoolsButton && (
              <div className="fav-btn-mobile">{talentPoolsButtonDisplay}</div>
            )}
          </Col>
          <Col sm={2}>
            {showActions && (
              <ContractorProfileActions contractor={contractor} />
            )}
          </Col>
        </Row>

        {showSignupCTA && <SignupCTA isPublicProfile={isPublicProfile} />}
      </div>
    );
  }
}

export default connect(state => ({
  isVerified: verifiedSelector(state),
}))(ContractorProfileHeader);
