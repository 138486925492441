import React, { Component } from "react";

import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { LinkContainer } from "react-router-bootstrap";
import { ButtonToolbar } from "react-bootstrap";
import { addNotification } from "reapop";
import { Candidate, JobOffer } from "types";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

import Actions from "./blocks";
import { createErrorNotification } from "common/utils/notifications";
import {
  CandidateOfferModal,
  CandidateRejectModal,
} from "hiringagent-dashboard/components/candidates/CandidateActionsModal";
import { addMessageRecipientIds } from "hiringagent-dashboard/actions/messages";
import { create as createJobOffer } from "queries/mutations/job-offers";
import { create as createCandidateRejection } from "queries/mutations/candidate-rejections";
import { createCandidateQuery } from "queries/requests/candidates";

const { ActionButton } = Actions;

const isOfferExpiredOrRejected = jobOffer =>
  ["EXPIRED", "REJECTED"].includes(JobOffer.statusTypeDisplay(jobOffer.status));

const placeholder = (
  <ButtonToolbar style={{ visibility: "hidden" }}>
    <ActionButton bsStyle="success">Offer</ActionButton>
    <ActionButton bsStyle="danger">Reject</ActionButton>
    <LinkContainer to="/agency/messages/create/">
      <ActionButton bsStyle="info">Message</ActionButton>
    </LinkContainer>
  </ButtonToolbar>
);

export class CandidateActions extends Component {
  state = {
    showOfferModal: false,
    showRejectModal: false,
    submitting: false,
  };

  get canOffer() {
    const {
      candidate,
      [types.JOB_OFFERS]: {
        data: jobOffersData,
        query: { data: jobOffersQueryData },
      },
    } = this.props;

    const jobOffers = jobOffersQueryData.map(offerId => jobOffersData[offerId]);

    const isCandidatePending =
      candidate.status === Candidate.STATUS_TYPES.PENDING;
    const isCandidateNegotiating =
      candidate.status === Candidate.STATUS_TYPES.NEGOTIATING;

    let allOffersExpiredOrRejected = false;
    if (jobOffers.length) {
      allOffersExpiredOrRejected = jobOffers.every(isOfferExpiredOrRejected);
    }

    return (
      isCandidatePending ||
      (isCandidateNegotiating && allOffersExpiredOrRejected)
    );
  }

  get canReject() {
    const { candidate } = this.props;

    const isCandidatePending =
      candidate.status === Candidate.STATUS_TYPES.PENDING;
    const isCandidateNegotiating =
      candidate.status === Candidate.STATUS_TYPES.NEGOTIATING;

    return isCandidatePending || isCandidateNegotiating;
  }

  toggleModal = (actionType, shouldOpen) =>
    actionType === "OFFER"
      ? this.setState({ showOfferModal: shouldOpen })
      : this.setState({ showRejectModal: shouldOpen });

  handleOpenModal = actionType => () => {
    this.toggleModal(actionType, true);
  };

  handleCloseModal = actionType => () => {
    this.toggleModal(actionType, false);
  };

  handleRejectClick = () => {
    this.handleActionClick(this.props.reject, "REJECT");
  };

  handleOfferClick = () => {
    this.handleActionClick(this.props.offer, "OFFER");
  };

  handleActionClick(action, actionType) {
    const {
      candidate: { uuid },
      refreshCandidate,
      notify,
    } = this.props;
    const data = { candidate: uuid };

    this.setState({ submitting: true }, () => {
      action(data).then(({ status, body }) => {
        if (!(status >= 200 && status < 300)) {
          const { non_field_errors } = body;
          const message = non_field_errors ? non_field_errors[0] : null;
          const errorMessage = message ? { message } : null;
          notify(createErrorNotification(errorMessage));
        } else {
          this.toggleModal(actionType, false);
        }
        this.setState({ submitting: false });
        return refreshCandidate(uuid);
      });
    });
  }

  render() {
    const {
      contractor,
      job,
      addMessageRecipientIds,
      [types.JOB_OFFERS]: { isPending },
    } = this.props;
    const { showOfferModal, showRejectModal, submitting } = this.state;

    if (isPending) return placeholder;

    const { canOffer, canReject } = this;

    return (
      <div>
        <CandidateOfferModal
          show={showOfferModal}
          name={contractor.full_name}
          job={job}
          onClose={this.handleCloseModal("OFFER")}
          onSubmit={this.handleOfferClick}
          submitting={submitting}
        />
        <CandidateRejectModal
          show={showRejectModal}
          name={contractor.full_name}
          job={job}
          onClose={this.handleCloseModal("REJECT")}
          onSubmit={this.handleRejectClick}
          submitting={submitting}
        />
        <Actions.ButtonContainer>
          {canOffer && (
            <ActionButton
              bsStyle="success"
              onClick={this.handleOpenModal("OFFER")}
            >
              Offer
            </ActionButton>
          )}
          {canReject && (
            <ActionButton
              bsStyle="danger"
              onClick={this.handleOpenModal("REJECT")}
            >
              Reject
            </ActionButton>
          )}
          <LinkContainer
            to="/agency/messages/create/"
            onClick={() => addMessageRecipientIds([contractor.uuid])}
          >
            <ActionButton bsStyle="info">Send Message</ActionButton>
          </LinkContainer>
        </Actions.ButtonContainer>
      </div>
    );
  }
}

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOB_OFFERS, {
    url: `/api/v2/job_offers/?candidate=${props.candidate.uuid}`,
  });

const jobOffersSelector = createResourceSelector(
  types.JOB_OFFERS,
  mapPropsToConfig,
);

const candidateQuery = candidateId =>
  createCandidateQuery({ force: true }, candidateId);

export default compose(
  connect(
    jobOffersSelector,
    {
      addMessageRecipientIds,
      offer: data => mutateAsync(createJobOffer(data)),
      reject: data => mutateAsync(createCandidateRejection(data)),
      refreshCandidate: candidateId =>
        requestAsync(candidateQuery(candidateId)),
      notify: addNotification,
    },
  ),
  connectRequest(mapPropsToConfig),
)(CandidateActions);
