import actions from "admin/actions/jobsDropdown";
import { handleActions } from "redux-actions";

const initialState = {
  jobOptions: [],
  jobsLoading: false,
  jobsLoaded: false,
};

const jobsDropdown = handleActions(
  {
    [actions.REQUEST_JOBS_FOR_DROPDOWN]: state => ({
      ...state,
      jobsLoading: true,
    }),
    [actions.RECEIVE_JOBS_FOR_DROPDOWN]: (state, action) => ({
      ...state,
      jobOptions: action.payload.map(job => ({
        value: job.uuid,
        label: `${job.name} (${job.hiring_agency_name}) ${
          job.location ? `(${job.location})` : ""
        }`,
      })),
      jobsLoaded: true,
      jobsLoading: false,
    }),
  },
  initialState,
);
export default jobsDropdown;
