import keyMirror from "keymirror";
import { get } from "common/api/api";

const actions = keyMirror({
  REQUEST_FIXTURE: null,
  RECEIVE_FIXTURE: null,
});
export default actions;

const fixtureMapping = {
  tagGroups: "/api/dev/tag_groups/?limit=999",
  tags: "/api/dev/tags/?limit=999",
  degrees: "/api/v2/degree_choices/",
  firmTypes: "/api/v2/firm_types/",
  positionTypes: "/api/v2/position_types/?limit=99",
};

export const _requestFixture = fixture =>
  get(
    fixtureMapping[fixture],
    [actions.REQUEST_FIXTURE, actions.RECEIVE_FIXTURE],
    null,
    { fixture },
  );

export const requestFixture = fixture => (dispatch, getState) => {
  const {
    [fixture]: { lastUpdated, isLoading },
  } = getState().common.fixtures;
  if (!lastUpdated && !isLoading) {
    return dispatch(_requestFixture(fixture));
  }
  return undefined;
};
