export default [
  {
    value: 0,
    label: "0 years",
  },
  {
    value: 1,
    label: "1 year",
  },
].concat(
  [...Array(51).keys()]
    .slice(2, 51)
    .map(obj => ({ value: obj, label: `${obj} years` })),
);
