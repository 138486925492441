// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  itemType: string,
};

const Item: ComponentType<Props> = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 10px;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  margin: 0 0 15px 0;
  padding: 15px;
  border-radius: 5px;
  box-sizing: border-box;
  clear: both;
  ${({ itemType, theme }) =>
    itemType === "selected" &&
    css`
      position: relative;
      border: 1px solid ${theme.colors.primary_turquoise()};
      background-color: ${theme.colors.pale_turquoise()};
    `};
  ${({ itemType, theme, isDragging }) =>
    itemType === "unselected" &&
    css`
      position: static;
      border: 1px solid ${theme.colors.primary_black40()};
      background-color: ${theme.colors.background_lightgray()};
      box-shadow: 1px 1px 3px
        ${isDragging
          ? theme.colors.primary_black("12.5%")
          : theme.colors.primary_black("25%")};
    `};
  ${({ itemType, theme }) =>
    itemType === "locked" &&
    css`
      grid-template-columns: 1fr auto;
      border: 1px solid ${theme.colors.primary_turquoise()};
    `};
`;

export default Item;
