import React from "react";

import { ThreadPlaceholderWrapper } from "../blocks";
import logo from "img/scales.svg";

const ThreadPlaceholder = () => (
  <ThreadPlaceholderWrapper>
    <img
      alt="scales logo for Hire an Esquire"
      id="briefcase"
      width="auto"
      height="10%"
      src={logo}
    />
    <br />
    <p>Click on a message to continue a conversation.</p>
  </ThreadPlaceholderWrapper>
);

export default ThreadPlaceholder;
