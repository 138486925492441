import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";

class AlertPagination extends Component {
  state = {
    page: 0,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { children } = this.props;
    const { page } = this.state;
    if (
      nextProps.children.length < children.length &&
      page === children.length - 1
    ) {
      this.setState(state => ({ page: state.page - 1 }));
    }
  }

  handleNextPageClick = () => this.setState({ page: this.state.page + 1 });
  handlePreviousPageClick = () => this.setState({ page: this.state.page - 1 });

  render() {
    const { children } = this.props;
    const { page } = this.state;

    if (children.length === 0) return null;

    const pagination = children.length > 1 && (
      <Fragment>
        <Button
          onClick={this.handlePreviousPageClick}
          bsStyle="link"
          style={{ visibility: page > 0 ? "visible" : "hidden" }}
        >
          <i className="far fa-chevron-left" />
        </Button>
        <span>
          {page + 1} of {children.length}
        </span>
        <Button
          onClick={this.handleNextPageClick}
          bsStyle="link"
          style={{
            visibility: page < children.length - 1 ? "visible" : "hidden",
          }}
        >
          <i className="far fa-chevron-right" />
        </Button>
      </Fragment>
    );

    return <div>{React.cloneElement(children[page], { pagination })}</div>;
  }
}

export default AlertPagination;
