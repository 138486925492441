// @flow
import { type Uuid } from "./primitives";

export default class JobLocation {
  uuid: Uuid;
  joblisting: Uuid;
  city: string;
  state: string;
  latitude: string;
  longitude: string;

  constructor(jobLocation: Object) {
    this.uuid = jobLocation.uuid;
    this.joblisting = jobLocation.joblisting;
    this.city = jobLocation.city;
    this.state = jobLocation.state;
    this.latitude = jobLocation.latitude;
    this.longitude = jobLocation.longitude;
  }
}
