// @flow strict
import styled from "styled-components";
import { Button as ReactBootstrapButton } from "react-bootstrap";

const BigButtonAsLink = styled(ReactBootstrapButton).attrs(props => ({
  bsStyle: "link",
  onClick: props.onClick ? props.onClick : null,
}))`
  &&&& {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
`;

export default BigButtonAsLink;
