import React from "react";

import { PaymentHistory as BasePaymentHistory } from "common/components/payment-history";

const PaymentHistory = () => (
  <div>
    <h2 className="edit-section-title">Payment History</h2>
    <BasePaymentHistory />
  </div>
);

export default PaymentHistory;
