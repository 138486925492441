import React from "react";

import ResponsiveContainer from "common/components/ResponsiveContainer";
import { SupportLink } from "common/components";

export const ContactSupportLink = () => (
  <p className="text-muted small">
    Believe this is an error? Contact <SupportLink />
  </p>
);

const NotFound = ({ children }) => (
  <ResponsiveContainer>
    <h1>404: Not Found</h1>
    <p>Sorry, we couldn&apos;t find the page you were looking for.</p>
    {children}
    <ContactSupportLink />
  </ResponsiveContainer>
);
export default NotFound;
