import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

import Score from "./blocks";

const overlay = (
  <Popover id="popover-fit-score">
    <p>
      Fit Score data may be unavailable for this candidate or job for different
      reasons including any of the following:
    </p>
    <Score.Indent>
      <p>This candidate has yet to take their Workstyle Assessment.</p>
      <p>
        This candidate has taken their Workstyle Assessment but this job may not
        be posted with any requirements.
      </p>
      <p>
        This candidate&apos;s results are still pending and may take up to 24
        hours to be available.
      </p>
      <p>This job was posted before January 18th, 2019.</p>
    </Score.Indent>
  </Popover>
);

const Alternate = () => (
  <Score.Alternate>
    <Score.AlternateMain>
      <h3 className="text-uppercase">LOOKING FOR FIT SCORE?</h3>
      <OverlayTrigger
        trigger={["hover", "click"]}
        placement="bottom"
        overlay={overlay}
      >
        <Score.InfoCircle />
      </OverlayTrigger>
    </Score.AlternateMain>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://hireanesquire.com/fit-score-faq"
    >
      <Score.AlternateLink>MORE ABOUT FIT SCORE</Score.AlternateLink>
    </a>
  </Score.Alternate>
);

export default Alternate;
