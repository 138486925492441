import React, { Component } from "react";
import { withFixture } from "hiringagent-dashboard/connectors";
import NullOr from "common/components/NullOr";

class PositionTypesList extends Component {
  positionTypesForIds() {
    const {
      fixtures: {
        positionTypes: { options },
      },
      positionTypeIds,
    } = this.props;
    return options
      .filter(type => positionTypeIds.includes(type.uuid))
      .map(type => type.name);
  }

  render() {
    const {
      fixtures: {
        positionTypes: { isLoading, lastUpdated },
      },
      positionTypeIds,
    } = this.props;

    return (
      <NullOr isNull={isLoading || !lastUpdated || !positionTypeIds.length > 0}>
        <span>{this.positionTypesForIds().join(", ")}</span>
      </NullOr>
    );
  }
}

export default withFixture("positionTypes")(PositionTypesList);
