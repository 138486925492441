// @flow strict
import styled from "styled-components";

const QuestionGroup = styled.div`
  width: 100%;
  min-height: 61px;
  display: grid;
  margin: 0 0 50px 0;

  ${({ theme }) => theme.media.mobile`
    align-items: center;
    grid-template-rows: auto auto;
    grid-gap: 15px;
  `};

  ${({ theme }) => theme.media.desktop`
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  `};
`;

export default QuestionGroup;
