import {
  Auth,
  Candidate,
  HiringAgency,
  HiringAgent,
  Job,
  JobListing,
  JobLocation,
  JobOffer,
  JobWorker,
  PaymentDetails,
  RequirementResponse,
} from "types";

const types = {
  ADD_PROFILE_PICTURE: "addProfilePicture",
  AGENCY: "agency",
  APPLICATIONS: "applications",
  AUTH: "auth",
  BANK_ACCOUNTS: "bankAccounts",
  BARCARD_VERIFICATIONS: "barcardVerifications",
  BAR_INFORMATIONS: "barInformations",
  BYPASS: "bypass",
  CANDIDATES: "candidates",
  CANDIDATE_REJECTIONS: "candidateRejections",
  CLIENT_FEEDBACK_SURVEY: "clientFeedbackSurvey",
  CLIENT_FEEDBACK_SURVEY_RESPONSES: "clientFeedbackSurveyResponses",
  COMPETENCY_QUESTIONS: "competencyQuestions",
  COMPETENCY_QUESTION_RESPONSES: "competencyQuestionResponses",
  CONTRACTORS: "contractors",
  CURRENT_USER_TYPE: "currentUserType",
  DEGREE_CHOICES: "degreeChoices",
  DISCOUNT_CODES: "discountCodes",
  EXPENSES: "expenses",
  SAVED_CONTRACTORS: "savedContractors",
  TALENT_POOLS: "talentPools",
  FIRM_TYPES: "firmTypes",
  FIXED_RATE_OCCURRENCES: "fixedRateOccurrences",
  HIRING_AGENCY: "hiringAgency",
  HIRING_AGENCY_MARKETING_CONVERSIONS: "hiringAgencyMarketingConversions",
  HIRING_AGENTS: "hiringAgents",
  INVOICES: "invoices",
  INVOICE_ENTRIES: "invoiceEntries",
  JOBS: "jobs",
  JOB_ATTACHMENTS: "jobAttachments",
  JOB_LISTINGS: "jobListings",
  JOB_LOCATIONS: "jobLocations",
  JOB_NOTIFICATION_PREFERENCES: "jobNotificationPreferences",
  JOB_OFFERS: "jobOffers",
  JOB_OFFER_PERMISSION: "jobOfferPermission",
  JOB_PERMISSION: "jobPermission",
  JOB_REQUIREMENTS: "jobRequirements",
  JOB_WORKERS: "jobWorkers",
  MESSAGES: "messages",
  MESSAGE_THREADS: "messageThreads",
  METADATA: "metadata",
  NEW_FEATURE_NOTIFICATIONS: "newFeatureNotifications",
  NEW_ITEMS: "newItems",
  NOT_STARTED_NON_ATTORNEYS: "notStartedNonAttorneys",
  LANGUAGES: "languages",
  ONBOARDING_REQUIREMENTS: "onboardingRequirements",
  OWN_CONTRACTOR: "ownContractor",
  OWN_USER: "ownUser",
  PAYMENT_DETAILS: "paymentDetails",
  PAYMENT_HISTORY: "paymentHistory",
  PAYMENT_SOURCES: "paymentSources",
  PENDING_NON_ATTORNEYS: "pendingNonAttorneys",
  POSITION_TYPES: "positionTypes",
  PREDICTORS: "predictors",
  PREDICTOR_STATEMENTS: "predictorStatements",
  PREVIOUS_EMPLOYMENTS: "previousEmployments",
  PREVIOUS_JOB_WORKERS: "previousJobWorkers",
  PROMO_CODE_REDEMPTIONS: "promoCodeRedemptions",
  RECOMMENDATIONS: "recommendations",
  REFERRAL_EMAILS: "referralEmails",
  REFERRAL_CODE_REDEMPTIONS: "referralCodeRedemptions",
  REJECTED_NON_ATTORNEYS: "rejectedNonAttorneys",
  REPLY_TO_THREAD: "replyToThread",
  REVIEW_REQUESTS: "reviewRequests",
  REQUIREMENT_RESPONSES: "requirementResponses",
  SCHOOLS: "schools",
  SCHOOL_TRANSCRIPT: "schoolTranscript",
  STASH_PROFILE_PICTURE: "stashProfilePicture",
  TAGS: "tags",
  TAG_GROUPS: "tagGroups",
  TAG_GROUP_REQUIREMENTS: "tagGroupRequirements",
  TERMS_OF_SERVICE: "termsOfService",
  THREAD: "thread",
  TIME_ENTRIES: "timeEntries",
  VERIFIED_NON_ATTORNEYS: "verifiedNonAttorneys",
};

export default types;

export const typeMapping = {
  [types.AUTH]: Auth,
  [types.CANDIDATES]: Candidate,
  [types.HIRING_AGENCY]: HiringAgency,
  [types.HIRING_AGENTS]: HiringAgent,
  [types.JOBS]: Job,
  [types.JOB_LISTINGS]: JobListing,
  [types.JOB_LOCATIONS]: JobLocation,
  [types.JOB_OFFERS]: JobOffer,
  [types.JOB_WORKERS]: JobWorker,
  [types.PAYMENT_DETAILS]: PaymentDetails,
  [types.REQUIREMENT_RESPONSES]: RequirementResponse,
};
