import { createAction } from "redux-actions";
import keyMirror from "keymirror";

export const metaGenerator = () => ({ receivedAt: Date.now() });
export const createActions = actionTypes =>
  keyMirror(
    actionTypes.reduce((result, value) => ({ ...result, [value]: null }), {}),
  );

export const createCallback = (dispatch, actionType, actionData) => response =>
  dispatch(
    createAction(actionType, p => p, metaGenerator)({
      ...response,
      ...actionData,
    }),
  );

export const createErrorCallback = (dispatch, actionType) => error =>
  dispatch(createAction(actionType, p => p, metaGenerator)(new Error(error)));

export const createApiResourceRetriever = apiRequest => (
  url,
  actionTypes,
  data,
  actionData,
) => {
  const [requestType, receiveType] = actionTypes;
  return dispatch => {
    dispatch(createAction(requestType)(actionData));
    return apiRequest(url, data).then(
      createCallback(dispatch, receiveType, actionData),
      createErrorCallback(dispatch, receiveType),
    );
  };
};
