import React, { Fragment } from "react";

import Onboard from "../../blocks";
import StyledText from "common/blocks/StyledText";

export const MainCopy = ({ actionIsRequired }) => (
  <Fragment>
    <p>
      We require at least{" "}
      <StyledText textType={actionIsRequired ? "danger" : "black"} bold>
        one active bar license
      </StyledText>{" "}
      in order to verify your account. Please enter as many licenses as you have
      to increase your chances of landing your next role!
    </p>
    <p>
      Please upload <b>one of the following per license:</b>
    </p>
    <ul>
      <li>Bar Card or Attorney Secure Pass</li>
      <li>Document with a Registration Number</li>
      <li>Certificate of Good Standing</li>
      <li>Bar Registration Receipt</li>
      <li>Receipt of Payment of Bar Dues</li>
    </ul>
  </Fragment>
);

export const PendingVerificationCopy = () => (
  <p>
    While we verify your bar status, hit &quot;Save and Continue&quot; below to
    continue to the next step. We will send you an email once you have been
    verified, and you can always return to this page at any time to check your
    bar verification status.
  </p>
);

export const NoEntriesCopy = () => (
  <Onboard.TablePlaceholder>
    You don&apos;t have any bar licenses on file. Click &quot;Add a
    License&quot; to add.
  </Onboard.TablePlaceholder>
);

export const barIDTooltipCopy =
  "You can find your bar ID listed on dues payment receipts," +
  " your state bar website, bar card, or other official documentation.";
