// @flow strict
import styled from "styled-components";

const NameAndStatusContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  ${({ theme }) => theme.media.desktop`
    justify-content: center;
    margin-top: 0.7rem;
  `};
`;

export default NameAndStatusContainer;
