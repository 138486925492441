import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { Alert, Button, Col, Row, Well } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import keyMirror from "keymirror";
import { addNotification as notify } from "reapop";
import { requestAsync } from "redux-query";
import { Elements, StripeProvider } from "react-stripe-elements";

import { withMetadata } from "common/connectors";
import ActionButton from "hiringagent-dashboard/components/ActionButton";
import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";
import CreateBankAccountForm from "hiringagent-dashboard/forms/CreateBankAccountForm";
import CreateCardAccountForm from "hiringagent-dashboard/forms/CreateCardAccountForm";
import PaymentSourceTable from "hiringagent-dashboard/components/settings/PaymentSourceTable";
import {
  requestPaymentSources,
  setFocusedSource,
  submitPaymentSourceForm,
  submitDeleteSource,
  submitSetActiveSource,
  setVerificationAccountId,
  toggleModal,
} from "hiringagent-dashboard/actions/payments";
import {
  paymentsSelector,
  hiringAgentsItemsSelector,
} from "hiringagent-dashboard/selectors";
import VerifyBankDepositsModal from "hiringagent-dashboard/components/VerifyBankDepositsModal";
import { createResourceListQuery } from "queries";
import { agencyQuery } from "queries/requests/agencies";
import types from "resources/types";

const modals = keyMirror({
  ADD_BANK: null,
  ADD_CARD: null,
  DELETE_SOURCE: null,
  VERIFY_BANK: null,
});

const accountDoesNotExistMsg = {
  title: "Error",
  message:
    "We cannot find that bank account to verify. It may have been deleted.",
  status: "error",
  position: "tr",
  dismissible: true,
  dismissAfter: 3000,
};

const ModalBody = (action, sourceName) => (
  <span>
    Are you sure you want to {action} <strong>{sourceName}</strong>?
  </span>
);

const AddBankModal = props => (
  <ConfirmModal
    {...props}
    title="Add Bank Account"
    showFooter={false}
    body={<CreateBankAccountForm {...props} />}
  />
);

const AddCardModal = props => (
  <ConfirmModal
    {...props}
    title="Add Credit Card Account"
    showFooter={false}
    body={<CreateCardAccountForm {...props} />}
  />
);

const AddPaymentAccountButtons = ({ createActionClickHandler }) => (
  <div>
    <div className="settings-buttons-container pull-right">
      <Button
        bsStyle="success"
        style={{ marginRight: ".3rem" }}
        onClick={createActionClickHandler(modals.ADD_CARD)}
      >
        <i className="far fa-credit-card" aria-hidden="true" /> Add Card
      </Button>
      <Button
        bsStyle="success"
        onClick={createActionClickHandler(modals.ADD_BANK)}
      >
        <i className="far fa-university" aria-hidden="true" /> Add Bank
      </Button>
    </div>
    <span className="clearfix" />
  </div>
);

const PaymentAccountsExplanation = ({ hasPaymentAccount }) => (
  <Alert style={{ marginBottom: "2rem" }}>
    <ul>
      {!hasPaymentAccount && (
        <li>
          <strong>Add a payment account now: </strong>
          You must add a valid payment method before you can send applicants job
          offers or start a job.
        </li>
      )}
      <li>
        <strong>Pay with a bank account and save: </strong>A 1.5% discount will
        be applied to all payments made with a bank account (ACH payment
        method).
      </li>
    </ul>
  </Alert>
);

const sortPaymentSources = paymentSources =>
  paymentSources
    .sort((a, b) => {
      if (a.object === "card" && b.object !== "card") {
        return -1;
      }
      return 1;
    })
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      return 1;
    })
    .sort((a, b) => {
      if (a.active && !b.active) {
        return -1;
      }
      return 1;
    });

export class PaymentAccountsSettings extends Component {
  componentDidUpdate(prevProps) {
    const {
      payments: { verificationAccountId, paymentSources },
      setVerificationAccountId,
      createActionClickHandler,
      notify,
    } = this.props;

    if (verificationAccountId !== null) {
      if (paymentSources !== prevProps.payments.paymentSources) {
        const verificationAccount = paymentSources.find(
          source => source.id === verificationAccountId,
        );
        if (!verificationAccount) {
          notify(accountDoesNotExistMsg);
          setVerificationAccountId(null);
        } else if (verificationAccount.status !== "verified") {
          createActionClickHandler(modals.VERIFY_BANK, verificationAccount)();
          setVerificationAccountId(null);
        }
      }
    }
  }

  handleSubmitPaymentSourceForm(action) {
    const { dispatch, refreshAgency, refreshJobs } = this.props;
    return values =>
      dispatch(submitPaymentSourceForm(values, action))
        .then(({ json, response }) => {
          if (!response.ok) {
            throw new SubmissionError({
              _error: json.non_field_errors,
            });
          }
          return json;
        })
        .then(() => {
          dispatch(requestPaymentSources());
          dispatch(toggleModal(null));
          refreshAgency();
          refreshJobs();
        });
  }

  render() {
    const {
      payments: { paymentSources, focusedSource, openModal, submitting },
      userHiringAgent,
      hiringAgents,
      createActionClickHandler,
      createSetActiveSourceHandler,
      createDeleteSourceHandler,
    } = this.props;
    const focusedSourceName = focusedSource
      ? focusedSource.metadata.label
        ? focusedSource.metadata.label
        : `${focusedSource.object.replace("_", " ")} ending in ${
            focusedSource.last4
          }`
      : "this hiring agent";

    const focusedSourceId = focusedSource ? focusedSource.id : null;

    const newPaymentSources = paymentSources.filter(item => Boolean(item.id));
    const sortedPaymentSources = sortPaymentSources(newPaymentSources);

    // TODO: Loading indicator

    if (!userHiringAgent.is_admin)
      return (
        <div className="no-content-message">
          <strong>Only admins can view and manage payment information.</strong>
          <br />
          Please ask one of the administrators for your agency to create a new
          payment account.
        </div>
      );

    return (
      <div>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
          <Fragment>
            <Elements>
              <AddBankModal
                show={openModal === modals.ADD_BANK}
                close={createActionClickHandler()}
                onSubmit={this.handleSubmitPaymentSourceForm(modals.ADD_BANK)}
                actions={null}
              />
            </Elements>

            <Elements>
              <AddCardModal
                show={openModal === modals.ADD_CARD}
                close={createActionClickHandler()}
                onSubmit={this.handleSubmitPaymentSourceForm(modals.ADD_CARD)}
                actions={null}
              />
            </Elements>

            <ConfirmModal
              show={openModal === modals.DELETE_SOURCE}
              close={createActionClickHandler()}
              title="Delete Payment Account"
              body={ModalBody("delete", focusedSourceName)}
              actions={
                <ActionButton
                  text="Delete"
                  bsStyle="danger"
                  disabled={Boolean(submitting)}
                  onClick={createDeleteSourceHandler(focusedSourceId)}
                />
              }
            />

            <VerifyBankDepositsModal
              show={!!focusedSource && openModal === modals.VERIFY_BANK}
              close={createActionClickHandler()}
              actions={null}
              paymentSource={focusedSource}
              onModalClose={createActionClickHandler()}
            />

            <PaymentAccountsExplanation
              hasPaymentAccount={sortedPaymentSources.length > 0}
            />
            <AddPaymentAccountButtons {...this.props} />

            <Row>
              {sortedPaymentSources.length > 0 ? (
                Object.values(sortedPaymentSources).map((source, key) => (
                  <Col sm={12} md={6} key={`payment-source-${key}`}>
                    <Well>
                      <Row>
                        <div style={{ marginBottom: "1.5rem" }}>
                          <Col xs={3}>
                            {source.active ? (
                              <Button
                                bsStyle="info"
                                className="active-source-button"
                                title="This account is set as your active payment source."
                              >
                                Active
                              </Button>
                            ) : (
                              <Button
                                className="inactive-source-button"
                                onClick={createSetActiveSourceHandler(
                                  source.id,
                                )}
                                title="Set this account to be your active payment source."
                              >
                                Make Active
                              </Button>
                            )}
                          </Col>
                          <Col xs={6}>
                            <div className="source-type-label visible-lg">
                              {source.object === "card"
                                ? "Credit Card"
                                : "Bank Account"}
                            </div>
                          </Col>
                          <Col xs={3}>
                            <Button
                              className="pull-right"
                              onClick={createActionClickHandler(
                                modals.DELETE_SOURCE,
                                source,
                              )}
                            >
                              Delete
                            </Button>
                          </Col>
                          <span className="clearfix" />
                        </div>
                      </Row>
                      <PaymentSourceTable
                        source={source}
                        hiringAgents={hiringAgents}
                        onVerifyClick={createActionClickHandler(
                          modals.VERIFY_BANK,
                          source,
                        )}
                      />
                    </Well>
                  </Col>
                ))
              ) : (
                <p className="no-content-message">
                  There are no saved payment accounts
                </p>
              )}
            </Row>
          </Fragment>
        </StripeProvider>
      </div>
    );
  }
}

const selector = createStructuredSelector({
  payments: paymentsSelector,
  hiringAgents: hiringAgentsItemsSelector,
});

const jobsQuery = () =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=999",
    force: true,
  });
const jobListingsQuery = () =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: "/api/v2/joblistings/?limit=999",
    force: true,
  });

export default compose(
  connect(
    selector,
    dispatch => ({
      createActionClickHandler: (modal = null, source = null) => () => {
        dispatch(setFocusedSource(source));
        dispatch(toggleModal(modal));
      },
      createSetActiveSourceHandler: sourceId => () => {
        dispatch(submitSetActiveSource(sourceId));
      },
      createDeleteSourceHandler: sourceId => () => {
        dispatch(submitDeleteSource(sourceId));
      },
      setVerificationAccountId: sourceId =>
        dispatch(setVerificationAccountId(sourceId)),
      notify: msg => dispatch(notify(msg)),
      refreshAgency: () => dispatch(requestAsync(agencyQuery({ force: true }))),
      refreshJobs: () => {
        dispatch(requestAsync(jobsQuery()));
        dispatch(requestAsync(jobListingsQuery()));
      },
      dispatch,
    }),
  ),
  withMetadata(),
)(PaymentAccountsSettings);
