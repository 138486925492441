import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

import VerifyBankDepositsModal from "hiringagent-dashboard/components/VerifyBankDepositsModal";

export default class PaymentsPanel extends Component {
  handleOpenModal(account) {
    this.props.onSetFocusedSource(account);
  }

  handleCloseModal() {
    this.props.onSetFocusedSource(null);
  }

  render() {
    const { focusedSource, paymentSources } = this.props;

    const creditCards = paymentSources.filter(
      source => source.object === "card",
    );

    // Grab the first 2 bank accounts, making sure unverified are displayed first
    const unverifiedBankAccounts = paymentSources
      .filter(source => source.object === "bank_account")
      .sort((a, b) => {
        if (a.status === "new" && b.status !== a.status) {
          return -1;
        }
        if (b.status === "new" && b.status !== a.status) {
          return 1;
        }
        return 0;
      })
      .splice(0, 2);

    return (
      <div>
        {unverifiedBankAccounts.length > 0 ? (
          <div>
            <div className="line-item">
              <Row>
                <Col xs={12}>
                  <span className="line-item-title">Bank Accounts</span>
                </Col>
              </Row>
            </div>
            {unverifiedBankAccounts.map(account => (
              <div className="line-item" key={account.id}>
                <Row>
                  <Col xs={6}>
                    <Link to="/agency/settings/#payment">
                      Ending in {account.last4}
                    </Link>
                  </Col>
                  <Col xs={6} className="text-right">
                    {account.status === "new" ? (
                      <div>
                        <Button
                          bsStyle="success"
                          bsSize="xs"
                          onClick={() => this.handleOpenModal(account)}
                        >
                          Verify
                        </Button>
                      </div>
                    ) : (
                      <span className="success">
                        <i
                          className="far fa-check-circle"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Verified
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        ) : creditCards.length > 0 ? (
          <div>
            <div className="line-item text-center">
              <Row>
                <Col xs={6}>
                  {creditCards.length} credit card
                  {creditCards.length === 1 ? "" : "s"} on file
                </Col>
                <Col xs={6}>
                  <span className="success">
                    <i
                      className="far fa-check-circle"
                      style={{ marginRight: "0.5rem" }}
                    />
                    Verified
                  </span>
                </Col>
              </Row>
            </div>
            <div className="line-item text-center">
              <Row>
                <Col xs={12}>
                  <Link to="/agency/settings/#payment">
                    Add a new payment method
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div>
            <div className="line-item text-center">
              <Row>
                <Col xs={12}>
                  <Link to="/agency/settings/#payment">
                    Payment information
                  </Link>{" "}
                  is required to start recruiting.
                </Col>
              </Row>
            </div>
          </div>
        )}
        <VerifyBankDepositsModal
          show={Boolean(focusedSource)}
          close={() => this.handleCloseModal()}
          actions={null}
          paymentSource={focusedSource}
          onModalClose={() => this.handleCloseModal()}
        />
      </div>
    );
  }
}

PaymentsPanel.propTypes = {
  onSetFocusedSource: PropTypes.func.isRequired,
};
