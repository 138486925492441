import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Tab, Tabs, Well } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import {
  agencySelector,
  hiringAgentSelector,
  hiringAgentsSelector,
} from "hiringagent-dashboard/selectors";
import ChangeNameForm from "hiringagent-dashboard/forms/ChangeNameForm";
import ChangePassword from "common/components/ChangePassword";

class EditSettings extends Component {
  // For use if requestHiringAgents is not called on sidebar
  // componentDidMount() {
  //     const { dispatch, hiringAgents, match: { params: { id } } } = this.props
  //     if (!hiringAgents.lastUpdated) {
  //         dispatch(requestHiringAgents(id))
  //     }
  // }

  render() {
    const {
      agency,
      userHiringAgent,
      hiringAgents: { hiringAgents, lastUpdated },
    } = this.props;
    const id = this.props.match.params.id;

    const hiringAgent = id
      ? id === "self"
        ? userHiringAgent
        : hiringAgents[id]
      : userHiringAgent;

    const changeNameInitialValues = hiringAgent
      ? {
          first_name: hiringAgent.first_name,
          last_name: hiringAgent.last_name,
          is_admin: hiringAgent.is_admin,
        }
      : {};

    const agentIsUser =
      hiringAgent && hiringAgent.uuid === userHiringAgent.uuid;

    return (
      lastUpdated && (
        <div className="settings-main-container">
          <div className="settings-header">
            <h1>
              <i className="far fa-user" />
              {agentIsUser ? "Profile" : "User Settings"}
            </h1>
          </div>
          <Row>
            <Col md={12}>
              <Tabs id="settings-tabs">
                {(userHiringAgent.is_admin || agentIsUser) && (
                  <Tab eventKey={1} title="Edit Profile">
                    <ChangeNameForm
                      agent={hiringAgent}
                      agency={agency}
                      initialValues={changeNameInitialValues}
                      initialValuesToPass={changeNameInitialValues}
                      userIsAdmin={userHiringAgent.is_admin}
                    />
                  </Tab>
                )}
                {agentIsUser && (
                  <Tab eventKey={2} title="Change Password">
                    <Row>
                      <Col xs={12} mdOffset={4} md={4}>
                        <Well>
                          <ChangePassword />
                        </Well>
                      </Col>
                    </Row>
                  </Tab>
                )}
              </Tabs>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

const selector = createStructuredSelector({
  agency: agencySelector,
  hiringAgents: hiringAgentsSelector,
  userHiringAgent: hiringAgentSelector,
});
export default connect(selector)(EditSettings);
