import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { SupportLink } from "common/components";

const AccountDeactivated = () => (
  <Row>
    <Helmet>
      <title>Account Deactivated</title>
    </Helmet>
    <Col md={8}>
      <h2>Your Account Has Been Deactivated</h2>
      <p>
        If you believe you have received this message in error, please contact{" "}
        <SupportLink />.
      </p>
    </Col>
  </Row>
);

export default AccountDeactivated;
