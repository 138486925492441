import styled from "styled-components";

const Close = styled.p`
  visibility: ${({ visibility }) => visibility};
  color: ${({ theme }) => theme.colors.primary_turquoise()} !important;
  cursor: pointer;
  justify-self: flex-end;
  align-self: center;
  text-transform: uppercase !important;

  &:hover {
    text-decoration: underline;
  }
`;

export default Close;
