import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { LegalProfSignupBenefits } from "public/components/signup/LegalProfSignupMarketing";
import {
  ModernLegalCareerMarketing,
  AcceptingApplicationsMarketing,
  ExpandLegalCareerMarketing,
  MakeHiringEasierMarketing,
} from "public/components/marketing/blurbs";

export const JobSearchMarketing = () => (
  <div className="job-search-marketing jobs-marketing">
    <div className="header">
      <h1>Expand Your Career</h1>
    </div>
    <div>
      <Row>
        <Col md={10} mdOffset={1}>
          <ModernLegalCareerMarketing />
          <AcceptingApplicationsMarketing />
        </Col>
      </Row>
    </div>
  </div>
);

export const JobMarketingFooter = ({ showSignup = true }) => (
  <div className="job-marketing-footer jobs-marketing">
    <div className="clearfix footer-title">
      <h2>Legal Staffing Redefined</h2>
      {showSignup && (
        <LinkContainer to="/signup/">
          <Button bsStyle="info">SIGN UP NOW</Button>
        </LinkContainer>
      )}
    </div>
    <p className="lead" style={{ fontWeight: "bold" }}>
      Hire an Esquire’s platform streamlines hiring by using technology to
      minimize the middleman.
    </p>
    <div>
      <Row>
        <Col md={10} mdOffset={1}>
          <ExpandLegalCareerMarketing />
          <Row>
            <Col md={10} mdOffset={1}>
              <LegalProfSignupBenefits />
            </Col>
          </Row>
          <MakeHiringEasierMarketing />
        </Col>
      </Row>
    </div>
  </div>
);
