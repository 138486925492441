import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Grid } from "react-bootstrap";

import {
  NotStartedNonAttorneys,
  PendingNonAttorneys,
  RejectedNonAttorneys,
  VerifiedNonAttorneys,
} from "admin/components/non-attorney-verification-manager/NonAttorneysByType";
import { SelectField } from "common/components/forms/inputs";

const verificationTypes = [
  { label: "Not Started", component: NotStartedNonAttorneys },
  { label: "Pending", component: PendingNonAttorneys },
  { label: "Rejected", component: RejectedNonAttorneys },
  { label: "Verified", component: VerifiedNonAttorneys },
];

let VerificationTypeSelectForm = () => (
  <form>
    <SelectField name="verificationType" bsSize="small">
      {verificationTypes.map(({ label }) => (
        <option key={label} value={label}>
          {label}
        </option>
      ))}
    </SelectField>
  </form>
);
VerificationTypeSelectForm = reduxForm({
  form: "verificationTypeSelect",
  initialValues: {
    verificationType: verificationTypes[0].label,
  },
})(VerificationTypeSelectForm);

class VerificationTypeSelect extends Component {
  render() {
    const {
      formName,
      formValues: { verificationType },
      initialValues,
    } = this.props;
    const selectedVerificationType = verificationTypes.find(
      vt => vt.label === verificationType,
    );
    return (
      <Grid fluid className="verification-type-select">
        <div style={{ margin: "0.5rem 0" }}>
          <VerificationTypeSelectForm
            form={formName}
            initialValues={initialValues}
          />
        </div>
        <div>
          {selectedVerificationType && <selectedVerificationType.component />}
        </div>
      </Grid>
    );
  }
}

const createSelector = formName => formValueSelector(formName);

export default connect((state, props) => ({
  formValues: {
    verificationType: createSelector(props.formName)(state, "verificationType"),
  },
}))(VerificationTypeSelect);
