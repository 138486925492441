// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  offset: number,
  stick: boolean,
};

const Container: ComponentType<Props> = styled.div`
  width: 50%;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 10px;
  align-items: center;
  ${({ offset, stick, theme }) => theme.media.mobile`
    position: fixed;
    z-index: 5;
    left: 0;
    bottom: 0;
    width: 100vw;
    min-height: 50px;
    height: 10vh;
    max-height: 75px;
    padding: 0 5vw;
    grid-template-columns: 1fr auto;
    background-color: ${theme.colors.background_lightgray()};
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.colors.primary_black40()};
    border-radius: 15px 15px 0 0;
    box-shadow: 0 -0.5px 0.5px 0 rgba(153, 153, 153, 0.3);
    ${offset &&
      stick &&
      css`
        position: absolute;
        left: 0;
        bottom: ${offset}px;
      `};
  `};
`;

export default Container;
