import React from "react";
import Geosuggest from "react-geosuggest";
import ReactSelect from "react-select";
import { Col, FormGroup, ControlLabel } from "react-bootstrap";

const HiringAgentSearchInputs = props => (
  <div>
    <Col md={3}>
      <FormGroup>
        <ControlLabel>Practice Areas</ControlLabel>
        <ReactSelect
          name="practice_area"
          id="practice_area"
          options={props.practiceAreaOptions}
          multi={false}
          placeholder={
            props.practiceAreasLoading ? "Loading..." : "Select a Practice Area"
          }
          onChange={newValue =>
            props.handleSearchParamsChange({
              practice_areas: newValue ? newValue.label : "",
            })
          }
          value={
            props.practiceAreaOptions.filter(
              pa => pa.label === props.searchParams.practice_areas,
            )[0]
              ? props.practiceAreaOptions.filter(
                  pa => pa.label === props.searchParams.practice_areas,
                )[0].value
              : ""
          }
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <ControlLabel>Barred In</ControlLabel>
        <ReactSelect
          name="barred_in"
          id="barred_in"
          options={props.stateOptions}
          multi={false}
          value={props.searchParams.barred_in}
          placeholder="Select a State"
          onChange={e =>
            props.handleSearchParamsChange({ barred_in: e ? e.value : "" })
          }
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <ControlLabel>Years Experience</ControlLabel>
        <ReactSelect
          name="years_experience"
          id="years_experience"
          options={props.yearsExperienceOptions}
          multi={false}
          placeholder="Select Years"
          onChange={e =>
            props.handleSearchParamsChange({
              years_experience: e ? e.value : "",
            })
          }
          value={props.searchParams.years_experience}
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <ControlLabel>Languages</ControlLabel>
        <ReactSelect
          name="proficient_in"
          id="proficient_in"
          label="Languages Spoken"
          placeholder="Select Language"
          value={props.searchParams.proficient_in}
          onChange={e =>
            props.handleSearchParamsChange({ proficient_in: e ? e.value : "" })
          }
          options={props.languageOptions.map(language => ({
            value: language,
            label: language,
          }))}
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <ControlLabel>Miles Options</ControlLabel>
        <ReactSelect
          name="miles"
          id="miles"
          options={props.milesOptions}
          multi={false}
          placeholder="Select Miles"
          value={props.searchParams.miles}
          onChange={e =>
            props.handleSearchParamsChange({ miles: e ? e.value : "" })
          }
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <ControlLabel>Location</ControlLabel>
        <Geosuggest
          className=""
          placeholder="Any Location"
          onSuggestSelect={e =>
            props.handleSearchParamsChange({ location: e.label })
          }
          onChange={value =>
            value === ""
              ? props.handleSearchParamsChange({ location: value })
              : null
          }
        />
      </FormGroup>
    </Col>
  </div>
);

export default HiringAgentSearchInputs;
