import React, { Component } from "react";
import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { Col, Row } from "react-bootstrap";
import { addNotification as notify } from "reapop";
import { createStructuredSelector } from "reselect";

import Loading from "common/components/Loading";
import requiredResources from "./requiredResources";
import processErrors from "./processErrors";
import { validateRequiredFields } from "common/utils/forms";
import { createErrorNotification } from "common/utils/notifications";
import { isNotAllowedToApply } from "contractor/components/utils/jobs";
import ApplicationForm, {
  requiredFields,
} from "contractor/forms/ApplicationForm";
import CannotApply from "contractor/components/application/CannotApply";
import ApplicationSubmittedDisplay from "./ApplicationSubmittedDisplay";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import { createCandidateQuery } from "queries/requests/candidates";
import {
  createApplicationMutation,
  createCandidateMutation,
} from "queries/mutations/candidates";
import { createRequirementResponseQuery } from "queries/mutations/requirement-responses";
import { startFitScoreCalculationQuery } from "queries/mutations/assessments";
import types from "resources/types";

class Application extends Component {
  state = {
    applicationSubmitted: false,
    requirementsSubmitted: false,
    calculatedFitScore: false,
    candidateId: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      requirementsSubmitted,
      candidateId,
      calculatedFitScore,
    } = this.state;
    const changed = prevState.requirementsSubmitted !== requirementsSubmitted;
    const hasValues = requirementsSubmitted && candidateId;

    if (changed && hasValues && !calculatedFitScore) {
      this.props.startFitScoreCalc({ candidate: candidateId });
      this.setState({ calculatedFitScore: true });
    }
  }

  validate = values => {
    const errors = validateRequiredFields(requiredFields, values);

    if (values.agreed_no_disciplinary_action === false) {
      errors.agreed_no_disciplinary_action =
        "You must agree in order to apply!";
    }
    if (values.agreed_bill_through_hae === false) {
      errors.agreed_bill_through_hae = "You must agree in order to apply!";
    }
    return errors;
  };

  getRequirementsInitialValues = requirements =>
    requirements
      ? requirements.reduce(
          (prev, curr) => ({ ...prev, [curr.uuid]: false }),
          {},
        )
      : {};

  getInitialValues = requirements => ({
    job: this.props.match.params.id,
    agreed_bill_through_hae: false,
    agreed_no_disciplinary_action: false,
    ...this.getRequirementsInitialValues(requirements),
  });

  getRequirementsData(values, applicationId) {
    const {
      [types.JOB_REQUIREMENTS]: {
        data: jobRequirements,
        query: { data: jobRequirementsQueryData },
      },
    } = this.props;

    return jobRequirementsQueryData.map(id => jobRequirements[id]).map(req => ({
      question_text: req.question,
      application: applicationId,
      response: values[req.uuid],
      requirement: req.uuid,
    }));
  }

  handleSubmit = values => {
    const {
      submitCreateApplication,
      submitCreateCandidate,
      submitCreateRequirementResponse,
      refreshCandidate,
      notify,
    } = this.props;
    const { job, ...applicationValues } = values;

    return submitCreateCandidate({ job })
      .then(response => {
        const { body, status } = response;
        if (status === 201) {
          return body;
        }
        return processErrors(response);
      })
      .then(candidate => {
        const { uuid } = candidate;
        this.setState({ candidateId: uuid });

        refreshCandidate(uuid);

        return submitCreateApplication({
          ...applicationValues,
          candidate: uuid,
        }).then(response => {
          const { body, status } = response;
          if (status === 201) {
            this.setState({ applicationSubmitted: true });
            return body;
          }
          return processErrors(response);
        });
      })
      .then(application => {
        const requirementsData = this.getRequirementsData(
          values,
          application.uuid,
        );
        const requirementPromises = requirementsData.map(req =>
          submitCreateRequirementResponse(req),
        );
        return Promise.all(requirementPromises)
          .then(responses => {
            const hasError = responses.some(resp => !resp.status === 201);
            if (hasError) {
              notify(
                createErrorNotification({
                  message: "Please fix the errors below.",
                }),
              );
            } else {
              this.setState({ requirementsSubmitted: true });
            }
          })
          .catch(() =>
            notify(
              createErrorNotification({
                message: "Please fix the errors below.",
              }),
            ),
          );
      });
  };

  render() {
    const {
      match: {
        params: { id },
      },
      [types.CANDIDATES]: {
        data: candidates,
        query: { data: candidatesQueryData },
      },
      [types.JOBS]: { data: jobs },
      [types.JOB_LISTINGS]: { data: jobListings },
      [types.JOB_PERMISSION]: { data: jobPermission },
      [types.JOB_REQUIREMENTS]: {
        data: jobRequirements,
        query: { data: jobRequirementsQueryData },
      },
      [types.OWN_CONTRACTOR]: { data: ownContractor },
    } = this.props;
    const { applicationSubmitted, candidateId } = this.state;

    const allFinished = requiredResources.every(
      resource => this.props[resource].isFinished,
    );

    if (!allFinished) return <Loading />;

    const jobListing = Object.values(jobListings).find(jl => jl.job === id);
    const job = jobs[id];
    const requirements = jobRequirementsQueryData.map(
      id => jobRequirements[id],
    );
    const contractor = Object.values(ownContractor)[0];
    const candidate = candidatesQueryData
      .map(id => candidates[id])
      .find(c => c.job === id);
    const permission = Object.values(jobPermission)[0];

    const hasPermission = permission && permission.has_permission;
    const notAllowed =
      jobListing &&
      isNotAllowedToApply(
        job,
        jobListing,
        contractor,
        candidate,
        hasPermission,
      );

    return (
      <div>
        <h1 style={{ marginBottom: "2rem" }}>
          Apply to <span className="text-muted">{jobListing.title}</span>
        </h1>
        <Row>
          <Col lgOffset={1} lg={10}>
            {!applicationSubmitted && notAllowed ? (
              <CannotApply
                job={job}
                jobListing={jobListing}
                contractor={contractor}
                hasPermission={hasPermission}
                hasApplied={Boolean(candidate)}
              />
            ) : applicationSubmitted && candidateId ? (
              <ApplicationSubmittedDisplay
                candidateId={candidateId}
                jobId={job.uuid}
              />
            ) : (
              <ApplicationForm
                onSubmit={this.handleSubmit}
                validate={this.validate}
                initialValues={this.getInitialValues(requirements)}
                requirements={requirements}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const candidateQuery = candidateId =>
  createCandidateQuery({ force: true }, candidateId);
const candidatesQuery = props =>
  createResourceListQuery(types.CANDIDATES, {
    url: `/api/v2/candidates/?job=${props.match.params.id}`,
  });
const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });
const jobListingQuery = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.match.params.id}`,
  });
const jobPermissionQuery = props =>
  createResourceDetailQuery(types.JOB_PERMISSION, {
    url: `/api/v2/jobs/${props.match.params.id}/permission/`,
  });
const jobRequirementsQuery = props =>
  createResourceListQuery(types.JOB_REQUIREMENTS, {
    url: `/api/v2/requirements/?joblisting__job=${
      props.match.params.id
    }&limit=999`,
  });
const ownContractorQuery = () =>
  createResourceDetailQuery(types.OWN_CONTRACTOR, {
    url: "/api/dev/contractors/self/",
  });

const mapPropsToConfig = props => [
  candidatesQuery(props),
  jobQuery(props),
  jobListingQuery(props),
  jobPermissionQuery(props),
  jobRequirementsQuery(props),
  ownContractorQuery(),
];

const candidatesSelector = createResourceSelectorConfig(
  types.CANDIDATES,
  candidatesQuery,
);
const jobSelector = createResourceSelectorConfig(types.JOBS, jobQuery);
const jobListingsSelector = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingQuery,
);
const jobPermissionSelector = createResourceSelectorConfig(
  types.JOB_PERMISSION,
  jobPermissionQuery,
);
const jobRequirementsSelector = createResourceSelectorConfig(
  types.JOB_REQUIREMENTS,
  jobRequirementsQuery,
);
const ownContractorSelector = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);

const mapStatetoProps = createStructuredSelector({
  ...candidatesSelector,
  ...jobSelector,
  ...jobListingsSelector,
  ...jobPermissionSelector,
  ...jobRequirementsSelector,
  ...ownContractorSelector,
});

const mapDispatchToProps = {
  submitCreateApplication: data => mutateAsync(createApplicationMutation(data)),
  submitCreateCandidate: data => mutateAsync(createCandidateMutation(data)),
  submitCreateRequirementResponse: data =>
    mutateAsync(createRequirementResponseQuery(data)),
  refreshCandidate: candidateId => requestAsync(candidateQuery(candidateId)),
  startFitScoreCalc: candidateId =>
    mutateAsync(startFitScoreCalculationQuery(candidateId)),
  notify,
};

export default (Application = compose(
  connect(
    mapStatetoProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Application));
