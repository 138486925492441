import styled from "styled-components";

const Toggle = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 34px;
  margin: 0;
  padding: 9px 10px;
  background: none;
  border: none;
  border-radius: 4px;
  outline: none;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.container_bg_darker()};
  }

  ${({ theme }) => theme.media.sm`
    visibility: hidden;
  `};
`;

export default Toggle;
