// @flow strict
import styled from "styled-components";
import { Label as BootstrapLabel } from "react-bootstrap";

const PracticeAreasItem = styled(BootstrapLabel).attrs({
  "aria-hidden": "true",
  bsStyle: "primary",
})`
  && {
    display: inline-block;
    margin: 5px 5px 5px 0;
    padding: 5px 7px 4px 7px;
    border-radius: 30px;
    font-size: 80%;
    text-transform: uppercase;
    border: 1px solid ${({ theme }) => theme.colors.primary_turquoise()};
    color: ${({ theme }) => theme.colors.primary_turquoise()};
    background-color: ${({ theme }) => theme.colors.white()};
    cursor: pointer;
  }
`;

export default PracticeAreasItem;
