import React from "react";
import { RenderWithExpertiseAreas } from "common/components/render-props";
import LabelListItem from "common/components/LabelListItem";

const JobExpertiseAreasDisplay = ({ jobAreas }) => {
  const jobAreasIds = jobAreas.map(area => area.tag);
  return (
    <RenderWithExpertiseAreas
      render={expertiseAreas =>
        Object.values(expertiseAreas)
          .filter(area => jobAreasIds.includes(area.uuid))
          .map(area => (
            <LabelListItem
              key={`expertise-area-${area.uuid}`}
              labelText={area.name}
            />
          ))
      }
    />
  );
};

export default JobExpertiseAreasDisplay;
