import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { addNotification as notify } from "reapop";

import Onboard from "../../blocks";
import { connect, mutateAsync } from "queries/utils";
import { addSchoolTranscriptQuery } from "queries/mutations/schools";
import DeleteModal from "../../DeleteModal";
import { createErrorNotification } from "common/utils/notifications";

const Transcript = ({
  justUploadedIds,
  onFileUploadError,
  onUploadTranscriptFile,
  school,
}) => (
  <Dropzone
    maxSize={15728640}
    onDropRejected={onFileUploadError}
    onDrop={file => onUploadTranscriptFile(file)}
    className="dropzone"
  >
    {school &&
      justUploadedIds.includes(school.uuid) && (
        <Onboard.Icon iconType="success" />
      )}
    <Onboard.TableButton
      bsStyle={school && school.schooltranscript ? "success" : "primary"}
    >
      {school && school.schooltranscript ? "Change" : "Upload"}
    </Onboard.TableButton>
  </Dropzone>
);

const MobileEntry = ({
  justUploadedIds,
  last,
  onEditClick,
  onFileUploadError,
  onShowDeleteModal,
  onUploadTranscriptFile,
  ownContractor,
  school,
  school: { school_name, degree, year_graduated, gpa },
  verifStatusVals,
}) => (
  <div>
    <br />
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>SCHOOL NAME</Onboard.EntryDataLabel>
      <Col xs={6}>{school_name}</Col>
    </Onboard.EntryDataRow>
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>DEGREE</Onboard.EntryDataLabel>
      <Col xs={6}>{degree}</Col>
    </Onboard.EntryDataRow>
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>CLASS</Onboard.EntryDataLabel>
      <Col xs={6}>{year_graduated}</Col>
    </Onboard.EntryDataRow>
    <Onboard.EntryDataRow>
      <Onboard.EntryDataLabel>GPA</Onboard.EntryDataLabel>
      <Col xs={6}>{gpa}</Col>
    </Onboard.EntryDataRow>
    {!ownContractor.is_attorney && (
      <Fragment>
        <Onboard.EntryDataRow>
          <Onboard.EntryDataLabel>TRANSCRIPT</Onboard.EntryDataLabel>
          <Col xs={6}>
            {verifStatusVals.text === "verified" ? (
              <Onboard.TableStatusText status="success">
                DONE
              </Onboard.TableStatusText>
            ) : (
              <Transcript
                justUploadedIds={justUploadedIds}
                onFileUploadError={onFileUploadError}
                onUploadTranscriptFile={onUploadTranscriptFile}
                school={school}
              />
            )}
          </Col>
        </Onboard.EntryDataRow>
        <Onboard.EntryDataRow>
          <Onboard.EntryDataLabel>STATUS</Onboard.EntryDataLabel>
          <Col xs={6}>
            <Onboard.TableStatusText status={verifStatusVals.verifStatus}>
              {verifStatusVals.text}
            </Onboard.TableStatusText>
          </Col>
        </Onboard.EntryDataRow>
      </Fragment>
    )}
    <br />
    {verifStatusVals.text !== "verified" && (
      <Fragment>
        <Onboard.EntryDataRow>
          <Col xs={6}>
            <Onboard.TableButton
              bsStyle="primary"
              block
              onClick={() => onEditClick(school)}
            >
              Edit
            </Onboard.TableButton>
          </Col>
          <Col xs={6}>
            <Onboard.TableButton
              bsStyle="danger"
              block
              onClick={() => onShowDeleteModal(school)}
            >
              Delete
            </Onboard.TableButton>
          </Col>
        </Onboard.EntryDataRow>
        <br />
      </Fragment>
    )}
    {!last && <Onboard.ContainerDivider />}
  </div>
);

const DesktopEntry = ({
  justUploadedIds,
  onEditClick,
  onFileUploadError,
  onShowDeleteModal,
  onUploadTranscriptFile,
  ownContractor,
  school,
  school: { school_name, degree, year_graduated, gpa },
  verifStatusVals,
}) => (
  <tr>
    <td>{school_name}</td>
    <td>{degree}</td>
    <td>{year_graduated}</td>
    <td>{gpa}</td>
    {!ownContractor.is_attorney && (
      <Fragment>
        {verifStatusVals.text === "verified" ? (
          <td>
            <Onboard.TableStatusText status="success">
              DONE
            </Onboard.TableStatusText>
          </td>
        ) : (
          <Onboard.TableButtonCell>
            <Transcript
              justUploadedIds={justUploadedIds}
              onFileUploadError={onFileUploadError}
              onUploadTranscriptFile={onUploadTranscriptFile}
              school={school}
            />
          </Onboard.TableButtonCell>
        )}
        <td>
          <Onboard.TableStatusText status={verifStatusVals.verifStatus}>
            {verifStatusVals.text}
          </Onboard.TableStatusText>
        </td>
      </Fragment>
    )}
    {verifStatusVals.text === "verified" ? (
      <Onboard.TableButtonCell />
    ) : (
      <Onboard.TableButtonCell>
        <Onboard.TableButton
          bsStyle="primary"
          margin="0 5px 0 0"
          onClick={() => onEditClick(school)}
        >
          Edit
        </Onboard.TableButton>
        <Onboard.TableButton
          bsStyle="danger"
          onClick={() => onShowDeleteModal(school)}
        >
          Delete
        </Onboard.TableButton>
      </Onboard.TableButtonCell>
    )}
  </tr>
);

class Entry extends Component {
  constructor(props) {
    super(props);
    this.state = { deletingSchool: null, showDeleteModal: false };
  }

  handleUploadTranscriptFile = async files => {
    const {
      addJustUploadedId,
      addSchoolTranscript,
      refreshSchools,
      school: { uuid },
    } = this.props;

    if (files.length) {
      const data = new FormData();
      data.append("transcript", files[0]);
      await addSchoolTranscript(data, uuid);
      await refreshSchools();
      addJustUploadedId(uuid);
    }
  };

  handleFileUploadError = () => {
    this.props.notify(
      createErrorNotification({ message: "File size must be under 15 MB." }),
    );
  };

  handleShowDeleteModal = deletingSchool =>
    this.setState({ deletingSchool, showDeleteModal: true });

  handleHideDeleteModal = () =>
    this.setState({ deletingSchool: null, showDeleteModal: false });

  getVerifStatusValues = () => {
    let verifStatus, text;

    switch (this.props.school.status) {
      case -2:
        verifStatus = "warning";
        text = "unknown";
        break;
      case -1:
        verifStatus = "danger";
        text = "rejected";
        break;
      case 0:
        verifStatus = "warning";
        text = "pending";
        break;
      case 1:
        verifStatus = "success";
        text = "verified";
        break;
      case 2:
        verifStatus = "default";
        text = "inactive";
        break;
      default:
        verifStatus = "warning";
        text = "unknown";
    }

    return { verifStatus, text };
  };

  render() {
    const {
      state: { deletingSchool, showDeleteModal },
      props: {
        justUploadedIds,
        onDeleteClick,
        last,
        mobile,
        onEditClick,
        ownContractor,
        school,
      },
      getVerifStatusValues,
      handleFileUploadError,
      handleHideDeleteModal,
      handleShowDeleteModal,
      handleUploadTranscriptFile,
    } = this;
    const entryProps = {
      justUploadedIds,
      onFileUploadError: handleFileUploadError,
      onUploadTranscriptFile: handleUploadTranscriptFile,
      onShowDeleteModal: handleShowDeleteModal,
      onEditClick,
      ownContractor,
      school,
      verifStatusVals: getVerifStatusValues(),
    };

    return (
      <Fragment>
        {mobile ? (
          <MobileEntry {...entryProps} last={last} />
        ) : (
          <DesktopEntry {...entryProps} />
        )}
        {deletingSchool && (
          <DeleteModal
            title="Delete Education Entry"
            onDeleteClick={onDeleteClick}
            onHide={handleHideDeleteModal}
            show={showDeleteModal}
            uuid={deletingSchool.uuid}
          >
            <p>
              {`Are you sure you want to delete your ${
                deletingSchool.degree
              } from ${deletingSchool.school_name}?`}
            </p>
          </DeleteModal>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addSchoolTranscript: (data, uuid) =>
    dispatch(mutateAsync(addSchoolTranscriptQuery(data, uuid))),
  notify,
});

export default connect(
  null,
  mapDispatchToProps,
)(Entry);
