import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { SubmissionError, submit } from "redux-form";
import { addNotification as notify } from "reapop";

import HeadlineForm, {
  formName,
  requiredFields,
} from "contractor/forms/HeadlineForm";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import { validateRequiredFields } from "common/utils/forms";
import { Error } from "common/utils/forms/validators";
import { submitContractorProfile } from "contractor/actions/profile";

class EditSummary extends Component {
  handleSaveClick = e => {
    e.preventDefault();
    this.props.onSaveClick();
  };

  handleSubmit = values => {
    const {
      contractor: { uuid },
      notify,
    } = this.props;
    return this.props.onSubmit(uuid, values).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      notify(createSuccessNotification({ message: "Summary has been saved." }));
    });
  };

  validate = values => {
    const errors = validateRequiredFields(requiredFields, values);
    if (values.summary_title && values.summary_title.split(" ").length < 2) {
      errors.summary_title = <Error>Headline must be 2 words or more.</Error>;
    }
    return errors;
  };

  render() {
    const { contractor } = this.props;
    const initialValues = {
      summary_title: contractor.summary_title,
      summary: contractor.summary,
    };
    return (
      <div>
        <h2 className="edit-section-title">Profile Summary</h2>
        <div>
          <form>
            <HeadlineForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              validate={this.validate}
            />
            <div className="text-right">
              <Button
                type="submit"
                bsStyle="primary"
                onClick={this.handleSaveClick}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    onSaveClick: () => submit(formName),
    onSubmit: submitContractorProfile,
    notify,
  },
)(EditSummary);
