import styled from "styled-components";
import { Button } from "react-bootstrap";

export const SignupButton = styled(Button).attrs({
  bsStyle: "info",
  bsSize: "lg",
  block: true,
})`
  &.btn {
    text-transform: uppercase;
    font-weight: bold;
    white-space: normal;
    margin-bottom: 5rem;
    padding: 3rem 16px;
  }
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  letter-spacing: 0.25px;
  font-weight: bold;
  ${({ theme }) => theme.media.sm`
    font-size: 3.5rem;
  `};
`;

export const Subtitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 5rem;
  ${({ theme }) => theme.media.sm`
    margin-bottom: 8.5rem;
    font-size: 3.5rem;
  `};
`;

export const ButtonTitleText = styled.h4`
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  ${({ theme }) => theme.media.sm`
    text-align: center;
  `};
`;
