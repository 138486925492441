// @flow strict
import React, { Component } from "react";
// $FlowFixMe (nonstrict-import)
import { DragDropContext } from "react-beautiful-dnd";
import LockedItemGroup from "./LockedItemGroup";
import SelectedDroppable from "./SelectedDroppable";
import UnselectedDroppable from "./UnselectedDroppable";
import DragDrop from "./blocks";

type ItemsArray = Array<{ uuid: Uuid, statement: string }>;

type Droppable = { droppableId: string, index: number };

type Props = {
  onClearSelected: () => void,
  onDragEnd: (source: Droppable, destination: Droppable) => void,
  onToggleSelected: (uuid: string, source: string, dest: string) => void,
  locked: ItemsArray,
  selected: ItemsArray,
  unselected: ItemsArray,
  selectedMax: number,
};

export default class DragAndDrop extends Component<Props> {
  render() {
    const {
      onClearSelected,
      onDragEnd,
      onToggleSelected,
      locked,
      selected,
      unselected,
      selectedMax,
    } = this.props;

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <DragDrop>
          <LockedItemGroup locked={locked} />
          <SelectedDroppable
            onClearSelected={onClearSelected}
            onToggleSelected={onToggleSelected}
            selected={selected}
            selectedMax={selectedMax}
          />
          <UnselectedDroppable
            isDragDisabled={selectedMax - selected.length <= 0}
            onToggleSelected={onToggleSelected}
            selected={selected}
            unselected={unselected}
          />
        </DragDrop>
      </DragDropContext>
    );
  }
}
