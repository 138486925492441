import { createAction } from "redux-actions";
import keyMirror from "keymirror";
import moment from "moment";

import urls from "urls";
import { symDiff } from "common/utils";

import { metaGenerator } from "common/actions";
import api, { oldApi } from "common/api";
import { createApiUrl } from "utils";

export const actions = keyMirror({
  REQUEST_JOB: null,
  RECEIVE_JOB: null,
  REQUEST_JOBS: null,
  RECEIVE_JOBS: null,
  REQUEST_SUBMIT_CREATE_JOB: null,
  RECEIVE_SUBMIT_CREATE_JOB: null,
  REQUEST_SUBMIT_UPDATE_JOB: null,
  RECEIVE_SUBMIT_UPDATE_JOB: null,
  CREATE_JOB_NEXT_PAGE: null,
  CREATE_JOB_PREVIOUS_PAGE: null,
  GO_TO_CREATE_JOB_PAGE: null,
  UPDATE_FIELD: null,
  RESET_FORM: null,
  REQUEST_INVITED_CONTRACTORS: null,
  RECEIVE_INVITED_CONTRACTORS: null,
  INITIALIZE_FORM_FOR_JOB: null,
  INITIALIZE_FORM_FOR_NEW_JOB: null,
  REQUEST_CLOSE_JOB: null,
  RECEIVE_CLOSE_JOB: null,
  REQUEST_SUBMIT_JOB_FILE: null,
  RECEIVE_SUBMIT_JOB_FILE: null,
  SET_JOBS_FILTER: null,
  RESET_JOBS_FILTERS: null,
  SET_JOBS_SORTING: null,
  TOGGLE_WORKER_ACTIVE: null,
  TOGGLE_JOB_MODAL: null,
  REMOVE_JOB_ALERT_MESSAGE: null,
  REMOVE_FILE: null,
  ADD_JOB_ALERT_MESSAGE: null,
});
export default actions;

export const requestJob = jobId =>
  api.get(`/api/dev/jobs/${jobId}/`, [
    actions.REQUEST_JOB,
    actions.RECEIVE_JOB,
  ]);

export const requestJobs = ({ count = 999 } = {}) => (dispatch, getState) => {
  const { allLoaded, jobsLoaded } = getState().hiringAgentDashboard.jobs;
  if (!allLoaded || !jobsLoaded) {
    dispatch(createAction(actions.REQUEST_JOBS)());
    return oldApi
      .get(`/api/dev/jobs/?limit=${count}`)
      .then(({ json, response }) => {
        const payload = response.ok ? json : new Error("Error getting jobs.");
        payload.allFetched = count === 999;
        dispatch(
          createAction(actions.RECEIVE_JOBS, p => p, metaGenerator)(payload),
        );
        return { json, response };
      });
  }
  return null;
};

// TODO: Need to figure out a promise workflow to capture errors in response when the promise chain gets to redux-form
export const submitCreateJob = data => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_CREATE_JOB)());
  return oldApi.post("/api/dev/jobs/", data).then(({ json, response }) => {
    const payload = response.ok ? json : new Error("Error creating job.");
    dispatch(
      createAction(actions.RECEIVE_SUBMIT_CREATE_JOB, p => p, metaGenerator)(
        payload,
      ),
    );
    return { json, response };
  });
};

export const submitUpdateJob = (jobId, data) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_UPDATE_JOB)());
  return oldApi
    .patch(`/api/dev/jobs/${jobId}/`, data)
    .then(({ json, response }) => {
      const payload = response.ok ? json : new Error("Error editing job.");
      dispatch(
        createAction(actions.RECEIVE_SUBMIT_UPDATE_JOB, p => p, metaGenerator)(
          payload,
        ),
      );
      return { json, response };
    });
};

export const createJobNextPage = createAction(actions.CREATE_JOB_NEXT_PAGE);
export const createJobPreviousPage = createAction(
  actions.CREATE_JOB_PREVIOUS_PAGE,
);
export const goToCreateJobPage = createAction(actions.GO_TO_CREATE_JOB_PAGE);
export const resetForm = createAction(actions.RESET_FORM);
export const updateField = createAction(actions.UPDATE_FIELD);

export const requestInvitedContractors = jobUuid =>
  api.get(`/api/dev/jobs/${jobUuid}/invitations/`, [
    actions.REQUEST_INVITED_CONTRACTORS,
    actions.RECEIVE_INVITED_CONTRACTORS,
  ]);

// TODO: Refactor to use new api
export const submitCloseJob = jobUuid => dispatch => {
  dispatch(createAction(actions.REQUEST_CLOSE_JOB)());
  return oldApi
    .post(`/api/dev/jobs/${jobUuid}/close/`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? {
            jobId: jobUuid,
            closed_date: moment().format("YYYY-MM-DD"),
          }
        : new Error("Error cloning job.");
      dispatch(
        createAction(actions.RECEIVE_CLOSE_JOB, p => p, metaGenerator)(payload),
      );
      return { json, response };
    });
};

export const submitJobFile = (jobUuid, file) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_JOB_FILE)());
  return fetch(createApiUrl(`/api/dev/jobs/${jobUuid}/files/`), {
    headers: {
      Accept: "application/json",
    },
    body: file,
    credentials: "include",
    method: "POST",
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error uploading file for job.");
      const message = response.ok
        ? "File uploaded!"
        : "Could not upload file. Contact support if issue persists.";
      dispatch(
        createAction(actions.RECEIVE_SUBMIT_JOB_FILE, p => p, metaGenerator)(
          payload,
        ),
      );
      dispatch(requestJob(jobUuid));
      dispatch(
        createAction(actions.ADD_JOB_ALERT_MESSAGE, p => p, metaGenerator)(
          message,
        ),
      );
      return { json, response };
    });
};

export const initializeFormForJob = createAction(
  actions.INITIALIZE_FORM_FOR_JOB,
);
export const initializeFormForNewJob = createAction(
  actions.INITIALIZE_FORM_FOR_NEW_JOB,
);

export const toggleWorkerActive = (jobId, workers) => (dispatch, getState) => {
  const job = getState().hiringAgentDashboard.jobs.jobs[jobId];
  const previousWorkerIds = [...job.jobworker_set]
    .filter(worker => worker.active)
    .map(worker => worker.uuid);
  const workerIds = workers.map(worker => worker.value);

  const changedWorkerId =
    [previousWorkerIds, workerIds].reduce((prev, next) =>
      symDiff(prev, next).concat(symDiff(next, prev)),
    )[0] || null;

  if (changedWorkerId) {
    const workerIndex = job.jobworker_set.findIndex(
      w => w.uuid === changedWorkerId,
    );
    const worker = job.jobworker_set[workerIndex];
    // Normally we want to wait for the API call to return before dispatching this action, but in this case
    // let's update the UI immediately so that it doesn't hang due to long-running API calls.
    // TODO: Refactor and optimize API calls (specifically applications) so that we can safely put this
    // TODO: dispatch into the callback.
    dispatch(
      createAction(actions.TOGGLE_WORKER_ACTIVE)({ jobId, workerIndex }),
    );
    return oldApi
      .patch(`/api/dev/workers/${worker.uuid}/`, { active: !worker.active })
      .then(() => {});
  }

  return undefined;
};

export const toggleJobModal = createAction(actions.TOGGLE_JOB_MODAL);
export const removeMessage = createAction(actions.REMOVE_JOB_ALERT_MESSAGE);
export const addMessage = createAction(actions.ADD_JOB_ALERT_MESSAGE);

export const removeFile = (jobUuid, fileUuid) => dispatch =>
  fetch(
    createApiUrl(urls["hiringagency:job_attachment_delete"](jobUuid, fileUuid)),
    {
      credentials: "include",
    },
  ).then(response => {
    const message = response.ok
      ? "File deleted!"
      : "Error deleting file. Contact support if issue persists.";
    dispatch(requestJob(jobUuid));
    dispatch(
      createAction(actions.ADD_JOB_ALERT_MESSAGE, p => p, metaGenerator)(
        message,
      ),
    );
  });
