import { mutateResourceQuery } from "queries";
import types from "resources/types";

export const postContractorLanguageQuery = data =>
  mutateResourceQuery(
    types.LANGUAGES,
    {
      url: "/api/v2/contractor_languages/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const patchContractorLanguageQuery = (data, uuid) =>
  mutateResourceQuery(
    types.LANGUAGES,
    {
      url: `/api/v2/contractor_languages/${uuid}`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );

export const deleteContractorLanguageQuery = uuid =>
  mutateResourceQuery(types.LANGUAGES, {
    url: `/api/v2/contractor_languages/${uuid}`,
    options: {
      method: "DELETE",
    },
  });

export const bypassLanguageProficienciesQuery = () =>
  mutateResourceQuery(types.BYPASS, {
    url: "/api/dev/contractors/onboarding_bypass_language_proficiencies/",
    options: {
      method: "POST",
    },
  });
