import React from "react";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import Entry from "./Entry";
import Label from "common/blocks/Label";
import { MainCopy, NoEntriesCopy, ProficiencyTooltipCopy } from "./copy";
import ToolTip from "common/components/ToolTip";

export const MobileEntries = ({
  actionIsRequired,
  customErrorMsg,
  languageFluencies,
  onDeleteClick,
  onEditClick,
  onFooterClick,
  refreshOwnContractor,
}) => (
  <div>
    <h1>Language Proficiencies</h1>
    {actionIsRequired && (
      <div>
        <Label color="danger">ACTION REQUIRED</Label>
        <br />
        <br />
      </div>
    )}
    {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
    <Onboard.OptionalLabel />
    <MainCopy />
    <Onboard.Container>
      <div>
        {languageFluencies.length === 0 ? (
          <NoEntriesCopy />
        ) : (
          languageFluencies.map((languageFluency, key) => {
            return (
              <Entry
                key={key}
                languageFluency={languageFluency}
                last={languageFluency.length === key + 1}
                mobile
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                refreshOwnContractor={refreshOwnContractor}
              />
            );
          })
        )}
      </div>
      <Onboard.ContainerFooter onClick={onFooterClick}>
        + ADD A NEW LANGUAGE
      </Onboard.ContainerFooter>
    </Onboard.Container>
  </div>
);

export const DesktopEntries = ({
  actionIsRequired,
  customErrorMsg,
  languageFluencies,
  onDeleteClick,
  onEditClick,
  onFooterClick,
  refreshOwnContractor,
}) => (
  <Onboard.Container>
    <Onboard.ContainerHeader>
      <div>
        <h1>Language Proficiencies</h1>
      </div>
      <div>
        {actionIsRequired && <Label color="danger">ACTION REQUIRED</Label>}
      </div>
    </Onboard.ContainerHeader>
    {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
    <Onboard.OptionalLabel />
    <MainCopy />
    <Onboard.Table>
      <thead>
        <tr>
          <th>LANGUAGE</th>
          <th>
            PROFICIENCY{" "}
            <ToolTip content={<ProficiencyTooltipCopy />} placement="bottom">
              <i className="far fa-info-circle" />
            </ToolTip>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {languageFluencies.length === 0 ? (
          <tr>
            <td colSpan="5">
              <NoEntriesCopy />
            </td>
          </tr>
        ) : (
          languageFluencies.map((languageFluency, key) => (
            <Entry
              key={key}
              languageFluency={languageFluency}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              refreshOwnContractor={refreshOwnContractor}
            />
          ))
        )}
      </tbody>
    </Onboard.Table>
    <Onboard.ContainerFooter onClick={onFooterClick}>
      + ADD A NEW LANGUAGE
    </Onboard.ContainerFooter>
  </Onboard.Container>
);
