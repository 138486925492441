import React, { Component } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { Alert, Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { StateSelect, TextField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";
import { CardElement, injectStripe } from "react-stripe-elements";
import { SubmissionError } from "redux-form";

import "styles/vendor/stripe.css";

const formName = "createCardAccountForm";
const requiredFields = [
  "name",
  "address_line1",
  "address_city",
  "address_state",
];

const CardInput = () => (
  <div>
    <label>Card Details</label>
    <CardElement style={{ base: { fontSize: "16px" } }} />
  </div>
);

const validate = values => validateRequiredFields(requiredFields, values);

class CreateCardAccountForm extends Component {
  onSubmit = async values => {
    const { token, error } = await this.props.stripe.createToken(values);

    // Only send token ID to backend
    if (token) values.stripe_token = token.id;
    if (error) throw new SubmissionError({ _error: error.message });

    return this.props.onSubmit(values);

    // TODO: Handle server-side validation errors (are there any)
  };

  render() {
    const { error, handleSubmit, submitting, close } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div style={{ marginBottom: "2rem" }}>
            <CardInput />
          </div>
          {error && <Alert bsStyle="danger">{error}</Alert>}
          <Row>
            <Col sm={12}>
              <TextField name="name" label="Cardholder Name" />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p>Billing Address</p>
              <TextField name="address_line1" label="Address Line 1" />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <TextField name="address_line2" label="Address Line 2" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TextField name="address_city" label="City" />
            </Col>
            <Col sm={6}>
              <StateSelect name="address_state" byAbbreviation />
            </Col>
          </Row>
          <span className="clearfix" />
          <p>
            <small>
              Credit card information is managed and processed by
              <a
                href="https://www.stripe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Stripe{" "}
              </a>
            </small>
          </p>
          <ButtonToolbar className="pull-right">
            <Button bsStyle="link" onClick={close}>
              Cancel
            </Button>
            <Button bsStyle="success" disabled={submitting} type="submit">
              Add Card Account
            </Button>
          </ButtonToolbar>
          <span className="clearfix" />
        </form>
      </div>
    );
  }
}

CreateCardAccountForm = compose(
  reduxForm({
    form: formName,
    validate,
  }),
  injectStripe,
)(CreateCardAccountForm);
export default CreateCardAccountForm;
