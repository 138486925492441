import React, { Component, Fragment } from "react";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const completedPaymentDetailsText = (
  <p>
    Your payment details for this job have been saved. You can now send offers
    to applicants. If you have not already, expand your applicant pool by
    inviting recommended candidates to apply.
  </p>
);

export const CompletedPaymentDetails = ({ jobId }) => (
  <Fragment>
    {completedPaymentDetailsText}
    <div style={{ textAlign: "center", margin: "4rem 0" }}>
      <ButtonToolbar style={{ textAlign: "center", display: "inline-block" }}>
        <LinkContainer
          to="/agency/"
          bsStyle="link"
          style={{ marginBottom: "1rem" }}
        >
          <Button>Go to Dashboard</Button>
        </LinkContainer>
        <LinkContainer
          bsStyle="info"
          style={{ marginBottom: "1rem" }}
          to={`/agency/jobs/create/${jobId}/recommended_candidates/`}
        >
          <Button>See Recommended Candidates</Button>
        </LinkContainer>
      </ButtonToolbar>
    </div>
  </Fragment>
);

class PaymentDetailsCompleted extends Component {
  render() {
    return (
      <Row>
        <Col lg={10} lgOffset={1}>
          <div style={{ marginTop: "2rem" }}>
            <h2 style={{ fontWeight: "700" }}>You&apos;re all set!</h2>
            <CompletedPaymentDetails jobId={this.props.match.params.id} />
          </div>
        </Col>
      </Row>
    );
  }
}

export default PaymentDetailsCompleted;
