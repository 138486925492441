import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";

import {
  AddressForm as AddressFields,
  requiredFields,
} from "contractor/forms/AddressForm";
import { validateRequiredFields } from "common/utils/forms/validators";
import {
  FormRow,
  withFormError,
} from "hiringagent-dashboard/components/jobs/create-job-listing/utils";
import { compose } from "resources/components/utils";

const validate = values => validateRequiredFields(requiredFields, values);

export const AddressForm = props => (
  <form onSubmit={props.handleSubmit}>
    <FormRow>
      <AddressFields />
      <Fragment>
        <p>
          If the worker is reporting on-site for this job, please provide the
          address for the work site.
        </p>
        <p>
          If the worker is remote, please provide the address for the site of
          the worker’s manager.
        </p>
      </Fragment>
    </FormRow>
  </form>
);

const ConnectedAddressForm = compose(
  reduxForm({
    validate,
    form: "jobPaymentDetailsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  withFormError(),
)(AddressForm);

export default class Address extends Component {
  render() {
    const { formProps, initialValues } = this.props;
    return (
      <Fragment>
        <h2>Main work address.</h2>
        <ConnectedAddressForm {...formProps} initialValues={initialValues} />
      </Fragment>
    );
  }
}
