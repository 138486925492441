import React, { Component } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";

import CropModal from "./CropModal";
import { ProfilePicture } from "hiringagent-dashboard/components/search/AttorneyProfilePicture";

const initState = {
  crop: {
    aspect: 1,
  },
  pxCrop: {},
};

const StyledDropZone = styled(Dropzone).attrs({
  accept: "image/*",
  activeClassName: "active-file-upload-dropzone",
  className: "file-upload-dropzone",
})`
  &&&& {
    border: 3px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;
    padding: 2rem;
  }
`;

const Placeholder = styled.i.attrs({ className: "far fa-user-circle fa-5x" })`
  display: block;
  margin: 2rem 0;
  color: #bbb;
`;

class ProfilePictureForm extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  handleProfilePictureUrlChange = files => {
    const fd = new FormData();
    fd.append("picture", files[0]);
    this.props.onProfilePictureUpload(fd);
  };

  handleCropComplete = (crop, pxCrop) => this.setState({ crop, pxCrop });

  handleModalClose = () => {
    this.setState(initState);
    this.props.onClearStashURL();
  };

  handleCropSave = () => {
    const { pxCrop } = this.state;
    this.props.onCropSave({
      x1: pxCrop.x,
      x2: pxCrop.width + pxCrop.x,
      y1: pxCrop.y,
      y2: pxCrop.height + pxCrop.y,
    });
    this.setState(initState);
  };

  render() {
    const {
      state: { crop },
      props: { stashURL, profileImgURL },
      handleCropComplete,
      handleCropSave,
      handleModalClose,
      handleProfilePictureUrlChange,
    } = this;

    return (
      <div>
        {stashURL && (
          <CropModal
            crop={crop}
            onCropComplete={handleCropComplete}
            onCropSave={handleCropSave}
            onModalClose={handleModalClose}
            stashURL={stashURL}
          />
        )}
        <StyledDropZone
          onDrop={handleProfilePictureUrlChange}
          accept="image/*"
          maxSize={15728640}
        >
          <center>
            {profileImgURL ? (
              <ProfilePicture
                pictureUrl={profileImgURL}
                firstName="A"
                lastName="A"
              />
            ) : (
              <Placeholder />
            )}
            <span>Drag a file here or click to browse.</span>
          </center>
        </StyledDropZone>
      </div>
    );
  }
}

export default ProfilePictureForm;
