import { createAction } from "redux-actions";
import { listToObject, metaGenerator } from "admin/utils";
import keyMirror from "keymirror";
import algoliasearchHelper from "algoliasearch-helper";

import { oldApi } from "common/api";
import { client } from "common/api/algolia";

const actions = keyMirror({
  REQUEST_HIRING_AGENCIES: null,
  RECEIVE_HIRING_AGENCIES: null,
  SET_SELECTED_JOB: null,
  REQUEST_RECOMMENDATIONS: null,
  RECEIVE_RECOMMENDATIONS: null,
  UPDATE_SEARCH_PARAMS: null,
  SUBMIT_SEARCH_PARAMS: null,
  UPDATE_PAGE: null,
  RECEIVE_SEARCH_RESULTS: null,
  REQUEST_TAGS: null,
  RECEIVE_TAGS: null,
  REQUEST_LANGUAGES: null,
  RECEIVE_LANGUAGES: null,
  SUBMIT_RECOMMENDATION: null,
  RECEIVE_SUBMIT_RECOMMENDATION: null,
  TOGGLE_RECOMMENDATION_MODAL: null,
  UPDATE_RECOMMENDATION_REASON: null,
});
export default actions;

export const requestHiringAgenciesV2 = () => dispatch => {
  dispatch(createAction(actions.REQUEST_HIRING_AGENCIES)());
  return oldApi
    .get("/api/v2/hiring_agencies/?limit=9999")
    .then(({ json, response }) => {
      const payload = response.ok
        ? { hiringAgencies: listToObject(json.results, "uuid") }
        : new Error("Error retrieving hiring agencies.");
      return dispatch(
        createAction(actions.RECEIVE_HIRING_AGENCIES, p => p, metaGenerator)(
          payload,
        ),
      );
    });
};

export const requestTags = () => dispatch => {
  dispatch(createAction(actions.REQUEST_TAGS)());
  return oldApi.get("/api/dev/tags/?limit=9999").then(({ json, response }) => {
    const payload = response.ok
      ? { tags: listToObject(json.results, "uuid") }
      : new Error("Error retrieving tags.");
    return dispatch(
      createAction(actions.RECEIVE_TAGS, p => p, metaGenerator)(payload),
    );
  });
};

export const requestLanguages = () => dispatch => {
  dispatch(createAction(actions.REQUEST_LANGUAGES)());
  return oldApi
    .get("/api/v2/languages/?limit=150")
    .then(({ json, response }) => {
      const payload = response.ok
        ? { languages: listToObject(json.results, "uuid") }
        : new Error("Error retrieving languages.");
      return dispatch(
        createAction(actions.RECEIVE_LANGUAGES, p => p, metaGenerator)(payload),
      );
    });
};

export const submitRecommendation = attorneyId => (dispatch, getState) => {
  dispatch(createAction(actions.SUBMIT_RECOMMENDATION)());
  const {
    recommendationReason,
    searchParams,
    searchResults,
    selectedJob,
  } = getState().admin.recommendations;
  const resultIndex = searchResults.findIndex(
    result => result.objectID === attorneyId,
  );
  return oldApi
    .post("/api/dev/recommendations/", {
      jobposting: selectedJob.uuid,
      contractor: attorneyId,
      reason: recommendationReason,
      search_params: { ...searchParams, resultIndex },
    })
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error submitting recommendation.");
      return dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_RECOMMENDATION,
          p => p,
          metaGenerator,
        )(payload),
      );
    });
};

const requestRecommendations = () => (dispatch, getState) => {
  dispatch(createAction(actions.REQUEST_RECOMMENDATIONS)());
  const { selectedJob } = getState().admin.recommendations;
  return oldApi
    .get(`/api/dev/recommendations/?job=${selectedJob.uuid}&limit=99`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? { recommendations: listToObject(json.results, "uuid") }
        : new Error("Error retrieving recommendations.");
      dispatch(submitSearchParams());
      return dispatch(
        createAction(actions.RECEIVE_RECOMMENDATIONS, p => p, metaGenerator)(
          payload,
        ),
      );
    });
};

export const setSelectedJob = jobId => dispatch =>
  oldApi.get(`/api/v2/jobs/${jobId}/`).then(({ json, response }) => {
    const payload = response.ok ? json : new Error("Error retrieving job");
    dispatch(createAction(actions.SET_SELECTED_JOB)(payload));
    return dispatch(requestRecommendations(jobId));
  });

export const updateSearchParams = createAction(actions.UPDATE_SEARCH_PARAMS);

/**
 * Returns only active search params.
 */
const filterSearchParams = params =>
  Object.keys(params)
    .filter(paramName => params[paramName].active)
    .reduce(
      (result, current) => ({
        ...result,
        [current]: params[current].value,
      }),
      {},
    );

const handleReceiveResults = (results, dispatch) =>
  dispatch(createAction(actions.RECEIVE_SEARCH_RESULTS)(results));

const initializeHelper = index =>
  algoliasearchHelper(client(), index, {
    removeStopWords: ["en"],
    facets: [
      "_index_barred_in_list",
      "_index_firm_types",
      "_index_practice_areas",
      "_index_practice_area_groups",
      "_index_language_proficiency_list",
      "_index_previousemployment_list.position_type",
      "contractor_type",
    ],
  });

const removeWordsFromQuery = query => {
  const wordsToRemove = ["law", "agreements", "agreement", "litigation"];

  const removeWordsRegex = new RegExp(wordsToRemove.join("|"), "gi");

  let newQuery = query.replace(removeWordsRegex, "");
  newQuery = newQuery.replace(/\s+/g, " ");
  newQuery = newQuery.trim();

  return newQuery;
};

export const submitSearchParams = () => (dispatch, getState) => {
  dispatch(createAction(actions.SUBMIT_SEARCH_PARAMS)());

  const {
    admin: {
      recommendations: { searchParams, tags, firmTypeOptions, languages },
    },
    common: {
      fixtures: { positionTypes },
      metadata: {
        data: { algolia_index },
      },
    },
  } = getState();

  const helper = initializeHelper(algolia_index);

  helper.clearRefinements();
  helper.removeAllListeners("result");
  helper.on("result", results => handleReceiveResults(results, dispatch));

  const filteredSearchParams = filterSearchParams(searchParams);

  const {
    barredIn,
    distancePrecision,
    firmTypes,
    location,
    practiceAreas,
    practiceAreaGroups,
    radius,
    rate,
    yearsExperience,
    maxYearsExperience,
    languageFluencies,
    positionType,
  } = filteredSearchParams;

  const allowAttorneys = searchParams.allowAttorneys.active;
  const allowNonAttorneys = searchParams.allowNonAttorneys.active;

  let query = "";
  let optionalWords = "";

  if (practiceAreaGroups) {
    const practiceAreaGroupsList = practiceAreaGroups.split(";");
    const practiceAreaGroupParams = practiceAreaGroupsList.join(" ");
    if (practiceAreaGroupParams) {
      optionalWords = `${optionalWords} ${practiceAreaGroupParams}`;
      query = `${query} ${practiceAreaGroupParams}`;
    }
  }

  if (practiceAreas) {
    const practiceAreasList = practiceAreas.split(";");
    const practiceAreaParams = practiceAreasList
      .map(practiceAreaId => tags[practiceAreaId].name)
      .join(" ");
    if (practiceAreaParams) {
      optionalWords = `${optionalWords} ${practiceAreaParams}`;
      query = `${query} ${practiceAreaParams}`;
    }
  }

  if (rate) {
    helper.addNumericRefinement(
      "_index_rate_info.dollar_per_hr_high",
      ">=",
      rate,
    );
    helper.addNumericRefinement(
      "_index_rate_info.dollar_per_hr_low",
      "<=",
      rate,
    );
  }
  if (yearsExperience && maxYearsExperience) {
    helper.addNumericRefinement(
      "_index_years_experience",
      ">=",
      yearsExperience,
    );
    helper.addNumericRefinement(
      "_index_years_experience",
      "<=",
      maxYearsExperience,
    );
  } else if (yearsExperience) {
    helper.addNumericRefinement(
      "_index_years_experience",
      ">=",
      yearsExperience,
    );
  } else if (maxYearsExperience) {
    helper.addNumericRefinement(
      "_index_years_experience",
      "<=",
      maxYearsExperience,
    );
  }

  helper.setQueryParameter("aroundPrecision", distancePrecision);
  helper.setQueryParameter("aroundRadius", radius);

  if (
    location &&
    location.location &&
    location.location.lat &&
    location.location.lat
  ) {
    helper.setQueryParameter(
      "aroundLatLng",
      `${location.location.lat},${location.location.lng}`,
    );
  } else {
    helper.setQueryParameter("aroundLatLng", undefined);
  }

  if (barredIn) helper.addFacetRefinement("_index_barred_in_list", barredIn);

  if (languageFluencies) {
    languageFluencies.split(",").forEach(languageId => {
      helper.addFacetRefinement(
        "_index_language_proficiency_list",
        languages[languageId].name,
      );
    });
  }
  if (firmTypes) {
    firmTypes.split(",").forEach(firmTypeId => {
      helper.addFacetRefinement(
        "_index_firm_types",
        firmTypeOptions[firmTypeId].label,
      );
    });
  }
  if (positionType) {
    const positionTypeObject = positionTypes.options.find(
      pt => pt.uuid === positionType,
    );
    if (positionTypeObject) {
      helper.addFacetRefinement(
        "_index_previousemployment_list.position_type",
        positionTypeObject.name,
      );
    }
  }
  if (allowAttorneys && !allowNonAttorneys) {
    helper.addFacetRefinement("contractor_type", "Attorney");
  }
  if (allowNonAttorneys && !allowAttorneys) {
    helper.addFacetRefinement("contractor_type", "-Attorney");
  }

  if (optionalWords) helper.setQueryParameter("optionalWords", optionalWords);

  query = removeWordsFromQuery(query);

  helper.setQuery(query);
  helper.search();
};

export const updatePage = page => (dispatch, getState) => {
  const {
    metadata: {
      data: { algolia_index },
    },
  } = getState().common;
  const helper = initializeHelper(algolia_index);
  helper.setPage(page).search();
  return dispatch(createAction(actions.UPDATE_PAGE)(page));
};

export const toggleModal = createAction(actions.TOGGLE_RECOMMENDATION_MODAL);
export const updateRecommendationReason = createAction(
  actions.UPDATE_RECOMMENDATION_REASON,
);
