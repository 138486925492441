import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import {
  Button,
  ButtonGroup,
  Col,
  DropdownButton,
  MenuItem,
  Modal,
  Row,
} from "react-bootstrap";
import { addNotification as notify } from "reapop";

import { ContractorProfileShareModal } from "common/components/contractor-profile";
import InviteToJobsAction from "common/components/contractor-profile/InviteToJobsAction";
import { withContractorTypes } from "common/connectors";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import {
  withClioContacts,
  withHaeUser,
} from "hiringagent-dashboard/connectors";
import { exportContractorToClio } from "hiringagent-dashboard/actions/attorneys";
import { addMessageRecipientIds } from "hiringagent-dashboard/actions/messages";
import urls from "urls";
import clioLogo from "img/clio/clio-logo.png";
import { createApiUrl } from "utils";

const AssociateClioModal = ({ onClose, show }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Associate Clio Account</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Before you can export a contact to Clio, you will first need to link
        your Clio account to your Hire an Esquire account. To get started, click
        below:
      </p>
      <Row>
        <Col md={8}>
          <Button
            block={true}
            bsSize="lg"
            style={{ marginBottom: "1rem" }}
            className="btn-social btn-clio"
            href={createApiUrl(
              `${urls["social:begin"]("clio-oauth2")}/?next=${
                window.location.pathname
              }%23cliosuccess`,
            )}
          >
            <img alt="clio logo" src={clioLogo} />
            <span style={{ color: "#0075bb" }}> Associate Clio</span>
          </Button>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="link" onClick={onClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

class ContractorProfileActions extends Component {
  static propTypes = {
    contractor: PropTypes.object.isRequired,
  };

  state = { showModal: false, showClioModal: false };

  contractorIsClioContact = () => {
    const {
      clioContacts: { data: contacts, ready },
      contractor,
    } = this.props;
    if (ready && contacts && contacts.data) {
      return contacts.data.some(contact =>
        contact.custom_field_values.some(
          customField => customField.value === contractor.uuid,
        ),
      );
    }
    return false;
  };

  closeShareProfileModal = () => {
    this.setState({ showModal: false });
  };

  openShareProfileModal = () => {
    this.setState({ showModal: true });
  };

  handleExportToClioClick = e => {
    e.preventDefault();
    const isContact = this.contractorIsClioContact();
    const {
      contractor: { first_name },
      exportContractorToClio,
      haeUser: {
        data: { clio_connected },
      },
      onReloadHandlers: { clioContacts: reloadClioContacts },
      notify,
    } = this.props;
    if (!isContact) {
      if (clio_connected) {
        exportContractorToClio().then(() => {
          notify(
            createSuccessNotification({
              message: `${first_name} has been added to your Clio contacts.`,
            }),
          );
          reloadClioContacts();
        });
      } else {
        this.setState({ showClioModal: true });
      }
    } else {
      notify(
        createErrorNotification({
          message: `${first_name} is already one of your Clio contacts.`,
        }),
      );
    }
  };

  handleClioModalClose = () => {
    this.setState({ showClioModal: false });
  };

  render() {
    const {
      haeUser: { isLoading: haeUserIsLoading, lastUpdated: haeUserLastUpdated },
      contractor,
      addMessageRecipientIds,
    } = this.props;
    const { showModal, showClioModal } = this.state;

    const showClioExportButton = !this.contractorIsClioContact();

    return (
      <div>
        <ContractorProfileShareModal
          modalStatus={showModal}
          close={this.closeShareProfileModal}
          contractor={contractor}
        />
        <AssociateClioModal
          show={showClioModal}
          onClose={this.handleClioModalClose}
        />
        <ButtonGroup justified className="contractor-actions">
          <DropdownButton
            title={"ACTIONS"}
            id="Contractor Profile Actions"
            className="header-action-dropdown"
          >
            <LinkContainer
              to={"/agency/messages/create/"}
              onClick={addMessageRecipientIds}
            >
              <MenuItem>
                <i className="far fa-envelope" /> Message
              </MenuItem>
            </LinkContainer>
            <MenuItem onSelect={this.openShareProfileModal}>
              <i className="far fa-share-square" /> Share Profile
            </MenuItem>
            {haeUserLastUpdated &&
              !haeUserIsLoading &&
              (showClioExportButton ? (
                <MenuItem onClick={this.handleExportToClioClick}>
                  <i className="far fa-address-book" /> Export to Clio Contact
                </MenuItem>
              ) : (
                <MenuItem className={"disabled"}>
                  <i className="far fa-address-book" /> Already Clio Contact
                </MenuItem>
              ))}
            <InviteToJobsAction
              className="invite-to-jobs-actions"
              style={{ width: "2px" }}
              onInviteClick={addMessageRecipientIds}
            />
          </DropdownButton>
        </ButtonGroup>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  addMessageRecipientIds: () =>
    dispatch(addMessageRecipientIds([ownProps.contractor.uuid])),
  exportContractorToClio: () =>
    dispatch(exportContractorToClio(ownProps.contractor)),
  notify: message => dispatch(notify(message)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withClioContacts(),
  withContractorTypes(),
  withHaeUser(),
)(ContractorProfileActions);
