import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const AttorneyPracticeArea = ({ onClick, practiceArea }) => (
  <li className="attorney-practice-area">
    {onClick ? (
      <a href="#" onClick={onClick}>
        <span dangerouslySetInnerHTML={{ __html: practiceArea.text }} />
      </a>
    ) : (
      <Link
        to={`/agency/search/?practice_areas=${practiceArea.text}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span dangerouslySetInnerHTML={{ __html: practiceArea.text }} />
      </Link>
    )}
  </li>
);

AttorneyPracticeArea.propTypes = {
  onClick: PropTypes.func,
  practiceArea: PropTypes.object.isRequired,
};

export default AttorneyPracticeArea;
