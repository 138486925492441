import styled from "styled-components";

const ParentItem = styled.li`
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  color: ${({ mobile, theme }) =>
    mobile ? theme.colors.primary_black60() : "black"};
`;

export default ParentItem;
