import React from "react";
import { reduxForm } from "redux-form";
import { Alert, Col, Row } from "react-bootstrap";

import { StateSelect, TextField } from "common/components/forms/inputs";

let AddressForm = ({ error, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <TextField name="address_1" label="Address Line 1" />
    <TextField name="address_2" label="Address Line 2" />
    <Row>
      <Col sm={5}>
        <TextField name="city" label="City" />
      </Col>
      <Col sm={4}>
        <StateSelect name="state" label="State" />
      </Col>
      <Col sm={3}>
        <TextField name="postal_code" label="Postal Code" />
      </Col>
    </Row>
  </form>
);

export default reduxForm({
  form: "onboardingAddressForm",
})(AddressForm);
