import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";
import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_APPLICATIONS_STATS: null,
  RECEIVE_APPLICATIONS_STATS: null,
});
export default actions;

export const receiveApplicationsStats = createAction(
  actions.RECEIVE_APPLICATIONS_STATS,
  p => p,
  metaGenerator,
);

export const requestApplicationsStats = () => (dispatch, getState) => {
  dispatch(createAction(actions.REQUEST_APPLICATIONS_STATS)());
  const { applicationsStatsForm1, applicationsStatsForm2 } = getState().form;

  const url = "/api/v2/candidate_stats/?";
  const forms = [applicationsStatsForm1, applicationsStatsForm2];
  const params = forms.map(form => ({
    start_date: form.values.start_date,
    end_date: form.values.end_date,
    job: form.values.job,
  }));
  const urls = params.map(
    param =>
      url +
      Object.keys(param)
        .filter(key => Boolean(param[key]))
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(param[key])}`,
        )
        .join("&"),
  );
  urls.forEach((url, index) => {
    oldApi.get(url).then(({ json, response }) => {
      const payload = response.ok
        ? {
            stats: json,
            index,
          }
        : new Error("Error retrieving applications stats.");
      return dispatch(receiveApplicationsStats(payload));
    });
  });
};
