export const agentHireURLs = [
  "/agency/jobs/create/",
  "/agency/candidates/",
  "/agency/attorneys/",
];

export const agentManageURLs = [
  "/agency/jobs/",
  "/agency/invoices/",
  "/agency/payment-history/",
  "/agency/talent_pools/",
];

export const userName = user => {
  if (!user) return "";
  let firstName = user.first_name;
  if (firstName.length > 12) {
    firstName = firstName.slice(0, 8) + "...";
  }
  return `${firstName} ▾`;
};
