import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { compose } from "queries/utils";

import { SubscriptionSettingsSection } from "contractor/forms/settings/job-notifications";
import { withFormError } from "hiringagent-dashboard/components/jobs/create-job-listing/utils";
import { withErrorBoundary } from "common/components/error-boundary";

export const formName = "subscriptionForm";

const validate = values => {
  const errors = {};
  if (
    values.hasOwnProperty("subscription_status") &&
    values.subscription_status === -1
  ) {
    if (!values.paused_until) {
      errors.paused_until =
        "Select the date you would like to start receiving job notifications again.";
    }
  }
  return errors;
};

class SubscriptionForm extends Component {
  render() {
    const {
      intitalSubscriptionStatus,
      handleSubmit,
      pristine,
      submitting,
      valid,
      error,
      reset,
      untouch,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="subscription-form">
        <div className="settings-page-section">
          <h4 className="hidden-xs">Current Subscription Settings</h4>
          <SubscriptionSettingsSection
            intitalSubscriptionStatus={intitalSubscriptionStatus}
            reset={reset}
            error={error}
            handleSubmit={handleSubmit}
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            untouch={untouch}
          />
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: formName,
    validate,
  }),
  withFormError(),
  withErrorBoundary,
)(SubscriptionForm);
