// @flow
import { type Uuid } from "./primitives";

export default class PaymentDetails {
  static billingIncrementOptions = [1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30, 60];
  static PAYMENT_TYPES = {
    HOURLY: 0,
    PROJECT: 1,
    FIXED_WEEK: 2,
    FIXED_MONTH: 3,
    FIXED_MONTH_AUTOMATIC: 4,
  };
  static paymentTypesMapping = {
    [PaymentDetails.PAYMENT_TYPES.HOURLY]: {
      name: "Hourly",
      label: "per hour",
    },
    [PaymentDetails.PAYMENT_TYPES.PROJECT]: {
      name: "Project",
      label: "per project",
    },
    [PaymentDetails.PAYMENT_TYPES.FIXED_WEEK]: {
      name: "Fixed Week",
      label: "per week",
    },
    [PaymentDetails.PAYMENT_TYPES.FIXED_MONTH]: {
      name: "Fixed Month",
      label: "per month",
    },
    [PaymentDetails.PAYMENT_TYPES.FIXED_MONTH_AUTOMATIC]: {
      name: "Fixed Month Automatic",
      label: "per month",
    },
  };
  static SERVICE_TYPES = {
    "1099": 0,
    W2: 1,
  };
  static serviceOptions = [
    {
      label: "W-2 through Hire an Esquire",
      value: "1",
    },
    {
      label: "1099 Independent Contractor",
      value: "0",
    },
  ];
  uuid: Uuid;
  job: Uuid;
  send_daily_reminder: boolean;
  allow_expenses: boolean;
  service: number;
  rate: string;
  payment_type: number;
  address_1: string;
  address_2: ?string;
  city: string;
  state: Uuid;
  postal_code: string;
  created: string;
  updated: string;

  constructor(paymentDetails: Object) {
    this.uuid = paymentDetails.uuid;
    this.job = paymentDetails.job;
    this.send_daily_reminder = paymentDetails.send_daily_reminder;
    this.allow_expenses = paymentDetails.allow_expenses;
    this.service = paymentDetails.service;
    this.rate = paymentDetails.rate;
    this.payment_type = paymentDetails.payment_type;
    this.address_1 = paymentDetails.address_1;
    this.address_2 = paymentDetails.address_2;
    this.city = paymentDetails.city;
    this.state = paymentDetails.state;
    this.postal_code = paymentDetails.postal_code;
    this.created = paymentDetails.created;
    this.updated = paymentDetails.updated;
  }

  get paymentTypeDisplay(): string {
    return PaymentDetails.paymentTypesMapping[this.payment_type].name;
  }

  get rateDisplay(): string {
    return `$${this.rate} ${PaymentDetails.paymentTypesMapping[this.payment_type].label}`;
  }

  get serviceDisplay(): string {
    return PaymentDetails.serviceOptions.filter(
      item => item.value == this.service,
    )[0].label;
  }

  get isFixed(): boolean {
    const { PAYMENT_TYPES } = PaymentDetails;
    const fixedPaymentTypes = [
      PAYMENT_TYPES.PROJECT,
      PAYMENT_TYPES.FIXED_WEEK,
      PAYMENT_TYPES.FIXED_MONTH,
      PAYMENT_TYPES.FIXED_MONTH_AUTOMATIC,
    ];
    return fixedPaymentTypes.includes(this.payment_type);
  }

  get isFixedAutomatic(): boolean {
    const { PAYMENT_TYPES } = PaymentDetails;
    return this.payment_type === PAYMENT_TYPES.FIXED_MONTH_AUTOMATIC;
  }

  get period(): string {
    const { PAYMENT_TYPES } = PaymentDetails;
    const periodTypes = {
      [PAYMENT_TYPES.PROJECT]: "project",
      [PAYMENT_TYPES.FIXED_WEEK]: "week",
      [PAYMENT_TYPES.FIXED_MONTH]: "month",
    };
    return periodTypes[this.payment_type]
      ? periodTypes[this.payment_type]
      : "N/A";
  }

  get frequency(): string {
    const { PAYMENT_TYPES } = PaymentDetails;
    const frequencyTypes = {
      [PAYMENT_TYPES.FIXED_WEEK]: "weekly",
      [PAYMENT_TYPES.FIXED_MONTH]: "monthly",
    };
    return frequencyTypes[this.payment_type]
      ? frequencyTypes[this.payment_type]
      : "N/A";
  }
}
