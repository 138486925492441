import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Markdown from "markdown-to-jsx";

const TOSTitle = props => (
  <div {...props}>
    <h1 className="document-title">User Agreement </h1>
    <h2 className="document-subtitle">Terms of Service</h2>
  </div>
);

const ParenthesizedAbbreviation = ({ children, ...props }) => (
  <span {...props}>{`(${children})`}</span>
);

// Override elements created by markdown
const options = {
  overrides: {
    h1: {
      component: TOSTitle,
      props: {
        className: "header-title",
      },
    },
    a: {
      component: ParenthesizedAbbreviation,
    },
  },
};

class TextContainer extends Component {
  componentDidMount() {
    const href = window.location.href.split("#");
    if (href.length > 0) {
      const hash = href.pop();
      const element = document.getElementById(hash);
      if (element) {
        window.scrollTo(0, element.offsetTop);
      }
    }
  }

  render() {
    const { termsMarkdown } = this.props;
    return (
      <Row>
        <Col lgOffset={2} lg={8}>
          <div className="legal-document">
            <Markdown options={options}>{termsMarkdown}</Markdown>
          </div>
        </Col>
      </Row>
    );
  }
}

export default TextContainer;
