import React, { Component } from "react";
import { connect } from "react-redux";

import {
  submitSearchQuery,
  updateSearchKeywords,
} from "hiringagent-dashboard/actions/search";
import SearchBar from "hiringagent-dashboard/components/search/SearchBar";

class AttorneySearchBar extends Component {
  onSearchQueryChange(e) {
    this.props.dispatch(updateSearchKeywords(e.target.value));
  }

  onSearchQueryClear(e) {
    e.preventDefault();
    this.props.dispatch(updateSearchKeywords(""));
    this.props.dispatch(submitSearchQuery());
  }

  onSearchQuerySubmit(e) {
    e.preventDefault();
    this.props.dispatch(submitSearchQuery());
  }

  render() {
    const { keywords } = this.props.queryParams;
    return (
      <SearchBar
        onSearchQueryChange={e => this.onSearchQueryChange(e)}
        onSearchQueryClear={e => this.onSearchQueryClear(e)}
        onSearchQuerySubmit={e => this.onSearchQuerySubmit(e)}
        searchQuery={keywords}
      />
    );
  }
}

AttorneySearchBar.propTypes = {};

export default connect(state => state.hiringAgentDashboard.search)(
  AttorneySearchBar,
);
