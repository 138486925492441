import * as expenseActions from "admin/actions/invoicing/expenses";

const initialExpensesState = {
  lastUpdated: null,
  isFetching: false,
  items: {},
};

export default function expenses(state = initialExpensesState, action) {
  switch (action.type) {
    case expenseActions.REQUEST_EXPENSES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case expenseActions.RECEIVE_EXPENSES:
      return Object.assign({}, state, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        items: action.items,
      });
    default:
      return state;
  }
}
