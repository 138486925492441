import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";

const nPromoTableCols = "8";
const nReferralTableCols = "8";

const Placeholder = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan}>
      <center>Nothing to show.</center>
    </td>
  </tr>
);

const Th = styled.th`
  &&&& {
    text-align: center;
    vertical-align: middle;
  }
`;

const Td = styled.td`
  text-align: center;
`;

const PromoCodesTableRow = ({ item }) => {
  if (!item) return;

  const {
    invoice_data,
    promo_code_data: { amount_type, code, discount_amount, name, must_use_by },
  } = item;
  const hasInvoice =
    invoice_data && invoice_data.uuid && invoice_data.invoice_number;

  return (
    <tr>
      <Td>
        {item.added_date
          ? moment(item.added_date).format("YYYY-MM-DD")
          : "None"}
      </Td>
      <Td>{code || "None Retrieved"}</Td>
      <Td>{item.status_label || "None Retrieved"}</Td>
      <Td>{name || "None Retrieved"}</Td>
      <Td>
        {item.used_date
          ? moment(item.used_date).format("YYYY-MM-DD")
          : "Unused"}
      </Td>
      <Td>
        {discount_amount
          ? amount_type === "1"
            ? `${discount_amount}%`
            : `$${discount_amount}`
          : "None Retrieved"}
      </Td>
      <Td>
        {item.used_date
          ? "Used"
          : must_use_by
            ? moment(must_use_by).format("YYYY-MM-DD")
            : "None Retrieved"}
      </Td>
      <Td>
        {hasInvoice ? (
          <Link to={`/agency/invoices/${invoice_data.uuid}`}>
            {`#${invoice_data.invoice_number}`}
          </Link>
        ) : (
          "None"
        )}
      </Td>
    </tr>
  );
};

export const PromoCodesTable = ({ promoCodeHistory }) => (
  <div>
    <p>
      <strong>Promo Codes History:</strong>
    </p>
    <Table striped bordered condensed responsive>
      <thead>
        <tr>
          <Th>Added</Th>
          <Th>Code</Th>
          <Th>Status</Th>
          <Th>Description</Th>
          <Th>Used On</Th>
          <Th>
            Discount<br />Amount
          </Th>
          <Th>
            Must<br />Invoice By
          </Th>
          <Th>Invoice</Th>
        </tr>
      </thead>
      <tbody>
        {promoCodeHistory.length ? (
          promoCodeHistory
            .sort((a, b) => new Date(b.added_date) - new Date(a.added_date))
            .map((item, key) => <PromoCodesTableRow item={item} key={key} />)
        ) : (
          <Placeholder colSpan={nPromoTableCols} />
        )}
      </tbody>
    </Table>
  </div>
);

const ReferralCodesTableRow = ({ item }) => {
  if (!item || !item.sender_or_recipient) return;

  const { recipient_data: r, sender_data: s } = item;
  let sentOrReceived, name, status, balance, original;

  switch (item.sender_or_recipient) {
    case "sender":
      sentOrReceived = "Sent";
      name = r.first_name
        ? r.last_name
          ? `${r.first_name} ${r.last_name}`
          : `${r.first_name}`
        : "None";
      status = item.sender_status_label;
      balance = item.current_sender_balance;
      original = item.starting_sender_balance;
      break;
    case "recipient":
      sentOrReceived = "Received";
      name = s.first_name
        ? s.last_name
          ? `${s.first_name} ${s.last_name}`
          : `${s.first_name}`
        : "None";
      status = item.recipient_status_label;
      balance = item.current_recipient_balance;
      original = item.starting_recipient_balance;
      break;
  }

  return (
    <tr>
      <Td>
        {item.created ? moment(item.created).format("YYYY-MM-DD") : "None"}
      </Td>
      <Td>{item.code || "None Retrieved"}</Td>
      <Td>{status || "None Retrieved"}</Td>
      <Td>{name || "None Retrieved"}</Td>
      <Td>{sentOrReceived || "None Retrieved"}</Td>
      <Td>{balance ? `$${balance}` : "None Retrieved"}</Td>
      <Td>{original ? `$${original}` : "None Retrieved"}</Td>
      <Td>
        {item.last_used
          ? moment(item.last_used).format("YYYY-MM-DD")
          : "Unused"}
      </Td>
    </tr>
  );
};

export const ReferralCodesTable = ({ referralCodeHistory }) => (
  <div>
    <p>
      <strong>Referral Codes History:</strong>
    </p>
    <Table striped bordered condensed responsive>
      <thead>
        <tr>
          <Th>Added</Th>
          <Th>Code</Th>
          <Th>Status</Th>
          <Th>Name</Th>
          <Th>Action</Th>
          <Th>
            Current<br />Balance
          </Th>
          <Th>
            Starting<br />Balance
          </Th>
          <Th>
            Last<br />Used
          </Th>
        </tr>
      </thead>
      <tbody>
        {referralCodeHistory.length ? (
          referralCodeHistory
            .sort((a, b) => new Date(b.created) - new Date(a.created))
            .map((item, key) => <ReferralCodesTableRow item={item} key={key} />)
        ) : (
          <Placeholder colSpan={nReferralTableCols} />
        )}
      </tbody>
    </Table>
  </div>
);
