import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";

import { reviewsSelector } from "hiringagent-dashboard/selectors";
import {
  setFocusedReview,
  setStatusFilter,
} from "hiringagent-dashboard/actions/reviews";
import Loading from "common/components/Loading";
import Review from "hiringagent-dashboard/components/reviews/Review";
import ReviewsTable from "hiringagent-dashboard/components/reviews/ReviewsTable";

class Reviews extends Component {
  render() {
    const {
      isLoading,
      performanceReviews,
      focusedReview,
      statusFilter,
      handleReviewButtonClick,
      handleSetStatusFilter,
    } = this.props;
    const statusIsPending = statusFilter === "pending";

    const pendingPerformanceReviews = performanceReviews.filter(
      review => !review.isCompleted,
    );
    const completedPerformanceReviews = performanceReviews.filter(
      review => review.isCompleted,
    );
    const filteredReviews = statusIsPending
      ? pendingPerformanceReviews
      : completedPerformanceReviews;

    return (
      <div className="reviews">
        <h1>
          <i className={"far fa-thumbs-up"} /> Reviews
        </h1>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col xs={12} sm={6} className="hidden-xs">
                <p>
                  {`Showing ${filteredReviews.length} out of ${
                    Object.values(performanceReviews).length
                  } total reviews`}
                </p>
              </Col>
              <Col xs={12} sm={6}>
                <div className="filter-buttons-container">
                  <ButtonGroup className="pull-right">
                    <Button
                      onClick={() => handleSetStatusFilter("pending")}
                      id={statusFilter === "pending" ? "selected" : null}
                    >
                      Pending
                    </Button>
                    <Button
                      onClick={() => handleSetStatusFilter("completed")}
                      id={statusFilter === "completed" ? "selected" : null}
                    >
                      Completed
                    </Button>
                  </ButtonGroup>
                  <span className="clearfix" />
                </div>
              </Col>
              <Col xs={12} sm={6} className="visible-xs">
                <p style={{ textAlign: "center" }}>
                  {`Showing ${filteredReviews.length} out of ${
                    Object.values(performanceReviews).length
                  } reviews`}
                </p>
              </Col>
            </Row>

            <ReviewsTable
              performanceReviews={filteredReviews}
              statusFilter={statusFilter}
              onReviewButtonClick={handleReviewButtonClick}
            />

            {focusedReview && <Review focusedReview={focusedReview} />}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  reviewsSelector,
  dispatch => ({
    handleSetStatusFilter: status => dispatch(setStatusFilter(status)),
    handleReviewButtonClick: review => dispatch(setFocusedReview(review)),
  }),
)(Reviews);
