import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { metaGenerator } from "common/actions";
import api, { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_PERFORMANCE_REVIEWS: null,
  RECEIVE_PERFORMANCE_REVIEWS: null,
  REQUEST_SUBMIT_PERFORMANCE_REVIEW: null,
  RECEIVE_SUBMIT_PERFORMANCE_REVIEW: null,
  SET_FOCUSED_REVIEW: null,
  SET_REVIEWS_STATUS_FILTER: null,
});
export default actions;

export const requestPerformanceReviews = () =>
  api.get("/api/dev/performance_reviews/?limit=990", [
    actions.REQUEST_PERFORMANCE_REVIEWS,
    actions.RECEIVE_PERFORMANCE_REVIEWS,
  ]);

export function submitPerformanceReview(data) {
  return dispatch => {
    dispatch(createAction(actions.REQUEST_SUBMIT_PERFORMANCE_REVIEW)());
    return oldApi
      .patch(`/api/dev/performance_reviews/${data.performanceReviewId}/`, data)
      .then(({ json, response }) => {
        const payload = response.ok
          ? json
          : new Error("Error updating performance review.");
        dispatch(
          createAction(
            actions.RECEIVE_SUBMIT_PERFORMANCE_REVIEW,
            payload => payload,
            metaGenerator,
          )(payload),
        );
        return { json, response };
      });
  };
}

export const setFocusedReview = createAction(actions.SET_FOCUSED_REVIEW);
export const setStatusFilter = createAction(actions.SET_REVIEWS_STATUS_FILTER);
