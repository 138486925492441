// @flow strict
import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { newItemsQuery } from "queries/requests/newItems";
import types from "resources/types";

type RenderProps = {
  badge: boolean | number,
  dot: boolean,
};
export type MessagesLinkItemRenderProps = RenderProps;

let badgeCount = 0;
const MessagesLinkItem = props => {
  const {
    newItems: { data, isFinished, isPending },
    render,
  } = props;
  const isReady = isFinished && !isPending;
  if (isReady) {
    const newItems = Object.values(data)[0];
    badgeCount = newItems ? newItems.unread_messages : 0;
  }

  // Inbox doesn't have a sub-menu, so a dot will never be displayed
  return render({ badge: badgeCount, dot: false });
};

const mapPropsToConfig = newItemsQuery;
const mapStateToProps = createResourceSelector(
  types.NEW_ITEMS,
  mapPropsToConfig,
);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  MessagesLinkItem,
);
