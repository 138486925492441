import React from "react";
import { getComponentDisplayName } from "common/utils";
import { localStorage } from "common/utils/helpers";

const setCampaignParams = WrappedComponent => {
  class WithCampaignParams extends React.Component {
    UNSAFE_componentWillMount() {
      this.setURLCampaignParams();
    }

    getParameterByName(name, url = window.location.href) {
      const paramName = name.replace(/[[\]]/g, "\\$&");
      const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";

      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    setURLCampaignParams() {
      ["utm_source", "utm_medium", "utm_campaign"].forEach(param => {
        const paramValue = this.getParameterByName(param);
        const currentValue = localStorage.getItem(param);
        let newValue = paramValue;

        if (currentValue && !paramValue) {
          newValue = currentValue;
        }

        localStorage.setItem(param, newValue);
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithCampaignParams.displayName = `WithCampaignParams(${getComponentDisplayName(
    WrappedComponent,
  )})`;

  return WithCampaignParams;
};

export default setCampaignParams;
