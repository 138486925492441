// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import ActionButton from "./ActionButton";
import ButtonContainer from "./ButtonContainer";

const CandidateActionsBlock: ComponentType<{}> = styled.div``;
const CandidateActions = (props: {}) => <CandidateActionsBlock {...props} />;

CandidateActions.ActionButton = ActionButton;
CandidateActions.ButtonContainer = ButtonContainer;

export default CandidateActions;
