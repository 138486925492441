import React from "react";
import { Link } from "react-router-dom";

const Index = () => (
  <div>
    <h1>Applications Dashboard</h1>
    <ul>
      <li>
        <Link to="/hae_admin/dashboards/applications/stats">
          Applications Stats
        </Link>
      </li>
    </ul>
  </div>
);

export default Index;
