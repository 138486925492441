import React from "react";
import styled from "styled-components";

import { Nav, Navbar, NavItem } from "react-bootstrap";

// Routes without outer slashes
const hideFooterRoutes = ["agency/messages", "contractor/messages"];

export const shouldHideFooter = () => {
  const { pathname } = window.location;

  if (pathname) {
    for (let i = 0; i < hideFooterRoutes.length; i++) {
      if (pathname.indexOf(hideFooterRoutes[i]) !== -1) return true;
    }
  }

  return false;
};

const FooterOuterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  ${({ theme }) => {
    if (shouldHideFooter()) {
      return theme.media.mobile`
        display: none;
      `;
    }
  }}
`;

const FooterInnerWrapper = styled.div`
  height: 215px;
  ${({ theme }) => theme.media.sm`
    height: 50px;
  `};
`;

const StyledNavbar = styled(Navbar)`
  &.navbar {
    border-radius: 0;
    border: none;
    margin-bottom: 0;
  }
`;

const Footer = () => (
  <FooterOuterWrapper>
    <FooterInnerWrapper>
      <StyledNavbar fluid inverse>
        <Nav>
          <NavItem
            eventKey={1}
            href="/public/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </NavItem>
          <NavItem
            eventKey={2}
            href="/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </NavItem>
          <NavItem
            eventKey={1}
            href="https://hireanesquire.com/faq-legal-professionals"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </NavItem>
          <NavItem
            eventKey={1}
            href="https://hireanesquire.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </NavItem>
        </Nav>
        <Navbar.Text pullRight style={{ marginRight: 0 }}>
          <span>
            Copyright &copy; 2010-{new Date().getFullYear()}, Hire an Esquire,
            Inc. All rights reserved.
          </span>
        </Navbar.Text>
      </StyledNavbar>
    </FooterInnerWrapper>
  </FooterOuterWrapper>
);

export default Footer;
