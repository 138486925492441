/** @flow */
import React, { Component, type ComponentType } from "react";
import { connect } from "react-redux";
import { addNotification } from "reapop";
import * as Sentry from "@sentry/browser";

import { createErrorNotification } from "common/utils/notifications";
import ErrorBoundaryDefaultComponent from "./ErrorBoundaryDefaultComponent";

type Props = {
  children: any,
  FallbackComponent: ComponentType<any>,
  addNotification: (createErrorNotification: () => void) => void,
  notify?: boolean,
  showError?: boolean,
};

type ErrorInfo = {
  componentStack: string,
};

type State = {
  error: ?Error,
  info: ?ErrorInfo,
};

class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    FallbackComponent: ErrorBoundaryDefaultComponent,
    notify: false,
    showError: true,
  };

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { notify, addNotification } = this.props;

    if (notify) {
      addNotification(createErrorNotification());
    }

    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });

    this.setState({ error, info });
  }

  render() {
    const { children, FallbackComponent, showError } = this.props;
    const { error, info } = this.state;
    const componentStack = info ? info.componentStack : "";

    if (error !== null) {
      if (!showError) {
        return null;
      }

      return (
        <FallbackComponent componentStack={componentStack} error={error} />
      );
    }

    return children;
  }
}

export default connect(
  null,
  { addNotification },
)(ErrorBoundary);
