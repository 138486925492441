// @flow strict
import { createResourceDetailQuery, createResourceListQuery } from "queries";
import types from "resources/types";

export const jobsQuery = () =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=999",
  });

export const createJobQuery = (config: {} = {}, job: string): QueryConfig =>
  createResourceDetailQuery("jobs", {
    url: `/api/v2/jobs/${job}`,
    ...config,
  });

export const createJobsQuery = (config: {} = {}): QueryConfig =>
  createResourceListQuery("jobs", {
    url: "/api/v2/jobs/",
    ...config,
  });

export const candidateJobListingsCountQuery = () =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: "/api/v2/joblistings/?is_candidate=true&limit=1",
    force: true,
  });
