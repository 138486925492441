import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { EnvelopeIcon, ArchiveIcon } from "./blocks";

const UnreadToggler = ({ unread }) => (
  <OverlayTrigger
    trigger={["hover"]}
    placement="top"
    overlay={
      <Popover id="popover-envelope">
        {`Mark as ${unread ? "Read" : "Unread"}`}
      </Popover>
    }
  >
    <EnvelopeIcon className={`far fa-envelope${unread ? "" : "-open"}`} />
  </OverlayTrigger>
);

const ArchivedToggler = ({ archived }) => (
  <OverlayTrigger
    trigger={["hover"]}
    placement="top"
    overlay={
      <Popover id="popover-archive">
        {`Mark as ${archived ? "Unarchived" : "Archived"}`}
      </Popover>
    }
  >
    <ArchiveIcon className={`far ${archived ? "fa-mailbox" : "fa-archive"}`} />
  </OverlayTrigger>
);

const Togglers = ({
  archivedLoadId,
  archived,
  onTogglersClick,
  unreadLoadId,
  unread,
  uuid,
}) => (
  <div onClick={e => onTogglersClick(uuid, e)}>
    {archivedLoadId === uuid ? (
      <i
        className="far fa-cog fa-spin"
        id="archived-loader"
        style={{ float: "right" }}
      />
    ) : (
      <ArchivedToggler archived={archived} />
    )}
    {unreadLoadId === uuid ? (
      <i
        className="far fa-cog fa-spin"
        id="unread-loader"
        style={{ float: "right", marginRight: "10px" }}
      />
    ) : (
      <UnreadToggler unread={unread} />
    )}
  </div>
);

export default Togglers;
