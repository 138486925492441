import React, { Component } from "react";
import moment from "moment";
import { connectRequest } from "redux-query-react";
import { connect, compose } from "resources/components/utils";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

export class JobsPanel extends Component {
  render() {
    const {
      jobListings: {
        data: jobListings,
        query: { data: ordering },
        isPending,
        lastUpdated,
      },
    } = this.props;

    let sortedJobs = ordering.map(uuid => jobListings[uuid]);
    const numJobs = sortedJobs.length; // Grab length before we splice

    sortedJobs = sortedJobs.splice(0, 2);

    return (
      <div>
        {isPending ? (
          <div className="text-center" style={{ marginTop: "4rem" }}>
            <i className="far fa-cog fa-spin fa-2x" />
          </div>
        ) : lastUpdated ? (
          numJobs > 0 ? (
            <div>
              <div className="line-item">
                <Row>
                  <Col xs={12}>
                    <span className="line-item-title">Manage</span>
                  </Col>
                </Row>
              </div>
              {sortedJobs.map(job => (
                <div className="line-item" key={job.uuid}>
                  <Row>
                    <Col xs={12}>
                      <span className="pull-left">
                        <Link to={`/agency/jobs/${job.job}/`}>
                          {job.formattedTitle}
                        </Link>
                      </span>
                      <div style={{ clear: "both" }} />
                      {job.published ? (
                        <div className="subline">
                          Published on{" "}
                          {moment(job.published_date).format("MMM D, YYYY")}
                        </div>
                      ) : (
                        <div className="subline">Not yet published</div>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
              {numJobs > 2 && (
                <div className="line-item text-center">
                  <Row>
                    <Col xs={12}>
                      <Link to="/agency/jobs/">View all jobs</Link>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="line-item text-center">
                <Row>
                  <Col xs={12}>
                    <Link to="/agency/jobs/create/">Post a job</Link> to start
                    recruiting!
                  </Col>
                </Row>
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }
}

const mapPropsToConfig = () =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: "/api/v2/joblistings/?limit=3&ordering=-created",
    force: true,
  });

const mapStateToProps = createResourceSelector(
  types.JOB_LISTINGS,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobsPanel);
