import React from "react";

import { createApiUrl } from "utils";

export const ContractorAlert = () => (
  <>
    <p>
      <strong>
        Did you include an email address or phone number in your message?
      </strong>
    </p>
    <p>
      A friendly reminder that accepting a job and/or billing around our
      platform is a violation of our{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={createApiUrl(
          "/terms-of-service#non-interference-with-client-contractsnon-solicitation-of-client-accounts",
        )}
      >
        TOS
      </a>
      . This voids our payment guarantees, professional liability and other
      contractor insurances and can result in termination of your account and
      legal action.
    </p>
  </>
);

export const HiringAgentAlert = () => (
  <>
    <p>
      <strong>
        Did you include an email address or phone number in your message?
      </strong>
    </p>
    <p>
      A friendly reminder that extending a job offer and/or paying a contractor
      outside of our platform is a violation of our{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={createApiUrl("/terms-of-service#non-circumvention")}
      >
        TOS
      </a>
      . This voids all of our contractor guarantees and insurances and can
      result in termination of your account and legal action.
    </p>
  </>
);

export const ShortGenericAlert = () => (
  <>
    It looks like an email address or phone number is in this message. A
    friendly remember that hiring or invoicing outside the platform is a
    violation of our{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={createApiUrl("/terms-of-service#non-circumvention")}
    >
      TOS
    </a>{" "}
    that voids all Hire an Esquire guarantees and insurances. It can result in
    termination of your account and possible legal action.
  </>
);
