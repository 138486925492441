import React from "react";
import styled from "styled-components";

import Container from "./Container";
import Main from "./Main";
import Bar from "common/blocks/Bar";
import TopLabel from "./TopLabel";
import BottomLabel from "./BottomLabel";
import BlueCheck from "./BlueCheck";

const ProgressBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Progress = props => <ProgressBlock {...props} />;

Progress.Container = Container;
Progress.Main = Main;
Progress.Bar = Bar;
Progress.TopLabel = TopLabel;
Progress.BottomLabel = BottomLabel;
Progress.BlueCheck = BlueCheck;

export default Progress;
