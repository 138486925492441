import React, { Component } from "react";
import { connect } from "react-redux";
import { getDisplayName } from "hiringagent-dashboard/utils";
import { action, resourceActionIfNeeded } from "common/actions/utils";

export const connectResource = mapPropsToOptions => WrappedComponent => {
  class ConnectResource extends Component {
    static defaultProps = {
      onLoadHandlers: {},
      onReloadHandlers: {},
    };

    componentDidMount() {
      const { onLoad } = this.props;
      const options = mapPropsToOptions(this.props);
      const { shouldLoad = true } = options;

      if (onLoad && shouldLoad) {
        onLoad({ ...options, actionType: "resource" });
      }
    }

    render() {
      const options = mapPropsToOptions(this.props);
      const { type } = options;
      const onLoadHandlers = {
        ...this.props.onLoadHandlers,
        [type]: () => this.props.onLoad({ ...options, actionType: "resource" }),
      };
      const onReloadHandlers = {
        ...this.props.onReloadHandlers,
        [type]: () =>
          this.props.onReload({ ...options, actionType: "resource" }),
      };

      return (
        <WrappedComponent
          {...this.props}
          onLoadHandlers={onLoadHandlers}
          onReloadHandlers={onReloadHandlers}
        />
      );
    }
  }

  ConnectResource.displayName = `ConnectResource(${getDisplayName(
    WrappedComponent,
  )})`;

  return connect(
    null,
    {
      onLoad: resourceActionIfNeeded,
      onReload: action,
    },
  )(ConnectResource);
};
