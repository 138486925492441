import React, { Component } from "react";
import { reduxForm } from "redux-form";
import FormButton from "common/components/FormButton";
import PositionInterestForm, {
  formName,
  requiredFields,
} from "contractor/forms/PositionInterestForm";

export { requiredFields };

class EditPositionInterestForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <PositionInterestForm />
        <div className="text-right">
          <FormButton
            className="text-right"
            action="save"
            submitting={submitting}
            disabled={pristine || submitting || !valid}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  initialValues: {
    interested_in_temp: false,
    interested_in_perm: false,
  },
})(EditPositionInterestForm);
