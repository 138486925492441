// @flow strict
import React, { Component } from "react";
import Progress from "./blocks";

type Props = {
  completed: number,
  total: number,
};

type State = {
  offset: number,
  stick: boolean,
};

// ProgressBar works best when placed at the bottom of a div React Component
// that is directly rendered into a React Route. ProgressBar also depends on
// the page footer (id="page-footer") component to work properly.

export default class ProgressBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { offset: 0, stick: false };
  }

  componentDidMount() {
    this.handlePosition();
    window.addEventListener("resize", this.handleReposition);
    window.addEventListener("scroll", this.handleReposition);
    window.addEventListener("focus", this.handleReposition);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleReposition);
    window.removeEventListener("scroll", this.handleReposition);
    window.removeEventListener("focus", this.handleReposition);
  }

  handlePosition = () => this.setState({ offset: 0, stick: false });

  handleReposition = () => {
    const footer = document.getElementById("page-footer");
    const bodyOffset = document.body ? document.body.offsetHeight : null;

    if (bodyOffset && footer) {
      const yPos = bodyOffset - (window.innerHeight + window.pageYOffset);
      if (yPos <= footer.offsetHeight) {
        this.setState({ offset: footer.offsetHeight, stick: true });
      } else if (this.state.offset !== 0) {
        this.setState({ offset: 0, stick: false });
      }
    }
  };

  render() {
    const { offset, stick } = this.state;
    const { completed, total } = this.props;
    return (
      <Progress offset={offset}>
        <Progress.Container offset={offset} stick={stick}>
          <Progress.Main>
            <Progress.Bar percentage={100 * (completed / total)} />
          </Progress.Main>
          {completed === total ? (
            <Progress.Label>Done!</Progress.Label>
          ) : (
            <Progress.Label>
              {`${completed} / ${total}`}
              <Progress.LabelExtra> complete</Progress.LabelExtra>
            </Progress.Label>
          )}
        </Progress.Container>
      </Progress>
    );
  }
}
