// @flow strict
import React from "react";
import { Col, Row } from "react-bootstrap";

const StripeDisclosure = () => (
  <Row>
    <Col sm={12}>
      <p>
        Hire an Esquire uses{" "}
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>{" "}
        to process payments. Direct deposit information is required prior to
        accepting a job offer.
      </p>
    </Col>
  </Row>
);

export default StripeDisclosure;
