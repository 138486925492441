import React from "react";

import { EditContractor } from "contractor/components/settings";
import AvailabilityForm, {
  hoursPerWeekOptions,
  requiredFields,
} from "contractor/forms/profile/AvailabilityForm";

const props = {
  formComponentClass: AvailabilityForm,
  title: "Availability",
  requiredFields,
};

const getIntialValues = ({ availability }) => {
  if (!availability) return {};
  const initialHoursPerWeek = hoursPerWeekOptions.find(
    option => option.label === availability.hours_per_week,
  );
  return {
    hours_per_week: initialHoursPerWeek ? initialHoursPerWeek.value : null,
    available_weekdays: availability.available_weekdays,
    available_weekends: availability.available_weekends,
    available_evenings: availability.available_evenings,
    notes: availability.notes,
  };
};

const EditAvailability = ({ contractor, ...otherProps }) => (
  <EditContractor
    contractorId={contractor.uuid}
    initialValues={getIntialValues(contractor)}
    transformValues={values => ({ availability: values })}
    {...props}
    {...otherProps}
  />
);

export default EditAvailability;
