import types from "resources/types";
import {
  deleteResourceQuery,
  getDefaultOptions,
  mutateResourceQuery,
} from "queries";

export const create = data => {
  const options = {
    ...getDefaultOptions(),
    method: "POST",
  };
  delete options.headers["Content-Type"]; // Let content type be inferred
  return mutateResourceQuery(
    types.JOB_ATTACHMENTS,
    {
      url: "/api/v2/job_attachments/",
      options,
    },
    data,
  );
};

export const Delete = id =>
  deleteResourceQuery(
    types.JOB_ATTACHMENTS,
    {
      url: `/api/v2/job_attachments/${id}/`,
      options: {
        method: "DELETE",
      },
    },
    id,
  );
