import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";

import { withMessages } from "hiringagent-dashboard/connectors/messages";

export class MessagesPanel extends Component {
  render() {
    const {
      messages: { messages },
    } = this.props;
    const messageThreads = Object.keys(messages)
      .map(k => messages[k])
      .splice(0, 3);

    return (
      <div>
        {messageThreads.length > 0 ? (
          <div className="messages-preview">
            <div className="visible-xs">
              {messageThreads.map(thread => {
                const firstRecipient = thread.recipients[0];
                return (
                  <Link
                    to={`/agency/messages/${thread.uuid}/`}
                    className={`line-item ${thread.unread ? "unread" : ""}`}
                    key={`${thread.uuid}-xs`}
                  >
                    <Row>
                      <Col xs={12}>
                        <div>
                          {!thread.from_self ? (
                            <span>
                              {thread.sender.first_name}{" "}
                              {thread.sender.last_name}
                            </span>
                          ) : (
                            <span>
                              {firstRecipient.first_name}{" "}
                              {firstRecipient.last_name}
                            </span>
                          )}
                        </div>
                        <div className="subline">{thread.subject}</div>
                      </Col>
                    </Row>
                  </Link>
                );
              })}
            </div>
            <Table className="hidden-xs">
              <tbody>
                {messageThreads.map(thread => {
                  const firstRecipient = thread.recipients[0];
                  return (
                    <tr
                      key={thread.uuid}
                      className={`thread ${thread.unread ? "unread" : ""}`}
                    >
                      <td className="name">
                        {!thread.from_self ? (
                          <span>
                            {thread.sender.first_name} {thread.sender.last_name}
                          </span>
                        ) : (
                          <span>
                            {firstRecipient.first_name}{" "}
                            {firstRecipient.last_name}
                          </span>
                        )}
                      </td>
                      <td className="subject">
                        <Link to={`/agency/messages/${thread.uuid}/`}>
                          {thread.subject}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>
            <div className="line-item text-center">
              <Row>
                <Col xs={12}>You have no new messages.</Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withMessages({ limit: 3 })(MessagesPanel);
