import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";

import {
  LanguageSelect,
  LanguageProficiencySelect,
} from "common/components/forms/inputs";

export const formName = "languageForm";
export const requiredFields = ["language", "fluency"];

class EditLanguagesForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <LanguageSelect />
        <LanguageProficiencySelect />
        <div className="text-right">
          <Button
            type="submit"
            bsStyle="primary"
            disabled={pristine || submitting}
          >
            {submitting ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: "languageForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(EditLanguagesForm);
