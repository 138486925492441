import actions from "../actions/favorites";
import sidebarActions from "../actions/sidebar";
import { handleActions } from "redux-actions";

const initialState = {
  favorites: [],
  isLoading: false,
  lastUpdated: null,
};

const favorites = handleActions(
  {
    [actions.REQUEST_UPDATE_FAVORITE]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_UPDATE_FAVORITE]: (state, action) => ({
      ...state,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [sidebarActions.REQUEST_HIRING_AGENT]: state => ({
      ...state,
      isLoading: true,
    }),
    [sidebarActions.RECEIVE_HIRING_AGENT]: (state, action) => ({
      ...state,
      favorites: action.payload.a_list,
      isLoading: false,
    }),
  },
  initialState,
);
export default favorites;
