import styled, { css } from "styled-components";

const StyledText = styled.span`
  color: ${({ textType, theme }) => {
    switch (textType) {
      case "success":
        return theme.colors.success();
      case "warning":
        return theme.colors.warning();
      case "danger":
        return theme.colors.secondary_red();
      case "primary":
        return theme.colors.primary_turquoise();
      case "black":
        return theme.colors.primary_black();
      default:
        return "default";
    }
  }};

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `};

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};
`;

export default StyledText;
