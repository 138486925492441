import React from "react";
import { reduxForm } from "redux-form";
import { Alert } from "react-bootstrap";

import { TextAreaField, TextField } from "common/components/forms/inputs";
import { headlinePlaceholder, summaryPlaceholder } from "./copy";

let AdditionalDetailsForm = ({ error, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <TextField
      name="summary_title"
      label="Headline"
      placeholder={headlinePlaceholder}
    />
    <TextAreaField
      name="summary"
      label="Summary"
      placeholder={summaryPlaceholder}
      rows={8}
      maxLength={500}
    />
  </form>
);

export default reduxForm({
  form: "onboardingAdditionalDetailsForm",
})(AdditionalDetailsForm);
