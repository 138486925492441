import { basePath } from "contractor/components/Onboarding/values";

const values = {
  onboarding: {
    label: "GO TO ACCOUNT SETUP",
    url: basePath,
  },
  contact_information: {
    label: "GO TO CONTACT INFO",
    url: `${basePath}/contact-information`,
  },
  email_address_confirmation: {
    label: "GO TO EMAIL CONFIRMATION",
    url: `${basePath}/email-address-confirmation`,
  },
  bar_admissions: {
    label: "GO TO BAR DOCUMENTATION",
    url: `${basePath}/bar-admissions`,
  },
  education: {
    label: "GO TO EDUCATION",
    url: `${basePath}/education`,
  },
  work_experience: {
    label: "GO TO WORK EXPERIENCE",
    url: `${basePath}/work-experience`,
  },
  references: {
    label: "GO TO REFERENCES",
    url: `${basePath}/references`,
  },
  address: {
    label: "GO TO ADDRESS",
    url: `${basePath}/address`,
  },
  practice_areas: {
    label: "GO TO PRACTICE AREAS",
    url: `${basePath}/practice-areas`,
  },
  additional_details: {
    label: "GO TO ADDITIONAL DETAILS",
    url: `${basePath}/additional-details`,
  },
  profile_picture: {
    label: "GO TO PROFILE PICTURE",
    url: `${basePath}/profile-picture`,
  },
  language_proficiencies: {
    label: "GO TO LANGUAGES",
    url: `${basePath}/language-proficiencies`,
  },
};

export default values;
