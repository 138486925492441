// @flow strict
import React, { Component, cloneElement, type Element } from "react";
import { createStructuredSelector } from "reselect";
import { connectRequest } from "redux-query-react";

import Loading from "common/components/Loading";
import { connect, compose } from "resources/components/utils";
import { createResourceSelectorConfig } from "queries";
import { currentUserTypeQuery } from "queries/requests/auth";
import { TermsOfServiceQuery } from "queries/requests/termsOfService";
import types from "resources/types";

type Props = {
  children: Element<*>,
};

class TermsOfService extends Component<Props> {
  componentDidMount() {
    document.title = "Terms of Service";
  }

  render() {
    const {
      children,
      [types.CURRENT_USER_TYPE]: {
        data: userTypeData,
        isFinished: currentUserTypeFinished,
      },
      [types.TERMS_OF_SERVICE]: {
        data: termsOfServiceData,
        isFinished: termsOfServiceFinished,
      },
    } = this.props;

    if (!currentUserTypeFinished || !termsOfServiceFinished) {
      return <Loading />;
    } else {
      const userType = userTypeData[Object.keys(userTypeData)[0]].user_type;
      const tosObj = termsOfServiceData[Object.keys(termsOfServiceData)[0]];
      let tosByUserType;

      switch (userType) {
        case "contractor":
          tosByUserType = tosObj.tos_markup_attorney;
          break;
        case "hiring_agent":
          tosByUserType = tosObj.tos_markup_hiring_agent;
          break;
        case "admin":
        case "anonymous":
        default:
          tosByUserType = tosObj.tos_markup_general;
          break;
      }

      return (
        <div>{cloneElement(children, { tosObj, tosByUserType, userType })}</div>
      );
    }
  }
}

const CurrentUserTypeConfig = createResourceSelectorConfig(
  types.CURRENT_USER_TYPE,
  currentUserTypeQuery,
);

const TermsOfServiceConfig = createResourceSelectorConfig(
  types.TERMS_OF_SERVICE,
  TermsOfServiceQuery,
);

const mapStateToProps = createStructuredSelector({
  ...CurrentUserTypeConfig,
  ...TermsOfServiceConfig,
});

const mapPropsToConfig = () => [currentUserTypeQuery(), TermsOfServiceQuery()];

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(TermsOfService);
