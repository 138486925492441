import { change, submit } from "redux-form";

import { action } from "common/actions/utils";
import { createConnectorCreator } from "common/connectors/utils";

const createContractorConnector = createConnectorCreator("contractor");

export const withApplications = createContractorConnector({
  type: "applications",
  params: { limit: 999 },
});
export const withConflicts = createContractorConnector({
  type: "conflicts",
  params: { limit: 99 },
});
export const withOwnContractor = createContractorConnector({
  type: "ownContractor",
  verboseName: "OwnContractor",
  path: "contractors/self",
  version: "dev",
});
export const withExperience = createContractorConnector({
  type: "experience",
  verboseName: "Experience",
  path: "previous_employments",
  params: { limit: 999 },
});
export const withJobOffers = createContractorConnector({
  type: "jobOffers",
  path: "applications",
  params: { offered: true },
});
export const withJobPermission = createContractorConnector({
  type: "jobPermission",
  path: null,
});
export const withJobRequirements = createContractorConnector({
  type: "jobRequirements",
  path: "null",
});
const jobSearchResultsOptions = { type: "jobSearchResults", path: "jobs" };
export const withJobSearchResults = createContractorConnector({
  ...jobSearchResultsOptions,
  mapDispatchToProps: {
    changeFieldValue: (field, value) => change("jobSearchForm", field, value),
    submitSearchForm: () => submit("jobSearchForm"),
    onSearchSubmit: options =>
      action({ ...jobSearchResultsOptions, ...options }),
    onLoad: null,
  },
});
export const withJobWorkers = createContractorConnector({
  type: "jobWorkers",
  path: "job_workers",
  params: { limit: 99 },
});
export const withJobWorkersOnOpenJobs = createContractorConnector({
  type: "jobWorkersOnOpenJobs",
  path: "job_workers",
  params: { job_open: true },
});
export const withLanguageFluency = createContractorConnector({
  type: "languageFluency",
  verboseName: "LanguageFluency",
  path: "contractor_languages",
  params: { limit: 999 },
});
export const withPendingApplications = createContractorConnector({
  type: "pendingApplications",
  path: "applications",
  params: { pending: true },
});
export const withRecommendations = createContractorConnector({
  type: "recommendations",
  version: "dev",
});
export const withSchools = createContractorConnector({
  type: "schools",
  params: { limit: 99 },
});
