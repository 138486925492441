import React from "react";

import Onboard from "../../blocks";

export const MainCopy = ({ isAttorney }) => (
  <p>
    {`We require at least one undergraduate degree${
      isAttorney ? " and one law degree." : "."
    }`}
  </p>
);

export const PendingVerificationCopy = () => (
  <p>We&apos;re currently reviewing your education entries.</p>
);

export const NoEntriesCopy = () => (
  <Onboard.TablePlaceholder>
    You don&apos;t have any schools on file. Click &quot;Add a New School&quot;
    to add.
  </Onboard.TablePlaceholder>
);
