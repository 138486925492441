import { combineReducers } from "redux";

import resources from "resources";
import { createResourceReducer, createResourceStoreReducer } from "./utils";

export const resourcesReducer = combineReducers(
  resources.reduce(
    (res, resource) => ({
      ...res,
      [resource]: createResourceReducer(resource),
    }),
    {},
  ),
);

export const resourcesStoreReducer = combineReducers(
  resources.reduce(
    (res, resource) => ({
      ...res,
      [resource]: createResourceStoreReducer(resource),
    }),
    {},
  ),
);
