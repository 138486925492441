import actions from "common/actions/fixtures";
import { handleActions } from "redux-actions";
import states from "common/constants/states";

const initialSubState = {
  options: [],
  isLoading: false,
  lastUpdated: null,
};

const initialState = {
  degrees: { ...initialSubState },
  firmTypes: { ...initialSubState },
  hoursPerWeek: { ...initialSubState },
  positionTypes: { ...initialSubState },
  states: { options: states },
  tagGroups: { ...initialSubState },
  tags: { ...initialSubState },
};

const fixtures = handleActions(
  {
    [actions.REQUEST_FIXTURE]: (state, action) => ({
      ...state,
      [action.payload.fixture]: {
        ...state[action.payload.fixture],
        isLoading: true,
      },
    }),
    [actions.RECEIVE_FIXTURE]: (state, action) => ({
      ...state,
      [action.payload.fixture]: {
        options: action.payload.results,
        isLoading: false,
        lastUpdated: action.meta.receivedAt,
      },
    }),
  },
  initialState,
);
export default fixtures;
