import React, { Component } from "react";

import Side from "./blocks";
import LinkItem from "./LinkItem";

export default class LinkList extends Component {
  render() {
    const { currentStep, steps, mobile } = this.props;

    return (
      <Side.ParentList mobile={mobile}>
        <Side.ParentItem mobile={mobile}>
          Introduce Yourself
          <Side.ChildList>
            <LinkItem
              currentStep={currentStep}
              linkStepName="contact_information"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="email_address_confirmation"
              steps={steps}
            />
          </Side.ChildList>
        </Side.ParentItem>
        <Side.ParentItem mobile={mobile}>
          Verification
          <Side.ChildList>
            <LinkItem
              currentStep={currentStep}
              linkStepName="bar_admissions"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="education"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="work_experience"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="references"
              steps={steps}
            />
          </Side.ChildList>
        </Side.ParentItem>
        <Side.ParentItem mobile={mobile}>
          Build Your Profile
          <Side.ChildList>
            <LinkItem
              currentStep={currentStep}
              linkStepName="address"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="practice_areas"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="additional_details"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="profile_picture"
              steps={steps}
            />
            <LinkItem
              currentStep={currentStep}
              linkStepName="language_proficiencies"
              steps={steps}
            />
          </Side.ChildList>
        </Side.ParentItem>
      </Side.ParentList>
    );
  }
}
