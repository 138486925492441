import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { connectRequest } from "redux-query-react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { addNotification as notify } from "reapop";

import { action } from "common/actions/utils";
import {
  markRecommendationDismissed,
  markRecommendationInvited,
} from "hiringagent-dashboard/actions/recommendations";
import DismissRecommendationModal from "hiringagent-dashboard/components/recommendations/DismissRecommendationModal";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { Job } from "types";

import * as Sentry from "@sentry/browser";

const createActionFailedMessage = (recommendation, action) => ({
  title: "Error",
  message: `Could not ${action} candidate. The candidate may have already been invited or dismissed. Please try refreshing the page, or contact support if the problem persists.`,
  status: "error",
  position: "tr",
  dismissible: true,
  dismissAfter: 5000,
});

const ActionStatusDisplay = ({ recommendation }) => (
  <div style={{ fontSize: "2.4rem" }}>
    {recommendation.invited && (
      <div className="text-success">{`${recommendation.contractor.full_name} has been invited.`}</div>
    )}
    {recommendation.dismissed && (
      <div className="text-danger">{`${recommendation.contractor.full_name} is no longer recommended.`}</div>
    )}
  </div>
);

class RecommendationActionButtons extends Component {
  static propTypes = {
    onReload: PropTypes.func.isRequired,
    recommendation: PropTypes.object.isRequired,
    invitedOrDismissed: PropTypes.bool.isRequired,
  };

  state = {
    submitting: false,
    isModalOpen: false,
    feedbackText: "",
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ feedbackText: "" });
    this.setState({ isModalOpen: false });
  };

  handleChangeFeedbackText = e => {
    this.setState({ feedbackText: e.target.value });
  };

  handleInviteRecommendation = () => {
    const {
      recommendation,
      invite,
      markInvited,
      notify,
      onReload,
    } = this.props;

    invite(recommendation.uuid)
      .then(({ json, response }) => {
        if (!response.ok) {
          let error = Error(
            "Recommendation invitation error: " + JSON.stringify(json),
          );
          Sentry.captureException(error);
          throw error;
        }
      })
      .then(() => markInvited(recommendation.uuid))
      .catch(() => {
        notify(createActionFailedMessage(recommendation, "invite"));
        onReload();
      });
  };

  handleDismissRecommendation = () => {
    this.setState({ submitting: true }, () => {
      const {
        recommendation,
        dismiss,
        markDismissed,
        notify,
        onReload,
      } = this.props;
      const reason = this.state.feedbackText || null;

      dismiss(recommendation.uuid, { reason })
        .then(({ json, response }) => {
          if (!response.ok) {
            let error = Error(
              "Recommendation dismissal error: " + JSON.stringify(json),
            );
            Sentry.captureException(error);
            throw error;
          }
        })
        .then(() => markDismissed(recommendation.uuid))
        .then(() => {
          this.setState({ submitting: false });
          this.closeModal();
        })
        .catch(() => {
          this.setState({ submitting: false });
          notify(createActionFailedMessage(recommendation, "dismiss"));
          onReload();
        });
    });
  };

  render() {
    const {
      [types.JOBS]: { data: jobs, isFinished },
      recommendation,
      invitedOrDismissed,
    } = this.props;
    const { isModalOpen, feedbackText, submitting } = this.state;
    const job =
      isFinished &&
      Object.values(jobs).find(
        job => job.joblisting === recommendation.joblisting,
      );
    const jobIsDraft = job && job.status === Job.STATUS.DRAFT;

    return (
      <Fragment>
        <div style={{ display: "inline-block" }}>
          <DismissRecommendationModal
            recommendation={recommendation}
            show={isModalOpen}
            close={this.closeModal}
            feedbackText={feedbackText}
            onChange={this.handleChangeFeedbackText}
            onDismissClick={this.handleDismissRecommendation}
            submitting={submitting}
          />
          {invitedOrDismissed ? (
            <ActionStatusDisplay recommendation={recommendation} />
          ) : (
            <ButtonToolbar>
              <Button bsStyle="danger" bsSize="large" onClick={this.openModal}>
                Dismiss
              </Button>
              <Button
                bsStyle="success"
                bsSize="large"
                disabled={jobIsDraft}
                onClick={this.handleInviteRecommendation}
              >
                Invite
              </Button>
            </ButtonToolbar>
          )}
        </div>
        {jobIsDraft && !invitedOrDismissed && (
          <div className="text-center text-muted">
            Your job must be published before you can invite candidates to
            apply.
          </div>
        )}
      </Fragment>
    );
  }
}

const createRecommendationAction = (recommendationId, actionName, args) =>
  action({
    type: types.RECOMMENDATIONS,
    path: `recommendations/${recommendationId}/${actionName}/`,
    version: "dev",
    actionType: "resource",
    method: "post",
    ...args,
  });

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOBS, {
    url: `/api/v2/jobs/?joblisting=${props.recommendation.joblisting}`,
  });

const mapStateToProps = createResourceSelector(types.JOBS, mapPropsToConfig);

export default compose(
  connectRequest(mapPropsToConfig),
  connect(
    mapStateToProps,
    {
      notify,
      dismiss: (recommendationId, data) =>
        createRecommendationAction(recommendationId, "dismiss", { data }),
      invite: recommendationId =>
        createRecommendationAction(recommendationId, "invite"),
      markDismissed: markRecommendationDismissed,
      markInvited: markRecommendationInvited,
    },
  ),
)(RecommendationActionButtons);
