// @flow strict
import styled from "styled-components";

const ProfilePictureContainer = styled.div`
  grid-column: 1 / 2;
  ${({ theme }) => theme.media.desktop`
    grid-row: 1 / 2;
    text-align: center;
  `};
`;

export default ProfilePictureContainer;
