// @flow strict
import React, { Fragment } from "react";

import AttorneyProfilePicture from "hiringagent-dashboard/components/search/AttorneyProfilePicture";

type Props = {
  contractor: Contractor,
};

const ProfilePicture = (props: Props) => {
  const { contractor } = props;
  return (
    <Fragment>
      <span className="visible-xs">
        <AttorneyProfilePicture
          size={100}
          pictureUrl={contractor.profile_picture_url}
          firstName={contractor.first_name}
          lastName={contractor.last_name}
          uuid={contractor.uuid}
        />
      </span>
      <span className="hidden-xs">
        <AttorneyProfilePicture
          size={150}
          pictureUrl={contractor.profile_picture_url}
          firstName={contractor.first_name}
          lastName={contractor.last_name}
          uuid={contractor.uuid}
        />
      </span>
    </Fragment>
  );
};

export default ProfilePicture;
