import React from "react";

import { JobSearch } from "common/components/job-search";

const ContractorJobSearch = () => (
  <div>
    <h1>Job Search</h1>
    <JobSearch />
  </div>
);

export default ContractorJobSearch;
