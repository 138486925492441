// @flow strict
import styled from "styled-components";

const ButtonContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .btn:last-child {
    display: block;
    flex-basis: 100%;
  }
  ${({ theme }) => theme.media.desktop`
    margin-bottom: 4rem;
    .btn:last-child {
      margin-left: auto;
      display: inline-block;
      flex-basis: auto;
    }
  `};
`;

export default ButtonContainer;
