import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import moment from "moment";
import { createStructuredSelector } from "reselect";
import { Col, Row } from "react-bootstrap";

import { compose, connect, connectRequest } from "queries/utils";
import { ownContractorQuery } from "queries/requests/contractors";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";
import { FormFieldToolTip } from "common/components/forms";
import { connectFixtures } from "common/utils";
import Loading from "common/components/Loading";
import {
  DatePickerField,
  EmailAddressInput,
  FirstNameInput,
  LastNameInput,
  PhoneNumberInput,
  ReactSelectField,
  SelectField,
  TextAreaField,
  TextField,
} from "common/components/forms/inputs";

export const formName = "workExperienceForm";
export const requiredFields = [
  "job_title",
  "other_company",
  "start_date",
  "description",
  "tags",
];
export const requiredNonAttorneyFields = requiredFields.concat([
  "reference_first_name",
  "reference_last_name",
  "reference_email",
  "reference_phone_number",
]);

const FirmTypeToolTip = () => {
  const hint = `If this work experience is law-related,
                   select the firm type that best characterizes the company.`;
  return <FormFieldToolTip label="Firm Type" content={hint} placement="top" />;
};

const PositionTypeToolTip = () => {
  const hint = `If this work experience is law-related,
                   select the position type that best characterizes your role.`;
  return (
    <FormFieldToolTip label="Position Type" content={hint} placement="top" />
  );
};

class WorkExperienceForm extends Component {
  renderNonAttorneyFields(ownContractor) {
    const { other_company = "the company above" } = this.props;
    return (
      !ownContractor.is_attorney && (
        <div>
          <div
            style={{
              margin: "2rem 0",
              paddingBottom: "1rem",
              borderBottom: "1px solid #EEE",
            }}
          >
            Below, please provide the contact details for a manager or HR
            representative at {other_company}. We will contact this person to
            verify your employment history:
          </div>
          <Row>
            <Col sm={6}>
              <FirstNameInput name="reference_first_name" />
            </Col>
            <Col sm={6}>
              <LastNameInput name="reference_last_name" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <EmailAddressInput name="reference_email" />
            </Col>
            <Col sm={6}>
              <PhoneNumberInput name="reference_phone_number" />
            </Col>
          </Row>
        </div>
      )
    );
  }

  render() {
    const {
      fixtures: {
        tags: { options: tagOptions },
        firmTypes: { options: firmTypeOptions },
        positionTypes: { options: positionTypeOptions },
      },
      ownContractor: { data, isFinished, isPending },
    } = this.props;
    const isReady = isFinished && !isPending;

    if (!isReady) return <Loading />;

    const ownContractor = Object.values(data)[0];

    const formattedTagOptions = tagOptions.map(tag => ({
      value: tag.uuid,
      label: tag.name,
    }));
    const filteredPositionTypeOptions = positionTypeOptions.filter(
      positionType => {
        if (ownContractor.is_attorney) return true;
        return !ownContractor.is_attorney && positionType.for_non_attorneys;
      },
    );

    return (
      <div>
        <TextField name="job_title" label="Job Title" />
        <TextField name="other_company" label="Company" />
        <Row>
          <Col sm={6}>
            <SelectField name="position_type" label={<PositionTypeToolTip />}>
              {filteredPositionTypeOptions.map(option => (
                <option key={option.uuid} value={option.uuid}>
                  {option.name}
                </option>
              ))}
            </SelectField>
          </Col>
          <Col sm={6}>
            <SelectField name="firm_type" label={<FirmTypeToolTip />}>
              {firmTypeOptions.map(option => (
                <option key={option.uuid} value={option.uuid}>
                  {option.name}
                </option>
              ))}
            </SelectField>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <DatePickerField
              name="start_date"
              label="Start Date"
              maxDate={moment().toDate()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </Col>
          <Col sm={6}>
            <DatePickerField
              name="end_date"
              label="End Date"
              maxDate={moment().toDate()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </Col>
        </Row>
        <TextAreaField
          name="description"
          label="Description"
          rows={8}
          maxLength={2000}
        />
        <ReactSelectField
          name="tags"
          label="Select up to 3 applicable areas of expertise."
          options={formattedTagOptions}
          multi={true}
        />
        {this.renderNonAttorneyFields(ownContractor)}
      </div>
    );
  }
}

const ownContractorSelectorConfig = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);

const mapStateToProps = state => ({
  other_company: formValueSelector(formName)(state, "other_company"),
  ...createStructuredSelector({ ...ownContractorSelectorConfig })(state),
});

const mapPropsToConfig = () => [ownContractorQuery()];

WorkExperienceForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName,
  }),
  connectFixtures,
  connectRequest(mapPropsToConfig),
)(WorkExperienceForm);

export default WorkExperienceForm;
