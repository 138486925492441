// @flow strict
import React from "react";
import styled from "styled-components";

import Header from "./Header";
import AlternateHeader from "./AlternateHeader";
import Subheader from "./Subheader";
import Paragraph from "./Paragraph";
import PrimaryButton from "common/blocks/PrimaryButton";
import LinkButton from "./LinkButton";
import OuterTop from "./OuterTop";
import Container from "./Container";
import Box from "./Box";
import Form from "./Form";
import QuestionGroup from "./QuestionGroup";
import Question from "./Question";
import QuestionHeader from "./QuestionHeader";
import HideOnDesktop from "./HideOnDesktop";
import HideOnMobile from "./HideOnMobile";

type Props = {};

const WorkstyleBlock = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.mobile`
    padding: 15px;
  `};
`;

const Workstyle = (props: Props) => <WorkstyleBlock {...props} />;

Workstyle.Header = Header;
Workstyle.AlternateHeader = AlternateHeader;
Workstyle.Subheader = Subheader;
Workstyle.Paragraph = Paragraph;
Workstyle.Button = PrimaryButton;
Workstyle.LinkButton = LinkButton;
Workstyle.OuterTop = OuterTop;
Workstyle.Container = Container;
Workstyle.Box = Box;
Workstyle.Form = Form;
Workstyle.QuestionGroup = QuestionGroup;
Workstyle.Question = Question;
Workstyle.QuestionHeader = QuestionHeader;
Workstyle.HideOnDesktop = HideOnDesktop;
Workstyle.HideOnMobile = HideOnMobile;

export default Workstyle;
