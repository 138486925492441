import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 30px 30px 45px 30px;
  padding: 15px;
  border: 2px solid ${({ theme }) => theme.colors.primary_black10()};
  border-radius: 15px;

  & h1 {
    text-align: center;
  }

  ${({ theme }) => theme.media.desktop`
    padding: 15px 30px;
  `};
`;

export default Container;
