// @flow strict
import styled from "styled-components";

const ClearAll = styled.p`
  margin: 0 0 15px 0;
  padding: 0;
  float: right;
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  font-size: 18px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default ClearAll;
