import React from "react";
import { reduxForm } from "redux-form";

import { PrimaryPracticeAreaSelectField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";
import { compose } from "resources/components/utils";
import { FormRow, withFormError } from "./utils";

const requiredFields = ["primary_practice_area"];

const validate = values => validateRequiredFields(requiredFields, values);

let PrimaryPracticeAreaForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <FormRow>
      <PrimaryPracticeAreaSelectField />
      <div>
        <p>You&apos;ll select more specific skill sets next.</p>
      </div>
    </FormRow>
  </form>
);

PrimaryPracticeAreaForm = compose(
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(PrimaryPracticeAreaForm);

const PrimaryPracticeArea = ({ formProps, jobListing }) => {
  const initialValues = {
    primary_practice_area: jobListing.primary_practice_area,
  };
  return (
    <div>
      <h2>What&apos;s the primary practice area for your job?</h2>
      <p className="description" />
      <PrimaryPracticeAreaForm {...formProps} initialValues={initialValues} />
    </div>
  );
};

export default PrimaryPracticeArea;
