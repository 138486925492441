// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Main from "./Main";
import DetailsContainer from "./DetailsContainer";
import TalentPoolsButtonContainer from "./TalentPoolsButtonContainer";
import Headline from "./Headline";
import Info from "./Info";
import Name from "./Name";
import NameAndStatusContainer from "./NameAndStatusContainer";
import PracticeAreasContainer from "./PracticeAreasContainer";
import PracticeAreasTitle from "./PracticeAreasTitle";
import ProfilePictureContainer from "./ProfilePictureContainer";
import StatusLabel from "./StatusLabel";
import HideOnDesktop from "./HideOnDesktop";

const SidebarBlock: ComponentType<{}> = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Sidebar = (props: {}) => <SidebarBlock {...props} />;

Sidebar.Main = Main;
Sidebar.DetailsContainer = DetailsContainer;
Sidebar.TalentPoolsButtonContainer = TalentPoolsButtonContainer;
Sidebar.Headline = Headline;
Sidebar.Info = Info;
Sidebar.Name = Name;
Sidebar.NameAndStatusContainer = NameAndStatusContainer;
Sidebar.PracticeAreasContainer = PracticeAreasContainer;
Sidebar.PracticeAreasTitle = PracticeAreasTitle;
Sidebar.ProfilePictureContainer = ProfilePictureContainer;
Sidebar.StatusLabel = StatusLabel;
Sidebar.HideOnDesktop = HideOnDesktop;

export default Sidebar;
