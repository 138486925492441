import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMutation, useRequest } from "redux-query-react";

import TalentPoolsNotification from "./TalentPoolsNotification";
import MessagingNotification from "./MessagingNotification";
import { newFeatureNotificationsRequest } from "queries/requests";
import { newFeatureNotificationMutations } from "queries/mutations";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";

const NewfeatureNotification = ({ new_feature, read, onClose }) => {
  if (read) {
    return null;
  } else if (new_feature === "talent_pools") {
    return <TalentPoolsNotification onClose={onClose} />;
  } else if (new_feature === "messaging") {
    return <MessagingNotification onClose={onClose} />;
  } else {
    return null;
  }
};

const NewFeatureNotificationsModal = () => {
  const [show, setShow] = useState(true);
  const request = newFeatureNotificationsRequest({
    url: { params: { read: false, new_feature__on: true } },
  });
  const selector = createResourceSelector(
    types.NEW_FEATURE_NOTIFICATIONS,
    request,
  );
  const {
    [types.NEW_FEATURE_NOTIFICATIONS]: {
      data,
      query: { data: queryData, count },
    },
  } = useSelector(selector);
  const [{ isFinished }, _] = useRequest(request);
  const [__, markNotificationAsRead] = useMutation(id =>
    newFeatureNotificationMutations.update(id, { read: true }),
  );
  if (!isFinished || count === 0) return null;
  const visibleNotificationId = queryData[0];
  const handleClose = () => {
    markNotificationAsRead(visibleNotificationId);
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>What&apos;s New on Hire an Esquire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Only display one notification per page load */}
        {/* TODO: Could implement in-modal pagination to display multiple notifications */}
        <NewfeatureNotification
          {...data[visibleNotificationId]}
          onClose={handleClose}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="link" onClick={handleClose}>
          <strong className="text-uppercase">Okay!</strong>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewFeatureNotificationsModal;
