import React from "react";
import PropTypes from "prop-types";
import { Popover, OverlayTrigger } from "react-bootstrap";

const EnglishSpokenNote = () => (
  <p>
    <strong>
      Please Note: It is assumed contractors speak English proficiently.
    </strong>
  </p>
);

export const LanguageHelpText = () => (
  <div>
    <p>
      We use the ILR (FSI) proficiency scale to measure language proficiency.
    </p>
    <p>The levels are as follows:</p>
    <ul>
      <li>
        <strong>S-1: </strong>Elementary Proficiency
      </li>
      <li>
        <strong>S-2: </strong>Limited Working Proficiency
      </li>
      <li>
        <strong>S-3: </strong>Professional Working Proficiency
      </li>
      <li>
        <strong>S-4: </strong>Full Professional Proficiency
      </li>
      <li>
        <strong>S-5: </strong>Native or Bilingual Proficiency
      </li>
    </ul>
  </div>
);

const LanguageTooltip = ({ id, placement }) => {
  const languageTooltipPopover = (
    <Popover
      title="Language Proficiency"
      id={`attorney-language-tooltip-${id}`}
    >
      <LanguageHelpText />
      <EnglishSpokenNote />
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={placement}
      overlay={languageTooltipPopover}
    >
      <i className="far fa-info-circle" />
    </OverlayTrigger>
  );
};

LanguageTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};

LanguageTooltip.defaultProps = {
  placement: "top",
};

export default LanguageTooltip;
