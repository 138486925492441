import { createSelector, createStructuredSelector } from "reselect";

import { createStructuredSelectorCreator } from "common/selectors";

export const stashProfilePictureSelector = createStructuredSelectorCreator(
  "stashProfilePicture",
  "contractor",
);

export const getApplicationForJob = jobId =>
  createSelector(
    state => state.resourcesStore.applications,
    applications =>
      Object.values(applications).find(app => app.job.uuid === jobId),
  );

export const getWorkerForJob = jobId =>
  createSelector(
    state => state.resourcesStore.jobWorkers,
    jobWorkers =>
      Object.values(jobWorkers).find(worker => worker.job.uuid === jobId),
  );

export const getBankAccounts = createStructuredSelector({
  bankAccounts: state => state.resources.bankAccounts,
});

export const getOwnContractor = createStructuredSelector({
  ownContractor: state => state.resources.ownContractor,
});

export const getReviewRequests = createSelector(
  state => state.resources.reviewRequests,
  reviewRequests => reviewRequests,
);

const pendingFirst = (a, b) => a.status < b.status;

export const getActiveReviewRequests = () =>
  createSelector(
    state => state.resourcesStore.reviewRequests,
    reviewRequests =>
      Object.values(reviewRequests)
        .filter(request => request.status !== "deleted")
        .sort(pendingFirst),
  );
