import React from "react";

export default () => (
  <a
    href="mailto:support@hireanesquire.com"
    target="_top"
    rel="noopener noreferrer"
  >
    support@hireanesquire.com
  </a>
);
