import styled from "styled-components";

const BottomLabel = styled.p`
  width: 100%;
  padding-right: 10px;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  font-size: 12px;
  font-weight: 700;
`;

export default BottomLabel;
