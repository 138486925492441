import styled from "styled-components";

const Item = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 10px 0;
`;

export default Item;
