import React, { Fragment } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";

import { Loading } from "common/components";
import { savedContractorsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";
import { removeEmptyValues } from "utils";
import { SavedContractorCard, TalentPoolsSearchPagination } from ".";

const NoResults = styled.div`
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
`;
const Results = styled.div`
  > :not(:first-child) {
    margin-top: 20px;
  }
`;
const Pagination = styled.div`
  text-align: center;
`;

const noSavedContractorsMessage =
  "It looks like you don't have any saved contractors yet. Click the Add to " +
  "Talent Pools button on a contractor profile to start tracking your saved contractors.";
const noResultsMessage =
  "There were no results matching the specified filters.";

const TalentPoolsSearchResults = props => {
  const {
    onPageChange,
    values: { talent_pool, limit, offset, search },
  } = props;

  const request = savedContractorsRequest({
    url: { params: removeEmptyValues(props.values) },
    force: true,
  });
  const selector = createResourceSelector(types.SAVED_CONTRACTORS, request);
  const {
    [types.SAVED_CONTRACTORS]: {
      data,
      query: { data: queryData, count },
    },
  } = useSelector(selector);
  const [{ isFinished, isPending }, _] = useRequest(request);

  const noFiltersApplied =
    talent_pool === null && offset === 0 && search === "";

  return (
    <div>
      {isPending && <Loading />}
      {isFinished &&
        count === 0 && (
          <NoResults>
            {noFiltersApplied ? noSavedContractorsMessage : noResultsMessage}
          </NoResults>
        )}
      {isFinished && (
        <Fragment>
          <Results>
            {queryData
              .map(id => data[id])
              .map((savedContractor, i) => (
                <SavedContractorCard
                  key={savedContractor.uuid}
                  savedContractor={savedContractor}
                  resultNumber={offset + i + 1}
                />
              ))}
          </Results>
          <Pagination>
            <TalentPoolsSearchPagination
              offset={offset}
              limit={limit}
              total={count}
              onChange={onPageChange}
            />
          </Pagination>
        </Fragment>
      )}
    </div>
  );
};

export default TalentPoolsSearchResults;
