import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";

const OfferCandidateModal = ({
  name = "this candidate",
  job,
  onClose,
  onSubmit,
  show,
  submitting,
}) => {
  const body = (
    <Fragment>
      <h4 style={{ marginBottom: "1rem" }}>
        Are you sure that you want to <strong>send an offer to {name}</strong>{" "}
        for the job {job.formattedTitle}?
      </h4>
      <p>
        If {name} accepts your offer, he/she will become a worker on the job.
      </p>
      <p>
        If {name} does not accept your offer, you can re-offer the position or
        reject the candidate. Try messaging the candidate to discuss terms. You
        can adjust the payment details for this job until you make your first
        hire.
      </p>
    </Fragment>
  );
  const offerButton = (
    <Button
      bsStyle="success"
      disabled={submitting}
      onClick={onSubmit}
      className="pull-right"
    >
      Send Offer
    </Button>
  );
  return (
    <ConfirmModal
      show={show}
      close={onClose}
      body={body}
      actions={offerButton}
    />
  );
};

const CannotOfferCandidateModal = ({
  name = "this candidate",
  job,
  onClose,
  show,
}) => {
  const paymentDetailsLink = `/agency/jobs/payment_details/${job.uuid}/`;
  const body = (
    <Fragment>
      <h4 style={{ marginBottom: "1rem" }}>
        You must submit payment details for this job before you can send offers
        to candidates.
      </h4>
      <p>
        Once you submit payment details for {job.formattedTitle}, you can return
        back here to send an offer to {name}.
      </p>
      <div style={{ textAlign: "center", margin: "1.5rem 0" }}>
        <LinkContainer to={paymentDetailsLink}>
          <Button bsStyle="primary">Finalize Payment Details</Button>
        </LinkContainer>
      </div>
    </Fragment>
  );
  return (
    <ConfirmModal
      show={show}
      close={onClose}
      showFooter={false}
      closeButton={true}
      body={body}
      title="Finalize Job Payment Details"
    />
  );
};

export const CandidateOfferModal = props =>
  props.job.paymentdetails ? (
    <OfferCandidateModal {...props} />
  ) : (
    <CannotOfferCandidateModal {...props} />
  );

export const CandidateRejectModal = ({
  name = "this candidate",
  job,
  onClose,
  onSubmit,
  show,
  submitting,
}) => {
  const body = (
    <Fragment>
      <h4 style={{ marginBottom: "1rem" }}>
        Are you sure that you want to{" "}
        <strong>reject {name}&apos;s application</strong> for the job{" "}
        {job.formattedTitle}?
      </h4>
      <p>
        {name} will be notified that he/she is no longer being considered for
        the job.
      </p>
    </Fragment>
  );
  const rejectButton = (
    <Button
      bsStyle="danger"
      disabled={submitting}
      onClick={onSubmit}
      className="pull-right"
    >
      Reject
    </Button>
  );
  return (
    <ConfirmModal
      show={show}
      close={onClose}
      body={body}
      actions={rejectButton}
    />
  );
};
