import styled from "styled-components";

const ChildItem = styled.li`
  margin: 0;
  padding: 0;

  &,
  & * {
    margin: 5px 0;
    padding: 0;
    font-weight: 400;
    color: ${({ isDisabled, theme }) =>
      isDisabled
        ? theme.colors.primary_black40()
        : theme.colors.primary_black()};

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: ${({ isDisabled, theme }) =>
        isDisabled
          ? theme.colors.primary_black40()
          : theme.colors.primary_black()};
    }
  }
`;

export default ChildItem;
