import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { metaGenerator } from "common/actions";
import api, { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_NOTIFICATIONS: null,
  RECEIVE_NOTIFICATIONS: null,
  REQUEST_MARK_NOTIFICATION_READ: null,
  RECEIVE_MARK_NOTIFICATION_READ: null,
});
export default actions;

export const requestNotifications = () =>
  api.get("/api/dev/notifications/?limit=99", [
    actions.REQUEST_NOTIFICATIONS,
    actions.RECEIVE_NOTIFICATIONS,
  ]);

export function markNotificationRead(notificationId) {
  return dispatch => {
    dispatch(
      createAction(actions.REQUEST_MARK_NOTIFICATION_READ)({ notificationId }),
    );
    return oldApi
      .patch(`/api/dev/notifications/${notificationId}/mark_read/`)
      .then(({ json, response }) => {
        const payload = response.ok
          ? json
          : new Error("Error marking notification read.");
        dispatch(
          createAction(
            actions.RECEIVE_MARK_NOTIFICATION_READ,
            payload => payload,
            metaGenerator,
          )(payload),
        );
        return { json, response };
      });
  };
}
