import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Col, Row } from "react-bootstrap";

import FormButton from "common/components/FormButton";
import { StateSelect, TextField } from "common/components/forms/inputs";

export const formName = "addressForm";
export const requiredFields = ["address_1", "city", "state", "postal_code"];

class AddressForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <TextField name="address_1" label="Address Line 1" />
        <TextField name="address_2" label="Address Line 2" />
        <Row>
          <Col sm={5}>
            <TextField name="city" label="City" />
          </Col>
          <Col sm={4}>
            <StateSelect name="state" label="State" />
          </Col>
          <Col sm={3}>
            <TextField name="postal_code" label="Postal Code" />
          </Col>
        </Row>
        <div className="text-right">
          <FormButton
            className="text-right"
            action="save"
            submitting={submitting}
            disabled={pristine || submitting || !valid}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
})(AddressForm);
