// @flow
import React, { Component } from "react";

import {
  Alert,
  AlertPagination,
} from "hiringagent-dashboard/components/alerts";
import {
  createJobListingComponents,
  calculateJobListingFormUrl,
} from "hiringagent-dashboard/utils/jobs";
import { Job, JobListing } from "types";

import { localStorage } from "common/utils/helpers";

const ALERT_TYPES = {
  COMPLETE_JOB_LISTING: 0,
  PUBLISH_JOB_LISTING: 1,
  SUBMIT_PAYMENT_DETAILS: 2,
  PROVIDE_PAYMENT_METHOD: 3,
  // MARK_JOB_AS_FILLED: 4,  // TODO
};

type Resource<ResourceType> = { [string]: ResourceType };
type JobResource = Resource<Job>;
type AlertType = {
  alertId: string,
  jobId: string,
  title: string,
  titleForJobDetails: string,
};

const filterJobHasStatus = (status: number) => (job: Job) =>
  job && job.status === status;
const filterJobListingHasStatus = (status, jobs: Job[]) => (
  jobListing: JobListing,
) => {
  const job = jobs[jobListing.job];
  return filterJobHasStatus(status)(job);
};

const ALERTS = {
  [ALERT_TYPES.COMPLETE_JOB_LISTING]: {
    createCTAProps: (jobListing: JobListing) => ({
      jobId: jobListing.job,
      label: "Complete now",
      link: calculateJobListingFormUrl(jobListing),
      title: `Complete your job posting: ${jobListing.formattedTitle}`,
      titleForJobDetails: "Complete your job posting",
      alertId: `alert-${jobListing.uuid}-${ALERT_TYPES.COMPLETE_JOB_LISTING}`,
      children: (
        <p>
          Your job posting hasn&apos;t been completed yet! Before you can start
          hiring for this job, you&apos;ll need to provide some more
          information.
        </p>
      ),
    }),
    filter: (jobs, jobListings) =>
      Object.values(jobListings)
        .filter((jobListing: JobListing) =>
          createJobListingComponents.find(step => {
            if (typeof step.isComplete === "function") {
              return !step.isComplete(jobListing);
            } else {
              return !step.isComplete;
            }
          }),
        )
        .filter(filterJobListingHasStatus(Job.STATUS.DRAFT, jobs)),
  },
  [ALERT_TYPES.PUBLISH_JOB_LISTING]: {
    createCTAProps: (jobListing: JobListing) => ({
      jobId: jobListing.job,
      label: "Publish now",
      link: calculateJobListingFormUrl(jobListing),
      title: `Publish your job posting: ${jobListing.formattedTitle}`,
      titleForJobDetails: "Publish your job posting",
      alertId: `alert-${jobListing.uuid}-${ALERT_TYPES.PUBLISH_JOB_LISTING}`,
      children: (
        <p>
          Your job posting hasn&apos;t been published yet! You&apos;ll need to
          publish this job posting to start hiring.
        </p>
      ),
    }),
    filter: (jobs, jobListings) =>
      Object.values(jobListings)
        .filter((jobListing: JobListing) =>
          createJobListingComponents.every(step => {
            if (typeof step.isComplete === "function") {
              return step.isComplete(jobListing);
            } else {
              return step.isComplete;
            }
          }),
        )
        .filter(filterJobListingHasStatus(Job.STATUS.DRAFT, jobs)),
  },
  [ALERT_TYPES.SUBMIT_PAYMENT_DETAILS]: {
    createCTAProps: (job: Job) => ({
      jobId: job.uuid,
      label: "Submit payment details",
      link: `/agency/jobs/payment_details/${job.uuid}/`,
      title: `Submit payment details for ${job.formattedTitle}`,
      titleForJobDetails: "Submit payment details",
      alertId: `alert-${job.uuid}-${ALERT_TYPES.SUBMIT_PAYMENT_DETAILS}`,
      children: (
        <p>
          You&apos;ll need to provide payment details for this job before you
          can offer it to an applicant.
        </p>
      ),
    }),
    filter: jobs =>
      Object.keys(jobs)
        .map(k => jobs[k])
        .filter((job: Job) => !job.paymentdetails)
        .filter(filterJobHasStatus(Job.STATUS.OPEN_FOR_APPLICATIONS)),
  },
  [ALERT_TYPES.PROVIDE_PAYMENT_METHOD]: {
    createCTAProps: (job: Job) => ({
      jobId: job.uuid,
      label: "Provide or verify payment method",
      link: "/agency/settings/#payment",
      title: `Provide or verify payment method for ${job.formattedTitle}`,
      titleForJobDetails: "Provide a payment method",
      alertId: `alert-${job.uuid}-${ALERT_TYPES.PROVIDE_PAYMENT_METHOD}`,
      children: (
        <p>
          You must have a verified payment method on file before you can offer
          this job to an applicant. To verify a bank account, you must enter the
          microdesposit amounts deposited to the account.
        </p>
      ),
    }),
    filter: (jobs: JobResource, _, agency) => {
      const STRIPE_PAYMENT_METHOD = 1;
      if (agency && agency.can_pay) return [];
      return Object.keys(jobs)
        .map(k => jobs[k])
        .filter((job: Job) => job.payment_method === STRIPE_PAYMENT_METHOD)
        .filter(filterJobHasStatus(Job.STATUS.OPEN_FOR_APPLICATIONS));
    },
  },
  // TODO: This alert involves more than just routing to a page, the Alert component will need to be refactored
  // TODO: slightly to accomodate this.
  // [ALERT_TYPES.MARK_JOB_AS_FILLED]: {
  //     createCTAProps: (job: Job) => ({
  //         jobId: job.uuid,
  //         label: 'Mark job as filled',
  //         link: `/agency/jobs/${job.uuid}/`,
  //         title: `Has ${job.formattedTitle} been filled?`,
  //         titleForJobDetails: 'Has your job been filled?',
  //         alertId: `alert-${job.uuid}-${ALERT_TYPES.MARK_JOB_AS_FILLED}`,
  //         children: (
  //             <Fragment>
  //                 <p>
  //                     It looks like you made a successful placement! If you are no longer recruiting for this
  //                     position, you can mark it as filled to close it to applicants.
  //                 </p>
  //                 <p>
  //                     If you are still recruiting, you can hide this message.
  //                 </p>
  //             </Fragment>
  //         ),
  //     }),
  //     filter: (jobs, jobListings) => Object.values(jobs).filter(
  //         (job: Job) => {
  //             const jobListing = jobListings[job.joblisting]
  //             return jobListing && job.committed_worker_count >= jobListing.num_openings
  //         }
  //     ).filter(filterJobHasStatus(Job.STATUS.OPEN_FOR_APPLICATIONS)),
  // },
};

const dashboardAlertPriority = [
  ALERT_TYPES.PROVIDE_PAYMENT_METHOD,
  ALERT_TYPES.SUBMIT_PAYMENT_DETAILS,
  // ALERT_TYPES.MARK_JOB_AS_FILLED,  // TODO
  ALERT_TYPES.PUBLISH_JOB_LISTING,
  ALERT_TYPES.COMPLETE_JOB_LISTING,
];
const jobDetailsAlertPriority = [
  ALERT_TYPES.COMPLETE_JOB_LISTING,
  ALERT_TYPES.PUBLISH_JOB_LISTING,
  ALERT_TYPES.SUBMIT_PAYMENT_DETAILS,
  ALERT_TYPES.PROVIDE_PAYMENT_METHOD,
  // ALERT_TYPES.MARK_JOB_AS_FILLED,  // TODO
];

type Props = {
  agency: Object,
  jobId: string,
  jobs: Object,
  jobListings: Object,
};

type State = {
  hiddenAlerts: string[],
};

class AlertSystem extends Component<Props, State> {
  static defaultProps = {
    jobId: null,
  };

  constructor(props: Props) {
    super(props);

    const alerts = this.getAlerts();
    const alertIds = alerts.map(({ alertId }) => alertId);
    const hiddenAlerts = alertIds.filter(
      alertId => localStorage.getItem(alertId) === "hide",
    );

    this.state = {
      hiddenAlerts,
    };
  }

  getAlertOrdering = () => {
    const { jobId } = this.props;
    if (jobId) return jobDetailsAlertPriority;
    return dashboardAlertPriority;
  };

  handleHideAlertClick = (alertId: string) => () => {
    const { hiddenAlerts } = this.state;
    if (!hiddenAlerts.includes(alertId)) {
      this.setState(state => ({
        hiddenAlerts: [...state.hiddenAlerts, alertId],
      }));
    }
    localStorage.setItem(alertId, "hide");
  };

  getAlerts = () => {
    const { agency, jobs, jobListings } = this.props;

    const ordering = this.getAlertOrdering();
    const orderedAlerts = ordering.map(alertType => ALERTS[alertType]);

    return orderedAlerts.reduce((res, alert) => {
      const objects = alert
        .filter(jobs, jobListings, agency)
        .map(obj => alert.createCTAProps(obj));
      return [...res, ...objects];
    }, []);
  };

  getFilteredAlerts = () => {
    const { jobId } = this.props;
    const { hiddenAlerts } = this.state;
    let alerts: Array<AlertType> = this.getAlerts();

    alerts = (alerts.filter(
      (alert: AlertType) => !hiddenAlerts.includes(alert.alertId),
    ): Array<AlertType>);

    if (jobId) {
      alerts = (alerts.filter(
        (alert: AlertType) => alert.jobId === jobId,
      ): Array<AlertType>);
    }

    return alerts;
  };

  render() {
    const { jobId } = this.props;

    const alerts = this.getFilteredAlerts();

    return (
      <AlertPagination>
        {alerts.map(alertProps => {
          if (jobId) alertProps.title = alertProps.titleForJobDetails;
          return (
            <Alert
              key={alertProps.alertId}
              onHideClick={this.handleHideAlertClick(alertProps.alertId)}
              {...alertProps}
            />
          );
        })}
      </AlertPagination>
    );
  }
}

export default AlertSystem;
