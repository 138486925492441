import { createSelector, createStructuredSelector } from "reselect";

export const fixturesSelector = createSelector(
  state => state.common.fixtures,
  fixtures => ({ fixtures }),
);

export const tagsSelector = createSelector(
  state => state.common.fixtures.tags,
  tags => ({ tags }),
);

export const createStructuredSelectorCreator = (slice, reducer = "common") =>
  createStructuredSelector({
    [slice]: state => {
      const slicedState = state[reducer][slice];
      const { isLoading, lastUpdated } = slicedState;
      return {
        ...slicedState,
        ready: Boolean(!isLoading && lastUpdated),
      };
    },
  });
