import React, { Component } from "react";
import { createStructuredSelector } from "reselect";

import Onboard from "../../blocks";
import NextButton from "../../NextButton";
import FormModal from "./FormModal";
import Loading from "common/components/Loading";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import {
  getActionIsRequired,
  getCustomErrorMsg,
  getCanMoveOnToNextStep,
  getVerifIsPending,
  redirectToNextStepIfNeeded,
} from "../../utils";
import { getResourcesAreReady } from "common/utils/helpers";
import { DesktopEntries, MobileEntries } from "./Entries";
import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { reviewRequestsQuery } from "queries/requests/reviewRequests";
import { previousEmploymentsQuery } from "queries/requests/workExperience";
import {
  deleteReviewRequestQuery,
  resendReviewRequestQuery,
} from "queries/mutations/reviewRequests";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";

const stepName = "references";
const requiredResources = [types.REVIEW_REQUESTS, types.PREVIOUS_EMPLOYMENTS];

class References extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextButtonLoading: false,
      showFormModal: false,
    };
  }

  handleDeleteClick = uuid => {
    const {
      deleteReviewRequest,
      refreshReqs,
      refreshReviewRequests,
    } = this.props;
    deleteReviewRequest(uuid).then(() => {
      refreshReqs();
      refreshReviewRequests();
    });
  };

  handleResendClick = uuid => {
    const {
      resendReviewRequest,
      refreshReviewRequests,
      refreshReqs,
    } = this.props;

    resendReviewRequest(uuid).then(() => {
      refreshReviewRequests();
      refreshReqs();
    });
  };

  handleHide = () => this.setState({ showFormModal: false });

  handleFooterClick = () => this.setState({ showFormModal: true });

  handleNextClick = () => {
    this.setState({ nextButtonLoading: true }, () => {
      redirectToNextStepIfNeeded(
        stepName,
        this.props.refreshReqs,
        this.stopButtonLoading,
      );
    });
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: { nextButtonLoading, showFormModal },
      props: {
        reqs: { steps },
        refreshReqs,
        refreshReviewRequests,
        reviewRequests: { data: reviewRequestsData },
        ownContractor,
        previousEmployments: { data: prevEmploymentsData },
      },
      handleDeleteClick,
      handleFooterClick,
      handleHide,
      handleNextClick,
      handleResendClick,
    } = this;
    const isReady = getResourcesAreReady(requiredResources, this.props);
    const reviewRequests = Object.values(reviewRequestsData).filter(
      reviewRequest => reviewRequest.status !== "deleted" && reviewRequest.uuid,
    );
    const prevEmployments = Object.values(prevEmploymentsData);
    const entriesProps = {
      actionIsRequired: getActionIsRequired(stepName, steps),
      customErrorMsg: getCustomErrorMsg(stepName, steps),
      isAttorney: ownContractor.is_attorney,
      onDeleteClick: handleDeleteClick,
      onFooterClick: handleFooterClick,
      onResendClick: handleResendClick,
      reviewRequests,
      verifIsPending: getVerifIsPending(stepName, steps),
      warningType: getActionIsRequired(stepName, steps) ? "danger" : "warning",
    };
    return isReady ? (
      <Onboard.Page>
        <DesktopOnly>
          <DesktopEntries {...entriesProps} />
        </DesktopOnly>
        <MobileOnly>
          <MobileEntries {...entriesProps} />
        </MobileOnly>
        <NextButton
          disabled={!getCanMoveOnToNextStep(stepName, steps)}
          float="right"
          isLoading={nextButtonLoading}
          onClick={handleNextClick}
        />
        <FormModal
          isAttorney={ownContractor.is_attorney}
          onHide={handleHide}
          prevEmployments={prevEmployments}
          refreshReqs={refreshReqs}
          refreshReviewRequests={refreshReviewRequests}
          show={showFormModal}
        />
      </Onboard.Page>
    ) : (
      <Loading />
    );
  }
}

const mapPropsToConfig = () => [
  reviewRequestsQuery(),
  previousEmploymentsQuery(),
];

const reviewRequestsSelectorConfig = createResourceSelectorConfig(
  types.REVIEW_REQUESTS,
  reviewRequestsQuery,
);

const previousEmploymentsSelectorConfig = createResourceSelectorConfig(
  types.PREVIOUS_EMPLOYMENTS,
  previousEmploymentsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...reviewRequestsSelectorConfig,
  ...previousEmploymentsSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  deleteReviewRequest: uuid =>
    dispatch(mutateAsync(deleteReviewRequestQuery(uuid))),
  refreshReviewRequests: () =>
    dispatch(requestAsync(reviewRequestsQuery({ force: true }))),
  resendReviewRequest: uuid =>
    dispatch(mutateAsync(resendReviewRequestQuery(uuid))),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(References);
