import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { history as browserHistory } from "app";

import { action } from "common/actions/utils";
import { VerifiedContractorCount } from "common/components";
import types from "resources/types";
import algorithmImg from "img/algorithm.png";

class CreateJobListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }
  handleSubmit = () => {
    this.setState({ submitting: true }, () => {
      this.props
        .onSubmit()
        .then(({ json, response }) => {
          if (response.ok) {
            browserHistory.push(`/agency/jobs/create/${json.job}/step/0/`);
          }
        })
        .then(() => this.setState({ submitting: false }));
    });
  };

  render() {
    const { submitting } = this.state;
    return (
      <div className="create-job-listing">
        <h1 style={{ margin: "0 0 2rem 0" }} className="text-muted">
          Post a Job
        </h1>
        <h2>Discover the perfect talent</h2>
        <p style={{ marginBottom: "2rem" }}>
          Fill out our simple job form to receive instant, customized candidate
          recommendations and applications from our network of{" "}
          <VerifiedContractorCount /> verified legal professionals.
        </p>
        <p
          className="text-info"
          style={{ marginBottom: "4rem", fontWeight: "700" }}
        >
          <i className="far fa-exclamation-circle" />&nbsp;Not ready to post a
          job now? Your job saves at each step and is ready to go when you are.
        </p>
        <div className="algorithm">
          <img
            alt="algorithm background design"
            style={{ maxHeight: "14rem", width: "auto", maxWidth: "100%" }}
            src={algorithmImg}
          />
          <Button
            bsSize="lg"
            bsStyle="info"
            style={{ position: "absolute" }}
            disabled={submitting}
            onClick={this.handleSubmit}
          >
            Get Started
          </Button>
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  {
    onSubmit: () =>
      action({
        type: types.JOB_LISTINGS,
        actionType: "resource",
        path: "joblistings",
        method: "post",
      }),
  },
)(CreateJobListing);
