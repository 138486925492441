import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { history as browserHistory } from "app";
import { Link } from "react-router-dom";

import { compose, connect, connectRequest, requestAsync } from "queries/utils";
import { onboardingRequirementsQuery } from "queries/requests/onboardingRequirements";
import { ownContractorQuery } from "queries/requests/contractors";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";
import Onboard from "./blocks";
import Sidebar from "./Sidebar";
import Loading from "common/components/Loading";
import { shouldDisableStep } from "./utils";
import { basePath } from "./values";

class Onboarding extends Component {
  componentDidUpdate() {
    const {
      onboardingRequirements: {
        data: onboardingReqsData,
        isFinished: onboardingReqsIsFinished,
        isPending: onboardingReqsIsPending,
      },
    } = this.props;

    if (onboardingReqsIsFinished && !onboardingReqsIsPending) {
      this.redirectIfShouldDisableStep(onboardingReqsData);
      this.redirectIfBasePath();
    }
  }

  redirectIfShouldDisableStep = data => {
    const reqs = data[Object.keys(data)[0]];
    const currentStep = reqs.current_step;
    const steps = reqs.steps;
    const activeStepName = window.location.pathname
      .split("/")
      .pop()
      .replace(/-/g, "_");

    if (shouldDisableStep(currentStep, activeStepName, steps)) {
      browserHistory.push(`${basePath}/${currentStep.name.replace(/_/g, "-")}`);
    }
  };

  redirectIfBasePath = () => {
    const { pathname } = window.location;
    if (pathname === basePath || pathname === "/contractor/onboarding") {
      browserHistory.push(`${basePath}/contact-information`);
    }
  };

  render() {
    const {
      children,
      onboardingRequirements: { data: onboardingReqsData },
      ownContractor: { data: ownContractorData },
      refreshReqs,
      refreshOwnContractor,
    } = this.props;
    const ownContractor = Object.values(ownContractorData)[0];
    const reqs = onboardingReqsData[Object.keys(onboardingReqsData)[0]];
    const isReady = ownContractor && reqs;

    return isReady ? (
      <div>
        <Onboard.GoBackContainer>
          <Link to="/contractor">
            <i className="fas fa-long-arrow-left" /> Back to dashboard
          </Link>
        </Onboard.GoBackContainer>
        <h1>Account Setup</h1>
        <Onboard>
          <Sidebar currentStep={reqs.current_step} steps={reqs.steps} />
          <div>
            {React.Children.map(children, child =>
              React.cloneElement(child, {
                reqs,
                refreshReqs,
                ownContractor,
                refreshOwnContractor,
              }),
            )}
          </div>
        </Onboard>
      </div>
    ) : (
      <Loading />
    );
  }
}

const onboardingRequirementsSelectorConfig = createResourceSelectorConfig(
  types.ONBOARDING_REQUIREMENTS,
  onboardingRequirementsQuery,
);

const ownContractorSelectorConfig = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);

const mapPropsToConfig = () => [
  onboardingRequirementsQuery({ force: true }),
  ownContractorQuery({ force: true }),
];

const mapStateToProps = createStructuredSelector({
  ...onboardingRequirementsSelectorConfig,
  ...ownContractorSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  refreshReqs: () =>
    dispatch(requestAsync(onboardingRequirementsQuery({ force: true }))),
  refreshOwnContractor: () =>
    dispatch(requestAsync(ownContractorQuery({ force: true }))),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Onboarding);
