import { handleActions } from "redux-actions";
import actions from "hiringagent-dashboard/actions/applicantsTable";

// Togglable columns
export const columnLabels = {
  fitscore: "Fit Score",
  requirements: "Req. Filled",
  workstyle: "Workstyle Score",
  date: "Date Applied",
};

const initialState = {
  selectedColumn: "fitscore",
};

const applicantsTable = handleActions(
  {
    [actions.SET_SELECTED_COLUMN]: (state, action) => ({
      ...state,
      selectedColumn: action.payload,
    }),
  },
  initialState,
);
export default applicantsTable;
