import React from "react";
import styled from "styled-components";
import { Button, Image } from "react-bootstrap";

import { history } from "app";
import talentPoolsMenuImg from "img/new-feature-notifications/talentpools-menu.png";
import talentPoolsProfileImg from "img/new-feature-notifications/talentpools-profile.png";

const ExampleImageContainer = styled.div.attrs({ className: "text-center" })`
  img {
    margin: 10px auto;
  }
`;

const TalentPoolsNotification = ({ onClose }) => (
  <>
    <h3 className="text-center">
      <strong>Introducing Talent Pools</strong>
    </h3>
    <p>
      To help you better organize and manage your favorite Hire an Esquire legal
      professionals, our &quot;Favorites&quot; feature has been upgraded to
      &quot;Talent Pools&quot;.
    </p>
    <p>
      You can now create separate groups of your favorite attorneys based on a
      practice area, office location or however you manage.
    </p>
    <h4 className="text-uppercase">How It Works:</h4>
    <ol>
      <li>
        Save contractor profile for later by clicking on the &quot;Save to
        Talent Pools&quot; button
        <ExampleImageContainer>
          <Image
            responsive
            src={talentPoolsProfileImg}
            alt="Talent pools button on contractor profile"
          />
        </ExampleImageContainer>
      </li>
      <li>
        Create new talent pools and message or invite contractors to roles
        directly from these talent pools via the navigation bar (Manage &gt;
        Talent Pools)
        <br />
        <ExampleImageContainer>
          <Image
            responsive
            src={talentPoolsMenuImg}
            alt="Talent pools menu location"
          />
        </ExampleImageContainer>
      </li>
      <li>
        The &quot;Save to Talent Pools&quot; now replaces the &quot;Save to
        Favorites&quot; button on candidate profiles &mdash; previously saved
        favorites can be found under the &quot;My Favorites&quot; talent pool.
      </li>
    </ol>
    <div className="text-center">
      <Button
        bsStyle="info"
        onClick={() => {
          onClose();
          history.push("/agency/talent_pools");
        }}
      >
        <strong className="text-uppercase">Go to Talent Pools</strong>
      </Button>
    </div>
  </>
);

export default TalentPoolsNotification;
