import actions from "hiringagent-dashboard/actions/notifications";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  notifications: {},
  isLoading: false,
  lastUpdated: null,
};

const notifications = handleActions(
  {
    [actions.REQUEST_NOTIFICATIONS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_NOTIFICATIONS]: (state, action) => ({
      ...state,
      notifications: listToObject(action.payload.results, "uuid"),
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
    [actions.REQUEST_MARK_NOTIFICATION_READ]: (state, action) => ({
      ...state,
      isLoading: true,
      notifications: {
        ...state.notifications,
        // Optimistically mark notification as read
        [action.payload.notificationId]: {
          ...state.notifications[action.payload.notificationId],
          read: true,
        },
      },
    }),
    [actions.RECEIVE_MARK_NOTIFICATION_READ]: (state, action) => ({
      ...state,
      notifications: {
        ...state.notifications,
        [action.payload.uuid]: action.payload,
      },
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
  },
  initialState,
);
export default notifications;
