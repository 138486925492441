import React from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
// import moment from "moment";
import moment from "moment-timezone";
import { compose, connect, connectRequest } from "queries/utils";

import JobOfferNotification from "contractor/components/job/JobOfferNotification";
import {
  candidateIsOverQualified,
  getCorrectCannotApplyMsg,
  isNotAllowedToApply,
  hasRequiredExperience,
} from "contractor/components/utils/jobs";

import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { JobOffer, Candidate } from "types";

export const getJobClosedMsg = job => (
  <Alert bsStyle="danger">
    <strong>{`This job was closed on ${moment(job.closed_date).format(
      "ll",
    )}.`}</strong>{" "}
    If you worked on this job, you may still view the time tracker for
    historical purposes, but only hours added up to and including the closed
    date will be billed.
  </Alert>
);

export const getJobNotOpenForApplicationsMsg = () => (
  <Alert bsStyle="warning">This job is no longer accepting applications.</Alert>
);

const getWorkerNotActiveMsg = () => (
  <Alert bsStyle="danger">
    You have been marked inactive on this position by your administrator. You
    will not be able to log work for this job until your administrator
    designates you as active.
  </Alert>
);

export const getGenericNotPermittedMsg = () => (
  <Alert bsStyle="danger">
    Sorry, you cannot apply to this job. This position has either been filled or
    you are not permitted to apply for this job because your account is not yet
    completed.
  </Alert>
);

export const getRequiredExperienceNotMetMsg = (jobListing, yearsExperience) => {
  const profileLink = (
    <Link to={"/contractor/profile/edit/work-experience/"}>your profile</Link>
  );
  return (
    <Alert bsStyle="warning">
      This job requires {jobListing.experienceRange}. According to our records,
      you have {yearsExperience} years experience. You may still apply, but may
      want to mention the discrepancy in your personal statement. Please note
      that years of experience is derived from the previous employments you have
      listed. If this number is incorrect, please return to your {profileLink}{" "}
      to update your work history.
    </Alert>
  );
};

const getWorkerOverQualifiedMsg = (jobListing, yearsExperience) => (
  <Alert bsStyle="warning">
    You may be over-qualified for this job. This job requires{" "}
    {jobListing.min_years_experience} to {jobListing.max_years_experience} years
    experience. According to our records, you have {yearsExperience} years
    experience.
  </Alert>
);

export const getWrongContractorTypeMsg = jobListing => {
  const acceptsAttorneys =
    jobListing.allowsAttorneysOnly && "licensed attorneys";
  const acceptsNonAttorneys =
    jobListing.allowsNonAttorneysOnly && "non-attorneys";
  const acceptedContractorType = acceptsAttorneys || acceptsNonAttorneys;
  return (
    <Alert bsStyle="danger">
      {`Sorry, you cannot apply to this job. This job is only open to
              ${acceptedContractorType || "a different type of contractor"}.`}
    </Alert>
  );
};

const getCandidateStatusMsg = candidate => (
  <Alert bsStyle="info">
    Your application was submitted on {moment(candidate.created).format("lll")}{" "}
    and currently has a status of:{" "}
    {Candidate.statusTypeDisplay(candidate.status)}
  </Alert>
);

const getCandidateIsHiredMsg = () => (
  <Alert bsStyle="success">
    You have accepted an offer to work on this job! If you have not already,
    message the hiring agent to determine next steps.
  </Alert>
);

const getCandidateRejectedMsg = () => (
  <Alert bsStyle="danger">
    Unfortunately, the application you submitted for this job was not accepted,
    possibly because all the positions for this job have been filled.
  </Alert>
);

const getDeclinedOfferMsg = jobOffer => (
  <Alert bsStyle="info">
    {`You declined a job offer for this job on ${moment(
      jobOffer.updated,
    ).format("lll")}
         If you are still interested in this job, message the hiring agent to discuss another offer.`}
  </Alert>
);

const getExpiredOfferMsg = jobOffer => (
  <Alert bsStyle="info">
    {`You received a job offer that expired on ${moment(
      jobOffer.updated,
    ).format("lll")}.
         If you are still interested in this job, message the hiring agent to discuss another offer.`}
  </Alert>
);

export const HasAppliedMessages = props => {
  const {
    job,
    candidate,
    [types.JOB_OFFERS]: {
      data: jobOffersData,
      isFinished,
      query: { data: jobOffersQueryData },
    },
  } = props;

  const jobOffers = jobOffersQueryData.map(offerId => jobOffersData[offerId]);

  if (isFinished && candidate) {
    if (jobOffers.length) {
      const mostRecentOffer = jobOffers.reduce((latestSoFar, offer) => {
        const latestSoFarDate = new Date(latestSoFar.updated);
        const offerDate = new Date(offer.updated);
        return offerDate > latestSoFarDate ? offer : latestSoFar;
      }, jobOffers[0]);

      const isOfferPending =
        JobOffer.statusTypeDisplay(mostRecentOffer.status) === "PENDING";
      const isOfferRejected =
        JobOffer.statusTypeDisplay(mostRecentOffer.status) === "REJECTED";
      const isOfferExpired =
        JobOffer.statusTypeDisplay(mostRecentOffer.status) === "EXPIRED";

      const isCandidateNegotiating =
        Candidate.statusTypeDisplay(candidate.status) === "NEGOTIATING";

      if (isCandidateNegotiating) {
        if (isOfferPending) {
          return <JobOfferNotification job={job} offer={mostRecentOffer} />;
        }
        if (isOfferRejected) {
          return getDeclinedOfferMsg(mostRecentOffer);
        }
        if (isOfferExpired) {
          return getExpiredOfferMsg(mostRecentOffer);
        }
      }
    }

    const isCandidateHired =
      Candidate.statusTypeDisplay(candidate.status) === "HIRED";
    const isCandidateRejected =
      Candidate.statusTypeDisplay(candidate.status) === "REJECTED";

    if (isCandidateHired) {
      return getCandidateIsHiredMsg();
    }
    if (isCandidateRejected) {
      return getCandidateRejectedMsg();
    }

    return getCandidateStatusMsg(candidate);
  }

  return null;
};

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOB_OFFERS, {
    url: `/api/v2/job_offers/?candidate=${props.candidate.uuid}`,
  });

const mapStateToProps = createResourceSelector(
  types.JOB_OFFERS,
  mapPropsToConfig,
);

const ConnectedHasAppliedMessages = compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(HasAppliedMessages);

const JobMessages = props => {
  const {
    candidate,
    contractor,
    job,
    jobListing,
    worker,
    hasPermission,
  } = props;

  const hasApplied = Boolean(candidate);
  const isWorker = Boolean(worker);
  const cannotApply =
    !job.isOpenForApplications ||
    isNotAllowedToApply(job, jobListing, contractor, candidate, hasPermission);
  const isOverQualified = candidateIsOverQualified(
    jobListing,
    contractor.years_work_experience,
  );
  const notEnoughExperience = !hasRequiredExperience(
    jobListing,
    contractor.years_work_experience,
  );

  if (job.isClosed) {
    return getJobClosedMsg(job);
  }
  if (hasApplied) {
    return <ConnectedHasAppliedMessages {...props} />;
  }
  if (cannotApply) {
    return getCorrectCannotApplyMsg(job, jobListing, contractor, hasPermission);
  }
  if (isWorker && !worker.active) {
    return getWorkerNotActiveMsg();
  }
  if (isOverQualified) {
    return getWorkerOverQualifiedMsg(
      jobListing,
      contractor.years_work_experience,
    );
  }
  if (notEnoughExperience) {
    return getRequiredExperienceNotMetMsg(
      jobListing,
      contractor.years_work_experience,
    );
  }
  return null;
};

export default JobMessages;
