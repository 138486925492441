// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  dot: boolean,
  open: boolean,
};

const NavTrigger: ComponentType<Props> = styled.div`
  width: 100%;
  height: 42px;
  line-height: 42px;
  color: ${({ theme }) => theme.colors.primary_black()};
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  box-sizing: border-box;
  cursor: pointer;

  & > span:first-child {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.secondary_red()};
    font-weight: 700;
    visibility: ${({ dot }) => (dot ? "visible" : "hidden")};
  }

  &:hover,
  &:focus {
    & > span:nth-child(2) {
      color: ${({ theme }) => theme.colors.primary_black()};
      text-decoration: none;
      font-weight: 900;
    }
  }

  & > i {
    float: right;
    margin-right: 10px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.primary_black()};
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    transition: transform 0.5s;
  }

  ${({ open }) =>
    open &&
    css`
      && {
        & > span:nth-child(2) {
          color: ${({ theme }) => theme.colors.primary_black()};
          text-decoration: none;
          font-weight: 900;
        }

        & > i {
          transform: rotate(90deg);
        }
      }
    `};
`;

export default NavTrigger;
