import { combineReducers } from "redux";
import createReducer from "common/reducers/utils";

import {
  clearStashedProfilePicture,
  ownContractor,
  recieveAddProfilePicture,
  schoolTranscript,
} from "contractor/reducers/profile";
import { application } from "contractor/reducers/application";

const stashProfilePictureHandlers = {
  ...recieveAddProfilePicture,
  ...clearStashedProfilePicture,
};

export default combineReducers({
  addProfilePicture: createReducer("addProfilePicture"),
  applications: createReducer("applications", null, application),
  conflicts: createReducer("conflicts"),
  experience: createReducer("experience"),
  ownContractor: createReducer("ownContractor", null, ownContractor),
  jobOffers: createReducer("jobOffers"),
  jobPermission: createReducer("jobPermission", null),
  jobRequirements: createReducer("jobRequirements", null),
  jobSearchResults: createReducer("jobSearchResults"),
  jobWorkers: createReducer("jobWorkers"),
  jobWorkersOnOpenJobs: createReducer("jobWorkersOnOpenJobs"),
  languageFluency: createReducer("languageFluency"),
  pendingApplications: createReducer("pendingApplications", null, application),
  recommendations: createReducer("recommendations"),
  schools: createReducer("schools", { transcript: null }, schoolTranscript),
  stashProfilePicture: createReducer(
    "stashProfilePicture",
    null,
    stashProfilePictureHandlers,
  ),
});
