import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { connectRequest } from "redux-query-react";
import { Link } from "react-router-dom";
import { Alert, Col, Row } from "react-bootstrap";

import Loading from "common/components/Loading";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { Job } from "types";

import ManageWorkersForJob from "./ManageWorkersForJob";

class ManageWorkers extends Component {
  render() {
    const {
      [types.JOBS]: {
        data,
        query: { data: ordering },
        isFinished,
        isPending,
      },
    } = this.props;

    const jobs = isFinished && ordering.map(jobId => data[jobId]);
    const filteredJobs =
      jobs && jobs.filter(job => job.status !== Job.STATUS.CLOSED);

    return (
      <div>
        <h1>Manage Active Workers</h1>
        <div style={{ marginBottom: "2rem" }}>
          <Link to="/agency/jobs/">&larr; Back to Jobs</Link>
        </div>
        <Row>
          <Col md={12} lg={6} lgOffset={3}>
            <Alert>
              <i className="far fa-info-circle" />&nbsp;Only workers marked as
              active (blue) will be able to enter time or expenses.
            </Alert>
          </Col>
        </Row>

        {filteredJobs &&
          filteredJobs.map(job => (
            <ManageWorkersForJob
              key={`select-workers-for-${job.uuid}`}
              job={job}
            />
          ))}
        {isPending && <Loading />}
      </div>
    );
  }
}

const mapPropsToConfig = () =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=999",
  });

const mapStateToProps = createResourceSelector(types.JOBS, mapPropsToConfig);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(ManageWorkers);
