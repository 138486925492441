// @flow
import { type Uuid } from "./primitives";

export default class RequirementResponse {
  uuid: Uuid;
  application: Uuid;
  requirement: Uuid;
  response: boolean;
  question_text: string;
  from_primary_practice_area: boolean;
  preference: string;
  requirement_description: string;
  requirement_description_text: string;

  constructor(requirementResponse: Object) {
    this.uuid = requirementResponse.uuid;
    this.application = requirementResponse.application;
    this.requirement = requirementResponse.requirement;
    this.response = requirementResponse.response;
    this.question_text = requirementResponse.question_text;
    this.from_primary_practice_area =
      requirementResponse.from_primary_practice_area;
    this.preference = requirementResponse.preference;
    this.requirement_description = requirementResponse.requirement_description;
    this.requirement_description_text =
      requirementResponse.requirement_description_text;
  }
}
