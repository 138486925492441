import { mutateAsync } from "redux-query";

import { createQueryConfig, getDefaultOptions } from "queries";
import { createApiUrl } from "utils";

export const login = data =>
  mutateAsync(
    createQueryConfig({
      url: "/api/auth/login/",
      body: data,
    }),
  );

export const logout = () =>
  mutateAsync(
    createQueryConfig({
      url: "/api/auth/logout/",
    }),
  );

export const resendConfirmationEmailQuery = () => ({
  url: createApiUrl("/api/v2/users/resend_confirmation/"),
  options: {
    ...getDefaultOptions(),
    method: "POST",
  },
  update: {
    confirmationEmail: (_, newData) => newData,
  },
});

export const submitConfirmationCodeQuery = code => ({
  url: createApiUrl(`/api/v2/email_confirmations/${code}/`),
  options: {
    ...getDefaultOptions(),
    method: "PATCH",
  },
  update: {},
});

export const acceptTermsOfServiceQuery = agreed_to_latest_tos => ({
  url: createApiUrl("/api/auth/user/"),
  body: {
    agreed_to_latest_tos,
  },
  options: {
    ...getDefaultOptions(),
    method: "PATCH",
  },
  update: {},
});
