// @flow strict
import * as React from "react";

import Top from "../blocks";
import Desktop from "./blocks";
import { isActiveURL } from "common/utils";

// External props
export type LinkItemProps = {
  badge: number | boolean,
  name: string,
  onClick?: () => void,
  to: string,
};

// Internal props
type Props = LinkItemProps;

let LinkItem: React.StatelessFunctionalComponent<Props> = (props: Props) => {
  const { badge, name, onClick, to } = props;
  const open = isActiveURL(to, true);

  return (
    <Top.NavLink onClick={onClick} open={open} to={to}>
      <Desktop.NavItem badge={badge} open={open}>
        <span name={name}>{name}</span>
        <span>{badge}</span>
      </Desktop.NavItem>
    </Top.NavLink>
  );
};

export default React.memo<LinkItemProps>(LinkItem);
