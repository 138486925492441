export default [
  {
    value: 5,
    label: "5 miles",
  },
  {
    value: 10,
    label: "10 miles",
  },
  {
    value: 20,
    label: "20 miles",
  },
  {
    value: 50,
    label: "50 miles",
  },
];
