import { createResourceListQuery } from "queries";
import { createResourceListQuery as hookCreateResourceListQuery } from "queries/hooks";
import types from "resources/types";

// New-style requests
const createRequestCreator = (resourceType, endpoint) => (
  config = { url: {} },
) => {
  return hookCreateResourceListQuery(resourceType, {
    ...config,
    url: { ...config.url, endpoint },
  });
};

export const newFeatureNotificationsRequest = createRequestCreator(
  types.NEW_FEATURE_NOTIFICATIONS,
  "new_feature_notifications",
);

export const savedContractorsRequest = createRequestCreator(
  types.SAVED_CONTRACTORS,
  "saved_contractors",
);

export const talentPoolsRequest = createRequestCreator(
  types.TALENT_POOLS,
  "talent_pools",
);

// Old-style requests (queries)
export const hiringAgencyMarketingConversionsQuery = (config = {}) =>
  createResourceListQuery(types.HIRING_AGENCY_MARKETING_CONVERSIONS, {
    url: "/api/v2/hiring_agency_marketing_conversions/",
    ...config,
  });

export const jobsQuery = (config = {}) =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=999",
    ...config,
  });

export const positionTypesQuery = (config = {}) =>
  createResourceListQuery(types.POSITION_TYPES, {
    url: "/api/v2/position_types/?limit=99",
    ...config,
  });

export const tagGroupsQuery = (config = {}) =>
  createResourceListQuery(types.TAG_GROUPS, {
    url: "/api/dev/tag_groups/?limit=999",
    ...config,
  });

export const tagGroupRequirementsQuery = (primary_practice_area, config = {}) =>
  createResourceListQuery(types.TAG_GROUP_REQUIREMENTS, {
    url: `/api/v2/tag_group_requirements/?tag_group=${primary_practice_area}&limit=99`,
    ...config,
  });

export const tagsQuery = (config = {}) =>
  createResourceListQuery(types.TAGS, {
    url: "/api/dev/tags/?limit=999",
    ...config,
  });
