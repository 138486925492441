// @flow strict
import styled from "styled-components";

const RadioOverlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.colors.primary_turquoise()};
  background-color: white;
  cursor: pointer;
  transition: 0.15s;

  ${({ theme }) => theme.media.mobile`
    height: 100%;
    border-width: 2px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};

  ${({ theme }) => theme.media.desktop`
    height: 20px;
    border-radius: 34px;
    border: 1px solid ${theme.colors.primary_turquoise()};
    background-color: white;
    transition: 0.15s;

    &:before {
      position: absolute;
      z-index: 1;
      content: "";
      height: 76%;
      width: 94%;
      left: 3%;
      bottom: 12%;
      border-radius: 34px;
      border: 1px solid white;
      background-color: white;
      transition: 0.15s;
    }

    &:after {
      position: absolute;
      z-index: -1;
      top: 50%;
      content: "";
      width: 150%;
      height: 1px;
      cursor: default;
      background-color: ${theme.colors.primary_turquoise()};
    }
  `};
`;

export default RadioOverlay;
