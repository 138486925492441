import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { Label, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import ExpandableText from "common/components/ExpandableText";
import {
  JobLanguageRequirement,
  JobPrimaryPracticeArea,
} from "common/components/jobs";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";

export const JobDetailsRow = props => (
  <tr>
    <td>
      <strong>{props.title}</strong>
    </td>
    <td>{props.children}</td>
  </tr>
);

export const JobDetails = props => {
  const {
    job,
    jobListing,
    [types.HIRING_AGENTS]: {
      data: hiringAgents,
      lastUpdated: hiringAgentsLastUpdated,
    },
    [types.POSITION_TYPES]: {
      data: positionTypes,
      lastUpdated: positionTypesLastUpdated,
    },
  } = props;

  const postingAgent =
    hiringAgentsLastUpdated && hiringAgents[jobListing.posting_agent];

  return (
    <div className="main-info-container">
      <div className="clearfix">
        <h2 className="main-info-title pull-left">Details</h2>
        <span className="pull-right">
          <Link to={`/agency/jobs/edit/${jobListing.job}`}>Edit</Link>
        </span>
      </div>
      <Table>
        <tbody>
          <JobDetailsRow title="Title">
            {jobListing.formattedTitle}
          </JobDetailsRow>
          <JobDetailsRow title="Status">{job.statusDisplay}</JobDetailsRow>
          <JobDetailsRow title="Primary Practice Area">
            <JobPrimaryPracticeArea {...jobListing} />
          </JobDetailsRow>
          <JobDetailsRow title="Location">
            {jobListing.remote
              ? "Remote"
              : jobListing.joblistinglocation_set.map(location => (
                  <span key={`job-location-${location.uuid}`}>
                    <Label>
                      {location.city}, {location.state}
                    </Label>{" "}
                  </span>
                ))}
          </JobDetailsRow>
          <JobDetailsRow title="Allowed Applicants">
            {jobListing.allowedApplicantsDisplay}
          </JobDetailsRow>
          {jobListing.position_type && (
            <JobDetailsRow title="Position Type">
              {positionTypesLastUpdated &&
                positionTypes[jobListing.position_type].name}
            </JobDetailsRow>
          )}
          <JobDetailsRow title="Experience">
            {jobListing.experienceDisplay}
          </JobDetailsRow>
          {jobListing.joblistinglanguage_set.length > 0 && (
            <JobDetailsRow title="Language Requirements">
              {jobListing.joblistinglanguage_set.map(fluency => (
                <span key={fluency.uuid}>
                  <JobLanguageRequirement {...fluency} /> ({fluency.proficiency})<br />
                </span>
              ))}
            </JobDetailsRow>
          )}
          {jobListing.bar_requirements &&
            jobListing.bar_requirements.length > 0 && (
              <JobDetailsRow title="State Bar Requirements">
                {jobListing.bar_requirements.join(", ")}
              </JobDetailsRow>
            )}
          <JobDetailsRow title="Description">
            {jobListing.description && (
              <ExpandableText
                text={jobListing.description}
                maxWords={20}
                className="job-desc-expandable"
              />
            )}
          </JobDetailsRow>
          {jobListing.projectLengthDisplay && (
            <JobDetailsRow title="Estimated Duration">
              {jobListing.projectLengthDisplay}
            </JobDetailsRow>
          )}
          {jobListing.num_openings && (
            <JobDetailsRow title="Number of Openings">
              {jobListing.num_openings}
            </JobDetailsRow>
          )}
          {(jobListing.min_estimated_rate || jobListing.max_estimated_rate) && (
            <JobDetailsRow title="Estimated Rate">
              {jobListing.estimatedRateDisplay}
            </JobDetailsRow>
          )}
          <JobDetailsRow title="Private?">
            {jobListing.searchable ? "No" : "Yes"}
          </JobDetailsRow>
          <JobDetailsRow title="Posted by">
            {postingAgent && postingAgent.fullName}
          </JobDetailsRow>
        </tbody>
      </Table>
    </div>
  );
};

const positionTypesQuery = () =>
  createResourceListQuery(types.POSITION_TYPES, {
    url: "/api/v2/position_types/?limit=99",
  });
const postingUserQuery = props =>
  createResourceDetailQuery(types.HIRING_AGENTS, {
    url: `/api/dev/hiring_agents/${props.jobListing.posting_agent}/`,
  });

const mapPropsToConfig = props => [
  positionTypesQuery(props),
  postingUserQuery(props),
];

const positionTypesSelectorConfig = createResourceSelectorConfig(
  types.POSITION_TYPES,
  positionTypesQuery,
);
const postingUserSelectorConfig = createResourceSelectorConfig(
  types.HIRING_AGENTS,
  postingUserQuery,
);

const mapStateToProps = createStructuredSelector({
  ...positionTypesSelectorConfig,
  ...postingUserSelectorConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobDetails);
