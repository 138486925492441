import React from "react";
import styled from "styled-components";

const Paragraph = styled.p.attrs({ className: "text-info" })`
  &&&& {
    font-weight: 700;
  }
`;

const Icon = styled.i.attrs({ className: "far fa-exclamation-circle" })``;

const OptionalLabel = () => (
  <Paragraph>
    <Icon />&nbsp;Optional
  </Paragraph>
);

export default OptionalLabel;
