import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const ActionButton = props => (
  <Button
    bsStyle={props.bsStyle}
    bsSize={props.bsSize}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.text}
  </Button>
);

ActionButton.propTypes = {
  bsStyle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
};
export default ActionButton;
