import { RECEIVE_TIME_ENTRIES } from "admin/actions/invoicing/timeEntries";
import { RECEIVE_EXPENSES } from "admin/actions/invoicing/expenses";
import { RECEIVE_FIXED_RATES } from "admin/actions/invoicing/fixedRates";

const initialJobEventsState = {
  lastUpdated: null,
};

export default function jobs(state = initialJobEventsState, action) {
  switch (action.type) {
    case RECEIVE_TIME_ENTRIES:
    case RECEIVE_EXPENSES:
    case RECEIVE_FIXED_RATES:
      return Object.assign({}, state, {
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}
