// @flow strict
import styled from "styled-components";
import Subheader from "./Subheader";
import Paragraph from "./Paragraph";
import PrimaryButton from "common/blocks/PrimaryButton";

const Box = styled.div`
  ${({ theme }) => theme.media.mobile`
    margin: 0;
    padding: 0;
  `};

  ${({ theme }) => theme.media.desktop`
    padding: 25px;
    border-radius: 16px;
    border: 2px solid ${theme.colors.primary_black()};
    display: grid;
    grid-template-columns: 4fr 1fr;
    justify-items: center;
    align-items: center;

    ${Subheader} {
       margin-top: 0;
     }

     ${Paragraph} {
       margin-bottom: 0px;
     }

    ${PrimaryButton} {
      justify-self: end;
      float: none !important;
      margin: 0 !important;
    }
  `};
`;

export default Box;
