import { fetchResourceWithDateRange, listToObject } from "admin/utils/index";

export const REQUEST_EXPENSES = "REQUEST_EXPENSES";
export const RECEIVE_EXPENSES = "RECEIVE_EXPENSES";

export function requestExpenses() {
  return {
    type: REQUEST_EXPENSES,
  };
}

export function receiveExpenses(json) {
  return {
    type: RECEIVE_EXPENSES,
    receivedAt: Date.now(),
    items: listToObject(json.results, "uuid"),
  };
}

export function fetchExpensesIfNeeded(startDate, endDate) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.admin.invoicing.expenses.isFetching) {
      dispatch(requestExpenses());
      return fetchResourceWithDateRange(startDate, endDate, "expenses").then(
        json => dispatch(receiveExpenses(json)),
      );
    }
    return undefined;
  };
}
