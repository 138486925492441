import React from "react";
import { ButtonToolbar, Modal } from "react-bootstrap";

import PrimaryButton from "common/blocks/PrimaryButton";

const DeleteModal = ({
  children,
  onDeleteClick,
  onHide,
  show,
  title,
  uuid,
}) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {children}
      <ButtonToolbar>
        <PrimaryButton bsStyle="danger" onClick={() => onDeleteClick(uuid)}>
          Delete
        </PrimaryButton>
        <PrimaryButton bsStyle="default" onClick={onHide}>
          Close
        </PrimaryButton>
      </ButtonToolbar>
    </Modal.Body>
  </Modal>
);

export default DeleteModal;
