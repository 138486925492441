import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, FieldArray } from "redux-form";
import { createStructuredSelector } from "reselect";
import { connect, compose, connectRequest } from "queries/utils";

import { PrimaryPracticeAreaSelectField } from "common/components/forms/inputs";
import { symDiff } from "common/utils";
import { ExpertiseAreasForPracticeArea } from "contractor/forms/settings/job-notifications";

import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import types from "resources/types";

const practiceAreaSelectPlaceholder =
  "Search for practice areas that you have experience with:";

const ExpertiseAreas = ({ fields, tags }) => {
  const removeFields = (currentValues, currentValuesIds, selectValuesIds) => {
    const removedItems = symDiff(currentValuesIds, selectValuesIds);
    removedItems.forEach(id => {
      const removedItemIdx = currentValues.findIndex(
        item => item.tag_group === id,
      );
      if (removedItemIdx > -1) {
        fields.remove(removedItemIdx);
      }
    });
  };

  const addFields = (currentValuesIds, selectValuesIds) => {
    const addedItems = symDiff(selectValuesIds, currentValuesIds);
    addedItems.forEach(id => {
      const tagIdsForTagGroup = tags
        .filter(t => t.tag_group_id === id)
        .map(t => t.uuid);
      fields.push({ tag_group: id, tags: tagIdsForTagGroup });
    });
  };

  const handleCustomChange = selectValues => {
    const currentValues = fields.map((name, idx, fields) => fields.get(idx));
    const selectIds = selectValues.map(v => v.value);
    const currentIds = currentValues.map(v => v.tag_group);

    if (currentValues.length < selectValues.length) {
      addFields(currentIds, selectIds);
    }

    if (currentValues.length > selectValues.length) {
      if (selectValues.length === 0) {
        fields.removeAll();
      } else {
        removeFields(currentValues, currentIds, selectIds);
      }
    }
  };

  return (
    <div className="expertise-areas-section">
      <PrimaryPracticeAreaSelectField
        name="primary_practice_areas"
        customOnChange={handleCustomChange}
        label={null}
        placeholder={practiceAreaSelectPlaceholder}
        multi={true}
      />
      {fields.map(
        (field, i) =>
          i % 2 === 0 ? (
            <Row key={i}>
              <Col sm={12} md={6}>
                <Field
                  name={`tag_group_items[${i}].tags`}
                  component={ExpertiseAreasForPracticeArea}
                  tagGroupId={fields.get(i).tag_group}
                  allTags={tags}
                />
              </Col>
              <Col sm={12} md={6}>
                {fields.get(i + 1) && (
                  <Field
                    name={`tag_group_items[${i + 1}].tags`}
                    component={ExpertiseAreasForPracticeArea}
                    tagGroupId={fields.get(i + 1).tag_group}
                    allTags={tags}
                  />
                )}
              </Col>
            </Row>
          ) : null,
      )}
    </div>
  );
};

export class ExpertiseAreasSection extends Component {
  render() {
    const {
      [types.TAGS]: { data },
    } = this.props;
    return (
      <FieldArray
        name="tag_group_items"
        component={ExpertiseAreas}
        tags={Object.values(data)}
      />
    );
  }
}

const mapPropsToConfig = () =>
  createResourceListQuery(types.TAGS, {
    url: "/api/dev/tags/?limit=999",
  });

const tagsSelector = createResourceSelectorConfig(types.TAGS, mapPropsToConfig);

const mapStateToProps = createStructuredSelector({
  ...tagsSelector,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(ExpertiseAreasSection);
