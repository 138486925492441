import types from "resources/types";
import { createResourceDetailQuery, createResourceListQuery } from "queries";

export const messageThreadsQuery = () =>
  createResourceListQuery(types.MESSAGE_THREADS, {
    url: `/api/dev/messages/`,
  });

export const messagesQuery = (
  config = { force: true },
  filter = "&inbox=1",
  limit = 10,
  offset = 0,
  search = "",
) =>
  createResourceListQuery(types.MESSAGES, {
    url: `/api/dev/messages/?offset=${offset}&limit=${limit}` + filter + search,
    ...config,
  });

export const threadQuery = (config = {}, search = "", threadId) =>
  createResourceDetailQuery(types.THREAD, {
    url: `/api/dev/messages/${threadId}/` + search,
    ...config,
  });
