import actions from "../actions/attorneys";
import { handleActions } from "redux-actions";

const initialState = {
  contractors: {},
  isLoading: null,
  lastUpdated: null,
};

const attorneys = handleActions(
  {
    [actions.REQUEST_CONTRACTOR]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_CONTRACTOR]: (state, action) => ({
      ...state,
      contractors: {
        ...state.contractors,
        [action.payload.uuid]: action.payload,
      },
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
    [actions.REQUEST_SUBMIT_SHARE_PROFILE]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_SUBMIT_SHARE_PROFILE]: (state, action) => ({
      ...state,
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
  },
  initialState,
);

export default attorneys;
