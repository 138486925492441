import styled from "styled-components";

const Main = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-column-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.desktop`
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0;
    margin-top: 0;
    margin-bottom: 0;
  `};
`;

export default Main;
