import React from "react";
import { Alert, Button, Col, Row, Well } from "react-bootstrap";
import Loading from "common/components/Loading";

import { withHaeUser } from "hiringagent-dashboard/connectors";
import urls from "urls";
import clioLogo from "img/clio/clio-logo.png";
import { createApiUrl } from "utils";

const AssociateSocialAccount = props => {
  const {
    haeUser: {
      data: { clio_connected },
      isLoading,
      lastUpdated,
    },
  } = props;
  const clioUrl = createApiUrl(urls["social:begin"]("clio-oauth2"));
  return (
    <Row>
      <Col xs={12} md={5}>
        <Well>
          <p style={{ fontSize: "1.5rem", marginBottom: "2rem" }}>
            Associate your Clio account to export your Hire an Esquire
            candidates to your Clio contacts.
          </p>
          <Row>
            <Col md={8}>
              {lastUpdated ? (
                clio_connected ? (
                  <Alert bsStyle="success">
                    <i className="far fa-check" />
                    &nbsp;Clio account is connected
                  </Alert>
                ) : (
                  <Button
                    block={true}
                    bsSize="lg"
                    style={{ marginBottom: "1rem" }}
                    className="btn-social btn-clio"
                    href={clioUrl}
                  >
                    <img alt="clio logo" src={clioLogo} />
                    <span style={{ color: "#0075bb" }}> Associate Clio</span>
                  </Button>
                )
              ) : (
                isLoading && <Loading />
              )}
            </Col>
          </Row>
        </Well>
      </Col>
    </Row>
  );
};
export default withHaeUser()(AssociateSocialAccount);
