import keyMirror from "keymirror";

import api from "common/api";

const actions = keyMirror({
  REQUEST_LANGUAGES: null,
  RECEIVE_LANGUAGES: null,
});
export default actions;

export const requestLanguages = () =>
  api.get("/api/v2/languages/?limit=150", [
    actions.REQUEST_LANGUAGES,
    actions.RECEIVE_LANGUAGES,
  ]);
