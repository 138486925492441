import React, { Component, Fragment } from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import {
  change,
  reset,
  reduxForm,
  SubmissionError,
  initialize,
} from "redux-form";
import { Elements, StripeProvider, injectStripe } from "react-stripe-elements";
import { createStructuredSelector } from "reselect";
import { addNotification as notify } from "reapop";

import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import { compose, connect, connectRequest, types } from "queries/utils";
import { ownContractorQuery } from "queries/requests/contractors";
import { createResourceSelectorConfig } from "queries";
import FormButton from "common/components/FormButton";
import {
  PersonalInfoFieldGroup,
  BankInfoFieldGroup,
  ACHAgreeField,
} from "./Fields";
import IDUploadSection from "./IDUploadSection";
import { dynamicFormName } from "./values";
import ErrorAlert from "./ErrorAlert";
import { update } from "resources/actions/bankAccounts";
import { createBankToken, createSSNToken, parseNonFieldErrors } from "./utils";
import { submitFile } from "common/actions/utils";

const stripeDocUpload = submitFile([
  "REQUEST/stripeDocUpload",
  "RECEIVE/stripeDocUpload",
]);

class DynamicDirectDepositForm extends Component {
  constructor(props) {
    super(props);
    this.state = { passport: null, state_id_front: null, state_id_back: null };
  }

  componentDidMount() {
    this.handleInitPrefill();
  }

  onSubmit = async values => {
    const { passport, state_id_front, state_id_back } = this.state;
    const keys = Object.keys(values);
    this.validateDocFieldsIfNeeded(); // Manual validation for non-standard fields

    const needBankToken =
      keys.includes("account_number") || keys.includes("routing_number");
    if (needBankToken) {
      const { token: bankToken, error: bankError } = await createBankToken(
        this.props.stripe.createToken,
        values.routing_number,
        values.account_number,
      );
      if (bankError) this.handleBankError(bankError);
      if (bankToken) values.stripe_token = bankToken.id;
    }

    const needSSNToken = keys.includes("social_security_number");
    if (needSSNToken) {
      const { token: ssnToken } = await createSSNToken(
        this.props.stripe.createToken,
        values.social_security_number,
      );
      if (ssnToken) {
        values.ssn_last_4 = values.social_security_number
          .trim()
          .slice(values.social_security_number.length - 4);
        // Send ssnToken to backend as personal_id_number to avoid leaking raw SSN
        values.personal_id_number = ssnToken.id;
      }
    }

    if (passport) {
      const { docError, docToken } = await this.createDocToken(passport);
      if (docError) throw new SubmissionError({ _error: docError });
      if (docToken) values.document_front = docToken;
    }

    if (state_id_front) {
      const { docError, docToken } = await this.createDocToken(state_id_front);
      if (docError) throw new SubmissionError({ _error: docError });
      if (docToken) values.document_front = docToken;
    }

    if (state_id_back) {
      const { docError, docToken } = await this.createDocToken(state_id_back);
      if (docError) throw new SubmissionError({ _error: docError });
      if (docToken) values.document_back = docToken;
    }

    // Don't send raw account, routing, or personal ID numbers to server
    const {
      account_number: _,
      routing_number: __,
      social_security_number: ___,
      ...valuesForServer
    } = values;

    return this.props
      .submitToServer(valuesForServer, { method: "patch" })
      .then(({ json, response }) => {
        if (!response.ok) this.handleSubmitError(json);
        this.handleSubmitSuccess();
      });
  };

  createDocToken = async doc => {
    const fd = new FormData();
    fd.append("file", doc[0]);
    const {
      json: { file: docError, token: docToken },
    } = await this.props.uploadDoc("/api/v2/stripe_upload/", fd);
    return { docError, docToken };
  };

  handleBankError = bankError => {
    const formErrors = {};
    const accountFields = ["account_number", "routing_number"];
    accountFields.forEach(field => {
      if (bankError.param === `bank_account[${field}]`)
        formErrors[field] = bankError.message;
    });
    throw new SubmissionError(formErrors);
  };

  validateDocFieldsIfNeeded = () => {
    const { passport, state_id_front, state_id_back } = this.state;
    const needDocFront = this.needField("document_front");
    const needDocBack = this.needField("document_back");

    if (needDocFront || needDocBack) {
      if (Boolean(state_id_front) !== Boolean(state_id_back)) {
        throw new SubmissionError({
          _error: "Please include images of both sides of your state ID.",
        });
      }

      if (needDocFront && !(passport || state_id_front)) {
        throw new SubmissionError({
          _error: "Please include image(s) of your government issued ID.",
        });
      }
    }
  };

  handleSubmitSuccess = () => {
    const { initializeForm, notify, onHideDynamicForm } = this.props;
    initializeForm();
    notify(
      createSuccessNotification({
        message: "Direct deposit information has been saved.",
      }),
    );
    onHideDynamicForm();
  };

  handleSubmitError = json => {
    this.props.notify(
      createErrorNotification({
        message: json.detail || "Please correct the errors below.",
      }),
    );
    // TODO: This is kind of a hack to get the individual[id_number] non_field_error to
    // display with a custom message (the message returned by Stripe is not user-friendly).
    if (Object.keys(json).includes("non_field_errors")) {
      const nonFieldErrors = parseNonFieldErrors(json.non_field_errors);
      const prefix =
        "Something went wrong. Please contact us at support@hireanesquire.com " +
        "and provide the following message: ";
      json._error = prefix + '"' + nonFieldErrors + '"';
    }
    throw new SubmissionError(json);
  };

  handleInitPrefill = () => {
    const {
      changeFieldIf,
      props: {
        fieldsNeeded: {
          first_name,
          last_name,
          phone_home,
          email_address,
          address_1,
          address_2,
          city,
          state: stateID,
          postal_code,
          birthdate,
          routing_number,
        },
      },
    } = this;
    const hasBirthdate =
      birthdate && birthdate.day && birthdate.month && birthdate.year;
    const dob = hasBirthdate
      ? `${birthdate.year}-${birthdate.month}-${birthdate.day}`
      : null;

    changeFieldIf("first_name", first_name, first_name);
    changeFieldIf("last_name", last_name, last_name);
    changeFieldIf("phone_home", phone_home, phone_home);
    changeFieldIf("email_address", email_address, email_address);
    changeFieldIf("address_1", address_1, address_1);
    changeFieldIf("address_2", address_2, address_2);
    changeFieldIf("city", city, city);
    changeFieldIf("state", stateID, stateID);
    changeFieldIf("postal_code", postal_code, postal_code);
    changeFieldIf("birthdate", dob, hasBirthdate);
    changeFieldIf("routing_number", routing_number, routing_number);
  };

  changeFieldIf = (name, value, condition) => {
    if (condition) this.props.changeFieldValue(name, value);
  };

  needField = field => {
    const { fieldsNeeded } = this.props;
    const fieldNamesNeeded = fieldsNeeded ? Object.keys(fieldsNeeded) : null;
    return Boolean(fieldNamesNeeded && fieldNamesNeeded.includes(field));
  };

  handleSetPassport = doc => this.setState({ passport: doc });

  handleSetStateIDFront = doc => this.setState({ state_id_front: doc });

  handleSetStateIDBack = doc => this.setState({ state_id_back: doc });

  render() {
    const { passport, state_id_front, state_id_back } = this.state;
    const {
      error,
      handleSubmit,
      pristine,
      submitting,
      onHide,
      withIDUpload,
      [types.OWN_CONTRACTOR]: { data, isFinished },
    } = this.props;
    const ownContractorData = isFinished ? data[Object.keys(data)[0]] : null;

    const need = {
      first_name: this.needField("first_name"),
      last_name: this.needField("last_name"),
      phone_home: this.needField("phone_home"),
      email_address: this.needField("email_address"),
      address_1: this.needField("address_1"),
      address_2: this.needField("address_2"),
      city: this.needField("city"),
      state: this.needField("state"),
      postal_code: this.needField("postal_code"),
      birthdate: this.needField("birthdate"),
      personal_id_number: this.needField("personal_id_number"),
      routing_number: this.needField("routing_number"),
      account_number: this.needField("account_number"),
      document_front: this.needField("document_front"),
      document_back: this.needField("document_back"),
    };

    const needPersonalField =
      need.first_name ||
      need.last_name ||
      need.phone_home ||
      need.email_address ||
      need.address_1 ||
      need.address_2 ||
      need.city ||
      need.state ||
      need.postal_code ||
      need.birthdate ||
      need.personal_id_number;
    const needBankField = need.routing_number || need.account_number;
    const needDocField = need.document_front || need.document_back;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {error && <ErrorAlert error={error} />}
        {needPersonalField && (
          <Fragment>
            <h4>Personal Information</h4>
            <PersonalInfoFieldGroup
              dynamic
              need={need}
              hasContractorData={Boolean(ownContractorData)}
            />
          </Fragment>
        )}
        {needBankField && (
          <Fragment>
            <h4>Bank Information</h4>
            <BankInfoFieldGroup />
          </Fragment>
        )}
        {withIDUpload &&
          needDocField && (
            <IDUploadSection
              onSetPassport={this.handleSetPassport}
              onSetStateIDFront={this.handleSetStateIDFront}
              onSetStateIDBack={this.handleSetStateIDBack}
              uploadedPassport={Boolean(passport)}
              uploadedStateIDFront={Boolean(state_id_front)}
              uploadedStateIDBack={Boolean(state_id_back)}
            />
          )}
        <ACHAgreeField />
        <ButtonToolbar>
          {onHide && <Button onClick={onHide}>Close</Button>}
          <FormButton
            action="save"
            submitting={submitting}
            style={{ float: "right" }}
            disabled={pristine || submitting}
          />
        </ButtonToolbar>
      </form>
    );
  }
}

const mapPropsToConfig = ownContractorQuery;
const ownContractorSelector = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);
const mapStateToProps = createStructuredSelector({
  ...ownContractorSelector,
});
const mapDispatchToProps = {
  submitToServer: update,
  initializeForm: () => initialize(dynamicFormName),
  changeFieldValue: (field, value) => change(dynamicFormName, field, value),
  resetForm: () => reset(dynamicFormName),
  uploadDoc: (url, fd) => stripeDocUpload(url, fd),
  notify,
};
DynamicDirectDepositForm = reduxForm({
  form: dynamicFormName,
})(DynamicDirectDepositForm);
DynamicDirectDepositForm = injectStripe(DynamicDirectDepositForm);
DynamicDirectDepositForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(DynamicDirectDepositForm);

class StripeDynamicDirectDepositForm extends Component {
  render() {
    const { ...formProps } = this.props;
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
        <Elements>
          <DynamicDirectDepositForm {...formProps} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default StripeDynamicDirectDepositForm;
