import React, { Component } from "react";

import Next from "./blocks";
import {
  finished,
  optional,
  currentSteps,
  stepsWithActionRequired,
  pendingSteps,
} from "./values";

const Item = ({ value }) => (
  <Next.Grid>
    <div>
      <Next.BorderedLabel color={value.label_color}>
        {value.label}
      </Next.BorderedLabel>
    </div>
    <div>{value.text}</div>
  </Next.Grid>
);

export default class OnboardingWhatsNext extends Component {
  render() {
    const { reqs } = this.props;
    const status = reqs.overall_status;
    const currentStep = reqs.current_step
      ? currentSteps[reqs.current_step.name]
      : null;
    const reds = reqs.steps_with_action_required_message.map(obj => obj.name);
    const yellows = reqs.steps_with_pending_message.map(obj => obj.name);

    return (
      <Next>
        {status === "COMPLETED" &&
          Object.entries(finished).map(([_key, value], index) => (
            <Item value={value} key={index} />
          ))}
        <Next.TopLabel>What&apos;s Next?</Next.TopLabel>
        {status === "COMPLETED" &&
          Object.entries(optional).map(([_key, value], index) => (
            <Item value={value} key={index} />
          ))}
        {(status === "ACTION_REQUIRED" ||
          status === "NOT_COMPLETED_AND_ACTION_REQUIRED") &&
          reds.map((red, index) => {
            const value = stepsWithActionRequired[red];
            return <Item value={value} key={index} />;
          })}
        {(status !== "ACTION_REQUIRED" ||
          status !== "NOT_COMPLETED_AND_ACTION_REQUIRED") &&
          yellows.map((yellow, index) => {
            const value = pendingSteps[yellow];
            return <Item value={value} key={index} />;
          })}
        {status !== "COMPLETED" && currentStep && <Item value={currentStep} />}
      </Next>
    );
  }
}
