import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { connectRequest } from "redux-query-react";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import { Alert, DropdownButton, MenuItem, Table } from "react-bootstrap";

import NotFound from "common/components/NotFound";
import Loading from "hiringagent-dashboard/components/utils/Loading";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";
import { createApiUrl } from "utils";

const SORTING_OPTIONS = {
  created: "Newest First",
  "-created": "Oldest First",
  last_name: "Alphabetical (last name)",
};

class PendingEntries extends Component<Props> {
  resourceDependencies = [
    types.CONTRACTORS,
    types.JOBS,
    types.JOB_WORKERS,
    types.PAYMENT_DETAILS,
    types.TIME_ENTRIES,
  ];

  state = {
    sorting: Object.keys(SORTING_OPTIONS)[0],
  };

  getContractorForWorker = workerId => {
    const {
      [types.CONTRACTORS]: {
        data: contractors,
        isFinished: contractorsFinished,
      },
      [types.JOB_WORKERS]: { data: workers, isFinished: workersFinished },
    } = this.props;

    const isFinished = contractorsFinished && workersFinished;

    if (!isFinished) {
      return null;
    }

    const worker = workers[workerId];
    return worker && contractors[worker.contractor];
  };

  getSortedTimeEntries = () => {
    const {
      [types.TIME_ENTRIES]: {
        data: timeEntries,
        query: { data: timeEntryIds },
        isFinished: timeEntriesFinished,
      },
    } = this.props;
    const { sorting } = this.state;

    return timeEntriesFinished
      ? timeEntryIds.map(id => timeEntries[id]).sort((a, b) => {
          if (sorting === "created") {
            return new Date(b.date) - new Date(a.date);
          } else if (sorting === "-created") {
            return new Date(a.date) - new Date(b.date);
          } else if (sorting === "last_name") {
            const contractorA = this.getContractorForWorker(a.worker);
            const contractorB = this.getContractorForWorker(b.worker);
            if (contractorA && contractorB) {
              const nameA = contractorA.last_name.toLowerCase();
              const nameB = contractorB.last_name.toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
            }
            return 0;
          }
          return a - b;
        })
      : [];
  };

  renderToolbar = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { sorting } = this.state;

    return (
      <div key="pending-entries-toolbar">
        <DropdownButton
          id="sort-by-dropdown"
          title={`Sort By${sorting ? `: ${SORTING_OPTIONS[sorting]}` : ""}`}
        >
          {Object.keys(SORTING_OPTIONS).map(sort => (
            <MenuItem
              key={sort}
              onClick={() => this.setState({ sorting: sort })}
            >
              {sorting === sort ? (
                <strong>{SORTING_OPTIONS[sort]}</strong>
              ) : (
                SORTING_OPTIONS[sort]
              )}
            </MenuItem>
          ))}
        </DropdownButton>
        <div className="pull-right">
          <DropdownButton
            pullRight={true}
            title="Export Entries"
            id="invoice-dropdown"
          >
            <MenuItem
              target="_blank"
              rel="noopener noreferrer"
              href={createApiUrl(
                `/agency/pending_entries_report/${id}/overview/`,
              )}
            >
              Overview
            </MenuItem>
            <MenuItem
              target="_blank"
              rel="noopener noreferrer"
              href={createApiUrl(
                `/agency/pending_entries_report/${id}/by_contractor/`,
              )}
            >
              By Contractor
            </MenuItem>
            <MenuItem
              target="_blank"
              rel="noopener noreferrer"
              href={createApiUrl(
                `/agency/pending_entries_report/${id}/by_individual_entry/`,
              )}
            >
              By Individual Entry
            </MenuItem>
            <MenuItem
              target="_blank"
              rel="noopener noreferrer"
              href={createApiUrl(`/agency/pending_entries_report/${id}/excel/`)}
            >
              Individual Entry XLS
            </MenuItem>
          </DropdownButton>
        </div>
      </div>
    );
  };

  render() {
    const {
      [types.JOBS]: { data: jobs },
      [types.PAYMENT_DETAILS]: { data: paymentDetails },
      match: {
        params: { id },
      },
    } = this.props;

    const isFinished = this.resourceDependencies.every(
      resource => this.props[resource].isFinished,
    );
    const isPending = this.resourceDependencies.some(
      resource => this.props[resource].isPending,
    );

    const job = jobs[id];
    const jobPaymentDetails = job && paymentDetails[job.paymentdetails];

    if (isFinished && !(job && jobPaymentDetails)) {
      return <NotFound />;
    }

    const sortedTimeEntries = this.getSortedTimeEntries();

    return (
      <div>
        <h1 style={{ marginBottom: "0.5rem" }}>
          Pending Entries for: {job && job.title}
        </h1>
        {isFinished && (
          <div>
            <div style={{ marginBottom: "2rem" }}>
              <Link to={`/agency/jobs/${job ? `${job.uuid}/` : ""}`}>
                &lt; Back to Job Details
              </Link>
            </div>
            <Alert bsStyle="warning">
              <i
                className="far fa-exclamation-circle"
                style={{ color: "red" }}
              />&nbsp; This table will show the pending entries that contractors
              have logged for this project. At the end of the billing cycle,
              these entries will be compiled into an invoice. Until that point,
              these entries are subject to change.
            </Alert>

            {this.renderToolbar()}

            <Table>
              <thead>
                <tr>
                  <th>Contractor</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Time</th>
                  <th className="text-right">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {sortedTimeEntries.map(entry => {
                  const hours = Math.floor(entry.minutes / 60);
                  const minutes = entry.minutes % 60;
                  const contractor = this.getContractorForWorker(entry.worker);
                  return (
                    <tr key={entry.uuid}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {contractor && contractor.full_name}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>{entry.date}</td>
                      <td>{entry.description}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {hours ? `${hours} hour${hours > 1 ? "s" : ""} ` : ""}
                        {minutes ? `${minutes} min` : ""}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        ${(
                          (jobPaymentDetails.rate / 60) *
                          entry.minutes
                        ).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        {isPending && <Loading />}
      </div>
    );
  }
}

const contractorsQuery = props =>
  createResourceListQuery(types.CONTRACTORS, {
    url: `/api/dev/contractors/?jobworker__job=${
      props.match.params.id
    }&limit=999`,
  });
const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });
const jobWorkersQuery = props =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: `/api/v2/job_workers/?job=${props.match.params.id}&limit=999`,
  });
const paymentDetailsQuery = props =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/?job=${props.match.params.id}`,
  });
const timeEntriesQuery = props =>
  createResourceListQuery(types.TIME_ENTRIES, {
    url: `/api/dev/time_entries/?worker__job=${
      props.match.params.id
    }&invoiced=false&limit=999`,
  });

const mapPropsToConfig = props => [
  contractorsQuery(props),
  jobQuery(props),
  jobWorkersQuery(props),
  paymentDetailsQuery(props),
  timeEntriesQuery(props),
];

const contractorsSelector = createResourceSelectorConfig(
  types.CONTRACTORS,
  contractorsQuery,
);
const jobSelector = createResourceSelectorConfig(types.JOBS, jobQuery);
const jobWorkersSelector = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkersQuery,
);
const paymentDetailsSelector = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailsQuery,
);
const timeEntriesSelector = createResourceSelectorConfig(
  types.TIME_ENTRIES,
  timeEntriesQuery,
);

const mapStateToProps = createStructuredSelector({
  ...contractorsSelector,
  ...jobSelector,
  ...jobWorkersSelector,
  ...paymentDetailsSelector,
  ...timeEntriesSelector,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(PendingEntries);
