import React, { Component } from "react";
import { connect } from "react-redux";
import { getDisplayName } from "hiringagent-dashboard/utils";
import { action, actionIfNeeded } from "common/actions/utils";
import { createStructuredSelectorCreator } from "common/selectors";
import { capitalizedString } from "common/utils";

export const createConnector = (
  displayName,
  mapStateToProps,
  mapDispatchToProps,
  type,
) => mapPropsToArgs => WrappedComponent => {
  class Connected extends Component {
    static defaultProps = {
      onLoadHandlers: {},
      onReloadHandlers: {},
    };

    componentDidMount() {
      const { onLoad } = this.props;

      if (onLoad) {
        const args =
          typeof mapPropsToArgs === "function"
            ? mapPropsToArgs(this.props)
            : mapPropsToArgs;
        onLoad(args);
      }
    }

    render() {
      const onLoadHandlers = {
        ...this.props.onLoadHandlers,
        [type]: () => this.props.onLoad(),
      };
      const onReloadHandlers = {
        ...this.props.onReloadHandlers,
        [type]: this.props.onReload,
      };

      return (
        <WrappedComponent
          {...this.props}
          onLoadHandlers={onLoadHandlers}
          onReloadHandlers={onReloadHandlers}
        />
      );
    }
  }

  Connected.displayName = `${displayName}(${getDisplayName(WrappedComponent)})`;
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Connected);
};

export const createConnectorCreator = reducer => ({
  mapDispatchToProps = {},
  ...options
}) => {
  const { type, verboseName = capitalizedString(type) } = options;

  return createConnector(
    `With${verboseName}`,
    createStructuredSelectorCreator(type, reducer),
    {
      onLoad: onLoadOptions =>
        actionIfNeeded(reducer, type)({ ...options, ...onLoadOptions }),
      onReload: onReloadOptions => action({ ...options, ...onReloadOptions }),
      ...mapDispatchToProps,
    },
    type,
  );
};
