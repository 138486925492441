export default {
  "03112b35-e1f3-4fbe-a52d-e65bd3c695c1": {
    value: "03112b35-e1f3-4fbe-a52d-e65bd3c695c1",
    label: "Boutique Firm",
  },
  "1f970f9e-0cec-41cd-bf25-29bdfc407ba4": {
    value: "1f970f9e-0cec-41cd-bf25-29bdfc407ba4",
    label: "In-House Legal Department",
  },
  "6c611830-15da-43bd-853f-7d2dafa5cf67": {
    value: "6c611830-15da-43bd-853f-7d2dafa5cf67",
    label: "AmLaw Firm",
  },
  "d1bdfd3e-ae29-49fe-bafe-1faf05f82337": {
    value: "d1bdfd3e-ae29-49fe-bafe-1faf05f82337",
    label: "Solo Practice",
  },
  "fa25dba1-2ff3-46fb-adcb-7530d805a677": {
    value: "fa25dba1-2ff3-46fb-adcb-7530d805a677",
    label: "Small/Medium Firm",
  },
};
