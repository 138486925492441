import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";
import { addNotification as notify } from "reapop";

import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";

import { oldApi } from "common/api";
import { get } from "common/api/api";

const actions = keyMirror({
  REQUEST_UNTAGGED_EMPLOYMENTS: null,
  RECEIVE_UNTAGGED_EMPLOYMENTS: null,
  REQUEST_UNTAGGED_EMPLOYMENTS_NEXT_PAGE: null,
  RECEIVE_UNTAGGED_EMPLOYMENTS_NEXT_PAGE: null,
  REQUEST_MARK_EMPLOYMENT_REVIEWED: null,
  RECEIVE_MARK_EMPLOYMENT_REVIEWED: null,
  REQUEST_ADD_TAG_TO_EMPLOYMENT: null,
  RECEIVE_ADD_TAG_TO_EMPLOYMENT: null,
});
export default actions;

const tagAddedMessage = createSuccessNotification({ message: "Tag updated!" });
const tagAlreadyAddedErrorMessage = createErrorNotification({
  message: "Tag has already been added to this employment record.",
});
const tooManyTagsErrorMEssage = createErrorNotification({
  message: "Cannot add more than 3 tags.",
});

export const requestUntaggedPreviousEmployments = () =>
  get("/api/v2/previous_employment_tags_manager/?limit=99", [
    actions.REQUEST_UNTAGGED_EMPLOYMENTS,
    actions.RECEIVE_UNTAGGED_EMPLOYMENTS,
  ]);

export const markEmploymentAsReviewed = employmentId => dispatch => {
  dispatch(
    createAction(actions.REQUEST_MARK_EMPLOYMENT_REVIEWED)(employmentId),
  );
  return oldApi
    .patch(`/api/v2/previous_employment_tags_manager/${employmentId}/`, {
      tag_review_override: true,
    })
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error marking employment as reviewed.");
      return dispatch(
        createAction(
          actions.RECEIVE_MARK_EMPLOYMENT_REVIEWED,
          null,
          metaGenerator,
        )(payload),
      );
    });
};

export const requestNextPageOfUntaggedEmployments = () => (
  dispatch,
  getState,
) => {
  dispatch(createAction(actions.REQUEST_UNTAGGED_EMPLOYMENTS_NEXT_PAGE)());
  return oldApi
    .get(getState().admin.previousEmploymentTagManager.next)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error getting next page of untagged employments");
      return dispatch(
        createAction(
          actions.RECEIVE_UNTAGGED_EMPLOYMENTS_NEXT_PAGE,
          null,
          metaGenerator,
        )(payload),
      );
    });
};

export const addTagToEmployment = (employmentId, tagId) => (
  dispatch,
  getState,
) => {
  const {
    employments: { [employmentId]: employment },
  } = getState().admin.previousEmploymentTagManager;
  const tagAlreadyAdded = employment.tags.includes(tagId);

  if (tagAlreadyAdded) {
    return dispatch(notify(tagAlreadyAddedErrorMessage));
  } else if (employment.tags.length >= 3) {
    return dispatch(notify(tooManyTagsErrorMEssage));
  }

  dispatch(
    createAction(actions.REQUEST_ADD_TAG_TO_EMPLOYMENT)({
      employmentId,
      tagId,
    }),
  );
  return oldApi
    .post("/api/v2/previous_employment_tags/", {
      tag: tagId,
      previous_employment: employmentId,
    })
    .then(({ json, response }) => {
      dispatch(notify(tagAddedMessage));
      const payload = response.ok
        ? json
        : new Error("Error creating employment tag");
      return dispatch(
        createAction(
          actions.RECEIVE_ADD_TAG_TO_EMPLOYMENT,
          null,
          metaGenerator,
        )(payload),
      );
    });
};
