// @flow strict
import React from "react";

import Details from "./Details";
import Sidebar from "./blocks";
import PracticeAreasList from "hiringagent-dashboard/components/candidates/ExperienceSection/PracticeAreasList";
import ProfilePicture from "./ProfilePicture";
import { SavedContractorButton } from "hiringagent-dashboard/components/talentPools";
import FitScore from "../FitScore";
import CandidateHeader from "../CandidateHeader";

type Props = {
  candidate: Candidate,
  contractor: Contractor,
  job: Job,
};

const CandidateSidebar = ({ contractor, candidate, job }: Props) => {
  return (
    <Sidebar>
      <Sidebar.Main>
        <Sidebar.ProfilePictureContainer>
          <ProfilePicture contractor={contractor} />
        </Sidebar.ProfilePictureContainer>
        <Sidebar.Info>
          <Sidebar.TalentPoolsButtonContainer>
            <SavedContractorButton contractorId={contractor.uuid} />
          </Sidebar.TalentPoolsButtonContainer>
          <Sidebar.NameAndStatusContainer>
            <Sidebar.Name>{contractor.full_name}</Sidebar.Name>{" "}
            <Sidebar.StatusLabel>{candidate.statusDisplay}</Sidebar.StatusLabel>
          </Sidebar.NameAndStatusContainer>
          <Sidebar.Headline>{contractor.summary_title}</Sidebar.Headline>
        </Sidebar.Info>
      </Sidebar.Main>
      <Sidebar.HideOnDesktop>
        <CandidateHeader
          candidate={candidate}
          contractor={contractor}
          job={job}
        />
      </Sidebar.HideOnDesktop>
      <Sidebar.DetailsContainer>
        <Details
          contractor={contractor}
          candidate={candidate}
          jobId={job.uuid}
        />
      </Sidebar.DetailsContainer>
      {contractor.practice_areas.length > 0 && (
        <Sidebar.PracticeAreasContainer>
          <Sidebar.PracticeAreasTitle>
            Practice Areas
          </Sidebar.PracticeAreasTitle>
          <PracticeAreasList
            attorneyUuid={contractor.uuid}
            practiceAreas={contractor.practice_areas}
          />
        </Sidebar.PracticeAreasContainer>
      )}
      <FitScore candidate={candidate} />
    </Sidebar>
  );
};

export default CandidateSidebar;
