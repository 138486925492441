import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";

import { changeReviewStatus } from "admin/actions/nonAttorneyVerification";
import { VERIFICATION_STATUS } from "../utils";
import { createApiUrl } from "utils";
import { createVisibilityStyle, StatusLabel } from "./utils";

export const ReferencesTable = ({ references }) => (
  <Table>
    <thead>
      <tr>
        <th />
        <th>Reviewer</th>
        <th>Related Employment</th>
        <th>Review</th>
        <th style={{ width: "10rem" }}>Status</th>
        <th style={{ width: "20rem" }}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {references.map(reference => (
        <ReferenceRow key={reference.uuid} reference={reference} />
      ))}
    </tbody>
  </Table>
);

class _ReferenceRow extends Component {
  render() {
    const { reference, verify, reject } = this.props;
    const editedReference = Object.assign({}, reference);

    if (!editedReference.review) return null;

    switch (editedReference.review.approval_status) {
      case 3:
        editedReference.review.status = VERIFICATION_STATUS.VERIFIED;
        break;
      case 2:
        editedReference.review.status = VERIFICATION_STATUS.REJECTED;
        break;
      case 1:
        editedReference.review.status = VERIFICATION_STATUS.PENDING;
        break;
      default:
        editedReference.review.status = VERIFICATION_STATUS.NOT_STARTED;
    }

    if (
      editedReference &&
      editedReference.related_previous_employment_data &&
      editedReference.related_previous_employment_data.start_date
    ) {
      editedReference.related_previous_employment_data["work_with_dates"] =
        editedReference.related_previous_employment_data.start_date +
        " to " +
        editedReference.related_previous_employment_data.end_date;
    } else {
      editedReference.related_previous_employment_data = {
        job_title: "",
        start_date: "",
        end_date: "",
        company_name: "",
        work_with_dates: "",
      };
    }

    return (
      <tr>
        <td>
          <a
            href={createApiUrl(
              `/hae_admin/review/reviewrequest/${editedReference.uuid}/change/`,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-external-link-alt" />
          </a>
        </td>
        <td>
          {`${editedReference.reviewer_first_name} ${
            editedReference.reviewer_last_name
          }`}
          <br />
          <small>
            {editedReference.review.title}
            <br />
            <em>{editedReference.review.work_with_location}</em>
            <br />
            <em>{editedReference.review.relationship_start_date}</em> to{" "}
            <em>{editedReference.review.relationship_end_date}</em>
          </small>
        </td>

        <td>
          <div
            style={createVisibilityStyle(
              editedReference.related_previous_employment != null,
            )}
          >
            <a
              href={createApiUrl(
                `/hae_admin/contractor/previousemployment/${
                  editedReference.related_previous_employment_data.uuid
                }/change/`,
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-external-link-alt" />
            </a>&nbsp;
            {editedReference.related_previous_employment_data.job_title}
            <br />
            <small>
              {editedReference.related_previous_employment_data.company_name}
              <br />
              <em>
                {editedReference.related_previous_employment_data.start_date} to{" "}
                {editedReference.related_previous_employment_data.end_date}{" "}
                <span
                  style={createVisibilityStyle(
                    editedReference.related_previous_employment != null &&
                      editedReference.related_previous_employment_data
                        .end_date == null,
                  )}
                >
                  today
                </span>
              </em>
            </small>
          </div>
        </td>
        <td>
          <a
            href={createApiUrl(
              `/hae_admin/review/review/${editedReference.review.uuid}/change/`,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-external-link-alt" />
          </a>
          <br />
          <strong>Work with again: </strong>
          {editedReference.review.work_with_again}
          <br />
          <strong>Complete requirements: </strong>
          {editedReference.review.complete_requirements}
          <br />
          <small>
            {editedReference.review.review}
            <br />
            <em>Additional comments: </em>
            {editedReference.review.additional_comments}
          </small>
        </td>
        <td>
          <StatusLabel status={editedReference.review.status} />
        </td>
        <td>
          <span
            style={createVisibilityStyle(
              editedReference.review.status !== VERIFICATION_STATUS.REJECTED,
            )}
          >
            <Button
              bsSize="xs"
              bsStyle="danger"
              onClick={reject(editedReference.review.uuid)}
            >
              Reject
            </Button>&nbsp;
          </span>
          <Button
            style={createVisibilityStyle(
              editedReference.review.status !== VERIFICATION_STATUS.VERIFIED,
            )}
            bsSize="xs"
            bsStyle="success"
            onClick={verify(editedReference.review.uuid)}
          >
            Verify
          </Button>
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  verify: id => () => dispatch(changeReviewStatus(id, 3)),
  reject: id => () => dispatch(changeReviewStatus(id, 2)),
});

const ReferenceRow = connect(
  null,
  mapDispatchToProps,
)(_ReferenceRow);
