import styled from "styled-components";

const Status = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: ${({ status, theme }) => {
    if (status === 1) return theme.colors.danger();
    else if (status === 2) return theme.colors.primary_turquoise();
    else if (status === 3) return theme.colors.success();
    else return theme.colors.primary_black40();
  }};
`;

export default Status;
