import styled from "styled-components";

const Degree = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Degree;
