import React, { Component, Fragment } from "react";
import { connectRequest } from "redux-query-react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import NotFound from "common/components/NotFound";
import { connect, compose } from "resources/components/utils";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { Loading } from "common/components";

import EditJobListingForm from "./edit/EditJobListingForm";

const getInitialValues = jobListing => ({
  allowed_applicants: jobListing.allowed_applicants,
  bar_requirements: jobListing.bar_requirements,
  description: jobListing.description,
  expertisearea_set: jobListing.expertisearea_set.map(value => value.tag),
  hours_per_week: jobListing.hours_per_week,
  joblistinglanguage_set: jobListing.joblistinglanguage_set,
  joblistinglocation_set: jobListing.joblistinglocation_set,
  make_private: jobListing.make_private,
  max_estimated_rate: jobListing.max_estimated_rate,
  max_years_experience: jobListing.max_years_experience,
  min_estimated_rate: jobListing.min_estimated_rate,
  min_years_experience: jobListing.min_years_experience,
  num_openings: jobListing.num_openings,
  position_type: jobListing.position_type,
  primary_practice_area: jobListing.primary_practice_area,
  primarypracticearearequirement_set:
    jobListing.primarypracticearearequirement_set,
  project_length: jobListing.project_length,
  remote: jobListing.remote,
  start_date: jobListing.start_date,
  title: jobListing.title,
});

export class EditJobListing extends Component {
  render() {
    const {
      jobListings: {
        isPending,
        isFinished,
        data,
        query: { data: queryData },
      },
    } = this.props;

    const jobListing = isFinished && data[queryData[0]];

    return (
      <div>
        <div className="back-button">
          <Link to={`/agency/jobs/${jobListing.job}`}>
            &larr; Back to Job Details
          </Link>
        </div>
        <h1>Edit Job{jobListing && `: ${jobListing.formattedTitle}`}</h1>
        {isPending && <Loading />}
        {isFinished && jobListing ? (
          <Fragment>
            <Row>
              <Col md={8}>
                <EditJobListingForm
                  jobListing={jobListing}
                  initialValues={getInitialValues(jobListing)}
                />
              </Col>
            </Row>
          </Fragment>
        ) : (
          !isPending && <NotFound />
        )}
      </div>
    );
  }
}

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.match.params.id}`,
  });

const mapStateToProps = createResourceSelector(
  types.JOB_LISTINGS,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(EditJobListing);
