import styled from "styled-components";

const CollapseHeader = styled.p`
  align-self: center;
  justify-self: start;
  margin: 0;
  padding: 0;
  font-size: 18px;
`;

export default CollapseHeader;
