// @flow strict
import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Modal } from "react-bootstrap";
import { connect } from "resources/components/utils";

import Onboarding from "./blocks";
import {
  completeAgentOnboading,
  hideOnboarding,
} from "hiringagent-dashboard/actions/onboarding";

type Props = {
  completeAgentOnboading: Uuid => void,
  hideOnboarding: () => void,
  hiringAgent: HiringAgent,
  showOnboarding: boolean,
};

class OnboardingModal extends Component<Props> {
  handleClick = () => {
    const {
      completeAgentOnboading,
      hideOnboarding,
      showOnboarding,
      hiringAgent,
    } = this.props;

    if (showOnboarding && hiringAgent.uuid) {
      hideOnboarding();
      completeAgentOnboading(hiringAgent.uuid);
    }
  };

  render() {
    const { showOnboarding } = this.props;

    return (
      <Onboarding show={showOnboarding} onHide={this.handleClick}>
        <Modal.Header closeButton>
          <Onboarding.Title>Welcome to Hire an Esquire!</Onboarding.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Since this is your first time here, we&#39;ll help you get up and
              running.
            </p>
            <p>
              Creating a job posting is the best way to experience the Hire an
              Esquire platform. Click on &quot;+ Post a Job&quot; in the top
              menu to explore our innovative recruiting tools and extensive
              network of legal professionals.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Onboarding.Button bsStyle="default" onClick={this.handleClick}>
            Okay, great!
          </Onboarding.Button>
          <LinkContainer to="/agency/jobs/create">
            <Onboarding.Button bsStyle="primary" onClick={this.handleClick}>
              + Post a Job
            </Onboarding.Button>
          </LinkContainer>
        </Modal.Footer>
      </Onboarding>
    );
  }
}

const mapStateToProps = state => ({
  showOnboarding: state.hiringAgentDashboard.onboarding.showOnboarding,
  hiringAgent: state.hiringAgentDashboard.sidebar.hiringAgent,
});

const mapDispatchToProps = {
  completeAgentOnboading,
  hideOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnboardingModal);
