// @flow strict
import styled from "styled-components";

const RadioCaption = styled.p`
  padding: 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  transition: 0.15s;
  font-weight: 700;
  font-size: 12px;

  ${({ theme }) => theme.media.mobile`
    margin: 0;
  `};

  ${({ theme }) => theme.media.desktop`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 25px 0 0 0;
    word-spacing: 100vw;
  `};
`;

export default RadioCaption;
