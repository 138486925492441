import { createAction } from "redux-actions";
import keyMirror from "keymirror";
import fetch from "isomorphic-fetch";

import api, { oldApi } from "common/api";
import { metaGenerator } from "common/actions";
import { getCookie } from "common/utils";
import { createApiUrl } from "utils";

const actions = keyMirror({
  REQUEST_MESSAGES: null,
  RECEIVE_MESSAGES: null,
  REQUEST_MESSAGE_THREAD: null,
  RECEIVE_MESSAGE_THREAD: null,
  REQUEST_TOGGLE_MESSAGE_READ_STATUS: null,
  RECEIVE_TOGGLE_MESSAGE_READ_STATUS: null,
  REQUEST_SUBMIT_REPLY_MESSAGE: null,
  RECEIVE_SUBMIT_REPLY_MESSAGE: null,
  REQUEST_SUBMIT_NEW_MESSAGE: null,
  RECEIVE_SUBMIT_NEW_MESSAGE: null,
  ADD_MESSAGE_RECIPIENT_IDS: null,
});
export default actions;

const _buildRequestMessagesUrl = args => {
  const url = "/api/dev/messages/";
  const urlWithParameters =
    Object.entries(args).reduce((prev, curr, idx) => {
      const separator = idx === 0 ? "?" : "&";
      return `${prev}${separator}${curr[0]}=${curr[1]}`;
    }, url) + "&inbox=1";
  return urlWithParameters;
};

export const requestMessages = (args = {}) => (dispatch, getState) => {
  const { limit = 999, forceFetch = false } = args;
  const completeUrl = _buildRequestMessagesUrl({ limit, ...args });
  const { allLoaded, lastUpdated } = getState().hiringAgentDashboard.messages;
  if (forceFetch || !allLoaded || !lastUpdated) {
    dispatch(createAction(actions.REQUEST_MESSAGES)());
    return oldApi.get(completeUrl).then(({ json, response }) => {
      const payload = response.ok ? json : new Error("Error getting messages.");
      payload.allFetched = limit === 999;
      dispatch(
        createAction(actions.RECEIVE_MESSAGES, p => p, metaGenerator)(payload),
      );
      return { json, response };
    });
  }
  return null;
};

export const requestMessageThread = threadId =>
  api.get(`/api/dev/messages/${threadId}/`, [
    actions.REQUEST_MESSAGE_THREAD,
    actions.RECEIVE_MESSAGE_THREAD,
  ]);

export const requestToggleMessageReadStatus = threadId => dispatch => {
  dispatch(
    createAction(actions.REQUEST_TOGGLE_MESSAGE_READ_STATUS)({ threadId }),
  );
  return oldApi
    .post(`/api/dev/messages/${threadId}/change_unread/`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? { threadId }
        : new Error("Error changing message read status");
      const meta = () => ({
        ...metaGenerator(),
        options: { type: "messages" },
      });
      dispatch(
        createAction(actions.RECEIVE_TOGGLE_MESSAGE_READ_STATUS, p => p, meta)(
          payload,
        ),
      );
      return { json, response };
    });
};

const _getBuiltFormData = data => {
  const {
    recipients = null,
    subject = null,
    files = null,
    job = null,
    body,
  } = data;
  const fd = new FormData();

  fd.append("body", body);
  if (job) fd.append("job", job);
  if (recipients)
    recipients.forEach(recipient => {
      fd.append("recipients", recipient);
    });
  if (subject) fd.append("subject", subject);
  if (files)
    files.forEach(file => {
      fd.append("file", file);
    });

  return fd;
};

const _submitMessage = (url, data) =>
  fetch(createApiUrl(url), {
    method: "POST",
    headers: {
      "X-CSRFToken": getCookie("csrftoken"),
      Accept: "application/json",
    },
    credentials: "include",
    body: _getBuiltFormData(data),
  });

export const submitNewMessage = data => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_NEW_MESSAGE)());

  return _submitMessage("/api/dev/messages/", data)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error sending new message.");
      dispatch(
        createAction(actions.RECEIVE_SUBMIT_NEW_MESSAGE, p => p, metaGenerator)(
          payload,
        ),
      );
      return { json, response };
    });
};

export const submitReplyMessage = (threadId, data) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_REPLY_MESSAGE)());

  return _submitMessage(`/api/dev/messages/${threadId}/reply/`, data)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error sending reply message.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_REPLY_MESSAGE,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const addMessageRecipientIds = createAction(
  actions.ADD_MESSAGE_RECIPIENT_IDS,
);
