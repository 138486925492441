import styled from "styled-components";

const DropdownImage = styled.img.attrs({
  alt: "User",
})`
  float: left;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  padding: 0;
  border-radius: 50%;
`;

export default DropdownImage;
