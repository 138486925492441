import React, { Component } from "react";
import { connectRequest } from "redux-query-react";
import { destroy } from "redux-form";
import { createStructuredSelector } from "reselect";

import Loading from "common/components/Loading";
import NotFound from "common/components/NotFound";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import { agencyQuery } from "queries/requests/agencies";
import { connect, compose } from "resources/components/utils";
import types from "resources/types";

import { formName } from "hiringagent-dashboard/components/jobs/payment-details/utils";

export class PaymentDetailsContainer extends Component {
  componentWillUnmount() {
    this.props.destroy(formName);
  }

  render() {
    const {
      match: {
        params: { id },
      },
      [types.AGENCY]: { data: agencyData, isFinished: agencyFinished },
      [types.JOBS]: { data: jobs, isFinished: jobsFinished },
      [types.PAYMENT_DETAILS]: {
        data: paymentDetails,
        isFinished: detailsFinished,
      },
    } = this.props;

    const agency = Object.values(agencyData)[0] || {};
    const job = jobs[id];
    const existingPaymentDetails =
      job &&
      Object.values(paymentDetails).find(detail => detail.job === job.uuid);

    const allFinished = agencyFinished && jobsFinished && detailsFinished;

    const propsToPass = {
      job,
      paymentDetails: existingPaymentDetails,
      agencyRates: {
        hae_1099_rate: agency.hae_1099_rate,
        hae_w2_rate: agency.hae_w2_rate,
      },
    };

    return (
      <div className="create-job-listing">
        <h1 className="create-job-listing-title text-muted">
          Finalize Payment Details{" "}
          {job && <small>for {job.formattedTitle}</small>}
        </h1>
        {allFinished ? (
          job ? (
            React.cloneElement(this.props.children, propsToPass)
          ) : (
            <NotFound />
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });
const paymentDetailsQuery = props =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/?job=${props.match.params.id}`,
  });

const mapPropsToConfig = props => [
  agencyQuery(),
  jobQuery(props),
  paymentDetailsQuery(props),
];

const agencySelectorConfig = createResourceSelectorConfig(
  types.AGENCY,
  agencyQuery,
);
const jobsSelectorConfig = createResourceSelectorConfig(types.JOBS, jobQuery);
const paymentDetailsSelectorConfig = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...agencySelectorConfig,
  ...jobsSelectorConfig,
  ...paymentDetailsSelectorConfig,
});

export default compose(
  connect(
    mapStateToProps,
    { destroy },
  ),
  connectRequest(mapPropsToConfig),
)(PaymentDetailsContainer);
