import React, { Component, Fragment } from "react";
import { reduxForm, formValueSelector, Field } from "redux-form";
import { Checkbox, FormGroup } from "react-bootstrap";

import { connect, compose } from "resources/components/utils";
import { FieldHelpBlock } from "common/components/forms";
import { PositionTypeSelectField } from "common/components/forms/inputs";
import { validateRequiredFields, validationState } from "common/utils/forms";
import { Error } from "common/utils/forms/validators";
import { FormRow, withFormError } from "./utils";

const formSelector = formValueSelector("createJobListingContractorTypes");

const validate = values => {
  const errors = validateRequiredFields(["position_type"], values);
  if (values.allowed_applicants === contractorTypeChoices.NONE) {
    errors.allowed_applicants = (
      <Error>Please choose at least one option.</Error>
    );
  }
  return errors;
};

export const contractorTypeChoices = {
  NONE: null,
  ATTORNEYS_ONLY: "attorneys",
  NON_ATTORNEYS_ONLY: "non_attorneys",
  ANY: "any",
};

export const attorneyTypes = [
  contractorTypeChoices.ATTORNEYS_ONLY,
  contractorTypeChoices.ANY,
];

export const nonAttorneyTypes = [
  contractorTypeChoices.NON_ATTORNEYS_ONLY,
  contractorTypeChoices.ANY,
];

class ContractorTypeField extends Component {
  constructor(props) {
    super(props);
    const {
      input: { value },
    } = this.props;
    this.state = {
      allowAttorneys: attorneyTypes.includes(value),
      allowNonAttorneys: nonAttorneyTypes.includes(value),
    };
  }

  componentDidUpdate() {
    const {
      input: { onChange },
    } = this.props;
    const { allowAttorneys, allowNonAttorneys } = this.state;
    let newValue;
    if (!allowAttorneys && !allowNonAttorneys) {
      newValue = contractorTypeChoices.NONE;
    } else if (allowAttorneys && !allowNonAttorneys) {
      newValue = contractorTypeChoices.ATTORNEYS_ONLY;
    } else if (!allowAttorneys && allowNonAttorneys) {
      newValue = contractorTypeChoices.NON_ATTORNEYS_ONLY;
    } else {
      newValue = contractorTypeChoices.ANY;
    }
    onChange(newValue);
  }

  handleAttorneyChange = e => {
    this.setState({ allowAttorneys: e.target.checked });
    this.props.onCheckboxChange();
  };

  handleNonAttorneyChange = e => {
    this.setState({ allowNonAttorneys: e.target.checked });
    this.props.onCheckboxChange();
  };

  render() {
    const { meta } = this.props;
    const { allowAttorneys, allowNonAttorneys } = this.state;
    return (
      <FormGroup validationState={validationState(meta)}>
        <FormRow>
          <Checkbox
            onChange={this.handleAttorneyChange}
            checked={allowAttorneys}
            inline
          >
            Attorneys
          </Checkbox>
          <div>Verified to be licensed and in good standing.</div>
        </FormRow>
        <FormRow>
          <Checkbox
            onChange={this.handleNonAttorneyChange}
            checked={allowNonAttorneys}
            inline
          >
            Non-attorney legal professionals
          </Checkbox>
          <div>
            Verified to have valid professional transcripts, certificates and
            relevant work experience.
          </div>
        </FormRow>
        <FieldHelpBlock {...meta} />
      </FormGroup>
    );
  }
}

class ContractorTypeForm extends Component {
  handleClearPositionType = () => {
    this.props.change("position_type", null);
  };
  render() {
    const {
      handleSubmit,
      formData: { allowedApplicants },
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="allowed_applicants"
          component={ContractorTypeField}
          onCheckboxChange={this.handleClearPositionType}
        />
        <hr />
        <FormRow>
          <PositionTypeSelectField allowedApplicants={allowedApplicants} />
          <Fragment />
        </FormRow>
      </form>
    );
  }
}

ContractorTypeForm = compose(
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
  withFormError(),
  connect(state => ({
    formData: {
      allowedApplicants: formSelector(state, "allowed_applicants"),
    },
  })),
)(ContractorTypeForm);

export default class ContractorType extends Component {
  render() {
    const {
      formProps,
      jobListing: { allowed_applicants, position_type },
    } = this.props;
    const initialValues = {
      allowed_applicants,
      position_type,
    };
    return (
      <div>
        <h2 style={{ marginBottom: "3rem" }}>
          What type of legal professional would you like to hire?
        </h2>
        <div>
          <ContractorTypeForm {...formProps} initialValues={initialValues} />
        </div>
      </div>
    );
  }
}
