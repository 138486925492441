import React, { Component } from "react";
import { ButtonToolbar, Modal } from "react-bootstrap";

import Bar from "./blocks";
import PrimaryButton from "common/blocks/PrimaryButton";

export default class FAQModal extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  handleHideModal = () => this.setState({ showModal: false });

  handleShowModal = () => this.setState({ showModal: true });

  render() {
    const {
      state: { showModal },
      handleHideModal,
      handleShowModal,
    } = this;

    return (
      <Bar.FAQ>
        <Bar.FAQTrigger onClick={handleShowModal}>
          Why do I need to upload a document?
        </Bar.FAQTrigger>
        <Modal show={showModal} onHide={handleHideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Bar Admissions FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Bar.FAQText>
              To apply to jobs on Hire an Esquire, you must verify via a bar
              license document that you are an attorney in good standing and
              authorized to practice law as required by a job&apos;s
              jurisdictional requirements.
            </Bar.FAQText>
            <ButtonToolbar>
              <PrimaryButton bsStyle="primary" onClick={handleHideModal}>
                OK, GREAT
              </PrimaryButton>
            </ButtonToolbar>
          </Modal.Body>
        </Modal>
      </Bar.FAQ>
    );
  }
}
