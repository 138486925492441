import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { ToggleButton } from "react-bootstrap";

import {
  CheckboxField,
  ToggleRadioButtonsField,
} from "common/components/forms/inputs";
import { withRejectionReasons } from "common/connectors";
import { validateRequiredFields } from "common/utils/forms/validators";

const requiredFields = ["verified", "rejection_reason"];
const validate = values => validateRequiredFields(requiredFields, values);

class DenyBarInformationForm extends Component {
  render() {
    const {
      formValues: { verified },
      handleSubmit,
      rejectionReasons: { data, ready },
    } = this.props;
    const rejectedTypeSelected = verified === "-1";
    return (
      <form onSubmit={handleSubmit}>
        <ToggleRadioButtonsField name="verified" label="Denial Type">
          <small>Why is the bar license being denied?</small>
          <br />
          <ToggleButton value="-1">Rejected</ToggleButton>
          <ToggleButton value="2">Inactive</ToggleButton>
        </ToggleRadioButtonsField>

        {ready &&
          rejectedTypeSelected && (
            <div>
              <ToggleRadioButtonsField
                name="rejection_reason"
                label="Rejection Reason"
              >
                {data.results.map(({ uuid, label }) => (
                  <ToggleButton
                    key={`reject-reason-option-${uuid}`}
                    value={uuid}
                  >
                    {label}
                  </ToggleButton>
                ))}
              </ToggleRadioButtonsField>
              <div>
                <CheckboxField
                  name="send_rejection_email"
                  label="Send rejection email?"
                />
              </div>
            </div>
          )}
        <ToggleRadioButtonsField
          name="overall_rejected"
          label="Overall Rejection"
        >
          <small>
            In addition to rejecting the bar license, you may reject the
            attorney entirely here. This will lock the attorney out of the
            platform.
          </small>
          <br />
          <ToggleButton value="1">Reject attorney overall</ToggleButton>
          <ToggleButton value="0">Do not reject attorney overall</ToggleButton>
        </ToggleRadioButtonsField>
      </form>
    );
  }
}

export const formName = "denyBarInformationForm";
const selector = formValueSelector(formName);

export default compose(
  connect(state => ({
    formValues: {
      verified: selector(state, "verified"),
    },
  })),
  reduxForm({
    form: formName,
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
  withRejectionReasons(),
)(DenyBarInformationForm);
