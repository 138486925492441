export default {
  "accounting:invoices": () => "/hae_admin/accounting/invoices/",
  "accounting:charges": () => "/hae_admin/accounting/charges/",

  "admin:attorney_attorney_change": object_id =>
    `/hae_admin/attorney/attorney/${object_id}/change/`,
  "admin:contractor_contractor_change": object_id =>
    `/hae_admin/contractor/contractor/${object_id}/change/`,
  "admin:contractor_previousemployment_change": object_id =>
    `/hae_admin/contractor/previousemployment/${object_id}/change/`,
  "admin:invoicing_invoice_change": object_id =>
    `/hae_admin/invoicing/invoice/${object_id}/change/`,
  "admin:school_school_change": object_id =>
    `/hae_admin/school/school/${object_id}/change/`,

  "attorney:account": () => "/attorney/account/",
  "attorney:edit_knowledge_areas_v3": () =>
    "/attorney/api/v0/knowledge_areas_v3/",
  "attorney:edit_practice_areas_v3": () => "/attorney/api/v0/tags_v3/",
  "attorney:edit_profile_summary_v2": () => "/attorney/api/v0/summary/",
  "attorney:index": () => "/attorney/",
  "attorney:jobs_apply_new": jobSlug => `/attorney/jobs/apply/${jobSlug}/`,
  "attorney:job_details_new": jobSlug => `/attorney/jobs/details/${jobSlug}/`,
  "attorney:jobs_search": () => "/attorney/jobs/search/",
  "attorney:new_message": jobSlug => `/attorney/message-center/new/${jobSlug}/`,
  "attorney:position_interest": () => "/attorney/api/v0/position_interest/",
  "attorney:profile": () => "/attorney/profile/",

  "hae_admin:index": () => "/hae_admin/dashboards/",

  "hiringagency:approve_invoice": uuid => `/agency/invoice/${uuid}/approve/`,
  "hiringagency:approve_invoice_v2": uuid =>
    `/agency/invoice/${uuid}/approve_v2/`,
  "hiringagency:invoice_print_basic": invoice_number =>
    `/agency/invoice/${invoice_number}/print/`,
  "hiringagency:invoice_print_bundled": invoice_number =>
    `/agency/invoice/${invoice_number}/print/bundled/`,
  "hiringagency:invoice_print_detailed": invoice_number =>
    `/agency/invoice/${invoice_number}/print/detailed/`,
  "hiringagency:invoice_xls_detailed": invoice_number =>
    `/agency/invoice/${invoice_number}/.xls`,
  "hiringagency:job_attachment_delete": (jobId, fileId) =>
    `/agency/job/attachment/delete/${jobId}/${fileId}/`,
  "hiringagency:job_invoices": (jobId, breakdown) =>
    `/agency/jobs/${jobId}/invoices/?breakdown=${breakdown}`,
  "hiringagency:job_preview": job_slug => `/agency/jobs/${job_slug}/preview/`,
  "hiringagency:pending_entries": (job_uuid, report_format) =>
    `/agency/pending_entries_report/${job_uuid}/${report_format}/`,

  "social:begin": auth => `/social/login/${auth}`,

  "nucleus:index": () => "/",
  "nucleus:login": () => "/login/",
  "nucleus:logout": () => "/logout/",
  "nucleus:message_thread": uuid => `/message-center/thread/${uuid}/`,
  "nucleus:privacy_policy": () => "/privacy-policy/",
  "nucleus:public_job_spa": jobId => `/public/jobs/${jobId}/`,
};
