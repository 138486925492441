import React, { Component } from "react";
import { compose, connect, connectRequest, mutateAsync } from "queries/utils";

import { JobNavigationActions } from "common/components/jobs";
import Loading from "common/components/Loading";
import NotFound from "common/components/NotFound";
import {
  JobActions,
  JobAttachments,
  JobDetails,
  JobMessages,
} from "contractor/components/job";

import { createStructuredSelector } from "reselect";
import {
  createResourceListQuery,
  createResourceDetailQuery,
  createResourceSelectorConfig,
  createQueryConfig,
} from "queries";
import types from "resources/types";

class Job extends Component {
  requiredResources = [
    types.OWN_CONTRACTOR,
    types.JOBS,
    types.JOB_LISTINGS,
    types.CANDIDATES,
    types.JOB_WORKERS,
    types.ONBOARDING_REQUIREMENTS,
  ];

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const {
      children,
      match: {
        params: { id },
      },
      [types.CANDIDATES]: {
        data: candidatesData,
        query: { data: candidatesQueryData },
      },
      [types.ONBOARDING_REQUIREMENTS]: { data: onboardingRequirementsData },
      [types.OWN_CONTRACTOR]: {
        data: ownContractorData,
        query: { data: ownContractorQueryData },
      },
      [types.JOBS]: { data: jobsData },
      [types.JOB_LISTINGS]: {
        data: jobListingsData,
        query: { data: jobListingsQueryData },
      },
      [types.JOB_WORKERS]: {
        data: jobWorkersData,
        query: { data: jobWorkersQueryData },
      },
    } = this.props;

    const onboardingRequirements = Object.values(onboardingRequirementsData)[0];
    const hasPermissionToApply =
      onboardingRequirements &&
      onboardingRequirements.permissions.apply_to_jobs;
    const contractor = ownContractorData[ownContractorQueryData[0]];
    const job = jobsData[id];
    const jobListing = jobListingsData[jobListingsQueryData[0]];
    const candidate = candidatesData[candidatesQueryData[0]];
    const jobWorker = jobWorkersData[jobWorkersQueryData[0]];

    const allFinished = this.requiredResources.every(
      resource => this.props[resource].isFinished,
    );

    if (allFinished && (!job || !jobListing)) {
      return (
        <NotFound text="Back to Search Results" to={"/contractor/jobs/"} />
      );
    }

    return (
      <div>
        {allFinished ? (
          <div>
            {children && children}
            <h1>{jobListing.formattedTitle}</h1>
            <JobMessages
              job={job}
              jobListing={jobListing}
              candidate={candidate}
              worker={jobWorker}
              hasPermission={hasPermissionToApply}
              contractor={contractor}
            />
            <JobActions
              job={job}
              jobListing={jobListing}
              worker={jobWorker}
              candidate={candidate}
              hasPermission={hasPermissionToApply}
              yearsExperience={contractor.years_work_experience}
            />
            <JobDetails job={job} jobListing={jobListing} />
            {/* TODO: Should we make sure the worker is active before showing job files? */}
            <JobAttachments jobId={id} isWorker={Boolean(jobWorker)} />
            <JobNavigationActions />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const candidatesQuery = props =>
  createResourceListQuery(types.CANDIDATES, {
    url: `/api/v2/candidates/?job=${props.match.params.id}`,
    force: true,
  });
const onboardingRequirementsQuery = () =>
  createResourceDetailQuery(types.ONBOARDING_REQUIREMENTS, {
    url: `/api/v2/onboarding_requirements/`,
    force: true,
  });
const ownContractorQuery = () =>
  createResourceDetailQuery(types.OWN_CONTRACTOR, {
    url: "/api/dev/contractors/self/",
  });
const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });
const jobListingsQuery = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.match.params.id}`,
    force: true,
  });
const jobWorkerQuery = props =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: `/api/v2/job_workers/?job=${props.match.params.id}`,
    force: true,
  });

const mapPropsToConfig = props => [
  candidatesQuery(props),
  onboardingRequirementsQuery(props),
  ownContractorQuery(),
  jobQuery(props),
  jobListingsQuery(props),
  jobWorkerQuery(props),
];

const candidatesSelector = createResourceSelectorConfig(
  types.CANDIDATES,
  candidatesQuery,
);
const onboardingRequirementsSelector = createResourceSelectorConfig(
  types.ONBOARDING_REQUIREMENTS,
  onboardingRequirementsQuery,
);
const ownContractorSelector = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);
const jobsSelector = createResourceSelectorConfig(types.JOBS, jobQuery);
const jobListingsSelector = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingsQuery,
);
const jobWorkersSelector = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkerQuery,
);

const mapStateToProps = createStructuredSelector({
  ...candidatesSelector,
  ...onboardingRequirementsSelector,
  ...ownContractorSelector,
  ...jobsSelector,
  ...jobListingsSelector,
  ...jobWorkersSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: () =>
    dispatch(
      mutateAsync(
        createQueryConfig({
          url: "/api/v2/user_activities/",
          options: { method: "POST" },
          body: {
            activity: 2,
            data: {
              job_id: props.match.params.id,
            },
          },
        }),
      ),
    ),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Job);
