import actions from "admin/actions/attorneyVerificationManager";
import { listToObject } from "admin/utils";
import createReducer from "common/reducers/utils";

const initialData = {
  barInformations: {},
  selectedBarInformationId: null,
};

const handleReceiveBarInformations = (state, action) => ({
  ...state,
  barInformations: {
    ...state.barInformations,
    ...listToObject(action.payload.results, "uuid"),
  },
});

const handlers = {
  [actions.SET_SELECTED_BAR_INFORMATION_ID]: (state, action) => ({
    ...state,
    selectedBarInformationId:
      action.payload === state.selectedBarInformationId ? null : action.payload,
  }),
  "RECEIVE/barInformations": handleReceiveBarInformations,
  "RECEIVE/pendingBarInformations": handleReceiveBarInformations,
  "RECEIVE/recentBarInformations": handleReceiveBarInformations,
  "RECEIVE/submitBarInformation": (state, action) => ({
    ...state,
    barInformations: {
      ...state.barInformations,
      [action.payload.uuid]: action.payload,
    },
  }),
};

export default createReducer(
  "attorneyVerificationManager",
  initialData,
  handlers,
);
