import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import PracticeAreasForm from "./PracticeAreasForm";
import { RemoteSubmitButton } from "../../NextButton";
import { validateRequiredFields } from "common/utils/forms";
import { getActionIsRequired, getCustomErrorMsg } from "../../utils";
import { getResourcesAreReady } from "common/utils/helpers";
import { compose, connect, connectRequest, mutateAsync } from "queries/utils";
import { createResourceSelectorConfig } from "queries";
import { updateOwnContractorQuery } from "queries/mutations/contractors";
import { tagsQuery } from "queries/requests/tags";
import { handleSubmitError, redirectToNextStepIfNeeded } from "../../utils";
import Loading from "common/components/Loading";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import Label from "common/blocks/Label";
import types from "resources/types";
import { MainCopy } from "./copy";

const stepName = "practice_areas";
const requiredResources = [types.TAGS];

class PracticeAreas extends Component {
  constructor(props) {
    super(props);
    this.state = { nextButtonLoading: false };
  }

  handleSubmitPracticeAreasForm = async ({ practice_areas }) => {
    await this.setState({ nextButtonLoading: true });

    const {
      props: {
        ownContractor: { uuid },
        updateOwnContractor,
      },
      handleNextClick,
      stopButtonLoading,
    } = this;
    const formattedValues = {
      practice_areas: practice_areas.map(uuid => ({ tag: uuid })),
    };
    const result = await updateOwnContractor(formattedValues, uuid);

    stopButtonLoading();
    handleSubmitError(result);
    handleNextClick();
  };

  getInitialValues = ownContractor => {
    const initialValues = ownContractor.practice_areas
      ? {
          practice_areas: ownContractor.practice_areas.map(
            practiceArea => practiceArea.tag,
          ),
        }
      : { practice_areas: [] };
    return initialValues;
  };

  validate = values => {
    const errors = validateRequiredFields([stepName], values);
    const { practice_areas } = values;
    if (practice_areas && practice_areas.length > 3) {
      errors.practice_areas =
        "You cannot select more than 3 areas of expertise.";
    }
    return errors;
  };

  handleNextClick = () => {
    redirectToNextStepIfNeeded(
      stepName,
      this.props.refreshReqs,
      this.stopButtonLoading,
    );
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: { nextButtonLoading },
      props: {
        ownContractor,
        tags: { data: tagsData },
        reqs: { steps },
      },
      getInitialValues,
      handleSubmitPracticeAreasForm,
      validate,
    } = this;
    const isReady = getResourcesAreReady(requiredResources, this.props);
    const actionIsRequired = getActionIsRequired(stepName, steps);
    const customErrorMsg = getCustomErrorMsg(stepName, steps);
    const tags = Object.values(tagsData).map(tag => ({
      value: tag.uuid,
      label: tag.name,
    }));
    const initialValues = getInitialValues(ownContractor);

    return isReady ? (
      <Onboard.Page>
        <Onboard.Container>
          <DesktopOnly>
            <Onboard.ContainerHeader>
              <div>
                <h1>Practice Areas</h1>
              </div>
              <div>
                {actionIsRequired && (
                  <Label color="danger">ACTION REQUIRED</Label>
                )}
              </div>
            </Onboard.ContainerHeader>
          </DesktopOnly>
          <MobileOnly>
            <h1>Practice Areas</h1>
            {actionIsRequired && (
              <div>
                <Label color="danger">ACTION REQUIRED</Label>
                <br />
                <br />
              </div>
            )}
          </MobileOnly>
          {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
          <MainCopy />
          <PracticeAreasForm
            initialValues={initialValues}
            onSubmit={handleSubmitPracticeAreasForm}
            tags={tags}
            validate={validate}
          />
        </Onboard.Container>
        <RemoteSubmitButton
          formName="onboardingPracticeAreasForm"
          isLoading={nextButtonLoading}
        />
      </Onboard.Page>
    ) : (
      <Loading />
    );
  }
}

const mapPropsToConfig = () => [tagsQuery()];

const tagsSelectorConfig = createResourceSelectorConfig(types.TAGS, tagsQuery);

const mapStateToProps = createStructuredSelector({
  ...tagsSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  updateOwnContractor: (data, uuid) =>
    dispatch(mutateAsync(updateOwnContractorQuery(data, uuid))),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(PracticeAreas);
