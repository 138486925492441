import React, { Component } from "react";
import { connect, compose, connectResource } from "resources/components/utils";
import { Col, Row } from "react-bootstrap";

import { ErrorBoundary } from "common/components/error-boundary";
import Loading from "common/components/Loading";
import { ownContractorRequest } from "resources/requests";
import { getOwnContractor } from "contractor/selectors";
import SettingsNavMenu from "./SettingsNavMenu";

class Settings extends Component {
  render() {
    const {
      ownContractor: { isLoading, lastUpdated, data: contractor },
    } = this.props;

    return (
      <div className="settings">
        <h1>Settings</h1>
        <Row>
          <Col md={3}>
            <SettingsNavMenu isAttorney={contractor.is_attorney} />
          </Col>
          <Col md={8} mdOffset={1}>
            {lastUpdated ? (
              <ErrorBoundary>
                {React.cloneElement(this.props.children, { contractor })}
              </ErrorBoundary>
            ) : (
              isLoading && <Loading />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  connect(getOwnContractor),
  connectResource(ownContractorRequest),
)(Settings);
