// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {
  activeSecondaryNav: "hire" | "manage" | null,
};

const SecondaryNavList: ComponentType<Props> = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 42px;
  max-height: ${({ activeSecondaryNav }) =>
    activeSecondaryNav === null ? "0" : "42px"};
  margin: 0;
  padding: 0 0 0 175px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #cce3f5;
`;

export default SecondaryNavList;
