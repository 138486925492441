/**
 * This file contains modified query config creators (queries/index.js) to
 * be used with redux-query's hooks.
 */
import { getQueryKey } from "redux-query";
import querystring from "querystring";

import { getCookie, listToObject } from "common/utils";
import { typeMapping } from "resources/types";
import { createApiUrl } from "utils";

export * from "./selectors";

const parseUrlObject = ({
  apiRoot = "api",
  version = "v2",
  endpoint,
  params = {},
}) => `/${apiRoot}/${version}/${endpoint}/?${querystring.stringify(params)}`;

export const getDefaultOptions = () => ({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
  },
  credentials: "include",
});

/**
 * Takes a base `config` object, and does the following:
 *    - Parses the `url` attribute to a string if it's an object
 *    - Adds or updates the `options` attribute by adding the default options
 *    - Returns the updated config object
 */
export const createQueryConfig = ({ url, ...config }) => {
  if (!url)
    throw new Error("`url` attribute is required on `config` argument.");
  let parsedUrl = url;
  if (typeof url === "object") {
    parsedUrl = parseUrlObject(url);
  }
  return {
    ...config,
    url: createApiUrl(parsedUrl),
    options: {
      ...getDefaultOptions(),
      ...config.options,
    },
  };
};

const createResourceUpdater = config => (prevResource, resource) => {
  if (!prevResource) return resource;
  if (!resource) return prevResource;

  return {
    ...prevResource,
    data: {
      ...prevResource.data,
      ...resource.data,
    },
    queries: {
      ...prevResource.queries,
      [getQueryKey(config)]: {
        data: resource.queryData.ordering,
        count: resource.queryData.count,
      },
    },
  };
};

const createResourceDeleter = (config, id) => (prevResource, resource) => {
  if (!prevResource) return resource;

  const { ...newData } = prevResource.data;
  delete newData[id];

  const newQueries = Object.entries(prevResource.queries).reduce(
    (res, [queryKey, { data, count }]) => {
      const idInData = data.includes(id);
      return {
        ...res,
        [queryKey]: {
          data: data.filter(dataId => dataId !== id),
          count: idInData ? count - 1 : count,
        },
      };
    },
    {},
  );

  return {
    ...prevResource,
    data: newData,
    queries: newQueries,
  };
};

// const createOptimisticUpdate = (
//   resourceName,
//   submitData,
//   objectIDToUpdate,
// ) => prevResource => {
//   let updatedItem = {
//     ...prevResource.data[objectIDToUpdate],
//     ...submitData,
//   };

//   const Class = typeMapping[resourceName];
//   if (Class) {
//     updatedItem = new Class(updatedItem);
//   }

//   return {
//     ...prevResource,
//     data: {
//       ...prevResource.data,
//       [objectIDToUpdate]: updatedItem,
//     },
//   };
// };

export const createResourceDetailQuery = (
  resourceName,
  config,
  idField = "uuid",
) => {
  const newConfig = createQueryConfig(config);
  const updater = createResourceUpdater(newConfig);
  return {
    ...newConfig,
    transform: json => {
      let transformedJson = json;

      if (!json) {
        return {};
      }

      const Class = typeMapping[resourceName];
      if (Class) {
        transformedJson = new Class(json);
      }

      return {
        [resourceName]: {
          data: {
            [json[idField]]: transformedJson,
          },
          queryData: {
            count: 1,
            ordering: [json[idField]],
          },
        },
      };
    },
    update: {
      [resourceName]: updater,
    },
  };
};

export const createResourceListQuery = (
  resourceName,
  config,
  idField = "uuid",
) => {
  const newConfig = createQueryConfig(config);
  const updater = createResourceUpdater(newConfig);
  return {
    ...newConfig,
    transform: json => {
      const { results } = json;
      let transformedResults = results;

      const Class = typeMapping[resourceName];
      if (Class) {
        transformedResults = results.map(data => new Class(data));
      }

      return {
        [resourceName]: {
          data: listToObject(transformedResults, idField),
          queryData: {
            count: json.count,
            ordering: json.results.map(j => j[idField]),
          },
        },
      };
    },
    update: {
      [resourceName]: updater,
    },
  };
};

export const mutateResourceQuery = (
  resourceName,
  config,
  data,
  // optimisticUpdateID = null,
) => {
  const query = createResourceDetailQuery(resourceName, config);

  if (data) {
    query.body = data;
  }
  // if (optimisticUpdateID) {
  //   query.optimisticUpdate = {
  //     [resourceName]: createOptimisticUpdate(
  //       resourceName,
  //       data,
  //       optimisticUpdateID,
  //     ),
  //   };
  // }
  return query;
};

export const deleteResourceQuery = (resourceName, config, deleteId) => {
  if (!deleteId) {
    throw new Error("Missing `deleteId` param in deleteResourceQuery");
  }
  const query = mutateResourceQuery(resourceName, config);
  const deleter = createResourceDeleter(config, deleteId);
  query.update = {
    [resourceName]: deleter,
  };
  return query;
};
