// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  guideBoxType: string,
};

const GuideBox: ComponentType<Props> = styled.div`
  z-index: 5;
  width: 100px;
  height: 50px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${({ guideBoxType }) =>
    guideBoxType === "dashed" &&
    css`
      border: 1px dashed ${({ theme }) => theme.colors.primary_turquoise()};
      transform: translate(-15px, 15px);
    `};
  ${({ guideBoxType, theme }) =>
    guideBoxType === "solid" &&
    css`
      background: ${theme.colors.background_lightgray()};
      border: 1px solid ${theme.colors.primary_black40()};
      border-radius: 5px;
      transform: translate(15px, -15px);
      box-shadow: 1px 1px 3px ${theme.colors.primary_black("25%")};
    `};
`;

export default GuideBox;
