import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Table } from "react-bootstrap";

import urls from "urls";
import { requestContractorAudits } from "admin/actions/contractorAudits";

class Index extends Component {
  componentDidMount() {
    this.props.dispatch(requestContractorAudits());
  }

  render() {
    const { contractorAudits, count, isLoading } = this.props;

    return (
      <Grid fluid={true}>
        <div style={{ marginBottom: "2rem" }}>
          <h1>Contractor Audits Dashboard</h1>
        </div>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <i className="far fa-cog fa-spin fa-2x" />
            &nbsp;Loading...
          </div>
        ) : (
          <div>
            <div style={{ marginBottom: "1rem" }}>{count} results</div>
            <Table>
              <thead>
                <tr>
                  <th />
                  <th>Admin</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Violations</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(contractorAudits)
                  .map(auditId => contractorAudits[auditId])
                  .map((audit, i) => (
                    <tr key={audit.uuid}>
                      <td>
                        <strong>{i + 1}</strong>
                      </td>
                      <td>
                        <a
                          href={
                            audit.is_attorney
                              ? urls["admin:attorney_attorney_change"](
                                  audit.uuid,
                                )
                              : urls["admin:contractor_contractor_change"](
                                  audit.uuid,
                                )
                          }
                        >
                          <i className="fas fa-external-link-alt" />
                        </a>
                      </td>
                      <td>
                        {audit.first_name} {audit.last_name}
                      </td>
                      <td>{audit.email_address}</td>
                      <td>{audit.all_violations.trim().slice(0, -1)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      </Grid>
    );
  }
}

Index.propTypes = {};

export default connect(state => state.admin.contractorAudits)(Index);
