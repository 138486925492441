import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { Alert, Button, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { addNotification as notify } from "reapop";
import { requestAsync } from "redux-query";

import { NumberField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";
import { Error } from "common/utils/forms/validators";
import {
  requestPaymentSources,
  submitVerifyBankAccount,
} from "hiringagent-dashboard/actions/payments";
import { agencyQuery } from "queries/requests/agencies";

const requiredFields = ["amount1", "amount2"];

const verificationSuccessMsg = {
  title: "Success",
  message: "Your bank account has been verified",
  status: "success",
  position: "tr",
  dismissible: true,
  dismissAfter: 3000,
};

const validate = values => {
  const errors = validateRequiredFields(requiredFields, values);
  const amountFields = ["amount1", "amount2"];

  Object.keys(values)
    .filter(k => amountFields.includes(k))
    .forEach(key => {
      const value = values[key];
      if (value.length > 2) {
        errors[key] = <Error>Must be 2 digits or less</Error>;
      } else if (value < 0) {
        errors[key] = <Error>Must be greater than 0</Error>;
      } else if (value > 99) {
        errors[key] = <Error>Must be less than 99</Error>;
      }
    });
  if (!values.paymentSource) {
    errors._error =
      "Unexpected error, please try refreshing the page. If this problem persists, contact support.";
  }
  return errors;
};

class VerifyBankDepositsForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const {
      notify,
      refreshAgency,
      refreshPaymentSources,
      submitForm,
    } = this.props;
    return submitForm(values)
      .then(({ json, response }) => {
        if (!response.ok) {
          let errors = {};
          if (json.hasOwnProperty("errors")) {
            errors._error = json.errors;
          } else {
            errors = json;
          }
          throw new SubmissionError(errors);
        }
        return json;
      })
      .then(() => {
        notify(verificationSuccessMsg);
        refreshPaymentSources();
        refreshAgency();
      });
  }

  render() {
    const {
      closeForm,
      error,
      handleSubmit,
      submitting,
      submitFailed,
    } = this.props;

    return (
      <Form horizontal onSubmit={handleSubmit(this.submit)}>
        {error && submitFailed && <Alert bsStyle="danger">{error}</Alert>}

        <Row>
          <Col md={4} mdOffset={2}>
            <NumberField
              name="amount1"
              label="First Amount:"
              addonLeft="$0."
              placeholder="00"
              min={0}
              max={99}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} mdOffset={2}>
            <NumberField
              name="amount2"
              label="Second Amount:"
              addonLeft="$0."
              placeholder="00"
              min={0}
              max={99}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ButtonToolbar className="pull-right">
              <Button bsStyle="link" onClick={closeForm}>
                Cancel
              </Button>
              <Button bsStyle="success" type="submit" disabled={submitting}>
                Verify
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    paymentSource:
      state.hiringAgentDashboard.payments.focusedSource &&
      state.hiringAgentDashboard.payments.focusedSource.id,
  },
});

VerifyBankDepositsForm = compose(
  connect(
    mapStateToProps,
    {
      notify,
      submitForm: data => submitVerifyBankAccount(data),
      refreshAgency: () => requestAsync(agencyQuery({ force: true })),
      refreshPaymentSources: () => requestPaymentSources(),
    },
  ),
  reduxForm({
    form: "verifyBankDeposits",
    validate,
  }),
)(VerifyBankDepositsForm);

export default VerifyBankDepositsForm;
