import React from "react";
import { Col, Table } from "react-bootstrap";

import { formatUSD, formatDuration } from "common/utils/helpers";

const InvoiceSummary = ({ invoice, paymentDetail }) => (
  <Col lg={7}>
    <div className="main-info-container invoice-table-container">
      <h2 className="main-info-title">Summary</h2>

      <h2 className="secondary-header">Contractor Fees</h2>
      <Table>
        <tbody>
          <tr>
            <td className="col-md-8">
              <strong>Contractor Rate</strong>
            </td>
            <td>
              {formatUSD(paymentDetail.rate)}
              {paymentDetail.payment_type === 0 && <span>/hr</span>}
            </td>
          </tr>
          {paymentDetail.overtime_after_hours_week > 0 && (
            <tr>
              <td>
                <strong>Overtime Rate</strong>
              </td>
              <td>
                {formatUSD(paymentDetail.overtime_rate)}/hr
                <br />
                <span className="small">
                  After {paymentDetail.overtime_after_hours_week} hours/week
                </span>
              </td>
            </tr>
          )}
          {paymentDetail.payment_type === 0 && (
            <tr>
              <td>
                <strong>Total Time</strong>
              </td>
              <td>{formatDuration(invoice.total_time)}</td>
            </tr>
          )}
          {invoice.expense_total > 0 && (
            <tr>
              <td>
                <strong>Total Expenses</strong>
              </td>
              <td>{formatUSD(invoice.expense_total)}</td>
            </tr>
          )}
          <tr>
            <td>
              <strong>Subtotal</strong>
            </td>
            <td>
              <strong>
                {formatUSD(invoice.subtotal)}
                {invoice.service === 1 && (
                  <span className="small">&nbsp;(tax exempt)</span>
                )}
              </strong>
            </td>
          </tr>
        </tbody>
      </Table>

      <h2 className="secondary-header">HAE Fees</h2>
      <Table>
        <tbody>
          <tr>
            <td className="col-md-8">Total Hire an Esquire service fees</td>
            <td>
              {formatUSD(invoice.total_hae_fees)}
              {invoice.service === 0 && (
                <div>
                  <span className="small">Rate {invoice.hae_1099_rate}</span>
                  {invoice.hae_fixed_fee_per_entry > 0 && (
                    <div>
                      <br />
                      <span className="small">
                        {`${invoice.hae_fixed_fee_per_entry}/entry`}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      {invoice.service !== 0 && (
        <div>
          <h2 className="secondary-header">Sales Tax</h2>
          <Table>
            <tbody>
              <tr>
                <td className="col-md-8">
                  <strong>
                    {invoice.total_sales_tax_rate}% state and local sales tax
                  </strong>
                </td>
                <td>{formatUSD(invoice.total_sales_tax_amount)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      <Table>
        <tbody>
          {invoice.adjustment_total && (
            <tr>
              <td className="col-md-8">Adjustments</td>
              <td>{formatUSD(invoice.adjustment_total)}</td>
            </tr>
          )}
          {invoice.credit_remaining > 0 && (
            <tr>
              <td className="col-md-8">Credit Remaining</td>
              <td>{formatUSD(invoice.credit_remaining)}</td>
            </tr>
          )}
          {invoice.paid_in_full ? (
            <tr>
              <td className="col-md-8">
                <h4 className="text-success">
                  <strong>Total Due</strong>
                </h4>
              </td>
              <td>
                <h4 className="text-success">
                  <strong>{formatUSD(invoice.total)}</strong>
                </h4>
              </td>
            </tr>
          ) : (
            <tr>
              <td className="col-md-8">
                <h4 className="text-danger">
                  <strong>Total Due</strong>
                </h4>
              </td>
              <td>
                <h4 className="text-danger">
                  <strong>{formatUSD(invoice.total)}</strong>
                </h4>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  </Col>
);

export default InvoiceSummary;
