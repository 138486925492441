import React, { Component, Fragment } from "react";
import { createStructuredSelector } from "reselect";
import { reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { addNotification as notify } from "reapop";

import { history as browserHistory } from "app";
import { compose, connect, connectRequest } from "queries/utils";
import { messagesSliceSelector } from "hiringagent-dashboard/selectors";
import {
  withAgency,
  withContractorsIfNeeded,
} from "hiringagent-dashboard/connectors";
import { withMetadata } from "common/connectors";
import { submitNewMessage } from "hiringagent-dashboard/actions/messages";
import { TextAreaField } from "common/components/forms/inputs";
import { Loading } from "common/components";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import {
  createResourceDetailQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";

const formName = "createInviteMessageForm";
const successMessage = createSuccessNotification({ message: "Invite sent." });
const errorMessage = createErrorNotification();

const MessageSection = ({ children, error, job }) => (
  <div className="message-section">
    <div>
      <div className="subsection-first-line">Hi!</div>
      <div style={{ marginBottom: "10px" }}>
        {`I’ve reviewed your qualifications and think you would be an excellent fit for one of our available roles: "${job.title}."`}
      </div>
    </div>
    {children}
    {error && (
      <Alert bsStyle="danger" style={{ textAlign: "center" }}>
        <strong>{error}</strong>
      </Alert>
    )}
    <div>
      <div className="subsection-first-line">
        <h2>
          <strong>
            To apply, please click on the link,{" "}
            <Link to={`/public/jobs/${job.joblisting}/`}>here.</Link>
          </strong>
        </h2>
      </div>
      <div>Have a great day!</div>
      <div style={{ marginTop: "5px" }}>
        <strong>
          ** Replies to this message will not suffice as an application. Please
          apply via the link provided above.
        </strong>
      </div>
    </div>
  </div>
);

class InviteToContractor extends Component {
  // TODO: determine if contractor should be routed to job or joblisting
  _getBuiltUrl = job => {
    const {
      metadata: {
        data: { site_url },
      },
    } = this.props;
    return `${site_url}/contractor/jobs/${job.uuid}/`;
  };

  _getCompleteBody = (body, job) => {
    const formattedAdditionalBody = body ? `\n\n${body}` : "";
    const jobUrl = this._getBuiltUrl(job);
    const preBody = `Hi!\n\nI’ve reviewed your qualifications and think you would be an excellent fit
            for one of our available roles: "${job.title}".`;
    const postBody =
      `\n\n ## **To apply, please click on the link [here](${jobUrl}).**\n\nHave a great day!\n\n` +
      "** **Replies to this message will not suffice as an application. Please apply via the link provided above.**";

    return `${preBody}${formattedAdditionalBody}${postBody}`;
  };

  _getBuiltData = values => {
    const {
      requiredContractors,
      [types.JOBS]: {
        data: jobs,
        query: { data: jobsQueryData },
      },
    } = this.props;

    const job = jobs[jobsQueryData[0]];
    const recipientIds = requiredContractors.map(
      recipient => recipient.haeuser_uuid,
    );

    return {
      recipients: recipientIds,
      subject: `You have been invited to apply for the job "${job.title}".`,
      body: this._getCompleteBody(values.body, job),
      job: job.uuid,
    };
  };

  submit = (values, dispatch) => {
    const data = this._getBuiltData(values);

    return dispatch(submitNewMessage(data)).then(({ json, response }) => {
      if (!response.ok) {
        this.props.notify(errorMessage);
        throw new SubmissionError(json);
      } else {
        this.props.notify(successMessage);
        browserHistory.push("/agency/messages/");
        return json;
      }
    });
  };

  render() {
    const {
      requiredContractors,
      error,
      submitting,
      handleSubmit,
      [types.JOBS]: {
        data: jobs,
        query: { data: jobsQueryData },
        isPending,
        isFinished,
      },
    } = this.props;

    const job = jobs[jobsQueryData[0]];

    const recipientsCount = requiredContractors.length;
    const formattedAttorneyText =
      recipientsCount > 1 ? "contractors" : "contractor";

    const recipientsDisplay = requiredContractors.map(recipient => (
      <LinkContainer
        key={recipient.uuid}
        to={`/agency/attorneys/${recipient.uuid}/`}
      >
        <Button bsStyle="primary" bsSize="sm">
          {recipient.full_name}
        </Button>
      </LinkContainer>
    ));

    const noRecipientsAlertDisplay = (
      <div style={{ textAlign: "center" }}>
        <h3 style={{ margin: "4rem" }}>No Recipients Selected</h3>
        <h4>
          You can select a recipient in the action dropdown on a Candidate
          Profile page.
        </h4>
      </div>
    );

    return (
      <Fragment>
        {isFinished && (
          <div className="messages-form create-invite-message-form">
            <h1>
              <i className={"far fa-paper-plane"} />
              &nbsp;Invite to Apply
            </h1>
            {recipientsCount ? (
              <Row>
                <Col lg={10} lgOffset={1}>
                  <Row>
                    <div className="invite-info-headers">
                      <Col lg={6}>
                        <div className="recipients-container">
                          <h3>To: </h3>
                          {recipientsDisplay}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="invite-status-header">
                          <span>
                            <p>{`Inviting ${recipientsCount} ${formattedAttorneyText} to `}</p>{" "}
                            <Link to={`/agency/jobs/${job.uuid}/`}>
                              {job.title}
                            </Link>
                          </span>
                        </div>
                      </Col>
                    </div>
                  </Row>
                  <div className="form-divider" />
                  <form onSubmit={handleSubmit(this.submit)}>
                    <label className="control-label">Message</label>
                    <MessageSection error={error} job={job}>
                      <TextAreaField
                        name="body"
                        placeholder="Additional text (optional)"
                        style={{ marginBottom: "0" }}
                      />
                    </MessageSection>
                    <div className="invite-form-buttons">
                      <Link to={"/agency/"}>
                        <Button bsSize="sm">Cancel</Button>
                      </Link>
                      <Button
                        type="submit"
                        bsStyle="info"
                        className="pull-right"
                        disabled={submitting}
                      >
                        {submitting ? (
                          <span>
                            <i className="far fa-fw fa-cog fa-spin" /> Sending
                          </span>
                        ) : (
                          <span>
                            <i className="far fa-fw fa-reply" /> Invite
                          </span>
                        )}
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            ) : (
              <div>{noRecipientsAlertDisplay}</div>
            )}
          </div>
        )}
        {isPending && <Loading />}
      </Fragment>
    );
  }
}

const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.jobId}/`,
  });

const mapPropsToConfig = props => [jobQuery(props)];

const jobsConfig = createResourceSelectorConfig(types.JOBS, jobQuery);

const mapJobStateToProps = createStructuredSelector({
  ...jobsConfig,
});

export default compose(
  connect(mapJobStateToProps),
  connectRequest(mapPropsToConfig),
  connect(
    messagesSliceSelector,
    { notify },
  ),
  withContractorsIfNeeded(props => props.messages.messageRecipientIds),
  withAgency(),
  withMetadata(),
  reduxForm({ form: formName, enableReinitialize: true }),
)(InviteToContractor);
