import actions from "hiringagent-dashboard/actions/auth";
import { handleActions } from "redux-actions";

const initialState = {
  authenticated: false,
  isLoading: false,
  lastUpdated: null,
};

const auth = handleActions(
  {
    [actions.REQUEST_AUTH_STATUS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_AUTH_STATUS]: (state, action) => ({
      ...state,
      authenticated: !action.error,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.REQUEST_LOGOUT]: state => ({
      ...state,
      authenticated: false,
    }),
  },
  initialState,
);
export default auth;
