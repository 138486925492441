import React, { Component } from "react";

import moment from "moment";

export default class TimeSinceUpdate extends Component {
  constructor(props) {
    super(props);
    this._interval = null;
    this.state = {
      lastUpdatedText: null,
    };
  }

  componentDidMount() {
    this._interval = setInterval(
      () => this.updateTimeSinceLoad(this.props),
      10000,
    );
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateTimeSinceLoad(nextProps);
  }

  updateTimeSinceLoad(props) {
    if (props.lastUpdated) {
      this.setState({
        lastUpdatedText: moment(props.lastUpdated).fromNow(),
      });
    }
  }

  render() {
    const lastUpdatedText = this.state.lastUpdatedText;
    return (
      lastUpdatedText && (
        <span>
          Last updated {lastUpdatedText} (
          <a href="" onClick={e => this.onRefreshClick(e)}>
            refresh
          </a>
          )
        </span>
      )
    );
  }

  onRefreshClick(e) {
    return this.props.onRefresh(e);
  }
}
