import { fetchEventsIfNeeded } from "admin/actions/invoicing/jobEvents";
import { fetchOpenInvoicesIfNeeded } from "admin/actions/invoicing/invoices";

export const SET_INITIAL_DATES = "SET_INITIAL_DATES";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";

export function setInitialDates(startDate, endDate) {
  return dispatch => {
    dispatch(_setInitialDates(startDate, endDate));
    return dispatch(fetchEventsIfNeeded());
  };
}

export function _setInitialDates(startDate, endDate) {
  return {
    type: SET_INITIAL_DATES,
    startDate,
    endDate,
  };
}

export function setStartDate(startDate) {
  return dispatch => {
    dispatch(_setStartDate(startDate));
    dispatch(fetchOpenInvoicesIfNeeded());
    return dispatch(fetchEventsIfNeeded());
  };
}

export function setEndDate(endDate) {
  return dispatch => {
    dispatch(_setEndDate(endDate));
    dispatch(fetchOpenInvoicesIfNeeded());
    return dispatch(fetchEventsIfNeeded());
  };
}

export function _setStartDate(startDate) {
  return {
    type: SET_START_DATE,
    startDate,
  };
}

export function _setEndDate(endDate) {
  return {
    type: SET_END_DATE,
    endDate,
  };
}
