import types from "resources/types";
import { mutateResourceQuery } from "queries";

export const create = data =>
  mutateResourceQuery(
    types.JOB_OFFERS,
    {
      url: "/api/v2/job_offers/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const update = (offerId, data) =>
  mutateResourceQuery(
    types.JOB_OFFERS,
    {
      url: `/api/v2/job_offers/${offerId}/`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );
