import React, { Component, Fragment } from "react";
import { initialize, reduxForm, SubmissionError } from "redux-form";
import {
  Alert,
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  Modal,
} from "react-bootstrap";
import styled from "styled-components";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { addNotification } from "reapop";

import { compose, connect, mutateAsync } from "queries/utils";
import { TextAreaField, TextField } from "common/components/forms/inputs";
import CopyToClipboardComponent from "./CopyToClipboardComponent";
import { sendReferralCodeEmailQuery } from "queries/mutations/discountCodes";
import { createSuccessNotification } from "common/utils/notifications";
import PrimaryButton, { PrimaryFormButton } from "common/blocks/PrimaryButton";
import { validateRequiredFields } from "common/utils/forms";

const formName = "referralCodeEmailForm";

const validate = values => validateRequiredFields(["body", "subject"], values);

const EmailShareButton = styled.button`
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  background: none;
  outline: none;
  border: none;
`;

const MultiEmailField = ({ emails, onChange }) => (
  <FormGroup>
    <ControlLabel>Recipient(s)</ControlLabel>
    <ReactMultiEmail
      placeholder={"Enter email addresses separated by commas or spaces."}
      emails={emails}
      onChange={emails => onChange(emails)}
      validateEmail={email => isEmail(email)}
      getLabel={(email, index, removeEmail) => (
        <div data-tag key={index}>
          {email}
          <span data-tag-handle onClick={() => removeEmail(index)}>
            ×
          </span>
        </div>
      )}
    />
  </FormGroup>
);

let EmailReferralForm = ({
  emails,
  error,
  handleSubmit,
  onChangeEmails,
  onCloseModal,
  submitting,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <MultiEmailField emails={emails} onChange={onChangeEmails} />
    <TextField
      name="subject"
      label="Subject"
      placeholder={"This is a text field placeholder."}
    />
    <TextAreaField
      name="body"
      label="Body"
      placeholder={"This is a text area field placeholder."}
      rows={12}
    />
    <ButtonToolbar>
      <PrimaryFormButton
        action="send"
        bsStyle="primary"
        disabled={submitting}
        submitting={submitting}
      />
      <PrimaryButton bsStyle="default" onClick={onCloseModal}>
        Close
      </PrimaryButton>
    </ButtonToolbar>
  </form>
);

const mapStateToProps = (state, { agentReferralCode }) => ({
  initialValues: {
    subject: "Here's a $100 credit to Hire an Esquire from me.",
    body: `I’ve been using Hire an Esquire to hire freelance/contract attorneys and paralegals and thought you’d like it too.

The platform instantly matches you with the best fit for your role based on expertise and soft skills from their pre-vetted national network of 12,000 attorneys and paralegals.

They’re the largest, most established freelance legal marketplace and the only one with full staffing agency functionality and compliance.

Sign up with this link and we’ll both get $100 off our next project:
${window.location.origin}/agency/signup/${agentReferralCode}`,
  },
});

EmailReferralForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    validate,
  }),
)(EmailReferralForm);

class ShareSection extends Component {
  constructor(props) {
    super(props);
    this.state = { emails: [], showModal: false };
  }

  handleSubmit = async values => {
    const {
      state: { emails },
      props: { initializeForm, notify, sendReferralCodeEmail },
      handleCloseModal,
    } = this;
    const submitValues = { to: emails, ...values };

    const { body, status } = await sendReferralCodeEmail(submitValues);

    if (status >= 200 && status < 300) {
      notify(
        createSuccessNotification({
          message: "Your referral code email has been sent!",
        }),
      );
      initializeForm();
      handleCloseModal();
    } else {
      if (body.hasOwnProperty("non_field_errors")) {
        body._error = body.non_field_errors;
        delete body.non_field_errors;
      }
      if (body.hasOwnProperty("to")) {
        body._error = body.to;
        delete body.to;
      }
      throw new SubmissionError(body);
    }
  };

  handleChangeEmails = emails => this.setState({ emails });

  handleShowModal = () => this.setState({ showModal: true });

  handleCloseModal = () => this.setState({ emails: [], showModal: false });

  render() {
    const {
      props: { agentReferralCode, url },
      state: { emails },
      handleChangeEmails,
      handleCloseModal,
      handleSubmit,
    } = this;

    return (
      <Fragment>
        <h2>Share</h2>
        <Alert style={{ marginBottom: "2rem" }}>
          <strong>Share Hire an Esquire with your network:</strong> When someone
          else looking to hire signs up using your referral code or link,
          you&apos;ll each receive a $100 credit after they generate their first
          invoice.
        </Alert>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%", maxWidth: "500px" }}>
            <CopyToClipboardComponent
              label="Referral Code:"
              value={agentReferralCode}
            />
            <br />
            <CopyToClipboardComponent label="Referral Link:" value={url} />
            <br />
            <center>
              <EmailShareButton onClick={this.handleShowModal}>
                <i className="far fa-envelope" /> Send Via Email
              </EmailShareButton>
            </center>
          </div>
        </div>
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Referral Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EmailReferralForm
              agentReferralCode={agentReferralCode}
              emails={emails}
              onChangeEmails={handleChangeEmails}
              onCloseModal={handleCloseModal}
              onSubmit={handleSubmit}
            />
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  initializeForm: () => dispatch(initialize(formName)),
  sendReferralCodeEmail: data =>
    dispatch(mutateAsync(sendReferralCodeEmailQuery(data))),
  notify: fn => dispatch(addNotification(fn)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(ShareSection);
