import React, { Component } from "react";
import { reduxForm } from "redux-form";

import FormButton from "common/components/FormButton";
import {
  CheckboxField,
  SelectField,
  TextAreaField,
} from "common/components/forms/inputs";

export const formName = "availabilityForm";
export const requiredFields = [
  "hours_per_week",
  "available_weekdays",
  "available_weekends",
  "available_evenings",
];

// TODO: Retrieve from server or js fixture/constant
export const hoursPerWeekOptions = [
  { label: "0 hours (not available)", value: "0" },
  { label: "0-10 hours", value: "1" },
  { label: "10-20 hours", value: "2" },
  { label: "20-40 hours", value: "3" },
  { label: "40 hours (full time)", value: "4" },
];

class AvailabilityForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <SelectField
          name="hours_per_week"
          label="Number of hours available per week"
        >
          {hoursPerWeekOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectField>
        <CheckboxField
          name="available_weekdays"
          label="Available to work on weekdays"
        />
        <CheckboxField
          name="available_weekends"
          label="Available to work on weekends"
        />
        <CheckboxField
          name="available_evenings"
          label="Available to work evenings"
        />
        <TextAreaField
          name="notes"
          label="Additional notes or availability limitations"
          maxLength={256}
        />
        <div className="text-right">
          <FormButton
            className="text-right"
            action="save"
            submitting={submitting}
            disabled={pristine || submitting || !valid}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  initialValues: {
    available_weekdays: false,
    available_weekends: false,
    available_evenings: false,
  },
})(AvailabilityForm);
