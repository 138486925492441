import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import api from "common/api";

const actions = keyMirror({
  REQUEST_PENDING_TIME_ENTRIES: null,
  RECEIVE_PENDING_TIME_ENTRIES: null,
  SET_PENDING_ENTRIES_SORTING: null,
});
export default actions;

export const requestPendingTimeEntries = (jobId = null) =>
  api.get(`/api/dev/time_entries/?job=${jobId}&not_invoiced=true&limit=9999`, [
    actions.REQUEST_PENDING_TIME_ENTRIES,
    actions.RECEIVE_PENDING_TIME_ENTRIES,
  ]);

export const setSortingBy = createAction(actions.SET_PENDING_ENTRIES_SORTING);
