import * as Sentry from "@sentry/browser";

export const initSentry = () => {
  let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || "local";
  if (environment !== "production") return;
  Sentry.init({
    dsn: "https://63360d6db5d34608b58b7a9577b16257@sentry.io/1200572",
    environment: environment,
    maxBreadcrumbs: 25, // This was set to 250, but was causing 413 response from Sentry (request too large)
    debug: environment.substr(0, 5) === "local",
  });
};
