import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connectRequest } from "redux-query-react";
import { connect, compose } from "resources/components/utils";

import Loading from "common/components/Loading";
import NotFound from "common/components/NotFound";
import { TimeEntries } from "contractor/components/time-tracker";

import {
  createResourceListQuery,
  createResourceSelectorConfig,
  createResourceDetailQuery,
} from "queries";
import types from "resources/types";

class TimeEntriesTracker extends Component {
  render() {
    const {
      [types.JOBS]: {
        data: jobs,
        query: {
          data: [jobQueryData],
        },
        isFinished: jobReady,
      },
      [types.JOB_WORKERS]: {
        data: jobWorkers,
        query: {
          data: [jobWorkerQueryData],
        },
        isFinished: jobWorkerReady,
      },
    } = this.props;

    const job = jobs[jobQueryData];
    const worker = jobWorkers[jobWorkerQueryData];

    const isReady = jobWorkerReady && jobReady;

    const timeTrackerDisplay =
      worker && job ? (
        <TimeEntries job={job} worker={worker} />
      ) : (
        <NotFound to={"/contractor/"} />
      );

    return (
      <div className="time-entries-tracker">
        {isReady ? timeTrackerDisplay : <Loading />}
      </div>
    );
  }
}

const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });

const jobWorkerQuery = props =>
  createResourceListQuery(types.JOB_WORKERS, {
    url: `/api/v2/job_workers/?job=${props.match.params.id}`,
  });

const mapPropsToConfig = props => [jobQuery(props), jobWorkerQuery(props)];

const jobConfig = createResourceSelectorConfig(types.JOBS, jobQuery);
const jobWorkerConfig = createResourceSelectorConfig(
  types.JOB_WORKERS,
  jobWorkerQuery,
);

const mapStateToProps = createStructuredSelector({
  ...jobConfig,
  ...jobWorkerConfig,
});

export default (TimeEntriesTracker = compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(TimeEntriesTracker));
