import React from "react";

export const LoadingIcon = ({ className = "" }) => (
  <i className={`far fa-cog fa-spin ${className}`} />
);
const Loading = ({ className = "", style = {}, showText = true }) => (
  <div style={{ textAlign: "center", fontSize: "20px", ...style }}>
    <LoadingIcon className={className} />
    &nbsp;
    {showText && "Loading..."}
  </div>
);

export default Loading;
