import types from "resources/types";
import { createResourceListQuery } from "queries";

export const promoCodeRedemptionsQuery = (config = {}) =>
  createResourceListQuery(types.PROMO_CODE_REDEMPTIONS, {
    url: "/api/v2/promo_code_redemptions/",
    ...config,
  });

export const referralCodeRedemptionsQuery = (config = {}) =>
  createResourceListQuery(types.REFERRAL_CODE_REDEMPTIONS, {
    url: "/api/v2/referral_code_redemptions/",
    ...config,
  });
