import React from "react";
import ContractorProfile from "hiringagent-dashboard/components/contractor/ContractorProfile";

const PublicContractorProfile = props => (
  <div className="public-profile">
    <ContractorProfile isPublicProfile={true} {...props} />
  </div>
);

export default PublicContractorProfile;
