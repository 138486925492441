import React from "react";
import styled, { css } from "styled-components";
import { Table as BootstrapTable } from "react-bootstrap";

export const TableRow = styled(({ candidate, ...props }) => <tr {...props} />)`
  ${({ candidate, theme }) => {
    if (candidate.status === 0) {
      return theme.media.sm`
        td:first-child::before {
          content: "•";
          position: absolute;
          left: -10px;
          color: ${theme.colors.danger()};
        }
      `;
    }
  }};
`;

export const MobileActionsTableRow = styled.tr`
  /* Bootstrap override */
  && td {
    border-top: none;
  }
`;

export const TableContainer = styled.div`
  position: relative;
  ${({ showViewAllButton }) => {
    if (showViewAllButton)
      return css`
        margin-bottom: 55px;
        ${({ theme }) => theme.media.sm`
          margin-bottom: 30px;
        `};
      `;
    return css`
      margin-bottom: 20px;
    `;
  }};
`;

const Table = styled(({ isDesktop, ...props }) => (
  <BootstrapTable {...props} />
)).attrs(({ isDesktop }) => ({
  striped: isDesktop,
}))`
  /* Fixed table layout works for mobile and large screens, but is not ideal for
  small & medium screens because the button column is too narrow so the buttons
  wrap to a new line. For these screens, the Bootstrap columns classes will make
  sure there is enough width on those table columns. */

  table-layout: fixed;

  ${({ theme }) => theme.media.sm`
    table-layout: auto;
  `};

  ${({ theme }) => theme.media.lg`
    table-layout: fixed;
  `};

  thead {
    tr {
      th:not(:first-child) {
        text-align: center;
      }
      th:last-child {
        text-align: right;
      }
      th {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  tbody {
    font-size: 16px;
    tr {
      td:not(:first-child) {
        text-align: center;
      }
      td:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      td:last-child {
        text-align: right;
      }
    }
  }

  a.btn {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    max-width: 150px;

    ${({ theme }) => theme.media.sm`
      padding: 1px 7px;

      & + .btn {
        margin-left: 5px;
      }
    `};
  }
`;

export default Table;
