import types from "resources/types";
import { mutateResourceQuery } from "queries";
import { getCookie } from "common/utils";

export const stashProfilePictureQuery = data =>
  mutateResourceQuery(
    types.STASH_PROFILE_PICTURE,
    {
      url: "/api/dev/contractors/stash_profile_picture/",
      options: {
        headers: {
          Accept: "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
        method: "POST",
      },
    },
    data,
  );

export const addProfilePictureQuery = data =>
  mutateResourceQuery(
    types.ADD_PROFILE_PICTURE,
    {
      url: `/api/dev/contractors/add_profile_picture/`,
      options: {
        headers: {
          Accept: "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
        method: "POST",
      },
    },
    data,
  );

export const bypassProfilePictureQuery = () =>
  mutateResourceQuery(types.BYPASS, {
    url: "/api/dev/contractors/onboarding_bypass_profile_picture/",
    options: {
      method: "POST",
    },
  });
