import React, { Component } from "react";
import PropTypes from "prop-types";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Col, Row } from "react-bootstrap";

import RecommendationActionButtons from "hiringagent-dashboard/components/recommendations/RecommendationActionButtons";

export default class JobRecommendationNavigation extends Component {
  static propTypes = {
    onReload: PropTypes.func.isRequired,
    recsForJob: PropTypes.array.isRequired,
    recommendation: PropTypes.object.isRequired,
    queryString: PropTypes.string.isRequired,
  };

  createRecommendationLink(recommendation) {
    const { queryString } = this.props;
    return `/agency/jobs/create/${recommendation.job}/recommended_candidates/${
      recommendation.contractor.uuid
    }/${queryString}`;
  }

  render() {
    const { onReload, recsForJob, recommendation } = this.props;
    const recommendationContractorIds = recsForJob.map(r => r.contractor.uuid);

    const currentIndex = recommendationContractorIds.indexOf(
      recommendation.contractor.uuid,
    );
    const previousIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;

    const previousRecommendation =
      previousIndex >= 0 && recsForJob[previousIndex];
    const nextRecommendation =
      nextIndex <= recommendationContractorIds.length - 1 &&
      recsForJob[nextIndex];

    const invitedOrDismissed =
      recommendation.invited || recommendation.dismissed || false;

    return (
      <Row>
        <div style={{ height: "49px" }}>
          <Col xs={2}>
            {previousRecommendation && (
              <LinkContainer
                to={this.createRecommendationLink(previousRecommendation)}
              >
                <Button bsStyle="info">
                  <i className="far fa-chevron-left" aria-hidden="true" />
                  <span className="hidden-xs" style={{ marginLeft: "1rem" }}>
                    PREVIOUS
                  </span>
                </Button>
              </LinkContainer>
            )}
          </Col>
          <Col xs={8}>
            <RecommendationActionButtons
              onReload={onReload}
              recommendation={recommendation}
              invitedOrDismissed={invitedOrDismissed}
            />
          </Col>
          <Col xs={2}>
            {nextRecommendation && (
              <LinkContainer
                to={this.createRecommendationLink(nextRecommendation)}
              >
                <Button bsStyle="info" className="pull-right">
                  <span className="hidden-xs" style={{ marginRight: "1rem" }}>
                    NEXT
                  </span>
                  <i className="far fa-chevron-right" aria-hidden="true" />
                </Button>
              </LinkContainer>
            )}
          </Col>
        </div>
      </Row>
    );
  }
}
