import React, { Component } from "react";
import { Label, Table } from "react-bootstrap";
import moment from "moment";

import { recentFirst } from "contractor/components/utils/timeTracker";

class FixedRateOccurrencesList extends Component {
  render() {
    const { occurrences: entries } = this.props;
    const numOfEntries = Object.keys(entries).length;

    return (
      <div className="fixed-rate-entries">
        <Table responsive>
          <tbody>
            {numOfEntries > 0 &&
              Object.values(entries)
                .sort(recentFirst)
                .map((entry, key) => (
                  <tr key={entry.uuid}>
                    <td>
                      <div className="text-center">
                        <div className="entry-number">
                          {`# ${numOfEntries - key}`}
                        </div>
                        <Label bsStyle="info">
                          {moment(entry.date).format("ll")}
                        </Label>
                      </div>
                    </td>
                    <td className="event-attr-titles">
                      <div>Submitted</div>
                    </td>
                    <td className="event-attr">
                      <div>{moment(entry.created).format("lll")}</div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
        {numOfEntries === 0 && (
          <div className="no-content-message">No Events</div>
        )}
      </div>
    );
  }
}

export default FixedRateOccurrencesList;
