import React, { Component } from "react";
import { Field } from "redux-form";
import { Col, Button, Row } from "react-bootstrap";

import { Error } from "common/utils/forms/validators";
import { OpacityTransition } from "common/utils/transitions";
import DeleteButton from "common/components/DeleteButton";
import { BarRequirementSelect } from "hiringagent-dashboard/components/jobs/create-job-listing/inputs";

const BarRequirementField = ({ input, onDelete }) => (
  <Row>
    <Col xs={10}>
      <BarRequirementSelect name={input.name} />
    </Col>
    <Col xs={2}>
      <DeleteButton onDelete={onDelete} />
    </Col>
  </Row>
);

class BarRequirementsFieldArray extends Component {
  handleAddBarReq = () => {
    this.props.fields.push();
  };

  handleDeleteBarReq = index => () => {
    this.props.fields.remove(index);
  };

  render() {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <OpacityTransition
          className="list-inline"
          component="ul"
          transitionLeave={false}
        >
          {this.props.fields.map((fieldName, idx) => (
            <Field
              key={fieldName}
              name={fieldName}
              component={BarRequirementField}
              onDelete={this.handleDeleteBarReq(idx)}
            />
          ))}
        </OpacityTransition>
        <div className="text-center">
          <Button onClick={this.handleAddBarReq} bsStyle="success">
            <i className="far fa-plus" />&nbsp;Add State Bar Requirement
          </Button>
        </div>
      </div>
    );
  }
}

BarRequirementsFieldArray.validate = (values, errors = {}) => {
  const { bar_requirements } = values;
  const barRequirements = bar_requirements || [];
  const barReqArrayErrors = [];

  barRequirements.forEach((currentReq, idx) => {
    if (!currentReq) {
      barReqArrayErrors[idx] = <Error>Required</Error>;
    } else {
      const isBarReqDuplicated =
        barRequirements.filter(entry => entry === currentReq).length > 1;
      if (isBarReqDuplicated) {
        barReqArrayErrors[idx] = <Error>State already added</Error>;
      }
    }
  });

  return {
    ...errors,
    ...(barReqArrayErrors.length && {
      bar_requirements: barReqArrayErrors,
    }),
  };
};

export default BarRequirementsFieldArray;
