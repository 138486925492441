// @flow strict
import styled from "styled-components";

const Info = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  ${({ theme }) => theme.media.desktop`
    grid-column: 1 / 2;
    grid-row: 2 / 3
  `};
`;

export default Info;
