import { campaignParams } from "common/constants";
import querystring from "querystring";
import { localStorage } from "common/utils/helpers";

export const getAuthHeader = () => {
  const key = JSON.parse(localStorage.getItem("key"));
  return key ? { Authorization: `Bearer ${key}` } : {};
};

export const getCampaignParamsFromLocalStorage = () =>
  campaignParams.reduce(
    (res, val) => ({
      ...res,
      [val]: localStorage.getItem(val),
    }),
    {},
  );

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const getUrlParamOrCookie = name => {
  const urlParams = querystring.parse(window.location.search.substr(1));
  return urlParams[name] || getCookie(name);
};
