import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

// This component can be passed as a label of a FormControlGroup
const FormFieldToolTip = ({
  title = null,
  content,
  placement = "right",
  label = null,
}) => {
  const overlay = (
    <Popover id={`popover-${title}`} title={title}>
      {content}
    </Popover>
  );

  return (
    <span>
      <OverlayTrigger
        trigger={["hover", "click"]}
        placement={placement}
        overlay={overlay}
      >
        <i className="far fa-info-circle" />
      </OverlayTrigger>
      &nbsp;{label}
    </span>
  );
};

export default FormFieldToolTip;
