import React from "react";

const JobDetailsRow = ({ title, children, ...otherProps }) => (
  <div
    className="job-details-row clearfix"
    style={{ marginBottom: "1rem" }}
    {...otherProps}
  >
    <div>
      <strong>{title}</strong>
    </div>
    <div>{children}</div>
  </div>
);

export default JobDetailsRow;
