import styled from "styled-components";

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
  margin: 0;
  padding: 0px 20px;
`;

export default Main;
