// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {
  numFulfilled: number,
  nuRequired: number,
};

const Heading: ComponentType<Props> = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid;
  background-color: ${({ numFulfilled, numRequired, theme }) => {
    if (numFulfilled === numRequired) return theme.colors.success("0.2");
    if (numFulfilled === 0) return theme.colors.danger("0.2");
    return theme.colors.primary_black10();
  }};
  border-color: ${({ numFulfilled, numRequired, theme }) => {
    if (numFulfilled === numRequired) return theme.colors.success("0.5");
    if (numFulfilled === 0) return theme.colors.danger("0.5");
    return theme.colors.background_gray();
  }};

  ${({ theme }) => theme.media.mobile`
    padding: 10px 10px 0 10px;
    border-radius: 0;
    border: none;
    background-color: transparent;
  `};
`;

export default Heading;
