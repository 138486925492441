import styled from "styled-components";

const CollapseBase = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.container_bg()};
  border-radius: ${({ isOpen }) => (isOpen ? "8px 8px 0 0" : "8px")};
`;

export default CollapseBase;
