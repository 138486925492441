import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "resources/components/utils";
import { SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import { validateRequiredFields } from "common/utils/forms";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import { update } from "resources/actions/ownContractor";

class EditContractor extends Component {
  static propTypes = {
    contractorId: PropTypes.string.isRequired,
    formComponentClass: PropTypes.func.isRequired,
    title: PropTypes.string,
    transformValues: PropTypes.func,
    validate: PropTypes.func,
    requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    title: "Profile",
  };

  validate = values =>
    validateRequiredFields(this.props.requiredFields, values);

  handleSubmit = values => {
    const { notify, contractorId, title, transformValues } = this.props;
    const valuesToSubmit = transformValues ? transformValues(values) : values;

    return this.props
      .onSubmit(contractorId, valuesToSubmit)
      .then(({ json, response }) => {
        if (!response.ok) {
          notify(
            createErrorNotification({
              message: json.detail || "Please correct the errors below.",
            }),
          );
          throw new SubmissionError(json);
        }
        notify(
          createSuccessNotification({ message: `${title} has been saved.` }),
        );
      });
  };

  render() {
    const {
      formComponentClass: FormWrapper,
      initialValues,
      title,
    } = this.props;

    return (
      <div>
        <h2 className="edit-section-title">{title}</h2>
        <FormWrapper
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validate={this.props.validate || this.validate}
        />
      </div>
    );
  }
}

export default (EditContractor = connect(
  null,
  {
    onSubmit: update,
    notify,
  },
)(EditContractor));
