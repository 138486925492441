// @flow strict
import React, { Component, Fragment } from "react";
// $FlowFixMe 'Cannot resolve module redux-form.'
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, type Dispatch as ReduxDispatch } from "redux";
import { mutateAsync } from "redux-query";
import { connectRequest } from "redux-query-react";
import { addNotification } from "reapop";
import { createStructuredSelector } from "reselect";

import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import { DragAndDrop } from "hiringagent-dashboard/components";
import { OptionalFormMessage, withFormError } from "./utils.js";
import { update } from "queries/mutations/job-listings";
import types from "resources/types";
import { areEqualArrays } from "common/utils";

const selectedMax = 5;

type PredictorStatements = Array<{
  uuid: Uuid,
  description: string,
  core: boolean,
}>;

type Props = {
  formProps: {
    handleSubmit: () => void,
    onSubmit: () => void,
  },
  notify: string => void,
  submitFormData: (Array<string>) => Promise<Response>,
};

type State = {
  all: PredictorStatements,
  locked: PredictorStatements,
  selected: PredictorStatements,
  unselected: PredictorStatements,
};

class JobAnalysisForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { all: [], locked: [], selected: [], unselected: [] };
  }

  static getDerivedStateFromProps(props, state) {
    const allObj = props.predictorStatements.data;
    const all = Object.keys(allObj).map(key => allObj[key]);

    if (!areEqualArrays(all, state.all) && all.length > 0) {
      const locked = Object.keys(allObj)
        .filter(id => allObj[id].core === true)
        .map(id => allObj[id]);
      const selectedIds = props.jobListing.predictor_statements;
      const selected = selectedIds.map(id => allObj[id]);
      const unselected = Object.keys(allObj)
        .filter(id => selectedIds.indexOf(id) === -1)
        .filter(id => allObj[id].core === false)
        .map(id => allObj[id]);

      return { all, locked, selected, unselected };
    }

    return null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const prevSelectedIds = prevState.selected.map(obj => obj.uuid);
    const nextSelectedIds = this.state.selected.map(obj => obj.uuid);
    const sizeChanged = nextSelectedIds.length !== prevSelectedIds.length;
    const allItemsEqual =
      !sizeChanged &&
      prevSelectedIds.every((id, i) => id === nextSelectedIds[i]);
    if (!allItemsEqual)
      this.props.change("predictor_statements", nextSelectedIds);
  };

  dragEnd = ({ source, destination }) => {
    if (!destination) return;

    const isReOrdering = source.droppableId === destination.droppableId;
    const isAdding = !isReOrdering && destination.droppableId === "selected";

    const sourceClone = this.state[source.droppableId].slice();
    const [removed] = sourceClone.splice(source.index, 1);

    if (isReOrdering) {
      // Reordering
      sourceClone.splice(destination.index, 0, removed);
      this.setState({ [source.droppableId]: sourceClone });
    } else {
      // Adding or removing
      const isFull = this.state.selected.length >= selectedMax;
      if (isAdding && isFull) return;
      const destClone = this.state[destination.droppableId].slice();
      destClone.splice(destination.index, 0, removed);
      this.setState({
        [source.droppableId]: sourceClone,
        [destination.droppableId]: destClone,
      });
    }
  };

  toggleSelected = (uuid: string, source: string, dest: string) => {
    const selectedFull = this.state.selected.length >= selectedMax;
    if (dest === "selected" && selectedFull) return;

    const sourceClone = this.state[source].slice();
    const sourceLen = sourceClone.length;
    const destClone = this.state[dest].slice();

    for (let i = 0; i < sourceLen; i++) {
      const item = sourceClone[i];
      if (item.uuid === uuid) {
        sourceClone.splice(i, 1);
        destClone.push(item);
        break;
      }
    }
    this.setState({ [source]: sourceClone, [dest]: destClone });
  };

  clearSelected = () => {
    const { selected, unselected } = this.state;
    this.setState({
      selected: [],
      unselected: unselected.concat(selected),
    });
  };

  render() {
    const { showDescription = true } = this.props;
    const { locked, selected, unselected } = this.state;

    return (
      <div>
        {showDescription && (
          <Fragment>
            <h2>Job Analysis Form</h2>
            <div className="description">
              <OptionalFormMessage />
              <p>
                Here we match the candidates most likely to succeed based on
                what traits are important for this role.
              </p>
              <p>
                Below is a list of work competencies that are associated with
                successful outcomes across legal jobs. The field below is
                populated with 6 competencies that are highly predictive across
                all roles.
              </p>
              <p>
                Please select and rank up to 5 additional competencies in order
                of importance. When choosing, consider the context of the job
                and the environment that the employee will be exposed to.
              </p>
            </div>
          </Fragment>
        )}
        <DragAndDrop
          onClearSelected={this.clearSelected}
          onDragEnd={this.dragEnd}
          onToggleSelected={this.toggleSelected}
          locked={locked}
          selected={selected}
          unselected={unselected}
          selectedMax={selectedMax}
        />
      </div>
    );
  }
}

JobAnalysisForm = compose(
  reduxForm({ enableReinitialize: true }),
  withFormError(),
)(JobAnalysisForm);

const JobAnalysis = ({ formProps, ...props }) => (
  <JobAnalysisForm {...formProps} {...props} />
);

const predictorStatementsQuery = () =>
  createResourceListQuery(types.PREDICTOR_STATEMENTS, {
    url: "/api/v2/predictor_statements/?limit=100",
    force: true,
  });

const mapStateToProps = createStructuredSelector(
  createResourceSelectorConfig(
    types.PREDICTOR_STATEMENTS,
    predictorStatementsQuery,
  ),
);

const mapDispatchToProps = (dispatch: ReduxDispatch<GenericAction>, props) => ({
  submitFormData: data =>
    dispatch(mutateAsync(update(props.jobListing.uuid, data))),
  notify: message => dispatch(addNotification(message)),
});

export default compose(
  connectRequest(predictorStatementsQuery),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(JobAnalysis);
