// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

const Top: ComponentType<{}> = styled.div`
  width: 100%;
  margin: 5px;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
`;

export default Top;
