import React from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import { EditTalentPools } from ".";

const ButtonTitle = styled.h4.attrs({ className: "text-uppercase" })`
  margin: 0;
`;

const EditTalentPoolModal = props => {
  const { onClose, show } = props;
  return (
    <Modal show={show}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>Edit Talent Pools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditTalentPools />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} bsStyle="link">
          <ButtonTitle>Close</ButtonTitle>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTalentPoolModal;
