// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import { history as browserHistory } from "app";
import { requestAuth } from "common/actions/auth";
import { CenteredContent } from "common/components/layout";

type Props = {
  auth: {
    data: Auth,
    error: ?Error,
    token: null,
    isLoading: boolean,
    isFinished: boolean,
  },
  onLoad: () => mixed,
};

class RedirectToApp extends Component<Props> {
  componentDidMount() {
    this.props.onLoad();
    this.redirectIfNecessary();
  }

  componentDidUpdate() {
    this.redirectIfNecessary();
  }

  redirectIfNecessary() {
    const {
      auth: { data: authData, isFinished },
    } = this.props;
    if (!isFinished) return;
    if (!authData) {
      browserHistory.push("/login");
      return;
    }

    let redirectTo = "/login";

    switch (authData.user_type) {
      case "admin":
        redirectTo = "/hae_admin/dashboards/";
        break;
      case "contractor":
        redirectTo = "/contractor";
        break;
      case "hiring_agent":
        redirectTo = "/agency/";
        break;
      default:
        redirectTo = "/login";
        break;
    }
    browserHistory.replace(redirectTo);
  }

  render() {
    const {
      auth: { isLoading },
    } = this.props;

    const content = isLoading ? (
      <i className="far fa-cog fa-spin fa-4x" />
    ) : (
      <span>Error loading response from server</span>
    );

    return <CenteredContent>{content}</CenteredContent>;
  }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = { onLoad: requestAuth };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RedirectToApp);
