import {
  Button,
  Label,
  ListGroup,
  ListGroupItem,
  Pager,
  Panel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { TextAreaField } from "common/components/forms/inputs";

export const MessagesControlWrapper = styled.div`
  & > * {
    margin: 5px 0;
  }
  & label {
    margin: 5px 0 0 0;
    padding: 0;
    vertical-align: middle;
  }
`;

export const SearchForm = styled.form`
  & {
    display: grid;
    grid-template-columns: 4fr 1fr;
    display: -ms-grid;
    -ms-grid-columns: 4fr 1fr;
  }
  & > *:first-child {
    -ms-grid-column: 1;
    margin-right: 5px;
  }
  & > *:last-child {
    -ms-grid-column: 2;
    margin-left: 5px;
  }
`;

export const CustomHeader = styled.h1`
  & {
    margin-bottom: 10px;
    ${({ theme }) => theme.media.landscape_mobile`
      display: none;
    `};
  }
`;

export const ThreadWrapper = styled.div`
  & {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    height: 100%;
    display: -ms-grid;
    -ms-grid-rows: auto 1fr auto;
    -ms-grid-columns: 1fr;
  }
  & > *:first-child {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  & > *:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  & > *:last-child {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
`;

export const ThreadPlaceholderWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }
`;

export const MessagesWrapper = styled.div`
  & {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    height: 100%;
    display: -ms-grid;
    -ms-grid-rows: auto 1fr;
    -ms-grid-columns: 1fr;
  }
  & > *:first-child {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  & > *:last-child {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
`;

export const PaperclipIcon = styled.i.attrs({
  "aria-hidden": "true",
  className: "far fa-paperclip",
})`
  float: ${({ float }) => (float ? float : "left")};
`;

export const EnvelopeIcon = styled.i.attrs({
  "aria-hidden": "true",
  id: "toggle-unread-icon",
})`
  float: right;
  margin-right: 10px;
  cursor: pointer;
`;

export const ArchiveIcon = styled.i.attrs({
  "aria-hidden": "true",
  id: "toggle-archive-icon",
})`
  float: right;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  & {
    float: right;
    width: 42px;
    height: 34px;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 4px;
    outline: none;
    &:hover,
    &:focus {
      background: ${({ theme }) => theme.colors.container_bg_darker()};
    }
  }
`;

export const CloseButtonIcon = styled.i.attrs({
  className: "far fa-times",
  "aria-hidden": "true",
})`
  & {
    margin: 0;
    padding: 0;
  }
`;

export const CloseButtonText = styled.span`
  & {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
`;

export const StyledListGroupItem = styled(ListGroupItem)`
  &&&& {
    outline: none;
    border-right: none;
    border-left: none;
    ${({ active, theme }) =>
      !active &&
      css`
        &,
        &:hover {
          background-color: ${theme.colors.background_lightgray()};
        }
      `};
    ${({ type }) => {
      return type === "footer"
        ? css`
            border-bottom: none;
          `
        : css`
            &:first-child {
              border-top-color: transparent;
            }
          `;
    }}
    ${({ active, theme, unread }) =>
      unread === "true" &&
      !active &&
      css`
        &,
        &:hover {
          background-color: ${theme.colors.white()};
        }
      `};
  }
`;

export const StyledListGroup = styled(ListGroup)`
  &&&& {
    height: auto;
    overflow: scroll;
    margin-bottom: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    ${({ theme }) => theme.media.mobile`
      margin-bottom: 15px;
    `};
  }
`;

export const StyledDate = styled.label`
  float: right;
`;

export const StyledLink = styled(Link)`
  &&&& {
    width: auto;
    display: inline-block;
  }
`;

export const DividerTop = styled.hr`
  margin: 0;
  padding: 0;
`;

export const StyledPanel = styled(Panel)`
  &&&& {
    margin: ${({ side }) =>
      side === "left" ? "15px 75px 15px 15px" : "15px 15px 15px 75px"};
  }
`;

export const TextStyles = styled.div`
  &&&& * {
    overflow-wrap: break-word;
    word-break: break-word;
  }
`;

export const StyledTime = styled.span`
  float: right;
`;

export const ThreadMainWrapper = styled.div.attrs({
  id: "inbox-thread-messages",
})`
  overflow: scroll;
`;

export const ThreadHeaderWrapper = styled.div`
  position: relative;
`;

export const ThreadHeaderGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 100px;
  display: -ms-grid;
  -ms-grid-columns: 1fr 100px;
  div,
  h6,
  strong,
  a,
  p,
  hr {
    margin: 0;
    padding: 0;
  }
  & > *:first-child {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  & > *:last-child {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
`;

export const NewMsgButtonWrapper = styled.div`
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const NewMsgButton = styled(Button).attrs({ bsStyle: "primary" })`
  &&&& {
    width: auto;
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 34px;
    cursor: pointer;
    outline: none;
  }
`;

export const ThreadReplyFormWrapper = styled.form`
  margin: 0 0 10px 0;
  padding: 0;
`;

export const DividerBottom = styled.hr`
  & {
    margin: 0 0 5px 0;
    padding: 0;
  }
`;

export const ResponsiveTextAreaField = styled(TextAreaField)`
  &&&& {
    height: 70px;
    ${({ theme }) => theme.media.mobile`
      height: 52px;
    `};
    ${({ theme }) => theme.media.landscape_mobile_lg`
      height: 35px;
    `};
  }
`;

export const StyledLabel = styled(Label).attrs({ bsStyle: "primary" })`
  &&&& {
    display: inline-block;
    width: 100%;
    margin: 0 5px 5px 0;
    padding: 5px;
  }
`;

export const StyledPager = styled(Pager)`
  &&&& {
    margin: 5px;
    li > a {
      margin: 0 1px;
      padding: 3px 10px;
    }
  }
`;
