import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { ErrorBoundary } from "common/components/error-boundary";

class BasePanel extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  handleToggleExpand(e) {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { action, children, isLoading, name, size } = this.props;
    const { expanded } = this.state;
    return (
      <Col lg={size} className={`dashboard-panel dashboard-${name}`}>
        <div className="panel-content">
          <ErrorBoundary>
            <h2>{name}</h2>
            {action}
            {isLoading ? (
              <div className="text-center" style={{ marginTop: "4rem" }}>
                <i className="far fa-cog fa-spin fa-2x" />
              </div>
            ) : (
              <div>
                {React.cloneElement(children, {
                  expanded,
                  onToggleExpand: e => this.handleToggleExpand(e),
                })}
              </div>
            )}
          </ErrorBoundary>
        </div>
      </Col>
    );
  }
}

BasePanel.propTypes = {
  action: PropTypes.node,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};

BasePanel.defaultProps = {
  action: null,
  isLoading: false,
  size: 4,
};

export default BasePanel;
