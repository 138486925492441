// @flow strict
import styled from "styled-components";

const TalentPoolsButtonContainer = styled.div`
  margin-bottom: 1rem;
  ${({ theme }) => theme.media.desktop`
    margin-top: 1rem;
    margin-bottom: 0;
  `};
`;

export default TalentPoolsButtonContainer;
