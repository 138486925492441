import React from "react";

import ToolTip from "common/components/ToolTip";
import {
  getJobClosedMsg,
  getGenericNotPermittedMsg,
  getRequiredExperienceNotMetMsg,
  getWrongContractorTypeMsg,
  getJobNotOpenForApplicationsMsg,
} from "contractor/components/job/JobMessages";
import { jobExperienceRequirement } from "public/utils/jobs";

export const isNotAllowedToApply = (
  job,
  jobListing,
  contractor,
  candidate,
  hasPermission,
) => {
  const jobIsclosed = job.status === 3;
  const candidateExists = Boolean(candidate);
  const doesNotHavePermission = !hasPermission;
  const incorrectContractorType = !jobAcceptsContractorType(
    jobListing,
    contractor.is_attorney,
  );

  const disqualifiers = [
    jobIsclosed,
    candidateExists,
    doesNotHavePermission,
    incorrectContractorType,
  ];

  return disqualifiers.some(s => s);
};

export const getWorkerForJob = (workers, jobId) =>
  workers.find(worker => worker.job.uuid === jobId);

export const getApplicationForJob = (applications, jobId) =>
  applications.find(app => app.job.uuid === jobId);

export const jobAcceptsContractorType = (jobListing, isAttorney) => {
  if (jobListing.allowsAttorneysAndNonAttorneys) return true;
  if (isAttorney && jobListing.allowsAttorneysOnly) return true;
  if (!isAttorney && jobListing.allowsNonAttorneysOnly) return true;
  return false;
};

export const hasRequiredExperience = (jobListing, yearsExperience) =>
  Boolean(yearsExperience >= jobListing.min_years_experience);

export const candidateIsOverQualified = (jobListing, yearsExperience) =>
  yearsExperience > jobListing.max_years_experience;

export const getCorrectCannotApplyMsg = (
  job,
  jobListing,
  contractor,
  hasPermission,
) => {
  const yearsExperience = contractor.years_work_experience;
  const isAttorney = contractor.is_attorney;

  if (job.isClosed) {
    return getJobClosedMsg(job);
  }
  if (!job.isOpenForApplications) {
    return getJobNotOpenForApplicationsMsg();
  }
  if (!hasRequiredExperience(jobListing, yearsExperience)) {
    return getRequiredExperienceNotMetMsg(jobListing, yearsExperience);
  }
  if (!jobAcceptsContractorType(jobListing, isAttorney)) {
    return getWrongContractorTypeMsg(jobListing);
  }
  if (!hasPermission) {
    return getGenericNotPermittedMsg();
  }
  return null;
};

export const JobRate = ({ min_estimated_rate, max_estimated_rate }) => (
  <span>
    ${min_estimated_rate} - ${max_estimated_rate} /hr
  </span>
);

export const JobService = ({ service }) => {
  const irsUrl =
    "http://www.irs.gov/Businesses/Small-Businesses-&-Self-Employed/Self-Employment-Tax-Social-" +
    "Security-and-Medicare-Taxes";
  const irsTaxCenterUrl =
    "http://www.irs.gov/Businesses/Small-Businesses-&amp;-Self-Employed/Small-Business-and-" +
    "Self-Employed-Tax-Center-1";
  const serviceTooltips = [
    {
      title: "What is a 1099 Independent Contractor?",
      content: (
        <span>
          You are not an employee but self-employed and responsible for
          withholding and submitting your own payroll taxes and paying
          additional self-employment taxes to{" "}
          <a href={irsUrl} target="_blank" rel="noopener noreferrer">
            federal, state and local tax departments
          </a>. Please visit the IRS self-employed tax center{" "}
          <a href={irsTaxCenterUrl} target="_blank" rel="noopener noreferrer">
            here
          </a>{" "}
          and consult your tax professional for further information on what
          federal state and local taxes you may need to pay. You will receive a
          1099 form at the end of the year as a tax document.
        </span>
      ),
    },
    {
      title: "What is a W-2 Employee?",
      content: (
        <span>
          W-2 projects mean that you are an employee of Hire an Esquire (<i>
            not the law firm or legal department that you are ultimately
            producing work product for
          </i>). Hire an Esquire withholds and files your payroll taxes and at
          the end of the year issues you a form W-2 to assist with preparation
          of your personal taxes. You will also be eligible for the Health
          Insurance after 90 days of working with Hire an Esquire!
        </span>
      ),
    },
  ];

  return (
    <span>
      {service === 0 ? "1099" : "W-2"}&nbsp;
      <ToolTip {...serviceTooltips[service]}>
        <i className="far fa-question-circle" />
      </ToolTip>
      <span itemProp="hiringOrganization" className="hidden">
        {service === 0 ? "Confidential" : "Hire an Esquire"}
      </span>
    </span>
  );
};

export const JobExperienceRequirement = ({
  min_years_experience,
  max_years_experience,
}) => {
  const content = jobExperienceRequirement(
    min_years_experience,
    max_years_experience,
  );
  return (
    <span className="text-nowrap" itemProp="experienceRequirements">
      {content}
    </span>
  );
};
