import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Clearfix, ListGroup, ListGroupItem, Panel } from "react-bootstrap";

import { PanelHeader } from "./utils";

class RecommendationsPanel extends Component {
  render() {
    const { recommendations, ...otherProps } = this.props;
    return (
      <Panel {...otherProps}>
        <PanelHeader
          title="Recommendations"
          bsStyle="info"
          count={recommendations.length}
        />
        <Panel.Collapse>
          <ListGroup>
            {recommendations.map(recommendation => (
              <ListGroupItem key={`job-offer-${recommendation.uuid}`}>
                <div>{recommendation.joblisting_title}</div>
                <div>
                  <Link to={`/contractor/jobs/${recommendation.job}/`}>
                    <i className="far fa-newspaper" />&nbsp;Job Details
                  </Link>
                  <Clearfix />
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default RecommendationsPanel;
