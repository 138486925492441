import React, { Component, Fragment } from "react";
import { MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { connectRequest } from "redux-query-react";
import PropTypes from "prop-types";

import { createResourceSelector } from "queries";
import { connect, compose } from "resources/components/utils";
import types from "resources/types";
import { Job } from "types";
import { jobsQuery } from "queries/requests";

export const jobName = job =>
  job.formattedTitle.length > 30
    ? `${job.formattedTitle.slice(0, 30)}...`
    : job.formattedTitle;

const JobLoading = () => (
  <MenuItem className="jobs-loading">
    <i className="far fa-cog fa-spin" /> Loading Jobs...
  </MenuItem>
);

export const InviteToJobMenuItem = ({ job, handleInviteClick }) => (
  <LinkContainer
    to={`/agency/messages/${job.uuid}/invite/`}
    onClick={handleInviteClick}
  >
    <MenuItem>{jobName(job)}</MenuItem>
  </LinkContainer>
);

export class InviteToJobsAction extends Component {
  static propTypes = {
    onInviteClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      [types.JOBS]: { isFinished, selectedData: availableJobs },
      onInviteClick: handleInviteClick,
    } = this.props;

    return (
      <Fragment>
        {isFinished &&
          availableJobs.length > 0 && (
            <Fragment>
              <MenuItem divider />
              <MenuItem header className="invite-to-job-menu-header">
                <i className="far fa-user-plus" /> Invite To Job:
              </MenuItem>
              {availableJobs.map(job => (
                <InviteToJobMenuItem
                  key={job.uuid}
                  job={job}
                  handleInviteClick={handleInviteClick}
                />
              ))}
            </Fragment>
          )}
        {!isFinished && <JobLoading />}
      </Fragment>
    );
  }
}

const mapPropsToConfig = jobsQuery;
const openForApplications = job =>
  job.status === Job.STATUS.OPEN_FOR_APPLICATIONS;
export const filterData = data =>
  Object.values(data).filter(openForApplications);
const mapStateToProps = createResourceSelector(
  types.JOBS,
  mapPropsToConfig,
  filterData,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(InviteToJobsAction);
