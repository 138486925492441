import actions from "contractor/actions/profile";

export const recieveAddProfilePicture = {
  "RECEIVE/addProfilePicture": (state, action) => ({
    ...state,
    isLoading: false,
    lastUpdated: action.meta.receivedAt,
    data: {
      ...state.data,
      url: null,
    },
  }),
};

export const clearStashedProfilePicture = {
  [actions.CLEAR_STASHED_PROFILE_PICTURE]: state => ({
    ...state,
    data: {
      ...state.data,
      url: null,
    },
  }),
};

export const ownContractor = {
  "RECEIVE/schools": (state, action) => ({
    ...state,
    data: {
      ...state.data,
      education: action.payload.results,
    },
  }),
  "RECEIVE/experience": (state, action) => ({
    ...state,
    data: {
      ...state.data,
      previous_employment: action.payload.results,
    },
  }),
  "RECEIVE/languageFluency": (state, action) => ({
    ...state,
    data: {
      ...state.data,
      language_fluency: action.payload.results,
    },
  }),
};

export const schoolTranscript = {
  [actions.ADD_TRANSCRIPT]: (state, action) => ({
    ...state,
    transcript: action.payload,
  }),
  [actions.REMOVE_TRANSCRIPT]: state => ({
    ...state,
    transcript: null,
  }),
};
