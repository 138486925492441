import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

const popoverHoverFocus = (
  <Popover id="popover-trigger-hover-focus">Verified Attorney</Popover>
);

export const AttorneyIcon = props => (
  <span className="attorney-icon-base" {...props}>
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="bottom"
      overlay={popoverHoverFocus}
    >
      <span className="fa-stack">
        <i className="fas fa-circle fa-stack-2x" />
        <i className="far fa-balance-scale fa-stack-1x" />
      </span>
    </OverlayTrigger>
  </span>
);

const AttorneyNameIcon = props => {
  const { isAttorney, children, className } = props;
  if (isAttorney) {
    return (
      <span>
        <AttorneyIcon />
        <span
          className={className}
          style={{ verticalAlign: "middle", marginLeft: "0.5rem" }}
        >
          {children}
        </span>
      </span>
    );
  }

  return <span className={className}>{children}</span>;
};

export default AttorneyNameIcon;
