import React, { Component, Fragment } from "react";
import { SubmissionError } from "redux-form";
import { Alert } from "react-bootstrap";
import { addNotification } from "reapop";
import { connect, compose, connectRequest, mutateAsync } from "queries/utils";

import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import {
  ActivePreferencesForm,
  SubscriptionForm,
} from "contractor/forms/settings/job-notifications";
import {
  createResourceListQuery,
  createResourceSelector,
  mutateResourceQuery,
} from "queries";
import types from "resources/types";

const getTagsByTagGroup = (expertiseAreaSet = []) =>
  expertiseAreaSet.reduce((accum, curr) => {
    const currTag = curr.tag;
    const currTagGroup = curr.tag_group;

    const isSubmittingActivePreferencesForm = accum.hasOwnProperty(
      currTagGroup,
    );
    if (isSubmittingActivePreferencesForm) {
      accum[currTagGroup] = [...accum[currTagGroup], currTag];
    } else {
      accum[currTagGroup] = [currTag];
    }
    return accum;
  }, {});

const getExpertiseAreaFieldItems = tagGroupsWithTags =>
  Object.entries(tagGroupsWithTags).map(([tagGroupId, tagsArray]) => ({
    tag_group: tagGroupId,
    tags: tagsArray,
  }));

const getSelectedTags = tagGroupItems =>
  tagGroupItems.reduce(
    (accum, curr) => [...accum, ...curr.tags.map(tag => ({ tag }))],
    [],
  );

const getSubmitValues = formValues => {
  const values = { ...formValues };
  if (values.hasOwnProperty("tag_group_items")) {
    values.expertiseareapreference_set = getSelectedTags(
      values.tag_group_items,
    );
    delete values.tag_group_items;
  }
  return values;
};

const NotVerifiedAlert = ({ isVerified }) =>
  isVerified ? null : (
    <Alert bsStyle="warning">
      You will <strong>not</strong> receive job notification emails from Hire an
      Esquire until your account is verified.
    </Alert>
  );

export class EditJobNotifications extends Component {
  handleSubmit = values => {
    const { notify, submit } = this.props;
    const valuesToSubmit = getSubmitValues(values);

    return submit(values.uuid, valuesToSubmit).then(({ status, body }) => {
      if (status >= 200 && status < 300) {
        notify(
          createSuccessNotification({
            message: "Your preferences have been saved.",
          }),
        );
        return;
      }
      let message = "Please try again. If problem persists, contact support.";
      if (status === 400) {
        message = "Please fix any form errors and try again.";
      }
      if (status === 500) {
        message =
          "There was a problem submitting your request. Please refresh this page and try again.";
      }
      notify(createErrorNotification({ message }));

      if (body.hasOwnProperty("non_field_errors")) {
        body._error = body.non_field_errors;
        delete body.non_field_errors;
      }
      throw new SubmissionError(body);
    });
  };

  getSubscriptionFormInitialValues = (preference = {}) => {
    const { uuid, subscription_status, paused_until } = preference;
    return {
      uuid,
      subscription_status,
      paused_until,
    };
  };

  getActivePreferencesInitialValues = (preference = {}) => {
    const {
      uuid,
      remote_positions_ok,
      locationpreference_set,
      expertiseareapreference_set = [],
    } = preference;
    const tagGroupsWithTags = getTagsByTagGroup(expertiseareapreference_set);
    const expertiseAreaPreferences = getExpertiseAreaFieldItems(
      tagGroupsWithTags,
    );

    return {
      uuid,
      remote_positions_ok,
      locationpreference_set,
      tag_group_items: expertiseAreaPreferences,
      primary_practice_areas: expertiseAreaPreferences.map(
        area => area.tag_group,
      ),
    };
  };

  render() {
    const {
      contractor: { is_verified },
      [types.JOB_NOTIFICATION_PREFERENCES]: { data, isPending },
    } = this.props;

    const preference = Object.values(data)[0];
    const intitalSubscriptionStatus =
      preference && preference.subscription_status;

    return (
      <div className="edit-job-notifications">
        <Fragment>
          <NotVerifiedAlert isVerified={is_verified} />
          <div className="hidden-xs">
            <h2 className="edit-section-title">Job Notification Emails</h2>
            <p>
              These emails will notify you about relevant job opportunities.
              Tell us your location and expertise to receive targeted alerts.
            </p>
          </div>
        </Fragment>
        {!isPending && (
          <Fragment>
            <SubscriptionForm
              onSubmit={this.handleSubmit}
              initialValues={this.getSubscriptionFormInitialValues(preference)}
              intitalSubscriptionStatus={intitalSubscriptionStatus}
            />
            <ActivePreferencesForm
              onSubmit={this.handleSubmit}
              initialValues={this.getActivePreferencesInitialValues(preference)}
              intitalSubscriptionStatus={intitalSubscriptionStatus}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

const updateJobNotificationsQuery = (id, data) =>
  mutateResourceQuery(
    types.JOB_NOTIFICATION_PREFERENCES,
    {
      url: `/api/v2/job_notification_preferences/${id}/`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );

const mapPropsToConfig = () =>
  createResourceListQuery(types.JOB_NOTIFICATION_PREFERENCES, {
    url: "/api/v2/job_notification_preferences/?limit=9999",
  });

const mapStateToProps = createResourceSelector(
  types.JOB_NOTIFICATION_PREFERENCES,
  mapPropsToConfig,
);

export default compose(
  connect(
    mapStateToProps,
    {
      notify: addNotification,
      submit: (id, data) => mutateAsync(updateJobNotificationsQuery(id, data)),
    },
  ),
  connectRequest(mapPropsToConfig),
)(EditJobNotifications);
