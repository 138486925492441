import { mutateResourceQuery } from "queries";
import types from "resources/types";

export const resendPendingEmailConfirmationQuery = () =>
  mutateResourceQuery(types.RESEND_PENDING_EMAIL_CONFIRMATION, {
    url: "/api/v2/users/resend_pending_email_confirmation",
    options: {
      method: "POST",
    },
  });
