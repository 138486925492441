import React from "react";
import { SubmissionError } from "redux-form";
import { OverlayTrigger } from "react-bootstrap";

export const fieldValidationState = ({ touched, error }) =>
  touched && error ? "error" : touched ? "success" : null;

export const domOnlyProps = ({ ...domProps }) => domProps;

// TODO: Rename to fieldValidationState
export const validationState = ({ touched, error, value }, maxLength = null) =>
  touched && error
    ? "error"
    : touched
      ? maxLength && value && value.length > maxLength
        ? "error"
        : "success"
      : null;

export const normalizeNumsOnlyField = value => {
  if (value !== undefined) {
    return value.replace(/[^\d]/g, "");
  }
  return value;
};

export {
  RequiredFieldError,
  validatePasswords,
  validateRequiredFields,
} from "common/utils/forms/validators";

// Designed to be used as the `label` prop to `FormControlGroup` or similar which will wrap it in `ControlLabel`
export const LabelWithTooltip = ({
  label,
  children,
  placement = "top",
  ...otherProps
}) => (
  <span>
    {label}&nbsp;
    <OverlayTrigger
      trigger={["hover", "click"]}
      placement={placement}
      overlay={children}
      {...otherProps}
    >
      <i className="far fa-question-circle" />
    </OverlayTrigger>
  </span>
);

export const handleSubmissionError = ({ json, response }) => {
  if (!response.ok) {
    throw new SubmissionError(json);
  }
  return json;
};
