import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Col, Row } from "react-bootstrap";

import { range } from "common/utils";
import { validateRequiredFields } from "common/utils/forms/validators";
import FormButton from "common/components/FormButton";
import {
  HiddenField,
  ReactSelectField,
  TextAreaField,
} from "common/components/forms/inputs";
import { TrackerDatePicker } from "contractor/components/time-tracker";

export const formName = "timeEntryForm";
export const requiredFields = [
  "date",
  "description",
  "hours",
  "minutes",
  "worker",
];
const validate = values => {
  const { hours, minutes } = values;
  const errors = validateRequiredFields(requiredFields, values);
  if (minutes === 0 && hours === 0) {
    errors.minutes = "You must enter time.";
    errors.hours = "You must enter time.";
  }
  return errors;
};

class TimeEntry extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      valid,
      billingIncrement,
    } = this.props;
    const hourOptions = range(0, 25).map(num => ({ value: num, label: num }));
    const minuteOptions = range(0, 61, billingIncrement).map(num => ({
      value: num,
      label: num,
    }));

    return (
      <form onSubmit={handleSubmit}>
        <TrackerDatePicker />
        <Row>
          <Col sm={6}>
            <ReactSelectField
              name="hours"
              label="Hours"
              options={hourOptions}
            />
          </Col>
          <Col sm={6}>
            <ReactSelectField
              name="minutes"
              label="Minutes"
              options={minuteOptions}
            />
          </Col>
        </Row>
        <TextAreaField name="description" label="Description" rows={5} />
        <HiddenField name="worker" />
        <div className="text-right">
          <FormButton
            className="text-right"
            action="save"
            submitting={submitting}
            disabled={pristine || submitting || !valid}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  validate,
})(TimeEntry);
