import React, { Component } from "react";
import { connect } from "resources/components/utils";
import { addNotification as notify } from "reapop";
import { Button } from "react-bootstrap";
import { history as browserHistory } from "app";

import { deactivate } from "resources/actions/ownContractor";
import { createErrorNotification } from "common/utils/notifications";
import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";

const DeactivateAccountModal = ({ onClose, onSubmit, show }) => {
  const body = (
    <span>
      Upon deactivation, you will be signed out and will lose access to your
      account immediately and permanently. Are you sure you want to deactivate
      your account?
    </span>
  );
  const deactivateButton = (
    <Button bsStyle="danger" onClick={onSubmit} className="pull-right">
      Deactivate
    </Button>
  );

  return (
    <ConfirmModal
      show={show}
      close={onClose}
      title={"Confirm Deactivation"}
      body={body}
      actions={deactivateButton}
    />
  );
};

const deactivationExplanationText = (
  <p
    className="header-copy"
    style={{ fontSize: "1.7rem", marginBottom: "2rem" }}
  >
    If you have any questions or concerns regarding your Hire an Esquire
    account, contact support before deactivating your account. Account
    deactivation is permanent; use this feature with caution!
  </p>
);

class DeactivateAccount extends Component {
  state = {
    isModalOpen: false,
  };

  showDeactivateModal = () => this.setState({ isModalOpen: true });

  hideDeactivateModal = () => this.setState({ isModalOpen: false });

  handleDeactivateConfirmClick = () =>
    this.props.onSubmit().then(({ json, response }) => {
      this.hideDeactivateModal();
      if (!response.ok) {
        this.props.notify(createErrorNotification({ message: json.detail }));
      } else {
        browserHistory.push("/logout");
      }
    });

  render() {
    return (
      <div>
        <h2 className="edit-section-title">Deactivate Account</h2>
        {deactivationExplanationText}
        <div className="text-center">
          <Button
            bsStyle="danger"
            style={{ textAlign: "center" }}
            onClick={this.showDeactivateModal}
          >
            Deactivate Account
          </Button>
        </div>
        <DeactivateAccountModal
          show={Boolean(this.state.isModalOpen)}
          onSubmit={this.handleDeactivateConfirmClick}
          onClose={this.hideDeactivateModal}
        />
      </div>
    );
  }
}

export default (DeactivateAccount = connect(
  null,
  {
    onSubmit: deactivate,
    notify,
  },
)(DeactivateAccount));
