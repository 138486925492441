export default [
  "English",
  "Afgani",
  "Afrikaans",
  "Akan",
  "Albanian",
  "Amharic",
  "Arabic",
  "Armenian",
  "Asante",
  "Assyrian",
  "Azerbaijani",
  "Azeri",
  "Bahasa",
  "Bambara",
  "Basare",
  "Belarusian",
  "Bengali",
  "Berber",
  "Bicol",
  "Bisaya",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Byelorussian",
  "Cambodian",
  "Cantonese",
  "Catalan",
  "Cebuano",
  "Chaldean",
  "Chao Chow",
  "Chewa",
  "Chinese",
  "Chinese(Mandarin)",
  "Chinyanta",
  "Circassian",
  "Creole",
  "Croatian",
  "Czech",
  "Danish",
  "Dari",
  "Dutch",
  "Edo",
  "Estonian",
  "Ewe",
  "Fanti",
  "Farsi",
  "Filipino",
  "Finnish",
  "French",
  "Fujian",
  "Fuzhounese",
  "Ga",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Guarani",
  "Gujarati",
  "Haitian",
  "Harrari",
  "Hausa",
  "Hebrew",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Ibibio",
  "Ibo",
  "Ilocano",
  "Indonesian",
  "Irish",
  "Ishan",
  "Italian",
  "Jamaican Patois",
  "Japanese",
  "Kannada",
  "Kashmiri",
  "Kikongo",
  "Kikuyu",
  "Kiswahili",
  "Korean",
  "Krio",
  "Kurdish",
  "Kyrgyz",
  "Laotian",
  "Latin",
  "Latrian",
  "Latvian",
  "Lebanese",
  "Lithuanian",
  "Luganda",
  "Lusoga",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Maltese",
  "Marathi",
  "Nepali",
  "Newari",
  "Ngoni",
  "Nigerian",
  "Norwegian",
  "Okihawah",
  "Oriya",
  "Pangasinan",
  "Papiamentu",
  "Persian",
  "Polish",
  "Portuguese",
  "Poshto",
  "Punjabi",
  "Romanian",
  "Runyankore",
  "Runyarwanda",
  "Russian",
  "Samoan",
  "Serbian",
  "Serbo-Croatian",
  "Shanghai",
  "Sign Language",
  "Sinhalese",
  "Slovak",
  "Somali",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Taiwanese",
  "Tajik",
  "Tamil",
  "Telugu",
  "Temne",
  "Thai",
  "Tibetian",
  "Tigrigna",
  "Tongan",
  "Turkish",
  "Twi",
  "Ukrainian",
  "Ukwuani",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Visayan",
  "Wolof",
  "Yiddish",
  "Yoruba",
  "Yugoslavian",
  "Zarma",
];
