// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import PrimaryButton from "common/blocks/PrimaryButton";
import Container from "common/blocks/Container/index";
import ContainerContent from "./ContainerContent";
import Dropzone from "./Dropzone";
import IconCheck from "./IconCheck";
import RedText from "common/blocks/RedText";

type Props = {};

const DepositBlock: ComponentType<Props> = styled.div``;

const Deposit = (props: Props) => <DepositBlock {...props} />;

Deposit.PrimaryButton = PrimaryButton;
Deposit.Container = Container;
Deposit.ContainerContent = ContainerContent;
Deposit.Dropzone = Dropzone;
Deposit.IconCheck = IconCheck;
Deposit.RedText = RedText;

export default Deposit;
