import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { addNotification as notify } from "reapop";
import { Alert, Button, ButtonToolbar } from "react-bootstrap";

import {
  handleSubmissionError,
  validateRequiredFields,
} from "common/utils/forms";
import {
  requestPerformanceReviews,
  setFocusedReview,
  submitPerformanceReview,
} from "hiringagent-dashboard/actions/reviews";
import { RadioButtonSetField } from "common/components/forms/inputs";
import { Error } from "common/utils/forms/validators";

const requiredFields = [
  "responsible_for_approval",
  "satisy_requirements",
  "work_with_again",
  "performanceReviewId",
];
const selectFields = [
  "responsible_for_approval",
  "satisy_requirements",
  "work_with_again",
];

const successNofification = {
  title: "Success",
  message: "Review submitted!",
  status: "success",
  position: "tr",
  dismissible: true,
  dismissAfter: 3000,
};

const validate = values => {
  const errors = validateRequiredFields(requiredFields, values);
  const allowedValues = [0, 1];

  Object.entries(values).forEach(([key, value]) => {
    if (selectFields.includes(key) && !allowedValues.includes(value)) {
      errors[key] = <Error>Please choose one of the provided options</Error>;
    }
  });

  return errors;
};

class PerformanceReviewForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values, dispatch) {
    return dispatch(submitPerformanceReview(values))
      .then(handleSubmissionError)
      .then(() => {
        dispatch(setFocusedReview(null));
        dispatch(requestPerformanceReviews());
        this.props.notify(successNofification);
      });
  }

  render() {
    const {
      closeForm,
      error,
      handleSubmit,
      submitting,
      submitFailed,
      performanceReview: {
        contractor: { full_name: contractorName },
      },
    } = this.props;

    return (
      <form
        onSubmit={handleSubmit(this.submit)}
        className="performance-review-form"
      >
        {error && submitFailed && <Alert bsStyle="danger">{error}</Alert>}
        <RadioButtonSetField
          name="responsible_for_approval"
          label={`Were you responsible for approving ${contractorName}'s work?`}
        />
        <RadioButtonSetField
          name="satisy_requirements"
          label={`Did ${contractorName} satisfy the requirements of the job?`}
        />
        <RadioButtonSetField
          name="work_with_again"
          label={`Would you work with ${contractorName} again?`}
        />
        {submitting ? (
          <div className="text-center" style={{ marginTop: "1rem" }}>
            <i className="far fa-cog fa-spin" /> Loading...
          </div>
        ) : (
          <div>
            <ButtonToolbar className="pull-right">
              <Button bsStyle="link" onClick={closeForm}>
                Cancel
              </Button>
              <Button bsStyle="success" type="submit" disabled={submitting}>
                Submit
              </Button>
            </ButtonToolbar>
            <div className="clearfix" />
          </div>
        )}
      </form>
    );
  }
}

PerformanceReviewForm = compose(
  connect(
    null,
    { notify },
  ),
  reduxForm({
    form: "performanceReview",
    validate,
  }),
)(PerformanceReviewForm);
export default PerformanceReviewForm;
