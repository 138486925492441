import React, { Fragment } from "react";
import { compose, connect, connectRequest } from "queries/utils";
import { Table } from "react-bootstrap";

import { Loading, USState } from "common/components";
import { createResourceDetailQuery, createResourceSelector } from "queries";
import types from "resources/types";

export const JobDetailsRow = props => (
  <tr>
    <td>
      <strong>{props.title}</strong>
    </td>
    <td>{props.children}</td>
  </tr>
);

const JobPaymentDetailsAddress = ({ paymentDetails }) => {
  const {
    address_1,
    address_2,
    city,
    state: stateId,
    postal_code,
  } = paymentDetails;
  const cityDisplay = city && `${city},`;
  const stateDisplay = stateId && <USState stateId={stateId} />;

  const requiredAddressFields = ["address_1", "city", "state", "postal_code"];
  const isAddressProvided = requiredAddressFields.some(
    field => paymentDetails[field],
  );

  return !isAddressProvided ? null : (
    <JobDetailsRow title="Address">
      <Fragment>
        <div>{address_1 && address_1}</div>
        <div>{address_2 && address_2}</div>
        <div>
          {cityDisplay} {stateDisplay} {postal_code}
        </div>
      </Fragment>
    </JobDetailsRow>
  );
};

export const JobPaymentDetailsTable = props => {
  const {
    paymentDetailsId,
    [types.PAYMENT_DETAILS]: { data: paymentDetails, isPending },
  } = props;

  const jobPaymentDetails = paymentDetails[paymentDetailsId];

  return (
    <Fragment>
      {jobPaymentDetails && (
        <Table>
          <tbody>
            <JobDetailsRow title="Worker Classification">
              {jobPaymentDetails.serviceDisplay}
            </JobDetailsRow>
            <JobDetailsRow title="Payment Type">
              {jobPaymentDetails.paymentTypeDisplay}
            </JobDetailsRow>
            <JobDetailsRow title="Rate">
              {jobPaymentDetails.rateDisplay}
            </JobDetailsRow>
            <JobDetailsRow title="Allow Expenses">
              {jobPaymentDetails.allow_expenses ? "Yes" : "No"}
            </JobDetailsRow>
            <JobPaymentDetailsAddress paymentDetails={jobPaymentDetails} />
          </tbody>
        </Table>
      )}
      {isPending && <Loading />}
    </Fragment>
  );
};

const mapPropsToConfig = props =>
  createResourceDetailQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/${props.paymentDetailsId}/`,
  });

const mapStateToProps = createResourceSelector(
  types.PAYMENT_DETAILS,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobPaymentDetailsTable);
