import React, { Component } from "react";
import { connect, compose, connectResource } from "resources/components/utils";
import { addNotification as notify } from "reapop";

import { updateResume } from "resources/actions/ownContractor";
import { ownContractorRequest } from "resources/requests";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import ResumeForm from "contractor/forms/ResumeForm";

const addResumeUrl = "/api/dev/contractors/add_resume/";

class EditResume extends Component {
  handleSubmit = formData => {
    const {
      notify,
      onSubmit,
      onReloadHandlers: { ownContractor: getOwnContractor },
    } = this.props;

    return onSubmit(addResumeUrl, formData).then(({ response }) => {
      if (!response.ok) {
        notify(createErrorNotification());
      } else {
        notify(createSuccessNotification({ message: "Resume uploaded" }));
        getOwnContractor();
      }
    });
  };

  render() {
    const {
      contractor: { resume = "" },
    } = this.props;

    const resumeFileLink = (
      <a href={resume} target="_blank" rel="noopener noreferrer">
        Resume <i className="fas fa-external-link-alt" />
      </a>
    );

    return (
      <div>
        <h2 className="edit-section-title">Resume</h2>
        <ResumeForm
          formDataKey="resume"
          currentResume={resumeFileLink}
          onFileUpload={this.handleSubmit}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      onSubmit: updateResume,
      notify,
    },
  ),
  connectResource(ownContractorRequest),
)(EditResume);
