import types from "resources/types";
import { createResourceDetailQuery, createResourceListQuery } from "queries";

export const schoolsQuery = (config = {}) =>
  createResourceListQuery(types.SCHOOLS, {
    url: "/api/v2/schools/?limit=99",
    ...config,
  });

export const degreeChoicesQuery = (config = {}) =>
  createResourceDetailQuery(types.DEGREE_CHOICES, {
    url: "/api/v2/degree_choices/?limit=99",
    ...config,
  });
