// @flow strict
import styled, { css } from "styled-components";
import { Button as ReactBootstrapButton } from "react-bootstrap";

type Props = {
  bsStyle?: string,
  onClick?: () => void,
};

const NavButton = styled(ReactBootstrapButton).attrs((props: Props) => ({
  bsStyle: props.bsStyle,
  onClick: props.onClick || null,
}))`
  &&&& {
    ${({ bsStyle }) =>
      bsStyle === "primary" &&
      css`
        background-color: ${({ theme }) => theme.colors.navlink()};
        border-color: ${({ theme }) => theme.colors.navlink()};

        &:hover {
          background-color: ${({ theme }) => theme.colors.navlink_hover()};
        }

        &:focus {
          background-color: ${({ theme }) => theme.colors.navlink_focus()};
        }

        &,
        &:hover,
        &:focus {
          color: ${({ theme }) => theme.colors.white()};
        }
      `};

    &,
    &:hover,
    &:focus {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      border-radius: 10px;
      outline: none;
    }

    ${({ theme }) => theme.media.not_sm`
      margin: 14px 0 0 0;
      padding: 10px 20px;
      width: 100%;
    `};

    ${({ theme }) => theme.media.sm`
      float: right;
      margin-right: 20px;
    `};
  }
`;

export default NavButton;
