import * as React from "react";
import moment from "moment";

import { Col } from "react-bootstrap";

import { formatStripeAmountUSD } from "common/utils/helpers";
import { Card, Status } from "./blocks";

const PaymentHistoryResult = ({ paymentHistory }) => (
  <div>
    <h2>{moment.unix(paymentHistory.transaction_date).format("ll")}</h2>
    <Card>
      <Card.Row>
        <Col xs={5}>
          <Card.Title>Amount</Card.Title>
        </Col>
        <Col xs={7}>{formatStripeAmountUSD(paymentHistory.amount)}</Col>
      </Card.Row>
      <Card.Row>
        <Col xs={5}>
          <Card.Title>Method</Card.Title>
        </Col>
        <Col xs={7}>{paymentHistory.source}</Col>
      </Card.Row>
      <Card.Row>
        <Col xs={5}>
          <Card.Title>Status</Card.Title>
        </Col>
        <Col xs={7}>
          <Status status={paymentHistory.status}>
            {paymentHistory.status}
          </Status>
        </Col>
      </Card.Row>
    </Card>
  </div>
);

export default PaymentHistoryResult;
