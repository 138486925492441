import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { submit } from "redux-form";

import { DenyBarInformationForm } from "admin/forms";
import { formName } from "admin/forms/DenyBarInformationForm";
import { USState } from "common/components";

class DenyBarInfoModal extends Component {
  handleSubmit = values => {
    const { verified, overall_rejected } = values;
    let cleanedValues;
    if (verified === "2") {
      cleanedValues = { verified, overall_rejected };
    } else {
      cleanedValues = values;
    }
    this.props.onDeny(cleanedValues);
  };

  render() {
    const { barInfo, submitForm, ...modalProps } = this.props;
    return (
      <Modal bsSize="large" {...modalProps}>
        <Modal.Header closeButton>
          <Modal.Title>
            Deny Bar License: <USState stateId={barInfo.state} />{" "}
            {barInfo.bar_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            Are you sure you want to deny {barInfo.full_name}&apos;s bar
            license?
          </h4>
          <div>
            <DenyBarInformationForm
              initialValues={{
                send_rejection_email: barInfo.send_rejection_email,
              }}
              onSubmit={this.handleSubmit}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="link"
            onClick={modalProps.onHide}
            className="pull-left"
          >
            Cancel
          </Button>
          <Button bsStyle="danger" onClick={submitForm}>
            Deny
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  null,
  {
    submitForm: () => submit(formName),
  },
)(DenyBarInfoModal);
