import React from "react";
import { Col, Row } from "react-bootstrap";

import { PaymentHistory as BasePaymentHistory } from "common/components/payment-history";

const PaymentHistory = () => (
  <div>
    <h1>Payment History</h1>
    <Row>
      <Col md={8} mdOffset={2}>
        <BasePaymentHistory />
      </Col>
    </Row>
  </div>
);

export default PaymentHistory;
