// @flow strict
import * as React from "react";
import { Authentication } from "common/components/auth";

type Props = {
  children: React.Node,
  location: Location,
};

const AuthenticationDetour = (props: Props) => (
  <Authentication
    authenticationRequired
    onAuthPath={props.location.pathname}
    redirectTo="/login"
    {...props}
  />
);

export default AuthenticationDetour;
