import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { SupportLink } from "common/components";
import { ownContractorQuery } from "queries/requests/contractors";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";
import { createStructuredSelector } from "reselect";
import { getResourcesAreReady } from "common/utils/helpers";
import Loading from "common/components/Loading";

const requiredResources = [types.OWN_CONTRACTOR];

class ApplicationDenied extends Component {
  render() {
    const {
      ownContractor: { data: ownContractorData },
    } = this.props;
    const ownContractor = Object.values(ownContractorData)[0];
    const isReady = getResourcesAreReady(requiredResources, this.props);

    return isReady ? (
      <Row>
        <Helmet>
          <title>
            {ownContractor.rejected_message
              ? "Account issue"
              : "Application denied"}
          </title>
        </Helmet>
        <Col md={8}>
          <h2>
            <i className="far fa-exclamation-triangle" />{" "}
            {ownContractor.rejected_message
              ? "Account issue"
              : "Application denied"}
          </h2>
          <p>
            {ownContractor.rejected_message
              ? ownContractor.rejected_message
              : "We appreciate your interest in seeking career opportunities on Hire an Esquire, but unfortunately your application has been denied."}{" "}
            If you have any questions or believe you have received this message
            in error, please contact <SupportLink />
          </p>
          <p style={{ marginTop: "1.6em" }}>
            <Link to="/logout" className="btn btn-info">
              <i className="far fa-arrow-left" /> Log Out
            </Link>
          </p>
        </Col>
      </Row>
    ) : (
      <Loading />
    );
  }
}

const ownContractorSelectorConfig = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);

const mapStateToProps = createStructuredSelector({
  ...ownContractorSelectorConfig,
});

const mapPropsToConfig = () => [ownContractorQuery({ force: true })];

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(ApplicationDenied);
