import React, { Component } from "react";
import { reduxForm, FieldArray } from "redux-form";
import { compose } from "redux";

import { FormRow, OptionalFormMessage, withFormError } from "./utils";
import { BarRequirementsFieldArray } from "common/components/forms/inputs";

class BarRequirementsForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormRow>
          <FieldArray
            name="bar_requirements"
            component={BarRequirementsFieldArray}
          />
          <p>
            We&apos;ll recommend attorneys that have at least one of the
            required bar licenses.
          </p>
        </FormRow>
      </form>
    );
  }
}

BarRequirementsForm = compose(
  reduxForm({
    validate: BarRequirementsFieldArray.validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(BarRequirementsForm);

export default class BarRequirements extends Component {
  render() {
    const {
      formProps,
      jobListing: { bar_requirements = [] },
    } = this.props;
    const initialValues = {
      bar_requirements,
    };
    return (
      <div>
        <h2>Is a particular bar admission required?</h2>
        <div className="description">
          <OptionalFormMessage />
        </div>
        <BarRequirementsForm {...formProps} initialValues={initialValues} />
      </div>
    );
  }
}
