import React from "react";
import PropTypes from "prop-types";
import { Popover, OverlayTrigger } from "react-bootstrap";

const ToolTip = ({ title = null, content, placement = "bottom", children }) => {
  const triggerElement = children || <i className="far fa-info-circle" />;
  const overlay = (
    <Popover id={`popover-${title}`} title={title}>
      {content}
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={["hover", "click"]}
      placement={placement}
      overlay={overlay}
    >
      {triggerElement}
    </OverlayTrigger>
  );
};

ToolTip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node.isRequired,
  placement: PropTypes.string,
  hasChild: PropTypes.bool,
};
export default ToolTip;
