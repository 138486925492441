import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import setCampaignParams from "common/components/setCampaignParams";
import setDocumentTitle from "common/components/setDocumentTitle";
import icon from "img/scales.svg";
import * as Signup from "./blocks";

export class UserSelect extends Component {
  render() {
    return (
      <div>
        <div className="signup-identity-container">
          <img alt="scales icon" src={icon} height="100" width="100" />
          <div>
            <Signup.Title>Welcome to Modern Legal Staffing</Signup.Title>
            <Signup.Subtitle>
              Law firms and legal departments can now effortlessly connect with
              legal talent.
            </Signup.Subtitle>
          </div>
          <Row>
            <Col md={6}>
              <Signup.ButtonTitleText>
                Have a project or role to staff?
              </Signup.ButtonTitleText>
              <LinkContainer to={{ pathname: "/agency/signup/" }}>
                <Signup.SignupButton>
                  I am seeking legal talent
                </Signup.SignupButton>
              </LinkContainer>
            </Col>
            <Col md={6}>
              <Signup.ButtonTitleText>
                Looking for a legal role?
              </Signup.ButtonTitleText>
              <LinkContainer to={{ pathname: "/signup/legal_professional/" }}>
                <Signup.SignupButton>
                  I am seeking career opportunities
                </Signup.SignupButton>
              </LinkContainer>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default setDocumentTitle("Signup")(setCampaignParams(UserSelect));
