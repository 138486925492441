// @flow strict
import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.mobile`
    padding: 15px;
    box-shadow: 0 4px 8px 0 ${theme.colors.shadow()};
    border: 1px solid ${theme.colors.primary_black10()}
    border-radius: 6px;
    background-color: white;
  `};
`;

export default Container;
