import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { connect, compose, connectResource } from "resources/components/utils";
import { createStructuredSelector } from "reselect";

import { messagesRequest } from "resources/requests";
import { getMessages } from "resources/selectors";
import Loading from "common/components/Loading";
import { getMessageTimeDisplay } from "common/utils";

const MessagesPreview = ({ messages }) => (
  <Table bordered striped responsive className="contractor-messages-preview">
    <thead>
      <tr>
        <th>With</th>
        <th>Subject</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      {messages.map(message => (
        <tr
          key={`message-thread-${message.uuid}`}
          className={message.unread ? "unread" : ""}
        >
          <td>
            {message.from_self
              ? `${message.recipients[0].first_name} ${
                  message.recipients[0].last_name
                }`
              : `${message.sender.first_name} ${message.sender.last_name}`}
          </td>
          <td>
            <Link to={`/contractor/messages/${message.uuid}`}>
              {message.subject}
            </Link>
          </td>
          <td>{getMessageTimeDisplay(message.created)}</td>
        </tr>
      ))}
      {messages.length > 0 && (
        <tr>
          <td colSpan={3}>
            <div className="text-center">
              <Link to="/contractor/messages/">View all messages</Link>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

class Messages extends Component {
  render() {
    const {
      messages: {
        metadata: { isLoading, lastUpdated },
        data,
      },
    } = this.props;
    const messages = Object.values(data);
    messages.sort((a, b) => {
      const dateA = new Date(a.created);
      const dateB = new Date(b.created);
      return dateB - dateA;
    });
    return (
      <div>
        <h3>
          <i className="far fa-envelope" />&nbsp;Messages
        </h3>
        {isLoading ? (
          <Loading />
        ) : (
          lastUpdated && <MessagesPreview messages={messages} />
        )}
      </div>
    );
  }
}

export default compose(
  connect(createStructuredSelector({ messages: getMessages })),
  connectResource(messagesRequest),
)(Messages);
