// @flow strict
import styled from "styled-components";

const Headline = styled.h2`
  ${({ theme }) => theme.media.mobile`
    margin-top: 0.5rem;
    margin-bottom: 0;
  `};
`;

export default Headline;
