import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

import NavButton from "common/blocks/NavButton";

const Title = styled(Modal.Title)`
  font-size: 32px;
`;

const OnboardingBlock = styled(Modal)`
  &&& {
    h4,
    p {
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      font-size: 18px;
    }
  }
`;

const Onboarding = props => <OnboardingBlock {...props} />;

Onboarding.Button = NavButton;
Onboarding.Title = Title;

export default Onboarding;
