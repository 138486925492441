import React, { Component } from "react";
import {
  Button,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class CopyToClipboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };
  }

  handleCopyClick = () => this.setState({ copied: true });

  handleSelectText = () => {
    if (this.textInput) this.textInput.select();
  };

  render() {
    const {
      state: { copied },
      props: { label, value },
    } = this;

    return (
      <div>
        <p>
          <b>{label}</b>
        </p>
        <InputGroup>
          <FormControl
            type="text"
            readOnly
            value={value}
            inputRef={input => {
              this.textInput = input;
            }}
            onFocus={this.handleSelectText}
          />
          <InputGroup.Button>
            <CopyToClipboard text={value} onCopy={this.handleCopyClick}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="share-copy-tooltip">
                    {copied ? "Copied!" : "Copy to Clipboard"}
                  </Tooltip>
                }
              >
                <Button style={{ height: "34px" }}>
                  <i className="far fa-clipboard text-muted" />
                </Button>
              </OverlayTrigger>
            </CopyToClipboard>
          </InputGroup.Button>
        </InputGroup>
      </div>
    );
  }
}
