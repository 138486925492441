import actions from "hiringagent-dashboard/actions/jobs";
import agencyActions from "hiringagent-dashboard/actions/agency";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  jobs: {},
  jobsLoaded: false,
  allLoaded: false,
  closeJobModal: null,
  message: "",
  formInitializedForJob: false,
  createJobPage: 0,
  agencyRates: {
    hae_1099_rate: {
      required: false,
      active: true,
      form: 1,
    },
    hae_w2_rate: {
      required: false,
      active: true,
      form: 1,
    },
  },
  fields: {
    service: {
      required: true,
      active: true,
      form: 0,
    },
    position_type: {
      required: true,
      active: true,
      form: 0,
    },
    name: {
      required: true,
      active: true,
      form: 0,
    },
    allow_attorneys: {
      required: true,
      active: true,
      form: 0,
      initial: true,
    },
    allow_non_attorneys: {
      required: true,
      active: true,
      form: 0,
      initial: false,
    },
    expected_duration_description: {
      required: true,
      active: true,
      form: 0,
    },
    start_date: {
      required: true,
      active: true,
      form: 0,
    },
    number_of_openings: {
      required: true,
      active: true,
      form: 0,
      initial: 1,
    },
    short_description: {
      required: true,
      active: true,
      form: 0,
    },
    payment_type: {
      required: true,
      active: true,
      form: 1,
    },
    billing_increment_minutes: {
      required: true,
      active: true,
      form: 1,
    },
    hae_1099_rate: {
      required: false,
      active: true,
      form: 1,
    },
    hae_w2_rate: {
      required: false,
      active: true,
      form: 1,
    },
    hae_rate: {
      required: true,
      active: true,
      form: 1,
    },
    rate: {
      required: false,
      active: true,
      form: 1,
    },
    total_rate: {
      required: false,
      active: true,
      form: 1,
    },
    min_years_experience: {
      required: true,
      active: true,
      form: 2,
      initial: 0,
    },
    max_years_experience: {
      required: true,
      active: true,
      form: 2,
      initial: 50,
    },
    tags: {
      required: true,
      active: true,
      form: 2,
    },
    location: {
      required: true,
      active: true,
      form: 2,
    },
    jobpostinglanguagefluency_set: {
      required: false,
      active: true,
      form: 2,
    },
    work_from_home: {
      required: true,
      active: true,
      form: 2,
      initial: false,
    },
    searchable: {
      required: true,
      active: true,
      form: 2,
      initial: true,
    },
    anonymous: {
      required: true,
      active: true,
      form: 2,
      initial: true,
    },
    notify: {
      required: true,
      active: true,
      form: 2,
      initial: true,
    },
    allow_expenses: {
      required: true,
      active: true,
      form: 2,
      initial: false,
    },
    send_daily_reminder: {
      required: true,
      active: true,
      form: 2,
      initial: false,
    },
  },
  invitedContractors: {},
  isLoading: null,
  lastUpdated: null,
  error: null,
};

const jobs = handleActions(
  {
    [actions.REQUEST_JOB]: state => ({
      ...state,
      jobsLoaded: false,
      isLoading: true,
    }),
    [actions.RECEIVE_JOB]: (state, action) => ({
      ...state,
      jobs: { ...state.jobs, [action.payload.uuid]: action.payload },
      jobsLoaded: true,
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
      error: action.error,
    }),
    [actions.REQUEST_JOBS]: state => ({
      ...state,
      jobsLoaded: false,
      isLoading: true,
    }),
    [actions.RECEIVE_JOBS]: (state, action) => ({
      ...state,
      jobs: listToObject(action.payload.results, "uuid"),
      jobsLoaded: true,
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
      allLoaded: action.payload.allFetched,
      error: action.error,
    }),
    [actions.CREATE_JOB_NEXT_PAGE]: state => ({
      ...state,
      createJobPage: state.createJobPage + 1,
    }),
    [actions.CREATE_JOB_PREVIOUS_PAGE]: state => ({
      ...state,
      createJobPage: state.createJobPage - 1,
    }),
    [actions.GO_TO_CREATE_JOB_PAGE]: (state, action) => ({
      ...state,
      createJobPage: action.payload,
    }),
    [actions.UPDATE_FIELD]: (state, action) => ({
      ...state,
      fields: {
        ...state.fields,
        [action.payload.fieldName]: {
          ...state.fields[action.payload.fieldName],
          ...action.payload.field,
        },
      },
    }),
    [actions.REQUEST_INVITED_CONTRACTORS]: state => ({
      ...state,
      invitedContractors: {},
    }),
    [actions.RECEIVE_INVITED_CONTRACTORS]: (state, action) => ({
      ...state,
      invitedContractors: { ...state.invitedContractors, ...action.payload },
    }),
    [actions.REQUEST_SUBMIT_CREATE_JOB]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_SUBMIT_CREATE_JOB]: (state, action) => ({
      ...state,
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
      jobs: {
        ...state.jobs,
        [action.payload.uuid]: {
          ...action.payload,
        },
      },
    }),
    [actions.REQUEST_SUBMIT_UPDATE_JOB]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_SUBMIT_UPDATE_JOB]: (state, action) => {
      const updatedJob = action.payload.hasOwnProperty("uuid")
        ? { [action.payload.uuid]: { ...action.payload } }
        : {};

      return {
        ...state,
        lastUpdated: action.meta.receivedAt,
        isLoading: false,
        jobs: {
          ...state.jobs,
          ...updatedJob,
        },
      };
    },
    [actions.RESET_FORM]: state => ({
      ...state,
      fields: {
        ...initialState.fields,
        ...state.agencyRates,
      },
      formInitializedForJob: false,
      createJobPage: 0,
    }),
    [actions.INITIALIZE_FORM_FOR_JOB]: (state, action) => ({
      ...state,
      formInitializedForJob: true,
      fields: {
        ...state.fields,
        ...Object.keys(action.payload).reduce(
          (result, item) => ({
            ...result,
            [item]: {
              ...state.fields[item],
              initial: action.payload[item],
            },
          }),
          {},
        ),
      },
    }),
    [actions.INITIALIZE_FORM_FOR_NEW_JOB]: state => ({
      ...state,
      formInitializedForJob: false,
      fields: {
        ...state.fields,
        ...state.agencyRates,
      },
    }),
    [actions.RECEIVE_CLOSE_JOB]: (state, action) => ({
      ...state,
      jobs: {
        ...state.jobs,
        [action.payload.jobId]: {
          ...state.jobs[action.payload.jobId],
          closed_date: action.payload.closed_date,
        },
      },
    }),
    [actions.TOGGLE_WORKER_ACTIVE]: (state, action) => ({
      ...state,
      jobs: {
        ...state.jobs,
        [action.payload.jobId]: {
          ...state.jobs[action.payload.jobId],
          jobworker_set: [
            ...state.jobs[action.payload.jobId].jobworker_set.slice(
              0,
              action.payload.workerIndex,
            ),
            {
              ...state.jobs[action.payload.jobId].jobworker_set[
                action.payload.workerIndex
              ],
              active: !state.jobs[action.payload.jobId].jobworker_set[
                action.payload.workerIndex
              ].active,
            },
            ...state.jobs[action.payload.jobId].jobworker_set.slice(
              action.payload.workerIndex + 1,
            ),
          ],
        },
      },
    }),
    [actions.TOGGLE_JOB_MODAL]: (state, action) => ({
      ...state,
      closeJobModal: action.payload,
    }),
    [actions.REMOVE_JOB_ALERT_MESSAGE]: state => ({
      ...state,
      message: "",
    }),
    [actions.ADD_JOB_ALERT_MESSAGE]: (state, action) => ({
      ...state,
      message: action.payload,
    }),
    [agencyActions.RECEIVE_AGENCY]: (state, action) => ({
      ...state,
      agencyRates: {
        ...state.agencyRates,
        hae_1099_rate: {
          ...state.agencyRates.hae_1099_rate,
          initial: action.payload.hae_1099_rate,
        },
        hae_w2_rate: {
          ...state.agencyRates.hae_w2_rate,
          initial: action.payload.hae_w2_rate,
        },
      },
    }),
  },
  initialState,
);
export default jobs;
