import React from "react";
import { Alert } from "react-bootstrap";

const ErrorAlert = ({ error }) => (
  <Alert bsStyle="danger">
    {Array.isArray(error) ? (
      error.length > 1 ? (
        <ul>{error.map((err, i) => <li key={i}>{err}</li>)}</ul>
      ) : (
        <p>{error[0]}</p>
      )
    ) : (
      <p>{error}</p>
    )}
  </Alert>
);

export default ErrorAlert;
