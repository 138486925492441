import styled from "styled-components";

const Grid = styled.div`
  display: grid;

  ${({ theme }) => theme.media.sm`
    grid-template-columns: auto 1fr;
    grid-column-gap: 15px;
    min-height: 50px;
  `};

  ${({ theme }) => theme.media.not_sm`
    grid-template-rows: auto 1fr;
  `};
`;

export default Grid;
