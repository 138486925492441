import { handleActions } from "redux-actions";

import actions from "admin/actions/nonAttorneyVerification";
import { listToObject } from "admin/utils";
import { utils } from "resources/reducer/resourceReducers/nonAttorneys";

const initialNonAttorneysState = () => ({
  items: [],
  count: -1,
  isLoading: false,
  lastUpdated: null,
});

const initialState = {
  notStarted: initialNonAttorneysState(),
  pending: initialNonAttorneysState(),
  verified: initialNonAttorneysState(),
  rejected: initialNonAttorneysState(),
  allNonAttorneys: {},
  selectedRow: null,
};

const updateAllNonAttorneys = (state, nonAttorney) => ({
  ...state,
  allNonAttorneys: {
    ...state.allNonAttorneys,
    [nonAttorney.uuid]: nonAttorney,
  },
});

const nonAttorneyVerification = handleActions(
  {
    "RECEIVE/resource": (state, action) => {
      if (action.meta.options.path !== "non_attorneys") {
        return state;
      }
      return {
        ...state,
        allNonAttorneys: {
          ...state.allNonAttorneys,
          ...listToObject(action.payload.results, "uuid"),
        },
      };
    },
    [actions.SELECT_NON_ATTORNEY_ROW]: (state, action) => ({
      ...state,
      selectedRow: action.payload === state.selectedRow ? null : action.payload,
    }),
    [actions.RECEIVE_CHANGE_SCHOOL_TRANSCRIPT_STATUS]: (state, action) => {
      if (action.error) return state;

      const { nonAttorney, schoolTranscript } = action.payload;

      utils.replaceNonAttorneyTranscript(nonAttorney, schoolTranscript);
      return updateAllNonAttorneys(state, nonAttorney);
    },
    [actions.RECEIVE_CHANGE_EMPLOYMENT_REFERENCE_STATUS]: (state, action) => {
      if (action.error) return state;

      const { nonAttorney, employmentReference } = action.payload;
      utils.replaceNonAttorneyEmploymentReference(
        nonAttorney,
        employmentReference,
      );
      return updateAllNonAttorneys(state, nonAttorney);
    },
    [actions.RECEIVE_CHANGE_REFERENCE_STATUS]: (state, action) => {
      if (action.error) return state;

      const { nonAttorney, review } = action.payload;
      utils.replaceNonAttorneyReference(nonAttorney, review);
      return updateAllNonAttorneys(state, nonAttorney);
    },
    [actions.RECEIVE_UPDATED_CONTRACTOR]: (state, action) => {
      if (action.error) return state;
      return updateAllNonAttorneys(state, action.payload);
    },
  },
  initialState,
);
export default nonAttorneyVerification;
