import React from "react";
import { Button } from "react-bootstrap";
import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";

const DeleteModal = ({ onClose, onSubmit, show, type = "expense" }) => {
  const body = (
    <div className="text-center">
      {`Are you sure you want to delete this ${type}?`}
    </div>
  );
  const deleteButton = (
    <Button bsStyle="danger" onClick={onSubmit} className="pull-right">
      Delete
    </Button>
  );

  return (
    <ConfirmModal
      show={show}
      close={onClose}
      body={body}
      actions={deleteButton}
      title={`Confirm delete ${type}`}
    />
  );
};

export default DeleteModal;
