import React, { Component } from "react";
import { connect } from "react-redux";

import {
  requestMessages,
  requestMessageThread,
} from "hiringagent-dashboard/actions/messages";
import { messagesSliceSelector } from "hiringagent-dashboard/selectors";
import { createConnector } from "common/connectors/utils";

import Loading from "common/components/Loading";

export const withMessages = createConnector(
  "WithMessages",
  messagesSliceSelector,
  { onLoad: requestMessages },
);

export const _withMessageThread = threadId => WrappedComponent => {
  class WithMessageThread extends Component {
    componentDidMount() {
      const {
        messages: { messageThreads, isLoading },
      } = this.props;

      if (threadId && !messageThreads[threadId] && !isLoading) {
        this.props.onLoad(threadId);
      }
    }

    render() {
      const {
        messages: { messageThreads },
      } = this.props;
      const messageThread = messageThreads[threadId];

      return messageThread ? (
        <WrappedComponent messageThread={messageThread} {...this.props} />
      ) : (
        <Loading />
      );
    }
  }

  WithMessageThread = connect(
    messagesSliceSelector,
    { onLoad: requestMessageThread },
  )(WithMessageThread);
  return WithMessageThread;
};

export const withMessageThread = mapPropsToId => WrappedComponent => {
  class WithMessageThread extends Component {
    constructor(props) {
      super(props);
      this.state = {
        threadId: null,
        ConnectedComponent: null,
      };
    }

    componentWillMount() {
      const threadId = mapPropsToId(this.props);
      const newState = {
        threadId,
        ConnectedComponent: threadId
          ? _withMessageThread(threadId)(WrappedComponent)
          : null,
      };
      this.setState(newState);
    }

    componentWillReceiveProps(nextProps) {
      const { threadId } = this.state;
      const newThreadId = mapPropsToId(nextProps);
      if (newThreadId && newThreadId !== threadId) {
        this.setState({
          threadId: newThreadId,
          ConnectedComponent: _withMessageThread(newThreadId)(WrappedComponent),
        });
      }
    }

    render() {
      const { ConnectedComponent } = this.state;
      return ConnectedComponent ? (
        <ConnectedComponent {...this.props} />
      ) : (
        <Loading />
      );
    }
  }

  return WithMessageThread;
};

export default withMessageThread;
