export const canEditJob = (job, hiringAgent) =>
  job.is_editable &&
  (hiringAgent.is_admin || hiringAgent.haeuser === job.posting_user);

export const compareDateTimes = (a, b) => {
  const dateA = new Date(a.created);
  const dateB = new Date(b.created);
  return dateA - dateB;
};

export const isCompleted = review => {
  if (
    review.responsible_for_approval !== null &&
    review.satisy_requirements !== null &&
    review.work_with_again !== null
  ) {
    return true;
  }
  return false;
};

export const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";
