// @flow strict
import styled from "styled-components";

const StatusLabel = styled.span`
  border: 2px solid ${({ theme }) => theme.colors.primary_turquoise()};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.primary_turquoise()};
  font-size: 80%;
  font-weight: bold;
  padding: 0.3rem 0.6rem;
  margin-top: 0.3rem;
`;

export default StatusLabel;
