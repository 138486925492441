// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

const BarOuter: ComponentType<{}> = styled.div`
  width: 100%;
  height: 16px;
  margin: 0;
  padding: 3px;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.primary_black40()};
  border-radius: 34px;
`;

export default BarOuter;
