import React from "react";
import moment from "moment";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import {
  Col,
  FormGroup,
  ControlLabel,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import AttorneySearchInputs from "admin/components/search-history/AttorneySearchInputs";
import HiringAgentSearchInputs from "admin/components/search-history/HiringAgentSearchInputs";

const SearchHistoryInputs = props => (
  <div>
    <Col md={2}>
      <FormGroup className="form-control-react-datepicker">
        <ControlLabel>Start Date</ControlLabel>
        <InputGroup>
          <DatePicker
            {...props.searchParams.start_date}
            className="form-control"
            selected={
              props.searchParams.start_date
                ? moment(props.searchParams.start_date, "YYYY-MM-DD").toDate()
                : null
            }
            dateFormat="yyyy-MM-dd"
            placeholderText={moment().format("YYYY-MM-DD")}
            onChange={value =>
              props.handleSearchParamsChange({
                start_date: moment(value).format("YYYY-MM-DD"),
              })
            }
            showMonthDropdown
            showYearDropdown
            todayButton={"Today"}
            dropdownMode="select"
          />
          <InputGroup.Addon>
            <i className="fas fa-calendar-alt" />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup className="form-control-react-datepicker">
        <ControlLabel>End Date</ControlLabel>
        <InputGroup>
          <DatePicker
            {...props.searchParams.end_date}
            className="form-control"
            selected={
              props.searchParams.end_date
                ? moment(props.searchParams.end_date, "YYYY-MM-DD").toDate()
                : null
            }
            dateFormat="yyyy-MM-dd"
            placeholderText={moment().format("YYYY-MM-DD")}
            onChange={value =>
              props.handleSearchParamsChange({
                end_date: moment(value).format("YYYY-MM-DD"),
              })
            }
            showMonthDropdown
            showYearDropdown
            todayButton={"Today"}
            dropdownMode="select"
          />
          <InputGroup.Addon>
            <i className="fas fa-calendar-alt" />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    </Col>
    <Col md={3}>
      <FormGroup>
        <ControlLabel>{`${props.userType}s`}</ControlLabel>
        <ReactSelect
          name={props.userType}
          id={props.userType}
          options={props.haeUserOptions}
          multi={false}
          placeholder={
            props.haeUsersLoading ? "Loading..." : `Select ${props.userType}`
          }
          onChange={newValue =>
            props.handleSearchParamsChange({
              haeuser_id: newValue ? newValue.value : "",
            })
          }
          value={props.searchParams.haeuser_id}
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <ControlLabel>Keywords</ControlLabel>
        <FormControl
          type="text"
          onChange={e =>
            props.handleSearchParamsChange({ keywords: e.target.value })
          }
        />
      </FormGroup>
    </Col>
    {props.userType === "Contractor" ? (
      <AttorneySearchInputs {...props} />
    ) : (
      <HiringAgentSearchInputs {...props} />
    )}
    <Button bsStyle="default" onClick={() => props.requestSearchHistories()}>
      Submit
    </Button>
  </div>
);

export default SearchHistoryInputs;
