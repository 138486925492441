// @flow strict
import React from "react";

const ExpiredConfirmationLink = () => (
  <div>
    <h1>Expired Link</h1>
    <p>
      For security purposes, this link has expired. Please ask your account
      owner to re-confirm your account.
    </p>
  </div>
);

export default ExpiredConfirmationLink;
