import React from "react";
import querystring from "querystring";

export default function requireAuthToken(Component) {
  class TokenAuthenticatedComponent extends React.Component {
    render() {
      const {
        location: { search },
      } = this.props;
      const query = querystring.parse(search.substring(1));
      // Just make sure we have an auth token. The API call within the child component will actually validate it
      // TODO: Create an auth token API endpoint and check for this token's existance here
      return query.auth_token ? (
        <Component {...this.props} />
      ) : (
        <div>Authentication Required</div>
      );
    }
  }

  return TokenAuthenticatedComponent;
}
