// @flow strict
import React from "react";
import {
  IconCheckCircleBlock,
  IconCircleBack,
  IconCircleFront,
} from "./blocks/Icons";

type Props = {
  onClick: () => void,
};

export { IconRemove, IconLock } from "./blocks/Icons";

export const IconBars = () => <i className="far fa-bars" aria-hidden="true" />;

export const IconCircle = (props: Props) => (
  <span className="fa-stack" {...props}>
    <IconCircleBack />
    <IconCircleFront />
  </span>
);

export const IconCheckCircle = (props: Props) => (
  <IconCheckCircleBlock {...props} />
);
