import React from "react";

import { languageProficiencies } from "common/constants";
import { SelectField } from "common/components/forms/inputs";
import LanguageTooltip from "common/components/LanguageTooltip";

const proficiencySelectOptions = languageProficiencies.map(proficiency => ({
  label: proficiency[0],
  value: proficiency[1],
}));

const fieldLabel = (
  <span>
    Proficiency{" "}
    {<LanguageTooltip id={"contractor-edit-languages"} placement="bottom" />}
  </span>
);

const LanguageProficiencySelect = () => (
  <SelectField name="fluency" label={fieldLabel}>
    {proficiencySelectOptions.map(({ label, value }) => (
      <option key={`language-option-${label}`} value={value}>
        {`${label} (${value})`}
      </option>
    ))}
  </SelectField>
);

export default LanguageProficiencySelect;
