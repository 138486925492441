import React, { Component } from "react";
import { Button, ButtonToolbar, Label } from "react-bootstrap";
import moment from "moment";

import Loading from "common/components/Loading";
import { withExperience } from "contractor/connectors";

export const ExperienceReferenceListInfo = ({ reference }) => (
  <div>
    <dt>Reference</dt>
    <dd>
      {reference.reference_first_name} {reference.reference_last_name}
    </dd>
    <dt>Reference Email</dt>
    <dd>{reference.reference_email}</dd>
    <dt>Reference Phone</dt>
    <dd>{reference.reference_phone_number}</dd>
  </div>
);

export const ExperienceListInfo = ({ experience }) => (
  <div>
    {experience.job_title && <dt>Job Title</dt>}
    <dd>{experience.job_title}</dd>
    <dt>Company</dt>
    <dd>{experience.company_name}</dd>
    {experience.position_type && <dt>Position Type</dt>}
    <dd>{experience.position_type}</dd>
    {experience.firm_type && <dt>Firm Type</dt>}
    <dd>{experience.firm_type}</dd>
    <dt>Date</dt>
    <dd>
      {moment(experience.start_date).format("MMM Do YYYY")}
      {experience.end_date
        ? ` - ${moment(experience.end_date).format("MMM Do YYYY")}`
        : " to Present"}
    </dd>
    <dt>Description</dt>
    <dd>{experience.description}</dd>
    {experience.tags.length > 0 && <dt>Areas of Expertise</dt>}
    <dd>
      {experience.tags.map(tag => (
        <Label key={tag.uuid} style={{ marginRight: "3px" }}>
          {tag.name}
        </Label>
      ))}
    </dd>
    {experience.previousemploymentreference && (
      <ExperienceReferenceListInfo
        reference={experience.previousemploymentreference}
      />
    )}
  </div>
);

class ListExperience extends Component {
  handleDeleteClick = experienceId => () =>
    this.props.onDeleteClick(experienceId);

  handleEditClick = experienceId => () => this.props.onEditClick(experienceId);

  render() {
    const {
      experience: {
        data: { results = [] },
        isLoading,
        lastUpdated,
      },
    } = this.props;

    return (
      <div>
        {lastUpdated &&
          !isLoading &&
          results.map(experience => (
            <div key={`experience-${experience.uuid}`}>
              <dl className="dl-horizontal">
                <ExperienceListInfo experience={experience} />
                <dt style={{ paddingTop: "5px" }}>Actions</dt>
                <dd style={{ paddingTop: "5px" }}>
                  <ButtonToolbar>
                    <Button
                      bsSize="xs"
                      bsStyle="primary"
                      style={{ float: "none" }}
                      onClick={this.handleEditClick(experience.uuid)}
                    >
                      Edit
                    </Button>
                    <Button
                      bsSize="xs"
                      bsStyle="danger"
                      style={{ float: "none" }}
                      onClick={this.handleDeleteClick(experience.uuid)}
                    >
                      Delete
                    </Button>
                  </ButtonToolbar>
                </dd>
              </dl>
              <hr />
            </div>
          ))}
        {isLoading ? (
          <Loading />
        ) : (
          results.length === 0 && (
            <div>
              <p className="text-center">
                You don't have any work experiences on file. Use the form below
                to add some.
              </p>
              <hr />
            </div>
          )
        )}
      </div>
    );
  }
}

export default withExperience()(ListExperience);
