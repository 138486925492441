import fetch from "isomorphic-fetch";

import { formatDate, getCookie, listToObject } from "common/utils";

import { fetchEventsIfNeeded } from "admin/actions/invoicing/jobEvents";
import { createApiUrl } from "utils";

export const REQUEST_OPEN_INVOICES = "REQUEST_OPEN_INVOICES";
export const RECEIVE_OPEN_INVOICES = "RECEIVE_OPEN_INVOICES";
export const GENERATE_INVOICE = "GENERATE_INVOICE";
export const GENERATE_INVOICE_COMPLETE = "GENERATE_INVOICE_COMPLETE";

export function requestOpenInvoices() {
  return {
    type: REQUEST_OPEN_INVOICES,
  };
}

export function receiveOpenInvoices(json) {
  const items = listToObject(json.results, "uuid");
  Object.keys(items).forEach(uuid => {
    items[uuid].selected = false;
  });
  return {
    type: RECEIVE_OPEN_INVOICES,
    receivedAt: Date.now(),
    items,
  };
}

function fetchOpenInvoices() {
  return dispatch => {
    dispatch(requestOpenInvoices());

    return fetch(
      createApiUrl("/api/dev/invoices/?format=json&limit=999&open=true"),
      {
        credentials: "include",
      },
    )
      .then(response => response.json())
      .then(json => dispatch(receiveOpenInvoices(json)));
  };
}

export function fetchOpenInvoicesIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.admin.invoicing.invoices.isFetching) {
      return dispatch(fetchOpenInvoices());
    }
    return undefined;
  };
}

export function generateInvoice() {
  return {
    type: GENERATE_INVOICE,
  };
}

export function generateInvoiceComplete() {
  return {
    type: GENERATE_INVOICE_COMPLETE,
  };
}

export function generateInvoiceIfNeeded(jobId) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.admin.invoicing.invoices.isGenerating) {
      dispatch(generateInvoice());

      return fetch(
        createApiUrl(`/api/v2/jobs/${jobId}/generate_invoice/?format=json`),
        {
          method: "POST",
          credentials: "include",
          headers: {
            "X-CSRFToken": getCookie("csrftoken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            job_uuid: jobId,
            start_date: formatDate(state.admin.invoicing.dates.startDate),
            end_date: formatDate(state.admin.invoicing.dates.endDate),
          }),
        },
      ).then(
        // Not expecting data to be returned
        () => {
          dispatch(generateInvoiceComplete());
          dispatch(fetchOpenInvoicesIfNeeded());
          dispatch(fetchEventsIfNeeded());
        },
      );
    }
    return undefined;
  };
}
