// @flow
import { type Uuid } from "./primitives";

export default class HiringAgency {
  uuid: Uuid;
  name: string;
  slug: string;
  phone_main: string;
  website: string;
  hae_1099_rate: number;
  hae_w2_rate: number;
  needs_id: Uuid;
  verified: string;
  verified_information: string;
  payment_method_override: boolean;
  custom_payment_period: ?string;
  firm_type: Object;
  created: string;
  updated: string;
  automatic_recommendations: boolean;
  can_pay: boolean;
  onboarding_state: ?string;

  constructor(hiringAgency: Object) {
    this.uuid = hiringAgency.uuid;
    this.name = hiringAgency.name;
    this.slug = hiringAgency.slug;
    this.phone_main = hiringAgency.phone_main;
    this.website = hiringAgency.website;
    this.hae_1099_rate = hiringAgency.hae_1099_rate;
    this.hae_w2_rate = hiringAgency.hae_w2_rate;
    this.needs_id = hiringAgency.needs_id;
    this.verified = hiringAgency.verified;
    this.verified_information = hiringAgency.verified_information;
    this.payment_method_override = hiringAgency.payment_method_override;
    this.custom_payment_period = hiringAgency.custom_payment_period;
    this.firm_type = hiringAgency.firm_type;
    this.created = hiringAgency.created;
    this.updated = hiringAgency.updated;
    this.automatic_recommendations = hiringAgency.automatic_recommendations;
    this.can_pay = hiringAgency.can_pay;
    this.onboarding_state = hiringAgency.onboarding_state;
  }
}
