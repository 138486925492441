import React from "react";
import { connect } from "react-redux";

import { requestFixture } from "common/actions/fixtures";
import { fixturesSelector } from "common/selectors";

const connectFixtures = Component => {
  class ConnectFixtures extends React.Component {
    UNSAFE_componentWillMount() {
      this.props.onLoad();
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  }

  return connect(
    fixturesSelector,
    dispatch => ({
      onLoad: () => {
        dispatch(requestFixture("tags"));
        dispatch(requestFixture("degrees"));
        dispatch(requestFixture("firmTypes"));
        dispatch(requestFixture("positionTypes"));
      },
    }),
  )(ConnectFixtures);
};

export default connectFixtures;
