import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { metaGenerator } from "common/actions";
import api, { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_RECOMMENDATIONS: null,
  RECEIVE_RECOMMENDATIONS: null,
  DISMISS_RECOMMENDATION: null,
  REQUEST_INVITE_RECOMMENDATION: null,
  RECEIVE_INVITE_RECOMMENDATION: null,
  MARK_RECOMMENDATION_INVITED: null,
  MARK_RECOMMENDATION_DISMISSED: null,
});
export default actions;

export const requestRecommendations = () =>
  api.get(
    // TODO: remove limit query param when using requestRecommendationsForJob for recommended candidates section
    "/api/dev/recommendations/?limit=9999",
    [actions.REQUEST_RECOMMENDATIONS, actions.RECEIVE_RECOMMENDATIONS],
  );

export function dismissRecommendation(recommendationId) {
  return dispatch =>
    oldApi.del(`/api/dev/recommendations/${recommendationId}/`).then(() => {
      dispatch(
        createAction(
          actions.DISMISS_RECOMMENDATION,
          payload => payload,
          metaGenerator,
        )(recommendationId),
      );
    });
}

export const inviteRecommendation = recommendationId => dispatch => {
  dispatch(
    createAction(actions.REQUEST_INVITE_RECOMMENDATION)(recommendationId),
  );
  return oldApi
    .post(`/api/dev/recommendations/${recommendationId}/invite/`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error inviting recommendation.");
      dispatch(
        createAction(
          actions.RECEIVE_INVITE_RECOMMENDATION,
          null,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const markRecommendationInvited = recommendationId =>
  createAction(actions.MARK_RECOMMENDATION_INVITED)({ recommendationId });

export const markRecommendationDismissed = recommendationId =>
  createAction(actions.MARK_RECOMMENDATION_DISMISSED)({ recommendationId });
