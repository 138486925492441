export const verificationStatusMapping = {
  none: {
    text: "Not Required",
    style: "muted",
  },
  init: {
    text: "Pending",
    style: "warning",
  },
  requested: {
    text: "Upload Now",
    style: "warning",
  },
  uploaded: {
    text: "Uploaded",
    style: "warning",
  },
  verified: {
    text: "Verified",
    style: "success",
    icon: "far fa-check",
  },
  failed: {
    text: "Failed",
    style: "danger",
    icon: "far fa-times",
  },
  verification_declined: {
    text: "Declined",
    style: "danger",
    icon: "far fa-times",
  },
  denied: {
    text: "Denied",
    style: "danger",
    icon: "far fa-times",
  },
};

export const emailVerificationStatusMapping = {
  ...verificationStatusMapping,
  waiting_authorization: {
    text: "Check Inbox",
    style: "warning",
    icon: "far fa-exclamation",
  },
  verification_attempted: {
    text: "Check Inbox",
    style: "warning",
    icon: "far fa-exclamation",
  },
  code_send_requested: {
    text: "Check Inbox",
    style: "warning",
    icon: "far fa-exclamation",
  },
};

export const phoneVerificationStatusMapping = {
  ...verificationStatusMapping,
  waiting_authorization: {
    text: "Confirm Now",
    style: "warning",
  },
  verification_attempted: {
    text: "Confirm Now",
    style: "warning",
  },
  code_send_requested: {
    text: "Confirm Now",
    style: "warning",
  },
};

export const barcardVerificationStatusMapping = verificationStatusMapping;
