// @flow strict
import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { oldApi } from "common/api";
import broadcastMessage from "utils/broadcast/sendMessage";

const actions = keyMirror({
  REQUEST_AUTH: null,
  RECEIVE_AUTH: null,
  LOG_IN: null,
  LOG_OUT: null,
});
export default actions;

export const requestAuth = (): ThunkAction => async (dispatch: Dispatch) => {
  dispatch(createAction(actions.REQUEST_AUTH)());
  const { json, response } = await oldApi.get("/api/auth/user/");
  const payload = response.ok
    ? json
    : new Error("Error retrieving auth status.");
  return dispatch(createAction(actions.RECEIVE_AUTH)(payload));
};

export const login = createAction(actions.LOG_IN);
const _logout = createAction(actions.LOG_OUT);

export const logout = (): ThunkAction => (dispatch: Dispatch) => {
  const action = _logout();
  dispatch(action);
  broadcastMessage(action);
};
