import actions from "hiringagent-dashboard/actions/invoices";
import jobActions from "hiringagent-dashboard/actions/jobs";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  invoices: {},
  isLoading: false,
  allLoaded: false,
  lastUpdated: null,
  jobFilters: {
    "All Jobs": true,
  },
  sorting: "Invoice #",
  sortingOptions: {
    "Invoice #": "invoice",
    "Job Name (A-Z)": "job",
    "Date Created": "created",
    "Date Due": "due",
    Paid: "paid",
    Total: "total",
  },
};

const invoices = handleActions(
  {
    [actions.REQUEST_INVOICE]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_INVOICE]: (state, action) => ({
      ...state,
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
      invoices: { ...state.invoices, [action.payload.uuid]: action.payload },
    }),
    [actions.REQUEST_INVOICES]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_INVOICES]: (state, action) => ({
      ...state,
      invoices: listToObject(action.payload.results, "uuid"),
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
      allLoaded: action.payload.allFetched,
    }),
    [actions.SET_INVOICES_JOB_FILTER]: (state, action) => {
      const newJobFilters = {};
      Object.keys(state.jobFilters).forEach(jobName => {
        newJobFilters[jobName] = jobName === action.payload.selectedJobFilter;
      });

      return { ...state, jobFilters: { ...newJobFilters } };
    },
    [actions.RESET_INVOICES_FILTERS]: state => ({
      ...state,
      // Keep existing job filters but ensure "All Jobs": true
      jobFilters: {
        ...state.jobFilters,
        ...initialState.jobFilters,
      },
      sorting: initialState.sorting,
    }),
    [actions.SET_INVOICES_SORTING]: (state, action) => ({
      ...state,
      sorting: action.payload,
    }),
    // Prime jobFilters with all job names
    [jobActions.RECEIVE_JOBS]: (state, action) => {
      const jobFilters = {};
      if (action.payload.results) {
        action.payload.results.forEach(job => (jobFilters[job.title] = false));
      }

      return { ...state, jobFilters: { ...state.jobFilters, ...jobFilters } };
    },
  },
  initialState,
);

export default invoices;
