import React from "react";
import classNames from "classnames";

export default props => {
  const { input, className, children, ...otherProps } = props;

  return (
    <div
      className={classNames("bubble-input", className, {
        active: Boolean(input.value),
      })}
      onClick={() => input.onChange(!input.value)}
      {...otherProps}
    >
      <i
        className={`far fa-fw ${input.value ? "fa-check-square" : "fa-square"}`}
      />
      &nbsp;
      {children}
    </div>
  );
};
