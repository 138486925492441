import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connect, compose, connectResource } from "resources/components/utils";
import { Badge } from "react-bootstrap";

import { messagesRequest } from "resources/requests";
import { getMessages } from "resources/selectors";

class MessageIcon extends Component {
  render() {
    const {
      messages: {
        data,
        metadata: { ready },
      },
    } = this.props;
    const messages = Object.values(data);
    const unreadMessages = ready
      ? messages.filter(message => message.unread)
      : [];

    return (
      <span className="message-icon">
        <i className="far fa-envelope" />
        <Badge className="important">
          {unreadMessages.length > 0 && unreadMessages.length}
        </Badge>
      </span>
    );
  }
}

export default compose(
  connect(createStructuredSelector({ messages: getMessages })),
  connectResource(messagesRequest),
)(MessageIcon);
