import actions from "admin/actions/recommendations";
import jobsDropdownActions from "admin/actions/jobsDropdown";
import { listToObject } from "admin/utils";
import { handleActions } from "redux-actions";
import firmTypeOptions from "hiringagent-dashboard/constants/firmTypeOptions";
import stateOptions from "hiringagent-dashboard/constants/stateOptions";

const initialState = {
  jobs: {},
  hiringAgencies: {},
  selectedJob: null,
  recommendations: {},
  recommendationsLoading: false,
  searchParams: {
    keywords: {
      active: false,
      value: "",
    },
    location: {
      active: false,
      value: {
        label: "",
        location: {
          lat: 0,
          lng: 0,
        },
      },
    },
    distancePrecision: {
      active: false,
      value: 64374, // Approx. 40 miles
    },
    rate: {
      active: false,
      value: 0,
    },
    yearsExperience: {
      active: true,
      value: 0,
    },
    maxYearsExperience: {
      active: true,
      value: 50,
    },
    practiceAreas: {
      active: true,
      value: "",
    },
    practiceAreaGroups: {
      active: true,
      value: "",
    },
    allowAttorneys: {
      active: false,
    },
    allowNonAttorneys: {
      active: false,
    },
    positionType: {
      active: false,
      value: "",
    },
    radius: {
      active: false,
      value: "",
    },
    barredIn: {
      active: false,
      value: "",
    },
    firmTypes: {
      active: false,
      value: "",
    },
    languageFluencies: {
      active: false,
      value: "",
    },
  },
  searchResults: [],
  numSearchResults: -1,
  searchResultsLoading: false,
  page: 0,
  nbPages: 0,
  firmTypeOptions,
  statesOptions: stateOptions,
  tags: {},
  languages: {},
  modal: null,
  recommendationReason: "",
  jobsLoading: false,
  lastUpdated: null,
};

const recommendations = handleActions(
  {
    [jobsDropdownActions.REQUEST_JOBS_FOR_DROPDOWN]: state => ({
      ...state,
      jobsLoading: true,
    }),
    [jobsDropdownActions.RECEIVE_JOBS_FOR_DROPDOWN]: (state, action) => ({
      ...state,
      jobs: listToObject(action.payload, "uuid"),
      selectedJobId: null,
      jobsLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.REQUEST_HIRING_AGENCIES]: state => ({
      ...state,
    }),
    [actions.RECEIVE_HIRING_AGENCIES]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [actions.SET_SELECTED_JOB]: (state, action) => {
      const selectedJob = action.payload;
      const newState = {
        ...state,
        selectedJob: action.payload,
        recommendations: {},
      };
      if (selectedJob) {
        newState.searchParams = {
          ...state.searchParams,
          keywords: {
            ...state.searchParams.keywords,
            value: selectedJob.name,
          },
          location: {
            ...state.searchParams.location,
            active: !selectedJob.work_from_home,
            value: {
              ...state.searchParams.location.value,
              label: selectedJob.location,
              location: {
                ...state.searchParams.location.value.location,
                lat: selectedJob.location_lat,
                lng: selectedJob.location_lng,
              },
            },
          },
          distancePrecision: {
            ...state.searchParams.distancePrecision,
            active: !selectedJob.work_from_home,
          },
          rate: {
            ...state.searchParams.rate,
            value: selectedJob.rate,
          },
          yearsExperience: {
            ...state.searchParams.yearsExperience,
            value: selectedJob.min_years_experience,
          },
          maxYearsExperience: {
            ...state.searchParams.maxYearsExperience,
            value: selectedJob.max_years_experience,
          },
          practiceAreas: {
            ...state.searchParams.practiceAreas,
            value: selectedJob.tags.join(";"),
          },
          practiceAreaGroups: {
            ...state.searchParams.practiceAreaGroups,
            value: selectedJob.tag_groups.join(";"),
          },
          positionType: {
            ...state.searchParams.positionType,
            value: selectedJob.position_type,
          },
          languageFluencies: {
            ...state.searchParams.languageFluencies,
            value: selectedJob.jobpostinglanguagefluency_set
              .map(languageFluency => languageFluency.language_uuid)
              .join(","),
            active: selectedJob.jobpostinglanguagefluency_set.length > 0,
          },
          barredIn: {
            ...state.searchParams.barredIn,
            value: selectedJob.location_state,
          },
          allowAttorneys: {
            ...state.searchParams.allowAttorneys,
            active: selectedJob.allow_attorneys,
          },
          allowNonAttorneys: {
            ...state.searchParams.allowNonAttorneys,
            active: selectedJob.allow_non_attorneys,
          },
        };
      }
      return newState;
    },
    [actions.REQUEST_RECOMMENDATIONS]: state => ({
      ...state,
      recommendationsLoading: true,
    }),
    [actions.RECEIVE_RECOMMENDATIONS]: (state, action) => ({
      ...state,
      ...action.payload,
      recommendationsLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.UPDATE_SEARCH_PARAMS]: (state, action) => {
      const newState = { ...state };
      const newParams = action.payload;
      Object.keys(newParams).forEach(param => {
        newState.searchParams = {
          ...newState.searchParams,
          ...{
            [param]: { ...newState.searchParams[param], ...newParams[param] },
          },
        };
      });
      return newState;
    },
    [actions.SUBMIT_SEARCH_PARAMS]: state => ({
      ...state,
      searchResultsLoading: true,
      page: 0,
      nbPages: 0,
    }),
    [actions.RECEIVE_SEARCH_RESULTS]: (state, action) => ({
      ...state,
      searchResults: action.payload.hits,
      numSearchResults: action.payload.nbHits,
      page: action.payload.page,
      nbPages: action.payload.nbPages,
      searchResultsLoading: false,
    }),
    [actions.REQUEST_TAGS]: state => ({
      ...state,
      tags: {},
    }),
    [actions.RECEIVE_TAGS]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [actions.REQUEST_LANGUAGES]: state => ({
      ...state,
      languages: {},
    }),
    [actions.RECEIVE_LANGUAGES]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [actions.RECEIVE_SUBMIT_RECOMMENDATION]: (state, action) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        [action.payload.uuid]: action.payload,
      },
      recommendationReason: "",
      modal: null,
    }),
    [actions.TOGGLE_RECOMMENDATION_MODAL]: (state, action) => ({
      ...state,
      modal: action.payload,
    }),
    [actions.UPDATE_RECOMMENDATION_REASON]: (state, action) => ({
      ...state,
      recommendationReason: action.payload,
    }),
  },
  initialState,
);
export default recommendations;
