import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose, connect, connectRequest } from "queries/utils";

import {
  createResourceListQuery,
  createStructuredSelector,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";

import { history } from "app";
import Loading from "common/components/Loading";
import NotFound from "common/components/NotFound";
import CandidateSidebar from "hiringagent-dashboard/components/candidates/CandidateSidebar";
import CandidateFooter from "./CandidateFooter";
import CandidateHeader from "./CandidateHeader";
import { createCandidateQuery } from "queries/requests/candidates";
import { predictorsQueryWithConfig } from "queries/requests/predictors";
import QualificationsFilled from "./QualificationsFilled";
import AssessmentResults from "./AssessmentResults";
import QualificationsSection from "./QualificationsSection";
import PersonalStatement from "./PersonalStatement";
import EducationSection from "./EducationSection";
import ExperienceSection from "./ExperienceSection";
import Display from "./blocks";

const requiredResources = [
  types.APPLICATIONS,
  types.CANDIDATES,
  types.CONTRACTORS,
  types.JOBS,
  types.JOB_OFFERS,
  types.JOB_REQUIREMENTS,
  types.PREDICTORS,
  types.REQUIREMENT_RESPONSES,
];

export class Candidate extends Component {
  get isFinished() {
    return requiredResources.every(resource => this.props[resource].isFinished);
  }
  get isPending() {
    return requiredResources.some(resource => this.props[resource].isPending);
  }
  handleGoBackClick = e => {
    // Only go back if there is history, else fallback to the Link
    if (window.history.length > 1) {
      e.preventDefault();
      history.goBack();
    }
  };
  render() {
    const {
      match: {
        params: { id: candidateId },
      },
      [types.CANDIDATES]: { data: candidatesData },
      [types.CONTRACTORS]: {
        data: contractorsData,
        query: { data: contractorsQueryData },
      },
      [types.JOBS]: {
        data: jobsData,
        query: { data: jobsQueryData },
      },
      [types.PREDICTORS]: { data: predictorsData },
      [types.REQUIREMENT_RESPONSES]: {
        data: requirementResponsesData,
        query: { data: requirementResponsesQueryData },
      },
    } = this.props;

    const candidate = candidatesData[candidateId];
    const contractor = contractorsData[contractorsQueryData[0]];
    const job = jobsData[jobsQueryData[0]];
    const predictorsArr = Object.values(predictorsData);

    const { isFinished, isPending } = this;
    const resourceMissing = !candidate || !contractor || !job || !predictorsArr;

    if (isFinished && resourceMissing) {
      return <NotFound to="/agency/candidates/" />;
    }

    if (isPending || resourceMissing) {
      return <Loading />;
    }

    return (
      <Display id="candidate-display">
        <div>
          <Display.GoBackContainer>
            <Link to="/agency/candidates" onClick={this.handleGoBackClick}>
              <i className="far fa-long-arrow-left" /> Go back
            </Link>
          </Display.GoBackContainer>
          <CandidateSidebar
            candidate={candidate}
            contractor={contractor}
            job={job}
          />
        </div>
        <div>
          <Display.HideOnMobile>
            <CandidateHeader
              candidate={candidate}
              contractor={contractor}
              job={job}
            />
          </Display.HideOnMobile>
          <QualificationsFilled
            data={requirementResponsesData}
            queryData={requirementResponsesQueryData}
          />
          <AssessmentResults candidate={candidate} predictors={predictorsArr} />
          <QualificationsSection candidate={candidate} />
          <PersonalStatement candidateId={candidate.uuid} />
          <EducationSection contractor={contractor} />
          <ExperienceSection contractor={contractor} />
          <CandidateFooter
            candidate={candidate}
            contractor={contractor}
            job={job}
          />
        </div>
      </Display>
    );
  }
}

// Load all data dependencies to display a Candidate here with force: true
const applicationsQuery = props =>
  createResourceListQuery(types.APPLICATIONS, {
    url: `/api/v2/applications/?candidate=${props.match.params.id}`,
    force: true,
  });
const candidateQuery = props =>
  createCandidateQuery({ force: true }, props.match.params.id);
const contractorsQuery = props =>
  createResourceListQuery(types.CONTRACTORS, {
    url: `/api/dev/contractors/?candidate=${props.match.params.id}`,
    force: true,
  });
const jobOffersQuery = props =>
  createResourceListQuery(types.JOB_OFFERS, {
    url: `/api/v2/job_offers/?candidate=${props.match.params.id}`,
    force: true,
  });
const jobsQuery = props =>
  createResourceListQuery(types.JOBS, {
    url: `/api/v2/jobs/?candidate=${props.match.params.id}`,
    force: true,
  });
const predictorsQuery = () => predictorsQueryWithConfig();
const requirementsQuery = props =>
  createResourceListQuery(types.JOB_REQUIREMENTS, {
    url: `/api/v2/requirements/?joblisting__job__candidate=${
      props.match.params.id
    }&limit=999`,
  });
const requirementResponsesQuery = props =>
  createResourceListQuery(types.REQUIREMENT_RESPONSES, {
    url: `/api/v2/requirement_responses/?application__candidate=${
      props.match.params.id
    }&limit=999`,
    force: true,
  });

const mapPropsToConfig = props => [
  applicationsQuery(props),
  candidateQuery(props),
  contractorsQuery(props),
  jobOffersQuery(props),
  jobsQuery(props),
  predictorsQuery(),
  requirementsQuery(props),
  requirementResponsesQuery(props),
];

const applicationsConfig = createResourceSelectorConfig(
  types.APPLICATIONS,
  applicationsQuery,
);
const candidateConfig = createResourceSelectorConfig(
  types.CANDIDATES,
  candidateQuery,
);
const contractorsConfig = createResourceSelectorConfig(
  types.CONTRACTORS,
  contractorsQuery,
);
const jobsConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);
const jobOffersConfig = createResourceSelectorConfig(
  types.JOB_OFFERS,
  jobOffersQuery,
);
const predictorsConfig = createResourceSelectorConfig(
  types.PREDICTORS,
  predictorsQuery,
);
const requirementsConfig = createResourceSelectorConfig(
  types.JOB_REQUIREMENTS,
  requirementsQuery,
);
const requirementResponsesConfig = createResourceSelectorConfig(
  types.REQUIREMENT_RESPONSES,
  requirementResponsesQuery,
);

const mapStateToProps = createStructuredSelector({
  ...applicationsConfig,
  ...candidateConfig,
  ...contractorsConfig,
  ...jobsConfig,
  ...jobOffersConfig,
  ...predictorsConfig,
  ...requirementsConfig,
  ...requirementResponsesConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(Candidate);
