import React from "react";
import { Alert } from "react-bootstrap";
import moment from "moment";

import { JobOffer } from "types";

const CandidateNegotiatingAlert = props => {
  const {
    jobOffers,
    contractor: { first_name, full_name },
  } = props;

  const mostRecentOffer = jobOffers.reduce((latestSoFar, offer) => {
    const latestSoFarDate = new Date(latestSoFar.updated);
    const offerDate = new Date(offer.updated);
    return offerDate > latestSoFarDate ? offer : latestSoFar;
  });

  const isPendingOffer =
    JobOffer.statusTypeDisplay(mostRecentOffer.status) === "PENDING";
  const isRejectedOffer =
    JobOffer.statusTypeDisplay(mostRecentOffer.status) === "REJECTED";
  const isExpiredOffer =
    JobOffer.statusTypeDisplay(mostRecentOffer.status) === "EXPIRED";

  const callToActionMsg = `Send ${first_name} a message or another offer, or reject him/her
        as a candidate for this position.`;

  let alertMsg = null;
  if (isPendingOffer) {
    alertMsg = (
      <div>
        <h4>Offer Pending</h4>
        <p>
          You sent {full_name} a job offer on{" "}
          {moment(mostRecentOffer.created).format("ll")}. {full_name} has until{" "}
          {moment(mostRecentOffer.expiration_date).format("ll")} to accept or
          reject it.
        </p>
      </div>
    );
  }
  if (isRejectedOffer) {
    alertMsg = (
      <div>
        <h4>Offer Declined</h4>
        <p>
          {full_name} has declined your job offer. {callToActionMsg}
        </p>
      </div>
    );
  }
  if (isExpiredOffer) {
    alertMsg = (
      <div>
        <h4>Offer Expired</h4>
        <p>
          The job offer you sent to {full_name} has expired. {callToActionMsg}
        </p>
      </div>
    );
  }

  return (
    alertMsg && (
      <div style={{ marginBottom: "2.5rem" }}>
        <Alert bsStyle="warning">{alertMsg}</Alert>
      </div>
    )
  );
};

export default CandidateNegotiatingAlert;
