import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";

import { StateSelect, TextField } from "common/components/forms/inputs";

const formName = "barLicensesForm";
export const requiredFields = ["bar_id", "state"];

class EditBarLicensesForm extends Component {
  render() {
    const { submitting, pristine, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <TextField name="bar_id" label="Bar ID" />
        <StateSelect name="state" />

        <div className="text-right">
          <Button
            type="submit"
            bsStyle="primary"
            disabled={pristine || submitting}
          >
            {submitting ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
})(EditBarLicensesForm);
