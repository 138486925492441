import React, { Component } from "react";
import { compose } from "redux";
import { formValueSelector, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { addNotification as notify } from "reapop";

import { history as browserHistory } from "app";
import { messagesSliceSelector } from "hiringagent-dashboard/selectors";
import { withContractorsIfNeeded } from "hiringagent-dashboard/connectors";
import { submitNewMessage } from "hiringagent-dashboard/actions/messages";
import ThreadAttachments from "../Thread/ThreadAttachments";
import { phone_re, email_re } from "common/utils/regex";
import {
  HiddenField,
  TextAreaField,
  TextField,
} from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import {
  ContractorAlert,
  HiringAgentAlert,
} from "common/components/copy/contactInfoAlerts";

const formName = "createMessageForm";
const requiredFields = ["recipients", "subject", "body"];
const successMessage = createSuccessNotification({ message: "Message sent" });
const errorMessage = createErrorNotification({
  title: "Error! Message did not send. Please try again.",
});

const validate = values => validateRequiredFields(requiredFields, values);

class MessageToContractor extends Component {
  state = {
    showAttachments: false,
    fileError: false,
    subjectHasPersonalInfo: false,
    messageHasPersonalInfo: false,
  };

  submit = (values, dispatch) => {
    const { requiredContractors } = this.props;
    const { subject, body, files } = values;
    const haeUserIds = requiredContractors.map(
      recipient => recipient.haeuser_uuid,
    );
    const data = { recipients: haeUserIds, files, subject, body };

    return dispatch(submitNewMessage(data)).then(({ json, response }) => {
      if (!response.ok) {
        this.props.notify(errorMessage);
        throw new SubmissionError(json);
      } else {
        this.props.notify(successMessage);
        browserHistory.push("/agency/messages/");
        return json;
      }
    });
  };

  checkForContactInfo = event => {
    return (
      phone_re.test(event.target.value) || email_re.test(event.target.value)
    );
  };

  checkSubjectForContactInfo = event => {
    this.setState({ subjectHasPersonalInfo: this.checkForContactInfo(event) });
  };

  checkMessageForContactInfo = event => {
    this.setState({ messageHasPersonalInfo: this.checkForContactInfo(event) });
  };

  handleRemoveFile = attachmentName => {
    const { files } = this.props;
    const fileIdx = files.findIndex(file => file.name === attachmentName);
    if (fileIdx > -1) {
      this.props.array.remove("files", fileIdx);
    }
  };

  handleFileUploadError = () => {
    this.setState({ fileError: true });
  };

  handleToggleAttachmentSection = () => {
    this.setState(prevState => ({
      showAttachments: !prevState.showAttachments,
    }));
  };

  render() {
    const {
      files = [],
      handleSubmit,
      error,
      submitting,
      requiredContractors,
      userType,
    } = this.props;

    const filesExist = Boolean(files.length);
    const recipientsExist = Boolean(requiredContractors.length);
    const showAttachmentsText = this.state.showAttachments
      ? "Hide Attachments"
      : "Add Attachments";

    const recipientsDisplay = requiredContractors.map(recipient => (
      <LinkContainer
        key={recipient.uuid}
        to={`/agency/attorneys/${recipient.uuid}/`}
      >
        <Button bsStyle="primary" bsSize="sm">
          {recipient.full_name}
        </Button>
      </LinkContainer>
    ));

    const noRecipientsAlertDisplay = (
      <div style={{ textAlign: "center" }}>
        <h3 style={{ margin: "4rem" }}>No Recipients Selected</h3>
        <h4>
          You can select recipients to message in the Candidates section or
          candidate profiles
        </h4>
      </div>
    );

    return (
      <div className="messages-form create-message-form">
        <h1>
          <i className={"far fa-paper-plane"} />
          &nbsp;Send Message
        </h1>
        {recipientsExist ? (
          <Row>
            <Col lg={10} lgOffset={1}>
              <div className="recipients-container">
                <h3>To: </h3>
                {recipientsDisplay}
              </div>

              <div>
                <Form onSubmit={handleSubmit(this.submit)}>
                  <HiddenField name="files" />

                  <TextField
                    name="subject"
                    label="Subject"
                    onChange={this.checkSubjectForContactInfo}
                  />

                  <TextAreaField
                    name="body"
                    label="Message"
                    onChange={this.checkMessageForContactInfo}
                  />

                  {(this.state.messageHasPersonalInfo ||
                    this.state.subjectHasPersonalInfo) &&
                    userType === "agent" && (
                      <Alert bsStyle={"danger"}>
                        <HiringAgentAlert />
                      </Alert>
                    )}

                  {(this.state.messageHasPersonalInfo ||
                    this.state.subjectHasPersonalInfo) &&
                    userType !== "agent" && (
                      <Alert bsStyle={"danger"}>
                        <ContractorAlert />
                      </Alert>
                    )}

                  {error && (
                    <Alert bsStyle="danger" style={{ textAlign: "center" }}>
                      <strong>{error}</strong>
                    </Alert>
                  )}

                  <Button
                    bsStyle="success"
                    style={{ display: "inline-block" }}
                    bsSize="sm"
                    onClick={this.handleToggleAttachmentSection}
                  >
                    {showAttachmentsText}
                  </Button>

                  {filesExist && (
                    <small
                      style={{ display: "inline-block", marginLeft: "2rem" }}
                    >
                      {`${files.length} file attached`}
                    </small>
                  )}

                  <Button
                    type="submit"
                    bsStyle="info"
                    className="pull-right"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <span>
                        <i className="far fa-fw fa-cog fa-spin" /> Sending
                      </span>
                    ) : (
                      <span>
                        <i className="far fa-fw fa-reply" /> Send
                      </span>
                    )}
                  </Button>

                  {this.state.showAttachments && (
                    <ThreadAttachments
                      filesExist={filesExist}
                      attachments={files}
                      onRemoveFile={this.handleRemoveFile}
                      onFileUploadError={this.handleFileUploadError}
                      fileError={this.state.fileError}
                      {...this.props}
                    />
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        ) : (
          <div>{noRecipientsAlertDisplay}</div>
        )}
      </div>
    );
  }
}

const formSelector = formValueSelector(formName);

export default compose(
  connect(
    messagesSliceSelector,
    { notify },
  ),
  withContractorsIfNeeded(props => props.messages.messageRecipientIds),
  reduxForm({ form: formName, validate }),
  connect(state => ({
    files: formSelector(state, "files"),
  })),
)(MessageToContractor);
