import React, { Component } from "react";

import Onboard from "../../blocks";
import { MobileEntries, DesktopEntries } from "./Entries";
import NextButton from "../../NextButton";
import FormModal from "./FormModal";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import {
  getActionIsRequired,
  getCustomErrorMsg,
  redirectToNextStepIfNeeded,
} from "../../utils";
import { compose, connect, mutateAsync } from "queries/utils";
import {
  deleteContractorLanguageQuery,
  bypassLanguageProficienciesQuery,
} from "queries/mutations/contractorLanguages";

const stepName = "language_proficiencies";

class LanguageProficiencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingLanguageProficiency: null,
      showFormModal: false,
    };
  }

  componentDidMount() {
    const { bypassLanguageProficiencies, refreshReqs } = this.props;
    bypassLanguageProficiencies().then(refreshReqs);
  }

  handleEditClick = editingLanguageProficiency =>
    this.setState({ showFormModal: true, editingLanguageProficiency });

  handleFooterClick = () =>
    this.setState({ showFormModal: true, editingLanguageProficiency: null });

  handleHide = () =>
    this.setState({ showFormModal: false, editingLanguageProficiency: null });

  handleDeleteClick = uuid => {
    const {
      deleteContractorLanguage,
      refreshReqs,
      refreshOwnContractor,
    } = this.props;
    deleteContractorLanguage(uuid).then(() => {
      refreshOwnContractor();
      refreshReqs();
    });
  };

  handleNextClick = () => {
    redirectToNextStepIfNeeded(stepName, this.props.refreshReqs);
  };

  render() {
    const {
      state: { editingLanguageProficiency, showFormModal },
      props: {
        refreshOwnContractor,
        ownContractor,
        refreshReqs,
        reqs: { steps },
      },
      handleDeleteClick,
      handleEditClick,
      handleFooterClick,
      handleHide,
      handleNextClick,
    } = this;
    const entriesProps = {
      actionIsRequired: getActionIsRequired(stepName, steps),
      customErrorMsg: getCustomErrorMsg(stepName, steps),
      onDeleteClick: handleDeleteClick,
      onEditClick: handleEditClick,
      onFooterClick: handleFooterClick,
      languageFluencies: ownContractor.language_fluency,
    };

    return (
      <Onboard.Page>
        <DesktopOnly>
          <DesktopEntries {...entriesProps} />
        </DesktopOnly>
        <MobileOnly>
          <MobileEntries {...entriesProps} />
        </MobileOnly>
        <NextButton float="right" onClick={handleNextClick} />
        <FormModal
          editingLanguageProficiency={editingLanguageProficiency}
          show={showFormModal}
          onHide={handleHide}
          ownContractor={ownContractor}
          refreshReqs={refreshReqs}
          refreshOwnContractor={refreshOwnContractor}
        />
      </Onboard.Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  bypassLanguageProficiencies: () =>
    dispatch(mutateAsync(bypassLanguageProficienciesQuery())),
  deleteContractorLanguage: uuid =>
    dispatch(mutateAsync(deleteContractorLanguageQuery(uuid))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(LanguageProficiencies);
