import keyMirror from "keymirror";

import api from "common/api";

const actions = keyMirror({
  REQUEST_HIRING_AGENT: null,
  RECEIVE_HIRING_AGENT: null,
  REQUEST_NEW_SIDEBAR_ITEMS: null,
  RECEIVE_NEW_SIDEBAR_ITEMS: null,
});
export default actions;

export const requestSelf = () =>
  api.get("/api/dev/hiring_agents/self/", [
    actions.REQUEST_HIRING_AGENT,
    actions.RECEIVE_HIRING_AGENT,
  ]);

export const requestNewItems = () =>
  api.get("/api/v2/hiring_agencies/new_items/", [
    actions.REQUEST_NEW_SIDEBAR_ITEMS,
    actions.RECEIVE_NEW_SIDEBAR_ITEMS,
  ]);

export const requestHiringAgent = () => dispatch => {
  dispatch(requestSelf());
  dispatch(requestNewItems());
};
