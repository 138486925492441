import { createGlobalStyle } from "styled-components";

const BASE_FONT_SIZE = 1.6; // rem units
const FONT_SCALE = 1.25;
// Multiplier to use for uppercase text to ensure uppercase variants use about
// the same amount of horizontal space.
const UPPERCASE_SCALE = 0.72;

const fontSizes = [1, 2, 3, 4, 5, 6].reduce(
  (acc, val) => ({
    ...acc,
    [`h${val}`]: BASE_FONT_SIZE * FONT_SCALE ** Math.max(5 - val, 0),
  }),
  {},
);

export default createGlobalStyle`
  :root {
    font-size: 62.5%;
    height: 100%;
  }

  body {
    font-size: 1.6rem;
    min-height: 100%;
    position: relative;

    h1 {
      font-size: ${fontSizes.h1 * (2 / 3)}rem;
      ${({ theme }) => theme.media.desktop`
        font-size: ${fontSizes.h1}rem;
      `}
    }

    h1.text-uppercase {
      font-size: ${fontSizes.h1 * (2 / 3) * UPPERCASE_SCALE}rem;
      ${({ theme }) => theme.media.desktop`
        font-size: ${fontSizes.h1 * UPPERCASE_SCALE}rem;
      `}
    }

    h2 {
      font-size: ${fontSizes.h2 * (2 / 3)}rem;
      ${({ theme }) => theme.media.desktop`
        font-size: ${fontSizes.h2}rem;
      `}
    }

    h2.text-uppercase {
      font-size: ${fontSizes.h2 * (2 / 3) * UPPERCASE_SCALE}rem;
      ${({ theme }) => theme.media.desktop`
        font-size: ${fontSizes.h2 * UPPERCASE_SCALE}rem;
      `}
    }

    h3 {
      font-size: ${fontSizes.h3 * 0.7}rem;
      ${({ theme }) => theme.media.desktop`
        font-size: ${fontSizes.h3}rem;
      `}
    }

    h3.text-uppercase {
      font-size: ${fontSizes.h3 * UPPERCASE_SCALE}rem;
    }

    h4 {
      font-size: ${fontSizes.h4 * 0.8}rem;
      ${({ theme }) => theme.media.desktop`
        font-size: ${fontSizes.h4}rem;
      `}
    }

    h4.text-uppercase {
      font-size: ${fontSizes.h4 * UPPERCASE_SCALE}rem;
    }

    h5 {
      font-size: ${fontSizes.h5 * 0.9}rem;
      ${({ theme }) => theme.media.desktop`
        font-size: ${fontSizes.h5}rem;
      `}
    }

    h5.text-uppercase {
      font-size: ${fontSizes.h5 * UPPERCASE_SCALE}rem;
    }

    h6 {
      font-size: ${fontSizes.h6}rem;
    }

    h6.text-uppercase {
      font-size: ${fontSizes.h6 * UPPERCASE_SCALE}rem;
    }

    h1.text-uppercase,
    h2.text-uppercase,
    h3.text-uppercase,
    h4.text-uppercase,
    h5.text-uppercase,
    h6.text-uppercase {
      font-weight: bold;
    }
  }
`;
