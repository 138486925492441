// @flow strict
import React from "react";
// $FlowFixMe (nonstrict-import)
import { Droppable, Draggable } from "react-beautiful-dnd";

import DragDrop from "./blocks";
import { IconBars, IconCircle } from "./Icons";

type Props = {
  isDragDisabled: boolean,
  onToggleSelected: (uuid: string, source: string, dest: string) => void,
  unselected: Array<{ uuid: string, statement: string }>,
};

const UnselectedDroppable = ({
  isDragDisabled,
  onToggleSelected,
  unselected,
}: Props) => (
  <Droppable droppableId="unselected">
    {provided => (
      <DragDrop.ItemGroup ref={provided.innerRef} selected={false}>
        {unselected.map((item, index) => (
          <Draggable
            key={item.uuid}
            draggableId={item.uuid}
            index={index}
            isDragDisabled={isDragDisabled}
          >
            {(provided, snapshot) => (
              <DragDrop.Item
                isDragging={snapshot.isDragging}
                itemType="unselected"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <IconCircle
                  onClick={() =>
                    onToggleSelected(item.uuid, "unselected", "selected")
                  }
                />
                <DragDrop.ItemText>{item.statement}</DragDrop.ItemText>
                <IconBars />
              </DragDrop.Item>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </DragDrop.ItemGroup>
    )}
  </Droppable>
);

export default UnselectedDroppable;
