import React from "react";
import { Link } from "react-router-dom";
import { Button, Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import logo from "img/logo-small.svg";

const Header = ({ children, showSearchLink, showSignupButton }) => (
  <Navbar inverse fluid staticTop className="contractor-navbar">
    <Navbar.Header>
      <Navbar.Brand>
        <Link to="/">
          <img
            alt="scales logo for Hire an Esquire"
            id="briefcase"
            width="auto"
            height="100%"
            src={logo}
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle />
    </Navbar.Header>
    <Navbar.Collapse>
      {showSearchLink && (
        <Nav>
          <LinkContainer to="/public/jobs/">
            <NavItem eventKey={1}>Search Jobs</NavItem>
          </LinkContainer>
        </Nav>
      )}
      {showSignupButton && (
        <LinkContainer to="/signup/">
          <Button
            bsStyle="default"
            className="navbar-btn navbar-right"
            style={{ marginRight: 0 }}
          >
            <span style={{ fontWeight: "700", fontSize: "1.6rem" }}>
              Sign Up Now
            </span>
          </Button>
        </LinkContainer>
      )}
      {children}
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
