import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { StateSelect, TextField } from "common/components/forms/inputs";

export const formName = "addressForm";
export const requiredFields = ["address_1", "city", "state", "postal_code"];

export class AddressForm extends Component {
  render() {
    return (
      <div>
        <TextField name="address_1" label="Address 1" />
        <TextField name="address_2" label="Address 2" />
        <TextField name="city" label="City" />
        <StateSelect />
        <TextField name="postal_code" label="Postal Code" />
      </div>
    );
  }
}

export default reduxForm({ form: formName })(AddressForm);
