import React from "react";
import { Col, Table } from "react-bootstrap";
import moment from "moment";

const InvoiceDetails = ({ invoice, job }) => {
  const InvoiceStatus = () => {
    let status;
    let statusClass = "text-danger";

    if (invoice.paid_in_full) {
      status = `Paid on ${moment(invoice.paid_date).format(
        "MMMM D, YYYY, h:mm a",
      )}`;
      statusClass = "text-success";
    } else if (invoice.approved_date) {
      status = "Approved, pending payment";
      statusClass = "text-success";
    } else if (invoice.is_disputed) {
      status = "Disputed, pending resolution";
    } else if (invoice.is_overdue) {
      status = "Overdue";
    } else {
      status = "Awaiting your approval!";
    }
    return (
      <tr>
        <td className={statusClass}>Status</td>
        <td className={statusClass}>{status}</td>
      </tr>
    );
  };

  return (
    <Col lg={5}>
      <div className="main-info-container invoice-table-container">
        <h2 className="main-info-title">Details</h2>
        <Table>
          <tbody>
            <tr>
              <td className="col-md-5">Invoice #</td>
              <td>{invoice.invoice_number}</td>
            </tr>
            <tr>
              <td>Job</td>
              <td>{job.title}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>
                {invoice.service === 0
                  ? "1099 Independent Contractor"
                  : "W-2 through HAE"}
              </td>
            </tr>
            <InvoiceStatus />
          </tbody>
        </Table>
      </div>
    </Col>
  );
};

export default InvoiceDetails;
