import styled from "styled-components";

const Bar = styled.span`
  display: block;
  width: 22px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary_black()};
  border-radius: 1px;

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

export default Bar;
