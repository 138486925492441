import React, { Fragment, useEffect } from "react";
import { mutateAsync } from "redux-query";
import { connectConnectRequest } from "queries/utils";

import { createResourceSelector } from "queries";
import { updateHiringAgencyMarketingConversion } from "queries/mutations";
import { hiringAgencyMarketingConversionsQuery } from "queries/requests";
import types from "resources/types";

const Conversion = ({ conversionId, onLoad }) => {
  useEffect(
    () => {
      onLoad();
    },
    [conversionId], // Make sure effect only runs once per conversionId
  );
  return null;
};

const MarketingConversionTracker = props => {
  const {
    [types.HIRING_AGENCY_MARKETING_CONVERSIONS]: {
      data,
      query: { data: queryData },
      lastUpdated,
    },
    logEvent,
  } = props;
  if (!lastUpdated) return null;
  return (
    <Fragment>
      {queryData
        .map(id => data[id])
        .map(conversion => (
          <Conversion
            key={conversion.uuid}
            conversionId={conversion.uuid}
            onLoad={() => logEvent(conversion)}
          />
        ))}
    </Fragment>
  );
};

const mapPropsToConfig = () =>
  hiringAgencyMarketingConversionsQuery({
    url: "/api/v2/hiring_agency_marketing_conversions/?event_logged=false",
  });

const mapStateToProps = createResourceSelector(
  types.HIRING_AGENCY_MARKETING_CONVERSIONS,
  mapPropsToConfig,
);

const mapDispatchToProps = dispatch => ({
  logEvent: conversion => {
    return dispatch(
      mutateAsync(
        updateHiringAgencyMarketingConversion(conversion.uuid, {
          event_logged: true,
        }),
      ),
    ).then(() => window.dataLayer.push({ event: conversion.conversion_type }));
  },
});

export default connectConnectRequest(
  mapPropsToConfig,
  mapStateToProps,
  mapDispatchToProps,
)(MarketingConversionTracker);
