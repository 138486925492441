import React, { Component } from "react";
import PropTypes from "prop-types";

export default class DeleteButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    iconSize: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: "",
    iconSize: "lg",
  };

  render() {
    const { className, iconSize, onDelete } = this.props;
    return (
      <div className={`delete-button ${className}`}>
        <i className={`far fa-${iconSize} fa-times`} onClick={onDelete} />
      </div>
    );
  }
}
