import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connect, compose, connectResource } from "resources/components/utils";
import { Link } from "react-router-dom";

import { getRecipient } from "common/utils/messages";
import { requestToggleMessageReadStatus } from "hiringagent-dashboard/actions/messages";
import { messagesRequest } from "resources/requests";
import { getMessages } from "resources/selectors";

const MessagePreview = ({ message }) => {
  const recipient = getRecipient(message);
  const recipientName = `${recipient.first_name} ${recipient.last_name}`;
  return (
    <Link
      to={`/contractor/messages/${message.uuid}`}
      className="message-preview-link"
    >
      <div>
        <strong>{recipientName}</strong>
      </div>
      <div>
        {message.subject.length > 75
          ? `${message.subject.slice(0, 75)}...`
          : message.subject}
      </div>
    </Link>
  );
};

class MessageTray extends Component {
  handleMessageClick = messageId => () => {
    const { onMessageClick, onLinkClick } = this.props;
    onMessageClick(messageId);
    onLinkClick();
  };

  render() {
    const {
      messages: {
        data,
        metadata: { ready },
      },
      onLinkClick,
    } = this.props;
    const messages = Object.values(data);
    messages.sort((a, b) => {
      const dateA = new Date(a.created);
      const dateB = new Date(b.created);
      return dateB - dateA;
    });
    const unreadMessages = ready
      ? messages.filter(message => message.unread)
      : [];

    return (
      <ul className="list-unstyled messages-tray-list">
        {unreadMessages.length > 0 ? (
          unreadMessages.splice(0, 5).map(message => (
            <li
              key={`message-tray-${message.uuid}`}
              onClick={this.handleMessageClick(message.uuid)}
            >
              <MessagePreview message={message} />
            </li>
          ))
        ) : (
          <li>No new messages</li>
        )}
        <li className="text-center" onClick={onLinkClick}>
          <Link to="/contractor/messages">View all messages</Link>
        </li>
      </ul>
    );
  }
}

export default compose(
  connect(
    createStructuredSelector({ messages: getMessages }),
    { onMessageClick: requestToggleMessageReadStatus },
  ),
  connectResource(messagesRequest),
)(MessageTray);
