import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";
import actions from "hiringagent-dashboard/actions/hiringAgents";

const initialState = {
  hiringAgents: {},
  openModal: null,
  focusedAgent: null,
  isLoading: false,
  lastUpdated: null,
};

const hiringAgents = handleActions(
  {
    // For use when requesting single hiring agent is necessary
    // TODO: change action name
    // [actions.REQUEST_HIRING_AGENT]: (state, action) => ({
    //     ...state, isLoading: true
    // }),
    // // Safer to create a separate reducer for requestHiringAgent
    // [actions.RECEIVE_HIRING_AGENT]: (state, action) => ({
    //     ...state,
    //     hiringAgents: {
    //         ...state,
    //         ...listToObject(action.payload, 'uuid')
    //     },
    //     lastUpdated: action.meta.receivedAt,
    //     isLoading: false
    // }),
    [actions.REQUEST_HIRING_AGENTS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_HIRING_AGENTS]: (state, action) => ({
      ...state,
      hiringAgents: listToObject(action.payload, "uuid"),
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
    [actions.SET_FOCUSED_AGENT]: (state, action) => ({
      ...state,
      focusedAgent: action.payload,
    }),
    [actions.TOGGLE_ADD_AGENT_MODAL]: (state, action) => ({
      ...state,
      openModal: action.payload,
    }),
  },
  initialState,
);
export default hiringAgents;
