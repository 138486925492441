// @flow
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";

import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

const JobLanguageRequirement = props => {
  const {
    language,
    [types.LANGUAGES]: { data, lastUpdated },
  } = props;

  return <span>{lastUpdated && data[language].name}</span>;
};

const mapPropsToConfig = () =>
  createResourceListQuery(types.LANGUAGES, {
    url: "/api/v2/languages/?limit=999",
  });

const mapStatetoProps = createResourceSelector(
  types.LANGUAGES,
  mapPropsToConfig,
);

export default compose(
  connect(mapStatetoProps),
  connectRequest(mapPropsToConfig),
)(JobLanguageRequirement);
