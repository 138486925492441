// This function used to be imported from "common/utils" but that caused a
// silent circular import error which resulted in the function being undefined.
const listToObject = (list, identifier) => {
  if (!list) {
    return {};
  }
  const items = {};
  const listLength = list.length;
  for (let i = 0; i < listLength; i++) {
    items[list[i][identifier]] = list[i];
  }
  return items;
};

const states = [
  { value: "e544de24-bdfc-42a1-a7c0-068c13e9e92d", label: "Alaska" },
  { value: "41a0b29d-a76c-4457-bba1-d96c090a704b", label: "Alabama" },
  { value: "78038902-7572-46f5-a4a3-ce867372ba5f", label: "Arkansas" },
  { value: "fa70dffb-701f-4f0e-a60f-237521c88715", label: "American Samoa" },
  { value: "4ea0211c-88ed-453f-a7b2-a30ee9f82f72", label: "Arizona" },
  { value: "ab5ad789-ef7e-4d7c-b48d-9dec60613e2e", label: "California" },
  { value: "44ccbfe7-8564-4a31-bbff-4384afd20fd4", label: "Colorado" },
  { value: "47acc7a0-e763-4f71-909f-58821202966c", label: "Connecticut" },
  {
    value: "1da0657f-5e87-4bd8-aa3d-0db9f4d32395",
    label: "District of Columbia",
  },
  { value: "ee618640-7d8d-4353-9bc7-15990cf34b19", label: "Delaware" },
  { value: "c8ee31f5-c56e-4fe9-8e2e-f6a29398eed4", label: "Florida" },
  { value: "0e48a07b-6474-403a-95f9-658a35739378", label: "Georgia" },
  { value: "c056de07-558a-4087-a9f9-ad40a27212b7", label: "Guam" },
  { value: "12417160-847a-4672-9c5d-34e6a39a7dc7", label: "Hawaii" },
  { value: "997d1488-cb24-4087-93a4-c5c79394148b", label: "Iowa" },
  { value: "6066c0a5-a007-4f06-8830-f021677e302c", label: "Idaho" },
  { value: "2d7b36a7-1b55-4c51-be7b-65d8ffb30b33", label: "Illinois" },
  { value: "d0645e40-bdec-4c8e-bcb0-064eeca2df06", label: "Indiana" },
  { value: "2c51800e-ccef-4822-9fb6-49d97fd61809", label: "Kansas" },
  { value: "dadca055-04bb-46c7-8b14-70882a957981", label: "Kentucky" },
  { value: "d93db696-c994-4efb-96b4-ed7591e9eb49", label: "Louisiana" },
  { value: "4dbf42a5-38e3-4100-a9bd-38759d95a446", label: "Massachusetts" },
  { value: "86b9d364-702e-4c61-a70c-627614735ec4", label: "Maryland" },
  { value: "713d7d80-3392-4ba8-9f9a-6c391f1cbaab", label: "Maine" },
  { value: "0dd09ae6-1f89-4bb3-9b23-e61bb0e58cea", label: "Michigan" },
  { value: "25b55262-d9ea-4cfb-9d33-c08e2c8b04a9", label: "Minnesota" },
  { value: "5da936c8-07a6-4673-aef7-6639f93a6648", label: "Missouri" },
  {
    value: "8361e9e3-8987-409a-b4d0-b14f9375df48",
    label: "Northern Mariana Islands",
  },
  { value: "f0363ba1-fe49-44ea-a4d7-ddd9941c0f09", label: "Mississippi" },
  { value: "a20681a3-502c-480b-bac5-40023cc65210", label: "Montana" },
  { value: "5335f4b7-722a-4109-aafb-b786bb492ac9", label: "North Carolina" },
  { value: "313450d5-9c45-41a8-9ef9-b645738e1309", label: "North Dakota" },
  { value: "e04bda1b-0605-4500-b378-7eb9d0f11df1", label: "Nebraska" },
  { value: "398b0ba7-6995-43c7-8ebc-ff48f6434ebc", label: "New Hampshire" },
  { value: "df04dc7e-aef5-4aed-9d50-3c71e9afc80c", label: "New Jersey" },
  { value: "f0806bae-5431-42d8-8b23-cde550fd6207", label: "New Mexico" },
  { value: "e423fff2-fc3a-4c47-90d8-7f968752dd6a", label: "Nevada" },
  { value: "680e8ba7-c68f-4106-a46b-09b3617ad179", label: "New York" },
  { value: "ac820c76-d65e-4422-a6a5-313cc423a061", label: "Ohio" },
  { value: "de199571-e852-42bc-af3d-8d0ba4b3be75", label: "Oklahoma" },
  { value: "12265efb-96ed-4496-9a44-1e32f86f0852", label: "Oregon" },
  { value: "167cc1f2-3ab0-4c94-b4eb-3bde9582634d", label: "Pennsylvania" },
  { value: "9b3825e0-3dfa-431f-a021-1ff06460906d", label: "Puerto Rico" },
  { value: "3a313563-8aa5-4d12-a0e2-0c2438f8f3d9", label: "Rhode Island" },
  { value: "efb7c812-847c-41d6-add2-9978cba12b89", label: "South Carolina" },
  { value: "3a629a06-e692-4721-ba5d-e5bda5c47b81", label: "South Dakota" },
  { value: "3eeb5bd4-9327-42ca-aa54-bebc6529193e", label: "Tennessee" },
  { value: "3dd56e56-c276-47e4-b723-6f510c0ca1a3", label: "Texas" },
  { value: "a4424ceb-9e91-4914-bb22-d9ac6cc78532", label: "Utah" },
  { value: "2685492b-b260-4fbd-8f8a-6c096ec60d1c", label: "Virginia" },
  { value: "6afdfc09-f260-430b-8da5-938c0415a929", label: "Virgin Islands" },
  { value: "e1967aa0-863d-4a32-bdc5-f5a3f292f1e9", label: "Vermont" },
  { value: "b3c36005-efac-41b3-8e6a-b72a2ee6ca15", label: "Washington" },
  { value: "1e6816c9-900c-40bd-813b-7a2e60d28a5c", label: "Wisconsin" },
  { value: "354b5036-83a9-4c27-8caa-059823ced474", label: "West Virginia" },
  { value: "df061d26-5392-4bcc-9217-01f909ba19e7", label: "Wyoming" },
];

export const statesByAbbreviation = [
  { value: "AK", label: "Alaska" },
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
];

export default states;
export const statesLookup = listToObject(states, "value");
