import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";

import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_NON_ATTORNEYS: null,
  RECEIVE_NON_ATTORNEYS: null,
  SELECT_NON_ATTORNEY_ROW: null,
  RECEIVE_CHANGE_SCHOOL_TRANSCRIPT_STATUS: null,
  RECEIVE_CHANGE_EMPLOYMENT_REFERENCE_STATUS: null,
  RECEIVE_CHANGE_REFERENCE_STATUS: null,
  RECEIVE_UPDATED_CONTRACTOR: null,
});

export default actions;

const findNonAttorneyBySchool = (allNonAttorneys, school) => ({
  ...Object.values(allNonAttorneys).find(nonAttorney =>
    nonAttorney.schools.find(
      nonAttorneySchool =>
        nonAttorneySchool.uuid == school.uuid ||
        (nonAttorneySchool.schooltranscript &&
          nonAttorneySchool.schooltranscript.uuid == school.uuid),
    ),
  ),
});

const findNonAttorneyByEmploymentReference = (allNonAttorneys, reference) => ({
  ...Object.values(allNonAttorneys).find(nonAttorney =>
    nonAttorney.previous_employments.find(
      ({ previousemploymentreference: ref }) =>
        ref && ref.uuid === reference.uuid,
    ),
  ),
});

const findNonAttorneyByReview = (allNonAttorneys, review) => ({
  ...Object.values(allNonAttorneys).find(function(element) {
    return element.review_requests.find(function(element) {
      return element.review && element.review.uuid == review.uuid;
    });
  }),
});

export const changeSchoolTranscriptStatus = (school, status) => (
  dispatch,
  getState,
) => {
  let url = "";
  if (school.schooltranscript) {
    url = `/api/v2/school_transcripts/${school.schooltranscript.uuid}/`;
  } else {
    url = `/api/v2/schools/${school.uuid}/`;
  }
  oldApi.patch(url, { status }).then(({ json, response }) => {
    const payload = response.ok
      ? {
          nonAttorney: findNonAttorneyBySchool(
            getState().admin.nonAttorneyVerification.allNonAttorneys,
            json,
          ),
          schoolTranscript: json,
        }
      : new Error("Error submitting school transcript status.");

    return dispatch(
      createAction(
        actions.RECEIVE_CHANGE_SCHOOL_TRANSCRIPT_STATUS,
        p => p,
        metaGenerator,
      )(payload),
    );
  });
};

export const changeReviewStatus = (referenceId, status) => (
  dispatch,
  getState,
) =>
  oldApi
    .patch(`/api/v2/reviews/${referenceId}/`, {
      approval_status: status,
    })
    .then(({ json, response }) => {
      const payload = response.ok
        ? {
            nonAttorney: findNonAttorneyByReview(
              getState().admin.nonAttorneyVerification.allNonAttorneys,
              json,
            ),
            review: json,
          }
        : new Error("Error submitting employment reference status.");
      return dispatch(
        createAction(
          actions.RECEIVE_CHANGE_REFERENCE_STATUS,
          p => p,
          metaGenerator,
        )(payload),
      );
    });

export const selectRow = createAction(actions.SELECT_NON_ATTORNEY_ROW);
export const receiveUpdatedContractor = createAction(
  actions.RECEIVE_UPDATED_CONTRACTOR,
);
