import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import querystring from "querystring";

import { Loading } from "common/components";
import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { createJobsQuery } from "queries/requests/jobs";
import { parseIntWithDefault } from "utils";

import Applicants from "./blocks";
import JobApplicantsPreview from "./JobApplicantsPreview";
import JobsDropdown from "./JobsDropdown";
import Pagination from "./Pagination";

const limit = 3;

const ApplicantsPreview = props => {
  const {
    jobs: {
      data,
      isFinished,
      isPending,
      query: { data: queryData, count },
    },
    location: { pathname, search },
    history,
  } = props;
  const query = querystring.parse(search.substring(1));
  const { page = "1" } = query;
  const pageInt = parseIntWithDefault(page, 1);
  const handlePageChange = newPage =>
    history.push(
      `${pathname}?${querystring.stringify({
        page: newPage,
      })}`,
    );
  const handleJobSelect = ({ value: jobId }) =>
    history.push(`/agency/candidates/job/${jobId}`);

  return (
    <div>
      <Applicants.PreviewHeader>
        <Applicants.PreviewHeader.PreviewTitle>
          View Applicants
        </Applicants.PreviewHeader.PreviewTitle>
        <div>
          <Applicants.PreviewHeader.JobsDropdownTitle>
            View by Job
          </Applicants.PreviewHeader.JobsDropdownTitle>
          <JobsDropdown onJobSelect={handleJobSelect} />
        </div>
      </Applicants.PreviewHeader>
      {isPending && <Loading />}
      {isFinished && (
        <Fragment>
          {queryData.map((jobId, i) => (
            <JobApplicantsPreview key={i} job={data[jobId]} />
          ))}
          {count === 0 && (
            <div className="text-center">
              <p>
                Once you&apos;ve created and published a job, it will be listed
                here with its applicants.
              </p>
              <p>
                <Link to="/agency/jobs/create">Click here</Link> to create a
                job.
              </p>
            </div>
          )}
        </Fragment>
      )}
      <Pagination
        pageNumber={pageInt}
        onChange={handlePageChange}
        numResults={count}
        limit={limit}
      />
    </div>
  );
};

const mapPropsToConfig = props => {
  const {
    location: { search },
  } = props;
  const query = querystring.parse(search.substring(1));
  const { page = "1" } = query;
  const pageInt = parseIntWithDefault(page, 1);
  const offset = pageInt * limit - limit;
  const params = querystring.stringify({
    joblisting__published: true,
    limit,
    offset,
    ordering: "-published_date",
  });
  return createJobsQuery({
    url: `/api/v2/jobs/?${params}`,
  });
};
const mapStateToProps = createResourceSelector("jobs", mapPropsToConfig);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  ApplicantsPreview,
);
