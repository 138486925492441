import styled from "styled-components";

export const IconSuccessCheck = styled.i.attrs({
  className: "success-check far fa-check-circle",
  "aria-hidden": "true",
})`
  justify-self: center;
  align-self: flex-start;
  margin: 0 10px;
  padding: 3px 0 0 0;
  font-size: 20px;
`;

export const IconDangerTimes = styled.i.attrs({
  className: "danger-times far fa-times-circle",
  "aria-hidden": "true",
})`
  justify-self: center;
  align-self: flex-start;
  margin: 0 10px;
  padding: 3px 0 0 0;
  font-size: 20px;
`;

export const IconEmptyCircle = styled.i.attrs({
  className: "far fa-circle",
  "aria-hidden": "true",
})`
  justify-self: center;
  align-self: flex-start;
  margin: 0 10px;
  padding: 3px 0 0 0;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary_black40()};
`;
