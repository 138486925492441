import styled from "styled-components";

const Main = styled.div`
  width: 100%;
  min-height: 50px;
  margin: 30px 0;
  padding: 0;
`;

export default Main;
