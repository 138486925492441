// @flow strict
import styled from "styled-components";
// $FlowFixMe 'Cannot resolve module redux-form.'
import { Field } from "redux-form";

import RadioOverlay from "./RadioOverlay";
import RadioCaption from "./RadioCaption";

const RadioField = styled(Field)`
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + ${RadioOverlay} {
    ${RadioCaption} {
      font-weight: 900;
    }
  }

  ${({ theme }) => theme.media.mobile`
    &:checked + ${RadioOverlay} {
      background-color: ${theme.colors.paler_turquoise()};
    }
  `};

  ${({ theme }) => theme.media.desktop`
    &:hover + ${RadioOverlay}:before {
      border-color: ${theme.colors.primary_turquoise()};
    }

    &:checked + ${RadioOverlay} {
      border-width: 2px;

      &:before {
        border: 2px solid ${theme.colors.primary_turquoise()};
        background-color: ${theme.colors.primary_turquoise()};
      }

      ${RadioCaption} {
        margin-top: 24px;
      }
    }
  `};
`;

export default RadioField;
