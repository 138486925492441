import * as React from "react";
import { Pagination } from "react-bootstrap";

const PaymentHistoryPagination = ({
  totalCount = 0,
  pageNumber = 0,
  pageSize = 10,
  onNextPageClick,
  onPreviousPageClick,
}) => {
  const numPages = Math.ceil(totalCount / pageSize);
  const hasPrevious = pageNumber > 0;
  const hasNext = pageNumber < numPages - 1; // pageNumber is zero-indexed
  const pageNumberDisplay = pageNumber + 1;

  if (numPages === 1) return null;

  const handleNextClick = () => hasNext && onNextPageClick();
  const handlePrevClick = () => hasPrevious && onPreviousPageClick();

  return (
    <Pagination>
      <Pagination.Prev disabled={!hasPrevious} onClick={handlePrevClick} />
      {hasPrevious && (
        <Pagination.Item onClick={handlePrevClick}>
          {pageNumberDisplay - 1}
        </Pagination.Item>
      )}
      <Pagination.Item active>{pageNumberDisplay}</Pagination.Item>
      {hasNext && (
        <Pagination.Item onClick={handleNextClick}>
          {pageNumberDisplay + 1}
        </Pagination.Item>
      )}
      <Pagination.Next disabled={!hasNext} onClick={handleNextClick} />
    </Pagination>
  );
};

export default PaymentHistoryPagination;
