import React, { Component } from "react";
import { connect, compose, connectResource } from "resources/components/utils";
import { Button, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Loading from "common/components/Loading";
import { ownContractorRequest } from "resources/requests";
import { getOwnContractor } from "contractor/selectors";
import EditProfileNavMenu from "contractor/components/profile/EditProfileNavMenu";

class EditProfile extends Component {
  render() {
    const {
      ownContractor: { isLoading, lastUpdated, data: contractor },
    } = this.props;

    return (
      <div className="edit-profile">
        <h1>
          Edit Profile
          <LinkContainer
            to="/contractor/profile/view"
            className="pull-right"
            style={{ fontSize: "1.6rem" }}
          >
            <Button>View Profile</Button>
          </LinkContainer>
        </h1>
        <Row>
          <Col md={3}>
            <EditProfileNavMenu isAttorney={contractor.is_attorney} />
          </Col>
          <Col md={8} mdOffset={1}>
            {lastUpdated
              ? React.cloneElement(this.props.children, { contractor })
              : isLoading && <Loading />}
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  connect(getOwnContractor),
  connectResource(ownContractorRequest),
)(EditProfile);
