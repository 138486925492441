import React, { Component } from "react";

import Side from "./blocks";
import LinkList from "./LinkList";

export default class MobileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseDown, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      this.toggleOpen();
    }
  }

  // Close on click away
  handleMouseDown = e => {
    const content = document.querySelector("#side-collapse");
    if (content && content.contains(e.target)) return;
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  handleClick = () => this.setState(state => ({ isOpen: !state.isOpen }));

  toggleOpen = () => {
    const content = document.querySelector("#side-collapse-content");
    if (this.state.isOpen) {
      content.style.maxHeight = content.scrollHeight + "px";
    } else {
      content.style.maxHeight = null;
    }
  };

  render() {
    const { currentStep, steps } = this.props;
    const { isOpen } = this.state;

    return (
      <div id="side-collapse">
        <Side.CollapseBase isOpen={isOpen}>
          <Side.CollapseHeader>Account Set-Up</Side.CollapseHeader>
          <Side.CollapseToggle onClick={this.handleClick}>
            <Side.CollapseBar />
            <Side.CollapseBar />
            <Side.CollapseBar />
          </Side.CollapseToggle>
        </Side.CollapseBase>
        <Side.CollapseContent id="side-collapse-content">
          <Side.CollapseContainer onClick={this.handleClick}>
            <LinkList currentStep={currentStep} steps={steps} mobile />
          </Side.CollapseContainer>
        </Side.CollapseContent>
      </div>
    );
  }
}
