export const application = {
  "RECEIVE/createApplication": (state, action) => ({
    ...state,
    data: action.error
      ? {
          ...state.data,
        }
      : {
          ...state.data,
          count: state.lastUpdated ? state.data.count + 1 : 1,
          results: state.lastUpdated
            ? [...state.data.results, action.payload]
            : [action.payload],
        },
  }),
};
