import styled from "styled-components";

const HideOnDesktop = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.media.desktop`
    display: none;
  `};
`;

export default HideOnDesktop;
