import React from "react";
import styled from "styled-components";

const WorkExpInfo = styled.p`
  float: right;
`;

const RefsBlock = styled.div``;

const Refs = props => <RefsBlock {...props} />;

Refs.WorkExpInfo = WorkExpInfo;

export default Refs;
