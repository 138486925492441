import React from "react";
import { ControlLabel, FormGroup } from "react-bootstrap";
import FieldHelpBlock from "common/components/forms/FieldHelpBlock";

import { domOnlyProps, validationState } from "common/utils/forms";
import Slider from "rc-slider";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export default props => {
  const {
    // redux-form Field props
    input,
    meta,
    // RangeSliderField props
    label,
    // props passed through to Range
    ...otherProps
  } = props;

  input.value = input.value || [];

  return (
    <FormGroup validationState={validationState(meta)}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <div className="years-experience-container">
        <Range
          {...domOnlyProps(input)}
          // onChange={this.handleChange}
          {...domOnlyProps(otherProps)}
        />
      </div>
      <FieldHelpBlock touched={meta.touched} error={meta.error} />
    </FormGroup>
  );
};
