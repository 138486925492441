import React, { Component } from "react";

import { Navigation } from "admin/components/layout";
import {
  NonAttorneyDetails,
  VerificationTypeSelect,
} from "admin/components/non-attorney-verification-manager";

class VerificationManager extends Component {
  render() {
    return (
      <div className="verification-manager">
        <Navigation />

        <div id="verifications">
          <div style={{ position: "relative", height: "100%" }}>
            <div id="pending-verifications">
              <VerificationTypeSelect
                formName="topVerificationTypeSelect"
                initialValues={{ verificationType: "Pending" }}
              />
            </div>
            <div id="recent-verifications">
              <VerificationTypeSelect
                formName="bottomVerificationTypeSelect"
                initialValues={{ verificationType: "Verified" }}
              />
            </div>
          </div>
        </div>
        <div id="verification-details">
          <NonAttorneyDetails />
        </div>
      </div>
    );
  }
}

export default VerificationManager;
