import styled from "styled-components";

import logo from "img/logo-small.svg";

const Brand = styled.img.attrs({
  alt: "scales logo for Hire an Esquire",
  id: "briefcase",
  src: logo,
})`
  float: left;
  width: auto;
  height: 34px;
  margin-right: 20px;
`;

export default Brand;
