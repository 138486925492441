import React, { Component } from "react";
import { connect } from "react-redux";
import { addNotification as notify } from "reapop";
import { SubmissionError } from "redux-form";

import { submitBarInformation } from "admin/actions/attorneyVerificationManager";
import NullOr from "common/components/NullOr";
import { BarInformationForm } from "admin/forms";
import { statesLookup } from "common/constants/states";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";

class EditBarInformation extends Component {
  handleSubmit = values => {
    const { barInfo, notify, onSubmit } = this.props;
    return onSubmit(barInfo.uuid, values).then(({ json, response }) => {
      if (response.ok) {
        notify(
          createSuccessNotification({
            title: "Bar info updated",
            message: `Bar information ${statesLookup[json.state].label} ${
              json.bar_id
            } for
                    ${json.full_name} has been  saved.`,
          }),
        );
      } else {
        notify(
          createErrorNotification({
            message: `Encountered an error when trying to save bar information
                    ${statesLookup[barInfo.state].label} ${
              barInfo.bar_id
            } for ${barInfo.full_name}. Please
                    refresh and try again.`,
          }),
        );
        throw new SubmissionError(json);
      }
    });
  };

  render() {
    const { barInfo } = this.props;
    const initialValues = {
      bar_id: barInfo.bar_id,
      state: barInfo.state,
    };

    return (
      <NullOr isNull={!barInfo}>
        <BarInformationForm
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
        />
      </NullOr>
    );
  }
}

export default connect(
  null,
  { onSubmit: submitBarInformation, notify },
)(EditBarInformation);
