import { handleActions } from "redux-actions";

import resourceReducers from "./resourceReducers";
import resourceStoreReducers from "./resourceStoreReducers";
import { listToObject } from "common/utils";

const createInitialResourceState = () => ({
  data: {},
  error: null,
  isLoading: false,
  lastUpdated: null,
  ready: false,
});

export const createResourceReducer = resource => {
  const handlers = resourceReducers[resource] || {};
  return handleActions(
    {
      "REQUEST/resource": (state, action) => {
        if (action.meta.options.type !== resource) return state;
        return {
          ...state,
          isLoading: true,
        };
      },
      "RECEIVE/resource": (state, action) => {
        if (action.meta.options.type !== resource) return state;
        return {
          ...state,
          isLoading: false,
          lastUpdated: action.meta.receivedAt,
          // TODO: Do we even need to store data here? Why not compute it from the store reducer with selectors?
          data: action.error ? state.data : action.payload,
          error: action.error ? action.payload : null,
          ready: true,
        };
      },
      ...handlers,
    },
    createInitialResourceState(),
  );
};

export const createResourceStoreReducer = resource => {
  const handlers = resourceStoreReducers[resource] || {};
  return handleActions(
    {
      "RECEIVE/resource": (state, action) => {
        const {
          payload,
          meta: { options },
        } = action;

        if (options.type !== resource) return state;
        if (options.method === "delete" && Boolean(options.id)) {
          const newState = { ...state };
          if (newState.hasOwnProperty(options.id)) {
            delete newState[options.id];
          }
          return newState;
        }

        let newState = {};
        if (Array.isArray(payload)) {
          newState = listToObject(payload, "uuid");
        } else if (payload.hasOwnProperty("results")) {
          newState = listToObject(payload.results, "uuid");
        } else if (payload.hasOwnProperty("uuid")) {
          newState = { [payload.uuid]: payload };
        }
        return {
          ...state,
          ...newState,
        };
      },
      ...handlers,
    },
    {},
  );
};
