import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import LegalProfSignupMarketing from "public/components/signup/LegalProfSignupMarketing";
import setCampaignParams from "common/components/setCampaignParams";
import setDocumentTitle from "common/components/setDocumentTitle";

export class LegalProfSelect extends Component {
  render() {
    return (
      <div>
        <div className="legal-prof-select">
          <LegalProfSignupMarketing />
          <div className="divider" />
          <div className="signup-path-container legal-prof">
            <h3>What type of legal professional are you?</h3>

            <Row>
              <div className="legal-prof-buttons-container">
                <Col md={6}>
                  <div className="legal-prof-button-container">
                    <div>
                      <LinkContainer to={{ pathname: "/attorney/signup/" }}>
                        <Button bsStyle="success" bsSize="lg">
                          Attorney
                        </Button>
                      </LinkContainer>
                      <div className="descriptions">
                        <div
                          className="text-left"
                          style={{ marginTop: "2rem" }}
                        >
                          Select if you:
                        </div>
                        <div className="list-container">
                          <ul>
                            <li>Have a law degree</li>
                            <li>Are licensed to practice</li>
                            <li>Are in good standing with the Bar</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} lgOffset={0}>
                  <div className="legal-prof-button-container">
                    <div>
                      <LinkContainer to={{ pathname: "/non-attorney/signup/" }}>
                        <Button bsStyle="success" bsSize="lg">
                          Other Legal Professional
                        </Button>
                      </LinkContainer>
                      <div className="descriptions">
                        <div
                          className="text-left"
                          style={{ marginTop: "2rem" }}
                        >
                          Select if you are a:
                        </div>
                        <div className="list-container">
                          <ul>
                            <li>Paralegal</li>
                            <li>Law Clerk</li>
                            <li>Project Manager</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default setDocumentTitle("Legal Professional Signup")(
  setCampaignParams(LegalProfSelect),
);
