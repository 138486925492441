// @flow strict
import React from "react";
import { Helmet } from "react-helmet";

import types from "resources/types";
import { createResourceSelector } from "queries";
import { compose, connect, connectRequest } from "queries/utils";
import { positionTypesQuery } from "queries/requests/positionTypes";
import ApplyNowButton from "public/components/jobs/ApplyNowButton";
import PublicJobMetaDescription from "public/components/jobs/PublicJobMetaDescription";

type Props = {
  jobListing: JobListing,
  positionTypes: QueryResource<PositionType>,
};

export const PublicJobHeader = (props: Props) => {
  const { jobListing, positionTypes } = props;
  const { REACT_APP_API_HOST, REACT_APP_FACEBOOK_APP_ID } = process.env;
  const title = jobListing.title
    ? `Apply for ${jobListing.title} | Hire an Esquire`
    : null;
  const imgURL = REACT_APP_API_HOST
    ? `${REACT_APP_API_HOST}/content/images/hae-logo-v1-white.png`
    : null;

  return (
    <div>
      <Helmet>
        {title && <title>{title}</title>}
        <meta property="og:url" content={window.location.href} />
        {jobListing.title && (
          <meta property="og:title" content={jobListing.title} />
        )}
        {jobListing.description && (
          <meta property="og:description" content={jobListing.description} />
        )}
        {imgURL && <meta property="og:image" content={imgURL} />}
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content={REACT_APP_FACEBOOK_APP_ID} />
      </Helmet>
      {positionTypes.isFinished && (
        <PublicJobMetaDescription
          jobListing={jobListing}
          positionTypes={positionTypes}
        />
      )}
      <h1 className="clearfix header">
        <span className="job-title" itemProp="title">
          {jobListing.title}
        </span>
        <ApplyNowButton />
      </h1>
    </div>
  );
};

const mapPropsToConfig = positionTypesQuery;
const mapStateToProps = createResourceSelector(
  types.POSITION_TYPES,
  positionTypesQuery,
);
export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(PublicJobHeader);
