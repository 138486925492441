import React from "react";

import Onboard from "../../blocks";
import Entry from "./Entry";
import ToolTip from "common/components/ToolTip";
import { barIDTooltipCopy, NoEntriesCopy } from "./copy";

export const MobileEntries = ({
  addJustUploadedId,
  barInfoObjs,
  barVerifObjs,
  justUploadedIds,
  onFileUploadError,
  refreshReqs,
}) => {
  const barInfoObjsLen = barInfoObjs.length;
  return (
    <div>
      {barInfoObjsLen === 0 ? (
        <NoEntriesCopy />
      ) : (
        barInfoObjs.map((barInfoObj, key) => {
          const barVerifId = barInfoObj.barcard_verifications[0];
          const barVerifObj = barVerifObjs.find(obj => obj.uuid === barVerifId);
          const hasData = Boolean(barInfoObj && barVerifObj);
          return hasData ? (
            <Entry
              addJustUploadedId={addJustUploadedId}
              barInfoObj={barInfoObj}
              barVerifObj={barVerifObj}
              justUploadedIds={justUploadedIds}
              last={barInfoObjsLen === key + 1}
              mobile
              onFileUploadError={onFileUploadError}
              refreshReqs={refreshReqs}
              key={key}
            />
          ) : null;
        })
      )}
    </div>
  );
};

export const DesktopEntries = ({
  addJustUploadedId,
  barInfoObjs,
  barVerifObjs,
  justUploadedIds,
  onFileUploadError,
  refreshReqs,
}) => (
  <Onboard.Table>
    <thead>
      <tr>
        <th>
          BAR ID{" "}
          <ToolTip content={barIDTooltipCopy} placement="top">
            <i className="far fa-info-circle" />
          </ToolTip>
        </th>
        <th>STATE</th>
        <th>DOCUMENT</th>
        <th>STATUS</th>
      </tr>
    </thead>
    <tbody>
      {barInfoObjs.length === 0 ? (
        <tr>
          <td colSpan="4">
            <NoEntriesCopy />
          </td>
        </tr>
      ) : (
        barInfoObjs.map((barInfoObj, key) => {
          const barVerifId = barInfoObj.barcard_verifications[0];
          const barVerifObj = barVerifObjs.find(obj => obj.uuid === barVerifId);
          const hasData = Boolean(barInfoObj && barVerifObj);
          return hasData ? (
            <Entry
              addJustUploadedId={addJustUploadedId}
              barInfoObj={barInfoObj}
              barVerifObj={barVerifObj}
              justUploadedIds={justUploadedIds}
              onFileUploadError={onFileUploadError}
              refreshReqs={refreshReqs}
              key={key}
            />
          ) : null;
        })
      )}
    </tbody>
  </Onboard.Table>
);
