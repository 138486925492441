import { combineReducers } from "redux";

import dates from "admin/reducers/invoicing/dates";
import expenses from "admin/reducers/invoicing/expenses";
import fixedRates from "admin/reducers/invoicing/fixedRates";
import invoices from "admin/reducers/invoicing/invoices";
import jobEvents from "admin/reducers/invoicing/jobEvents";
import timeEntries from "admin/reducers/invoicing/timeEntries";

export default combineReducers({
  dates,
  expenses,
  fixedRates,
  invoices,
  jobEvents,
  timeEntries,
});
