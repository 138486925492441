import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";

import { changeSchoolTranscriptStatus } from "admin/actions/nonAttorneyVerification";
import { VERIFICATION_STATUS } from "../utils";
import { createVisibilityStyle, StatusLabel } from "./utils";
import { createApiUrl } from "utils";

class _SchoolRow extends Component {
  render() {
    const {
      school,
      school: { schooltranscript },
      verify,
      reject,
    } = this.props;
    let transcript_single_use_url = null;

    if (schooltranscript && schooltranscript.transcript) {
      transcript_single_use_url = schooltranscript.transcript.single_use_url;
    }

    return (
      <tr>
        <td>
          <a
            href={createApiUrl(
              `/hae_admin/school/school/${school.uuid}/change/`,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-external-link-alt" />
          </a>
        </td>
        <td>
          {school.school_name}
          <br />
          <i>{school.degree_type}</i>
        </td>
        <td>{school.year_graduated}</td>
        <td>{school.gpa}</td>
        <td style={{ fontSize: "16px" }}>
          <span
            style={createVisibilityStyle(transcript_single_use_url !== null)}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={transcript_single_use_url}
            >
              <i className="fas fa-external-link-alt" />
            </a>&nbsp;&nbsp;&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              download
              href={transcript_single_use_url}
            >
              <i className="far fa-download" />
            </a>
          </span>
        </td>
        <td>
          <StatusLabel status={school.status} />
        </td>
        <td>
          <span
            style={createVisibilityStyle(
              school.status !== VERIFICATION_STATUS.REJECTED,
            )}
          >
            <Button bsSize="xs" bsStyle="danger" onClick={reject(school)}>
              Reject
            </Button>&nbsp;
          </span>
          <Button
            style={createVisibilityStyle(
              school.status !== VERIFICATION_STATUS.VERIFIED,
            )}
            bsSize="xs"
            bsStyle="success"
            onClick={verify(school)}
          >
            Verify
          </Button>
        </td>
      </tr>
    );
  }
}

const SchoolRow = connect(
  null,
  dispatch => ({
    verify: school => () => dispatch(changeSchoolTranscriptStatus(school, 1)),
    reject: school => () => dispatch(changeSchoolTranscriptStatus(school, -1)),
  }),
)(_SchoolRow);

export const SchoolsTable = ({ schools }) => (
  <Table>
    <thead>
      <tr>
        <th />
        <th>Degree</th>
        <th>Year Graduated</th>
        <th>GPA</th>
        <th>Transcript</th>
        <th style={{ width: "10rem" }}>Status</th>
        <th style={{ width: "20rem" }}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {schools.map(school => <SchoolRow key={school.uuid} school={school} />)}
    </tbody>
  </Table>
);
