import PropTypes from "prop-types";

const NullOr = ({ children, isNull }) => (isNull ? null : children);

NullOr.propTypes = {
  children: PropTypes.node.isRequired,
  isNull: PropTypes.bool.isRequired,
};

export default NullOr;
