// @flow
import React, { type ComponentType } from "react";
import ErrorBoundary from "./ErrorBoundary";

// TODO: Add FallbackComponent and options (notify and showError) as arguments
const withErrorBoundary = (
  Component: ComponentType<any>,
): Function => props => (
  <ErrorBoundary>
    <Component {...props} />
  </ErrorBoundary>
);

export default withErrorBoundary;
