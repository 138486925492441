import { createErrorNotification } from "common/utils/notifications";
import { addNotification as notify } from "reapop";
import { SubmissionError } from "redux-form";

const processErrors = ({ status, body = {} }) => {
  let message = "Please try again. If problem persists, contact support.";
  if (status === 400 && Object.keys(body).length > 0) {
    message = "Please fix the errors below.";
  }
  if (status === 500) {
    message =
      "There was a problem submitting your request. Please refresh this page and try again.";
  }
  if (body.hasOwnProperty("non_field_errors")) {
    body._error = body.non_field_errors;
    delete body.non_field_errors;
  }
  notify(createErrorNotification({ message }));
  throw new SubmissionError(body);
};

export default processErrors;
