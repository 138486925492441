// @flow strict
import styled from "styled-components";

const AlternateHeader = styled.p`
  position: absolute;
  top: 1px;
  right: 0;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary_black40()}
  font-weight: bold;
  text-transform: uppercase;
  font-size: calc(11px + 0.2vw);
`;

export default AlternateHeader;
