/** @flow */
import React from "react";
import * as Sentry from "@sentry/browser";

const ShowReportDialogButton = () => (
  <button
    style={{ color: "initial" }}
    onClick={() => Sentry.showReportDialog()}
  >
    Click here
  </button>
);

const style = {
  height: "100%",
  width: "100%",
  textAlign: "center",
  backgroundColor: "#C00",
  color: "#FFF",
};

const ErrorBoundaryDefaultComponent = () => (
  <div className="text-danger" style={style}>
    <i className="far fa-lg fa-exclamation-triangle" />{" "}
    <h4 style={{ display: "inline-block" }}>
      {"We're sorry - something's gone wrong."}
    </h4>
    <p>
      <ShowReportDialogButton /> to let us know what happened, then try
      refreshing the page.
    </p>
  </div>
);

ErrorBoundaryDefaultComponent.defaultProps = {
  componentStack: "",
};

export default ErrorBoundaryDefaultComponent;
