import React, { Component } from "react";
import { Button, ButtonToolbar, Table } from "react-bootstrap";

import { withLanguageFluency } from "contractor/connectors";
import Loading from "common/components/Loading";
import LanguageTooltip from "common/components/LanguageTooltip";

const languageTooltip = (
  <LanguageTooltip id={"contractor-edit-languages"} placement="bottom" />
);

class ListLanguages extends Component {
  render() {
    const {
      languageFluency: {
        data: { results = [] },
        isLoading,
      },
      onDeleteClick,
      onEditClick,
    } = this.props;

    return (
      <div>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Language</th>
              <th>Proficiency {languageTooltip}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {results.map(lang => (
              <tr key={lang.uuid}>
                <td>{lang.language}</td>
                <td>{lang.fluency}</td>
                <td>
                  <ButtonToolbar>
                    <Button
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={() => onEditClick(lang.uuid)}
                    >
                      Edit
                    </Button>
                    <Button
                      bsStyle="danger"
                      bsSize="xs"
                      onClick={() => onDeleteClick(lang.uuid)}
                    >
                      Delete
                    </Button>
                  </ButtonToolbar>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {isLoading ? (
          <Loading />
        ) : (
          results.length === 0 && (
            <p className="text-center">
              You don't have any languages proficiencies on file. Use the form
              below to add some.
            </p>
          )
        )}
      </div>
    );
  }
}

export default withLanguageFluency()(ListLanguages);
