import React from "react";

import Onboard from "../../blocks";

export const MainCopy = () => (
  <p>
    Please list your language proficiencies. Note that native or bilingual
    proficiency (S-5) in English is assumed and does not need to be listed.
  </p>
);

export const NoEntriesCopy = () => (
  <Onboard.TablePlaceholder>
    You don&apos;t have any language proficiencies on file. Click &quot;Add a
    Language&quot; to add.
  </Onboard.TablePlaceholder>
);

export const ProficiencyTooltipCopy = () => (
  <div className="tooltip-content">
    <p>
      <strong>This field is optional.</strong>
    </p>
    <p>
      We use the ILR (FSI) proficiency scale to measure language proficiency.
    </p>
    <p>The levels are as follows:</p>
    <ul>
      <li>
        <strong>S-1: </strong>Elementary Proficiency
      </li>
      <li>
        <strong>S-2: </strong>Limited Working Proficiency
      </li>
      <li>
        <strong>S-3: </strong>Professional Working Proficiency
      </li>
      <li>
        <strong>S-4: </strong>Full Professional Proficiency
      </li>
      <li>
        <strong>S-5: </strong>Native or Bilingual Proficiency
      </li>
    </ul>
    <p>
      <strong>
        Please Note: It is assumed contractors speak English proficiently.
      </strong>
    </p>
  </div>
);
