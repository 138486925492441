import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { NewFeatureNotificationsModal } from "common/components";
import {
  Messaging,
  MessageToContractor,
  InviteToContractor,
} from "common/components/Messaging";
import _NotFound from "common/components/NotFound";
import {
  ApplicantsPreview,
  JobApplicants,
} from "hiringagent-dashboard/components/applicants";
import { AttorneySearchContainer as AttorneySearch } from "hiringagent-dashboard/containers/AttorneySearch";
import Base from "hiringagent-dashboard/containers/Base";
import { Candidate } from "hiringagent-dashboard/components/candidates";
import ContractorProfile from "hiringagent-dashboard/components/contractor/ContractorProfile";
import {
  CreateJobListing,
  CreateJobListingContainer,
  CreateJobListingStep as _CreateJobListingStep,
  Dashboard,
  EditJobListing,
  TalentPoolsPage,
  PaymentDetailsCompleted as _PaymentDetailsCompleted,
  PaymentDetailsContainer,
  PaymentDetailsStep as _PaymentDetailsStep,
} from "hiringagent-dashboard/components";
import EditSettings from "hiringagent-dashboard/containers/settings/EditSettings";
import Invoice from "hiringagent-dashboard/containers/invoice/Invoice";
import Invoices from "hiringagent-dashboard/containers/invoice/Invoices";
import InvoiceDispute from "hiringagent-dashboard/components/invoice/InvoiceDispute";
import {
  Job,
  Jobs,
  ManageWorkers,
  PendingEntries,
} from "hiringagent-dashboard/components/jobs";
import { PaymentHistory } from "hiringagent-dashboard/components/payment-history";
import { Publish as _Publish } from "hiringagent-dashboard/components/jobs/create-job-listing";
import Recommendations from "hiringagent-dashboard/components/recommendations/Recommendations";
import Recommendation from "hiringagent-dashboard/components/recommendations/Recommendation";
import Reviews from "hiringagent-dashboard/containers/reviews/Reviews";
import {
  ExpiredConfirmationLink as _ExpiredConfirmationLink,
  HiringAgentRequired,
} from "hiringagent-dashboard/components/auth";
import Settings from "hiringagent-dashboard/containers/settings/Settings";
import { Base as PublicBase } from "public/components/layout";
import { FeedbackList, FeedbackSurvey } from "./components/Feedback";
import _VerificationDenied from "hiringagent-dashboard/components/VerificationDenied";
import _SignupComplete from "public/components/signup/SignupComplete";
import _HiringAgencySignup from "public/containers/signup/HiringAgencySignUp/";

const SignupBase = props => (
  <PublicBase empty={true} showSignupButton={false} {...props} />
);

const SignupContainer = props => (
  <div className="signup-container">{props.children}</div>
);

const NotFound = props => <_NotFound to="/agency" {...props} />;

const redirectUrls = [
  ["search", "attorneys"],
  ["attorney/profile/public/:contractorId", "attorneys/:contractorId"],
  ["bank_account/:id/verify", "bank-account/:id/verify"],
  ["not-verified", "verification-denied"],
];

const Signup = props => (
  <SignupBase>
    <_HiringAgencySignup {...props} />
  </SignupBase>
);

const SignupComplete = props => (
  <SignupBase>
    <SignupContainer>
      <_SignupComplete {...props} />
    </SignupContainer>
  </SignupBase>
);

const ExpiredConfirmationLink = props => (
  <SignupBase>
    <SignupContainer>
      <_ExpiredConfirmationLink {...props} />
    </SignupContainer>
  </SignupBase>
);

const VerificationDenied = props => (
  <Base>
    <_VerificationDenied {...props} />
  </Base>
);

const AttorneyRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={AttorneySearch} />
    <Route path={`${match.url}/:contractorId`} component={ContractorProfile} />
  </Switch>
);

const CandidateRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={ApplicantsPreview} />
    <Route path={`${match.url}/job/:id`} component={JobApplicants} />
    <Route path={`${match.url}/:id`} component={Candidate} />
  </Switch>
);

const InvoiceRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={Invoices} />
    <Route path={`${match.url}/:id/dispute`} component={InvoiceDispute} />
    <Route path={`${match.url}/:id`} component={Invoice} />
  </Switch>
);

const MessagingRoutes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/create`}
      render={() => <MessageToContractor userType={"agent"} />}
    />
    <Route path={`${match.url}/:jobId/invite`} component={InviteToContractor} />
    <Route path={`${match.url}/:id?`} component={Messaging} />
  </Switch>
);

const SettingsRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={Settings} />
    <Route path={`${match.url}/bank-account/:id/verify`} component={Settings} />
    <Route path={`${match.url}/discount-codes/:id?`} component={Settings} />
    <Route path={`${match.url}/agent/:id/edit`} component={EditSettings} />
  </Switch>
);

const CreateJobListingStep = props => (
  <CreateJobListingContainer {...props}>
    <_CreateJobListingStep {...props} />
  </CreateJobListingContainer>
);

const Publish = props => (
  <CreateJobListingContainer {...props}>
    <_Publish {...props} />
  </CreateJobListingContainer>
);

const PaymentDetailsCompleted = props => (
  <PaymentDetailsContainer {...props}>
    <_PaymentDetailsCompleted {...props} />
  </PaymentDetailsContainer>
);

const PaymentDetailsStep = props => (
  <PaymentDetailsContainer {...props}>
    <_PaymentDetailsStep {...props} />
  </PaymentDetailsContainer>
);

const CreateJobRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={CreateJobListing} />
    <Route
      path={`${match.url}/:id/recommended_candidates/:contractorId`}
      component={Recommendation}
    />
    <Route
      path={`${match.url}/:id/recommended_candidates`}
      component={Recommendations}
    />
    <Route path={`${match.url}/:id/publish`} component={Publish} />
    <Route
      path={`${match.url}/:id/step/:step`}
      component={CreateJobListingStep}
    />
  </Switch>
);

const JobRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={Jobs} />
    <Route path={`${match.url}/create`} component={CreateJobRoutes} />
    <Route path={`${match.url}/edit/:id`} component={EditJobListing} />
    <Route
      path={`${match.url}/:id/pending-entries`}
      component={PendingEntries}
    />
    <Redirect
      exact
      from={`${match.url}/payment_details/:id`}
      to={`${match.url}/payment_details/:id/step/0`}
    />
    <Route
      path={`${match.url}/payment_details/:id/completed`}
      component={PaymentDetailsCompleted}
    />
    <Route
      path={`${match.url}/payment_details/:id/step/:step`}
      component={PaymentDetailsStep}
    />
    <Route path={`${match.url}/:id`} component={Job} />
  </Switch>
);

const AgencyRoutes = ({ match }) => (
  <HiringAgentRequired>
    <Base>
      <Switch>
        <Route exact path={match.url} component={Dashboard} />
        <Route path={`${match.url}/attorneys`} component={AttorneyRoutes} />
        <Route path={`${match.url}/candidates`} component={CandidateRoutes} />
        <Redirect
          strict
          exact
          from={`${match.url}/talent_pools/`}
          to={`${match.url}/talent_pools`}
        />
        <Route path={`${match.url}/talent_pools`} component={TalentPoolsPage} />
        <Route path={`${match.url}/invoices`} component={InvoiceRoutes} />
        <Route path={`${match.url}/messages`} component={MessagingRoutes} />
        <Route path={`${match.url}/reviews`} component={Reviews} />
        <Route path={`${match.url}/settings`} component={SettingsRoutes} />
        <Route path={`${match.url}/workers`} component={ManageWorkers} />
        <Route path={`${match.url}/jobs`} component={JobRoutes} />
        <Route exact path={`${match.url}/feedback`} component={FeedbackList} />
        <Route path={`${match.url}/feedback/:id`} component={FeedbackSurvey} />
        <Route
          path={`${match.url}/payment-history`}
          component={PaymentHistory}
        />
        <Route component={NotFound} />
      </Switch>
      <NewFeatureNotificationsModal />
    </Base>
  </HiringAgentRequired>
);

export default ({ match }) => (
  <Switch>
    {redirectUrls.map(([from, to], i) => (
      <Redirect
        key={`agency-redirect-${i}`}
        from={`${match.url}/${from}`}
        to={`${match.url}/${to}`}
      />
    ))}
    <Route path={`${match.url}/signup/:id?`} component={Signup} />
    <Route path={`${match.url}/signup_complete`} component={SignupComplete} />
    <Route
      path={`${match.url}/agent/add/complete/:code_one/:haeuser_pk/r/:code_two`}
      component={ExpiredConfirmationLink}
    />
    <Route
      path={`${match.url}/verification-denied`}
      component={VerificationDenied}
    />
    <Route component={AgencyRoutes} />
  </Switch>
);
