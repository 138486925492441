import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";

import { connect, compose, withResource } from "resources/components/utils";
import { positionTypesRequest, tagGroupsRequest } from "resources/requests";
import { TextField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";
import JobListing from "types/JobListing";
import { FormRow, withFormError } from "./utils";

export function getGeneratedJobTitle(jobListing, tagGroups, positionTypes) {
  const {
    max_years_experience,
    min_years_experience,
    primary_practice_area,
    position_type,
    remote,
  } = jobListing;
  const haveResources = Boolean(
    Object.keys(tagGroups).length && Object.keys(positionTypes).length,
  );
  const haveListingSelections = Boolean(primary_practice_area && position_type);
  const shouldGenerateTitle = haveResources && haveListingSelections;

  function getPrimaryPracticeArea() {
    return (
      tagGroups[primary_practice_area] && tagGroups[primary_practice_area].name
    );
  }

  function getPositionType() {
    return positionTypes[position_type] && positionTypes[position_type].name;
  }

  function getRemote() {
    return remote ? "(remote)" : "";
  }

  function getExperience() {
    const yearsExpOption = JobListing.experienceOptions.find(
      option =>
        option.min === min_years_experience &&
        option.max === max_years_experience,
    );
    const yearsExpLabel = yearsExpOption && yearsExpOption.label;
    return yearsExpLabel === "Any" ? "" : yearsExpLabel;
  }

  function getComposedTitle() {
    const titleParts = [
      getExperience(),
      getPrimaryPracticeArea(),
      getPositionType(),
      getRemote(),
    ];
    const composedTitle = titleParts.join(" ");
    return composedTitle.trim();
  }

  if (shouldGenerateTitle) {
    return getComposedTitle();
  }
  return null;
}

const requiredFields = ["title"];

const validate = values => validateRequiredFields(requiredFields, values);

const GeneratedTitleExplanation = ({ generatedTitle }) => (
  <div style={{ marginTop: "2rem" }}>
    <p>Here&apos;s a suggested title:</p>
    <p className="text-center" style={{ fontWeight: "700", height: "22px" }}>
      {generatedTitle}
    </p>
  </div>
);

export class JobTitleForm extends Component {
  handleResetToGeneratedTitle = e => {
    const { change, generatedTitle } = this.props;
    e.preventDefault();
    change("title", generatedTitle);
  };

  isTitleSuggestedTitle(currentTitle, generatedTitle) {
    return (
      currentTitle &&
      generatedTitle &&
      currentTitle.trim() === generatedTitle.trim()
    );
  }

  renderTitleHelpText = isSuggestedTitle =>
    isSuggestedTitle
      ? this.renderSuggestedTitleHelpText()
      : this.renderCustomTitleHelpText();

  renderSuggestedTitleHelpText = () => (
    <p>Feel free to customize - no need to include dates or locations.</p>
  );

  renderCustomTitleHelpText = () => {
    const {
      formValues: { currentTitle },
      generatedTitle,
    } = this.props;
    return (
      currentTitle &&
      generatedTitle && (
        <p>
          This is your custom title. To use the suggested title (above), click{" "}
          <a href="" onClick={e => this.handleResetToGeneratedTitle(e)}>
            here
          </a>.
        </p>
      )
    );
  };

  render() {
    const {
      formValues: { currentTitle = null },
      generatedTitle,
      handleSubmit,
    } = this.props;
    const isSuggestedTitle = this.isTitleSuggestedTitle(
      currentTitle,
      generatedTitle,
    );

    return (
      <form onSubmit={handleSubmit}>
        <FormRow>
          <TextField name="title" placeholder="Job title" />
          <div>{this.renderTitleHelpText(isSuggestedTitle)}</div>
        </FormRow>
      </form>
    );
  }
}

const ConnectedJobTitleForm = compose(
  connect((state, props) => ({
    formValues: {
      currentTitle: formValueSelector(props.form)(state, "title"),
    },
  })),
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(JobTitleForm);

export class JobTitle extends Component {
  render() {
    const {
      formProps,
      jobListing,
      jobListing: { primary_practice_area, position_type },
      tagGroups: {
        metadata: { ready: practiceAreasReady },
        data: tagGroupsData,
      },
      positionTypes: {
        metadata: { ready: positionTypesReady },
        data: positionTypesData,
      },
    } = this.props;

    const readyToGenerateTitle = practiceAreasReady && positionTypesReady;
    const shouldShowGeneratedTitle =
      Boolean(primary_practice_area) && Boolean(position_type);
    const generatedTitle =
      readyToGenerateTitle && shouldShowGeneratedTitle
        ? getGeneratedJobTitle(jobListing, tagGroupsData, positionTypesData)
        : null;

    const initialValues = {
      title: jobListing.title || generatedTitle,
    };

    return (
      <div>
        <h2>Create a job title.</h2>
        <div className="description">
          <p>Describe your ideal candidate, concisely!</p>
          {generatedTitle && (
            <GeneratedTitleExplanation generatedTitle={generatedTitle} />
          )}
        </div>
        <ConnectedJobTitleForm
          {...formProps}
          initialValues={initialValues}
          generatedTitle={generatedTitle}
        />
      </div>
    );
  }
}

export default compose(
  withResource(tagGroupsRequest),
  withResource(positionTypesRequest),
)(JobTitle);
