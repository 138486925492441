import React from "react";

const Loading = props => (
  <div style={{ textAlign: "center", ...props.style }}>
    <i className="far fa-cog fa-spin fa-2x" />
    &nbsp;Loading...
  </div>
);

export default Loading;
