import React, { Component } from "react";
import { Field, FieldArray } from "redux-form";
import {
  ButtonToolbar,
  Col,
  FormGroup,
  Panel,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";

import DeleteButton from "common/components/DeleteButton";
import { FieldHelpBlock } from "common/components/forms";
import {
  CheckboxField,
  CityStateGeosuggest as LocationInput,
} from "common/components/forms/inputs";
import { Error } from "common/utils/forms/validators";

const DISTANCE_OPTIONS = {
  "20": 20,
  "50": 50,
  "100": 100,
};
const DEFAULT_DISTANCE = DISTANCE_OPTIONS["50"];

class LocationRangeInput extends Component {
  handleBlur = event => {
    const distanceInt = Number(event.target.value);
    this.props.input.onBlur(Number(distanceInt));
  };

  render() {
    return (
      <div className="location-range-input">
        <ButtonToolbar>
          <ToggleButtonGroup
            bsSize="sm"
            type="radio"
            {...this.props.input}
            onBlur={this.handleBlur}
          >
            {Object.values(DISTANCE_OPTIONS).map(option => (
              <ToggleButton key={option} value={option}>
                {option} miles
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>
    );
  }
}

const LocationRangePanel = props => {
  const {
    onDelete,
    meta: { touched, error },
    label,
  } = props;
  return (
    <Panel className="location-range-panel">
      <Panel.Heading>
        <Panel.Title>
          <div className="location-title">{label}</div>
          <DeleteButton
            onDelete={onDelete}
            className="pull-right"
            iconSize="sm"
          />
        </Panel.Title>
      </Panel.Heading>
      <LocationRangeInput {...props} />
      {touched && error && <span>{error}</span>}
    </Panel>
  );
};

const isInvalid = value =>
  Object.values(value).some(v => [undefined, null].includes(v));
const isValueInFields = (value, fields) =>
  Boolean(
    fields
      .getAll()
      .find(field => field.city === value.city && field.state === value.state),
  );
const invalidSelectionError = (
  <Error>Invalid selection, please select a city.</Error>
);

class LocationsInputArray extends Component {
  state = {
    invalidSelection: false,
    touched: false,
  };

  handleAddLocation = value => {
    if (!value) return;
    const getAddressComponentByType = type =>
      value.gmaps.address_components.find(component =>
        component.types.includes(type),
      );
    const city = getAddressComponentByType("locality");
    const state = getAddressComponentByType("administrative_area_level_1");
    const newValue = {
      city: city && city.long_name,
      state: state && state.short_name,
      latitude: value.location.lat.toPrecision(8),
      longitude: value.location.lng.toPrecision(8),
      distance_from_location: DEFAULT_DISTANCE,
    };

    const invalidSelection = isInvalid(newValue);
    const valueInFields = isValueInFields(newValue, this.props.fields);

    if (!invalidSelection && !valueInFields) {
      this.props.fields.push(newValue);
    }
    this.setState({ invalidSelection });
  };

  handleDeleteLocation = index => () => {
    this.props.fields.remove(index);
    this.setState({
      invalidSelection: false,
      touched: true,
    });
  };

  handleLocationSearchBlur = () => {
    this.setState({
      touched: true,
    });
  };

  render() {
    const {
      fields,
      meta: { error },
    } = this.props;
    const { invalidSelection, touched } = this.state;

    return (
      <div className="city-input-fields">
        <FormGroup validationState={"error"}>
          <LocationInput
            onAddLocation={this.handleAddLocation}
            placeholder="Search for cities where you would like to work:"
            onBlur={this.handleLocationSearchBlur}
          />
          {invalidSelection ? (
            <FieldHelpBlock touched={true} error={invalidSelectionError} />
          ) : (
            <FieldHelpBlock touched={touched} error={error} />
          )}
        </FormGroup>

        {Boolean(fields.length) && (
          <p>
            Select the distance you are willing to travel from each city to a
            job.
          </p>
        )}

        <Row>
          {fields.map((location, i) => {
            const field = fields.get(i);
            return (
              <Col sm={12} md={4} key={i}>
                <Field
                  key={i}
                  name={`${location}.distance_from_location`}
                  component={LocationRangePanel}
                  label={`${field.city}, ${field.state}`}
                  onDelete={this.handleDeleteLocation(i)}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default class LocationSection extends Component {
  render() {
    return (
      <div className="locations-section">
        <CheckboxField
          name="remote_positions_ok"
          label={<span>I'm interested in remote opportunities</span>}
          className="input-large"
        />
        <FieldArray
          name="locationpreference_set"
          component={LocationsInputArray}
        />
      </div>
    );
  }
}
