import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { algolia, basicAlgoliaArgs } from "common/api/algolia";
import { oldApi } from "common/api";
import { setQueryParams } from "public/utils/search";

const actions = keyMirror({
  INITIALIZE_SEARCH: null,
  RECEIVE_PRACTICE_AREAS_OPTIONS: null,
  REQUEST_ATTORNEYS: null,
  RECEIVE_ATTORNEYS: null,
  UPDATE_SEARCH_KEYWORDS: null,
  REMOVE_QUERY_PARAM: null,
  SUBMIT_QUERY: null,
  UPDATE_QUERY_PARAMS: null,
  INCREMENT_PAGE_NUMBER: null,
  TOGGLE_SHOW_FILTERS: null,
  CLEAR_FILTERS: null,
  REPLACE_PRACTICE_AREAS: null,
  UPDATE_PRACTICE_AREA: null,
  UPDATE_ATTORNEY_FAVORITE: null,
  REMOVE_ATTORNEY: null,
  RECEIVE_ALGOLIA: null,
});
export default actions;

const metaGenerator = () => ({ receivedAt: Date.now() });

function updateHistory(query, replace = false) {
  Object.keys(query).forEach(key => {
    if ([null, undefined].includes(query[key])) {
      delete query[key];
    }
  });
  setQueryParams(query, replace);
}

function getPageOfAttorneys(dispatchRequest = true) {
  return (dispatch, getState) => {
    if (dispatchRequest) {
      dispatch(createAction(actions.REQUEST_ATTORNEYS)());
    }
    const { queryParams } = getState().hiringAgentDashboard.search;
    const verified =
      getState().hiringAgentDashboard.agency.agency.verified === "Verified";

    oldApi.post("/api/v2/search/", { queryParams });

    // Algolia testing
    const args = { ...basicAlgoliaArgs(verified), page: queryParams.page };
    // TODO: If not verified, don't allow names to be searchable attributes (should they even if verified?)
    if (verified) {
      args.attributesToRetrieve.push("_index_profile_picture_url");
      args.attributesToRetrieve.push("first_name");
      args.attributesToHighlight.push("first_name");
    } else {
      args.attributesToRetrieve.push("first_name_initial");
      args.attributesToHighlight.push("first_name_initial");
    }

    const {
      practice_areas,
      barred_in,
      miles,
      proficient_in,
      is_available,
      years_experience,
      max_years_experience,
      lat,
      lng,
      contractor_type,
    } = queryParams;
    if (practice_areas) {
      args.facetFilters.push(`_index_practice_areas:${practice_areas}`);
    }
    if (barred_in) {
      args.facetFilters.push(`_index_barred_in_list:${barred_in}`);
    }
    if (years_experience) {
      args.numericFilters = args.numericFilters.concat(
        `_index_years_experience>=${years_experience}`,
      );
    }
    if (max_years_experience) {
      args.numericFilters = args.numericFilters.concat(
        `_index_years_experience<=${max_years_experience}`,
      );
    }
    if (lat && lng) {
      args.aroundLatLng = `${lat},${lng}`;
      if (miles) {
        args.aroundRadius = Math.floor(miles * 1609.34);
      }
    }
    if (proficient_in && proficient_in !== "English") {
      args.facetFilters.push(
        `_index_language_proficiency_list:${proficient_in}`,
      );
    }
    if (is_available) {
      args.facetFilters.push(`_index_is_available:${is_available}`);
    }
    if (contractor_type && contractor_type === "non_attorneys") {
      args.facetFilters.push("contractor_type:-Attorney");
    }
    if (!contractor_type || contractor_type === "attorneys") {
      args.facetFilters.push("contractor_type:Attorney");
    }

    const {
      metadata: {
        data: { algolia_index },
      },
    } = getState().common;

    return algolia(algolia_index).search(
      queryParams.keywords,
      args,
      (err, content) => {
        const payload = err ? new Error(err) : content;
        return dispatch(
          createAction(
            actions.RECEIVE_ALGOLIA,
            payload => payload,
            metaGenerator,
          )(payload),
        );
      },
    );
  };
}

export function initialize(query = {}) {
  return dispatch => {
    dispatch(createAction(actions.INITIALIZE_SEARCH)(query));
    return oldApi
      .get("/api/dev/tags/?limit=999")
      .then(({ json, response }) => {
        const payload = response.ok
          ? json
          : new Error("Error retrieving practice areas.");
        return dispatch(
          createAction(actions.RECEIVE_PRACTICE_AREAS_OPTIONS)(payload),
        );
      })
      .then(() => dispatch(getPageOfAttorneys()));
  };
}

export const updateSearchKeywords = createAction(
  actions.UPDATE_SEARCH_KEYWORDS,
);

export function submitSearchQuery(newParams = {}) {
  return (dispatch, getState) => {
    dispatch(createAction(actions.SUBMIT_QUERY)(newParams));
    updateHistory(getState().hiringAgentDashboard.search.queryParams);
    return dispatch(getPageOfAttorneys());
  };
}

export function resubmitSearchQuery() {
  return (dispatch, getState) =>
    dispatch(
      submitSearchQuery(getState().hiringAgentDashboard.search.queryParams),
    );
}

export function removeQueryParam(paramName) {
  return dispatch => {
    dispatch(createAction(actions.REMOVE_QUERY_PARAM)(paramName));
    return dispatch(submitSearchQuery());
  };
}

export function getNextPage() {
  return (dispatch, getState) => {
    dispatch(createAction(actions.INCREMENT_PAGE_NUMBER)());
    updateHistory(getState().hiringAgentDashboard.search.queryParams, true);
    return dispatch(getPageOfAttorneys(false));
  };
}

export const toggleShowFilters = createAction(actions.TOGGLE_SHOW_FILTERS);

export function clearFilters() {
  return dispatch => {
    dispatch(createAction(actions.CLEAR_FILTERS)());
    return dispatch(submitSearchQuery());
  };
}

export function updatePracticeArea(newPracticeArea) {
  return dispatch => {
    dispatch(createAction(actions.UPDATE_PRACTICE_AREA)(newPracticeArea));
    return dispatch(submitSearchQuery());
  };
}

export const removeAttorney = createAction(actions.REMOVE_ATTORNEY);
