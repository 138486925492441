import React from "react";
import moment from "moment";

import Togglers from "../Togglers";
import MessagesControl from "./MessagesControl";
import AlternateFooter from "./AlternateFooter";
import {
  TextStyles,
  PaperclipIcon,
  StyledListGroupItem,
  StyledListGroup,
  StyledDate,
  MessagesWrapper,
} from "../blocks";
import MobileOnly from "common/blocks/MobileOnly";
import Loading from "common/components/Loading";

const MessagesItem = ({
  message: {
    archived,
    from_self,
    messages: thread,
    subject_highlight: subject,
    recipients,
    sender,
    unread,
    uuid,
  },
  archivedLoadId,
  unreadLoadId,
  onTogglersClick,
  ...otherProps
}) => {
  if (!uuid) return null;
  const lastThread = thread && thread[0] ? thread[0] : null;
  let body = lastThread ? lastThread.body_highlight : null;
  if (body && body.length > 75) {
    body = body.slice(0, 75) + "...";
  }
  const otherUser = from_self ? recipients[0] : sender;
  if (!otherUser) return null;
  let hasAttachments = false;
  if (lastThread) {
    for (let i = 0; i < thread.length; i++) {
      if (thread[i].attachments.length) {
        hasAttachments = true;
        break;
      }
    }
  }

  return (
    <StyledListGroupItem unread={unread ? "true" : "false"} {...otherProps}>
      <TextStyles>
        {lastThread && (
          <StyledDate>
            {moment(lastThread.created).format("MM/DD/YY")}
          </StyledDate>
        )}
        <label>{`${otherUser.first_name} ${otherUser.last_name}`}</label>
        <h4 dangerouslySetInnerHTML={{ __html: subject }} />
        {body && <p dangerouslySetInnerHTML={{ __html: body }} />}
      </TextStyles>
      {hasAttachments && <PaperclipIcon />}
      <Togglers
        archivedLoadId={archivedLoadId}
        archived={archived}
        unreadLoadId={unreadLoadId}
        onTogglersClick={onTogglersClick}
        unread={unread}
        uuid={uuid}
      />
    </StyledListGroupItem>
  );
};

const MessagesList = ({
  activeMsgKey,
  messagesData,
  mQuery,
  onMessageItemClick,
  onTogglersClick,
  archivedLoadId,
  unreadLoadId,
}) => (
  <StyledListGroup>
    {mQuery ? (
      mQuery.count === 0 ? (
        <center>
          <p style={{ margin: "25px 0" }}>Empty.</p>
        </center>
      ) : (
        mQuery.data.map(uuid => (
          <MessagesItem
            active={activeMsgKey === uuid}
            key={uuid}
            message={messagesData[uuid]}
            onClick={e => onMessageItemClick(uuid, e)}
            onTogglersClick={onTogglersClick}
            archivedLoadId={archivedLoadId}
            unreadLoadId={unreadLoadId}
          />
        ))
      )
    ) : (
      <Loading style={{ margin: "25px 0" }} />
    )}
    {mQuery && (
      <MobileOnly>
        <AlternateFooter />
      </MobileOnly>
    )}
  </StyledListGroup>
);

const Messages = ({
  activeMsgKey,
  messagesData,
  mQuery,
  offset,
  onSearch,
  onSearchChange,
  onFilterChange,
  onMessageItemClick,
  onTogglersClick,
  onPaginateMessages,
  archivedLoadId,
  unreadLoadId,
  pendingSearch,
  filter,
}) => (
  <MessagesWrapper>
    <MessagesControl
      offset={offset}
      onSearch={onSearch}
      onSearchChange={onSearchChange}
      onFilterChange={onFilterChange}
      onPaginateMessages={onPaginateMessages}
      mQuery={mQuery}
      pendingSearch={pendingSearch}
      filter={filter}
    />
    <MessagesList
      activeMsgKey={activeMsgKey}
      messagesData={messagesData}
      mQuery={mQuery}
      onMessageItemClick={onMessageItemClick}
      onTogglersClick={onTogglersClick}
      archivedLoadId={archivedLoadId}
      unreadLoadId={unreadLoadId}
    />
  </MessagesWrapper>
);

export default Messages;
