import styled from "styled-components";

const Plus = styled.i.attrs({
  className: "fa",
  "aria-hidden": "true",
})`
  margin: 0;
  padding: 0;
  transform: rotate(45deg);

  &:before {
    content: "\00d7";
    font-size: 18px;
  }
`;

export default Plus;
