import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { mutateAsync } from "redux-query";
import { connectRequest } from "redux-query-react";
import { Row } from "react-bootstrap";

import { Loading } from "common/components";
import InvoiceHeader from "hiringagent-dashboard/components/invoice/InvoiceHeader";
import InvoiceActionAlerts from "hiringagent-dashboard/components/invoice/InvoiceActionAlerts";
import InvoiceSummary from "hiringagent-dashboard/components/invoice/InvoiceSummary";
import InvoiceDates from "hiringagent-dashboard/components/invoice/InvoiceDates";
import InvoiceDetails from "hiringagent-dashboard/components/invoice/InvoiceDetails";
import InvoiceEntries from "hiringagent-dashboard/components/invoice/InvoiceEntries";

import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import { approveInvoiceQuery } from "queries/mutations/invoices";
import types from "resources/types";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.handleApproveClick = this.handleApproveClick.bind(this);
  }

  handleApproveClick(e) {
    e.preventDefault();
    this.props.approveInvoice();
  }

  render() {
    const {
      match: {
        params: { id },
      },
      [types.INVOICES]: { data: invoices },
      [types.JOBS]: {
        data: jobs,
        query: {
          data: [jobsQueryData],
        },
      },
      [types.PAYMENT_DETAILS]: {
        data: paymentDetails,
        query: {
          data: [paymentDetailsQueryData],
        },
      },
    } = this.props;

    const invoice = invoices[id];
    const job = jobs[jobsQueryData];
    const paymentDetail = paymentDetails[paymentDetailsQueryData];

    return !(invoice && job && paymentDetail) ? (
      <Loading />
    ) : (
      <div>
        <InvoiceHeader invoice={invoice} />
        <InvoiceActionAlerts
          invoice={invoice}
          onApproveClick={e => this.handleApproveClick(e)}
        />
        <Row>
          <InvoiceSummary invoice={invoice} paymentDetail={paymentDetail} />
          <InvoiceDates invoice={invoice} />
          <InvoiceDetails invoice={invoice} job={job} />
        </Row>
        <InvoiceEntries
          invoiceId={invoice.uuid}
          paymentDetail={paymentDetail}
        />
      </div>
    );
  }
}

const invoiceQuery = (props, extraConfig = {}) =>
  createResourceDetailQuery(types.INVOICES, {
    url: `/api/dev/invoices/${props.match.params.id}/`,
    ...extraConfig,
  });

const jobsQuery = props =>
  createResourceListQuery(types.JOBS, {
    url: `/api/v2/jobs/?invoice=${props.match.params.id}`,
  });

const paymentDetailsQuery = props =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/?job__invoice=${props.match.params.id}`,
  });

const mapPropsToConfig = props => [
  invoiceQuery(props),
  jobsQuery(props),
  paymentDetailsQuery(props),
];

const invoiceSelectorConfig = createResourceSelectorConfig(
  types.INVOICES,
  invoiceQuery,
);
const jobsSelectorConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);
const paymentDetailsSelectorConfig = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...invoiceSelectorConfig,
  ...jobsSelectorConfig,
  ...paymentDetailsSelectorConfig,
});

const mapDispatchToProps = (dispatch, props) => ({
  approveInvoice: () =>
    dispatch(mutateAsync(approveInvoiceQuery(props.match.params.id))),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Invoice);
