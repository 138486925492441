import React from "react";

import { SelectField } from ".";
import { JobListing } from "types";

const ProjectLengthSelectField = () => (
  <SelectField name="project_length" label="Estimated project length">
    {JobListing.projectLengthOrdering.map(key => (
      <option
        key={`expected-duration-option-${key}`}
        value={Number.parseInt(key, 10)}
      >
        {JobListing.projectLengthOptions[key]}
      </option>
    ))}
  </SelectField>
);

export default ProjectLengthSelectField;
