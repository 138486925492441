import React from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";

const DismissRecommendationModal = props => {
  const {
    recommendation: { contractor, joblisting_title },
    show,
    close,
    feedbackText,
    onChange,
    onDismissClick,
    submitting,
  } = props;

  const feedbackTextArea = (
    <FormGroup>
      <ControlLabel style={{ margin: 0 }}>
        Provide a reason (optional)
      </ControlLabel>
      <div style={{ marginBottom: "8px" }}>
        {/* TODO: Remove note about contractor being able to see job recommendation once dismissed
                    TODO: recommendations are hidden from contractors */}
        <small>
          The candidate will not be notified that they have been dismissed from
          recommendations, though they may still see that they were recommended
          for this job. Any feedback that you provide will be used for internal
          purposes only and will not be shared with the candidate.
        </small>
      </div>
      <FormControl
        componentClass="textarea"
        placeholder={
          "Ex: Candidate is lacking sufficient practice area expertise for this role."
        }
        value={feedbackText}
        onChange={onChange}
        rows={5}
      />
    </FormGroup>
  );

  return (
    <ConfirmModal
      show={show}
      close={close}
      title={`Remove ${contractor.full_name} from recommendations for ${joblisting_title}?`}
      body={feedbackTextArea}
      actions={
        <Button bsStyle="danger" disabled={submitting} onClick={onDismissClick}>
          Dismiss
        </Button>
      }
    />
  );
};

DismissRecommendationModal.propTypes = {
  recommendation: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  feedbackText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDismissClick: PropTypes.func.isRequired,
};

export default DismissRecommendationModal;
