// @flow strict
import React, { Component } from "react";
import { compose, connect, connectRequest } from "queries/utils";

import { partition } from "common/utils";
import { createResourceListQuery, createResourceSelector } from "queries";
import types, { JobListing } from "resources/types";

type Props = {
  handleDisplayParent: () => {},
  jobListing: JobListing,
  jobRequirements: QueryResource<JobRequirement>,
};

type State = {
  requiredRequirements: Array<JobRequirement>,
  preferredRequirements: Array<JobRequirement>,
  gotRequirements: boolean,
};

class JobPrimaryPracticeAreaRequirements extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      requiredRequirements: [],
      preferredRequirements: [],
      gotRequirements: false,
    };
  }

  componentDidMount() {
    // When isFinished is true and component may not update
    const {
      jobRequirements: { isFinished },
    } = this.props;
    if (!this.state.gotRequirements && isFinished) {
      this.getRequirements();
    }
  }

  componentDidUpdate() {
    // When isFinished becomes true after component updates
    const {
      jobRequirements: { isFinished },
    } = this.props;
    if (!this.state.gotRequirements && isFinished) {
      this.getRequirements();
    }
  }

  getRequirements = () => {
    const {
      handleDisplayParent,
      jobRequirements: {
        data: jobRequirementsData,
        query: { data: jobRequirementsQueryData },
      },
    } = this.props;
    const jobRequirements = jobRequirementsQueryData
      .map(id => jobRequirementsData[id])
      .filter(req => req.category === "Primary Practice Area Requirement");

    const isRequiredCategory = (requirement: JobRequirement) =>
      requirement.preference === "Required";
    const [requiredRequirements, preferredRequirements] = partition(
      jobRequirements,
      isRequiredCategory,
    );
    this.setState({
      requiredRequirements,
      preferredRequirements,
      gotRequirements: true,
    });

    if (requiredRequirements.length > 0 || preferredRequirements.length > 0) {
      handleDisplayParent();
    }
  };

  render() {
    const { requiredRequirements, preferredRequirements } = this.state;
    const listRequirementItem = req => <li key={req.uuid}>{req.question}</li>;
    return (
      <div>
        {requiredRequirements.length > 0 && (
          <div style={{ marginBottom: "2rem" }}>
            <h4>Required</h4>
            <ul>{requiredRequirements.map(listRequirementItem)}</ul>
          </div>
        )}
        {preferredRequirements.length > 0 && (
          <div style={{ marginBottom: "2rem" }}>
            <h4>Preferred</h4>
            <ul>{preferredRequirements.map(listRequirementItem)}</ul>
          </div>
        )}
      </div>
    );
  }
}

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOB_REQUIREMENTS, {
    url: `/api/v2/requirements/?joblisting=${props.jobListing.uuid}&limit=999`,
  });

const mapStateToProps = createResourceSelector(
  types.JOB_REQUIREMENTS,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobPrimaryPracticeAreaRequirements);
