// @flow
import { type Uuid } from "./primitives";

export default class JobWorker {
  uuid: Uuid;
  contractor: Uuid;
  job: Uuid;
  active: ?boolean;
  created: string;
  updated: string;

  constructor(jobWorker: Object) {
    this.uuid = jobWorker.uuid;
    this.contractor = jobWorker.contractor;
    this.job = jobWorker.job;
    this.active = jobWorker.active;
    this.created = jobWorker.created;
    this.updated = jobWorker.updated;
  }
}
