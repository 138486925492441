import React, { Component } from "react";
import { compose } from "redux";
import { FieldArray, reduxForm } from "redux-form";
import { Alert } from "react-bootstrap";

import { PracticeAreaRequirementsFieldArray } from "common/components/forms/inputs";
import { Warning } from "common/utils/forms/validators";
import { withFormError } from "./utils";

const warn = values => {
  const warnings = {};
  const { primarypracticearearequirement_set = [] } = values;
  const noRequirementsChosen =
    primarypracticearearequirement_set.filter(
      req => req.preference !== practiceAreaRequirementChoices.NOT_NEEDED,
    ).length === 0;
  if (noRequirementsChosen) {
    warnings.primarypracticearearequirement_set = {
      _warning: (
        <Warning>
          Are you sure you don&apos;t want to include any job requirements? We
          recommend choosing at least 5 requirements.
        </Warning>
      ),
    };
  }
  return warnings;
};

const practiceAreaRequirementChoices = {
  NOT_NEEDED: -1,
  REQUIRED: 0,
  PREFERRED: 1,
};

class PracticeAreaRequirementsForm extends Component {
  render() {
    const { formValues, error } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit}
        className="practice-area-requirement-form"
      >
        <div>
          <FieldArray
            name="primarypracticearearequirement_set"
            component={PracticeAreaRequirementsFieldArray}
            formValues={formValues}
          />
        </div>
        {error && (
          <Alert bsStyle="danger">Please resolve the errors above.</Alert>
        )}
      </form>
    );
  }
}

PracticeAreaRequirementsForm = compose(
  reduxForm({
    warn,
    enableReinitialize: true,
  }),
  withFormError(),
)(PracticeAreaRequirementsForm);

export default class PracticeAreaRequirements extends Component {
  handleSubmit = values => {
    const {
      formProps: { onSubmit },
    } = this.props;
    const submitValues = {
      primarypracticearearequirement_set: values.primarypracticearearequirement_set.filter(
        value => value.preference !== practiceAreaRequirementChoices.NOT_NEEDED,
      ),
    };
    return onSubmit(submitValues);
  };

  render() {
    const {
      formProps,
      jobListing: {
        allowed_applicants,
        primary_practice_area,
        primarypracticearearequirement_set,
      },
    } = this.props;
    const initialValues = { primarypracticearearequirement_set };
    return (
      <div>
        <h2>What are the job requirements?</h2>
        <p className="description">
          Indicate whether each job requirement below is required, preferred, or
          not needed. Applicants will be asked if they meet the selected job
          requirements.
        </p>
        <PracticeAreaRequirementsForm
          {...formProps}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          formValues={{ allowed_applicants, primary_practice_area }}
        />
      </div>
    );
  }
}
