import React from "react";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";

import { savedContractorsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";
import {
  CreateSavedContractorButton,
  SavedContractorTalentPoolDropdown,
} from ".";

const SavedContractorButton = props => {
  const { contractorId } = props;
  const request = savedContractorsRequest({
    url: { params: { contractor: contractorId } },
  });
  const selector = createResourceSelector(types.SAVED_CONTRACTORS, request);
  const {
    [types.SAVED_CONTRACTORS]: {
      data,
      query: { data: queryData, count },
    },
  } = useSelector(selector);
  const [{ isPending }, refresh] = useRequest(request);
  const isSavedContractor = count > 0;

  if (isPending) return null;

  if (!isSavedContractor) {
    return (
      <CreateSavedContractorButton
        contractorId={contractorId}
        onSubmitSuccess={refresh}
      />
    );
  }

  const savedContractor = data[queryData[0]];
  return (
    <SavedContractorTalentPoolDropdown savedContractor={savedContractor} />
  );
};

export default SavedContractorButton;
