import { action } from "common/actions/utils";

const options = {
  type: "reviewRequests",
  version: "v2",
};

export const createReferenceRequest = data =>
  action({
    ...options,
    actionType: "resource",
    path: "review_requests",
    method: "post",
    data,
  });

export const deleteReferenceRequest = referenceRequestId =>
  action({
    ...options,
    path: `review_requests/${referenceRequestId}`,
    method: "delete",
  });

export const resendReferenceRequest = referenceRequestId =>
  action({
    ...options,
    path: `review_requests/${referenceRequestId}/resend/`,
    method: "post",
  });
