import React from "react";
import { Col, Table } from "react-bootstrap";
import { formatDateTime } from "common/utils/helpers";

const InvoiceDates = ({ invoice }) => (
  <Col lg={5}>
    <div className="main-info-container invoice-table-container">
      <h2 className="main-info-title">Dates</h2>
      <Table>
        <tbody>
          {!invoice.paid_in_full ? (
            <tr className="text-danger">
              <td className="col-md-5">Due</td>
              <td>{formatDateTime(invoice.due_date)}</td>
            </tr>
          ) : (
            <tr>
              <td className="col-md-5">Due</td>
              <td>{formatDateTime(invoice.due_date)}</td>
            </tr>
          )}
          <tr>
            <td className="col-md-5">Created</td>
            <td>{formatDateTime(invoice.created, true)}</td>
          </tr>
          <tr>
            <td>Start</td>
            <td>{formatDateTime(invoice.start_date, true)}</td>
          </tr>
          <tr>
            <td>End</td>
            <td>{formatDateTime(invoice.end_date, true)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  </Col>
);

export default InvoiceDates;
