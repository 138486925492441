import { createAction } from "redux-actions";
import keyMirror from "keymirror";
import { requestAsync } from "redux-query";
import { agencyQuery } from "queries/requests/agencies";

import { metaGenerator } from "common/actions";
import api, { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_PAYMENT_SOURCES: null,
  RECEIVE_PAYMENT_SOURCES: null,
  REQUEST_SUBMIT_VERIFY_BANK_ACCOUNT: null,
  RECEIVE_SUBMIT_VERIFY_BANK_ACCOUNT: null,
  REQUEST_SUBMIT_SET_ACTIVE_SOURCE: null,
  RECEIVE_SUBMIT_SET_ACTIVE_SOURCE: null,
  REQUEST_SUBMIT_DELETE_SOURCE: null,
  RECEIVE_SUBMIT_DELETE_SOURCE: null,
  REQUEST_SUBMIT_PAYMENT_SOURCE_FORM: null,
  RECEIVE_SUBMIT_PAYMENT_SOURCE_FORM: null,
  SET_VERIFICATION_ACCOUNT_ID: null,
  SET_FOCUSED_SOURCE: null,
  TOGGLE_ADD_PAYMENT_ACCT_MODAL: null,
});
export default actions;

export const requestPaymentSources = () =>
  api.get("/api/v2/payment_sources/", [
    actions.REQUEST_PAYMENT_SOURCES,
    actions.RECEIVE_PAYMENT_SOURCES,
  ]);

export const submitVerifyBankAccount = data => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_VERIFY_BANK_ACCOUNT)());
  return oldApi
    .post(`/api/v2/payment_sources/${data.paymentSource}/verify/`, data)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error updating payment source.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_VERIFY_BANK_ACCOUNT,
          payload => payload,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const submitSetActiveSource = paymentSourceId => dispatch => {
  dispatch(
    createAction(actions.REQUEST_SUBMIT_SET_ACTIVE_SOURCE)({
      sourceId: paymentSourceId,
    }),
  );
  return oldApi
    .post(`/api/v2/payment_sources/${paymentSourceId}/make_active/`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error setting payment source active.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_SET_ACTIVE_SOURCE,
          payload => payload,
          metaGenerator,
        )(payload),
      );
      dispatch(requestPaymentSources());
      return { json, response };
    });
};

export const submitDeleteSource = paymentSourceId => dispatch => {
  dispatch(
    createAction(actions.REQUEST_SUBMIT_DELETE_SOURCE)({
      sourceId: paymentSourceId,
    }),
  );
  return oldApi
    .del(`/api/v2/payment_sources/${paymentSourceId}/`)
    .then(response => {
      const payload = response.ok
        ? { paymentSourceId }
        : new Error("Error deleting payment source.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_DELETE_SOURCE,
          payload => payload,
          metaGenerator,
        )(payload),
      );
      dispatch(requestPaymentSources());
      dispatch(requestAsync(agencyQuery({ force: true })));
      return dispatch(toggleModal());
    });
};

export const submitPaymentSourceForm = (data, action) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_PAYMENT_SOURCE_FORM)());
  const paymentSourceType = action === "ADD_BANK" ? "bank" : "card";
  return oldApi
    .post("/api/v2/payment_sources/", data)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error(`Error creating new ${paymentSourceType} account`);
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_PAYMENT_SOURCE_FORM,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const setFocusedSource = createAction(actions.SET_FOCUSED_SOURCE);
export const setVerificationAccountId = createAction(
  actions.SET_VERIFICATION_ACCOUNT_ID,
);
export const toggleModal = createAction(actions.TOGGLE_ADD_PAYMENT_ACCT_MODAL);
