import React, { Component, Fragment } from "react";
import { compose, connect, connectRequest } from "queries/utils";
import {
  Image,
  MenuItem,
  Nav,
  NavDropdown,
  NavItem,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Header } from "common/components/layout";
import MessageIcon from "contractor/components/layout/MessageIcon";
import MessageTray from "contractor/components/layout/MessageTray";
import { createResourceDetailQuery, createResourceSelector } from "queries";
import types from "resources/types";

const ContractorImage = ({ profilePictureUrl, firstName, lastName }) => (
  <span style={{ textTransform: "none" }}>
    {profilePictureUrl && (
      <Image
        src={profilePictureUrl}
        style={{ width: "20px", height: "20px", marginRight: "0.5rem" }}
        circle
      />
    )}
    {firstName}&nbsp;{lastName}
  </span>
);

const createMessageTrayOverlay = onLinkClick => (
  <Popover
    id="popover-messages-tray"
    className="messages-tray"
    title="New Messages"
  >
    <MessageTray onLinkClick={onLinkClick} />
  </Popover>
);

class MessagesMenuItem extends Component {
  handleLinkClick = () => {
    this.overlay.hide();
  };

  render() {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        ref={overlay => {
          this.overlay = overlay;
        }}
        overlay={createMessageTrayOverlay(this.handleLinkClick)}
        rootClose
      >
        <NavItem style={{ position: "relative" }} {...this.props}>
          <MessageIcon />
          <span className="visible-xs-inline-block">&nbsp;Messages</span>
        </NavItem>
      </OverlayTrigger>
    );
  }
}

class ContractorHeader extends Component {
  static defaultProps = {
    enableLinks: true,
  };

  render() {
    const {
      [types.OWN_CONTRACTOR]: {
        data: ownContractorData,
        query: { data: ownContractorQueryData },
        isFinished,
      },
      enableLinks,
    } = this.props;

    const contractor = ownContractorData[ownContractorQueryData[0]];

    const settingsDropdownTitle = isFinished ? (
      <ContractorImage
        firstName={contractor.first_name}
        lastName={contractor.last_name}
        profilePictureUrl={contractor.profile_picture_url}
      />
    ) : (
      " "
    );
    return (
      <Header>
        <Fragment>
          {enableLinks && (
            <Nav style={{ marginLeft: "1rem" }}>
              <LinkContainer to="/contractor/profile/view">
                <NavItem eventKey={1}>Profile</NavItem>
              </LinkContainer>
              <LinkContainer to="/contractor/settings">
                <NavItem eventKey={2}>Settings</NavItem>
              </LinkContainer>
              <LinkContainer to="/contractor/jobs">
                <NavItem eventKey={3}>Find Jobs</NavItem>
              </LinkContainer>
            </Nav>
          )}
          <Nav pullRight>
            <MessagesMenuItem eventKey={4} />
            <NavDropdown
              eventKey={5}
              title={settingsDropdownTitle}
              id="navbar-settings-dropdown"
              pullRight
            >
              <LinkContainer to="/logout">
                <MenuItem eventKey={5.1}>Logout</MenuItem>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Fragment>
      </Header>
    );
  }
}

const mapPropsToConfig = () =>
  createResourceDetailQuery(types.OWN_CONTRACTOR, {
    url: "/api/dev/contractors/self/",
  });

const mapStateToProps = createResourceSelector(
  types.OWN_CONTRACTOR,
  mapPropsToConfig,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(ContractorHeader);
