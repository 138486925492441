// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Main from "./Main";
import Top from "./Top";
import Predictor from "./Predictor";
import InfoCircle from "./InfoCircle";
import LabelContainer from "./LabelContainer";
import Label from "./Label";
import BarLabel from "./BarLabel";
import BarOuter from "./BarOuter";
import BarInner from "./BarInner";
import Description from "./Description";
import HiddenDivider from "./HiddenDivider";

const PercentileBlock: ComponentType<Props> = styled.div`
  margin: 0 0 50px 0;
`;

const Percentile = (props: Props) => <PercentileBlock {...props} />;

Percentile.Main = Main;
Percentile.Top = Top;
Percentile.Predictor = Predictor;
Percentile.InfoCircle = InfoCircle;
Percentile.LabelContainer = LabelContainer;
Percentile.Label = Label;
Percentile.BarLabel = BarLabel;
Percentile.BarOuter = BarOuter;
Percentile.BarInner = BarInner;
Percentile.Description = Description;
Percentile.HiddenDivider = HiddenDivider;

export default Percentile;
