import { mutateResourceQuery } from "queries";
import types from "resources/types";
import { getCookie } from "common/utils";

export const addBarInformationsQuery = data =>
  mutateResourceQuery(
    types.BAR_INFORMATIONS,
    {
      url: "/api/v1/bar_informations/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const addBarcardFileQuery = (data, verifId) =>
  mutateResourceQuery(
    types.BARCARD_VERIFICATIONS,
    {
      url: `/api/v1/barcard_verifications/${verifId}/upload_barcard_file/`,
      options: {
        headers: {
          Accept: "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
        method: "POST",
      },
    },
    data,
  );
