import React from "react";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import Entry from "./Entry";
import { MainCopy, NoEntriesCopy, PendingVerificationCopy } from "./copy";
import Label from "common/blocks/Label";

export const MobileEntries = ({
  actionIsRequired,
  customErrorMsg,
  onDeleteClick,
  onEditClick,
  onFooterClick,
  prevEmployments,
  verifIsPending,
}) => (
  <div>
    <h1>Work Experience</h1>
    {actionIsRequired && (
      <div>
        <Label color="danger">ACTION REQUIRED</Label>
        <br />
        <br />
      </div>
    )}
    {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
    {verifIsPending && <PendingVerificationCopy />}
    <MainCopy />
    <Onboard.Container>
      <div>
        {prevEmployments.length === 0 ? (
          <NoEntriesCopy />
        ) : (
          prevEmployments.map((prevEmployment, key) => {
            return (
              <Entry
                key={key}
                last={prevEmployments.length === key + 1}
                mobile
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                prevEmployment={prevEmployment}
              />
            );
          })
        )}
      </div>
      <Onboard.ContainerFooter onClick={onFooterClick}>
        + ADD WORK EXPERIENCE
      </Onboard.ContainerFooter>
    </Onboard.Container>
  </div>
);

export const DesktopEntries = ({
  actionIsRequired,
  customErrorMsg,
  onDeleteClick,
  onEditClick,
  onFooterClick,
  prevEmployments,
  verifIsPending,
}) => (
  <Onboard.Container>
    <Onboard.ContainerHeader>
      <div>
        <h1>Work Experience</h1>
      </div>
      <div>
        {actionIsRequired && <Label color="danger">ACTION REQUIRED</Label>}
      </div>
    </Onboard.ContainerHeader>
    {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
    {verifIsPending && <PendingVerificationCopy />}
    <MainCopy />
    <div>
      {prevEmployments.length === 0 ? (
        <Onboard.Table>
          <tbody>
            <tr>
              <td colSpan="1">
                <NoEntriesCopy />
              </td>
            </tr>
          </tbody>
        </Onboard.Table>
      ) : (
        prevEmployments.map((prevEmployment, key) => {
          return (
            <Entry
              key={key}
              last={prevEmployments.length === key + 1}
              mobile
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              prevEmployment={prevEmployment}
            />
          );
        })
      )}
    </div>
    <Onboard.ContainerFooter onClick={onFooterClick}>
      + ADD WORK EXPERIENCE
    </Onboard.ContainerFooter>
  </Onboard.Container>
);
