import React, { Fragment } from "react";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import moment from "moment";

import Deposit from "./blocks";
import {
  CheckboxField,
  DatePickerField,
  TextField,
  FirstNameInput,
  LastNameInput,
  PhoneNumberInput,
  EmailAddressInput,
  StateSelect,
} from "common/components/forms/inputs";
import {
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateEmailAddress,
  validateAddress,
  validateCity,
  validateState,
  validatePostalCode,
  validateDOB,
  validateSSN,
  validateBankRouting,
  validateBankAccount,
  validateACHAgreement,
} from "common/utils/forms/validatorsByField";
import {
  idLabel,
  accountInfoLabel,
  ssnLabel,
  achAgreeLabel,
  countryLabel,
} from "./labels";

const requiredLabel = (label, required) => {
  if (required) {
    return (
      <div>
        {label}
        <Deposit.RedText>*</Deposit.RedText>
      </div>
    );
  } else {
    return label;
  }
};

export const PersonalInfoFieldGroup = ({
  dynamic,
  hasBankData,
  isStripeVerified,
  need,
  onChangePrefill,
  hasContractorData,
}) => {
  const isNewUser = !dynamic && !hasBankData && !isStripeVerified;

  return (
    <Fragment>
      {!dynamic &&
        hasContractorData && (
          <div>
            <CheckboxField
              name="hae_info"
              label={accountInfoLabel}
              onChange={onChangePrefill}
            />
          </div>
        )}
      <Row>
        {(isNewUser || (need && need.first_name)) && (
          <Col sm={6}>
            <FirstNameInput
              label={requiredLabel("First Name", true)}
              validate={validateFirstName}
            />
          </Col>
        )}
        {(isNewUser || (need && need.last_name)) && (
          <Col sm={6}>
            <LastNameInput
              label={requiredLabel("Last Name", true)}
              validate={validateLastName}
            />
          </Col>
        )}
      </Row>
      <Row>
        {(!dynamic || (need && need.phone_home)) && (
          <Col sm={6}>
            <PhoneNumberInput
              label={requiredLabel("Phone Number", true)}
              name="phone_home"
              validate={validatePhoneNumber}
            />
          </Col>
        )}
        {(!dynamic || (need && need.email_address)) && (
          <Col sm={6}>
            <EmailAddressInput
              label={requiredLabel("Email Address", true)}
              validate={validateEmailAddress}
            />
          </Col>
        )}
      </Row>
      <Row>
        {(!dynamic || (need && need.address_1)) && (
          <Col sm={6}>
            <TextField
              label={requiredLabel("Address Line 1", true)}
              name="address_1"
              validate={validateAddress}
            />
          </Col>
        )}
        {(!dynamic || (need && need.address_2)) && (
          <Col sm={6}>
            <TextField
              label={requiredLabel("Address Line 2", false)}
              name="address_2"
            />
          </Col>
        )}
      </Row>
      <Row>
        {(!dynamic || (need && need.city)) && (
          <Col sm={4}>
            <TextField
              label={requiredLabel("City", true)}
              name="city"
              validate={validateCity}
            />
          </Col>
        )}
        {(!dynamic || (need && need.state)) && (
          <Col sm={4}>
            <StateSelect
              label={requiredLabel("State", true)}
              validate={validateState}
            />
          </Col>
        )}
        {(!dynamic || (need && need.postal_code)) && (
          <Col sm={2}>
            <TextField
              label={requiredLabel("Zip Code", true)}
              name="postal_code"
              validate={validatePostalCode}
            />
          </Col>
        )}
        {!dynamic && (
          <Col sm={2}>
            <TextField
              disabled
              label={countryLabel}
              name="country"
              placeholder="USA"
            />
          </Col>
        )}
      </Row>
      <Row>
        {(isNewUser || (need && need.birthdate)) && (
          <Col sm={6}>
            <DatePickerField
              name="birthdate"
              label={requiredLabel("Birth Date", true)}
              maxDate={moment()
                .subtract(13, "years")
                .toDate()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              validate={validateDOB}
            />
          </Col>
        )}
        {(isNewUser || (need && need.personal_id_number)) && (
          <Col sm={6}>
            <TextField
              label={requiredLabel(ssnLabel, true)}
              name="social_security_number"
              validate={validateSSN}
            />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export const BankInfoFieldGroup = () => (
  <Row>
    <Col sm={6}>
      <TextField
        label={requiredLabel("Routing Number", true)}
        name="routing_number"
        validate={validateBankRouting}
      />
    </Col>
    <Col sm={6}>
      <TextField
        label={requiredLabel("Account Number", true)}
        name="account_number"
        validate={validateBankAccount}
      />
    </Col>
  </Row>
);

export const IDUploadField = ({ activeUpload, handleChange }) => (
  <Row>
    <Col sm={6}>
      <FormGroup>
        <ControlLabel>{requiredLabel(idLabel, true)}</ControlLabel>
        <FormControl
          componentClass="select"
          name="id_upload"
          onChange={handleChange}
          value={activeUpload}
        >
          <option value="default">Choose a government issued ID</option>
          <option value="state_id">State-issued Identification</option>
          <option value="passport">Passport</option>
        </FormControl>
      </FormGroup>
    </Col>
  </Row>
);

export const ACHAgreeField = () => (
  <div>
    <CheckboxField
      name="ach_agree"
      label={achAgreeLabel}
      validate={validateACHAgreement}
    />
  </div>
);
