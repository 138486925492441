// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Badge from "./Badge";
import Brand from "./Brand";
import NavLink from "./NavLink";
import NavButton from "common/blocks/NavButton";

type Props = {};

const TopBlock: ComponentType<Props> = styled.nav`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Top = (props: Props) => <TopBlock {...props} />;

Top.Badge = Badge;
Top.Brand = Brand;
Top.NavLink = NavLink;
Top.Button = NavButton;

export default Top;
