// @flow strict
import styled from "styled-components";

const QuestionHeader = styled.p`
  text-align: left;
  font-size: 18px;
  font-weight: bold;

  ${({ theme }) => theme.media.desktop`
    margin: 0;
  `};
`;

export default QuestionHeader;
