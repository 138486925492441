import types from "resources/types";
import { mutateResourceQuery } from "queries";

export const createApplicationMutation = data =>
  mutateResourceQuery(
    types.CANDIDATES,
    {
      url: "/api/v2/applications/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const createCandidateMutation = data =>
  mutateResourceQuery(
    types.CANDIDATES,
    {
      url: "/api/v2/candidates/",
      options: {
        method: "POST",
      },
    },
    data,
  );
