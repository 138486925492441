import fetch from "isomorphic-fetch";
import { createApiResourceRetriever } from "common/actions";
import { getCookie } from "common/utils";
import { createApiUrl } from "utils";

export const createApiRequest = method => (
  url,
  data = null,
  extraHeaders = {},
) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...extraHeaders,
  };
  const args = {
    credentials: "include",
    method,
    headers,
  };
  if (["POST", "PATCH", " PUT"].includes(method)) {
    args.body = JSON.stringify(data);
    headers["X-CSRFToken"] = getCookie("csrftoken");
  }
  return fetch(createApiUrl(url), args).then(response => {
    redirectOnUnauthorizedResponse(response);
    return response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json); // TODO: Get error message from server
      }
      return json;
    });
  });
};

export const redirectOnUnauthorizedResponse = response => {
  if (response.status === 403 && response.headers.get("Redirect")) {
    window.location.href = response.headers.get("Redirect");
  }
};

// Signature: (url, requestActionType, receiveActionType, data) => (dispatch, getState) => {...}
export const get = createApiResourceRetriever(createApiRequest("GET"));
export const patch = createApiResourceRetriever(createApiRequest("PATCH"));
export const post = createApiResourceRetriever(createApiRequest("POST"));
export const del = createApiResourceRetriever(createApiRequest("DELETE"));

export default {
  get,
  patch,
  post,
  del,
};
