import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";

import { TextField } from "common/components/forms/inputs";

export const formName = "conflictForm";
export const requiredFields = [
  "name_of_matter",
  "client_represented",
  "name_of_firm",
  "opposing_party",
];

class ConflictForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <TextField name="name_of_matter" label="Matter" />
        <TextField name="client_represented" label="Client" />
        <TextField name="name_of_firm" label="Firm" />
        <TextField name="opposing_party" label="Opposing Party" />
        <TextField name="firm_for_opposing_party" label="Opposing Counsel" />
        <TextField name="nature_of_matter" label="Nature of Matter" />
        <div className="text-right">
          <Button
            type="submit"
            bsStyle="primary"
            disabled={pristine || submitting}
          >
            {submitting ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ConflictForm);
