// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {
  badge: number | boolean,
};

const Badge: ComponentType<Props> = styled.span`
  vertical-align: middle;
  margin: 0;
  padding: 2px 6px;
  font-size: 10px;
  line-height: 42px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white()};
  background-color: ${({ theme }) => theme.colors.secondary_red()};
  border-radius: 10px;
  visibility: ${({ badge }) => (badge ? "visible" : "hidden")};

  ${({ theme }) => theme.media.sm`
      line-height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
  `};
`;

export default Badge;
