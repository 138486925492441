// @flow strict
import React from "react";

import TextContainer from "./TextContainer";

type TOSByUserTypeProps = {
  tosByUserType: string,
};

type TOSProps = {
  tosObj: TermsOfService,
};

export { default as TermsOfService } from "./index";

export { default as TOSAccept } from "./TOSAccept";

export const TOSByUserType = ({ tosByUserType }: TOSByUserTypeProps) => (
  <TextContainer termsMarkdown={tosByUserType} />
);

export const TOSGeneral = ({ tosObj }: TOSProps) => (
  <TextContainer termsMarkdown={tosObj.tos_markup_general} />
);

export const TOSContractor = ({ tosObj }: TOSProps) => (
  <TextContainer termsMarkdown={tosObj.tos_markup_attorney} />
);

export const TOSAgency = ({ tosObj }: TOSProps) => (
  <TextContainer termsMarkdown={tosObj.tos_markup_hiring_agent} />
);
