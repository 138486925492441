import styled from "styled-components";

const TopLabel = styled.h4`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary_black()};
  font-weight: 700;
`;

export default TopLabel;
