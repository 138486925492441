// @flow strict
import React from "react";
// $FlowFixMe 'Cannot resolve module redux-form.'
import { reduxForm } from "redux-form";

import { RadioInputGroup } from "contractor/components";
import Workstyle from "contractor/components/WorkstyleAssessment/blocks";
import validate from "./validate";

const ExampleAssessmentForm = ({
  handleSubmit,
  handleUpdateProgress,
  questions,
}) => (
  <Workstyle.Form onSubmit={handleSubmit}>
    {questions.map(question => (
      <Workstyle.QuestionGroup key={question.uuid}>
        <div>
          <Workstyle.QuestionHeader>{question.header}</Workstyle.QuestionHeader>
          <Workstyle.Question>{question.content}</Workstyle.Question>
        </div>
        <RadioInputGroup
          handleUpdateProgress={handleUpdateProgress}
          name={"question" + question.uuid.toString()}
        />
      </Workstyle.QuestionGroup>
    ))}
  </Workstyle.Form>
);

export default reduxForm({
  form: "exampleAssessmentForm",
  destroyOnUnmount: true,
  onSubmit: (result, dispatch, props) => validate(result, props),
  onSubmitSuccess: (result, dispatch, props) => props.setPage(2),
})(ExampleAssessmentForm);
