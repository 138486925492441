import keyMirror from "keymirror";

import { createApiRequest } from "common/api/api";
import { createErrorCallback } from "common/actions";

const actions = keyMirror({
  "RECEIVE/changed_user_type": null,
});
export default actions;

export const requestUserType = expectedUserType => dispatch =>
  createApiRequest("GET")("/api/v2/current_user_type/").then(
    pollUntilNotLoggedIn(expectedUserType, dispatch),
    createErrorCallback(dispatch, actions["RECEIVE/changed_user_type"]),
  );

// TODO: Disabling this functionality for now (v2-authentication)
function pollUntilNotLoggedIn() {
  return () => {};
  //   const actualUserType = json.user_type;

  //   if (actualUserType === expectedUserType) {
  //     setTimeout(() => {
  //       dispatch(requestUserType(expectedUserType));
  //     }, 20000);
  //   } else {
  //     createCallback(dispatch, actions["RECEIVE/changed_user_type"])(json);
  //     // Log out user if user type has changed
  //     browserHistory.push("/logout");
  //   }
  // };
}
