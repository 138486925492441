import React, { Component } from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { Alert, Button, ButtonToolbar, Modal } from "react-bootstrap";

import { connect, compose } from "resources/components/utils";
import {
  DividerBottom,
  ThreadReplyFormWrapper,
  ResponsiveTextAreaField,
} from "../blocks";
import PrimaryButton from "common/blocks/PrimaryButton";
import { validateRequiredFields } from "common/utils/forms/validators";
import ThreadAttachments from "./ThreadAttachments";

const formName = "threadReplyForm";
const requiredFields = ["body"];
const validate = values => {
  let errors = validateRequiredFields(requiredFields, values);
  return errors;
};

const AttachmentsModal = ({
  fileError,
  files,
  filesExist,
  onCloseModal,
  onFileUploadError,
  onRemoveFile,
  showModal,
  ...otherProps
}) => (
  <Modal backdrop="static" show={showModal} onHide={onCloseModal}>
    <Modal.Header closeButton>
      <Modal.Title>Attachments</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ThreadAttachments
        filesExist={filesExist}
        attachments={files}
        onRemoveFile={onRemoveFile}
        onFileUploadError={onFileUploadError}
        fileError={fileError}
        {...otherProps}
      />
      <br />
      <ButtonToolbar>
        <PrimaryButton bsStyle="primary" onClick={onCloseModal}>
          Done
        </PrimaryButton>
      </ButtonToolbar>
    </Modal.Body>
  </Modal>
);

class ThreadReplyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { fileError: false, showModal: false };
  }

  handleCloseModal = () => this.setState({ showModal: false });

  handleFileUploadError = () => this.setState({ fileError: true });

  handleOpenModal = () => this.setState({ showModal: true });

  handleRemoveFile = attachmentName => {
    const { files } = this.props;
    const fileIdx = files.findIndex(file => file.name === attachmentName);
    if (fileIdx > -1) {
      this.props.array.remove("files", fileIdx);
    }
  };

  render() {
    const {
      state: { fileError, showModal },
      props: { error, files = [], handleSubmit, submitting, ...otherProps },
      handleCloseModal,
      handleFileUploadError,
      handleOpenModal,
      handleRemoveFile,
    } = this;

    const filesExist = Boolean(files.length);

    return (
      <ThreadReplyFormWrapper onSubmit={handleSubmit}>
        <DividerBottom />
        {error && <Alert bsStyle="danger">{error}</Alert>}
        <ResponsiveTextAreaField
          name="body"
          placeholder={"Type a message..."}
        />
        <ButtonToolbar className="pull-right">
          <Button bsStyle="success" onClick={handleOpenModal}>
            <i className="fas fa-paperclip" /> {`Attachments (${files.length})`}
          </Button>
          <Button bsStyle="primary" type="submit">
            <i className="fal fa-paper-plane" />{" "}
            {submitting ? "Sending..." : "Send"}
          </Button>
        </ButtonToolbar>
        <AttachmentsModal
          fileError={fileError}
          files={files}
          filesExist={filesExist}
          onCloseModal={handleCloseModal}
          onFileUploadError={handleFileUploadError}
          onRemoveFile={handleRemoveFile}
          showModal={showModal}
          {...otherProps}
        />
      </ThreadReplyFormWrapper>
    );
  }
}

const formSelector = formValueSelector(formName);
const mapStateToProps = state => ({ files: formSelector(state, "files") });

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: formName, validate }),
)(ThreadReplyForm);
