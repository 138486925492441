import styled from "styled-components";

import PrimaryButton from "common/blocks/PrimaryButton";

const Button = styled(PrimaryButton)`
  &&&& {
    position: absolute;
    top: -4px;
    width: auto;
  }
`;

export default Button;
