import { fetchResourceWithDateRange, listToObject } from "admin/utils/index";

export const REQUEST_TIME_ENTRIES = "REQUEST_TIME_ENTRIES";
export const RECEIVE_TIME_ENTRIES = "RECEIVE_TIME_ENTRIES";

export function requestTimeEntries() {
  return {
    type: REQUEST_TIME_ENTRIES,
  };
}

export function receiveTimeEntries(json) {
  return {
    type: RECEIVE_TIME_ENTRIES,
    receivedAt: Date.now(),
    items: listToObject(json.results, "uuid"),
  };
}

export function fetchTimeEntriesIfNeeded(startDate, endDate) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.admin.invoicing.timeEntries.isFetching) {
      dispatch(requestTimeEntries());
      return fetchResourceWithDateRange(
        startDate,
        endDate,
        "time_entries",
      ).then(json => dispatch(receiveTimeEntries(json)));
    }
    return undefined;
  };
}
