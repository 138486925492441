import { createConnectorCreator } from "common/connectors/utils";

const createCommonConnector = createConnectorCreator("common");

export const withAttorney = createCommonConnector({
  type: "attorney",
  version: "v1",
});
export const withContractorTypes = createCommonConnector({
  type: "contractor_content_types",
  verboseName: "ContractorTypes",
});
export const withDegreeTypes = createCommonConnector({
  type: "degreeTypes",
  path: "degree_choices",
});
export const withFirmTypes = createCommonConnector({
  type: "firm_types",
  verboseName: "FirmTypes",
});
export const withHowHeard = createCommonConnector({
  type: "how_you_heard",
  verboseName: "HowYouHeard",
});
export const withJobDetails = createCommonConnector({
  type: "jobDetails",
  path: null,
});
export const withLanguageOptions = createCommonConnector({
  type: "languages",
  verboseName: "LanguageOptions",
  params: { limit: 999 },
});
export const withMessages = createCommonConnector({
  type: "messages",
  verboseName: "Messages",
  version: "dev",
});
export const withMetadata = createCommonConnector({
  type: "metadata",
  verboseName: "Metadata",
});
export const withPositionTypes = createCommonConnector({
  type: "position_types",
  verboseName: "PositionTypes",
});
export const withRejectionReasons = createCommonConnector({
  type: "rejectionReasons",
  path: "rejection_reasons",
});
export const withUserType = createCommonConnector({
  type: "current_user_type",
  verboseName: "CurrentUserType",
});
