// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Item from "./Item";
import ItemOuter from "./ItemOuter";
import ItemText from "./ItemText";
import ItemGroup from "./ItemGroup";
import Guide from "./Guide";
import GuideBox from "./GuideBox";
import GuideSecondary from "./GuideSecondary";
import ClearAll from "./ClearAll";

const DragDropBlock: ComponentType<{}> = styled.div`
  width: 100%;
`;

const DragDrop = (props: {}) => <DragDropBlock {...props} />;

DragDrop.Item = Item;
DragDrop.ItemOuter = ItemOuter;
DragDrop.ItemText = ItemText;
DragDrop.ItemGroup = ItemGroup;
DragDrop.Guide = Guide;
DragDrop.GuideBox = GuideBox;
DragDrop.GuideSecondary = GuideSecondary;
DragDrop.ClearAll = ClearAll;

export default DragDrop;
