import React, { Component } from "react";
import ReactSelect from "react-select";

import { algolia } from "common/api/algolia";

export default class SchoolSelect extends Component {
  handleChange = newValue => {
    algolia("hae_school_index").getObject(newValue.value, (err, content) => {
      if (content) {
        this.props.onChange({
          school_name: content.name,
          address: content.location.address,
          city: content.location.city,
          state: content.location.state,
          postal_code: content.location.postal_code,
          meta_data_id: content.objectID,
        });
      }
    });
  };

  handleLoadOptions = (input, callback) => {
    algolia("hae_school_index").search(input, (err, content) => {
      const options = input
        ? content.hits.map(obj => ({
            value: obj.objectID,
            label: obj.name,
          }))
        : [];
      callback(null, { options });
    });
  };

  render() {
    return (
      <ReactSelect.Async
        loadOptions={this.handleLoadOptions}
        placeholder={"Search for your school here"}
        cache={false}
        cacheAsyncResults={false}
        onChange={this.handleChange}
        autoload={false}
        filterOption={() => true}
      />
    );
  }
}
