import React from "react";

const centerInPageStyles = {
  height: "6rem",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  textAlign: "center",
};

export const CenteredContent = ({ children }) => (
  <div style={centerInPageStyles}>{children}</div>
);

export { default as ContentWrapper } from "./ContentWrapper";
export { default as Header } from "./Header";
export { default as Footer } from "./Footer";
