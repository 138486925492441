import actions from "hiringagent-dashboard/actions/messages";

export default {
  [actions.RECEIVE_TOGGLE_MESSAGE_READ_STATUS]: (state, action) => ({
    ...state,
    [action.payload.threadId]: {
      ...state[action.payload.threadId],
      unread: !state[action.payload.threadId].unread,
    },
  }),
};
