import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Alert, Col, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import FormButton from "common/components/FormButton";
import {
  EmailAddressInput,
  PhoneNumberInput,
  TextField,
} from "common/components/forms/inputs";
import Onboard from "contractor/components/Onboarding/blocks";
import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { updateOwnContractorQuery } from "queries/mutations/contractors";
import { resendPendingEmailConfirmationQuery } from "queries/mutations/email";
import { ownContractorQuery } from "queries/requests/contractors";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";
import Loading from "common/components/Loading";

export const formName = "personalInfoForm";
export const requiredFields = ["first_name", "last_name", "email_address"];

class PersonalInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailChangeCancelled: false,
      resending: false,
      resent: false,
      resendError: false,
    };
  }

  handleSubmitPersonalInfoForm = values => {
    const {
      change,
      handleSubmit,
      ownContractor: { data: ownContractorData },
      refreshOwnContractor,
    } = this.props;
    const ownContractor = Object.values(ownContractorData)[0];
    handleSubmit(values).then(() => {
      change("email_address", ownContractor.email_address);
      refreshOwnContractor();
    });
    this.setState({
      emailChangeCancelled: false,
      resending: false,
      resent: false,
      resendError: false,
    });
  };

  handleCancelEmailChange = async () => {
    const {
      props: {
        ownContractor: { data: ownContractorData },
        updateOwnContractor,
        refreshOwnContractor,
      },
    } = this;
    const ownContractor = Object.values(ownContractorData)[0];

    await updateOwnContractor(
      { pending_email_address: null },
      ownContractor.uuid,
    );

    this.setState({
      emailChangeCancelled: true,
      resent: false,
      resendError: false,
    });

    refreshOwnContractor();
  };

  handleResendConfirmationEmail = () => {
    const { refreshOwnContractor, resendPendingEmailConfirmation } = this.props;

    this.setState({ resending: true }, () => {
      resendPendingEmailConfirmation().then(({ status }) => {
        if (status >= 200 && status < 300) {
          this.setState({
            emailChangeCancelled: false,
            resending: false,
            resent: true,
            resendError: false,
          });
        } else {
          this.setState({
            emailChangeCancelled: false,
            resending: false,
            resent: false,
            resendError: true,
          });
        }
        refreshOwnContractor();
      });
    });
  };

  render() {
    const {
      state: { emailChangeCancelled, resent },
      props: {
        ownContractor: { data: ownContractorData, isFinished, isPending },
        pristine,
        submitting,
        valid,
      },
      handleCancelEmailChange,
      handleResendConfirmationEmail,
      handleSubmitPersonalInfoForm,
    } = this;
    const contractor = Object.values(ownContractorData)[0];
    const isReady = contractor && isFinished && !isPending;

    return isReady ? (
      <form onSubmit={values => handleSubmitPersonalInfoForm(values)}>
        {contractor.pending_email_address && (
          <Alert style={{ wordWrap: "break-word" }}>
            {resent ? "Resent. " : `You have a pending email address change. `}
            {`Check for a confirmation email at: ${
              contractor.pending_email_address
            }`}
            <Onboard.EntryDataRow>
              <Col xs={12}>
                <Onboard.TableButton
                  bsStyle="primary"
                  margin="0 5px 0 0"
                  onClick={handleResendConfirmationEmail}
                >
                  Resend Confirmation Email
                </Onboard.TableButton>
                <Onboard.TableButton
                  bsStyle="danger"
                  onClick={handleCancelEmailChange}
                >
                  Cancel Email Change
                </Onboard.TableButton>
              </Col>
            </Onboard.EntryDataRow>
          </Alert>
        )}
        {emailChangeCancelled && (
          <Alert style={{ wordWrap: "break-word" }}>
            {`You cancelled the email address change. Your email address is still: ${
              contractor.email_address
            }`}
          </Alert>
        )}
        <Row>
          <Col sm={6}>
            <TextField name="first_name" label="First Name" />
          </Col>
          <Col sm={6}>
            <TextField name="last_name" label="Last Name" />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <EmailAddressInput
              name="email_address"
              disabled={Boolean(contractor.pending_email_address)}
            />
          </Col>
          <Col sm={6}>
            <PhoneNumberInput name="phone_home" />
          </Col>
        </Row>
        <div className="text-right">
          <FormButton
            className="text-right"
            action="save"
            submitting={submitting}
            disabled={pristine || submitting || !valid}
          />
        </div>
      </form>
    ) : (
      <Loading />
    );
  }
}

PersonalInfoForm = reduxForm({
  form: formName,
})(PersonalInfoForm);

const ownContractorSelectorConfig = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);

const mapPropsToConfig = () => [ownContractorQuery({ force: true })];

const mapStateToProps = createStructuredSelector({
  ...ownContractorSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  updateOwnContractor: (data, uuid) =>
    dispatch(mutateAsync(updateOwnContractorQuery(data, uuid))),
  refreshOwnContractor: () =>
    dispatch(requestAsync(ownContractorQuery({ force: true }))),
  resendPendingEmailConfirmation: () =>
    dispatch(mutateAsync(resendPendingEmailConfirmationQuery())),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(PersonalInfoForm);
