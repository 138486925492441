import React from "react";
import DatePicker from "react-datepicker";
import { ControlLabel, FormGroup, InputGroup } from "react-bootstrap";
import moment from "moment";

import FieldHelpBlock from "./FieldHelpBlock";
import { validationState } from "common/utils/forms";

const handleChange = onChange => value => {
  let transformedValue = value;
  if (!moment.isMoment(transformedValue)) {
    transformedValue = moment(transformedValue);
  }
  if (moment.isMoment(transformedValue)) {
    transformedValue = transformedValue.format("YYYY-MM-DD");
  }
  return onChange(transformedValue);
};

export default ({ input, meta, label, bsSize, ...otherProps }) => {
  let value = input.value || null;
  if (value) {
    value = moment(value, "YYYY-MM-DD");
    if (value.isValid()) {
      value = value.toDate();
    } else {
      value = null;
    }
  }
  return (
    <FormGroup
      validationState={validationState(meta)}
      bsSize={bsSize}
      className="form-control-react-datepicker"
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <InputGroup>
        <DatePicker
          {...input}
          onChange={handleChange(input.onChange)}
          className="form-control"
          selected={value}
          autoComplete="off"
          placeholderText="YYYY-MM-DD"
          dateFormat="yyyy-MM-dd"
          {...otherProps}
        />
        <InputGroup.Addon>
          <i className="fas fa-calendar-alt" />
        </InputGroup.Addon>
      </InputGroup>
      <FieldHelpBlock touched={meta.touched} error={meta.error} />
    </FormGroup>
  );
};
