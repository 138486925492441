import React from "react";

import { SelectField } from "common/components/forms/inputs";
import { languageProficiencies } from "common/constants";
import stateOptions from "hiringagent-dashboard/constants/stateOptions";
import { withResource } from "resources/components/utils";
import { languagesRequest } from "resources/requests";

const proficiencySelectOptions = languageProficiencies.map(proficiency => ({
  label: proficiency[0],
  value: proficiency[1],
}));

const LanguageRequirementProficiencySelect = props => (
  <SelectField placeholder="Select proficiency" {...props}>
    {proficiencySelectOptions.map(({ label, value }) => (
      <option key={`language-option-${label}`} value={value}>
        {`${label} (${value})`}
      </option>
    ))}
  </SelectField>
);

let LanguageRequirementSelect = props => {
  const {
    languages: {
      metadata: { ready },
      data,
    },
    name,
  } = props;
  return (
    <SelectField placeholder="Select language" name={name}>
      {ready ? (
        Object.values(data).map(({ uuid, name }) => (
          <option key={`language-option-${uuid}`} value={uuid}>
            {name}
          </option>
        ))
      ) : (
        <option>Loading...</option>
      )}
    </SelectField>
  );
};
LanguageRequirementSelect = withResource(languagesRequest)(
  LanguageRequirementSelect,
);

const BarRequirementSelect = props => (
  <SelectField placeholder="Select state" {...props}>
    {stateOptions.map(state => (
      <option key={`state-option-${state.value}`} value={state.value}>
        {`${state.label} (${state.value})`}
      </option>
    ))}
  </SelectField>
);

export {
  BarRequirementSelect,
  LanguageRequirementSelect,
  LanguageRequirementProficiencySelect,
};
