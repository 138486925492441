import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import querystring from "querystring";

import { handleSubmissionError } from "common/utils/forms";
import Loading from "hiringagent-dashboard/components/utils/Loading";
import {
  logSurveyNotAvailable,
  requestDeclinedReasonChoices,
  requestReviewRequest,
  submitDeclineReviewRequest,
} from "public/actions/reviews";
import ResponsiveContainer from "common/components/ResponsiveContainer";
import NotFound from "public/components/NotFound";
import { SurveyNotAvailable } from "public/containers/Review";
import DeclineReviewRequestForm from "public/forms/DeclineReviewRequestForm";

class DeclineReviewRequest extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      location: { search },
      match: {
        params: { id },
      },
    } = this.props;
    const query = querystring.parse(search.substring(1));
    this.props.onLoad(id, query.auth_token);
  }

  handleSubmit(values, dispatch) {
    const {
      location: { search },
      match: {
        params: { id },
      },
    } = this.props;
    const query = querystring.parse(search.substring(1));

    return dispatch(
      submitDeclineReviewRequest(id, values, query.auth_token),
    ).then(handleSubmissionError);
  }

  render() {
    const {
      isLoading,
      lastUpdated,
      declinedReasonChoices,
      reviewRequestDeclined,
      reviewRequest,
      reviewRequest: {
        attorney_first_name,
        attorney_last_name,
        reviewer_first_name,
      },
      logSurveyNotAvailable,
      location,
      match: {
        params: { id },
      },
    } = this.props;
    const query = querystring.parse(location.search.substring(1));
    const { auth_token } = query;

    const linkToSurvey = (
      <Link
        to={{
          ...location,
          pathname: `/attorney/profile/review/complete/${id}`,
        }}
      >
        Click here
      </Link>
    );

    const declineReviewRequestForm = reviewRequestDeclined ? (
      <ResponsiveContainer>
        <h1>Reference Declined</h1>
        <p>
          Thanks, we&apos;ve received your submission. We&apos;ll be sure to
          respectfully let {attorney_first_name} know of your decision.
        </p>
        <p>
          If you&apos;re interested in learning more about the Hire an Esquire
          network,{" "}
          <a href="https://hireanesquire.com/firms-and-hiring-managers/">
            click here
          </a>
          .
        </p>
        <p>Otherwise, feel free to close this page. Thanks again!</p>
      </ResponsiveContainer>
    ) : (
      <div>
        <ResponsiveContainer>
          <h1>Decline Reference Request</h1>
          <div className="review-description">
            <p>
              Hi{reviewer_first_name && ` ${reviewer_first_name}`}. You have
              been selected to be a reference for{" "}
              <strong>
                {attorney_first_name} {attorney_last_name}
              </strong>
              , but you have elected to decline this reference request. If you
              would be so kind as to tell us why you are declining to be{" "}
              {attorney_first_name}&apos;s reference, we would greatly
              appreciate it.
            </p>
            <p className="text-muted">
              End up here by mistake? {linkToSurvey} to complete the reference
              survey.
            </p>
          </div>
        </ResponsiveContainer>
        <Row>
          <Col sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4}>
            {reviewRequest && (
              <DeclineReviewRequestForm
                declinedReasonChoices={declinedReasonChoices}
                attorneyFirstName={attorney_first_name}
                onSubmit={this.handleSubmit}
              />
            )}
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="review-form-public">
        {isLoading ? (
          <Loading style={{ margin: "2rem 0" }} />
        ) : lastUpdated && reviewRequest.status ? (
          reviewRequest.status === "pending" ? (
            declineReviewRequestForm
          ) : (
            <SurveyNotAvailable
              status={reviewRequest.status}
              completed={reviewRequest.review_completed}
              onLoad={() => logSurveyNotAvailable(id, auth_token)}
            />
          )
        ) : (
          <NotFound />
        )}
      </div>
    );
  }
}

export default connect(
  state => state.anonymous.reviews,
  dispatch => ({
    onLoad: (reviewRequestId, authTokenId) => {
      dispatch(requestDeclinedReasonChoices(authTokenId));
      dispatch(requestReviewRequest(reviewRequestId, authTokenId));
    },
    logSurveyNotAvailable: (reviewRequestId, authTokenId) => {
      dispatch(logSurveyNotAvailable(reviewRequestId, authTokenId));
    },
  }),
)(DeclineReviewRequest);
