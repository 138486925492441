import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import ExpertiseAreasForm, {
  formName,
  requiredFields,
} from "contractor/forms/ExpertiseAreasForm";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import { validateRequiredFields } from "common/utils/forms";
import { submitContractorProfile } from "contractor/actions/profile";

class EditExpertiseAreas extends Component {
  onSubmit = values => {
    const {
      contractor: { uuid },
      notify,
    } = this.props;
    const formattedValues = {
      practice_areas: values.practice_areas.map(uuid => ({ tag: uuid })),
    };
    return this.props
      .submitContractorProfile(uuid, formattedValues)
      .then(({ json, response }) => {
        if (!response.ok) {
          notify(
            createErrorNotification({
              message: json.detail || "Please correct the errors below.",
            }),
          );
          throw new SubmissionError(json);
        }
        notify(
          createSuccessNotification({
            message: "Areas of expertise have been saved.",
          }),
        );
      });
  };

  validate = values => {
    const errors = validateRequiredFields(requiredFields, values);
    const { practice_areas } = values;
    if (practice_areas && practice_areas.length > 3) {
      errors.practice_areas =
        "You cannot select more than 3 areas of expertise.";
    }
    return errors;
  };

  render() {
    const { contractor, handleSubmit, pristine, submitting } = this.props;
    const initialValues = {
      practice_areas: contractor.practice_areas.map(pa => pa.tag),
    };
    return (
      <div>
        <h2 className="edit-section-title">Areas of Expertise</h2>
        <div>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <ExpertiseAreasForm
              initialValues={initialValues}
              validate={this.validate}
            />
            <div className="text-right">
              <Button
                type="submit"
                bsStyle="primary"
                disabled={pristine || submitting}
              >
                {submitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      submitContractorProfile,
      notify,
    },
  ),
  reduxForm({ form: formName }),
)(EditExpertiseAreas);
