import React, { Component } from "react";
import { getQueryKey } from "redux-query";
import { createStructuredSelector } from "reselect";

import Onboard from "../../blocks";
import { MobileEntries, DesktopEntries } from "./Entries";
import FormModal from "./FormModal";
import NextButton from "../../NextButton";
import Loading from "common/components/Loading";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import {
  getActionIsRequired,
  getCustomErrorMsg,
  getCanMoveOnToNextStep,
  getVerifIsPending,
  redirectToNextStepIfNeeded,
} from "../../utils";
import { getResourcesAreReady } from "common/utils/helpers";
import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { createResourceSelectorConfig } from "queries";
import { degreeChoicesQuery } from "queries/requests/schools";
import { schoolsQuery } from "queries/requests/schools";
import { deleteSchoolQuery } from "queries/mutations/schools";
import types from "resources/types";

const stepName = "education";
const requiredResources = [types.DEGREE_CHOICES, types.SCHOOLS];

class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingSchool: null,
      justUploadedIds: [],
      nextButtonLoading: false,
      showFormModal: false,
    };
  }

  handleEditClick = editingSchool =>
    this.setState({ showFormModal: true, editingSchool });

  handleFooterClick = () =>
    this.setState({ showFormModal: true, editingSchool: null });

  handleHide = () =>
    this.setState({ showFormModal: false, editingSchool: null });

  handleDeleteClick = uuid => {
    const { deleteSchool, refreshReqs, refreshSchools } = this.props;
    deleteSchool(uuid).then(() => {
      refreshSchools();
      refreshReqs();
    });
  };

  getSchools = schoolsData => {
    const {
      [getQueryKey(schoolsQuery())]: schoolsQueries,
    } = this.props.schools.queries;
    let schools = Object.values(schoolsData);
    if (schoolsQueries) {
      schools = schools.filter(school =>
        schoolsQueries.data.includes(school.uuid),
      );
    }
    return schools;
  };

  addJustUploadedId = uuid =>
    this.setState(state => ({
      justUploadedIds: state.justUploadedIds.concat(uuid),
    }));

  handleNextClick = () => {
    this.setState({ nextButtonLoading: true }, () => {
      redirectToNextStepIfNeeded(
        stepName,
        this.props.refreshReqs,
        this.stopButtonLoading,
      );
    });
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: {
        editingSchool,
        justUploadedIds,
        nextButtonLoading,
        showFormModal,
      },
      props: {
        degreeChoices: { data: degreeChoicesData },
        ownContractor,
        schools: { data: schoolsData },
        refreshSchools,
        refreshReqs,
        reqs: { steps },
      },
      addJustUploadedId,
      getSchools,
      handleDeleteClick,
      handleEditClick,
      handleFooterClick,
      handleNextClick,
      handleHide,
    } = this;
    const isReady = getResourcesAreReady(requiredResources, this.props);
    const degreeChoices = degreeChoicesData[Object.keys(degreeChoicesData)[0]];
    const schools = getSchools(schoolsData);
    const entriesProps = {
      actionIsRequired: getActionIsRequired(stepName, steps),
      addJustUploadedId,
      customErrorMsg: getCustomErrorMsg(stepName, steps),
      isAttorney: ownContractor.is_attorney,
      justUploadedIds,
      onDeleteClick: handleDeleteClick,
      onEditClick: handleEditClick,
      onFooterClick: handleFooterClick,
      ownContractor,
      refreshSchools,
      schools,
      verifIsPending: getVerifIsPending(stepName, steps),
    };

    return isReady ? (
      <Onboard.Page>
        <DesktopOnly>
          <DesktopEntries {...entriesProps} />
        </DesktopOnly>
        <MobileOnly>
          <MobileEntries {...entriesProps} />
        </MobileOnly>
        <NextButton
          disabled={!getCanMoveOnToNextStep(stepName, steps)}
          float="right"
          isLoading={nextButtonLoading}
          onClick={handleNextClick}
        />
        <FormModal
          degreeChoices={degreeChoices}
          editingSchool={editingSchool}
          show={showFormModal}
          onHide={handleHide}
          ownContractor={ownContractor}
          refreshReqs={refreshReqs}
          refreshSchools={refreshSchools}
        />
      </Onboard.Page>
    ) : (
      <Loading />
    );
  }
}

const mapPropsToConfig = () => [degreeChoicesQuery(), schoolsQuery()];

const degreeChoicesSelectorConfig = createResourceSelectorConfig(
  types.DEGREE_CHOICES,
  degreeChoicesQuery,
);

const schoolsSelectorConfig = createResourceSelectorConfig(
  types.SCHOOLS,
  schoolsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...degreeChoicesSelectorConfig,
  ...schoolsSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  deleteSchool: uuid => dispatch(mutateAsync(deleteSchoolQuery(uuid))),
  refreshSchools: () => dispatch(requestAsync(schoolsQuery({ force: true }))),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Education);
