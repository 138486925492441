import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { StaffRequired } from "admin/components/auth";
import ContractorAuditsIndex from "admin/containers/contractor-audits/Index";
import { AttorneyVerificationManager } from "admin/components/attorney-verification-manager";
import ApplicationsIndex from "admin/components/applications/Index";
import ApplicationsStats from "admin/containers/applications/ApplicationsStats";
import Base from "admin/containers/Base";
import Index from "admin/containers/Index";
import Invoicing from "admin/containers/accounting/Invoicing";
import PreviousEmploymentTagManager from "admin/components/previous-employment-tag-manager/Index";
import RecommendationStatsIndex from "admin/containers/recommendation-stats/Index";
import SearchHistoryIndex from "admin/containers/search-history/Index";
import LanguageAuditIndex from "admin/containers/language-audit/Index";
import NonAttorneyVerificationManager from "admin/components/non-attorney-verification-manager/VerificationManager";

const Applications = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={ApplicationsIndex} />
    <Route path={`${match.url}/stats`} component={ApplicationsStats} />
  </Switch>
);

const Recommendations = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/stats`} component={RecommendationStatsIndex} />
  </Switch>
);

const VerificationManager = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/attorneys`}
      component={AttorneyVerificationManager}
    />
    <Route
      path={`${match.url}/non-attorneys`}
      component={NonAttorneyVerificationManager}
    />
  </Switch>
);

const Dashboards = ({ match }) => (
  <StaffRequired>
    <Base>
      <Switch>
        {/* Index can either have `exact` or be listed at the bottom of <Switch> */}
        <Route exact path={match.url} component={Index} />
        <Redirect
          exact
          path={`${match.url}/accounting`}
          to={`${match.url}/accounting/invoicing`}
        />
        <Route
          path={`${match.url}/accounting/invoicing`}
          component={Invoicing}
        />
        <Route path={`${match.url}/applications`} component={Applications} />
        <Redirect
          exact
          path={`${match.url}/recommendationstats`}
          to={`${match.url}/recommendations/stats`}
        />
        <Route
          path={`${match.url}/recommendations`}
          component={Recommendations}
        />
        <Route
          path={`${match.url}/contractor-audits`}
          component={ContractorAuditsIndex}
        />
        <Route
          path={`${match.url}/search-history`}
          component={SearchHistoryIndex}
        />
        <Route
          path={`${match.url}/language-audit`}
          component={LanguageAuditIndex}
        />
        <Route
          path={`${match.url}/previous-employment-tag-manager`}
          component={PreviousEmploymentTagManager}
        />
      </Switch>
    </Base>
  </StaffRequired>
);

export default ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/verification-manager`}
      component={VerificationManager}
    />
    <Route path={match.url} component={Dashboards} />
  </Switch>
);
