import React, { Component } from "react";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import ContactInformationForm from "./ContactInformationForm";
import { RemoteSubmitButton } from "../../NextButton";
import { getActionIsRequired, getCustomErrorMsg } from "../../utils";
import { compose, connect, mutateAsync } from "queries/utils";
import { updateOwnContractorQuery } from "queries/mutations/contractors";
import { handleSubmitError, redirectToNextStepIfNeeded } from "../../utils";
import { validateRequiredFields } from "common/utils/forms";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import Label from "common/blocks/Label";
import { EmailChangeCopy } from "./copy";

const stepName = "contact_information";
const requiredFields = ["first_name", "last_name", "pending_email_address"];

class ContactInformation extends Component {
  constructor(props) {
    super(props);
    this.state = { justSubmitted: false, nextButtonLoading: false };
  }

  handleSubmitContactInformationForm = async values => {
    await this.setState({ nextButtonLoading: true });

    const {
      props: {
        ownContractor: { uuid, pending_email_address },
        updateOwnContractor,
      },
      handleNextClick,
      stopButtonLoading,
    } = this;
    let submitValues = Object.assign({}, values);

    if (pending_email_address) {
      submitValues.pending_email_address = pending_email_address;
    }

    const result = await updateOwnContractor(submitValues, uuid);

    this.setState({ justSubmitted: true }, () => {
      stopButtonLoading();
      handleSubmitError(result);
      handleNextClick();
    });
  };

  validate = values => validateRequiredFields(requiredFields, values);

  handleNextClick = () => {
    redirectToNextStepIfNeeded(
      stepName,
      this.props.refreshReqs,
      this.stopButtonLoading,
    );
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: { justSubmitted, nextButtonLoading },
      props: {
        ownContractor: {
          first_name,
          last_name,
          email_address,
          pending_email_address,
          phone_home,
        },
        reqs: { steps },
      },
      handleSubmitContactInformationForm,
      validate,
    } = this;
    const actionIsRequired = getActionIsRequired(stepName, steps);
    const initialValues = {
      first_name,
      last_name,
      pending_email_address: email_address,
      phone_home,
    };
    const customErrorMsg = getCustomErrorMsg(stepName, steps);

    return (
      <Onboard.Page>
        <Onboard.Container>
          <DesktopOnly>
            <Onboard.ContainerHeader>
              <div>
                <h1>Contact Information</h1>
              </div>
              <div>
                {actionIsRequired && (
                  <Label color="danger">ACTION REQUIRED</Label>
                )}
              </div>
            </Onboard.ContainerHeader>
          </DesktopOnly>
          <MobileOnly>
            <h1>Contact Information</h1>
            {actionIsRequired && (
              <div>
                <Label color="danger">ACTION REQUIRED</Label>
                <br />
                <br />
              </div>
            )}
          </MobileOnly>
          {!justSubmitted &&
            pending_email_address && (
              <Alert bsStyle="warning">
                <EmailChangeCopy />
              </Alert>
            )}
          {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
          <ContactInformationForm
            initialValues={initialValues}
            onSubmit={handleSubmitContactInformationForm}
            pending_email_address={pending_email_address}
            validate={validate}
          />
        </Onboard.Container>
        <RemoteSubmitButton
          formName="onboardingContactInformationForm"
          isLoading={nextButtonLoading}
        />
      </Onboard.Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateOwnContractor: (data, uuid) =>
    dispatch(mutateAsync(updateOwnContractorQuery(data, uuid))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(ContactInformation);
