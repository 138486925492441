import React from "react";
import { Label } from "react-bootstrap";

const LabelListItem = ({ labelText, ...otherProps }) => (
  <span {...otherProps}>
    <Label>{labelText}</Label>{" "}
  </span>
);

export default LabelListItem;
