import React, { Component } from "react";

import Score from "./blocks";
import Alternate from "./Alternate";

export default class FitScore extends Component {
  constructor(props) {
    super(props);
    this.state = { radius: 100, stroke: 10 };
  }

  componentDidMount() {
    this.resizeCircle();
    window.addEventListener("resize", this.resizeCircle);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCircle);
  }

  resizeCircle = () => {
    if (window.innerWidth <= 991) {
      this.setState({ radius: 70, stroke: 7 });
    } else {
      this.setState({ radius: 90, stroke: 9 });
    }
  };

  render() {
    const { candidate } = this.props;
    const score =
      candidate && candidate.overall_fitscore
        ? candidate.overall_fitscore
        : null;

    if (score === null) return <Alternate />;

    const { radius, stroke } = this.state;
    const normalizedRadius = radius - stroke * 2;
    const circumf = normalizedRadius * 2 * Math.PI;
    const strokeDasharray = circumf + " " + circumf;
    const roundedScore = Math.round(score);
    const strokeDashoffset = circumf - (roundedScore / 100) * circumf;

    return (
      <Score>
        <svg height={2 * radius} width={2 * radius}>
          <Score.Circle
            strokeWidth={stroke}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <Score.Text x="50%" y="30%">
            FIT
          </Score.Text>
          <Score.Text x="50%" y="40%">
            SCORE
          </Score.Text>
          <Score.PercentText x="50%" y="65%">
            {`${roundedScore}%`}
          </Score.PercentText>
        </svg>
        <Score.Bottom>
          <p>
            The Fit Score is the cumulative score of the candidate&apos;s
            results on our platform assessment of good fit to a specific job
            posting.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hireanesquire.com/fit-score-faq"
          >
            <Score.Footer>MORE ABOUT FIT SCORE</Score.Footer>
          </a>
        </Score.Bottom>
      </Score>
    );
  }
}
