import React from "react";
import styled, { css } from "styled-components";
import { useRequest } from "redux-query-react";
import { useSelector } from "react-redux";

import { Loading } from "common/components";
import { talentPoolsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";
import { SelectInput } from "common/components/forms";

const TalentPool = styled.ul.attrs({ className: "list-unstyled" })`
  font-size: 2rem;
`;
const TalentPoolItem = styled.li`
  cursor: pointer;
  margin: 5px 0;
  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      color: ${theme.colors.primary_turquoise()};
      font-weight: bold;
    `};
  :hover {
    font-weight: bold;
  }
`;

const TalentPoolSelect = props => {
  const { onChange, value } = props;
  const request = talentPoolsRequest({
    url: { params: { limit: 99 } },
  });
  const selector = createResourceSelector(types.TALENT_POOLS, request);
  const {
    [types.TALENT_POOLS]: { data },
  } = useSelector(selector);
  const [{ isFinished }, _] = useRequest(request);

  const options = isFinished
    ? Object.keys(data).map(id => (
        <TalentPoolItem
          key={id}
          isSelected={value === id}
          onClick={() => onChange(id)}
        >
          {data[id].name}
        </TalentPoolItem>
      ))
    : [
        <TalentPoolItem key="loading">
          <Loading />
        </TalentPoolItem>,
      ];
  const selectProps = isFinished ? {} : { placeholder: "Loading..." };
  return (
    <div>
      <div className="visible-xs">
        <SelectInput
          input={{
            value: value || "",
            onChange: e => onChange(e.target.value),
          }}
          includeEmptyOption={false}
          meta={{}}
          {...selectProps}
        >
          <option value="">All Talent Pools</option>
          {Object.keys(data).map(id => (
            <option key={id} value={id}>
              {data[id].name}
            </option>
          ))}
        </SelectInput>
      </div>
      <div className="hidden-xs">
        <TalentPool>
          <TalentPoolItem onClick={() => onChange(null)} isSelected={!value}>
            All Talent Pools
          </TalentPoolItem>
          {options}
        </TalentPool>
      </div>
    </div>
  );
};

export default TalentPoolSelect;
