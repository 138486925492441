import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

import StyledText from "common/blocks/StyledText";

export const EntryDataLabel = ({ children }) => (
  <Col xs={6}>
    <StyledText textType="black" bold>
      {children}
    </StyledText>
  </Col>
);

const EntryDataRow = styled(Row)`
  &&&& {
    margin-top: 10px;
    margin-bottom: 10px;

    * {
      margin-left: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
    }

    p {
      display: auto;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export default EntryDataRow;
