// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  percentage: ?number,
};

const BarInner: ComponentType<Props> = styled.div`
  width: 10px;
  height: 100%;
  margin: 0;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.light_orange()};
  border-radius: 34px;
  ${({ percentile, theme }) =>
    percentile &&
    css`
      width: ${percentile}%;
      background-color: ${theme.colors.light_orange()};
    `};
`;

export default BarInner;
