import React from "react";

const TermsOfServiceACH = () => (
  <div>
    <p>
      <strong>Bank Authorization</strong>
    </p>
    <p>
      Client authorizes Hire an Esquire, Inc. (HAE) or its designee successor or
      assign (hereinafter “Vendor”) to withdraw any amounts including any and
      all applicable taxes now due or hereinafter imposed owed by Client under
      the Terms of Service and this and any other agreements the customer has
      with Hire an Esquire, Inc. or agreements with legal professionals
      registered with HAE through HAE (“Agreements”). Client authorizes HAE to
      initiate debit entries to Client’s account to fulfill payment obligations
      at the financial institution (hereinafter “Bank”) indicated in this
      agreement or at any such other Bank as Client may from time to time use.
      If Client defaults on these obligations, Client authorizes debits from
      Client’s account or credit card for the full amount due under this
      agreement. Further, Client authorizes Bank to accept and to charge any
      debit entries initiated by Vendor to Client’s account. In the event that
      Vendor withdraws erroneously from Client’s account, Client authorizes
      Vendor to credit Client’s account for the amount erroneously withdrawn.
    </p>
    <p>
      Client understands that the foregoing ACH authorization is a fundamental
      condition to induce Vendor to accept this Agreement. Consequently, such
      authorization is intended to be irrevocable and if cancelled, Client
      authorizes Bank to pay a single and final ACH debit to Vendor equal to any
      balance due on Agreement. In the event that Vendor is unable to collect
      any ACH debit to Client, in its sole discretion, may either deem such an
      event as default in accordance with the Agreement or may invoice Customer
      for payments due under this Agreement and include a $5.00 processing fee
      in such invoices. Additionally invoices not paid within 30 days of invoice
      date will be subject to a 5% per month finance charge beginning
      immediately after invoice date.
    </p>

    <p>
      <strong>Escrow Clause</strong>
    </p>
    <p>
      In accordance with the above, Client agrees that an estimate of the weekly
      amount to be paid to legal professionals and Hire an Esquire’s transaction
      fee will be debited by HAE and placed in an Escrow Account at PNC Bank no
      more than 4 days prior to each week of the project unless Client and HAE
      have an alternative or additional agreement that states otherwise.
      Estimate will be based upon the hourly rate and number of hours Client
      lists in project posting. If Client believes that hours will exceed the
      listed hours at any point in the project, Client agrees to inform Hire an
      Esquire so that HAE may escrow additional funds to ensure that there are
      sufficient funds available to pay legal professional hours and the
      applicable transaction fee. Client also agrees and authorizes HAE to debit
      additional amounts if legal professional hours and transaction fee exceed
      escrowed amounts. Escrowed money will be paid to consultants and HAE in
      accordance with the following paragraph:
    </p>

    <p>
      <strong>
        Timely Approval or Contest of Legal Professional Invoices:
      </strong>
    </p>
    <p>
      Client agrees to approve or contest legal professional invoices submitted
      for their projects. Client agrees that HAE is a 3rd party beneficiary of
      the contract between Client and Legal Professional for services rendered
      and that the integrity o fthe HAE web service depends upon prompt and
      good-faith payment of legal professionals. Therefore, Client agrees to
      approve or contest legal professional invoices within 72 hours or the
      invoiced amount will be automatically paid to the legal professionals.
      Further, if client contests an legal professional invoice, Client agrees
      to respond promptly and within 48 hours to HAE’s requests for further
      information explaining why they believe an legal professional invoice
      should not be approved. Failure to respond within 48 hours to information
      requests regarding legal professional invoices may also result in
      automatic payment to legal professionals. Further, Client agrees to have
      funds available to HAE and legal professionals so that payment may be
      processed to HAE and legal professionals within 72 hours of invoice
      submission. Failure to do so will result in finance charges, late fees and
      any damage to HAE’s reputation that results from clients not paying legal
      professionals contracted through HAE in accordance with the terms of
      agreements Client has with HAE and legal professionals.
    </p>

    <p>
      <strong>Guarantee</strong>
    </p>
    <p>
      To induce Vendor to enter into this Agreement for Customer knowing that
      Vendor is relying on this Guaranty as a precondition to making this
      Agreement,{" "}
      <strong>
        I INDIVIDUALLY, PERSONALLY, ABSOLUTELY AND UNCONDITIONALLY GUARANTY
      </strong>{" "}
      to Vendor (and any person or firm Vendor may transfer its interests to)
      all payments and other obligations owed by Client to Vendor under this
      Agreement and any add-on Services, Equipment Schedules and future
      Agreements between Vendor and Client, including, but not limited to,
      Vendor’s legal professional’s fees and legal costs incurred in enforcing
      this Agreement. I will also pay all reasonable costs and fees incurred by
      Vendor in enforcing this Guaranty. I waive notice of demand and notice of
      default and I agree that Vendor may proceed directly against me without
      first proceeding against Client or the security (including the Equipment).
      This Guaranty shall be governed by the laws of New York for amounts up to
      the limits of Small Claims court jurisdiction in New York, NY. For any
      amount above what can be settled in small claims court in New York, NY I
      consent to binding arbitration in accordance with the terms and guidelines
      of the American Arbitration Association. I waive my right to trial by
      jury. I consent to personal jurisdiction in New York and the city courts
      of New York, NY. I agree that arbitration or a small claims court suit
      will occur in New York, NY.
    </p>
  </div>
);
export default TermsOfServiceACH;
