import styled, { css } from "styled-components";

import GenericBar from "common/blocks/Bar";

const Bar = styled(GenericBar)`
  ${({ percentage, theme }) => theme.media.mobile`
    background-color: ${theme.colors.background_lightgray()};
    border-color: ${theme.colors.primary_black40()};
    ${percentage &&
      css`
        background-color: ${theme.colors.background_gray()};
      `};
  `};
`;

export default Bar;
