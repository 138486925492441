import React, { Component } from "react";
import { MenuItem, Nav, NavDropdown, NavItem, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { createApiUrl } from "utils";

const NavbarLink = ({ to, eventKey, children, ...props }) => (
  <LinkContainer to={to} {...props}>
    <MenuItem eventKey={eventKey}>{children}</MenuItem>
  </LinkContainer>
);

export default class Navigation extends Component {
  render() {
    return (
      <Navbar fluid staticTop>
        <Navbar.Header>
          <Navbar.Text>
            <strong>
              <Link to="/hae_admin/dashboards/">HaE Admin</Link>
            </strong>
          </Navbar.Text>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <NavDropdown
              eventKey={1}
              title="Dashboards"
              id="admin-dashboards-dropdown"
            >
              <NavbarLink eventKey={1.01} to="/hae_admin/dashboards/accounting">
                Accounting
              </NavbarLink>
              <NavbarLink
                eventKey={1.02}
                to="/hae_admin/dashboards/applications"
              >
                Applications
              </NavbarLink>
              <NavbarLink
                eventKey={1.03}
                to="/hae_admin/dashboards/contractor-audits"
              >
                Contractor Audits
              </NavbarLink>
              <NavbarLink
                eventKey={1.04}
                to="/hae_admin/dashboards/language-audit"
              >
                Language Audits
              </NavbarLink>
              <NavbarLink
                eventKey={1.05}
                to="/hae_admin/dashboards/previous-employment-tag-manager"
              >
                Previous Employment Tag Manager
              </NavbarLink>
              <NavbarLink
                eventKey={1.06}
                to="/hae_admin/dashboards/recommendationstats"
              >
                Recommendation Stats
              </NavbarLink>
              <NavbarLink
                eventKey={1.07}
                to="/hae_admin/dashboards/recommendations"
              >
                Recommendation
              </NavbarLink>
              <NavbarLink
                eventKey={1.08}
                to="/hae_admin/dashboards/search-history"
              >
                Search History
              </NavbarLink>
              <NavbarLink
                eventKey={1.09}
                to="/hae_admin/dashboards/verification-manager/attorneys"
              >
                Verify Attorneys
              </NavbarLink>
              <NavbarLink
                eventKey={1.1}
                to="/hae_admin/dashboards/verification-manager/non-attorneys"
              >
                Verify Non-Attorneys
              </NavbarLink>
            </NavDropdown>
          </Nav>
          <Nav pullRight>
            <NavItem eventKey={2} href={createApiUrl("/hae_admin")}>
              Go to Admin <i className="fas fa-external-link-alt" />
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
