import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";

import { handleSubmissionError } from "common/utils/forms";
import { requestRecommendationsStats } from "admin/actions/recommendationStats";
import DateRangeForm from "admin/forms/DateRangeForm";
import Loading from "common/components/Loading";

const DownArrow = () => (
  <i className="far fa-arrow-down fa-4x" style={{ margin: "1rem 0" }} />
);

const InfoToolTip = ({ title, content }) => {
  const overlay = (
    <Popover id={`popover-${title}`} title={title}>
      {content}
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={["hover", "click"]}
      placement="right"
      overlay={overlay}
    >
      <i className="far fa-info-circle" />
    </OverlayTrigger>
  );
};

const minDistanceTooltipContent =
  "This number indicates how accurate our ordering algorithm is. The most accurate " +
  "results should be at the top, so we want this number to be 0.";
const applicationConversionTooltipContent = (
  <div>
    <p>
      This is the pure recommendation conversion rate. This number shows what
      percentage of recommendations converted to an application. The goal of 17%
      is based on a target 50% conversion rate per job, with the assumption that
      jobs will get an average of 3 recommendations.
    </p>
    <p>
      If using a date range close to the current date, this number will be low
      due to new recommendations that have not had a chance to convert yet.
    </p>
  </div>
);
const offerConversionTooltipContent =
  "This shows the convesion rate of applications from recommendations to job " +
  "offers.";
const offerAcceptanceConversionTooltipContent =
  "This is the conversion rate of job offers from applications due to " +
  "recommendations.";
const placementsMadeTooltipContent =
  "The percentage of all placements made on the platform through recommendations.";

class Index extends Component {
  submit = (values, dispatch) => {
    const { start_date, end_date } = values;
    return dispatch(requestRecommendationsStats(start_date, end_date)).then(
      handleSubmissionError,
    );
  };

  render() {
    const {
      error,
      isLoading,
      lastUpdated,
      stats: {
        num_recommendations,
        num_applications,
        num_offers,
        num_offers_accepted,
        amount_invoiced,
      },
      accuracyStats: {
        average_minimum_distance,
        application_conversion_rate,
        offer_conversion_rate,
        offer_acceptance_conversion_rate,
        placements_made,
      },
      parameterStats,
    } = this.props;

    return (
      <div>
        <h1>Recommendation Stats</h1>
        <div style={{ margin: "2rem 0" }}>
          <Row>
            <Col md={6}>
              {/* TODO: Add a second daterange form to allow comparisons (like applications stats) */}
              <DateRangeForm
                form="recommendationsStatsDateRangeForm"
                onSubmit={this.submit}
              />
            </Col>
          </Row>
        </div>
        <div>
          {isLoading ? (
            <Loading />
          ) : lastUpdated && !error ? (
            <Row>
              <Col md={6} style={{ borderRight: "1px solid #EEE" }}>
                <h3>Recommendations Accuracy</h3>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th />
                      <th>Stat</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <InfoToolTip
                          title="Avg. Min. Distance From Top"
                          content={minDistanceTooltipContent}
                        />
                      </td>
                      <td>Avg. Min. Distance From Top (goal: 0)</td>
                      <td>
                        {average_minimum_distance >= 0 &&
                          average_minimum_distance}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <InfoToolTip
                          title="Application Conversion Rate"
                          content={applicationConversionTooltipContent}
                        />
                      </td>
                      <td>Application Conversion Rate (goal: 17%)</td>
                      <td>
                        {application_conversion_rate >= 0 &&
                          (application_conversion_rate * 100).toFixed(2)}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <InfoToolTip
                          title="Job Offer Conversion Rate"
                          content={offerConversionTooltipContent}
                        />
                      </td>
                      <td>Job Offer Conversion Rate (goal: 60%)</td>
                      <td>
                        {offer_conversion_rate >= 0 &&
                          (offer_conversion_rate * 100).toFixed(2)}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <InfoToolTip
                          title="Job Offer Acceptance Conversion Rate"
                          content={offerAcceptanceConversionTooltipContent}
                        />
                      </td>
                      <td>Job Offer Acceptance Conversion Rate (goal: 100%)</td>
                      <td>
                        {offer_acceptance_conversion_rate >= 0 &&
                          (offer_acceptance_conversion_rate * 100).toFixed(2)}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <InfoToolTip
                          title="Placements Made through Recommendations"
                          content={placementsMadeTooltipContent}
                        />
                      </td>
                      <td>
                        Placements Made through Recommendations (goal: 50%)
                      </td>
                      <td>
                        {placements_made >= 0 &&
                          (placements_made * 100).toFixed(2)}
                        %
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <h3>Parameter Accuracy</h3>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Parameter Name</th>
                      <th>Exact Match (%)</th>
                      <th>Close Match (%)</th>
                      <th>Usage (%)</th>
                      <th>Usages (#)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(parameterStats).map(parameter => (
                      <tr key={parameter}>
                        <td>{parameter}</td>
                        {["exact", "fuzzy", "usage"].map(parameterStat => (
                          <td key={`${parameter}-${parameterStat}`}>
                            {parameterStats[parameter][parameterStat] >= 0 &&
                              (
                                parameterStats[parameter][parameterStat] * 100
                              ).toFixed(2)}
                          </td>
                        ))}
                        <td>{parameterStats[parameter].usages}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              <Col md={6}>
                <h3>Recommendations Funnel</h3>
                <div style={{ fontSize: "1.5rem" }} className="text-center">
                  <div>{num_recommendations} recommendations made</div>
                  <DownArrow />
                  <div>{num_applications} applications created</div>
                  <DownArrow />
                  <div>{num_offers} offers made</div>
                  <DownArrow />
                  <div>{num_offers_accepted} offers accepted</div>
                  <DownArrow />
                  <div>${amount_invoiced} invoiced</div>
                </div>
              </Col>
            </Row>
          ) : error ? (
            <div style={{ color: "red" }}>
              Server error, please retry or reload the page.
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(state => state.admin.recommendationStats)(Index);
