import styled from "styled-components";

const Counts = styled.h5`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary_black40()};
`;

const Total = styled.span`
  color: ${({ theme }) => theme.colors.primary_black40()};
`;

const Hired = styled.span`
  color: ${({ theme }) => theme.colors.success()};
`;

const Negotiating = styled.span`
  color: ${({ theme }) => theme.colors.primary_turquoise()};
`;

const Rejected = styled.span`
  color: ${({ theme }) => theme.colors.danger()};
`;

Counts.Total = Total;
Counts.Hired = Hired;
Counts.Negotiating = Negotiating;
Counts.Rejected = Rejected;

export default Counts;
