import { createResourceDetailQuery, getDefaultOptions } from "queries";
import { createApiUrl } from "utils";
import types from "resources/types";

export const ownUserQuery = () => ({
  url: createApiUrl("/api/v2/users/self/"),
  options: getDefaultOptions(),
  transform: json => ({
    ownUser: json,
  }),
  update: {
    ownUser: (prevResource, newData) => ({
      ...prevResource,
      data: newData,
    }),
  },
});

export const authQuery = () => ({
  url: createApiUrl("/api/v2/auth/"),
  options: getDefaultOptions(),
  transform: json => ({
    auth: json,
  }),
  update: {
    auth: (_prevResource, newData) => ({
      data: newData,
    }),
  },
});

export const authUserQuery = () =>
  createResourceDetailQuery(types.AUTH, {
    url: "/api/auth/user/",
    force: true,
  });

export const currentUserTypeQuery = ({ force = true } = {}) =>
  createResourceDetailQuery(types.CURRENT_USER_TYPE, {
    url: "/api/v2/current_user_type/",
    force,
  });
