import { mutateResourceQuery } from "queries";
import types from "resources/types";

export const clientFeedbackSurveyResponsesQuery = data =>
  mutateResourceQuery(
    types.CLIENT_FEEDBACK_SURVEY_RESPONSES,
    {
      url: "/api/v2/client_feedback_survey_responses/",
      options: {
        method: "POST",
      },
    },
    data,
  );
