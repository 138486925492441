import React, { Component } from "react";
import { connect } from "react-redux";
import { history as browserHistory } from "app";

import ContractorProfile from "hiringagent-dashboard/components/contractor/ContractorProfile";
import { requestAgency } from "hiringagent-dashboard/actions/agency";
import { requestAuthStatus } from "hiringagent-dashboard/actions/auth";

export class SharedContractorProfile extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const {
      hiringAgentAuthStatus: { authenticated },
      hiringAgency: {
        lastUpdated,
        agency: { verified },
      },
    } = nextProps;
    if (authenticated && (lastUpdated && verified === "Verified")) {
      browserHistory.push({ pathname: `/agency/attorneys/${id}` });
    }
  }

  render() {
    const { ...otherProps } = this.props;
    return <ContractorProfile isSharedProfile={true} {...otherProps} />;
  }
}

export default connect(
  state => ({
    hiringAgentAuthStatus: state.hiringAgentDashboard.auth,
    hiringAgency: state.hiringAgentDashboard.agency,
  }),
  dispatch => ({
    onLoad: () => {
      dispatch(requestAuthStatus());
      dispatch(requestAgency());
    },
  }),
)(SharedContractorProfile);
