import React, { Component, Fragment } from "react";
import { Button, Col, Row, Well } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { reduxForm, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import { EmailAddressInput } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";
import { createErrorNotification } from "common/utils/notifications";

import { logout } from "queries/mutations/auth";
import { connect, mutateAsync } from "queries/utils";
import { createQueryConfig } from "queries";

const validate = values => validateRequiredFields(["email"], values);

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <EmailAddressInput name="email" />
        <Button
          style={{ width: "100%" }}
          type="submit"
          bsStyle="success"
          disabled={submitting}
        >
          Send Password Reset Email
        </Button>
      </form>
    );
  }
}

ForgotPasswordForm = reduxForm({
  form: "forgotPasswordForm",
  validate,
})(ForgotPasswordForm);

export class ForgotPassword extends Component {
  state = {
    emailSentTo: null,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  handleSubmit = async values => {
    const { createResetRequest, notify } = this.props;
    const { status, body } = await createResetRequest(values);

    if (status === 200) {
      this.setState({
        emailSentTo: values.email,
      });
    } else {
      notify(createErrorNotification());
      throw new SubmissionError(body);
    }
  };

  renderForm = () => (
    <Fragment>
      <h2 style={{ marginTop: "0" }}>Forgot your password?</h2>
      <p>
        Fill in your email address and we&apos;ll send you a link to reset your
        password.
      </p>
      <ForgotPasswordForm onSubmit={this.handleSubmit} />
    </Fragment>
  );

  renderSuccessMsg = () => (
    <Fragment>
      <h2 style={{ marginTop: "0" }}>Email Sent</h2>
      <p>
        An email has been sent to <code>{this.state.emailSentTo}</code> with
        instructions for resetting your password.
      </p>
    </Fragment>
  );

  render() {
    return (
      <Row>
        <Helmet>
          <title>Forgot password</title>
        </Helmet>
        <Col md={6} mdOffset={3}>
          <Well>
            {!this.state.emailSentTo
              ? this.renderForm()
              : this.renderSuccessMsg()}
          </Well>
        </Col>
      </Row>
    );
  }
}

export default connect(
  null,
  {
    createResetRequest: data =>
      mutateAsync(
        createQueryConfig({
          url: "/api/auth/password/reset/",
          body: data,
        }),
      ),
    notify,
    onLoad: logout,
  },
)(ForgotPassword);
