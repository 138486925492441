/**
 * This file contains modified selectors (queries/selectors/index.js) to
 * be used with redux-query's hooks. We don't need to use querySelectors here
 * because redux-query's useRequest hook returns that information.
 */
import { getQueryKey } from "redux-query";

export const createResourceSelector = (resource, request) => {
  return state => {
    const queryKey = getQueryKey(request);
    const entity = state.entities[resource];
    return {
      [resource]: {
        query: entity.queries[queryKey] || { data: [], count: -1 },
        ...entity,
      },
    };
  };
};
