import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import { compose, connect, connectRequest } from "queries/utils";
import {
  JobDescriptionSection,
  JobDetailsRow,
  JobExpertiseAreasDisplay,
  JobLocationDisplay,
  JobPrimaryPracticeArea,
  JobRequirementsSection,
} from "common/components/jobs";
import PositionTypesList from "common/components/PositionTypesList";
import types from "resources/types";
import { createResourceSelectorConfig } from "queries";
import { onboardingRequirementsQuery } from "queries/requests/onboardingRequirements";

const DetailsSection = ({
  job,
  jobListing,
  onboardingRequirements: { data, isFinished, isPending },
}) => {
  const isReady = isFinished && !isPending;
  let completedOnboarding = isReady
    ? Object.values(data)[0].overall_status === "COMPLETED"
    : false;

  return (
    // TODO: When status is on jobListing, remove job prop
    <Fragment>
      <h2 className="main-info-title" style={{ marginBottom: "rem" }}>
        Details
      </h2>
      <div>
        {jobListing.published_date && (
          <JobDetailsRow title="Published">
            {moment(jobListing.published_date).format("ll")}
          </JobDetailsRow>
        )}
        <JobDetailsRow title="Status">{job.statusDisplay}</JobDetailsRow>
        {isReady &&
          jobListing.estimatedRateDisplay && (
            <JobDetailsRow title="Estimated rate">
              {completedOnboarding
                ? jobListing.estimatedRateDisplay
                : "Finish account setup to view rate"}
            </JobDetailsRow>
          )}
        {typeof jobListing.project_length === "number" && (
          <JobDetailsRow title="Expected duration">
            {jobListing.projectLengthDisplay}
          </JobDetailsRow>
        )}
        {jobListing.start_date && (
          <JobDetailsRow title="Estimated start date">
            {moment(jobListing.start_date).format("ll")}
          </JobDetailsRow>
        )}
        <JobDetailsRow title="Allowed applicants">
          {jobListing.allowedApplicantsDisplay}
        </JobDetailsRow>
        {jobListing.position_type && (
          <JobDetailsRow title="Position type">
            <PositionTypesList positionTypeIds={[jobListing.position_type]} />
          </JobDetailsRow>
        )}
        <JobDetailsRow title="Experience">
          {jobListing.experienceDisplay}
        </JobDetailsRow>
        <JobDetailsRow title="Can work remotely">
          {jobListing.remote ? "Yes" : "No"}
        </JobDetailsRow>
        {jobListing.joblistinglocation_set.length > 0 && (
          <JobDetailsRow title="Locations">
            <JobLocationDisplay locations={jobListing.joblistinglocation_set} />
          </JobDetailsRow>
        )}
        <JobDetailsRow title="Primary practice area">
          <JobPrimaryPracticeArea {...jobListing} />
        </JobDetailsRow>
        <JobDetailsRow title="Expertise areas">
          <JobExpertiseAreasDisplay jobAreas={jobListing.expertisearea_set} />
        </JobDetailsRow>
      </div>
    </Fragment>
  );
};

const JobDetails = props => (
  <div className="main-info-container" style={{ margin: "1.5rem 0" }}>
    <Row>
      <Col sm={7} lg={8}>
        <JobRequirementsSection {...props} />
        <JobDescriptionSection {...props} />
      </Col>
      <Col sm={5} lg={4}>
        <DetailsSection {...props} />
      </Col>
    </Row>
  </div>
);

const onboardingRequirementsSelectorConfig = createResourceSelectorConfig(
  types.ONBOARDING_REQUIREMENTS,
  onboardingRequirementsQuery,
);

const mapPropsToConfig = () => [onboardingRequirementsQuery()];

const mapStateToProps = createStructuredSelector({
  ...onboardingRequirementsSelectorConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobDetails);
