import styled from "styled-components";
import { Button, Table as RBSTable } from "react-bootstrap";

const getTextColor = ({ theme, status }) => {
  switch (status) {
    case "success":
      return theme.colors.success();
    case "warning":
      return theme.colors.warning();
    case "danger":
      return theme.colors.danger();
    default:
      return theme.colors.primary_black60();
  }
};

export const TablePlaceholder = styled.p`
  &,
  & * {
    width: 100%;
    margin: 0;
    padding: 15px 10px;
    text-align: center;
    background: none;
    color: ${({ theme }) => theme.colors.primary_black60()};
    font-size: 16px;
  }
`;

export const TableButton = styled(Button).attrs({
  bsSize: "xs",
})`
  &&&& {
    float: ${({ float }) => (float ? float : "none")};
    width: ${({ block }) => (block ? "100%" : "auto")};
    margin: ${({ margin }) => (margin ? margin : "0")};
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const TableButtonCell = styled.td`
  overflow: auto;
  white-space: nowrap;

  & * {
    display: inline;
  }
`;

export const TableStatusText = styled.span`
  margin: 0;
  padding: 0;
  color: ${getTextColor};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
`;

const Table = styled(RBSTable).attrs({
  striped: true,
  responsive: true,
})``;

export default Table;
