// @flow
import { type Uuid } from "./primitives";

export default class HiringAgent {
  uuid: Uuid;
  first_name: string;
  last_name: string;
  email_address: string;
  haeuser: string;
  is_admin: boolean;
  confirmed: boolean;
  completed_onboarding: boolean;
  active: boolean;

  constructor(hiringAgent: Object) {
    this.uuid = hiringAgent.uuid;
    this.first_name = hiringAgent.first_name;
    this.last_name = hiringAgent.last_name;
    this.email_address = hiringAgent.email_address;
    this.haeuser = hiringAgent.haeuser;
    this.is_admin = hiringAgent.is_admin;
    this.confirmed = hiringAgent.confirmed;
    this.completed_onboarding = hiringAgent.completed_onboarding;
    this.active = hiringAgent.active;
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
