import React from "react";

import { RadioInputGroup } from "contractor/components";
import Workstyle from "contractor/components/WorkstyleAssessment/blocks";

const RadioGroupQuestion = ({
  handleUpdateProgress,
  question,
  responseOptions,
}) => (
  <Workstyle.QuestionGroup>
    <Workstyle.Question>{question.question_text}</Workstyle.Question>
    <RadioInputGroup
      handleUpdateProgress={handleUpdateProgress}
      name={question.uuid.toString()}
      responseOptions={responseOptions}
    />
  </Workstyle.QuestionGroup>
);

export default RadioGroupQuestion;
