import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Col, Row, Table } from "react-bootstrap";
import { requestApplicationsStats } from "admin/actions/applications/applicationsStats";
import ApplicationsStatsForm from "admin/forms/ApplicationsStatsForm";
import Loading from "common/components/Loading";

const ChangeIndicator = ({ amount }) => {
  const isZero = amount === 0;
  const isNegative = amount < 0;
  return (
    <span>
      <i
        className={`far fa-${
          isNegative ? "caret-down" : isZero ? "" : "caret-up"
        }`}
        style={{ color: isNegative ? "red" : isZero ? "" : "green" }}
      />
      &nbsp;
    </span>
  );
};

const PercentChange = ({ amount1, amount2 }) => {
  const changeAmount =
    amount1 === 0 ? 0 : (((amount2 - amount1) / amount1) * 100).toFixed(2);
  return changeAmount && !(amount1 === -1 && amount2 === -1) ? (
    <span>
      <ChangeIndicator amount={changeAmount} />
      {changeAmount}%
    </span>
  ) : null;
};

class ApplicationsStats extends Component {
  render() {
    const {
      isLoading,
      requestApplicationsStats,
      stats,
      statsMapping,
    } = this.props;

    const stats1 = stats[0];
    const stats2 = stats[1];

    return (
      <div className="applications-stats">
        <h1>Applications Stats</h1>
        <div style={{ margin: "2rem 0" }}>
          <Row>
            <Col md={6} mdOffset={6}>
              <div>Compare with:</div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col md={6}>
            <ApplicationsStatsForm form="applicationsStatsForm1" />
          </Col>
          <Col md={6}>
            <ApplicationsStatsForm form="applicationsStatsForm2" />
          </Col>
        </Row>

        <div className="text-center">
          <Button
            bsStyle="default"
            disabled={isLoading}
            onClick={() => requestApplicationsStats()}
          >
            Submit
          </Button>
        </div>

        <div style={{ margin: "2rem 0" }}>
          {isLoading ? (
            <Loading />
          ) : (
            <Table striped bordered>
              <thead>
                <tr>
                  <th className="text-right">Metric</th>
                  <th className="text-right">Results</th>
                  <th className="text-center">% Change</th>
                  <th className="text-left">Compare</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(statsMapping).map(name => (
                  <tr key={`stats-row-${name}`}>
                    <td className="text-right">{statsMapping[name]}:</td>
                    <td className="text-right">
                      {stats1[name] >= 0 && stats1[name].toLocaleString()}
                    </td>
                    <td className="text-center">
                      <PercentChange
                        amount1={stats1[name]}
                        amount2={stats2[name]}
                      />
                    </td>
                    <td className="text-left">
                      {stats2[name] >= 0 && stats2[name].toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => state.admin.applicationsStats,
  dispatch => ({
    requestApplicationsStats: () => dispatch(requestApplicationsStats()),
  }),
)(ApplicationsStats);
