import styled from "styled-components";

const Date = styled.div.attrs({
  className: "accent-title experience-date",
})`
  && {
    color: ${({ theme }) => theme.colors.primary_black()};
    display: inline-block;
    float: right;
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.media.mobile`
    && {
      display: block;
    }
  `};
`;

export default Date;
