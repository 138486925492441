import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "react-bootstrap";

import { requestUserType } from "common/actions/user";
import { requestHiringAgent } from "hiringagent-dashboard/actions/sidebar";
import { requestHiringAgents } from "hiringagent-dashboard/actions/hiringAgents";
import { requestPaymentSources } from "hiringagent-dashboard/actions/payments";
import { requestRecommendations } from "hiringagent-dashboard/actions/recommendations";
import { requestPerformanceReviews } from "hiringagent-dashboard/actions/reviews";
import { requestTags } from "hiringagent-dashboard/actions/tags";
import { requestLanguages } from "hiringagent-dashboard/actions/languages";

import {
  withAgency,
  withNotifications,
} from "hiringagent-dashboard/connectors";

import { TopNavHeader } from "common/components";
import { ContentWrapper, Footer } from "common/components/layout";
import { MarketingConversionTracker } from "hiringagent-dashboard/components/tracking";
import Notifications from "hiringagent-dashboard/containers/Notifications";

class Base extends Component {
  componentDidMount() {
    // Load some of the things
    this.props.dispatch(requestUserType("hiring_agent"));
    this.props.dispatch(requestHiringAgent());
    this.props.dispatch(requestHiringAgents());
    this.props.dispatch(requestPaymentSources());
    this.props.dispatch(requestRecommendations());
    this.props.dispatch(requestPerformanceReviews());
    this.props.dispatch(requestTags());
    this.props.dispatch(requestLanguages());
  }

  render() {
    const {
      notifications: { notifications },
    } = this.props;
    const pendingNotifications = Object.values(notifications).filter(
      notification => !notification.read,
    );

    return (
      <Fragment>
        <TopNavHeader userType="hiring_agent" />
        <ContentWrapper>
          <Grid id="content">{this.props.children}</Grid>
        </ContentWrapper>
        <Footer />
        {pendingNotifications && (
          <Notifications pendingNotifications={pendingNotifications} />
        )}
        <MarketingConversionTracker />
      </Fragment>
    );
  }
}

// `connect()` passes `dispatch()` to props
export default compose(
  connect(
    null,
    null,
  ),
  withAgency(),
  withNotifications(),
)(Base);
