import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { verifiedSelector } from "hiringagent-dashboard/selectors";

import {
  removeAttorney,
  updatePracticeArea,
} from "hiringagent-dashboard/actions/search";

import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Alert, Col, Row } from "react-bootstrap";

import AttorneySearchResult from "hiringagent-dashboard/components/search/AttorneySearchResult";

export class AttorneySearchResultsList extends Component {
  handleHideClick(contractorUuid) {
    this.props.dispatch(removeAttorney(contractorUuid));
  }

  handlePracticeAreaClick(practiceAreaName) {
    this.props.dispatch(updatePracticeArea(practiceAreaName));
  }

  render() {
    const { verified, showPreviewAlert } = this.props;
    const { attorneys, isLoading, numberOfResults } = this.props.search;
    const sortedAttorneys = Object.keys(attorneys)
      .map(key => attorneys[key])
      .sort((a, b) => {
        if (a.order > b.order) return 1;
        if (b.order > a.order) return -1;
        return 0;
      });

    return (
      <div id="search-results-list" className="list-unstyled">
        <ReactCSSTransitionGroup
          transitionName="search-results-transition"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {isLoading ? (
            <p key="content" className="no-search-results">
              Search in progress... <span className="far fa-spinner fa-spin" />
            </p>
          ) : numberOfResults === 0 ? (
            <p key="content" className="no-search-results">
              Sorry, there are no candidates matching these search criteria.
              Please try broadening your search!
            </p>
          ) : (
            <div key="content">
              {showPreviewAlert && (
                <div className="preview-alert-container">
                  <Row>
                    <Col
                      xs={12}
                      xsOffset={0}
                      lg={6}
                      lgOffset={3}
                      sm={10}
                      smOffset={1}
                    >
                      <Alert bsStyle="info" style={{ marginBottom: 0 }}>
                        <i className="far fa-exclamation-circle" />
                        &nbsp;<strong>Preview enabled</strong> You are currently
                        viewing a preview of the Hire an Esquire network. Names,
                        pictures, and full profiles have been hidden to protect
                        our users&apos; privacy. Once your agency has been
                        verified, you will have access to full profiles.
                      </Alert>
                    </Col>
                  </Row>
                </div>
              )}
              {sortedAttorneys.map(attorney => (
                <AttorneySearchResult
                  attorney={attorney.attorney}
                  key={attorney.attorney.uuid}
                  onPracticeAreaClick={practiceAreaName =>
                    this.handlePracticeAreaClick(practiceAreaName)
                  }
                  onHideClick={() =>
                    this.handleHideClick(attorney.attorney.uuid)
                  }
                  verified={verified}
                />
              ))}
            </div>
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

export default connect(state =>
  createStructuredSelector({
    search: state => state.hiringAgentDashboard.search,
    sidebar: state => state.hiringAgentDashboard.sidebar,
    verified: verifiedSelector,
  })(state),
)(AttorneySearchResultsList);
