import React from "react";

import { Pagination } from "common/components";

const TalentPoolsSearchPagination = ({ offset, limit, total, onChange }) => {
  const numPages = Math.ceil(total / limit);
  if (numPages < 2) return null;
  const handleChange = pageNumber => {
    const newOffset = pageNumber * limit - limit;
    return onChange(newOffset);
  };
  return (
    <Pagination
      currentPage={offset / limit + 1}
      totalPages={numPages}
      onChange={handleChange}
    />
  );
};

export default TalentPoolsSearchPagination;
