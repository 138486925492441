// @flow strict
import { history as browserHistory } from "app";
import querystring from "querystring";

type Query = {
  keywords?: string,
  location?: string,
  limit: number,
  offset: number,
  ordering: string,
};

const defaultQuery = {
  limit: 10,
  offset: 0,
  ordering: "-published_date",
};

export function updateHistoryQuery(query: Query = defaultQuery) {
  const { pathname } = browserHistory.location;
  Object.keys(query).forEach(key => {
    if ([null, undefined].includes(query[key])) {
      delete query[key];
    }
  });
  browserHistory.push({
    pathname,
    search: "?" + querystring.stringify(query),
  });
}

export const setQueryParams = (params, replace = false) => {
  const { pathname } = browserHistory.location;
  const func = replace ? browserHistory.replace : browserHistory.push;
  func({
    pathname,
    search: "?" + querystring.stringify(params),
  });
};
