import React, { Component } from "react";
import PropTypes from "prop-types";
import PracticeAreaGroup from "common/components/PracticeAreaGroup";

export default class AttorneyPracticeAreasGroupsList extends Component {
  practiceAreasByGroup(practiceAreas) {
    const result = practiceAreas.reduce((acc, tag) => {
      if (acc[tag.group.name]) {
        acc[tag.group.name].practiceAreas.push(tag.name);
      } else {
        acc[tag.group.name] = { practiceAreas: [tag.name] };
      }
      return acc;
    }, {});
    return result;
  }

  render() {
    const { attorneyUuid, practiceAreas } = this.props;
    const attorneyPracticeAreas = Object.entries(
      this.practiceAreasByGroup(practiceAreas),
    ).map((practiceArea, i) => (
      <PracticeAreaGroup
        practiceArea={practiceArea}
        key={`${attorneyUuid}-${practiceArea[0]}-${i}`}
      />
    ));

    return (
      practiceAreas.length > 0 && (
        <ul className="attorney-practice-areas list-inline">
          {attorneyPracticeAreas}
        </ul>
      )
    );
  }
}

AttorneyPracticeAreasGroupsList.propTypes = {
  attorneyUuid: PropTypes.string.isRequired,
  onPracticeAreaClick: PropTypes.func,
  practiceAreas: PropTypes.array,
  selectedTags: PropTypes.array,
};

AttorneyPracticeAreasGroupsList.defaultProps = {
  attorneyUuid: "",
  practiceAreas: [],
  selectedTags: [],
};
