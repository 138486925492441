import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";

import { YearsExperienceRadioField } from "common/components/forms/inputs";
import { Error } from "common/utils/forms/validators";
import { FormRow, withFormError } from "./utils";
import JobListing from "types/JobListing";

const validate = values => {
  const errors = {};
  const minOptions = JobListing.experienceOptions.map(option => option.min);
  const maxOptions = JobListing.experienceOptions.map(option => option.max);
  if (
    !minOptions.includes(values.min_years_experience) ||
    !maxOptions.includes(values.max_years_experience)
  ) {
    // Store all errors on min_years_experience
    errors.min_years_experience = (
      <Error>Please select one of the options above.</Error>
    );
  }
  return errors;
};

class ExperienceForm extends Component {
  handleExperienceSelect = (min, max) => {
    const { change } = this.props;
    change("min_years_experience", min);
    change("max_years_experience", max);
  };
  render() {
    const { formValues, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FormRow>
          <div>
            <YearsExperienceRadioField
              formValues={formValues}
              onChange={this.handleExperienceSelect}
            />
          </div>
          <p />
        </FormRow>
      </form>
    );
  }
}

ExperienceForm = compose(
  connect((state, props) => {
    const selector = formValueSelector(props.form);
    return {
      formValues: {
        max_years_experience: selector(state, "max_years_experience"),
        min_years_experience: selector(state, "min_years_experience"),
      },
    };
  }),
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(ExperienceForm);

export default class Experience extends Component {
  render() {
    const { formProps, jobListing } = this.props;
    const initialValues = {
      max_years_experience: jobListing.max_years_experience,
      min_years_experience: jobListing.min_years_experience,
    };
    return (
      <div>
        <h2>What&apos;s the ideal experience range?</h2>
        <ExperienceForm {...formProps} initialValues={initialValues} />
      </div>
    );
  }
}
