import React, { Component } from "react";
import { connect, compose, connectResource } from "resources/components/utils";

import { getDisplayName } from "hiringagent-dashboard/utils";
import {
  createResourceSelector,
  createResourceMetadataSelector,
} from "resources/selectors";

export const withResource = request => WrappedComponent => {
  const requestObj = request();
  const requestName = requestObj.type;

  class WithResource extends Component {
    static displayName = `With${requestName}Resource(${getDisplayName(
      WrappedComponent,
    )})`;

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const selector = createResourceSelector(requestName)();
  const metadataSelector = createResourceMetadataSelector(requestName)();
  const mapStateToProps = state => ({
    [requestName]: {
      data: selector(state),
      metadata: metadataSelector(state),
    },
  });

  return compose(
    connect(mapStateToProps),
    connectResource(request),
  )(WithResource);
};
