import React, { Component } from "react";
import { compose } from "redux";
import { Button, ButtonToolbar, Table } from "react-bootstrap";

import Loading from "common/components/Loading";
import { withSchools } from "contractor/connectors";

class ListSchools extends Component {
  handleDeleteClick = schoolId => () => {
    this.props.onDeleteClick(schoolId);
  };

  handleEditClick = schoolId => () => {
    this.props.onEditClick(schoolId);
  };

  render() {
    const {
      schools: {
        data: { results = [] },
        isLoading,
        lastUpdated,
      },
    } = this.props;
    return (
      <div>
        <Table striped responsive>
          <thead>
            <tr>
              <th>School Name</th>
              <th>Degree</th>
              <th>Year Graduated</th>
              <th>GPA</th>
              <th />
            </tr>
          </thead>
          {lastUpdated && !isLoading && (
            <tbody>
              {results.map(school => (
                <tr key={`school-row-${school.uuid}`}>
                  <td>{school.school_name}</td>
                  <td>{school.degree}</td>
                  <td>{school.year_graduated}</td>
                  <td>{school.gpa}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <ButtonToolbar>
                      <Button
                        bsSize="xs"
                        bsStyle="primary"
                        style={{ float: "none" }}
                        onClick={this.handleEditClick(school.uuid)}
                      >
                        Edit
                      </Button>
                      <Button
                        bsSize="xs"
                        bsStyle="danger"
                        style={{ float: "none" }}
                        onClick={this.handleDeleteClick(school.uuid)}
                      >
                        Delete
                      </Button>
                    </ButtonToolbar>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {isLoading ? (
          <Loading />
        ) : (
          results.length === 0 && (
            <p className="text-center">
              You don&apos;t have any schools on file. Use the form below to add
              some.
            </p>
          )
        )}
      </div>
    );
  }
}

export default compose(withSchools())(ListSchools);
