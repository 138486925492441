// @flow strict
import * as React from "react";

import Top from "../blocks";
import Mobile from "./blocks";
import type { LinkItemProps } from "../DesktopNav/LinkItem";
import { isActiveURL } from "common/utils";

const { Fragment } = React;

type MobileLinkItemProps = LinkItemProps;

type Props = MobileLinkItemProps;

const MobileLinkItem: React.StatelessFunctionalComponent<Props> = (
  props: Props,
) => {
  const { badge, onClick, name, to } = props;
  const open = isActiveURL(to, true);

  return (
    <Fragment>
      <Mobile.Divider />
      <Top.NavLink onClick={onClick} open={open} to={to}>
        <Mobile.NavItem>
          <span name={name}>{name}</span>
          <div>
            <Top.Badge badge={badge}>{badge}</Top.Badge>
          </div>
        </Mobile.NavItem>
      </Top.NavLink>
    </Fragment>
  );
};

export default React.memo<MobileLinkItemProps>(MobileLinkItem);
