import React from "react";

import Qualifications from "./blocks";

const scrollToQualifications = () =>
  document
    .querySelector("#candidate-qualifications")
    .scrollIntoView({ block: "start", behavior: "smooth" });

const QualificationsFilled = ({ data, queryData }) => {
  const required = queryData
    .map(responseId => data[responseId])
    .filter(item => item.preference === "Required" && item.requirement);
  const numFulfilled = required.filter(item => item.response).length;
  const numRequired = required.length;

  return numRequired === 0 ? null : (
    <Qualifications numFulfilled={numFulfilled} numRequired={numRequired}>
      <Qualifications.Heading
        numFulfilled={numFulfilled}
        numRequired={numRequired}
      >
        <span>
          <Qualifications.Header2
            numFulfilled={numFulfilled}
            numRequired={numRequired}
          >
            {`${numFulfilled} out of ${numRequired} `}
          </Qualifications.Header2>{" "}
          <Qualifications.Header1
            numFulfilled={numFulfilled}
            numRequired={numRequired}
          >
            {"required qualifications fulfilled"}
          </Qualifications.Header1>
        </span>
      </Qualifications.Heading>
      <Qualifications.Footing onClick={scrollToQualifications}>
        <Qualifications.ViewLink>
          View All Qualifications
        </Qualifications.ViewLink>
      </Qualifications.Footing>
    </Qualifications>
  );
};

export default QualificationsFilled;
