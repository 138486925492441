import React, { Component } from "react";
import { connect } from "react-redux";

import {
  clearFilters,
  removeQueryParam,
  submitSearchQuery,
  updatePracticeArea,
  toggleShowFilters,
} from "../../actions/search";

import AttorneyPracticeAreaFilter from "../../components/search/AttorneyPracticeAreaFilter";
import BooleanFilter from "../../components/BooleanFilter";
import SelectFilter from "../../components/SelectFilter";

import Geosuggest from "react-geosuggest";

class AttorneySearchFilters extends Component {
  handleClearFiltersClick(e) {
    e.preventDefault();
    this.props.dispatch(clearFilters());
  }

  handleClearLocationClick(e) {
    e.preventDefault();
    this.props.dispatch(removeQueryParam("lat"));
    this.props.dispatch(removeQueryParam("lng"));
    this.props.dispatch(submitSearchQuery({ location: "" }));
  }

  handleLocationFilterChange(selection) {
    if (selection) {
      this.props.dispatch(
        submitSearchQuery({
          location: selection.label,
          lat: selection.location.lat,
          lng: selection.location.lng,
        }),
      );
    }
  }

  handleShowHideClick(e) {
    e.preventDefault();
    this.props.dispatch(toggleShowFilters());
  }

  handleSelectFilterChange(paramName, value) {
    if (value) {
      this.props.dispatch(submitSearchQuery({ [paramName]: value }));
    } else {
      this.props.dispatch(removeQueryParam(paramName));
    }
  }

  handlePracticeAreasChange(newPracticeArea) {
    this.props.dispatch(updatePracticeArea(newPracticeArea));
  }

  render() {
    const { numberOfResults, showFilters } = this.props;
    const {
      barred_in,
      location,
      miles,
      proficient_in,
      practice_areas: selectedPracticeArea,
      years_experience,
      max_years_experience,
      contractor_type,
    } = this.props.queryParams;
    const {
      languages: languageOptions,
      miles: milesOptions,
      states: statesOptions,
      practiceAreas,
      yearsExperience: yearsExperienceOptions,
    } = this.props.filterOptions;

    const is_available = Boolean(this.props.queryParams.is_available);

    return (
      <form className="form-inline">
        <div className="form-group show-hide-filters-container">
          <a
            href="#"
            className="show-hide-filters-button"
            onClick={e => this.handleShowHideClick(e)}
          >
            {showFilters ? "Hide filters" : "Show filters"}
          </a>
        </div>
        {/* <ReactCSSTransitionGroup transitionName='search-results-transition'
                                         transitionEnterTimeout={500} transitionLeaveTimeout={300}>*/}
        {showFilters && (
          <div>
            <div className="form-group">
              <select
                name={"contractor_type"}
                id={"id_contractor_type"}
                onChange={e =>
                  this.handleSelectFilterChange(
                    "contractor_type",
                    e.target.value,
                  )
                }
                value={contractor_type}
                className={"form-control"}
              >
                <option value={"attorneys"} key={"attorneys"}>
                  Search Attorneys
                </option>
                <option value={"non_attorneys"} key={"non_attorneys"}>
                  Search Non-Attorneys
                </option>
                <option value={"all"} key={"all"}>
                  Search All Candidates
                </option>
              </select>
            </div>
            <AttorneyPracticeAreaFilter
              onPracticeAreasChange={newPracticeArea =>
                this.handlePracticeAreasChange(newPracticeArea)
              }
              practiceAreas={practiceAreas}
              selectedPracticeArea={selectedPracticeArea}
            />

            <div className="form-group">
              <SelectFilter
                value={years_experience}
                label={"Min. Years of Experience"}
                name={"years_experience"}
                onChange={e =>
                  this.handleSelectFilterChange(
                    "years_experience",
                    e.target.value,
                  )
                }
              >
                {yearsExperienceOptions.map(year => (
                  <option value={year.value} key={year.value}>
                    {year.label}
                  </option>
                ))}
              </SelectFilter>
            </div>

            <div className="form-group">
              <SelectFilter
                value={max_years_experience}
                label={"Max Years of Experience"}
                name={"max_years_experience"}
                onChange={e =>
                  this.handleSelectFilterChange(
                    "max_years_experience",
                    e.target.value,
                  )
                }
              >
                {yearsExperienceOptions.map(year => (
                  <option value={year.value} key={year.value}>
                    {year.label}
                  </option>
                ))}
              </SelectFilter>
            </div>

            <div className="form-group geosuggest-container">
              <Geosuggest
                className=""
                placeholder="Any Location"
                onSuggestSelect={selection =>
                  this.handleLocationFilterChange(selection)
                }
                initialValue={location}
              />
              {location && (
                <a
                  className="clear-location"
                  onClick={e => this.handleClearLocationClick(e)}
                >
                  <i className="far fa-times " />
                </a>
              )}
            </div>

            <div className="form-group">
              <SelectFilter
                value={miles}
                label={"Search Within"}
                name={"miles"}
                onChange={e =>
                  this.handleSelectFilterChange("miles", e.target.value)
                }
              >
                {milesOptions.map(mile => (
                  <option value={mile.value} key={mile.value}>
                    {mile.label}
                  </option>
                ))}
              </SelectFilter>
            </div>

            <div className="form-group">
              <SelectFilter
                value={barred_in}
                label={"Licensed in"}
                name={"barred_in"}
                onChange={e =>
                  this.handleSelectFilterChange("barred_in", e.target.value)
                }
              >
                {statesOptions.map(state => (
                  <option value={state.value} key={state.value}>
                    {state.label}
                  </option>
                ))}
              </SelectFilter>
            </div>

            <div className="form-group">
              <SelectFilter
                value={proficient_in}
                label={"Languages Spoken"}
                name={"proficient_in"}
                onChange={e =>
                  this.handleSelectFilterChange("proficient_in", e.target.value)
                }
              >
                {languageOptions.map(language => (
                  <option value={language} key={language}>
                    {language}
                  </option>
                ))}
              </SelectFilter>
            </div>

            <BooleanFilter
              active={is_available}
              label={"Available"}
              onChange={e =>
                this.handleSelectFilterChange("is_available", e.target.checked)
              }
            />

            <div className="form-group">
              <a href="#" onClick={e => this.handleClearFiltersClick(e)}>
                Clear all filters
              </a>
            </div>

            {numberOfResults > 0 && (
              <div className="number-of-results">
                {numberOfResults} candidates found
              </div>
            )}
          </div>
        )}
        {/* </ReactCSSTransitionGroup>*/}
      </form>
    );
  }
}

AttorneySearchFilters.propTypes = {};

export default connect(state => state.hiringAgentDashboard.search)(
  AttorneySearchFilters,
);
