import styled from "styled-components";

const TooltipIcon = styled.i.attrs({
  className: "far fa-info-circle",
})`
  color: ${({ theme }) => theme.colors.primary_black40()};
  cursor: pointer;
`;

export default TooltipIcon;
