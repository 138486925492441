import React, { Fragment } from "react";
import { Alert, Col, Row } from "react-bootstrap";

import Edit from "./blocks";
import { DynamicDirectDepositForm } from "contractor/forms/settings/DirectDepositForm/index.js";

const DynamicFormSection = ({
  needMoreFields,
  fieldsNeeded,
  hasIssueMessages,
  issueMessages,
  hasBankData,
  bankData,
  onHideDynamicForm,
}) => (
  <Fragment>
    <Row>
      <Col xs={12}>
        <h2>Update Bank Account</h2>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <p>
          Stripe requires more information from you before your account can
          receive payments.{" "}
          {needMoreFields && (
            <span>
              Never miss a payment by filling out the following required ({
                <Edit.RedText>*</Edit.RedText>
              }) fields.
            </span>
          )}
        </p>
      </Col>
    </Row>
    {hasIssueMessages && (
      <Row>
        <Col xs={12}>
          <Alert bsStyle="danger">
            <ul>
              {issueMessages.map((issue, key) => <li key={key}>{issue}</li>)}
            </ul>
          </Alert>
        </Col>
      </Row>
    )}
    {needMoreFields && (
      <Row>
        <Col xs={12}>
          <DynamicDirectDepositForm
            bankData={bankData}
            fieldsNeeded={fieldsNeeded}
            dynamic
            hasBankData={hasBankData}
            onHideDynamicForm={onHideDynamicForm}
            withIDUpload
          />
        </Col>
      </Row>
    )}
  </Fragment>
);

export default DynamicFormSection;
