import * as Sentry from "@sentry/browser";

// Sentry middleware
const sentryMiddleware = store => next => action => {
  const state = store.getState();
  Sentry.addBreadcrumb({
    message: action.type,
    category: "redux action",
    level: "info",
    data: {
      payload: action.payload,
      auth: state.auth,
    },
  });
  if (action.type === "RECEIVE_AUTH" && !action.error) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: action.payload.pk,
        email: action.payload.email,
        user_type: action.payload.user_type,
        verified: action.payload.verified,
      });
    });
  }
  return next(action);
};

export default sentryMiddleware;
