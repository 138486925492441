import { createAction } from "redux-actions";
import { listToObject, metaGenerator } from "admin/utils";
import keyMirror from "keymirror";

import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_CONTRACTOR_AUDITS: null,
  RECEIVE_CONTRACTOR_AUDITS: null,
});
export default actions;

export const requestContractorAudits = () => dispatch => {
  dispatch(createAction(actions.REQUEST_CONTRACTOR_AUDITS)());
  return oldApi
    .get("/api/v2/contractor_audits/?limit=999")
    .then(({ json, response }) => {
      const payload = response.ok
        ? {
            contractorAudits: listToObject(json.results, "uuid"),
            count: json.count,
            next: json.next,
            previous: json.previous,
          }
        : new Error("Error retrieving audits.");
      return dispatch(
        createAction(actions.RECEIVE_CONTRACTOR_AUDITS, p => p, metaGenerator)(
          payload,
        ),
      );
    });
};
