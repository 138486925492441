import types from "resources/types";
import { mutateResourceQuery } from "queries";

export const postCompetencyQuestionResponsesQuery = data =>
  mutateResourceQuery(
    types.COMPETENCY_QUESTION_RESPONSES,
    {
      url: "/api/v2/competency_question_responses/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const startFitScoreCalculationQuery = data =>
  mutateResourceQuery(
    types.COMPETENCY_QUESTION_RESPONSES,
    {
      url: "/api/v2/fitscores/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const updateDemographicDataQuery = data =>
  mutateResourceQuery(
    types.OWN_CONTRACTOR,
    {
      url: "/api/dev/contractors/self/",
      options: {
        method: "PATCH",
      },
    },
    data,
  );
