import { listToObject } from "admin/utils";
import createReducer from "common/reducers/utils";

const handleReceivejobDetails = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    ...listToObject(
      action.payload.results ? action.payload.results : [action.payload],
      "uuid",
    ),
  },
});

const handlers = {
  "RECEIVE/jobDetails": handleReceivejobDetails,
};

const jobDetailsSet = createReducer("jobDetailsSet", null, handlers);

export { jobDetailsSet };
