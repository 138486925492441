import { fetchResourceWithDateRange, listToObject } from "admin/utils/index";

export const REQUEST_FIXED_RATES = "REQUEST_FIXED_RATES";
export const RECEIVE_FIXED_RATES = "RECEIVE_FIXED_RATES";

export function requestFixedRates() {
  return {
    type: REQUEST_FIXED_RATES,
  };
}

export function receiveFixedRates(json) {
  return {
    type: RECEIVE_FIXED_RATES,
    receivedAt: Date.now(),
    items: listToObject(json.results, "uuid"),
  };
}

export function fetchFixedRatesIfNeeded(startDate, endDate) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.admin.invoicing.fixedRates.isFetching) {
      dispatch(requestFixedRates());
      return fetchResourceWithDateRange(
        startDate,
        endDate,
        "fixed_rate_occurrences",
      ).then(json => dispatch(receiveFixedRates(json)));
    }
    return undefined;
  };
}
