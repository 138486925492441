import React from "react";
import {
  Badge,
  ListGroupItem,
  OverlayTrigger,
  Panel,
  Popover,
} from "react-bootstrap";
import { Link } from "react-router-dom";

export const PanelHeader = ({ count, title, bsStyle = null }) => (
  <Panel.Heading>
    <Panel.Title toggle>
      <Badge className={bsStyle ? `badge-${bsStyle}` : ""}>{count}</Badge>
      &nbsp;{title}
    </Panel.Title>
  </Panel.Heading>
);

export const workerNotActiveTooltip = (
  <Popover id="worker-not-active-tooltip" title="Inactive">
    You have been marked inactive on this position by your administrator. You
    will not be able to log work for this job until your administrator
    designates you as active.
  </Popover>
);

export const LinkIfWorkerActive = ({ active, to, tooltip, children }) =>
  active ? (
    <Link to={to}>{children}</Link>
  ) : (
    <OverlayTrigger
      overlay={tooltip}
      trigger={["click", "hover"]}
      placement="bottom"
    >
      <span className="text-muted">{children}</span>
    </OverlayTrigger>
  );

export const ViewPaymentHistoryLinkItem = () => (
  <ListGroupItem className="text-right">
    <Link to="/contractor/settings/payment-history">View payment history</Link>
  </ListGroupItem>
);

export const JobWorkerListItem = props => {
  const { ActionListComponentClass, jobListing, ...otherProps } = props;
  return (
    <ListGroupItem>
      <div>{jobListing.title}</div>
      <ActionListComponentClass job={jobListing.job} {...otherProps} />
    </ListGroupItem>
  );
};

export const JobWorkersList = props => {
  const {
    jobListings,
    jobWorkers,
    paymentDetails: allPaymentDetails,
    panelType,
    ComponentClass,
  } = props;
  return jobWorkers.map(jobWorker => {
    const jobListing = Object.values(jobListings).find(
      jl => jl.job === jobWorker.job,
    );
    const paymentDetails = Object.values(allPaymentDetails).find(
      pd => pd.job === jobWorker.job,
    );
    const isValid = jobWorker && jobListing && paymentDetails;
    return !isValid ? null : (
      <JobWorkerListItem
        key={`${panelType}-job-${jobWorker.uuid}`}
        jobListing={jobListing}
        jobWorker={jobWorker}
        paymentDetails={paymentDetails}
        ActionListComponentClass={ComponentClass}
      />
    );
  });
};
