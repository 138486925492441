import React, { Component } from "react";
import { getQueryKey } from "redux-query";
import { createStructuredSelector } from "reselect";

import Onboard from "../../blocks";
import FormModal from "./FormModal";
import NextButton from "../../NextButton";
import Loading from "common/components/Loading";
import { MobileEntries, DesktopEntries } from "./Entries";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import {
  getActionIsRequired,
  getCustomErrorMsg,
  getCanMoveOnToNextStep,
  getVerifIsPending,
  redirectToNextStepIfNeeded,
} from "../../utils";
import { getResourcesAreReady } from "common/utils/helpers";
import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { createResourceSelectorConfig } from "queries";
import { firmTypesQuery } from "queries/requests/firmTypes";
import { deletePreviousEmploymentQuery } from "queries/mutations/previousEmployments";
import { previousEmploymentsQuery } from "queries/requests/workExperience";
import { positionTypesQuery } from "queries/requests/positionTypes";
import { tagsQuery } from "queries/requests/tags";
import types from "resources/types";

const stepName = "work_experience";
const requiredResources = [
  types.FIRM_TYPES,
  types.PREVIOUS_EMPLOYMENTS,
  types.POSITION_TYPES,
  types.TAGS,
];

class WorkExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingPrevEmployment: null,
      nextButtonLoading: false,
      showFormModal: false,
    };
  }

  handleDeleteClick = uuid => {
    const {
      deletePreviousEmployment,
      refreshPreviousEmployments,
      refreshReqs,
    } = this.props;
    deletePreviousEmployment(uuid).then(() => {
      refreshPreviousEmployments();
      refreshReqs();
    });
  };

  handleEditClick = editingPrevEmployment =>
    this.setState({ showFormModal: true, editingPrevEmployment });

  handleHide = () =>
    this.setState({ showFormModal: false, editingPrevEmployment: null });

  handleFooterClick = () =>
    this.setState({ showFormModal: true, editingPrevEmployment: null });

  getPrevEmployments = prevEmploymentsData => {
    const {
      [getQueryKey(previousEmploymentsQuery())]: prevEmploymentsQueries,
    } = this.props.previousEmployments.queries;
    let prevEmployments = Object.values(prevEmploymentsData);
    if (prevEmploymentsQueries) {
      prevEmployments = prevEmployments.filter(prevEmployment =>
        prevEmploymentsQueries.data.includes(prevEmployment.uuid),
      );
    }
    return prevEmployments;
  };

  handleNextClick = () => {
    this.setState({ nextButtonLoading: true }, () => {
      redirectToNextStepIfNeeded(
        stepName,
        this.props.refreshReqs,
        this.stopButtonLoading,
      );
    });
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: { editingPrevEmployment, nextButtonLoading, showFormModal },
      props: {
        firmTypes: { data: firmTypesData },
        ownContractor,
        previousEmployments: { data: prevEmploymentsData },
        positionTypes: { data: positionTypesData },
        refreshPreviousEmployments,
        refreshReqs,
        reqs: { steps },
        tags: { data: tagsData },
      },
      getPrevEmployments,
      handleDeleteClick,
      handleEditClick,
      handleHide,
      handleFooterClick,
      handleNextClick,
    } = this;
    const isReady = getResourcesAreReady(requiredResources, this.props);
    const firmTypes = Object.values(firmTypesData);
    const positionTypes = Object.values(positionTypesData);
    const prevEmployments = getPrevEmployments(prevEmploymentsData);
    const tags = Object.values(tagsData);
    const entriesProps = {
      actionIsRequired: getActionIsRequired(stepName, steps),
      customErrorMsg: getCustomErrorMsg(stepName, steps),
      onDeleteClick: handleDeleteClick,
      onEditClick: handleEditClick,
      onFooterClick: handleFooterClick,
      prevEmployments,
      verifIsPending: getVerifIsPending(stepName, steps),
    };

    return isReady ? (
      <Onboard.Page>
        <DesktopOnly>
          <DesktopEntries {...entriesProps} />
        </DesktopOnly>
        <MobileOnly>
          <MobileEntries {...entriesProps} />
        </MobileOnly>
        <NextButton
          disabled={!getCanMoveOnToNextStep(stepName, steps)}
          float="right"
          isLoading={nextButtonLoading}
          onClick={handleNextClick}
        />
        <FormModal
          editingPrevEmployment={editingPrevEmployment}
          firmTypes={firmTypes}
          show={showFormModal}
          onHide={handleHide}
          ownContractor={ownContractor}
          positionTypes={positionTypes}
          refreshPreviousEmployments={refreshPreviousEmployments}
          refreshReqs={refreshReqs}
          tags={tags}
        />
      </Onboard.Page>
    ) : (
      <Loading />
    );
  }
}

const mapPropsToConfig = () => [
  firmTypesQuery(),
  previousEmploymentsQuery(),
  positionTypesQuery(),
  tagsQuery(),
];

const firmTypesSelectorConfig = createResourceSelectorConfig(
  types.FIRM_TYPES,
  firmTypesQuery,
);

const previousEmploymentsSelectorConfig = createResourceSelectorConfig(
  types.PREVIOUS_EMPLOYMENTS,
  previousEmploymentsQuery,
);

const positionTypesSelectorConfig = createResourceSelectorConfig(
  types.POSITION_TYPES,
  positionTypesQuery,
);

const tagsSelectorConfig = createResourceSelectorConfig(types.TAGS, tagsQuery);

const mapStateToProps = createStructuredSelector({
  ...firmTypesSelectorConfig,
  ...previousEmploymentsSelectorConfig,
  ...positionTypesSelectorConfig,
  ...tagsSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  deletePreviousEmployment: uuid =>
    dispatch(mutateAsync(deletePreviousEmploymentQuery(uuid))),
  refreshPreviousEmployments: () =>
    dispatch(requestAsync(previousEmploymentsQuery({ force: true }))),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(WorkExperience);
