import React from "react";

import { ControlLabel, FormGroup } from "react-bootstrap";
import FieldHelpBlock from "./FieldHelpBlock";
import RatingInput from "./RatingInput";

import { domOnlyProps, fieldValidationState } from "common/utils/forms";

export const OldRatingInputGroup = ({ field, label }) => (
  <FormGroup validationState={fieldValidationState(field)}>
    {label && <ControlLabel>{label}</ControlLabel>}
    <RatingInput {...domOnlyProps(field)} />
    <FieldHelpBlock {...field} />
  </FormGroup>
);

export const RatingInputGroup = props => {
  const { input, meta, bsSize, label, ...otherProps } = props;
  return (
    <FormGroup validationState={fieldValidationState(meta)} bsSize={bsSize}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <RatingInput {...domOnlyProps(input)} {...domOnlyProps(otherProps)} />
      <FieldHelpBlock touched={meta.touched} error={meta.error} />
    </FormGroup>
  );
};
