import React, { Component } from "react";
import { history as browserHistory } from "app";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connectRequest } from "redux-query-react";

import Loading from "common/components/Loading";
import { connect, compose } from "resources/components/utils";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";

const NoRecommendationsForJob = ({ jobName }) => (
  <div>
    <h2>
      Recommended Candidates <small>{`for ${jobName}`}</small>
    </h2>
    <div style={{ marginBottom: "3rem" }}>
      <p>
        We weren&apos;t able to find any recommended candidates yet. Rest
        assured, we are actively looking for candidates that will meet your
        needs. (You might see this message if you&apos;ve already invited or
        dismissed all available recommendations.)
      </p>
    </div>
    <div style={{ textAlign: "center" }}>
      <LinkContainer to="/agency/">
        <Button bsStyle="info">Go to Dashboard</Button>
      </LinkContainer>
    </div>
  </div>
);

export class Recommendations extends Component {
  componentDidMount() {
    this.redirectToNextRecommendation(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.redirectToNextRecommendation(nextProps);
  }

  redirectToNextRecommendation(props) {
    const {
      recommendations: {
        lastUpdated,
        data,
        query: { data: queryData },
      },
      location: { search },
      match: {
        params: { id },
      },
    } = props;

    const recommendations = queryData.map(id => data[id]);

    if (lastUpdated && Object.values(recommendations).length > 0) {
      const firstContractorId = Object.values(recommendations)[0].contractor
        .uuid;
      browserHistory.push(
        `/agency/jobs/create/${id}/recommended_candidates/${firstContractorId}/${search}`,
      );
    }
  }

  render() {
    const {
      jobs: {
        isPending: jobsPending,
        lastUpdated: jobsUpdated,
        data,
        query: { data: queryData },
      },
      recommendations: {
        isPending: recommendationsPending,
        lastUpdated: recommendationsUpdated,
        query: { count: recommendationsCount },
      },
    } = this.props;
    const job = data[queryData[0]];

    const isPending = recommendationsPending || jobsPending;
    const lastUpdated = recommendationsUpdated && jobsUpdated;
    const recommendationsExist = recommendationsCount > 0;

    return (
      <div>
        {isPending ? (
          <Loading />
        ) : (
          lastUpdated &&
          !recommendationsExist && (
            <NoRecommendationsForJob jobName={job.formattedTitle} />
          )
        )}
      </div>
    );
  }
}

const jobsQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.match.params.id}/`,
    force: true,
  });

const recommendationsQuery = props =>
  createResourceListQuery(types.RECOMMENDATIONS, {
    url: `/api/dev/recommendations/?job=${props.match.params.id}&limit=999`,
  });

const mapPropsToConfig = props => [
  jobsQuery(props),
  recommendationsQuery(props),
];

const jobsConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);
const recommendationsConfig = createResourceSelectorConfig(
  types.RECOMMENDATIONS,
  recommendationsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...jobsConfig,
  ...recommendationsConfig,
});

Recommendations = compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(Recommendations);

export default Recommendations;
