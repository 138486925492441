import React, { Component } from "react";
import { history as browserHistory } from "app";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { addNotification } from "reapop";
import {
  destroy,
  formValueSelector,
  submit,
  SubmissionError,
} from "redux-form";
import { mutateAsync, requestAsync } from "redux-query";

import { ErrorBoundary } from "common/components/error-boundary";
import { createErrorNotification } from "common/utils/notifications";
import { OpacityReplaceTransition } from "common/utils/transitions";
import {
  formName,
  paymentDetailsComponents,
} from "hiringagent-dashboard/components/jobs/payment-details/utils";
import { createResourceDetailQuery, mutateResourceQuery } from "queries";
import { connect } from "resources/components/utils";
import types from "resources/types";

const hiddenStyle = {
  visibility: "hidden",
};

const getAllFieldNames = components =>
  components.reduce((prev, curr) => [...prev, ...curr.fields], []);

export class PaymentDetailsStep extends Component {
  componentDidMount() {
    this.checkStepWithinRange();
    this.goToStartIfValuesNeeded();
  }

  UNSAFE_componentWillReceiveProps() {
    this.checkStepWithinRange();
  }

  checkStepWithinRange = () => {
    const currentStep = this.getCurrentStep();
    const lastStep = paymentDetailsComponents.length - 1;
    if (currentStep > lastStep) {
      browserHistory.push(this.getCompletedUrl());
    }
    if (currentStep < 0) {
      browserHistory.push(this.getStepUrl(0));
    }
  };

  getCompletedUrl = () =>
    `/agency/jobs/payment_details/${this.props.job.uuid}/completed/`;
  getStepUrl = step =>
    `/agency/jobs/payment_details/${this.props.job.uuid}/step/${step}/`;

  // TODO: Find the first page that has an error rather than getting the page of
  // first error that comes back in the response
  getComponentWithErrorStep = errors => {
    const firstComponentIndex = 0;
    const firstErrorField = Object.keys(errors)[0];

    const errorComponentIndex = paymentDetailsComponents.findIndex(comp =>
      comp.fields.includes(firstErrorField),
    );
    return errorComponentIndex === -1
      ? firstComponentIndex
      : errorComponentIndex;
  };

  getComponentAtStep = () =>
    paymentDetailsComponents[this.getCurrentStep()].component;

  getCurrentStep = () => Number.parseInt(this.props.match.params.step, 10);

  getPreviousStepUrl = () => {
    const {
      match: {
        params: { step },
      },
    } = this.props;
    const currentStep = Number.parseInt(step, 10);
    const previousStep = currentStep - 1;
    if (previousStep < 0) {
      return this.getStepUrl(currentStep);
    }
    return this.getStepUrl(previousStep);
  };

  getNextStepUrl = () => {
    const {
      match: {
        params: { step },
      },
    } = this.props;
    const currentStep = Number.parseInt(step, 10);
    const nextStep = currentStep + 1;
    const lastStep = paymentDetailsComponents.length - 1;
    if (nextStep > lastStep) {
      return this.getCompletedUrl();
    }
    return this.getStepUrl(nextStep);
  };

  goToStartIfValuesNeeded = () => {
    const {
      currentValues,
      match: {
        params: { step },
      },
    } = this.props;
    if (step > 0 && !Object.keys(currentValues).length) {
      this.goToStepPage(0);
    }
  };

  goToNextStep = () => {
    browserHistory.push(this.getNextStepUrl());
  };

  goToStepPage = (step = 0) => {
    browserHistory.push(
      `/agency/jobs/payment_details/${this.props.job.uuid}/step/${step}/`,
    );
  };

  handleNextClick = e => {
    e.preventDefault();
    return this.props.submitForm(formName);
  };

  handleSubmit = values => {
    const currentStep = Number.parseInt(this.props.match.params.step, 10);
    const nextStep = currentStep + 1;
    const lastStep = paymentDetailsComponents.length - 1;
    if (nextStep > lastStep) {
      return this.handleSubmitFormData(values);
    }
    return this.goToNextStep();
  };

  handleSubmitFormData = values => {
    const { destroyForm, job, notify, refreshJob, submitFormData } = this.props;
    const valuesWithJob = { ...values, job: job.uuid };
    return submitFormData(valuesWithJob).then(({ status, body }) => {
      if (status >= 200 && status < 300) {
        destroyForm();
        refreshJob();
        return;
      }
      let message = "Please try again. If problem persists, contact support.";
      if (status === 400) {
        message = "Please fix the errors below.";
      }
      if (status === 500) {
        message =
          "There was a problem submitting your request. Please refresh this page and try again.";
      }
      notify(
        createErrorNotification({
          message,
        }),
      );
      if (body.hasOwnProperty("non_field_errors")) {
        body._error = body.non_field_errors;
        delete body.non_field_errors;
      }
      throw new SubmissionError(body);
    });
  };

  handleSubmitFail = errors => {
    const stepWithError = this.getComponentWithErrorStep(errors);
    this.goToStepPage(stepWithError);
  };

  handleSubmitSuccess = () => {
    this.goToNextStep();
  };

  render() {
    const { agencyRates, job, paymentDetails = {} } = this.props;
    const currentStep = this.getCurrentStep();
    const paymentDetailsComponent = this.getComponentAtStep();
    const progressPercent =
      (currentStep / paymentDetailsComponents.length) * 100;
    const submitButtonText =
      currentStep === paymentDetailsComponents.length - 1 ? (
        "Finish"
      ) : (
        <span>Next Step &rarr;</span>
      );

    let paymentDetailsStepComponent;
    if (paymentDetailsComponent) {
      const ComponentClass = paymentDetailsComponent;
      const formProps = {
        onSubmit: this.handleSubmit,
        onSubmitSuccess: this.handleSubmitSuccess,
        onSubmitFail: this.handleSubmitFail,
      };
      const initialValues = { ...paymentDetails, ...agencyRates };

      paymentDetailsStepComponent = (
        <div
          key={`create-job-form-component-${currentStep}`}
          style={{ minHeight: "30rem" }}
        >
          <ComponentClass
            job={job}
            initialValues={initialValues}
            formProps={formProps}
          />
        </div>
      );
    }

    return (
      <div>
        <div className="form-container">
          <Row style={{ margin: "4rem 0" }}>
            <Col lg={6} lgOffset={3}>
              {paymentDetailsComponent && (
                <ProgressBar
                  now={progressPercent}
                  label={`${progressPercent}%`}
                  srOnly
                  style={{ margin: 0 }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={10} lgOffset={1}>
              <div className="form-step">
                <ErrorBoundary>
                  <OpacityReplaceTransition>
                    {paymentDetailsStepComponent && paymentDetailsStepComponent}
                  </OpacityReplaceTransition>
                </ErrorBoundary>
              </div>
            </Col>
          </Row>
        </div>
        <div className="text-center">
          <LinkContainer to={this.getPreviousStepUrl()}>
            <Button bsStyle="link" style={currentStep > 0 ? {} : hiddenStyle}>
              &larr; Back
            </Button>
          </LinkContainer>{" "}
          <Button bsStyle="info" onClick={this.handleNextClick}>
            {submitButtonText}
          </Button>
        </div>
      </div>
    );
  }
}

const jobQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.job.uuid}/`,
    force: true,
  });

const createPaymentDetails = data =>
  mutateResourceQuery(
    types.PAYMENT_DETAILS,
    {
      url: "/api/v2/payment_details/",
      options: {
        method: "POST",
      },
    },
    data,
  );

const updatePaymentDetails = (props, data) =>
  mutateResourceQuery(
    types.PAYMENT_DETAILS,
    {
      url: `/api/v2/payment_details/${props.paymentDetails.uuid}/`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );

const createOrUpdatePaymentDetails = (props, data) =>
  props.paymentDetails
    ? updatePaymentDetails(props, data)
    : createPaymentDetails(data);

const formValues = formValueSelector(formName);

export default connect(
  state => ({
    currentValues: formValues(
      state,
      ...getAllFieldNames(paymentDetailsComponents),
    ),
  }),
  (dispatch, props) => ({
    destroyForm: () => dispatch(destroy(formName)),
    notify: message => dispatch(addNotification(message)),
    refreshJob: () => dispatch(requestAsync(jobQuery(props))),
    submitForm: formName => dispatch(submit(formName)),
    submitFormData: data =>
      dispatch(mutateAsync(createOrUpdatePaymentDetails(props, data))),
  }),
)(PaymentDetailsStep);
