import moment from "moment";

export default class JobListing {
  // uuid: string
  // hiring_agency: string
  // posting_agent: string
  // primary_practice_area: ?string
  // position_type: ?string
  // title: ?string
  // description: ?string
  // remote: boolean
  // allowed_applicants: ?string
  // min_years_experience: ?number
  // max_years_experience: ?number
  // contract_type: ?string
  // min_estimated_rate: ?number
  // max_estimated_rate: ?number
  // num_openings: ?number
  // hours_per_week: ?number
  // project_length: ?number
  // searchable: boolean
  // published: boolean
  // published_date: ?number
  // expertisearea_set: Array<Object>
  // joblistinglocation_set: Array<Object>
  // bar_requirements: Array<Object>
  // joblistinglanguage_set: Array<Object>
  // primarypracticearearequirement_set: Array<Object>
  // job: ?string
  // created: number
  // updated: numbner

  static ALLOWED_APPLICANTS = {
    ATTORNEYS_ONLY: "attorneys",
    NON_ATTORNEYS_ONLY: "non_attorneys",
    ANY: "any",
  };
  static experienceOptions = [
    { value: "entry", label: "Entry-level", min: 0, max: 2 },
    { value: "junior", label: "Junior", min: 2, max: 4 },
    { value: "mid", label: "Mid-level", min: 4, max: 7 },
    { value: "senior", label: "Senior", min: 7, max: 50 },
    { value: "any", label: "Any", min: 0, max: 50 },
  ];
  static projectLengthOptions = {
    0: "< 1 month",
    1: "1-3 months",
    3: "3-6 months",
    6: "6-12 months",
    12: "12+ months",
  };
  static projectLengthOrdering = [0, 1, 3, 6, 12];
  static hoursPerWeekOptions = {
    "-1": "One time project",
    10: "Limited (0-15 hours per week)",
    25: "Part Time (16-34 hours per week)",
    40: "Full time (35+ hours per week)",
  };
  static hoursPerWeekOrdering = [-1, 10, 25, 40];
  static STATUS = {
    DRAFT: 0,
    OPEN_FOR_APPLICATIONS: 1,
    FILLED: 2,
    CLOSED: 3,
  };
  static STATUS_OPTIONS = [
    { label: "Draft", value: JobListing.STATUS.DRAFT },
    {
      label: "Open for applications",
      value: JobListing.STATUS.OPEN_FOR_APPLICATIONS,
    },
    { label: "Filled", value: JobListing.STATUS.FILLED },
    { label: "Closed", value: JobListing.STATUS.CLOSED },
  ];

  constructor(job) {
    Object.keys(job).forEach(attr => {
      // TODO: This causes `Flow: assignment of computed property/element. Indexable signature not found in JobListing`
      this[attr] = job[attr];
    });
  }

  get allowsAttorneysOnly() {
    return (
      this.allowed_applicants === JobListing.ALLOWED_APPLICANTS.ATTORNEYS_ONLY
    );
  }

  get allowsNonAttorneysOnly() {
    return (
      this.allowed_applicants ===
      JobListing.ALLOWED_APPLICANTS.NON_ATTORNEYS_ONLY
    );
  }

  get allowsAttorneysAndNonAttorneys() {
    return this.allowed_applicants === JobListing.ALLOWED_APPLICANTS.ANY;
  }

  get allowedApplicantsDisplay() {
    switch (this.allowed_applicants) {
      case "attorneys":
        return "Attorneys";
      case "non_attorneys":
        return "Non-attorney legal professionals";
      case "any":
        return "Attorneys and non-attorney legal professionals";
      default:
        return "None";
    }
  }

  get estimatedRateDisplay() {
    const { max_estimated_rate: max, min_estimated_rate: min } = this;

    if (max && min) {
      return `$${min} - $${max} /hr`;
    } else if (max) {
      return `< $${max} /hr`;
    } else if (min) {
      return `$${min}+ /hr`;
    }

    return `None specified`;
  }

  get experienceDisplay() {
    const { max_years_experience: max, min_years_experience: min } = this;
    let experienceRange = this.experienceRange;

    const experienceOption = JobListing.experienceOptions.find(
      option => option.max === max && option.min === min,
    );

    if (experienceOption) {
      if (experienceRange) {
        return `${experienceOption.label} (${experienceRange})`;
      }
      return experienceOption.label;
    }

    return experienceRange;
  }

  get experienceRange() {
    const { max_years_experience: max, min_years_experience: min } = this;
    let descriptor = null;
    if (max === 50) {
      if (min > 0) {
        descriptor = `${min}+ years experience`;
      }
    } else if (max > 0 && max <= 50 && min >= 0) {
      descriptor = `${min}-${max} years experience`;
    }
    return descriptor;
  }

  get formattedTitle() {
    const { created, title } = this;
    if (title != null) return title;
    return `<Draft job created on ${moment(created).format("MM-DD-YYYY")}>`;
  }

  get projectLengthDisplay() {
    return JobListing.projectLengthOptions[this.project_length];
  }

  get statusDisplay() {
    const statusOption = JobListing.STATUS_OPTIONS[this.status];
    if (statusOption) return statusOption.label;
    return "";
  }
}
