import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createSelector } from "reselect";

import { listToObject } from "admin/utils";
import {
  addTagToEmployment,
  markEmploymentAsReviewed,
  requestNextPageOfUntaggedEmployments,
  requestUntaggedPreviousEmployments,
} from "admin/actions/previousEmploymentTagManager";
import { withFixture } from "hiringagent-dashboard/connectors";
import Loading from "common/components/Loading";
import { createApiUrl } from "utils";

import {
  Button,
  ButtonToolbar,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from "react-bootstrap";

const previousEmploymentTagManagerSelector = createSelector(
  state => state.admin.previousEmploymentTagManager,
  slice => ({ previousEmploymentTagManager: slice }),
);

const reviewPopOver = (
  <Popover id="review-button-popover" title="Mark as Reviewed">
    Clicking this button will mark the employment record as reviewed and remove
    it from this list.
  </Popover>
);

class TagManagerTableRow extends Component {
  render() {
    const { props } = this;
    const { onPreviousEmploymentTagClick } = this.props;

    return (
      <tr>
        <td>
          <a
            href={createApiUrl(
              `/hae_admin/contractor/contractor/${props.contractor_id}/change/`,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.first_name} {props.last_name}
          </a>
        </td>
        <td>{props.job_title}</td>
        <td>{props.other_company}</td>
        <td style={{ whiteSpace: "nowrap" }}>{props.start_date}</td>
        <td style={{ whiteSpace: "nowrap" }}>{props.end_date}</td>
        <td>
          <ButtonToolbar>
            {props.found_tags.map(tagId => (
              <Button
                key={`tag-button-${props.uuid}-${tagId}`}
                bsSize="sm"
                bsStyle={props.tags.includes(tagId) ? "success" : "default"}
                style={{ marginBottom: "0.5rem" }}
                onClick={() => onPreviousEmploymentTagClick(props.uuid, tagId)}
              >
                <i className="far fa-plus" />&nbsp;{
                  props.tagOptions[tagId].name
                }
              </Button>
            ))}
          </ButtonToolbar>
        </td>
        <td>
          <Button
            bsSize="sm"
            onClick={() => props.onMarkReviewClick(props.uuid)}
          >
            <i className="far fa-check" />&nbsp;Mark as Reviewed
          </Button>
        </td>
      </tr>
    );
  }
}

const ConnectedTagManagerTableRow = connect(
  null,
  {
    onMarkReviewClick: markEmploymentAsReviewed,
    onPreviousEmploymentTagClick: addTagToEmployment,
  },
)(TagManagerTableRow);

const TagManagerDescriptionRow = props => (
  <tr>
    <td />
    <td colSpan="5">
      <p style={{ maxWidth: "80rem" }}>{props.description}</p>
    </td>
  </tr>
);

class PreviousEmploymentTagManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDescriptions: true,
    };
    this.handleToggleDescriptionsClick = this.handleToggleDescriptionsClick.bind(
      this,
    );
  }

  componentDidMount() {
    this.props.onLoad();
  }

  handleToggleDescriptionsClick() {
    this.setState({ showDescriptions: !this.state.showDescriptions });
  }

  render() {
    const {
      onNextPageClick,
      fixtures: {
        tags: { isLoading: tagsLoading, lastUpdated: tagsUpdated, options },
      },
      previousEmploymentTagManager: {
        isLoading,
        lastUpdated,
        count,
        employments,
        next,
      },
    } = this.props;
    const { showDescriptions } = this.state;

    const loading = tagsLoading || isLoading;
    const updated = tagsUpdated && lastUpdated;
    const tagOptions = tagsUpdated && listToObject(options, "uuid");

    return (
      <div>
        <h1>Previous Employment Tag Manager</h1>
        <Row style={{ marginBottom: "1rem" }}>
          <Col md={3}>
            <ButtonToolbar>
              <Button onClick={this.handleToggleDescriptionsClick}>
                <i
                  className={`far fa-eye${showDescriptions ? "-slash" : ""}`}
                />&nbsp;
                {showDescriptions ? "Hide " : "Show "}descriptions
              </Button>
            </ButtonToolbar>
          </Col>
          <Col md={9}>
            {count > 0 && (
              <div className="text-right" style={{ marginBottom: "1rem" }}>
                Number of Untagged Employments: {count} (showing{" "}
                {Object.keys(employments).length})
              </div>
            )}
          </Col>
        </Row>
        <div>
          <Table>
            <thead>
              <tr>
                <th>Contractor</th>
                <th>Title</th>
                <th>Company</th>
                <th>Start</th>
                <th>End</th>
                <th>Found Tags</th>
                <th style={{ whiteSpace: "nowrap" }}>
                  Review&nbsp;
                  <OverlayTrigger
                    trigger={["hover", "click"]}
                    placement="left"
                    overlay={reviewPopOver}
                  >
                    <i className="far fa-info-circle" />
                  </OverlayTrigger>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                updated &&
                Object.values(employments).map(employment => [
                  <ConnectedTagManagerTableRow
                    key={`employment-table-row-${employment.uuid}`}
                    tagOptions={tagOptions}
                    {...employment}
                  />,
                  showDescriptions && (
                    <TagManagerDescriptionRow
                      key={`employment-description-row=${employment.uuid}`}
                      description={employment.description}
                    />
                  ),
                ])}
            </tbody>
          </Table>
        </div>
        {loading && (
          <div>
            <Loading />
          </div>
        )}
        {!loading &&
          next && (
            <div className="text-center">
              <Button onClick={onNextPageClick}>&darr;Next Page</Button>
            </div>
          )}
      </div>
    );
  }
}

const ConnectedPreviousEmploymentTagManager = compose(
  withFixture("tags"),
  connect(
    previousEmploymentTagManagerSelector,
    {
      onLoad: requestUntaggedPreviousEmployments,
      onNextPageClick: requestNextPageOfUntaggedEmployments,
    },
  ),
)(PreviousEmploymentTagManager);

export default ConnectedPreviousEmploymentTagManager;
