import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { history as browserHistory } from "app";

import AssociateSocialAccount from "hiringagent-dashboard/components/settings/AssociateSocialAccount";
import HiringAgentsSettings from "hiringagent-dashboard/components/settings/HiringAgentsSettings";
import PaymentAccountsSettings from "hiringagent-dashboard/components/settings/PaymentAccountsSettings";
import DiscountCodesSettings from "hiringagent-dashboard/components/settings/DiscountCodesSettings";
import { setVerificationAccountId } from "hiringagent-dashboard/actions/payments";
import {
  hiringAgentSelector,
  hiringAgentsSelector,
} from "hiringagent-dashboard/selectors";

const tabMapping = {
  1: "#hiring-agents",
  2: "#payment",
  3: "#discount-codes",
  4: "#connected-accounts",
};

class Settings extends Component {
  constructor(props) {
    super(props);

    const { hash, pathname } = window.location;
    let key = 1;
    let discountCode = null;

    if (hash === tabMapping[2]) {
      key = 2;
    } else if (hash === tabMapping[3]) {
      key = 3;
    } else if (hash === tabMapping[4]) {
      key = 4;
    }

    if (pathname) {
      const urlParts = pathname.split("/");
      if (urlParts.includes("bank-account") && urlParts.includes("verify")) {
        key = 2;
        this.props.setVerificationAccountId(urlParts[4]);
      } else if (urlParts.includes("discount-codes")) {
        key = 3;
        discountCode = this.props.match.params.id;
      }
    }

    this.state = { discountCode, key };
  }

  handleSelect = key => {
    this.setState({ key });
    browserHistory.replace(`/agency/settings/${tabMapping[key]}`);
  };

  render() {
    const {
      state: { discountCode },
      props: {
        hiringAgents,
        userHiringAgent,
        userHiringAgent: { referral_code },
      },
    } = this;

    return (
      <div className="settings-main-container">
        <div className="settings-header">
          <h1>
            <i className="far far fa-cog" />
            Settings
          </h1>
        </div>
        <Row>
          <Col md={12}>
            <Tabs
              id="settings-tabs"
              activeKey={this.state.key}
              onSelect={this.handleSelect}
            >
              <Tab eventKey={1} title="Hiring Agents">
                <HiringAgentsSettings
                  hiringAgents={hiringAgents}
                  userHiringAgent={userHiringAgent}
                />
              </Tab>
              <Tab eventKey={2} title="Payment Accounts">
                <PaymentAccountsSettings
                  userHiringAgent={userHiringAgent}
                  hiringAgents={hiringAgents}
                />
              </Tab>
              <Tab eventKey={3} title="Promo/Referral Codes">
                <DiscountCodesSettings
                  agentReferralCode={referral_code}
                  discountCode={discountCode}
                />
              </Tab>
              <Tab eventKey={4} title="Connected Accounts">
                <div style={{ paddingTop: "2rem" }}>
                  <AssociateSocialAccount />
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hiringAgents: hiringAgentsSelector,
  userHiringAgent: hiringAgentSelector,
});
const mapDispatchToProps = { setVerificationAccountId };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
