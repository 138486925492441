// @flow strict
import React, { Component } from "react";
import { history } from "app";

import Top from "./blocks";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

type Props = {
  empty: ?boolean,
  location: { pathname: string },
  userType: UserType,
  withSignUpButton: ?boolean,
  withSearchLink: ?boolean,
};

class TopNavHeader extends Component<Props> {
  static defaultProps = {
    empty: false,
    userType: "anonymous",
    withSignUpButton: false,
    withSearchLink: false,
  };

  render() {
    const { empty, userType, withSearchLink, withSignUpButton } = this.props;
    const {
      location: { pathname },
    } = history;

    return (
      <Top id="top-nav-header">
        <DesktopNav
          empty={Boolean(empty)}
          pathname={pathname}
          userType={userType}
          withSearchLink={Boolean(withSearchLink)}
          withSignUpButton={Boolean(withSignUpButton)}
        />
        <MobileNav
          empty={Boolean(empty)}
          pathname={pathname}
          userType={userType}
          withSearchLink={Boolean(withSearchLink)}
          withSignUpButton={Boolean(withSignUpButton)}
        />
      </Top>
    );
  }
}

export default TopNavHeader;
