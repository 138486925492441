import styled from "styled-components";

const NavList = styled.div`
  width: 100%;
  margin: 0;
  padding: 20px;

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

export default NavList;
