import React, { Component } from "react";
import { connect } from "react-redux";
import { addNotification as notify } from "reapop";
import { SubmissionError } from "redux-form";

import { submitAttorney } from "admin/actions/attorneyVerificationManager";
import NullOr from "common/components/NullOr";
import { AttorneyNotesForm } from "admin/forms";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";

class AttorneyNotes extends Component {
  handleSubmit = values => {
    const { attorney, notify, onSubmit } = this.props;
    return onSubmit(attorney.uuid, values).then(({ json, response }) => {
      if (response.ok) {
        notify(
          createSuccessNotification({
            title: "Attorney info updated",
            message: `Attorney ${json.full_name} has been saved.`,
          }),
        );
      } else {
        notify(
          createErrorNotification({
            message: `Encountered an error when trying to save info for ${attorney.full_name}. Please
                    refresh and try again.`,
          }),
        );
        throw new SubmissionError(json);
      }
    });
  };

  getInitialValues = attorney => ({
    year_first_admitted: attorney.year_first_admitted,
    notes: attorney.notes,
  });

  render() {
    const { attorney } = this.props;
    const initialValues = attorney ? this.getInitialValues(attorney) : {};

    return (
      <NullOr isNull={!attorney}>
        <AttorneyNotesForm
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
        />
      </NullOr>
    );
  }
}

export default connect(
  null,
  { onSubmit: submitAttorney, notify },
)(AttorneyNotes);
