import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Clearfix, ListGroup, ListGroupItem, Panel } from "react-bootstrap";
import moment from "moment";

import { PanelHeader } from "./utils";

const getOfferExpirationMessage = expirationDate => {
  const hasExpired = moment(expirationDate).isBefore();
  const expirationTense = hasExpired ? "Expired" : "Expires";
  return `${expirationTense} ${moment(expirationDate).fromNow()}`;
};

export const JobOfferExpiration = ({ expirationDate }) =>
  expirationDate ? (
    <span className="job-offer-expire-warning text-danger">
      <i className="far fa-fw fa-exclamation-triangle" />
      &nbsp;<span>{getOfferExpirationMessage(expirationDate)}</span>
    </span>
  ) : null;

const JobOfferListItem = props => {
  const { jobListing, jobOffer } = props;
  return (
    <ListGroupItem>
      <div>{jobListing.title}</div>
      <div>
        <Link to={`/contractor/jobs/${jobListing.job}/`}>
          <i className="far fa-newspaper" />&nbsp;Job Details
        </Link>
        <JobOfferExpiration expirationDate={jobOffer.expiration_date} />
        <Clearfix />
      </div>
    </ListGroupItem>
  );
};

class OffersPanel extends Component {
  render() {
    const { candidates, jobListings, jobOffers, ...otherProps } = this.props;
    return (
      <Panel {...otherProps}>
        <PanelHeader
          title="Offers"
          bsStyle="success"
          count={jobOffers.length}
        />
        <Panel.Collapse>
          <ListGroup>
            {jobOffers.map(jobOffer => {
              const candidate = candidates[jobOffer.candidate];
              const jobListing = Object.values(jobListings).find(
                jl => jl.job === candidate.job,
              );
              return (
                <JobOfferListItem
                  key={`job-offer-${jobOffer.uuid}`}
                  jobListing={jobListing}
                  jobOffer={jobOffer}
                />
              );
            })}
          </ListGroup>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default OffersPanel;
