import { createStore, applyMiddleware, compose } from "redux";
import { queryMiddleware } from "redux-query";
import thunkMiddleware from "redux-thunk";
import superagentInterface from "redux-query-interface-superagent";

import sentryMiddleware from "./sentryMiddleware";
import rootReducer from "reducer";
import { getEntities, getQueries } from "./utils";

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(
        queryMiddleware(superagentInterface, getQueries, getEntities),
        thunkMiddleware,
        sentryMiddleware,
      ),
    ),
  );
}
