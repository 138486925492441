// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Main from "./Main";
import NavItem from "./NavItem";
import NavTrigger from "./NavTrigger";
import NavList from "./NavList";
import Dropdown from "./Dropdown";
import DropdownContent from "./DropdownContent";
import DropdownTrigger from "./DropdownTrigger";
import DropdownImage from "./DropdownImage";
import DropdownName from "./DropdownName";
import SecondaryNavList from "./SecondaryNavList";
import SecondaryNavItem from "./SecondaryNavItem";
import Plus from "./Plus";

type Props = {};

const DesktopBlock: ComponentType<Props> = styled.nav`
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.container_bg()};
  display: none;

  ${({ theme }) => theme.media.sm`
    display: block;
  `};
`;

const Desktop = (props: Props) => <DesktopBlock {...props} />;

Desktop.Main = Main;
Desktop.NavItem = NavItem;
Desktop.NavTrigger = NavTrigger;
Desktop.NavList = NavList;
Desktop.Dropdown = Dropdown;
Desktop.DropdownContent = DropdownContent;
Desktop.DropdownTrigger = DropdownTrigger;
Desktop.DropdownImage = DropdownImage;
Desktop.DropdownName = DropdownName;
Desktop.Image = Image;
Desktop.SecondaryNavList = SecondaryNavList;
Desktop.SecondaryNavItem = SecondaryNavItem;
Desktop.Plus = Plus;

export default Desktop;
