// @flow strict
import * as React from "react";

import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { createCandidatesQuery } from "queries/requests/candidates";
import querystring from "querystring";
import types from "resources/types";

type RenderProps = {
  badge: boolean | number,
  dot: boolean,
};
export type PendingCandidatesNavRenderProps = RenderProps;

type PendingCandidatesNavProps = {
  candidates: QueryResource<Candidate>,
  render: RenderProps => React.Node,
};

const PendingCandidatesNav = (props: PendingCandidatesNavProps) => {
  const {
    candidates: {
      query: { count },
      isFinished,
      isPending,
    },
    render,
  } = props;
  let badge = 0;
  let dot = false;
  const isReady = isFinished && !isPending;
  if (isReady) {
    dot = Boolean(count);
    badge = count;
  }
  return render({ badge, dot });
};

const params = querystring.stringify({ status: 0, limit: 1 });
const mapPropsToConfig = () =>
  createCandidatesQuery({
    url: `/api/v2/candidates/?${params}`,
  });
const mapStateToProps = createResourceSelector(
  types.CANDIDATES,
  mapPropsToConfig,
);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  PendingCandidatesNav,
);
