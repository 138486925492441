import actions from "hiringagent-dashboard/actions/onboarding";
import sidebarActions from "hiringagent-dashboard/actions/sidebar";
import { default as agencyActions } from "hiringagent-dashboard/actions/agency";
import { addOrRemoveArrayItem } from "common/utils";
import { handleActions } from "redux-actions";

const initialState = {
  isLoading: false,
  lastUpdated: null,
  showOnboarding: null,
  onboardingHiddenByUser: false,
  onboardingModal: "welcome",
  needsProfilePage: 1,
  totalNeedsProfilePages: 5,
  isModalOpen: false,
  selectedContractorTypes: [],
  selectedHiringReasons: [],
  selectedTagGroups: [],
};

const stateWithUpdatedSlice = propKey => (state, action) => ({
  ...state,
  [propKey]: addOrRemoveArrayItem(state[propKey], action.payload),
});

const onboarding = handleActions(
  {
    [actions.INCREMENT_NEEDS_PROFILE_PAGE]: state => ({
      ...state,
      needsProfilePage: state.needsProfilePage + 1,
    }),
    [actions.DECREMENT_NEEDS_PROFILE_PAGE]: state => ({
      ...state,
      needsProfilePage: Math.max(0, state.needsProfilePage - 1),
    }),
    [actions.GO_TO_NEEDS_PROFILE_PAGE]: (state, action) => ({
      ...state,
      needsProfilePage: action.payload,
    }),
    [actions.TOGGLE_NEEDS_PROFILE_MODAL]: (state, action) => ({
      ...state,
      isModalOpen: action.payload,
    }),
    [actions.REQUEST_AGENCY_NEEDS_PROFILE]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.REQUEST_UPDATE_AGENCY_NEEDS_PROFILE]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_AGENCY_NEEDS_PROFILE]: (state, action) => ({
      ...state,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
      selectedContractorTypes: [
        ...state.selectedContractorTypes,
        ...action.payload.desired_contractor_types,
      ],
      selectedHiringReasons: [
        ...state.selectedHiringReasons,
        ...action.payload.hiring_reasons,
      ],
      selectedTagGroups: [
        ...state.selectedTagGroups,
        ...action.payload.desired_tag_groups,
      ],
    }),
    [agencyActions.RECEIVE_AGENCY]: (state, action) => ({
      ...state,
      isModalOpen:
        state.isModalOpen || action.payload.onboarding_state === "Unstarted",
    }),
    [actions.SET_CONTRACTOR_TYPE]: stateWithUpdatedSlice(
      "selectedContractorTypes",
    ),
    [actions.SET_HIRING_REASON]: stateWithUpdatedSlice("selectedHiringReasons"),
    [actions.SET_TAG_GROUP]: stateWithUpdatedSlice("selectedTagGroups"),
    [actions.HIDE_ONBOARDING]: state => ({
      ...state,
      showOnboarding: false,
      onboardingHiddenByUser: true,
    }),
    // Move to sidebar reducer once other onboarding aspects are finalized
    [sidebarActions.RECEIVE_HIRING_AGENT]: (state, action) => ({
      ...state,
      showOnboarding:
        !action.error &&
        !state.onboardingHiddenByUser &&
        !action.payload.completed_onboarding,
    }),
  },
  initialState,
);
export default onboarding;
