import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import api from "common/api";

const actions = keyMirror({
  REQUEST_AUTH_STATUS: null,
  RECEIVE_AUTH_STATUS: null,
  REQUEST_LOGOUT: null,
});

export default actions;

export const requestAuthStatus = () =>
  api.get("/api/dev/hiring_agents/auth/", [
    actions.REQUEST_AUTH_STATUS,
    actions.RECEIVE_AUTH_STATUS,
  ]);

export const logout = createAction(actions.REQUEST_LOGOUT);
