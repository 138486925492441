import React from "react";
import styled from "styled-components";

import TopLabel from "./TopLabel";
import Label from "common/blocks/Label";
import Text from "./Text";
import BoldText from "./BoldText";
import Grid from "./Grid";

const NextBlock = styled.div`
  width: 100%;
`;

const Next = props => <NextBlock {...props} />;

Next.TopLabel = TopLabel;
Next.BorderedLabel = Label;
Next.Text = Text;
Next.BoldText = BoldText;
Next.Grid = Grid;

export default Next;
