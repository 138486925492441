import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ExpandableText extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    href: PropTypes.string,
    maxWords: PropTypes.number,
    text: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    style: {},
    href: "#",
    maxWords: 10,
  };

  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (this.props.href === "#") {
      e.preventDefault();
      this.setState({ expanded: !this.state.expanded });
    }
  }

  render() {
    const { href, maxWords, text, className, style } = this.props;
    const { expanded } = this.state;

    if (!text) return null;
    let textArray = text.split(" ");
    if (textArray.length === 0) return null;
    const hasMore = textArray.length > maxWords;

    if (textArray.length > this.props.maxWords) {
      if (!this.state.expanded) {
        textArray = textArray.splice(0, maxWords);
      }
    }

    const trimmedText =
      textArray.join(" ") + (hasMore && !expanded ? "..." : "");

    // TODO: dangerouslySetInnerHTML on trimmedText could end up breaking between practice areas
    return (
      <p
        className={`expandable-text ${className}`}
        style={{ whiteSpace: "pre-wrap", ...style }}
      >
        <span dangerouslySetInnerHTML={{ __html: trimmedText }} />
        &nbsp;
        {hasMore && (
          <a href={href} onClick={this.handleClick}>
            {expanded ? "Less" : "More"}
          </a>
        )}
      </p>
    );
  }
}
