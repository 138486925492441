import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formValueSelector, reduxForm } from "redux-form";
import { Button, Col, Row } from "react-bootstrap";

import {
  AgreeNotARecruiterInput,
  AgreeTermsOfServiceInput,
  EmailAddressInput,
  FirstNameInput,
  LastNameInput,
  PasswordInput,
  PhoneNumberInput,
  ReactSelectField,
  RepeatPasswordInput,
  SelectField,
} from "common/components/forms/inputs";
import { getCampaignParamsFromLocalStorage } from "common/utils";
import { validatePasswords, validateRequiredFields } from "common/utils/forms";
import {
  adminTypes,
  specialistTypes,
  nonAttorneyTypes,
} from "public/constants/nonAttorneyOptions";

const formName = "nonAttorneySignupForm";
export const requiredFields = [
  "first_name",
  "last_name",
  "phone_home",
  "email_address",
  "password",
  "repeat_password",
  "agreed_tos",
  "agreed_nar",
  "non_attorney_type",
];

const validate = values => {
  const localRequiredFields = [...requiredFields];
  const nonAttorneyTypesWithLabels = [
    nonAttorneyTypes.LEGAL_ADMINISTRATION,
    nonAttorneyTypes.LEGAL_SPECIALIST,
  ];
  if (nonAttorneyTypesWithLabels.includes(values.non_attorney_type)) {
    localRequiredFields.push("labels");
  }
  let errors = validateRequiredFields(localRequiredFields, values);
  errors = validatePasswords(values, errors);
  return errors;
};

const ToSLabel = (
  <span>
    By checking this box, you agree to the{" "}
    <Link
      to="/public/terms-of-service/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Service&nbsp;
      <i className="fas fa-external-link-alt" aria-hidden="true" />
    </Link>{" "}
    as outlined and the
    <a
      href="https://stripe.com/connect/account-terms"
      target="_blank"
      rel="noopener noreferrer"
    >
      &nbsp;Stripe Connected Account Agreement&nbsp;
      <i className="fas fa-external-link-alt" aria-hidden="true" />
    </a>
    .
  </span>
);

class NonAttorneySignupForm extends Component {
  render() {
    const { submitting, handleSubmit, non_attorney_type } = this.props;

    let labelSet = null;
    if (
      non_attorney_type &&
      non_attorney_type !== nonAttorneyTypes.NON_BARRED_LAWYER
    ) {
      labelSet =
        non_attorney_type === nonAttorneyTypes.LEGAL_ADMINISTRATION
          ? adminTypes
          : specialistTypes;
    }

    const getLabelSelectOptions = labelSet =>
      Object.values(labelSet).map(type => ({ value: type, label: type }));

    return (
      <div className="signup-form-container">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={6}>
              <FirstNameInput />
            </Col>

            <Col sm={6}>
              <LastNameInput />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <PhoneNumberInput name="phone_home" />
            </Col>
            <Col sm={6}>
              <EmailAddressInput />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <PasswordInput />
            </Col>
            <Col sm={6}>
              <RepeatPasswordInput />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <SelectField
                name="non_attorney_type"
                label="Which of these best describes your background?"
              >
                {Object.values(nonAttorneyTypes).map(choice => (
                  <option key={`non-attorney-type-${choice}`} value={choice}>
                    {choice}
                  </option>
                ))}
              </SelectField>
            </Col>
            {labelSet && (
              <Col sm={6}>
                <ReactSelectField
                  name="labels"
                  label="Which titles best describe your current or past roles?"
                  options={getLabelSelectOptions(labelSet)}
                  multi={true}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={12}>
              <AgreeTermsOfServiceInput label={ToSLabel} />
            </Col>
            <Col sm={12}>
              <AgreeNotARecruiterInput />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="signup-submit-button">
                <Button
                  type="submit"
                  bsSize="large"
                  bsStyle="info"
                  disabled={submitting}
                >
                  {submitting ? (
                    <span>
                      <i className="far fa-cog fa-spin" aria-hidden="true" />
                      &nbsp;Submitting...
                    </span>
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const formSelector = formValueSelector(formName);

const mapStateToProps = state => ({
  initialValues: {
    labels: [],
    ...getCampaignParamsFromLocalStorage(),
  },
  non_attorney_type: formSelector(state, "non_attorney_type"),
});

NonAttorneySignupForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    validate,
  }),
)(NonAttorneySignupForm);

export default NonAttorneySignupForm;
