import React from "react";
import { Link } from "react-router-dom";

import JobApplicantsTable from "./JobApplicantsTable";
import Applicants from "./blocks";

const JobApplicantsPreview = props => {
  const { job } = props;
  return (
    <Applicants.Preview>
      <h2>
        <Link to={`/agency/candidates/job/${job.uuid}/`}>{job.title}</Link>
      </h2>
      <JobApplicantsTable limit={5} job={job} preview />
    </Applicants.Preview>
  );
};

export default JobApplicantsPreview;
