// @flow strict
import React, { Component, Fragment } from "react";

import Statement from "./blocks";
import { createResourceListQuery, createResourceSelector } from "queries";
import { compose, connect, connectRequest } from "queries/utils";
import types from "resources/types";

class PersonalStatement extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { open: false };
  }

  handleClose = () => {
    const collapsible = document.querySelector("#statement-collapse");
    collapsible.style.maxHeight = null;
    this.setState({ open: false });
  };

  handleClick = () => {
    const collapsible = document.querySelector("#statement-collapse");

    if (collapsible.style.maxHeight) {
      collapsible.style.maxHeight = null;
      this.setState({ open: false }, () =>
        document
          .querySelector("#personal-statement")
          .scrollIntoView({ block: "start" }),
      );
    } else {
      collapsible.style.maxHeight = collapsible.scrollHeight + "px";
      this.setState({ open: true }, () =>
        document
          .querySelector("#personal-statement")
          .scrollIntoView({ block: "start", behavior: "smooth" }),
      );
    }
  };

  render() {
    const { open } = this.state;
    const {
      [types.APPLICATIONS]: {
        data,
        query: { data: queryData },
      },
    } = this.props;

    const application = data[queryData[0]] || {};
    const personalStatement = application.cover_letter || null;
    const isLong = personalStatement ? personalStatement.length > 500 : null;

    return !personalStatement ? null : (
      <Statement id="personal-statement">
        <h1>Personal Statement</h1>
        {isLong ? (
          <Fragment>
            <Statement.ButtonContainer>
              <Statement.Close
                visibility={open ? "visible" : "hidden"}
                onClick={this.handleClose}
              >
                Collapse
              </Statement.Close>
            </Statement.ButtonContainer>
            <Statement.Collapsible id="statement-collapse" open={open}>
              {personalStatement}
            </Statement.Collapsible>
            <Statement.ButtonContainer>
              <Statement.Toggle onClick={this.handleClick}>
                {open ? "Collapse" : "Expand"}
              </Statement.Toggle>
            </Statement.ButtonContainer>
          </Fragment>
        ) : (
          <p>{personalStatement}</p>
        )}
      </Statement>
    );
  }
}

const applicationQuery = props =>
  createResourceListQuery(types.APPLICATIONS, {
    url: `/api/v2/applications/?candidate=${props.candidateId}`,
  });
const applicationsSelector = createResourceSelector(
  types.APPLICATIONS,
  applicationQuery,
);

export default compose(
  connect(applicationsSelector),
  connectRequest(applicationQuery),
)(PersonalStatement);
