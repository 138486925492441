// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Circle from "./Circle";
import Text from "./Text";
import PercentText from "./PercentText";
import Bottom from "./Bottom";
import Footer from "./Footer";
import Alternate from "./Alternate";
import AlternateMain from "./AlternateMain";
import AlternateLink from "./AlternateLink";
import Indent from "./Indent";
import InfoCircle from "./InfoCircle";

const ScoreBlock: ComponentType<{}> = styled.div`
  width: 100%;
  margin: 0 0 50px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.mobile`
    display: grid;
    grid-template-columns: auto 1fr;
  `};
`;

const Score = (props: {}) => <ScoreBlock {...props} />;

Score.Circle = Circle;
Score.Text = Text;
Score.Bottom = Bottom;
Score.Footer = Footer;
Score.Alternate = Alternate;
Score.AlternateMain = AlternateMain;
Score.AlternateLink = AlternateLink;
Score.Indent = Indent;
Score.InfoCircle = InfoCircle;
Score.PercentText = PercentText;

export default Score;
