import actions from "../actions/applications";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  applications: {},
  statusFilters: {
    Pending: true,
    Offered: false,
    Rejected: false,
    "Offer Accepted": false,
    "Offer Declined": false,
    "Offer Expired": false,
  },
  jobFilters: {
    "All Jobs": true,
  },
  selectedJob: "All Jobs",
  sorting: "Newest First",
  sortingOptions: {
    "Newest First": "created",
    "Oldest First": "-created",
    "Alphabetical (last name)": "last_name",
  },
  allSelected: false,
  isLoading: false,
  allLoaded: false,
  lastUpdated: null,
};

const getAllApplicationsDeselected = applications => {
  const applicationsToDeselect = { ...applications };
  Object.values(applications).forEach(application => {
    application.selected = false;
  });

  return applicationsToDeselect;
};

const applications = handleActions(
  {
    [actions.RESET_APPLICATIONS]: state => {
      const allApplicationsDeselected = getAllApplicationsDeselected(
        state.applications,
      );

      return {
        ...initialState,
        applications: allApplicationsDeselected,
      };
    },
    [actions.REQUEST_APPLICATIONS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_APPLICATIONS]: (state, action) => {
      const jobFilters = {};
      action.payload.results.forEach(
        application =>
          (jobFilters[application.job.name] =
            state.jobFilters[application.job.name] || false),
      );
      const applications = listToObject(action.payload.results, "uuid");
      const allApplicationsDeselected = getAllApplicationsDeselected(
        applications,
      );

      return {
        ...state,
        applications: allApplicationsDeselected,
        lastUpdated: action.meta.receivedAt,
        isLoading: false,
        allLoaded: action.payload.allFetched,
        jobFilters: {
          ...state.jobFilters,
          ...jobFilters,
        },
      };
    },
    [actions.SET_APPLICATIONS_STATUS_FILTER]: (state, action) => {
      const newStatusFilters = {};
      Object.keys(state.statusFilters).forEach(
        filterKey =>
          (newStatusFilters[filterKey] =
            action.payload.filterName === filterKey),
      );
      const allApplicationsDeselected = getAllApplicationsDeselected(
        state.applications,
      );

      return {
        ...state,
        statusFilters: newStatusFilters,
        allSelected: false,
        applications: allApplicationsDeselected,
      };
    },
    [actions.SET_APPLICATIONS_JOB_FILTER]: (state, action) => {
      const allApplicationsDeselected = getAllApplicationsDeselected(
        state.applications,
      );

      return {
        ...state,
        selectedJob: action.payload,
        allSelected: false,
        applications: allApplicationsDeselected,
      };
    },
    [actions["RECEIVE/change_application_status"]]: (state, action) => {
      if (!action.error) {
        return {
          ...state,
          applications: {
            ...state.applications,
            [action.payload.uuid]: {
              ...state.applications[action.payload.uuid],
              status: action.payload.status,
            },
          },
          lastUpdated: action.meta.receivedAt,
          isLoading: false,
        };
      }
      return { ...state };
    },
    [actions.SET_APPLICATIONS_SORTING]: (state, action) => ({
      ...state,
      sorting: action.payload,
    }),
    [actions.TOGGLE_APPLICATION]: (state, action) => ({
      ...state,
      allSelected: false,
      applications: {
        ...state.applications,
        [action.payload]: {
          ...state.applications[action.payload],
          selected: !state.applications[action.payload].selected,
        },
      },
    }),
    [actions.TOGGLE_ALL_APPLICATIONS]: (state, action) => ({
      ...state,
      allSelected: !state.allSelected,
      applications: {
        ...state.applications,
        ...action.payload.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.uuid]: { ...curr, selected: !state.allSelected },
          }),
          {},
        ),
      },
    }),
  },
  initialState,
);

export default applications;
