import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { history as browserHistory } from "app";

import { handleSubmissionError } from "common/utils/forms";
import { signupSliceSelector } from "public/selectors";
import setCampaignParams from "common/components/setCampaignParams";
import setDocumentTitle from "common/components/setDocumentTitle";
import NonAttorneySignupForm from "public/forms/signup/NonAttorneySignupForm";
import { requestSubmitNonAttorneySignup } from "public/actions/signup";
import LegalProfSignup from "public/components/signup/LegalProfSignup";

export class NonAttorneySignup extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, dispatch) {
    return dispatch(requestSubmitNonAttorneySignup(values))
      .then(handleSubmissionError)
      .then(() => {
        browserHistory.push("/contractor/signup_complete/");
      });
  }

  render() {
    return (
      <LegalProfSignup>
        <NonAttorneySignupForm onSubmit={this.handleSubmit} />
      </LegalProfSignup>
    );
  }
}

export default compose(
  connect(signupSliceSelector),
  setDocumentTitle("Legal Professional Signup"),
  setCampaignParams,
)(NonAttorneySignup);
