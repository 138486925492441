import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, ButtonToolbar, Col, Media, Row } from "react-bootstrap";

import AttorneyProfilePicture from "../search/AttorneyProfilePicture";
import VerifiedLabel from "hiringagent-dashboard/components/utils/VerifiedLabel";
import AttorneyNameIcon from "hiringagent-dashboard/components/attorney/AttorneyNameIcon";

export default class RecommendedAttorneysPanel extends Component {
  render() {
    const {
      agency,
      expanded,
      onDismissRecommendation,
      onInviteClick,
      onToggleExpand,
      data,
    } = this.props;
    let recommendedContractors = Object.keys(data).map(key => data[key]);
    const numRecommendations = recommendedContractors.length;

    if (!expanded) {
      recommendedContractors = recommendedContractors.splice(0, 3);
    }

    return (
      <div>
        {recommendedContractors.length > 0 ? (
          <div>
            {recommendedContractors
              .filter(recommendation => !recommendation.invited)
              .map(recommendation => {
                const { contractor, job } = recommendation;
                const recommendation_link = `/agency/jobs/create/${job}/recommended_candidates/${contractor.uuid}/`;

                return (
                  <div className="line-item" key={recommendation.uuid}>
                    <Row>
                      <Col xs={12}>
                        <Media className="attorney-snippet">
                          <Media.Left>
                            <Link to={recommendation_link}>
                              <AttorneyProfilePicture
                                pictureUrl={contractor.profile_picture_url}
                                size={40}
                                lastName={contractor.last_name}
                                firstName={contractor.first_name}
                                uuid={contractor.uuid}
                              />
                            </Link>
                          </Media.Left>
                          <Media.Body className="recommended-attorney-snippet">
                            <Link to={recommendation_link}>
                              <div>
                                <AttorneyNameIcon
                                  isAttorney={contractor.is_attorney}
                                  className="attorney-title-small"
                                >
                                  {contractor.full_name}
                                </AttorneyNameIcon>
                                {recommendation.is_available && (
                                  <span className="availability-container">
                                    <VerifiedLabel
                                      type="available"
                                      className="is-available"
                                      contractorName={contractor.first_name}
                                    />
                                  </span>
                                )}
                                <span className="clearfix" />
                              </div>
                              <div>
                                Recommended for{" "}
                                {recommendation.joblisting_title}
                              </div>
                              <div className="subline">
                                {recommendation.reason}
                              </div>
                              {contractor.firm_type &&
                                contractor.firm_type.uuid ===
                                  agency.firm_type.uuid && (
                                  <div>
                                    <i className="far fa-star" />
                                    &nbsp;
                                    {contractor.first_name} has experience
                                    working with {contractor.firm_type.name}s
                                  </div>
                                )}
                            </Link>
                            <ButtonToolbar
                              style={{ marginTop: "1rem" }}
                              className="recommendation-toolbar"
                            >
                              <Button
                                bsSize="sm"
                                bsStyle="success"
                                onClick={() =>
                                  onInviteClick(recommendation.uuid)
                                }
                              >
                                Invite {contractor.first_name} to apply
                              </Button>
                              <Button
                                bsSize="sm"
                                bsStyle="link"
                                onClick={() =>
                                  onDismissRecommendation(recommendation.uuid)
                                }
                              >
                                Dismiss
                              </Button>
                            </ButtonToolbar>
                          </Media.Body>
                        </Media>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            {numRecommendations > 3 && (
              <div className="line-item text-center">
                <Row>
                  <Col xs={12}>
                    <a href="" onClick={onToggleExpand} className="expander">
                      {expanded ? "Show less" : "View all recommendations"}
                    </a>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="line-item text-center">
              <Row>
                <Col>
                  Once our system has identified strong candidates for your
                  job(s), they will be listed here.
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}
