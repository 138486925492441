import React, { Component } from "react";
import moment from "moment";

import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { formatUSD } from "common/utils/helpers";
import { withInvoices } from "hiringagent-dashboard/connectors";

export class InvoicesPanel extends Component {
  render() {
    const {
      expanded,
      invoices: { invoices },
    } = this.props;

    const sortedInvoices = Object.keys(invoices)
      .map(k => invoices[k])
      .sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateB - dateA;
      });

    // A lot of this is temporary until we have a real invoices component
    const numInvoices = sortedInvoices.length; // Grab length before we splice
    const dueInvoices = [];
    const approvedInvoices = [];
    const paidInvoices = [];
    sortedInvoices.forEach(invoice => {
      if (!invoice.paid_date && !invoice.approved_date) {
        invoice.status = (
          <span className="alert-color">
            <i
              className="far fa-exclamation-circle"
              style={{ marginRight: "0.5rem" }}
            />
            Due
          </span>
        );
        invoice.actionDate = (
          <span>{moment(invoice.due_date).format("MMM D, YYYY")}</span>
        );
        dueInvoices.push(invoice);
      } else if (!invoice.paid_date && Boolean(invoice.approved_date)) {
        invoice.status = (
          <span className="success">
            <i
              className="far fa-check-circle"
              style={{ marginRight: "0.5rem" }}
            />
            Approved
          </span>
        );
        invoice.actionDate = (
          <span>{moment(invoice.approved_date).format("MMM D, YYYY")}</span>
        );
        approvedInvoices.push(invoice);
      } else if (invoice.paid_date) {
        invoice.status = (
          <span className="success">
            <i
              className="far fa-check-circle"
              style={{ marginRight: "0.5rem" }}
            />
            Paid
          </span>
        );
        invoice.actionDate = (
          <span>{moment(invoice.paid_date).format("MMM D, YYYY")}</span>
        );
        paidInvoices.push(invoice);
      }
    });
    let bucketedInvoices = [
      ...dueInvoices,
      ...approvedInvoices,
      ...paidInvoices,
    ];

    if (!expanded) {
      bucketedInvoices = bucketedInvoices.splice(0, 2);
    }

    return (
      <div>
        {bucketedInvoices.length > 0 ? (
          <div>
            {dueInvoices.length > 0 && (
              <div className="line-item text-center">
                <Row>
                  <Col xs={12}>
                    <Link to={"/agency/invoices/"} className="line-item-alert">
                      <i className="far fa-bell fa-inline" />
                      You have {dueInvoices.length} new invoice
                      {dueInvoices.length > 1 ? "s" : ""}
                    </Link>
                  </Col>
                </Row>
              </div>
            )}
            {bucketedInvoices.map(invoice => (
              <Link
                to={`/agency/invoices/${invoice.uuid}`}
                className="line-item"
                key={invoice.uuid}
              >
                <Row>
                  <Col xs={12}>
                    <span className="pull-left">
                      Invoice #{invoice.invoice_number}
                    </span>
                    <span className="pull-right">
                      {formatUSD(invoice.total)}
                    </span>
                    <div style={{ clear: "both" }} className="subline">
                      {invoice.job.name}
                    </div>
                    <span className="pull-left">{invoice.actionDate}</span>
                    <span className="pull-right">{invoice.status}</span>
                    <div style={{ clear: "both" }} />
                  </Col>
                </Row>
              </Link>
            ))}
            {numInvoices > 2 && (
              <div className="line-item text-center">
                <Row>
                  <Col xs={12}>
                    <Link to="/agency/invoices/">View all invoices</Link>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="line-item text-center">
              <Row>
                <Col>
                  Once your project begins and contractors start billing, your
                  invoices will be listed here.
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withInvoices({ count: 3 })(InvoicesPanel);
