import React, { Component } from "react";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { compose } from "queries/utils";

import { withErrorBoundary } from "common/components/error-boundary";
import FormButton from "common/components/FormButton";
import { Error, validateRequiredFields } from "common/utils/forms/validators";
import {
  ExpertiseAreasSection,
  LocationsSection,
} from "contractor/forms/settings/job-notifications";

export const formName = "activePreferencesForm";
export const requiredFields = ["remote_positions_ok", "primary_practice_areas"];

const validate = values => {
  const {
    locationpreference_set = [],
    primary_practice_areas,
    remote_positions_ok,
    tag_group_items,
  } = values;
  const errors = validateRequiredFields(requiredFields, values);

  const validatePracticeAreas = () => {
    const errorMsg = (
      <Error>
        You must select at least one primary practice area to receive job
        notification emails.
      </Error>
    );
    errors.primary_practice_areas = { _error: errorMsg };
  };

  const validateExpertiseAreaSets = () => {
    const expertiseAreaSetsErrors = [];
    tag_group_items.forEach((areaSet, index) => {
      if (!areaSet.tags.length) {
        const errorMsg = (
          <Error>
            Select at least one area of expertise or remove this primary
            practice area.
          </Error>
        );
        expertiseAreaSetsErrors[index] = { tags: errorMsg };
      }
    });
    if (expertiseAreaSetsErrors.length) {
      errors.tag_group_items = expertiseAreaSetsErrors;
    }
  };

  const validateLocationPreferences = () => {
    const errorMsg = (
      <Error>
        You must select &qout;I&apos;m interested in remote opportunities&quot;
        and/or add at least one location to receive job notification emails.
      </Error>
    );
    errors.locationpreference_set = {
      _error: errorMsg,
    };
  };

  if (primary_practice_areas) {
    if (!primary_practice_areas.length) {
      validatePracticeAreas();
    } else {
      validateExpertiseAreaSets();
    }
  }
  if (
    !remote_positions_ok &&
    locationpreference_set &&
    locationpreference_set.length < 1
  ) {
    validateLocationPreferences();
  }

  return errors;
};

const editProfileLink = <Link to="/contractor/profile/edit">click here</Link>;

const EditPreferencesNote = () => (
  <div style={{ marginTop: "1rem" }}>
    <small style={{ display: "block" }}>
      Saved changes on this page only affect your email preferences.
    </small>
    <small>To update your profile, {editProfileLink}.</small>
  </div>
);

class ActivePreferencesForm extends Component {
  render() {
    const {
      intitalSubscriptionStatus,
      handleSubmit,
      pristine,
      submitting,
      valid,
    } = this.props;

    const disableForm = intitalSubscriptionStatus !== 1;

    return (
      <form onSubmit={handleSubmit} className="active-preferences-form">
        <div className={disableForm ? "disabling-content" : ""}>
          <div className={disableForm ? "disabling-overlay" : ""} />
          <div className="settings-page-section">
            <h4>Primary Practice Areas</h4>
            <ExpertiseAreasSection />
          </div>
          <div className="settings-page-section">
            <h4>Locations</h4>
            <LocationsSection />
          </div>
          <div className="settings-page-section text-right">
            <FormButton
              className="text-right"
              action="save"
              submitting={submitting}
              disabled={pristine || submitting || !valid}
            />
            <EditPreferencesNote />
          </div>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: formName,
    validate,
  }),
  withErrorBoundary,
)(ActivePreferencesForm);
