import { createConnectorCreator } from "common/connectors/utils";

const createHiringAgentConnector = createConnectorCreator(
  "hiringAgentDashboard",
);

export { default as withAgency } from "hiringagent-dashboard/connectors/agency";
export {
  default as withApplications,
  withApplicationsByJobId,
} from "hiringagent-dashboard/connectors/applications";
export {
  default as withRecommendationsForJob,
} from "hiringagent-dashboard/connectors/recommendations";
export {
  default as withContractorsIfNeeded,
} from "hiringagent-dashboard/connectors/contractors";
export {
  default as withFixture,
} from "hiringagent-dashboard/connectors/fixture";
export {
  default as withHiringNeeds,
} from "hiringagent-dashboard/connectors/hiringNeeds";
export {
  default as withInvoice,
} from "hiringagent-dashboard/connectors/invoice";
export {
  default as withInvoices,
} from "hiringagent-dashboard/connectors/invoices";
export { default as withJob } from "hiringagent-dashboard/connectors/job";
export { default as withJobs } from "hiringagent-dashboard/connectors/jobs";
export {
  withMessages,
  withMessageThread,
} from "hiringagent-dashboard/connectors/messages";
export {
  default as withNotifications,
} from "hiringagent-dashboard/connectors/notifications";

export const withClioContacts = createHiringAgentConnector({
  type: "clioContacts",
  path: "proxy/clio/api/v4/contacts",
  params: { fields: "id,etag,name,custom_field_values{id,field_name,value}" },
});
export const withHiringReasons = createHiringAgentConnector({
  type: "hiring_reasons",
  verboseName: "HiringReasons",
});

export const withHaeUser = createHiringAgentConnector({
  type: "haeUser",
  verboseName: "HAEUser",
  path: "users/self",
});
