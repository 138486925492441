import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { connectRequest } from "redux-query-react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  DropdownButton,
  FormControl,
  FormGroup,
  MenuItem,
  Radio,
  Row,
  Table,
  Well,
} from "react-bootstrap";
import moment from "moment";

import Loading from "common/components/Loading";
import { AlertSystemContainer } from "hiringagent-dashboard/components/alerts";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { Job } from "types";

export const JobTitle = ({ job }) => (
  <td>
    <Link to={`/agency/jobs/${job.uuid}/`}>{job.formattedTitle}</Link>
  </td>
);

export const JobCreated = ({ job }) => (
  <td style={{ whiteSpace: "nowrap" }}>
    {moment(job.created).format("MM-DD-YYYY")}
  </td>
);

export const JobStatus = ({ job }) => <td>{job.statusDisplay}</td>;

export const JobWorkerCount = ({ job: { committed_worker_count } }) => (
  <td className="text-center">{committed_worker_count}</td>
);

export const JobsTable = ({ jobs }) => (
  <Table responsive>
    <thead>
      <tr>
        <th>Title</th>
        <th>Date Created</th>
        <th className="text-center">Workers on Job</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {jobs.map(job => (
        <tr key={job.uuid}>
          <JobTitle job={job} />
          <JobCreated job={job} />
          <JobWorkerCount job={job} />
          <JobStatus job={job} />
        </tr>
      ))}
    </tbody>
  </Table>
);

const createInitialFilters = () => ({
  hasWorkers: null,
  title: "",
  status: "",
});

const sortingOptions = {
  "Date Created": "created",
  "Title (A-Z)": "title",
  "Title (Z-A)": "-title",
};

export class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: createInitialFilters(),
      sorting: "Date Created",
    };
  }

  getFilteredJobs = () => {
    const {
      jobs: { data: jobs },
    } = this.props;
    const { filters } = this.state;

    let filteredJobs = Object.values(jobs);

    if (filters.status !== "") {
      filteredJobs = filteredJobs.filter(job => job.status === filters.status);
    }

    if (filters.hasWorkers !== null) {
      filteredJobs = filteredJobs.filter(job => {
        const hasComittedWorkers = job.committed_worker_count > 0;
        return hasComittedWorkers === filters.hasWorkers;
      });
    }

    return filteredJobs.filter(job =>
      job.formattedTitle.toLowerCase().includes(filters.title.toLowerCase()),
    );
  };

  getSortedJobs = () => {
    const { sorting } = this.state;

    const filteredJobs = this.getFilteredJobs();
    let sortedJobs = filteredJobs;

    if (sorting) {
      const sortField = sortingOptions[sorting];
      if (sortField === "title" || sortField === "-title") {
        sortedJobs = filteredJobs.sort((a, b) => {
          const titleA = a.formattedTitle.toLowerCase();
          const titleB = b.formattedTitle.toLowerCase();
          if (sortField === "title") {
            if (titleA < titleB) {
              return -1;
            }
            if (titleB < titleA) {
              return 1;
            }
          } else if (sortField === "-title") {
            if (titleA < titleB) {
              return 1;
            }
            if (titleB < titleA) {
              return -1;
            }
          }
          return 0;
        });
      } else if (sortField === "created") {
        sortedJobs = sortedJobs.sort(
          (a, b) => new Date(b.created) - new Date(a.created),
        );
      }
    }
    return sortedJobs;
  };

  resetFilters = () => {
    this.setState({
      filters: createInitialFilters(),
    });
  };

  updateFilter = newFilters => {
    this.setState({
      filters: {
        ...this.state.filters,
        ...newFilters,
      },
    });
  };

  updateSorting = newSorting => {
    this.setState({
      sorting: newSorting,
    });
  };

  render() {
    const {
      jobs: {
        query: { count },
        isPending,
        lastUpdated,
      },
    } = this.props;
    const { filters, sorting } = this.state;
    const sortedFilteredJobs = this.getSortedJobs();

    return (
      <div>
        <h1>
          <i className="far fa-briefcase" />&nbsp;Jobs
        </h1>
        <Row>
          <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
            <AlertSystemContainer />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Well className="list-filters">
              <form onSubmit={e => e.preventDefault()}>
                <h3 className="list-filters-heading">Filter</h3>
                <FormGroup>
                  <ControlLabel className="title">Name</ControlLabel>
                  <FormControl
                    type="search"
                    value={filters.title}
                    onChange={e => this.updateFilter({ title: e.target.value })}
                  />
                </FormGroup>
                <ControlLabel className="title">Status</ControlLabel>
                <FormGroup>
                  <FormControl
                    componentClass="select"
                    value={filters.status}
                    onChange={e =>
                      this.updateFilter({
                        status: Number.parseInt(e.target.value, 10),
                      })
                    }
                  >
                    <option value="" />
                    {Job.STATUS_OPTIONS.map(({ label, value }) => (
                      <option key={`status-select-${label}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
                <ControlLabel className="title">Has Job Workers?</ControlLabel>
                <FormGroup>
                  <Radio
                    name="committedWorkers"
                    inline
                    checked={filters.hasWorkers === true}
                    onChange={() => this.updateFilter({ hasWorkers: true })}
                  >
                    Yes
                  </Radio>
                  <Radio
                    name="committedWorkers"
                    inline
                    checked={filters.hasWorkers === false}
                    onChange={() => this.updateFilter({ hasWorkers: false })}
                  >
                    No
                  </Radio>
                </FormGroup>
                <ButtonToolbar>
                  <Button bsStyle="info" onClick={() => this.resetFilters()}>
                    Reset
                  </Button>
                </ButtonToolbar>
              </form>
            </Well>
          </Col>
          <Col lg={9}>
            <div className="clearfix">
              <DropdownButton
                title={`Sort By: ${sorting}`}
                id="job-sort-dropdown"
              >
                {Object.keys(sortingOptions).map(option => (
                  <MenuItem
                    key={`sort-by-${option}`}
                    onClick={() => this.updateSorting(option)}
                  >
                    {sorting && sorting === option ? (
                      <strong>{option}</strong>
                    ) : (
                      option
                    )}
                  </MenuItem>
                ))}
              </DropdownButton>
              <LinkContainer className="pull-right" to="/agency/jobs/create/">
                <Button bsStyle="success">Post Job</Button>
              </LinkContainer>
              <LinkContainer
                className="pull-right manage-workers-btn"
                to="/agency/workers/"
              >
                <Button bsStyle="info">Manage Active Workers</Button>
              </LinkContainer>
            </div>
            <div className="jobs-showing-info clearfix">
              <p
                className="pull-right"
                style={{ visibility: lastUpdated ? "visible" : "hidden" }}
              >
                Showing {sortedFilteredJobs.length} out of {count} jobs
              </p>
            </div>

            <JobsTable jobs={sortedFilteredJobs} />
            {!lastUpdated && isPending && <Loading />}
            {!isPending &&
              lastUpdated &&
              sortedFilteredJobs.length < 1 && (
                <div className="no-content-message">No jobs</div>
              )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapPropsToConfig = () =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=999",
    force: true,
  });

const jobsSelector = createResourceSelector(types.JOBS, mapPropsToConfig);

export default compose(
  connect(jobsSelector),
  connectRequest(mapPropsToConfig),
)(Jobs);
