import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Col, Row } from "react-bootstrap";

import { validateRequiredFields } from "common/utils/forms/validators";
import { TrackerDatePicker } from "contractor/components/time-tracker";
import {
  DropzoneField,
  HiddenField,
  NumberField,
  TextAreaField,
} from "common/components/forms/inputs";
import FormButton from "common/components/FormButton";

export const formName = "ExpenseForm";
export const requiredFields = [
  "worker",
  "date",
  "amount",
  "description",
  "attachment",
];

const validate = values => validateRequiredFields(requiredFields, values);

class ExpenseForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="expenses-form">
          <Row>
            <Col sm={6}>
              <TrackerDatePicker />
            </Col>
            <Col lg={6}>
              <NumberField
                addonLeft="$"
                placeholder={"0.00"}
                min="0.01"
                step="0.01"
                name="amount"
                label="Amount"
              />
            </Col>
          </Row>
          <DropzoneField name="attachment" label="Attachment" />
          <TextAreaField name="description" label="Description" rows={4} />
          <HiddenField name="worker" />
          <div className="text-right">
            <FormButton
              className="text-right"
              action="save"
              submitting={submitting}
              disabled={pristine || submitting || !valid}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  validate,
})(ExpenseForm);
