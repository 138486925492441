import React, { Component } from "react";
import { requestAsync } from "redux-query";
import { createStructuredSelector } from "reselect";
import { addNotification as notify } from "reapop";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import { MobileEntries, DesktopEntries } from "./Entries";
import FormModal from "./FormModal";
import FAQModal from "./FAQModal";
import NextButton from "../../NextButton";
import types from "resources/types";
import { createResourceSelectorConfig } from "queries";
import { compose, connect, connectRequest } from "queries/utils";
import {
  barInformationsQueryWithConfig,
  barVerificationsQueryWithConfig,
} from "queries/requests/barInformations";
import { createErrorNotification } from "common/utils/notifications";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import {
  getActionIsRequired,
  getCustomErrorMsg,
  getCanMoveOnToNextStep,
  getVerifIsPending,
  redirectToNextStepIfNeeded,
} from "../../utils";
import { getResourcesAreReady } from "common/utils/helpers";
import Label from "common/blocks/Label";
import { MainCopy, PendingVerificationCopy } from "./copy";
import Loading from "common/components/Loading";

const stepName = "bar_admissions";
const requiredResources = [types.BAR_INFORMATIONS, types.BARCARD_VERIFICATIONS];

class BarAdmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      justUploadedIds: [],
      nextButtonLoading: false,
      showFormModal: false,
    };
  }

  handleFooterClick = () => this.setState({ showFormModal: true });

  handleHideModal = () => this.setState({ showFormModal: false });

  handleFileUploadError = () => {
    this.props.notify(
      createErrorNotification({ message: "File size must be under 15 MB." }),
    );
  };

  addJustUploadedId = uuid =>
    this.setState(state => ({
      justUploadedIds: state.justUploadedIds.concat(uuid),
    }));

  handleNextClick = () => {
    this.setState({ nextButtonLoading: true }, () => {
      redirectToNextStepIfNeeded(
        stepName,
        this.props.refreshReqs,
        this.stopButtonLoading,
      );
    });
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: { justUploadedIds, nextButtonLoading, showFormModal },
      props: {
        barInformations: { data: barInfoData },
        barcardVerifications: { data: barVerifData },
        refreshBarInfo,
        refreshBarVerif,
        refreshReqs,
        reqs: { steps },
      },
      addJustUploadedId,
      handleNextClick,
      handleFooterClick,
      handleHideModal,
      handleFileUploadError,
    } = this;
    const isReady = getResourcesAreReady(requiredResources, this.props);
    const actionIsRequired = getActionIsRequired(stepName, steps);
    const customErrorMsg = getCustomErrorMsg(stepName, steps);
    const verifIsPending = getVerifIsPending(stepName, steps);
    const entriesProps = {
      addJustUploadedId,
      barInfoObjs: Object.values(barInfoData),
      barVerifObjs: Object.values(barVerifData),
      justUploadedIds,
      onFileUploadError: handleFileUploadError,
      refreshReqs,
    };

    return isReady ? (
      <Onboard.Page>
        <DesktopOnly>
          <Onboard.Container>
            <Onboard.ContainerHeader>
              <div>
                <h1>Bar Admissions</h1>
              </div>
              <div>
                {actionIsRequired && (
                  <Label color="danger">ACTION REQUIRED</Label>
                )}
              </div>
            </Onboard.ContainerHeader>
            <Onboard.ContainerBody>
              {customErrorMsg && (
                <Alert bsStyle="danger">{customErrorMsg}</Alert>
              )}
              <MainCopy actionIsRequired={actionIsRequired} />
              <DesktopEntries {...entriesProps} />
              {verifIsPending && <PendingVerificationCopy />}
            </Onboard.ContainerBody>
            <Onboard.ContainerFooter onClick={handleFooterClick}>
              + ADD A LICENSE
            </Onboard.ContainerFooter>
          </Onboard.Container>
        </DesktopOnly>
        <MobileOnly>
          <h1>Bar Admissions</h1>
          {actionIsRequired && (
            <div>
              <Label color="danger">ACTION REQUIRED</Label>
              <br />
              <br />
            </div>
          )}
          {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
          <MainCopy actionIsRequired={actionIsRequired} />
          <Onboard.Container>
            <Onboard.ContainerBody>
              <MobileEntries {...entriesProps} />
            </Onboard.ContainerBody>
            <Onboard.ContainerFooter onClick={handleFooterClick}>
              + ADD A LICENSE
            </Onboard.ContainerFooter>
          </Onboard.Container>
          {verifIsPending && <PendingVerificationCopy />}
        </MobileOnly>
        <FAQModal />
        <NextButton
          disabled={!getCanMoveOnToNextStep(stepName, steps)}
          float="right"
          isLoading={nextButtonLoading}
          onClick={handleNextClick}
        />
        <FormModal
          show={showFormModal}
          onHide={handleHideModal}
          refreshBarInfo={refreshBarInfo}
          refreshBarVerif={refreshBarVerif}
          refreshReqs={refreshReqs}
        />
      </Onboard.Page>
    ) : (
      <Loading />
    );
  }
}

const mapPropsToConfig = () => [
  barInformationsQueryWithConfig(),
  barVerificationsQueryWithConfig(),
];

const barInformationsSelectorConfig = createResourceSelectorConfig(
  types.BAR_INFORMATIONS,
  barInformationsQueryWithConfig,
);

const barVerificationsSelectorConfig = createResourceSelectorConfig(
  types.BARCARD_VERIFICATIONS,
  barVerificationsQueryWithConfig,
);

const mapStateToProps = createStructuredSelector({
  ...barInformationsSelectorConfig,
  ...barVerificationsSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  refreshBarInfo: () =>
    dispatch(requestAsync(barInformationsQueryWithConfig({ force: true }))),
  refreshBarVerif: () =>
    dispatch(requestAsync(barVerificationsQueryWithConfig({ force: true }))),
  notify: f => dispatch(notify(f)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(BarAdmissions);
