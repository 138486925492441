// @flow strict
import * as React from "react";
import styled from "styled-components";

type Props = {
  open: boolean,
  children?: React.Node,
};

const bottomPaddingHeight = 5;
const SecondaryNavList: React.ComponentType<Props> = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, auto);
  width: 100%;
  max-height: ${({ open, children }) =>
    open ? `${42 * children.length + bottomPaddingHeight}px` : "0"};
  margin: 0;
  padding: ${({ open }) => (open ? `0 0 ${bottomPaddingHeight}px 0` : "0")};
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`;

export default SecondaryNavList;
