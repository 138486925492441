import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Col, Button, ButtonToolbar, Row } from "react-bootstrap";

import {
  CheckboxField,
  EmailAddressInput,
  FirstNameInput,
  LastNameInput,
} from "common/components/forms/inputs";
import FormFieldToolTip from "common/components/forms/FormFieldToolTip";
import { validateRequiredFields } from "common/utils/forms";

export const fields = ["first_name", "last_name", "email_address", "is_admin"];

export const requiredFields = ["first_name", "last_name", "email_address"];

const isAdminToolTip = agencyName => {
  const text = `Check this box if you want this user to be able to administer the Hire
                  an Esquire account for ${agencyName}. An admin can manage addresses, payment
                  account information, and other hiring agents.`;
  return (
    <FormFieldToolTip
      title="Administrator Privileges"
      content={text}
      placement="top"
    />
  );
};

const validate = values => validateRequiredFields(requiredFields, values);

class CreateHiringAgentForm extends Component {
  render() {
    const { handleSubmit, submitting, close, agencyName } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm={6}>
            <FirstNameInput />
          </Col>
          <Col sm={6}>
            <LastNameInput />
          </Col>
        </Row>
        <EmailAddressInput />
        <CheckboxField name="is_admin" label="This user is an account admin">
          &nbsp;{isAdminToolTip(agencyName)}
        </CheckboxField>
        <ButtonToolbar className="pull-right">
          <Button bsStyle="link" onClick={close}>
            Cancel
          </Button>
          <Button bsStyle="success" disabled={submitting} type="submit">
            Add Agent
          </Button>
        </ButtonToolbar>
        <span className="clearfix" />
      </form>
    );
  }
}

CreateHiringAgentForm = reduxForm({
  form: "CreateHiringAgentForm",
  validate,
  initialValues: {
    is_admin: true,
  },
})(CreateHiringAgentForm);
export default CreateHiringAgentForm;
