import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Grid } from "react-bootstrap";

import { requestUserType } from "common/actions/user";
import { TopNavHeader } from "common/components";
import { ContentWrapper, Footer } from "common/components/layout";

class Base extends Component {
  static defaultProps = {
    enableLinks: true,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { children, enableLinks } = this.props;
    return (
      <Fragment>
        <TopNavHeader userType="contractor" empty={!enableLinks} />
        <ContentWrapper>
          <Grid>{children}</Grid>
        </ContentWrapper>
        <Footer />
      </Fragment>
    );
  }
}

export default connect(
  null,
  {
    onLoad: () => requestUserType("contractor"),
  },
)(Base);
