import styled from "styled-components";

const NavItem = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-gap: 10px;
  align-items: center;
  width: 100%;
  height: 42px;
  margin: 0 0 0 15.53px;

  & > span:first-child::after {
    display: block;
    content: attr(name);
    font-weight: 900;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export default NavItem;
