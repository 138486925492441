import { createAction } from "redux-actions";
import keyMirror from "keymirror";

const actions = keyMirror({
  SET_SELECTED_COLUMN: null,
});

export default actions;

export const setSelectedColumn = createAction(actions.SET_SELECTED_COLUMN);
