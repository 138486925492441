import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";

import { SupportLink } from "common/components";
import {
  CheckboxField,
  DollarPerHourInput,
  NumberField,
} from "common/components/forms/inputs";
import { FormRow, OptionalFormMessage, withFormError } from "./utils";

class JobSpecificationsForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormRow>
          <NumberField
            name="num_openings"
            label={"Number of openings"}
            min={0}
          />
          <p />
        </FormRow>
        <FormRow>
          <Row>
            <Col lg={6} xs={12}>
              <DollarPerHourInput
                name="min_estimated_rate"
                label={"Min. rate"}
                min={0}
              />
            </Col>
            <Col lg={6} xs={12}>
              <DollarPerHourInput
                name="max_estimated_rate"
                label={"Max. rate"}
                min={0}
              />
            </Col>
          </Row>
          <p>
            Provide your budget or best estimate range. For further guidance,
            contact us at <SupportLink />.
          </p>
        </FormRow>
        <FormRow>
          <CheckboxField name="make_private" label="Make this job private" />
          <p>
            Only candidates you invite to apply can view this job. For both
            private and public jobs, your name is only shown to those you hire.
          </p>
        </FormRow>
      </form>
    );
  }
}
JobSpecificationsForm = compose(
  reduxForm({
    enableReinitialize: true,
  }),
  withFormError(),
)(JobSpecificationsForm);

export default class JobSpecifications extends Component {
  handleSubmit = values => {
    const {
      formProps: { onSubmit },
    } = this.props;
    const {
      make_private,
      max_estimated_rate,
      min_estimated_rate,
      num_openings,
      ...otherValues
    } = values;
    return onSubmit({
      searchable: !make_private,
      num_openings: num_openings || null,
      max_estimated_rate: max_estimated_rate || null,
      min_estimated_rate: min_estimated_rate || null,
      ...otherValues,
    });
  };

  render() {
    const {
      formProps,
      jobListing: {
        max_estimated_rate,
        min_estimated_rate,
        num_openings,
        searchable,
      },
    } = this.props;
    const initialValues = {
      make_private: !searchable,
      max_estimated_rate,
      min_estimated_rate,
      num_openings: num_openings || 1,
    };
    return (
      <div>
        <h2>Project Parameters</h2>
        <div className="description">
          <OptionalFormMessage />
          <p>
            Job postings that include number of openings and rate information
            receive better candidate recommendations and are often filled
            faster.
          </p>
        </div>
        <JobSpecificationsForm
          {...formProps}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
        />
      </div>
    );
  }
}
