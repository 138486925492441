import React from "react";

import { SelectField } from "common/components/forms/inputs";

const ContractorTypeSelectField = props => {
  return (
    <SelectField name="allowed_applicants" {...props}>
      <option value="attorneys">Attorneys</option>
      <option value="non_attorneys">Non-attorney legal professionals</option>
      <option value="any">Any</option>
    </SelectField>
  );
};
export default ContractorTypeSelectField;
