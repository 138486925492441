import React from "react";
import moment from "moment";
import { Table } from "react-bootstrap";

const HiringAgentSearchHistoryResult = props => (
  <Table striped bordered>
    <thead>
      <tr>
        <th className="text-center">User</th>
        <th className="text-center">Time</th>
        <th className="text-center">Keywords</th>
        <th className="text-center">Barred in</th>
        <th className="text-center">Miles</th>
        <th className="text-center">Patent Bar Id</th>
        <th className="text-center">Language</th>
        <th className="text-center">Years Experience</th>
        <th className="text-center">Practice Area</th>
        <th className="text-center">Location</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(props.searchHistories).map(search => {
        const searchHistory = props.searchHistories[search];
        return (
          <tr key={`stats-row-${search}`}>
            <td className="text-left">
              {searchHistory.haeuser
                ? `${searchHistory.haeuser.first_name} ${searchHistory.haeuser.last_name}`
                : "-"}
            </td>
            <td className="text-left">
              {moment(searchHistory.created, moment.ISO_8601).format("LLLL")}
            </td>
            <td className="text-left">{searchHistory.keywords}</td>
            {searchHistory.hiring_agent_params && (
              <>
                <td className="text-left">
                  {searchHistory.hiring_agent_params.barred_in}
                </td>
                <td className="text-left">
                  {searchHistory.hiring_agent_params.miles}
                </td>
                <td className="text-left">
                  {searchHistory.hiring_agent_params.proficient_in}
                </td>
                <td className="text-left">
                  {searchHistory.hiring_agent_params.years_experience}
                </td>
                <td className="text-left">
                  {searchHistory.hiring_agent_params.practice_areas}
                </td>
                <td className="text-left">
                  {searchHistory.hiring_agent_params.location}
                </td>
              </>
            )}
          </tr>
        );
      })}
    </tbody>
  </Table>
);

export default HiringAgentSearchHistoryResult;
