import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";

import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_JOBS_FOR_DROPDOWN: null,
  RECEIVE_JOBS_FOR_DROPDOWN: null,
});
export default actions;

export const requestJobsV2 = () => (dispatch, getState) => {
  if (
    getState().admin.jobsDropdown.jobsLoaded ||
    getState().admin.jobsDropdown.jobsLoading
  ) {
    return undefined;
  }
  dispatch(createAction(actions.REQUEST_JOBS_FOR_DROPDOWN)());
  return oldApi
    .get("/api/v2/job_names/?limit=9999")
    .then(({ json, response }) => {
      const payload = response.ok
        ? json.results
        : new Error("Error retrieving jobs.");
      return dispatch(
        createAction(actions.RECEIVE_JOBS_FOR_DROPDOWN, p => p, metaGenerator)(
          payload,
        ),
      );
    });
};
