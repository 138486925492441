import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";
import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_APPLICATIONS: null,
  RECEIVE_APPLICATIONS: null,
  SET_SEARCH_PARAMETERS: null,
  RESET_SEARCH_PARAMETERS: null,
});
export default actions;

export const setSearchParameters = newParams => (dispatch, getState) => {
  dispatch(createAction(actions.SET_SEARCH_PARAMETERS)(newParams));
  const { searchParams } = getState().applications;
  let url = "/api/dev/applications/?limit=9999";
  Object.keys(searchParams).forEach(key => {
    const param = searchParams[key];
    if (param !== null && param !== undefined) {
      url += `&${key}=${param}`;
    }
  });
  return oldApi.get(url).then(({ json, response }) => {
    const payload = response.ok
      ? {
          applications: json.results,
          applicationsCount: json.count,
        }
      : new Error("Error retrieving applications stats.");
    return dispatch(
      createAction(actions.RECEIVE_APPLICATIONS, p => p, metaGenerator)(
        payload,
      ),
    );
  });
};
export const resetSearchParameters = createAction(
  actions.RESET_SEARCH_PARAMETERS,
);
