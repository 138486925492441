import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import Deposit from "./blocks";
import { IDUploadField } from "./Fields";

const UploadSuccess = ({ onClick }) => (
  <Deposit.Container compact="true">
    <Deposit.ContainerContent>
      <Row>
        <Col xs={6}>
          <Deposit.IconCheck />
          UPLOADED
        </Col>
        <Col xs={6}>
          <Deposit.PrimaryButton
            bsStyle="danger"
            compact="true"
            onClick={onClick}
            float="right"
          >
            REMOVE
          </Deposit.PrimaryButton>
        </Col>
      </Row>
    </Deposit.ContainerContent>
  </Deposit.Container>
);

const ConfirmationModal = ({ onHide, onConfirm, showModal }) => (
  <Modal show={showModal} onHide={onHide}>
    <Modal.Header closeButton>
      <h1>Changing Your ID Type</h1>
    </Modal.Header>
    <Modal.Body>
      <p>
        Changing your Government-issued ID type will delete any uploaded files.
        Are you sure you want to proceed?
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="default" onClick={onHide}>
        No, Go Back
      </Button>
      <Button bsStyle="primary" onClick={onConfirm}>
        Yes, Change My ID
      </Button>
    </Modal.Footer>
  </Modal>
);

export default class IDUploadSection extends Component<
  IDUploadSectionProps,
  IDUploadSectionState,
> {
  constructor(props) {
    super(props);
    this.state = {
      activeUpload: "default",
      tempUpload: "state_id",
      showModal: false,
      error: false,
    };
  }

  handleChange = e => {
    e.preventDefault();
    const { target } = e;

    if (target instanceof HTMLSelectElement) {
      const { value } = target;
      const { activeUpload } = this.state;
      const {
        uploadedPassport,
        uploadedStateIDBack,
        uploadedStateIDFront,
      } = this.props;

      if (
        value !== activeUpload &&
        (uploadedPassport || uploadedStateIDBack || uploadedStateIDFront)
      ) {
        this.setState({ tempUpload: value, showModal: true });
      } else {
        this.setState({ activeUpload: value });
      }
    }
  };

  handleHide = () => this.setState({ showModal: false });

  handleConfirm = () => {
    this.setState(state => ({
      activeUpload: state.tempUpload,
      tempUpload: "state_id",
      showModal: false,
    }));

    this.props.onSetPassport(null);
    this.props.onSetStateIDFront(null);
    this.props.onSetStateIDBack(null);
  };

  handleSetUpload = (file, fileType) => {
    switch (fileType) {
      case "passport":
        this.props.onSetPassport(file);
        break;
      case "state_id_front":
        this.props.onSetStateIDFront(file);
        break;
      case "state_id_back":
        this.props.onSetStateIDBack(file);
        break;
      default:
        this.setState({ error: true });
    }
  };

  render() {
    const { activeUpload, showModal } = this.state;
    const {
      uploadedPassport,
      uploadedStateIDFront,
      uploadedStateIDBack,
    } = this.props;

    return (
      <div>
        <IDUploadField
          activeUpload={activeUpload}
          handleChange={this.handleChange}
        />
        {activeUpload === "state_id" && (
          <Row>
            <Col sm={6}>
              {uploadedStateIDFront ? (
                <UploadSuccess
                  onClick={() => this.handleSetUpload(null, "state_id_front")}
                />
              ) : (
                <Deposit.Dropzone
                  onDrop={file => this.handleSetUpload(file, "state_id_front")}
                  accept="image/*"
                  maxSize={15728640}
                >
                  <span>+ Upload Front of ID</span>
                </Deposit.Dropzone>
              )}
            </Col>
            <Col sm={6}>
              {uploadedStateIDBack ? (
                <UploadSuccess
                  onClick={() => this.handleSetUpload(null, "state_id_back")}
                />
              ) : (
                <Deposit.Dropzone
                  onDrop={file => this.handleSetUpload(file, "state_id_back")}
                  accept="image/*"
                  maxSize={15728640}
                >
                  <span>+ Upload Back of ID</span>
                </Deposit.Dropzone>
              )}
            </Col>
          </Row>
        )}
        {activeUpload === "passport" && (
          <Row>
            <Col sm={6}>
              {uploadedPassport ? (
                <UploadSuccess
                  onClick={() => this.handleSetUpload(null, "passport")}
                />
              ) : (
                <Deposit.Dropzone
                  onDrop={file => this.handleSetUpload(file, "passport")}
                  accept="image/*"
                  maxSize={15728640}
                >
                  <span>+ Upload Passport Image</span>
                </Deposit.Dropzone>
              )}
            </Col>
          </Row>
        )}
        <ConfirmationModal
          onConfirm={this.handleConfirm}
          onHide={this.handleHide}
          showModal={showModal}
        />
      </div>
    );
  }
}
