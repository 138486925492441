import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import { initialize, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import ListConflicts from "./ListConflicts";
import { validateRequiredFields } from "common/utils/forms";
import { withConflicts } from "contractor/connectors";
import ConflictForm, {
  formName,
  requiredFields,
} from "contractor/forms/ConflictForm";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import {
  submitCreateConflict,
  submitDeleteConflict,
  submitUpdateConflict,
} from "contractor/actions/profile";

const ConfirmDeleteConflictModal = ({ conflict, onClose, onSubmit, show }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>Confirm Delete Conflict</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4 className="text-center">
        Are you sure you want to delete the following conflict?
      </h4>
      {conflict && (
        <dl className="dl-horizontal">
          <dt>Matter</dt>
          <dd>{conflict.name_of_matter}</dd>
          <dt>Client</dt>
          <dd>{conflict.client_represented}</dd>
          <dt>Firm</dt>
          <dd>{conflict.name_of_firm}</dd>
          <dt>Opposing Party</dt>
          <dd>{conflict.opposing_party}</dd>
        </dl>
      )}
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar>
        <Button bsStyle="link" onClick={onClose}>
          Cancel
        </Button>
        <Button bsStyle="danger" onClick={onSubmit} className="pull-right">
          Delete
        </Button>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>
);

class EditConflicts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletingConflictId: null,
      editingConflictId: null,
    };
  }

  handleAddConflictClick = () => {
    this.props.initializeForm();
    this.setState({ editingConflictId: null });
  };

  handleDeleteConflictClick = conflictId =>
    this.setState({ deletingConflictId: conflictId });

  handleDeleteConflictCloseClick = () =>
    this.setState({ deletingConflictId: null });

  handleDeleteConflictConfirmClick = () => {
    const { notify, onReload, submitDeleteConflict } = this.props;
    const { deletingConflictId } = this.state;
    submitDeleteConflict(deletingConflictId).then(() => {
      this.setState({ deletingConflictId: null });
      onReload();
    });
    notify(
      createSuccessNotification({ message: "Conflict has been deleted." }),
    );
  };

  handleEditConflictClick = conflictId =>
    this.setState({ editingConflictId: conflictId });

  handleSubmit = values => {
    const {
      initializeForm,
      notify,
      onReload,
      submitCreateConflict,
      submitUpdateConflict,
    } = this.props;
    const { editingConflictId } = this.state;
    const args = editingConflictId ? [editingConflictId, values] : [values];
    const action = editingConflictId
      ? submitUpdateConflict
      : submitCreateConflict;
    return action(...args).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      notify(
        createSuccessNotification({
          message: editingConflictId
            ? "Conflict has been updated."
            : "New conflict has been saved.",
        }),
      );
      initializeForm();
      this.setState({ editingConflictId: null });
      onReload();
    });
  };

  validate = values => validateRequiredFields(requiredFields, values);

  renderEditConflict() {
    const {
      conflicts: {
        data: { results },
      },
    } = this.props;
    const { editingConflictId } = this.state;
    const editingConflict = results.find(
      conflict => conflict.uuid === editingConflictId,
    );
    return (
      <div>
        <h2>
          Edit Conflict
          <Button
            bsSize="xs"
            bsStyle="success"
            style={{ marginLeft: "1rem" }}
            onClick={this.handleAddConflictClick}
          >
            <i className="far fa-plus" />&nbsp;Add new conflict
          </Button>
        </h2>
        <ConflictForm
          key={`edit-conflict-${editingConflictId}`}
          initialValues={editingConflict}
          onSubmit={this.handleSubmit}
          validate={this.validate}
        />
      </div>
    );
  }

  renderAddConflict() {
    return (
      <div>
        <h2>Add New Conflict</h2>
        <ConflictForm onSubmit={this.handleSubmit} validate={this.validate} />
      </div>
    );
  }

  render() {
    const {
      conflicts: {
        data: { results = [] },
        isLoading,
        lastUpdated,
      },
    } = this.props;
    const { deletingConflictId, editingConflictId } = this.state;
    return (
      <div>
        <h2 className="edit-section-title">Conflicts</h2>
        <div>
          <ListConflicts
            onDeleteClick={this.handleDeleteConflictClick}
            onEditClick={this.handleEditConflictClick}
          />
          <hr />
          {editingConflictId && !isLoading && lastUpdated
            ? this.renderEditConflict()
            : this.renderAddConflict()}
        </div>
        <ConfirmDeleteConflictModal
          show={Boolean(deletingConflictId)}
          onSubmit={this.handleDeleteConflictConfirmClick}
          onClose={this.handleDeleteConflictCloseClick}
          conflict={results.find(c => c.uuid === deletingConflictId)}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      initializeForm: () => initialize(formName),
      notify,
      submitCreateConflict,
      submitDeleteConflict,
      submitUpdateConflict,
    },
  ),
  withConflicts(),
)(EditConflicts);
