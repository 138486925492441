import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const BUTTON_ACTION_TEXT = {
  save: { static: "Save", inProgress: "Saving..." },
  submit: { static: "Submit", inProgress: "Submitting..." },
  send: { static: "Send", inProgress: "Sending..." },
};

const FormButton = props => {
  const { action, submitting, disabled, ...buttonProps } = props;
  const buttonText = BUTTON_ACTION_TEXT[action];

  return (
    <Button type="submit" disabled={disabled || submitting} {...buttonProps}>
      {submitting ? buttonText.inProgress : buttonText.static}
    </Button>
  );
};

FormButton.propTypes = {
  action: PropTypes.oneOf(["save", "submit", "send"]).isRequired,
  submitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  bsStyle: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};
FormButton.defaultProps = {
  action: "save",
  bsStyle: "primary",
  style: null,
  className: "",
};
export default FormButton;
