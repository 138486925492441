import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Grid, DropdownButton, MenuItem } from "react-bootstrap";
import Loading from "common/components/Loading";
import SearchHistoryInputs from "admin/components/search-history/SearchHistoryInputs";
import AttorneySearchHistoryResult from "admin/components/search-history/AttorneySearchHistoryResult";
import HiringAgentSearchHistoryResult from "admin/components/search-history/HiringAgentSearchHistoryResult";
import {
  setUserType,
  requestSearchHistories,
  requestPracticeAreas,
  changeSearchParams,
  requestUsers,
} from "admin/actions/search-history";

class Index extends Component {
  handleUserTypeChange(newValue) {
    this.props.dispatch(setUserType(newValue));
    this.props.dispatch(requestUsers());
    if (newValue === "Hiring Agent") {
      this.props.dispatch(requestPracticeAreas());
    }
  }

  requestSearchHistories() {
    this.props.dispatch(requestSearchHistories());
  }

  handleSearchParamsChange(newValue) {
    this.props.dispatch(changeSearchParams(newValue));
  }

  render() {
    const {
      isLoading,
      searchHistoriesLoaded,
      searchHistories,
      userType,
    } = this.props;
    const dropDownTitle = userType || "Choose a user type";

    return (
      <Grid fluid={true}>
        <div>
          <h1 style={{ marginBottom: "2rem" }}>Search History Dashboard</h1>
          <DropdownButton
            id="search-history-user-select"
            title={dropDownTitle}
            onSelect={e => this.handleUserTypeChange(e)}
          >
            <MenuItem style={{ listStyle: "none" }} eventKey="Hiring Agent">
              Hiring Agent
            </MenuItem>
            <MenuItem style={{ listStyle: "none" }} eventKey="Contractor">
              Contractor
            </MenuItem>
          </DropdownButton>
        </div>
        <div>
          <Row>
            {userType ? (
              <SearchHistoryInputs
                {...this.props}
                handleSearchParamsChange={this.handleSearchParamsChange.bind(
                  this,
                )}
                requestSearchHistories={this.requestSearchHistories.bind(this)}
              />
            ) : null}
          </Row>
          <Row>{isLoading ? <Loading /> : null}</Row>
          {searchHistoriesLoaded ? (
            <div>
              <h4>{`Results: ${searchHistories.length}`}</h4>
              {userType === "Contractor" ? (
                <AttorneySearchHistoryResult {...this.props} />
              ) : (
                <HiringAgentSearchHistoryResult {...this.props} />
              )}
            </div>
          ) : null}
        </div>
      </Grid>
    );
  }
}

export default connect(state => state.admin.searchHistories)(Index);
