import React from "react";
import PropTypes from "prop-types";
import { Alert, Col, Row } from "react-bootstrap";

export const FormRow = ({ children, style }) => (
  <Row style={{ marginBottom: "2rem", ...style }}>
    <Col md={7}>{children[0]}</Col>
    <Col md={5} className="help-text">
      {children[1]}
    </Col>
  </Row>
);
FormRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  style: PropTypes.object,
};

FormRow.defaultProps = {
  style: {},
};

export const OptionalFormMessage = () => (
  <p className="text-info" style={{ fontWeight: "700" }}>
    <i className="far fa-exclamation-circle" />&nbsp;Optional.
  </p>
);

// When using with `compose`, make sure this comes after `reduxForm`
export const withFormError = ({
  showAbove = true,
  showBelow = false,
} = {}) => Component => props => (
  <div>
    {showAbove && <FormError error={props.error} />}
    <Component {...props} />
    {showBelow && <FormError error={props.error} />}
  </div>
);

export const FormError = ({ error = [] }) =>
  error.length > 0 && (
    <Alert bsStyle="danger">
      <ul>{error.map(err => <li key={err}>{err}</li>)}</ul>
    </Alert>
  );
