// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Container from "common/components/styled/Container";
import Section from "./Section";
import Item from "./Item";
import { IconSuccessCheck, IconDangerTimes, IconEmptyCircle } from "./Icons";
import Qualification from "./Qualification";

const QualificationsBlock: ComponentType<Props> = styled.div`
  margin: 0 0 50px 0;
`;

const Qualifications = (props: Props) => <QualificationsBlock {...props} />;

Qualifications.Container = Container;
Qualifications.Section = Section;
Qualifications.Item = Item;
Qualifications.IconSuccessCheck = IconSuccessCheck;
Qualifications.IconDangerTimes = IconDangerTimes;
Qualifications.IconEmptyCircle = IconEmptyCircle;
Qualifications.Qualification = Qualification;

export default Qualifications;
