import React, { Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { Button, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { withScreenSize } from "common/components/responsive";
import { addMessageRecipientIds } from "hiringagent-dashboard/actions/messages";

import Applicants from "./blocks";

const ScoreValue = ({ score, percentage = false, fallback = "--" }) => {
  // Null score values that are not percentages should display fallback

  if (!score && typeof score !== "number") {
    return <span>{fallback}</span>;
  }
  return (
    <span>
      {Math.floor(score)}
      {percentage && "%"}
    </span>
  );
};

let MessageButton = ({ onClick, contractorId, ...props }) => (
  <LinkContainer to="/agency/messages/create/" onClick={onClick}>
    <Button bsStyle="primary" {...props}>
      Message
    </Button>
  </LinkContainer>
);
MessageButton = connect(
  null,
  (dispatch, props) => ({
    onClick: () => dispatch(addMessageRecipientIds([props.contractorId])),
  }),
)(MessageButton);

const DesktopTableRow = ({ candidate }) => (
  <Applicants.TableRow candidate={candidate}>
    <td className="col-sm-2">{candidate.full_name}</td>
    <td className="col-sm-1">
      <Applicants.AssessmentScore score={candidate.overall_fitscore}>
        <ScoreValue score={candidate.overall_fitscore} fallback="n/a" />
      </Applicants.AssessmentScore>
    </td>
    <td className="col-sm-1">
      <Applicants.RequirementsScore score={candidate.brief_check_score}>
        <ScoreValue score={candidate.brief_check_score} percentage />
      </Applicants.RequirementsScore>
    </td>
    <td className="col-sm-1 col-md-2">
      <Applicants.AssessmentScore score={candidate.workstyle_assessment_score}>
        <ScoreValue score={candidate.workstyle_assessment_score} />
      </Applicants.AssessmentScore>
    </td>
    <td className="col-sm-2">
      <Applicants.Status status={candidate.status}>
        {candidate.statusDisplay}
      </Applicants.Status>
    </td>
    <td className="col-sm-2">
      {moment(candidate.created).format("MM/DD/YYYY")}
    </td>
    <td className="col-sm-3 col-md-2">
      <LinkContainer to={`/agency/candidates/${candidate.uuid}`}>
        <Button bsSize="xs" bsStyle="success">
          View
        </Button>
      </LinkContainer>
      <MessageButton bsSize="xs" contractorId={candidate.contractor} />
    </td>
  </Applicants.TableRow>
);

let MobileTableRow = ({ candidate, selectedColumn }) => (
  <Fragment>
    <Applicants.TableRow candidate={candidate}>
      <td>{candidate.full_name}</td>
      {selectedColumn === "fitscore" && (
        <td>
          <Applicants.AssessmentScore score={candidate.overall_fitscore}>
            <ScoreValue score={candidate.overall_fitscore} fallback="n/a" />
          </Applicants.AssessmentScore>
        </td>
      )}
      {selectedColumn === "requirements" && (
        <td>
          <Applicants.RequirementsScore score={candidate.brief_check_score}>
            <ScoreValue score={candidate.brief_check_score} percentage />
          </Applicants.RequirementsScore>
        </td>
      )}
      {selectedColumn === "workstyle" && (
        <td>
          <Applicants.AssessmentScore
            score={candidate.workstyle_assessment_score}
          >
            <ScoreValue score={candidate.workstyle_assessment_score} />
          </Applicants.AssessmentScore>
        </td>
      )}
      {selectedColumn === "date" && (
        <td>{moment(candidate.created).format("MM/DD/YYYY")}</td>
      )}
      <td>
        <Applicants.Status status={candidate.status}>
          {candidate.statusDisplay}
        </Applicants.Status>
      </td>
    </Applicants.TableRow>
    <Applicants.MobileActionsTableRow>
      <td colSpan={3}>
        <Row>
          <Col xs={5}>
            <LinkContainer to={`/agency/candidates/${candidate.uuid}`}>
              <Button bsStyle="success" block>
                View
              </Button>
            </LinkContainer>
          </Col>
          <Col xs={5} xsOffset={2}>
            <MessageButton
              contractorId={candidate.contractor}
              className="pull-right"
              block
            />
          </Col>
        </Row>
      </td>
    </Applicants.MobileActionsTableRow>
  </Fragment>
);

MobileTableRow = connect(state => ({
  selectedColumn: state.hiringAgentDashboard.applicantsTable.selectedColumn,
}))(MobileTableRow);

const TableRow = ({ candidate, isMobile }) =>
  isMobile ? (
    <MobileTableRow candidate={candidate} />
  ) : (
    <DesktopTableRow candidate={candidate} />
  );

export default withScreenSize(TableRow);
