// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Title from "./Title";

const CandidateHeaderBlock: ComponentType<{}> = styled.div``;
const CandidateHeader = (props: {}) => <CandidateHeaderBlock {...props} />;

CandidateHeader.Title = Title;

export default CandidateHeader;
