// @flow strict
import styled from "styled-components";

const Question = styled.p`
  text-align: left;
  font-size: 18px;

  ${({ theme }) => theme.media.desktop`
    margin: 0;
  `};
`;

export default Question;
