import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { metaGenerator } from "common/actions";
import { oldApi } from "common/api";
import { redirectOnUnauthorizedResponse } from "common/api/api";

const actions = keyMirror({
  REQUEST_CONTRACTOR: null,
  RECEIVE_CONTRACTOR: null,
  REQUEST_SUBMIT_SHARE_PROFILE: null,
  RECEIVE_SUBMIT_SHARE_PROFILE: null,
});

export default actions;

export const requestContractor = (uuid, authToken = null) => (
  dispatch,
  getState,
) => {
  const { contractors } = getState().hiringAgentDashboard.attorneys;
  if (!contractors.uuid) {
    dispatch(createAction(actions.REQUEST_CONTRACTOR)());
    const headers = authToken ? { "X-TERNAL-LINK-TOKEN": authToken } : null;
    return oldApi
      .get(`/api/dev/contractors/${uuid}/`, headers)
      .then(({ json, response }) => {
        redirectOnUnauthorizedResponse(response);
        const payload = response.ok
          ? json
          : new Error("Error retrieving contractor.");
        dispatch(
          createAction(actions.RECEIVE_CONTRACTOR, null, metaGenerator)(
            payload,
          ),
        );
      });
  }
  return null;
};

export const submitShareProfile = (uuid, data) => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_SHARE_PROFILE)());
  return oldApi
    .post(`/api/dev/contractors/${uuid}/share_profile/`, { email: data })
    .then(({ json, response }) => {
      const payload = response.ok ? json : new Error("Error sharing profile.");
      dispatch(
        createAction(actions.RECEIVE_SUBMIT_SHARE_PROFILE, null, metaGenerator)(
          payload,
        ),
      );
      return { json, response };
    });
};

/*
    1. Create custom uuid field POST
    2. Get custom fields        GET
    3. Create contact           POST
    4. Set custom uuid field    PATCH
 */
const clioActionsForContractor = (contractor, state) => {
  let contactId;
  let customUuidFieldId;
  const {
    results: contractorTypes,
  } = state.common.contractor_content_types.data;
  return {
    getCustomFields: () =>
      oldApi
        .get(
          "/api/v2/proxy/clio/api/v4/custom_fields?fields=id,name,parent_type,field_type",
        )
        .then(({ json, response }) => {
          const customUuidField = json.data.find(
            customField => customField.name === "uuid",
          );
          if (customUuidField) {
            customUuidFieldId = customUuidField.id;
          }
          return { json, response };
        }),
    createCustomUuidFieldIfNeeded: () => {
      if (!customUuidFieldId) {
        return oldApi
          .post("/api/v2/proxy/clio/api/v4/custom_fields", {
            data: {
              name: "uuid",
              parent_type: "Contact",
              field_type: "text_line",
            },
          })
          .then(({ json, response }) => {
            if (response.ok && json.data) {
              customUuidFieldId = json.data.id;
            }
          });
      }
      return null;
    },
    createContact: () =>
      oldApi
        .post("/api/v2/proxy/clio/api/v4/contacts", {
          data: {
            first_name: contractor.first_name,
            last_name: contractor.full_name.split(" ")[1],
            type: "Person",
            title: `Hire an Esquire ${
              contractorTypes.find(
                contractorType =>
                  contractorType.id === contractor.contractor_type,
              ).name
            }`,
            web_sites: [
              {
                address: window.location.href,
              },
            ],
          },
        })
        .then(({ json, response }) => {
          contactId = json.data.id;
          return { json, response };
        }),
    setContactUuid: () =>
      oldApi.patch(`/api/v2/proxy/clio/api/v4/contacts/${contactId}`, {
        data: {
          custom_field_values: [
            {
              custom_field: { id: customUuidFieldId },
              value: contractor.uuid,
            },
          ],
        },
      }),
  };
};

export const exportContractorToClio = contractor => (dispatch, getState) => {
  const clioActions = clioActionsForContractor(contractor, getState());
  return clioActions
    .getCustomFields()
    .then(clioActions.createCustomUuidFieldIfNeeded)
    .then(clioActions.createContact)
    .then(clioActions.setContactUuid);
};
