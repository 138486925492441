import React from "react";
import {
  Col,
  Checkbox,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

const AttorneySearchInputs = props => (
  <div>
    <Col md={3}>
      <FormGroup>
        <ControlLabel>Rate</ControlLabel>
        <FormControl
          type="number"
          step="any"
          onChange={e =>
            props.handleSearchParamsChange({ rate: e.target.value })
          }
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <ControlLabel>Within (miles)</ControlLabel>
        <FormControl
          type="number"
          step="any"
          onChange={e =>
            props.handleSearchParamsChange({ within: e.target.value })
          }
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <Checkbox
        style={{ marginBottom: 0 }}
        onChange={() =>
          props.handleSearchParamsChange({
            telecommute_only: !props.searchParams.telecommute_only,
          })
        }
      >
        Telecommute
      </Checkbox>
      <Checkbox
        style={{ marginBottom: 0 }}
        onChange={() =>
          props.handleSearchParamsChange({ irs_w2: !props.searchParams.irs_w2 })
        }
      >
        W2
      </Checkbox>
      <Checkbox
        style={{ marginBottom: 0 }}
        onChange={() =>
          props.handleSearchParamsChange({
            irs_1099: !props.searchParams.irs_1099,
          })
        }
      >
        1099
      </Checkbox>
    </Col>
  </div>
);

export default AttorneySearchInputs;
