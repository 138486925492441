// @flow strict
import * as React from "react";
import { getQueryKey } from "redux-query";

import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { invoicesQuery } from "queries/requests/invoices";
import types from "resources/types";

type RenderProps = {
  badge: boolean | number,
  dot: boolean,
};
export type PendingInvoicesNavRenderProps = RenderProps;

type PendingInvoicesNavProps = {
  invoices: QueryResource<Invoice>,
  render: RenderProps => React.Node,
};

const PendingInvoicesNav = (props: PendingInvoicesNavProps) => {
  const {
    invoices: {
      data: invoices,
      queries: { [getQueryKey(query())]: invoicesData },
      isFinished,
      isPending,
    },
    render,
  } = props;
  let badge = 0;
  let dot = false;
  const isReady = isFinished && !isPending;
  if (isReady) {
    const numPendingInvoices = invoicesData.count;
    dot = Boolean(numPendingInvoices);
    badge = numPendingInvoices;
  }
  return render({ badge, dot });
};

const query = invoicesQuery({
  url: "/api/dev/invoices/?unread=true",
});
const mapPropsToConfig = query;
const mapStateToProps = createResourceSelector(
  types.INVOICES,
  mapPropsToConfig,
);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  PendingInvoicesNav,
);
