import React, { Component } from "react";
import PaymentHistoryResults from "./PaymentHistoryResults";

class PaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startingAfter: null,
      pageNumber: 0,
      pages: [], // Store each page as an array with [firstId, lastId]
    };
  }

  handleNextPageClick = currentPage => {
    this.setState(state => {
      const newPages = [...state.pages];
      const [_, last] = currentPage;
      newPages[state.pageNumber] = currentPage;
      return {
        pageNumber: state.pageNumber + 1,
        pages: newPages,
        startingAfter: last,
      };
    });
  };

  handlePreviousPageClick = currentPage => {
    this.setState(state => {
      const newPageNumber = state.pageNumber - 1;
      if (newPageNumber < 0) return {};
      const newPages = [...state.pages];
      newPages[state.pageNumber] = currentPage;

      // To go back a page, we need to retrieve the last item from 2 pages ago as `startingAfter`
      const twoPagesAgo = state.pageNumber - 2;
      const previousPageStartingAfter =
        twoPagesAgo < 0 ? null : state.pages[twoPagesAgo][1];

      return {
        pageNumber: newPageNumber,
        pages: newPages,
        startingAfter: previousPageStartingAfter,
      };
    });
  };

  render() {
    const { pageNumber, pages, startingAfter } = this.state;
    return (
      <PaymentHistoryResults
        pages={pages}
        pageNumber={pageNumber}
        pageSize={10}
        startingAfter={startingAfter}
        onNextPageClick={this.handleNextPageClick}
        onPreviousPageClick={this.handlePreviousPageClick}
      />
    );
  }
}

export default PaymentHistory;
