import { createSelector, createStructuredSelector } from "reselect";
import { getIdFromParams } from "resources/selectors";

export const getMessages = createStructuredSelector({
  data: state => state.resourcesStore.messages,
  metadata: state => state.resources.messages,
});

const getMessageThreadsStore = state => state.resourcesStore.messageThreads;
const getMessageThreads = state => state.resources.messageThreads;

export const makeGetMessageThread = () =>
  createSelector(
    [getMessageThreadsStore, getIdFromParams],
    (messageThreadsStore, id) => messageThreadsStore[id],
  );

export const makeGetMessageThreadMetadata = () =>
  createSelector(
    [getMessageThreads, getIdFromParams],
    messageThreads => messageThreads,
  );
