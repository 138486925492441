import React from "react";
import { Row, Col } from "react-bootstrap";

const ResponsiveContainer = ({ children }) => (
  <Row>
    <Col sm={8} smOffset={2}>
      {children}
    </Col>
  </Row>
);
export default ResponsiveContainer;
