import React, { Component } from "react";

import { requestApplications } from "hiringagent-dashboard/actions/applications";
import { applicationsSliceSelector } from "hiringagent-dashboard/selectors";
import Loading from "common/components/Loading";

import { createConnector } from "common/connectors/utils";

const withApplications = createConnector(
  "WithApplications",
  applicationsSliceSelector,
  { onLoad: requestApplications },
);

export default withApplications;

export const withApplicationsByJobId = (
  mapPropsToId,
  args,
) => WrappedComponent => {
  class WithApplications extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ConnectedComponent: null,
        jobId: null,
      };
    }

    componentWillMount() {
      const jobId = mapPropsToId(this.props);
      const newState = {
        jobId,
        ConnectedComponent: jobId
          ? withApplications({
              ...args,
              jobId,
            })(WrappedComponent)
          : null,
      };
      this.setState(newState);
    }

    componentWillReceiveProps(nextProps) {
      const newJobId = mapPropsToId(nextProps);
      const { jobId } = this.state;
      if (newJobId && newJobId !== jobId) {
        this.setState({
          jobId: newJobId,
          ConnectedComponent: withApplications({
            ...args,
            jobId: newJobId,
          })(WrappedComponent),
        });
      }
    }

    render() {
      const { ConnectedComponent } = this.state;
      return ConnectedComponent ? (
        <ConnectedComponent {...this.props} />
      ) : (
        <Loading />
      );
    }
  }

  return WithApplications;
};
