import actions from "admin/actions/search-history";
import { handleActions } from "redux-actions";
import languageOptions from "hiringagent-dashboard/constants/languageOptions";
import milesOptions from "hiringagent-dashboard/constants/milesOptions";
import stateOptions from "hiringagent-dashboard/constants/stateOptions";
import yearsExperienceOptions from "hiringagent-dashboard/constants/yearsExperienceOptions";

const initialState = {
  languageOptions,
  milesOptions,
  stateOptions,
  yearsExperienceOptions,
  practiceAreaOptions: [],
  practiceAreasLoading: false,
  selectedPracticeAreaId: null,
  userType: null,
  isLoading: null,
  searchHistories: [],
  searchHistoriesLoaded: null,
  haeUsersLoading: false,
  haeUsersLoaded: false,
  haeUserOptions: [],
  searchParams: {
    haeuser_id: "",
    start_date: null,
    end_date: null,
    years_experience: null,
    location: null,
    miles: null,
    licensed_in: null,
    proficient_in: null,
    keywords: null,
    practice_areas: null,
    rate: null,
    within: null,
    telecommute_only: false,
    irs_w2: false,
    irs_1099: false,
  },
};
const sortByLabel = payload =>
  payload.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) return -1;
    if (labelB < labelA) return 1;
    return 0;
  });

const searchHistories = handleActions(
  {
    [actions.SET_USER_TYPE]: (state, action) => ({
      ...initialState,
      userType: action.payload,
    }),
    [actions.REQUEST_USERS]: state => ({
      ...state,
      haeUsersLoading: true,
    }),
    [actions.RECEIVE_USERS]: (state, action) => {
      const haeUserOptions = sortByLabel(
        action.payload.map(user => ({
          value: user.uuid,
          label: `${user.first_name} ${user.last_name}`,
        })),
      );
      return {
        ...state,
        haeUserOptions,
        haeUsersLoaded: true,
        haeUsersLoading: false,
      };
    },
    [actions.REQUEST_PRACTICE_AREAS_FOR_SEARCH_HISTORY]: state => ({
      ...state,
      practiceAreasLoading: true,
    }),
    [actions.RECEIVE_PRACTICE_AREAS_FOR_SEARCH_HISTORY]: (state, action) => {
      const practiceAreaOptions = sortByLabel(
        action.payload.results.map(user => ({
          value: user.uuid,
          label: user.name,
        })),
      );
      return {
        ...state,
        practiceAreaOptions,
        practiceAreasLoaded: true,
        practiceAreasLoading: false,
      };
    },
    [actions.REQUEST_SEARCH_HISTORIES]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_SEARCH_HISTORIES]: (state, action) => ({
      ...state,
      isLoading: false,
      searchHistories: action.payload,
      searchHistoriesLoaded: true,
    }),
    [actions.CHANGE_SEARCH_PARAMS]: (state, action) => {
      const newSearchParameter = action.payload;
      const key = Object.keys(newSearchParameter)[0];
      const value = Object.values(newSearchParameter)[0];
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          [key]: value,
        },
      };
    },
  },
  initialState,
);
export default searchHistories;
