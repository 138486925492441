export const VERIFICATION_STATUS = {
  NOT_STARTED: -2,
  REJECTED: -1,
  PENDING: 0,
  VERIFIED: 1,
};

export const verificationStatusMapping = {
  [VERIFICATION_STATUS.NOT_STARTED]: {
    label: "Not Started",
    key: "notStarted",
    bsStyle: "default",
  },
  [VERIFICATION_STATUS.REJECTED]: {
    label: "Rejected",
    key: "rejected",
    bsStyle: "danger",
  },
  [VERIFICATION_STATUS.PENDING]: {
    label: "Pending",
    key: "pending",
    bsStyle: "info",
  },
  [VERIFICATION_STATUS.VERIFIED]: {
    label: "Verified",
    key: "verified",
    bsStyle: "success",
  },
};

export const getNonAttorneyVerificationStatus = ({
  rejected,
  is_verified,
  old_is_verified,
  review_requests,
  schools,
  created,
}) => {
  if (rejected) return -1;
  if (is_verified) return 1;

  const references = review_requests.filter(request => request.review != null);
  const approvedReferences = references.filter(
    request => request.review.approval_status == 3,
  );
  const rejectedReferences = references.filter(
    request => request.review.approval_status == 2,
  );
  const pendingReferences = references.filter(
    request => request.review.approval_status == 1,
  );

  const relatedReferences = references.filter(review_request => {
    if (!review_request.related_previous_employment_data) {
      return false;
    }
    if (!review_request.related_previous_employment_data.end_date) {
      return true;
    }
    const fiveYearsEarlier = new Date(created);
    fiveYearsEarlier.setFullYear(fiveYearsEarlier.getFullYear() - 5);
    const end_date = new Date(
      review_request.related_previous_employment_data.end_date,
    );
    return end_date >= fiveYearsEarlier;
  });

  const approvedRelatedReferences = approvedReferences.filter(value =>
    relatedReferences.includes(value),
  );
  const rejectedRelatedReferences = rejectedReferences.filter(value =>
    relatedReferences.includes(value),
  );
  const pendingRelatedReferences = pendingReferences.filter(value =>
    relatedReferences.includes(value),
  );

  const approvedSchools = schools.filter(school => school.status == 1);
  const rejectedSchools = schools.filter(school => school.status == -1);
  const pendingSchools = schools.filter(school => school.status == 0);

  //RULES FOR THOSE CREATED BEFORE NEW ONBOARDING

  if (created <= "2019-07-12 00:00:00") {
    if (
      approvedSchools.length > 0 &&
      approvedReferences.length > 2 //&&
      //approvedRelatedReferences.length > 1
    ) {
      return 1;
    }

    if (
      approvedSchools.length + pendingSchools.length > 0 &&
      approvedReferences.length + pendingReferences.length > 2 //&&
      //approvedRelatedReferences.length + pendingRelatedReferences.length > 1
    ) {
      return 0;
    }

    if (
      rejectedSchools.length > 0 ||
      rejectedReferences.length > 0 //||
      //rejectedRelatedReferences.length > 0
    ) {
      return -1;
    }

    return -2;
  }

  //RULES FOR THOSE CREATED AFTER NEW ONBOARDING
  if (
    approvedSchools.length > 0 &&
    approvedReferences.length > 2 &&
    approvedRelatedReferences.length > 1
  ) {
    return 1;
  }

  if (
    approvedSchools.length + pendingSchools.length > 0 &&
    approvedReferences.length + pendingReferences.length > 2 &&
    approvedRelatedReferences.length + pendingRelatedReferences.length > 1
  ) {
    return 0;
  }

  if (
    rejectedSchools.length > 0 ||
    rejectedReferences.length > 0 ||
    rejectedRelatedReferences.length > 0
  ) {
    return -1;
  }

  return -2;
};
