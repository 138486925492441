import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import { initialize, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import ListSchools from "./ListSchools";
import { submitTranscriptFile } from "contractor/actions/onboarding";
import { validateRequiredFields } from "common/utils/forms";
import { withDegreeTypes } from "common/connectors";
import { withOwnContractor, withSchools } from "contractor/connectors";
import SchoolForm, {
  formName,
  requiredFields,
} from "contractor/forms/SchoolForm";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import {
  removeTranscript,
  submitCreateSchool,
  submitDeleteSchool,
  submitUpdateSchool,
} from "contractor/actions/profile";

const ConfirmDeleteSchoolModal = ({ school, onClose, onSubmit, show }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>Confirm Delete School</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4 className="text-center">
        Are you sure you want to delete the following school?
      </h4>
      {school && (
        <dl className="dl-horizontal">
          <dt>School Name</dt>
          <dd>{school.school_name}</dd>
          <dt>Degree</dt>
          <dd>{school.degree}</dd>
          <dt>Year Graduated</dt>
          <dd>{school.year_graduated}</dd>
          <dt>GPA</dt>
          <dd>{school.gpa}</dd>
        </dl>
      )}
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar>
        <Button bsStyle="link" onClick={onClose}>
          Cancel
        </Button>
        <Button bsStyle="danger" onClick={onSubmit} className="pull-right">
          Delete
        </Button>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>
);

class EditSchools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletingSchoolId: null,
      editingSchoolId: null,
    };
  }

  handleAddSchoolClick = () => {
    this.props.initializeForm();
    this.setState({ editingSchoolId: null });
  };

  handleDeleteSchoolClick = schoolId =>
    this.setState({ deletingSchoolId: schoolId });

  handleDeleteSchoolCloseClick = () =>
    this.setState({ deletingSchoolId: null });

  handleDeleteSchoolConfirmClick = () => {
    const { notify, onReload, submitDeleteSchool } = this.props;
    const { deletingSchoolId } = this.state;
    submitDeleteSchool(deletingSchoolId).then(() => {
      this.setState({ deletingSchoolId: null });
      onReload();
    });
    notify(createSuccessNotification({ message: "School has been deleted." }));
  };

  handleEditSchoolClick = schoolId =>
    this.setState({ editingSchoolId: schoolId });

  handleSubmit = values => {
    const {
      initializeForm,
      notify,
      onReload,
      submitCreateSchool,
      submitUpdateSchool,
    } = this.props;
    const { editingSchoolId } = this.state;
    const args = editingSchoolId ? [editingSchoolId, values] : [values];
    const action = editingSchoolId ? submitUpdateSchool : submitCreateSchool;
    return action(...args).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      notify(
        createSuccessNotification({
          message: editingSchoolId
            ? "School has been updated."
            : "New school has been saved.",
        }),
      );
      initializeForm();
      this.setState({ editingSchoolId: null });
      onReload();
      this.submitTranscript(json.uuid);
    });
  };

  submitTranscript = schoolId => {
    const {
      ownContractor: { data: contractor, ready },
      schools: { transcript },
      removeTranscript,
      submitTranscriptFile,
    } = this.props;
    if (ready && !contractor.is_attorney && transcript) {
      const fd = new FormData();
      fd.append("transcript", transcript);
      submitTranscriptFile(`/api/v2/schools/${schoolId}/add_transcript/`, fd);
      removeTranscript();
    }
  };

  validate = values => validateRequiredFields(requiredFields, values);

  renderEditSchool() {
    const {
      schools: {
        data: { results: schools },
      },
      degreeTypes: {
        data: { results: degreeTypes },
      },
    } = this.props;
    const { editingSchoolId } = this.state;
    const editingSchool = schools.find(
      school => school.uuid === editingSchoolId,
    );
    let initialValues = {};
    if (editingSchool) {
      initialValues.degree_type = degreeTypes.find(
        degreeType => degreeType.label === editingSchool.degree,
      ).value;
    }
    return (
      <div>
        <h2>
          Edit School
          <Button
            bsSize="xs"
            bsStyle="success"
            style={{ marginLeft: "1rem" }}
            onClick={this.handleAddSchoolClick}
          >
            <i className="far fa-plus" />
            &nbsp;Add new school
          </Button>
        </h2>
        <SchoolForm
          key={`edit-school-${editingSchoolId}`}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validate={this.validate}
        />
      </div>
    );
  }

  renderAddSchool() {
    return (
      <div>
        <h2>Add New School</h2>
        <SchoolForm
          key="add-school"
          onSubmit={this.handleSubmit}
          validate={this.validate}
        />
      </div>
    );
  }

  render() {
    const {
      schools: {
        data: { results = [] },
        isLoading: schoolsLoading,
        lastUpdated: schoolsLastUpdated,
      },
      degreeTypes: {
        isLoading: degreeTypesLoading,
        lastUpdated: degreeTypesLastUpdated,
      },
    } = this.props;
    const { deletingSchoolId, editingSchoolId } = this.state;
    const isLoading = schoolsLoading || degreeTypesLoading;
    const lastUpdated = schoolsLastUpdated && degreeTypesLastUpdated;
    return (
      <div>
        <h2 className="edit-section-title">Schools</h2>
        <div>
          <ListSchools
            onDeleteClick={this.handleDeleteSchoolClick}
            onEditClick={this.handleEditSchoolClick}
          />
          <hr />
          {editingSchoolId && !isLoading && lastUpdated
            ? this.renderEditSchool()
            : this.renderAddSchool()}
        </div>
        <ConfirmDeleteSchoolModal
          show={Boolean(deletingSchoolId)}
          onSubmit={this.handleDeleteSchoolConfirmClick}
          onClose={this.handleDeleteSchoolCloseClick}
          school={results.find(c => c.uuid === deletingSchoolId)}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      initializeForm: () => initialize(formName),
      notify,
      removeTranscript,
      submitCreateSchool,
      submitDeleteSchool,
      submitTranscriptFile,
      submitUpdateSchool,
    },
  ),
  withDegreeTypes(),
  withOwnContractor(),
  withSchools(),
)(EditSchools);
