import React, { Fragment } from "react";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import FieldHelpBlock from "./FieldHelpBlock";

import { domOnlyProps, validationState } from "common/utils/forms";

// Can receive custom 'horizontal' prop for alternate style on large screens

export default props => {
  const {
    // redux-form Field props
    input,
    meta,
    // FormControlGroup props
    label,
    type,
    componentClass,
    placeholder,
    children,
    buttonLeft,
    buttonRight,
    addonLeft,
    addonRight,
    bsSize,
    maxLength = null,
    // custom props
    onChangeAction,
    horizontal,
    // props passed through to FormControl
    ...otherProps
  } = props;

  // This was causing problems on inputs that can be set to null. Doing so would cause their values to be
  // overwritten as '' instead of null. This could have unintended side effects that we should look out for.
  // input.value = input.value || ''

  const onChange = onChangeAction
    ? e => {
        input.onChange(e.target.value);
        setTimeout(onChangeAction, 500);
      }
    : input.onChange;

  const formControl = (
    <FormControl
      type={type}
      componentClass={componentClass}
      placeholder={placeholder}
      {...domOnlyProps(input)}
      {...domOnlyProps(otherProps)}
      onChange={onChange}
    >
      {children}
    </FormControl>
  );

  const formMain = (
    <Fragment>
      {addonLeft || addonRight || buttonLeft || buttonRight ? (
        <InputGroup>
          {addonLeft && <InputGroup.Addon>{addonLeft}</InputGroup.Addon>}
          {buttonLeft && <InputGroup.Button>{buttonLeft}</InputGroup.Button>}
          {formControl}
          {buttonRight && <InputGroup.Button>{buttonRight}</InputGroup.Button>}
          {addonRight && <InputGroup.Addon>{addonRight}</InputGroup.Addon>}
        </InputGroup>
      ) : (
        formControl
      )}
      {maxLength && (
        <div className="text-right">
          {input.value.length}/{maxLength}
        </div>
      )}
      <FieldHelpBlock touched={meta.touched} error={meta.error} />
    </Fragment>
  );

  return (
    <FormGroup
      validationState={validationState(meta, maxLength)}
      bsSize={bsSize}
    >
      {label &&
        (horizontal ? (
          <Col componentClass={ControlLabel} sm={2}>
            {label}
          </Col>
        ) : (
          <ControlLabel>{label}</ControlLabel>
        ))}
      {horizontal ? <Col sm={10}>{formMain}</Col> : formMain}
    </FormGroup>
  );
};
