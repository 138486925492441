import React from "react";

import RadioGroup from "./blocks";

const defaultOptions = {
  strongly_agree: "5",
  agree: "4",
  neutral: "3",
  disagree: "2",
  strongly_disagree: "1",
};

const RadioInputGroup = ({ handleUpdateProgress, name, responseOptions }) => {
  const options = responseOptions || defaultOptions;
  const numOptions = Object.keys(options).length;

  return (
    <RadioGroup numOptions={numOptions}>
      {Object.keys(options).map((option, key) => (
        <RadioGroup.Radio key={key}>
          <RadioGroup.RadioField
            component="input"
            name={name}
            onChange={handleUpdateProgress}
            type="radio"
            value={options[option]}
          />
          <RadioGroup.RadioOverlay>
            <RadioGroup.RadioCaption>
              {responseOptions
                ? options[option]
                    .toString()
                    .split("_")
                    .join(" ")
                : option.split("_").join(" ")}
            </RadioGroup.RadioCaption>
          </RadioGroup.RadioOverlay>
        </RadioGroup.Radio>
      ))}
    </RadioGroup>
  );
};

export default RadioInputGroup;
