import React from "react";
import { Button } from "react-bootstrap";

import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";
import { ExperienceListInfo } from "contractor/components/profile/experience/ListExperience";

const DeleteExperienceModal = ({ experience, onClose, onSubmit, show }) => {
  const body = !experience ? (
    ""
  ) : (
    <dl className="dl-horizontal" key={`experience-${experience.uuid}`}>
      <ExperienceListInfo experience={experience} />
    </dl>
  );
  const deleteButton = (
    <Button bsStyle="danger" onClick={onSubmit} className="pull-right">
      Delete
    </Button>
  );

  return (
    <ConfirmModal
      show={show}
      close={onClose}
      title={"Confirm Delete Experience"}
      body={body}
      actions={deleteButton}
    />
  );
};

export default DeleteExperienceModal;
