import React, { Component } from "react";
import { Grid } from "react-bootstrap";

import { BarInformationsTable } from "admin/components/attorney-verification-manager";
import { createPaginationForForm } from "admin/components/attorney-verification-manager/BarInformationsTable";
import {
  pendingBarInformationInitialParams,
  withPendingBarInformations,
} from "admin/connectors";
import { VerificationSearchForm } from "admin/forms";

const formName = "pendingBarInformationSearchForm";
const Pagination = createPaginationForForm(formName);

class PendingBarInformations extends Component {
  handleOffsetChange = offset => {
    this.props.changeFieldValue("offset", offset);
    setTimeout(this.props.submitSearchForm);
  };

  handleSubmit = values => {
    this.props.onSearchSubmit({ params: values });
  };

  render() {
    const {
      pendingBarInformations,
      pendingBarInformations: {
        data: { count, results },
        ready,
      },
    } = this.props;
    const initialFormData = {
      ...pendingBarInformationInitialParams,
      text: "",
      start_date: null,
      end_date: null,
      offset: 0,
    };
    return (
      <Grid fluid>
        <h2>
          Pending Bar Licenses{" "}
          {ready && (
            <small>
              showing {results.length} / {count}
            </small>
          )}
        </h2>
        <VerificationSearchForm
          form={formName}
          onSubmit={this.handleSubmit}
          initialValues={initialFormData}
        />
        <BarInformationsTable
          barInformations={pendingBarInformations}
          type="pending"
        />
        {ready && (
          <Pagination onOffsetChange={this.handleOffsetChange} length={count} />
        )}
      </Grid>
    );
  }
}

export default withPendingBarInformations()(PendingBarInformations);
