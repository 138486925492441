import styled from "styled-components";

const GoBackContainer = styled.div`
  margin-bottom: 1rem;
  a {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export default GoBackContainer;
