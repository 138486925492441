import { mutateResourceQuery } from "queries";
import types from "resources/types";

export const checkDiscountCodeStatusQuery = discountCode =>
  mutateResourceQuery(
    types.DISCOUNT_CODES,
    {
      url: "/api/v2/check_discount_code_status/",
      options: {
        method: "POST",
      },
    },
    { code: discountCode },
  );

export const applyDiscountCodeQuery = discountCode =>
  mutateResourceQuery(
    types.DISCOUNT_CODES,
    {
      url: "/api/v2/apply_discount_code/",
      options: {
        method: "POST",
      },
    },
    { code: discountCode },
  );

export const sendReferralCodeEmailQuery = data =>
  mutateResourceQuery(
    types.REFERRAL_EMAILS,
    {
      url: "/api/v2/referral_emails/",
      options: {
        method: "POST",
      },
    },
    data,
  );
