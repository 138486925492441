import styled from "styled-components";

const FootingLink = styled.span`
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1px;
`;

export default FootingLink;
