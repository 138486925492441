import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { DollarPerHourInput } from "common/components/forms/inputs";

export const formName = "rateInfoForm";
export const requiredFields = [
  "dollar_per_hr_mode",
  "dollar_per_hr_low",
  "dollar_per_hr_high",
];

class RateInfoForm extends Component {
  render() {
    return (
      <div>
        <DollarPerHourInput
          name="dollar_per_hr_mode"
          label="Typical Rate"
          placeholder="60"
        />
        <DollarPerHourInput
          name="dollar_per_hr_low"
          label="Rarely Below"
          placeholder="40"
        />
        <DollarPerHourInput
          name="dollar_per_hr_high"
          label="Rarely Above"
          placeholder="90"
        />
      </div>
    );
  }
}

RateInfoForm = reduxForm({ form: formName })(RateInfoForm);

export default RateInfoForm;
