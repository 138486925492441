import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

import { lessThanOneMonthAgo } from "common/utils/helpers";
import { USState } from "common/components";
import RatingInput from "common/components/forms/RatingInput";
import ToolTip from "common/components/ToolTip";
import VerifiedLabel from "hiringagent-dashboard/components/utils/VerifiedLabel";
import AttorneyNameIcon from "hiringagent-dashboard/components/attorney/AttorneyNameIcon";
import AttorneyProfilePicture from "hiringagent-dashboard/components/search/AttorneyProfilePicture";

const Name = styled.h1`
  margin-top: 0;
  display: inline-block;
  font-weight: bold;
`;

const Location = styled.h3`
  color: ${({ theme }) => theme.colors.primary_black40()};
`;

class ContractorProfileInfoHeader extends Component {
  static propTypes = {
    contractor: PropTypes.object.isRequired,
    isOwnContractor: PropTypes.bool,
    isPublicProfile: PropTypes.bool,
    isSharedProfile: PropTypes.bool,
  };

  static defaultProps = {
    isOwnContractor: false,
    isPublicProfile: false,
    isSharedProfile: false,
  };

  render() {
    const {
      contractor,
      isOwnContractor,
      isPublicProfile,
      isSharedProfile,
    } = this.props;
    const contractorType = contractor.is_attorney
      ? "Attorney"
      : "Legal Professional";
    const contractorNameDisplay =
      contractor.full_name && !isPublicProfile
        ? contractor.full_name
        : `Hire an Esquire ${contractorType}`;
    const showProfilePicture =
      (isOwnContractor || isSharedProfile) && !isPublicProfile;

    return (
      <Row>
        <Col sm={2}>
          {/* TODO: Style picture to be size=100 for mobile screen */}
          <div className="profile-picture-component-container">
            <AttorneyProfilePicture
              size={130}
              pictureUrl={contractor.profile_picture_url}
              firstName={contractor.first_name}
              lastName={contractor.last_name}
              showPicture={showProfilePicture}
              uuid={contractor.uuid}
            />
          </div>
        </Col>
        <Col sm={7}>
          <div className="attorney-headers">
            <span>
              <span
                className="hidden"
                itemProp="memberOf"
                itemScope
                itemType="http://schema.org/Organization"
              >
                Hire an Esquire
              </span>
              <span className="hidden" itemProp="hasOccupation">
                legal professional
              </span>
            </span>
            <AttorneyNameIcon
              isAttorney={contractor.is_attorney}
              className="attorney-title-large"
            >
              <Name>
                <span itemProp="name">{contractorNameDisplay}</span>
              </Name>
            </AttorneyNameIcon>
            {contractor.summary_title && (
              <h3 itemProp="description">{contractor.summary_title}</h3>
            )}
            {contractor.highest_degree && (
              <h3 className="hidden-xs">{contractor.highest_degree}</h3>
            )}
            {contractor.location && (
              <Location>
                <i className="fas fa-map-marker-alt" />
                <span itemProp="homeLocation workLocation">
                  &nbsp;&nbsp;{`${contractor.location.city}, `}
                  <USState stateId={contractor.location.state} />
                </span>
              </Location>
            )}
          </div>
        </Col>
        <Col sm={3}>
          <div className="attorney-subheaders">
            {!isOwnContractor &&
              contractor.display_score &&
              contractor.display_score.num_reviews >= 3 && (
                <div className="attorney-display_score hidden-xs">
                  <RatingInput
                    value={contractor.display_score.score}
                    num_reviews={contractor.display_score.num_reviews}
                  />
                </div>
              )}
            <h2 className="years-experience hidden-xs">
              {contractor.years_work_experience}
              <span className="muted">&nbsp;Years Experience</span>
            </h2>
            {contractor.bar_information && (
              <h2 className="license-states">
                <span className="muted">Licensed in </span>
                {contractor.bar_information
                  .filter(license => license.verified === 1)
                  .map(license => license.state)
                  .sort()
                  .join(", ")}
              </h2>
            )}
            {contractor.rate && (
              <ToolTip
                content="Rate does not include HaE fees."
                placement="top"
                hasChild={true}
              >
                <h2 className="attorney-rate">
                  {`$${contractor.rate.dollar_per_hr_low} - ${contractor.rate.dollar_per_hr_high} / hour`}
                  &nbsp;
                  <span style={{ fontWeight: "bold", fontSize: "2.3rem" }}>
                    *
                  </span>
                </h2>
              </ToolTip>
            )}
            {!isOwnContractor &&
              contractor.display_score &&
              contractor.display_score.num_reviews >= 3 && (
                <div className="attorney-display_score visible-xs-inline-block">
                  <RatingInput
                    value={contractor.display_score.score}
                    num_reviews={contractor.display_score.num_reviews}
                  />
                </div>
              )}
            {lessThanOneMonthAgo(contractor.last_activity_at) && (
              <h2 className="license-states">
                Active: {moment(contractor.last_activity_at).fromNow()}
              </h2>
            )}
            {!isOwnContractor && contractor.is_available && (
              <div>
                <VerifiedLabel
                  type="available"
                  placement="left"
                  contractorName={contractor.first_name}
                  style={{ fontWeight: "bold" }}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default ContractorProfileInfoHeader;
