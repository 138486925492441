import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { TextAreaField, TextField } from "common/components/forms/inputs";

export const formName = "headlineForm";
export const requiredFields = ["summary_title", "summary"];

export class HeadlineForm extends Component {
  render() {
    return (
      <div>
        <TextField
          name="summary_title"
          label="Headline"
          placeholder="E.g. Senior Transactional Attorney - Technology & IP"
        />
        <TextAreaField
          name="summary"
          label="Summary"
          rows={8}
          maxLength={500}
        />
      </div>
    );
  }
}

export default reduxForm({ form: formName })(HeadlineForm);
