import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { addNotification } from "reapop";

import { PasswordRequirements } from "common/components/ChangePassword";
import { PasswordInputsWithReveal } from "common/components/forms/inputs";
import { Error } from "common/utils/forms/validators";
import { validatePasswords, validateRequiredFields } from "common/utils/forms";
import { createErrorNotification } from "common/utils/notifications";
import { createQueryConfig } from "queries";
import { logout } from "queries/mutations/auth";
import { connect, mutateAsync } from "queries/utils";

const requiredFields = ["new_password1", "new_password2"];

const validate = values => {
  let errors = validateRequiredFields(requiredFields, values);
  errors = validatePasswords(values, errors, "new_password1", "new_password2");
  return errors;
};

class ResetPasswordForm extends Component {
  render() {
    const { error, handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {error && <Alert bsStyle="danger">{error}</Alert>}
        <Row>
          <Col md={7}>
            <PasswordInputsWithReveal />
          </Col>
          <Col md={5}>
            <PasswordRequirements />
          </Col>
        </Row>
        <div className="text-center">
          <Button type="submit" bsStyle="success" disabled={submitting}>
            Reset Password
          </Button>
        </div>
      </form>
    );
  }
}

ResetPasswordForm = reduxForm({
  form: "resetPasswordForm",
  validate,
})(ResetPasswordForm);

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitSucceeded: false,
    };
  }

  componentDidMount() {
    this.props.onLoad();
  }

  handleSubmit = async values => {
    const { notify, resetPassword } = this.props;
    const response = await resetPassword(values);

    const { body = {}, status } = response;
    if (status >= 200 && status < 300) {
      this.setState({ submitSucceeded: true });
    } else if (status >= 400 && status < 600) {
      if (["token", "uid"].some(k => Object.keys(body).includes(k))) {
        body._error = (
          <Error>
            We&apos;re sorry, but the reset link you attempted to use has
            expired or been invalidated. Please try requesting another password
            reset <Link to="/public/forgot-password">here</Link>.
          </Error>
        );
      } else {
        notify(createErrorNotification());
        body._error = (
          <Error>
            There was a problem submitting your request. Please try again.
          </Error>
        );
      }
      throw new SubmissionError(body);
    }
  };

  renderForm = () => {
    const {
      match: {
        params: { token, uid },
      },
    } = this.props;
    return (
      <Fragment>
        <h2 style={{ marginTop: "0" }}>Choose a new password</h2>
        <p>
          Please enter a new password to use with your Hire an Esquire account.
        </p>
        <ResetPasswordForm
          onSubmit={this.handleSubmit}
          initialValues={{ token, uid }}
        />
      </Fragment>
    );
  };

  renderSuccessMsg = () => (
    <Fragment>
      <h2 style={{ marginTop: "0" }}>Your password has been reset!</h2>
      <p>
        Please <Link to="/login">log in</Link> with your new password to
        continue.
      </p>
    </Fragment>
  );

  render() {
    return (
      <Row>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>
        <Col md={6} mdOffset={3}>
          {!this.state.submitSucceeded
            ? this.renderForm()
            : this.renderSuccessMsg()}
        </Col>
      </Row>
    );
  }
}

export default connect(
  null,
  {
    resetPassword: data =>
      mutateAsync(
        createQueryConfig({
          url: "/api/auth/password/reset/confirm/",
          body: data,
        }),
      ),
    notify: addNotification,
    onLoad: logout,
  },
)(ResetPassword);
