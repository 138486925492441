import React from "react";

import { SelectField } from ".";
import { JobListing } from "types";

const HoursPerWeekSelectField = () => (
  <SelectField
    name="hours_per_week"
    label="Estimated number of hours per week the contractor will be expected to work"
  >
    {JobListing.hoursPerWeekOrdering.map(key => (
      <option
        key={`hours-per-week-option-${key}`}
        value={Number.parseInt(key, 10)}
      >
        {JobListing.hoursPerWeekOptions[key]}
      </option>
    ))}
  </SelectField>
);

export default HoursPerWeekSelectField;
