import React from "react";

import { ReactSelectField } from "common/components/forms/inputs";
import { connectConnectRequest } from "queries/utils";
import { tagsQuery } from "queries/requests";
import { createResourceSelector } from "queries";
import types from "resources/types";

const ExpertiseAreasSelectField = props => {
  const {
    [types.TAGS]: {
      data,
      isFinished,
      isPending,
      query: { data: queryData },
    },
    ...otherProps
  } = props;
  const options = isFinished
    ? queryData.map(tagId => ({
        value: tagId,
        label: data[tagId].name,
      }))
    : [];
  return (
    <ReactSelectField
      name="expertisearea_set"
      isLoading={isPending}
      multi={true}
      {...otherProps}
      options={options}
    />
  );
};

const mapPropsToConfig = tagsQuery;
const mapStateToProps = createResourceSelector(types.TAGS, mapPropsToConfig);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  ExpertiseAreasSelectField,
);
