import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { initialize, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";
import { Button } from "react-bootstrap";

import { withLanguageFluency } from "contractor/connectors";
import {
  submitCreateLanguage,
  submitDeleteLanguage,
  submitUpdateLanguage,
} from "contractor/actions/profile";
import { validateRequiredFields } from "common/utils/forms";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import EditLanguagesForm, {
  formName,
  requiredFields,
} from "contractor/forms/profile/EditLanguagesForm";
import ListLanguages from "contractor/components/profile/languages/ListLanguages";

class EditLanguages extends Component {
  state = {
    editLanguageId: null,
  };

  _clearForm = () => {
    this.props.initializeForm();
    this.setState({ editLanguageId: null });
  };

  handleAddLanguageClick = () => {
    this._clearForm();
  };

  handleDeleteLanguageClick = languageId => {
    const { submitDeleteLanguage, onReload, notify } = this.props;
    submitDeleteLanguage(languageId).then(() => {
      notify(
        createSuccessNotification({ message: "Language has been deleted." }),
      );
      onReload();
      this._clearForm();
    });
  };

  handleEditLanguageClick = languageId =>
    this.setState({ editLanguageId: languageId });

  handleSubmit = values => {
    const {
      submitCreateLanguage,
      submitUpdateLanguage,
      onReload,
      notify,
    } = this.props;
    const { editLanguageId } = this.state;
    const action = editLanguageId ? submitUpdateLanguage : submitCreateLanguage;
    const args = editLanguageId ? [editLanguageId, values] : [values];
    const successMsg = editLanguageId
      ? "Language has been updated."
      : "New language has been saved.";

    return action(...args).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      notify(createSuccessNotification({ message: successMsg }));
      this._clearForm();
      onReload();
    });
  };

  validate = values => {
    const { editLanguageId } = this.state;
    const {
      languageFluency: {
        data: { results = [] },
      },
    } = this.props;
    const savedItemWithSameLang = results.find(
      lang =>
        lang.language_uuid === values.language && lang.uuid !== editLanguageId,
    );

    const errors = validateRequiredFields(requiredFields, values);
    if (savedItemWithSameLang) {
      errors.language =
        "You have already saved this language. Edit or delete it in the list above.";
    }
    return errors;
  };

  renderAddLanguage = () => (
    <div>
      <h2 className="edit-section-title">Add Language</h2>
      <EditLanguagesForm
        onSubmit={this.handleSubmit}
        validate={this.validate}
      />
    </div>
  );

  renderEditLanguage = () => {
    const {
      languageFluency: {
        data: { results = [] },
      },
    } = this.props;
    const { editLanguageId } = this.state;
    const languageToEdit = results.find(lang => lang.uuid === editLanguageId);
    const { language_uuid: language, fluency } = languageToEdit;

    return (
      <div>
        <h2 className="edit-section-title">
          Edit Language
          <Button
            bsSize="xs"
            bsStyle="success"
            style={{ marginLeft: "1rem" }}
            onClick={this.handleAddLanguageClick}
          >
            <i className="far fa-plus" />&nbsp;Add new language
          </Button>
        </h2>
        <EditLanguagesForm
          key={`edit-language-${editLanguageId}`}
          onSubmit={this.handleSubmit}
          initialValues={{ language, fluency }}
          validate={this.validate}
        />
      </div>
    );
  };

  render() {
    const { editLanguageId } = this.state;
    return (
      <div>
        <h2 className="edit-section-title" style={{ marginBottom: "3rem" }}>
          Languages
        </h2>
        <ListLanguages
          onDeleteClick={this.handleDeleteLanguageClick}
          onEditClick={this.handleEditLanguageClick}
        />
        <hr />
        {editLanguageId ? this.renderEditLanguage() : this.renderAddLanguage()}
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      initializeForm: () => initialize(formName),
      notify,
      submitCreateLanguage,
      submitDeleteLanguage,
      submitUpdateLanguage,
    },
  ),
  withLanguageFluency(),
)(EditLanguages);
