import { action, submitFile } from "common/actions/utils";

const options = {
  type: "ownContractor",
  actionType: "resource",
  version: "dev",
};

export const update = (contractorId, data) =>
  action({
    ...options,
    path: `contractors/${contractorId}`,
    method: "patch",
    data,
  });

export const updatePassword = data =>
  action({
    type: "ownContractorPassword",
    version: "dev",
    path: "contractors/update_password/",
    method: "post",
    data,
  });

export const deactivate = () =>
  action({
    type: "ownContractor",
    version: "dev",
    path: "contractors/deactivate/",
    method: "post",
  });

export const updateResume = submitFile([
  "REQUEST/ownContractorResume",
  "RECEIVE/ownContractorResume",
]);
