import React from "react";
import PropTypes from "prop-types";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const ReviewsTable = ({
  performanceReviews,
  statusFilter,
  onReviewButtonClick,
}) => (
  <div>
    {performanceReviews.length > 0 ? (
      <div>
        <Table className="reviews-table">
          <thead>
            <tr>
              <th>Job</th>
              <th>Date Closed</th>
              <th>Worker</th>
              {statusFilter === "pending" && <th />}
            </tr>
          </thead>
          <tbody>
            {Object.values(performanceReviews).map(review => (
              <tr key={`review-${review.uuid}`}>
                <td>
                  {review.worker.job ? (
                    <Link to={`/agency/jobs/${review.worker.job.uuid}/`}>
                      {review.worker.job.title}
                    </Link>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  {review.worker.job && review.worker.job.closed_date
                    ? moment(review.worker.job.closed_date).format("MM-DD-YYYY")
                    : "N/A"}
                </td>
                <td>
                  <Link to={`/agency/attorneys/${review.contractor.uuid}/`}>
                    {review.contractor.full_name}
                  </Link>
                </td>
                {statusFilter === "pending" && (
                  <td>
                    <Button
                      className="pull-right"
                      bsStyle="success"
                      onClick={() => onReviewButtonClick(review)}
                    >
                      Review
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    ) : (
      <p className="no-content-message">
        {`There are no ${statusFilter} reviews`}
      </p>
    )}
  </div>
);

ReviewsTable.propTypes = {
  performanceReviews: PropTypes.array.isRequired,
  statusFilter: PropTypes.string,
};
export default ReviewsTable;
