import types from "resources/types";
import { mutateResourceQuery } from "queries";
import {
  deleteResourceQuery as hookDeleteResourceQuery,
  mutateResourceQuery as hookMutateResourceQuery,
} from "queries/hooks";

// Generic
const resourceCreator = (resourceName, endpoint) => data =>
  hookMutateResourceQuery(
    resourceName,
    {
      url: { endpoint },
      options: {
        method: "POST",
      },
    },
    data,
  );
const resourceDeleter = (resourceName, endpoint) => id =>
  hookDeleteResourceQuery(
    resourceName,
    {
      url: { endpoint: `${endpoint}/${id}` },
      options: {
        method: "DELETE",
      },
    },
    id,
  );
const resourceUpdater = (resourceName, endpoint) => (id, data) =>
  hookMutateResourceQuery(
    resourceName,
    {
      url: { endpoint: `${endpoint}/${id}` },
      options: {
        method: "PATCH",
      },
    },
    data,
  );

export const updateHiringAgencyMarketingConversion = (id, data) =>
  mutateResourceQuery(
    types.HIRING_AGENCY_MARKETING_CONVERSIONS,
    {
      url: `/api/v2/hiring_agency_marketing_conversions/${id}/`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );

const updateNewFeatureNotification = resourceUpdater(
  types.NEW_FEATURE_NOTIFICATIONS,
  "new_feature_notifications",
);
export const newFeatureNotificationMutations = {
  update: updateNewFeatureNotification,
};
const createSavedContractor = resourceCreator(
  types.SAVED_CONTRACTORS,
  "saved_contractors",
);
const deleteSavedContractor = resourceDeleter(
  types.SAVED_CONTRACTORS,
  "saved_contractors",
);
const updateSavedContractor = resourceUpdater(
  types.SAVED_CONTRACTORS,
  "saved_contractors",
);
export const savedContractorMutations = {
  create: createSavedContractor,
  delete: deleteSavedContractor,
  update: updateSavedContractor,
};
export const createTalentPool = resourceCreator(
  types.TALENT_POOLS,
  "talent_pools",
);
export const deleteTalentPool = resourceDeleter(
  types.TALENT_POOLS,
  "talent_pools",
);
export const updateTalentPool = resourceUpdater(
  types.TALENT_POOLS,
  "talent_pools",
);
