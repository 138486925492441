import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
  static defaultProps = {
    copy: "Sorry, we couldn't find the page you were looking for.",
    text: "Back to Dashboard",
    to: "/",
  };

  static propTypes = {
    copy: PropTypes.string,
    text: PropTypes.string,
    to: PropTypes.string,
  };

  render() {
    const { copy, text, to } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h1>404: Not Found</h1>
            <p>{copy}</p>
            {to && (
              <p>
                <Link to={to} className="btn btn-info">
                  <i className="far fa-arrow-left" />
                  &nbsp;{text}
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
