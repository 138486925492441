import styled from "styled-components";

export const IconRemove = styled.i.attrs({
  className: "far fa-times",
  "aria-hidden": "true",
})`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary_black40()};
`;

export const IconCheckCircleBlock = styled.i.attrs({
  className: "far fa-check-circle",
  "aria-hidden": "true",
})`
  margin: 0;
  padding: 0;
  font-size: 24px;
  cursor: pointer;
`;

export const IconCircleBack = styled.i.attrs({
  className: "far fa-circle fa-stack-1x",
  "aria-hidden": "true",
})`
  margin: 0;
  padding: 0;
  font-size: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white()};
`;

export const IconCircleFront = styled.i.attrs({
  className: "far fa-circle fa-stack-1x",
  "aria-hidden": "true",
})`
  margin: 0;
  padding: 0;
  font-size: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary_black40()};
`;

export const IconLock = styled.i.attrs({
  className: "far fa-lock",
  "aria-hidden": "true",
})`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary_black60()};
`;
