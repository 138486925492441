import { createSelector, createStructuredSelector } from "reselect";

import types from "resources/types";

export {
  getMessages,
  makeGetMessageThread,
  makeGetMessageThreadMetadata,
} from "./messages";
export {
  makeGetJobListingById,
  makeGetJobListingMetadataById,
} from "./jobListings";

export const getIdFromParams = (state, props) => props.match.params.id;
export const getIdFromProps = (state, props) => props.jobId;

export const getCurrentUserType = createSelector(
  state => state.resources.currentUserType,
  currentUserType => currentUserType,
);

export const getJob = id =>
  createStructuredSelector({
    data: state => state.resourcesStore.jobs[id],
    metadata: state => state.resources.jobs,
  });

export const getMetadata = createSelector(
  state => state.resources.metadata,
  metadata => metadata,
);

export const getOwnUser = createSelector(
  state => state.resources.ownUser,
  ownUser => ownUser,
);

const getJobsStore = state => state.resourcesStore.jobs;
const getJobs = state => state.resources.jobs;

export const makeGetJob = () =>
  createSelector(
    [getJobsStore, getIdFromParams],
    (jobsStore, id) => jobsStore[id],
  );
export const makeGetJobMetadata = () =>
  createSelector([getJobs, getIdFromParams], jobs => jobs);

const getJobWorkersStore = state => state.resourcesStore.jobWorkers;
const getJobWorkers = state => state.resources.jobWorkers;

export const makeGetJobWorker = () =>
  createSelector([getJobWorkersStore, getIdFromParams], (jobWorkersStore, id) =>
    Object.values(jobWorkersStore).find(worker => worker.job.uuid === id),
  );
export const makeGetJobWorkerMetadata = () =>
  createSelector([getJobWorkers, getIdFromParams], jobWorkers => jobWorkers);

export const createResourceSelector = resourceType => {
  const getResourceStore = state => state.resourcesStore[resourceType];
  return () =>
    createSelector([getResourceStore], resourceStore => resourceStore);
};

export const createResourceMetadataSelector = resourceType => {
  const getResource = state => state.resources[resourceType];
  return () => createSelector([getResource], resource => resource);
};

export const resourceSelectors = Object.values(types).reduce(
  (res, val) => ({
    ...res,
    [val]: createStructuredSelector({
      data: createResourceSelector(val)(),
      metadata: createResourceMetadataSelector(val)(),
    }),
  }),
  {},
);
