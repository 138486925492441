import React from "react";

import { ControlLabel, FormGroup } from "react-bootstrap";
import { domOnlyProps, validationState } from "common/utils/forms";
import FieldHelpBlock from "./FieldHelpBlock";

export default ({ input, meta, label, ...otherProps }) => (
  <FormGroup validationState={validationState(meta)}>
    {label && <ControlLabel>{label}</ControlLabel>}
    <div className="text-center">
      <span className="radio">
        <input
          type="radio"
          value={0}
          className="radio-bubble"
          checked={input.value === 0}
          {...domOnlyProps(input)}
          {...domOnlyProps(otherProps)}
        />
        <label onClick={() => input.onChange(0)}>No</label>
        <span className="check" onClick={() => input.onChange(0)} />
      </span>
      <span className="radio">
        <input
          type="radio"
          value={1}
          className="radio-bubble"
          checked={input.value === 1}
          {...domOnlyProps(input)}
          {...domOnlyProps(otherProps)}
        />
        <label {...domOnlyProps(input)} onClick={() => input.onChange(1)}>
          Yes
        </label>
        <span
          className="check"
          {...domOnlyProps(input)}
          onClick={() => input.onChange(1)}
        />
      </span>
    </div>
    <FieldHelpBlock touched={meta.touched} error={meta.error} />
  </FormGroup>
);
