import actions from "hiringagent-dashboard/actions/sidebar";
import applicationActions from "hiringagent-dashboard/actions/applications";
import { handleActions } from "redux-actions";

const initialState = {
  hiringAgent: {},
  isHiringAgentLoading: false,
  menuItems: {
    dashboard: {
      name: "Dashboard",
      iconClassName: "fa-tachometer",
      subItems: [],
      url: "/agency",
      react: true,
      index: true,
    },
    candidates: {
      name: "Candidates",
      iconClassName: "fa-users",
      subItems: [],
      url: "/agency/candidates",
      react: true,
    },
    invoices: {
      name: "Invoices",
      iconClassName: "fa-file-alt",
      subItems: [],
      url: "/agency/invoices",
      react: true,
    },
    attorneys: {
      name: "Find Candidates",
      iconClassName: "fa-search",
      subItems: [],
      url: "/agency/search",
      react: true,
    },
    jobs: {
      name: "Jobs",
      iconClassName: "fa-briefcase",
      subItems: [],
      url: "/agency/jobs",
      react: true,
    },
    messages: {
      name: "Messages",
      iconClassName: "fa-envelope",
      subItems: [],
      url: "/agency/messages",
      react: true,
    },
    reviews: {
      name: "Reviews",
      iconClassName: "fa-thumbs-up",
      subItems: [],
      url: "/agency/reviews",
      react: true,
    },
    settings: {
      name: "Settings",
      iconClassName: "fa-cog",
      subItems: [],
      url: "/agency/settings",
      react: true,
    },
    logout: {
      name: "Logout",
      iconClassName: "fa-sign-out",
      subItems: [],
      url: "/logout",
      react: true,
    },
  },
};

const sidebar = handleActions(
  {
    [actions.REQUEST_HIRING_AGENT]: state => ({
      ...state,
      isHiringAgentLoading: true,
    }),
    [actions.RECEIVE_HIRING_AGENT]: (state, action) => ({
      ...state,
      hiringAgent: action.payload,
      isHiringAgentLoading: false,
    }),
    [actions.RECEIVE_NEW_SIDEBAR_ITEMS]: (state, action) => ({
      ...state,
      menuItems: {
        ...state.menuItems,
        candidates: {
          ...state.menuItems.candidates,
          newItems: action.payload.pending_candidates,
        },
        invoices: {
          ...state.menuItems.invoices,
          newItems: action.payload.unapproved_invoices,
        },
        messages: {
          ...state.menuItems.messages,
          newItems: action.payload.unread_messages,
        },
        reviews: {
          ...state.menuItems.reviews,
          newItems: action.payload.pending_reviews,
        },
      },
    }),
    [applicationActions.RECEIVE_CHANGE_APPLICATION_STATUS]: state => ({
      ...state,
      menuItems: {
        ...state.menuItems,
        applications: {
          ...state.menuItems.applications,
          newItems: state.menuItems.applications.newItems - 1,
        },
      },
    }),
  },
  initialState,
);

export default sidebar;
