import React, { Fragment } from "react";
import { Alert } from "react-bootstrap";

import DiscountCodesSettingsForm from "./DiscountCodesSettingsForm";
import { PromoCodesTable, ReferralCodesTable } from "./Tables";
import DiscountCodesTotals from "hiringagent-dashboard/components/settings/DiscountCodesSettings/utils";

export const AddSection = ({ discountCode, onSubmit }) => (
  <Fragment>
    <h2>Add</h2>
    <Alert style={{ marginBottom: "2rem" }}>
      <strong>Add and save:</strong> Have you received a promo or referral code?
      Enter the code here to add it. We will apply it to your next invoice! Note
      that you can only add one promo code at a time; any pending codes will be
      replaced when adding new codes.
    </Alert>
    <DiscountCodesSettingsForm
      onSubmit={onSubmit}
      discountCode={discountCode}
    />
  </Fragment>
);

export const HistorySection = ({ promoCodeHistory, referralCodeHistory }) => (
  <Fragment>
    <hr />
    <h2>History</h2>
    <DiscountCodesTotals />
    <br />
    <PromoCodesTable promoCodeHistory={promoCodeHistory} />
    <ReferralCodesTable referralCodeHistory={referralCodeHistory} />
  </Fragment>
);
