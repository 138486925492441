import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ListGroup, Panel } from "react-bootstrap";

import {
  JobWorkersList,
  LinkIfWorkerActive,
  PanelHeader,
  ViewPaymentHistoryLinkItem,
  workerNotActiveTooltip,
} from "./utils";

const WorkerActionsList = ({ job, jobWorker: { active }, paymentDetails }) => {
  const JobWorkerLink = props => (
    <LinkIfWorkerActive
      tooltip={workerNotActiveTooltip}
      active={active}
      {...props}
    />
  );

  return (
    <ul className="list-inline" style={{ marginBottom: 0 }}>
      {paymentDetails.isFixed && (
        <li>
          <JobWorkerLink
            to={`/contractor/jobs/${job}/mark_event/`}
            routerLink={true}
          >
            <span>
              <i className="far fa-list-alt" />&nbsp;View Billable Events
            </span>
          </JobWorkerLink>
        </li>
      )}
      {!paymentDetails.isFixed && (
        <li>
          <JobWorkerLink
            to={`/contractor/jobs/${job}/time_tracker/`}
            routerLink={true}
          >
            <span>
              <i className="far fa-clock" />&nbsp;View Time Tracker
            </span>
          </JobWorkerLink>
        </li>
      )}
      {paymentDetails.allow_expenses && (
        <li>
          <JobWorkerLink to={`/contractor/jobs/${job}/expenses/`}>
            <span>
              <i className="fas fa-dollar-sign" />&nbsp;View Expenses
            </span>
          </JobWorkerLink>
        </li>
      )}
      <li>
        <Link to={`/contractor/jobs/${job}/`}>
          <i className="far fa-newspaper" />&nbsp;Job Details
        </Link>
      </li>
    </ul>
  );
};

class PreviousJobsPanel extends Component {
  render() {
    const { jobWorkers, eventKey } = this.props;
    return (
      <Panel eventKey={eventKey}>
        <PanelHeader
          title="Previous Jobs"
          bsStyle="primary"
          count={jobWorkers.length}
        />
        <Panel.Collapse>
          <ListGroup>
            {jobWorkers.length > 0 && <ViewPaymentHistoryLinkItem />}
            <JobWorkersList
              panelType="previous"
              ComponentClass={WorkerActionsList}
              {...this.props}
            />
          </ListGroup>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default PreviousJobsPanel;
