// @flow strict
import styled from "styled-components";

const ItemText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 18px;
`;

export default ItemText;
