import React from "react";
import PropTypes from "prop-types";

const BooleanFilter = props => (
  <div className="checkbox">
    <label>
      <input type="checkbox" checked={props.active} onChange={props.onChange} />
      {props.label}
    </label>
  </div>
);

BooleanFilter.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

BooleanFilter.defaultProps = {
  active: false,
  label: "",
};

export default BooleanFilter;
