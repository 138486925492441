import styled from "styled-components";

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;

  & a:first-child {
    width: 100%;
  }
`;

export default Footer;
