import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import striptags from "striptags";
import moment from "moment";

import { verifiedSelector } from "hiringagent-dashboard/selectors";
import AttorneyProfilePicture from "./AttorneyProfilePicture";
import AttorneyPracticeAreasList from "./AttorneyPracticeAreasList";
import ExpandableText from "common/components/ExpandableText";
import RatingInput from "common/components/forms/RatingInput";
import VerifiedLabel from "hiringagent-dashboard/components/utils/VerifiedLabel";
import AttorneyNameIcon from "hiringagent-dashboard/components/attorney/AttorneyNameIcon";
import { lessThanOneMonthAgo } from "common/utils/helpers";

export class ContractorTitle extends Component {
  render() {
    const { contractor, verified } = this.props;

    return verified ? (
      <div>
        <Link to={`/agency/attorneys/${contractor.uuid}/`}>
          <AttorneyNameIcon
            isAttorney={contractor.contractor_type === "Attorney"}
            className="attorney-title-large"
          >
            <h1 className="attorney-name attorney-title">
              {contractor.first_name} {contractor.last_name}.
            </h1>
          </AttorneyNameIcon>
        </Link>
        <div className="search-result-title-labels">
          {contractor._is_via && <VerifiedLabel type="via" />}
          {contractor._index_is_available && (
            <VerifiedLabel
              type="available"
              contractorName={contractor.first_name}
            />
          )}
        </div>
      </div>
    ) : null;
  }
}

const ConnectedContractorTitle = connect(state => ({
  verified: verifiedSelector(state),
}))(ContractorTitle);

export default class AttorneySearchResult extends Component {
  render() {
    const { attorney, onHideClick, onPracticeAreaClick } = this.props;

    return (
      <li className="search-result">
        <article>
          <span className="hide-attorney" onClick={onHideClick}>
            <i className="far fa-times" />
          </span>
          <div className="row">
            <div className="col-sm-10">
              <div className="attorney-profile-picture-container">
                <AttorneyProfilePicture
                  pictureUrl={attorney._index_profile_picture_url}
                  firstName={attorney.first_name || attorney.first_name_initial}
                  lastName={attorney.last_name}
                  uuid={attorney.objectID}
                />
                {/* Leaving this commented out for now to avoid perf. issues */}
                {/* {verified && <SavedContractorButton contractorId={attorney.uuid} />} */}
              </div>
              <div className="attorney-profile-snippet">
                <ConnectedContractorTitle contractor={attorney} />
                {attorney.summary_title && (
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: attorney._highlightResult.summary_title.value,
                    }}
                  />
                )}
                {attorney._index_city_state && (
                  <span className="location">
                    <i className="fas fa-map-marker-alt" />
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          attorney._highlightResult._index_city_state.value,
                      }}
                    />
                  </span>
                )}
                <ExpandableText
                  text={attorney._highlightResult.summary.value}
                  maxWords={65}
                />
                <AttorneyPracticeAreasList
                  practiceAreas={
                    attorney._highlightResult._index_practice_areas
                      ? attorney._highlightResult._index_practice_areas.map(
                          pa => ({ text: pa.value, name: striptags(pa.value) }),
                        )
                      : attorney._index_practice_areas.map(pa => ({
                          text: pa,
                          name: pa,
                        }))
                  }
                  attorneyUuid={attorney.uuid}
                  onPracticeAreaClick={onPracticeAreaClick}
                />
              </div>
            </div>
            <div className="extra-info col-sm-2">
              <Link
                to={`/agency/attorneys/${attorney.uuid}`}
                className="btn btn-info view-profile"
              >
                View Profile
              </Link>
              {attorney.display_score &&
                attorney.display_score.num_reviews >= 3 && (
                  <div className="search-display_score">
                    <RatingInput
                      value={attorney.display_score.score}
                      num_reviews={attorney.display_score.num_reviews}
                    />
                  </div>
                )}
              <div className="years-experience">
                {attorney._index_years_experience}
                <span className="muted">&nbsp;years experience</span>
              </div>
              {attorney._index_barred_in_list &&
                attorney._index_barred_in_list.length > 0 && (
                  <div className="states-barred-in">
                    <span className="muted">Licensed in </span>
                    {attorney._index_barred_in_list.sort().join(", ")}
                  </div>
                )}
              {lessThanOneMonthAgo(attorney._index_last_activity_at) && (
                <div className="states-barred-in">
                  <span className="muted">Active:&nbsp;</span>
                  {moment.unix(attorney._index_last_activity_at).fromNow()}
                </div>
              )}
            </div>
          </div>
        </article>
      </li>
    );
  }
}

AttorneySearchResult.propTypes = {
  attorney: PropTypes.object.isRequired,
  onPracticeAreaClick: PropTypes.func.isRequired,
  onHideClick: PropTypes.func.isRequired,
  verified: PropTypes.bool,
};

AttorneySearchResult.defaultProps = {
  verified: false,
};
