import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import { Navigation } from "admin/components/layout";

export default class Base extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <Grid fluid>
          <div style={{ padding: "0 2rem" }}>{this.props.children}</div>
        </Grid>
      </div>
    );
  }
}
