export const createSuccessNotification = (args = {}) => {
  const {
    title = "Success!",
    message = null,
    duration = 4000,
    ...config
  } = args;

  return {
    title,
    message,
    status: "success",
    position: "tr",
    dismissible: true,
    dismissAfter: duration,
    ...config,
  };
};

const errorMessage =
  "Try refreshing the page. If problem persists, contact support.";
export const createErrorNotification = (args = {}) => {
  const {
    title = "Error!",
    message = errorMessage,
    duration = 7000,
    ...config
  } = args;

  return {
    title,
    message,
    status: "error",
    position: "tr",
    dismissible: true,
    dismissAfter: duration,
    ...config,
  };
};

export const createWarningNotification = (args = {}) => {
  const {
    title = "Warning!",
    message = null,
    duration = 7000,
    ...config
  } = args;

  return {
    title,
    message,
    status: "warning",
    position: "tr",
    dismissible: true,
    dismissAfter: duration,
    ...config,
  };
};
