// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {
  open: boolean,
};

const Plus: ComponentType<Props> = styled.i.attrs({
  className: "fa",
  "aria-hidden": "true",
})`
  margin: 0 0 3px 10px;
  padding: 0;
  transform: rotate(45deg);
  visibility: ${({ open }) => (open ? "hidden" : "visible")};

  &::before {
    content: "\00d7";
    font-size: 14px;
  }
`;

export default Plus;
