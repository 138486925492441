// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Alert, Button, Clearfix, Col, Row, Well } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Helmet } from "react-helmet";
// $FlowFixMe 'Cannot resolve module redux-form.'
import { reduxForm, SubmissionError } from "redux-form";

import { history as browserHistory } from "app";
import { login } from "common/actions/auth";
import {
  EmailAddressInput,
  PasswordField,
} from "common/components/forms/inputs";
import { login as loginMutation } from "queries/mutations/auth";

import { localStorage } from "common/utils/helpers";

const ForgotPasswordButton = () => (
  <LinkContainer to="/forgot-password/">
    <Button bsSize="sm" bsStyle="link" href="/signup/">
      <span className="text-muted">Forgot Password?</span>
    </Button>
  </LinkContainer>
);

const SocialLogInAlert = () => (
  <Alert bsStyle="info">
    <p>
      Looking for LinkedIn or Google sign in?{" "}
      <Link className="alert-link" to="/forgot-password">
        Click here
      </Link>{" "}
      to create a password.
    </p>
  </Alert>
);

const SignupButton = () => (
  <LinkContainer to="/signup" style={{ display: "block" }}>
    <Button bsStyle="info">
      <span className="text-uppercase text-center small">
        sign up for hire an esquire
      </span>
    </Button>
  </LinkContainer>
);

type LogInFormValues = {
  email?: String,
  password?: String,
};

type SubmitFunction = (values: LogInFormValues) => void;

type _LogInFormProps = {
  error: Node,
  handleSubmit: SubmitFunction,
  submitting: boolean,
};

class _LogInForm extends Component<_LogInFormProps> {
  render() {
    const { error, handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {error && <Alert bsStyle="danger">{error}</Alert>}
        <EmailAddressInput name="email" label={null} placeholder="Email" />
        <PasswordField name="password" placeholder="Password" />
        <div className="pull-right">
          <ForgotPasswordButton />
          <Button
            type="submit"
            bsStyle="info"
            className="text-uppercase"
            disabled={submitting}
          >
            Sign In
          </Button>
        </div>
        <Clearfix />
        <hr />
        <SocialLogInAlert />
      </form>
    );
  }
}

const LogInForm = reduxForm({
  form: "loginForm",
})(_LogInForm);

const Content = props => (
  <Fragment>
    <h2 className="text-uppercase text-center text-muted">welcome back</h2>
    <div className="text-uppercase" style={{ fontWeight: 700 }}>
      sign in
    </div>
    <Well className="form-well">
      <LogInForm onSubmit={props.handleSubmit} />
    </Well>
    <div className="sign-up-container">
      <h2 className="text-uppercase text-center text-muted">not a member?</h2>
      <Well className="sign-up-well">
        <SignupButton />
      </Well>
    </div>
  </Fragment>
);

const NonMobileDisplay = props => (
  <Well className="hidden-xs">
    <Content {...props} />
  </Well>
);

const MobileDisplay = props => (
  <div className="visible-xs">
    <Content {...props} />
  </div>
);

type Response = {
  body: {
    key?: String,
    non_field_errors?: [String],
    _error?: [String],
  },
  status: number,
};

type LogInProps = {
  onSubmit: (data: LogInFormValues) => Promise<Response>,
  location: Location,
};

class LogIn extends Component<LogInProps> {
  handleSubmit = async (values: LogInFormValues) => {
    const { location, onSubmit } = this.props;
    const response: Response = await onSubmit(values);
    const { body, status } = response;

    if (status >= 200 && status < 300) {
      if (body.hasOwnProperty("key") && body.key) {
        localStorage.setItem("authtoken", String(body.key));
      }
      if (location.state && location.state.onAuthPath) {
        browserHistory.push(location.state.onAuthPath);
      } else {
        // Root URL will redirect user to the correct app
        browserHistory.push("/");
      }
    } else if (status >= 400 && status < 600) {
      if (body.hasOwnProperty("non_field_errors")) {
        body._error = body.non_field_errors;
      }
      throw new SubmissionError(body);
    }
  };

  render() {
    return (
      <Fragment>
        <Row className="login">
          <Helmet>
            <title>Log In or Sign Up</title>
          </Helmet>
          <Col lg={5} lgOffset={4} md={6} mdOffset={3} sm={8} smOffset={2}>
            <MobileDisplay handleSubmit={this.handleSubmit} />
            <NonMobileDisplay handleSubmit={this.handleSubmit} />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: data => {
    dispatch(login());
    return dispatch(loginMutation(data));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(LogIn);
