import React from "react";
import PropTypes from "prop-types";
import { Popover, OverlayTrigger } from "react-bootstrap";

const renderExpertiseAreaPopover = elements => (
  <Popover id="popover-trigger-hover-focus" title="Areas of Expertise">
    {elements.map(el => (
      <p key={el}> {el}</p>
    ))}
  </Popover>
);

const PracticeAreaGroup = ({ practiceArea }) => (
  <li className="attorney-practice-area">
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="bottom"
      overlay={renderExpertiseAreaPopover(practiceArea[1].practiceAreas)}
    >
      <p>{practiceArea[0]}</p>
    </OverlayTrigger>
  </li>
);

PracticeAreaGroup.propTypes = {
  practiceArea: PropTypes.array.isRequired,
};

export default PracticeAreaGroup;
