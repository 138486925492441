import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import { compose, connect, connectRequest, types } from "queries/utils";
import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import { clientFeedbackSurveyQuery } from "queries/requests/clientFeedback";
import { jobsQuery } from "queries/requests/jobs";
import { setFocusedSource } from "hiringagent-dashboard/actions/payments";
import {
  dismissRecommendation,
  inviteRecommendation,
} from "hiringagent-dashboard/actions/recommendations";
import { AlertSystemContainer } from "hiringagent-dashboard/components/alerts";
import CandidatesPanel from "hiringagent-dashboard/components/dashboard/CandidatesPanel";
import BasePanel from "hiringagent-dashboard/components/dashboard/BasePanel";
import SavedContractorsPanel from "hiringagent-dashboard/components/dashboard/SavedContractorsPanel";
import JobsPanel from "hiringagent-dashboard/components/dashboard/JobsPanel";
import InvoicesPanel from "hiringagent-dashboard/components/dashboard/InvoicesPanel";
import MessagesPanel from "hiringagent-dashboard/components/dashboard/MessagesPanel";
import OnboardingModal from "hiringagent-dashboard/components/onboarding/OnboardingModal";
import PaymentsPanel from "hiringagent-dashboard/components/dashboard/PaymentsPanel";
import RecommendedAttorneysPanel from "hiringagent-dashboard/components/dashboard/RecommendedAttorneysPanel";
import FeedbackPanel from "./FeedbackPanel";
import Welcome from "./Welcome";

class Dashboard extends Component {
  render() {
    const {
      clientFeedbackSurvey: feedback,
      handleInviteRecommendation,
      recommendations,
      hiringAgentDashboard: { agency, payments },
      jobs,
    } = this.props;
    const feedbackOrJobsLoading =
      !feedback.isFinished ||
      feedback.isPending ||
      !jobs.isFinished ||
      jobs.isPending;

    return (
      <div>
        <h1>
          <i className="fas fa-tachometer-alt" />&nbsp;Dashboard
        </h1>
        <OnboardingModal />
        <Welcome />
        <Row>
          <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
            <AlertSystemContainer />
          </Col>
        </Row>
        <Row>
          <BasePanel
            size={4}
            name="recommended candidates"
            isLoading={recommendations.isPending}
            action={null}
          >
            <RecommendedAttorneysPanel
              {...recommendations}
              {...agency}
              onDismissRecommendation={recommendationId =>
                this.props.dispatch(dismissRecommendation(recommendationId))
              }
              onInviteClick={handleInviteRecommendation}
            />
          </BasePanel>
          <BasePanel
            size={4}
            name="pending candidates"
            action={
              <Link className="action" to="/agency/candidates">
                review candidates
              </Link>
            }
          >
            <CandidatesPanel />
          </BasePanel>
          <BasePanel
            size={4}
            name="jobs"
            action={
              <Link className="action" to="/agency/jobs/create/">
                post a job
              </Link>
            }
          >
            <JobsPanel />
          </BasePanel>
        </Row>
        <Row>
          <BasePanel size={4} name="invoices" action={null}>
            <InvoicesPanel />
          </BasePanel>
          <BasePanel
            size={8}
            name="messages"
            action={
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Link className="action" to="/agency/messages/">
                    view all messages
                  </Link>
                </Col>
              </Row>
            }
          >
            <MessagesPanel />
          </BasePanel>
        </Row>
        <Row>
          <SavedContractorsPanel />
          <BasePanel
            size={6}
            name="payment info"
            isLoading={payments.isLoading}
            action={
              <Link className="action" to="/agency/settings/#payment">
                manage payment info
              </Link>
            }
          >
            <PaymentsPanel
              {...payments}
              onSetFocusedSource={source =>
                this.props.dispatch(setFocusedSource(source))
              }
            />
          </BasePanel>
        </Row>
        <Row>
          <BasePanel
            size={6}
            name="feedback"
            isLoading={feedbackOrJobsLoading}
            action={
              <Link className="action" to="/agency/feedback">
                see full feedback list
              </Link>
            }
          >
            <FeedbackPanel jobs={jobs} feedback={feedback} />
          </BasePanel>
        </Row>
      </div>
    );
  }
}

const recommendationsQuery = () =>
  createResourceListQuery(types.RECOMMENDATIONS, {
    url: "/api/dev/recommendations/?limit=99",
    force: true,
  });

const recommendationsSelector = createResourceSelectorConfig(
  types.RECOMMENDATIONS,
  recommendationsQuery,
);

const clientFeedbackSurveySelector = createResourceSelectorConfig(
  types.CLIENT_FEEDBACK_SURVEY,
  clientFeedbackSurveyQuery,
);

const jobsSelector = createResourceSelectorConfig(types.JOBS, jobsQuery);

const mapStateToProps = state => ({
  hiringAgentDashboard: state.hiringAgentDashboard,
  ...createStructuredSelector({ ...recommendationsSelector })(state),
  ...createStructuredSelector({ ...clientFeedbackSurveySelector })(state),
  ...createStructuredSelector({ ...jobsSelector })(state),
});

const mapDispatchToProps = dispatch => ({
  handleInviteRecommendation: recommendationId => {
    dispatch(inviteRecommendation(recommendationId));
  },
  dispatch,
});

const mapPropsToConfig = () => [
  recommendationsQuery(),
  clientFeedbackSurveyQuery(),
  jobsQuery(),
];

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Dashboard);
