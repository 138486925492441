import { combineReducers } from "redux";

import contractorAudits from "admin/reducers/contractorAudits";
import attorneyVerificationManager from "admin/reducers/attorneyVerificationManager";
import applications from "admin/reducers/applications/index";
import applicationsStats from "admin/reducers/applications/applicationsStats";
import barcardVerifications from "admin/reducers/barcardVerifications";
import emailVerifications from "admin/reducers/emailVerifications";
import {
  pendingBarInformations,
  recentBarInformations,
} from "admin/reducers/barInformations";
import hiringAgenciesDropdown from "admin/reducers/hiringAgenciesDropdown";
import invoicingReducer from "admin/reducers/invoicing/index";
import jobsDropdown from "admin/reducers/jobsDropdown";
import nonAttorneyVerification from "admin/reducers/nonAttorneyVerification";
import phoneVerifications from "admin/reducers/phoneVerifications";
import previousEmploymentTagManager from "admin/reducers/previousEmploymentTagManager";
import recommendations from "admin/reducers/recommendations";
import recommendationStats from "admin/reducers/recommendationStats";
import searchHistories from "admin/reducers/search-history";
import languageAudits from "admin/reducers/language-audit";
import createReducer from "common/reducers/utils";

const reducers = combineReducers({
  contractorAudits,
  applications,
  applicationsStats,
  attorneyVerificationManager,
  barcardVerifications,
  barcardVerificationSet: createReducer("barcardVerificationSet"),
  barInformations: createReducer("barInformations"),
  emailVerifications,
  emailVerificationSet: createReducer("emailVerificationSet"),
  hiringAgenciesDropdown,
  invoicing: invoicingReducer,
  jobsDropdown,
  nonAttorneyVerification,
  phoneVerifications,
  phoneVerificationSet: createReducer("phoneVerificationSet"),
  pendingBarInformations,
  previousEmploymentTagManager,
  recentBarInformations,
  recommendations,
  recommendationStats,
  searchHistories,
  languageAudits,
});

export default reducers;
