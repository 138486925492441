import React from "react";
import styled from "styled-components";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";

import { savedContractorsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";
import { removeEmptyValues } from "utils";

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  margin-bottom: 15px;
`;

const ResultsCountDisplay = styled.span`
  align-self: center;
`;

const ResultsCount = ({ limit, offset, isPending, total }) => {
  if (isPending) return null;
  if (total < 1) return <span>0 results</span>;
  const minRange = offset + 1;
  const maxRange = Math.min(limit + offset, total);
  return (
    <span>
      {minRange}-{maxRange} of {total && total > 0 ? total : "..."} results
    </span>
  );
};

const OrderingDropdownContainer = styled.div`
  justify-self: right;
`;
const OrderingDropdownButton = styled(DropdownButton)`
  & + ul {
    font-size: 1.6rem;
  }
`;

const sortingOptions = {
  "-created": "Date Saved (newest first)",
  created: "Date Saved (oldest first)",
  "-updated": "Date Updated (newest first)",
  updated: "Date Updated (oldest first)",
  contractor__haeuser__last_name: "Last Name (A-Z)",
  "-contractor__haeuser__last_name": "Last Name (Z-A)",
};

const TalentPoolsSearchHeader = props => {
  const {
    onOrderingChange,
    values,
    values: { limit, offset, ordering },
  } = props;
  const request = savedContractorsRequest({
    url: { params: removeEmptyValues(values) },
  });
  const selector = createResourceSelector(types.SAVED_CONTRACTORS, request);
  const {
    [types.SAVED_CONTRACTORS]: {
      query: { count },
    },
  } = useSelector(selector);
  const [{ isPending }, _] = useRequest(request);

  return (
    <Layout>
      <ResultsCountDisplay>
        <ResultsCount
          limit={limit}
          offset={offset}
          total={count}
          isPending={isPending}
        />
      </ResultsCountDisplay>
      <OrderingDropdownContainer>
        <OrderingDropdownButton
          title="Sort By"
          id="sort-talent-pools-dropdown"
          pullRight
        >
          {Object.entries(sortingOptions).map(([value, label]) => (
            <MenuItem
              key={`sort-by-${value}`}
              onClick={() => onOrderingChange(value)}
            >
              {ordering && value === ordering ? (
                <strong>{label}</strong>
              ) : (
                label
              )}
            </MenuItem>
          ))}
        </OrderingDropdownButton>
      </OrderingDropdownContainer>
    </Layout>
  );
};

export default TalentPoolsSearchHeader;
