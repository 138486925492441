// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  locked?: boolean,
  selected?: boolean,
  isDraggingOver?: boolean,
  numSelected?: number,
};

const ItemGroup: ComponentType<Props> = styled.div`
  width: 100%;
  margin: 0;
  padding: 30px;
  ${({ selected, isDraggingOver, numSelected, theme }) =>
    selected &&
    css`
      background-color: ${isDraggingOver
        ? theme.colors.primary_turquoise("30%")
        : theme.colors.primary_turquoise("15%")};
      border: ${isDraggingOver
        ? `1px solid ${theme.colors.primary_turquoise()}`
        : numSelected === 0
          ? `1px dashed ${theme.colors.primary_turquoise()}`
          : `1px solid ${theme.colors.primary_turquoise()}`};
    `};
  ${({ locked }) =>
    locked &&
    css`
      background-color: ${({ theme }) => theme.colors.primary_turquoise("15%")};
    `};
`;

export default ItemGroup;
