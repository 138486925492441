import React, { Component } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { Alert, Button, ButtonToolbar, Col, Row, Well } from "react-bootstrap";
import { injectStripe } from "react-stripe-elements";
import { SubmissionError } from "redux-form";

import TermsOfServiceACH from "hiringagent-dashboard/components/settings/TermsOfServiceACH";
import {
  CheckboxField,
  NumberField,
  TextField,
} from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";

const requiredFields = ["routing_number", "account_number", "agreed_tos"];

const validate = values => validateRequiredFields(requiredFields, values);

class CreateBankAccountForm extends Component {
  onSubmit = async values => {
    const { token, error } = await this.props.stripe.createToken(
      "bank_account",
      {
        country: "US",
        currency: "usd",
        routing_number: values.routing_number,
        account_number: values.account_number,
      },
    );

    // Only send token ID to backend
    if (token) values.stripe_token = token.id;
    if (error) throw new SubmissionError({ _error: error.message });

    return this.props.onSubmit(values);
  };
  render() {
    const { error, handleSubmit, submitting, close } = this.props;

    return (
      <div className="create-bank-form">
        {/* TODO: Create and link to devoted tos_ach page */}
        <Well className="ach-tos">
          <h4>
            <strong>
              Please read the following agreement. You will be asked to
              acknowledge and agree to it below.
            </strong>
          </h4>
          <p>
            <small>
              Bank account information is collected to make payment processing
              easy. We process all payments through{" "}
              <a
                href="https://stripe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>
              .
            </small>
          </p>
          <TermsOfServiceACH />
        </Well>
        <div className="bank-form-divider" />
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {error && <Alert bsStyle="danger">{error}</Alert>}
          <Row>
            <Col sm={6}>
              <NumberField name="routing_number" label="Routing Number" />
            </Col>
            <Col sm={6}>
              <NumberField name="account_number" label="Account Number" />
            </Col>
          </Row>
          <TextField name="account_label" label="Account Label" />
          <CheckboxField
            name="agreed_tos"
            label=" I agree to the ACH terms of service outlined above."
          />
          <div style={{ marginBottom: "1.5rem" }}>
            <small>
              To verify this account bank for ACH payments, two small deposits
              will be made to your account. These transfers can take 1-2
              business days to appear in your account. Once you receive the
              deposits, confirm them by clicking on the &lsquo;verify&rsquo;
              link (found on the payment account) and entering the amounts.
            </small>
          </div>
          <ButtonToolbar className="pull-right">
            <Button bsStyle="link" onClick={close}>
              Cancel
            </Button>
            <Button bsStyle="success" disabled={submitting} type="submit">
              Add Bank Account
            </Button>
          </ButtonToolbar>
          <span className="clearfix" />
        </form>
      </div>
    );
  }
}

CreateBankAccountForm = compose(
  reduxForm({
    form: "CreateBankAccountForm",
    validate,
  }),
  injectStripe,
)(CreateBankAccountForm);
export default CreateBankAccountForm;
