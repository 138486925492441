// @flow
import * as React from "react";
import { history as browserHistory } from "app";
import { connect } from "react-redux";
import { requestAuth } from "common/actions/auth";

import { CenteredContent } from "common/components/layout";

type Props = {
  authenticationRequired: boolean,
  // activeRequired: boolean, // TODO
  agreedToSRequired: boolean,
  emailConfirmedRequired: boolean,
  requiredUserType: ?UserType,
  verificationRequired: boolean,
  redirectTo: string,
  redirectToIfNotVerified: string,
  children?: React.Node,
  auth: {
    data: Auth,
    error: ?Error,
    token: null,
    isLoading: boolean,
    isFinished: boolean,
  },
  onLoad: () => mixed,
  onAuthPath: string,
};

type State = {
  success: boolean,
};

class Authentication extends React.Component<Props, State> {
  static defaultProps = {
    authenticationRequired: false,
    agreedToSRequired: false,
    emailConfirmedRequired: false,
    requiredUserType: null,
    verificationRequired: false,
    redirectTo: "/login",
    redirectToIfNotVerified: "/not-verified",
    onAuthPath: "/",
  };

  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }

  componentDidMount() {
    this.props.onLoad();
    this.redirectIfFinishedLoading();
  }

  componentDidUpdate() {
    this.redirectIfFinishedLoading();
  }

  handleReloadClick = e => {
    e.preventDefault();
    this.props.onLoad();
  };

  redirectIfFinishedLoading() {
    const {
      auth: { isFinished },
    } = this.props;
    if (isFinished) this.redirectIfNecessary();
  }

  redirectIfNecessary() {
    const {
      auth: { data: authData },
      authenticationRequired,
      agreedToSRequired,
      emailConfirmedRequired,
      redirectToIfNotVerified,
      requiredUserType,
      verificationRequired,
    } = this.props;
    const { success } = this.state;

    if (!authData) {
      // If we get here, this is an anonymous (unauthenticated) user
      return this.redirect("/login");
    }

    const isAuthenticated = authData.is_authenticated;
    const hasAgreedToS = authData.agreed_to_latest_tos;
    const isConfirmed = authData.confirmed;
    const isRequiredUserType = requiredUserType === authData.user_type;
    const isVerified = authData.verified;
    const isHiringAgent = authData.user_type === "hiring_agent";

    if (authenticationRequired && !isAuthenticated) {
      return this.redirect("/login");
    }
    if (requiredUserType && !isRequiredUserType) {
      return this.redirect("/not-permitted", true);
    }
    if (agreedToSRequired && !hasAgreedToS) {
      return this.redirect("/terms-of-service/accept");
    }
    if (emailConfirmedRequired && !isConfirmed) {
      return this.redirect("/signup_complete");
    }
    if (verificationRequired && !isVerified) {
      return this.redirect(redirectToIfNotVerified);
    }
    if (isHiringAgent && authData.hiring_agency_verification === "Denied") {
      return this.redirect("/agency/verification-denied");
    }

    if (!success) this.setState({ success: true });
  }

  redirect(alternateRedirect?: string, replace?: boolean = false) {
    const redirectTo = alternateRedirect || this.props.redirectTo;
    const changeHistory = replace
      ? browserHistory.replace
      : browserHistory.push;

    if (redirectTo === "/login") {
      changeHistory({
        pathname: redirectTo,
        state: { onAuthPath: this.props.onAuthPath },
      });
    } else {
      changeHistory(redirectTo);
    }
  }

  render() {
    const {
      auth: { data: authData, isFinished, isLoading },
      children,
    } = this.props;
    const { success } = this.state;

    if (authData && isFinished && success && children) {
      return children;
    }

    if (isLoading)
      return (
        <CenteredContent>
          <i className="far fa-cog fa-spin fa-4x" />
        </CenteredContent>
      );

    return (
      <CenteredContent>
        <span>
          Error loading response from server.&nbsp;
          <a href="" onClick={this.handleReloadClick}>
            Click here
          </a>{" "}
          to try again.
        </span>
      </CenteredContent>
    );
  }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = { onLoad: requestAuth };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Authentication);
