import styled from "styled-components";

const Toggle = styled.p`
  justify-self: center;
  align-self: center;
  color: ${({ theme }) => theme.colors.primary_turquoise()} !important;
  cursor: pointer;
  text-transform: uppercase !important;

  &:hover {
    text-decoration: underline;
  }
`;

export default Toggle;
