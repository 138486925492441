import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";

import { Loading } from "common/components";
import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { createJobQuery } from "queries/requests/jobs";

import JobApplicantsTable from "./JobApplicantsTable";
import Applicants from "./blocks";

const StatusSelect = ({ onChange, value }) => {
  const handleChange = e => {
    e.preventDefault();
    onChange(e.target.value);
  };
  return (
    <FormControl
      componentClass="select"
      placeholder="select"
      value={value}
      onChange={handleChange}
    >
      <option value="default">View All</option>
      <option value={0}>Pending</option>
      <option value={1}>Rejected</option>
      <option value={2}>Negotiating</option>
      <option value={3}>Hired</option>
    </FormControl>
  );
};

const JobApplicants = props => {
  const [status, setStatus] = useState("default");
  const {
    jobs: { data, isFinished, isPending },
    location: { search },
    match: {
      params: { id },
    },
  } = props;

  if (isPending || !isFinished) return <Loading />;
  const job = data[id];

  return (
    <div>
      <Applicants.JobApplicantsHeader>
        <Applicants.BackButton>
          {/* NOTE: If user navigated to this page from somewhere other than the applicants page,
          this back button will not go back to where they expect. */}
          <Link to="/agency/candidates">
            <i className="fas fa-long-arrow-left" /> Back to all jobs
          </Link>
        </Applicants.BackButton>
        <Applicants.JobTitle>
          <span className="hidden-xs">Applicants for </span>
          <Link to={`/agency/jobs/${id}`}>{job.formattedTitle}</Link>
        </Applicants.JobTitle>
        <Applicants.StatusDropdownContainer>
          <Applicants.StatusDropdownTitle>
            Filter by Status
          </Applicants.StatusDropdownTitle>
          <StatusSelect onChange={setStatus} value={status} />
        </Applicants.StatusDropdownContainer>
      </Applicants.JobApplicantsHeader>
      {isFinished && (
        <JobApplicantsTable
          limit={10}
          job={data[id]}
          status={status}
          search={search}
        />
      )}
    </div>
  );
};

const mapPropsToConfig = props =>
  createJobQuery({
    url: `/api/v2/jobs/${props.match.params.id}/`,
  });
const mapStateToProps = createResourceSelector("jobs", mapPropsToConfig);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  JobApplicants,
);
