import React, { Fragment } from "react";
import { connect } from "react-redux";
import { MenuItem } from "react-bootstrap";

import ToolTip from "common/components/ToolTip";
import { withScreenSize } from "common/components/responsive";
import { setSelectedColumn } from "hiringagent-dashboard/actions/applicantsTable";
import { columnLabels } from "hiringagent-dashboard/reducers/applicantsTable";

import Applicants from "./blocks";

const requirementsFilledTooltip =
  "This is a measure of how task requirements that are chosen " +
  "in the job posting fit with the work experience of a candidate.";

const workstyleTooltip =
  "This is a measure of how well a candidate’s personality " +
  "fits with the competencies chosen during the job posting.";

const DesktopTableHeaders = () => (
  <Fragment>
    <th>Name</th>
    <th>Fit Score &#9662;</th>
    <th>
      <ToolTip content={requirementsFilledTooltip} placement="top">
        <Applicants.TooltipIcon />
      </ToolTip>{" "}
      Req. Filled
    </th>
    <th>
      <ToolTip content={workstyleTooltip} placement="top">
        <Applicants.TooltipIcon />
      </ToolTip>{" "}
      Workstyle<br />Score
    </th>
    <th>Status</th>
    <th>Date Applied</th>
    <th />
  </Fragment>
);

let MobileTableHeaders = ({ onColumnSelectClick, selectedColumn }) => (
  <Fragment>
    <th>Name</th>
    <th>
      <Applicants.ColumnSelectDropdown
        id="applicants-table-mobile-dropdown"
        title={columnLabels[selectedColumn]}
        bsSize="xs"
      >
        <MenuItem eventKey="1" onSelect={() => onColumnSelectClick("fitscore")}>
          {columnLabels.fitscore}
        </MenuItem>
        <MenuItem
          eventKey="2"
          onSelect={() => onColumnSelectClick("requirements")}
        >
          {columnLabels.requirements}
        </MenuItem>
        <MenuItem
          eventKey="3"
          onSelect={() => onColumnSelectClick("workstyle")}
        >
          Workstyle Score
        </MenuItem>
        <MenuItem eventKey="4" onSelect={() => onColumnSelectClick("date")}>
          Date Applied
        </MenuItem>
      </Applicants.ColumnSelectDropdown>
    </th>
    <th>Status</th>
  </Fragment>
);

MobileTableHeaders = connect(
  state => ({
    selectedColumn: state.hiringAgentDashboard.applicantsTable.selectedColumn,
  }),
  {
    onColumnSelectClick: setSelectedColumn,
  },
)(MobileTableHeaders);

const TableHeader = ({ isMobile }) => (
  <thead>
    <tr>{isMobile ? <MobileTableHeaders /> : <DesktopTableHeaders />}</tr>
  </thead>
);

export default withScreenSize(TableHeader);
