import actions from "admin/actions/attorneyVerificationManager";
import { listToObject } from "admin/utils";
import createReducer from "common/reducers/utils";

const handleReceivePhoneVerifications = (state, action) => ({
  ...state,
  phoneVerifications: {
    ...state.phoneVerifications,
    ...listToObject(
      action.payload.results ? action.payload.results : [action.payload],
      "uuid",
    ),
  },
});

const handleReceiveCreatePhoneVerification = (state, action) => ({
  ...state,
  phoneVerifications: {
    [action.payload.uuid]: action.payload,
    ...state.phoneVerifications,
  },
});

const handlers = {
  "RECEIVE/phoneVerificationSet": handleReceivePhoneVerifications,
  "RECEIVE/createPhoneVerification": handleReceiveCreatePhoneVerification,
  [actions.REMOVE_PHONE_VERIFICATION]: (state, action) => {
    const {
      [action.payload]: _,
      ...phoneVerifications
    } = state.phoneVerifications;
    return {
      ...state,
      phoneVerifications,
    };
  },
};

export default createReducer("phoneVerifications", null, handlers);
