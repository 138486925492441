// @flow strict
import React from "react";
// $FlowFixMe (nonstrict-import)
import { Droppable, Draggable } from "react-beautiful-dnd";

import DragDrop from "./blocks";
import { IconBars, IconCheckCircle, IconRemove } from "./Icons";

type Props = {
  onClearSelected: () => void,
  onToggleSelected: (uuid: string, source: string, dest: string) => void,
  selected: Array<{ uuid: string, statement: string }>,
  selectedMax: number,
};

// A development only warning regarding css styles is thrown by
// react-beautiful-dnd when this component is empty and then receives its first
// item via drag and drop. The css behavior has been overwritten to accommodate
// custom styles and the warning can be safely ignored.

const SelectedDroppable = ({
  onClearSelected,
  onToggleSelected,
  selected,
  selectedMax,
}: Props) => (
  <Droppable droppableId="selected">
    {(provided, snapshot) => (
      <DragDrop.ItemGroup
        selected={true}
        numSelected={selected.length}
        isDraggingOver={snapshot.isDraggingOver}
        ref={provided.innerRef}
      >
        {selected.length > 0 && (
          <DragDrop.ClearAll onClick={onClearSelected}>
            Clear All
          </DragDrop.ClearAll>
        )}
        {selected.map((item, index) => (
          <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
            {(provided, snapshot) => (
              <DragDrop.Item
                itemType="selected"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <DragDrop.ItemOuter
                  isDragging={snapshot.isDragging}
                  side="left"
                >
                  {index + 1}
                </DragDrop.ItemOuter>
                <IconCheckCircle
                  onClick={() =>
                    onToggleSelected(item.uuid, "selected", "unselected")
                  }
                />
                <DragDrop.ItemText>{item.statement}</DragDrop.ItemText>
                <IconBars />
                <DragDrop.ItemOuter
                  isDragging={snapshot.isDragging}
                  onClick={() =>
                    onToggleSelected(item.uuid, "selected", "unselected")
                  }
                  side="right"
                >
                  <IconRemove />
                </DragDrop.ItemOuter>
              </DragDrop.Item>
            )}
          </Draggable>
        ))}
        {selected.length > 0 && provided.placeholder}
        {selected.length > 0 ? (
          <DragDrop.GuideSecondary>
            {selectedMax - selected.length <= 0
              ? "Done!"
              : `Add up to ${selectedMax - selected.length} more...`}
          </DragDrop.GuideSecondary>
        ) : (
          <DragDrop.Guide>
            <div>
              <DragDrop.GuideBox guideBoxType="dashed" />
              <DragDrop.GuideBox guideBoxType="solid" />
            </div>
            <DragDrop.GuideSecondary>
              {`Drag and rank up to ${selectedMax} selections here.`}
            </DragDrop.GuideSecondary>
          </DragDrop.Guide>
        )}
      </DragDrop.ItemGroup>
    )}
  </Droppable>
);

export default SelectedDroppable;
