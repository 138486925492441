import React, { Component } from "react";

import ChangePassword from "common/components/ChangePassword";

export default class EditPassword extends Component {
  render() {
    return (
      <div>
        <h2 className="edit-section-title">Password</h2>
        <ChangePassword />
      </div>
    );
  }
}
