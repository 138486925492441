import React from "react";

export const Error = ({ children }) => (
  <span>
    <span className="far fa-exclamation-circle" />&nbsp;{children}
  </span>
);

export const Warning = ({ children }) => (
  <span>
    <span className="far fa-exclamation-triangle" />&nbsp;{children}
  </span>
);

export const RequiredFieldError = () => <Error>This field is required.</Error>;

export const notMatchingError = <Error>Passwords do not match.</Error>;
export const validatePasswordsMatch = (
  password,
  repeat_password,
  errors,
  passwordFieldName = "password",
  repeatPasswordFieldName = "repeat_password",
) => {
  const passwordsMatch = password === repeat_password;

  if (!passwordsMatch) {
    errors[passwordFieldName] = notMatchingError;
    errors[repeatPasswordFieldName] = notMatchingError;
  }
};

export const formatError = (
  <Error>
    Your password should be 8+ characters long and include an uppercase letter,
    a lower case letter and a number or symbol.
  </Error>
);

export const validatePasswordFormat = (
  password,
  repeat_password,
  errors,
  passwordFieldName = "password",
  repeatPasswordFieldName = "repeat_password",
) => {
  const passwordValidator = /^(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[!@#$%^&*]))(?=.{8,})/;

  if (!password.match(passwordValidator))
    errors[passwordFieldName] = formatError;
  if (!repeat_password.match(passwordValidator))
    errors[repeatPasswordFieldName] = formatError;
};

export const validatePasswords = (
  values,
  errors = {},
  passwordFieldName = "password",
  repeatPasswordFieldName = "repeat_password",
) => {
  const {
    [passwordFieldName]: password,
    [repeatPasswordFieldName]: repeat_password,
  } = values;
  const passwordsNotEmpty = password && repeat_password;

  if (passwordsNotEmpty) {
    validatePasswordsMatch(
      password,
      repeat_password,
      errors,
      passwordFieldName,
    );
    validatePasswordFormat(
      password,
      repeat_password,
      errors,
      repeatPasswordFieldName,
    );
  }

  return errors;
};

const valuesContainsKey = (values, key) => Object.keys(values).includes(key);

const invalidValues = ["", null, undefined];
const valueIsEmpty = value => {
  // Array.includes does not use strict equality comparison in IE11
  let isInvalid = false;
  invalidValues.forEach(invalidValue => {
    if (value === invalidValue) isInvalid = true;
  });
  const isEmptyArray = Array.isArray(value) && value.length === 0;
  return isInvalid || isEmptyArray;
};

export const validateRequiredFields = (
  requiredFields = [],
  values = {},
  errors = {},
) =>
  requiredFields.reduce((res, val) => {
    const missingRequiredKey = !valuesContainsKey(values, val);
    const isEmpty = valueIsEmpty(values[val]);
    return {
      ...res,
      ...((missingRequiredKey || isEmpty) && { [val]: <RequiredFieldError /> }),
    };
  }, errors);
