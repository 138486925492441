import React from "react";
import { ButtonToolbar, Modal } from "react-bootstrap";

import PrimaryButton from "common/blocks/PrimaryButton";

const ConfirmModal = ({
  action,
  reviewRequest: { reviewer_first_name, reviewer_last_name, uuid },
  onActionClick,
  onHide,
  show,
}) => {
  const capitalized = action.charAt(0).toUpperCase() + action.slice(1);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{`${capitalized} Reference Entry`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {`Are you sure you want to ${action} your reference entry for `}
          {reviewer_first_name
            ? reviewer_last_name
              ? `${reviewer_first_name} ${reviewer_last_name}`
              : `${reviewer_first_name}`
            : "None"}?
        </p>
        <ButtonToolbar>
          <PrimaryButton bsStyle="primary" onClick={() => onActionClick(uuid)}>
            {capitalized}
          </PrimaryButton>
          <PrimaryButton bsStyle="default" onClick={onHide}>
            Close
          </PrimaryButton>
        </ButtonToolbar>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
