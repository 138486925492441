import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { connectRequest } from "redux-query-react";
import querystring from "querystring";
import { history } from "app";

import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Loading } from "common/components";
import { withScreenSize } from "common/components/responsive";
import { createResourceSelector } from "queries";
import { createCandidatesQuery } from "queries/requests/candidates";
import { parseIntWithDefault } from "utils";

import Applicants from "./blocks";
import Pagination from "./Pagination";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const ApplicantCounts = ({
  counts: { total, hired, negotiating, rejected },
}) => {
  if (total === 0) return null;
  return (
    <Applicants.Counts>
      <Applicants.Counts.Total>
        {total}&nbsp;Total Applications:
      </Applicants.Counts.Total>{" "}
      <Applicants.Counts.Hired>{hired}&nbsp;Hired</Applicants.Counts.Hired>,{" "}
      <Applicants.Counts.Negotiating>
        {negotiating}&nbsp;Negotiating
      </Applicants.Counts.Negotiating>,{" "}
      <Applicants.Counts.Rejected>
        {rejected}&nbsp;Rejected
      </Applicants.Counts.Rejected>
    </Applicants.Counts>
  );
};

const ViewAllApplicantsButton = ({ job }) => (
  <Fragment>
    <Applicants.FloatingButtonGradientBackground />
    <Applicants.FloatingButtonContainer>
      <LinkContainer to={`/agency/candidates/job/${job.uuid}`}>
        <Button bsStyle="primary">View all applicants</Button>
      </LinkContainer>
    </Applicants.FloatingButtonContainer>
  </Fragment>
);

const NoApplicantsMessage = ({ status = "default" }) => (
  <p className="text-center">
    {status === "default"
      ? "No applicants yet"
      : "No applicants match the specified status filter."}
  </p>
);

const JobApplicantsTable = props => {
  const {
    candidates: {
      data,
      isFinished,
      isPending,
      query: { data: queryData, count },
    },
    isDesktop,
    job,
    limit,
    preview,
    search = "?",
    status = "default",
  } = props;
  const {
    location: { pathname },
  } = history;
  const showViewAllButton = isFinished && preview && count > queryData.length;
  const query = querystring.parse(search.substring(1));
  const { page = "1" } = query;
  const pageInt = parseIntWithDefault(page, 1);
  const handlePageChange = newPage =>
    history.push(
      `${pathname}?${querystring.stringify({
        page: newPage,
      })}`,
    );
  return (
    <div>
      {/* TableContainer should contain the table and the View All button */}
      <Applicants.TableContainer showViewAllButton={showViewAllButton}>
        <Applicants.Table isDesktop={isDesktop}>
          <TableHeader />
          <tbody>
            {isFinished && (
              <Fragment>
                {queryData.map((candidateId, i) => (
                  <TableRow key={i} candidate={data[candidateId]} />
                ))}
              </Fragment>
            )}
          </tbody>
        </Applicants.Table>
        {isPending && <Loading />}
        {isFinished && (
          <Fragment>
            {count === 0 && <NoApplicantsMessage status={status} />}
            {showViewAllButton && <ViewAllApplicantsButton job={job} />}
          </Fragment>
        )}
      </Applicants.TableContainer>
      {isFinished && <ApplicantCounts counts={job.candidate_counts} />}
      {!preview &&
        count > limit && (
          <Pagination
            pageNumber={pageInt}
            onChange={handlePageChange}
            numResults={count}
            limit={limit}
          />
        )}
    </div>
  );
};

const mapPropsToConfig = props => {
  const {
    limit,
    job: { uuid },
    preview,
    status = "default",
  } = props;
  const {
    location: { search },
  } = history;
  const query = querystring.parse(search.substring(1));
  const { page = "1" } = query;
  const pageInt = parseIntWithDefault(page, 1);
  const offset = pageInt * limit - limit;
  const paramObj = {
    job: uuid,
    limit,
  };
  // Only paginate (provide offset) if on the Job-Candidates page (aka preview == false)
  if (!preview) paramObj.offset = offset;
  // TODO: Edge case: User could be on page >= 2, then apply a status filter with less than
  // 1 page of results. Page number will remain too high. Need to reset page to 1 when status changes.
  if (status !== "default") paramObj.status = status;
  const params = querystring.stringify(paramObj);
  return createCandidatesQuery({
    url: `/api/v2/candidates/?${params}`,
  });
};
const candidatesResourceSelector = createResourceSelector(
  "candidates",
  mapPropsToConfig,
);
const mapStateToProps = (state, ownProps) => ({
  ...candidatesResourceSelector(state, ownProps),
  selectedColumn: state.hiringAgentDashboard.applicantsTable.selectedColumn,
});

export default compose(
  withScreenSize,
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobApplicantsTable);
