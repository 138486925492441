// @flow strict

export const jobExperienceRequirement = (min: ?number, max: ?number) => {
  if (typeof min !== "number" || typeof max !== "number") {
    return null;
  } else if (min === 0 && max === 50) {
    return "Any experience level";
  } else if (max === 50) {
    return `At least ${min} years experience`;
  } else {
    return `${min} to ${max} years experience`;
  }
};
