import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";
import { oldApi } from "common/api";

const actions = keyMirror({
  SET_USER_TYPE: null,
  REQUEST_USERS: null,
  RECEIVE_USERS: null,
  SET_SELECTED_HAE_USER: null,
  REQUEST_PRACTICE_AREAS_FOR_SEARCH_HISTORY: null,
  RECEIVE_PRACTICE_AREAS_FOR_SEARCH_HISTORY: null,
  CHANGE_SEARCH_PARAMS: null,
  REQUEST_SEARCH_HISTORIES: null,
  RECEIVE_SEARCH_HISTORIES: null,
});
export const setUserType = createAction(actions.SET_USER_TYPE);
export const changeSearchParams = createAction(actions.CHANGE_SEARCH_PARAMS);

export const requestPracticeAreas = () => dispatch => {
  dispatch(createAction(actions.REQUEST_PRACTICE_AREAS_FOR_SEARCH_HISTORY)());
  return oldApi.get("/api/dev/tags/?limit=300").then(({ json, response }) => {
    const payload = response.ok
      ? json
      : new Error("Error retrieving practice areas.");
    return dispatch(
      createAction(actions.RECEIVE_PRACTICE_AREAS_FOR_SEARCH_HISTORY)(payload),
    );
  });
};

export const requestUsers = () => (dispatch, getState) => {
  dispatch(createAction(actions.REQUEST_USERS)());
  const userType = getState().admin.searchHistories.userType.toLowerCase();
  return oldApi
    .get(`/api/v2/users/?type=${userType}&limit=9999`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json.results
        : new Error("Error retrieving attorneys.");
      return dispatch(
        createAction(actions.RECEIVE_USERS, p => p, metaGenerator)(payload),
      );
    });
};

export const requestSearchHistories = () => (dispatch, getState) => {
  dispatch(createAction(actions.REQUEST_SEARCH_HISTORIES)());
  const searchHistoriesState = getState().admin.searchHistories;
  const type = searchHistoriesState.userType;
  let searchParams = "";

  Object.keys(searchHistoriesState.searchParams).forEach(key => {
    if (
      searchHistoriesState.searchParams[key] ||
      searchHistoriesState.searchParams[key] === 0
    ) {
      searchParams += `&${key}=${searchHistoriesState.searchParams[key]}`;
    }
  });
  return oldApi
    .get(`/api/v2/search/?limit=9999&type=${type}${searchParams}`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json.results
        : new Error("Error retrieving search histories.");
      return dispatch(
        createAction(actions.RECEIVE_SEARCH_HISTORIES, p => p, metaGenerator)(
          payload,
        ),
      );
    });
};

export default actions;
