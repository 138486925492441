// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import ButtonContainer from "./ButtonContainer";
import Close from "./Close";
import Toggle from "./Toggle";
import Collapsible from "./Collapsible";
import Content from "./Content";

const StatementBlock: ComponentType<{}> = styled.div`
  width: 100%;
  margin: 0 0 50px 0;
`;

const Statement = (props: {}) => <StatementBlock {...props} />;

Statement.ButtonContainer = ButtonContainer;
Statement.Close = Close;
Statement.Toggle = Toggle;
Statement.Collapsible = Collapsible;
Statement.Content = Content;

export default Statement;
