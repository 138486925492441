import React, { Component } from "react";
import { createStructuredSelector } from "reselect";

import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import { compose, connect, connectRequest } from "queries/utils";
import types from "resources/types";
import Workstyle from "./blocks";
import IntroSection from "./IntroSection";
import MainSection from "./MainSection";
import OutroSection from "./OutroSection";
import { requestAsync } from "redux-query";
import { destroy } from "redux-form";
import DemographicsSection from "contractor/components/WorkstyleAssessment/DemographicsSection";

class WorkstyleAssessment extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      introComplete: false,
      demographicsComplete: false,
    };
  }

  componentWillUnmount() {
    this.props.destroyForm("workstyleAssessmentForm");
  }

  onFinishIntro = () => this.setState({ introComplete: true });

  onFinishDemographics = () => this.setState({ demographicsComplete: true });

  onFinishMain = () => {
    this.props.refreshCompetencyQuestions();
  };

  parseFinished = competencyQuestions => {
    let isFinished = false;

    if (
      competencyQuestions &&
      competencyQuestions.queries &&
      Object.values(competencyQuestions.queries)[0] &&
      Object.values(competencyQuestions.queries)[0].count === 0
    ) {
      isFinished = true;
    }

    return isFinished;
  };

  render() {
    const {
      competencyQuestions,
      ownContractor,
      match: { params },
      route,
    } = this.props;
    const contractor = ownContractor.data[Object.keys(ownContractor.data)[0]];
    const contractorId = contractor && contractor.uuid ? contractor.uuid : null;
    const { introComplete, demographicsComplete } = this.state;
    const isFinished = this.parseFinished(competencyQuestions);
    let questions = competencyQuestions
      ? Object.values(competencyQuestions.data)
      : [];

    if (isFinished) {
      return (
        <Workstyle>
          <OutroSection />
        </Workstyle>
      );
    }

    if (demographicsComplete) {
      return (
        <Workstyle>
          <MainSection
            competencyQuestions={questions}
            onFinishMain={this.onFinishMain}
            candidateId={params.candidateId}
            contractorId={contractorId}
            route={route}
          />
        </Workstyle>
      );
    }

    if (introComplete) {
      return (
        <Workstyle>
          <DemographicsSection
            onFinishDemographics={this.onFinishDemographics}
            contractor={contractor}
          />
        </Workstyle>
      );
    }

    return (
      <Workstyle>
        <IntroSection onFinishIntro={this.onFinishIntro} />
      </Workstyle>
    );
  }
}

const competencyQuestionsQuery = candidateId =>
  createResourceListQuery(types.COMPETENCY_QUESTIONS, {
    url: `/api/v2/competency_questions/?candidate_not_completed=${candidateId}&limit=999`,
    force: true,
    replace: true,
  });

const ownContractorQuery = () =>
  createResourceDetailQuery(types.OWN_CONTRACTOR, {
    url: "/api/dev/contractors/self/",
  });

const mapPropsToConfig = ({ match: { params } }) => [
  competencyQuestionsQuery(params.candidateId),
  ownContractorQuery(),
];

const competencyQuestionsSelector = createResourceSelectorConfig(
  types.COMPETENCY_QUESTIONS,
  competencyQuestionsQuery,
);

const ownContractorSelector = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);

const mapStateToProps = createStructuredSelector({
  ...competencyQuestionsSelector,
  ...ownContractorSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
  refreshCompetencyQuestions: () =>
    dispatch(
      requestAsync(competencyQuestionsQuery(props.match.params.candidateId)),
    ),
  destroyForm: formName => dispatch(destroy(formName)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(WorkstyleAssessment);
