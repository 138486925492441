import React from "react";

import { EditContractor } from "contractor/components/settings";
import AddressForm, {
  requiredFields,
} from "contractor/forms/settings/AddressForm";

const fields = ["address_1", "address_2", "city", "state", "postal_code"];

const props = {
  formComponentClass: AddressForm,
  title: "Address",
  requiredFields,
};

const getIntialValues = contractor => {
  if (contractor && contractor.uuid && contractor.location) {
    return fields.reduce(
      (res, val) => ({
        ...res,
        [val]: contractor.location[val],
      }),
      {},
    );
  } else {
    return {};
  }
};

const EditAddress = ({ contractor, ...otherProps }) => (
  <EditContractor
    contractorId={contractor.uuid}
    initialValues={getIntialValues(contractor)}
    transformValues={values => ({ location: values })}
    {...props}
    {...otherProps}
  />
);

export default EditAddress;
