import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Col, ControlLabel, Row } from "react-bootstrap";

import FormButton from "common/components/FormButton";
import { StateSelect, TextField } from "common/components/forms/inputs";

const formName = "barInformation";

class BarInformationForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm={4}>
            <TextField name="bar_id" label="Bar ID" />
          </Col>
          <Col sm={4}>
            <StateSelect name="state" label="State" />
          </Col>
          <Col sm={4}>
            <ControlLabel style={{ visibility: "hidden" }}>Save</ControlLabel>
            <FormButton
              action="save"
              submitting={submitting}
              disabled={pristine || submitting || !valid}
              block
            />
          </Col>
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(BarInformationForm);
