// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

const Main: ComponentType<{}> = styled.div`
  justify-self: end;
  width: 100%;
  height: 16px;
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.white()};
  border: 1px solid ${({ theme }) => theme.colors.primary_turquoise()};
  border-radius: 34px;
  box-sizing: border-box;

  ${({ theme }) => theme.media.mobile`
    justify-self: start;
    background-color: ${theme.colors.background_lightgray()};
    border-color: ${theme.colors.primary_black40()};
  `};
`;

export default Main;
