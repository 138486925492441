import React, { Component } from "react";
import { connect } from "react-redux";

import urls from "urls";
import Loading from "common/components/Loading";
import { Grid, Table, Button } from "react-bootstrap";
import {
  requestLanguageAudit,
  addLanguageProficiency,
} from "admin/actions/language-audit";

class Index extends Component {
  componentDidMount() {
    this.props.dispatch(requestLanguageAudit());
  }

  handleAddLanguage(attorney_uuid, language_uuid) {
    this.props.dispatch(addLanguageProficiency(attorney_uuid, language_uuid));
  }

  render() {
    const { isLoading, languageAudits } = this.props;
    const languagesWithAttorneys = languageAudits.filter(
      language => language.attorneys.length,
    );
    return (
      <Grid fluid={true}>
        <div>
          <h1 style={{ marginBottom: "2rem" }}>Language Audit Dashboard</h1>
        </div>
        <div>
          {isLoading ? (
            <div>
              <p>Please be patient, query can take up to 20 seconds.</p>
              <Loading />
            </div>
          ) : languageAudits && languagesWithAttorneys.length ? (
            languagesWithAttorneys.map(language => (
              <div key={`language-row-${language.uuid}`}>
                <h2>{language.name}</h2>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>(Admin opens in new tab)</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {language.attorneys.map(attorney => (
                      <tr key={`language-audit-row-${attorney.uuid}`}>
                        <td>{attorney.haeuser__first_name}</td>
                        <td>{attorney.haeuser__last_name}</td>
                        <td>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={urls["admin:contractor_contractor_change"](
                              attorney.uuid,
                            )}
                          >
                            View in Admin&nbsp;
                            <i className="fas fa-external-link-alt" />
                          </a>
                        </td>
                        <td>
                          {attorney.submitted ? (
                            <p>Elementary Language Proficiency Added!</p>
                          ) : (
                            <Button
                              onClick={() =>
                                this.handleAddLanguage(
                                  attorney.uuid,
                                  language.uuid,
                                )
                              }
                            >
                              Add Elementary Language Proficiency
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))
          ) : (
            <p>
              No contractors were found with potential language proficiencies.
            </p>
          )}
        </div>
      </Grid>
    );
  }
}

export default connect(state => state.admin.languageAudits)(Index);
