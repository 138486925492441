import actions from "admin/actions/nonAttorneyVerification";
import {
  getNonAttorneyVerificationStatus,
  verificationStatusMapping,
} from "admin/components/non-attorney-verification-manager/utils";

const getVerificationStatus = nonAttorney =>
  verificationStatusMapping[getNonAttorneyVerificationStatus(nonAttorney)].key;

const replaceNonAttorneyTranscript = (nonAttorney, newTranscript) => {
  nonAttorney.schools = nonAttorney.schools.map(school => {
    if (school.uuid == newTranscript.uuid) {
      return newTranscript;
    }
    if (
      school.schooltranscript &&
      school.schooltranscript.uuid == newTranscript.uuid
    ) {
      return newTranscript.school;
    }
    return school;
  });
  return nonAttorney;
};

const replaceNonAttorneyEmploymentReference = (nonAttorney, newReference) => {
  nonAttorney.previous_employments = nonAttorney.previous_employments.map(
    employment => {
      const { previousemploymentreference: ref } = employment;
      return {
        ...employment,
        previousemploymentreference:
          ref && ref.uuid === newReference.uuid ? newReference : ref,
      };
    },
  );
  return nonAttorney;
};

const replaceNonAttorneyReference = (nonAttorney, newReview) => {
  nonAttorney.review_requests = nonAttorney.review_requests.map(
    review_request => {
      const { review } = review_request;
      return {
        ...review_request,
        review: review && review.uuid === newReview.uuid ? newReview : review,
      };
    },
  );
  return nonAttorney;
};

export const utils = {
  replaceNonAttorneyTranscript,
  replaceNonAttorneyEmploymentReference,
  replaceNonAttorneyReference,
};

const handleReceiveVerification = (type, state, nonAttorney) => {
  const {
    data: { count, results },
  } = state;
  let newResults = results ? [...results] : [];
  let newCount = count;

  const nextStatus = getVerificationStatus(nonAttorney);
  const nonAttorneyIndex =
    results && results.findIndex(na => na.uuid === nonAttorney.uuid);

  const nonAttorneyInResults = nonAttorneyIndex >= 0;
  const nonAttorneyBelongsInResults = nextStatus === type;

  const shouldUpdate = nonAttorneyInResults && nonAttorneyBelongsInResults;
  const shouldRemove = nonAttorneyInResults && !nonAttorneyBelongsInResults;
  const shouldAdd = !nonAttorneyInResults && nonAttorneyBelongsInResults;

  if (shouldRemove) {
    newResults.splice(nonAttorneyIndex, 1);
    newCount -= 1;
  } else if (shouldAdd) {
    newResults = [nonAttorney, ...newResults];
    newCount += 1;
  } else if (shouldUpdate) {
    newResults[nonAttorneyIndex] = nonAttorney;
  }

  return {
    ...state,
    data: {
      ...state.data,
      count: newCount,
      results: newResults,
    },
  };
};

export default type => ({
  [actions.RECEIVE_CHANGE_SCHOOL_TRANSCRIPT_STATUS]: (state, action) => {
    if (action.error) return state;

    const { nonAttorney, schoolTranscript } = action.payload;

    replaceNonAttorneyTranscript(nonAttorney, schoolTranscript);

    return handleReceiveVerification(type, state, nonAttorney);
  },
  [actions.RECEIVE_CHANGE_EMPLOYMENT_REFERENCE_STATUS]: (state, action) => {
    if (action.error) return state;

    const { nonAttorney, employmentReference } = action.payload;

    replaceNonAttorneyEmploymentReference(nonAttorney, employmentReference);

    return handleReceiveVerification(type, state, nonAttorney);
  },
  [actions.RECEIVE_CHANGE_REFERENCE_STATUS]: (state, action) => {
    if (action.error) return state;
    const { nonAttorney, review } = action.payload;

    replaceNonAttorneyReference(nonAttorney, review);
    return handleReceiveVerification(type, state, nonAttorney);
  },
  [actions.RECEIVE_UPDATED_CONTRACTOR]: (state, action) => {
    if (action.error) return state;
    return handleReceiveVerification(type, state, action.payload);
  },
});
