import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { metaGenerator } from "common/actions";
import api, { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_COMPLETE_AGENT_ONBOARDING: null,
  RECEIVE_COMPLETE_AGENT_ONBOARDING: null,
  REQUEST_AGENCY_NEEDS_PROFILE: null,
  REQUEST_UPDATE_AGENCY_NEEDS_PROFILE: null,
  RECEIVE_AGENCY_NEEDS_PROFILE: null,
  INCREMENT_NEEDS_PROFILE_PAGE: null,
  DECREMENT_NEEDS_PROFILE_PAGE: null,
  GO_TO_NEEDS_PROFILE_PAGE: null,
  TOGGLE_NEEDS_PROFILE_MODAL: null,
  SET_CONTRACTOR_TYPE: null,
  SET_HIRING_REASON: null,
  SET_TAG_GROUP: null,
  HIDE_ONBOARDING: null,
});
export default actions;

export const completeAgentOnboading = agentId =>
  api.post(`/api/dev/hiring_agents/${agentId}/complete_onboarding/`, [
    actions.REQUEST_COMPLETE_AGENT_ONBOARDING,
    actions.RECEIVE_COMPLETE_AGENT_ONBOARDING,
  ]);

export const submitAgencyNeedsProfile = (need_type, data) => (
  dispatch,
  getState,
) => {
  dispatch(createAction(actions.REQUEST_UPDATE_AGENCY_NEEDS_PROFILE)());
  const { needs_id } = getState().hiringAgentDashboard.agency.agency;
  return oldApi
    .patch(`/api/v2/hiring_needs/${needs_id}/`, { [need_type]: data })
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error submitting onboarding profile.");
      dispatch(
        createAction(actions.RECEIVE_AGENCY_NEEDS_PROFILE, null, metaGenerator)(
          payload,
        ),
      );
      return { json, response };
    });
};

export const getAgencyNeedsProfile = () => (dispatch, getState) => {
  dispatch(createAction(actions.REQUEST_AGENCY_NEEDS_PROFILE)());
  const { needs_id } = getState().hiringAgentDashboard.agency.agency;
  return oldApi
    .get(`/api/v2/hiring_needs/${needs_id}/`)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error submitting onboarding profile.");
      dispatch(
        createAction(actions.RECEIVE_AGENCY_NEEDS_PROFILE, null, metaGenerator)(
          payload,
        ),
      );
      return { json, response };
    });
};

export const showNeedsProfileModal = status => dispatch =>
  dispatch(createAction(actions.TOGGLE_NEEDS_PROFILE_MODAL)(status));

export const incrementNeedsProfilePage = createAction(
  actions.INCREMENT_NEEDS_PROFILE_PAGE,
);
export const decrementNeedsProfilePage = createAction(
  actions.DECREMENT_NEEDS_PROFILE_PAGE,
);
export const goToNeedsProfilePage = createAction(
  actions.GO_TO_NEEDS_PROFILE_PAGE,
);

export const setContractorType = createAction(actions.SET_CONTRACTOR_TYPE);
export const setHiringReason = createAction(actions.SET_HIRING_REASON);
export const setTagGroup = createAction(actions.SET_TAG_GROUP);

export const hideOnboarding = createAction(actions.HIDE_ONBOARDING);
