// @flow strict
import React, { Component, Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { compose, connect, connectRequest, mutateAsync } from "queries/utils";
import { Helmet } from "react-helmet";
import { querySelectors } from "redux-query";

import { ownUserQuery } from "queries/requests/auth";
import { resendConfirmationEmailQuery } from "queries/mutations/auth";
import * as Signup from "./blocks";

type Props = {
  onResendClick: () => Promise<QueryResponse<OwnUser>>,
  ownUser: {
    data?: OwnUser,
    isFinished: ?boolean,
  },
};
type State = {
  resent: boolean,
  error: boolean,
};

class SignupComplete extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { resent: false, error: false };
  }
  handleResendClick = async e => {
    e.preventDefault();
    const response: QueryResponse<OwnUser> = await this.props.onResendClick();
    const isSuccessful = response.status === 200;
    this.setState({
      resent: isSuccessful,
      error: !isSuccessful,
    });
  };
  renderError() {
    return (
      <p className="text-danger">
        We&apos;re sorry, there was a problem submitting your request. Please
        refresh this page and try again.
      </p>
    );
  }
  renderResent() {
    return (
      <Fragment>
        <p>Your confirmation email has been resent.</p>
        <p>Please check your email inbox for your confirmation link.</p>
        <p>
          <Button bsStyle="info" href="/login">
            Back To Login
          </Button>
        </p>
      </Fragment>
    );
  }
  renderContent() {
    const {
      ownUser: { data: user, isFinished },
    } = this.props;
    const { error, resent } = this.state;

    if (error) return this.renderError();
    if (resent) return this.renderResent();

    let userEmail = "";
    if (isFinished && user) {
      userEmail = (
        <Fragment>
          at <code>{user.email_address}</code>
        </Fragment>
      );
    }

    return (
      <Row>
        <Col md={8} mdOffset={2}>
          <p>
            Check your inbox {userEmail} for a confirmation email. Follow the
            instructions to confirm your account.
          </p>

          <p>
            Can&apos;t find your confirmation email?
            <br />
            <a onClick={this.handleResendClick} href="">
              Click here to have it resent
            </a>
          </p>
        </Col>
      </Row>
    );
  }
  render() {
    return (
      <Row>
        <Helmet>
          <title>Signup Complete | Hire an Esquire</title>
        </Helmet>
        <Signup.Title>Thanks for joining Hire an Esquire!</Signup.Title>
        {this.renderContent()}
      </Row>
    );
  }
}

const mapPropsToConfig = ownUserQuery;
const mapStateToProps = state => ({
  ownUser: {
    ...state.entities.ownUser,
    isFinished: querySelectors.isFinished(state.queries, mapPropsToConfig()),
  },
});
const mapDispatchToProps = {
  onResendClick: () => mutateAsync(resendConfirmationEmailQuery()),
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(SignupComplete);
