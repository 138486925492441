import React, { Component } from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import {
  Alert,
  Col,
  Row,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";

import { Loading } from "common/components";
import { contractorTypeChoices } from "hiringagent-dashboard/components/jobs/create-job-listing/ContractorTypes";

import { connectConnectRequest } from "queries/utils";
import { tagGroupRequirementsQuery } from "queries/requests";
import { createResourceSelector } from "queries";
import types from "resources/types";

const practiceAreaRequirementChoices = {
  NOT_NEEDED: -1,
  REQUIRED: 0,
  PREFERRED: 1,
};

const practiceAreaRequirementInputOptions = [
  { label: "Required", value: practiceAreaRequirementChoices.REQUIRED },
  { label: "Preferred", value: practiceAreaRequirementChoices.PREFERRED },
  { label: "Not needed", value: practiceAreaRequirementChoices.NOT_NEEDED },
];

const categories = {
  DEFAULT: 0,
  ATTORNEY: 1,
  NON_ATTORNEY: 2,
};

const isRequirementCorrectContractorType = allowedApplicants => requirement => {
  if (allowedApplicants === contractorTypeChoices.ANY) {
    return true;
  } else if (allowedApplicants === contractorTypeChoices.ATTORNEYS_ONLY) {
    return attorneyCategories.includes(requirement.category);
  } else if (allowedApplicants === contractorTypeChoices.NON_ATTORNEYS_ONLY) {
    return nonAttorneyCategories.includes(requirement.category);
  }
  return false;
};

const isRequirementNotInFields = allFields => requirement =>
  // Only push fields that have not already been added by initialValues
  allFields.findIndex(
    field => field.tag_group_requirement === requirement.uuid,
  ) === -1;

const attorneyCategories = [categories.DEFAULT, categories.ATTORNEY];

const nonAttorneyCategories = [categories.DEFAULT, categories.NON_ATTORNEY];

const PracticeAreaRequirementOptions = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
`;

const PracticeAreaRequirementToggleGroup = styled(ToggleButtonGroup)`
  margin-bottom: 1rem;
  ${({ theme }) => theme.media.lg`
    margin-bottom: 0;
    margin-right: 1rem;
  `};
`;

const PracticeAreaRequirementLabel = styled.div`
  margin-bottom: 2rem;
`;

const ButtonContainer = styled(Col)`
  text-align: center;
  ${({ theme }) => theme.media.lg`
    text-align: left;
  `};
`;

const PracticeAreaRequirementDisplay = ({ input, label, options }) => (
  <PracticeAreaRequirementOptions>
    <Row>
      <Col lg={7} lgPush={5} md={6} mdPush={6} xs={12}>
        <PracticeAreaRequirementLabel>{label}</PracticeAreaRequirementLabel>
      </Col>
      <ButtonContainer lg={5} lgPull={7} md={6} mdPull={6} xs={12}>
        <PracticeAreaRequirementToggleGroup
          type="radio"
          {...input}
          className="visible-lg"
        >
          {options.map(({ label, value }) => (
            <ToggleButton
              key={`toggle-option-${input.name}-${value}`}
              value={value}
              bsSize="sm"
            >
              <i
                className={`far fa-fw ${
                  input.value === value
                    ? "text-success fa-check-square"
                    : "text-muted fa-square"
                }`}
              />
              &nbsp;{label}
            </ToggleButton>
          ))}
        </PracticeAreaRequirementToggleGroup>
        <PracticeAreaRequirementToggleGroup
          type="radio"
          {...input}
          className="hidden-lg"
        >
          {options.map(({ label, value }) => (
            <ToggleButton
              key={`toggle-option-${input.name}-${value}-sm`}
              value={value}
              bsSize="xs"
            >
              <i
                className={`far fa-fw ${
                  input.value === value
                    ? "text-success fa-check-square"
                    : "text-muted fa-square"
                }`}
              />
              &nbsp;{label}
            </ToggleButton>
          ))}
        </PracticeAreaRequirementToggleGroup>
      </ButtonContainer>
    </Row>
  </PracticeAreaRequirementOptions>
);

class PracticeAreaRequirementsFieldArray extends Component {
  addMissingFields = () => {
    const {
      fields,
      tagGroupRequirements,
      formValues: { allowed_applicants },
    } = this.props;

    const allFields = fields.getAll();
    // Push a field for each requirement that does not have a preference
    tagGroupRequirements
      .filter(isRequirementCorrectContractorType(allowed_applicants))
      .filter(isRequirementNotInFields(allFields))
      .forEach(requirement => {
        fields.push({
          preference: practiceAreaRequirementChoices.NOT_NEEDED,
          tag_group_requirement: requirement.uuid,
        });
      });
  };
  componentDidUpdate() {
    this.addMissingFields();
  }
  componentDidMount() {
    this.addMissingFields();
  }
  componentWillUnmount() {
    this.props.fields.removeAll();
  }

  render() {
    const {
      fields,
      tagGroupRequirements,
      meta: { dirty, warning },
    } = this.props;
    return (
      <div style={{ marginBottom: "4rem" }}>
        {fields.map((fieldName, idx) => {
          const field = fields.get(idx);
          let requirement, label;

          if (field && field.tag_group_requirement) {
            requirement = tagGroupRequirements.find(
              req => req.uuid === field.tag_group_requirement,
            );
            if (requirement && requirement.requirement) {
              label = requirement.requirement;
            }
          }

          return label && fieldName ? (
            <div key={fieldName}>
              <Field
                name={`${fieldName}.preference`}
                component={PracticeAreaRequirementDisplay}
                label={label}
                options={practiceAreaRequirementInputOptions}
                parse={value => parseInt(value, 10)}
              />
            </div>
          ) : null;
        })}
        {dirty && (
          <div>{warning && <Alert bsStyle="warning">{warning}</Alert>}</div>
        )}
      </div>
    );
  }
}

const PracticeAreaRequirementsField = props => {
  const {
    [types.TAG_GROUP_REQUIREMENTS]: {
      data: tagGroupRequirementsData,
      query: { data: queryData },
      isPending,
      isFinished,
    },
    ...otherProps
  } = props;

  return isFinished && !isPending ? (
    <PracticeAreaRequirementsFieldArray
      tagGroupRequirements={queryData.map(id => tagGroupRequirementsData[id])}
      {...otherProps}
    />
  ) : (
    <Loading />
  );
};

const mapPropsToConfig = props =>
  tagGroupRequirementsQuery(props.formValues.primary_practice_area);
const mapStateToProps = createResourceSelector(
  types.TAG_GROUP_REQUIREMENTS,
  mapPropsToConfig,
);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  PracticeAreaRequirementsField,
);
