// @flow
import React from "react";
import { compose, connect, connectRequest } from "queries/utils";

import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

const JobPrimaryPracticeArea = props => {
  const {
    primary_practice_area,
    [types.TAG_GROUPS]: { data, lastUpdated },
  } = props;

  return (
    <span>
      {lastUpdated &&
        data[primary_practice_area] &&
        data[primary_practice_area].name}
    </span>
  );
};

const mapPropsToConfig = () =>
  createResourceListQuery(types.TAG_GROUPS, {
    url: "/api/dev/tag_groups/?limit=999",
  });

const mapStatetoProps = createResourceSelector(
  types.TAG_GROUPS,
  mapPropsToConfig,
);

export default compose(
  connect(mapStatetoProps),
  connectRequest(mapPropsToConfig),
)(JobPrimaryPracticeArea);
