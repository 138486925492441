// @flow strict
import React from "react";
import { Link } from "react-router-dom";

import Top from "./blocks";

type Props = {
  onCloseAll?: () => void,
  userType: UserType,
};

const Brand = ({ onCloseAll, userType }: Props) => {
  let to = "/";

  switch (userType) {
    case "admin":
      to = "/hae_admin/dashboards/";
      break;
    case "anonymous":
      to = "/login/";
      break;
    case "contractor":
      to = "/contractor/";
      break;
    case "hiring_agent":
      to = "/agency/";
      break;
    default:
      to = "/";
  }

  return (
    <Link
      onClick={() => {
        if (onCloseAll) onCloseAll();
      }}
      to={to}
    >
      <Top.Brand />
    </Link>
  );
};

export default Brand;
