import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import urls from "urls";
import api, { oldApi } from "common/api";
import { metaGenerator } from "common/actions";

const actions = keyMirror({
  REQUEST_INVOICE: null,
  RECEIVE_INVOICE: null,
  REQUEST_INVOICES: null,
  RECEIVE_INVOICES: null,
  SET_INVOICES_JOB_FILTER: null,
  RESET_INVOICES_FILTERS: null,
  SET_INVOICES_SORTING: null,
  REQUEST_APPROVE_INVOICE: null,
  RECEIVE_APPROVE_INVOICE: null,
});
export default actions;

export const setInvoicesJobFilter = createAction(
  actions.SET_INVOICES_JOB_FILTER,
);
export const resetFilters = createAction(actions.RESET_INVOICES_FILTERS);
export const setSortingBy = createAction(actions.SET_INVOICES_SORTING);

export const requestInvoice = invoiceId =>
  api.get(`/api/dev/invoices/${invoiceId}/`, [
    actions.REQUEST_INVOICE,
    actions.RECEIVE_INVOICE,
  ]);

export const requestInvoices = ({ count = 999 } = {}) => (
  dispatch,
  getState,
) => {
  const { allLoaded } = getState().hiringAgentDashboard.invoices;
  if (!allLoaded) {
    dispatch(createAction(actions.REQUEST_INVOICES)());
    return oldApi
      .get(`/api/dev/invoices/?limit=${count}`)
      .then(({ json, response }) => {
        const payload = response.ok
          ? json
          : new Error("Error getting invoices.");
        payload.allFetched = count === 999;
        dispatch(
          createAction(actions.RECEIVE_INVOICES, p => p, metaGenerator)(
            payload,
          ),
        );
        return { json, response };
      });
  }
  return null;
};

// TODO: Request action through API, replace: 'hiringagency:approve_invoice_v2'
export const approveInvoice = invoiceId => dispatch => {
  dispatch(createAction(actions.REQUEST_APPROVE_INVOICE)());
  return oldApi
    .get(urls["hiringagency:approve_invoice_v2"](invoiceId))
    .then(({ json, response }) => {
      const payload = response.ok ? json : new Error("Error approving invoice");
      dispatch(
        createAction(
          actions.RECEIVE_APPROVE_INVOICE,
          payload => payload,
          metaGenerator,
        )(payload),
      );
      return dispatch(requestInvoice(invoiceId));
    });
};
