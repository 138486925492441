import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "react-bootstrap";

export default class RenderWithCheckbox extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  };

  static defaultProps = {
    text: "",
  };

  state = {
    isChecked: false,
  };

  handleCheckboxClick = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  render() {
    const { isChecked } = this.state;
    return (
      <Fragment>
        {this.props.render(isChecked)}
        <Checkbox checked={isChecked} onClick={this.handleCheckboxClick}>
          {this.props.text}
        </Checkbox>
      </Fragment>
    );
  }
}
