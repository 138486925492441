import React, { Component } from "react";
import { connect, compose, connectResource } from "resources/components/utils";
import { SubmissionError, initialize } from "redux-form";
import { addNotification as notify } from "reapop";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";

import { getActiveReviewRequests } from "contractor/selectors";
import {
  createReferenceRequest,
  deleteReferenceRequest,
  resendReferenceRequest,
} from "resources/actions/referenceRequests";
import { reviewRequestsRequest } from "resources/requests";
import { RequiredFieldError, validateRequiredFields } from "common/utils/forms";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import ReferencesForm, {
  formName,
  requiredFields,
} from "contractor/forms/settings/ReferencesForm";
import ListReferences from "./ListReferences";

const ReferenceModal = ({ type, reference, onClose, onSubmit, show }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>
        <span className="text-capitalize">{`Confirm ${type}`}</span>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4 className="text-center">
        {`Are you sure you want to ${type} the following reference request?`}
      </h4>
      {reference && (
        <dl className="dl-horizontal">
          <dt>First Name</dt>
          <dd>{reference.reviewer_first_name}</dd>
          <dt>Last Name</dt>
          <dd>{reference.reviewer_last_name}</dd>
          <dt>Email</dt>
          <dd>{reference.reviewer_email}</dd>
        </dl>
      )}
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar>
        <Button bsStyle="link" onClick={onClose}>
          Cancel
        </Button>
        <Button
          bsStyle={type === "delete" ? "danger" : "info"}
          onClick={onSubmit}
          className="pull-right"
        >
          <span className="text-capitalize">{type}</span>
        </Button>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>
);

const ConfirmDeleteReferenceModal = props => (
  <ReferenceModal type="delete" {...props} />
);

const ConfirmResendReferenceModal = props => (
  <ReferenceModal type="resend" {...props} />
);

class EditReferences extends Component {
  state = {
    deletingReferenceId: null,
    resendingReferenceId: null,
  };

  validate = values => {
    const errors = validateRequiredFields(requiredFields, values);
    if (!values.agree_real_reference)
      errors.agree_real_reference = <RequiredFieldError />;
    return errors;
  };

  handleSubmit = values => {
    const { notify, initializeForm } = this.props;
    return this.props.onSubmit(values).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      initializeForm();
      notify(createSuccessNotification({ message: "Reference request sent." }));
    });
  };

  handleDeleteReferenceClick = referenceId =>
    this.setState({ deletingReferenceId: referenceId });

  handleDeleteReferenceCloseClick = () =>
    this.setState({ deletingReferenceId: null });

  handleDeleteReferenceConfirmClick = () => {
    const {
      notify,
      onDelete,
      onReloadHandlers: { reviewRequests: getReviewRequests },
    } = this.props;
    onDelete(this.state.deletingReferenceId).then(() => {
      this.setState({ deletingReferenceId: null });
      getReviewRequests();
      notify(
        createSuccessNotification({ message: "Reference request deleted." }),
      );
    });
  };

  handleResendReferenceClick = referenceId =>
    this.setState({ resendingReferenceId: referenceId });

  handleResendReferenceCloseClick = () =>
    this.setState({ resendingReferenceId: null });

  handleResendReferenceConfirmClick = () => {
    const { notify, onResend } = this.props;
    onResend(this.state.resendingReferenceId).then(
      ({ response }) =>
        response.ok
          ? notify(
              createSuccessNotification({
                message: "Reference request resent.",
              }),
            )
          : notify(
              createErrorNotification({
                message: "Reference request was not resent.",
              }),
            ),
    );
    this.setState({ resendingReferenceId: null });
  };

  render() {
    const { deletingReferenceId, resendingReferenceId } = this.state;
    const { activeReviewRequests: requests } = this.props;

    return (
      <div className="references">
        <h2 className="edit-section-title">References</h2>
        <ListReferences
          onDeleteClick={this.handleDeleteReferenceClick}
          onResendClick={this.handleResendReferenceClick}
        />
        <hr />
        <ReferencesForm onSubmit={this.handleSubmit} validate={this.validate} />
        <ConfirmDeleteReferenceModal
          show={Boolean(deletingReferenceId)}
          onSubmit={this.handleDeleteReferenceConfirmClick}
          onClose={this.handleDeleteReferenceCloseClick}
          reference={requests.find(r => r.uuid === deletingReferenceId)}
        />
        <ConfirmResendReferenceModal
          show={Boolean(resendingReferenceId)}
          onSubmit={this.handleResendReferenceConfirmClick}
          onClose={this.handleResendReferenceCloseClick}
          reference={requests.find(r => r.uuid === resendingReferenceId)}
        />
      </div>
    );
  }
}

export default (EditReferences = compose(
  connect(
    state => ({
      activeReviewRequests: getActiveReviewRequests()(state),
    }),
    {
      onSubmit: createReferenceRequest,
      onDelete: deleteReferenceRequest,
      onResend: resendReferenceRequest,
      initializeForm: () => initialize(formName),
      notify,
    },
  ),
  connectResource(reviewRequestsRequest),
)(EditReferences));
