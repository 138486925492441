import React, { Component } from "react";
import { connect } from "react-redux";

import { Loading } from "common/components";
import { withMetadata } from "common/connectors";

import { initialize } from "hiringagent-dashboard/actions/search";

import AttorneySearchBar from "./search/AttorneySearchBar";
import AttorneySearchResultsContainer from "./search/AttorneySearchResultsContainer";

const AttorneySearchContainer = withMetadata()(
  ({ location: { query }, metadata }) => {
    const { ready } = metadata;
    if (!ready) return <Loading />;
    return <AttorneySearch query={query} />;
  },
);

export { AttorneySearchContainer };

class AttorneySearch extends Component {
  componentDidMount() {
    const { query } = this.props;
    this.props.dispatch(initialize(query));
  }

  render() {
    return (
      <div id="search-index-container">
        <h1>
          <i className="far fa-search" />
          Find Candidates
        </h1>
        <div id="search-bar-container">
          <AttorneySearchBar key="search-bar" />
        </div>
        <AttorneySearchResultsContainer />
      </div>
    );
  }
}

AttorneySearch = connect(state => state.hiringAgentDashboard.search)(
  AttorneySearch,
);

export default AttorneySearch;
