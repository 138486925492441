import React, { Component } from "react";
import { initialize, reduxForm, SubmissionError } from "redux-form";
import { Alert } from "react-bootstrap";
import { addNotification as notify } from "reapop";

import FormButton from "common/components/FormButton";
import { PasswordInputsWithReveal } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";
import { Error, validatePasswords } from "common/utils/forms/validators";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";

import { connect, mutateAsync } from "queries/utils";
import { createQueryConfig } from "queries";

export const formName = "passwordForm";
export const requiredFields = ["new_password1", "new_password2"];

export const PasswordRequirements = () => (
  <p className="text-muted">
    <i className="far fa-info-circle" />&nbsp;Your password should be 8+
    characters long and include an uppercase letter, a lowercase letter, and a
    number or symbol.
  </p>
);

const validate = values => {
  let errors = validateRequiredFields(requiredFields, values);
  errors = validatePasswords(values, errors, "new_password1", "new_password2");
  return errors;
};

let PasswordForm = ({ handleSubmit, error, pristine, submitting }) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <PasswordRequirements />
    <PasswordInputsWithReveal />
    <div className="text-right">
      <FormButton
        className="text-right"
        action="save"
        submitting={submitting}
        disabled={pristine || submitting}
      />
    </div>
  </form>
);

PasswordForm = reduxForm({
  form: formName,
  validate,
})(PasswordForm);

class ChangePassword extends Component {
  validate = values => {
    const errors = {};
    const requiredErrors = validateRequiredFields(requiredFields, values);
    if (Object.keys(requiredErrors).length > 0) return requiredErrors;
    return validatePasswords(values, errors);
  };

  handleSubmit = async values => {
    const { changePassword, initializeForm, notify } = this.props;
    const response = await changePassword(values);

    const { body = {}, status } = response;
    if (status >= 200 && status < 300) {
      notify(
        createSuccessNotification({
          message: "Your password has been updated.",
        }),
      );
      initializeForm();
    } else {
      notify(createErrorNotification());
      body._error = (
        <Error>
          There was a problem submitting your request. Please try again.
        </Error>
      );
      throw new SubmissionError(body);
    }
  };

  render() {
    return <PasswordForm onSubmit={this.handleSubmit} />;
  }
}

export default connect(
  null,
  {
    changePassword: data =>
      mutateAsync(
        createQueryConfig({
          url: "/api/auth/password/change/",
          body: data,
        }),
      ),
    initializeForm: () => initialize(formName),
    notify,
  },
)(ChangePassword);
