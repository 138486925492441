import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, ControlLabel, FormGroup } from "react-bootstrap";
import Dropzone from "react-dropzone";
import ReactSelect from "react-select";

import { addTranscript } from "contractor/actions/profile";
import {
  NumberField,
  SelectField,
  TextAreaField,
  TextField,
} from "common/components/forms/inputs";
import { withDegreeTypes } from "common/connectors";
import { withOwnContractor, withSchools } from "contractor/connectors";
import { algolia } from "common/api/algolia";

export const formName = "educationForm";
export const requiredFields = [
  "school_name",
  "degree_type",
  "year_graduated",
  "address",
  "city",
  "postal_code",
];

class SchoolSelect extends Component {
  handleChange = newValue => {
    algolia("hae_school_index").getObject(newValue.value, (err, content) => {
      this.props.onChange({
        school_name: content.name,
        address: content.location.address,
        city: content.location.city,
        state: content.location.state,
        postal_code: content.location.postal_code,
        meta_data_id: content.objectID,
      });
    });
  };

  handleLoadOptions = (input, callback) => {
    algolia("hae_school_index").search(input, (err, content) => {
      const options = input
        ? content.hits.map(obj => ({
            value: obj.objectID,
            label: obj.name,
          }))
        : [];
      callback(null, { options });
    });
  };

  render() {
    return (
      <ReactSelect.Async
        loadOptions={this.handleLoadOptions}
        placeholder={"Search for your school here"}
        cache={false}
        cacheAsyncResults={false}
        onChange={this.handleChange}
        autoload={false}
        filterOption={() => true}
      />
    );
  }
}

class SchoolForm extends Component {
  handleSchoolChange = values => {
    const { change } = this.props;
    change("school_name", values.school_name);
  };

  handleSubmitFile = files => {
    const transcript = files[0];
    this.props.addTranscript(transcript);
  };

  render() {
    const {
      ownContractor: { data: contractor, ready: contractorReady },
      degreeTypes: {
        data: { results = [] },
      },
      schools: { transcript },
      handleSubmit,
      pristine,
      submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <ControlLabel>School Search</ControlLabel>
          <SchoolSelect onChange={this.handleSchoolChange} />
        </FormGroup>
        <TextField name="school_name" label="School Name" />
        <SelectField name="degree_type" label="Degree Type">
          {results.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectField>
        <NumberField name="year_graduated" label="Year Graduated" />
        <TextField name="gpa" label="GPA" />
        <TextAreaField
          name="additional_notes"
          label="Additional Notes"
          rows={8}
          maxLength={500}
        />
        {contractorReady &&
          !contractor.is_attorney && (
            <div>
              <label>Transcript</label>
              <div className="dropzone-container">
                <Dropzone
                  multiple={false}
                  className="file-upload-dropzone dropzone"
                  activeClassName="active-file-upload-dropzone"
                  onDrop={this.handleSubmitFile}
                  maxSize={15728640}
                  style={{
                    border: "3px dashed #DDD",
                    borderRadius: "4px",
                    cursor: "pointer",
                    padding: "2rem",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div className="text-center">
                      <i className="fas fa-file-alt" />&nbsp;
                      <span className="dropzone-text">
                        {transcript
                          ? `File selected: ${transcript.name}`
                          : "Drag a file here or click to browse."}
                      </span>
                      <div />
                    </div>
                  </div>
                </Dropzone>
              </div>
            </div>
          )}
        <div className="text-right">
          <Button
            type="submit"
            bsStyle="primary"
            disabled={pristine || submitting}
          >
            {submitting ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    );
  }
}

export default compose(
  connect(
    null,
    {
      addTranscript,
    },
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }),
  withDegreeTypes(),
  withOwnContractor(),
  withSchools(),
)(SchoolForm);
