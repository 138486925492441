import React from "react";
import styled from "styled-components";

import { OpacityTransition } from "common/utils/transitions";

const Item = styled.li`
  color: ${({ theme }) => theme.colors.primary_black60()};
  margin-bottom: 7.5px;
  & > * {
    border: 1px solid #b9dbe8;
    display: inline-block;
  }
`;

const Label = styled.span`
  border-radius: 2px 0 0 2px;
  border-right: none;
  padding: 2px 6px;
`;

const Delete = styled.span`
  border-radius: 0 2px 2px 0;
  padding: 2px 8px;
  cursor: ${({ disabled }) => (disabled ? "wait" : "pointer")};
  display: inline-block;
  color: #7abad3;

  &:hover {
    background-color: #b9dbe8;
    transition: background-color 100ms ease-in;
  }
`;

const SelectedItem = ({ label, onDelete, disabled = false }) => (
  <Item>
    <Label>{label}</Label>
    {onDelete && (
      <Delete onClick={onDelete} disabled={disabled}>
        <i className="far fa-times" />
      </Delete>
    )}
  </Item>
);

SelectedItem.Label = Label;
SelectedItem.Delete = Delete;
export { SelectedItem };

const SelectedItemsInlineList = styled.ul.attrs({ className: "list-inline" })`
  margin-bottom: 0;
`;
const SelectedItemsList = props => (
  <OpacityTransition component={SelectedItemsInlineList} {...props} />
);

export default SelectedItemsList;
