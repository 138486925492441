import React, { Component } from "react";
import PropTypes from "prop-types";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { history } from "app";

class NavMenu extends Component {
  static propTypes = {
    baseRoute: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
        nonAttorney: PropTypes.bool,
      }),
    ).isRequired,
  };

  renderMenuLinks = () => {
    const { baseRoute, isAttorney, routes } = this.props;
    const menuRoutes = isAttorney
      ? routes
      : routes.filter(route => route.nonAttorney);

    return menuRoutes.map(({ name, route }, i) => (
      <LinkContainer to={`${baseRoute}${route}`} key={`nav-men-link-${route}`}>
        <NavItem eventKey={i}>{name}</NavItem>
      </LinkContainer>
    ));
  };

  renderMobileNav = () => {
    const { baseRoute, routes } = this.props;
    const {
      location: { pathname },
    } = history;
    const currentSection = routes.find(
      ({ route }) => `${baseRoute}${route}` === pathname,
    );
    const currentSectionName = currentSection && currentSection.name;

    return (
      <Navbar
        collapseOnSelect
        className="visible-xs-block"
        style={{ margin: "1rem 0 3rem 0", borderRadius: "5px" }}
      >
        <Navbar.Header>
          <Navbar.Brand style={{ padding: "15px" }}>
            {currentSectionName || "Select Section to Edit"}
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav bsStyle="pills" stacked>
            {this.renderMenuLinks()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };

  renderNonMobileNav = () => (
    <Nav bsStyle="pills" stacked className="hidden-xs">
      {this.renderMenuLinks()}
    </Nav>
  );

  render() {
    return (
      <div>
        {this.renderMobileNav()}
        {this.renderNonMobileNav()}
      </div>
    );
  }
}

export default NavMenu;
