import React, { Component } from "react";
import PropTypes from "prop-types";
import { addNotification as notify } from "reapop";
import querystring from "querystring";
import { createStructuredSelector } from "reselect";

import { compose, connect, connectRequest } from "queries/utils";
import LoadingOr from "common/components/LoadingOr";
import NotFound, { ContactSupportLink } from "public/components/NotFound";
import { createSuccessNotification } from "common/utils/notifications";
import ContractorProfileHead from "common/components/contractor-profile/ContractorProfileHead";
import {
  ContractorProfileBody,
  ContractorProfileHeader,
} from "common/components/contractor-profile";
import { requestContractor } from "hiringagent-dashboard/actions/attorneys";
import types from "resources/types";
import { createResourceSelectorConfig } from "queries";
import { createCandidatesQuery } from "queries/requests/candidates";
import ResponsiveContainer from "common/components/ResponsiveContainer";
import { currentUserTypeQuery } from "queries/requests/auth";
import { contractorByIdQuery } from "queries/requests/contractors";

const clioSuccessMessage = createSuccessNotification({
  message: "Clio Account is now linked.",
});

const PrivateProfile = () => (
  <ResponsiveContainer>
    <h1>Private Profile</h1>
    <p>This profile is marked as private.</p>
    <ContactSupportLink />
  </ResponsiveContainer>
);

class ContractorProfile extends Component {
  static propTypes = {
    contractorId: PropTypes.string,
    isPublicProfile: PropTypes.bool,
    isSharedProfile: PropTypes.bool,
  };

  static defaultProps = {
    contractorId: null,
    isPublicProfile: false,
    isSharedProfile: false,
  };

  constructor(props) {
    super(props);
    this.state = { setPrivacy: false, show: false };
  }

  componentDidMount() {
    const {
      location: { hash, search },
      contractors: { data: contractorsData },
      dispatch,
    } = this.props;
    const contractorId = this.getContractorId();
    const query = querystring.parse(search.substring(1));
    if (!contractorsData[contractorId])
      dispatch(requestContractor(contractorId, query.auth_token));

    if (hash === "#cliosuccess") {
      dispatch(notify(clioSuccessMessage));
    }

    this.setPrivacyIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const {
      contractorId,
      contractors: { data: contractorsData },
      dispatch,
    } = this.props;

    if (
      !contractorsData[contractorId] &&
      prevProps.contractorId !== contractorId
    ) {
      dispatch(requestContractor(contractorId));
    }

    this.setPrivacyIfNeeded();
  }

  getContractorId = () => {
    const {
      match: { params },
      contractorId,
    } = this.props;
    return contractorId || (params && params.contractorId);
  };

  setPrivacyIfNeeded = () => {
    const {
      state: { setPrivacy },
      props: {
        contractors: {
          data: contractorsData,
          isFinished: contractorsIsFinished,
          isPending: contractorsIsPending,
        },
        candidates: {
          data: candidatesData,
          isFinished: candidatesFinished,
          isPending: candidatesPending,
        },
      },
    } = this;
    const contractorId = this.getContractorId();
    const contractor = contractorsData[contractorId];

    if (
      setPrivacy ||
      !candidatesFinished ||
      candidatesPending ||
      !contractorsIsFinished ||
      contractorsIsPending ||
      !contractor
    ) {
      return;
    }

    const isCandidate = Object.values(candidatesData)
      .map(candidate => candidate.contractor)
      .includes(contractorId);
    const allowPublic = contractor.allow_public_profile;

    this.setState({ setPrivacy: true, show: allowPublic || isCandidate });
  };

  render() {
    const {
      state: { setPrivacy, show },
      props: {
        contractors: {
          data: contractorsData,
          isFinished: contractorsIsFinished,
          isPending: contractorsIsPending,
        },
        candidates: {
          isFinished: candidatesFinished,
          isPending: candidatesPending,
        },
        isPublicProfile,
        isSharedProfile,
        isRecommendation,
        [types.CURRENT_USER_TYPE]: {
          data: userTypeData,
          isPending: currentUserTypePending,
          isFinished: currentUserTypeFinished,
        },
      },
    } = this;
    const isReady =
      contractorsIsFinished &&
      !contractorsIsPending &&
      candidatesFinished &&
      !candidatesPending &&
      currentUserTypeFinished &&
      !currentUserTypePending &&
      setPrivacy;
    const contractorId = this.getContractorId();
    const contractor = contractorsData[contractorId];
    const contractorFound = isReady && contractor;

    if (isReady && !show) return <PrivateProfile />;

    return (
      <LoadingOr showLoading={!isReady}>
        {contractorFound ? (
          <div
            className="enclosing-container"
            itemScope
            itemType="http://schema.org/Person"
          >
            <ContractorProfileHead contractor={contractor} />
            <ContractorProfileHeader
              contractor={contractor}
              isPublicProfile={isPublicProfile}
              isSharedProfile={isSharedProfile}
              isRecommendation={isRecommendation}
            />
            <ContractorProfileBody
              contractor={contractor}
              isPublicProfile={isPublicProfile}
              userType={
                isReady && userTypeData[Object.keys(userTypeData)[0]].user_type
              }
            />
          </div>
        ) : (
          <NotFound>
            <p>
              This contractor might have a private profile, or their account has
              been deactivated.
            </p>
          </NotFound>
        )}
      </LoadingOr>
    );
  }
}

const createCandidatesSelectorConfig = createResourceSelectorConfig(
  types.CANDIDATES,
  createCandidatesQuery,
);
const contractorByIdSelectorConfig = createResourceSelectorConfig(
  types.CONTRACTORS,
  contractorByIdQuery,
);
const currentUserTypeConfig = createResourceSelectorConfig(
  types.CURRENT_USER_TYPE,
  currentUserTypeQuery,
);

const mapStateToProps = createStructuredSelector({
  ...createCandidatesSelectorConfig,
  ...contractorByIdSelectorConfig,
  ...currentUserTypeConfig,
});

const mapPropsToConfig = props => [
  createCandidatesQuery(),
  contractorByIdQuery(props),
  currentUserTypeQuery({ force: false }),
];

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(ContractorProfile);
