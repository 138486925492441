// @flow
import { type Uuid, type DateTime } from "./primitives";

type UserType = "admin" | "hiring_agent" | "contractor" | "anonymous";

export default class Auth {
  pk: ?number;
  haeuser_id: ?Uuid;
  contractor_id: ?Uuid;
  hiring_agent_id: ?Uuid;
  hiring_agency_id: ?Uuid;
  email: ?string;
  first_name: ?string;
  last_name: ?string;
  is_authenticated: boolean;
  is_active: ?boolean;
  confirmed: ?boolean;
  agreed_to_latest_tos: ?DateTime;
  user_type: UserType;
  verified: boolean;
  hiring_agency_verification: ?boolean;
  from_admin: boolean;

  constructor(auth: Object) {
    this.pk = auth.pk;
    this.haeuser_id = auth.haeuser_id;
    this.contractor_id = auth.contractor_id;
    this.hiring_agent_id = auth.hiring_agent_id;
    this.hiring_agency_id = auth.hiring_agency_id;
    this.email = auth.email;
    this.first_name = auth.first_name;
    this.last_name = auth.last_name;
    this.is_authenticated = auth.is_authenticated;
    this.is_active = auth.is_active;
    this.confirmed = auth.confirmed;
    this.agreed_to_latest_tos = auth.agreed_to_latest_tos;
    this.user_type = auth.user_type;
    this.verified = auth.verified;
    this.hiring_agency_verification = auth.hiring_agency_verification;
    this.from_admin = auth.from_admin;
  }
}
