import React, { useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button as BootstrapButton,
  ButtonToolbar,
  FormControl,
  FormGroup,
} from "react-bootstrap";
import { useMutation, useRequest } from "redux-query-react";
import { useSelector } from "react-redux";
import {
  createTalentPool,
  deleteTalentPool,
  updateTalentPool,
} from "queries/mutations";
import { talentPoolsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import { Loading } from "common/components";
import types from "resources/types";

const Button = styled(BootstrapButton)`
  &.btn {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const CreateButtonContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const EditTalentPool = styled.div`
  display: grid;
  ${({ theme }) => theme.media.mobile_lg`
    grid-template-columns: auto 1fr;
  `};

  & + & {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #ddd;
  }
  & .form-group {
    margin-bottom: 0;
  }
`;

const EditTalentPoolToolbar = styled(ButtonToolbar)`
  align-self: end;
  ${({ theme }) => theme.media.mobile_lg`
    justify-self: end;
    margin: 5px 0;
  `};
`;

const states = {
  CREATE: "create",
  DEFAULT: "default",
  DELETE: "delete",
  EDIT: "edit",
};

const EditableTalentPool = ({
  talentPool,
  initialState = states.DEFAULT,
  onCreateEnd,
  onError,
  onStateChange,
}) => {
  const [state, setState] = useState(initialState);
  const [editedName, setEditedName] = useState(
    talentPool ? talentPool.name : "",
  );
  const [{ isPending: isCreating }, createList] = useMutation(name =>
    createTalentPool({ name }),
  );
  const [{ isPending: isDeleting }, deleteList] = useMutation(() =>
    deleteTalentPool(talentPool.uuid),
  );
  const [{ isPending: isUpdating }, updateList] = useMutation(name =>
    updateTalentPool(talentPool.uuid, { name }),
  );
  const isCreatingOrUpdating = isCreating || isUpdating;

  const changeState = newState => {
    setState(newState);
    onStateChange();
  };
  const setStateToDefault = () => changeState(states.DEFAULT);
  const setStateToEdit = () => changeState(states.EDIT);
  const setStateToDelete = () => changeState(states.DELETE);

  const handleDelete = () => {
    deleteList().then(response => {
      const { status } = response;
      if (status !== 204) {
        onError(
          "There was an error processing this request. Please try again or refresh the page.",
        );
      }
    });
  };
  const handleCancelClick = () => {
    if (state === states.EDIT) {
      setStateToDefault();
    }
    if (onCreateEnd) {
      onCreateEnd();
    }
  };
  const handleChange = e => {
    setEditedName(e.target.value);
  };
  const handleSave = () => {
    const action = talentPool ? updateList : createList;
    action(editedName).then(response => {
      const { body, status } = response;
      if (status === 200 || status === 201) {
        if (state === states.EDIT) {
          setStateToDefault();
        }
        if (onCreateEnd) {
          onCreateEnd();
        }
      } else if (status === 400 && body) {
        if (body.non_field_errors) {
          onError(body.non_field_errors[0]);
        } else if (body.name) {
          onError(body.name[0]);
        }
      } else {
        onError(
          "There was an error processing this request. Please try again or refresh the page.",
        );
      }
    });
  };

  return (
    <EditTalentPool>
      <div>
        {state === states.DEFAULT && <span>{talentPool.name}</span>}
        {state === states.DELETE && (
          <span>
            Are you sure you want to delete{" "}
            <strong>{`${talentPool.name}?`}</strong>
          </span>
        )}
        {(state === states.EDIT || state === states.CREATE) && (
          <FormGroup controlId="nameInput">
            <FormControl
              type="text"
              placeholder="Name"
              autocomplete="off"
              value={editedName}
              onChange={handleChange}
              disabled={isCreatingOrUpdating}
            />
          </FormGroup>
        )}
      </div>
      <EditTalentPoolToolbar>
        {state === states.DEFAULT && (
          <>
            <Button bsStyle="default" bsSize="xs" onClick={setStateToEdit}>
              Edit
            </Button>
            <Button bsStyle="danger" bsSize="xs" onClick={setStateToDelete}>
              Delete
            </Button>
          </>
        )}
        {(state === states.EDIT || state === states.CREATE) && (
          <>
            <Button
              bsStyle="link"
              bsSize="xs"
              onClick={handleCancelClick}
              disabled={isCreatingOrUpdating}
            >
              Cancel
            </Button>
            <Button
              bsStyle="success"
              bsSize="xs"
              onClick={handleSave}
              disabled={isCreatingOrUpdating}
            >
              {isCreatingOrUpdating ? "Saving..." : "Save"}
            </Button>
          </>
        )}
        {state === states.DELETE && (
          <>
            <Button
              bsStyle="link"
              bsSize="xs"
              onClick={setStateToDefault}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              bsStyle="success"
              bsSize="xs"
              onClick={handleDelete}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Yes, delete"}
            </Button>
          </>
        )}
      </EditTalentPoolToolbar>
    </EditTalentPool>
  );
};

const EditTalentPools = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);
  const request = talentPoolsRequest({
    url: { params: { limit: 99 } },
  });
  const selector = createResourceSelector(types.TALENT_POOLS, request);
  const {
    [types.TALENT_POOLS]: { data },
  } = useSelector(selector);
  const [{ isFinished, isPending }, _] = useRequest(request);
  return (
    <div>
      {isPending && <Loading />}
      {error && <Alert bsStyle="danger">{error}</Alert>}
      {isFinished && (
        <>
          {Object.keys(data)
            .map(id => data[id])
            .map(list => (
              <EditableTalentPool
                key={list.uuid}
                talentPool={list}
                onError={setError}
                onStateChange={() => setError(null)}
              />
            ))}
          {isCreating ? (
            <EditableTalentPool
              initialState={states.CREATE}
              onCreateEnd={() => setIsCreating(false)}
              onError={setError}
              onStateChange={() => setError(null)}
            />
          ) : (
            <CreateButtonContainer>
              <Button bsStyle="success" onClick={() => setIsCreating(true)}>
                <i className="far fa-plus" aria-hidden="true" />&nbsp;Create new
                Talent Pool
              </Button>
            </CreateButtonContainer>
          )}
        </>
      )}
    </div>
  );
};

export default EditTalentPools;
