// @flow
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Col, Media, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connectRequest } from "redux-query-react";
import { createStructuredSelector } from "reselect";

import AttorneyProfilePicture from "../search/AttorneyProfilePicture";
import AttorneyNameIcon from "hiringagent-dashboard/components/attorney/AttorneyNameIcon";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";
import { Candidate, Job } from "types";

type CandidateRowProps = {
  candidate: Candidate,
  contractors: Object,
  jobs: Job,
};

export class CandidateRow extends Component<CandidateRowProps> {
  render() {
    const {
      candidate,
      [types.CONTRACTORS]: {
        data: contractors,
        query: { data: contractorsQueryData },
      },
      [types.JOBS]: {
        data: jobs,
        query: { data: jobsQueryData },
      },
    } = this.props;

    const job = jobs[jobsQueryData[0]];
    const contractor = contractors[contractorsQueryData[0]];

    const ready = job && contractor;

    return !ready ? null : (
      <div className="line-item">
        <Row>
          <Col xs={12}>
            <Media className="application-snippet">
              <Media.Left>
                <AttorneyProfilePicture
                  pictureUrl={contractor.profile_picture_url}
                  size={40}
                  lastName={contractor.last_name}
                  firstName={contractor.first_name}
                  uuid={contractor.uuid}
                />
              </Media.Left>
              <Media.Body>
                <Link to={`/agency/candidates/${candidate.uuid}/`}>
                  <AttorneyNameIcon
                    isAttorney={contractor.is_attorney}
                    className="attorney-title-small"
                  >
                    {contractor.full_name}
                  </AttorneyNameIcon>
                </Link>
                <div className="subline">{job.formattedTitle}</div>
              </Media.Body>
            </Media>
          </Col>
        </Row>
      </div>
    );
  }
}

const jobsQuery = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.candidate.job}/`,
  });
const contractorsQuery = props =>
  createResourceDetailQuery(types.CONTRACTORS, {
    url: `/api/dev/contractors/${props.candidate.contractor}/`,
  });

const candidateRowMapPropsToConfig = props => [
  jobsQuery(props),
  contractorsQuery(props),
];

const jobsConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);
const contractorsConfig = createResourceSelectorConfig(
  types.CONTRACTORS,
  contractorsQuery,
);

const candidateRowMapStateToProps = createStructuredSelector({
  ...jobsConfig,
  ...contractorsConfig,
});

const ConnectedCandidateRow = compose(
  connect(candidateRowMapStateToProps),
  connectRequest(candidateRowMapPropsToConfig),
)(CandidateRow);

type CandidatesPanelProps = {
  candidates: Object,
  newItems: Object,
};

export class CandidatesPanel extends Component<CandidatesPanelProps> {
  render() {
    const {
      [types.CANDIDATES]: {
        data: pendingCandidates,
        isPending: candidatesPending,
        lastUpdated,
        query: { data: ordering },
      },
      [types.NEW_ITEMS]: { data: newItems, isPending: newItemsPending },
    } = this.props;

    const count = newItems.undefined && newItems.undefined.pending_candidates;
    const candidates = ordering.map(id => pendingCandidates[id]).slice(0, 3);
    const pending = candidatesPending && newItemsPending;

    return (
      <Fragment>
        {pending ? (
          <div className="text-center" style={{ marginTop: "4rem" }}>
            <i className="far fa-cog fa-spin fa-2x" />
          </div>
        ) : lastUpdated ? (
          candidates.length > 0 ? (
            <Fragment>
              <div className="line-item text-center">
                <Row>
                  <Col xs={12}>
                    {count && (
                      <Link to="/agency/candidates" className="line-item-alert">
                        <i className="far fa-bell fa-inline" />
                        You have {count && count} pending candidates
                      </Link>
                    )}
                  </Col>
                </Row>
              </div>
              {candidates.map(candidate => (
                <ConnectedCandidateRow
                  candidate={candidate}
                  key={candidate.uuid}
                />
              ))}
            </Fragment>
          ) : (
            <Fragment>
              <div className="line-item text-center">
                <Row>
                  <Col xs={12}>
                    You have no pending candidates.
                    <br />
                    <Link to="/agency/search">Find candidates</Link> to invite
                    to jobs.
                  </Col>
                </Row>
              </div>
            </Fragment>
          )
        ) : null}
      </Fragment>
    );
  }
}

const pendingStatus = Candidate.statusTypeValue("PENDING");

const candidatesQuery = () =>
  createResourceListQuery(types.CANDIDATES, {
    url: `/api/v2/candidates/?limit=3&status=${pendingStatus}&ordering=created`,
  });
const newItemsQuery = () =>
  createResourceDetailQuery(types.NEW_ITEMS, {
    url: "/api/v2/hiring_agencies/new_items/",
  });

const candidatesMapPropsToConfig = () => [candidatesQuery(), newItemsQuery()];

const candidatesSelector = createResourceSelectorConfig(
  types.CANDIDATES,
  candidatesQuery,
);
const newItemsSelector = createResourceSelectorConfig(
  types.NEW_ITEMS,
  newItemsQuery,
);

const candidatesMapStateToProps = createStructuredSelector({
  ...newItemsSelector,
  ...candidatesSelector,
});

export default compose(
  connect(candidatesMapStateToProps),
  connectRequest(candidatesMapPropsToConfig),
)(CandidatesPanel);
