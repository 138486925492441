import React from "react";

import { EditContractor } from "contractor/components/settings";
import PersonalInfoForm, {
  requiredFields,
} from "contractor/forms/settings/PersonalInfoForm";

const fields = ["first_name", "last_name", "email_address", "phone_home"];

const props = {
  formComponentClass: PersonalInfoForm,
  title: "Personal Info",
  requiredFields,
};

const EditPersonalInfo = ({ contractor, ...otherProps }) => {
  const initialValues = fields.reduce(
    (res, val) => ({ ...res, [val]: contractor[val] }),
    {},
  );
  const { pending_email_address } = contractor;

  return (
    <EditContractor
      contractorId={contractor.uuid}
      initialValues={initialValues}
      transformValues={values => {
        const { email_address, first_name, last_name, phone_home } = values;

        return {
          pending_email_address: pending_email_address
            ? pending_email_address
            : email_address,
          first_name,
          last_name,
          phone_home,
        };
      }}
      {...props}
      {...otherProps}
    />
  );
};

export default EditPersonalInfo;
