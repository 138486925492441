// @flow
import {
  AdditionalDetails,
  AreasOfExpertise,
  BarRequirements,
  JobAnalysis,
  ContractorTypes,
  Experience,
  HoursPerWeek,
  JobTitle,
  JobSpecifications,
  LanguageRequirements,
  Location,
  PracticeAreaRequirements,
  PrimaryPracticeArea,
  Timeline,
} from "hiringagent-dashboard/components/jobs/create-job-listing";

export const createJobListingComponents = [
  {
    isComplete: (jobListing: Object): boolean =>
      jobListing.primary_practice_area,
    component: PrimaryPracticeArea,
    formName: "createJobListingPrimaryPracticeArea",
  },
  {
    isComplete: (jobListing: Object): boolean =>
      jobListing.expertisearea_set.length > 0 &&
      jobListing.expertisearea_set.length <= 12,
    component: AreasOfExpertise,
    formName: "createJobListingAreasOfExpertise",
  },
  {
    isComplete: (jobListing: Object): boolean =>
      jobListing.remote || jobListing.joblistinglocation_set.length > 0,
    component: Location,
    formName: "createJobListingLocation",
  },
  {
    isComplete: (jobListing: Object): boolean => jobListing.allowed_applicants,
    component: ContractorTypes,
    formName: "createJobListingContractorTypes",
  },
  {
    isComplete: (jobListing: Object): boolean =>
      jobListing.min_years_experience >= 0 || jobListing.max_years_experience,
    component: Experience,
    formName: "createJobListingExperience",
  },
  {
    isComplete: (jobListing: Object): boolean => jobListing.title,
    component: JobTitle,
    formName: "createJobListingJobTitle",
  },
  {
    isComplete: true,
    component: PracticeAreaRequirements,
    formName: "createJobListingPracticeAreaRequirements",
  },
  {
    isComplete: true,
    component: LanguageRequirements,
    formName: "createJobListingLanguageRequirements",
  },
  {
    isComplete: true,
    component: BarRequirements,
    formName: "createJobListingBarRequirements",
  },
  {
    isComplete: true,
    component: JobAnalysis,
    formName: "createJobListingJobAnalysis",
  },
  {
    isComplete: true,
    component: AdditionalDetails,
    formName: "createJobListingAdditionalDetails",
  },
  {
    isComplete: (jobListing: Object): boolean =>
      jobListing.start_date && jobListing.project_length >= 0,
    component: Timeline,
    formName: "createJobListingTimeline",
  },
  {
    isComplete: true,
    component: JobSpecifications,
    formName: "createJobListingJobSpecifications",
  },
];

export const calculateNextStep = (jobListing: Object) =>
  createJobListingComponents.findIndex(({ isComplete }) => {
    if (typeof isComplete === "function") {
      return !isComplete(jobListing);
    } else {
      return !isComplete;
    }
  });

export const calculateJobListingFormUrl = (jobListing: Object) => {
  const nextStep = calculateNextStep(jobListing);
  if (nextStep === -1) {
    return `/agency/jobs/create/${jobListing.job}/publish/`;
  }
  return `/agency/jobs/create/${jobListing.job}/step/${nextStep}/`;
};
