// @flow strict
import React, { Component, Fragment } from "react";

import ExampleAssessmentForm from "contractor/forms/WorkstyleAssessmentForm/Example";
import RemoteSubmitButton from "contractor/forms/WorkstyleAssessmentForm/RemoteSubmitButton";
import { ProgressBar } from "common/components";
import Workstyle from "./blocks";

type Props = {
  onFinishIntro: () => void,
};

type State = {
  completed: Set<string>,
  page: number,
};

const paragraphs = {
  instructions1:
    'The following example activity consists of statements relating to your work style. Please read each statement carefully and indicate your agreement from "Strongly Disagree" to "Strongly Agree" on the scales provided.',
  instructions2:
    "You must answer all questions on a page before moving on to the next.",
  advice1:
    "Respond to the statements as candidly and honestly as possible. There are no right or wrong answers; job roles vary and there are many ways to be effective in a specific role. It may be useful to reflect on your own work style and to consider feedback you have received from others at work.",
  advice2:
    "You must complete the entire assessment in one session, otherwise your answers will not be saved.",
  warning:
    "This exercise is designed to identify individuals who give inaccurate or false responses, and your responses are verified against other information collected in the application process. So, be as honest with us (and yourself) as possible, since false responses can eliminate you from the applicant pool.",
};

const questions = [
  {
    uuid: 1,
    header: "Example Question #1",
    content: "I work best in a competitive environment",
  },
  {
    uuid: 2,
    header: "Example Question #2",
    content: "I like to work in a job where I meet a wide range of people",
  },
];

export default class IntroSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { completed: new Set(), page: 0 };
  }

  handleUpdateProgress = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      this.setState(({ completed }) => ({
        completed: new Set(completed.add(e.currentTarget.name)),
      }));
    }
  };

  nextPage = () =>
    this.setState({ completed: new Set(), page: this.state.page + 1 });

  setPage = (pageNumber: number) =>
    this.setState({ completed: new Set(), page: pageNumber });

  shouldDisable = () => {
    const { size } = this.state.completed;
    const shouldHaveDone = questions.length;

    if (size < shouldHaveDone) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { onFinishIntro } = this.props;
    const { completed, page } = this.state;

    return (
      <Fragment>
        {page === 0 && (
          <Fragment>
            <Workstyle.Container>
              <Workstyle.Header>Workstyle Assessment</Workstyle.Header>
              <Workstyle.Subheader>EXAMPLE QUESTIONS</Workstyle.Subheader>
              <Workstyle.Paragraph>
                {paragraphs.instructions1 + " "}
                <strong>{paragraphs.instructions2}</strong>
              </Workstyle.Paragraph>
              <Workstyle.HideOnMobile>
                <ExampleAssessmentForm
                  handleUpdateProgress={this.handleUpdateProgress}
                  questions={questions}
                  total={questions.length}
                  setPage={this.setPage}
                />
              </Workstyle.HideOnMobile>
              <Workstyle.HideOnDesktop>
                <Workstyle.Button onClick={this.nextPage}>
                  Next ›
                </Workstyle.Button>
              </Workstyle.HideOnDesktop>
            </Workstyle.Container>
            <Workstyle.HideOnMobile>
              <RemoteSubmitButton
                disabled={this.shouldDisable()}
                form="exampleAssessmentForm"
              />
              <ProgressBar
                completed={completed.size}
                total={questions.length}
              />
            </Workstyle.HideOnMobile>
          </Fragment>
        )}
        {page === 1 && (
          <Fragment>
            <Workstyle.Container>
              <Workstyle.HideOnDesktop>
                <Workstyle.Header>Example Assessment</Workstyle.Header>
              </Workstyle.HideOnDesktop>
              <Workstyle.HideOnMobile>
                <Workstyle.Header>Workstyle Assessment</Workstyle.Header>
                <Workstyle.Subheader>EXAMPLE QUESTIONS</Workstyle.Subheader>
                <Workstyle.Paragraph>
                  {paragraphs.instructions1 + " "}
                  <strong>{paragraphs.instructions2}</strong>
                </Workstyle.Paragraph>
              </Workstyle.HideOnMobile>
              <ExampleAssessmentForm
                handleUpdateProgress={this.handleUpdateProgress}
                questions={questions}
                total={questions.length}
                setPage={this.setPage}
              />
            </Workstyle.Container>
            <RemoteSubmitButton
              disabled={this.shouldDisable()}
              form="exampleAssessmentForm"
            />
            <ProgressBar completed={completed.size} total={questions.length} />
          </Fragment>
        )}
        {page === 2 && (
          <Workstyle.Container>
            <Workstyle.Header>Workstyle Assessment</Workstyle.Header>
            <Workstyle.Paragraph>
              Great work! Now you&apos;re ready to begin the Workstyle
              Assessment.
            </Workstyle.Paragraph>
            <Workstyle.Subheader>INSTRUCTIONS</Workstyle.Subheader>
            <Workstyle.Paragraph>
              {paragraphs.advice1 + " "}
              <strong>{paragraphs.advice2}</strong>
            </Workstyle.Paragraph>
            <Workstyle.Box>
              <div>
                <Workstyle.Subheader>BEFORE YOU START</Workstyle.Subheader>
                <Workstyle.Paragraph>{paragraphs.warning}</Workstyle.Paragraph>
              </div>
              <Workstyle.Button onClick={onFinishIntro}>
                START ›
              </Workstyle.Button>
            </Workstyle.Box>
          </Workstyle.Container>
        )}
      </Fragment>
    );
  }
}
