// @flow strict
import React, { Component } from "react";

import LanguageTooltip from "common/components/LanguageTooltip";
import {
  JobLanguagesDisplay,
  JobPrimaryPracticeAreaRequirements,
} from "common/components/jobs";
import { JobListing } from "resources/types";

type Props = {
  jobListing: JobListing,
};

type State = {
  display: boolean,
};

const languageTitle = (
  <span>
    Language Requirements{" "}
    <LanguageTooltip id={"contractor-job-details-languages"} />
  </span>
);

export default class JobRequirementsSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { display: false };
  }

  componentDidMount() {
    const { bar_requirements, joblistinglanguage_set } = this.props.jobListing;
    const hasBarRequirements = bar_requirements && bar_requirements.length > 0;
    const hasLanguageRequirements = joblistinglanguage_set.length > 0;
    if (hasBarRequirements || hasLanguageRequirements) {
      this.handleDisplayComponent();
    }
  }

  handleDisplayComponent = () => {
    this.setState({ display: true });
  };

  render() {
    const { jobListing } = this.props;
    const { display } = this.state;
    return (
      <div
        style={display ? { marginBottom: "3rem" } : { display: "none" }}
        itemProp="description"
      >
        <h2 className="main-info-title" style={{ marginBottom: "2rem" }}>
          Requirements
        </h2>
        {jobListing.bar_requirements &&
          jobListing.bar_requirements.length > 0 && (
            <div style={{ marginBottom: "2rem" }}>
              <h4>State Bar Requirements</h4>
              <ul>
                {jobListing.bar_requirements.map(req => (
                  <li key={req.uuid}>{req}</li>
                ))}
              </ul>
            </div>
          )}
        {jobListing.joblistinglanguage_set.length > 0 && (
          <div style={{ marginBottom: "2rem" }}>
            <h4>{languageTitle}</h4>
            <JobLanguagesDisplay jobListing={jobListing} />
          </div>
        )}
        <JobPrimaryPracticeAreaRequirements
          jobListing={jobListing}
          handleDisplayParent={this.handleDisplayComponent}
        />
      </div>
    );
  }
}
