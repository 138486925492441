import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm, Field, FieldArray } from "redux-form";
import { ControlLabel, FormGroup } from "react-bootstrap";

import { FieldHelpBlock } from "common/components/forms";
import {
  CheckboxField,
  CityStateGeosuggest as LocationInput,
} from "common/components/forms/inputs";
import SelectedItemsList, {
  SelectedItem,
} from "common/components/SelectedItemsList";
import { validationState } from "common/utils/forms";
import { OpacitySlideInTransition } from "common/utils/transitions";
import { Error, validateRequiredFields } from "common/utils/forms/validators";
import { FormRow, withFormError } from "./utils";

const requiredFields = ["remote"];

const validate = values => {
  const { remote, joblistinglocation_set } = values;
  const errors = validateRequiredFields(requiredFields, values);
  if (!remote && joblistinglocation_set && joblistinglocation_set.length < 1) {
    errors.joblistinglocation_set = {
      _error: (
        <Error>You must specify at least one city or select remote.</Error>
      ),
    };
  }
  return errors;
};

const LocationDisplayField = ({ input, onDelete, ...otherProps }) => (
  <SelectedItem
    label={`${input.value.city}, ${input.value.state}`}
    onDelete={onDelete}
    {...otherProps}
  />
);

const isInvalid = value =>
  Object.values(value).some(v => [undefined, null].includes(v));
const isValueInFields = (value, fields) =>
  Boolean(
    fields
      .getAll()
      .find(field => field.city === value.city && field.state === value.state),
  );
const invalidSelectionError = (
  <Error>Invalid selection, please select a city.</Error>
);

export class LocationsInputArray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidSelection: false,
    };
  }
  handleAddLocation = value => {
    if (!value) return;
    const { fields, jobListing } = this.props;
    const getAddressComponentByType = type =>
      value.gmaps.address_components.find(component =>
        component.types.includes(type),
      );
    const city = getAddressComponentByType("locality");
    const state = getAddressComponentByType("administrative_area_level_1");
    const cityValue = city && city.long_name;
    const stateValue = state && state.short_name;
    const existing = jobListing.joblistinglocation_set.find(
      location => location.city === cityValue && location.state === stateValue,
    );
    const newValue = {
      city: cityValue,
      state: stateValue,
      latitude: value.location.lat.toPrecision(8),
      longitude: value.location.lng.toPrecision(8),
      joblisting: jobListing.uuid,
      ...(existing && { uuid: existing.uuid }),
    };

    const invalidSelection = isInvalid(newValue);
    const valueInFields = isValueInFields(newValue, fields);

    if (!invalidSelection && !valueInFields) {
      this.props.fields.push(newValue);
    }
    this.setState({ invalidSelection });
  };
  handleDeleteLocation = index => () => {
    const { fields } = this.props;
    fields.remove(index);
    this.setState({ invalidSelection: false });
  };
  render() {
    const {
      fields,
      meta: { error, submitFailed },
    } = this.props;
    const { invalidSelection } = this.state;
    return (
      <div>
        <ControlLabel>Cities</ControlLabel>
        <SelectedItemsList style={{ marginBottom: "1rem" }}>
          {fields.map((location, i) => {
            const field = fields.get(i);
            return (
              <Field
                key={`selected-location-${field.latitude}-${field.longitude}`}
                name={location}
                component={LocationDisplayField}
                onDelete={this.handleDeleteLocation(i)}
              />
            );
          })}
        </SelectedItemsList>

        <FormGroup
          validationState={validationState({
            touched: submitFailed,
            error,
            value: null,
          })}
        >
          <LocationInput onAddLocation={this.handleAddLocation} />
          {invalidSelection ? (
            <FieldHelpBlock touched={true} error={invalidSelectionError} />
          ) : (
            <FieldHelpBlock touched={submitFailed} error={error} />
          )}
        </FormGroup>
      </div>
    );
  }
}

class LocationForm extends Component {
  handleRemoteChange = (e, value) => {
    const { change } = this.props;
    if (value) {
      change("joblistinglocation_set", []);
    }
  };
  render() {
    const {
      formValues: { remote = false },
      handleSubmit,
      jobListing,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FormRow>
          <CheckboxField
            name="remote"
            label="This is a remote job"
            onChange={this.handleRemoteChange}
          />
          <p>Allowing remote work expands your pool of candidates.</p>
        </FormRow>
        <OpacitySlideInTransition>
          {!remote && (
            <FormRow key="joblistinglocation_set-fieldarray">
              <FieldArray
                name="joblistinglocation_set"
                component={LocationsInputArray}
                jobListing={jobListing}
              />
              <p>You can add multiple cities.</p>
            </FormRow>
          )}
        </OpacitySlideInTransition>
      </form>
    );
  }
}

LocationForm = compose(
  connect((state, props) => ({
    formValues: {
      remote: formValueSelector(props.form)(state, "remote"),
    },
  })),
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(LocationForm);

export default class Location extends Component {
  handleSubmit = values => {
    const {
      formProps: { onSubmit },
    } = this.props;
    if (values.remote) {
      values.joblistinglocation_set = [];
    }
    return onSubmit(values);
  };
  render() {
    const { formProps, jobListing } = this.props;
    const initialValues = {
      joblistinglocation_set: jobListing.joblistinglocation_set,
      remote: jobListing.remote,
    };
    return (
      <div className="location-form">
        <h2 style={{ marginBottom: "2rem" }}>
          Where will the work be performed?
        </h2>
        <LocationForm
          {...formProps}
          jobListing={jobListing}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
        />
      </div>
    );
  }
}
