import React, { Component } from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import {
  change,
  reset,
  reduxForm,
  SubmissionError,
  initialize,
} from "redux-form";
import { Elements, StripeProvider, injectStripe } from "react-stripe-elements";
import { createStructuredSelector } from "reselect";
import { addNotification as notify } from "reapop";

import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import { compose, connect, connectRequest, types } from "queries/utils";
import { ownContractorQuery } from "queries/requests/contractors";
import { createResourceSelectorConfig } from "queries";
import FormButton from "common/components/FormButton";
import {
  PersonalInfoFieldGroup,
  BankInfoFieldGroup,
  ACHAgreeField,
} from "./Fields";
import { formName } from "./values";
import ErrorAlert from "./ErrorAlert";
import { update } from "resources/actions/bankAccounts";
import { createBankToken, createSSNToken, parseNonFieldErrors } from "./utils";

class DirectDepositForm extends Component {
  componentDidMount() {
    this.handleInitPrefill();
  }

  onSubmit = async values => {
    const {
      stripe,
      submitToServer,
      hasBankData,
      onHideDynamicForm,
    } = this.props;
    const fields = Object.keys(values);
    const needBankToken =
      fields.includes("account_number") || fields.includes("routing_number");
    const needSSNToken = fields.includes("social_security_number");

    if (!hasBankData) onHideDynamicForm();

    if (needBankToken) {
      const { token: bankToken, error: bankError } = await createBankToken(
        stripe.createToken,
        values.routing_number,
        values.account_number,
      );
      if (bankError) this.handleBankError(bankError);
      if (bankToken) values.stripe_token = bankToken.id;
    }

    if (needSSNToken) {
      const { token: ssnToken } = await createSSNToken(
        stripe.createToken,
        values.social_security_number,
      );
      if (ssnToken) {
        values.ssn_last_4 = values.social_security_number
          .trim()
          .slice(values.social_security_number.length - 4);
        // Send ssnToken to backend as personal_id_number to avoid leaking raw SSN
        values.personal_id_number = ssnToken.id;
      }
    }

    // Don't send raw account, routing, or personal ID numbers to server
    const {
      account_number: _,
      routing_number: __,
      social_security_number: ___,
      ...valuesForServer
    } = values;
    const config = hasBankData ? { method: "patch" } : { method: "post" };

    return submitToServer(valuesForServer, config).then(
      ({ json, response }) => {
        if (!response.ok) this.handleSubmitError(json);
        this.handleSubmitSuccess();
      },
    );
  };

  handleBankError = bankError => {
    const formErrors = {};
    const accountFields = ["account_number", "routing_number"];
    accountFields.forEach(field => {
      if (bankError.param === `bank_account[${field}]`)
        formErrors[field] = bankError.message;
    });
    throw new SubmissionError(formErrors);
  };

  handleSubmitSuccess = () => {
    const { onHide, initializeForm, notify } = this.props;
    initializeForm();
    notify(
      createSuccessNotification({
        message: "Direct deposit information has been saved.",
      }),
    );
    onHide();
  };

  handleSubmitError = json => {
    this.props.notify(
      createErrorNotification({
        message: json.detail || "Please correct the errors below.",
      }),
    );
    // TODO: This is kind of a hack to get the individual[id_number] non_field_error to
    // display with a custom message (the message returned by Stripe is not user-friendly).
    if (Object.keys(json).includes("non_field_errors")) {
      const nonFieldErrors = parseNonFieldErrors(json.non_field_errors);
      json._error = nonFieldErrors;
    }
    throw new SubmissionError(json);
  };

  handleChangePrefill = ({ currentTarget: { checked } }) => {
    if (checked) {
      const {
        hasBankData,
        bankData,
        [types.OWN_CONTRACTOR]: { data },
      } = this.props;
      const {
        first_name,
        last_name,
        phone_home,
        email_address,
        location: { address_1, address_2, city, state, postal_code },
      } = data[Object.keys(data)[0]];
      const isStripeVerified =
        hasBankData &&
        bankData.verified_status &&
        bankData.verified_status.identify_verified;

      if (!hasBankData && !isStripeVerified) {
        this.changeFieldIf("first_name", first_name, first_name);
        this.changeFieldIf("last_name", last_name, last_name);
      }
      this.changeFieldIf("phone_home", phone_home, phone_home);
      this.changeFieldIf("email_address", email_address, email_address);
      this.changeFieldIf("address_1", address_1, address_1);
      this.changeFieldIf("address_2", address_2, address_2);
      this.changeFieldIf("city", city, city);
      this.changeFieldIf("state", state, state);
      this.changeFieldIf("postal_code", postal_code, postal_code);
    } else {
      this.props.resetForm();
    }
  };

  handleInitPrefill = () => {
    if (this.props.hasBankData) {
      const { changeFieldIf } = this;
      const {
        bankData: {
          fields: {
            email_address,
            address_1,
            address_2,
            city,
            state: stateID,
            postal_code,
            phone_home,
          },
          routing_number,
        },
      } = this.props;

      changeFieldIf("phone_home", phone_home, phone_home);
      changeFieldIf("email_address", email_address, email_address);
      changeFieldIf("address_1", address_1, address_1);
      changeFieldIf("address_2", address_2, address_2);
      changeFieldIf("city", city, city);
      changeFieldIf("state", stateID, stateID);
      changeFieldIf("postal_code", postal_code, postal_code);
      changeFieldIf("routing_number", routing_number, routing_number);
    } else {
      this.props.resetForm();
    }
  };

  changeFieldIf = (name, value, condition) => {
    if (condition) this.props.changeFieldValue(name, value);
  };

  render() {
    const {
      hasBankData,
      bankData,
      error,
      handleSubmit,
      pristine,
      submitting,
      onHide,
      [types.OWN_CONTRACTOR]: { data, isFinished },
    } = this.props;
    const ownContractorData = isFinished ? data[Object.keys(data)[0]] : null;
    const isStripeVerified =
      hasBankData &&
      bankData.verified_status &&
      bankData.verified_status.identify_verified;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {error && <ErrorAlert error={error} />}
        <h4>Personal Information</h4>
        <PersonalInfoFieldGroup
          hasBankData={hasBankData}
          isStripeVerified={isStripeVerified}
          onChangePrefill={this.handleChangePrefill}
          hasContractorData={Boolean(ownContractorData)}
        />
        <h4>Bank Information</h4>
        <BankInfoFieldGroup />
        <ACHAgreeField />
        <ButtonToolbar>
          {onHide && <Button onClick={onHide}>Close</Button>}
          <FormButton
            action="save"
            submitting={submitting}
            style={{ float: "right" }}
            disabled={pristine || submitting}
          />
        </ButtonToolbar>
      </form>
    );
  }
}

const mapPropsToConfig = ownContractorQuery;
const ownContractorSelector = createResourceSelectorConfig(
  types.OWN_CONTRACTOR,
  ownContractorQuery,
);
const mapStateToProps = createStructuredSelector({
  ...ownContractorSelector,
});
const mapDispatchToProps = {
  submitToServer: update,
  initializeForm: () => initialize(formName),
  changeFieldValue: (field, value) => change(formName, field, value),
  resetForm: () => reset(formName),
  notify,
};
DirectDepositForm = reduxForm({
  form: formName,
})(DirectDepositForm);
DirectDepositForm = injectStripe(DirectDepositForm);
DirectDepositForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(DirectDepositForm);

class StripeDirectDepositForm extends Component {
  render() {
    const { ...formProps } = this.props;
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
        <Elements>
          <DirectDepositForm {...formProps} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default StripeDirectDepositForm;
