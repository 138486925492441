import actions from "common/actions/auth";
import { handleActions } from "redux-actions";

const createInitialState = () => ({
  data: {},
  error: null,
  token: null,
  isLoading: false,
  isFinished: false,
});

const authReducer = handleActions(
  {
    [actions.REQUEST_AUTH]: state => ({
      ...state,
      isLoading: true,
      isFinished: false,
    }),
    [actions.RECEIVE_AUTH]: (state, action) => ({
      ...state,
      data: action.error ? null : action.payload,
      error: action.error ? action.error : null,
      isLoading: false,
      isFinished: true,
    }),
    [actions.LOG_IN]: () => createInitialState(),
    [actions.LOG_OUT]: () => createInitialState(),
  },
  createInitialState(),
);
export default authReducer;
