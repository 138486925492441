import React from "react";
import styled from "styled-components";

import PrimaryButton from "common/blocks/PrimaryButton";
import Page from "./Page";
import Container, {
  ContainerHeader,
  ContainerBody,
  ContainerFooter,
  ContainerDivider,
} from "./Container";
import Table, {
  TablePlaceholder,
  TableButton,
  TableButtonCell,
  TableStatusText,
} from "./Table";
import EntryDataRow, { EntryDataLabel } from "./EntryDataRow";
import Icon from "./Icon";
import OptionalLabel from "./OptionalLabel";
import GoBackContainer from "common/blocks/GoBackContainer";

const OnboardBlock = styled.div`
  margin: 30px 0 0 0;
  padding: 0;

  ${({ theme }) => theme.media.desktop`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};

  font-size: 16px;
`;

const Onboard = props => <OnboardBlock {...props} />;

Onboard.Button = PrimaryButton;
Onboard.Page = Page;
Onboard.Container = Container;
Onboard.ContainerHeader = ContainerHeader;
Onboard.ContainerBody = ContainerBody;
Onboard.ContainerFooter = ContainerFooter;
Onboard.ContainerDivider = ContainerDivider;
Onboard.Table = Table;
Onboard.TablePlaceholder = TablePlaceholder;
Onboard.TableButton = TableButton;
Onboard.TableButtonCell = TableButtonCell;
Onboard.TableStatusText = TableStatusText;
Onboard.EntryDataRow = EntryDataRow;
Onboard.EntryDataLabel = EntryDataLabel;
Onboard.Icon = Icon;
Onboard.OptionalLabel = OptionalLabel;
Onboard.GoBackContainer = GoBackContainer;

export default Onboard;
