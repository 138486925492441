// @flow strict
import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";

import { jobExperienceRequirement } from "public/utils/jobs";

type Props = {
  jobListing: JobListing,
  positionTypes: QueryResource<PositionType>,
};

const createMetaDescription = (jobListing, positionTypes) => {
  const descriptionObj = {
    "Job Title": jobListing.title,
    "Position Type":
      jobListing.position_type &&
      positionTypes.data[jobListing.position_type].name,
    "Location(s)": jobListing.joblistinglocation_set
      .map(location => `${location.city}, ${location.state}`)
      .join("; "),
    "Experience Requirement": jobExperienceRequirement(
      jobListing.min_years_experience,
      jobListing.max_years_experience,
    ),
    "Date Posted":
      jobListing.published_date &&
      moment(jobListing.published_date).format("ll"),
  };
  let aggregateDescription = "";
  for (let key in descriptionObj) {
    if (descriptionObj[key]) {
      aggregateDescription += `${key}: ${descriptionObj[key]}, `;
    }
  }
  return aggregateDescription ? aggregateDescription.slice(0, -2) : null;
};

const PublicJobMetaDescription = ({ jobListing, positionTypes }: Props) => {
  const aggregateDescription = createMetaDescription(jobListing, positionTypes);
  return (
    <Helmet>
      {aggregateDescription && (
        <meta name="description" content={aggregateDescription} />
      )}
    </Helmet>
  );
};

export default PublicJobMetaDescription;
