import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { history as browserHistory } from "app";
import { createStructuredSelector } from "reselect";
import { addNotification } from "reapop";

import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  types,
} from "queries/utils";
import { createResourceSelectorConfig } from "queries";
import RadioGroupQuestion from "common/components/RadioGroupQuestion";
import { clientFeedbackSurveyResponsesQuery } from "queries/mutations/clientFeedback";
import { clientFeedbackSurveyQuery } from "queries/requests/clientFeedback";
import { jobsQuery } from "queries/requests/jobs";
import PrimaryButton from "common/blocks/PrimaryButton";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import Loading from "common/components/Loading";
import { flattenObject } from "common/utils";
import NotFound from "common/components/NotFound";

const formName = "feedbackSurveyForm";

const responseOptions = {
  not_applicable: "not_observed",
  strongly_agree: "5",
  agree: "4",
  neutral: "3",
  disagree: "2",
  strongly_disagree: "1",
};

const BackButton = () => (
  <div className="back-button">
    <Link to="/agency/feedback">&larr; Back to Feedback List</Link>
  </div>
);

const CustomNotFound = () => (
  <NotFound
    to="/agency/"
    copy={
      "Sorry, we couldn't find the survey you were looking for. " +
      "It's possible that you have already completed this survey."
    }
  />
);

class FeedbackSurveyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { completed: [], submitting: false };
  }

  handleSubmit = () => {
    this.setState({ submitting: true }, () => {
      const { notify, submitFeedback } = this.props;
      const submitVal = this.state.completed.slice();
      const submitValLen = submitVal.length;

      for (let i = 0; i < submitValLen; i++) {
        if (submitVal[i].score === "not_observed") submitVal[i].score = null;
      }

      return submitFeedback(submitVal).then(({ status }) => {
        if (status >= 200 && status < 300) {
          browserHistory.push("/agency/feedback");
          return notify(
            createSuccessNotification({
              message: "Submitted successfully!",
            }),
          );
        } else {
          this.setState({ submitting: false }, () => {
            return notify(
              createErrorNotification({
                message: "Encountered an error.",
              }),
            );
          });
        }
      });
    });
  };

  handleUpdateProgress = e => {
    if (e.currentTarget.checked) {
      const { completed } = this.state;
      const completedLength = completed.length;

      for (let i = 0; i < completedLength; i++) {
        if (
          "predictor_" + completed[i].predictor === e.currentTarget.name ||
          "question_" + completed[i].general_question === e.currentTarget.name
        ) {
          const completedClone = completed.slice();
          completedClone[i].score = e.currentTarget.value;
          this.setState({ completed: completedClone });
          return;
        }
      }

      let type, id, general_question, predictor;
      [type, id] = e.currentTarget.name.split("_");
      general_question = type == "question" ? id : null;
      predictor = type == "predictor" ? id : null;

      this.setState({
        completed: completed.concat({
          predictor: predictor,
          general_question: general_question,
          score: e.currentTarget.value,
          survey: this.props.surveyId,
        }),
      });
    }
  };

  render() {
    const {
      state: { completed, submitting },
      props: { pristine, valid, survey },
      handleUpdateProgress,
      handleSubmit,
    } = this;
    const allAnswered = completed.length >= survey.length;

    return (
      <form>
        {survey.map((question, key) => (
          <RadioGroupQuestion
            responseOptions={responseOptions}
            question={question}
            handleUpdateProgress={handleUpdateProgress}
            key={key}
          />
        ))}
        <PrimaryButton
          disabled={!allAnswered || pristine || submitting || !valid}
          onClick={handleSubmit}
        >
          {submitting ? "Submitting..." : "Finish ›"}
        </PrimaryButton>
      </form>
    );
  }
}

FeedbackSurveyForm = reduxForm({ form: formName })(FeedbackSurveyForm);

class FeedbackSurvey extends Component {
  render() {
    const {
      props: {
        clientFeedbackSurvey: {
          data: surveyData,
          isFinished: surveyIsFinished,
          isPending: surveyIsPending,
        },
        jobs: {
          data: jobsData,
          isFinished: jobsIsFinished,
          isPending: jobsIsPending,
        },
        match: {
          params: { id },
        },
        notify,
        submitFeedback,
      },
    } = this;
    const isReady =
      surveyIsFinished && !surveyIsPending && jobsIsFinished && !jobsIsPending;

    if (!isReady) return <Loading />;
    if (!surveyData[id]) return <CustomNotFound />;

    const survey = surveyData[id].questions
      .map(question => ({
        uuid: question.uuid,
        question_text: question.question_text,
        type: question.type,
      }))
      .filter(question => question.question_text != null);
    const { full_name, job, status } = flattenObject(surveyData[id]);
    const foundJob = job && jobsData && jobsData[job] && jobsData[job].title;

    // 1 = Sent, 2 = Reminded
    if ((status === 1 || status === 2) && survey.length) {
      return (
        <div>
          <BackButton />
          <h1>Feedback Survey</h1>
          {full_name &&
            foundJob && <h4>{`${full_name} | ${jobsData[job].title}`}</h4>}
          <p>
            For the questions below, please rank the worker on a scale of 1-5; 1
            being unsatisfactory, 2-4 being average, and 5 being superior. This
            information is collected to validate and further improve our
            matching process and is not used against the employees, so please
            try to be as objective as possible.
          </p>
          <br />
          <FeedbackSurveyForm
            notify={notify}
            submitFeedback={submitFeedback}
            survey={survey}
            surveyId={id}
          />
        </div>
      );
    } else {
      return <CustomNotFound />;
    }
  }
}

const clientFeedbackSurveySelectorConfig = createResourceSelectorConfig(
  types.CLIENT_FEEDBACK_SURVEY,
  clientFeedbackSurveyQuery,
);

const jobsSelectorConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);

const mapStateToProps = createStructuredSelector({
  ...clientFeedbackSurveySelectorConfig,
  ...jobsSelectorConfig,
});

const mapDispatchToProps = dispatch => ({
  notify: message => dispatch(addNotification(message)),
  submitFeedback: data =>
    dispatch(mutateAsync(clientFeedbackSurveyResponsesQuery(data))),
});

const mapPropsToConfig = () => [clientFeedbackSurveyQuery(), jobsQuery()];

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(FeedbackSurvey);
