// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Container from "common/components/styled/Container";
import SchoolName from "./SchoolName";
import Degree from "./Degree";
import Extra from "./Extra";

const EducationBlock: ComponentType<Props> = styled.div`
  margin: 0 0 50px 0;
`;

const Education = (props: Props) => <EducationBlock {...props} />;

Education.Container = Container;
Education.SchoolName = SchoolName;
Education.Degree = Degree;
Education.Extra = Extra;

export default Education;
