import actions from "admin/actions/recommendationStats";
import { handleActions } from "redux-actions";

const initialParameterStatsState = {
  exact: -1,
  fuzzy: -1,
  usage: -1,
  usages: -1,
};

const initialState = {
  stats: {
    num_recommendations: 0,
    num_applications: 0,
    num_offers: 0,
    num_offers_accepted: 0,
    num_dismissed: 0,
    amount_invoiced: 0,
  },
  accuracyStats: {
    average_minimum_distance: -1,
    application_conversion_rate: -1,
    offer_conversion_rate: -1,
    offer_acceptance_conversion_rate: -1,
    placements_made: -1,
  },
  parameterStats: {
    keywords: { ...initialParameterStatsState },
    location: { ...initialParameterStatsState },
    rate: { ...initialParameterStatsState },
    min_years_experience: { ...initialParameterStatsState },
    max_years_experience: { ...initialParameterStatsState },
    practice_areas: { ...initialParameterStatsState },
    barred_in: { ...initialParameterStatsState },
    distance_precision: { ...initialParameterStatsState },
    radius: { ...initialParameterStatsState },
    firm_types: { ...initialParameterStatsState },
    language_fluency: { ...initialParameterStatsState },
  },
  error: false,
  isLoading: false,
  lastUpdated: null,
};

const recommendations = handleActions(
  {
    [actions.REQUEST_RECOMMENDATION_STATS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_RECOMMENDATION_STATS]: (state, action) => ({
      ...state,
      stats: {
        ...state.stats,
        ...action.payload.stats,
      },
      accuracyStats: {
        ...state.accuracyStats,
        ...action.payload.accuracyStats,
      },
      parameterStats: {
        ...state.parameterStats,
        ...action.payload.parameterStats,
      },
      error: action.error,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.CLEAR_DATES]: state => ({
      ...state,
      endDate: null,
      startDate: null,
    }),
  },
  initialState,
);
export default recommendations;
