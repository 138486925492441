import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  Application,
  Base,
  ContractorRequired,
  Dashboard,
  EditAvailability,
  EditBarLicenses,
  EditConflicts,
  EditExperience,
  EditLanguages,
  EditPositionInterest,
  EditExpertiseAreas,
  EditPrivacy,
  EditProfile,
  EditProfilePicture,
  EditRate,
  EditSchools,
  EditSummary,
  JobSearch,
  Profile,
} from "contractor/components";
import { Messaging, MessageToAgent } from "common/components/Messaging";
import NotFound from "common/components/NotFound";
import {
  DeactivateAccount,
  EditAddress,
  EditDirectDeposit,
  EditJobNotifications,
  EditPassword,
  EditPersonalInfo,
  EditReferences,
  EditResume,
  PaymentHistory,
  Settings,
} from "contractor/components/settings";
import { Job } from "contractor/components/job";
import {
  ExpenseTracker,
  FixedRateTracker,
  TimeEntriesTracker,
} from "contractor/components/time-tracker";
import { WorkstyleAssessment } from "contractor/components";
import {
  Onboarding,
  ContactInformation as _ContactInformation,
  EmailAddressConfirmation as _EmailAddressConfirmation,
  BarAdmissions as _BarAdmissions,
  Education as _Education,
  WorkExperience as _WorkExperience,
  References as _References,
  Address as _Address,
  PracticeAreas as _PracticeAreas,
  AdditionalDetails as _AdditionalDetails,
  ProfilePicture as _ProfilePicture,
  LanguageProficiencies as _LanguageProficiencies,
} from "contractor/components/Onboarding/index";
import _SignupComplete from "public/components/signup/SignupComplete";
import { NewFeatureNotificationsModal } from "common/components";

const BaseWithoutSignupButton = props => (
  <Base empty={true} showSignupButton={false} {...props} />
);
const SignupContainer = props => (
  <div className="signup-container">{props.children}</div>
);

// Wraps route components that rendered children in react-router 3
const onboardingRoute = Component => props => (
  <Onboarding>
    <Component {...props} />
  </Onboarding>
);

const editProfileRoute = Component => props => (
  <EditProfile>
    <Component {...props} />
  </EditProfile>
);

const settingsRoute = Component => props => (
  <Settings>
    <Component {...props} />
  </Settings>
);

export const editProfileRoutes = [
  {
    name: "Profile Summary",
    route: "summary",
    nonAttorney: true,
    component: editProfileRoute(EditSummary),
  },
  {
    name: "Areas of Expertise",
    route: "expertise-areas",
    nonAttorney: true,
    component: editProfileRoute(EditExpertiseAreas),
  },
  {
    name: "Bar Licenses",
    route: "bar-licenses",
    nonAttorney: false,
    component: editProfileRoute(EditBarLicenses),
  },
  {
    name: "Profile Picture",
    route: "profile-picture",
    nonAttorney: true,
    component: editProfileRoute(EditProfilePicture),
  },
  {
    name: "Education",
    route: "education",
    nonAttorney: true,
    component: editProfileRoute(EditSchools),
  },
  {
    name: "Work Experience",
    route: "work-experience",
    nonAttorney: true,
    component: editProfileRoute(EditExperience),
  },
  {
    name: "Rate",
    route: "rate",
    nonAttorney: true,
    component: editProfileRoute(EditRate),
  },
  {
    name: "Availability",
    route: "availability",
    nonAttorney: true,
    component: editProfileRoute(EditAvailability),
  },
  {
    name: "Position Interest",
    route: "position-interest",
    nonAttorney: true,
    component: editProfileRoute(EditPositionInterest),
  },
  {
    name: "Conflicts",
    route: "conflicts",
    nonAttorney: true,
    component: editProfileRoute(EditConflicts),
  },
  {
    name: "Languages",
    route: "languages",
    nonAttorney: true,
    component: editProfileRoute(EditLanguages),
  },
  {
    name: "Privacy",
    route: "privacy",
    nonAttorney: true,
    component: editProfileRoute(EditPrivacy),
  },
];

export const settingsRoutes = [
  {
    name: "Job Notification Emails",
    route: "job-notifications",
    nonAttorney: true,
    component: settingsRoute(EditJobNotifications),
  },
  {
    name: "Personal Information",
    route: "personal-info",
    nonAttorney: true,
    component: settingsRoute(EditPersonalInfo),
  },
  {
    name: "Address",
    route: "address",
    nonAttorney: true,
    component: settingsRoute(EditAddress),
  },
  {
    name: "Resume",
    route: "resume",
    nonAttorney: true,
    component: settingsRoute(EditResume),
  },
  {
    name: "References",
    route: "references",
    nonAttorney: true,
    component: settingsRoute(EditReferences),
  },
  {
    name: "Password",
    route: "password",
    nonAttorney: true,
    component: settingsRoute(EditPassword),
  },
  {
    name: "Direct Deposit",
    route: "direct-deposit",
    nonAttorney: true,
    component: settingsRoute(EditDirectDeposit),
  },
  {
    name: "Payment History",
    route: "payment-history",
    nonAttorney: true,
    component: settingsRoute(PaymentHistory),
  },
  {
    name: "Deactivate Account",
    route: "deactivate-account",
    nonAttorney: true,
    component: settingsRoute(DeactivateAccount),
  },
];

const BarAdmissions = onboardingRoute(_BarAdmissions);
const ContactInformation = onboardingRoute(_ContactInformation);
const EmailAddressConfirmation = onboardingRoute(_EmailAddressConfirmation);
const Education = onboardingRoute(_Education);
const WorkExperience = onboardingRoute(_WorkExperience);
const References = onboardingRoute(_References);
const Address = onboardingRoute(_Address);
const PracticeAreas = onboardingRoute(_PracticeAreas);
const AdditionalDetails = onboardingRoute(_AdditionalDetails);
const ProfilePicture = onboardingRoute(_ProfilePicture);
const LanguageProficiencies = onboardingRoute(_LanguageProficiencies);

const OnboardingRoutes = ({ match }) => (
  <Base enableLinks={false}>
    <Switch>
      <Redirect
        exact
        path={match.url}
        to={`${match.url}/contact-information`}
      />
      <Route
        path={`${match.url}/contact-information`}
        component={ContactInformation}
      />
      <Route
        path={`${match.url}/email-address-confirmation`}
        component={EmailAddressConfirmation}
      />
      <Route path={`${match.url}/bar-admissions`} component={BarAdmissions} />
      <Route path={`${match.url}/education`} component={Education} />
      <Route path={`${match.url}/work-experience`} component={WorkExperience} />
      <Route path={`${match.url}/references`} component={References} />
      <Route path={`${match.url}/address`} component={Address} />
      <Route path={`${match.url}/practice-areas`} component={PracticeAreas} />
      <Route
        path={`${match.url}/additional-details`}
        component={AdditionalDetails}
      />
      <Route path={`${match.url}/profile-picture`} component={ProfilePicture} />
      <Route
        path={`${match.url}/language-proficiencies`}
        component={LanguageProficiencies}
      />
    </Switch>
  </Base>
);

const MessagingRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/:id?`} component={Messaging} />
  </Switch>
);

const JobRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={JobSearch} />
    <Route path={`${match.url}/:id/message`} component={MessageToAgent} />
    <Route path={`${match.url}/:id/apply`} component={Application} />
    <Route path={`${match.url}/:id/expenses`} component={ExpenseTracker} />
    <Route path={`${match.url}/:id/mark_event`} component={FixedRateTracker} />
    <Route
      path={`${match.url}/:id/time_tracker`}
      component={TimeEntriesTracker}
    />
    <Route path={`${match.url}/:id`} component={Job} />
  </Switch>
);

const EditProfileRoutes = ({ match }) => (
  <Switch>
    <Redirect
      exact
      from={match.url}
      to={`${match.url}/${editProfileRoutes[0].route}`}
    />
    {editProfileRoutes.map(({ component, route }) => (
      <Route
        key={`route-edit-profile-${route}`}
        path={`${match.url}/${route}`}
        component={component}
      />
    ))}
  </Switch>
);

const ProfileRoutes = ({ match }) => (
  <Switch>
    <Redirect exact from={match.url} to={`${match.url}/view`} />
    <Route path={`${match.url}/view`} component={Profile} />
    <Route path={`${match.url}/edit`} component={EditProfileRoutes} />
  </Switch>
);

const SettingsRoutes = ({ match }) => (
  <Switch>
    <Redirect
      exact
      from={match.url}
      to={`${match.url}/${settingsRoutes[0].route}`}
    />
    {settingsRoutes.map(({ component, route }) => (
      <Route
        key={`route-edit-profile-${route}`}
        path={`${match.url}/${route}`}
        component={component}
      />
    ))}
  </Switch>
);

const SignupComplete = props => (
  <BaseWithoutSignupButton>
    <SignupContainer>
      <_SignupComplete {...props} />
    </SignupContainer>
  </BaseWithoutSignupButton>
);

const ContractorRoutes = ({ match }) => (
  <Base>
    <Switch>
      <Route exact path={match.url} component={Dashboard} />
      <Route
        path={`${match.url}/workstyle-assessment/:candidateId`}
        component={WorkstyleAssessment}
      />
      <Route path={`${match.url}/messages`} component={MessagingRoutes} />
      <Route path={`${match.url}/jobs`} component={JobRoutes} />
      <Route path={`${match.url}/profile`} component={ProfileRoutes} />
      <Route path={`${match.url}/settings`} component={SettingsRoutes} />
      <Route component={NotFound} />
    </Switch>
  </Base>
);

export default ({ match }) => (
  <ContractorRequired>
    <Switch>
      <Route path={`${match.url}/onboarding`} component={OnboardingRoutes} />
      <Route path={`${match.url}/signup_complete`} component={SignupComplete} />
      <Route path={match.url} component={ContractorRoutes} />
    </Switch>
    <NewFeatureNotificationsModal />
  </ContractorRequired>
);
