// @flow strict
import React from "react";
import { Link } from "react-router-dom";

import ContractorDropdownTrigger from "./ContractorDropdownTrigger";
import HiringAgentDropdownTrigger from "./HiringAgentDropdownTrigger";
import Desktop from "./blocks";

type DropdownProps = {
  dropdownOpen: boolean,
  onToggleUserDropdown: () => void,
  userType: UserType,
};

const Dropdown = (props: DropdownProps) => {
  const { dropdownOpen, onToggleUserDropdown, userType } = props;
  switch (userType) {
    case "admin":
      return null;
    case "anonymous":
      return null;
    case "contractor":
      return (
        <Desktop.Dropdown id="top-nav-dropdown" onClick={onToggleUserDropdown}>
          <ContractorDropdownTrigger dropdownOpen={dropdownOpen} />
          <Desktop.DropdownContent dropdownOpen={dropdownOpen}>
            <Link to="/contractor/settings">Settings</Link>
            <Link to="/logout">Log&nbsp;Out</Link>
          </Desktop.DropdownContent>
        </Desktop.Dropdown>
      );
    case "hiring_agent":
      return (
        <Desktop.Dropdown id="top-nav-dropdown" onClick={onToggleUserDropdown}>
          <HiringAgentDropdownTrigger dropdownOpen={dropdownOpen} />
          <Desktop.DropdownContent dropdownOpen={dropdownOpen}>
            <Link to="/agency/settings">Settings</Link>
            <Link to="/logout">Log&nbsp;Out</Link>
          </Desktop.DropdownContent>
        </Desktop.Dropdown>
      );
    default:
      return null;
  }
};

export default Dropdown;
