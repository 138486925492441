// @flow strict
import styled from "styled-components";

import { Button as BootstrapButton } from "react-bootstrap";

const Button = styled(BootstrapButton)`
  text-transform: uppercase;
  min-width: 120px;
  flex-grow: 1;
  ${({ theme }) => theme.media.desktop`
    flex-grow: 0;
  `};
  ${({ theme }) => theme.media.lg`
    min-width: 180px;
  `};
  ${({ theme }) => theme.media.xl`
    min-width: 220px;
  `};
  & + button.btn {
    margin-left: 1rem;
  }
  && {
    font-size: 18px;
    font-weight: bold;
    ${({ theme }) => theme.media.mobile`
      margin-bottom: 1rem;
    `};
  }
`;

export default Button;
