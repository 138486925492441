import * as timeEntryActions from "admin/actions/invoicing/timeEntries";

const initialTimeEntriesState = {
  lastUpdated: null,
  isFetching: false,
  items: {},
};

export default function timeEntries(state = initialTimeEntriesState, action) {
  switch (action.type) {
    case timeEntryActions.REQUEST_TIME_ENTRIES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case timeEntryActions.RECEIVE_TIME_ENTRIES:
      return Object.assign({}, state, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        items: action.items,
      });
    default:
      return state;
  }
}
