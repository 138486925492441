import actions from "public/actions/reviews";
import { handleActions } from "redux-actions";

const initialState = {
  reviewRequest: {
    practice_areas: [],
    review_completed: false,
  },
  relationshipChoices: [],
  declinedReasonChoices: [],
  reviewCreated: false,
  reviewRequestDeclined: false,
  isLoading: false,
  lastUpdated: null,
};

const reviews = handleActions(
  {
    [actions.RECEIVE_RELATIONSHIP_CHOICES]: (state, action) => ({
      ...state,
      relationshipChoices: action.payload,
    }),
    [actions.RECEIVE_DECLINED_REASON_CHOICES]: (state, action) => ({
      ...state,
      declinedReasonChoices: action.payload,
    }),
    [actions.REQUEST_REVIEW_REQUEST]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_REVIEW_REQUEST]: (state, action) => ({
      ...state,
      ...action.payload,
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
    [actions.RECEIVE_SUBMIT_REVIEW]: (state, action) => ({
      ...state,
      reviewCreated: !action.error,
    }),
    [actions.RECEIVE_SUBMIT_DECLINE_REVIEW_REQUEST]: (state, action) => ({
      ...state,
      reviewRequestDeclined: !action.error,
    }),
  },
  initialState,
);
export default reviews;
