import styled from "styled-components";

const SUCCESS_STATUSES = ["paid"];
const PENDING_STATUSES = ["pending", "in_transit"];
const FAILED_STATUSES = ["canceled", "failed", "refunded"];

const Status = styled.span`
  text-transform: uppercase;
  font-weight: bold;

  color: ${({ theme, status }) => {
    if (SUCCESS_STATUSES.includes(status)) return theme.colors.success();
    if (PENDING_STATUSES.includes(status)) return theme.colors.warning();
    if (FAILED_STATUSES.includes(status)) return theme.colors.danger();
    return theme.colors.primary_black40();
  }};
`;

export default Status;
