import React from "react";

import Onboard from "../blocks";
import { isActiveURL } from "common/utils";
import { basePath } from "contractor/components/Onboarding/values";

export const getIcon = (step, steps, disabled = false) => {
  const len = steps.length;

  if (disabled) return <Onboard.Icon iconType="unvisited" />;

  for (let i = 0; i < len; i++) {
    if (steps[i].name === step) {
      switch (steps[i].status) {
        case "PENDING_VERIFICATION":
          return <Onboard.Icon iconType="pending" />;
        case "COMPLETED":
          return <Onboard.Icon iconType="success" />;
        case "ACTION_SUGGESTED":
        case "REJECTED":
          return <Onboard.Icon iconType="danger" />;
        case "NOT_COMPLETED":
          if (isActiveURL(`${basePath}/${step.replace(/_/g, "-")}`)) {
            return <Onboard.Icon iconType="unstarted" />;
          } else {
            return <Onboard.Icon iconType="unvisited" />;
          }
        default:
          return <Onboard.Icon iconType="unvisited" />;
      }
    }
  }
};
