import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";

import { Button } from "react-bootstrap";
import { SelectField, TextField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms";

const FORM_NAME = "declineReviewRequestForm";

const requiredFields = ["declined_reason"];

const declineReviewFormSelector = formValueSelector(FORM_NAME);

const validate = values => {
  const localRequiredFields = [...requiredFields];
  if (values.declined_reason === "4")
    localRequiredFields.push("declined_reason_other");
  return validateRequiredFields(localRequiredFields, values);
};

class DeclineReviewRequestForm extends Component {
  render() {
    const {
      attorneyFirstName,
      declined_reason,
      declinedReasonChoices,
      handleSubmit,
      submitting,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <SelectField
          name="declined_reason"
          label={`Why are you unable to be a professional reference for ${attorneyFirstName}?`}
        >
          {declinedReasonChoices.map(choice => (
            <option key={choice.value} value={choice.value}>
              {choice.label}
            </option>
          ))}
        </SelectField>
        {declined_reason === "4" && (
          <TextField
            name="declined_reason_other"
            label={`Describe why you are unable to be a professional reference for ${attorneyFirstName}`}
          />
        )}
        <div className="text-center">
          <Button type="submit" bsStyle="info" disabled={submitting}>
            {submitting ? (
              <span>
                <i className="far fa-cog fa-spin" />
                &nbsp;Submitting...
              </span>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    );
  }
}

DeclineReviewRequestForm = compose(
  connect(state => ({
    declined_reason: declineReviewFormSelector(state, "declined_reason"),
  })),
  reduxForm({
    form: FORM_NAME,
    validate,
  }),
)(DeclineReviewRequestForm);

export default DeclineReviewRequestForm;
