import styled from "styled-components";

import DropdownTrigger from "./DropdownTrigger";

const Dropdown = styled.div`
  position: relative;
  float: right;

  &:hover ${DropdownTrigger} {
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary_turquoise()};
  }
`;

export default Dropdown;
