import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";

export const UnconfirmedCopy = ({ pendingEmailAddress }) => (
  <p style={{ wordWrap: "break-word" }}>
    {`Confirmation sent. Check your inbox at: ${pendingEmailAddress}`}
  </p>
);

export const ConfirmedCopy = () => (
  <Fragment>
    <p>Your email address is confirmed. Great job!</p>
    <p>
      Change your email address in{" "}
      <Link to="/contractor/onboarding/contact-information">
        Contact Information
      </Link>.
    </p>
  </Fragment>
);

export const resendErrorCopy =
  "Sorry, there was a problem resending the confirmation email. Try reloading the page and sending it again.";

export const ResendSuccessCopy = () => (
  <p>
    Confirmation email resent. <Onboard.Icon iconType="success" />
  </p>
);

export const EmailChangeCancelledCopy = ({ emailAddress }) => (
  <Alert style={{ wordWrap: "break-word" }}>
    {`You cancelled the change. Your email address is still: ${emailAddress}`}
  </Alert>
);
