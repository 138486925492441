// @flow strict
import styled from "styled-components";

const IconCheck = styled.i.attrs({
  className: "success-check fa fa-check-circle-o",
  "aria-hidden": "true",
})`
  &&&& {
    width: auto;
    margin: 0 5px 0 0;
    padding: 0;
    font-size: 20px;
  }
`;

export default IconCheck;
