import React, { Component } from "react";
import PropTypes from "prop-types";
import { ControlLabel, FormGroup } from "react-bootstrap";
import Dropzone from "react-dropzone";

import FieldHelpBlock from "./FieldHelpBlock";
import { validationState } from "common/utils/forms";

export default class DropzoneInput extends Component {
  static propTypes = {
    // redux-form Field props
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    // FormControlGroup props
    label: PropTypes.string.isRequired,
    bsSize: PropTypes.string,
    // react-dropzone props passed through
    accept: PropTypes.string,
    disable: PropTypes.bool,
    multiple: PropTypes.bool,
    maxSize: PropTypes.number,
    style: PropTypes.object,
  };

  static defaultProps = {
    accept: "",
    multiple: false,
    maxSize: 15728640,
    style: {},
  };

  render() {
    const {
      input,
      meta,
      label,
      bsSize,
      accept,
      multiple,
      maxSize,
      style,
      ...otherProps
    } = this.props;
    return (
      <FormGroup
        validationState={validationState(meta)}
        className="react-dropzone-input"
        bsSize={bsSize}
      >
        {label && <ControlLabel>{label}</ControlLabel>}

        <div className="react-dropzone-container">
          <Dropzone
            className="dropzone"
            activeClassName="active-dropzone"
            onDrop={fileToUpload => input.onChange(fileToUpload)}
            accept={accept}
            multiple={multiple}
            maxSize={maxSize}
            style={style}
            {...otherProps}
          >
            <div className="dropzone-text-container">
              <i className="fas fa-file-alt" />
              &nbsp;
              <span>Drag a file here or click to browse.</span>
            </div>
          </Dropzone>
          {input.value && Array.isArray(input.value) && (
            <ul>
              {input.value.map((file, i) => (
                <li key={i}>{file.name}</li>
              ))}
            </ul>
          )}
        </div>

        <FieldHelpBlock touched={meta.touched} error={meta.error} />
      </FormGroup>
    );
  }
}
