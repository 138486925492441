import { createAction } from "redux-actions";
import querystring from "querystring";

import { getCookie } from "common/utils";
import { metaGenerator } from "common/actions";
import { oldApi } from "common/api";
import { createApiUrl } from "utils";

const apiMethods = {
  get: oldApi.get,
  post: oldApi.post,
  patch: oldApi.patch,
  put: oldApi.put,
  delete: oldApi.del,
};

export const action = options => dispatch => {
  const {
    type,
    actionType = type,
    version = "v2",
    data = {},
    path = type,
    params = {},
    method = "get",
  } = options;
  const stringParams = params && querystring.stringify(params);

  const urlNeedsTrailingSlash = !path.includes("?") && !path.endsWith("/");
  let url = `/api/${version}/${path}${urlNeedsTrailingSlash ? "/" : ""}`;

  if (stringParams) {
    url += `?${stringParams}`;
  }

  const meta = () => ({ ...metaGenerator(), options });

  dispatch(createAction(`REQUEST/${actionType}`, null, meta)());
  return apiMethods[method](url, data).then(({ json, response }) => {
    const payload = response
      ? response.ok
        ? json
        : new Error(json.detail || "Unknown error.")
      : {};
    const dispatchedAction = dispatch(
      createAction(`RECEIVE/${actionType}`, null, meta)(payload),
    );
    if (method === "get") {
      return dispatchedAction;
    }
    return { json, response };
  });
};

// TODO: Having to specify the reducer isn't ideal
// TODO: How can we check if we need to dispatch action if options change without clearing state?
const actionHashes = new Set();
const createHash = (...args) => JSON.stringify(args);
export const actionIfNeeded = (reducer, slice) => options => dispatch => {
  const hash = createHash(reducer, slice, options);
  if (!actionHashes.has(hash) || options.forceFetch) {
    actionHashes.add(hash);
    return dispatch(action(options));
  }
  return null;
};

export const resourceActionIfNeeded = options => (dispatch, getState) => {
  const { type, id } = options;
  if (id && getState().resourcesStore[type].hasOwnProperty(id)) {
    return null;
  }
  const hash = createHash(options);
  if (!actionHashes.has(hash) || options.forceFetch) {
    actionHashes.add(hash);
    return dispatch(action(options));
  }
  return null;
};

export const anonymousActionIfNeeded = actionIfNeeded("anonymous");
export const attorneyActionIfNeeded = actionIfNeeded("attorney");
export const commonActionIfNeeded = actionIfNeeded("common");
export const hiringAgentActionIfNeeded = actionIfNeeded("hiringAgentDashboard");

export const submitFile = ([requestActionType, receiveActionType] = []) => (
  url,
  file,
) => dispatch => {
  if (requestActionType) dispatch(createAction(requestActionType)());
  return fetch(createApiUrl(url), {
    headers: {
      Accept: "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
    },
    body: file,
    credentials: "include",
    method: "POST",
  })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      const payload = response.ok ? json : new Error("Error uploading file.");
      if (receiveActionType) {
        dispatch(
          createAction(receiveActionType, p => p, metaGenerator)(payload),
        );
      }
      return { json, response };
    });
};
