import React, { Component } from "react";
import { ControlLabel, FormGroup, InputGroup } from "react-bootstrap";
import FieldHelpBlock from "./FieldHelpBlock";

import { domOnlyProps, validationState } from "common/utils/forms";
import Geosuggest from "react-geosuggest";

export default class GeosuggestControl extends Component {
  render() {
    const {
      // redux-form Field props
      input,
      meta,
      // FormControlGroup props
      label,
      buttonLeft,
      buttonRight,
      addonLeft,
      addonRight,
      bsSize,
      maxLength = null,
      // custom props
      onChangeAction,
      tooltip,
      // props passed through to FormControl
      ...otherProps
    } = this.props;

    input.value = input.value || "";

    const onChange = onChangeAction
      ? location => {
          input.onChange(location.location);
          setTimeout(onChangeAction, 500);
        }
      : input.onChange;

    const formControl = (
      <Geosuggest
        onSuggestSelect={onChange}
        types={["geocode"]}
        initialValue={input.value.label}
        {...domOnlyProps(input)}
        {...domOnlyProps(otherProps)}
      />
    );

    return (
      <FormGroup
        validationState={validationState(meta, maxLength)}
        bsSize={bsSize}
      >
        {label && <ControlLabel>{label}</ControlLabel>}
        {addonLeft || addonRight || buttonLeft || buttonRight ? (
          <InputGroup>
            {addonLeft && <InputGroup.Addon>{addonLeft}</InputGroup.Addon>}
            {buttonLeft && <InputGroup.Button>{buttonLeft}</InputGroup.Button>}
            {formControl}
            {buttonRight && (
              <InputGroup.Button>{buttonRight}</InputGroup.Button>
            )}
            {addonRight && <InputGroup.Addon>{addonRight}</InputGroup.Addon>}
          </InputGroup>
        ) : (
          formControl
        )}
        {maxLength && (
          <div className="text-right">
            {input.value.length}/{maxLength}
          </div>
        )}
        <FieldHelpBlock touched={meta.touched} error={meta.error} />
        {tooltip && <div className="text-muted">{tooltip}</div>}
      </FormGroup>
    );
  }
}
