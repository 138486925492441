// @flow
import { history as browserHistory } from "app";

import authActions from "common/actions/auth";
import { BROADCAST_MESSAGE_KEY } from "./config";

function processMessage<T>(message: BroadcastMessage<T>, store: any) {
  const { action } = message;
  if (action === undefined || action.type === undefined) return;

  store.dispatch(action);

  if (action.type === authActions.LOG_OUT) {
    browserHistory.push("/");
  }
}

export default function createReceiveMessage(store: any) {
  function receiveMessage(e: StorageEvent) {
    if (e.key !== BROADCAST_MESSAGE_KEY || !e.newValue) return;
    const message = JSON.parse(e.newValue);
    if (!message) return;

    processMessage(message, store);
  }
  return receiveMessage;
}
