// @flow strict
import React from "react";
import moment from "moment-timezone";

import { createApiUrl } from "utils";

export function fetchResourceWithDateRange<T>(
  startDate: string,
  endDate: string,
  resource: string,
): Promise<T> {
  return fetch(
    createApiUrl(
      `/api/dev/${resource}/?format=json&limit=999&start_date=${startDate}&end_date=${endDate}&not_invoiced=true`,
    ),
    { credentials: "include" },
  ).then(response => response.json());
}

export function formatDate(date: string = moment()): string {
  return moment.parseZone(date).format("YYYY-MM-DD");
}

export const makeGetTimeInTimezone = (timezone: string) => (
  time: string,
): string =>
  moment(time)
    .clone()
    .tz(timezone);

export const getPacificTime = (time: string): string =>
  makeGetTimeInTimezone("America/Los_Angeles")(time);

export const getMondayOfWeek = (date: string): string =>
  moment(date).startOf("isoWeek");

export const getSundayOfWeek = (date: string): string =>
  moment(date)
    .startOf("isoWeek")
    .add(6, "days");

export const getIsInCurrentWeek = (date: string): string =>
  moment(date).isSame(moment(), "isoWeek");

export function getMessageTimeDisplay(dbDateTime: string): string {
  const dateTime = moment(dbDateTime);
  const currentDate = moment();
  if (currentDate.isSame(dateTime, "day")) {
    return dateTime.format("h:mm a");
  } else if (currentDate.isSame(dateTime, "year")) {
    return dateTime.format("MMM DD");
  }

  return dateTime.format("MM/DD/YYYY");
}

/**
 * Takes an array and returns a new array with the same elements randomized.
 * @param {Array} arr
 * @returns {Array}
 */
export function randomizeArray(arr: Array<mixed>): Array<mixed> {
  const arrClone = arr.slice();

  let j, tmp1, tmp2;
  for (let i = arrClone.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    [tmp1, tmp2] = [arrClone[j], arrClone[i]];
    arrClone[i] = tmp1;
    arrClone[j] = tmp2;
  }

  return arrClone;
}

/**
 * Converts a list of objects with an `identifier` attribute to an object keyed by `identifier`.
 * @param {Array} list
 * @param {string} identifier
 * @returns {{}}
 */
export function listToObject(
  list: Array<{ [key: string]: string }>,
  identifier: string,
): {} {
  if (!list) {
    return {};
  }
  const items = {};
  const listLength = list.length;
  for (let i = 0; i < listLength; i++) {
    items[list[i][identifier]] = list[i];
  }
  return items;
}

export const metaGenerator = (): {} => ({ receivedAt: Date.now() });

class WrappedComponentType extends React.Component<{}> {
  displayName: ?string;
  name: ?string;
}

export const getComponentDisplayName = (
  WrappedComponent: WrappedComponentType,
): string =>
  WrappedComponent.displayName || WrappedComponent.name || "WrappedComponent";

// Use symmetric difference to find out which was added or removed (opposite of intersection)
export const symDiff = <T>(arr1: T[], arr2: T[]): T[] =>
  arr1.filter(ele => arr2.indexOf(ele) < 0);

export const addOrRemoveArrayItem = <T>(array: T[], item: T): T[] =>
  array.includes(item) ? array.filter(x => x !== item) : [...array, item];

export const capitalizedString = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const flattenObject = obj => {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export const range = (
  start: number,
  stop: number = start,
  step: number = 1,
): Array<number> => {
  const actualStart = stop === start ? 0 : start;

  if ((step > 0 && actualStart >= stop) || (step < 0 && actualStart <= stop)) {
    return [];
  }

  const result = [];
  for (let i = actualStart; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
};

/**
 * Checks for deep equality between two items
 * @param {any} x
 * @param {any} y
 * @returns {boolean}
 */
export function deepEqual<T>(x: T, y: T): boolean {
  if (x && y && typeof x === "object" && typeof y === "object") {
    const sameLength = Object.keys(x).length === Object.keys(y).length;
    return sameLength && Object.keys(x).every(key => deepEqual(x[key], y[key]));
  } else {
    return x === y;
  }
}

/**
 * Checks for shallow equality between two arrays
 * @param {array} x
 * @param {array} y
 * @returns {boolean}
 */
export function areEqualArrays<T>(x: Array<T>, y: Array<T>): boolean {
  if (Array.isArray(x) && Array.isArray(y)) {
    if (x.length === y.length) {
      if (x.every((element, index) => element === y[index])) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Checks if a URL path is active
 * @param {string or array} url
 * @param {boolean} exact
 * @returns {boolean}
 */
export function isActiveURL(
  urls: string | Array<string>,
  exact?: boolean,
): boolean {
  const { pathname } = window.location;
  function checkActive(url: string) {
    return (
      url === pathname ||
      url === pathname + "/" ||
      url === pathname.slice(0, -1) ||
      (exact ? false : url === pathname.slice(0, url.length))
    );
  }

  if (Array.isArray(urls)) {
    const urlLen = urls.length;
    for (let i = 0; i < urlLen; i++) {
      if (checkActive(urls[i])) return true;
    }
  } else {
    return checkActive(urls);
  }

  return false;
}

/**
 * Returns the key of the first property with the given 'value'
 * @param {any}      value  Property value to find key for
 * @param {object}   object Object containing properties to search
 * @returns {string}        Key of the first property that matches searched value
 */
export function keyForValue<T>(value: T, obj: {}): ?string {
  const propertyWithValue = Object.entries(obj).find(([_key, targetValue]) =>
    deepEqual(targetValue, value),
  );
  return propertyWithValue ? propertyWithValue[0] : null;
}

/**
 * Partitions items in an array based on a callback test
 * Return value can be destructured (ex: const [pass, fail] = partition(jobResponses, isValid))
 * @param {array}    array   Array of items to partition into two categories
 * @param {function} isValid Callback to test elements
 * @returns {array}          Array containing a "pass" array and "fail" array -> [[passItems], [failItems]]
 */
export function partition<T>(array: T[], isValid: T => boolean): [T[], T[]] {
  return array.reduce(
    ([pass, fail], curr) =>
      isValid(curr) ? [[...pass, curr], fail] : [pass, [...fail, curr]],
    [[], []],
  );
}

export { default as connectFixtures } from "./connectFixtures";
export {
  getAuthHeader,
  getCampaignParamsFromLocalStorage,
  getCookie,
  getUrlParamOrCookie,
} from "./api";
