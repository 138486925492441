import { combineReducers } from "redux";

import createReducer from "common/reducers/utils";

import agency from "hiringagent-dashboard/reducers/agency";
import applicantsTable from "hiringagent-dashboard/reducers/applicantsTable";
import applications from "hiringagent-dashboard/reducers/applications";
import attorneys from "hiringagent-dashboard/reducers/attorneys";
import auth from "hiringagent-dashboard/reducers/auth";
import favorites from "hiringagent-dashboard/reducers/favorites";
import hiringAgents from "hiringagent-dashboard/reducers/hiringAgents";
import invoices from "hiringagent-dashboard/reducers/invoices";
import jobs from "hiringagent-dashboard/reducers/jobs";
import messages from "hiringagent-dashboard/reducers/messages";
import notifications from "hiringagent-dashboard/reducers/notifications";
import onboarding from "hiringagent-dashboard/reducers/onboarding";
import payments from "hiringagent-dashboard/reducers/payments";
import pendingEntries from "hiringagent-dashboard/reducers/pendingEntries";
import reviews from "hiringagent-dashboard/reducers/reviews";
import recommendations from "hiringagent-dashboard/reducers/recommendations";
import search from "hiringagent-dashboard/reducers/search";
import sidebar from "hiringagent-dashboard/reducers/sidebar";
import tags from "hiringagent-dashboard/reducers/tags";
import languages from "hiringagent-dashboard/reducers/languages";

export default combineReducers({
  agency,
  applicantsTable,
  applications,
  attorneys,
  auth,
  clioContacts: createReducer("clioContacts"),
  favorites,
  haeUser: createReducer("haeUser"),
  hiringAgents,
  hiring_reasons: createReducer("hiring_reasons"),
  invoices,
  jobs,
  languages,
  messages,
  notifications,
  onboarding,
  payments,
  pendingEntries,
  reviews,
  recommendations,
  search,
  sidebar,
  tags,
});
