import { mutateResourceQuery } from "queries";
import types from "resources/types";
import { getCookie } from "common/utils";

export const createSchoolQuery = data =>
  mutateResourceQuery(
    types.SCHOOLS,
    {
      url: "/api/v2/schools/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const updateSchoolQuery = (data, uuid) =>
  mutateResourceQuery(
    types.SCHOOLS,
    {
      url: `/api/v2/schools/${uuid}`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );

export const deleteSchoolQuery = uuid =>
  mutateResourceQuery(types.SCHOOLS, {
    url: `/api/v2/schools/${uuid}`,
    options: {
      method: "DELETE",
    },
  });

export const addSchoolTranscriptQuery = (data, uuid) =>
  mutateResourceQuery(
    types.SCHOOL_TRANSCRIPT,
    {
      url: `/api/v2/schools/${uuid}/add_transcript/`,
      options: {
        headers: {
          Accept: "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
        method: "POST",
      },
    },
    data,
  );
