// @flow strict
import * as React from "react";

import Desktop from "./blocks";
import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { ownContractorQuery } from "queries/requests/contractors";
import types from "resources/types";
import { userName } from "../values";

type ContractorDropdownTriggerProps = {
  dropdownOpen: boolean,
};
type Props = ContractorDropdownTriggerProps & {
  ownContractor: QueryResource<Contractor>,
};

const ContractorDropdownTrigger = (props: Props) => {
  const {
    ownContractor: { data, isFinished, isPending },
    dropdownOpen,
  } = props;
  const isReady = isFinished && !isPending;
  if (!isReady) return null;
  const contractor = data[Object.keys(data)[0]];
  const name = userName(contractor);

  return (
    <Desktop.DropdownTrigger>
      {contractor.profile_picture_url && (
        <Desktop.DropdownImage src={contractor.profile_picture_url} />
      )}
      <Desktop.DropdownName dropdownOpen={dropdownOpen} name={name}>
        {name}
      </Desktop.DropdownName>
    </Desktop.DropdownTrigger>
  );
};

const mapPropsToConfig = ownContractorQuery;
const mapStateToProps = createResourceSelector(
  types.OWN_CONTRACTOR,
  mapPropsToConfig,
);

export default React.memo<ContractorDropdownTriggerProps>(
  connectConnectRequest(mapPropsToConfig, mapStateToProps)(
    ContractorDropdownTrigger,
  ),
);
