// @flow strict
import React from "react";

import Education from "./blocks";

const EducationSection = ({ contractor }) => {
  if (!contractor.education) return null;

  return (
    <Education>
      <h1>Education</h1>
      <Education.Container>
        {contractor.education.map(degree => (
          <Education.Degree key={degree.uuid}>
            <Education.SchoolName>{degree.school_name}</Education.SchoolName>
            <Education.Extra>{degree.degree}</Education.Extra>
            <Education.Extra>
              {`Graduated: ${degree.year_graduated}`}
            </Education.Extra>
            {degree.gpa && (
              <Education.Extra>{`${degree.gpa} GPA`}</Education.Extra>
            )}
          </Education.Degree>
        ))}
      </Education.Container>
    </Education>
  );
};

export default EducationSection;
