import React from "react";
import { reduxForm } from "redux-form";
import { Alert } from "react-bootstrap";

import { ReactSelectField } from "common/components/forms/inputs";

let PracticeAreasForm = ({ error, handleSubmit, tags }) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <ReactSelectField
      name="practice_areas"
      label="Practice Areas"
      multi={true}
      options={tags}
    />
  </form>
);

export default reduxForm({
  form: "onboardingPracticeAreasForm",
})(PracticeAreasForm);
