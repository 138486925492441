import React, { Component } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { Col, ControlLabel, Row } from "react-bootstrap";

import FormButton from "common/components/FormButton";
import { TextField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";

export const formName = "emailVerificationForm";
const requiredFields = ["verification_email_address", "barinformation"];

// TODO: Add length validation
const validate = values => validateRequiredFields(requiredFields, values);

class EmailVerificationForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm={9}>
            <TextField
              name="verification_email_address"
              label="Add Email Verification"
            />
          </Col>
          <Col sm={3}>
            <ControlLabel style={{ visibility: "hidden" }}>Save</ControlLabel>
            <FormButton
              action="save"
              submitting={submitting}
              disabled={pristine || submitting || !valid}
              block
            />
          </Col>
        </Row>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate,
  }),
)(EmailVerificationForm);
