import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Clearfix, ListGroup, ListGroupItem, Panel } from "react-bootstrap";

import { compose, connect, connectRequest } from "queries/utils";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { PanelHeader } from "./utils";

let PendingApplication = props => {
  const {
    candidateId,
    jobListing,
    [types.COMPETENCY_QUESTIONS]: {
      isPending,
      isFinished,
      query: { count },
    },
  } = props;
  const displayAssessmentLink = isFinished && !isPending && count > 0;
  return (
    <ListGroupItem>
      <div>{jobListing.title}</div>
      <ul className="list-inline" style={{ marginBottom: 0 }}>
        <li>
          <Link to={`/contractor/jobs/${jobListing.job}/`}>
            <i className="far fa-newspaper" />&nbsp;Job Details
          </Link>
        </li>
        {displayAssessmentLink && (
          <li>
            <Link to={`/contractor/workstyle-assessment/${candidateId}`}>
              <i className="far fa-clipboard" />&nbsp; Complete Workstyle
              Assessment
            </Link>
          </li>
        )}
        <Clearfix />
      </ul>
    </ListGroupItem>
  );
};

const mapPropsToConfig = ({ candidateId }) =>
  createResourceListQuery(types.COMPETENCY_QUESTIONS, {
    url: `/api/v2/competency_questions/?candidate_not_completed=${candidateId}&limit=999`,
    force: true,
    replace: true,
  });

const mapStateToProps = createResourceSelector(
  types.COMPETENCY_QUESTIONS,
  mapPropsToConfig,
);

PendingApplication = compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(PendingApplication);

class PendingApplicationsPanel extends Component {
  render() {
    const { candidates, jobListings, ...otherProps } = this.props;
    return (
      <Panel {...otherProps}>
        <PanelHeader
          title="Applications"
          bsStyle="warning"
          count={candidates.length}
        />
        <Panel.Collapse>
          <ListGroup>
            {candidates.map(candidate => {
              const jobListing = Object.values(jobListings).find(
                jl => jl.job === candidate.job,
              );
              return (
                <PendingApplication
                  key={`pending-application-${candidate.uuid}`}
                  candidateId={candidate.uuid}
                  jobListing={jobListing}
                />
              );
            })}
          </ListGroup>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default PendingApplicationsPanel;
