import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Media } from "react-bootstrap";

import { setFocusedReview } from "hiringagent-dashboard/actions/reviews";
import AttorneyProfilePicture from "hiringagent-dashboard/components/search/AttorneyProfilePicture";
import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";
import PerformanceReviewForm from "hiringagent-dashboard/forms/PerformanceReviewForm";

export const ReviewModalContent = props => {
  const { onModalClose, performanceReview } = props;
  const initialFormValues = {
    initialValues: {
      performanceReviewId: performanceReview.uuid,
    },
  };

  return (
    <div className="review-modal-content-container">
      <Media>
        <Media.Left>
          <AttorneyProfilePicture
            pictureUrl={performanceReview.contractor.profile_picture_url}
            firstName={performanceReview.contractor.first_name}
            lastName={performanceReview.contractor.last_name}
            uuid={performanceReview.contractor.uuid}
          />
        </Media.Left>
        <Media.Body>
          <p className="form-description">
            Your agency&apos;s job &ldquo;
            {performanceReview.worker.job
              ? performanceReview.worker.job.title
              : null}
            &rdquo; was recently closed. Please take a moment to review{" "}
            {performanceReview.contractor.full_name}&apos;s performance.
            We&apos;ll use this information to improve our recommendations for
            future projects.
          </p>
        </Media.Body>
      </Media>

      <PerformanceReviewForm
        closeForm={onModalClose}
        performanceReview={performanceReview}
        {...initialFormValues}
      />
    </div>
  );
};

ReviewModalContent.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  performanceReview: PropTypes.object.isRequired,
};

export class Review extends Component {
  static propTypes = {
    focusedReview: PropTypes.object.isRequired,
  };

  render() {
    const { focusedReview, handleCloseModal } = this.props;

    const modalContent = (
      <ReviewModalContent
        performanceReview={focusedReview}
        onModalClose={handleCloseModal}
      />
    );
    const modalTitle = `Performance Review for ${focusedReview.contractor.full_name}`;

    return (
      <div className="reviews-container">
        <ConfirmModal
          key={`review-modal-${focusedReview.uuid}`}
          body={modalContent}
          title={modalTitle}
          showFooter={false}
          show={Boolean(focusedReview)}
          close={handleCloseModal}
          actions={null}
        />
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    handleCloseModal: () => dispatch(setFocusedReview(null)),
  }),
)(Review);
