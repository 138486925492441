import React, { useEffect, useReducer } from "react";
import querystring from "querystring";
import keyMirror from "keymirror";
import { Button, Well } from "react-bootstrap";
import { Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useRequest } from "redux-query-react";

import { savedContractorsRequest } from "queries/requests";
import { getAttr, removeEmptyValues } from "utils";
import { setQueryParams } from "public/utils/search";
import {
  EditTalentPoolModal,
  TalentPoolsSearchForm,
  TalentPoolsSearchHeader,
  TalentPoolsSearchResults,
} from ".";
import styles from "./styles";

const updateHistory = (values = {}, replace) => {
  // Don't include values that are the same as initial
  const nonInitialValues = Object.entries(values).reduce((acc, [key, val]) => {
    if (val === initialState[key]) return acc;
    return { ...acc, [key]: val };
  }, {});
  return setQueryParams(nonInitialValues, replace);
};
const initialUrlParams = querystring.parse(window.location.search.substring(1));
const PAGE_SIZE = 10;
const defaultValues = {
  search: "",
  talent_pool: null,
  limit: 10,
  offset: 0,
  ordering: "-created",
};
const init = () => ({
  search: getAttr(initialUrlParams, "search", defaultValues.search),
  talent_pool: getAttr(
    initialUrlParams,
    "talent_pool",
    defaultValues.talent_pool,
  ),
  limit: getAttr(initialUrlParams, "limit", defaultValues.limit),
  offset: getAttr(initialUrlParams, "offset", defaultValues.offset),
  ordering: getAttr(initialUrlParams, "ordering", defaultValues.ordering),
});
const initialState = init();
const actions = keyMirror({
  SET_SEARCH_FILTER: null,
  SET_TALENT_POOL_FILTER: null,
  NEXT_PAGE: null,
  PREVIOUS_PAGE: null,
  SET_PAGE: null,
  SET_ORDERING: null,
  RESET: null,
});

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_SEARCH_FILTER:
      return {
        ...state,
        search: action.payload.search,
        limit: PAGE_SIZE,
        offset: 0,
      };
    case actions.SET_TALENT_POOL_FILTER:
      return {
        ...state,
        talent_pool: action.payload.talent_pool,
        limit: PAGE_SIZE,
        offset: 0,
      };
    case actions.SET_PAGE:
      return {
        ...state,
        offset: action.payload.offset,
      };
    case actions.SET_ORDERING:
      return {
        ...state,
        ordering: action.payload.ordering,
      };
    case actions.RESET:
      return { ...defaultValues };
    default:
      return state;
  }
};

const TalentPoolsPage = ({ history, match }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    updateHistory(state, true);
  }, Object.values(state));
  // Request initial data on page load (will only fire on mount because initialState won't change)
  useRequest(
    savedContractorsRequest({
      url: { params: removeEmptyValues(initialState) },
      force: true,
    }),
  );
  const handleSearchFilterChange = data =>
    dispatch({
      type: actions.SET_SEARCH_FILTER,
      payload: { search: data },
    });
  const handleTalentPoolChange = data =>
    dispatch({
      type: actions.SET_TALENT_POOL_FILTER,
      payload: { talent_pool: data },
    });
  const handleOrderingChange = data =>
    dispatch({
      type: actions.SET_ORDERING,
      payload: { ordering: data },
    });
  const handlePageChange = data =>
    dispatch({
      type: actions.SET_PAGE,
      payload: { offset: data },
    });
  const handleReset = () => dispatch({ type: actions.RESET });

  return (
    <div>
      <h1>Talent Pools</h1>
      <styles.Container>
        <styles.Sidebar>
          <Well>
            <TalentPoolsSearchForm
              defaultValues={{
                search: state.search,
              }}
              values={{
                talent_pool: state.talent_pool,
              }}
              onSearchChange={handleSearchFilterChange}
              onTalentPoolChange={handleTalentPoolChange}
              onReset={handleReset}
            />
          </Well>
          <LinkContainer to={`${match.url}/lists`}>
            <Button block>Edit Talent Pools</Button>
          </LinkContainer>
        </styles.Sidebar>
        <div>
          <TalentPoolsSearchHeader
            values={state}
            onOrderingChange={handleOrderingChange}
          />
          <TalentPoolsSearchResults
            values={state}
            onPageChange={handlePageChange}
          />
        </div>
      </styles.Container>
      <Route path={`${match.url}/lists`}>
        {({ match: innerMatch }) => (
          <EditTalentPoolModal
            show={Boolean(innerMatch)}
            onClose={() => history.push("/agency/talent_pools")}
          />
        )}
      </Route>
    </div>
  );
};

export default TalentPoolsPage;
