import React, { Component } from "react";
import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import { Button, ButtonToolbar, Col, Jumbotron, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { addNotification as notify } from "reapop";
import moment from "moment";
import { Link } from "react-router-dom";

import { createErrorNotification } from "common/utils/notifications";
import {
  createResourceDetailQuery,
  createResourceListQuery,
  createResourceSelectorConfig,
} from "queries";
import { update as updateJobOffer } from "queries/mutations/job-offers";
import { createCandidateQuery } from "queries/requests/candidates";
import types from "resources/types";
import { JobOffer } from "types";

import Loading from "common/components/Loading";

const JobOfferPaymentDetail = ({ paymentDetail }) => (
  <div className="offer-details">
    <h4>Offer Details</h4>
    <dl>
      <dt>Rate</dt>
      <dd>{paymentDetail.rateDisplay}</dd>
      <dt>Employment classification</dt>
      <dd>{paymentDetail.serviceDisplay}</dd>
      <dt>Payment type</dt>
      <dd>{paymentDetail.paymentTypeDisplay}</dd>
    </dl>
  </div>
);

const JobOfferMessage = ({ offer, paymentDetail, onboarding_requirements }) => {
  const has_permission = onboarding_requirements.permissions.accept_1099_jobs;
  const steps_needed =
    onboarding_requirements.steps_needed_to_meet_permissions.accept_1099_jobs;
  if (!has_permission) {
    return (
      <div>
        <p>
          However, you cannot accept this offer yet because you are missing the
          following requirements:
        </p>
        <ul>
          {steps_needed.map(step => {
            let url = `/contractor/onboarding/${step.name
              .toLowerCase()
              .replace(/_/g, "-")}`;
            if (step.name == "direct_deposit_information") {
              url = `/contractor/settings/direct-deposit`;
            }
            return (
              <li key={step.label}>
                <Link to={url}>{step.label}</Link>
              </li>
            );
          })}
        </ul>
        <p>
          Please return to this page to accept or decline the offer after
          completing these requirements.
        </p>
      </div>
    );
  }

  return (
    <Row>
      <Col sm={8} md={7}>
        <p>
          {`You have been offered a position for this job. This offer will expire
                        on ${moment(offer.expiration_date, "YYYY-MM-DD").format(
                          "ll",
                        )}. Review the
                        offer details and accept or reject the offer. If you have any questions,
                        message the hiring agent.`}
        </p>
      </Col>
      <Col sm={4} mdOffset={1}>
        <JobOfferPaymentDetail paymentDetail={paymentDetail} />
      </Col>
    </Row>
  );
};

const JobOfferButtons = ({
  onboarding_requirements,
  handleAcceptOffer,
  handleDeclineOffer,
}) =>
  onboarding_requirements.permissions.accept_1099_jobs ? (
    <div className="action-buttons">
      <ButtonToolbar>
        <Button bsStyle="success" onClick={handleAcceptOffer}>
          Accept
        </Button>
        <Button bsStyle="danger" onClick={handleDeclineOffer}>
          Decline
        </Button>
      </ButtonToolbar>
    </div>
  ) : null;

class JobOfferNotification extends Component {
  handleAcceptOffer = () => {
    const status = JobOffer.STATUS_TYPES.ACCEPTED;
    this.updateJobOfferStatus({ status });
  };

  handleDeclineOffer = () => {
    const status = JobOffer.STATUS_TYPES.REJECTED;
    this.updateJobOfferStatus({ status });
  };

  async updateJobOfferStatus(data) {
    const {
      offer: { candidate, uuid: offerId },
      notify,
      refreshCandidate,
      updateOffer,
    } = this.props;

    const updatedOffer = await updateOffer(offerId, data);
    const { status, body } = updatedOffer;
    if (!(status >= 200 && status < 300)) {
      const message = body.non_field_errors[0];
      const errorMessage = message ? { message } : null;
      notify(createErrorNotification(errorMessage));
    }

    return refreshCandidate(candidate);
  }

  render() {
    const {
      offer,
      [types.PAYMENT_DETAILS]: {
        data: paymentDetailsData,
        query: { data: paymentDetailsQueryData },
      },
      [types.ONBOARDING_REQUIREMENTS]: { data: onboardingRequirementsData },
    } = this.props;

    const paymentDetail = paymentDetailsData[paymentDetailsQueryData[0]];
    const onboardingRequirements = Object.values(onboardingRequirementsData)[0];

    if (!onboardingRequirements || !paymentDetail) {
      return <Loading />;
    }

    return (
      <div className="job-offer-notification">
        <Jumbotron>
          <h3 className="title">You have been offered this job!</h3>
          <JobOfferMessage
            offer={offer}
            onboarding_requirements={onboardingRequirements}
            paymentDetail={paymentDetail}
          />
          <JobOfferButtons
            onboarding_requirements={onboardingRequirements}
            handleAcceptOffer={this.handleAcceptOffer}
            handleDeclineOffer={this.handleDeclineOffer}
          />
        </Jumbotron>
      </div>
    );
  }
}

const candidateQuery = candidateId =>
  createCandidateQuery({ force: true }, candidateId);

const onboardingRequirementsQuery = () =>
  createResourceDetailQuery(types.ONBOARDING_REQUIREMENTS, {
    url: `/api/v2/onboarding_requirements/`,
  });

const paymentDetailQuery = props =>
  createResourceListQuery(types.PAYMENT_DETAILS, {
    url: `/api/v2/payment_details/?job=${props.job.uuid}`,
  });

const mapPropsToConfig = props => [
  onboardingRequirementsQuery(props),
  paymentDetailQuery(props),
];

const paymentDetailsSelector = createResourceSelectorConfig(
  types.PAYMENT_DETAILS,
  paymentDetailQuery,
);
const onboardingRequirementsSelector = createResourceSelectorConfig(
  types.ONBOARDING_REQUIREMENTS,
  onboardingRequirementsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...paymentDetailsSelector,
  ...onboardingRequirementsSelector,
});

export default compose(
  connect(
    mapStateToProps,
    {
      notify,
      refreshCandidate: candidateId =>
        requestAsync(candidateQuery(candidateId)),
      updateOffer: (offerId, data) =>
        mutateAsync(updateJobOffer(offerId, data)),
    },
  ),
  connectRequest(mapPropsToConfig),
)(JobOfferNotification);
