import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";

import Action from "./blocks";
import values from "./values";

export default class ActionButton extends Component {
  render() {
    const { label, url } = values[this.props.currentStepName];
    return (
      <Action>
        <LinkContainer to={url}>
          <Action.Button>{label}</Action.Button>
        </LinkContainer>
      </Action>
    );
  }
}
