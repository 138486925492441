import React from "react";

import {
  CloseButton,
  CloseButtonIcon,
  CloseButtonText,
  StyledLink,
  DividerTop,
  ThreadHeaderWrapper,
  ThreadHeaderGrid,
  NewMsgButtonWrapper,
  NewMsgButton,
} from "../blocks";

const HeaderSubject = ({ job, onGetBaseRoute, subject, windowSize }) => {
  let s = subject;
  const isMobile = windowSize.width < 992;
  if (isMobile && s && s.length > 50) s = s.slice(0, 50) + "...";
  return (
    <h6>
      {job ? (
        <StyledLink to={`/${onGetBaseRoute()}/jobs/${job}`}>{s}</StyledLink>
      ) : (
        s
      )}
    </h6>
  );
};

const HeaderInterlocutor = ({ authUser, otherUser }) =>
  otherUser && (
    <strong>
      <p>
        {authUser && authUser.hiring_agent_id && otherUser.contractor_id ? (
          <StyledLink to={`/agency/attorneys/${otherUser.contractor_id}/`}>
            {`${otherUser.first_name} ${otherUser.last_name}`}
          </StyledLink>
        ) : (
          `${otherUser.first_name} ${otherUser.last_name}`
        )}
      </p>
    </strong>
  );

const HeaderScroller = ({ onClickNewMsgButton, showScroller }) =>
  showScroller && (
    <NewMsgButtonWrapper>
      <NewMsgButton onClick={onClickNewMsgButton}>
        <i className="fas fa-long-arrow-down" /> New Message
      </NewMsgButton>
    </NewMsgButtonWrapper>
  );

const ThreadHeader = ({
  authUser,
  otherUser,
  onClickNewMsgButton,
  onCloseThread,
  onGetBaseRoute,
  showScroller,
  thread: { job, subject },
  windowSize,
}) => (
  <ThreadHeaderWrapper>
    <ThreadHeaderGrid>
      <div>
        <HeaderSubject
          job={job}
          onGetBaseRoute={onGetBaseRoute}
          subject={subject}
          windowSize={windowSize}
        />
        <HeaderInterlocutor authUser={authUser} otherUser={otherUser} />
      </div>
      <div>
        <CloseButton onClick={onCloseThread}>
          <CloseButtonIcon />
          <br
            style={{
              display: "block",
              content: '""',
              marginTop: "-10px",
            }}
          />
          <CloseButtonText>CLOSE</CloseButtonText>
        </CloseButton>
      </div>
    </ThreadHeaderGrid>
    <DividerTop />
    <HeaderScroller
      onClickNewMsgButton={onClickNewMsgButton}
      showScroller={showScroller}
    />
  </ThreadHeaderWrapper>
);

export default ThreadHeader;
