import moment from "moment";

export const validateFirstName = value =>
  value ? undefined : "Please enter a valid first name.";

export const validateLastName = value =>
  value ? undefined : "Please enter a valid last name.";

export const validatePhoneNumber = value => {
  // Field is valid for exactly 10 numeric characters
  // All other characters are accepted but ignored
  const onlyNumReg = /\d/g;
  const errorMsg = "Please enter a valid 10-digit phone number.";
  if (!value) return errorMsg;
  const matched = value.match(onlyNumReg);
  if (!matched || matched.length !== 10) return errorMsg;
};

export const validateEmailAddress = value => {
  // Allow only single use of '@', somewhere before use of '.'
  // Allow any number of `.` characters. No spaces allowed.
  // Otherwise allow any other characters.
  const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailReg.test(value)) return "Please enter a valid email address.";
};

export const validateAddress = value =>
  value ? undefined : "Please enter a valid address.";

export const validateCity = value =>
  value ? undefined : "Please enter a valid city.";

export const validateState = value => (value ? undefined : "State required.");

export const validatePostalCode = value => {
  // 5 numbers or
  // 5 numbers and then 4 numbers, separated by a dash
  const zipReg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  if (!zipReg.test(value)) return "Please enter a valid postal code.";
};

export const validateDOB = value => {
  // User must be at least 13 years old
  const errorMsg = "Please enter a valid date of birth.";
  if (value) {
    const ageInYears = moment().diff(value, "years");
    if (ageInYears < 13) return errorMsg;
  } else {
    return errorMsg;
  }
};

export const validateSSN = value => {
  // Exactly 9 numbers and optional dashes in correct order
  // No spaces, no other characters
  const ssnReg = /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/;
  if (!ssnReg.test(value))
    return "Please enter a valid Social Security number in ***-**-**** or ********* format.";
};

export const validateBankRouting = value => {
  // Only exactly 9 numbers allowed, no other characters
  const numReg = /^[0-9]{9}$/;
  if (!numReg.test(value)) return "Please enter a valid routing number.";
};

export const validateBankAccount = value => {
  // Only numbers allowed
  const numReg = /^\d+$/;
  if (!numReg.test(value)) return "Please enter a valid account number.";
};

export const validateACHAgreement = value =>
  value ? undefined : "This field is required to continue.";
