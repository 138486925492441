import * as fixedRateActions from "admin/actions/invoicing/fixedRates";

const initialFixedRatesState = {
  lastUpdated: null,
  isFetching: false,
  items: {},
};

export default function expenses(state = initialFixedRatesState, action) {
  switch (action.type) {
    case fixedRateActions.REQUEST_FIXED_RATES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case fixedRateActions.RECEIVE_FIXED_RATES:
      return Object.assign({}, state, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        items: action.items,
      });
    default:
      return state;
  }
}
