import React, { Component } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

import Experience from "./blocks";

const ExpertiseAreaPopover = elements => (
  <Popover id="popover-trigger-hover-focus" title="Areas of Expertise">
    {elements.map(el => <p key={el}> {el}</p>)}
  </Popover>
);

export default class PracticeAreasList extends Component {
  practiceAreasByGroup(practiceAreas) {
    const result = practiceAreas.reduce((acc, tag) => {
      if (acc[tag.group.name]) {
        acc[tag.group.name].practiceAreas.push(tag.name);
      } else {
        acc[tag.group.name] = { practiceAreas: [tag.name] };
      }
      return acc;
    }, {});
    return result;
  }

  render() {
    const { attorneyUuid, practiceAreas } = this.props;
    const practiceAreasByGroup = this.practiceAreasByGroup(practiceAreas);
    const entries = Object.entries(practiceAreasByGroup);

    return practiceAreas.length <= 0 ? null : (
      <div>
        {entries.map((practiceArea, i) => (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={ExpertiseAreaPopover(practiceArea[1].practiceAreas)}
            key={`${attorneyUuid}-${practiceArea[0]}-${i}`}
          >
            <Experience.PracticeAreasItem>
              {practiceArea[0]}
            </Experience.PracticeAreasItem>
          </OverlayTrigger>
        ))}
      </div>
    );
  }
}
