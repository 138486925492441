import React from "react";
import { Label } from "react-bootstrap";

export const JobLocationDisplay = ({ locations }) =>
  locations.map(location => (
    <span key={`job-location-${location.uuid}`}>
      <Label
        itemProp="jobLocation"
        itemScope
        itemType="http://schema.org/Place"
      >
        <span
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          <span itemProp="addressLocality">{location.city}</span>,{" "}
          <span itemProp="addressRegion">{location.state}</span>
        </span>
      </Label>{" "}
    </span>
  ));

export default JobLocationDisplay;
