import React from "react";

import { settingsRoutes } from "contractor/routes";
import { NavMenu } from "contractor/components/layout";

const SettingsNavMenu = props => (
  <NavMenu
    baseRoute="/contractor/settings/"
    routes={settingsRoutes}
    {...props}
  />
);
export default SettingsNavMenu;
