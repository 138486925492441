import React, { Component } from "react";
import { connect, compose, connectResource } from "resources/components/utils";
import { Button, ButtonToolbar, Table } from "react-bootstrap";

import {
  getReviewRequests,
  getActiveReviewRequests,
} from "contractor/selectors";
import { reviewRequestsRequest } from "resources/requests";
import Loading from "common/components/Loading";

class ListReferences extends Component {
  handleDeleteClick = requestId => () => this.props.onDeleteClick(requestId);

  handleResendClick = requestId => () => this.props.onResendClick(requestId);

  render() {
    const {
      reviewRequests: { isLoading },
      activeReviewRequests: requests,
    } = this.props;

    const ResendButton = ({ request }) => (
      <Button
        style={{ float: "none" }}
        bsStyle="primary"
        bsSize="xs"
        onClick={this.handleResendClick(request.uuid)}
      >
        Resend
      </Button>
    );

    const DeleteButton = ({ request }) => (
      <Button
        style={{ float: "none" }}
        bsStyle="danger"
        bsSize="xs"
        onClick={this.handleDeleteClick(request.uuid)}
      >
        Delete
      </Button>
    );

    return (
      <div className="list-references">
        <Table striped responsive>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {requests.map(request => (
              <tr key={request.uuid}>
                <td>{request.reviewer_first_name}</td>
                <td>{request.reviewer_last_name}</td>
                <td>{request.reviewer_email}</td>
                <td className="text-capitalize">{request.status}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <ButtonToolbar>
                    {request.status !== "deleted" && (
                      <DeleteButton request={request} />
                    )}
                    {request.status === "pending" && (
                      <ResendButton request={request} />
                    )}
                  </ButtonToolbar>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {isLoading ? (
          <Loading />
        ) : (
          Object.values(requests).length === 0 && (
            <p className="text-center">
              You don't have any reference requests on file. Use the form above
              to send a reference request.
            </p>
          )
        )}
      </div>
    );
  }
}

export default (ListReferences = compose(
  connect(state => ({
    activeReviewRequests: getActiveReviewRequests()(state),
    reviewRequests: getReviewRequests(state),
  })),
  connectResource(reviewRequestsRequest),
)(ListReferences));
