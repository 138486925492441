import React from "react";
import { Alert } from "react-bootstrap";

import { getMondayOfWeek, getSundayOfWeek } from "common/utils";
import { PaymentDetails } from "types";

export const CannotAddEntriesMsg = ({ reason }) => (
  <Alert bsStyle="danger">
    {`You cannot add entries for this job${
      reason ? ` because ${reason}` : ""
    }.`}
  </Alert>
);

export const TimeTrackerInfoMsg = () => {
  const thisMondayDate = getMondayOfWeek().format("MM/DD/YYYY");
  const thisSundayDate = getSundayOfWeek().format("MM/DD/YYYY");
  return (
    <Alert>
      Time for this week must be entered between{" "}
      <strong>{`12:00 AM PST Monday,
            ${thisMondayDate}`}</strong>{" "}
      and <strong>{`11:59 PM PST Sunday, ${thisSundayDate}`}</strong>. We cannot
      pay you on time or guarantee that you will be paid for time entered after
      this deadline.
    </Alert>
  );
};

export const BillableEventInfoMsg = ({ paymentDetail }) => {
  const isFixedWeek =
    paymentDetail.payment_type === PaymentDetails.PAYMENT_TYPES.FIXED_WEEK;
  const isFixedMonth =
    paymentDetail.payment_type === PaymentDetails.PAYMENT_TYPES.FIXED_MONTH;
  const isFixedProject =
    paymentDetail.payment_type === PaymentDetails.PAYMENT_TYPES.FIXED_PROJECT;

  let message = `Before adding a billable event, please ensure all work is finished and both you and
        the hiring agency agree on the completeness of any deliverables or other work product.`;
  if (isFixedWeek || isFixedMonth) {
    message = `This job has a ${
      paymentDetail.frequency
    } billing cycle. You are required to add a single billable
        event each ${
          paymentDetail.period
        } of the billing cycle to certify that you have completed all work associated
        with this job for that time period. ${message}`;
  }
  if (isFixedProject) {
    message = `This job bills once, when the project is finished. You are required to add a single
        billable event when you have completed all work associated with this project. ${message}`;
  }

  return <Alert style={{ margin: "2rem 0" }}>{message}</Alert>;
};
