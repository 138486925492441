import React, { Component } from "react";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import AdditionalDetailsForm from "./AdditionalDetailsForm";
import { RemoteSubmitButton } from "../../NextButton";
import { validateRequiredFields } from "common/utils/forms";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import Label from "common/blocks/Label";
import { getActionIsRequired, getCustomErrorMsg } from "../../utils";
import { compose, connect, mutateAsync } from "queries/utils";
import { handleSubmitError, redirectToNextStepIfNeeded } from "../../utils";
import { updateOwnContractorQuery } from "queries/mutations/contractors";
import { MainCopy } from "./copy";

const stepName = "additional_details";
const requiredFields = ["summary", "summary_title"];

class AdditionalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { nextButtonLoading: false };
  }

  handleSubmitAdditionalDetailsForm = async values => {
    await this.setState({ nextButtonLoading: true });

    const {
      props: {
        ownContractor: { uuid },
        updateOwnContractor,
      },
      handleNextClick,
      stopButtonLoading,
    } = this;
    const result = await updateOwnContractor(values, uuid);

    stopButtonLoading();
    handleSubmitError(result);
    handleNextClick();
  };

  getInitialValues = ownContractor => {
    let initialValues = {};
    if (ownContractor) {
      const { summary, summary_title } = ownContractor;
      initialValues = {
        ...(summary && { summary }),
        ...(summary_title && { summary_title }),
      };
    }
    return initialValues;
  };

  validate = values => validateRequiredFields(requiredFields, values);

  handleNextClick = () => {
    redirectToNextStepIfNeeded(
      stepName,
      this.props.refreshReqs,
      this.stopButtonLoading,
    );
  };

  stopButtonLoading = () => this.setState({ nextButtonLoading: false });

  render() {
    const {
      state: { nextButtonLoading },
      props: {
        ownContractor,
        reqs: { steps },
      },
      getInitialValues,
      handleSubmitAdditionalDetailsForm,
      validate,
    } = this;
    const actionIsRequired = getActionIsRequired(stepName, steps);
    const customErrorMsg = getCustomErrorMsg(stepName, steps);
    const initialValues = getInitialValues(ownContractor);

    return (
      <Onboard.Page>
        <Onboard.Container>
          <DesktopOnly>
            <Onboard.ContainerHeader>
              <div>
                <h1>Additional Details</h1>
              </div>
              <div>
                {actionIsRequired && (
                  <Label color="danger">ACTION REQUIRED</Label>
                )}
              </div>
            </Onboard.ContainerHeader>
          </DesktopOnly>
          <MobileOnly>
            <h1>Additional Details</h1>
            {actionIsRequired && (
              <div>
                <Label color="danger">ACTION REQUIRED</Label>
                <br />
                <br />
              </div>
            )}
          </MobileOnly>
          {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
          <MainCopy />
          <AdditionalDetailsForm
            initialValues={initialValues}
            onSubmit={handleSubmitAdditionalDetailsForm}
            validate={validate}
          />
        </Onboard.Container>
        <RemoteSubmitButton
          formName="onboardingAdditionalDetailsForm"
          isLoading={nextButtonLoading}
        />
      </Onboard.Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateOwnContractor: (data, uuid) =>
    dispatch(mutateAsync(updateOwnContractorQuery(data, uuid))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(AdditionalDetails);
