// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";

type Props = {
  dot: boolean,
  open: boolean,
};

const NavTrigger: ComponentType<Props> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
  line-height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
  margin-right: 20px;
  cursor: pointer;

  & > span:first-child {
    visibility: ${({ dot }) => (dot ? "visible" : "hidden")};
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.secondary_red()};
    font-weight: 700;
    line-height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};
  }

  & > span:nth-child(2) {
    color: ${({ theme }) => theme.colors.primary_black()};
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    line-height: ${({ theme }) => theme.sizes.top_nav_header_desktop_height};

    &::after {
      display: block;
      content: attr(name);
      font-weight: 900;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &:hover > span:nth-child(2) {
    color: ${({ theme }) => theme.colors.primary_black()};
    text-decoration: none;
    font-weight: 900;

    &::after {
      display: block;
      content: attr(name);
      font-weight: 900;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  ${({ open }) =>
    open &&
    css`
      &&& {
        & > span:nth-child(2) {
          position: relative;
          color: ${({ theme }) => theme.colors.primary_black()};
          text-decoration: none;
          font-weight: 900;

          span {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 9px solid #cce3f5;
          }
        }
      }
    `};
`;

export default NavTrigger;
