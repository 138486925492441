// @flow strict
import styled from "styled-components";

const Guide = styled.div`
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
`;

export default Guide;
