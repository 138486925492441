// @flow strict
import React from "react";
import { Link } from "react-router-dom";

import { compose, connect, connectRequest } from "queries/utils";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import Candidate from "types/Candidate";

import CandidateActions from "hiringagent-dashboard/components/candidates/CandidateActions";
import CandidateNegotiatingAlert from "hiringagent-dashboard/components/candidates/CandidateNegotiatingAlert";
import Header from "./blocks";

type Props = {
  candidate: Candidate,
  contractor: Contractor,
  job: Job,
};

const CandidateHeader = (props: Props) => {
  const {
    candidate,
    contractor,
    job,
    [types.JOB_OFFERS]: {
      data: jobOffersData,
      query: { data: jobOffersQueryData },
    },
  } = props;

  const jobOffers = jobOffersQueryData.map(offerId => jobOffersData[offerId]);
  const isCandidateNegotiating =
    candidate.status === Candidate.STATUS_TYPES.NEGOTIATING;
  const showNegotiatingMsg = isCandidateNegotiating && jobOffers.length > 0;

  return (
    <div>
      {showNegotiatingMsg && (
        <CandidateNegotiatingAlert
          contractor={contractor}
          jobOffers={jobOffers}
        />
      )}
      <Header.Title>
        Candidate for{" "}
        <Link to={`/agency/jobs/${job.uuid}`}>{job.formattedTitle}</Link>
      </Header.Title>
      <CandidateActions
        candidate={candidate}
        contractor={contractor}
        job={job}
      />
    </div>
  );
};

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOB_OFFERS, {
    url: `/api/v2/job_offers/?candidate=${props.candidate.uuid}`,
  });

const jobOffersSelector = createResourceSelector(
  types.JOB_OFFERS,
  mapPropsToConfig,
);

export default compose(
  connect(jobOffersSelector),
  connectRequest(mapPropsToConfig),
)(CandidateHeader);
