import React from "react";

import Onboard from "../../blocks";
import { FormFieldToolTip } from "common/components/forms";

export const MainCopy = () => (
  <p>
    We require at least two (2) work experience entries. If you&apos;ve had
    multiple positions at the same company, feel free to add one entry per
    position.
  </p>
);

export const NoEntriesCopy = () => (
  <Onboard.TablePlaceholder>
    You don&apos;t have any work experience on file. Click &quot;Add Work
    Experience&quot; to add.
  </Onboard.TablePlaceholder>
);

export const FirmTypeToolTip = () => {
  const hint = `If this work experience is law-related,
                   select the firm type that best characterizes the company.`;
  return <FormFieldToolTip label="Firm Type" content={hint} placement="top" />;
};

export const PendingVerificationCopy = () => (
  <p>
    We&apos;re currently reviewing your work experience entries. You&apos;ve
    done all you can for this stage.
  </p>
);

export const PositionTypeToolTip = () => {
  const hint = `If this work experience is law-related,
                   select the position type that best characterizes your role.`;
  return (
    <FormFieldToolTip label="Position Type" content={hint} placement="top" />
  );
};
