import createReducer from "common/reducers/utils";

const submitBarInformationHandler = shouldPayloadBeInResults => ({
  "RECEIVE/submitBarInformation": (state, action) => {
    if (action.error) return state;
    let newResults = [...state.data.results];
    let newCount = state.data.count;
    const payloadIndex = state.data.results.findIndex(
      barInfo => barInfo.uuid === action.payload.uuid,
    );

    const payloadInResults = payloadIndex >= 0;
    const payloadBelongsInResults = shouldPayloadBeInResults(
      action.payload.verified,
    );

    const shouldUpdate = payloadInResults && payloadBelongsInResults;
    const shouldRemove = payloadInResults && !payloadBelongsInResults;
    const shouldAdd = !payloadInResults && payloadBelongsInResults;

    if (shouldRemove) {
      newResults.splice(payloadIndex, 1);
      newCount -= 1;
    } else if (shouldAdd) {
      newResults = [action.payload, ...newResults];
      newCount += 1;
    } else if (shouldUpdate) {
      newResults[payloadIndex] = action.payload;
    }

    return {
      ...state,
      data: {
        ...state.data,
        count: newCount,
        results: newResults,
      },
    };
  },
});

export const pendingBarInformations = createReducer(
  "pendingBarInformations",
  null,
  submitBarInformationHandler(verified => verified === 0),
);

export const recentBarInformations = createReducer(
  "recentBarInformations",
  null,
  submitBarInformationHandler(verified => verified !== 0),
);
