import React, { Component } from "react";
import { connect } from "react-redux";
import { mutateAsync } from "redux-query";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { addNotification as notify } from "reapop";

import { formatDate } from "common/utils";
import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import { update } from "queries/mutations/jobs";
import { Job as JobClass } from "types";

export const getNextStatusOption = currentStatus => {
  const openOrFilled = [1, 2];

  if (JobClass.STATUS_OPTIONS[currentStatus].value === JobClass.STATUS.DRAFT) {
    return JobClass.STATUS_OPTIONS[JobClass.STATUS.CLOSED];
  }
  if (openOrFilled.includes(currentStatus)) {
    return JobClass.STATUS_OPTIONS[currentStatus + 1];
  }
  return false;
};

export class JobActionsDropdown extends Component {
  getUpdateStatusData() {
    const nextStatusOption = getNextStatusOption(this.props.job.status);
    const shouldAdvanceToFilled =
      nextStatusOption.value === JobClass.STATUS.FILLED;
    const shouldAdvanceToClosed =
      nextStatusOption.value === JobClass.STATUS.CLOSED;

    let data;
    if (shouldAdvanceToFilled) data = { filled_date: formatDate() };
    if (shouldAdvanceToClosed) data = { closed_date: formatDate() };
    return data;
  }

  handleUpdateJobStatus = () => {
    const { job, notify, updateJobStatus } = this.props;
    const data = this.getUpdateStatusData();

    if (data) {
      updateJobStatus(data, job.uuid).then(({ status }) => {
        if (status >= 200 && status < 300) {
          notify(
            createSuccessNotification({
              message: `Job has been marked as ${getNextStatusOption(
                job.status,
              ).label.toLowerCase()}.`,
            }),
          );
        } else {
          notify(createErrorNotification());
        }
      });
    } else {
      notify(createErrorNotification());
    }
  };

  render() {
    const {
      job: { status, uuid: id },
    } = this.props;
    const nextStatusOption = getNextStatusOption(status);

    return (
      <DropdownButton
        title="Actions"
        bsStyle="primary"
        id="invoice-dropdown-mobile"
        pullRight
      >
        {nextStatusOption && (
          <MenuItem onSelect={this.handleUpdateJobStatus}>
            Mark as {nextStatusOption.label}
          </MenuItem>
        )}
        <LinkContainer to={`/agency/jobs/${id}/pending-entries/`}>
          <MenuItem>View Pending Entries</MenuItem>
        </LinkContainer>
        <LinkContainer to={`/agency/jobs/create/${id}/recommended_candidates/`}>
          <MenuItem>View Recommended Candidates</MenuItem>
        </LinkContainer>
      </DropdownButton>
    );
  }
}

export default connect(
  null,
  {
    notify,
    updateJobStatus: (data, id) => mutateAsync(update(data, id)),
  },
)(JobActionsDropdown);
