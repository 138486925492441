import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { SupportLink } from "common/components";

export default class NotPermitted extends Component {
  static defaultProps = {
    to: null,
  };

  static propTypes = {
    to: PropTypes.string,
  };

  render() {
    return (
      <Row>
        <Helmet>
          <title>Not Permitted</title>
        </Helmet>
        <Col md={8}>
          <h2>
            <i className="far fa-exclamation-triangle" /> Not Permitted
          </h2>
          <p>
            Sorry, you are not authorized to access this page.
            <br />
            If you believe you have received this message in error, please
            contact <SupportLink />.
          </p>
          <p style={{ marginTop: "1.6em" }}>
            <Link to="/" className="btn btn-info">
              <i className="far fa-arrow-left" /> Back to Dashboard
            </Link>
          </p>
        </Col>
      </Row>
    );
  }
}
