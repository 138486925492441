import React from "react";
import { Modal } from "react-bootstrap";
import { DirectDepositForm } from "contractor/forms/settings/DirectDepositForm/index.js";

const DirectDepositModal = ({
  hasBankData,
  bankData,
  modalOpen,
  onHideModal,
  onHideDynamicForm,
}) => (
  <Modal backdrop="static" show={modalOpen} onHide={onHideModal}>
    <Modal.Header closeButton>
      <h2>{`${hasBankData ? "Update" : "Add"} Bank Account`}</h2>
    </Modal.Header>
    <Modal.Body>
      <DirectDepositForm
        bankData={bankData}
        hasBankData={hasBankData}
        onHide={onHideModal}
        onHideDynamicForm={onHideDynamicForm}
      />
    </Modal.Body>
  </Modal>
);

export default DirectDepositModal;
