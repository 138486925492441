import styled from "styled-components";

const AlternateLink = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1px;
`;

export default AlternateLink;
