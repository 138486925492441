import actions from "hiringagent-dashboard/actions/messages";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  messages: {},
  messageThreads: {},
  messageRecipientIds: [],
  isLoading: false,
  lastUpdated: null,
  allLoaded: false,
};

const messages = handleActions(
  {
    [actions.REQUEST_MESSAGES]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_MESSAGES]: (state, action) => ({
      ...state,
      messages: {
        ...state.messages,
        ...listToObject(action.payload.results, "uuid"),
      },
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
      allLoaded: action.payload.allFetched,
    }),
    "RECEIVE/resource": (state, action) => {
      if (action.meta.options.type === "messages") {
        return {
          messages: {
            ...state.messages,
            ...listToObject(action.payload.results, "uuid"),
          },
          lastUpdated: action.meta.receivedAt,
          isLoading: false,
        };
      }
      return state;
    },
    [actions.RECEIVE_TOGGLE_MESSAGE_READ_STATUS]: (state, action) => {
      const threadId = action.payload.threadId;
      return {
        ...state,
        messages: {
          ...state.messages,
          [threadId]: {
            ...state.messages[threadId],
            unread: !state.messages[threadId].unread,
          },
        },
        lastUpdated: action.meta.receivedAt,
        isLoading: false,
      };
    },
    [actions.REQUEST_MESSAGE_THREAD]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_MESSAGE_THREAD]: (state, action) => {
      const threadId = action.payload.uuid;
      return {
        ...state,
        messageThreads: {
          ...state.messageThreads,
          [threadId]: action.payload,
        },
        lastUpdated: action.meta.receivedAt,
        isLoading: false,
      };
    },
    [actions.REQUEST_SUBMIT_NEW_MESSAGE]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_SUBMIT_NEW_MESSAGE]: state => ({
      ...state,
      // Allow to fetch requestMessages when a new message is created
      allLoaded: false,
      messageRecipientIds: [],
      isLoading: false,
    }),
    [actions.ADD_MESSAGE_RECIPIENT_IDS]: (state, action) => ({
      ...state,
      messageRecipientIds: [...action.payload],
    }),
  },
  initialState,
);
export default messages;
