import { createAction } from "redux-actions";
import { metaGenerator } from "admin/utils";
import keyMirror from "keymirror";
import { oldApi } from "common/api";

const actions = keyMirror({
  REQUEST_LANGUAGE_AUDIT: null,
  RECEIVE_LANGUAGE_AUDIT: null,
  ADD_LANGUAGE_PROFICIENCY: null,
  RECEIVE_LANGUAGE_PROFICIENCY: null,
});

export const requestLanguageAudit = () => dispatch => {
  dispatch(createAction(actions.REQUEST_LANGUAGE_AUDIT)());
  return oldApi
    .get("/api/v2/language_audits/?limit=170")
    .then(({ json, response }) => {
      const payload = response.ok
        ? json.results
        : new Error("Error retrieving language audit.");
      return dispatch(createAction(actions.RECEIVE_LANGUAGE_AUDIT)(payload));
    });
};

export const addLanguageProficiency = (
  attorney_uuid,
  language_uuid,
) => dispatch => {
  dispatch(createAction(actions.ADD_LANGUAGE_PROFICIENCY)());
  const data = {
    attorney_uuid,
    language_uuid,
  };
  return oldApi
    .post(
      `/api/v2/language_audits/${language_uuid}/add_language_proficiency/`,
      data,
    )
    .then(({ response }) => {
      const payload = response.ok
        ? data
        : new Error("Error adding language proficiency.");
      return dispatch(
        createAction(
          actions.RECEIVE_LANGUAGE_PROFICIENCY,
          p => p,
          metaGenerator,
        )(payload),
      );
    });
};

export default actions;
