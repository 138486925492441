import styled from "styled-components";

const Container = styled.div`
  margin: 0px 0 20px 0;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid #ccc;
`;

export default Container;
