import styled from "styled-components";

export const Signup = styled.div`
  position: relative;
`;

export const Title = styled.h1.attrs({ className: "text-capitalize" })`
  font-weight: bold;
  text-align: center;
`;

export const Subtitle = styled.h2`
  line-height: 1.25;
  text-align: center;
  ${({ theme }) => theme.media.desktop`
    margin-bottom: 60px;
  `};
`;

export const FormHeader = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

export const FormContainer = styled.div`
  max-width: 500px;
  margin: 30px auto;
  padding: 15px;
  border: 2px solid ${({ theme }) => theme.colors.primary_black20()};
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.primary_black10()};

  /* Allow this to be positioned in front of the background icons */
  position: relative;
  z-index: 1;
`;

export const Footer = styled.p`
  margin-top: 60px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary_black60()};
`;

const Icon = styled.i.attrs({
  "aria-hidden": true,
})`
  font-size: 15em;
  position: absolute;
  z-index: 0;
`;

export const BackgroundIconLeft = styled(Icon).attrs({
  className: "far fa-chart-network fa-rotate-270",
})`
  top: 30%;
  left: 0;
  color: ${({ theme }) => theme.colors.pale_turquoise()};
`;

export const BackgroundIconRight = styled(Icon).attrs({
  className: "far fa-chart-network fa-rotate-90",
})`
  top: 60%;
  right: 0;
  color: ${({ theme }) => theme.colors.light_orange("0.5")};
`;
