// @flow strict
import { type ComponentType } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

type Props = {
  open?: boolean,
};

const NavLink: ComponentType<Props> = styled(Link)`
  &&&& {
    color: ${({ theme }) => theme.colors.primary_black()};
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.primary_black()};
      text-decoration: none;
      font-weight: 900;
    }

    ${({ open }) =>
      open &&
      css`
        && {
          color: ${({ theme }) => theme.colors.navlink()};
          text-decoration: none;
          font-weight: 900;
        }
      `};
  }
`;

export default NavLink;
