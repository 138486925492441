import styled from "styled-components";
import { Button } from "react-bootstrap";

export const FAQText = styled.p`
  margin-bottom: 25px;
`;

export const FAQTrigger = styled(Button).attrs({ bsStyle: "link" })`
  &&&& {
    ${({ theme }) => theme.media.desktop`
      position: absolute;
      right: 0;
    `};
  }
`;

const FAQ = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  margin: -10px 0 25px 0;

  ${({ theme }) => theme.media.mobile`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};
`;

export default FAQ;
