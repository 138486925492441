import actions from "admin/actions/language-audit";
import { handleActions } from "redux-actions";

const initialState = {
  isLoading: null,
  languageAudits: [],
  lastUpdated: null,
};

const languageAudits = handleActions(
  {
    [actions.REQUEST_LANGUAGE_AUDIT]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_LANGUAGE_AUDIT]: (state, action) => ({
      ...state,
      isLoading: false,
      languageAudits: action.payload,
    }),
    [actions.RECEIVE_LANGUAGE_PROFICIENCY]: (state, action) => {
      const newState = { ...state, lastUpdated: action.meta.receivedAt };
      const language = newState.languageAudits.filter(
        language => language.uuid === action.payload.language_uuid,
      )[0];
      const attorney = language.attorneys.filter(
        attorney => attorney.uuid === action.payload.attorney_uuid,
      )[0];
      attorney.submitted = true;
      return newState;
    },
  },
  initialState,
);

export default languageAudits;
