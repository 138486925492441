import React, { Fragment } from "react";

const JobDescriptionSection = ({ jobListing }) =>
  jobListing.description && (
    <Fragment>
      <h2 className="main-info-title" style={{ marginBottom: "2rem" }}>
        Description
      </h2>
      <div style={{ marginBottom: "2rem" }}>
        {jobListing.description.split("\n").map((text, i) => (
          <span key={`description-line-${i}`}>
            {text}
            <br />
          </span>
        ))}
      </div>
    </Fragment>
  );

export default JobDescriptionSection;
