import actions from "admin/actions/applications";
import { handleActions } from "redux-actions";

const initialState = {
  applications: [],
  applicationsCount: -1,
  applicationsLoading: false,
  searchParams: {
    startDate: null,
    endDate: null,
    hiringAgency: null,
    job: null,
    jobType: "",
  },
  lastUpdated: null,
};

const applications = handleActions(
  {
    [actions.SET_SEARCH_PARAMETERS]: (state, action) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...action.payload,
      },
      applicationsLoading: true,
    }),
    [actions.RESET_SEARCH_PARAMETERS]: state => ({
      ...state,
      applications: initialState.applications,
      applicationsCount: initialState.applicationsCount,
      searchParams: {
        ...initialState.searchParams,
      },
    }),
    [actions.RECEIVE_APPLICATIONS]: (state, action) => ({
      ...state,
      ...action.payload,
      applicationsLoading: false,
    }),
  },
  initialState,
);
export default applications;
