import React from "react";

export const MainCopy = () => (
  <div>
    <p>
      Let hiring agents know a little bit more about yourself. Optimal summaries
      are 2-4 sentences. Please note that contact information (email, phone
      number, website) or your full name are not allowed here.
    </p>
  </div>
);

export const headlinePlaceholder =
  "Brief Job Title to highlight overall experience (eg. Sr. Transactions Attorney)";

export const summaryPlaceholder =
  "Brief explanation of experience (e.g. Sr. Transactions Attorney with 10+ " +
  "years of experience serving AmLaw firms, significant commercial contracts " +
  "experience (NDAs, SOWs, MSAs, etc.))";
