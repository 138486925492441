import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const publicSearchUrl = "/public/jobs/";
const contractorSearchUrl = "/contractor/jobs/";

const jobNotificationsSettingsUrl = "/contractor/settings/job-notifications/";

const LinkButton = ({ children, searchUrl }) => (
  <LinkContainer to={searchUrl} active={false}>
    <Button bsStyle="primary">{children}</Button>
  </LinkContainer>
);

const BackToJobSearchButton = props => (
  <LinkButton {...props}>Back to Job Search</LinkButton>
);

const UpdateEmailPreferencesButton = () => (
  <LinkButton searchUrl={jobNotificationsSettingsUrl}>
    Update Email Preferences
  </LinkButton>
);

export default class JobNavigationActions extends Component {
  static propTypes = {
    isPublic: PropTypes.bool,
  };

  static defaultProps = {
    isPublic: false,
  };

  render() {
    const { isPublic } = this.props;

    const searchUrl = isPublic ? publicSearchUrl : contractorSearchUrl;

    return (
      <Fragment>
        <ButtonToolbar className="pull-right">
          {!isPublic && <UpdateEmailPreferencesButton />}
          <BackToJobSearchButton searchUrl={searchUrl} />
        </ButtonToolbar>
        <span className="clearfix" />
      </Fragment>
    );
  }
}
