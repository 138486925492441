import React from "react";
import styled, { css } from "styled-components";

const BarBlock = styled.div`
  width: 10px;
  height: 100%;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white()};
  border: 1px solid ${({ theme }) => theme.colors.primary_turquoise()};
  border-radius: 34px;
  box-sizing: border-box;

  ${({ percentage, theme }) =>
    percentage &&
    css`
      width: ${percentage}%;
      background-color: ${theme.colors.pale_turquoise()};
    `};
`;

const Bar = props => <BarBlock {...props} />;

export default Bar;
