import React from "react";

import { SelectField } from "common/components/forms/inputs";
import { connectConnectRequest } from "queries/utils";
import { positionTypesQuery } from "queries/requests";
import { createResourceSelector } from "queries";
import types from "resources/types";

export const contractorTypeChoices = {
  NONE: null,
  ATTORNEYS_ONLY: "attorneys",
  NON_ATTORNEYS_ONLY: "non_attorneys",
  ANY: "any",
};

export const attorneyTypes = [
  contractorTypeChoices.ATTORNEYS_ONLY,
  contractorTypeChoices.ANY,
];

export const nonAttorneyTypes = [
  contractorTypeChoices.NON_ATTORNEYS_ONLY,
  contractorTypeChoices.ANY,
];

/**
 * Needs to know what the value of `allowed_applicants` is, so it must be provided as a prop
 */
const PositionTypeSelectField = props => {
  const {
    allowedApplicants,
    [types.POSITION_TYPES]: {
      data,
      isFinished,
      isPending,
      query: { data: queryData },
    },
  } = props;

  const allowAttorneys = attorneyTypes.includes(allowedApplicants);
  const allowNonAttorneys = nonAttorneyTypes.includes(allowedApplicants);

  const filteredPositionTypes = isFinished
    ? queryData
        .map(id => data[id])
        .filter(
          positionType =>
            (allowAttorneys && !positionType.for_non_attorneys) ||
            (allowNonAttorneys && positionType.for_non_attorneys),
        )
    : [];
  const allowedApplicantsNotSelected =
    !filteredPositionTypes.length &&
    allowedApplicants === contractorTypeChoices.NONE;
  const placeholderText = allowedApplicantsNotSelected
    ? "Select a contractor type above"
    : null;

  return (
    <SelectField
      name="position_type"
      label="Position Type"
      placeholder={isPending ? "Loading..." : placeholderText}
      disabled={isPending}
    >
      {filteredPositionTypes.map(option => (
        <option key={`option-position-type-${option.name}`} value={option.uuid}>
          {option.name}
        </option>
      ))}
    </SelectField>
  );
};

const mapPropsToConfig = positionTypesQuery;
const mapStateToProps = createResourceSelector(
  types.POSITION_TYPES,
  mapPropsToConfig,
);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  PositionTypeSelectField,
);
