// @flow strict
import React from "react";
import { Helmet } from "react-helmet";

import { statesLookup } from "common/constants/states";

type Props = {
  contractor: PublicContractor,
};

const createMetaDescription = contractor => {
  const descriptionObj = {
    "Legal Professional Summary": contractor.summary,
    "Highest Educational Degree": contractor.highest_degree,
    Location: contractor.location
      ? `${contractor.location.city}, ${
          statesLookup[contractor.location.state].label
        }`
      : null,
    "Years Experience": contractor.years_work_experience,
    "Rate per Hour": contractor.rate
      ? `mode: $${contractor.rate.dollar_per_hr_mode}/hr; low: $${
          contractor.rate.dollar_per_hr_low
        }/hr; high: $${contractor.rate.dollar_per_hr_high}/hr`
      : null,
    "Hours Available per Week": contractor.availability
      ? contractor.availability.hours_per_week
      : null,
    "Practice Areas": contractor.practice_areas
      .map(area => area.name)
      .join("; "),
    "Licensed In": contractor.bar_information
      ? contractor.bar_information.map(item => item.state).join("; ")
      : null,
  };
  let aggregateDescription = "";
  for (let key in descriptionObj) {
    if (descriptionObj[key]) {
      aggregateDescription += `${key}: ${descriptionObj[key]}, `;
    }
  }
  return aggregateDescription ? aggregateDescription.slice(0, -2) : null;
};

const ContractorProfileMetaDescription = ({ contractor }: Props) => {
  const aggregateDescription = createMetaDescription(contractor);
  return (
    <Helmet>
      {aggregateDescription && (
        <meta name="description" content={aggregateDescription} />
      )}
    </Helmet>
  );
};

export default ContractorProfileMetaDescription;
