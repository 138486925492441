import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect, compose, connectResource } from "resources/components/utils";
import { SubmissionError } from "redux-form";
import { Table } from "react-bootstrap";
import { addNotification as notify } from "reapop";
import moment from "moment";

import { ownContractorRequest } from "resources/requests";
import { submitCreateBarLicense } from "contractor/actions/profile";

import {
  createSuccessNotification,
  createErrorNotification,
} from "common/utils/notifications";
import { validateRequiredFields } from "common/utils/forms";
import EditBarLicensesForm, {
  requiredFields,
} from "contractor/forms/profile/EditBarLicensesForm";

const ListBarLicenses = ({ barLicenses }) =>
  barLicenses.length === 0 ? (
    <p className="text-center">
      You don&apos;t have any bar licenses on file. Use the form below to add
      some.
    </p>
  ) : (
    <div>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Bar ID</th>
            <th>State</th>
            <th>Date Added</th>
          </tr>
        </thead>
        <tbody>
          {barLicenses.map(license => (
            <tr key={`bar-licenses-row-${license.uuid}`}>
              <td>{license.bar_id}</td>
              <td>{license.state}</td>
              <td>{moment(license.created).format("MMM D YYYY, h:mm a")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <p className="text-center">
        <small>
          To upload documents for bar license identity verification,{" "}
          <Link to="/contractor/onboarding/bar-admissions">click here.</Link>
        </small>
      </p>
    </div>
  );

class EditBarLicenses extends Component {
  handleSubmit = values => {
    const {
      notify,
      submitCreateBarLicense,
      onReloadHandlers: { ownContractor: requestOwnContractor },
    } = this.props;
    return submitCreateBarLicense(values).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      requestOwnContractor();
      notify(
        createSuccessNotification({ message: "Bar license has been saved." }),
      );
    });
  };

  validate = values => validateRequiredFields(requiredFields, values);

  render() {
    const {
      contractor: { bar_information },
    } = this.props;

    return (
      <div>
        <h2 className="edit-section-title">Bar Licenses</h2>
        <ListBarLicenses barLicenses={bar_information} />
        <hr />
        <div>
          <h2>Add Bar License</h2>
          <EditBarLicensesForm
            onSubmit={this.handleSubmit}
            validate={this.validate}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    null,
    {
      submitCreateBarLicense,
      notify,
    },
  ),
  connectResource(ownContractorRequest),
)(EditBarLicenses);
