import { SubmissionError } from "redux-form";
import { history as browserHistory } from "app";

import { basePath } from "./values";

export const shouldDisableStep = (currentStep, linkStepName, steps) => {
  let disabledStepNames = [];

  if (currentStep) {
    const stepsLen = steps.length;

    for (let i = stepsLen - 1; i >= 0; i--) {
      if (steps[i].name === currentStep.name) break;
      disabledStepNames.push(steps[i].name);
    }
  }

  return disabledStepNames.includes(linkStepName);
};

export const shouldDisplayStep = (linkStepName, steps) => {
  const stepsLen = steps.length;

  for (let i = 0; i < stepsLen; i++) {
    if (steps[i].name === linkStepName) {
      return steps[i].display;
    }
  }

  return false;
};

export const handleSubmitError = ({ body, status }) => {
  if (body && status) {
    if (status >= 200 && status < 300) {
      return body;
    } else {
      if (body.hasOwnProperty("non_field_errors")) {
        body._error = body.non_field_errors;
        delete body.non_field_errors;
      }
      throw new SubmissionError(body);
    }
  }
};

export const getActionIsRequired = (stepName, steps) => {
  const { status } = steps.find(step => step.name === stepName);
  return (
    status === "ACTION_REQUIRED" ||
    status === "NOT_COMPLETED_AND_ACTION_REQUIRED" ||
    status === "REJECTED"
  );
};

export const getCustomErrorMsg = (stepName, steps) => {
  const { custom_error } = steps.find(step => step.name === stepName);
  return custom_error;
};

export const getVerifIsPending = (stepName, steps) => {
  const { status } = steps.find(step => step.name === stepName);
  return status === "PENDING_VERIFICATION";
};

export const getCanMoveOnToNextStep = (stepName, steps) => {
  const { status } = steps.find(step => step.name === stepName);
  return status === "PENDING_VERIFICATION" || status === "COMPLETED";
};

export const redirectToNextStepIfNeeded = (
  activeStepName,
  refreshReqs,
  stopButtonLoading,
) => {
  refreshReqs().then(({ body }) => {
    if (body) {
      const { current_step, steps, steps_with_action_required_message } = body;
      const stepsLen = steps.length;
      const isUnfinished = current_step && current_step.name === activeStepName;
      const requiresAction =
        steps_with_action_required_message.length > 0 &&
        steps_with_action_required_message.some(e => e.name === activeStepName);

      if (isUnfinished || requiresAction) {
        if (stopButtonLoading) stopButtonLoading();
        return;
      }

      for (let i = 0; i < stepsLen; i++) {
        if (steps[i].name === activeStepName) {
          if (activeStepName === "language_proficiencies") {
            browserHistory.push("/contractor");
          } else {
            const nextPathName = steps[i + 1].name.replace(/_/g, "-");
            browserHistory.push(`${basePath}/${nextPathName}`);
          }
        }
      }
    }
  });
};
