// @flow strict

const apiHost: ?string = process.env.REACT_APP_API_HOST;
if (!apiHost) {
  throw new Error("process.env.REACT_APP_API_HOST is not defined.");
}

export const createApiUrl = (url: string): string => {
  const hasTrailingSlash = url.endsWith("/");
  const hasQuery = url.includes("?");
  const hasExtension = url
    .split("/")
    .slice(-1)[0]
    .includes(".");
  const urlNeedsTrailingSlash = !(hasQuery || hasExtension || hasTrailingSlash);
  return `${apiHost}${url}${urlNeedsTrailingSlash ? "/" : ""}`;
};

export const parseIntWithDefault = (value, defaultValue) => {
  const valueInt = parseInt(value, 10);
  if (isNaN(valueInt)) return defaultValue;
  return valueInt;
};

export function debounce(func, wait = 500) {
  var timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function() {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
}

// Like python's getAttr()
export const getAttr = (obj, attr, defaultValue) =>
  Object.prototype.hasOwnProperty.call(obj, attr) ? obj[attr] : defaultValue;

// Removes values that are null, undefined, or empty string and returns a new object.
export const removeEmptyValues = obj => {
  if (typeof obj !== "object") return obj;
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (val === null || val === undefined || val === "") return acc;
    return { ...acc, [key]: val };
  }, {});
};
