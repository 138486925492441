import React from "react";
import styled from "styled-components";
import { Button as ReactBootstrapButton } from "react-bootstrap";

const BUTTON_ACTION_TEXT = {
  save: { static: "Save", inProgress: "Saving..." },
  submit: { static: "Submit", inProgress: "Submitting..." },
  send: { static: "Send", inProgress: "Sending..." },
};

export const PrimaryFormButton = props => {
  const { action, submitting, disabled, ...buttonProps } = props;
  const buttonText = BUTTON_ACTION_TEXT[action];

  return (
    <PrimaryButton
      type="submit"
      disabled={disabled || submitting}
      {...buttonProps}
    >
      {submitting ? buttonText.inProgress : buttonText.static}
    </PrimaryButton>
  );
};

// The `compact` prop is a string as opposed to a boolean to avoid the
// following error warning:
// > Warning: Received true for a non-boolean attribute compact.

const PrimaryButton = styled(ReactBootstrapButton).attrs(props => ({
  bsStyle: props.bsStyle ? props.bsStyle : "primary",
  bsSize: "large",
  disabled: props.disabled,
  onClick: props.onClick,
}))`
  &&&& {
    float: ${({ float }) => (float ? float : "none")};
    margin: ${({ compact }) => (compact === "true" ? "3px" : "5px")};
    padding: ${({ compact }) => (compact === "true" ? "4px 12px" : "8px 24px")};
    font-size: ${({ compact }) => (compact === "true" ? "14px" : "18px")};
    font-weight: 900;
    border-radius: 9px;
    text-transform: uppercase;

    ${({ theme, compact }) => theme.media.mobile`
      width: ${compact === "true" ? "auto" : "100%"};
      margin-right: 0;
      margin-left: 0;
    `};

    ${({ theme }) => theme.media.desktop`
      float: right;
    `};
  }
`;

export default PrimaryButton;
