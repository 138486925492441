import React from "react";

import { editProfileRoutes } from "contractor/routes";
import { NavMenu } from "contractor/components/layout";

const EditProfileNavMenu = props => (
  <NavMenu
    baseRoute="/contractor/profile/edit/"
    routes={editProfileRoutes}
    {...props}
  />
);
export default EditProfileNavMenu;
