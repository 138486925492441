import React from "react";
import { getPacificTime, getMondayOfWeek, getSundayOfWeek } from "common/utils";
import { DatePickerField } from "common/components/forms/inputs";

const currentPacificTime = getPacificTime();
const monOfWeekPacific = getMondayOfWeek(currentPacificTime).toDate();
const sunOfWeekPacific = getSundayOfWeek(currentPacificTime).toDate();

const TrackerDatePicker = ({ disabled = false, ...otherProps }) => (
  <DatePickerField
    disabled={disabled}
    name="date"
    label="Date"
    minDate={monOfWeekPacific}
    maxDate={sunOfWeekPacific}
    openToDate={currentPacificTime.toDate()}
    todayButton={"Today"}
    {...otherProps}
  />
);

export default TrackerDatePicker;
