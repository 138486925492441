import actions from "../actions/contractorAudits";
import { handleActions } from "redux-actions";

const initialState = {
  contractorAudits: {},
  count: -1,
  next: null,
  previous: null,
  isLoading: false,
  lastUpdated: null,
};

const contractorAudits = handleActions(
  {
    [actions.REQUEST_CONTRACTOR_AUDITS]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_CONTRACTOR_AUDITS]: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
  },
  initialState,
);
export default contractorAudits;
