import React, { Fragment } from "react";
import { FormGroup, Radio } from "react-bootstrap";

import { HiddenFieldWithHelpText } from "common/components/forms/inputs";
import JobListing from "types/JobListing";

const formatExperienceLabel = ({ value, label, min, max }) => {
  let descriptor = "";
  if (value === "any") {
    descriptor = "";
  } else if (max === 50) {
    descriptor = ` (${min}+ years experience)`;
  } else {
    descriptor = ` (${min}-${max} years experience)`;
  }

  return `${label}${descriptor}`;
};

const YearsExperienceRadioField = props => {
  const {
    formValues: { max_years_experience: max, min_years_experience: min },
    onChange,
  } = props;
  return (
    <Fragment>
      <FormGroup>
        {JobListing.experienceOptions.map(option => (
          <Radio
            key={`experience-select-${option.value}`}
            checked={max === option.max && min === option.min}
            name="experience"
            onChange={() => onChange(option.min, option.max)}
          >
            {formatExperienceLabel(option)}
          </Radio>
        ))}
      </FormGroup>
      <HiddenFieldWithHelpText name="min_years_experience" />
      <HiddenFieldWithHelpText name="max_years_experience" />
    </Fragment>
  );
};

export default YearsExperienceRadioField;
