import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { compose, connect, mutateAsync } from "queries/utils";
import { createReviewRequestQuery } from "queries/mutations/reviewRequests";
import {
  CheckboxField,
  EmailAddressInput,
  FirstNameInput,
  LastNameInput,
  SelectField,
} from "common/components/forms/inputs";
import { agreeRealReferenceCopy } from "./copy";
import { handleSubmitError } from "../../utils";
import { RequiredFieldError, validateRequiredFields } from "common/utils/forms";
import Refs from "./blocks";
import FormButtons from "../../FormButtons";

const requiredFields = [
  "reviewer_first_name",
  "reviewer_last_name",
  "reviewer_email",
  "agree_real_reference",
];

let ReferencesForm = ({
  error,
  handleSubmit,
  isAttorney,
  onHide,
  prevEmployments,
  pristine,
  submitting,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <Row>
      <Col sm={6}>
        <FirstNameInput name="reviewer_first_name" />
      </Col>
      <Col sm={6}>
        <LastNameInput name="reviewer_last_name" />
      </Col>
    </Row>
    <Row>
      <Col sm={isAttorney ? 12 : 6}>
        <EmailAddressInput name="reviewer_email" />
      </Col>
      {!isAttorney && (
        <Col sm={6}>
          <SelectField
            name="related_previous_employment"
            label="Where did you work with this reference?"
          >
            {prevEmployments.map((prevEmployment, key) => (
              <option value={prevEmployment.uuid} key={key}>
                {`${prevEmployment.company_name} (${prevEmployment.job_title})`}
              </option>
            ))}
          </SelectField>
        </Col>
      )}
    </Row>
    {!isAttorney && (
      <Row>
        <Col sm={12}>
          <Refs.WorkExpInfo>
            Need to add a work experience entry?
            <br />
            <Link to="/contractor/onboarding/work-experience">
              Click here to go to Work Experience.
            </Link>
          </Refs.WorkExpInfo>
        </Col>
      </Row>
    )}
    <CheckboxField name="agree_real_reference" label={agreeRealReferenceCopy} />
    <FormButtons onHide={onHide} submitting={submitting} pristine={pristine} />
  </form>
);

ReferencesForm = reduxForm({
  form: "onboardingReferencesForm",
})(ReferencesForm);

class FormModal extends Component {
  handleSubmitReferenceForm = values => {
    const {
      props: { createReviewRequest },
      postSubmit,
    } = this;

    return createReviewRequest(values)
      .then(handleSubmitError)
      .then(postSubmit);
  };

  postSubmit = () => {
    const { onHide, refreshReqs, refreshReviewRequests } = this.props;
    onHide();
    refreshReqs();
    refreshReviewRequests();
  };

  validate = values => {
    const errors = validateRequiredFields(requiredFields, values);
    if (!values.agree_real_reference)
      errors.agree_real_reference = <RequiredFieldError />;
    return errors;
  };

  render() {
    const {
      props: { isAttorney, onHide, prevEmployments, show },
      handleSubmitReferenceForm,
      validate,
    } = this;

    return (
      <Modal backdrop="static" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Reference</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReferencesForm
            isAttorney={isAttorney}
            onHide={onHide}
            onSubmit={handleSubmitReferenceForm}
            prevEmployments={prevEmployments}
            validate={validate}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createReviewRequest: data =>
    dispatch(mutateAsync(createReviewRequestQuery(data))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(FormModal);
