import React from "react";

import Onboard from "../../blocks";
import StyledText from "common/blocks/StyledText";

export const MainCopy = ({ actionIsRequired, isAttorney, verifIsPending }) => (
  <p>
    {!verifIsPending &&
      "Please add professional references who have direct experience with you, your work, and your skills. "}
    Each reference will receive a short email survey. You can check your
    reference status here at any time. Only references from within the past five
    (5) years are accepted. Reference surveys not submitted within fourteen (14)
    days will expire.{" "}
    <StyledText textType={actionIsRequired ? "danger" : "black"} bold>
      We require three (3) completed references to accept a job.{!isAttorney &&
        " At least two (2) of these references must be related to your listed work experiences."}
    </StyledText>
  </p>
);

export const NoEntriesCopy = () => (
  <Onboard.TablePlaceholder>
    You don&apos;t have any references on file. Click &quot;Add a
    Reference&quot; to add.
  </Onboard.TablePlaceholder>
);

export const agreeRealReferenceCopy =
  "I certify that I understand that creating a fake reference and/or sending the " +
  " reference to a person other than the named valid professional reference will " +
  "result in being deactivated from the Hire an Esquire Network.";

export const PendingVerificationCopy = () => (
  <p>
    We&apos;re just waiting for your references to submit their reference forms.
    You&apos;ve done all you can for this stage.
  </p>
);
