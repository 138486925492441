import React from "react";

import { ReactSelectField } from "common/components/forms/inputs";
import { connectConnectRequest } from "queries/utils";
import { tagGroupsQuery } from "queries/requests";
import { createResourceSelector } from "queries";
import types from "resources/types";

const PrimaryPracticeAreaSelectField = props => {
  const {
    [types.TAG_GROUPS]: {
      data,
      isFinished,
      isPending,
      query: { data: queryData },
    },
    ...otherProps
  } = props;
  const options = isFinished
    ? queryData.map(tagGroupId => ({
        value: tagGroupId,
        label: data[tagGroupId].name,
      }))
    : [];
  return (
    <ReactSelectField
      name="primary_practice_area"
      isLoading={isPending}
      {...otherProps}
      options={options}
    />
  );
};

const mapPropsToConfig = tagGroupsQuery;
const mapStateToProps = createResourceSelector(
  types.TAG_GROUPS,
  mapPropsToConfig,
);

export default connectConnectRequest(mapPropsToConfig, mapStateToProps)(
  PrimaryPracticeAreaSelectField,
);
