// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import HideOnMobile from "./HideOnMobile";
import GoBackContainer from "common/blocks/GoBackContainer";

const DisplayBlock: ComponentType<{}> = styled.div`
  ${({ theme }) => theme.media.desktop`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
  `};

  ${({ theme }) => theme.media.lg`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
  `};

  p {
    font-size: 18px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
  }

  h1 {
    font-weight: bold;
  }
`;

const Display = (props: {}) => <DisplayBlock {...props} />;

Display.GoBackContainer = GoBackContainer;
Display.HideOnMobile = HideOnMobile;

export default Display;
