import React, { Component } from "react";
import moment from "moment";
import { Button, Clearfix, Label, Table } from "react-bootstrap";

import { formatDuration } from "common/utils/helpers";
import { getIsInCurrentWeek } from "common/utils";
import { recentFirst } from "contractor/components/utils/timeTracker";

const TimeEntriesForWeek = ({
  entriesForWeek,
  canDeleteEntries,
  onDeleteClick,
}) => {
  const handleDeleteClick = entryId => onDeleteClick(entryId);
  return (
    <Table striped>
      <tbody>
        {entriesForWeek.map(entry => (
          <tr key={entry.uuid}>
            <td style={{ whiteSpace: "pre" }}>
              <div>{moment(entry.date).format("MM/DD/YY")}</div>
            </td>
            <td style={{ whiteSpace: "pre" }}>
              <Label
                bsStyle="info"
                style={{ display: "inline-block", fontSize: "1.3rem" }}
              >
                {formatDuration(entry.minutes, true)}
              </Label>
            </td>
            <td style={{ width: "100rem" }}>{entry.description}</td>
            {canDeleteEntries && (
              <td>
                <Button
                  onClick={() => handleDeleteClick(entry.uuid)}
                  bsStyle="danger"
                  bsSize="small"
                >
                  <i className="fas fa-trash" />
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

class TimeEntriesList extends Component {
  getEntriesAndTotalsByWeek = entries =>
    entries.reduce((accum, curr) => {
      const startOfWeek = moment(curr.date)
        .startOf("isoWeek")
        .format("YYYY-MM-DD");

      if (!accum[startOfWeek]) {
        accum[startOfWeek] = {
          entries: [curr],
          totalTime: curr.minutes,
        };
      } else {
        accum[startOfWeek] = {
          entries: [...accum[startOfWeek].entries, curr],
          totalTime: accum[startOfWeek].totalTime + curr.minutes,
        };
      }
      return accum;
    }, {});

  getWeekTitleDisplay = weekKey =>
    getIsInCurrentWeek(weekKey)
      ? "This Week"
      : `Week of ${moment(weekKey).format("MMM Do, YYYY")}`;

  render() {
    const { onDeleteClick, timeEntries } = this.props;
    const sortedTimeEntries = Object.values(timeEntries).sort(recentFirst);
    const entriesByWeek = this.getEntriesAndTotalsByWeek(sortedTimeEntries);
    const weekKeys = Object.keys(entriesByWeek);

    return (
      <div>
        <h3>Time Entries</h3>
        {timeEntries && weekKeys.length ? (
          weekKeys.map(weekKey => {
            const isInCurrentWeek = getIsInCurrentWeek(weekKey);
            const totalTime = entriesByWeek[weekKey].totalTime;
            const weekTotalTimeDisplay = `${formatDuration(
              totalTime,
              true,
            )} total`;
            return (
              <div key={weekKey}>
                <div className="pull-right entries-week-title">
                  <span>
                    {this.getWeekTitleDisplay(weekKey)} - {weekTotalTimeDisplay}
                  </span>
                </div>
                <Clearfix />
                <TimeEntriesForWeek
                  onDeleteClick={onDeleteClick}
                  canDeleteEntries={isInCurrentWeek}
                  entriesForWeek={entriesByWeek[weekKey].entries}
                />
              </div>
            );
          })
        ) : (
          <div className="no-content-message">No time entries</div>
        )}
      </div>
    );
  }
}

export default TimeEntriesList;
