// @flow strict
import * as React from "react";

import Desktop from "./blocks";
import { createResourceSelector } from "queries";
import { connectConnectRequest } from "queries/utils";
import { ownHiringAgentQuery } from "queries/requests/hiring-agents";
import types from "resources/types";
import { userName } from "../values";

type HiringAgentDropdownTriggerProps = {
  dropdownOpen: boolean,
};
type Props = HiringAgentDropdownTriggerProps & {
  hiringAgents: QueryResource<HiringAgent>,
};

const HiringAgentDropdownTrigger = (props: Props) => {
  const {
    dropdownOpen,
    hiringAgents: { data, query, isFinished, isPending },
  } = props;
  const isReady = isFinished && !isPending;
  if (!isReady) return null;
  const hiringAgent = data[query.data[0]];
  const name = userName(hiringAgent);

  return (
    <Desktop.DropdownTrigger>
      <Desktop.DropdownName dropdownOpen={dropdownOpen} name={name}>
        {name}
      </Desktop.DropdownName>
    </Desktop.DropdownTrigger>
  );
};

const mapPropsToConfig = ownHiringAgentQuery;
const mapStateToProps = createResourceSelector(
  types.HIRING_AGENTS,
  mapPropsToConfig,
);

export default React.memo<HiringAgentDropdownTriggerProps>(
  connectConnectRequest(mapPropsToConfig, mapStateToProps)(
    HiringAgentDropdownTrigger,
  ),
);
