import types from "resources/types";
import { mutateResourceQuery } from "queries";

export const create = data =>
  mutateResourceQuery(
    types.CANDIDATE_REJECTIONS,
    {
      url: "/api/v2/candidate_rejections/",
      options: {
        method: "POST",
      },
    },
    data,
  );
