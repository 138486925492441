import React from "react";
import PropTypes from "prop-types";

const PlaceholderSelectOption = ({ placeholder }) => (
  <option value="" disabled style={{ display: "none" }}>
    {placeholder}
  </option>
);

PlaceholderSelectOption.propTypes = {
  placeholder: PropTypes.string.isRequired,
};
export default PlaceholderSelectOption;
