import { change, submit } from "redux-form";

import { action } from "common/actions/utils";
import { setSelectedBarInformationId } from "admin/actions/attorneyVerificationManager";
import { createConnectorCreator } from "common/connectors/utils";

const createAdminConnector = createConnectorCreator("admin");

// TODO: This could be a useful pattern - creating a connector without the onLoad and onReload handlers built in
export const withAttorneyVerificationManager = createAdminConnector({
  type: "attorneyVerificationManager",
  mapDispatchToProps: {
    onLoad: null,
    onReload: null,
    setSelectedBarInformationId,
  },
});

export const withBarInformations = createAdminConnector({
  type: "barInformations",
  path: "bar_informations",
  version: "v1",
  params: { order_by: "-created" },
});

export const pendingBarInformationInitialParams = {
  verified: 0,
  limit: 20,
  order_by: "-created",
};
const pendingBarInformationOptions = {
  type: "pendingBarInformations",
  path: "bar_informations",
  version: "v1",
  params: pendingBarInformationInitialParams,
};
export const withPendingBarInformations = createAdminConnector({
  ...pendingBarInformationOptions,
  mapDispatchToProps: {
    changeFieldValue: (field, value) =>
      change("pendingBarInformationSearchForm", field, value),
    submitSearchForm: () => submit("pendingBarInformationSearchForm"),
    onSearchSubmit: options =>
      action({ ...pendingBarInformationOptions, ...options }),
  },
});

export const recentBarInformationInitialParams = {
  verified_not: 0,
  limit: 20,
  order_by: "-created",
};
const recentBarInformationOptions = {
  type: "recentBarInformations",
  path: "bar_informations",
  version: "v1",
  params: recentBarInformationInitialParams,
};
export const withRecentBarInformations = createAdminConnector({
  ...recentBarInformationOptions,
  mapDispatchToProps: {
    changeFieldValue: (field, value) =>
      change("recentBarInformationSearchForm", field, value),
    submitSearchForm: () => submit("recentBarInformationSearchForm"),
    onSearchSubmit: options =>
      action({ ...recentBarInformationOptions, ...options }),
  },
});

export const withBarcardVerificationSet = createAdminConnector({
  type: "barcardVerificationSet",
  version: "v1",
});

export const withEmailVerificationSet = createAdminConnector({
  type: "emailVerificationSet",
  version: "v1",
});
