import { mutateResourceQuery } from "queries";
import types from "resources/types";
import { getCookie } from "common/utils";

const getBuiltFormData = data => {
  const {
    recipients = null,
    subject = null,
    files = null,
    job = null,
    body,
  } = data;
  const fd = new FormData();

  fd.append("body", body);
  if (job) fd.append("job", job);
  if (recipients)
    recipients.forEach(recipient => {
      fd.append("recipients", recipient);
    });
  if (subject) fd.append("subject", subject);
  if (files)
    files.forEach(file => {
      fd.append("file", file);
    });

  return fd;
};

export const createMessageQuery = data =>
  mutateResourceQuery(
    types.MESSAGES,
    {
      url: "/api/dev/messages/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const toggleThreadArchivedQuery = (messages, uuid, filter) =>
  mutateResourceQuery(types.MESSAGES, {
    url: `/api/dev/messages/${uuid}/change_archived/`,
    options: {
      method: "POST",
    },
    optimisticUpdate: {
      messages: () => {
        let newMessages = { ...messages };
        if (!filter) {
          newMessages.data[uuid].archived = !newMessages.data[uuid].archived;
        } else {
          newMessages.data[uuid] = {};
        }
        return newMessages;
      },
    },
  });

export const toggleThreadUnreadQuery = (messages, uuid, filter) =>
  mutateResourceQuery(types.MESSAGES, {
    url: `/api/dev/messages/${uuid}/change_unread/`,
    options: {
      method: "POST",
    },
    optimisticUpdate: {
      messages: () => {
        let newMessages = { ...messages };
        if (filter === "&unread=1") {
          newMessages.data[uuid] = {};
        } else {
          newMessages.data[uuid].unread = !newMessages.data[uuid].unread;
        }
        return newMessages;
      },
    },
  });

export const replyToThreadQuery = (threadId, data) =>
  mutateResourceQuery(types.REPLY_TO_THREAD, {
    url: `/api/dev/messages/${threadId}/reply/`,
    options: {
      headers: {
        Accept: "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      credentials: "include",
      method: "POST",
    },
    body: getBuiltFormData(data),
  });
