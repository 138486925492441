// @flow
import React, { Component, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connectRequest } from "redux-query-react";
import { createStructuredSelector } from "reselect";

import { logout } from "common/actions/auth";
import { logout as logoutMutation } from "queries/mutations/auth";
import { connect, compose } from "resources/components/utils";
import {
  createResourceDetailQuery,
  createResourceSelectorConfig,
} from "queries";
import types from "resources/types";

type LoginProps = {
  onLoad: () => mixed,
};

export class LogOut extends Component<LoginProps> {
  componentDidMount() {
    // TODO: Ensure other browser tabs are logged out too
    // TODO: (use localStorage or window.postMessage)
    this.props.onLoad();
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Log Out</title>
        </Helmet>
        <Row>
          <Col md={6} mdOffset={3} sm={8} smOffset={2}>
            <h1>Log Out</h1>
            <p>Thanks for using Hire an Esquire! You have been logged out.</p>
            <p>
              <Link to="/login">Click here</Link> to log in.
            </p>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const currentUserQuery = () =>
  createResourceDetailQuery(types.CURRENT_USER_TYPE, {
    url: "/api/v2/current_user_type/",
    force: true,
  });

const mapPropsToConfig = currentUserQuery;

const currentUserConfig = createResourceSelectorConfig(
  types.CURRENT_USER_TYPE,
  currentUserQuery,
);

const mapStateToProps = createStructuredSelector({
  ...currentUserConfig,
});

export default compose(
  connect(
    mapStateToProps,
    (dispatch: Dispatch) => ({
      onLoad: () => {
        dispatch(logout());
        return dispatch(logoutMutation());
      },
    }),
  ),
  connectRequest(mapPropsToConfig),
)(LogOut);
