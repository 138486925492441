import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { change, reduxForm, formValueSelector } from "redux-form";

import {
  BubbleCheckboxField,
  DollarInput,
  NumberField,
} from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";
import {
  FormRow,
  withFormError,
} from "hiringagent-dashboard/components/jobs/create-job-listing/utils";
import { normalizeRate } from "hiringagent-dashboard/components/jobs/payment-details/utils";
import { connect, compose } from "resources/components/utils";
import { PaymentDetails } from "types";

export const formName = "jobPaymentDetailsForm";

const validate = values => validateRequiredFields(["rate"], values);

export const getformattedHaeRate = haeRate =>
  parseFloat(haeRate * 100).toFixed();

export const getTotalRate = (contractorRate, haeFeePercent) =>
  (parseFloat(contractorRate) * (haeFeePercent + 1)).toFixed(2);

export class RateForm extends Component {
  componentDidMount() {
    this.updateHaeRate();
    this.updateTotal(this.props.formValues.rate);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      formValues: { rate },
    } = this.props;
    const {
      formValues: { rate: nextRate },
    } = nextProps;
    if (rate !== nextRate) {
      this.updateTotal(nextRate || "0");
    }
  }

  getHaeRatePercentage() {
    const { hae_1099_rate, hae_w2_rate, service } = this.props.formValues;
    return parseInt(service, 10) === PaymentDetails.SERVICE_TYPES.W2
      ? hae_w2_rate
      : hae_1099_rate;
  }

  updateHaeRate() {
    this.props.change("hae_rate", this.getHaeRatePercentage());
  }

  updateTotal = contractorRate => {
    const updatedTotalRate = getTotalRate(
      contractorRate,
      this.getHaeRatePercentage(),
    );
    this.props.change("total_rate", updatedTotalRate);
  };

  renderFeeHelpText() {
    const { hae_1099_rate, hae_w2_rate } = this.props.formValues;
    return (
      <p>
        {`We believe in being transparent about contractor rates and our staffing service charges.
                (${getformattedHaeRate(
                  hae_1099_rate,
                )}% for 1099 and ${getformattedHaeRate(
          hae_w2_rate,
        )}% for W-2 projects.)`}
      </p>
    );
  }

  render() {
    const {
      handleSubmit,
      formValues: { payment_type, service },
    } = this.props;
    const paymentType = parseInt(payment_type, 10);
    const havePaymentType = payment_type || payment_type === 0;
    const haveService = service || service === 0;

    return (
      <form onSubmit={handleSubmit}>
        <FormRow style={{ marginBottom: "1rem" }}>
          <DollarInput
            name="rate"
            label="Rate Paid to Contractor"
            addonRight={
              havePaymentType &&
              PaymentDetails.paymentTypesMapping[paymentType].label
            }
            min={0}
            step="0.01"
            normalize={normalizeRate}
          />
          <p>This will be included in the job offer sent to a candidate.</p>
        </FormRow>
        <div className="extra-rate-info" style={{ marginBottom: "4rem" }}>
          {haveService && (
            <FormRow>
              <Row>
                <Col md={6}>
                  <NumberField
                    name="hae_rate"
                    label="Hire an Esquire Service Charge"
                    addonRight="%"
                    bsSize="sm"
                    className="text-right"
                    format={getformattedHaeRate}
                    disabled
                    readOnly
                  />
                </Col>
                <Col md={6}>
                  <DollarInput
                    name="total_rate"
                    label="Total Rate to You"
                    disabled
                    readOnly
                    bsSize="sm"
                  />
                </Col>
              </Row>
              {this.renderFeeHelpText()}
            </FormRow>
          )}
        </div>

        <FormRow>
          <BubbleCheckboxField name="allow_expenses">
            Allow Expenses
          </BubbleCheckboxField>
          <p>
            If this option is selected, contractors will be able to submit
            job-related expenses.
          </p>
        </FormRow>
      </form>
    );
  }
}

const formValues = formValueSelector(formName);

const ConnectedRateForm = compose(
  connect(
    state => ({
      formValues: {
        hae_1099_rate: formValues(state, "hae_1099_rate"),
        hae_w2_rate: formValues(state, "hae_w2_rate"),
        payment_type: formValues(state, "payment_type"),
        rate: formValues(state, "rate"),
        service: formValues(state, "service"),
      },
    }),
    {
      change,
    },
  ),
  reduxForm({
    validate,
    form: "jobPaymentDetailsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  withFormError(),
)(RateForm);

export default class Rate extends Component {
  render() {
    const { formProps, initialValues, job } = this.props;

    return (
      <div>
        <h2>Finalize rate for this position.</h2>
        <ConnectedRateForm
          {...formProps}
          jobId={job.uuid}
          initialValues={initialValues}
        />
      </div>
    );
  }
}
