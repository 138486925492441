import React, { Component } from "react";
import { Alert, Modal } from "react-bootstrap";
import { reduxForm } from "redux-form";

import { compose, connect, mutateAsync } from "queries/utils";
import { StateSelect, TextField } from "common/components/forms/inputs";
import { addBarInformationsQuery } from "queries/mutations/barInformations";
import { handleSubmitError } from "../../utils";
import FormButtons from "../../FormButtons";

let BarAdmissionsForm = ({
  error,
  handleSubmit,
  onHide,
  submitting,
  pristine,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <TextField name="bar_id" label="Bar ID" bsSize="small" />
    <StateSelect name="state" />
    <FormButtons onHide={onHide} submitting={submitting} pristine={pristine} />
  </form>
);

BarAdmissionsForm = reduxForm({
  form: "onboardingBarAdmissionsForm",
})(BarAdmissionsForm);

class FormModal extends Component {
  handleSubmitBarForm = async values => {
    const {
      props: { addBarInformations },
      postSubmit,
    } = this;

    const result = await addBarInformations(values);

    handleSubmitError(result);
    postSubmit();
  };

  postSubmit = () => {
    const { onHide, refreshBarInfo, refreshBarVerif, refreshReqs } = this.props;
    onHide();
    refreshBarInfo();
    refreshBarVerif();
    refreshReqs();
  };

  render() {
    const {
      handleSubmitBarForm,
      props: { show, onHide },
    } = this;

    return (
      <Modal backdrop="static" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Upload a Bar License</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BarAdmissionsForm onHide={onHide} onSubmit={handleSubmitBarForm} />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addBarInformations: data =>
    dispatch(mutateAsync(addBarInformationsQuery(data))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(FormModal);
