import React from "react";
import styled from "styled-components";

import FAQ, { FAQText, FAQTrigger } from "./FAQ";

const BarBlock = styled.div``;

const Bar = props => <BarBlock {...props} />;

Bar.FAQ = FAQ;
Bar.FAQText = FAQText;
Bar.FAQTrigger = FAQTrigger;

export default Bar;
