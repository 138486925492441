import { mutateResourceQuery } from "queries";
import types from "resources/types";

export const createPreviousEmploymentQuery = data =>
  mutateResourceQuery(
    types.PREVIOUS_EMPLOYMENTS,
    {
      url: "/api/v2/previous_employments/",
      options: {
        method: "POST",
      },
    },
    data,
  );

export const updatePreviousEmploymentQuery = (data, uuid) =>
  mutateResourceQuery(
    types.PREVIOUS_EMPLOYMENTS,
    {
      url: `/api/v2/previous_employments/${uuid}`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );

export const deletePreviousEmploymentQuery = uuid =>
  mutateResourceQuery(types.PREVIOUS_EMPLOYMENTS, {
    url: `/api/v2/previous_employments/${uuid}`,
    options: {
      method: "DELETE",
    },
  });
