import React from "react";
import PropTypes from "prop-types";
import { Button, Table } from "react-bootstrap";
import moment from "moment";

const agentById = (source, hiringAgents) => {
  const hiringAgent = Object.values(hiringAgents).find(
    agent => agent.haeuser === source.metadata.created_by,
  );
  return hiringAgent
    ? `${hiringAgent.first_name} ${hiringAgent.last_name}`
    : "";
};

const verificationLink = verifyFunc => (
  <span>
    <Button
      className="bank-verify-button"
      bsStyle="link"
      onClick={() => verifyFunc()}
    >
      (Verify)
    </Button>
  </span>
);

const sourceStatusDisplay = status =>
  status === "verified" ? (
    <span>Verified</span>
  ) : (
    <span className="alert-color">Not Verified</span>
  );

export const PaymentSourceTable = ({ source, hiringAgents, onVerifyClick }) => (
  <Table className="payment-sources-table" striped>
    {source.object === "card" ? (
      <tbody>
        {source.metadata.label && (
          <tr>
            <td>Account Label:</td>
            <td>
              <strong>{source.metadata.label}</strong>
            </td>
          </tr>
        )}
        <tr>
          <td>Card Type:</td>
          <td>{source.brand}</td>
        </tr>
        <tr>
          <td>Name on Account:</td>
          <td>{source.name}</td>
        </tr>
        <tr>
          <td>Card Number:</td>
          <td>************{source.last4}</td>
        </tr>
        <tr>
          <td>Expiration:</td>
          <td>
            {source.exp_month} / {source.exp_year}
          </td>
        </tr>
        <tr>
          <td>Created by:</td>
          <td>{agentById(source, hiringAgents)}</td>
        </tr>
        {source.metadata.datetime_created && (
          <tr>
            <td>Created:</td>
            <td>{moment(source.metadata.datetime_created).format("lll")}</td>
          </tr>
        )}
      </tbody>
    ) : (
      <tbody>
        {source.metadata.label && (
          <tr>
            <td>Account Label:</td>
            <td>
              <strong>{source.metadata.label}</strong>
            </td>
          </tr>
        )}
        <tr>
          <td>Bank Name:</td>
          <td>{source.bank_name}</td>
        </tr>

        <tr>
          <td>Routing Number:</td>
          <td>{source.routing_number}</td>
        </tr>

        <tr>
          <td>Account Number:</td>
          <td>************{source.last4}</td>
        </tr>
        <tr>
          <td>Status:</td>
          {source.status === "verified" ? (
            <td>{sourceStatusDisplay(source.status)}</td>
          ) : source.metadata.error_message ? (
            <td className="danger">
              Error ({source.metadata.error_message}){" "}
              {verificationLink(onVerifyClick)}
            </td>
          ) : (
            <td>
              {sourceStatusDisplay(source.status)}{" "}
              {verificationLink(onVerifyClick)}
            </td>
          )}
        </tr>
        <tr>
          <td>Created by:</td>
          <td>{agentById(source, hiringAgents)}</td>
        </tr>
        {source.metadata.datetime_created && (
          <tr>
            <td>Created:</td>
            <td>{moment(source.metadata.datetime_created).format("lll")}</td>
          </tr>
        )}
      </tbody>
    )}
  </Table>
);

PaymentSourceTable.propTypes = {
  source: PropTypes.object.isRequired,
  hiringAgents: PropTypes.object.isRequired,
};
export default PaymentSourceTable;
