import actions from "../actions/payments";
import { handleActions } from "redux-actions";

const initialState = {
  paymentSources: [],
  paymentMethodOverride: null,
  focusedSource: null,
  verificationAccountId: null,
  openModal: null,
  submitting: false,
  isLoading: false,
  lastUpdated: null,
};

const payments = handleActions(
  {
    [actions.REQUEST_PAYMENT_SOURCES]: state => ({
      ...state,
      focusedSource: null,
      isLoading: true,
    }),
    [actions.RECEIVE_PAYMENT_SOURCES]: (state, action) => ({
      ...state,
      paymentSources: action.payload.sources,
      paymentMethodOverride: action.payload.payment_method_override,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    // Optimistically update active source
    // TODO: If response paymentSource state is different than current state
    // (ie set active source request failed), alert user somehow
    [actions.REQUEST_SUBMIT_SET_ACTIVE_SOURCE]: (state, action) => {
      const sources = state.paymentSources;
      const sourceId = action.payload.sourceId;

      sources.forEach(source => {
        source.active = false;
      });
      const sourceToSetActive = sources.find(source => source.id === sourceId);
      sourceToSetActive.active = true;

      return { ...state, paymentSources: [...sources] };
    },
    [actions.REQUEST_SUBMIT_PAYMENT_SOURCE_FORM]: state => ({
      ...state,
      isLoading: true,
    }),
    [actions.RECEIVE_SUBMIT_PAYMENT_SOURCE_FORM]: (state, action) => ({
      ...state,
      isLoading: false,
      lastUpdated: action.meta.receivedAt,
    }),
    [actions.REQUEST_SUBMIT_DELETE_SOURCE]: (state, action) => ({
      ...state,
      submitting: action.payload.sourceId,
    }),
    [actions.RECEIVE_SUBMIT_DELETE_SOURCE]: state => ({
      ...state,
      submitting: false,
    }),
    [actions.SET_FOCUSED_SOURCE]: (state, action) => ({
      ...state,
      focusedSource: action.payload,
    }),
    [actions.SET_VERIFICATION_ACCOUNT_ID]: (state, action) => ({
      ...state,
      verificationAccountId: action.payload,
    }),
    [actions.TOGGLE_ADD_PAYMENT_ACCT_MODAL]: (state, action) => ({
      ...state,
      openModal: action.payload,
    }),
  },
  initialState,
);
export default payments;
