// @flow strict
import styled from "styled-components";
import { Button as ReactBootstrapButton } from "react-bootstrap";

const LinkButton = styled(ReactBootstrapButton).attrs({
  bsStyle: "link",
})`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: calc(11px + 0.2vw) !important;
  font-weight: bold;
  text-transform: uppercase !important;
`;

export default LinkButton;
