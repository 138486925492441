// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Footer from "./Footer";

type Props = {
  compact?: "true" | "false",
};

const ContainerBlock: ComponentType<Props> = styled.div`
  margin: 0px 0 20px 0;
  padding: ${({ compact }) => (compact ? "1rem" : "2rem")};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.background_gray()};
`;

const Container = (props: Props) => <ContainerBlock {...props} />;

Container.Footer = Footer;

export default Container;
