// @flow strict
import React, { Component } from "react";
// $FlowFixMe 'Cannot resolve module redux-form.'
import { reduxForm } from "redux-form";
import { Jumbotron } from "react-bootstrap";
import moment from "moment";

import FormButton from "common/components/FormButton";
import { CheckboxField } from "common/components/forms/inputs";

type Values = {
  agrees_to_tos: boolean,
};

type Props = {
  agreedToLatestTOS?: string,
  handleSubmit: Values => void,
  submitting: boolean,
};

type State = {
  checked: boolean,
};

export class AcceptTermsOfServiceForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { checked: false };
  }

  toggleCheck = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ checked: e.currentTarget.checked });
  };

  render() {
    const { agreedToLatestTOS, handleSubmit, submitting } = this.props;
    return agreedToLatestTOS ? (
      <Jumbotron style={{ borderRadius: "0px", textAlign: "center" }}>
        {`Thank you for agreeing to Hire an Esquire's Terms of Service on
        ${moment(agreedToLatestTOS).format("MMMM Do, YYYY")}.`}
      </Jumbotron>
    ) : (
      <Jumbotron style={{ borderRadius: "0px" }}>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <CheckboxField
            className="input-large"
            name="agrees_to_tos"
            label={
              "I have read and agree to Hire an Esquire's Terms of Service."
            }
            onChange={this.toggleCheck}
          />
          <FormButton
            action="submit"
            bsSize="large"
            style={{ marginLeft: "1em" }}
            submitting={submitting}
            disabled={!this.state.checked || submitting}
          />
        </form>
      </Jumbotron>
    );
  }
}

AcceptTermsOfServiceForm = reduxForm({
  form: "acceptTermsOfServiceForm",
})(AcceptTermsOfServiceForm);

export default AcceptTermsOfServiceForm;
