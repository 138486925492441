import { action } from "common/actions/utils";

const options = {
  type: "bankAccounts",
  actionType: "resource",
};

export const update = (data, config = {}) =>
  action({
    ...options,
    path: "bank_accounts/self",
    method: "post",
    data,
    ...config,
  });
