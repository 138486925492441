import React from "react";
import { Nav, NavItem } from "react-bootstrap";
import { StyledListGroupItem } from "../blocks";

const AlternateFooter = () => (
  <StyledListGroupItem type="footer">
    <Nav stacked>
      <NavItem
        eventKey={1}
        href="/public/terms-of-service/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </NavItem>
      <NavItem
        eventKey={2}
        href="/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </NavItem>
      <NavItem
        eventKey={3}
        href="https://hireanesquire.com/faq-legal-professionals"
        target="_blank"
        rel="noopener noreferrer"
      >
        FAQ
      </NavItem>
      <NavItem
        eventKey={4}
        href="https://hireanesquire.com/blog"
        target="_blank"
        rel="noopener noreferrer"
      >
        Blog
      </NavItem>
      <NavItem eventKey={5} disabled>
        Copyright © 2010-2019, Hire an Esquire, Inc. All rights reserved.
      </NavItem>
    </Nav>
  </StyledListGroupItem>
);

export default AlternateFooter;
