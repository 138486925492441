// @flow strict
import React, { Component, Fragment } from "react";
import moment from "moment";

import { compose, connect, connectRequest } from "queries/utils";
import {
  createResourceListQuery,
  createResourceSelectorConfig,
  createStructuredSelector,
} from "queries";
import types from "resources/types";
import Qualifications from "hiringagent-dashboard/components/candidates/QualificationsSection/blocks";

const Detail = ({ met, children }) => (
  <Qualifications.Item>
    {met ? (
      <Qualifications.IconSuccessCheck />
    ) : (
      <Qualifications.IconDangerTimes />
    )}
    <Qualifications.Qualification>{children}</Qualifications.Qualification>
  </Qualifications.Item>
);

const isMet = (
  requirement: JobRequirement,
  response: ?JobRequirementResponse,
) => {
  if (!requirement) return false;
  if (!response) return false;
  return response.response;
};

type ResponseDetailProps = {
  jobListing: ?JobListing,
  requirement: JobRequirement,
  response: JobRequirementResponse,
};
const ExperienceDetail = ({
  requirement,
  response,
  jobListing,
}: ResponseDetailProps) => {
  if (jobListing.min_years_experience === 0) return null;
  let display;
  if (jobListing.max_years_experience === 50) {
    display = `${jobListing.min_years_experience}+ years experience`;
  } else {
    display = `${response.requirement_description} experience`;
  }

  return <Detail met={isMet(requirement, response)}>{display}</Detail>;
};

const LocationDetail = ({ requirement, response }: ResponseDetailProps) => (
  <Detail met={isMet(requirement, response)}>
    Works in {requirement.requirement_description}
  </Detail>
);
type RateDetailProps = {
  jobListing: JobListing,
};
const RateDetail = ({ jobListing }: RateDetailProps) => {
  const rate = jobListing.estimatedRateDisplay;
  if (!rate) return null;
  return <Detail met>Rate of {jobListing.estimatedRateDisplay}</Detail>;
};

type StartDateDetailProps = {
  startDate: ?string,
};
const StartDateDetail = ({ startDate }: StartDateDetailProps) => (
  <Detail met>
    Available starting {moment(startDate).format("MMM. D, YYYY")}
  </Detail>
);

const requiredResources = [
  types.JOB_LISTINGS,
  types.JOB_REQUIREMENTS,
  types.REQUIREMENT_RESPONSES,
];

type Props = {
  candidate: Candidate,
  contractor: Contractor,
  jobId: Uuid,
};
class Details extends Component<Props> {
  get isFinished() {
    return requiredResources.every(resource => this.props[resource].isFinished);
  }
  get isPending() {
    return requiredResources.some(resource => this.props[resource].isPending);
  }
  getRequirementsByCategory(category: string): ?Array<JobRequirement> {
    const {
      [types.JOB_REQUIREMENTS]: {
        data: jobRequirementsData,
        query: { data: jobRequirementIds },
      },
    } = this.props;
    const jobRequirements = jobRequirementIds.map(
      id => jobRequirementsData[id],
    );
    return jobRequirements.filter(req => req.category === category);
  }
  getResponsesForCategory(category: string): ?Array<JobRequirementResponse> {
    const {
      [types.REQUIREMENT_RESPONSES]: {
        data: requirementResponsesData,
        query: { data: requirementResponseIds },
      },
    } = this.props;
    const requirementResponses = requirementResponseIds.map(
      id => requirementResponsesData[id],
    );
    const requirements = this.getRequirementsByCategory(category);
    if (!requirements) return null;
    const requirementIds = requirements.map(requirement => requirement.uuid);
    return requirementResponses.filter(resp =>
      requirementIds.includes(resp.requirement),
    );
  }
  render() {
    const {
      [types.JOB_LISTINGS]: { data: jobListings },
      contractor,
      jobId,
    } = this.props;
    const { isFinished, isPending } = this;
    if (isPending || !isFinished) return null;
    const jobListing = Object.keys(jobListings)
      .map(id => jobListings[id])
      .find(jl => jl.job === jobId);
    if (!jobListing) return null;
    const experienceResponses = this.getResponsesForCategory(
      "Years Experience",
    );
    const experienceRequirements = this.getRequirementsByCategory(
      "Years Experience",
    );
    const locationResponses = this.getResponsesForCategory("Location");
    const locationRequirements = this.getRequirementsByCategory("Location");

    return (
      <ul className="list-unstyled">
        {jobListing.remote ? (
          <Detail met>Works remotely</Detail>
        ) : (
          <Fragment>
            {locationResponses &&
              locationResponses.map(resp => (
                <LocationDetail
                  key={resp.uuid}
                  response={resp}
                  requirement={locationRequirements.find(
                    req => req.uuid === resp.requirement,
                  )}
                />
              ))}
          </Fragment>
        )}
        <StartDateDetail startDate={jobListing.start_date} />
        <RateDetail jobListing={jobListing} />
        {jobListing.bar_requirements &&
          jobListing.bar_requirements.map(state => (
            <Detail
              key={state}
              met={(contractor.bar_information || [])
                .map(bi => bi.state)
                .includes(state)}
            >
              Licensed in {state}
            </Detail>
          ))}
        {experienceResponses &&
          experienceResponses.length > 0 && (
            <ExperienceDetail
              jobListing={jobListing}
              requirement={experienceRequirements.find(
                req => req.uuid === experienceResponses[0].requirement,
              )}
              response={experienceResponses[0]}
            />
          )}
      </ul>
    );
  }
}

const jobListingQuery = props =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: `/api/v2/joblistings/?job=${props.jobId}`,
  });
const jobRequirementsQuery = props =>
  createResourceListQuery(types.JOB_REQUIREMENTS, {
    url: `/api/v2/requirements/?joblisting__job=${props.jobId}&limit=999`,
  });
const requirementResponsesQuery = props =>
  createResourceListQuery(types.REQUIREMENT_RESPONSES, {
    url: `/api/v2/requirement_responses/?application__candidate=${
      props.candidate.uuid
    }&limit=999`,
  });
const jobListingConfig = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingQuery,
);
const jobRequirementsConfig = createResourceSelectorConfig(
  types.JOB_REQUIREMENTS,
  jobRequirementsQuery,
);
const requirementResponsesConfig = createResourceSelectorConfig(
  types.REQUIREMENT_RESPONSES,
  requirementResponsesQuery,
);

const mapPropsToConfig = props => [
  jobListingQuery(props),
  jobRequirementsQuery(props),
  requirementResponsesQuery(props),
];

const mapStateToProps = createStructuredSelector({
  ...jobListingConfig,
  ...jobRequirementsConfig,
  ...requirementResponsesConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(Details);
