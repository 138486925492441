import styled from "styled-components";

export const FloatingButtonGradientBackground = styled.div`
  position: absolute;
  bottom: 0px;
  height: 88px;
  width: 100%;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );

  ${({ theme }) => theme.media.sm`
    height: 40px;
  `};
`;

const FloatingButtonContainer = styled.div`
  position: absolute;
  bottom: -46px;
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.media.sm`
    bottom: -20px;
  `};

  a.btn {
    font-weight: bold;
    text-transform: uppercase;
    padding: 6px 48px;
    font-size: 16px;
  }
`;

export default FloatingButtonContainer;
