// @flow strict
import { type Uuid, type DateTime } from "./primitives";
import { keyForValue } from "common/utils";

export default class CandidateType {
  uuid: Uuid;
  contractor: Uuid;
  job: Uuid;
  predictor_scores: PredictorScores;
  overall_fitscore: ?number;
  brief_check_score: ?number;
  workstyle_assessment_score: ?number;
  workstyle_assessment_form_enabled: boolean;
  status: CandidateStatus;
  full_name: String;
  created: DateTime;
  predictor_scores: PredictorScores;

  constructor(candidate: Candidate) {
    this.uuid = candidate.uuid;
    this.contractor = candidate.contractor;
    this.job = candidate.job;
    this.predictor_scores = candidate.predictor_scores;
    this.overall_fitscore = candidate.overall_fitscore;
    this.workstyle_assessment_form_enabled =
      candidate.workstyle_assessment_form_enabled;
    this.status = candidate.status;
    this.full_name = candidate.full_name;
    this.created = candidate.created;
    this.predictor_scores = candidate.predictor_scores;
    this.brief_check_score = candidate.brief_check_score;
    this.workstyle_assessment_score = candidate.workstyle_assessment_score;
  }

  static STATUS_TYPES = {
    PENDING: 0,
    REJECTED: 1,
    NEGOTIATING: 2,
    HIRED: 3,
  };

  static statusTypesOrdering = [0, 2, 3, 1];

  static statusTypeDisplay(typeValue: ?CandidateStatus): ?string {
    if (!Object.values(this.STATUS_TYPES).includes(typeValue)) return null;
    return keyForValue(typeValue, this.STATUS_TYPES);
  }

  static statusTypeValue(display: string): string {
    return this.STATUS_TYPES[display];
  }

  get statusDisplay(): ?string {
    return keyForValue(this.status, CandidateType.STATUS_TYPES);
  }
}
