import React from "react";

import { SelectField } from "common/components/forms/inputs";
import { withHowHeard } from "common/connectors";

// TODO: #148831615 Use `priority` field on HowYouHeard model in API for option ordering
const reformatHeardChoices = howYouHeardChoices => {
  const howYouHeardChoicesCopy = [...howYouHeardChoices];
  const index = howYouHeardChoicesCopy.findIndex(
    ({ how_heard }) => how_heard === "Other",
  );
  if (index > -1) {
    const other = howYouHeardChoicesCopy.splice(index, 1);
    howYouHeardChoicesCopy.push(other[0]);
    return howYouHeardChoicesCopy;
  }
  return howYouHeardChoices;
};

const HowYouHeardSelect = props => {
  const {
    how_you_heard: {
      isLoading,
      lastUpdated,
      data: { results },
    },
    ...otherProps
  } = props;

  return (
    <SelectField
      name="how_heard"
      label="How did you hear about Hire an Esquire?"
      {...otherProps}
    >
      {!isLoading &&
        lastUpdated &&
        reformatHeardChoices(results).map(({ uuid, how_heard }) => (
          <option key={`how-heard-option-${uuid}`} value={uuid}>
            {how_heard}
          </option>
        ))}
    </SelectField>
  );
};
export default withHowHeard()(HowYouHeardSelect);
