import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

import PublicProfileMarketing from "public/components/marketing/PublicProfileMarketing";
import AttorneyPracticeAreasGroupsList from "hiringagent-dashboard/components/attorney/AttorneyPracticeAreasGroupsList";
import LanguageTooltip from "common/components/LanguageTooltip";

const ContractorProfileBody = ({ contractor, isPublicProfile, userType }) => {
  const showPrivateInfo = !isPublicProfile || userType === "admin";
  return (
    <div>
      <div className="attorney-main-info">
        <Row>
          <Col sm={12}>
            <div className="main-info-container attorney-summary-container">
              <h2 className="main-info-title">Summary</h2>
              <div className="tertiary-header">{contractor.summary}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="main-info-container attorney-experience-container">
              <span className="hidden" itemProp="jobTitle" itemRef="jobTitle" />
              <h2 className="main-info-title">Experience</h2>
              {contractor.previous_employment.map(experience => (
                <div
                  itemProp="worksFor"
                  itemScope
                  itemType="http://schema.org/Organization"
                  className="main-info-section attorney-experience-section"
                  key={experience.uuid}
                >
                  <div className="experience-details">
                    {experience.company_name && (
                      <div className="secondary-header">
                        {showPrivateInfo && (
                          <span
                            itemProp="name"
                            style={{ marginRight: ".7rem" }}
                          >
                            {experience.company_name}
                          </span>
                        )}
                        {experience.firm_type && (
                          <small
                            style={{
                              fontWeight: showPrivateInfo ? "100" : "500",
                            }}
                          >
                            {`${experience.firm_type}`}
                          </small>
                        )}
                      </div>
                    )}
                    {experience.job_title && (
                      <div className="tertiary-header">
                        {experience.position_type && (
                          <span>{`${experience.position_type} | `}</span>
                        )}
                        <span id="jobTitle">{experience.job_title}</span>
                      </div>
                    )}
                  </div>
                  {showPrivateInfo && (
                    <div className="experience-details experience-date-container">
                      {experience.start_date ? (
                        experience.end_date ? (
                          <div className="accent-title experiencence-date">
                            <time>
                              {moment(experience.start_date).format("MMM YYYY")}
                            </time>{" "}
                            -{" "}
                            <time>
                              {moment(experience.end_date).format("MMM YYYY")}
                            </time>
                          </div>
                        ) : (
                          <div className="accent-title experience-date">
                            <time>
                              {moment(experience.start_date).format("MMM YYYY")}
                            </time>{" "}
                            - Present
                          </div>
                        )
                      ) : null}
                    </div>
                  )}
                  {experience.location && (
                    <div
                      itemProp="location"
                      itemScope
                      itemType="http://schema.org/Place"
                      className="tertiary-header"
                    >
                      <span className="hidden" itemProp="address">
                        {experience.location}
                      </span>
                      <span itemProp="name">{experience.location}</span>
                    </div>
                  )}
                  {experience.description && (
                    <div className="tertiary-header experience-description">
                      <span itemProp="description">
                        {experience.description}
                      </span>
                    </div>
                  )}
                  <div className="practice-areas">
                    {experience.tags && (
                      <AttorneyPracticeAreasGroupsList
                        attorneyUuid={contractor.uuid}
                        practiceAreas={experience.tags.map(tag => ({
                          text: tag.name,
                          name: tag.name,
                          group: tag.group,
                        }))}
                      />
                    )}
                  </div>
                  <hr className="hidden-xs" />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        {contractor.availability && (
          <Col sm={6}>
            <div className="main-info-container attorney-availability-container">
              <h2 className="main-info-title">Availability</h2>
              <div className="main-info-section">
                {(contractor.interested_in_temp ||
                  contractor.interested_in_perm) && (
                  <div>
                    {contractor.interested_in_temp ? (
                      <dl className="row">
                        <dt className="col-xs-7 secondary-header">
                          Interested in:
                        </dt>
                        <dd className="col-xs-5 accent-title">
                          <span className="accent-title pull-right">
                            {contractor.interested_in_perm &&
                            contractor.interested_in_temp
                              ? "Temporary, Permanent Positions"
                              : "Temporary Positions"}
                          </span>
                        </dd>
                      </dl>
                    ) : null}
                    <hr />
                  </div>
                )}
                {contractor.availability && (
                  <div>
                    <dl className="row">
                      <dt className="col-xs-7 secondary-header">
                        Available Weekdays
                      </dt>
                      <dd className="col-xs-5 accent-title">
                        <span className="accent-title pull-right">
                          {contractor.availability.available_weekdays
                            ? "Yes"
                            : "No"}
                        </span>
                      </dd>
                    </dl>
                    <hr />
                    <dl className="row">
                      <dt className="col-xs-7 secondary-header">
                        Available Evenings
                      </dt>
                      <dd className="col-xs-5">
                        <span className="accent-title pull-right">
                          {contractor.availability.available_evenings
                            ? "Yes"
                            : "No"}
                        </span>
                      </dd>
                    </dl>
                    <hr />
                    <dl className="row">
                      <dt className="col-xs-7 secondary-header">
                        Available Weekends
                      </dt>
                      <dd className="col-xs-5">
                        <span className="accent-title pull-right">
                          {contractor.availability.available_weekends
                            ? "Yes"
                            : "No"}
                        </span>
                      </dd>
                    </dl>
                    <hr />
                    <dl className="row">
                      <dt className="col-xs-7 secondary-header">
                        Available Hours
                      </dt>
                      <dd className="col-xs-5">
                        <span
                          style={{ float: "right" }}
                          className="accent-title pull-right"
                        >
                          {`${contractor.availability.hours_per_week}/week`}
                        </span>
                      </dd>
                    </dl>
                  </div>
                )}
              </div>
            </div>
          </Col>
        )}
        <Col sm={6}>
          <div className="main-info-container attorney-education-container">
            <h2 className="main-info-title">Education</h2>
            <div className="tertiary-header">
              {contractor.education.map(degree => (
                <div
                  itemProp="alumniOf"
                  itemScope
                  itemType="http://schema.org/EducationalOrganization"
                  className="main-info-section"
                  key={degree.uuid}
                >
                  {showPrivateInfo && (
                    <div itemProp="name" className="secondary-header">
                      {degree.school_name}
                    </div>
                  )}
                  <div itemProp="description" className="tertiary-header">
                    {degree.degree}
                  </div>
                  <div className="accent-title">{`Graduated: ${
                    degree.year_graduated
                  }`}</div>
                  {degree.gpa ? (
                    degree.additional_notes ? (
                      <div>{`${degree.gpa} GPA | ${
                        degree.additional_notes
                      }`}</div>
                    ) : (
                      <div>{`${degree.gpa} GPA`}</div>
                    )
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </Col>
        {contractor.language_fluency.length ? (
          <Col sm={6}>
            <div
              itemScope
              itemType="http://schema.org/ItemList"
              className="main-info-container attorney-language-container"
            >
              <h2 className="main-info-title">
                <span itemProp="name">Languages</span>
                <i className="lang-tooltip">
                  &nbsp;&nbsp;{<LanguageTooltip id={contractor.uuid} />}
                </i>
              </h2>
              <div className="tertiary-header">
                {contractor.language_fluency.map(language => (
                  <div className="main-info-section" key={language.uuid}>
                    <div
                      itemProp="itemListElement"
                      className="secondary-header"
                    >
                      {language.language}
                    </div>
                    <div>{`Proficiency: ${language.fluency}`}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        ) : null}
      </Row>
      {isPublicProfile && <PublicProfileMarketing />}
    </div>
  );
};

export default ContractorProfileBody;
