import React from "react";
import PropTypes from "prop-types";

import { HelpBlock } from "react-bootstrap";

const FieldHelpBlock = ({ touched, error }) =>
  touched && error ? (
    <HelpBlock>
      {Array.isArray(error) ? (
        <ul>
          {error.map((e, i) => (
            <li key={`error-${i}`}>{e}</li>
          ))}
        </ul>
      ) : (
        error
      )}
    </HelpBlock>
  ) : null;

FieldHelpBlock.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.node,
};

export default FieldHelpBlock;
