// @flow strict
import styled from "styled-components";

const Subheader = styled.h4`
  text-align: left;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.25px;
`;

export default Subheader;
