import React from "react";
import { Link } from "react-router-dom";

import { JobPaymentDetailsTable } from "hiringagent-dashboard/components/jobs";

const JobPaymentDetails = props => {
  const { jobId, paymentDetailsId, workerCount } = props;

  const paymentDetailsLink = `/agency/jobs/payment_details/${jobId}/`;
  const allowEdit = paymentDetailsId && !workerCount;

  return (
    <div className="main-info-container">
      <div className="clearfix">
        <h2 className="main-info-title pull-left">Payment Details</h2>
        {allowEdit && (
          <span className="pull-right">
            <Link to={paymentDetailsLink}>Edit</Link>
          </span>
        )}
      </div>
      {paymentDetailsId ? (
        <JobPaymentDetailsTable paymentDetailsId={paymentDetailsId} />
      ) : (
        <p className="text-center">
          You haven&apos;t submitted payment details for this job yet.{" "}
          <Link to={paymentDetailsLink}>Click here</Link> to submit payment
          details.
        </p>
      )}
    </div>
  );
};

export default JobPaymentDetails;
