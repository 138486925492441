import React from "react";
import styled from "styled-components";

import Button from "./Button";

const ActionBlock = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0 0 0;
  display: flex;
  justify-content: center;

  &&&& * {
    margin: 0;
  }
`;

const Action = props => <ActionBlock {...props} />;

Action.Button = Button;

export default Action;
