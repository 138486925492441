import React, { Component } from "react";

import {
  BarLicenseDetails,
  PendingBarInformations,
  RecentlyProcessedBarInformations,
} from "admin/components/attorney-verification-manager";
import { Navigation } from "admin/components/layout";
import { withAttorneyVerificationManager } from "admin/connectors";

class AttorneyVerificationManager extends Component {
  handleLicenseClick = licenseId => {
    this.props.setSelectedBarInformationId(licenseId);
  };

  render() {
    const {
      attorneyVerificationManager: { selectedBarInformationId },
    } = this.props;
    return (
      <div className="verification-manager">
        <Navigation />

        <div id="verifications">
          <div style={{ position: "relative", height: "100%" }}>
            <div id="pending-verifications">
              <PendingBarInformations
                onLicenseClick={this.handleLicenseClick}
                selectedLicense={selectedBarInformationId}
              />
            </div>
            <div id="recent-verifications">
              <RecentlyProcessedBarInformations
                onLicenseClick={this.handleLicenseClick}
                selectedLicense={selectedBarInformationId}
              />
            </div>
          </div>
        </div>
        <div
          id="verification-details"
          key={`verification-details-${selectedBarInformationId}`}
        >
          {selectedBarInformationId ? (
            <BarLicenseDetails />
          ) : (
            <div className="no-license-selected">
              <p>Select a bar license to the left to view details here.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withAttorneyVerificationManager()(AttorneyVerificationManager);
