import { action, submitFile } from "common/actions/utils";

const options = {
  type: "timeEntries",
  version: "dev",
};

export const createTimeEntry = data =>
  action({
    ...options,
    actionType: "resource",
    path: "time_entries",
    method: "post",
    data,
  });

export const deleteTimeEntry = id =>
  action({
    ...options,
    actionType: "resource",
    path: `time_entries/${id}`,
    method: "delete",
    id,
  });

export const createFixedRateOccurrence = data =>
  action({
    type: "fixedRateOccurrences",
    version: "dev",
    actionType: "resource",
    path: "fixed_rate_occurrences",
    method: "post",
    data,
  });

export const createExpense = submitFile();

export const deleteExpense = id =>
  action({
    type: "expenses",
    version: "dev",
    actionType: "resource",
    path: `expenses/${id}`,
    method: "delete",
    id,
  });
