import React, { Component } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import { submitContractorProfile } from "contractor/actions/profile";
import { EditPrivacyForm } from "contractor/forms";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";

const successMessage = isSearchable =>
  isSearchable
    ? "Your profile will now be searchable"
    : "Your profile will no longer be searchable";

class EditPrivacy extends Component {
  handleSubmit = values => {
    const {
      contractor: { uuid },
      notify,
      submitContractorProfile,
    } = this.props;
    return submitContractorProfile(uuid, values).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message:
              json.detail || "Privacy setting not saved. Please try again.",
          }),
        );
        throw new SubmissionError(json);
      }
      const message = successMessage(values.allow_public_profile);
      notify(
        createSuccessNotification({ title: "Privacy setting saved", message }),
      );
    });
  };

  render() {
    const { contractor } = this.props;

    return (
      <div>
        <h2 className="edit-section-title">Privacy</h2>
        <h4 style={{ marginBottom: "2rem", fontWeight: "300" }}>
          Make your profile searchable so that employers on the Hire an Esquire
          platform can find you. If your profile is not searchable, potential
          employers will not see you in search but you could still be
          recommended for a job by our staff.
        </h4>
        <EditPrivacyForm
          initialValues={{
            allow_public_profile: contractor.allow_public_profile,
          }}
          onSubmit={values => this.handleSubmit(values)}
          contractor={contractor}
        />
      </div>
    );
  }
}

export default connect(
  null,
  {
    notify,
    submitContractorProfile,
  },
)(EditPrivacy);
