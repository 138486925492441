import React, { Component } from "react";
import { requestAsync } from "redux-query";
import { connectRequest } from "redux-query-react";
import { connect, compose } from "resources/components/utils";
import { Col, Row } from "react-bootstrap";
import { reset, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import { createFixedRateOccurrence } from "resources/actions/timeTracker";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { getCannotAddEntryReason } from "contractor/components/utils/timeTracker";
import { FixedRateOccurrencesList } from "contractor/components/time-tracker";
import {
  BillableEventInfoMsg,
  CannotAddEntriesMsg,
} from "contractor/components/time-tracker/Messages";
import FixedRateOccurrenceForm, {
  formName,
} from "contractor/forms/FixedRateOccurrenceForm";

import { createResourceListQuery, createResourceSelector } from "queries";
import Loading from "common/components/Loading";
import types from "resources/types";

const CannotAddEntry = ({ workerIsActive, jobIsClosed, isFixedAutoJob }) => {
  let reason = getCannotAddEntryReason(workerIsActive, jobIsClosed);
  if (!reason && isFixedAutoJob) reason = "this job bills automatically";
  return <CannotAddEntriesMsg reason={reason} />;
};

class FixedRateOccurrences extends Component {
  handleSubmit = values => {
    const { resetForm, notify, onSubmit, refreshOccurrences } = this.props;
    return onSubmit(values).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      refreshOccurrences(this.props);
      notify(createSuccessNotification({ message: "Event has been saved." }));
      resetForm();
    });
  };

  render() {
    const {
      job: { closed_date, title },
      worker: { active: workerIsActive, uuid: workerId },
      paymentDetail,
      [types.FIXED_RATE_OCCURRENCES]: {
        isFinished: fixedRateOccurrencesFinished,
        data: fixedRateOccurences,
      },
    } = this.props;
    const canAddEntry =
      workerIsActive && !closed_date && !paymentDetail.isFixedAutomatic;

    return (
      <div>
        <h1>
          Add a billable event<span className="small">{` for ${title}`}</span>
        </h1>
        {canAddEntry &&
          paymentDetail && (
            <BillableEventInfoMsg paymentDetail={paymentDetail} />
          )}
        <Row>
          <Col sm={5}>
            <h3>Add Event</h3>
            {!canAddEntry && (
              <CannotAddEntry
                workerIsActive={workerIsActive}
                jobIsClosed={closed_date}
                isFixedAutoJob={paymentDetail.isFixedAutomatic}
              />
            )}
            <FixedRateOccurrenceForm
              initialValues={{ job_worker: workerId }}
              onSubmit={this.handleSubmit}
              isDisabled={!canAddEntry}
            />
          </Col>
          <Col sm={6} smOffset={1}>
            <h3>Submitted Events</h3>
            {fixedRateOccurrencesFinished ? (
              <FixedRateOccurrencesList occurrences={fixedRateOccurences} />
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const fixedRateOccurrencesQuery = props =>
  createResourceListQuery(types.FIXED_RATE_OCCURRENCES, {
    url: `/api/dev/fixed_rate_occurrences/?job_worker__job=${
      props.job.uuid
    }&limit=9999`,
    force: true,
  });

const mapPropsToConfig = props => [fixedRateOccurrencesQuery(props)];

export default (FixedRateOccurrences = compose(
  connectRequest(mapPropsToConfig),
  connect(
    createResourceSelector(
      types.FIXED_RATE_OCCURRENCES,
      fixedRateOccurrencesQuery,
    ),
    {
      notify,
      resetForm: () => reset(formName),
      refreshOccurrences: props =>
        requestAsync(fixedRateOccurrencesQuery(props)),
      onSubmit: createFixedRateOccurrence,
    },
  ),
)(FixedRateOccurrences));
