import React, { Component } from "react";
import PropTypes from "prop-types";
import Geosuggest from "react-geosuggest";

export default class LocationInput extends Component {
  static propTypes = {
    onAddLocation: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: "Search for a city",
  };

  onSelect = value => {
    this.props.onAddLocation(value);
    this.geoSuggest.clear();
  };

  render() {
    return (
      <Geosuggest
        ref={geo => {
          this.geoSuggest = geo;
        }}
        onSuggestSelect={this.onSelect}
        country="us"
        types={["(cities)"]}
        {...this.props}
      />
    );
  }
}
