import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Alert, Col, Form, Row, Well } from "react-bootstrap";

import FormButton from "common/components/FormButton";
import FormFieldToolTip from "common/components/forms/FormFieldToolTip";
import {
  handleSubmissionError,
  validateRequiredFields,
} from "common/utils/forms";
import {
  requestHiringAgents,
  submitEditProfileForm,
} from "hiringagent-dashboard/actions/hiringAgents";
import { addNotification as notify } from "reapop";
import {
  CheckboxField,
  FirstNameInput,
  LastNameInput,
} from "common/components/forms/inputs";

const requiredFields = ["first_name", "last_name", "is_admin"];

const editProfileSuccessMsg = {
  title: "Success",
  message: "Profile changes saved",
  status: "success",
  position: "tr",
  dismissible: true,
  dismissAfter: 3000,
};

const isAdminToolTip = agencyName => {
  const text = `Check this box if you want this user to be able to administer the Hire
                  an Esquire account for ${agencyName}. An admin can manage addresses, payment
                  account information, and other hiring agents.`;
  return (
    <FormFieldToolTip
      title="Administrator Privileges"
      content={text}
      placement="right"
    />
  );
};

const validate = values => validateRequiredFields(requiredFields, values);

class ChangeNameForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values, dispatch) {
    const {
      agent: { uuid },
      notify,
    } = this.props;
    return dispatch(submitEditProfileForm(uuid, values))
      .then(handleSubmissionError)
      .then(() => {
        notify(editProfileSuccessMsg);
        dispatch(requestHiringAgents());
      });
  }

  render() {
    const {
      agency,
      userIsAdmin,
      handleSubmit,
      error,
      pristine,
      submitting,
    } = this.props;

    return (
      <Row>
        <Col xs={12} mdOffset={4} md={4}>
          <Well>
            <Form onSubmit={handleSubmit(this.submit)}>
              {error && <Alert bsStyle="danger">{error}</Alert>}
              <FirstNameInput />
              <LastNameInput />
              {userIsAdmin && (
                <CheckboxField
                  name="is_admin"
                  label="This user is an account admin"
                >
                  &nbsp;{isAdminToolTip(agency.name)}
                </CheckboxField>
              )}
              <div className="text-right">
                <FormButton
                  className="text-right"
                  action="save"
                  submitting={submitting}
                  disabled={pristine || submitting}
                />
              </div>
            </Form>
          </Well>
        </Col>
      </Row>
    );
  }
}

ChangeNameForm = compose(
  connect(
    null,
    { notify },
  ),
  reduxForm({
    form: "ChangeNameForm",
    validate,
  }),
)(ChangeNameForm);
export default ChangeNameForm;
