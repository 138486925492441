import React, { Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import moment from "moment";

const InvoiceActionAlerts = ({ invoice, onApproveClick }) => (
  <Fragment>
    {invoice.disputed_date && !invoice.dispute_resolved ? (
      <div className="alert alert-danger">
        <p>
          <strong>
            This invoice has been marked as &lsquo;Disputed.&rsquo;&nbsp;
          </strong>
          Someone from Hire an Esquire will be in touch with you shortly.
        </p>
        <p>
          Have things been worked out?&nbsp;
          <a className="btn btn-danger" onClick={onApproveClick} href="">
            Approve
          </a>{" "}
          and mark the dispute as resolved.
        </p>
      </div>
    ) : !invoice.approved_date && !invoice.paid_date ? (
      <div className="alert alert-info">
        <p>
          By approving this invoice, you understand that Hire an Esquire will
          perform an ACH pull from your bank account or charge your credit card
          and distribute payments to contractors on your behalf. If your account
          has insufficent funds, penalties may apply.
        </p>
        <p>
          If you have not approved or disputed this invoice by the due date
          listed below, the invoice will be automatically marked as approved and
          contractors will be paid.
        </p>
        {/*             if (hiringagent.is_admin || invoice.job.posting_user == hiringagent.haeuser)
                        // TODO: Approve and Dispute views still direct to old templates, redirect to new SPA Invoice
    */}
        <p>
          <a
            className="btn btn-success"
            onClick={onApproveClick}
            style={{ marginRight: "5px" }}
            href=""
          >
            Approve
          </a>
          <LinkContainer to={`/agency/invoices/${invoice.uuid}/dispute/`}>
            <Button bsStyle="danger">Dispute</Button>
          </LinkContainer>
        </p>
        {/*             else
                        <p>
                            <strong>
                                You must either be the hiring agent for this project or an admin for
                                <em> {agency.name} </em> to approve this invoice.
                            </strong>
                        </p>
    */}
      </div>
    ) : !invoice.approved_date ? (
      <div className="alert alert-info" role="alert">
        <em>Automatically approved by Hire an Esquire.</em>
        <br />
      </div>
    ) : (
      <div className="alert alert-success" role="alert">
        {/* invoice.approved_by, previously used, always returns null on endpoint. */}
        <em>
          Approved on{" "}
          {moment(invoice.approved_date).format("MMMM D, YYYY, h:mm a")}
        </em>
        <br />
      </div>
    )}
    {!!invoice.paid_in_full && (
      <div className="alert alert-success" role="alert">
        <em>
          Paid in full on{" "}
          {moment(invoice.paid_date).format("MMMM D, YYYY, h:mm a")}.
        </em>
      </div>
    )}
  </Fragment>
);

export default InvoiceActionAlerts;
