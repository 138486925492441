// @flow
import { compose, connect, connectRequest } from "queries/utils";

import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";

const RenderWithExpertiseAreas = props => props.render(props[types.TAGS].data);

const mapPropsToConfig = () =>
  createResourceListQuery(types.TAGS, {
    url: "/api/dev/tags/?limit=999",
  });

const mapStatetoProps = createResourceSelector(types.TAGS, mapPropsToConfig);

export default compose(
  connect(mapStatetoProps),
  connectRequest(mapPropsToConfig),
)(RenderWithExpertiseAreas);
