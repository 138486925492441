import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { change, submit } from "redux-form";
import { connect, compose, connectResource } from "resources/components/utils";

import { createPaginationForForm } from "admin/components/attorney-verification-manager/BarInformationsTable";
import { VerificationSearchForm } from "admin/forms";
import { action } from "common/actions/utils";
import NonAttorneyTable from "./NonAttorneyTable";
import resourceTypes from "resources/types";

const paginationComponents = [
  "notStartedNonAttorneySearchForm",
  "pendingNonAttorneySearchForm",
  "rejectedNonAttorneySearchForm",
  "verifiedNonAttorneySearchForm",
].reduce(
  (components, formName) => ({
    ...components,
    [formName]: createPaginationForForm(formName),
  }),
  {},
);

class NonAttorneysByType extends Component {
  handleOffsetChange = offset => {
    this.props.changeFieldValue("offset", offset);
    setTimeout(this.props.submitSearchForm);
  };

  handleSubmit = values => {
    this.props.onSearchSubmit({ params: values });
  };

  render() {
    const {
      formName,
      initialValues,
      metadata: { data, lastUpdated, isLoading, ready },
      selectedRow,
    } = this.props;

    const propsToPass = {
      nonAttorneys: data.results,
      lastUpdated,
      isLoading,
      selectedRow,
    };
    const initialFormData = {
      ...initialValues,
      text: "",
      start_date: null,
      end_date: null,
      offset: 0,
    };
    const Pagination = paginationComponents[formName];
    return (
      <div>
        <div className="clearfix">
          <div className="pull-left">
            <VerificationSearchForm
              form={formName}
              onSubmit={this.handleSubmit}
              initialValues={initialFormData}
            />
          </div>
          {ready && (
            <small className="pull-right">
              showing {data.results.length} / {data.count}
            </small>
          )}
        </div>

        <NonAttorneyTable {...propsToPass} />
        {ready && (
          <Pagination
            onOffsetChange={this.handleOffsetChange}
            length={data.count}
          />
        )}
      </div>
    );
  }
}

const createConnectedNonAttorneysComponent = (type, formName, params) => {
  const defaultParams = {
    limit: 10,
    ...params,
  };
  const defaultOptions = {
    type,
    actionType: "resource",
    path: "non_attorneys",
    params: {
      ordering: "-updated",
      ...defaultParams,
    },
  };
  return compose(
    connect(
      state => ({
        formName,
        initialValues: defaultParams,
        selectedRow: state.admin.nonAttorneyVerification.selectedRow,
        ...createStructuredSelector({
          metadata: state => state.resources[type],
        })(state),
      }),
      {
        changeFieldValue: (field, value) => change(formName, field, value),
        submitSearchForm: () => submit(formName),
        onSearchSubmit: options => action({ ...defaultOptions, ...options }),
      },
    ),
    connectResource(() => defaultOptions),
  )(NonAttorneysByType);
};

export const NotStartedNonAttorneys = createConnectedNonAttorneysComponent(
  resourceTypes.NOT_STARTED_NON_ATTORNEYS,
  "notStartedNonAttorneySearchForm",
  { not_started_verification: true },
);
export const PendingNonAttorneys = createConnectedNonAttorneysComponent(
  resourceTypes.PENDING_NON_ATTORNEYS,
  "pendingNonAttorneySearchForm",
  { pending_verification: true },
);
export const RejectedNonAttorneys = createConnectedNonAttorneysComponent(
  resourceTypes.REJECTED_NON_ATTORNEYS,
  "rejectedNonAttorneySearchForm",
  { is_verified: 0 },
);
export const VerifiedNonAttorneys = createConnectedNonAttorneysComponent(
  resourceTypes.VERIFIED_NON_ATTORNEYS,
  "verifiedNonAttorneySearchForm",
  { is_verified: 1 },
);
