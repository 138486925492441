import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import CannotApplyMessage from "contractor/components/application/CannotApplyMessage";

const buttonContainerStyles = { textAlign: "center" };

const CannotApplyNotification = props => (
  <div>
    <CannotApplyMessage {...props} />
    <div style={buttonContainerStyles}>
      <LinkContainer to={"/contractor/jobs/"}>
        <Button bsStyle="info">Search for Open Jobs</Button>
      </LinkContainer>
    </div>
  </div>
);

const AlreadyAppliedNotification = ({ job }) => (
  <div style={buttonContainerStyles}>
    <p
      className="header-copy"
      style={{ fontSize: "2rem", marginBottom: "2rem" }}
    >
      You have already applied to this position. You can only apply once!
    </p>
    <LinkContainer to={`/contractor/jobs/${job.uuid}/`}>
      <Button bsStyle="info">See Job Details</Button>
    </LinkContainer>
  </div>
);

const CannotApply = ({
  contractor,
  job,
  jobListing,
  hasApplied,
  hasPermission,
}) => (
  <div style={{ marginTop: "2.5rem" }}>
    {hasApplied ? (
      <AlreadyAppliedNotification job={job} />
    ) : (
      <CannotApplyNotification
        job={job}
        jobListing={jobListing}
        contractor={contractor}
        hasPermission={hasPermission}
      />
    )}
  </div>
);
export default CannotApply;
