import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  DropdownButton,
  FormGroup,
  MenuItem,
  Row,
  Table,
  Well,
} from "react-bootstrap";
import Select from "react-select";
import moment from "moment";

import { withInvoices } from "hiringagent-dashboard/connectors";
import {
  setInvoicesJobFilter,
  resetFilters,
  setSortingBy,
} from "hiringagent-dashboard/actions/invoices";
import { formatUSD } from "common/utils/helpers";
import Loading from "common/components/Loading";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { connectRequest } from "redux-query-react";
import DiscountCodesTotals from "hiringagent-dashboard/components/settings/DiscountCodesSettings/utils";

class Invoices extends Component {
  render() {
    const {
      dispatch,
      invoices: { invoices, isLoading, sorting, jobFilters, sortingOptions },
      [types.JOBS]: { data: jobs },
      menuItems,
    } = this.props;
    const {
      invoices: { iconClassName },
    } = menuItems;

    const allInvoices = Object.keys(invoices).map(key => invoices[key]);

    allInvoices.forEach(invoice => {
      if (jobs[invoice.job]) {
        invoice.job_info = jobs[invoice.job];
        jobFilters[invoice.job_info.title] =
          jobFilters[invoice.job_info.title] || false;
      }
    });

    const invoiceJobNames = new Set(
      allInvoices.map(invoice =>
        invoice.job_info ? invoice.job_info.title : undefined,
      ),
    );

    const invoiceJobFilters = Object.keys(jobFilters).reduce(
      (reducedFilters, jobName) => {
        const allJobsStatus = jobFilters["All Jobs"];
        if (invoiceJobNames.has(jobName)) {
          reducedFilters[jobName] = jobFilters[jobName];
        }

        return { ...reducedFilters, "All Jobs": allJobsStatus };
      },
      {},
    );

    const selectedJobFilter = invoiceJobFilters["All Jobs"]
      ? "All Jobs"
      : Object.keys(invoiceJobFilters).find(job => invoiceJobFilters[job]);

    let filteredInvoices = allInvoices;
    if (selectedJobFilter !== "All Jobs") {
      filteredInvoices = allInvoices.filter(
        invoice => invoice.job_info.title === selectedJobFilter,
      );
    }

    let sortedInvoices = filteredInvoices;
    if (sorting) {
      const sortField = sortingOptions[sorting];
      if (sortField === "invoice") {
        sortedInvoices = sortedInvoices.sort(
          (a, b) => b.invoice_number - a.invoice_number,
        );
      } else if (sortField === "job") {
        sortedInvoices = sortedInvoices.sort((a, b) => {
          const nameA = a.job_info.title.toLowerCase();
          const nameB = b.job_info.title.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameB < nameA) {
            return 1;
          }
          return 0;
        });
      } else if (sortField === "created") {
        sortedInvoices = sortedInvoices.sort((a, b) => {
          const dateA = new Date(a.created);
          const dateB = new Date(b.created);
          return dateB - dateA;
        });
      } else if (sortField === "due") {
        sortedInvoices = sortedInvoices.sort((a, b) => {
          const dateA = new Date(a.due_date);
          const dateB = new Date(b.due_date);
          return dateB - dateA;
        });
      } else if (sortField === "paid") {
        sortedInvoices = sortedInvoices.sort(
          (a, b) => b.paid_in_full - a.paid_in_full,
        );
      } else if (sortField === "total") {
        sortedInvoices = sortedInvoices.sort((a, b) => b.total - a.total);
      }
    }

    const allJobFilters = Object.keys(invoiceJobFilters).map(filterKey => {
      const jobCount = allInvoices.reduce(
        (prev, curr) =>
          prev +
          (curr.job_info &&
          (curr.job_info.title === filterKey || filterKey === "All Jobs")
            ? 1
            : 0),
        0,
      );
      return {
        value: filterKey,
        label: `${filterKey} (${jobCount})`,
        count: jobCount,
      };
    });

    return (
      <div>
        <h1>
          <i className={`far ${iconClassName}`} />
          &nbsp;Invoices
        </h1>
        <Row>
          <Col lg={3}>
            <Well className="list-filters">
              <form onSubmit={e => e.preventDefault()}>
                <h3 className="list-filters-heading">Filter</h3>
                <FormGroup>
                  <ControlLabel className="title">Job</ControlLabel>
                  <Select
                    value={selectedJobFilter}
                    options={allJobFilters}
                    clearable={false}
                    onChange={job =>
                      dispatch(
                        setInvoicesJobFilter({ selectedJobFilter: job.value }),
                      )
                    }
                  />
                </FormGroup>
                <ButtonToolbar>
                  <Button
                    bsStyle="info"
                    onClick={() => dispatch(resetFilters())}
                  >
                    Reset
                  </Button>
                </ButtonToolbar>
              </form>
            </Well>
          </Col>
          <Col lg={9}>
            <DiscountCodesTotals showLink alert />
            <div className="invoices-header">
              <DropdownButton title={"Sort By"} id="sort-invoices-dropdown">
                {Object.keys(sortingOptions).map(option => (
                  <MenuItem
                    key={`sort-by-${option}`}
                    onClick={() => dispatch(setSortingBy(option))}
                  >
                    {sorting && option === sorting ? (
                      <strong>{option}</strong>
                    ) : (
                      option
                    )}
                  </MenuItem>
                ))}
              </DropdownButton>
              <p className="pull-right">
                Showing {sortedInvoices.length} out of{" "}
                {Object.keys(invoices).length} invoices
              </p>
            </div>
            <Table responsive striped hover className="invoices-list-table">
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Job</th>
                  <th>Created</th>
                  <th>Due</th>
                  <th className="text-center">Approved</th>
                  <th className="text-center">Paid</th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>
              {!jobs.isPending && (
                <tbody className="invoices-list-table-body">
                  {sortedInvoices.length > 0 &&
                    sortedInvoices.map(invoice => (
                      <tr key={`invoice-${invoice.uuid}`}>
                        <td>
                          <Link to={`/agency/invoices/${invoice.uuid}`}>
                            #{invoice.invoice_number}
                          </Link>
                        </td>
                        <td>{invoice.job_info && invoice.job_info.title}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {moment(invoice.created).format("MM-DD-YYYY")}
                        </td>
                        <td
                          className={invoice.is_due ? "danger-color" : ""}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {moment(invoice.due_date).format("MM-DD-YYYY")}
                        </td>
                        <td className="text-center">
                          {invoice.approved_date ? (
                            <i className="success-check far fa-check-circle" />
                          ) : (
                            <i className="danger-times far fa-times-circle" />
                          )}
                        </td>
                        <td className="text-center">
                          {invoice.paid_in_full ? (
                            <i className="success-check far fa-check-circle" />
                          ) : (
                            <i className="danger-times far fa-times-circle" />
                          )}
                        </td>
                        <td className="text-center">
                          {formatUSD(invoice.total)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </Table>
            {(!(isLoading || jobs.isPending) && sortedInvoices.length) ===
              0 && (
              <div
                className="text-center"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                There are no invoices to display.
              </div>
            )}
            {(isLoading || jobs.isPending) && <Loading />}
          </Col>
        </Row>
      </div>
    );
  }
}

const jobsQuery = () =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=999",
  });

const mapPropsToConfig = props => [jobsQuery(props)];

Invoices = compose(
  withInvoices(),
  connect(createResourceSelector(types.JOBS, jobsQuery)),
  connectRequest(mapPropsToConfig),
  connect(state => ({
    ...state.hiringAgentDashboard.sidebar,
  })),
)(Invoices);

export default Invoices;
