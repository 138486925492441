import styled from "styled-components";

import { DropdownButton } from "react-bootstrap";

const ColumnSelectDropdown = styled(DropdownButton)`
  &&.dropdown-toggle {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80px;

    ${({ theme }) => theme.media.mobile_md`
      max-width: 120px;
    `};

    ${({ theme }) => theme.media.mobile_lg`
      max-width: none;
    `};

    &,
    & + ul li a {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
`;

export default ColumnSelectDropdown;
