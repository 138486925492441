// @flow strict
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import Loading from "common/components/Loading";
import { compose, connect, connectRequest } from "queries/utils";
import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import types from "resources/types";

type Props = {
  candidateId: Uuid,
  jobId: Uuid,
};

class ApplicationSubmittedDisplay extends Component<Props> {
  render() {
    const { candidateId, competencyQuestions, jobId } = this.props;
    const questions = competencyQuestions
      ? Object.values(competencyQuestions.data)
      : null;

    return (
      <div>
        <p className="header-copy">
          Your application has been submitted! You’ll receive an email when your
          application status changes. To check the status or contact the hiring
          manager, click on the{" "}
          <Link to={`/contractor/jobs/${jobId}`}>job details page</Link> at any
          time.
        </p>
        {candidateId && questions ? (
          questions.length > 0 ? (
            <Fragment>
              <p className="header-copy">
                The following Workstyle Assessment helps to match you with roles
                best suited to your work style. The results are saved for future
                applications. Completing this section boosts your profile for
                recommendations and searches, which increases your chances of
                getting hired.{" "}
                <strong>
                  Complete the entire assessment in one session, otherwise your
                  answers will not be saved.
                </strong>
              </p>
              <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
                <LinkContainer
                  to={`/contractor/workstyle-assessment/${candidateId}`}
                >
                  <Button bsStyle="info">START WORKSTYLE ASSESSMENT</Button>
                </LinkContainer>
              </div>
            </Fragment>
          ) : (
            <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
              <LinkContainer to={"/contractor/jobs/"}>
                <Button bsStyle="info">Search Jobs</Button>
              </LinkContainer>
            </div>
          )
        ) : (
          <div className="text-center">
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

const competencyQuestionsQuery = candidateId =>
  createResourceListQuery(types.COMPETENCY_QUESTIONS, {
    url: `/api/v2/competency_questions/?candidate_not_completed=${candidateId}&limit=999`,
    force: true,
    replace: true,
  });

const mapPropsToConfig = ({ candidateId }) => [
  competencyQuestionsQuery(candidateId),
];

const competencyQuestionsSelector = createResourceSelectorConfig(
  types.COMPETENCY_QUESTIONS,
  competencyQuestionsQuery,
);

const mapStatetoProps = createStructuredSelector({
  ...competencyQuestionsSelector,
});

export default compose(
  connect(mapStatetoProps),
  connectRequest(mapPropsToConfig),
)(ApplicationSubmittedDisplay);
