import React from "react";
import PropTypes from "prop-types";
import ConfirmModal from "hiringagent-dashboard/components/ConfirmModal";
import VerifyBankDepositsForm from "hiringagent-dashboard/forms/VerifyBankDepositsForm";

const VerifyBankDepositsModalContent = props => {
  const { paymentSource } = props;
  return (
    <div>
      <p className="form-description">
        You will receive 2 microdeposits to your bank account within 3-4
        business days from entering your bank account information. Please enter
        them below to verify your bank account.
      </p>
      {paymentSource && (
        <dl className="dl-horizontal">
          <dt>Bank</dt>
          <dd>{paymentSource.bank_name}</dd>
          <dt>Account Number</dt>
          <dd>Ending in {paymentSource.last4}</dd>
          <dt>Routing Number</dt>
          <dd>{paymentSource.routing_number}</dd>
          <dt>Verification</dt>
          <dd>
            {paymentSource.status === "new" ? (
              <span className="error">Not Verified</span>
            ) : (
              <span>Verified</span>
            )}
          </dd>
        </dl>
      )}

      <VerifyBankDepositsForm closeForm={props.onModalClose} />
    </div>
  );
};

const VerifyBankDepositsModal = props => {
  const { paymentSource } = props;
  return (
    <ConfirmModal
      {...props}
      body={<VerifyBankDepositsModalContent {...props} />}
      title={`Verify Bank Account${
        paymentSource ? ` ending in ${paymentSource.last4}` : ""
      }`}
      showFooter={false}
    />
  );
};

VerifyBankDepositsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  actions: PropTypes.element,
  paymentSource: PropTypes.object,
  onModalClose: PropTypes.func.isRequired,
};
export default VerifyBankDepositsModal;
