import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Button, ButtonGroup, Form } from "react-bootstrap";

import { DatePickerField, TextField } from "common/components/forms/inputs";

class VerificationSearchForm extends Component {
  handleReset = () => {
    this.props.reset();
    setTimeout(this.props.handleSubmit);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Form
        onSubmit={handleSubmit}
        className="verification-search-form"
        autoComplete="off"
        inline
      >
        <DatePickerField
          name="start_date"
          placeholderText="Created After"
          bsSize="small"
          showMonthDropdown
          showYearDropdown
          todayButton={"Today"}
          dropdownMode="select"
        />&nbsp;
        <DatePickerField
          name="end_date"
          placeholderText="Created Before"
          bsSize="small"
          showMonthDropdown
          showYearDropdown
          todayButton={"Today"}
          dropdownMode="select"
        />&nbsp;
        <TextField name="text" placeholder="Text" bsSize="small" />&nbsp;
        <ButtonGroup bsSize="small">
          <Button type="submit">
            <i className="far fa-fw fa-search" />&nbsp;Search
          </Button>
          <Button onClick={this.handleReset}>
            <i className="far fa-fw fa-times" />
          </Button>
        </ButtonGroup>
      </Form>
    );
  }
}

export default reduxForm({
  form: "verificationSearchForm",
})(VerificationSearchForm);
