import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { Link } from "react-router-dom";
import { change, formValueSelector, submit } from "redux-form";
import {
  Alert,
  DropdownButton,
  ListGroup,
  ListGroupItem,
  MenuItem,
} from "react-bootstrap";
import moment from "moment";
import { createStructuredSelector } from "reselect";

import { Loading, Pagination } from "common/components";
import { withPositionTypes, withUserType } from "common/connectors";
import types from "resources/types";
import { createResourceListQuery, createResourceSelector } from "queries";
import { updateHistoryQuery } from "public/utils/search";

// TODO: Replace with render props component once implemented
let JobPrimaryPracticeArea = props => {
  const {
    [types.TAG_GROUPS]: { data, isFinished },
    primary_practice_area,
  } = props;

  return (
    <span>
      {isFinished &&
        data[primary_practice_area] &&
        data[primary_practice_area].name}
    </span>
  );
};
const mapPracticeAreaPropsToConfig = () =>
  createResourceListQuery(types.TAG_GROUPS, {
    url: "/api/dev/tag_groups/?limit=999",
  });
JobPrimaryPracticeArea = compose(
  connect(
    createResourceSelector(types.TAG_GROUPS, mapPracticeAreaPropsToConfig),
  ),
  connectRequest(mapPracticeAreaPropsToConfig),
)(JobPrimaryPracticeArea);

let JobExpertiseAreas = props => {
  const {
    [types.TAGS]: { data, isFinished },
    expertisearea_set,
  } = props;

  return (
    <div>
      {isFinished &&
        expertisearea_set.map(expertiseArea => (
          <Fragment key={`expertise-area-${expertiseArea.uuid}`}>
            <span className="expertise-area">
              {data[expertiseArea.tag].name}
            </span>{" "}
          </Fragment>
        ))}
    </div>
  );
};
const mapExpertiseAreasPropsToConfig = () =>
  createResourceListQuery(types.TAGS, {
    url: "/api/dev/tags/?limit=999",
  });
JobExpertiseAreas = compose(
  connect(createResourceSelector(types.TAGS, mapExpertiseAreasPropsToConfig)),
  connectRequest(mapExpertiseAreasPropsToConfig),
)(JobExpertiseAreas);

const PaddedBullet = () => <span> &bull; </span>;
const NoResults = () => (
  <div>
    <p>
      We couldn&apos;t find any results matching the specified filters. Try
      broadening your search!
    </p>
    <Alert bsStyle="info">
      <i className="far fa-info-circle" />&nbsp;Most jobs published on Hire an
      Esquire are not listed in search for privacy reasons. Rest assured, if you
      are a good fit for a private job listing, our system will automatically
      alert you.
    </Alert>
  </div>
);

class JobSearchResult extends Component {
  render() {
    const {
      job,
      current_user_type: {
        data: { user_type },
      },
    } = this.props;
    const isContractor = user_type === "contractor";
    const jobDetailsUrl = isContractor
      ? `/contractor/jobs/${job.job}/`
      : `/public/jobs/${job.job}/`;

    return (
      <ListGroupItem
        key={`job-search-result-${job.uuid}`}
        className="job-search-result"
      >
        <div>
          <h4 style={{ marginBottom: "1.5rem" }}>
            <Link to={jobDetailsUrl}>{job.title}</Link>
          </h4>
        </div>
        <div className="job-attributes text-muted">
          <Fragment>
            {job.remote ? "Remote" : "On-site"}
            <PaddedBullet />
          </Fragment>
          {!job.remote && (
            <Fragment>
              {job.joblistinglocation_set.map(location => (
                <Fragment key={`job-location-${location.uuid}`}>
                  {location.city}, {location.state}
                  <PaddedBullet />
                </Fragment>
              ))}
            </Fragment>
          )}
          <Fragment>
            <JobPrimaryPracticeArea {...job} />
            <PaddedBullet />
          </Fragment>
          {job.allowed_applicants === "attorneys" && (
            <Fragment>
              <span>Bar license required</span>
              <PaddedBullet />
            </Fragment>
          )}
          <span>Published {moment(job.published_date).fromNow()}</span>
        </div>
        <div className="expertise-areas">
          <JobExpertiseAreas expertisearea_set={job.expertisearea_set} />
        </div>
      </ListGroupItem>
    );
  }
}
JobSearchResult = withUserType()(JobSearchResult);

const JobSearchResultsList = ({ jobs }) => (
  <ListGroup>
    {jobs.map(job => (
      <JobSearchResult job={job} key={`search-result-${job.uuid}`} />
    ))}
  </ListGroup>
);

const sortingOptions = {
  "-published_date": "Date Published",
  title: "Alphabetical",
};

const SortingDropdown = ({ onClick, ordering }) => (
  <DropdownButton
    title={`Sort By: ${sortingOptions[ordering]}`}
    id="search-results-sorting-menu"
  >
    {Object.entries(sortingOptions).map(([param, label], i) => (
      <MenuItem
        eventKey={i + 1}
        active={ordering === param}
        onClick={() => onClick(param)}
        key={`sorting-option-${param}`}
      >
        {label}
      </MenuItem>
    ))}
  </DropdownButton>
);

class JobSearchResults extends Component {
  handlePageSelect = pageNumber => {
    const { formValues, changeFieldValue, submitSearchForm } = this.props;
    const newOffset = pageNumber * formValues.limit - formValues.limit;
    changeFieldValue("offset", newOffset);
    updateHistoryQuery({ ...formValues, offset: newOffset });
    setTimeout(submitSearchForm);
  };

  handleRefreshClick = e => {
    e.preventDefault();
    this.props.submitSearchForm();
  };

  handleSortClick = sort => {
    const { changeFieldValue, formValues, submitSearchForm } = this.props;
    changeFieldValue("ordering", sort);
    changeFieldValue("offset", 0);
    updateHistoryQuery({ ...formValues, ordering: sort, offset: 0 });
    setTimeout(submitSearchForm);
  };

  render() {
    const {
      searchResults: {
        data: searchResultsData,
        isFinished,
        isPending,
        query: { count: searchResultsCount, data: searchResultsOrdering },
      },
      formValues: { limit, offset, ordering },
    } = this.props;
    const numPages = Math.ceil(searchResultsCount / limit);
    return (
      <div className="job-search-results">
        <h2 className="clearfix">
          <span className="number-of-results">
            {isFinished && <span>{searchResultsCount}&nbsp;</span>}Results&nbsp;
            <small>
              <a href="" onClick={this.handleRefreshClick}>
                refresh
              </a>
            </small>
          </span>
          <span className="sorting-options">
            <SortingDropdown
              ordering={ordering}
              onClick={this.handleSortClick}
            />
          </span>
        </h2>
        {isPending ? (
          <Loading />
        ) : (
          isFinished &&
          (searchResultsCount > 0 ? (
            <div>
              <JobSearchResultsList
                jobs={searchResultsOrdering.map(id => searchResultsData[id])}
              />
              {numPages > 1 && (
                <div className="text-center">
                  <Pagination
                    currentPage={offset / limit + 1}
                    totalPages={numPages}
                    onChange={this.handlePageSelect}
                  />
                </div>
              )}
            </div>
          ) : (
            <NoResults />
          ))
        )}
      </div>
    );
  }
}

const formSelector = formValueSelector("jobSearchForm");
const specificFormValueSelector = field => state => formSelector(state, field);
const mapStateToProps = createStructuredSelector({
  formValues: createStructuredSelector({
    keywords: specificFormValueSelector("keywords"),
    location: specificFormValueSelector("location"),
    limit: specificFormValueSelector("limit"),
    offset: specificFormValueSelector("offset"),
    ordering: specificFormValueSelector("ordering"),
  }),
});
const mapDispatchToProps = {
  submitSearchForm: () => submit("jobSearchForm"),
  changeFieldValue: (field, value) => change("jobSearchForm", field, value),
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPositionTypes(),
)(JobSearchResults);
