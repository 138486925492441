import algoliasearch from "algoliasearch";

export const client = () =>
  algoliasearch("ZTY5PO4NHU", process.env.REACT_APP_ALGOLIA_SEARCH_KEY);

export const algolia = (index = "hae_index") =>
  client(process.env.REACT_APP_ALGOLIA_SEARCH_KEY).initIndex(index);

export const basicAlgoliaArgs = verified => {
  const args = {
    facets: "*",
    facetFilters: [],
    numericFilters: [],
    attributesToRetrieve: [
      "summary",
      "summary_title",
      "_is_via",
      "_index_education",
      "_index_previousemployment_list",
      "_index_practice_areas",
      "_index_city_state",
      "_index_years_experience",
      "_index_barred_in_list",
      "_index_language_proficiency_list",
      "_index_num_alist",
      "_index_patent_bar",
      "_index_profile_completion",
      "_index_rate_info",
      "_index_availability",
      "_index_firm_types",
      "_index_is_available",
      "display_score",
      "overall_ranking",
      "last_name",
      "contractor_type",
      "_index_last_activity_at",
      "full_last_name",
    ],
    attributesToHighlight: [
      "summary",
      "summary_title",
      "_index_education.name",
      "_index_previousemployment_list.description",
      "_index_previousemployment_list.jobTitle",
      "_index_previousemployment_list.typeOfWork",
      "_index_previousemployment_list.company",
      "_index_practice_areas",
      "_index_barred_in_list",
      "_index_language_proficiency_list",
      "_index_city_state",
      "_index_availability",
      "_index_firm_types",
      "full_last_name",
      "last_name",
      "display_score",
      "overall_ranking",
    ],
    disableTypoToleranceOnAttributes: [
      "_index_practice_areas",
      "_index_practice_area_groups",
    ],
  };

  if (verified) {
    args.attributesToRetrieve.push("_index_profile_picture_url");
    args.attributesToRetrieve.push("first_name");
    args.attributesToHighlight.push("first_name");
  } else {
    args.attributesToRetrieve.push("first_name_initial");
    args.attributesToHighlight.push("first_name_initial");
  }
  return args;
};
