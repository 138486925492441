import React, { Component } from "react";
import ReactSelect from "react-select";
import { ControlLabel, FormGroup } from "react-bootstrap";
import FieldHelpBlock from "./FieldHelpBlock";

import { validationState } from "common/utils/forms";

export default class ReactSelectFormControlGroup extends Component {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(v) {
    const { input, multi, customOnChange } = this.props;
    input.onChange(multi ? v.map(r => r.value) : v ? v.value : "");

    if (customOnChange) customOnChange(v);
  }

  handleBlur() {
    const { input } = this.props;
    input.onBlur(input.value);
  }

  render() {
    const {
      // redux-form Field props
      input,
      meta,
      // FormControlGroup props
      label,
      bsSize,
      maxLength = null,
      // props passed through to FormControl
      ...otherProps
    } = this.props;

    return (
      <FormGroup
        validationState={validationState(meta, maxLength)}
        bsSize={bsSize}
      >
        {label && <ControlLabel>{label}</ControlLabel>}
        <ReactSelect
          {...input}
          onBlurResetsInput={false}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          value={input.value}
          {...otherProps}
        />
        {maxLength && (
          <div className="text-right">
            {input.value.length}/{maxLength}
          </div>
        )}
        <FieldHelpBlock touched={meta.touched} error={meta.error} />
      </FormGroup>
    );
  }
}
