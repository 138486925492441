// @flow
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import moment from "moment";
import { Table } from "react-bootstrap";

import { humanFileSize } from "common/utils/helpers";
import Loading from "common/components/Loading";
import { createResourceListQuery, createResourceSelector } from "queries";
import types from "resources/types";
import { type Uuid } from "types/primitives";

type Props = {
  jobId: Uuid,
  onDeleteFileClick?: Function,
  showCreated: boolean,
};

class JobAttachmentsList extends Component<Props> {
  static defaultProps = {
    showCreated: false,
    showLoading: false,
  };

  render() {
    const {
      [types.JOB_ATTACHMENTS]: {
        selectedData: attachments,
        isFinished,
        isPending,
      },
      onDeleteFileClick,
      showCreated,
    } = this.props;

    return (
      <div>
        {isFinished && (
          <Table striped responsive>
            <tbody>
              {attachments.map(attachment => (
                <tr key={`job-attachment-${attachment.uuid}`}>
                  <td>
                    <a href={attachment.single_use_url} download>
                      {attachment.name}
                    </a>
                  </td>
                  <td style={{ whiteSpace: "pre" }}>
                    {humanFileSize(attachment.size)}
                  </td>
                  {showCreated && (
                    <td>{moment(attachment.created).format("lll")}</td>
                  )}
                  {onDeleteFileClick && (
                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => onDeleteFileClick(attachment.uuid)}
                        className="far fa-times"
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {isPending && <Loading />}
      </div>
    );
  }
}

type JobAttachment = { job: Uuid };

const mapPropsToConfig = props =>
  createResourceListQuery(types.JOB_ATTACHMENTS, {
    url: `/api/v2/job_attachments/?job=${props.jobId}&limit=99`,
  });
const filterData = (data, state, props) =>
  Object.values(data).filter(attachment => {
    // If the following looks silly, here's why: https://github.com/facebook/flow/issues/2174
    const attach: JobAttachment = (attachment: any);
    return attach.job === props.jobId;
  });

const mapStateToProps = createResourceSelector(
  types.JOB_ATTACHMENTS,
  mapPropsToConfig,
  filterData,
);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobAttachmentsList);
