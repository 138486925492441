import React, { Component } from "react";
import { requestAsync, mutateAsync } from "redux-query";
import { connectRequest } from "redux-query-react";
import { connect, compose } from "resources/components/utils";
import { Col, Row } from "react-bootstrap";
import { reset, SubmissionError } from "redux-form";
import { addNotification as notify } from "reapop";

import { createExpense } from "resources/actions/timeTracker";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { getCannotAddEntryReason } from "contractor/components/utils/timeTracker";
import { ExpensesList } from "contractor/components/time-tracker";
import { CannotAddEntriesMsg } from "contractor/components/time-tracker/Messages";
import ExpensesForm, { formName } from "contractor/forms/ExpensesForm";
import DeleteModal from "contractor/components/time-tracker/DeleteModal";
import Loading from "common/components/Loading";

import {
  createResourceListQuery,
  createResourceSelector,
  deleteResourceQuery,
} from "queries";
import types from "resources/types";

const CannotAddEntry = ({ workerIsActive, jobIsClosed }) => {
  const reason = getCannotAddEntryReason(workerIsActive, jobIsClosed);
  return <CannotAddEntriesMsg reason={reason} />;
};

class Expenses extends Component {
  state = {
    deletingExpenseId: null,
  };

  handleCloseModalClick = () => this.setState({ deletingExpenseId: null });

  handleDeleteClick = expenseId =>
    this.setState({ deletingExpenseId: expenseId });

  handleDeleteConfirmClick = () => {
    const { notify, refreshExpenses, deleteExpense } = this.props;
    deleteExpense(this.state.deletingExpenseId).then(() => {
      this.setState({ deletingExpenseId: null });
      refreshExpenses(this.props);
      notify(
        createSuccessNotification({ message: "Expense has been deleted." }),
      );
    });
  };

  handleSubmit = values => {
    const { resetForm, notify, onSubmit, refreshExpenses } = this.props;
    const createExpenseUrl = "/api/dev/expenses/";
    const formData = this.getFormData(values);

    return onSubmit(createExpenseUrl, formData).then(({ json, response }) => {
      if (!response.ok) {
        notify(
          createErrorNotification({
            message: json.detail || "Please correct the errors below.",
          }),
        );
        throw new SubmissionError(json);
      }
      refreshExpenses(this.props);
      notify(createSuccessNotification({ message: "Expense has been saved." }));
      resetForm();
    });
  };

  getFormData(formValues) {
    const fd = new FormData();
    Object.entries(formValues).forEach(field => {
      const [key, value] = field;
      if (key === "attachment") {
        fd.append("attachment", value[0]);
      } else {
        fd.append(key, value);
      }
    });
    return fd;
  }

  render() {
    const {
      worker: { active: workerIsActive, uuid: workerId },
      [types.EXPENSES]: { isFinished: expensesFinished, data: expenses },
      paymentDetail,
      job: { closed_date, title },
    } = this.props;
    const canAddExpense =
      workerIsActive && !closed_date && paymentDetail.allow_expenses;

    return (
      <div>
        <h1>
          Track Expenses<span className="small">{` for ${title}`}</span>
        </h1>
        <Row>
          <Col sm={4}>
            <h3>Add Expense</h3>
            {!canAddExpense ? (
              <CannotAddEntry
                workerIsActive={workerIsActive}
                jobIsClosed={closed_date}
              />
            ) : (
              <ExpensesForm
                initialValues={{ worker: workerId }}
                onSubmit={this.handleSubmit}
              />
            )}
          </Col>
          <Col sm={7} smOffset={1}>
            <h3>Submitted Expenses</h3>
            {expensesFinished ? (
              <ExpensesList
                expenses={expenses}
                onDeleteClick={this.handleDeleteClick}
              />
            ) : (
              <Loading />
            )}
          </Col>
        </Row>

        <DeleteModal
          show={Boolean(this.state.deletingExpenseId)}
          onSubmit={this.handleDeleteConfirmClick}
          onClose={this.handleCloseModalClick}
        />
      </div>
    );
  }
}

const expensesQuery = props =>
  createResourceListQuery(types.EXPENSES, {
    url: `/api/dev/expenses/?worker__job=${props.job.uuid}&limit=9999`,
    force: true,
  });

const mapPropsToConfig = props => [expensesQuery(props)];

const deleteExpenseQuery = id =>
  deleteResourceQuery(
    types.EXPENSES,
    {
      url: `/api/dev/expenses/${id}/`,
      options: {
        method: "DELETE",
      },
    },
    id,
  );

export default (Expenses = compose(
  connectRequest(mapPropsToConfig),
  connect(
    createResourceSelector(types.EXPENSES, expensesQuery),
    {
      notify,
      resetForm: () => reset(formName),
      onSubmit: createExpense,
      deleteExpense: id => mutateAsync(deleteExpenseQuery(id)),
      refreshExpenses: props => requestAsync(expensesQuery(props)),
    },
  ),
)(Expenses));
