// @flow strict
import React from "react";

import DragDrop from "./blocks";
import { IconLock } from "./Icons";

type Props = {
  locked: Array<{ uuid: Uuid, statement: string }>,
};

const LockedItemGroup = ({ locked }: Props) => (
  <DragDrop.ItemGroup locked={true}>
    {locked.map((item, index) => (
      <DragDrop.Item itemType="locked" key={index}>
        <DragDrop.ItemText>{item.statement}</DragDrop.ItemText>
        <IconLock />
      </DragDrop.Item>
    ))}
  </DragDrop.ItemGroup>
);

export default LockedItemGroup;
