import styled from "styled-components";

const Bottom = styled.div`
  margin: 5px 0 0 0;
  padding: 0 16px 0 0;

  ${({ theme }) => theme.media.mobile`
    padding: 0 14px 0 0;
  `};
`;

export default Bottom;
