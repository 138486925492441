import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => theme.media.sm`
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 50px;
  `};
`;
const Sidebar = styled.div`
  margin-bottom: 10px;
`;
export const NotesTooltip = styled.div`
  font-style: italic;
  color: #999;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin-top: 5px;
`;

export const talentPoolButtonStyles = css`
  &.btn {
    &,
    &:active,
    &&:focus,
    &:hover {
      border-color: ${({ theme }) => theme.colors.success()};
    }
    border-width: 1px;
    max-width: 300px;
  }
  + .dropdown-menu {
    width: 100%;
    max-width: 300px;
  }
`;

export default {
  Container,
  Sidebar,
};
