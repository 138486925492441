import React from "react";
import moment from "moment";
import { Table } from "react-bootstrap";

const AttorneySearchHistoryResult = props => (
  <Table striped bordered>
    <thead>
      <tr>
        <th className="text-center">User</th>
        <th className="text-center">Time</th>
        <th className="text-center">Keywords</th>
        <th className="text-center">Rate</th>
        <th className="text-center">Within</th>
        <th className="text-center">Telecommute</th>
        <th className="text-center">W2</th>
        <th className="text-center">1099</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(props.searchHistories).map(search => (
        <tr key={`stats-row-${search}`}>
          <td className="text-left">{`${props.searchHistories[search].haeuser.first_name} ${props.searchHistories[search].haeuser.last_name}`}</td>
          <td className="text-left">
            {moment(
              props.searchHistories[search].created,
              moment.ISO_8601,
            ).format("LLLL")}
          </td>
          <td className="text-left">
            {props.searchHistories[search].keywords}
          </td>
          <td className="text-left">
            {props.searchHistories[search].attorney_params.rate}
          </td>
          <td className="text-left">
            {props.searchHistories[search].attorney_params.within}
          </td>
          <td className="text-left">
            {props.searchHistories[search].attorney_params.telecommute_only
              ? "True"
              : ""}
          </td>
          <td className="text-left">
            {props.searchHistories[search].attorney_params.irs_w2 ? "True" : ""}
          </td>
          <td className="text-left">
            {props.searchHistories[search].attorney_params.irs_1099
              ? "True"
              : ""}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default AttorneySearchHistoryResult;
