import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

import {
  JobDescriptionSection,
  JobDetailsRow,
  JobExpertiseAreasDisplay,
  JobLanguagesDisplay,
  JobLocationDisplay,
  JobPrimaryPracticeArea,
  JobRequirementsSection,
} from "common/components/jobs";
import LanguageTooltip from "common/components/LanguageTooltip";
import PositionTypesList from "common/components/PositionTypesList";
import ToolTip from "common/components/ToolTip";
import { ErrorBoundary } from "common/components/error-boundary";

const rateTooltip = (
  <ToolTip
    content={
      "Join Hire an Esquire to unlock rates and other additional details for jobs."
    }
  />
);

const rateTitle = <span>Rate {rateTooltip}</span>;

const languageTitle = (
  <span>
    Language Requirements{" "}
    <LanguageTooltip id={"public-job-details-languages"} />
  </span>
);

export const PublicJobDetailsInfoRows = ({ jobListing }) => (
  <ErrorBoundary>
    <h2 className="main-info-title" style={{ marginBottom: "1rem" }}>
      Details
    </h2>
    <JobDetailsRow itemProp="datePosted" title="Published">
      {jobListing.published_date
        ? moment(jobListing.published_date).format("ll")
        : "Job is not published (not searchable)"}
    </JobDetailsRow>
    <JobDetailsRow title="Status">{jobListing.statusDisplay}</JobDetailsRow>
    {typeof jobListing.project_length === "number" && (
      <JobDetailsRow title="Expected duration">
        {jobListing.projectLengthDisplay}
      </JobDetailsRow>
    )}
    {jobListing.start_date && (
      <JobDetailsRow title="Estimated start date">
        {moment(jobListing.start_date).format("ll")}
      </JobDetailsRow>
    )}
    <JobDetailsRow title={rateTitle}>
      <Link to="/signup/">See rates for all jobs</Link>
    </JobDetailsRow>
    {jobListing.position_type && (
      <JobDetailsRow title="Position type">
        <PositionTypesList positionTypeIds={[jobListing.position_type]} />
      </JobDetailsRow>
    )}
    <JobDetailsRow title="Experience">
      {jobListing.experienceDisplay}
    </JobDetailsRow>
    <JobDetailsRow title="Can work remotely">
      <Fragment>{jobListing.remote ? "Yes" : "No"}</Fragment>
      {jobListing.remote && (
        <div style={{ display: "none" }}>
          <span itemProp="jobLocationType">TELECOMMUTE</span>
          <span
            itemProp="applicantLocationRequirements"
            itemScope
            itemType="http://schema.org/Country"
          >
            <span itemProp="name">USA</span>
          </span>
        </div>
      )}
    </JobDetailsRow>
    {jobListing.joblistinglocation_set.length > 0 && (
      <JobDetailsRow title="Locations">
        <JobLocationDisplay locations={jobListing.joblistinglocation_set} />
      </JobDetailsRow>
    )}
    <JobDetailsRow title="Primary practice area">
      <JobPrimaryPracticeArea {...jobListing} />
    </JobDetailsRow>
    <JobDetailsRow title="Expertise areas">
      <JobExpertiseAreasDisplay jobAreas={jobListing.expertisearea_set} />
    </JobDetailsRow>
    {jobListing.joblistinglanguage_set.length > 0 && (
      <JobDetailsRow title={languageTitle}>
        <JobLanguagesDisplay jobListing={jobListing} />
      </JobDetailsRow>
    )}
    <JobDetailsRow title="Employment Type">
      <span style={{ display: "none" }} itemProp="employmentType">
        CONTRACTOR
      </span>
      <span>Contract</span>
    </JobDetailsRow>

    <span className="hidden" itemProp="hiringOrganization">
      Hire an Esquire
    </span>
    <span className="hidden" itemProp="industry">
      Law
    </span>
  </ErrorBoundary>
);

const PublicJobDetails = ({ jobListing }) => (
  <div
    className="main-info-container job-details"
    style={{ margin: "1.5rem 0" }}
  >
    <Row>
      <Col sm={7} lg={8}>
        <JobRequirementsSection jobListing={jobListing} />
        <JobDescriptionSection jobListing={jobListing} />
      </Col>
      <Col sm={5} lg={4}>
        <PublicJobDetailsInfoRows jobListing={jobListing} />
      </Col>
    </Row>
  </div>
);
export default PublicJobDetails;
