import React, { Component } from "react";
import { Table } from "react-bootstrap";

import { createApiUrl } from "utils";
import { formatDate } from "admin/utils/index";

export default class OpenInvoices extends Component {
  render() {
    const { loading, invoices, jobs } = this.props;
    const cellLoading = <i className="fab fa-empirear fa-spin" />;

    return (
      <Table condensed striped>
        <thead>
          <tr>
            <th>Job</th>
            <th>Client</th>
            <th className="number">Invoice #</th>
            <th>Invoice Period</th>
            <th>Due Date</th>
            <th className="decimal">Subtotal</th>
            <th className="decimal">Total</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            Object.values(invoices).map(invoice => (
              <tr key={invoice.uuid}>
                <td>
                  {jobs[invoice.job] ? jobs[invoice.job].title : cellLoading}
                </td>
                <td>{invoice.hiring_agency_name}</td>
                <td className="number">
                  <a
                    href={createApiUrl(
                      `/hae_admin/invoicing/invoice/${invoice.uuid}/change/`,
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {invoice.invoice_number}
                  </a>
                </td>
                <td>
                  {formatDate(invoice.start_date)} &mdash;{" "}
                  {formatDate(invoice.end_date)}
                </td>
                <td>{formatDate(invoice.due_date)}</td>
                <td className="decimal">${invoice.subtotal}</td>
                <td className="decimal">${invoice.total}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}
