import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import { addNotification as notify } from "reapop";

import {
  deleteBarcardVerification,
  removeBarcardVerification,
  submitCreateBarcardVerification,
  submitBarcardVerification,
} from "admin/actions/attorneyVerificationManager";
import {
  withBarcardVerificationSet,
  withAttorneyVerificationManager,
} from "admin/connectors";
import { barcardVerificationStatusMapping } from "contractor/utils/onboarding";
import {
  VerificationStatusText,
  VerificationActions,
} from "admin/components/attorney-verification-manager/utils";
import LoadingOr from "common/components/LoadingOr";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";

const FileLink = ({ fileInfo }) => (
  <a href={`${fileInfo.file_url}`} target="_blank" rel="noopener noreferrer">
    {fileInfo.file_name}
  </a>
);

class BarcardVerificationsList extends Component {
  render() {
    const { onDelete, onDeny, onRequest, onVerify, verifications } = this.props;

    return (
      <div>
        <Table className="verifications-list-table">
          <thead>
            <tr>
              <th>Barcard</th>
              <th>Status</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Actions</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {verifications.map(verification => (
              <tr key={verification.uuid}>
                <td>
                  {verification.barcard_file ? (
                    <FileLink fileInfo={verification.barcard_file} />
                  ) : (
                    "Waiting for upload..."
                  )}
                </td>
                <td>
                  <VerificationStatusText
                    {...barcardVerificationStatusMapping[verification.state]}
                    status={verification.state}
                  />
                </td>
                <td>{moment(verification.created).format("L")}</td>
                <td>{moment(verification.updated).format("L")}</td>
                <td>
                  <VerificationActions
                    onDeny={() => onDeny(verification.uuid)}
                    onRequest={() => onRequest(verification.uuid)}
                    onVerify={() => onVerify(verification.uuid)}
                    state={verification.state}
                  />
                </td>
                <td>
                  {
                    <Button
                      bsStyle="danger"
                      bsSize="small"
                      onClick={() => onDelete(verification.uuid)}
                    >
                      <i className="fas fa-trash-alt" />
                    </Button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {verifications.length === 0 && (
          <div className="text-center">No Barcard Verifications</div>
        )}
      </div>
    );
  }
}

class BarcardVerifications extends Component {
  createSuccessNotification = verb =>
    createSuccessNotification({
      message: `Barcard verification successfully ${verb}.`,
    });

  handleAddBarcardClick = barInfoId => {
    const data = { barinformation: barInfoId };
    this.props.submitCreateBarcardVerification(data).then(({ response }) => {
      if (!response.ok) {
        const message = `Encountered an error when trying to add barcard verification.
                         Please refresh and try again.`;
        this.props.notify(createErrorNotification({ message }));
      }
    });
  };

  handleDelete = verificationId => {
    const { notify, onDelete, removeBarcardVerification } = this.props;
    onDelete(verificationId)
      .then(removeBarcardVerification(verificationId))
      .then(notify(this.createSuccessNotification("deleted")));
  };

  handleDeny = verificationId => {
    const { notify, submitBarcardVerification } = this.props;
    submitBarcardVerification(verificationId, { action: "deny" }).then(
      notify(this.createSuccessNotification("denied")),
    );
  };

  handleRequest = verificationId => {
    const { notify, submitBarcardVerification } = this.props;
    submitBarcardVerification(verificationId, {
      action: "request_barcard",
    }).then(notify(this.createSuccessNotification("requested")));
  };

  handleVerify = verificationId => {
    const { notify, submitBarcardVerification } = this.props;
    submitBarcardVerification(verificationId, { action: "verify" }).then(
      notify(this.createSuccessNotification("verified")),
    );
  };

  render() {
    const {
      barcardVerifications = {},
      barcardVerificationSet: { ready },
      attorneyVerificationManager: { selectedBarInformationId },
    } = this.props;
    const verifications = Object.values(barcardVerifications).filter(
      verification => verification.barinformation === selectedBarInformationId,
    );

    return (
      <LoadingOr showLoading={!ready}>
        <div>
          <BarcardVerificationsList
            onDelete={this.handleDelete}
            onDeny={this.handleDeny}
            onRequest={this.handleRequest}
            onVerify={this.handleVerify}
            verifications={verifications}
          />
          <div style={{ marginBottom: "2rem" }}>
            <Button
              bsStyle="success"
              bsSize="small"
              onClick={() =>
                this.handleAddBarcardClick(selectedBarInformationId)
              }
            >
              <i className="far fa-plus" aria-hidden="true" /> Add Barcard
            </Button>
          </div>
        </div>
      </LoadingOr>
    );
  }
}

export default compose(
  connect(
    state => ({
      barcardVerifications:
        state.admin.barcardVerifications.barcardVerifications,
    }),
    {
      notify,
      onDelete: deleteBarcardVerification,
      removeBarcardVerification,
      submitBarcardVerification,
      submitCreateBarcardVerification,
    },
  ),
  withAttorneyVerificationManager(),
  withBarcardVerificationSet(
    ({ attorneyVerificationManager: { selectedBarInformationId } }) => ({
      path: `barcard_verifications/?bar_info=${selectedBarInformationId}`,
    }),
  ),
)(BarcardVerifications);
