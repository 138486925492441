import actions from "hiringagent-dashboard/actions/recommendations";
import { handleActions } from "redux-actions";
import { listToObject } from "admin/utils";

const initialState = {
  recommendations: {},
  isLoading: false,
  lastUpdated: null,
};

const recommendations = handleActions(
  {
    [actions.REQUEST_RECOMMENDATIONS]: state => ({
      ...state,
      isLoading: true,
    }),
    // TODO: Break recommendations for job out into separate state slice
    // TODO: Do not use listToObject to ensure recommendations for job retain order from backend
    [actions.RECEIVE_RECOMMENDATIONS]: (state, action) => ({
      ...state,
      recommendations: listToObject(action.payload.results, "uuid"),
      lastUpdated: action.meta.receivedAt,
      isLoading: false,
    }),
    [actions.REQUEST_INVITE_RECOMMENDATION]: (state, action) => {
      const newRecs = { ...state.recommendations };
      if (newRecs && action.payload && newRecs[action.payload]) {
        newRecs[action.payload].invited = true;
      }
      return {
        ...state,
        recommendations: newRecs,
      };
    },
    [actions.REQUEST_DISMISS_RECOMMENDATION_WITH_FEEDBACK]: (state, action) => {
      const newRecommendations = { ...state.recommendations };
      newRecommendations[action.payload].dismissed = true;
      return {
        ...state,
        recommendations: newRecommendations,
      };
    },
    [actions.DISMISS_RECOMMENDATION]: (state, action) => {
      const newRecommendations = { ...state.recommendations };
      delete newRecommendations[action.payload];
      return {
        ...state,
        recommendations: newRecommendations,
        lastUpdated: action.meta.receivedAt,
        isLoading: false,
      };
    },
  },
  initialState,
);
export default recommendations;
