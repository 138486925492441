import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { Alert, ListGroup, ListGroupItem } from "react-bootstrap";

import { CheckboxField, TextAreaField } from "common/components/forms/inputs";
import FormButton from "common/components/FormButton";
import NullOr from "common/components/NullOr";

export const formName = "applicationForm";
export const requiredFields = [
  "job",
  "cover_letter",
  "agreed_bill_through_hae",
  "agreed_no_disciplinary_action",
];

const termsOfServiceLink = (
  <Link
    to={"/public/terms-of-service/"}
    target="_blank"
    rel="noopener noreferrer"
  >
    Terms of Service{" "}
    <i className="fas fa-external-link-alt" aria-hidden="true" />
  </Link>
);

const profileUpdateLink = (
  <Link
    to={"/contractor/profile/edit/"}
    target="_blank"
    rel="noopener noreferrer"
  >
    here
  </Link>
);

const agreedBillThroughHaeLabel = (
  <span>
    I agree to use the Hire an Esquire billing system to receive compensation
    for all work performed for law practices/clients identified through Hire an
    Esquire in accordance with <span>{termsOfServiceLink}</span>.
  </span>
);

const agreedNoDisciplinaryActionLabel = (
  <span>
    I verify that I have no known pending disciplinary actions in any state. I
    also verify that I have no past disciplinary history with any bar
    association unless noted on my profile.
  </span>
);

const jobRequirementsInstructions = (
  <p>
    Please answer the questions below to the best of your ability. You are not
    required to possess all the listed qualifications in order to apply to this
    position, however all answers should be accurate and equivalently
    represented in your profile. If any information on your profile is
    inaccurate or otherwise needs to be updated, please update it{" "}
    {profileUpdateLink}.
  </p>
);

const JobRequirements = ({ requirements }) => {
  const primaryPracticeAreaRequirementCategory =
    "Primary Practice Area Requirement";
  const coreRequirements = requirements.filter(
    req => req.category !== primaryPracticeAreaRequirementCategory,
  );
  const primaryPracticeAreaRequirements = requirements.filter(
    req => req.category === primaryPracticeAreaRequirementCategory,
  );
  return (
    <NullOr isNull={requirements && requirements.length === 0}>
      <div className="application-requirements">
        {jobRequirementsInstructions}
        <ListGroup>
          {coreRequirements.map(requirement => (
            <ListGroupItem key={requirement.uuid}>
              <CheckboxField
                name={requirement.uuid}
                label={requirement.question}
              />
            </ListGroupItem>
          ))}
        </ListGroup>
        <p>
          Indicate if you have experience with or are capable of performing the
          following tasks:
        </p>
        <ListGroup>
          {primaryPracticeAreaRequirements.map(requirement => (
            <ListGroupItem key={requirement.uuid}>
              <CheckboxField
                name={requirement.uuid}
                label={requirement.question}
              />
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    </NullOr>
  );
};

class ApplicationForm extends Component {
  render() {
    const { error, handleSubmit, submitting, requirements } = this.props;

    return (
      <div>
        <p className="text-center" style={{ fontSize: "15px" }}>
          Your resume, conflict information, and whether or not you have worked
          for this company before will be sent along with your application
          automatically.
        </p>
        <hr />
        <form onSubmit={handleSubmit}>
          {error && <Alert bsStyle="danger">{error}</Alert>}
          <JobRequirements requirements={requirements} />
          <TextAreaField
            name="cover_letter"
            label="Personal Statement"
            rows={8}
          />
          <CheckboxField
            name="agreed_bill_through_hae"
            label={agreedBillThroughHaeLabel}
          />
          <CheckboxField
            name="agreed_no_disciplinary_action"
            label={agreedNoDisciplinaryActionLabel}
          />
          {error && <Alert bsStyle="danger">{error}</Alert>}
          <FormButton
            action="submit"
            submitting={submitting}
            className="pull-right"
          />
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: formName,
})(ApplicationForm);
