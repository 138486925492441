import React from "react";
import moment from "moment";

import { createApiUrl } from "utils";
import { StatusLabel } from "./utils";
import { getNonAttorneyVerificationStatus } from "../utils";
import {
  PendingSchoolsCounter,
  VerifiedSchoolsCounter,
  RejectedSchoolsCounter,
  PendingReferencesCounter,
  VerifiedReferencesCounter,
  RejectedReferencesCounter,
  PendingEmploymentReferencesCounter,
  VerifiedEmploymentReferencesCounter,
  RejectedEmploymentReferencesCounter,
} from "./Counters";

export const NonAttorneyRow = ({ nonAttorney, selected = false, onClick }) => {
  return (
    <tr onClick={onClick} className={selected ? "selected" : ""}>
      <td style={{ fontSize: "16px" }}>
        <a
          href={createApiUrl(
            `/hae_admin/contractor/contractor/${nonAttorney.uuid}/change/`,
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-external-link-alt" />
        </a>
      </td>
      <td>{nonAttorney.first_name}</td>
      <td>{nonAttorney.last_name}</td>
      <td>{moment(nonAttorney.created).fromNow()}</td>
      <td>
        <StatusLabel status={getNonAttorneyVerificationStatus(nonAttorney)} />
      </td>
      <td>
        <PendingSchoolsCounter schools={nonAttorney.schools} />&nbsp;
        <VerifiedSchoolsCounter schools={nonAttorney.schools} />&nbsp;
        <RejectedSchoolsCounter schools={nonAttorney.schools} />
      </td>
      <td>
        <PendingReferencesCounter references={nonAttorney.review_requests} />&nbsp;
        <VerifiedReferencesCounter references={nonAttorney.review_requests} />&nbsp;
        <RejectedReferencesCounter references={nonAttorney.review_requests} />
      </td>
      <td>
        <PendingEmploymentReferencesCounter nonAttorney={nonAttorney} />&nbsp;
        <VerifiedEmploymentReferencesCounter nonAttorney={nonAttorney} />&nbsp;
        <RejectedEmploymentReferencesCounter nonAttorney={nonAttorney} />
      </td>
    </tr>
  );
};
