// @flow strict
import types from "resources/types";
import { createResourceDetailQuery } from "queries";

export const hiringAgentQueryWithConfig = (
  config: QueryConfig = {},
  posting_agent: Uuid,
) =>
  createResourceDetailQuery(types.HIRING_AGENTS, {
    url: `/api/dev/hiring_agents/${posting_agent}`,
    ...config,
  });

export const ownHiringAgentQuery = () =>
  createResourceDetailQuery(types.HIRING_AGENTS, {
    url: "/api/dev/hiring_agents/self/",
  });
