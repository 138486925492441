import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { withOwnContractor } from "contractor/connectors";

import Loading from "common/components/Loading";
import {
  ContractorProfileBody,
  ContractorProfileHeader,
} from "common/components/contractor-profile";

class Profile extends Component {
  render() {
    const {
      ownContractor: { data: contractor, ready },
    } = this.props;

    return !ready ? (
      <Loading />
    ) : (
      <div>
        <h1 style={{ marginBottom: "4rem" }}>
          Profile
          <LinkContainer
            to="/contractor/profile/edit"
            className="pull-right"
            style={{ fontSize: "1.6rem" }}
          >
            <Button>Edit Profile</Button>
          </LinkContainer>
        </h1>
        <ContractorProfileHeader
          contractor={contractor}
          isOwnContractor={true}
        />
        <ContractorProfileBody contractor={contractor} />
      </div>
    );
  }
}

export default withOwnContractor()(Profile);
