import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { ReactSelectField } from "common/components/forms/inputs";
import { connectFixtures } from "common/utils";

export const formName = "expertiseAreasForm";
export const requiredFields = ["practice_areas"];

class ExpertiseAreasForm extends Component {
  render() {
    const {
      fixtures: {
        tags: { options },
      },
    } = this.props;
    const tagOptions = options.map(tag => ({
      value: tag.uuid,
      label: tag.name,
    }));

    return (
      <div>
        <ReactSelectField
          name="practice_areas"
          label="Areas of Expertise"
          options={tagOptions}
          multi={true}
        />
      </div>
    );
  }
}

ExpertiseAreasForm = connectFixtures(ExpertiseAreasForm);
ExpertiseAreasForm = reduxForm({
  form: formName,
})(ExpertiseAreasForm);
export default ExpertiseAreasForm;
