import React from "react";
import PropTypes from "prop-types";
import { ControlLabel, FormGroup } from "react-bootstrap";

import FieldHelpBlock from "./FieldHelpBlock";
import { validationState } from "common/utils/forms";

const FormGroupValidation = ({
  children,
  bsSize,
  className,
  input,
  meta,
  label,
  maxLength,
}) => (
  <FormGroup
    validationState={validationState(meta, maxLength)}
    bsSize={bsSize}
    className={className}
  >
    {label && <ControlLabel>{label}</ControlLabel>}
    {children}
    {maxLength && (
      <div className="text-right">
        {input.value.length}/{maxLength}
      </div>
    )}
    <FieldHelpBlock {...meta} />
  </FormGroup>
);

FormGroupValidation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bsSize: PropTypes.string,
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.number,
};

FormGroupValidation.defaultProps = {
  bsSize: "sm",
  className: "",
  label: null,
  maxLength: null,
};

export default FormGroupValidation;
