import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ButtonGroup, DropdownButton, MenuItem } from "react-bootstrap";
import { useMutation, useRequest } from "redux-query-react";

import { savedContractorMutations } from "queries/mutations";
import { talentPoolsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";
import { SavedContractorIcon } from ".";
import { talentPoolButtonStyles } from "./styles";

const MenuItemIconContainer = styled.span`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  color: ${({ theme }) => theme.colors.success()};
`;

const TalentPoolDropdown = styled(DropdownButton)`
  ${talentPoolButtonStyles};
`;

const TalentPoolMenuItem = ({
  children,
  isPending,
  isSelected,
  ...otherProps
}) => {
  const icon = isPending ? "fa-spinner fa-pulse" : "fa-check";
  const checkmark = (
    <MenuItemIconContainer visible={isSelected || isPending}>
      <i className={`fas ${icon} fa-fw`} />&nbsp;
    </MenuItemIconContainer>
  );
  return (
    <MenuItem {...otherProps}>
      {checkmark}
      {children}
    </MenuItem>
  );
};

const SavedContractorTalentPoolDropdown = props => {
  const { savedContractor } = props;
  const request = talentPoolsRequest({
    url: { params: { limit: 99 } },
  });
  const selector = createResourceSelector(types.TALENT_POOLS, request);
  const {
    [types.TALENT_POOLS]: { data },
  } = useSelector(selector);
  const [{ isFinished, isPending }, _] = useRequest(request);
  const [{ isPending: isAdding }, addToList] = useMutation(listId =>
    savedContractorMutations.update(savedContractor.uuid, {
      talentpool_set: [...savedContractor.talentpool_set, listId],
    }),
  );
  const [{ isPending: isDeleting }, deleteSavedContractor] = useMutation(() =>
    savedContractorMutations.delete(savedContractor.uuid),
  );
  const [{ isPending: isRemoving }, removeFromList] = useMutation(listId =>
    savedContractorMutations.update(savedContractor.uuid, {
      talentpool_set: savedContractor.talentpool_set.filter(
        id => id !== listId,
      ),
    }),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [pendingTalentPoolIds, setPendingTalentPoolIds] = useState([]);

  const isSubmitting = isAdding || isDeleting || isRemoving;

  const handleClick = listId => {
    setPendingTalentPoolIds(prevIds => [...prevIds, listId]);

    const isDeletingSavedContractor =
      savedContractor.talentpool_set.length === 1 &&
      savedContractor.talentpool_set[0] === listId;
    const isRemovingFromTalentPool = savedContractor.talentpool_set.includes(
      listId,
    );

    if (isDeletingSavedContractor) {
      deleteSavedContractor();
    } else {
      const action = isRemovingFromTalentPool ? removeFromList : addToList;
      action(listId).then(() => {
        setPendingTalentPoolIds(prevIds => prevIds.filter(id => id !== listId));
      });
    }
  };

  let label = "Save to Talent Pools";
  if (isPending) label = "Loading...";
  if (isSubmitting) label = "Saving...";
  const isSavedContractor =
    isFinished && savedContractor.talentpool_set.length > 0;
  if (isSavedContractor)
    label = `Saved to ${savedContractor.talentpool_set.length} Talent Pool${
      savedContractor.talentpool_set.length > 1 ? "s" : ""
    }`;

  const title = (
    <span>
      <SavedContractorIcon isSelected={isSavedContractor} />&nbsp;
      {label}
    </span>
  );
  return (
    <div>
      <ButtonGroup justified>
        <TalentPoolDropdown
          title={title}
          id={`talent-pool-select-${savedContractor.uuid}`}
          open={isOpen}
          onToggle={(isOpen, e, source) => {
            // Don't change open status when clicking menu items
            if (source.source !== "select") {
              setIsOpen(isOpen);
            }
          }}
        >
          {Object.keys(data).map((talentPoolId, i) => (
            <TalentPoolMenuItem
              key={`${savedContractor.uuid}--${talentPoolId}`}
              eventKey={i + 1}
              onClick={() => handleClick(talentPoolId)}
              isSelected={savedContractor.talentpool_set.includes(talentPoolId)}
              isPending={pendingTalentPoolIds.includes(talentPoolId)}
            >
              {data[talentPoolId].name}
            </TalentPoolMenuItem>
          ))}
        </TalentPoolDropdown>
      </ButtonGroup>
    </div>
  );
};

export default SavedContractorTalentPoolDropdown;
