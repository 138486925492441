export const nonAttorneyTypes = {
  LEGAL_ADMINISTRATION: "Legal Administration and Support",
  LEGAL_SPECIALIST: "Paralegal/Legal Specialist",
  NON_BARRED_LAWYER: "JD/Non-Barred Lawyer",
};

export const adminTypes = {
  LEGAL_INTERN: "Legal Intern",
  SECRETARY: "Secretary",
  LAW_CLERK: "Law Clerk",
  LAW_LIBRARIAN: "Law Librarian",
};

export const specialistTypes = {
  PARALEGAL: "Paralegal",
  CONTRACTS_MANAGER: "Contract Manager",
  PROJECT_MANAGER: "Project Manager",
  PATENT_AGENT: "Patent Agent",
};
