import React, { Component } from "react";

import { getBankAccounts } from "contractor/selectors";
import { connect, compose, connectResource } from "resources/components/utils";
import { bankAccountsRequest } from "resources/requests";

import Edit from "./blocks";
import NoBankInfo from "./NoBankInfo";
import StripeDisclosure from "./StripeDisclosure";
import DirectDepositDisplaySection from "./DirectDepositDisplaySection";
import { Loading } from "common/components";
import DynamicFormSection from "./DynamicFormSection";
import DirectDepositModal from "./DirectDepositModal";

class EditDirectDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false, forceHideDynamicForm: false };
  }

  handleClickToggle = () =>
    this.setState(state => ({ modalOpen: !state.modalOpen }));

  handleHideModal = () => this.setState({ modalOpen: false });

  handleHideDynamicForm = () => this.setState({ forceHideDynamicForm: true });

  render() {
    const {
      bankAccounts: { data: bankData, ready },
    } = this.props;
    const { forceHideDynamicForm, modalOpen } = this.state;
    const hasBankData = ready && bankData.bank_name;
    const fieldsNeeded = hasBankData
      ? bankData.verified_status.fields_needed
      : null;
    const needMoreFields = fieldsNeeded
      ? Object.keys(fieldsNeeded).length !== 0
      : false;
    const issueMessages = hasBankData
      ? bankData.verified_status.additional_issues_messages
      : null;
    const hasIssueMessages = issueMessages ? issueMessages.length !== 0 : false;
    const showDynamicForm =
      !forceHideDynamicForm &&
      hasBankData &&
      (needMoreFields || hasIssueMessages);

    return (
      <div>
        {ready ? (
          <div>
            {showDynamicForm && (
              <DynamicFormSection
                needMoreFields={needMoreFields}
                fieldsNeeded={fieldsNeeded}
                hasIssueMessages={hasIssueMessages}
                issueMessages={issueMessages}
                hasBankData={hasBankData}
                bankData={bankData}
                onHideDynamicForm={this.handleHideDynamicForm}
              />
            )}
            <Edit.Header>Direct Deposit</Edit.Header>
            <StripeDisclosure />
            {hasBankData ? (
              <DirectDepositDisplaySection
                bankData={bankData}
                onClickToggle={this.handleClickToggle}
              />
            ) : (
              <NoBankInfo onClick={this.handleClickToggle} />
            )}
            <DirectDepositModal
              hasBankData={hasBankData}
              bankData={bankData}
              modalOpen={modalOpen}
              onHideModal={this.handleHideModal}
              onHideDynamicForm={this.handleHideDynamicForm}
            />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = getBankAccounts;

export default (EditDirectDeposit = compose(
  connect(mapStateToProps),
  connectResource(bankAccountsRequest),
)(EditDirectDeposit));
