import React from "react";
import PropTypes from "prop-types";

const SelectFilter = props => (
  <select
    name={props.name}
    id={`id_${props.name}`}
    className={props.className}
    onChange={props.onChange}
    value={props.value}
  >
    <option value="">{props.label}</option>
    {props.children}
  </select>
);

SelectFilter.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectFilter.defaultProps = {
  className: "form-control",
};

export default SelectFilter;
