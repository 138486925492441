import { mutateResourceQuery } from "queries";
import types from "resources/types";

export const updateOwnContractorQuery = (data, uuid) =>
  mutateResourceQuery(
    types.OWN_CONTRACTOR,
    {
      url: `/api/dev/contractors/${uuid}/`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );
