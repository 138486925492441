import React from "react";
import PropTypes from "prop-types";
import {
  EmptySelectOption,
  FormControlGroup,
  PlaceholderSelectOption,
} from "common/components/forms";

const SelectInput = ({
  children,
  includeEmptyOption,
  placeholder,
  ...otherProps
}) => (
  <FormControlGroup componentClass="select" {...otherProps}>
    {includeEmptyOption && !placeholder && <EmptySelectOption />}
    {placeholder && <PlaceholderSelectOption placeholder={placeholder} />}
    {children}
  </FormControlGroup>
);

SelectInput.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  includeEmptyOption: PropTypes.bool,
  placeholder: PropTypes.string,
};
SelectInput.defaultProps = {
  includeEmptyOption: true,
  placeholder: null,
};
export default SelectInput;
