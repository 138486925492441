import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { ButtonToolbar, Grid } from "react-bootstrap";

import { StatusLabel } from "./TableComponents/utils";
import { SchoolsTable } from "./TableComponents/Schools";
import { ReferencesTable } from "./TableComponents/References";
import { getNonAttorneyVerificationStatus } from "admin/components/non-attorney-verification-manager/utils";
import RejectButton from "./RejectButton";

class NonAttorneyDetails extends Component {
  render() {
    const { nonAttorney } = this.props;
    return nonAttorney ? (
      <div style={{ position: "relative", height: "100%" }}>
        <div className="verification-overview">
          <Grid fluid>
            <h2>
              Selected Non-Attorney: {nonAttorney.first_name}{" "}
              {nonAttorney.last_name}
            </h2>
            <h3>
              <StatusLabel
                status={getNonAttorneyVerificationStatus(nonAttorney)}
              />
              <ButtonToolbar className="pull-right">
                <RejectButton nonAttorney={nonAttorney} />
              </ButtonToolbar>
            </h3>
          </Grid>
        </div>
        <div className="verification-details">
          <Grid fluid>
            <SchoolsTable schools={nonAttorney.schools} />
            <ReferencesTable references={nonAttorney.review_requests} />
          </Grid>
        </div>
      </div>
    ) : null;
  }
}

const getNonAttorneys = state =>
  state.admin.nonAttorneyVerification.allNonAttorneys;
const getSelectedRow = state => state.admin.nonAttorneyVerification.selectedRow;
const selector = createSelector(
  [getNonAttorneys, getSelectedRow],
  (nonAttorneys, selectedRow) => nonAttorneys[selectedRow],
);

export default connect(state => ({
  nonAttorney: selector(state),
}))(NonAttorneyDetails);
