import React, { Component } from "react";
import { initialize, SubmissionError } from "redux-form";
import { createStructuredSelector } from "reselect";
import { createResourceSelectorConfig } from "queries";
import { addNotification } from "reapop";

import { history as browserHistory } from "app";
import ThreadHeader from "./ThreadHeader";
import ThreadMain from "./ThreadMain";
import ThreadReplyForm from "./ThreadReplyForm";
import { threadQuery } from "queries/requests/messages";
import { replyToThreadQuery } from "queries/mutations/messages";
import {
  connect,
  compose,
  connectRequest,
  mutateAsync,
  requestAsync,
} from "queries/utils";
import types from "resources/types";
import Loading from "common/components/Loading";
import { ThreadWrapper } from "../blocks";
import { createErrorNotification } from "common/utils/notifications";
import { Error } from "common/utils/forms/validators";

const pollingInterval = 10000;
const formName = "threadReplyForm";
const initState = { itemsCount: null, showScroller: false };

class Thread extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.handleRouteSetup();
    this.props.onFetchMessages();
    this.handleThreadPolling();
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeMsgKey !== prevProps.activeMsgKey) {
      this.handleActiveMessageChange();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.threadTimer);
  }

  handleActiveMessageChange = () => {
    this.setState(initState);
    this.handleRouteSetup();
    this.props.onFetchMessages();
  };

  handleRouteSetup = () => {
    const { activeMsgKey, onGetBaseRoute } = this.props;
    browserHistory.push(`/${onGetBaseRoute()}/messages/${activeMsgKey}`);
  };

  handleThreadPolling = async () => {
    await this.handleRefreshAndScroll();
    this.threadTimer = setTimeout(this.handleThreadPolling, pollingInterval);
  };

  handleRefreshAndScroll = async () => {
    const { activeMsgKey, refreshThread, search } = this.props;
    const el = this.getThreadElement();
    const scroll = el && el.scrollTop + el.offsetHeight === el.scrollHeight;
    const { itemsCount } = this.state;
    const { body } = await refreshThread(activeMsgKey, search);
    const newCount = body && body.messages ? body.messages.length : null;

    if (newCount && itemsCount && itemsCount !== newCount) {
      if (scroll) {
        await this.setState({ itemsCount: newCount, showScroller: false });
        this.handleScrollToBottom();
      } else {
        await this.setState({ itemsCount: newCount, showScroller: true });
      }
    } else if (newCount && !itemsCount) {
      await this.setState({ itemsCount: newCount, showScroller: false });
    }
  };

  handleScrollToBottom = () => {
    const el = this.getThreadElement();
    el.scrollTop = el.scrollHeight;
  };

  getThreadElement = () => document.querySelector("#messaging-thread");

  handleHideNewMsgButton = () => this.setState({ showScroller: false });

  handleClickNewMsgButton = () => {
    this.handleScrollToBottom();
    this.handleHideNewMsgButton();
  };

  handleSubmit = async data => {
    const {
      props: {
        activeMsgKey,
        initializeForm,
        notify,
        refreshThread,
        replyToThread,
        search,
      },
      handleScrollToBottom,
    } = this;
    const replyResponse = await replyToThread(activeMsgKey, data);

    if (replyResponse.status >= 200 && replyResponse.status < 300) {
      const { body } = await refreshThread(activeMsgKey, search);
      const newCount = body && body.messages ? body.messages.length : null;
      await this.setState({ itemsCount: newCount, showScroller: false });
      handleScrollToBottom();
      initializeForm();
    } else {
      notify(createErrorNotification());
      replyResponse.body._error = (
        <Error>
          There was a problem submitting your request. Please try again.
        </Error>
      );
      throw new SubmissionError(replyResponse.body);
    }
  };

  render() {
    const {
      state: { showScroller },
      props: {
        authUser,
        activeMsgKey,
        thread: { data: threadData },
        onCloseThread,
        onGetBaseRoute,
        windowSize,
      },
      handleClickNewMsgButton: onClickNewMsgButton,
      handleScrollToBottom: onScrollToBottom,
      handleSubmit,
    } = this;
    const thread = threadData[activeMsgKey];
    const isReady = thread && thread.uuid;
    if (!isReady) return <Loading />;
    const otherUser = thread.recipients.find(
      r => r.uuid !== authUser.haeuser_id,
    );

    return (
      <ThreadWrapper>
        <ThreadHeader
          authUser={authUser}
          otherUser={otherUser}
          onCloseThread={onCloseThread}
          onClickNewMsgButton={onClickNewMsgButton}
          onGetBaseRoute={onGetBaseRoute}
          showScroller={showScroller}
          thread={thread}
          windowSize={windowSize}
        />
        <ThreadMain
          authUser={authUser}
          onScrollToBottom={onScrollToBottom}
          thread={thread}
        />
        <ThreadReplyForm onSubmit={handleSubmit} />
      </ThreadWrapper>
    );
  }
}

const threadConfig = createResourceSelectorConfig(types.THREAD, threadQuery);
const mapStateToProps = createStructuredSelector({ ...threadConfig });

const mapDispatchToProps = dispatch => ({
  initializeForm: () => dispatch(initialize(formName)),
  notify: fn => dispatch(addNotification(fn)),
  refreshThread: (activeMsgKey, search) =>
    dispatch(requestAsync(threadQuery({ force: true }, search, activeMsgKey))),
  replyToThread: (activeMsgKey, data) =>
    dispatch(mutateAsync(replyToThreadQuery(activeMsgKey, data))),
});

const mapPropsToConfig = ({ activeMsgKey, search }) => [
  threadQuery({ force: true }, search, activeMsgKey),
];

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(Thread);
