import React, { Component } from "react";
import { Link } from "react-router-dom";
import { reduxForm, SubmissionError } from "redux-form";
import { LinkContainer } from "react-router-bootstrap";
import { Col, Button, Row } from "react-bootstrap";
import { addNotification } from "reapop";

import { history as browserHistory } from "app";
import Loading from "common/components/Loading";
import { TextAreaField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";
import { createErrorNotification } from "common/utils/notifications";

import { createResourceDetailQuery, createResourceSelector } from "queries";
import { update as updateInvoice } from "queries/mutations/invoices";
import {
  compose,
  connect,
  connectRequest,
  mutateAsync,
  types,
} from "queries/utils";

const formName = "invoiceDisputeForm";
const disputeReasonPlaceholder =
  "Please provide a detailed explanation for your dispute.";

const getInvoiceDetailsUrl = id => `/agency/invoices/${id}/`;

const routeToInvoiceDetails = id =>
  browserHistory.push(getInvoiceDetailsUrl(id));

const validate = values => validateRequiredFields(["dispute_reason"], values);

class InvoiceDisputeForm extends Component {
  render() {
    const { invoiceId, handleSubmit, submitting } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <TextAreaField
            name="dispute_reason"
            label="Reason for Dispute"
            placeholder={disputeReasonPlaceholder}
            rows={8}
          />
          <p>
            Someone from Hire an Esquire will contact you as soon as possible.
          </p>
          <div className="text-right" style={{ margin: "2rem 0" }}>
            <LinkContainer to={getInvoiceDetailsUrl(invoiceId)}>
              <Button bsStyle="link">Back to Invoice</Button>
            </LinkContainer>
            <Button type="submit" disabled={submitting} bsStyle="success">
              Submit Dispute
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

InvoiceDisputeForm = reduxForm({
  form: formName,
  validate,
})(InvoiceDisputeForm);

class InvoiceDispute extends Component {
  componentDidMount() {
    this.redirectToInvoiceIfNecessary(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      [types.INVOICES]: { data },
    } = this.props;
    const {
      [types.INVOICES]: { data: nextData },
    } = nextProps;

    if (nextData !== data) {
      this.redirectToInvoiceIfNecessary(nextProps);
    }
  }

  isNotAllowedToDispute = invoice => {
    const isApproved = invoice.approved_date;
    const isPaid = invoice.paid_date;
    const isDisputedAndUnresolved =
      invoice.disputed_date && !invoice.dispute_resolved;
    return isApproved || isPaid || isDisputedAndUnresolved;
  };

  redirectToInvoiceIfNecessary = props => {
    const {
      match: {
        params: { id },
      },
      [types.INVOICES]: { data },
    } = props;
    const invoice = data[id];

    if (invoice && this.isNotAllowedToDispute(invoice)) {
      routeToInvoiceDetails(id);
    }
  };

  handleSubmit = async values => {
    const {
      notify,
      disputeInvoice,
      match: {
        params: { id },
      },
    } = this.props;
    const { body, status } = await disputeInvoice(id, values);

    if (status >= 200 && status < 300) {
      routeToInvoiceDetails(id);
    } else {
      notify(createErrorNotification());
      throw new SubmissionError(body);
    }
  };

  render() {
    const {
      match: {
        params: { id },
      },
      [types.INVOICES]: { data },
    } = this.props;

    const invoice = data[id];

    return !invoice ? (
      <Loading />
    ) : (
      <div>
        <h1>Dispute Invoice</h1>
        <Row>
          <Col lgOffset={2} lg={8}>
            <div style={{ margin: "2rem 0" }}>
              <h2 style={{ margin: "0", marginBottom: ".5rem" }}>
                Invoice{" "}
                <Link to={getInvoiceDetailsUrl(id)}>
                  #{invoice.invoice_number}
                </Link>
              </h2>
              <h2 style={{ margin: "0" }}>{invoice.job_title}</h2>
            </div>
            <InvoiceDisputeForm invoiceId={id} onSubmit={this.handleSubmit} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapPropsToConfig = props =>
  createResourceDetailQuery(types.INVOICES, {
    url: `/api/dev/invoices/${props.match.params.id}/`,
  });

const mapStateToProps = createResourceSelector(
  types.INVOICES,
  mapPropsToConfig,
);

const mapDispatchToProps = dispatch => ({
  notify: fn => dispatch(addNotification(fn)),
  disputeInvoice: (invoiceId, data) =>
    dispatch(mutateAsync(updateInvoice(invoiceId, data))),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(InvoiceDispute);
