import React, { Component } from "react";
import { reduxForm } from "redux-form";

import { ExpertiseAreasSelectField } from "common/components/forms/inputs";
import { Error, validateRequiredFields } from "common/utils/forms/validators";
import { compose } from "resources/components/utils";
import { FormRow, withFormError } from "./utils";

const requiredFields = ["expertisearea_set"];

export const validate = values => {
  const errors = validateRequiredFields(requiredFields, values);
  if (values.expertisearea_set && values.expertisearea_set.length > 12) {
    errors.expertisearea_set = (
      <Error>Please select no more than 12 expertise areas.</Error>
    );
  }
  return errors;
};

let ExpertiseAreasForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <FormRow>
      <ExpertiseAreasSelectField />
      <p>These specialties optimize our recommendation engine.</p>
    </FormRow>
  </form>
);
ExpertiseAreasForm = compose(
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(ExpertiseAreasForm);

export default class ExpertiseAreas extends Component {
  handleSubmit = values => {
    const {
      jobListing,
      formProps: { onSubmit },
    } = this.props;
    return onSubmit({
      expertisearea_set: values.expertisearea_set.map(value => {
        const existing = jobListing.expertisearea_set.find(
          expertisearea => expertisearea.tag === value,
        );
        // If expertise area exists on jobListing, add its uuid to data
        return {
          tag: value,
          ...(existing && { uuid: existing.uuid }),
        };
      }),
    });
  };

  render() {
    const { formProps, jobListing } = this.props;
    const initialValues = {
      expertisearea_set: jobListing.expertisearea_set.map(value => value.tag),
    };
    return (
      <div>
        <h2>Let&apos;s get more specific.</h2>
        <p className="description">Choose 2 - 5 areas of expertise.</p>
        <ExpertiseAreasForm
          {...formProps}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
        />
      </div>
    );
  }
}
