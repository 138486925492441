import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Alert, ControlLabel, FormGroup, Modal } from "react-bootstrap";

import { compose, connect, mutateAsync } from "queries/utils";
import {
  createSchoolQuery,
  updateSchoolQuery,
} from "queries/mutations/schools";
import SchoolSelect from "./SchoolSelect";
import {
  NumberField,
  SelectField,
  TextField,
} from "common/components/forms/inputs";
import { handleSubmitError } from "../../utils";
import FormButtons from "../../FormButtons";

let EducationForm = ({
  change,
  degreeChoices: { results },
  error,
  handleSubmit,
  onHide,
  pristine,
  submitting,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <FormGroup>
      <ControlLabel>School Search</ControlLabel>
      <SchoolSelect
        onChange={vals => change("school_name", vals.school_name)}
      />
    </FormGroup>
    <TextField name="school_name" label="School Name" />
    <SelectField name="degree_type" label="Degree Type">
      {results.map(result => (
        <option key={result.value} value={result.value}>
          {result.label}
        </option>
      ))}
    </SelectField>
    <NumberField name="year_graduated" label="Year Graduated" />
    <TextField name="gpa" label="GPA" />
    <FormButtons onHide={onHide} submitting={submitting} pristine={pristine} />
  </form>
);

EducationForm = reduxForm({
  form: "onboardingEducationForm",
})(EducationForm);

class FormModal extends Component {
  handleSubmitEducationForm = values => {
    const {
      props: { createSchool, editingSchool, updateSchool },
      postSubmit,
    } = this;

    if (editingSchool && editingSchool.uuid) {
      return updateSchool(values, editingSchool.uuid)
        .then(handleSubmitError)
        .then(postSubmit);
    } else {
      return createSchool(values)
        .then(handleSubmitError)
        .then(postSubmit);
    }
  };

  postSubmit = () => {
    const { onHide, refreshReqs, refreshSchools } = this.props;
    onHide();
    refreshSchools();
    refreshReqs();
  };

  getInitialValues = () => {
    const { editingSchool, degreeChoices } = this.props;
    let initialValues = {};

    if (editingSchool && degreeChoices) {
      initialValues = {
        ...editingSchool,
        degree_type: degreeChoices.results.find(
          degreeChoice => degreeChoice.label === editingSchool.degree,
        ).value,
      };
    }

    return initialValues;
  };

  render() {
    const {
      props: { degreeChoices, editingSchool, show, onHide },
      getInitialValues,
      handleSubmitEducationForm,
    } = this;

    return (
      <Modal backdrop="static" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{editingSchool ? "Edit" : "Add New"} School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EducationForm
            degreeChoices={degreeChoices}
            initialValues={getInitialValues()}
            onHide={onHide}
            onSubmit={handleSubmitEducationForm}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createSchool: data => dispatch(mutateAsync(createSchoolQuery(data))),
  updateSchool: (data, uuid) =>
    dispatch(mutateAsync(updateSchoolQuery(data, uuid))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(FormModal);
