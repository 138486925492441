import React from "react";
import { submit } from "redux-form";

import Onboarding from "./blocks";
import Loading from "common/components/Loading";
import { connect } from "queries/utils";

const NextButton = ({ isLoading, ...props }) => (
  <Onboarding.Button {...props}>
    {isLoading ? <Loading /> : "Save and Continue"}
  </Onboarding.Button>
);

export const RemoteSubmitButton = connect()(
  ({ dispatch, formName, disabled, isLoading }) => (
    <NextButton
      disabled={disabled}
      float="right"
      isLoading={isLoading}
      onClick={() => dispatch(submit(formName))}
    />
  ),
);

export default NextButton;
