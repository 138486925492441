import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { Fade } from "react-bootstrap";

import { SelectField } from "common/components/forms/inputs";
import {
  FormRow,
  withFormError,
} from "hiringagent-dashboard/components/jobs/create-job-listing/utils";
import { compose } from "resources/components/utils";
import { PaymentDetails } from "types";

const linkToIRS =
  "https://www.irs.gov/businesses/small-businesses-self-employed/independent-contractor-self-employed-or-employee";

const classificationExplanation = (
  <Fragment>
    <p>
      Supervision and control are the determining factors for worker
      classification.{" "}
      <a href={linkToIRS} target="_blank" rel="noopener noreferrer">
        More information.
      </a>
    </p>
    <p>
      Hire an Esquire is the employer of record and assumes all employer
      responsibilities for projects classified as W-2.
    </p>
  </Fragment>
);

export class StatusDefinitions extends Component {
  state = {
    expanded: false,
  };

  handleExpandDefinitions = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { expanded } = this.state;

    return (
      <div className="service-help-text">
        <a href="#" onClick={this.handleExpandDefinitions}>
          How are 1099 and W-2 different?{" "}
          <i className={`far fa-fw fa-angle-${expanded ? "down" : "left"}`} />
        </a>
        <Fade in={expanded}>
          <div>
            <div style={{ marginTop: "2rem" }}>{classificationExplanation}</div>
          </div>
        </Fade>
      </div>
    );
  }
}

export class ServiceForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FormRow>
          <SelectField
            name="service"
            label="Worker Classification"
            includeEmptyOption={false}
          >
            {PaymentDetails.serviceOptions.map(option => (
              <option
                key={`option-service-${option.value}`}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </SelectField>
          <StatusDefinitions />
        </FormRow>
      </form>
    );
  }
}

const ConnectedServiceForm = compose(
  reduxForm({
    form: "jobPaymentDetailsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  withFormError(),
)(ServiceForm);

export default class Service extends Component {
  render() {
    const { formProps, initialValues } = this.props;
    return (
      <div>
        <h2>Should your contractor be classified as 1099 or W-2 worker?</h2>
        <ConnectedServiceForm {...formProps} initialValues={initialValues} />
      </div>
    );
  }
}
