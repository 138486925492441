import React from "react";

import { Error } from "common/utils/forms/validators";
import { EditContractor } from "contractor/components/settings";
import EditPositionInterestForm, {
  requiredFields,
} from "contractor/forms/profile/EditPositionInterestForm";

const validate = values => {
  const errors = {};
  if (!values.interested_in_temp && !values.interested_in_perm) {
    errors._error = (
      <Error>You must be interested in at least one type of position.</Error>
    );
  }
  return errors;
};

const props = {
  formComponentClass: EditPositionInterestForm,
  title: "Position Interest",
  requiredFields,
  validate,
};

const getIntialValues = ({ interested_in_perm, interested_in_temp }) => ({
  interested_in_perm,
  interested_in_temp,
});

const EditPositionInterest = ({ contractor, ...otherProps }) => (
  <EditContractor
    contractorId={contractor.uuid}
    initialValues={getIntialValues(contractor)}
    {...props}
    {...otherProps}
  />
);

export default EditPositionInterest;
