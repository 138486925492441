import styled from "styled-components";

const Text = styled.text.attrs({
  dominantBaseline: "middle",
  textAnchor: "middle",
})`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;

  ${({ theme }) => theme.media.mobile`
    font-size: 14px;
  `};
`;

export default Text;
