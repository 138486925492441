import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Button } from "react-bootstrap";
import styled from "styled-components";

import { connect, compose } from "resources/components/utils";
import { TextField } from "common/components/forms/inputs";
import { validateRequiredFields } from "common/utils/forms/validators";

const formName = "discountCodesSettingsForm";
const requiredFields = ["discount_code"];

const validate = values => {
  let errors = validateRequiredFields(requiredFields, values);
  return errors;
};

const Submit = styled.div`
  text-align: center;
  margin: 20px 0;

  & .btn {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

class DiscountCodesSettingsForm extends Component {
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%", maxWidth: "500px" }}>
          <form onSubmit={handleSubmit}>
            <TextField
              name="discount_code"
              label="Add Promo or Referral Code"
            />
            <Submit>
              <Button
                type="submit"
                style={{ width: "180px" }}
                bsSize="large"
                bsStyle="info"
                disabled={submitting}
              >
                {submitting ? (
                  <span>
                    <i className="far fa-cog fa-spin" aria-hidden="true" />
                    &nbsp;Submitting...
                  </span>
                ) : (
                  "Add Code"
                )}
              </Button>
            </Submit>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: {
    discount_code: props.discountCode,
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    validate,
  }),
)(DiscountCodesSettingsForm);
