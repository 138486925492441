import keyMirror from "keymirror";

import api from "common/api";

const actions = keyMirror({
  REQUEST_PRACTICE_AREAS: null,
  RECEIVE_PRACTICE_AREAS: null,
});
export default actions;

export const requestTags = () =>
  api.get("/api/dev/tags/?limit=999", [
    actions.REQUEST_PRACTICE_AREAS,
    actions.RECEIVE_PRACTICE_AREAS,
  ]);
