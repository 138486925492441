// @flow strict
import React, { type ComponentType } from "react";
import styled from "styled-components";

import Main from "./Main";
import Toggle from "./Toggle";
import Close from "./Close";
import Plus from "./Plus";
import Text from "./Text";
import Bar from "./Bar";
import Divider from "./Divider";
import NavItem from "./NavItem";
import NavTrigger from "./NavTrigger";
import SecondaryNavList from "./SecondaryNavList";
import SecondaryNavItem from "./SecondaryNavItem";
import NavList from "./NavList";
import Collapse from "./Collapse";
import Footer from "./Footer";
import LogOut from "./LogOut";

type Props = {
  mobileNavOpen: boolean,
};

const MobileBlock: ComponentType<Props> = styled.nav`
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.container_bg()};
  border-radius: ${({ mobileNavOpen }) =>
    mobileNavOpen ? "0 0 16px 16px" : "0"};

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

const Mobile = (props: Props) => <MobileBlock {...props} />;

Mobile.Main = Main;
Mobile.Toggle = Toggle;
Mobile.Close = Close;
Mobile.Plus = Plus;
Mobile.Text = Text;
Mobile.Bar = Bar;
Mobile.Divider = Divider;
Mobile.NavItem = NavItem;
Mobile.NavTrigger = NavTrigger;
Mobile.SecondaryNavList = SecondaryNavList;
Mobile.SecondaryNavItem = SecondaryNavItem;
Mobile.NavList = NavList;
Mobile.Collapse = Collapse;
Mobile.Footer = Footer;
Mobile.LogOut = LogOut;

export default Mobile;
