import React, { Fragment } from "react";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import Entry from "./Entry";
import Label from "common/blocks/Label";
import { MainCopy, PendingVerificationCopy, NoEntriesCopy } from "./copy";

export const MobileEntries = ({
  actionIsRequired,
  addJustUploadedId,
  customErrorMsg,
  isAttorney,
  justUploadedIds,
  onDeleteClick,
  onEditClick,
  onFooterClick,
  ownContractor,
  refreshSchools,
  schools,
  verifIsPending,
}) => (
  <div>
    <h1>Education</h1>
    {actionIsRequired && (
      <div>
        <Label color="danger">ACTION REQUIRED</Label>
        <br />
        <br />
      </div>
    )}
    {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
    {verifIsPending && <PendingVerificationCopy />}
    <MainCopy isAttorney={isAttorney} />
    <Onboard.Container>
      <div>
        {schools.length === 0 ? (
          <NoEntriesCopy />
        ) : (
          schools.map((school, key) => {
            return (
              <Entry
                addJustUploadedId={addJustUploadedId}
                justUploadedIds={justUploadedIds}
                onDeleteClick={onDeleteClick}
                last={schools.length === key + 1}
                mobile
                onEditClick={onEditClick}
                ownContractor={ownContractor}
                refreshSchools={refreshSchools}
                school={school}
                key={key}
              />
            );
          })
        )}
      </div>
      <Onboard.ContainerFooter onClick={onFooterClick}>
        + ADD A NEW SCHOOL
      </Onboard.ContainerFooter>
    </Onboard.Container>
  </div>
);

export const DesktopEntries = ({
  actionIsRequired,
  addJustUploadedId,
  customErrorMsg,
  isAttorney,
  justUploadedIds,
  onDeleteClick,
  onEditClick,
  onFooterClick,
  ownContractor,
  refreshSchools,
  schools,
  verifIsPending,
}) => (
  <Onboard.Container>
    <Onboard.ContainerHeader>
      <div>
        <h1>Education</h1>
      </div>
      <div>
        {actionIsRequired && <Label color="danger">ACTION REQUIRED</Label>}
      </div>
    </Onboard.ContainerHeader>
    {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
    {verifIsPending && <PendingVerificationCopy />}
    <MainCopy isAttorney={isAttorney} />
    <Onboard.Table>
      <thead>
        <tr>
          <th>SCHOOL NAME</th>
          <th>DEGREE</th>
          <th>CLASS</th>
          <th>GPA</th>
          {!ownContractor.is_attorney && (
            <Fragment>
              <th>TRANSCRIPT</th>
              <th>STATUS</th>
            </Fragment>
          )}
          <th />
        </tr>
      </thead>
      <tbody>
        {schools.length === 0 ? (
          <tr>
            <td colSpan={!ownContractor.is_attorney ? "7" : "5"}>
              <NoEntriesCopy />
            </td>
          </tr>
        ) : (
          schools.map((school, key) => (
            <Entry
              addJustUploadedId={addJustUploadedId}
              justUploadedIds={justUploadedIds}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              ownContractor={ownContractor}
              refreshSchools={refreshSchools}
              school={school}
              key={key}
            />
          ))
        )}
      </tbody>
    </Onboard.Table>
    <Onboard.ContainerFooter onClick={onFooterClick}>
      + ADD A NEW SCHOOL
    </Onboard.ContainerFooter>
  </Onboard.Container>
);
