import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";

import {
  BackgroundIconLeft,
  BackgroundIconRight,
  Footer,
  FormContainer,
  FormHeader,
  Signup,
  Subtitle,
  Title,
} from "./blocks";
import { compose, connect, mutateAsync } from "queries/utils";
import { history as browserHistory } from "app";
import { VerifiedContractorCount } from "common/components";
import { handleSubmissionError } from "common/utils/forms";
import setCampaignParams from "common/components/setCampaignParams";
import setDocumentTitle from "common/components/setDocumentTitle";
import { getCookie } from "common/utils";
import HiringAgencySignupForm from "public/forms/signup/HiringAgencySignupForm";
import { requestSubmitHiringAgencySignup } from "public/actions/signup";
import {
  checkDiscountCodeStatusQuery,
  applyDiscountCodeQuery,
} from "queries/mutations/discountCodes";

export class HiringAgencySignup extends Component {
  componentDidMount() {
    this.setCookie();
  }

  setCookie = () => {
    const hubspotCookie =
      getCookie("hubspotutk") || "cafebabefeeddeadbeefdefec8";
    document.cookie = `hubspotutk=${hubspotCookie}`;
  };

  validateDiscountCode = async discountCode => {
    const { checkDiscountCodeStatus } = this.props;
    let errorMsg = null;

    if (discountCode) {
      const { body, status } = await checkDiscountCodeStatus(discountCode);
      if (status >= 200 && status < 300) {
        if (!body.can_be_applied) {
          if (!body.exists) {
            return "Invalid code.";
          } else {
            return "This code is either expired or it has already been redeemed.";
          }
        }
        return null;
      } else {
        return "Network error.";
      }
    }
  };

  handleSubmit = async values => {
    const {
      props: { applyDiscountCode, submitHiringAgencySignup },
      validateDiscountCode,
    } = this;
    const { discount_code, ...signupValues } = values;
    const validationErrorMsg = await validateDiscountCode(discount_code);

    if (validationErrorMsg) {
      throw new SubmissionError({ discount_code: validationErrorMsg });
    }

    return submitHiringAgencySignup(signupValues)
      .then(handleSubmissionError)
      .then(() => applyDiscountCode(discount_code))
      .then(() => browserHistory.push("/agency/signup_complete"));
  };

  render() {
    const { id: discountCode } = this.props.match.params;

    return (
      <Signup>
        <BackgroundIconLeft />
        <BackgroundIconRight />
        <Title>Exceptional legal talent awaits!</Title>
        <Subtitle>
          Instantly match with the best legal talent for your role with our
          proprietary combination of technology, psychology, and network of{" "}
          <VerifiedContractorCount /> vetted legal professionals.
        </Subtitle>
        <FormContainer>
          <FormHeader>
            <Title>Create your account</Title>
            <div className="text-center">
              <Link to="/login">Have an account? Log in</Link>
            </div>
          </FormHeader>
          <HiringAgencySignupForm
            discountCode={discountCode}
            onSubmit={this.handleSubmit}
          />
        </FormContainer>
        <Footer>
          We verify the standing and identity of all law firms, companies, and
          legal professionals. This typically occurs within the hour during
          normal business hours.
        </Footer>
      </Signup>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkDiscountCodeStatus: discountCode =>
    dispatch(mutateAsync(checkDiscountCodeStatusQuery(discountCode))),
  submitHiringAgencySignup: values =>
    dispatch(requestSubmitHiringAgencySignup(values)),
  applyDiscountCode: discountCode =>
    dispatch(mutateAsync(applyDiscountCodeQuery(discountCode))),
});

HiringAgencySignup = compose(
  setDocumentTitle("Law Practice Signup"),
  setCampaignParams,
  connect(
    null,
    mapDispatchToProps,
  ),
)(HiringAgencySignup);

export default HiringAgencySignup;
