import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { addNotification as notify } from "reapop";

import { stashProfilePictureSelector } from "contractor/selectors";
import { withOwnContractor } from "contractor/connectors";
import {
  clearStashedProfilePicture,
  submitStashProfilePicture,
  submitAddProfilePicture,
} from "contractor/actions/profile";
import { createErrorNotification } from "common/utils/notifications";
import Loading from "common/components/Loading";
import ProfilePictureForm from "contractor/forms/ProfilePictureForm";

const stashUrl = "/api/dev/contractors/stash_profile_picture/";

export class EditProfilePicture extends Component {
  handleClearStashUrl = () => {
    this.props.clearStashedProfilePicture();
  };

  handleCropSave = cropData => {
    const {
      stashProfilePicture: {
        data: { url },
      },
      onReload,
      notify,
    } = this.props;
    return this.props
      .submitAddProfilePicture({ ...cropData, url })
      .then(({ response }) => {
        if (!response.ok) notify(createErrorNotification());
        onReload();
      });
  };

  handleProfilePictureUpload = formData =>
    this.props
      .submitStashProfilePicture(stashUrl, formData)
      .then(({ response }) => {
        if (!response.ok) this.props.notify(createErrorNotification());
      });

  render() {
    const {
      stashProfilePicture: {
        data: { url },
      },
      ownContractor: {
        data: { profile_picture_url },
        isLoading,
        lastUpdated,
      },
    } = this.props;

    return (
      <div>
        <h2 className="edit-section-title">Profile Picture</h2>
        {isLoading || !lastUpdated ? (
          <Loading />
        ) : (
          <ProfilePictureForm
            stashUrl={url}
            profile_picture_url={profile_picture_url}
            onProfilePictureUpload={this.handleProfilePictureUpload}
            onCropSave={this.handleCropSave}
            onClearStashUrl={this.handleClearStashUrl}
          />
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    stashProfilePictureSelector,
    {
      submitStashProfilePicture,
      submitAddProfilePicture,
      clearStashedProfilePicture,
      notify,
    },
  ),
  withOwnContractor(),
)(EditProfilePicture);
