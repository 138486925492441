import styled from "styled-components";

const Main = styled.div`
  width: 100%;
  height: 24px;
  margin: 10px 0 3px 0;
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.white()};
  border: 2px solid ${({ theme }) => theme.colors.primary_turquoise()};
  border-radius: 34px;
  box-sizing: border-box;
`;

export default Main;
