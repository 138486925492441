import React, { Component } from "react";

import Progress from "./blocks";

export default class OnboardingProgress extends Component {
  render() {
    const { percentage } = this.props;

    return (
      <Progress>
        <Progress.Container>
          <Progress.TopLabel>Your Progress</Progress.TopLabel>
          <Progress.Main>
            <Progress.Bar percentage={percentage} />
          </Progress.Main>
          <Progress.BottomLabel>
            {percentage === 100 && <Progress.BlueCheck />}
            ACCOUNT ON HIRE AN ESQUIRE
          </Progress.BottomLabel>
        </Progress.Container>
      </Progress>
    );
  }
}
