import React, { Component } from "react";
import { compose } from "redux";
import { history as browserHistory } from "app";

import { handleSubmissionError } from "common/utils/forms";
import setCampaignParams from "common/components/setCampaignParams";
import setDocumentTitle from "common/components/setDocumentTitle";
import AttorneySignupForm from "public/forms/signup/AttorneySignupForm";
import { requestSubmitAttorneySignup } from "public/actions/signup";
import LegalProfSignup from "public/components/signup/LegalProfSignup";

export class AttorneySignup extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, dispatch) {
    return dispatch(requestSubmitAttorneySignup(values))
      .then(handleSubmissionError)
      .then(() => {
        browserHistory.push("/attorney/signup_complete/");
      });
  }

  render() {
    return (
      <LegalProfSignup>
        <AttorneySignupForm onSubmit={this.handleSubmit} />
      </LegalProfSignup>
    );
  }
}

export default compose(
  setDocumentTitle("Attorney Signup"),
  setCampaignParams,
)(AttorneySignup);
