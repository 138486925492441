import React, { Component } from "react";
import { Button, Clearfix } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { compose, connect } from "queries/utils";
import { SharePublicJobButton } from "common/components/jobs";
import { JobWorkerActionButtons } from "contractor/components/job";
import { createResourceListQuery } from "queries";
import { requestAsync } from "redux-query";
import types from "resources/types";

const SendMessageButton = ({ jobId }) => (
  <LinkContainer to={`/contractor/jobs/${jobId}/message`}>
    <Button bsStyle={"primary"} style={{ marginRight: "3px" }}>
      Message Hiring Agent
    </Button>
  </LinkContainer>
);

const ApplyButton = ({ jobId }) => (
  <LinkContainer className="pull-right" to={`/contractor/jobs/${jobId}/apply/`}>
    <Button bsSize={"large"} bsStyle={"success"}>
      Apply Now!
    </Button>
  </LinkContainer>
);

const CompleteOnboardingButton = () => (
  <LinkContainer className="pull-right" to={`/contractor/onboarding`}>
    <Button bsSize={"large"} bsStyle={"warning"}>
      Complete Account Setup
    </Button>
  </LinkContainer>
);

const AssessmentButton = ({ candidateId }) => (
  <LinkContainer
    className="pull-right"
    to={`/contractor/workstyle-assessment/${candidateId}/`}
  >
    <Button bsStyle={"primary"} style={{ marginRight: "3px" }}>
      WORKSTYLE ASSESSMENT
    </Button>
  </LinkContainer>
);

class JobActions extends Component {
  componentDidMount() {
    const { candidate, getCompetencyQuestions } = this.props;
    if (candidate) getCompetencyQuestions();
  }

  render() {
    const {
      job,
      jobListing,
      worker,
      candidate,
      competencyQuestions,
      hasPermission,
    } = this.props;
    const isWorker = Boolean(worker);
    const hasApplied = Boolean(candidate);
    const canSendMessage = hasPermission || hasApplied;
    // TODO: Once job status is on jobListing, use that here and remove job prop
    const canApply = job.isOpenForApplications && hasPermission && !hasApplied;
    const questions = competencyQuestions
      ? Object.values(competencyQuestions.data)
      : null;
    const canTakeAssessment = hasApplied && questions && questions.length > 0;

    return (
      <div style={{ marginBottom: "0", marginTop: "1.5rem" }}>
        {canSendMessage && <SendMessageButton jobId={jobListing.job} />}
        {isWorker && (
          <JobWorkerActionButtons worker={worker} jobId={jobListing.job} />
        )}
        {canApply && <ApplyButton jobId={jobListing.job} />}
        {!hasPermission && <CompleteOnboardingButton />}
        {canTakeAssessment && <AssessmentButton candidateId={candidate.uuid} />}
        <SharePublicJobButton jobId={jobListing.job} text="Share" />
        <Clearfix />
      </div>
    );
  }
}

const competencyQuestionsQuery = props =>
  createResourceListQuery(types.COMPETENCY_QUESTIONS, {
    url: `/api/v2/competency_questions/?candidate_not_completed=${props.candidate.uuid}&limit=999`,
    force: true,
    replace: true,
  });
const mapStatetoProps = state => ({
  competencyQuestions: state.entities.competencyQuestions,
});
const mapDispatchToProps = (dispatch, props) => ({
  getCompetencyQuestions: () =>
    dispatch(requestAsync(competencyQuestionsQuery(props))),
});

export default compose(
  connect(
    mapStatetoProps,
    mapDispatchToProps,
  ),
)(JobActions);
