import types from "resources/types";
import { mutateResourceQuery } from "queries";

export const update = (id, data) =>
  mutateResourceQuery(
    types.JOB_LISTINGS,
    {
      url: `/api/v2/joblistings/${id}/`,
      options: {
        method: "PATCH",
      },
    },
    data,
    id,
  );
