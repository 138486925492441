import React, { Fragment } from "react";
import {
  formValueSelector,
  reduxForm,
  FieldArray,
  SubmissionError,
} from "redux-form";
import { mutateAsync } from "redux-query";
import { addNotification } from "reapop";
import { Alert, Button, Panel } from "react-bootstrap";
import moment from "moment";

import {
  BarRequirementsFieldArray,
  CheckboxField,
  ContractorTypeSelectField,
  DatePickerField,
  DollarPerHourInput,
  ExpertiseAreasSelectField,
  HoursPerWeekSelectField,
  LanguageProficienciesFieldArray,
  NumberField,
  PositionTypeSelectField,
  PracticeAreaRequirementsFieldArray,
  PrimaryPracticeAreaSelectField,
  ProjectLengthSelectField,
  TextAreaField,
  TextField,
  YearsExperienceRadioField,
} from "common/components/forms/inputs";
import { OpacitySlideInTransition } from "common/utils/transitions";
import {
  createErrorNotification,
  createSuccessNotification,
} from "common/utils/notifications";
import { Error, validateRequiredFields } from "common/utils/forms/validators";
import { withFormError } from "hiringagent-dashboard/components/jobs/create-job-listing/utils";
import { JobAnalysis } from "hiringagent-dashboard/components/jobs/create-job-listing";
import { LocationsInputArray } from "hiringagent-dashboard/components/jobs/create-job-listing/Location";
import { update } from "queries/mutations/job-listings";
import { compose, connect } from "queries/utils";
import JobListing from "types/JobListing";

const requiredFields = [
  "allowed_applicants",
  "expertisearea_set",
  "hours_per_week",
  "position_type",
  "primary_practice_area",
  "project_length",
  "remote",
  "start_date",
  "title",
];

const formSelector = formValueSelector("editJobListing");

const validateLocation = (values, errors = {}) => {
  const { remote, joblistinglocation_set } = values;
  if (!remote && joblistinglocation_set && joblistinglocation_set.length < 1) {
    errors.joblistinglocation_set = {
      _error: (
        <Error>You must specify at least one city or select remote.</Error>
      ),
    };
  }
  return errors;
};

const validateExperience = (values, errors = {}) => {
  const minOptions = JobListing.experienceOptions.map(option => option.min);
  const maxOptions = JobListing.experienceOptions.map(option => option.max);
  if (
    !minOptions.includes(values.min_years_experience) ||
    !maxOptions.includes(values.max_years_experience)
  ) {
    // Store all errors on min_years_experience
    errors.min_years_experience = (
      <Error>Please select one of the options above.</Error>
    );
  }
  return errors;
};

const validateExpertiseAreas = (values, errors = {}) => {
  if (values.expertisearea_set && values.expertisearea_set.length > 12) {
    errors.expertisearea_set = (
      <Error>Please select no more than 12 expertise areas.</Error>
    );
  }
  return errors;
};

const validate = values =>
  compose(
    errors => validateRequiredFields(requiredFields, values, errors),
    errors => validateExperience(values, errors),
    errors => validateExpertiseAreas(values, errors),
    errors => validateLocation(values, errors),
    errors => LanguageProficienciesFieldArray.validate(values, errors),
    errors => BarRequirementsFieldArray.validate(values, errors),
  )({});

const EditJobListingForm = props => {
  const {
    change,
    handleSubmit,
    jobListing,
    formValues: {
      allowed_applicants,
      max_years_experience,
      min_years_experience,
      primary_practice_area,
      remote,
    },
    notify,
    submitting,
    submitFailed,
    submitFormData,
  } = props;
  const handleContractorTypeChange = () => {
    change("position_type", null);
    change("bar_requirements", []);
  };
  const handleExperienceChange = (min, max) => {
    change("min_years_experience", min);
    change("max_years_experience", max);
  };
  const handleRemoteChange = (e, value) => {
    if (value) {
      change("joblistinglocation_set", []);
    }
  };
  const prepValuesForSubmit = values => {
    // Don't mutate values
    const newValues = { ...values };

    // Data cleaning before submit
    if (newValues.remote) {
      newValues.joblistinglocation_set = [];
    }
    newValues.expertisearea_set = newValues.expertisearea_set.map(value => {
      const existing = jobListing.expertisearea_set.find(
        expertisearea => expertisearea.tag === value,
      );
      // If expertise area exists on jobListing, add its uuid to data
      return {
        tag: value,
        ...(existing && { uuid: existing.uuid }),
      };
    });
    newValues.primarypracticearearequirement_set = newValues.primarypracticearearequirement_set
      .filter(value => value.preference !== -1)
      .map(value => {
        const existing = jobListing.primarypracticearearequirement_set.find(
          requirement =>
            requirement.tag_group_requirement === value.tag_group_requirement,
        );
        if (!existing) delete value.uuid;
        // If requirement area exists on jobListing, add its uuid to data
        return {
          ...value,
          ...(existing && { uuid: existing.uuid }),
        };
      });
    return newValues;
  };
  const onSubmit = values => {
    return submitFormData(prepValuesForSubmit(values)).then(
      ({ status, body }) => {
        if (status >= 200 && status < 300) {
          return notify(
            createSuccessNotification({
              message: "Your changes have been saved.",
            }),
          );
        }
        let message = "Please try again. If problem persists, contact support.";
        if (status === 400) {
          message = "Please fix the errors below.";
        }
        if (status === 500) {
          message =
            "There was a problem submitting your request. Please refresh this page and try again.";
        }
        notify(
          createErrorNotification({
            message,
          }),
        );
        if (body.hasOwnProperty("non_field_errors")) {
          body._error = body.non_field_errors;
          delete body.non_field_errors;
        }
        throw new SubmissionError(body);
      },
    );
  };

  const displayBarRequirements =
    allowed_applicants === "attorneys" || allowed_applicants === "any";
  const submittingIcon = <i className="far fa-cog fa-spin" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: "20px" }}>
      {submitFailed &&
        !submitting && (
          <Alert bsStyle="danger">
            <Error>Please fix the errors below and save again</Error>
          </Alert>
        )}
      <PrimaryPracticeAreaSelectField label="Primary Practice Area" />
      <ExpertiseAreasSelectField label="Areas of Expertise" />
      <label>Location</label>
      <Panel>
        <Panel.Body>
          <CheckboxField
            name="remote"
            label="This is a remote job"
            onChange={handleRemoteChange}
          />
          <OpacitySlideInTransition>
            {!remote && (
              <div key="joblistinglocation_set-fieldarray">
                <FieldArray
                  name="joblistinglocation_set"
                  component={LocationsInputArray}
                  jobListing={jobListing}
                />
              </div>
            )}
          </OpacitySlideInTransition>
        </Panel.Body>
      </Panel>
      <ContractorTypeSelectField
        label="Allowed Applicants"
        onChange={handleContractorTypeChange}
      />
      <PositionTypeSelectField allowedApplicants={allowed_applicants} />
      <label>Desired Experience</label>
      <Panel>
        <Panel.Body>
          <YearsExperienceRadioField
            onChange={handleExperienceChange}
            formValues={{
              max_years_experience,
              min_years_experience,
            }}
          />
        </Panel.Body>
      </Panel>
      <TextField name="title" label="Job title" />
      <Panel>
        <Panel.Heading>
          <Panel.Title toggle>Job Requirements</Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          <FieldArray
            name="primarypracticearearequirement_set"
            component={PracticeAreaRequirementsFieldArray}
            formValues={{ allowed_applicants, primary_practice_area }}
          />
        </Panel.Body>
      </Panel>
      <label>Language Proficiencies</label>
      <Panel>
        <Panel.Body>
          <FieldArray
            name="joblistinglanguage_set"
            component={LanguageProficienciesFieldArray}
          />
        </Panel.Body>
      </Panel>
      {displayBarRequirements && (
        <Fragment>
          <label>Bar Admission Requirements</label>
          <Panel>
            <Panel.Body>
              <FieldArray
                name="bar_requirements"
                component={BarRequirementsFieldArray}
              />
            </Panel.Body>
          </Panel>
        </Fragment>
      )}
      <label>Job Analysis</label>
      <Panel>
        <Panel.Heading>
          <Panel.Title toggle>Job Analysis</Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <JobAnalysis
              jobListing={jobListing}
              formProps={{ form: "editJobListing" }}
              showDescription={false}
            />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
      <TextAreaField
        name="description"
        rows="10"
        maxLength={500}
        label="Additional Details"
      />
      <DatePickerField
        name="start_date"
        label="Estimated start date"
        minDate={moment().toDate()}
      />
      <ProjectLengthSelectField />
      <HoursPerWeekSelectField />
      <NumberField name="num_openings" label="Number of openings" min={0} />
      <DollarPerHourInput name="min_estimated_rate" label="Min. rate" min={0} />
      <DollarPerHourInput name="max_estimated_rate" label="Max. rate" min={0} />
      <CheckboxField name="make_private" label="Make this job private" />

      {submitFailed &&
        !submitting && (
          <Alert bsStyle="danger">
            <Error>Please fix the errors below and save again</Error>
          </Alert>
        )}

      <div className="text-center">
        <Button bsStyle="primary" type="submit" disabled={submitting}>
          {submitting ? (
            <span>{submittingIcon} Saving...</span>
          ) : (
            "Save Changes"
          )}
        </Button>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    validate,
    form: "editJobListing",
  }),
  withFormError({ showBelow: true }),
  connect(
    state => ({
      formValues: {
        allowed_applicants: formSelector(state, "allowed_applicants"),
        max_years_experience: formSelector(state, "max_years_experience"),
        min_years_experience: formSelector(state, "min_years_experience"),
        primary_practice_area: formSelector(state, "primary_practice_area"),
        remote: formSelector(state, "remote"),
      },
    }),
    (dispatch, props) => ({
      submitFormData: data =>
        dispatch(mutateAsync(update(props.jobListing.uuid, data))),
      notify: message => dispatch(addNotification(message)),
    }),
  ),
)(EditJobListingForm);
