import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import api, { oldApi } from "common/api";
import { metaGenerator } from "common/actions";

const actions = keyMirror({
  REQUEST_HOW_YOU_HEARD: null,
  RECEIVE_HOW_YOU_HEARD: null,
  REQUEST_SUBMIT_ATTORNEY_SIGNUP: null,
  RECEIVE_SUBMIT_ATTORNEY_SIGNUP: null,
  REQUEST_SUBMIT_NON_ATTORNEY_SIGNUP: null,
  RECEIVE_SUBMIT_NON_ATTORNEY_SIGNUP: null,
  REQUEST_SUBMIT_HIRING_AGENCY_SIGNUP: null,
  RECEIVE_SUBMIT_HIRING_AGENCY_SIGNUP: null,
  SET_NON_ATTORNEY_TYPE: null,
  UPDATE_NON_ATTORNEY_SUBTYPES: null,
  RESET_NON_ATTORNEY_SUBTYPES: null,
});
export default actions;

export const requestHowYouHeardInfo = () =>
  api.get("/api/v2/how_you_heard/", [
    actions.REQUEST_HOW_YOU_HEARD,
    actions.RECEIVE_HOW_YOU_HEARD,
  ]);

export const requestSubmitAttorneySignup = data => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_ATTORNEY_SIGNUP)());
  return oldApi
    .post("/api/v2/attorney/signup/", data)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error submitting attorney signup form.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_ATTORNEY_SIGNUP,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const requestSubmitNonAttorneySignup = data => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_NON_ATTORNEY_SIGNUP)());
  return oldApi
    .post("/api/v2/non_attorney/signup/", data)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error submitting non-attorney signup form.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_NON_ATTORNEY_SIGNUP,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};

export const requestSubmitHiringAgencySignup = data => dispatch => {
  dispatch(createAction(actions.REQUEST_SUBMIT_HIRING_AGENCY_SIGNUP)());
  return oldApi
    .post("/api/v2/hiring_agency/signup/", data)
    .then(({ json, response }) => {
      const payload = response.ok
        ? json
        : new Error("Error submitting hiring agency signup form.");
      dispatch(
        createAction(
          actions.RECEIVE_SUBMIT_HIRING_AGENCY_SIGNUP,
          p => p,
          metaGenerator,
        )(payload),
      );
      return { json, response };
    });
};
