import React, { Component } from "react";
import {
  areResourcesReady,
  compose,
  connect,
  connectRequest,
} from "queries/utils";
import { createStructuredSelector } from "reselect";

import { AlertSystem } from "hiringagent-dashboard/components/alerts";
import { createResourceListQuery, createResourceSelectorConfig } from "queries";
import { agencyQuery } from "queries/requests/agencies";
import types from "resources/types";

class AlertSystemContainer extends Component {
  render() {
    const {
      jobId,
      [types.AGENCY]: { data: agencyData },
      [types.JOBS]: { data: jobsData },
      [types.JOB_LISTINGS]: { data: jobListingsData },
    } = this.props;

    const isReady = areResourcesReady(
      [types.AGENCY, types.JOBS, types.JOB_LISTINGS].map(t => this.props[t]),
    );

    return !isReady ? null : (
      <AlertSystem
        jobId={jobId}
        jobs={jobsData}
        jobListings={jobListingsData}
        agency={Object.values(agencyData)[0]}
      />
    );
  }
}

const jobsQuery = () =>
  createResourceListQuery(types.JOBS, {
    url: "/api/v2/jobs/?limit=999",
    force: true,
  });
const jobListingsQuery = () =>
  createResourceListQuery(types.JOB_LISTINGS, {
    url: "/api/v2/joblistings/?limit=999",
    force: true,
  });

const mapPropsToConfig = props => [
  agencyQuery(),
  jobsQuery(props),
  jobListingsQuery(props),
];

const agencySelectorConfig = createResourceSelectorConfig(
  types.AGENCY,
  agencyQuery,
);
const jobsSelectorConfig = createResourceSelectorConfig(types.JOBS, jobsQuery);
const jobListingsSelectorConfig = createResourceSelectorConfig(
  types.JOB_LISTINGS,
  jobListingsQuery,
);

const mapStateToProps = createStructuredSelector({
  ...agencySelectorConfig,
  ...jobsSelectorConfig,
  ...jobListingsSelectorConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(AlertSystemContainer);
