import React, { Component } from "react";
import { createStructuredSelector } from "reselect";

import { compose, connect, connectRequest } from "queries/utils";
import Welcome from "./blocks";
import OnboardingProgress from "./OnboardingProgress";
import OnboardingWhatsNext from "./OnboardingWhatsNext";
import ActionButton from "./ActionButton";
import { onboardingRequirementsQuery } from "queries/requests/onboardingRequirements";
import { createResourceSelectorConfig } from "queries";
import types from "resources/types";
import Loading from "common/components/Loading";

const cannotApplyMsg = "Complete your account to apply to jobs on our network.";
const canApplyMsg = "You can apply to jobs! Finish setup to accept jobs.";
const completedMsg = "Your account setup is complete!";

class WelcomeBox extends Component {
  render() {
    const {
      onboardingRequirements: { data, isFinished, isPending },
    } = this.props;
    const isReady = isFinished && !isPending;
    const reqs = isReady ? data[Object.keys(data)[0]] : null;
    const hasPercentage =
      reqs &&
      reqs.percentage_completed &&
      typeof reqs.percentage_completed === "number";
    const currentStepName =
      reqs && reqs.current_step && reqs.current_step.name
        ? reqs.current_step.name
        : "onboarding";
    const canApplyToJobs = reqs ? reqs.permissions.apply_to_jobs : null;

    return (
      <Welcome>
        {isReady ? (
          <Welcome.Container>
            <h1>Welcome to Hire an Esquire</h1>
            <h2>
              {canApplyToJobs
                ? reqs.overall_status === "COMPLETED"
                  ? completedMsg
                  : canApplyMsg
                : cannotApplyMsg}
            </h2>
            {hasPercentage && (
              <OnboardingProgress percentage={reqs.percentage_completed} />
            )}
            <OnboardingWhatsNext reqs={reqs} />
            {currentStepName && (
              <ActionButton currentStepName={currentStepName} />
            )}
          </Welcome.Container>
        ) : (
          <Loading />
        )}
      </Welcome>
    );
  }
}

const onboardingRequirementsSelectorConfig = createResourceSelectorConfig(
  types.ONBOARDING_REQUIREMENTS,
  onboardingRequirementsQuery,
);

const mapPropsToConfig = () => onboardingRequirementsQuery({ force: true });

const mapStateToProps = createStructuredSelector({
  ...onboardingRequirementsSelectorConfig,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(WelcomeBox);
