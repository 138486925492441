import React from "react";
import { verificationStatusMapping, VERIFICATION_STATUS } from "../utils";
import { Label } from "react-bootstrap";

const createFilter = (status, attribute, second_attribute = null) => item => {
  if (typeof item !== "object" || !item.hasOwnProperty(attribute)) {
    return false;
  }
  if (
    second_attribute &&
    (typeof item[attribute] !== "object" ||
      item[attribute] === null ||
      !item[attribute].hasOwnProperty(second_attribute))
  ) {
    return false;
  }
  return second_attribute !== null
    ? item[attribute][second_attribute] == status
    : item[attribute] == status;
};

export const filterPendingSchools = createFilter(
  VERIFICATION_STATUS.PENDING,
  "status",
);
export const filterVerifiedSchools = createFilter(
  VERIFICATION_STATUS.VERIFIED,
  "status",
);
export const filterRejectedSchools = createFilter(
  VERIFICATION_STATUS.REJECTED,
  "status",
);

export const filterPendingReferences = createFilter(
  1,
  "review",
  "approval_status",
);
export const filterVerifiedReferences = createFilter(
  3,
  "review",
  "approval_status",
);
export const filterRejectedReferences = createFilter(
  2,
  "review",
  "approval_status",
);

export const hasRecentRelatedFilter = (review_request, nonAttorney) => {
  if (!review_request.related_previous_employment_data) {
    return false;
  }
  if (!review_request.related_previous_employment_data.end_date) {
    return true;
  }
  const fiveYearsEarlier = new Date(nonAttorney.created);
  fiveYearsEarlier.setFullYear(fiveYearsEarlier.getFullYear() - 5);
  const end_date = new Date(
    review_request.related_previous_employment_data.end_date,
  );

  return end_date >= fiveYearsEarlier;
};

export const createVisibilityStyle = visible => ({
  visibility: visible ? "visible" : "hidden",
});

export const StatusLabel = ({ status }) => {
  const { bsStyle, label } = verificationStatusMapping[status];
  return <Label bsStyle={bsStyle}>{label}</Label>;
};
