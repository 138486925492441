import { createAction } from "redux-actions";
import keyMirror from "keymirror";

import { metaGenerator } from "common/actions";
import { oldApi } from "common/api";
import { requestHiringAgent } from "./sidebar";

const actions = keyMirror({
  REQUEST_FAVORITES: null,
  RECEIVE_FAVORITES: null,
  REQUEST_UPDATE_FAVORITE: null,
  RECEIVE_UPDATE_FAVORITE: null,
});
export default actions;

// TODO: This is already being sent along with the request to /hiring_agents/self/. Should it be broken out?
// export function requestFavorites() {
//    return (dispatch, getState) => {
//        dispatch(createAction(actions.REQUEST_FAVORITES)())
//        return api.get(
//            '/api/dev/hiring_agents/favorites/'
//        ).then(
//            ({json, response}) => {
//                const payload = response.ok ? json : new Error('Error retrieving favorites.')
//                dispatch(createAction(actions.RECEIVE_FAVORITES)(payload))
//            }
//        )
//    }
// }

// TODO: Need a way to access getState before calling new API methods
export function addToFavorites(contractorUuid) {
  return (dispatch, getState) => {
    dispatch(createAction(actions.REQUEST_UPDATE_FAVORITE)());
    const { uuid } = getState().hiringAgentDashboard.sidebar.hiringAgent;
    return oldApi
      .post(`/api/dev/hiring_agents/${uuid}/a_list/`, {
        contractor: contractorUuid,
      })
      .then(({ response }) => {
        const payload = response.ok
          ? { contractorUuid, isFavorite: true }
          : new Error("Error adding favorite.");
        dispatch(requestHiringAgent());
        return dispatch(
          createAction(
            actions.RECEIVE_UPDATE_FAVORITE,
            payload => payload,
            metaGenerator,
          )(payload),
        );
      });
  };
}

export function removeFromFavorites(contractorUuid) {
  return (dispatch, getState) => {
    dispatch(createAction(actions.REQUEST_UPDATE_FAVORITE)());
    const { favorites } = getState().hiringAgentDashboard.favorites;
    const favoriteContractor = favorites.find(
      f => f.contractor.uuid === contractorUuid,
    );
    return oldApi
      .del(`/api/dev/a_list/${favoriteContractor.uuid}/`)
      .then(response => {
        const payload = response.ok
          ? { contractorUuid, isFavorite: false }
          : new Error("Error removing favorite.");
        dispatch(requestHiringAgent());
        return dispatch(
          createAction(
            actions.RECEIVE_UPDATE_FAVORITE,
            payload => payload,
            metaGenerator,
          )(payload),
        );
      });
  };
}
