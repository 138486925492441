// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

const LabelExtra: ComponentType<{}> = styled.span`
  justify-self: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary_turquoise()};

  ${({ theme }) => theme.media.mobile`
    display: none;
  `};
`;

export default LabelExtra;
