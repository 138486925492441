import styled from "styled-components";

import { Panel, Row as BSRow } from "react-bootstrap";

const Card = styled(Panel)`
  padding: 1.5rem;

  /* Bootstrap overrides */
  &.panel {
    margin-bottom: 30px;
  }
`;

const Row = styled(BSRow)`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  text-transform: uppercase;
  font-weight: bold;
`;

Card.Row = Row;
Card.Title = Title;

export default Card;
