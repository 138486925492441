// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";

type Props = {};

const ContainerContent: ComponentType<Props> = styled.div`
  div {
    display: flex;
    align-items: center;
    vertical-align: middle;

    div:first-child {
      height: 100%;
      line-height: 100%;
      text-transform: uppercase;
      color: #5cb85c;
    }

    div:last-child {
      justify-content: flex-end;
    }
  }
`;

export default ContainerContent;
