import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import styled from "styled-components";

import { Loading } from "common/components";
import { savedContractorsRequest } from "queries/requests";
import { createResourceSelector } from "queries/selectors/hooks";
import types from "resources/types";
import AttorneyProfilePicture from "../search/AttorneyProfilePicture";
import AttorneyNameIcon from "hiringagent-dashboard/components/attorney/AttorneyNameIcon";
import BasePanel from "hiringagent-dashboard/components/dashboard/BasePanel";
import { SavedContractorTalentPoolDropdown } from "hiringagent-dashboard/components/talentPools";

const Layout = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "picture name"
      "picture talent-pools";
    column-gap: 15px;
    row-gap: 5px;
    padding: 10px;
  `,
  LineItem: styled.div`
    &:nth-child(odd) {
      background-color: #f7f7f7;
    }
  `,
  Name: styled.div`
    grid-area: name;
  `,
  Picture: styled.div`
    grid-area: picture;
  `,
  TalentPools: styled.div`
    grid-area: talent-pools;
  `,
};

const SavedContractorsPanel = () => {
  const request = savedContractorsRequest({ url: { params: { limit: 2 } } });
  const selector = createResourceSelector(types.SAVED_CONTRACTORS, request);
  const {
    [types.SAVED_CONTRACTORS]: {
      data,
      query: { data: queryData, count },
    },
  } = useSelector(selector);
  const [{ isFinished, isPending }, _] = useRequest(request);

  return (
    <BasePanel size={6} name="saved talent" isLoading={isPending} action={null}>
      <>
        {isPending && <Loading />}
        {isFinished &&
          count > 0 &&
          queryData
            .map(id => data[id])
            .map(savedContractor => {
              const { contractor_data: contractor, uuid } = savedContractor;
              return (
                <Layout.LineItem key={uuid}>
                  <Layout.Container>
                    <Layout.Picture>
                      <AttorneyProfilePicture
                        pictureUrl={contractor.profile_picture_url}
                        size={60}
                        lastName={contractor.last_name}
                        firstName={contractor.first_name}
                        uuid={contractor.uuid}
                      />
                    </Layout.Picture>
                    <Layout.Name>
                      <Link to={`/agency/attorneys/${contractor.uuid}/`}>
                        <AttorneyNameIcon
                          isAttorney={contractor.contractor_type === "Attorney"}
                          className="attorney-title-small"
                        >
                          {contractor.full_name}
                        </AttorneyNameIcon>
                      </Link>
                    </Layout.Name>
                    <Layout.TalentPools>
                      <SavedContractorTalentPoolDropdown
                        savedContractor={savedContractor}
                      />
                    </Layout.TalentPools>
                  </Layout.Container>
                </Layout.LineItem>
              );
            })}
        {count > 3 && (
          <div className="line-item text-center">
            <Row>
              <Col xs={12}>
                <Link to="/agency/talent_pools">View talent pools</Link>
              </Col>
            </Row>
          </div>
        )}
        {isFinished && count === 0 && (
          <div className="line-item text-center">
            <Row>
              <Col>
                You haven&apos;t saved any contractors to your talent pools.{" "}
                <br />
                <Link to="/agency/search">Start searching</Link> for some great
                legal professionals!
              </Col>
            </Row>
          </div>
        )}
      </>
    </BasePanel>
  );
};

export default SavedContractorsPanel;
