// @flow strict
import styled from "styled-components";
import { Label as BootstrapLabel } from "react-bootstrap";

const Label = styled(BootstrapLabel).attrs({
  "aria-hidden": "true",
  bsStyle: "primary",
})`
  margin: 0 !important;
  padding: 3px 5px !important;
  border-radius: 30px !important;
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.colors.primary_turquoise()} !important;
  color: ${({ theme }) => theme.colors.primary_turquoise()} !important;
  background-color: ${({ theme }) => theme.colors.white()} !important;
`;

export default Label;
