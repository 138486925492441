import React from "react";
import PropTypes from "prop-types";

const SearchBar = props => (
  <div className="row search-bar">
    <form method="get" onSubmit={props.onSearchQuerySubmit}>
      <div className="col-md-8 col-md-offset-1 form-group">
        <div id="search-input">
          <label htmlFor="search-input">
            <i className="far fa-search" />
          </label>
          <input
            type="search"
            className="form-control input-lg"
            name="query"
            value={props.searchQuery}
            onChange={props.onSearchQueryChange}
          />
          {props.searchQuery !== "" && (
            <a className="clear-search" onClick={props.onSearchQueryClear}>
              <i className="far fa-times " />
            </a>
          )}
        </div>
      </div>
      <div className="col-md-2 form-group">
        <input
          type="submit"
          className="btn btn-success form-control input-lg"
          value="Search"
        />
      </div>
    </form>
  </div>
);

SearchBar.propTypes = {
  searchQuery: PropTypes.string,
  onSearchQueryChange: PropTypes.func.isRequired,
  onSearchQueryClear: PropTypes.func.isRequired,
  onSearchQuerySubmit: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  searchQuery: "",
};

export default SearchBar;
