import types from "resources/types";
import { createResourceDetailQuery, mutateResourceQuery } from "queries";

export const update = (invoiceId, data) =>
  mutateResourceQuery(
    types.INVOICES,
    {
      url: `/api/dev/invoices/${invoiceId}/`,
      options: {
        method: "PATCH",
      },
    },
    data,
  );

export const approveInvoiceQuery = invoiceId =>
  createResourceDetailQuery(types.INVOICES, {
    url: `/api/dev/invoices/${invoiceId}/approve/`,
    options: {
      method: "PATCH",
    },
  });
