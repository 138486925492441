import React, { Component } from "react";
import { connect } from "react-redux";
import { history as browserHistory } from "app";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { submit, SubmissionError } from "redux-form";
import { mutateAsync } from "redux-query";
import { addNotification } from "reapop";

import { createErrorNotification } from "common/utils/notifications";
import {
  createJobListingComponents,
  calculateJobListingFormUrl,
} from "hiringagent-dashboard/utils/jobs";
import { BarRequirements } from "hiringagent-dashboard/components/jobs/create-job-listing";
import { update } from "queries/mutations/job-listings";

const hiddenStyle = {
  visibility: "hidden",
};

export class CreateJobListingStep extends Component {
  componentDidMount() {
    this.checkStepWithinRange(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkStepWithinRange(nextProps);
  }

  checkStepWithinRange = props => {
    // Make sure this is being rendered with a reasonable step number. Redirect if not.
    const { jobListing } = props;
    const currentStep = this.getCurrentStep();
    const finalStep = createJobListingComponents.length - 1;

    if (
      Number.isNaN(currentStep) ||
      !(finalStep >= currentStep && currentStep >= 0)
    ) {
      browserHistory.push(calculateJobListingFormUrl(jobListing));
    }
    if (this.shouldSkipBarRequirements(currentStep)) {
      browserHistory.push(this.getNextUrl(-1));
    }
  };

  getStepUrl = step =>
    `/agency/jobs/create/${this.props.jobListing.job}/step/${step}/`;
  getPublishUrl = () =>
    `/agency/jobs/create/${this.props.jobListing.job}/publish/`;

  getNextUrl = increment => {
    const currentStep = this.getCurrentStep();
    let nextStep = currentStep + increment;

    if (nextStep > createJobListingComponents.length - 1) {
      return this.getPublishUrl();
    }
    if (nextStep < 0) {
      nextStep = currentStep;
    }
    if (this.shouldSkipBarRequirements(nextStep)) {
      nextStep += increment;
    }
    return this.getStepUrl(nextStep);
  };

  shouldSkipBarRequirements = nextStep => {
    const { jobListing } = this.props;
    const nextPage = createJobListingComponents[nextStep];
    return (
      nextPage &&
      nextPage.component === BarRequirements &&
      !jobListing.allowsAttorneysOnly
    );
  };

  getCurrentStep = () => Number.parseInt(this.props.match.params.step, 10);

  getJobListingComponent = () =>
    createJobListingComponents[this.getCurrentStep()];

  handleForwardClick = e => {
    e.preventDefault();
    const { formName } = this.getJobListingComponent();
    this.props.submitForm(formName);
  };

  handleSubmit = values => {
    const { notify, submitFormData } = this.props;

    return submitFormData(values).then(({ status, body }) => {
      if (status >= 200 && status < 300) {
        return;
      }

      let message = "Please try again. If problem persists, contact support.";

      if (status === 400) {
        message = "Please fix the errors below.";
      } else if (status.toString()[0] !== "2") {
        message =
          "There was a problem submitting your request. Please refresh this page and try again.";
      }

      notify(createErrorNotification({ message }));

      if (body) {
        if (body.hasOwnProperty("non_field_errors")) {
          body._error = body.non_field_errors;
          delete body.non_field_errors;
        }
        throw new SubmissionError(body);
      }
    });
  };

  handleSubmitSuccess = () => {
    browserHistory.push(this.getNextUrl(1));
  };

  render() {
    const { jobListing } = this.props;
    const currentStep = this.getCurrentStep();
    const jobListingComponent = this.getJobListingComponent();
    const progressPercent =
      (currentStep / createJobListingComponents.length) * 100;

    let jobListingStepComponent;
    if (jobListingComponent) {
      const { component: ComponentClass, formName } = jobListingComponent;
      const formProps = {
        form: formName,
        onSubmit: this.handleSubmit,
        onSubmitSuccess: this.handleSubmitSuccess,
      };
      jobListingStepComponent = (
        <div
          key={`create-job-form-component-${currentStep}`}
          style={{ minHeight: "30rem" }}
        >
          <ComponentClass jobListing={jobListing} formProps={formProps} />
        </div>
      );
    }

    return (
      <div>
        <div className="form-container">
          <Row style={{ margin: "4rem 0" }}>
            <Col lg={6} lgOffset={3}>
              {jobListingComponent && (
                <ProgressBar
                  now={progressPercent}
                  label={`${progressPercent}%`}
                  style={{ margin: 0 }}
                  srOnly
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={10} lgOffset={1}>
              <div className="form-step">
                {jobListingStepComponent && jobListingStepComponent}
              </div>
            </Col>
          </Row>
        </div>
        <div className="text-center">
          <LinkContainer to={this.getNextUrl(-1)}>
            <Button bsStyle="link" style={currentStep > 0 ? {} : hiddenStyle}>
              &larr; Back
            </Button>
          </LinkContainer>{" "}
          <Button bsStyle="info" onClick={this.handleForwardClick}>
            Next Step &rarr;
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  (dispatch, props) => ({
    submitFormData: data =>
      dispatch(mutateAsync(update(props.jobListing.uuid, data))),
    submitForm: formName => dispatch(submit(formName)),
    notify: message => dispatch(addNotification(message)),
  }),
)(CreateJobListingStep);
