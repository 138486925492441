import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import { MainCopy } from "./copy";
import ProfilePictureForm from "./ProfilePictureForm";
import NextButton from "../../NextButton";
import DesktopOnly from "common/blocks/DesktopOnly";
import MobileOnly from "common/blocks/MobileOnly";
import Label from "common/blocks/Label";
import { getActionIsRequired, getCustomErrorMsg } from "../../utils";
import { compose, connect, mutateAsync } from "queries/utils";
import {
  addProfilePictureQuery,
  stashProfilePictureQuery,
  bypassProfilePictureQuery,
} from "queries/mutations/profilePicture";
import { basePath } from "../../values";

const stepName = "profile_picture";

class ProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = { stashURL: null };
  }

  componentDidMount() {
    const { bypassProfilePicture, refreshReqs } = this.props;
    bypassProfilePicture().then(refreshReqs);
  }

  handleCropSave = async cropData => {
    const {
      state: { stashURL: url },
      props: { addProfilePicture, refreshOwnContractor, refreshReqs },
    } = this;

    const result = await addProfilePicture({ ...cropData, url });

    if (result && result.status === 200) {
      this.setState({ stashURL: null });
      refreshOwnContractor();
      refreshReqs();
    }
  };

  handleClearStashURL = () => this.setState({ stashURL: null });

  handleProfilePictureUpload = async formData => {
    const {
      props: { stashProfilePicture },
    } = this;
    const result = await stashProfilePicture(formData);

    if (result && result.status === 200) {
      this.setState({ stashURL: result.body.url });
    }
  };

  render() {
    const {
      state: { stashURL },
      props: {
        ownContractor,
        reqs: { steps },
      },
      handleCropSave,
      handleClearStashURL,
      handleProfilePictureUpload,
    } = this;
    const actionIsRequired = getActionIsRequired(stepName, steps);
    const customErrorMsg = getCustomErrorMsg(stepName, steps);

    return (
      <Onboard.Page>
        <Onboard.Container>
          <DesktopOnly>
            <Onboard.ContainerHeader>
              <div>
                <h1>Profile Picture</h1>
              </div>
              <div>
                {actionIsRequired && (
                  <Label color="danger">ACTION REQUIRED</Label>
                )}
              </div>
            </Onboard.ContainerHeader>
          </DesktopOnly>
          <MobileOnly>
            <h1>Profile Picture</h1>
            {actionIsRequired && (
              <div>
                <Label color="danger">ACTION REQUIRED</Label>
                <br />
                <br />
              </div>
            )}
          </MobileOnly>
          {customErrorMsg && <Alert bsStyle="danger">{customErrorMsg}</Alert>}
          <Onboard.OptionalLabel />
          <MainCopy />
          <ProfilePictureForm
            onCropSave={handleCropSave}
            onClearStashURL={handleClearStashURL}
            onProfilePictureUpload={handleProfilePictureUpload}
            profileImgURL={ownContractor.profile_picture_url}
            stashURL={stashURL}
          />
        </Onboard.Container>
        <Link to={`${basePath}/language-proficiencies`}>
          <NextButton />
        </Link>
      </Onboard.Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addProfilePicture: data =>
    dispatch(mutateAsync(addProfilePictureQuery(data))),
  bypassProfilePicture: () =>
    dispatch(mutateAsync(bypassProfilePictureQuery())),
  stashProfilePicture: data =>
    dispatch(mutateAsync(stashProfilePictureQuery(data))),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(ProfilePicture);
