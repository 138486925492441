import React, { Component } from "react";
import { history as browserHistory } from "app";
import { Helmet } from "react-helmet";
import { Alert } from "react-bootstrap";
import { compose, connect, connectRequest, mutateAsync } from "queries/utils";

import { ownUserQuery } from "queries/requests/auth";
import { submitConfirmationCodeQuery } from "queries/mutations/auth";

export class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, finished: false, success: false };
  }

  componentDidMount() {
    this.handleSubmit();
  }

  componentDidUpdate() {
    if (this.state.success) {
      setTimeout(this.handleRedirect, 1500);
    }
  }

  handleSubmit = async () => {
    const {
      match: {
        params: { code },
      },
    } = this.props;
    const response = await this.props.onSubmit(code);
    const isSuccessful = response ? response.status === 200 : false;
    if (isSuccessful) {
      this.setState({ error: false, finished: true, success: true });
    } else {
      this.setState({ error: true, finished: true, success: false });
    }
  };

  handleRedirect = () => browserHistory.replace("/");

  render() {
    const { error, finished, success } = this.state;
    return (
      <div>
        <Helmet>
          <title>Confirm Email</title>
        </Helmet>
        <h2>Confirm Email</h2>
        <Alert>
          {finished
            ? success && "Success! Redirecting to dashboard..."
            : "Checking your confirmation code..."}
        </Alert>
        {error && (
          <Alert bsStyle="danger">
            We&apos;re sorry, there was a problem submitting your request.
            Please refresh this page and try again.
          </Alert>
        )}
      </div>
    );
  }
}

const mapPropsToConfig = ownUserQuery;
const mapDispatchToProps = {
  onSubmit: code => mutateAsync(submitConfirmationCodeQuery(code)),
};
export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  connectRequest(mapPropsToConfig),
)(ConfirmEmail);
