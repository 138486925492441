import React from "react";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { Button, Image } from "react-bootstrap";

import { history } from "app";
import { connect, compose, connectRequest } from "queries/utils";
import { authUserQuery } from "queries/requests/auth";
import { createResourceSelectorConfig } from "queries";
import searchHighlightImg from "img/messaging-search-highlight.png";
import filteringImg from "img/messaging-filtering.png";
import paginationImg from "img/messaging-pagination.png";
import types from "resources/types";

const ExampleImageContainer = styled.div.attrs({ className: "text-center" })`
  img {
    margin: 10px auto;
  }
`;

const getUserBaseRoute = authData => {
  const auth = Object.values(authData)[0];
  const userType = auth ? auth.user_type : null;
  if (userType) {
    if (userType === "hiring_agent") return "agency";
    if (userType === "contractor") return "contractor";
  }
};

const MessagingNotification = ({ onClose, auth: { data: authData } }) => {
  const userBaseRoute = getUserBaseRoute(authData);
  if (!userBaseRoute) return null;

  return (
    <>
      <h3 className="text-center">
        <strong>Fast, Sleek Messaging</strong>
      </h3>
      <p>
        Our new and improved messaging experience provides more speed, control,
        and organization. You can now search, filter, and paginate across
        messages.
      </p>
      <h4 className="text-uppercase">How It Works:</h4>
      <ul>
        <li>
          Keep your messages clean and organized by filtering by inbox (i.e. not
          archived), unread, sent, all messages, and archived:
          <ExampleImageContainer>
            <Image
              responsive
              src={filteringImg}
              alt="Filtering field location"
            />
          </ExampleImageContainer>
        </li>
        <li>
          Find and <span className="highlight">highlight</span> key terms with
          the search field in the top left:
          <ExampleImageContainer>
            <Image
              responsive
              src={searchHighlightImg}
              alt="Searching and highlighting messages location"
            />
          </ExampleImageContainer>
        </li>
        <li>
          Paginate through groups of messages to find things faster, and jump to
          your oldest or newest messages with a simple click:
          <ExampleImageContainer>
            <Image responsive src={paginationImg} alt="Pagination location" />
          </ExampleImageContainer>
        </li>
        <li>
          New messages and conversations now automatically load, making your
          interactions more time-efficient.
        </li>
      </ul>
      <div className="text-center">
        <Button
          bsStyle="info"
          onClick={() => {
            onClose();
            history.push(`/${userBaseRoute}/messages`);
          }}
        >
          <strong className="text-uppercase">Go to Messages</strong>
        </Button>
      </div>
    </>
  );
};

const authUserConfig = createResourceSelectorConfig(types.AUTH, authUserQuery);
const mapStateToProps = createStructuredSelector({ ...authUserConfig });
const mapPropsToConfig = () => [authUserQuery()];

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(MessagingNotification);
