import React from "react";
import { Media } from "react-bootstrap";
import { Link } from "react-router-dom";

const signUpNowLink = (
  <Link to="/signup" className="text-uppercase">
    Sign up now
  </Link>
);

const MarketingIcon = ({ iconName }) => (
  <span>
    <i className={`far ${iconName} fa-2x fa-fw visible-xs`} />
    <i className={`far ${iconName} fa-3x fa-fw hidden-xs`} />
  </span>
);

export const ModernLegalCareerMarketing = () => (
  <Media className="hidden-sm hidden-xs">
    <Media.Left>
      <MarketingIcon iconName="fa-balance-scale" />
    </Media.Left>
    <Media.Body>
      <Media.Heading>
        <strong>
          We believe in a modern legal career - so do our clients.
        </strong>
      </Media.Heading>
      <p>
        Our clients include law firms and legal departments of all sizes and
        practice areas, from solos to Am Law 20 firms and agile legal
        departments to those of the Fortune 500.
      </p>
      <p>
        We work with top firms and departments, offering temporary,
        temp-to-perm, and permanent opportunities to our curated network of
        vetted legal talent. Members in our network enjoy exclusive access to
        Hire an Esquire roles, benefits, and opportunities to market themselves.
      </p>
    </Media.Body>
  </Media>
);

export const AcceptingApplicationsMarketing = () => (
  <Media>
    <Media.Left>
      <MarketingIcon iconName="fa-paper-plane" />
    </Media.Left>
    <Media.Body>
      <Media.Heading>
        <strong>
          We&apos;re accepting applications for the opportunities below!
        </strong>
      </Media.Heading>
      <p>
        Hire an Esquire opportunities change daily, sometimes hourly, and not
        all of our opportunities are publicly visible. Sign up now to join the
        attorneys, paralegals, and legal specialists building modern legal
        careers with Hire an Esquire.
      </p>
      <p>{signUpNowLink}</p>
    </Media.Body>
  </Media>
);

export const ExpandLegalCareerMarketing = () => (
  <Media>
    <Media.Left>
      <MarketingIcon iconName="fa-rocket" />
    </Media.Left>
    <Media.Body>
      <Media.Heading>
        <strong>Legal professionals: expand your legal career</strong>
      </Media.Heading>
      <p>
        Our vision of a modern legal career includes flexible, diverse work
        opportunities, direct client contact and messaging, and a guaranteed
        weekly payment when working. Our technology can help you achieve your
        dreams of being a freelance lawyer or building up a solo practice.
        Whatever your career goals, Hire an Esquire helps you get there. Sign up
        as a legal professional{" "}
        <Link to="/signup/legal_professional/">here</Link>.
      </p>
    </Media.Body>
  </Media>
);

export const MakeHiringEasierMarketing = () => (
  <Media>
    <Media.Left>
      <MarketingIcon iconName="fa-users" />
    </Media.Left>
    <Media.Body>
      <Media.Heading>
        <strong>Hiring? Make it easier</strong>
      </Media.Heading>
      <p>
        Hire an Esquire simplifies staffing by putting legal talent directly at
        your fingertips. A powerful matching algorithm simplifies candidate
        culling, user-friendly applications optimize selection, and one-click
        invoice approval slashes administrative burdens. Simplify staffing
        today. Sign up as a client <Link to="/agency/signup/">here</Link>.
      </p>
    </Media.Body>
  </Media>
);
