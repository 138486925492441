import React from "react";
import PropTypes from "prop-types";
import { Label } from "react-bootstrap";

import ToolTip from "common/components/ToolTip";

const VerifiedLabel = ({
  type,
  style,
  className,
  contractorName,
  placement,
}) => {
  let labelTitle = null;
  let toolTipTitle = null;
  let toolTipContent = null;

  if (type === "via") {
    labelTitle = "VIA Approved";
    toolTipTitle = "V.I.A. Approved Status";
    toolTipContent =
      "Attorneys earn the invitation-only V.I.A. status by passing additional screening, assessments, and vetting.";
  } else if (type === "available") {
    labelTitle = "Available";
    toolTipTitle = "Availability Confirmed";
    toolTipContent = `${contractorName} has recently confirmed that he or she is available to accept new work.`;
  }

  return (
    <ToolTip
      title={toolTipTitle}
      content={toolTipContent}
      placement={placement}
      hasChild={true}
    >
      <Label className={`${type}-label ${className}`} style={style}>
        <span>
          <i className="far fa-check" /> {labelTitle}
        </span>
      </Label>
    </ToolTip>
  );
};

VerifiedLabel.propTypes = {
  type: PropTypes.oneOf(["via", "available"]),
  style: PropTypes.object,
  className: PropTypes.string,
  contractorName: PropTypes.string,
  placement: PropTypes.string,
};
VerifiedLabel.defaultProps = {
  type: "available",
  className: "",
  contractorName: "This contractor",
  placement: "top",
};
export default VerifiedLabel;
