import styled from "styled-components";

const Circle = styled.circle.attrs({
  stroke: "rgb(0, 198, 215)",
  strokeLinecap: "round",
  fill: "transparent",
})`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export default Circle;
