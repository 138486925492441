import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import ReactCSSTransitionReplace from "react-css-transition-replace";

const BaseTransition = ({ replace = false, ...otherProps }) => {
  const TransitionClass = replace
    ? ReactCSSTransitionReplace
    : CSSTransitionGroup;
  return (
    <TransitionClass
      transitionEnterTimeout={300}
      transitionLeaveTimeout={200}
      {...otherProps}
    />
  );
};

export const OpacitySlideInTransition = props => (
  <BaseTransition transitionName="transition-slide-opacity" {...props} />
);

export const OpacityTransition = props => (
  <BaseTransition transitionName="transition-opacity" {...props} />
);

export const OpacityReplaceTransition = props => (
  <BaseTransition
    transitionName="transition-opacity-replace"
    replace
    {...props}
  />
);
