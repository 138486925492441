import React, { Component } from "react";
import { FieldArray, reduxForm } from "redux-form";
import { compose } from "redux";

import { LanguageProficienciesFieldArray } from "common/components/forms/inputs";
import { OptionalFormMessage, withFormError } from "./utils";

class LanguageRequirementsForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div>
          <FieldArray
            name="joblistinglanguage_set"
            component={LanguageProficienciesFieldArray}
          />
        </div>
      </form>
    );
  }
}

LanguageRequirementsForm = compose(
  reduxForm({
    validate: LanguageProficienciesFieldArray.validate,
    enableReinitialize: true,
  }),
  withFormError(),
)(LanguageRequirementsForm);

export default class LanguageRequirements extends Component {
  render() {
    const { formProps, jobListing } = this.props;
    const initialValues = {
      joblistinglanguage_set: jobListing.joblistinglanguage_set,
    };
    return (
      <div className="language-form">
        <h2>Do candidates need to know another language?</h2>
        <div className="description">
          <OptionalFormMessage />
          <p>
            Indicate below if this job requires proficiency in a language other
            than English.
          </p>
        </div>
        <LanguageRequirementsForm
          {...formProps}
          initialValues={initialValues}
        />
      </div>
    );
  }
}
