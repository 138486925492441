import React from "react";

const LegalProfSignup = ({ children }) => (
  <div>
    <div className="legal-prof-signup">
      <div className="signup-form-header">
        <h1 className="signup-form-title">Own your legal career.</h1>
        <h2>
          Join the most innovative, technology-driven legal talent network.
        </h2>
      </div>
      <div className="form-container">
        {/* Signup Form */}
        {children}
      </div>
      <div className="confirm-expectation-container">
        <p className="text-center">
          Hire an Esquire individually confirms all law professionals that sign
          up. During normal business hours, this step typically takes less than
          one hour.
        </p>
      </div>
      <div className="go-to-login-link">
        <a href="/login/">Have an account? Log in</a>
      </div>
    </div>
  </div>
);

export default LegalProfSignup;
