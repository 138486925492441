import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { SharePublicJobButton } from "common/components/jobs";
import { AlertSystemContainer } from "hiringagent-dashboard/components/alerts";
import { JobActionsDropdown } from "hiringagent-dashboard/components/jobs/job-details";
import { createResourceDetailQuery, createResourceSelector } from "queries";
import types from "resources/types";

export const BackToJobsButton = props => (
  <div className="back-button" {...props}>
    <Link to="/agency/jobs/">&larr; Back to Jobs</Link>
  </div>
);

export const JobTitle = ({ name, closedDate }) => (
  <div className="job-title-header">
    <h1>
      {name}
      {closedDate && (
        <small>
          <span className="text-danger">CLOSED</span>
        </small>
      )}
    </h1>
  </div>
);

export const JobHeader = props => {
  const {
    jobId,
    [types.JOBS]: { data: jobs },
  } = props;

  const job = jobs[jobId];

  return (
    <div className="job-header">
      <div className="clearfix">
        <BackToJobsButton className="pull-left" />
      </div>

      <Row>
        <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
          <AlertSystemContainer jobId={jobId} />
        </Col>
      </Row>

      <Row>
        <div className="job-header-title-actions">
          <Col md={8} xs={12}>
            <JobTitle name={job.formattedTitle} closedDate={job.closed_date} />
          </Col>
          <Col md={4} xs={12}>
            <div className="text-right">
              <SharePublicJobButton
                jobId={job.uuid}
                bsStyle="default"
                placement="bottom"
                tooltipPlacement="bottom"
                style={{ marginRight: "0.5rem" }}
              />
              <JobActionsDropdown job={job} />
            </div>
          </Col>
          <span className="clearfix" />
        </div>
      </Row>
    </div>
  );
};

const mapPropsToConfig = props =>
  createResourceDetailQuery(types.JOBS, {
    url: `/api/v2/jobs/${props.jobId}/`,
  });

const mapStateToProps = createResourceSelector(types.JOBS, mapPropsToConfig);

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(JobHeader);
