// @flow strict
// $FlowFixMe 'Cannot resolve module redux-form.'
import { SubmissionError } from "redux-form";

const validate = (result: {}, props: { total: number }) => {
  if (Object.keys(result).length !== props.total) {
    throw new SubmissionError({
      _error: "Missing responses!",
    });
  }
};

export default validate;
