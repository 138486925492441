import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { connectRequest } from "redux-query-react";
import { createQueryConfig } from "queries";

const VerifiedContractorCount = ({
  segment = "total",
  verifiedContractorCounts,
}) => {
  let numberToDisplay = 10000; // Default
  if (verifiedContractorCounts) {
    const { total, attorneys, non_attorneys } = verifiedContractorCounts;
    if (segment === "total") numberToDisplay = total;
    if (segment === "attorneys") numberToDisplay = attorneys;
    if (segment === "non_attorneys") numberToDisplay = non_attorneys;
  }
  return (
    <span>{numberToDisplay.toLocaleString("en", { useGrouping: true })}</span>
  );
};

const mapPropsToConfig = () =>
  createQueryConfig({
    url: "/api/v2/verified_contractor_counts/",
    transform: responseJson => {
      if (!responseJson) return {};
      return {
        verifiedContractorCounts: responseJson,
      };
    },
    update: {
      verifiedContractorCounts: (_, newCounts) => newCounts,
    },
  });

const mapStateToProps = state => ({
  verifiedContractorCounts: state.entities.verifiedContractorCounts,
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToConfig),
)(VerifiedContractorCount);
