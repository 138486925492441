import styled from "styled-components";

const Icon = styled.i.attrs(({ iconType }) => {
  switch (iconType) {
    case "unstarted":
      return { className: "far fa-circle" };
    case "pending":
      return { className: "far fa-minus-circle" };
    case "success":
      return { className: "far fa-check-circle" };
    case "danger":
      return { className: "far fa-exclamation-circle" };
    case "unqualifying":
      return { className: "far fa-times-circle" };
    case "unvisited":
    default:
      return { className: "far fa-circle" };
  }
})`
  &&&& {
    margin-right: 5px;
    color: ${({ iconType, theme }) => {
      switch (iconType) {
        case "unstarted":
          return theme.colors.primary_black40();
        case "pending":
          return theme.colors.warning();
        case "success":
          return theme.colors.success();
        case "danger":
          return theme.colors.danger();
        case "unqualifying":
          return theme.colors.primary_black60();
        case "unvisited":
        default:
          return theme.colors.transparent();
      }
    }};
  }
`;

export default Icon;
