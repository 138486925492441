import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { jobDetailsSet } from "common/reducers/job";
import createReducer from "common/reducers/utils";
import fixtures from "common/reducers/fixtures";

export default combineReducers({
  fixtures,
  attorney: createReducer("attorney"),
  attorneys: handleActions(
    {
      "RECEIVE/attorney": (state, action) => ({
        ...state,
        [action.payload.uuid]: action.payload,
      }),
    },
    {},
  ),
  contractor_content_types: createReducer("contractor_content_types"),
  degreeTypes: createReducer("degreeTypes"),
  firm_types: createReducer("firm_types"),
  how_you_heard: createReducer("how_you_heard"),
  jobDetails: createReducer("jobDetails"),
  jobDetailsSet,
  languages: createReducer("languages"),
  messages: createReducer("messages"),
  metadata: createReducer("metadata", {
    algolia_index: null,
    algolia_key: null,
    stripe_publishable_key: null,
    site_url: null,
  }),
  position_types: createReducer("position_types"),
  rejectionReasons: createReducer("rejectionReasons"),
  user: createReducer("changed_user_type", {
    user_type: null,
  }),
  current_user_type: createReducer("current_user_type"),
});
