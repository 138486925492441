// @flow strict
import { type ComponentType } from "react";
import styled from "styled-components";
import ReactDropzone from "react-dropzone";

type Props = {};

const Dropzone: ComponentType<Props> = styled(ReactDropzone)`
  border: 1px dashed ${({ theme }) => theme.colors.background_gray()};
  color: ${({ theme }) => theme.colors.primary_black40()};
  border-radius: 8px;
  cursor: pointer;
  margin: 0 0 15px 0;
  padding: 2rem;
  text-align: center;
`;

export default Dropzone;
