import styled from "styled-components";

const Footer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1px;

  ${({ theme }) => theme.media.mobile`
    text-align: left;
  `};
`;

export default Footer;
