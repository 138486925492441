import React, { Component } from "react";
import PropTypes from "prop-types";
import AttorneyPracticeArea from "./AttorneyPracticeArea";

export default class AttorneyPracticeAreasList extends Component {
  handlePracticeAreaClick(e, practiceAreaName) {
    e.preventDefault();
    this.props.onPracticeAreaClick(practiceAreaName);
  }

  render() {
    const {
      attorneyUuid,
      onPracticeAreaClick,
      practiceAreas,
      selectedTags,
    } = this.props;

    const attorneyPracticeAreas = practiceAreas.map((practiceArea, i) => (
      <AttorneyPracticeArea
        practiceArea={practiceArea}
        key={`${attorneyUuid}-${practiceArea.name}-${i}`}
        selected={selectedTags.includes(practiceArea)}
        onClick={
          onPracticeAreaClick
            ? e => this.handlePracticeAreaClick(e, practiceArea.name)
            : null
        }
      />
    ));

    return (
      practiceAreas.length > 0 && (
        <ul className="attorney-practice-areas list-inline">
          {attorneyPracticeAreas}
        </ul>
      )
    );
  }
}

AttorneyPracticeAreasList.propTypes = {
  attorneyUuid: PropTypes.string.isRequired,
  onPracticeAreaClick: PropTypes.func,
  practiceAreas: PropTypes.array,
  selectedTags: PropTypes.array,
};

AttorneyPracticeAreasList.defaultProps = {
  attorneyUuid: "",
  practiceAreas: [],
  selectedTags: [],
};
