import styled from "styled-components";

const NavList = styled.div`
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export default NavList;
