import React from "react";
import { Alert } from "react-bootstrap";

import Onboard from "../../blocks";
import Entry from "./Entry";
import Label from "common/blocks/Label";
import { MainCopy, NoEntriesCopy, PendingVerificationCopy } from "./copy";

export const MobileEntries = ({
  actionIsRequired,
  customErrorMsg,
  isAttorney,
  onDeleteClick,
  onFooterClick,
  onResendClick,
  reviewRequests,
  verifIsPending,
  warningType,
}) => (
  <div>
    <h1>References</h1>
    {actionIsRequired && (
      <div>
        <Label color="danger">ACTION REQUIRED</Label>
        <br />
        <br />
      </div>
    )}
    {customErrorMsg && (
      <Alert bsStyle={warningType}>
        <span dangerouslySetInnerHTML={{ __html: customErrorMsg }}></span>
      </Alert>
    )}
    {verifIsPending && <PendingVerificationCopy />}
    <MainCopy actionIsRequired={actionIsRequired} isAttorney={isAttorney} />
    <Onboard.Container>
      <div>
        {reviewRequests.length === 0 ? (
          <NoEntriesCopy />
        ) : (
          reviewRequests.map((reviewRequest, key) => (
            <Entry
              isAttorney={isAttorney}
              last={reviewRequests.length === key + 1}
              mobile
              onDeleteClick={onDeleteClick}
              onResendClick={onResendClick}
              reviewRequest={reviewRequest}
              key={key}
            />
          ))
        )}
      </div>
      <Onboard.ContainerFooter onClick={onFooterClick}>
        + ADD A REFERENCE
      </Onboard.ContainerFooter>
    </Onboard.Container>
  </div>
);

export const DesktopEntries = ({
  actionIsRequired,
  customErrorMsg,
  isAttorney,
  onDeleteClick,
  onFooterClick,
  onResendClick,
  reviewRequests,
  verifIsPending,
  warningType,
}) => (
  <Onboard.Container>
    <Onboard.ContainerHeader>
      <div>
        <h1>References</h1>
      </div>
      <div>
        {actionIsRequired && <Label color="danger">ACTION REQUIRED</Label>}
      </div>
    </Onboard.ContainerHeader>
    {customErrorMsg && (
      <Alert bsStyle={warningType}>
        <span dangerouslySetInnerHTML={{ __html: customErrorMsg }}></span>
      </Alert>
    )}
    {verifIsPending && <PendingVerificationCopy />}
    <MainCopy
      actionIsRequired={actionIsRequired}
      isAttorney={isAttorney}
      verifIsPending={verifIsPending}
    />
    <Onboard.Table>
      <thead>
        <tr>
          <th>NAME</th>
          <th>EMAIL</th>
          {!isAttorney && (
            <th>
              <center>WORK EXPERIENCE</center>
            </th>
          )}
          <th>STATUS</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {reviewRequests.length === 0 ? (
          <tr>
            <td colSpan={isAttorney ? "4" : "5"}>
              <NoEntriesCopy />
            </td>
          </tr>
        ) : (
          reviewRequests.map((reviewRequest, key) => (
            <Entry
              isAttorney={isAttorney}
              onDeleteClick={onDeleteClick}
              onResendClick={onResendClick}
              reviewRequest={reviewRequest}
              key={key}
            />
          ))
        )}
      </tbody>
    </Onboard.Table>
    <Onboard.ContainerFooter onClick={onFooterClick}>
      + ADD A REFERENCE
    </Onboard.ContainerFooter>
  </Onboard.Container>
);
