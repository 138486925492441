// @flow strict
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { destroy } from "redux-form";

import { ProgressBar } from "common/components";
import Workstyle from "./blocks";

class OutroSection extends Component {
  componentDidMount() {
    this.props.destroyForm("workstyleAssessmentForm");
  }

  render() {
    return (
      <Fragment>
        <Workstyle.OuterTop>
          <Link to="/contractor/">
            <Workstyle.LinkButton>← BACK TO DASHBOARD</Workstyle.LinkButton>
          </Link>
        </Workstyle.OuterTop>
        <Workstyle.Container>
          <Workstyle.Header>Workstyle Assessment</Workstyle.Header>
          <Workstyle.Box>
            <div>
              <Workstyle.Subheader>
                YOU&apos;RE ALL FINISHED!
              </Workstyle.Subheader>
              <Workstyle.Paragraph>
                Thank you for taking the time to complete your Workstyle
                Assessment—you have boosted your candidacy for this role. Take
                advantage of your assessment data and apply to more jobs now!
              </Workstyle.Paragraph>
            </div>
            <Link to="/contractor/jobs/">
              <Workstyle.Button>JOB SEARCH ›</Workstyle.Button>
            </Link>
          </Workstyle.Box>
        </Workstyle.Container>
        <ProgressBar completed={1} total={1} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  destroyForm: formName => dispatch(destroy(formName)),
});

export default connect(
  null,
  mapDispatchToProps,
)(OutroSection);
